import { mapValues } from 'lodash-es';
import { chain } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { includes } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Alert Service
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  var alertCategoriesList = [];
  var alertCategoriesEnum;
  var tenantAlertCategoriesList = [];
  var tenantAlertCategoriesEnum;

  angular.module('C').service('AlertService', AlertServiceFn);

  function AlertServiceFn(_, $q, $http, API, TenantService,
    $rootScope, UserService, NgIrisContextService, cUtils, CHART_COLORS,
    evalAJAX, FEATURE_FLAGS, NgAlertServiceApi) {

    return {
      addAlertsToResolution: addAlertsToResolution,
      createResolution: createResolution,
      deleteAlertNotificationRule: deleteAlertNotificationRule,
      getAlert: getAlert,
      getAlertCategories: getAlertCategories,
      getAlerts: getAlerts,
      getAlertTypeFilters: getAlertTypeFilters,
      getAlertTypes: getAlertTypes,
      getAnomalyAnalysisStats: getAnomalyAnalysisStats,
      getCategoryFilters: getCategoryFilters,
      getDeliveryRules: getDeliveryRules,
      getOwnResolutions: getOwnResolutions,
      getResolution: getResolution,
      getResolutions: getResolutions,
      loadAlertNotificationRules: loadAlertNotificationRules,
      saveAlertNotificationRule: saveAlertNotificationRule,
      setDeliveryRules: setDeliveryRules,
      transformAlertNotificationV1ToV2: transformAlertNotificationV1ToV2,
      transformAlertNotificationV2ToV1: transformAlertNotificationV2ToV1,
      mapAlertNamesAndList: mapAlertNamesAndList
    };


    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////

    /**
     * Returns alert categories enum.
     *
     * @method  getAlertCategories
     * @param   {Boolean} returnEnum Whether to return a list (default) or enum.
     * @return  {Array|Object}  Angular promise to return alert categories.
     */
    function getAlertCategories(returnEnum) {
      var isTenantUser = UserService.isTenantUser();
      if (returnEnum) {
        if (isTenantUser) {
          if (tenantAlertCategoriesEnum)
            return $q.resolve(tenantAlertCategoriesEnum);
        } else if (alertCategoriesEnum)
          return $q.resolve(alertCategoriesEnum);
      } else {
        if (isTenantUser) {
          if (tenantAlertCategoriesList.length)
            return $q.resolve(tenantAlertCategoriesList);
        } else if (alertCategoriesList.length)
          return $q.resolve(alertCategoriesList);
      }

      return getAlertTypes().then(alertTypeList => {
        const alertCategoriesSet = new Set();
        alertTypeList.forEach(alertType => alertCategoriesSet.add(alertType.category));
        return $http.get(API.public('alertCategories')).then(
          function fetchedAlertCategories(response) {
            var categoriesList = response.data || [];
            // 1. Filter out deprecated alert categories
            // 2. Filter out categories not present in alertTypes (backend filters it based on tenant/SP Admin scope).
            categoriesList = categoriesList.filter(
              function filterCategories(alertCategory) {
                return alertCategory.category && !alertCategory.category.startsWith('kDeprecated') &&
                  alertCategoriesSet.has(alertCategory.category);
            });
            var categoriesEnum =
              mapValues(keyBy(categoriesList, 'category'), 'name');

            if (isTenantUser) {
              tenantAlertCategoriesList = categoriesList;
              tenantAlertCategoriesEnum = categoriesEnum;
            } else {
              alertCategoriesList = categoriesList;
              alertCategoriesEnum = categoriesEnum;
            }
            return returnEnum ? categoriesEnum : categoriesList;
          }
        );
      });
    }

    /**
     * Returns alert typeBuckets from backend.
     * This is based on distinctive type buckets present in current scope.
     * Backend filters it on the basis of tenant/SP admin scope.
     *
     * @method  getAlertTypeFilters
     *
     * @return  {object}  Angular promise to return alert types.
     */
    function getAlertTypeFilters() {
      return getAlertTypes().then(alertTypeList => {
        const alertCategoriesSet = new Set();
        alertTypeList.forEach(alertType => alertCategoriesSet.add(alertType.alertTypeBucket));
        return Array.from(alertCategoriesSet);
      });
    }

    /**
     * Returns alert types list from backend.
     *
     * @method  getAlertTypes
     *
     * @param   {object}  [params]  Optional GET params.
     * @return  {object}  Angular promise to return alert types list.
     */
    function getAlertTypes(params) {
      return $http.get(API.public('alertTypes'), {
        params: params
      }).then(
        function fetchedAlertTypes(response) {
          return response.data || [];
        }
      );
    }

    /**
     * Deletes alert notification rule.
     *
     * @method  deleteAlertNotificationRule
     * @param   {object} ruleConfig  Alert Notification Rule config object.
     * @return  {object}             Angular $http promise.
     */
    function deleteAlertNotificationRule(ruleConfig) {
      if(FEATURE_FLAGS.alertNotificationV2) {
        return NgAlertServiceApi.RemoveAlertNotificationRule(ruleConfig?.ruleId)?.toPromise();
      }
      var api = API.public('alertNotificationRules') + '/' + ruleConfig.ruleId;
      return $http.delete(api);
    }

    /**
     * Creates new or saves existing Alert Notification Rule to server.
     *
     * @method  saveAlertNotificationRule
     * @param   {object} ruleConfig  Alert Notification Rule config object.
     * @return  {object}             Angular $http promise.
     */
    function saveAlertNotificationRule(ruleConfig) {
      if(FEATURE_FLAGS.alertNotificationV2) {
        const { ruleId = '' } = ruleConfig || {};

        const config = {
          ...ruleConfig,
          ...(ruleId && { id: ruleId })
        };

        const saveAlertApiCall = ruleId
          ? NgAlertServiceApi.UpdateAlertNotificationRule(ruleId, config)
          : NgAlertServiceApi.CreateAlertNotificationRule(config);

        return saveAlertApiCall?.toPromise();
      }
      return $http({
        url: API.public('alertNotificationRules'),
        method: ruleConfig.ruleId ? 'put' : 'post',
        data: ruleConfig,
      });
    }

    /**
     * Loads all alert notification rules.
     *
     * @method  loadAlertNotificationRules
     * @return  {object} Angular $http promise.
     */
    function loadAlertNotificationRules() {
      if(FEATURE_FLAGS.alertNotificationV2) {
        return NgAlertServiceApi.GetAlertNotificationRules()?.toPromise();
      }
      return $http.get(API.public('alertNotificationRules'));
    }

    /**
     * takes an alert id and return a promise to get the specific alert
     * @param  {Integer} alertId   the id of the desired alert
     * @return {promise}           returns a promise
     */
    function getAlert(alertId) {
      return $http({
        method: 'get',
        url:  $rootScope.basicClusterInfo.mcmMode &&
          get($rootScope, 'clusterInfo._allClusters') ?
          API.mcm('alerts', alertId) : API.public('alerts', alertId),
      });
    }


    // type AlertParams struct {
    // AlertIdList is the filter for Alert Ids.
    // AlertIdList []string `json:"'"alertIdList,omitempty"`
    // AlertTypeList is the filter for querying specific alert types.
    // AlertTypeList []int32 `json:"alertTypeList,omitempty"`
    // AlertCategoryList is the filter for querying specific alert categories.
    // AlertCategoryList []AlertCategory `json:"alertCategoryList,omitempty"`
    // StartTimeUsecs specifies the start time filter.
    // StartTimeUsecs *int64 `json:"startDateUsecs,omitempty"`
    // EndTimeUsecs specifies the end time filter.
    // EndTimeUsecs *int64 `json:"endDateUsecs,omitempty"`
    // AlertStateList is the filter for querying alerts in specific states.
    // AlertStateList []AlertState `json:"alertStateList,omitempty"`
    // AlertSeverityList is the filter for querying alerts of specific severity.
    // AlertSeverityList []AlertSeverity `json:"alertSeverityList,omitempty"`
    // ResolutionIdList is the filter for querying alerts having specific
    // resolution Ids.
    // ResolutionIdList []int64 `json:"resolutionIdList,omitempty"`
    // MaxAlerts limits the number of alerts returned to 'max_alerts'. To avoid
    // overwhelming the server and the client, this limit should always be set.
    // MaxAlerts *int32 `json:"maxAlerts,omitempty"`
    // }
    // These are filters.
    // Returns alert JSON ordered by lastRaiseDateUsecs
    function getAlerts(params) {
      var _url;
      var clusterInfo = $rootScope.clusterInfo;

      switch (true) {
        case ($rootScope.basicClusterInfo.mcmMode &&
          get($rootScope, 'clusterInfo._allClusters')):
          _url = API.mcm('alerts');
          break;

        case (includes(params.eventCategoryList, 'kSystemEvent')):
          _url = API.mcm('alerts');
          params.clusterIdentifiers = [
            clusterInfo.id, ':', clusterInfo.incarnationId].join('');
          break;

        default:
          _url = API.public('alerts');
      }

      return $http({
        method: 'get',
        url: _url,
        params: params,
        // cache alerts if endDateUsecs is provided and isn't a future date
        cache: params.hasOwnProperty('endDateUsecs') && ((params.endDateUsecs / 1000) <= Date.clusterNow()),
      }).then(function gotAlerts(response) {
        if (params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(response.data, 'tenantIds')
            .then(function updatedAlerts(alerts) {
              response.data = _transformAlerts(alerts);
              return response;
            });
        }
        return response;
      });
    }

    /**
     * Transforms the alerts list.
     *
     * @method   _transformAlerts
     * @param    {Array}   alerts   The list of alerts.
     * @returns  {Array}   The modified list of alerts.
     */
    function _transformAlerts(alerts) {
      return map(alerts, function mapAlerts(alert) {
        return assign(alert, {
          // True if the alert is generated by logged in user.
          _isAlertOwner:
            isEntityOwner(NgIrisContextService.irisContext, get(alert, 'tenantIds[0]')),
        });
      });
    }

    // Request: JSON for AlertResolution without resolution id
    // Response: JSON for AlertResolution that was created with resolution id.
    function createResolution(alertResolution) {
      return $http({
        method: 'post',
        url: API.public('alertResolutions'),
        data: alertResolution,
      });
    }

    // Request Payload:  JSON of {alertIds []int64}
    function addAlertsToResolution(resolutionId, alertIds) {
      return $http({
        method: 'put',
        url: API.public('alertResolutions', resolutionId),
        data: alertIds,
      });
    }


    function getResolution(resolutionId) {
      return $http({
        method: 'get',
        url: API.public('alertResolutions', resolutionId),
      });
    }

    /**
     * Get list of alert resolution owned by logged in user organization.
     *
     * @method     getOwnResolutions
     * @param      {Object}  params   The request params.
     * @return     {Object}  Promise resolved with list of alert resolutions
     *                       else rejected with error.
     */
    function getOwnResolutions(params) {
      return getResolutions(TenantService.extendWithEntityOwnerParams(params));
    }

    // Request Query Parameters{
    //  ids []int64
    //  alertIds []int64
    //  startDateUsecs int64 (for searching resolutionDate i.e. last update date)
    //  endDateUsecs int64
    //  maxCount int64
    // }
    // These are filter criteria. If none specified, get alert resolutions for last 24 hours.

    // Response: JSON for []AlertResolution
    function getResolutions(params) {
      params = params || {};
      cUtils.selfOrDefault(params, 'allUnderHierarchy', true);
      return $http({
        method: 'get',
        url: API.public('alertResolutions'),
        params: params,
      }).then(function getResolutionsSuccess(response) {
        if (params._includeTenantInfo) {
          return TenantService
            .resolveTenantDetails(response.data || [], 'tenantIds')
            .then(function updatedResolutions(resolutions) {
              response.data = resolutions;
              return response;
            });
        }
        return response;
      });
    }

    // Response: JSON for AlertDeliveryRules
    function getDeliveryRules() {
      return $http({
        method: 'get',
        url: API.private('alertDeliveryRules'),
      });
    }

    function setDeliveryRules(alertDeliveryRules) {
      return $http({
        method: 'put',
        url: API.private('alertDeliveryRules'),
        data: alertDeliveryRules,
      });
    }

    /**
     * Returns the list of category filters depending on the type of user.
     *
     * @method   getCategoryFilters
     * @returns  {Array}   List of category filters.
     */
    function getCategoryFilters() {
      var categoryFilters = [];
      var isHeliosUser = UserService.isHeliosTenantUser();
      var isTenantUser = UserService.isTenantUser();

      var categoryFilterKeysForGcpTenant = ['kBackupRestore'];
      // Checks for tenant scope in cluster has been removed because we are constructing categories by types provided by
      // backend which is already filtered in tenant scope.
      var categoriesEnum = isTenantUser ? tenantAlertCategoriesEnum : alertCategoriesEnum;

      for (var catEnum in categoriesEnum) {
        // skip loop if property is from prototype
        if (isHeliosUser && !categoryFilterKeysForGcpTenant.includes(catEnum)) {
          continue;
        }
        categoryFilters.push({
          name: categoriesEnum[catEnum],
          value: catEnum,
        });
      }

      return categoryFilters;
    }

    /**
     * Fetch Anomaly analysis stats to plot graph
     *
     * @method   getAnomalyAnalysisStats
     * @param     {Object}    params        GET Parameters
     * @param     {Object}    alertInfo     Alert Object containing dedup
     *                                      and clean snapshot times.
     * @returns   {Object}                  Promise to resolve with server's raw response.
     */
    function getAnomalyAnalysisStats(params, alertInfo) {
      return $http({
        method: 'GET',
        url: API.mcm('alerts', alertInfo.id, 'statistics'),
        params: params,
      }).then(function onSuccess(response) {
        return transformAnomalyAnalysisData(response.data, alertInfo);
      }, evalAJAX.errorMessage);
    }

    /**
     * Transform Anomaly analysis data into chart format.
     *
     * @method    transformAnomalyAnalysisData
     *
     * @param     {Object}    response        response object.
     * @param     {Object}    alertInfo   Alert Object containing dedup
     *                                    and clean snapshot times.
     * @returns   {Object}                returns transformed object.
     */
    function transformAnomalyAnalysisData(response, alertInfo) {
      var yPointMarker;
      var customMarker = {
        enabled: true,
        symbol: 'circle',
        radius: 5
      };
      var disableMarker = {
        enabled: false,
        states: {
          select: {
            enabled: false
          }
        }
      };
      var transformedData = {
        anomalousDataPoints: [],
        latestAnomalyIndex: -1,
        dataPointVec: [],
      }

      response = response || {};

      // Sort the timestamps before transforming
      transformedData.dataPointVec = chain(response.dataPointVec)
        .sortBy('timestampUsecs').map(function dataTransformer(dataPoint, index) {
          // Switch case to identify anomalous and clean datapoints.
          switch (true) {
            case (alertInfo.dedupTimestamps.includes(dataPoint.timestampUsecs)):
              customMarker.fillColor = CHART_COLORS.red;
              yPointMarker = customMarker;
              transformedData.latestAnomalyIndex = index;
              transformedData.anomalousDataPoints.push(dataPoint);
              break;

            case (dataPoint.timestampUsecs == alertInfo.cleanSnapshotTimestamp):
              customMarker.fillColor = CHART_COLORS.green;
              yPointMarker = customMarker;
              break;

            default:
              yPointMarker = disableMarker;
          }
          return {
            x: dataPoint.timestampUsecs / 1000,
            y: dataPoint.bytesWritten,
            marker: clone(yPointMarker),
          };
        }).value();

      return assign(response, transformedData);
    }

    /**
     * Converts alert notification data from v2 API format to v1 API format for UI rendering.
     * @param {Object|Array} alertNotificationV2Data - The alert notification data from v2 API,
     *  which can be an object containing an array or a direct array of notifications.
     * @returns {Object|Array} The transformed alert notification data in v1 API format.
     */
    function transformAlertNotificationV2ToV1(alertNotificationV2Data) {
      // Return as is if feature flag for alertNotificationV2 is not enabled
      if (!FEATURE_FLAGS.alertNotificationV2) {
        return alertNotificationV2Data;
      }

      if (!Array.isArray(alertNotificationV2Data)) {
        return []; // Return an empty array if the input is not valid
      }

      const data = alertNotificationV2Data?.map(function mapFn(alertRule) {
        const rule = {
          ...alertRule,
          ruleId: alertRule?.id || undefined,
            webHookDeliveryTargets: Array.isArray(
              alertRule?.webhookDeliveryTargets
            )
            ? alertRule.webhookDeliveryTargets.map(targets => ({
              externalApiUrl: targets.webhookUrl,
              curlOptions: targets.curlOptions
            })): [],
        };
        delete rule.webhookDeliveryTargets;
        return rule;
      });
      return {
        data
      };
    }

    /**
     * Converts an alert notification from v1 API format to v2 API format.
     * @param {Object} alertRule - The alert rule configuration from v1 API.
     * @returns {Object} The transformed alert rule in v2 API format.
     */
    function transformAlertNotificationV1ToV2(alertRule) {
      // Return as is if feature flag for alertNotificationV2 is not enabled
      if (!FEATURE_FLAGS.alertNotificationV2) {
        return alertRule;
      }

      if (!alertRule || typeof alertRule !== 'object') {
        return {};
      }

      const rule =  {
        ...alertRule,
        id: alertRule?.ruleId || undefined,
        webhookDeliveryTargets: Array.isArray(alertRule?.webHookDeliveryTargets)
          ? alertRule.webHookDeliveryTargets.map(targets => ({
            webhookUrl: targets.externalApiUrl,
            curlOptions: targets.curlOptions
          })): [],
      };
      delete rule.webHookDeliveryTargets;
      return rule;
    }

    /**
     * Maps alert types to their corresponding values based on the provided keys.
     *
     * This function filters and transforms an alert list using the provided alert types
     * to generate a list of alert names and their associated values.
     *
     * @param {Array} alertTypes - Array of alertTypes.
     * @param {Array} alertList - List of alertNames or alertTypeList to be mapped.
     * @param {string} key1 - The key used to identify items in the alertTypes (e.g., 'name' or 'value').
     * @param {string} key2 - The key used to map to the corresponding value in the alert types.
     * @returns {[Array, Array]} An array containing filtered alert names and filtered alertTypeList.
     */
    function mapAlertNamesAndList(alertTypes, alertList, key1, key2) {
      const alertTypesHash = {};
      let filteredAlertNames = [];
      let filteredAlertTypes = [];

      if (Array.isArray(alertList)) {
        // Create a hash map using the provided key1 and key2 from alertTypes
        alertTypes.forEach(function forEachAlertType(alertType) {
          alertTypesHash[alertType[key1]] = alertType[key2];
        });

        alertList.forEach(function alertTypesFilter(alertItem) {
          if (key1 === 'name' && alertTypesHash[alertItem]) {
            filteredAlertNames.push(alertItem);
            filteredAlertTypes = filteredAlertNames.map(function alertTypesMap(alertType) {
              return alertTypesHash[alertType];
            });
          } else if (key1 === 'value' && alertTypesHash[alertItem]) {
            filteredAlertTypes.push(alertItem);
            filteredAlertNames = filteredAlertTypes.map(function alertTypesMap(alertType) {
              return alertTypesHash[alertType];
            });
          }
        });
      }

      return [
        filteredAlertNames,
        filteredAlertTypes
      ];
    }

  }
})(angular);
