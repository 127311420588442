import { assign } from 'lodash-es';
// Controller: Agent Summary Report

;(function(angular, undefined) {
  'use strict';

  angular.module('C.reports')
    .controller('AgentStatusReportCtrl', agentStatusReportControllerFn);

  function agentStatusReportControllerFn(_, ReportsUtil, ReportsService,
    ENUM_HOST_TYPE, evalAJAX, FEATURE_FLAGS) {

    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,

      getData: getData,
      downloadCsv: downloadCsv,

      // Controller data
      agentsStatus: [],
      agentStatusConfig: {},
      ENUM_HOST_TYPE: ENUM_HOST_TYPE,
      FEATURE_FLAGS: FEATURE_FLAGS,
      loadingData: false,
      filter: {},
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      var params = {};
      $ctrl.agentStatusConfig =
        ReportsUtil.getReportConfig('kAgentDeploymentReport')();

      getData(params);
    }

    /**
     * Downloads the report as an CSV
     *
     * @method   downloadCsv
     */
    function downloadCsv() {
      var params = assign({}, $ctrl.filter, {
        outputFormat: 'csv'
      });

      getData(params);
    }

    /**
     * Retrieve the list of agent status based on the filter parameters
     *
     * @param   {array}   params   Filter params
     */
    function getData(params) {
      var filterParams = {
        hostOsType: [],
        healthStatus: [],
        compactVersion: '',
      };

      $ctrl.filter = params;
      $ctrl.loadingData = true;
      $ctrl.agentsStatus.length = 0;

      (params.agentOsType || []).forEach(function addOsType(type) {
        filterParams.hostOsType.push(type.name);
      });

      (params.agentHealthTypes || []).forEach(function addHealth(health) {
        filterParams.healthStatus.push(health.name);
      });

      filterParams.compactVersion = params.agentVersion === '' ?
        undefined : params.agentVersion;
      filterParams.outputFormat = params.outputFormat;

      ReportsService.getAgentStatusReport(filterParams)
        .then(function gotAgentStatus(agents) {
          $ctrl.agentsStatus = agents;
      }, evalAJAX.errorMessage).finally(function finalize() {
        $ctrl.filter.outputFormat = undefined;
        $ctrl.loadingData = false;
      });
    }
  }

})(angular);
