import { assign } from 'lodash-es';
// Controller: Export Report.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.heliosReports')
    .component('exportReport', {
      bindings: {},
      require: {
        cModalHoc: '^^?cModalHoc',
      },
      controller: 'ExportReportCtrl',
      templateUrl: 'app/reports/helios/export-report.html',
    })
    .controller('ExportReportCtrl', ExportReportCtrlFn);

  /**
   * Controller for Export Report.
   */
  function ExportReportCtrlFn(_, $q) {
    var $ctrl = this;

    var exportFormats = [
      {
        labelKey: 'pdf',
        value: 'pdf',
      },
      {
        labelKey: 'csv',
        value: 'csv',
      },
    ];

    assign($ctrl, {
      exportFormats: exportFormats,
      selectedFormat: exportFormats[0],

      // Life-Cycle Hooks.
      $onInit: $onInit,
    });

    /**
     * Initialize this component.
     *
     * @method     $onInit
     */
    function $onInit() {
      $ctrl.cModalHoc.onModalSave = onModalSave;
    }

    /**
     * Handle on save event when used inside modal.
     *
     * @method     onModalSave
     * @return     {Object}    Promise resolved with selected format.
     */
    function onModalSave() {
      return $q.resolve($ctrl.selectedFormat);
    }
  }
})(angular);