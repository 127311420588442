import { filter } from 'lodash-es';
import { find } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: Cluster Setup, Cluster Detect
// TODO(spencer): Clean up tasks: clean indent, ngAnnotate pattern, etc.

angular.module('C.clusterSetup')
  .controller(
    'clusterSetupDetectController',
      function($rootScope, $scope, $state, NodeService, cUtils, evalAJAX, _,
        cMessage, LIMIT_NODES, $translate, IP_FAMILY) {
        // Maximum and Minimum number of the nodes that can be selected.
        var minNodes = LIMIT_NODES.virtualEditionCluster.min;
        $scope.maxNodes = LIMIT_NODES.virtualEditionCluster.max;

        $scope.text = $rootScope.text.adminClusterSetupDetect;

        $scope.noNodesFound = false;
        $scope.currentChassisSerial;

        $scope.missingNodeCounter = 0;
        $scope.IP_FAMILY = IP_FAMILY;

        // Initialize to a resonable default value.  If IPv6 IP address used
        // in browser, we will assume user wants to use IPv6.
        // IPv6 addresses have minimum of 2 colons.
        let numColons = window.location.host.match(/:/g) || 0;
        $scope.shared.ipFamily = numColons >= 2 ? IP_FAMILY.IPv6 : IP_FAMILY.IPv4;

        /**
         * Directs user to appropriate setup flow based on install type
         */
        $scope.getStarted = function getStarted() {
          if ($rootScope.isVirtualEditionCluster &&
            filter($scope.shared.discoveredNodes, 'selected').length <
            minNodes) {
            cMessage.warn({
              textKey: 'adminClusterSetupDetect.warning.minimumNodes',
            });
            return;
          }
          var target;
          switch(true) {
            // Virtual ROBO or Virtual Edition Cluster.
            case $scope.isVirtualRobo || $rootScope.isVirtualEditionCluster:
              target = 'cluster-setup.details';
              break;

            // Physical ROBO.
            case $scope.isPhysicalRobo:
              target = 'cluster-setup.nodes';
              break;

            default:
              target = 'cluster-setup.select';
          }
          $state.go(target);
        };

        /**
         * Initializes the component.
         *
         * @method   activate
         */
        function activate() {
          if($scope.shared.discoverNodes) {
            return;
          }
          // THIS IS SPARTA!!!
          NodeService.discoverNodes().then(
            function discoverNodesSuccess(response) {
              var discoveredChassis = $scope.shared.discoveredChassis;
              var chassisDataTemplate = {};
              var discoveredNodeIps;

              if (response.data.freeNodes && response.data.freeNodes.length) {
                $scope.shared.discoveredNodes = response.data.freeNodes;

                $scope.shared.discoveredNodes.forEach(
                  function isNewChassisSerial(node) {
                    node.selected = !!node.connectedTo;
                    discoveredNodeIps = NodeService.getIps(node);

                    // If discoveredNodeIps is not an empty object
                    if (cUtils.jsObjectPropertyCount(discoveredNodeIps) >= 1) {
                      assign(node, discoveredNodeIps);
                    }

                    // check if Product Model name exists
                    if (!node.productModel) {
                      node.productModel = $translate.instant('unknownPlatform');
                    }

                    // Add missing nodes
                    if ($scope.currentChassisSerial !== node.chassisSerial &&
                      !node.missingNode) {
                      $scope.missingNodeCounter += NodeService.addMissingNodes(node,
                        $scope.shared.discoveredNodes);
                      $scope.currentChassisSerial = node.chassisSerial;
                    }

                    // Add chassisSerial to shared.discoveredChassis.
                    chassisDataTemplate = {
                      chassisSerial: node.chassisSerial,
                    };
                    if (!find(discoveredChassis, chassisDataTemplate)) {
                      discoveredChassis.push(chassisDataTemplate);
                    }
                  });
              } else {
                $scope.noNodesFound = true;
              }
            },
            evalAJAX.errorMessage
          ).finally(
            function discoverNodesFinally() {
              $scope.dataReady = true;
            }
          );
        }

        activate();
      });
