import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const clusterAccess = (ctx: StateAccessContext) => ctx.CLUSTER_VIEW && getConfigByKey(ctx.irisContext, 'system.features.storage', true);

export const StorageConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'consumer-detail.**',
      url: '^/monitoring/storage/consumers/detail/{consumerType: string}/{entityId: any}',
      loadChildren: () => import('./storage.module').then(m => m.StorageModule)
    },
    {
      name: 'ng-storage.**',
      url: '/monitoring/storage',
      loadChildren: () => import('./storage.module').then(m => m.StorageModule)
    },
    {
      name: 'organization-detail.**',
      url: '^/monitoring/storage/organizations/detail/{entityId: string}',
      loadChildren: () => import('./storage.module').then(m => m.StorageModule)
    },
    {
      name: 'storage-domain-detail.**',
      url: '^/monitoring/storage/storage-domains/detail/{entityId: int}',
      loadChildren: () => import('./storage.module').then(m => m.StorageModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    // these will be shown when FEATURE_FLAGS.storageNgDesign === true
    return {
      'consumer-detail': clusterAccess,
      'ng-storage': clusterAccess,
      'ng-storage.cluster': clusterAccess,
      'ng-storage.consumers': clusterAccess,
      'ng-storage.organizations': (ctx: StateAccessContext) =>
        ctx.CLUSTER_VIEW && ctx.clusterInfo.multiTenancyEnabled,
      'ng-storage.storage-domains': clusterAccess,
      'organization-detail': clusterAccess,
      'storage-domain-detail': clusterAccess,
    };
  }
};
