import { merge } from 'lodash-es';
import { forEach } from 'lodash-es';
import { constant } from 'lodash-es';
// MODULE:  slide modal service

;(function(angular) {
  'use strict';

  angular.module('C.slideModal', [])
    .service('SlideModalService', slideModalServiceFn);

  function slideModalServiceFn($rootScope, $uibModal, $transitions, _) {

    var modalConfigDefaults = {
      // 'static' = backdrop is present but modal window is not closed when
      // clicking outside of the modal window
      backdrop: 'static',
      size: 'md',
      windowClass: 'c-slide-modal-window',
      windowTemplateUrl: 'app/global/c-slide-modal/c-slide-modal-window.html',
      openedClass: 'c-slide-modal-open',
      fullHeight: false,

      // Set this property to true if you dont want to close the slider modal
      // on state change
      persistOnStateChanges: false,

      // Set this property to true if you want to get modal instance instead of
      // result promise and used to externally control the modal presence.
      returnModalInstance: false,
    };

    // at a mimimum, modalOptions must specify a template/templateUrl and
    // controller
    this.newModal = function newModal(modalOptions) {

      var config = modalOptions.useNativeMerge ?
        merge({}, modalConfigDefaults, modalOptions) :
        angular.merge({}, modalConfigDefaults, modalOptions);

      var modalInstance;
      var _cleanupListener;

      config.resolve = config.resolve || {};

      /**
       * NOTE: If you provide an innerComponent inside of resolve,
       * then this service will render the inner component inside the cModalHOC
       * with bindings passed to the inner component.
       *
       * @example
       * var modalConfig = {
       *  size: 'xl',
       *  resolve: {
       *    innerComponent: 'activeDirectoryList',
       *    ...
       *    bindings: {...},
       * },
       *
       * This would result in rendering the 'activeDirectoryList' inside
       * cModalHOC with the given bindings.
       *
       * Also if you need to overwrite the default onModalSave, you can add this
       * piece of code to the inner component's require bindings.
       *
       * require: {
       *  ...
       *  cModalHoc: '^^?cModalHoc',
       * }
       *
       * By this you can override the onModalSave and onModalCancel by simply
       * writing $ctrl.onModalSave = f() which should return a promise. Also
       * you will have the access the formCtrl to make form validations and API
       * calls etc.
       */
      if (config.resolve.innerComponent) {
        config.component = 'cModalHoc';
      }

      /**
       * We need to provide a function everytime to resolve the values
       * inside the resolve block. So if we provide a primitive value (It only
       * blows up if its a string), the code breaks. So, in order to incorporate
       * those drawbacks, we can use the constant function which is just a
       * wrapper function which returns the argument passed.
       */
      forEach(config.resolve, function mapResolve(value, key) {
        config.resolve[key] = typeof value === 'string' ? constant(value) :
          value;
      });

      if (config.fullHeight) {
        config.openedClass =
          [config.openedClass, 'c-slide-modal-full-height'].join(' ');
      }

      if (config.autoHeight) {
        config.openedClass =
          [config.openedClass, 'c-slide-modal-auto-height'].join(' ');
      }

      modalInstance = $uibModal.open(config);

      if (!config.resolve.persistOnStateChanges) {
        // since nav is exposed, setup a state change listener to close the
        // slider/modal on state change
        _cleanupListener = $transitions.onStart({}, function closelFn() {
          modalInstance.dismiss('state change');
        });
      }

      // on promise resolution, do some cleanup
      modalInstance.result.finally(_cleanupListener);

      return config.returnModalInstance ? modalInstance : modalInstance.result;

    };
  }

}(angular));
