import { sortBy } from 'lodash-es';
import { findIndex } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Access Management

/* NOTE
 * Access Management Module is tabbed view which holds access related modules
 * like User & Groups Management.
 */

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.accessManagement', ['C.seeMore', 'C.viewMoreButton'])
    .config(configFn)
    .controller('accessManagementController', accessManagementCtrlFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('access-management', {
        title: 'Cohesity Access Management',
        url: '^/admin/access',
        canAccess: getAccessExpressionForTabs(),
        allClustersSupport: {
          singleClusterState: 'access-management',
          allClustersState: 'helios-access-management',
        },
        controller: 'accessManagementController as $ctrl',
        templateUrl: 'app/admin/access-management/access-management.html',
      });
  }

  /*
   * to add a new tab, add the tab config in accessManagementTabsConfig list
   * to make default route add an key 'default: true' while defining the tab
   * config. tab should render its template inside view named 'tab-view'.
   */
  var accessManagementTabsConfig = [
    {
      headingKey: 'usersAndGroups.tabName',
      route: 'access-management.user-groups',
      default: true,
    },
    {
      headingKey: 'support',
      route: 'access-management.support.list',
    },
    {
      headingKey: 'roles.tabName',
      route: 'access-management.roles',
    },
    {
      headingKey: 'ad.tabName',
      route: 'access-management.active-directory',
    },
    {
      headingKey: 'ldap',
      route: 'access-management.ldap',
    },
    {
      headingKey: 'organizations',
      route: 'access-management.tenants',
    },
    {
      headingKey: 'sso',
      route: 'access-management.sso',
    },
    {
      headingKey: 'hybridExtender',
      route: 'access-management.bifrost-settings',
    },
  ];

  /**
   * Returns an expression which will indicate if the currently logged in user
   * can access any one of the tabs included in the set. if user can't access
   * any of the tabs, the entire tabbed page should/will be hidden.
   *
   * @method   getAccessExpressionForTabs
   * @return   {String}   The canAccess expression for tabs.
   */
  function getAccessExpressionForTabs() {
    return 'canUserAccessAnyTab(' + JSON.stringify(accessManagementTabsConfig) + ')';
  }

  function accessManagementCtrlFn(_, $state, $transitions, $rootScope, $scope,
    FEATURE_FLAGS, NgStateManagementService, ngDialogService) {

    var $ctrl = this;

    // Specify subset of allowed types for Tenant users. Otherwise pass null and
    // use the defaults.
    var allowedTypes = $rootScope.isTenantUser() ?
      ['local_user', 'local_group', 'ad', 'sso'] : null;
    var _unregisterTrans;

    // split button items config
    var actionsList = [
      {
        translateKey: 'addCustomRole',
        state: FEATURE_FLAGS.ngRoles ? 'new-role-ng' : 'new-role',
        parentState: FEATURE_FLAGS.ngRoles ? 'on-prem-access-management.roles' : 'roles',
      },
      {
        translateKey: 'addActiveDirectory',
        state: FEATURE_FLAGS.ngActiveDirectory ?
          'active-directory-add' : 'add-ad',
        parentState: FEATURE_FLAGS.ngActiveDirectory ?
          'active-directory-ng' : 'active-directory',
      },
      {
        translateKey: 'addLdapProvider',
        state: FEATURE_FLAGS.ngLdapProvider ?
          'create-ldap' : 'add-ldap',
        parentState: FEATURE_FLAGS.ngLdapProvider ?
          'ldap-ng' :'ldap',
      },
      {
        translateKey: 'addOrganization',
        state: 'add-tenant',
        parentState: 'tenants',
      },
      {
        translateKey: 'configureSSO',
        state: 'add-sso',
        parentState: 'sso',
      },
      FEATURE_FLAGS.apiKeysEnabled && !$rootScope.isTenantUser() ? {
        translateKey: 'addApiKey',
        state: 'add-api-key',
        parentState: 'api-keys',
      } : null,
    ].filter(Boolean);

    actionsList.unshift(
      _getNewPrincipalMenuOption('group', 'local', null, 'addLocalGroup'),
    );

    actionsList.unshift(
      _getNewPrincipalMenuOption('principal', 'ad', allowedTypes, 'addAdUsersGroups'),
      _getNewPrincipalMenuOption('principal', 'sso', allowedTypes, 'addSsoUsersGroups'),
      _getNewPrincipalMenuOption('user', 'local', allowedTypes, 'addLocalUser')
    );

    // Only if Keystone feature flag and Organizations are both enabled.
    if (FEATURE_FLAGS.keystoneModuleEnabled &&
      $rootScope.clusterInfo.multiTenancyEnabled) {
      actionsList.push(
        {
          translateKey: 'addKeystoneServer',
          state: 'keystone-add',
          parentState: 'keystone',
        }
      );
    }

     // Only if abac feature flag is enabled
     if (FEATURE_FLAGS.abacModule) {
      actionsList.push(
        {
          translateKey: 'addAbacServer',
          state: 'abac-add',
          parentState: 'abac',
        }
      );
    }

    assign($ctrl, {
      accessManagementTabsConfig: accessManagementTabsConfig,
      actionsList: actionsList.slice(),
      multiTenancyEnabled: $rootScope.clusterInfo.multiTenancyEnabled,

      // Component life-cycle methods.
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      updateTabList();

      var defaultTab;

      if ($state.current.name === 'access-management') {
        defaultTab = NgStateManagementService.getDefaultTab(
          $ctrl.accessManagementTabsConfig);
        $state.go(defaultTab.route);
      }

      updateActionsList($state.current);
    }

    /**
     * Updates the tab items based on the FEATURE_FLAGS status.
     *
     * @method   updateTabList
     */
    function updateTabList() {
      // When ngRoles is enabled, replace ajs roles
      // state with NG roles-ng state.
      if (FEATURE_FLAGS.ngRoles) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'roles.tabName']);

        $ctrl.accessManagementTabsConfig.splice(index, 1, {
          headingKey: 'roles.tabName',
          route: 'on-prem-access-management.roles',
        });
      }

      // when ngActiveDirectory is enabled, replace ajs active-directory
      // state with NG active-directory-ng state.
      if (FEATURE_FLAGS.ngActiveDirectory) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'ad.tabName']);

        $ctrl.accessManagementTabsConfig.splice(index, 1, {
          headingKey: 'ad.tabName',
          route: 'access-management.active-directory-ng',
        });
      }

      // When ngLdapProvider is enabled, replace ajs ldap
      // state with NG ldap-ng state.
      if (FEATURE_FLAGS.ngLdapProvider) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'ldap']);

        $ctrl.accessManagementTabsConfig.splice(index, 1, {
          headingKey: 'ldap',
          route: 'access-management.ldap-ng',
        });
      }

      // When ngSSO is enabled, replace ajs SSO tab with NG SSO tab
      if (FEATURE_FLAGS.ngSSO) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'sso']);

        $ctrl.accessManagementTabsConfig.splice(index, 1, {
          headingKey: 'sso',
          route: 'access-management.ng-sso',
        });
      }

      // When keystoneModuleEnabled is enabled, add keystone tab.
      if (FEATURE_FLAGS.keystoneModuleEnabled) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'keystone']);

        if (index < 0) {
          $ctrl.accessManagementTabsConfig.push(
            {
              headingKey: 'keystone',
              route: 'access-management.keystone',
            },
          );
        }
      }

      // When nisEnabled is enabled, add nis tab.
      if (FEATURE_FLAGS.nisEnabled) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'nis']);

        if (index < 0) {
          $ctrl.accessManagementTabsConfig.push(
            {
              headingKey: 'nis',
              route: 'access-management.nis',
            },
          );
        }
      }

      // When abac is enabled, add abac tab.
      if (FEATURE_FLAGS.abacModule) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'abac']);

        if (index < 0) {
          $ctrl.accessManagementTabsConfig.push(
            {
              headingKey: 'abac',
              route: 'access-management.abac',
            },
          );
        }
      }

      // Enable API Keys if the flag is enabled.
      if (FEATURE_FLAGS.apiKeysEnabled && !$rootScope.isTenantUser()) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'apiKeys']);

        if (index < 0) {
          $ctrl.accessManagementTabsConfig.push(
            {
              headingKey: 'apiKeys',
              route: 'access-management.api-keys',
            },
          );
        }
      }

      // Push the kerberos tab after AD.
      const isTenantUser = $rootScope.isTenantUser() || $rootScope.isHeliosTenantUser();
      if (FEATURE_FLAGS.kerberosEnabled && !isTenantUser) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'kerberos']);

        var indexOfAD = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'ad.tabName']);

        if (index < 0) {
          $ctrl.accessManagementTabsConfig.splice(indexOfAD + 1, 0, {
            headingKey: 'kerberos',
            route: 'access-management.kerberos',
          });
        }
      }

      // Push the MFA tab after AD.
      if (FEATURE_FLAGS.mfaEnabled || FEATURE_FLAGS.splitKeyHostAccess) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'secureLogin.tabName']);

        var indexOfAD = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'ad.tabName']);

        if (index < 0) {
          $ctrl.accessManagementTabsConfig.splice(indexOfAD + 1, 0, {
            headingKey: 'secureLogin.tabName',
            route: 'access-management.secure-login',
          });
        }
      }

      // Delete Support tab if feature flags is false.
      if (!FEATURE_FLAGS.supportUserMfaEnabled) {
        var index = findIndex($ctrl.accessManagementTabsConfig,
          ['headingKey', 'support']);

        if (index >= 0) {
          $ctrl.accessManagementTabsConfig.splice(index, 1);
        }
      }
    }

    /**
     * Updates(sorts) the actionsList according to the current state.
     *
     * @method   updateActionsList
     *
     * @param    {Object} state The current state.
     */
    function updateActionsList(state) {
      if (FEATURE_FLAGS.ngUsersAndGroups) {
        $ctrl.actionsList = [];
        return;
      }
      // The parent state name is of the type 'access-management.user-groups',
      // so use split to get the sub state name.
      var parentStateName = $ctrl.parentState = state.name.split('.')[1];
      var index = findIndex(actionsList, {
        parentState: parentStateName,
      });

      // Moves the action to the first element.
      if (index !== -1) {
        $ctrl.actionsList = sortBy(actionsList, function sortBy(action) {
          return action.parentState !== parentStateName;
        });
      }
    }

    // Listen to state change event and update the actionsList.
    _unregisterTrans = $transitions.onStart({}, function stateUpdate(trans) {
      updateActionsList(trans.$to());
    });

    $scope.$on('$destroy', _unregisterTrans);

    /**
     * Returns the context menu option for creating a
     * new principal based on the feature flag enabled
     *
     * @method   updateActionsList
     *
     * @param type specifies the principal type
     * @param realm specifies the context for the principol to be created
     * @param allowedTypes list of allowed principalType options
     * @param label translation key for the menu option title
     */
    function _getNewPrincipalMenuOption(type, realm, allowedTypes, label) {
      if(FEATURE_FLAGS.ngUsersAndGroups) {
        var category = realm !== 'local' ? realm : type === 'group' ? 'localGroup' : 'localUser';

        return {
          translateKey: label,
          state: 'onprem-access.edit',
          stateParams: { category },
        }
      } else {
        return {
          translateKey: label,
          state: 'new-principal',
          stateParams: {
            realm,
            type,
            allowedTypes: allowedTypes,
          },
          parentState: 'user-groups',
        }
      }
    }

    function _openAddPrincipalDialog(type, realm, allowedTypes) {
      if (FEATURE_FLAGS.ngActiveDirectory) {
        return ngDialogService.showDialog('user-groups-builder-dialog', {
          type,
          domainCategory: realm,
          allowedTypes
        }, {
          width: '50rem',
        });
      }
    }

  }

})(angular);
