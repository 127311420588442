import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import { AppName, ClusterConfig, getConfigByKey, IrisContextService } from '@cohesity/iris-core';
import { getClusterConfigPartial } from '@cohesity/sizer-libs/shared';
import { StateGuardService } from 'src/app/core/state/state-guard.service';

import { AppServiceConfig, AppServiceType } from '../app-service.config';
import { PlatformInsightsNavService } from './platform-insights-nav.service';
import { PlatformInsightsGuard } from './platform-insights.guard';


/**
 * This service configures the Platform Insights service configuration within iris.
 */
@Injectable({ providedIn: 'root' })
export class PlatformInsightsServiceConfig extends AppServiceConfig {
  serviceType: AppServiceType = 'piaas';

  appName: AppName = 'platformInsights';

  homeState = 'simulation.list';

  get clusterConfigPartial() {
    return {
      name: this.serviceTitle,
      ...getClusterConfigPartial(),
    } as Partial<ClusterConfig>;
  }

  // Conditionally suports global search based on logic in AppLayoutComponent.
  supportsGlobalSearch = true;

  navProvider = this.navService;

  constructor(
    private irisContext: IrisContextService,
    private navService: PlatformInsightsNavService,
    stateGuardService: StateGuardService,
    translate: TranslateService,
  ) {
    super(translate);
    stateGuardService.registerStateGuards([PlatformInsightsGuard]);
    this.applyOverrides();
  }

  /**
   * This function will override AppServiceConfig with the platform overrides
   * for only the static properties like homeState, clusterConfigPartial etc.
   */
  private applyOverrides() {
    this.irisContext.irisContext$.pipe(
      filter(irisContext => !isEmpty(irisContext?.appConfiguration)),
      take(1),
    ).subscribe((irisContext) => {
      const clusterConfigOverrides = getConfigByKey<Partial<
        Record<AppServiceType, Partial<AppServiceConfig>>
      >>(irisContext, 'appServiceConfigs', {});

      const serviceOverride = clusterConfigOverrides?.[this.serviceType] ?? {};

      if (serviceOverride?.homeState) {
        this.homeState = serviceOverride.homeState;
      }
    });
  }
}
