import { assign } from 'lodash-es';
// Directive: Job Settings List

;(function(angular) {
  'use strict';

  angular.module('C.jobs').directive('jobSettingsList', jobSettingsListFn);

  function jobSettingsListFn(_, $rootScope, $translate, ViewBoxService,
    evalAJAX, ENUM_PRIORITY, JOB_QOS_POLICY_TYPE, ENV_GROUPS,
    ENV_TYPE_CONVERSION, ENUM_FULL_BACKUP_TYPE, DateTimeService, ClusterService) {

    return {
      restrict: 'AE',
      link: linkFn,
      scope: {
        job: '=',
      },
      templateUrl:
        'app/protection/jobs/job-settings-list/job-settings-list.html',
    };

    /**
     * Component link function.
     *
     * @function   linkFn
     * @param      {Object}   scope   The Component's scope.
     * @param      {Object}   elem    The Component element.
     * @param      {Object}   attrs   Attributes on the component instance.
     */
    function linkFn(scope, elem, attrs) {

      assign(scope, {
        ENUM_PRIORITY: ENUM_PRIORITY,
        ENV_GROUPS: ENV_GROUPS,
        ENUM_FULL_BACKUP_TYPE: ENUM_FULL_BACKUP_TYPE,
        FEATURE_FLAGS: $rootScope.FEATURE_FLAGS,
        JOB_QOS_POLICY_TYPE: JOB_QOS_POLICY_TYPE,
        now: Date.clusterNow(),
        MAX_INT: Math.pow(2, 63),
      });

      /**
       * Directive initialization
       */
      function activate() {
        getViewBoxName();
      }

      // If the hardware model is PXG1, then cluster is using Disaggregated Storage.
      scope._isDisaggregatedStorage = ClusterService.clusterInfo._isDisaggregatedStorage;

      /**
       * Gets the alerts list string, formatted with commas and/or ands as
       * appropriate.
       *
       * @method   getAlertsListString
       * @return   {string}   The alerts list string.
       */
      scope.getAlertsListString = function getAlertsListString() {
        // TODO: Consolidate this string construction into ui.json.
        var alertsArr = [];
        var joiner;

        // check for alerting on success
        if ([1, 3, 5, 7, 9, 11].includes(scope.job.alertingPolicy.policy)) {
          alertsArr.push($translate.instant('success'));
        }

        // check for alerting on failure
        if ([2, 3, 6, 7, 9, 11].includes(scope.job.alertingPolicy.policy)) {
          alertsArr.push($translate.instant('failure'));
        }

        // check for alerting on sla violation
        if ([4, 5, 6, 7, 9, 11].includes(scope.job.alertingPolicy.policy)) {
          alertsArr.push($translate.instant('slaViolation'));
        }

        if (alertsArr.length > 1) {
          // and an 'and ' to the last item in the array
          alertsArr[alertsArr.length - 1] = [
            $translate.instant('and'),
            alertsArr[alertsArr.length - 1],
          ].join(' ');

          joiner = alertsArr.length > 2 ? ', ' : ' ';
        }

        return alertsArr.join(joiner);
      };

      /**
       * Determines if a job is sql volume based physical server job.
       *
       * @method   isSqlVolumeBasedPhysicalJob
       * @return   {boolean}   True if sql physical server job, False otherwise.
       */
      scope.isSqlVolumeBasedPhysicalJob =
        function isSqlVolumeBasedPhysicalJob() {
          return scope.job.type === 3 &&
            scope.job.envBackupParams.sqlBackupJobParams &&
            (!scope.job.envBackupParams.sqlBackupJobParams.fullBackupType ||
            scope.job.envBackupParams.sqlBackupJobParams.fullBackupType === 0) &&
            (scope.job.sources.length === 0 ||
            !!scope.job.sources[0].entities[0].physicalEntity);
        };

      /**
       * gets the viewbox for the job
       *
       * @method   getViewBoxName
       */
      function getViewBoxName() {
        // viewBoxName is defined in job flow,
        // if its not present an API call is necessary
        if (scope.job.hasOwnProperty('viewBoxName')) {
          scope.viewBox = {
            id: scope.job.viewBoxId,
            name: scope.job.viewBoxName,
          };
        } else {
          // Is Cloud job without local snapshots or is CloudArchiveDirect.
          if (ENV_GROUPS.cloudJobsWithoutLocalSnapshot
            .includes(scope.job.type) || scope.job._isCloudArchiveDirect) {

            switch (scope.job.type) {
              case ENV_TYPE_CONVERSION.kRDSSnapshotManager:
              case ENV_TYPE_CONVERSION.kAuroraSnapshotManager:
              case ENV_TYPE_CONVERSION.kAWSSnapshotManager:
                scope.remoteSnapshotLocation = 'awsS3';
                break;

              case ENV_TYPE_CONVERSION.kAzureSnapshotManager:
                scope.remoteSnapshotLocation = 'azureStorage';
                break;

              default:
                angular.noop();
            }
          } else {
            ViewBoxService.getViewBox(scope.job.viewBoxId).then(
              function getViewBoxSuccess(viewBox) {
                scope.viewBox = viewBox;
              },
              evalAJAX.errorMessage
            );
          }
        }
      }

      activate();
    }
  }

}(angular));
