import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ByteSizeService, LineChartComponent, MomentDatePipe } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { HumanizeFromNumbersPipe } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import {
  Options,
  PlotAreaOptions,
  SeriesAfterAnimateEventObject,
  SeriesBubbleOptions,
  SeriesSplineOptions,
  XAxisPlotLinesOptions,
  YAxisOptions,
} from 'highcharts';
import { findIndex, merge } from 'lodash-es';

import { MetricTypePipe } from '../../pipes';
import {
  AnomalyAlert,
  AnomalyChartSeries,
  ChartPoint,
  DataPoint,
  GroupedAnomalyMetrics,
  MetricType,
} from '../../security-shared.models';

/**
 * X-axis plotline
 */
const plotLineId = 'plot-line-1';
const xAxisSelectionPlotLine: XAxisPlotLinesOptions = {
  value: 0,
  id: plotLineId,
};

/**
 * Component to manage chart on details and recovery page
 *
 * 1. Defines base series
 * 2. Updates configuration based on type (kDetails / kRecovery)
 *
 * @example
 *  <dg-ar-anomaly-chart
 *    [seriesData]="anomaly.seriesData"
 *    [type]="'kDetails'"
 *    (pointSelection)="anomalousSnapshotSelected($event)">
 *  </dg-ar-anomaly-chart>
 */
@Component({
  selector: 'dg-ar-anomaly-chart',
  templateUrl: './anomaly-chart.component.html',
  styleUrls: ['./anomaly-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HumanizeFromNumbersPipe],
})
export class AnomalyChartComponent implements OnInit {
  /**
   * Line chart reference.
   */
  @ViewChild('lineChart') lineChart: LineChartComponent;

  /**
   * Chart data
   */
  @Input() seriesData: AnomalyChartSeries;

  /**
   * Chart data
   */
  @Input() selectedSnapshot: ChartPoint;

  /**
   * Chart data
   */
  @Input() alert: AnomalyAlert;

  /**
   * Type of chart
   * kDetails / kRecovery
   */
  @Input() type = 'kDetails';

  /**
   * Metric name for which graph is plotted
   */
  @Input() metric: MetricType;

  /**
   * Emit point selection for host component
   */
  @Output() pointSelection = new EventEmitter<ChartPoint>();

  /**
   * Chart series data with default options
   */
  lineChartSeriesData: (SeriesBubbleOptions | SeriesSplineOptions)[] = [];

  /**
   * Enum for template use
   */
  readonly metricType = MetricType;

  /**
   * Indicates whether the chart is being hovered on or not to update the     stat-list. Default is false.
   */
  isChartActive = false;

  /**
   * Used for showing legend information
   */
  chartLegendInfo: DataPoint = {};

  /**
   * saves anomalous data point selection
   */
  selectedAnomalousPoint: ChartPoint = {};

  /**
   * Chart options
   * This has to be set because chart prints axis in UTC while cogDate converts it to local TS.
   */
  chartOptions: Options = {
    time: {
      useUTC: false,
    },
    tooltip: {
      ...this.formatTooltip(),
    },
    plotOptions: {
      spline: {
        stickyTracking: false,
        connectNulls: true,
        marker: {
          enabled: true,
        },
      },
      bubble: {
        maxSize: 18,
      },
    },
  };

  /**
   * Gets the metric label
   */
  get metricTypeLabel(): string {
    return this.metricType[this.metric];
  }

  /**
   * flag to check if clean room recovery is enabled
   */
  get isCleanRoomRecoveryPhase1Enabled() {
    return flagEnabled(this.irisCtx.irisContext, 'cleanRoomRecoveryPhase1');
  }

  /**
   * returns true if the new tagging is enabled
   */
  get isNewSnapshotTaggingEnabled(): boolean {
    return this.isCleanRoomRecoveryPhase1Enabled;
  }

  constructor(
    private byteSizeService: ByteSizeService,
    private datePipe: MomentDatePipe,
    private humanizeFromNumbersPipe: HumanizeFromNumbersPipe,
    private irisCtx: IrisContextService,
    private metricTypePipe: MetricTypePipe,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.setSeriesData();
    this.setYaxisOptions();
    this.updateSeriesConfiguration(this.type);
  }

  /**
   * This function sets series data.
   */
  setSeriesData() {
    /**
     * Series data
     */
    this.lineChartSeriesData = [
      {
        type: 'spline',
        data: this.seriesData.transformedDataPoints,
        name: this.translateService.instant('cleanSnapshot'),
        showInLegend: true,
      },
      {
        type: 'bubble',
        data: this.seriesData.anomalousDataPoints,
        name: this.translateService.instant('anomalousSnapshot'),
        marker: {
          symbol: 'square',
        },
      },
      {
        type: 'bubble',
        data: [this.seriesData.latestCleanDataPoint],
        name: this.translateService.instant('latestCleanSnapshot'),

        // Add different marker for accessibility
        marker: {
          symbol: 'triangle',
        },
      },
      {
        type: 'spline',
        data: this.seriesData.expiredDataPoints,
        name: this.translateService.instant('expiredSnapshot'),
        lineWidth: 0,
        showInLegend: true,

        // Add different marker for accessibility
        marker: {
          symbol: 'circle',
        },
      },
    ];

    if (this.isCleanRoomRecoveryPhase1Enabled) {
      this.lineChartSeriesData.push({
        type: 'bubble',
        data: this.seriesData.taggedDataPoints,
        name: this.translateService.instant('taggedSnapshots'),
        marker: {
          symbol: 'url(helix-assets/i/icn/core/action-tag.svg)',
        },
        tooltip: null,
      });
    }
  }

  /**
   * Custom tooltip formatter for highcharts series.
   */
  formatTooltip() {
    // Cache component context for value formatting.
    const self = this;

    return {
      formatter() {
        if (this.point.noTooltip) {
          return false;
        }

        // if a data point has its own tooltip, show that instead
        if (this.point.tooltip) {
          return this.point.tooltip;
        }

        const timestamp = this.x;
        self.chartLegendInfo = self.seriesData.timestampDataPoints[timestamp * 1000][0];

        if (!self.chartLegendInfo) {
          return false;
        }

        // Add tags in the tooltip if present
        const tags = self.chartLegendInfo.tags?.length && !self.isNewSnapshotTaggingEnabled
          ? `<span class="pill-tooltip-margin c-task-status-pill c-task-anomalous-snapshot-pill">
            ${self.chartLegendInfo.tags[0]?.label}
          </span>`
          : '';

        let tooltip = `
          <div class="flex-row"><h5>${self.chartLegendInfo.tooltip}</h5><div>${tags}</div></div>
          <div class="margin-top-sm">
            ${self.translateService.instant('time')}: ${self.datePipe.transform(timestamp)}
          </div>
        `;

        for (const key in self.chartLegendInfo) {
          if (key) {
            const metricTranslation = self.metricTypePipe.transform(key as MetricType);
            const value = self.chartLegendInfo[key];
            switch (true) {
              case key === MetricType.kBytesWritten:
                tooltip += `<div class="margin-top-sm">${metricTranslation}: ${
                  self.byteSizeService.bytesToSize(value).displayValue
                }</div>`;
                break;

              case GroupedAnomalyMetrics.files.includes(key as MetricType):
                tooltip += `<div class="margin-top-sm">${metricTranslation}: ${self.humanizeFromNumbersPipe.transform(
                  value
                )}</div>`;
                break;

              case GroupedAnomalyMetrics.ratios.includes(key as MetricType):
                tooltip += `<div class="margin-top-sm">${metricTranslation}: ${value}</div>`;
                break;
            }
          }
        }
        return tooltip;
      },
    };
  }

  /**
   * Sets Y axis title & formatter
   */
  setYaxisOptions() {
    const self = this;
    const seriesChartMetricType = this.metricType[this.seriesData.chartMetric];

    // For ratios, No changes needed
    if (GroupedAnomalyMetrics.ratios.includes(seriesChartMetricType)) {
      return;
    }

    /**
     * Add Y-Axis options
     * If the delta between clean and anomalous snapshot is very big then bubble chart has difficulty in displaying.
     * for e.g. Anomalous snapshot deleted 10k files and clean snapshot didn't delete any files
     * This gives highcharts breather and plots first label below 0 to show clear graph.
     */
    const yAxisPointsOptions: YAxisOptions = {
      showFirstLabel: false,
      min: (this.seriesData.latestCleanDataPoint.y || 1) * 0.01 * -1,
    };

    /**
     * Formatter is needed only for bytes & files, other options contain plain numerical values
     * Not using arrow function here since need to use current scope's value
     */
    switch (true) {
      case seriesChartMetricType === MetricType.kBytesWritten:
        yAxisPointsOptions.labels = {
          formatter: function () {
            return self.byteSizeService.bytesToSize(this.value as number).displayValue;
          },
        };
        break;

      case GroupedAnomalyMetrics.files.includes(<MetricType>seriesChartMetricType):
        yAxisPointsOptions.labels = {
          formatter: function () {
            return self.humanizeFromNumbersPipe.transform(this.value as number);
          },
        };
        break;
    }

    merge(this.chartOptions, { yAxis: yAxisPointsOptions });
  }

  /**
   * This updates series configuration according to type.
   * Graph is rendered when viewing details (kDetails) and starting recovery (kRecovery).
   * In both cases, chart is slightly tweaked to allow different series selection.
   */
  updateSeriesConfiguration(type: string) {
    const pointSelection: PlotAreaOptions = {
      allowPointSelect: true,
      events: {
        afterAnimate: (event: SeriesAfterAnimateEventObject) => {
          let selectedPoint;
          if (this.selectedSnapshot && this.selectedSnapshot.x && this.selectedSnapshot.y) {
            const points = event.target.chart.series[0].data;
            const index = findIndex(points, ['x', this.selectedSnapshot.x]);

            // This is to make sure it shows selection plot line
            selectedPoint = points[index];
          } else {
            const selectionIndex = type === 'kDetails' ? event.target.points.length - 1 : 0;
            selectedPoint = event.target.points[selectionIndex];
          }

          // In case the API does not return the select snapshot.
          // Make sure we don't process any information after this.
          if (!selectedPoint) {
            return;
          }

          selectedPoint.select();
          this.lineChart.chart.ref.tooltip.refresh(selectedPoint);
        },
      },
    };

    switch (type) {
      case 'kDetails':
        merge(this.lineChartSeriesData[1], pointSelection);
        break;

      case 'kRecovery':
        merge(this.lineChartSeriesData[2], pointSelection);
        merge(this.lineChartSeriesData[0], { allowPointSelect: true });
        merge(this.lineChartSeriesData[1], { allowPointSelect: true });
        break;
    }
  }

  /**
   * To add plot line to the selected point.
   */
  pointSelected(point: ChartPoint) {
    const { chartRef } = point;
    xAxisSelectionPlotLine.value = point.x;

    // Remove plot line before adding new one.
    chartRef.xAxis[0].removePlotLine(plotLineId);
    chartRef.xAxis[0].addPlotLine(xAxisSelectionPlotLine);

    this.selectedAnomalousPoint = point;
    this.chartLegendInfo = this.seriesData.timestampDataPoints[this.selectedAnomalousPoint.x * 1000][0];
    this.pointSelection.emit(point);
  }
}
