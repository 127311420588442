<div>
  <div class="flex-row-sm flex-equal-division" [formGroup]="form">
    <mat-form-field class="full-width">
      <mat-label>{{ 'measurement' | translate }}</mat-label>
      <input cogDataId="measurement" matInput type="number" formControlName="measurement" [inheritDataId]="true" />

      <mat-error *ngIf="form.controls.measurement.errors?.required">
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>{{ 'unit' | translate }}</mat-label>

      <mat-select cogDataId="unit" formControlName="unit" [inheritDataId]="true">
        <mat-option
          value="{{ durationUnit }}"
          *ngFor="let durationUnit of durationUnits"
          cogDataId="option-{{ durationUnit }}"
          [inheritDataId]="true">
          {{ durationUnit | translate }}
        </mat-option>
      </mat-select>

      <mat-error *ngIf="form.controls.unit.errors?.required">
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <mat-error *ngIf="form.errors && !hasControlErrors" class="form-error">
    <span *ngIf="form.errors.min" [inheritDataId]="true" cogDataId="minimum-error">
      {{ 'errors.min' | translate: { value: minValue.measurement + ' ' + minValue.unit | translate } }}
    </span>

    <span *ngIf="form.errors.max" [inheritDataId]="true" cogDataId="maximum-error">
      {{ 'errors.max' | translate: { value: maxValue.measurement + ' ' + maxValue.unit | translate } }}
    </span>
  </mat-error>
</div>
