import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit } from 'lodash-es';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { convertToHttpParams, getPassThroughHeaders } from '../api.utils';
import {
  EntityProto,
  ListEntitiesForResourcePoolParams,
  ListResourcePoolsParams,
  PassthroughOptions,
  ResourcePoolResult,
} from '../private-models';

/**
 * Private params for registering an source. This is only a partial interface
 * definition. If called from source-service with more params, it will still work.
 */
export interface SourceRegistrationParams {
  entity: {
    physicalEntity: {
      hostType: number;
      name: string;
      type: number;
    };
    type: number;
  };
  entityInfo: {
    endPoint: string;
    hostType: number;
    type: number;
  };
  connectionId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class BackupSourcesServiceApi {
  constructor(private http: HttpClient) {}

  /**
   * Uses the private API to register a source. This is being used for active directory,
   * which doesn't support the public API yet.
   *
   * @param   data   The source registration params.
   * @param   headersParams Header parameters
   * @returns An observable stream of the result.
   */
  public registerSource(data: SourceRegistrationParams, headersParams?: PassthroughOptions): Observable<any> {
    let headers = new HttpHeaders();
    if (headersParams?.accessClusterId) {
      headers = headers.append('accessClusterId', String(headersParams?.accessClusterId));
    }
    if (headersParams?.regionId) {
      headers = headers.append('regionId', String(headersParams?.regionId));
    }
    return this.http.post<any>(Api.private('backupsources'), data, { headers });
  }

  /**
   * Uses the private API to get backup sources data.
   *
   * @param    data  The entity id params.
   * @returns  An observable stream of source data.
   */
  public getSource(data: any): Observable<any> {
    return this.http.get(Api.private('backupsources'), { params: data });
  }

  /**
   * List resource pools for a vCenter.
   *
   * @param params The params to pass to the API.
   * @returns An observable of the resource pools.
   */
  GetResourcePools(params: ListResourcePoolsParams): Observable<ResourcePoolResult[]> {
    return this.http.get<ResourcePoolResult[]>(Api.private('resourcePools'), {
      params: convertToHttpParams(omit(params, ['accessClusterId', 'regionId'])),
      headers: getPassThroughHeaders(params),
    });
  }

  /**
   * List data stores for a resource pool.
   *
   * @param params The params to pass to the API.
   * @returns An observable of the datastores.
   */
  GetDatastores(params: ListEntitiesForResourcePoolParams): Observable<EntityProto[]> {
    return this.http.get<EntityProto[]>(Api.private('datastores'), {
      params: convertToHttpParams(omit(params, ['accessClusterId', 'regionId'])),
      headers: getPassThroughHeaders(params),
    });
  }

  /**
   * List network entities for a resource pool.
   *
   * @param params The params to pass to the API.
   * @returns An observable of the network entities.
   */
  GetNetworkEntities(params: ListEntitiesForResourcePoolParams): Observable<EntityProto[]> {
    return this.http.get<EntityProto[]>(Api.private('networkEntities'), {
      params: convertToHttpParams(omit(params, ['accessClusterId', 'regionId'])),
      headers: getPassThroughHeaders(params),
    });
  }
}
