import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IndexingPolicy } from '@cohesity/api/v2';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { filter, startWith } from 'rxjs/operators';
import { ProtectionUtilsService } from 'src/app/modules/protection/protection-shared/services';
import {
  Environment,
  indexableDmsEnvironments,
  indexableEnvironmentsExposedAsServers,
  indexableEnvironmentsExposedAsViews,
} from 'src/app/shared';

import { Indexing, IndexingSettingsListProperties, ProtectionItemName } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Default value for indexing.
 */
export const DefaultIndexing: Indexing = {
  enabled: false,
  paths: {
    include: [],
    exclude: [],
  },
};

/**
 * Default value for indexing of servers.
 */
export const DefaultIndexingServers: Indexing = {
  enabled: true,
  paths: {
    include: ['/'],
    exclude: [
      '/$Recycle.Bin',
      '/Windows',
      '/Program Files',
      '/Program Files (x86)',
      '/ProgramData',
      '/System Volume Information',
      '/Users/*/AppData',
      '/Recovery',
      '/var',
      '/usr',
      '/sys',
      '/proc',
      '/lib',
      '/grub',
      '/grub2',
      '/opt',
      '/splunk',
    ],
  },
};

/**
 * Default value for indexing of views.
 */
export const DefaultIndexingViews: Indexing = {
  enabled: true,
  paths: {
    include: ['/'],
    exclude: [],
  },
};

@Component({
  selector: 'coh-settings-list-indexing',
  templateUrl: './settings-list-indexing.component.html',
  styleUrls: ['./settings-list-indexing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListIndexingComponent
  extends BaseProtectionBuilderComponent<Indexing, IndexingSettingsListProperties>
  implements OnInit {
  /**
   * Default value for indexing.
   */
  _value: Indexing = DefaultIndexing;

  /**
   * Determines if Indexing setting is enabled by default when protection group
   * is created. Indexing hint is applicable only if the setting is turned on
   * by default.
   */
  isIndexingEnabledByDefault = false;

  /**
   * Determines if the protection is snapMirror based.
   */
  isSnapMirrorBackup = false;

  /**
   * Environment of source
   */
  environment: Environment;

  /**
   * Boolean flag to check if policy is a CAD policy or not.
   */
  isCadPolicy = false;

  /**
   * Indexing form group to be attached to the settings list form group.
   */
  indexingFormGroup = new UntypedFormGroup({
    enabled: new UntypedFormControl(this.value.enabled, Validators.required),
    paths: new UntypedFormControl(this.value.paths),
  });

  constructor(private protectionUtilsService: ProtectionUtilsService, private irisContext: IrisContextService) {
    super();
  }

  /**
   * Component Init.
   */
  ngOnInit() {
    const isEdit = this.protectionGroup && this.protectionGroup.id;

    // TODO(tauseef): Move away from using formGroup.parent.
    const sourceFormControl = this.formGroup.parent?.get(ProtectionItemName.Source);

    // This formControl is only available for Views as it doesn't use the
    // source selector.
    const viewFormControl = this.formGroup.parent?.get(ProtectionItemName.View);
    // Selected policy form control.
    const policyControl = this.formGroup.parent?.get(ProtectionItemName.Policy);

    // If selected policy is a CAD policy or not.
    this.isCadPolicy = policyControl?.value?.backupPolicy?.regular?.primaryBackupTarget?.targetType === 'Archival';
    this.indexingFormGroup.get('enabled').valueChanges.pipe(
      this.untilDestroy(),
      startWith(this.value.enabled)
    ).subscribe(value => {
      if (value) {
        this.indexingFormGroup.addControl('paths', new UntypedFormControl(this.value.paths));
      } else {
        this.indexingFormGroup.removeControl('paths');
      }
    });

    if (isEdit) {
      return;
    }

    // Handle indexable entities exposed as servers.
    if (sourceFormControl) {
      sourceFormControl.valueChanges.pipe(
        this.untilDestroy(),
        startWith(sourceFormControl.value),
        filter(value => !!value),
      ).subscribe(value => {
        this.environment = value.protectionSource.environment;
        this.setDefaultIndexingPolicy();
      });
    } else if (viewFormControl) {
      // Handle indexable entities exposed as views.
      this.environment = Environment.kView;
      this.setDefaultIndexingPolicy();
    }

    // Hide the include/exclude paths control if not needed
    if (this.properties.hideIncludeExcludePaths) {
      this.indexingFormGroup.removeControl('paths');
    }

    /**
     * If the component is used as a standalone component then watch for changes
     * in the form and set the value to the original formControl.
     *
     * NOTE - The inner form value (indexingFormGroup) is of type 'Indexing' and
     *        the outer formControl is of type 'IndexingPolicy'.
     */
    if (this.properties?.standalone) {
      this.indexingFormGroup.valueChanges
        .pipe(this.untilDestroy())
        .subscribe(formValue => {
          this.formControl.setValue(this.protectionUtilsService.transformIndexingPolicy(formValue));
        });
    }
  }

  /**
   * Set the default indexing policy depending on the environment type of the
   * group.
   *
   * For environments which are exposed as volumes/servers or views, we want
   * indexing to be turned on by default when creating a new protection group.
   *
   */
  setDefaultIndexingPolicy() {
    if (isDmsScope(this.irisContext.irisContext) && !indexableDmsEnvironments.includes(this.environment) ||
     this.environment === Environment.kVMware && this.isCadPolicy) {
      this.value = DefaultIndexing;
    } else if (indexableEnvironmentsExposedAsServers.includes(this.environment)) {
      this.value = DefaultIndexingServers;
    } else if (indexableEnvironmentsExposedAsViews.includes(this.environment)) {
      this.value = DefaultIndexingViews;
    }

    if (this.value) {
      this.isIndexingEnabledByDefault = this.value.enabled;
      this.indexingFormGroup.reset(this.value);
    }
  }

  /**
   * Add the indexing form group as control.
   */
  addFormControl() {
    if (this.properties?.standalone) {
      this.setDefaultValues(this.formControl.value);
    } else {
      this.formGroup.addControl(this.name, this.indexingFormGroup);
    }
  }

  /**
   * Get count of excluded or included paths.
   *
   * @param   type   One of 'exclude' or 'include'.
   * @return   Length of controls array.
   */
  getPathCount(type): number {
    const pathsValue = this.indexingFormGroup.get('paths')?.value;
    return pathsValue ? (pathsValue[type] || []).length : 0;
  }

  /**
   * get indexing hint depending on type of source
   */
  getIndexingHint(): string {
    if (this.environment === Environment.kVMware && this.isCadPolicy) {
      return 'protectionGroups.vmwareCadPolicyIndexingHint';
    } else {
      return this.environment === Environment.kKubernetes ?
        'protectionGroups.kubernetesIndexingHint' : 'protectionGroups.indexingHint';
    }
  }

  /**
   * Form control init.
   */
  initFormControl() {
    const snapMirrorFormControl = this.formGroup.parent.get(
      ProtectionItemName.SnapMirrorBackupComponent);

    if (snapMirrorFormControl) {
      this.isSnapMirrorBackup = !!snapMirrorFormControl.get('isSnapMirrorBackup')?.value;
      snapMirrorFormControl.valueChanges.pipe(
        this.untilDestroy()
      ).subscribe(value => this.isSnapMirrorBackup = !!value.isSnapMirrorBackup);
    }

    if (this.protectionGroup && this.protectionGroup.indexing) {
      const { enabled, paths } = this.protectionGroup.indexing;
      this.environment = this.protectionGroup.environment as Environment;
      this.value = { enabled, paths };
      this.isIndexingEnabledByDefault = this.value.enabled;

      // Set configuration in form group.
      this.formControl.setValue(this.value);
    }
  }

  /**
   * Set the default value for the form. If standalone mode is true.
   *
   * NOTE - This is the case where the default value is of type IndexingPolicy.
   *
   * @param indexing The indexing policy.
   */
  setDefaultValues(indexing: IndexingPolicy) {
    this.indexingFormGroup.setValue(this.protectionUtilsService.untransformIndexingPolicy(indexing));
  }
}
