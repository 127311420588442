import { Component } from '@angular/core';
import { AggregatedSubtreeInfo, Office365ProtectionSource, ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Office365SourceDetailsService } from 'src/app/modules/sources/office365/services/office365-source-details.service';

import { OFFICE365_GROUPS, Office365BackupType, Office365ProtectionGroupEnvMap } from '../../constants';
import { PassthroughOptionsService } from 'src/app/core/services';

@Component({
  selector: 'coh-office365-popover',
  templateUrl: './office365-popover.component.html',
  styleUrls: ['./office365-popover.component.scss']
})
export class Office365PopoverComponent extends AutoDestroyable {
  /**
   * Specifies the workload type for the given node's popover component.
   */
  workload: Office365BackupType;

  /**
   * Cached protection group environment map.
   */
  readonly protectionGroupEnvMap = Office365ProtectionGroupEnvMap;

  /**
   * Specifies the protectionSourceNode for which metadata is required.
   */
  sourceNode: ProtectionSourceNode;

  /**
   * Specifies whether protectionSources API is in progress for
   * fetching metadata on demand when pruneNonCriticalInfo is true.
   */
  isMetaDataLoading = false;

  /**
   * Fetches the protected summary.
   */
  get protectedSummary(): AggregatedSubtreeInfo {
    return (this.node.protectedSourcesSummary || []).find(sum =>
      sum.environment === Office365ProtectionGroupEnvMap[this.workload]);
  }

  /**
   * Fetches the unprotected summary.
   */
  get unprotectedSummary(): AggregatedSubtreeInfo {
    return (this.node.unprotectedSourcesSummary || []).find(sum =>
      sum.environment === Office365ProtectionGroupEnvMap[this.workload]);
  }

  /**
   * Gets the logical size of all leaf children by summing the protected and unprotected
   * logical size, except for kMailbox and kOneDrive, which it gets directly.
   */
  get logicalSize(): number {
    return (this.protectedSummary?.totalLogicalSize || 0) + (this.unprotectedSummary?.totalLogicalSize || 0);
  }

  /**
   * Specifies the current node property.
   */
  get node(): ProtectionSourceNode {
    return this.sourceNode;
  }

  /**
   * Specifies the Office365 source node.
   */
  get office365Source(): Office365ProtectionSource {
    return this.node.protectionSource?.office365ProtectionSource;
  }

  /**
   * Specifies the SMPT address of the User/Site/Team/Group.
   */
  get primarySMTPAddress(): string {
    return this.office365Source?.primarySMTPAddress;
  }

  /**
   * Specifies the city of the User(kUser).
   */
  get city(): string {
    return this.office365Source?.userInfo?.city;
  }

  /**
   * Specifies the country of the User(kUser).
   */
  get country(): string {
    return this.office365Source?.userInfo?.country;
  }

  /**
   * Specifies the department of the User(kUser).
   */
  get department(): string {
    return this.office365Source?.userInfo?.department;
  }

  /**
   * Specifies the designation of the User(kUser).
   */
  get designation(): string {
    return this.office365Source?.userInfo?.designation;
  }

  /**
   * Specifies the Site URL for a Sharepoint Site.
   */
  get siteWebUrl(): string {
    return this.office365Source?.webUrl;
  }

  /**
   * Returns the count of member entities within the Group entity.
   * Currently this is applicable only for Security Groups.
   */
  get groupMemberCount(): number {
    return this.office365Source?.groupInfo?.memberCount;
  }

  /**
   * Specifies whether the node is protected or not.
   */
  isNodeProtected = false;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private office365SourceDetailsService: Office365SourceDetailsService,
    private passthroughOptionsService: PassthroughOptionsService,
    private popoverRef: PopoverRef<ProtectionSourceNode>,
    readonly irisContext: IrisContextService,
    readonly sourceService: ProtectionSourcesServiceApi) {
    super();
    this.sourceNode = this.popoverRef?.data;

    /**
     * Metadata is pruned when pruneNonCriticalInfo is true.
     * Metadata consists of primary SMTP address, size, city,
     * country, department, designation.
     * A separate protectionSouces API call is required
     * to fetch metadata on demand.
     */
    if (flagEnabled(this.irisContext.irisContext, 'pruneNonCriticalInfo')) {
      this.isMetaDataLoading = true;
      this.sourceService
        .ListProtectionSources({
          id: this.popoverRef?.data?.protectionSource?.id,
          ...this.passthroughOptionsService.requestParams,
        }).pipe(
          catchError((err) => {
            this.ajaxHandlerService.handler(err);
            return of(null);
          }),
          finalize(() => {
            this.isMetaDataLoading = false;
          })
        ).subscribe(node => {
          if (!node?.length || !node?.[0]) {
            return;
          }
          this.sourceNode = node?.[0];
        });
    }

    this.office365SourceDetailsService.workload$.pipe(
      this.untilDestroy()
    ).subscribe(workload => {
      this.workload = workload;
      const protectionSummary = this.node.protectedSourcesSummary;

      if (!protectionSummary?.length) {
        return;
      }

      const envSummary = this.workload ?
        protectionSummary.find(summary => summary.environment === this.protectionGroupEnvMap[workload]) :
        protectionSummary[0];

      this.isNodeProtected = !!(envSummary?.leavesCount > 0);
    });
  }

  /**
   * Returns whether Office365 node has popover or not.
   *
   * @returns   boolean   whether node has popover or not
   */
  static hasPopover(office365Node: ProtectionSourceNode): boolean {
    return OFFICE365_GROUPS.office365Leaves.includes(
      office365Node.protectionSource?.office365ProtectionSource?.type);
  }

}
