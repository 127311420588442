import { assign } from 'lodash-es';
// Service: MCM(aka Helios) source services formatters.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.mcmSourceServiceFormatter', ['C'])
    .service('McmSourceServiceFormatter', McmSourceServiceFormatterFn);

  function McmSourceServiceFormatterFn(_, ENUM_ENV_TYPE, HeliosService) {

    return {
      decorateSource: decorateSource,
    };

    /**
     * Decorate source node info with helper properties for quick lookups.
     *
     * @method   decorateSource
     * @param    {Object}   node   The source node info from backend
     *   mcmData.RegisteredSource proto from
     *   iris/go/server/data/mcm/protection_sources_data.go file.
     * @return   {Object}   returns transformed source node info.
     */
    function decorateSource(node) {
      node = node || {};

      assign(node, {
        // creating UI friendly protectionSource info.
        protectionSource: {
          id: -1,
          name: node.name,
          environment: node.environment,
        },
        registeredSourceInfoList:
          node.registeredSourceInfoList.map(decorateRegisteredSourceInfo),
      });

      assign(node, {
        _typeStr: ENUM_ENV_TYPE[node.protectionSource.environment],
      });

      return node;
    }

    /**
     * Decorate source registration info with helper properties for quick
     * lookups.
     *
     * @method   decorateRegisteredSourceInfo
     * @param    {Object}   sourceInfo   The registered source info.
     *   mcmData.RegisteredSourceInfo proto from
     *   iris/go/server/data/mcm/protection_sources_data.go file.
     * @return   {Object}   returns transformed registered source info.
     */
    function decorateRegisteredSourceInfo(sourceInfo) {
      return assign(sourceInfo,
        {
          // generating globally unique uuid for registered source which
          // will be like <clusterId:clusterIncarnationId:sourceId>
          _uuid: HeliosService.generateUuid(sourceInfo, sourceInfo.sourceId),
        }
      );
    }

  }

}(angular));
