import { each } from 'lodash-es';
import { isObject } from 'lodash-es';
import { find } from 'lodash-es';
import { get } from 'lodash-es';
// Service: ExternalTargetServiceFormatter utility service.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.externalTargetsServiceFormatter', [])
    .service(
      'ExternalTargetsServiceFormatter',
      ExternalTargetsServiceFormatterFn
    );

  function ExternalTargetsServiceFormatterFn(_, EXTERNAL_TARGET_USAGE_TYPES,
    ARCHIVE_EXTERNAL_TARGETS, FEATURE_FLAGS) {

    // This Service's API
    return {
      addFeatureControlledTargets: addFeatureControlledTargets,
      transformVault: transformVault,
      transformBandwidthLimitData: transformBandwidthLimitData,
      untransformBandwidthLimitData: untransformBandwidthLimitData,
    };

    /**
     * Reusable vault transformer.
     *
     * @method   transformVault
     * @param    {object}             resp   The Server's raw response.
     *
     * @return   {object|undefined}   The transformed vault, or undefined if not
     *                                a valid vault object.
     */
    function transformVault(resp) {
      var vault = resp.data || resp || {};

      var isGlacierOrTape = ['kGlacier', 'kQStarTape'].includes(vault.type);

      if (vault && vault.vaultBandwidthLimits) {
        vault.vaultBandwidthLimits =
          transformBandwidthLimitData(vault.vaultBandwidthLimits);
      }

      _createVaultTierData(vault);

      return angular.extend({
        // Sets a default 'usageType' if the target doesn't have one defined.
        // This covers ExternalTargets created prior to 2.7
        usageType: EXTERNAL_TARGET_USAGE_TYPES.kArchival,

        // Set default values for dedup and incremental archival. This covers
        // ExternalTargets created prior to 3.7
        dedupEnabled: !isGlacierOrTape,
        incrementalArchivesEnabled: !isGlacierOrTape,
      }, vault);
    }

    /**
     * process bandwidth data after recieving from server for use with
     * c-throttle-override component
     *
     * @method   transformBandwidthLimitData
     * @param    {Object}    data         data receieved from server
     *
     * @return   {Object}    the transformed data
     */
    function transformBandwidthLimitData(data) {
      if (!data || !Object.keys(data).length) {
        return;
      }

      var retData = {
        bandwidthLimitOverrides: [],
        timezone: null,
      };

      // if data has upload info
      if (data.upload) {
        retData.timezone = data.upload.timezone;
        if (data.upload.bandwidthLimitOverrides) {
          data.upload.bandwidthLimitOverrides.forEach(function eachData(obj) {
            obj.traffic = 'upload';
            retData.bandwidthLimitOverrides.push(obj);
          });
        }
      }

      // if data has download info
      if (data.download) {
        retData.timezone = data.download.timezone;
        if (data.download.bandwidthLimitOverrides) {
          data.download.bandwidthLimitOverrides.forEach(function eachData(obj) {
            obj.traffic = 'download';
            retData.bandwidthLimitOverrides.push(obj);
          });
        }
      }

      return retData;
    }

    /**
     * process bandwidth data before sending to server
     *
     * @method   untransformBandwidthLimitData
     * @param    {Object}    data   data to send to server
     * @return   {Object}    the processed data
     */
    function untransformBandwidthLimitData(data) {
      var upload;
      var download;

      if (!data) {
        return;
      }

      if (data.bandwidthLimitOverrides &&
          data.bandwidthLimitOverrides.length) {

        data.bandwidthLimitOverrides.forEach(function eachData(obj) {
          if (obj.traffic === 'upload') {
            upload = upload || {
              bandwidthLimitOverrides: [],
              timezone: data.timezone,
            };
            upload.bandwidthLimitOverrides.push(obj);
          } else {
            download = download || {
              bandwidthLimitOverrides: [],
              timezone: data.timezone,
            };
            download.bandwidthLimitOverrides.push(obj);
          }
        });
      }

      return {
        upload: upload || undefined,
        download: download || undefined,
      };
    }

    /**
     * Creates a uniform _tierData for all kinds of vaults (old and new,
     * tiered/non-tiered vaults) for easy use.
     *
     * @method   _createVaultTierData
     */
    function _createVaultTierData(vault) {
      var targetTypes = ARCHIVE_EXTERNAL_TARGETS;
      var tierType;

      // Append any feature flag protected targets
      targetTypes = addFeatureControlledTargets(targetTypes);

      vault._tierData = {};

      each(targetTypes, function eachTarget(target, key) {
        var isOtherType = key === 'others';

        if (!target.types.length) {
          return;
        }

        each(target.types, function eachType(type) {
          var isOldTargetGovType;
          var tier;

          // backward compatibility
          if (vault.type) {

            if ([type.oldKey, type.oldGovKey].includes(vault.type)) {

              vault._isOldTarget = true;

              if (vault.type === type.oldGovKey) {
                vault._title = 'gov';
                isOldTargetGovType = true;
              }

              vault._tierData = {
                tier: type.tier,
                vault: isOldTargetGovType ? target.vaults.gov :
                  (get(target, 'vaults.standard') || type.vault),
                targetType:
                  target.vaults ? target.vaults.standard : type.vault,
              };

              vault._name = type.name;
              vault._vaultName = isOtherType ? '' : target.name;

              if (isOldTargetGovType) {
                vault._typeKey = 'externalTarget.govCloud';
              }

              return false;
            }

          // new target types
          } else {

            // if vault is a tiered type, normalize it.

            find(vault.config, function(value, key) {
              if (isObject(value)) {
                return find(value, function findTierType(v, k) {
                  if (k === 'tierType') {
                    tierType =  v;
                  }
                });
              }
            });

            if (tierType) {
              tier = tierType;

              if (type.tier === tier) {
                switch (true) {
                  case get(target, 'vaults.gov') === vault.externalTargetType:
                    vault._typeKey = 'externalTarget.govCloud';
                    vault._title = 'gov';
                    break;
                  case get(target, 'vaults.c2s') === vault.externalTargetType:
                    vault._typeKey = 'c2s';
                    vault._title = 'c2s';
                    break;
                  default:
                    vault._title = 'standard';
                    break;
                }

                vault._name = type.name;
                vault._vaultName = target.name;
                vault._tierData.tier = tier;
                vault._tierData.vault = vault.type;
                vault._tierData.targetType =
                  target.vaults ? target.vaults.standard : type.vault;

                return false;
              }

            // 'other' vaults
            } else if (type.vault === vault.externalTargetType) {
              vault._name = type.name;
              vault._vaultName = '';
              vault._tierData.vault = type.vault;
              vault._tierData.targetType = type.vault;

              return false;
            }
          }
        });
      });

      if (vault._tierData.tier === 'kAmazonGlacier') {
        vault._tierData.targetType = 'kAmazonGlacier';
      }
    }

    /**
     * Add any feature flag protected targets to the provided list
     *
     * @method  addFeatureControlledTargets
     * @param   {Object}  targetList The list to which targets are to be added
     * @return  {Object}  The formatted list
     */
    function addFeatureControlledTargets(targetList) {
      targetList = angular.copy(targetList);

      if (FEATURE_FLAGS.awsIAAndIntelligentVault) {
        targetList.aws.types.push({
          name: 'S3-One-Zone',
          tier: 'kAmazonS3OneZoneIA',
          hasGov: true,
          cloudSpillSupported: false,
        }, {
          name: 'S3-Intelligent',
          tier: 'kAmazonS3IntelligentTiering',
          hasGov: true,
          cloudSpillSupported: true,
        });
      }

      if (FEATURE_FLAGS.awsDeepArchive) {
        targetList.aws.types.push({
          name: 'S3-Glacier-Deep-Archive',
          tier: 'kAmazonS3GlacierDeepArchive',
          hasGov: true,
        });
      }

      if (FEATURE_FLAGS.awsS3Glacier) {
        targetList.aws.types.push({
          name: 'S3-Glacier-FR',
          tier: 'kAmazonS3Glacier',
          hasGov: true,
        });
      }

      if (FEATURE_FLAGS.awsS3Sts) {
        targetList.aws.types.push({
          name: 'S3-STS',
          tier: 'kAmazonS3Standard',
          hasC2S: true,
          hasGov: true,
          oldKey: 'kS3',
          oldGovKey: 'kAWSGovCloud',
          cloudSpillSupported: true
        });
      }

      return targetList;
    }
  }

})(angular);
