import { some } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Kuiper Recovery Options

;(function(angular) {
  'use strict';

  angular
    .module('C.restore')
    .controller('KuiperRecoveryOptionsCtrl', KuiperRecoveryOptionsCtrlFn)
    .component('kuiperRecoveryOptions', {
      bindings:{
        /**
         * Provides the value of snapshot index to get the jobInstanceId.
         *
         * @type  {number}  [snapshotIndex=undefined]
         */
        snapshotIndex: '<',

        /**
         * Provides the recovery params for the specific job.
         *
         * @type   {object}  [recoveryParams=undefined]
         */
        recoveryParams: '<',

         /**
         * Provides the list of all the snapshots for the specific job.
         *
         * @type   {array}  [snapshots=undefined]
         */
        snapshots: '<',
      },
      require: {
        /**
         * Provides the value of properties needed for spnning the VM
         *
         * @type  {Object}  [ngModelCtrl=undefined]
         */
        ngModelCtrl: 'ngModel',
      },
      templateUrl: 'app/protection/recovery/vm/kuiper-recovery-options.html',
      controller: 'KuiperRecoveryOptionsCtrl',
    });

  function KuiperRecoveryOptionsCtrlFn(_, FILESIZES, NetworkService, evalAJAX,
    HypervisorVmService, FORMATS) {

    var $ctrl = this;
    var currentSnapshotIndex = -1;

    // Declare component methods and variable.
    assign($ctrl, {
      // Component life cycle methods.
      $onInit: initializeDefaultValues,
      $onChanges: getAvailableComponents,

      // Component methods.
      checkDuplicateName: checkDuplicateName,
      getInterfaceList: getInterfaceList,
      toggleRenameVM: toggleRenameVM,

      // List of interfaces.
      interfaces: [],

      // List of VMs
      hypervisorVms: [],

      // UI States.
      showRenameVM: false,
      loadingInterfaces: false,
      FILESIZES: FILESIZES,
      FORMATS: FORMATS,
    });

    /**
     * Initializes the default parameters for recovery.
     *
     * @method   initializeDefaultValues
     */
    function initializeDefaultValues() {
      getInterfaceList();
      getAvailableComponents();

      HypervisorVmService.getHypervisorVMs({query: 'all'}).then(
        function getHypervisorVmSuccess(hypervisorVms) {
          $ctrl.hypervisorVms = hypervisorVms;
        }, evalAJAX.errorMessage);
    }

    /**
     * Removes VM rename params when hiding the options.
     *
     * @method     toggleRenameVM
     * @param      {boolean}  showRename  Show or hide the rename options
     */
    function toggleRenameVM(showRename) {
      if (!showRename) {
        $ctrl.ngModelCtrl.$modelValue.name = null;
      }
    }

    /**
     * Get the interfaces list
     *
     * @method    getInterfaceList
     */
    function getInterfaceList() {
      $ctrl.loadingInterfaces = true;
      NetworkService.getInterfaces(['bond'])
        .then(function gotInterfacesSuccess(interfaces) {
          $ctrl.interfaces = interfaces.filter(
          function removeKuiperTab(value, index, arr) {
            return value.staticIp !== "";
        });
        }, evalAJAX.errorMessage)
        .finally(function getInterfacesFinally() {
          $ctrl.loadingInterfaces = false;
        });
    }

    /**
     * Gets the available components for the selected vm
     *
     * @method    getAvailableComponents
     */
    function getAvailableComponents() {
      var jobInstanceId = -1;
      var attemptNum = -1;
      var params = {};

      if ($ctrl.snapshotIndex !== currentSnapshotIndex) {
        currentSnapshotIndex = $ctrl.snapshotIndex;

        jobInstanceId =
          $ctrl.snapshots[$ctrl.snapshotIndex].instanceId.jobInstanceId;

        attemptNum =
          $ctrl.snapshots[$ctrl.snapshotIndex].instanceId.attemptNum;

        params = Object.assign($ctrl.recoveryParams, {
          attemptNum: attemptNum,
          jobInstanceId: jobInstanceId
        });
        HypervisorVmService.getAvailableVMComponents(params).then(
          function getComponentsSuccess(response) {
            assign($ctrl.ngModelCtrl.$modelValue,
              HypervisorVmService.getCurrentTask($ctrl.ngModelCtrl.$modelValue,
                response));
        }, evalAJAX.errorMessage);
      }
    }

    /**
     * Checks if a VM exists with the same name
     *
     * @method   checkDuplicateName
     * @param    {string}    vmName     name of the VM
     * @return   {bool}      return true if vm with the same name already exists
     *                       otherwise false.
     */
    function checkDuplicateName(vmName) {
      return !some($ctrl.hypervisorVms, ['name', vmName]);
    }
  }
})(angular);