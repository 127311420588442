import { get } from 'lodash-es';
// Service: AdServiceFormatter utility service.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('AdServiceFormatter', AdServiceFormatterFn);

  function AdServiceFormatterFn(WELL_KNOWN_PRINCIPALS, AD_ENCRYPTION_TYPES) {

    // Reduce the list of well known principals to just a list of SIDs.
    var wellKnownSids = WELL_KNOWN_PRINCIPALS.map(
      function reducePrincipalsToSids(principal) {
        if (!principal.builtIn) {
          return principal.sid;
        }
      }
    );

    // This Service's API
    return {
      sanitizeWellKnownSids: sanitizeWellKnownSids,
      transformDomain: transformDomain,
      transformDomainControllers: transformDomainControllers,
      transformPrincipal: transformPrincipal,
      untransformDomain: untransformDomain,
      untransformIgnoredTrustedDomains: untransformIgnoredTrustedDomains,
    };

    /**
     * Transforms a domain by defaulting values and adding convenience
     * properties and returns the domain.
     *
     * @method     transformDomain
     *
     * @param      {Object}  domain  The domain
     * @return     {Object}  The same domain object with added and transformed
     *                       data
     */
    function transformDomain(domain) {
      var centrifyInfo;
      var distinguishedName;

      if (!domain) {
        return;
      }

      /**
       * If the AD config includes a Centrify Zone, then derive `zoneName` and
       * `zoneDomain` given a `distinguishedName` string as follows:
       * "CN=zone-rfc2703,CN=Zones,OU=Centrify,OU=Centrify,DC=dev04,DC=eng,DC=cohesity,DC=com"
       */
      if (domain.userIdMappingInfo &&
        domain.userIdMappingInfo.type === 'kCentrify') {
        centrifyInfo = domain.userIdMappingInfo.centrifyZoneMapping;
        distinguishedName = centrifyInfo.distinguishedName;

        // Derive `zoneName` which is the first 'CN' part.
        centrifyInfo.zoneName =
          distinguishedName.substring(3, distinguishedName.indexOf(','));

        // Derive `zoneDomain` which is a concatenation of all of the 'DC'
        // parts. We have to standardize case ourself because case is not
        // standard amongst systems.
        distinguishedName = distinguishedName.toLowerCase();
        centrifyInfo.zoneDomain =
          distinguishedName.slice(distinguishedName.indexOf('dc') + 3)
            .split(',dc=').join('.');
      }

      domain.preferredDomainControllers =
        domain.preferredDomainControllers || [];

      domain.trustedDomains = domain.trustedDomains || [];
      domain.ignoredTrustedDomains = domain.ignoredTrustedDomains || [];

      domain._encryptionNames = _decodeEncryptionType(domain.encryption);

      return domain;
    }

    /**
     * Transforms a list of domain controllers.
     *
     * @method     transformDomainControllers
     *
     * @param    {Array}   controllers  The domain controllers
     * @return   {Array}   The transformed list of controllers
     */
    function transformDomainControllers(controllers) {
      controllers = controllers || [];

      // Domain Controllers are user-entered values, so we must change all to
      // lowercase for proper matching.
      return controllers.map(function(controller) {
        return controller.toLowerCase();
      });
    }

    /**
     * Transforms a principal by defaulting values and adding convenience
     * properties and returns the principal.
     *
     * @method     transformPrincipal
     *
     * @param      {Object}  principal  The principal object.
     * @return     {Object}  The same principal object with added and
     *                       transformed data.
     */
    function transformPrincipal(principal) {
      principal.fullName = principal.fullName || principal.principalName;

      // Clear the domain property if the principal is a Well-Known SID.
      principal = sanitizeWellKnownSids(principal);

      // Replace angle brackets with underscore because ui-select cannot display
      // angle brackets. This is consistent with Windows treatment of angle
      // brackets as well.
      principal.fullName = principal.fullName.replace(/[<>]/g, '_');

      return principal;
    }

    /**
     * Transforms a domain object for posting to API.
     *
     * @method     untransformDomain
     *
     * @param      {Object}  domain  The domain
     * @return     {Object}  The same domain object with transformed data
     */
    function untransformDomain(domain) {
      var idMappingInfo;
      var fallbackInfo;

      if (!domain) {
        return;
      }

      idMappingInfo = domain.userIdMappingInfo;
      fallbackInfo = domain.fallbackUserIdMappingInfo;

      // Coerce the Fixed ID strings to numbers
      if (get(idMappingInfo, 'fixedMapping')) {
        idMappingInfo.fixedMapping.gid = +idMappingInfo.fixedMapping.gid;
        idMappingInfo.fixedMapping.uid = +idMappingInfo.fixedMapping.uid;
      }
      if (get(fallbackInfo, 'fixedMapping')) {
        fallbackInfo.fixedMapping.gid = +fallbackInfo.fixedMapping.gid;
        fallbackInfo.fixedMapping.uid = +fallbackInfo.fixedMapping.uid;
      }

      return domain;
    }

    /**
     * Transforms an AD object for posting to Ignored Trusted Domains API.
     *
     * @method     untransformIgnoredTrustedDomains
     *
     * @param      {Object}  adConfig  The AD config object
     * @return     {Object}  request obj for ignored trusted domains api
     */
    function untransformIgnoredTrustedDomains(adConfig) {
      return {
        ignoredTrustedDomains: adConfig.ignoredTrustedDomains,
      };
    }

    /**
     * Sanitize Well-Known SIDs by stripping the domain property. We need to do
     * this because they are not domain specific.
     *
     * @method     sanitizeWellKnownSids
     * @param      {object}  principal  The principal
     * @return     {object}  santized principal
     */
    function sanitizeWellKnownSids(principal) {
      if (wellKnownSids.includes(principal.sid)) {
        principal.domain = undefined;
      }
      return principal;
    }

    /**
     * Decodes the decimal value of encryption types and returns a list of
     * user-facing names of encryption types.
     *
     * The encryption value is the sum of selected doubles in a sequence of
     * 1, 2, 4, 8, 16. Each double is the ID of one encryption type. If the
     * encryption value is 5, then we know the selected encryption types are 1
     * and 4.
     *
     * @method   _decodeEncryptionType
     * @param    {Number}   encryption   Decimal encoded value of encryption
     *                                   types.
     * @returns  {Array}    List of names of encryption types.
     */
    function _decodeEncryptionType(encryption) {
      var list = [];
      var remainder = encryption;

      if (!encryption) {
        return list;
      }

      // Process the list of encryption types in reverse order because we have
      // to start with the largest value and work backwards.
      AD_ENCRYPTION_TYPES.forEachRight(
        function forEachEncryptionType(type) {
          if (remainder >= type.id) {
            remainder = remainder - type.id;
            list.push(type.name);
          }
        }
      );

      return list;
    }

  }

})(angular);
