/**
 * Production hostnames for Helios.
 */
// TODO: (Nidhi Kulkarni): Populate the right host name for IBM deployment
export const heliosProductionHostnames = [
  // Helios SaaS Production Environment
  'helios.cohesity.com',

  // Helios FedRAMP Production Environment
  'helios.gov-cohesity.com',
];

/**
 * Interface for environment model. The environment object created using
 * this is used to determine app's environment, and specific app functionality
 * changes based on that.
 */
export interface AppEnvironment {
   /**
    * Whether the build is in production mode. This is set to true when the app
    * is not running from the webpack development server.
    */
  production: boolean;

   /**
    * Whether the app is a cluster UI which is opened within an ifram in Helios.
    */
  heliosInFrame: boolean;

  /**
   * Whether the build is in IBM Aqua mode.
   */
  ibmAqua?: boolean;

   /**
    * Whether the app is currently running on production Helios (helios.cohesity.com).
    */
  heliosProduction: boolean;
}
