import { find } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Add Email Address for Cloud Edition Notifications

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('cloudDeploymentEmail', {
      require: {
        cModalHoc: '^^?cModalHoc',
      },
      controller: 'cloudDeploymentEmailCtrl',
      templateUrl:
        'app/platform/cloud-edition-manager/email-notification.html',
    })
    .controller('cloudDeploymentEmailCtrl',
      cloudDeploymentEmailCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:cloudDeploymentEmail
   * @function
   *
   * @description
   * Add a new Email Address for CE Deployment Notification
   */
  function cloudDeploymentEmailCtrlFn(_, FORMATS) {
    var $ctrl = this;

    assign($ctrl, {
      submitButtonText: 'add',
      confirmed: false,
      email: [],

      // methods
      cancel: cancel,
      processForm: processForm,
      validateEmailAddress: validateEmailAddress,
    });

    /**
     * Add new Email Address
     *
     * @method  processForm
     * @param   {object}  form   The form object to process
     */
    function processForm(form) {
      if (!form.$valid) {
        return false;
      }

      $ctrl.cModalHoc.save($ctrl.email);
    }

    /**
     * Validate the email addresses
     *
     * @method  validateEmailAddress
     * @param   {String[]}  selectedAddresses The current selection of email
     *                                        addresses
     * @return  {Boolean}   True if addresses are valid. False otherwise
     */
    function validateEmailAddress(selectedAddresses) {
      // set the flag if even one invalid network tag is found
      return(
        !selectedAddresses.length || !find(selectedAddresses,
          function findTag(address) {
            return !FORMATS.email.test(address);
          })
      );
    }

    /**
     * Cancel form submission and close the modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.cModalHoc.close();
    }
  }

})(angular);