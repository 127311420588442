import { isDate } from 'lodash-es';
import { isNumber } from 'lodash-es';
// Directive: cTimePicker

;(function(angular, undefined) {

  angular.module('C.timePicker', ['C.timeZoneConverter'])
    .directive('cTimePicker', cTimePickerFn);

  /**
   * @ngdoc directive
   * @name C.timePicker:cTimePicker
   * @description
   *  This directive is a wrapper over the uib-timepicker and adds validations
   *  along with extended control over the attributes.
   *
   * @restrict 'AE'
   * @requires ngModel
   *
   * @example
   * <c-time-picker
      id="restore-time-picker-{{version.instanceId}}"
      name="restoreTimePicker{{version.instanceId}}"
      ng-model="restoreTime.date"
      ng-change="changeFn"
      max-time="1234567890"
      time-zone="Asia/Calcutta"
      options="{
        isMeridian: false,
        showSeconds: true,
        mStep: 1,
      }"
      ng-disabled="!restoreTime.date">
    </c-time-picker>
   */

  function cTimePickerFn($timeout, cUtils, DateTimeService, _) {
    return {
      restrict: 'E',
      require: 'ngModel',
      scope: {
        // @type   {Object}   ngModel to bind the selected ng model
        ngModel: '=',

        // @type   {Number}   Minimum time in milliseconds
        minTime: '=?',

        // @type   {Number}   Maximum time in milliseconds
        maxTime: '=?',

        // @type   {Object}   Additonal options for time picker such as time
        //                    steps for hours, minutes or seconds. The default
        //                    values are:
        //                    hour step(hStep)   = 1
        //                    minute step(mStep) = 1
        //                    second step(sStep) = 1
        //                    show meridian(isMeridian) = true
        //                    show arrow keys(arrowKeys) = true
        //                    show seconds(showSeconds) = false
        options: '=?',

        // @type   {Boolean}  True if disabled.
        disabled: '=?',

        // @type   {Object}   Time zone details
        timeZone: '<?',
      },
      templateUrl: 'app/global/c-time-picker/c-time-picker.html',
      link: cTimePickerLinkFn,
    };

    function cTimePickerLinkFn(scope, iElement, iAttrs, ngModelCtrl) {

      /**
       * Controller initialization/activation function
       *
       * @method   activate
       */
      function activate() {
        // Default step for hours, minutes and seconds.
        scope.hStep = 1;
        scope.mStep = 1;
        scope.sStep = 1;

        // Default options for time picker.
        scope.isMeridian = true;
        scope.arrowKeys = true;
        scope.showSeconds = false;


        // will hold min/maxTime for internal converted Date object
        scope.minMaxState = {};

        // if options were provided, override defaults
        if (scope.options) {
          scope.hStep = isNumber(scope.options.hStep) ?
            scope.options.hStep : scope.hStep;
          scope.mStep = isNumber(scope.options.mStep) ?
            scope.options.mStep : scope.mStep;
          scope.sStep = isNumber(scope.options.sStep) ?
            scope.options.sStep : scope.sStep;
          scope.isMeridian = scope.options.isMeridian === false ?
            false : scope.isMeridian;
          scope.showSeconds = scope.options.showSeconds || scope.showSeconds;
          scope.arrowKeys = scope.options.arrowKeys === false ?
            false : scope.arrowKeys;
        }

        setupModelValidation();

        // update internal model on external model updates
        ngModelCtrl.$render = scope.syncModelValue;

        if (iAttrs.minTime || iAttrs.maxTime) {
          _setupMinMaxWatchers();
        }
      }

      /**
       * Create watchers for min/maxTime so that whenever they are updated, if a
       * non Date value is passed, it is converted into Date object
       *
       * @method   _setupMinMaxWatchers
       */
      function _setupMinMaxWatchers() {
        // If a {hour: x, minute: y} object is passed, convert it to date object
        if (iAttrs.minTime && !(scope.minTime instanceof Date)) {
          scope.$watch('minTime',
            function minTimeWatcherFn(newValue) {
              if (newValue) {
                scope.minMaxState.minTime =
                  DateTimeService.timeObjectToDate(newValue);
              }
            }, true);
        }

        // If a {hour: x, minute: y} object is passed, convert it to date object
        if (iAttrs.maxTime  && !(scope.maxTime instanceof Date)) {
          scope.$watch('maxTime',
            function maxTimeWatcherFn(newValue) {
              if (newValue) {
                scope.minMaxState.maxTime =
                  DateTimeService.timeObjectToDate(newValue);
              }
            }, true);
        }
      }

      /**
       * Sync internal & external model values.
       *
       * @method   syncModelValue
       * @param    {Object}   newValue   The new model value to set
       */
      scope.syncModelValue = function syncModelValue(newValue) {
        if (arguments.length) {
          // set external model with new model value or set undefined to keep
          // required validation working as expected.
          if (scope.timeZone) {
            ngModelCtrl.$setViewValue(newValue);
            return;
          }
          ngModelCtrl.$setViewValue(newValue ?
            DateTimeService.dateToTimeObject(newValue, true) : undefined);
          return;
        }

        if (ngModelCtrl.$isEmpty(ngModelCtrl.$modelValue)) {
          // initialize internal model
          scope.pickerDate = scope.timeZone ?
            DateTimeService.generateMomentWithTimezone(
              ngModelCtrl.$modelValue,
              scope.timeZone) :
            DateTimeService.timeObjectToDate(ngModelCtrl.$modelValue);

          // initialize external model in next digest cycle
          $timeout(function setExternalModel() {
            scope.syncModelValue(scope.pickerDate);
          });
        } else {
          scope.pickerDate = scope.timeZone ?
            DateTimeService.generateMomentWithTimezone(
              ngModelCtrl.$modelValue,
              scope.timeZone) :
            DateTimeService.timeObjectToDate(ngModelCtrl.$modelValue);
        }
      };

      /**
       * setup external model validations function
       *
       * @method   setupModelValidation
       */
      function setupModelValidation() {
        // add required validity checker
        ngModelCtrl.$validators.required =
          function validateRequiredVal(modelValue, viewValue) {
            var model = modelValue || viewValue;

            // if value is not required and undefined, consider it valid
            if (!iAttrs.required && angular.isUndefined(model)) {
              return true;
            }

            // Otherwise the object needs to meet certain criteria
            if (scope.timeZone) {
              return isDate(model);
            }
            return angular.isObject(model) &&
              cUtils.isInteger(model.hour) &&
              cUtils.isInteger(model.minute) &&
              model.hour >= 0 &&
              model.hour <= 23 &&
              model.minute >= 0 &&
              model.minute <= 59;
          };
      }

      activate();
    }
  }

})(angular);
