import { isObject } from 'lodash-es';
import { intersection } from 'lodash-es';
import { set } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// COMPONENT: Parent & shared functionality for Recover as Mount Point flow
import { recoveryGroup } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.recoverAsMountPoint';
  var moduleDeps = ['C.restoreCommon', 'C.jobRunsService'];

  angular
    .module(moduleName, moduleDeps)
    .config(recoverAsMountPointConfigFn)
    .controller('recoverAsMountPointController', recoverAsMountPointControllerFn);

  /**
   * Recover as Mount Point Config: States & Routing
   **************************************************************************/
  function recoverAsMountPointConfigFn($stateProvider) {
    var restoreModifyAccess = ctx => {
      return ctx.RESTORE_MODIFY &&
        ctx.FEATURE_FLAGS.instantVolumeMountEnabled &&
        ctx.canAccessSomeEnv(recoveryGroup.mountPoint) &&
        !ctx.isDmsScope;
    };

    $stateProvider
      .state('recover-mount-point', {
        url: '/protection/recovery/mount-point',
        title: 'Mount Volume',
        canAccess: restoreModifyAccess,
        parentState: 'recover',
        params: {
          type: undefined,
        },
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'recoverAsMountPointController',
          },
          'col-l@recover-mount-point': {
            templateUrl: 'app/protection/recovery/mount-point/mount-point.parent.html',
          },
        },
        redirectTo: 'recover-mount-point.search'
      })
      .state('recover-mount-point.search', {
        url: '^/protection/recovery/mount-point/search',
        help: 'protection_recovery_mountpoint_select',
        title: 'Mount Volume: Select Server',
        canAccess: restoreModifyAccess,
        parentState: 'recover-mount-point',
        views: {
          'canvas@recover-mount-point': {
            templateUrl: 'app/protection/recovery/mount-point/mount-point.search.html',
            controller: 'recoverAsMountPointSearchController',
          },
        },
      })
      .state('recover-mount-point.options', {
        url: '^/protection/recovery/mount-point/options?{entityId}&{hostId}&{jobId}&{jobInstanceId}',
        help: 'protection_recovery_mountpoint_options',
        title: 'Mount Volume: Options',
        canAccess: restoreModifyAccess,
        parentState: 'recover-mount-point',
        params: {
          entity: undefined,
          host: undefined,
          mountTarget: undefined,
          volumeInfoVec: undefined,
          inactive: false,
          entityId: { type: 'string' },
          hostId: { type: 'string' },
          jobId: { type: 'string' },
          jobRunStartTime: { type: 'int', value: null },
          jobUid: { type: 'any' },
          jobInstanceId: { type: 'string' },
          osType: { type: 'string', value: null },
          resubmitRecoveryObject: { type: 'any' },
        },
        views: {
          'canvas@recover-mount-point': {
            templateUrl: 'app/protection/recovery/mount-point/mount-point.options.html',
            controller: 'recoverAsMountPointOptionsController',
          },
        },
      })
      .state('recover-mount-point.summary', {
        url: '^/protection/recovery/mount-point/summary/{targetType}/{taskId}',
        help: 'protection_recovery_mountpoint_confirm',
        title: 'Mount Volume: Summary',
        canAccess: restoreModifyAccess,
        parentState: 'recover-mount-point',
        params: {
          targetType: { type: 'string' },
          taskId: { type: 'string' },
        },
        views: {
          'canvas@recover-mount-point': {
            templateUrl: 'app/protection/recovery/mount-point/mount-point.summary.html',
            controller: 'recoverMountPointSummaryController',
          },
        },
      });
  }

  /**
   * recoverAsMountPoint Parent Controller
   **************************************************************************/
  function recoverAsMountPointControllerFn(_, $rootScope, $scope, $state, $q,
    $timeout, cSearchService, SlideModalService, SourceService, cModal, cUtils,
    ViewBoxService, JobService, SearchService, RestoreService, ClusterService,
    JobRunsService, evalAJAX, ENUM_HOST_TYPE, ENUM_ENV_TYPE, ENV_GROUPS,
    ENV_TYPE_CONVERSION, IVM_ENV_PARAMS, $transition$, FEATURE_FLAGS,
    $stateParams) {

    // Holder for shared.cart $watcher
    var cartWatcher;

    $scope.defaultRestoreTask = {
      // @type       {string} The task name
      name: undefined,
      // @type       {array} The object(s) to mount volumes from (single
      // item in an array)
      objects: [],
      // @type       {object} The parent source of the chosen target
      // Entity
      restoreParentSource: undefined,
      // @type       {object} Mount disk params
      mountVolumesParams: {
        // @type       {object} The chosen target Entity (P2P, or VM2VM)
        targetEntity: undefined,
        // @type       {array} Array of volume name Strings
        volumeNameVec: undefined,
        // @type       {object} VMware specific params
      },
    };

    angular.extend($scope, {
      // GENERAL SCOPE VARS

      /**
      * Ordered list of state names in this recovery flow
      *
      * @type       {Array}
      */
      stateNames: [
        'recover',
        'recover-mount-point.search',
        'recover-mount-point.options',
      ],

      /**
      * Hash of known Clusters' info for lookup
      *
      * @type       {Object}
      */
      clusters: {},

      /**
      * Object for sharing within and across descendant $scopes
      *
      * @type       {Object}
      */
      shared: {
        task: {},
        cart: [],
        showDbs: {},
        selectedVlanTarget: undefined,
        isFailover: false,
        snapshotVolumes: [],
        isAbbreviated: false,
      },

      /**
      * Bool to show or hide the volumes picker on the options step.
      * @type {Boolean}
      */
      showVolumesPicker: false,

      /**
      * Bool to indicate if there's an unknown volumes state (no volumes
      * info returned).
      * @type {Boolean}
      */
      unknownVolumes: false,
      os: ENUM_HOST_TYPE,
      entityOsType: '',

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoveryMountPoint_recoverMountPointText,

      // ERRORS
      errorText: $rootScope.text.protectionRecoveryMountPoint_recoverMountPoint1ErrorText,

      // SCOPE METHODS
      addToCart: addToCart,
      startFlowOver: startFlowOver,
      submitTask: submitTask,
      getViewBox: getViewBox,
      fetchDependencies: fetchDependencies,
      removeFromCart: removeFromCart,
      updateTaskWithEntity: updateTaskWithEntity,
      updateTaskWithTargetEntity: updateTaskWithTargetEntity,
      updateTaskWithSnapshot: updateTaskWithSnapshot,
      updateTaskWithArchiveTarget: updateTaskWithArchiveTarget,
      isOriginalTarget: SourceService.isSameEntity,
      browseForLeafEntities: SourceService.browseForLeafEntities,
      toggleVolumesPicker: toggleVolumesPicker,
      getVolumes: getVolumes,
      getOStype: getOStype,
      getIvmEnvParams: getIvmEnvParams,
    });


    /**
    * WATCHERS
    ******************************************************************/

    // Destroy this search session when destroying this scope (not done
    // automatically??)
    $scope.$on('$destroy', destroyThisSearchSession);

    // Watch the cart for changes and make other updates accordingly
    cartWatcher = $scope.$watch('shared.cart',
      function cartWatcherFn(nv, ov) {
        var entity;
        var backupSourceEntity;

        if (Array.isArray(nv) && nv.length) {
          entity = nv[0];
          backupSourceEntity = entity.vmDocument.objectId.entity;

          toggleVolumesPicker(false);
          updateTaskWithEntity(entity);
          updateTaskWithSnapshot(entity._snapshot);
          updateTaskWithArchiveTarget(entity._archiveTarget);
          getVolumes();

          // If the backup source is selected and not in re-submit workflow,
          // update the task.
          if (backupSourceEntity && !$stateParams.mountTarget) {
            updateTaskWithTargetEntity(backupSourceEntity);
          }
        }
      });


    /**
     * METHODS
     ******************************************************************/

    /**
     * Initialize all the things!
     *
     * @method     recoverAsMountPointInit
     */
    function recoverAsMountPointInit() {
      $scope.pageTitle = $transition$.params().type === 'vmdk' ?
        'recoverVirtualDisks' : 'recoverAsMountPoint';

      initRecoverTask();
      fetchDependencies();
    }

    /**
     * Update the task with the given Entity and any other task defaults
     * that are entity related.
     *
     * @method     updateTaskWithEntity
     * @param      {Object}  entity  The Entity
     */
    function updateTaskWithEntity(entity) {
      $scope.shared.task.objects = [entity.vmDocument.objectId];

      // If the new entity is a different type than any previously
      // selected targetEntity, clear targetEntity & restoreParentSource
      // params out. They have to be the same type.
      if ($scope.shared.task.mountVolumesParams.targetEntity &&
        ($scope.shared.task.mountVolumesParams.targetEntity.type !== entity.vmDocument.objectId.entity.type)) {
          angular.extend($scope.shared.task, {
            restoreParentSource: undefined,
            mountVolumesParams: angular.copy($scope.defaultRestoreTask.mountVolumesParams)
          });
      }
    }

    /**
     * Update the task with the given restore Target
     *
     * @method     updateTaskWithTargetEntity
     * @param      {object}  target  The target Entity
     */
    function updateTaskWithTargetEntity(target) {

      // Check to see if this entity exists locally in this cluster
      SourceService.getEntitiesById(target.id).then(
        function getEntitiesSuccess(resp) {
          var typedEntity;
          // If the response is not empty, we have the target data locally.
          // Use it... wisely
          resp = Array.isArray(resp) && resp[0];
          if (isObject(resp)) {
            assign($scope.shared.task, {
               // parentId will exist for VM but not physical.
              restoreParentSource: target.parentId ?
                {id: target.parentId} : undefined,
              mountVolumesParams: {
                // preserve old volumeNameVec value
                volumeNameVec: get($scope,
                  'shared.task.mountVolumesParams.volumeNameVec'),
                targetEntity: target,
                useExistingAgent: false,
              }
            });

            $scope.shared.ivmEnvParam = getIvmEnvParams();
            $scope.shared.task.mountVolumesParams[$scope.shared.ivmEnvParam] = {
              bringDisksOnline: $scope.entityOsType === 'Linux' ? true : false,
              targetEntityCredentials: undefined,
            };
          }
      });
    }

    /**
     * proto key for IVM options needs to be dynamic according to selected
     * target entity. this method will return the proper key
     *
     * @return   {String}    proto key to sucessfully submit task
     */
    function getIvmEnvParams() {
      switch($scope.shared.task.mountVolumesParams.targetEntity.type) {
        case ENV_TYPE_CONVERSION.kVMware:
          return IVM_ENV_PARAMS.kVMware;

        case ENV_TYPE_CONVERSION.kHyperV || ENV_TYPE_CONVERSION.kHyperVVSS:
          return IVM_ENV_PARAMS.kHyperV;

        default:
          return IVM_ENV_PARAMS.kVMware;
      }
    }

    /**
     * Update the task with the given snapshot
     *
     * @method     updateTaskWithSnapshot
     * @param      {object}  snapshot  The entity snapshot
     */
    function updateTaskWithSnapshot(snapshot) {
      if (snapshot) {
        angular.merge($scope.shared.task.objects[0], {
          jobInstanceId: snapshot.instanceId.jobInstanceId,
          startTimeUsecs: snapshot.instanceId.jobStartTimeUsecs,
        });

        angular.merge($scope.shared.cart[0]._snapshot.instanceId, {
          jobInstanceId: snapshot.instanceId.jobInstanceId,
          startTimeUsecs: snapshot.instanceId.jobStartTimeUsecs,
          jobStartTimeUsecs: snapshot.instanceId.jobStartTimeUsecs,
        });
      }
    }

    /**
     * Update the task with the given archiveTarget
     *
     * @method     updateTaskWithArchiveTarget
     * @param      {object}  target  The archiveTarget
     */
    function updateTaskWithArchiveTarget(target) {
      angular.merge($scope.shared.task.objects[0], {
        archivalTarget: target && target.target &&
                target.target.archivalTarget
      });
    }

    /**
     * Toggles the "Select all Volumes" option and does some cleanup when
     * the checkbox is checked.
     *
     * @method   toggleVolumesPicker
     * @param    {boolean=}   areAllSelected   True if all volumes are to
     *                                         be selected, False otherwise.
     */
    function toggleVolumesPicker(areAllSelected) {
      $scope.shared.areAllVolumesSelected = !!areAllSelected;
      // This is an inverted toggle, in that checking it hides additional
      // controls, and unchecking displays them:
      // Unchecked = cherry pick disks;
      // Checked = mount everything.
      $scope.showVolumesPicker = !areAllSelected;
      if (areAllSelected) {
        // The user has checked "Select all Volumes". When the user checks
        // this, it means they don't want to cherry pick any volumes, but
        // instead mount all protected disks for the VMDK. So we will
        // clear any selection (deselect) and send nothing, which Magneto
        // interperets literally as "mount all protected disks."

        // Slight delay to allow animation to complete then empty the
        // selection.
        $timeout(deselectAllVolumes, 75);
      }
    }

    /**
     * Deselects all volumes from the task model AND in the options for
     * cMultiselect.
     *
     * @method   deselectAllVolumes
     */
    function deselectAllVolumes() {
      delete $scope.shared.task.mountVolumesParams.volumeNameVec;
      // Deselect all options
      $scope.shared.snapshotVolumes.forEach(function deselectAll(volume) {
        volume.selected = false;
      });
    }

    /**
     * Gets the volumes for the currently selected entity and snapshot and
     * sets them up in $scope.
     *
     * @method     getVolumes
     */
    function getVolumes() {
      var params = {};
      var entity = $scope.shared.cart[0];
      var unmountableCount = 0;

      // From the task: entityId jobUidObjectId, jobInstanceId,
      // jobStartTimeUsecs, attemptNum
      params = angular.extend({
          entityId: entity.vmDocument.objectId.entity.id,
          jobId: entity.vmDocument.objectId.jobId,
          jobUidObjectId: entity.vmDocument.objectId.jobUid.objectId,
        },
        entity.vmDocument.objectId.jobUid,
        entity._snapshot.instanceId);

      // if we are in 'Instant Volume Mount' recovery flow we only want to get
      // supported volumes only
      if ($state.current.name === 'recover-mount-point.options' ||
          $state.current.name === 'recover-mount-point.search') {
        params.supportedVolumesOnly = false;
      }

      // Delete extraneous properties leftover from the merge
      delete params.objectId;

      // Some pre-fetch resets
      $scope.shared.loadingVolumes = true;
      $scope.shared.snapshotVolumes.length = 0;
      $scope.volumesUnknown = false;

      RestoreService
        .getVolumeInfo(params)
        .then(function getVolumeInfoSuccess(resp) {
          $scope.shared.snapshotVolumes = resp.volumeInfos.sort(sortByDisplayName)
            .map(function selectOptionsMapper(volume) {

              var rxFileSystemType =  /^(ntfs|refs)$/i;
              if (FEATURE_FLAGS.instantVolumeMountLinuxVM) {
                rxFileSystemType = /^(ntfs|refs|ext[2-4]|xfs)$/i;
              }

              // For VMs. NTFS, and Linux volumes can be mounted at this time.
              var isMountable = !entity._isVM ||
                rxFileSystemType.test(volume.filesystemType);

              // Increment the count of unmountable volumes if
              // this is not mountable
              unmountableCount += (!isMountable) ? 1 : 0;

              return {
                name: volume.displayName || volume.name,
                value: volume.name,
                filesystemType: volume.filesystemType,
                // For whatever reason, volume.isSupported can
                // be true, but if it's not an NTFS volume we
                // can't mount it.
                disabled: !isMountable
              };

            });
        })
        .finally(function getVolumeInfoFinally() {
          var areKnownVolumes;
          var areVolumesValid;
          var areNativeSnapshots;

          $scope.volumesUnknown = !$scope.shared.snapshotVolumes.length;

          areKnownVolumes = !$scope.volumesUnknown && entity._isVM;
          areVolumesValid =
            unmountableCount === $scope.shared.snapshotVolumes.length;
          areNativeSnapshots = ENV_GROUPS.nativeSnapshotTypes
            .includes($scope.shared.cart[0]._jobType);

          // IVM is not supported if volumes being mounted are non VM volumes,
          // or have been backed up using native snapshots.
          $scope.noSupportedVolumes = (areKnownVolumes && areVolumesValid) ||
            areNativeSnapshots;
          $scope.shared.loadingVolumes = false;

          if ($scope.volumesUnknown) {
            $scope.shared.task.mountVolumesParams
              .vmwareParams.bringDisksOnline = false;
            toggleVolumesPicker(false);
          }
        });
    }

    /**
     * Array.filter Fn to sort by `displayName` property (ascending)
     *
     * @method     sortByDisplayName
     * @param      {object}   a       An object
     * @param      {object}   b       Another object
     * @return     {integer}  Sorted by 1 or -1
     */
    function sortByDisplayName(a, b) {
      return (a.displayName < b.displayName) ? -1 : 1;
    }

    /**
     * Gets the OS type.
     *
     * @method     getOStype
     * @param      {object}  entity  The entity
     * @return     {string}  The OS type string
     */
    function getOStype(entity) {
      if (entity._isPhysical) {
        return $scope.os[entity.vmDocument.objectId.entity.physicalEntity.hostType] || $scope.text.unknown;
      }
      return entity.vmDocument.osType || $scope.text.unknown;
    }

    /**
     * Destroy this search session completely.
     *
     * @method     destroyThisSearchSession
     */
    function destroyThisSearchSession() {
      cSearchService.destroy($scope.shared.searchId);
    }

    /**
     * Removes the given Entity from the cart
     *
     * @method     removeFromCart
     * @param      {Entity}  row     Entity to remove
     */
    function removeFromCart(row) {
      // Shortcut to empty the cart.
      $scope.shared.cart.length = 0;
    }

    /**
     * Lookup a viewbox by id
     *
     * @method     getViewBox
     * @param      {integer}  id      The view box id
     * @return     {object}   The found view box entity
     */
    function getViewBox(id) {
      var out = {};
      if (id) {
        $scope.shared.filterLookups.viewBoxIds.some(function findViewboxFn(vb) {
          if (id === vb.id) {
            out = vb;
            return true;
          }
          return false;
        });
      }
      return out;
    }

    /**
     * Initializes recover task with defaults
     *
     * @method     initRecoverTask
     */
    function initRecoverTask() {
      $scope.shared.task = angular.copy($scope.defaultRestoreTask);
    }

    /**
     * Shared function to start the flow over and replaces the browser history
     *
     * @method     startFlowOver
     */
    function startFlowOver() {
      $state.go($scope.stateNames[1], undefined, {
        location: 'replace'
      });
    }

    /**
     * Adds the given entity to the task Cart. Currently enforces single
     * item restrictions
     *
     * @method     addToCart
     * @param      {Object}  entity  Server Entity
     */
    function addToCart(entity) {
      var entityType;
      var firstVersion;

      var isVMDK = !!get(entity,
        'vmDocument.objectId.entity.vmwareEntity.virtualDiskInfo.length');

      $scope.entityOsType = getOStype(entity);

      // Process this server for usable snapshots
      entity.vmDocument.versions =
        RestoreService.getRestorableVersions(entity.vmDocument.versions);

      firstVersion = entity.vmDocument.versions[0];

      angular.extend(entity, {
        // Default the selectedSnapshot to the first in the list
        _selectedSnapshot: firstVersion,

        // If the first replicaVec isn't a local target (we don't send
        // that), select the first by default. Unsupported Replicaiton
        // (type 2) targets have already been filtered out at this point.
        _archiveTarget: (firstVersion &&
          firstVersion.replicaInfo &&
          Array.isArray(firstVersion.replicaInfo.replicaVec) &&
          firstVersion.replicaInfo.replicaVec[0] &&
          firstVersion.replicaInfo.replicaVec[0].target &&
          (1 !== firstVersion.replicaInfo.replicaVec[0].target.type)) ?
            // It's not local, use it
            firstVersion.replicaInfo.replicaVec[0] :

            // It's local, don't set it
            undefined
      });

      // Determine which source type for an accurate task name.
      $scope.shared.task.name = RestoreService.getDefaultTaskName(
        $scope.text.taskNamePrefix,
        ENUM_ENV_TYPE[entity._type]);

      $scope.shared.cart = [entity];

      updateTaskWithEntity(entity);

      if (isVMDK && $transition$.params().type === 'vmdk') {
        $state.go('recover-mount-point.vmdk-recover-options', {
          entity: entity.vmDocument.objectId.entity,
          host: entity.registeredSource,
          jobId: entity.vmDocument.objectId.jobId,
          jobUid: entity.vmDocument.objectId.jobUid,
        });

        return;
      }

      if (!$scope.shared.isAbbreviated) {
        $state.go($scope.stateNames[2], {
          entity: entity.vmDocument.objectId.entity,
          host: entity.registeredSource,
          jobId: entity.vmDocument.objectId.jobId,
          osType: $scope.entityOsType,
          jobUid: entity.vmDocument.objectId.jobUid,
        });
      }
    }

    /**
     * Sets up the lookups skeleton needed for this flow
     *
     * @method     initFilterLookups
     */
    function initFilterLookups() {
      $scope.shared.filterLookups = $scope.shared.filterLookups || {};
      assign($scope.shared.filterLookups, {
        entityIds: [],
        entityTypes: [{
          display: $scope.text.physicalServers,
          value: 'kPhysical',
        }, {
          display: $scope.text.vms,
          value: intersection(
            cUtils.onlyStrings(ENV_GROUPS.hypervisor),
            cUtils.onlyStrings(ENV_GROUPS.instantMount)
          ),
        }],
        viewBoxIds: [],
        registeredSourceIds: [],
        jobIds: [],
      });
    }

    /**
     * Fetch dependencies from the server. Mostly for filter lookups.
     *
     * @method     fetchDependencies
     */
    function fetchDependencies() {
      var promises = [
        // viewBoxes
        ViewBoxService.getOwnViewBoxes(),

        // ParentSources
        SourceService.getEntitiesOfType({
          environmentTypes: cUtils.onlyStrings(ENV_GROUPS.instantMount),
          vmwareEntityTypes: ['kVCenter'],
          physicalEntityTypes: ['kHost'],
          azureEntityTypes: ['kSubscription'],
          awsEntityTypes: ['kIAMUser'],
        }),

        // All protected Servers (VM + Physical)
        SourceService.getServers(true),

        // All jobs with mountable types + SQL.
        JobService.getJobs({
          envTypes: cUtils.onlyNumbers(ENV_GROUPS.instantMount).concat(3),
        }),
      ];

      return $q.all(promises)
        .then(function allFetched(responses) {
          initFilterLookups();
          if (Array.isArray(responses[0])) {
            $scope.shared.filterLookups.viewBoxIds = responses[0];
          }
          if (Array.isArray(responses[1])) {
            $scope.shared.filterLookups.registeredSourceIds = responses[1];
          }
          if (Array.isArray(responses[2])) {
            $scope.shared.filterLookups.entityIds = responses[2];
          }
          if (Array.isArray(responses[3])) {
            $scope.shared.filterLookups.jobIds = responses[3].map(
              function augmentJobsFn(job) {
                return angular.merge(job, {
                  jobName: job.name,
                });
              }
            );
          }
          $scope.clusters = ClusterService.updateClusterHash(ClusterService.clusterInfo);
          $scope.shared.filterLookups.clusters = $scope.clusters;
        });
    }

    /**
     * Submit the task for restoration
     *
     * @method     submitTask
     */
    function submitTask(form) {
      if (form.$invalid) {
        return false;
      }

      // If the volumes vec is an empty list, delete because it means
      // mount all volumes.
      if ($scope.shared.task.mountVolumesParams.volumeNameVec &&
        !$scope.shared.task.mountVolumesParams.volumeNameVec.length) {
          delete $scope.shared.task.mountVolumesParams.volumeNameVec;
      }

      $scope.shared.task.restoreVlanParams = RestoreService.getVlanParams(
        $scope.shared.selectedVlanTarget
      );

      // Set aws glacier retrieval type from sharedState.retrievalOption into
      // vaultRestoreParams.glacier.retrievalType
      if ($scope.shared.retrievalOption) {
        set($scope.shared.task, 'vaultRestoreParams.glacier.retrievalType',
        $scope.shared.retrievalOption);
      }

      // If the toggle is off, do not send useExistingAgent property to backend.
      if (!$scope.shared.task.mountVolumesParams[$scope.shared.ivmEnvParam]
          .bringDisksOnline) {
          $scope.shared.task.mountVolumesParams.useExistingAgent = undefined;
      }

      // Kick off the task
      $scope.isSubmitting = true;
      return RestoreService.restoreVM($scope.shared.task)
        .then(function taskAcceptedFn(restoreTask) {
          var stateName =
            RestoreService.getRestoreTaskDetailStateName(
              'recover', $scope.targetType);

          $state.go(stateName, {
            id: restoreTask.performRestoreTaskState.base.taskId,
          });
        }, evalAJAX.errorMessage)
        .finally(function taskSubmittedFn() {
          $scope.isSubmitting = false;
        });
    }


    // Initialize!
    recoverAsMountPointInit();
  }


})(angular);
