import { assign } from 'lodash-es';
// Component: Office365 Recovery Search component.

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.office365Recovery')
    .controller('Office365RecoverySearchCtrl', office365RecoverySearchCtrlFn)
    .component('office365RecoverySearch', {
      templateUrl:
        'app/protection/recovery/office365/search/search.html',
      controller: 'Office365RecoverySearchCtrl',
    });

  /**
   * @ngdoc component
   * @name C.office365Recovery:office365RecoverySearch
   * @function
   * @param   {object}   _                          Lodash Library
   * @param   {object}   SlideModalService          Modal service
   * @param   {object}   RecoveryStore              Cart service
   * @param   {object}   NgStateManagementService   State management service
   * @param   {object}   $state                     State object
   *
   * @description
   * Component to provide search functionality for mailbox or emails.
   */
  function office365RecoverySearchCtrlFn(_, SlideModalService, RecoveryStore) {
    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
      openCart: openCart,
      clearCartAndSearch: clearCartAndSearch,
    });

    /**
     * @method   $onInit
     *
     * Initializes component.
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();
    }

    /**
     * Launches the recovery cart.
     *
     * @method   openCart
     */
    function openCart() {
      SlideModalService.newModal({
        autoHeight: true,
        component: 'office365RecoveryCart',
        size: 'md',
      });
    }

    /**
     * Clears the recovery cart and the search results when the search type is
     * changed.
     * NOTE: Although both iris and magneto support recovery of mailbox,
     * folders & emails in a single recovery job but currently this is just to
     * segregate the flow. This method may be removed in future.
     *
     * @method   clearCartAndSearch
     */
    function clearCartAndSearch() {
      $ctrl.shared.cart.removeAll();

      // Reset the Email search parameter info.
      $ctrl.state.showEmailSearchParameters = false;
    }
  }
})(angular);