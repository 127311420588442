<form [formGroup]="form" cohValidateOnSubmit (validatedSubmit)="onFormSubmit()">
  <mat-card class="margin-bottom">
    <mat-card-content>
      <div class="flex-row flex-gap-32">
        <div class="flex-column label-container">
          <div class="field-label">{{ 'sessionManagement.inactivityTimeout' | translate }}</div>
          <div class="field-caption">{{ 'sessionManagement.inactivityTimeoutCaption' | translate }}</div>
        </div>
        <coh-duration-selector
          formControlName="inactivityTimeout"
          [durationUnits]="durationUnits"
          [minValue]="inactivityLimits.min"
          [maxValue]="inactivityLimits.max"
          class="flex-column"
          cogDataId="inactivity-timeout">
        </coh-duration-selector>
      </div>

      <div class="flex-row flex-gap-32">
        <div class="flex-column label-container">
          <div class="field-label">{{ 'sessionManagement.absoluteTimeout' | translate }}</div>
          <div class="field-caption">{{ 'sessionManagement.absoluteTimeoutCaption' | translate }}</div>
        </div>
        <coh-duration-selector
          formControlName="absoluteTimeout"
          [durationUnits]="durationUnits"
          [minValue]="absoluteLimits.min"
          [maxValue]="absoluteLimits.max"
          class="flex-column"
          cogDataId="absolute-timeout">
        </coh-duration-selector>
      </div>
      <mat-error *ngIf="form.errors?.timeoutInvalidError" cogDataId="timeout-range-error">
        {{ 'sessionManagement.errorMsg.timeoutInvalidError' | translate }}
      </mat-error>
    </mat-card-content>
  </mat-card>
  <div class="flex-row flex-gap-16">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      cogSubmitButton
      [disabled]="(submitting$ | async) || isReadOnlyUser"
      [loading]="(submitting$ | async) || (loading$ | async)"
      cogDataId="submit-button">
      {{ 'save' | translate }}
    </button>
    <button
      mat-flat-button
      type="button"
      (click)="resetForm()"
      [disabled]="(submitting$ | async) || (loading$ | async) || isReadOnlyUser"
      cogDataId="reset-button">
      {{ 'resetToDefault' | translate }}
    </button>
  </div>
</form>
