import { assign } from 'lodash-es';
// Module: Policy/Job Details Directive

;(function(angular) {
  'use strict';

  var configOptions = {
    bindings: {
      // @type   {Object}   policy   The policy info
      policy: '<',

      // @type   {Boolean}  hideLocalRetention    Should local retention info
      //                                          be hidden? Some jobs don't
      //                                          have any local snapshots. So
      //                                          local viewbox info is not
      //                                          needed for them
      hideLocalRetention: '<',

      // @type   {Object}   job   The job info
      job: '<',
    },
    controller: 'PolicyDetailsCtrl',
    templateUrl: 'app/protection/policies/policy/policy-details.html',
  };

  angular.module('C.policies')
    .controller('PolicyDetailsCtrl', policyDetailsCtrlFn)
    .component('policyDetails', configOptions);

  function policyDetailsCtrlFn(ENV_GROUPS, FEATURE_FLAGS, evalAJAX, cUtils,
    ExternalTargetService, RemoteClusterService, PubSourceService) {

    var $ctrl = this;

    // declare component methods
    assign($ctrl, {
      remoteHashmap: {},
      externalTargetHashmap: {},
      sourceHashmap: {},

      // component life cycle methods
      $onInit: getDependencies,
      $onChanges: getDependencies,
    });

    /**
     * Gets the policy dependencies like archival, remote cluster or cloud
     * deploy targets details
     *
     * @method   getDependencies
     */
    function getDependencies() {
      // do noting if policy is not defined
      if (!$ctrl.policy) {
        return;
      }

      if (!$ctrl.job) {
        return;
      }

      // resolve archival target names if policy contains archival config
      if ($ctrl.policy.snapshotArchivalCopyPolicies.length) {
        ExternalTargetService.getTargets().then(
          function getTargetsSuccess(extTargets) {
            extTargets.forEach(function mapVault(extTarget) {
              $ctrl.externalTargetHashmap[extTarget.id] = extTarget.name;
            });
          },
          evalAJAX.errorMessage
        ).finally(
          function getVaultsFinally() {
            $ctrl.externalTargetsLoaded = true;
          }
        );
      }

      // resolve remote targets names if policy contains replication setup
      if ($ctrl.policy.snapshotReplicationCopyPolicies.length) {
        RemoteClusterService.getAllClustersHash().then(
          function gotRemoteClustersHash(clustersHash) {
            $ctrl.remoteHashmap = clustersHash;
          },
          evalAJAX.errorMessage
        ).finally(function getVaultsFinally() {
          $ctrl.remotesLoaded = true;
        });
      }

      // resolve cloud deploy sources names if policy contains cloud deploy info
      if ($ctrl.policy.cloudDeployPolicies && FEATURE_FLAGS.cloudDeploy) {
        PubSourceService.getRootNodes({
          environments: cUtils.onlyStrings(ENV_GROUPS.cloudDeploySources)
        }).then(
          function getSourcesSuccess(sources) {
            sources.forEach(function mapSource(source) {
              $ctrl.sourceHashmap[source.protectionSource.id] =
                source.protectionSource.name;
            });
          },
          evalAJAX.errorMessage
        ).finally(
          function getSourcesFinally() {
            $ctrl.cloudSourcesLoaded = true;
          }
        );
      }

      // Check whether this is an externally triggered UDA job
      // and set policyInfoMsg based on etEnableLogBackupPolicy.
      if (FEATURE_FLAGS.udaEtLogBackup &&
        $ctrl.job.envBackupParams.udaBackupJobParams && $ctrl.job.envBackupParams.udaBackupJobParams.etLogBackup) {
        let params = {
          allUnderHierarchy: false,
        };
        PubSourceService.getSource($ctrl.job.parentSource.id, params).then(function getSource(source) {
          const udaParams = source[0]?.registrationInfo?.udaParams ?? null;
          const etEnableLogBackupPolicy = udaParams?.etEnableLogBackupPolicy ?? false;
          if (!etEnableLogBackupPolicy) {
            $ctrl.policyInfoMsg = 'policy.udaETLogBackupNote';
          }
        }, evalAJAX.errorMessage);
      }
    }

  }

}(angular));
