import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: cStorageVolumePureRecoveryOptions

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.storageVolumePureRecoveryOptions', [])
    .component('cStorageVolumePureRecoveryOptions', {
      bindings: {
        /**
         * Flow Type
         *
         * @param   flowType
         * @type    {string}
         */
        flowType: '<',

        /**
         * isClone property from the parent view
         *
         * @param   isClone
         * @type    {boolean}
         */
        isClone: '<',

        /**
         * Page Config
         *
         * @param  pageConfig    routeParams and any other params required for
         *                       component init
         *                       {
         *                         entityId: '',
         *                         jobId: '',
         *                         jobInstanceId: '',
         *                         parentId: '',
         *                       }
         * @type   {object}
         */
        pageConfig: '<',

        /**
         *  addToCart callback function defined in parent view
         *
         * @method   addToCart
         * @type     {function}
         */
        addToCart: '=',

        /**
         *  startFlowOver callback function defined in parent view
         *
         * @method   startFlowOver
         * @type     {function}
         */
        startFlowOver: '=',

        /**
         *  getViewBox callback function defined in parent view
         *
         * @method   getViewBox
         * @type     {function}
         */
        getViewBox: '=',
      },
      templateUrl:
        'app/protection/recovery/storage-volume/pure.options.html',
      controller: cStorageVolumePureOptionsCtrlFn
    });

  /**
   * Recover Pure Storage Volume Options Controller
   *
   * @method   cStorageVolumePureOptionsCtrlFn
   */
  function cStorageVolumePureOptionsCtrlFn(_, $state, evalAJAX, RestoreService,
    RecoveryStore, SearchService, SlideModalService, SourceService, $q, cUtils,
    ENV_GROUPS) {
    var $ctrl = this;

    // declare component methods
    assign($ctrl, {
      selectRestorePoint: selectRestorePoint,
      submitTask: submitTask,

      // Component Lifecycle Methods
      $onInit: $onInit,
    });

    /**
     * Initialize the data required for the component
     *
     * @method   _initializeCtrl
     */
    function _initializeCtrl() {
      // Shared data stored in a service.
      // Accessible via getters and setters
      var _shared = RecoveryStore.get();

      assign($ctrl, {
        shared: _shared,

        // Entity selected
        entity: _shared.cart[0],

        // pure sources
        sources: (_shared.filterLookups.registeredSourceIds || [])
          .filter(function filterPureSources(san) {
            return san.entity.pureEntity;
          }),

      });
    }

    /**
     * Component init function
     *
     * @method   $onInit
     */
    function $onInit() {
      // Initialize data
      _initializeCtrl();

      // Recover directly from the a job backup instance
      if ($ctrl.pageConfig.jobId &&
        ($ctrl.pageConfig.entityId || $ctrl.pageConfig.jonInstanceId)) {
        _setupAbbreviatedFlow();

      // Edge Case: When the recover flow is initialized manually
      //            without required params.
      } else if (!$ctrl.shared.cart.length) {
        $ctrl.startFlowOver();
      } else {
        _updateTaskObjects();
        _updateRestoreParentSource();
      }
    }

    /**
     * Function to make some scope changes for Direct object selection.
     *
     * @method   _setupAbbreviatedFlow
     */
    function _setupAbbreviatedFlow() {
      var foundMatch = false;
      $ctrl.isLoading = true;

      var promises = {
        sources: SourceService.getSources({
          onlyReturnOneLevel: true,
          envTypes: cUtils.onlyNumbers(ENV_GROUPS.san),
        }),
        search: SearchService.pureSearch({
          entityIds: $ctrl.pageConfig.entityId,
          jobIds: [$ctrl.pageConfig.jobId],
          registeredSourceIds: !!$ctrl.pageConfig.parentId ?
            [$ctrl.pageConfig.parentId] :
            undefined,
        })
      };

      $q.all(promises).then(
        function allSuccess(responses) {
          if (get(responses, 'sources.entityHierarchy.children')) {
            $ctrl.sources = responses.sources.entityHierarchy.children;
          }

          if (responses.search && responses.search.length) {
            // Parse the response for the matching entity and add it to the cart
            foundMatch = responses.search.some(
              function findMatchingEntity(entity) {
                // Using == in here because stateParams are strings
                if (
                  $ctrl.pageConfig.entityId ==
                    entity.vmDocument.objectId.entity.id &&
                  $ctrl.pageConfig.jobId == entity.vmDocument.objectId.jobId
                ) {
                  // Preselect snapshot?
                  if ($ctrl.pageConfig.jobInstanceId) {
                    entity._snapshot = entity.vmDocument.versions.find(
                      function findSnapshot(run) {
                        return $ctrl.pageConfig.jobInstanceId ==
                          run.instanceId.jobInstanceId;
                      }
                    );
                  }

                  // Add it to the cart now
                  $ctrl.addToCart(entity, false);
                  return true;
                }
              }
            );
          }

          // No matching entity was found, show an error
          if (!foundMatch) {
            cMessage.error({
              titleKey: 'noVolumeFound',
              textKey: 'recover.pure.options.noEntityFound',
            });
          }

          _updateTaskObjects();
          _updateRestoreParentSource();
        }, evalAJAX.errorMessage
      )
      .finally(function promisesfinally() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Update task objects
     */
    function _updateTaskObjects() {
      $ctrl.shared.task.objects =
        RestoreService.generateRestoreTaskObjects($ctrl.shared.cart);
    }

    /**
     * Conditionally updates the parent source of the task
     *
     * @method   _updateRestoreParentSource
     */
    function _updateRestoreParentSource() {
      if (!$ctrl.shared.task.restoreParentSource &&
        $ctrl.sources.length &&
        $ctrl.shared.task.objects.length) {
        // Convenience to preselect the volume's parent SAN, if one hasn't
        // already been selected.
        $ctrl.sources.some(
          function findParent(san) {
            if (san.entity.id === $ctrl.shared.task.objects[0].entity.parentId) {
              $ctrl.shared.task.restoreParentSource = san.entity;
              return true;
            }
          }
        );
      }
    }

    /**
     * Open the snapshot selector to let the user choose a differebt restore
     * point
     *
     * @method   selectRestorePoint
     * @param    {object} row the search result entity in question
     */
    function selectRestorePoint(row) {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/common/snapshot-selector/common.snapshot-selector.html',
        controller: 'commonRestoreSnapshotModalController',
        size: 'lg',
        resolve: {
          task: angular.copy($ctrl.shared.task),
          entity: row,
        },
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {
          assign(row, {
            _snapshot: resp.snapshot,
            _archiveTarget: resp.archiveTarget,
          });
          if (resp.tapeListParams) {
            $ctrl.shared.tapeListParams = resp.tapeListParams;
          }
          _updateTaskObjects();
        });
    }

    /**
     * Submit the task as configured.
     *
     * @method   submitTask
     * @param    {object}   form   The form object
     */
    function submitTask(form) {
      if (!form || form.$invalid) {
        return false;
      }

      delete $ctrl.shared.task.viewName;
      delete $ctrl.shared.task.defaultTaskName;


      $ctrl.isSubmitting = true;
      RestoreService.restoreVM($ctrl.shared.task)
        .then(
          function taskAcceptedSuccess(restoreTask) {
            // TODO(sanath): Check with jeff on targetType
            var stateName = RestoreService.getRestoreTaskDetailStateName(
              $ctrl.flowType, $ctrl.targetType);

            var stateParams = {
              id: restoreTask.performRestoreTaskState.base.taskId,
            };

            // Clear the shared store to keep things sane.
            RecoveryStore.clear();

            // Move to the next state.
            $state.go(stateName, stateParams);
          },
          evalAJAX.errorMessage
        )
        .finally(function taskAcceptedFinally() {
          $ctrl.isSubmitting = false;
        });

    }
  }
})(angular);
