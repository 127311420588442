import { isEmpty } from 'lodash-es';
import { assign } from 'lodash-es';
// Component:  Selected Restore Point display.

import { OwnershipContext } from "src/app/shared";

/**
 * NOTE: Designed for use only in restore flows. YMMV if you try to use it
 * elsewhere.
 */

;(function(angular, undefined) {
  'use strict';

  var componentName = 'cRestorePoint';
  var options = {
    // These are bound to the controller, not the scope
    bindings: {
      /**
       * Required search result row with vmDocument.versions[]
       *
       * @type   {object}
       */
      data: '<',

      /**
       * Optional string to indicate if this is 'recover' or 'clone' flow.
       * Default: 'recover'.
       *
       * @type   {string}
       */
      flowType: '@?',

      /**
       * Optional label string. Use 'false' to hide the label entirely.
       * Default: "Restore Point" for clone flows, "Recover Point" for Recover
       * flows.
       *
       * @type   {string}
       */
      label: '@?',

      /**
       * Determines if this renders in read-only mode or not.
       *
       * @type   {boolean}
       */
      readonly: '<?',

      /**
       * Optional function to trigger selection of different snapshots (create
       * restore task flow). If omitted, display is immutable.
       *
       * @type   {function}
       */
      select: '&?',

      /**
       * Optional timezone variable to convert the restore point to the
       * desired timezone.
       */
      timezone: '<?',

      /**
       * Optional Boolean to specify whether the restore point data is public
       * data.
       */
      isPublicData: '<?'
    },

    // The controller
    controller: 'CRestorePointControllerFn',

    // URL to the template
    templateUrl: 'app/global/c-restore-point/c-restore-point.html',
  };

  angular
    .module('C')
    .controller('CRestorePointControllerFn', cRestorePointControllerFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C:cRestorePoint
   * @function
   *
   * @description
   * In a Restore flow (Recover or Clone), displays the selected restore point
   * (snapshot), indicates if it's the latest, displays info about the selected
   * remote Archive target (icebox vault), and allows selection of different
   * snapshots.
   *
   * @example
     <example module="C">
       <c-restore-point data="searchResultRow" select="selectSnapshotFn"></c-restore-point>
       <c-restore-point
         data="searchResultRow"
         flow-type="clone"
         select="selectSnapshotFn()"></c-restore-point>
     </example>
   */
  function cRestorePointControllerFn(_, $scope, $filter, ENUM_ARCHIVAL_TARGET,
    ExternalTargetService, SNAPSHOT_TARGET_TYPE) {

    var $ctrl = this;
    var isClone;

    /**
     * Internal cache of versions. Normalized for inconsistencies across restore
     * flows.
     *
     * @type   {array}
     */
    var versions;

    // Expose properties and methods on the Controller for the template.
    assign($ctrl, {
      $onInit: onInit,
      getSnapshotDisplay: getSnapshotDisplay,
      targetIcons: {
        cloud: 'icn-cloud',
        local: 'icn-local',
        remote: 'icn-remote',
        tape: 'icn-tape',
        vault: 'icn-vault',
      },

      // Template methods
      getTargetIconClass: getTargetIconClass,
      getTargetName: getTargetName,
      isLatestSnapshot: isLatestSnapshot,
    });

    // Watch the data for changes (for snapshot & archiveTarget changes)/
    $scope.$watch(function() { return $ctrl.data; }, dataChangeHandler, true);

    /**
     * $onInit handler (activate)
     *
     * @method   onInit
     */
    function onInit() {
      versions = $ctrl.data.versions ||
        $ctrl.data._versions ||
        $ctrl.data.vmDocument.versions;

      isClone = $ctrl.flowType === 'clone';

      assign($ctrl, {
        isClone: isClone,
        flowType: isClone ? 'clone' : 'recover',
      });

      $ctrl.selectedSnapshot = getSelectedSnapshot();

      // If label is undefined, use the flowType dependent default.
      if ($ctrl.label === undefined) {
        $ctrl.label = $ctrl.isClone ? 'restorePoint' : 'recoverPoint';
      }

      getVaults();
    }

    /**
     * $ctrl.data $watcher handler.
     *
     * @method   dataChangeHandler
     */
    function dataChangeHandler() {
      // Uptake changes to the data param.
      if ($ctrl.data) {
        $ctrl.selectedSnapshot = getSelectedSnapshot();
        $ctrl.targetName = getTargetName();
        $ctrl.targetIconClass = getTargetIconClass();
      }
    }

    /**
     * Determines if the selected snapshot is the latest one.
     *
     * @method   isLatestSnapshot
     * @return   {boolean}   True if latest, false otherwise.
     */
    function isLatestSnapshot() {
      if ($ctrl.isPublicData) {
        return !!$ctrl.selectedSnapshot &&
          versions[0].jobRunId === $ctrl.selectedSnapshot.jobRunId;
      }
      return !!$ctrl.selectedSnapshot &&
        versions[0].instanceId.jobInstanceId ===
        $ctrl.selectedSnapshot.instanceId.jobInstanceId;
    }

    /**
     * Grabs the selected snapshot. Normalizes for inconsistencies in the
     * different restore flows. Returns undefined if there is no specified
     * snapshot.
     *
     * @method     getSelectedSnapshot
     *
     * @return     {object}  The detected snapshot.
     */
    function getSelectedSnapshot() {
      return $ctrl._snapshot ||
        $ctrl.data._selectedSnapshot ||
        $ctrl.data._snapshot;
    }

    /**
     * Get the registered vaults for showing names.
     *
     * @method   getVaults
     * @return   {object}   Promise to resolve with the list of vaults, or the
     *                      server's response if error.
     */
    function getVaults() {
      $ctrl.loading = true;

      return ExternalTargetService.getTargets().then(
        function vaultsReceived() {
          return $ctrl.vaults = ExternalTargetService.targetNameMapById;
        }

        // No error handler. We want this to fail silently and fallback on
        // generic "Local Target" and "External Target" strings.
      )
      .finally(function vaultsFinally() {
        $ctrl.loading = false;
      });
    }

    /**
     * Get the vault name of the given archivalTarget.
     *
     * @method   getTargetName
     * @param    {object}   [target]   The archiveTarget, Default: gets it from
     *                                 data
     * @return   {string}   The name of the given archivalTarget. Default:
     *                      ~"Local Archive"
     */
    function getTargetName(target) {
      target = target || getArchiveTarget();

      switch (true) {
        // This is clearly a local target. Exit now.
        case !target || target.type === 1:
          return 'localArchive';

        // Replication Target
        case target.type === 2:
          return target.replicationTarget.clusterName || 'remoteCluster';

        // Archival Target
        case !!(target.archivalTarget && target.archivalTarget.vaultId):

          // Return the known vault name, if we have it, otherwise...
          return target.archivalTarget.name ||
            ($ctrl.vaults && $ctrl.vaults[target.archivalTarget.vaultId]);
      }
    }

    /**
     * Get the appropriate vault icon for the given archivalTarget.
     *
     * @method   getTargetIconClass
     * @param    {object}   [target]   The archiveTarget, Default: gets it from
     *                                 data.
     * @return   {string}   The icon class string. Default: ~"Local"
     */
    function getTargetIconClass(target) {
      target = target || getArchiveTarget();

      if (!target || target.type === 1) {
        return $ctrl.targetIcons.local;
      }

      switch (true) {
        // Fortknox target
        case target.archivalTarget.ownershipContext === 1:
          return $ctrl.targetIcons.vault;

        // Archive Target
        case !!target.archivalTarget:
          return $ctrl.targetIcons[
            target.archivalTarget.type === 1 ? 'tape' : 'cloud'
          ];

        // Replication Target
        case target.type === 2:
          return $ctrl.targetIcons.remote;

        // Nothing matched
        default:
          return $ctrl.targetIcons.local;
      }
    }

    /**
     * Simple getter for the archiveTarget.
     *
     * @method   getArchiveTarget
     * @return   {object}   Whatever the value has been assigned as. Default:
     *                      undefined
     */
    function getArchiveTarget() {
      var archiveTargetInfo = {};

      if ($ctrl.data) {
        archiveTargetInfo = $ctrl.data._archiveTarget || {};

        // Handle Public REST API struct for archival target. This conversion
        // is needed since c-restore-point works on private data structure.
        if (isEmpty(archiveTargetInfo) &&
          $ctrl.data._snapshot &&
          $ctrl.data._snapshot.archivalTarget) {
          archiveTargetInfo = {
            target: {
              archivalTarget: {
                type: ENUM_ARCHIVAL_TARGET[
                  $ctrl.data._snapshot.archivalTarget.vaultType],
                vaultId: $ctrl.data._snapshot.archivalTarget.vaultId,
                name: $ctrl.data._snapshot.archivalTarget.vaultName,
              },
              type: SNAPSHOT_TARGET_TYPE['kArchival'],
            }
          }
        }
      }

      return archiveTargetInfo.target;
    }

    /**
     * Gets the snapshot timestamp or undefined. Doing this here in a function
     * because we want to return `undefined` rather than pipe it through the
     * usecsToDate filter and get an "n/a".
     *
     * @return     {String}  The snapshot timestamp.
     */
    function getSnapshotDisplay() {
      if ($ctrl.isPublicData) {
        // Returns Restore point returned from Public API.
        var startedTimeUsecs = $ctrl.selectedSnapshot.startedTimeUsecs;
        return $filter('usecsToDate')(startedTimeUsecs, null, $ctrl.timezone);
      }
      // Returns restore point from the Private API.
      return $ctrl.selectedSnapshot && $filter('usecsToDate')($ctrl
        .selectedSnapshot.instanceId.jobStartTimeUsecs, null, $ctrl.timezone);
    }

  }

})(angular);
