import { assign } from 'lodash-es';
// Module: Remote Clusters Manager
// TODO(veetesh): make remote cluster pages works in modal

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    controller: 'RemoteClustersManagerCtrl',
    templateUrl: 'app/remote-clusters/remote-clusters.html',
  };

  angular.module('C.remoteClusters')
    .controller('RemoteClustersManagerCtrl', remoteClustersManagerCtrlFn)
    .component('remoteClustersManager', configOptions);

  function remoteClustersManagerCtrlFn(_, $rootScope, RemoteClusterService) {

    var $ctrl = this;

    assign($ctrl, {
      // UI states
      state: {
        loading: true,
        isSpogSupported: false,
      },

      // component properties used in the view
      remoteClustersList: [],

      // component methods used in the view
      removeRemoteCluster: removeRemoteCluster,

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      getRemoteClusters();
    }

    /**
     * Gets list of Remote Clusters
     *
     * @method   getRemoteClusters
     */
    function getRemoteClusters() {
      $ctrl.state.loading = true;
      RemoteClusterService.getRemoteClusters({_includeTenantInfo: true}).then(
        function remoteClusterSuccess(remoteClusters) {
          const hasRemoteAccessClusters =
            remoteClusters.some(cluster => cluster.purposeRemoteAccess);

          // If SPoG is deprecated via feature flag and no clusters have been
          // registered as remote access, SPoG functionality should be hidden.
          const isSpogDeprecatedAndUnused =
            $rootScope.FEATURE_FLAGS.spogDeprecated && !hasRemoteAccessClusters;

          $ctrl.remoteClustersList = remoteClusters;

          $ctrl.state.isSpogSupported =
            $rootScope.user.privs.CLUSTER_REMOTE_VIEW &&
            $rootScope.FEATURE_FLAGS.spogEnabled &&
            !isSpogDeprecatedAndUnused;
        }
      ).finally(function remoteClusterFinally() {
        $ctrl.state.loading = false;
      });
    }

    /**
     * Removes a connection between local and remote clusters.
     *
     * @method   removeRemoteCluster
     * @param    {Object}   cluster   The cluster to remove
     */
    function removeRemoteCluster(cluster) {
      RemoteClusterService.deleteRemoteCluster(cluster).then(
        function remoteClusterRemovedSuccessfully() {
          var index = $ctrl.remoteClustersList.indexOf(cluster);
          if (~index) {
            // remove the cluster from the list.
            $ctrl.remoteClustersList.splice(index, 1);
          }
        }
      );
    }
  }

})(angular);
