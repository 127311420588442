import { set } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: RDS Recovery search component

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.rdsRecovery')
    .controller('rdsRecoverySearchCtrl', rdsRecoverySearchCtrlFn)
    .component('rdsRecoverySearch', {
      bindings: {
        // @type {Boolean}  Is this recover flow
        isRecover: '<',
      },
      templateUrl:
        'app/protection/recovery/rds/rds.search.html',
      controller: 'rdsRecoverySearchCtrl',
    });

  /**
   * @ngdoc component
   * @name C.rdsRecoverySearch:rdsRecoverySearch
   * @function
   *
   * @description
   * Component to provide search functionality for Amazon RDS.
   */
  function rdsRecoverySearchCtrlFn(_, SearchService, DateTimeService,
    SourceService, cSearchService, ENV_TYPE_CONVERSION, evalAJAX, JobService,
    RecoveryStore) {
    var $ctrl = this;

    assign($ctrl, {
      searchEndpoint: SearchService.getSearchUrl('rds'),

      // Lifecycle hooks.
      $onInit: $onInit,

      //methods
      preProcessSearchResults: preProcessSearchResults,
    });

    /**
     * @method   $onInit
     *
     * Initializes component.
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();

      // Initialize default filters.
      $ctrl.shared.filters = [{
        property: 'registeredSourceIds',
        display: 'sourceName',
        transformFn: _transformSources,
        locked: false,
      }, {
        property: 'jobIds',
        display: 'protectionJob',
        transformFn: _transformJobs,
        locked: false,
      }, {
        property: 'fromTimeUsecs',
        display: 'startDate',
        transformFn: DateTimeService.dateToUsecs,
        locked: false,
      }, {
        property: 'toTimeUsecs',
        display: 'endDate',
        transformFn: DateTimeService.dateToUsecs,
        locked: false,
      }];

      _fetchDependencies();
      resetRDSSearch();
    }

    /**
     * Clears any old search result and resets the c-search filters
     *
     * @method   resetRDSSearch
     */
    function resetRDSSearch() {
      cSearchService.purgeResults($ctrl.shared.searchId);
      cSearchService.resetFilters($ctrl.shared.searchId, true);
    }

    /**
     * Processes the search results for the RDS.
     *
     * @method   preProcessSearchResults
     * @param    {object}   results   Object containing search results.
     * @return   {object}   Object containing decorated search results.
     */
    function preProcessSearchResults(results) {
      var results = SearchService.processVmsSearchResults(results);

      return results.filter(function removeJobs(result) {
        return result._type !== 'job';
      });
    }

    /**
     * Transforms Source objects into just IDs.
     *
     * @method   _transformSources
     * @param    {string[]}   selectedSourceNames   Array of source names.
     * @return   {number[]}   Array of source IDs.
     */
    function _transformSources(selectedSourceNames) {
      var lookups = $ctrl.filterLookups;
      var selectedSourceIDs = [];

      lookups.registeredSources.forEach(
        function fetchSelectedSource(source) {
          if (selectedSourceNames.includes(source._typeEntity.name)) {
            selectedSourceIDs.push(source.id);
          }
        }
      );

      return selectedSourceIDs;
    }

    /**
     * Transforms Job objects into just IDs.
     *
     * @method   _transformJobs
     * @param    {string[]}   selectedJobNames   Array of Job names
     * @return   {number[]}   Array of Job IDs.
     */
    function _transformJobs(selectedJobNames) {
      var lookups = $ctrl.filterLookups;
      var selectedJobIDs = [];

      lookups.jobs.forEach(
        function fetchSelectedViewBox(job) {
          if (selectedJobNames.includes(job.name)) {
            selectedJobIDs.push(job.jobId);
          }
        }
      );

      return selectedJobIDs;
    }

    /**
     * @method   _fetchDependencies
     *
     * Fetches the list of Jobs & Sources for RDS
     * and populates the filter look ups for c-search.
     */
    function _fetchDependencies() {
      // Fetch RDS Sources.
      SourceService.getEntitiesOfType({
        environmentTypes: ['kAWS'],
        awsEntityTypes: ['kIAMUser'],
      }).then(
        function successfulResponse(sources) {
          set($ctrl, 'shared.filterLookups.registeredSources', sources);
        }, evalAJAX.errorMessage
      );

      // Fetch RDS Jobs.
      JobService.getJobs({envTypes: [ENV_TYPE_CONVERSION.kRDSSnapshotManager]})
        .then(function successfulResponse(jobs) {
            set($ctrl, 'shared.filterLookups.jobs', jobs);
          }, evalAJAX.errorMessage
        );
    }
  }
})(angular);
