import { BackupRun, ObjectRunResult, ObjectSummary } from '@cohesity/api/v2';
import { Office365LeafNodeType } from '@cohesity/iris-shared-constants';
import { envGroups, Environment, HostTypes, ProtectionRunObjectStatus } from 'src/app/shared';
import { isRelationalDatabase, isValidHttpURL } from 'src/app/util';

import { ArchivalTarget } from './archival-target.models';
import { RunProgress } from './common.models';
import { ProtectionRunReplication } from './protection-run-replication.models';

/**
 * Data model for Protection Run Details page.
 */
export class ProtectionRunObject {
  /**
   * Object id.
   */
  id: number;

  /**
   * Specifies registered source id to which object belongs.
   */
  sourceId?: number;

  /**
   * Snapshot id.
   */
  snapshotId: string;

  /**
   * Name of Protection Object.
   */
  name: string;

  /**
   * Type of the object.
   */
  objectType: ObjectSummary['objectType'];

  /**
   * Environment of Protection Object.
   */
  environment: Environment;

  /**
   * OS Type of Protection Object.
   */
  osType?: HostTypes;

  /**
   * Start time of attempt in microseconds.
   */
  startTimeUsecs?: number;

  /**
   * Time at which the source snapshot was taken in microseconds.
   */
  snapshotCreationTime?: number;

  /**
   * Time at which the backup task was admitted to run in microseconds.
   */
  admittedTime?: number;

  /**
   * End time of attempt in microseconds.
   */
  endTimeUsecs?: number;

  /**
   * Duration of run.
   */
  duration?: number;

  /**
   * Status of snapshot.
   */
  status?: ProtectionRunObjectStatus;

  /**
   * Status message.
   */
  statusMessage?: string;

  /**
   * Size in bytes.
   * TODO: rethink this property name. Its too generic.
   */
  size?: number;

  /**
   * Data read in bytes.
   * TODO: rethink this property name. Its too generic.
   */
  dataRead?: number;

  /**
   * Data written in bytes.
   * TODO: rethink this property name. Its too generic.
   */
  dataWritten?: number;

  /**
   * Optional object name alias.
   */
  nameAlias?: string;

  /**
   * Latest failed attempt message about backup status for this object.
   */
  message: string;

  /**
   * Warning messages about this object. It seems the only use case is for NAS warnings.
   */
  warnings: string[];

  /**
   * Store `message` or first item in `warnings` array.
   */
  messageOrWarning: string;

  /**
   * Snapshot task ID for querying detailed status log.
   */
  progressTaskId: string;

  /**
   * Previously failed tasks IDs.
   */
  failedTasks: string[];

  /**
   * Run replications.
   */
  replications: ProtectionRunReplication[];

  /**
   * Specifies the expiry time of attempt in Unix epoch Timestamp (in microseconds) for an object.
   */
  expiryTimeUsecs: number;

  /**
   * Specifies if object's snapshot is on legal hold.
   */
  onLegalHold: boolean;

  /**
   * Indicates if snapshot is deleted.
   */
  isDeleted: boolean;

  /**
   * The total number of file and directory entities visited in this backup.
   * Only applicable to file based backups.
   */
  totalFileCount = 0;

  /**
   * The total number of file and directory entities that are backed up in this run.
   * Only applicable to file based backups.
   */
  backupFileCount = 0;

  /**
   * Check if legal hold can be applied to this object.
   */
  get canChangeLegalHold(): boolean {
    return Boolean(this.snapshotId) && this.status !== 'kFailed';
  }

  /**
   * Indicates if object status is in progress.
   */
  get isInProgress(): boolean {
    return [
      'Accepted',
      'Active',
      'Finalizing',
      'kCurrentAttemptResuming',
      'kInProgress',
      'kWaitingForNextAttempt',
      'kWaitingForOlderBackupRun',
      'Running'
    ].includes(this.status);
  }

  /**
   * Returns true if object environment is file-based.
   */
  get isFileBased(): boolean {
    return envGroups.nas.includes(this.environment);
  }

  /**
   * Specifies if the environment if Office365.
   */
  get isOffice365Workload(): boolean {
    return this.environment === Environment.kO365;
  }

  /**
   * Archival run progress info.
   */
  archivalProgress: RunProgress = {};

  /**
   * Local backup run progress info.
   */
  backupProgress: RunProgress = {};

  /**
   * Replication run progress info.
   */
  replicationProgress: RunProgress = {};

  /**
   * Indicates if object task is running.
   */
  isRunning?: boolean;

  /**
   * Array of objects that are children of parent object. Currently, only
   * applies to kPure.
   */
  childObjects: ObjectSummary[];

  /**
   * Specifies the object run start time of the latest attempt when multiple attempts are
   * made for backup run.
   */
   latestAttemptObjectRunStartTime?: number;

  /**
   * Constructor.
   *
   * @param   reference   Return data structure of Protection Group object from API
   * @param   isCloudArchivalDirect   Specifies if object is part of CAD run.
   */
  constructor(reference: ObjectRunResult, isCloudArchivalDirect = false) {
    this.transform(reference, isCloudArchivalDirect);
  }

  /**
   * Returns true if this object is of type database environment (except noSql environment).
   */
  isDb(): boolean {
    return isRelationalDatabase(this.environment);
  }

  /**
   * Compares object's snapshot expiry time with specified time.
   *
   * @param    currentTimeUsecs  Time to compare object's expiration time.
   * @returns  True if this object's snapshot has expired.
   */
  hasExpired(currentTimeUsecs: number): boolean {
    return !isNaN(this.expiryTimeUsecs) && this.expiryTimeUsecs < currentTimeUsecs;
  }

  /**
   * Parses API response object to this instance.
   *
   * @param  reference  API response protection run object instance.
   * @param  isCloudArchivalDirect  Specifies if object is part of CAD run.
   */
  private transform(reference: ObjectRunResult, isCloudArchivalDirect = false) {
    const {
      onLegalHold,
      object: {
        id,
        sourceId,
        environment,
        name,
        objectType,
        osType,
        childObjects,
      } = {},
      originalBackupInfo,
      localSnapshotInfo,
      archivalInfo: {
        archivalTargetResults = [],
      } = {},
      replicationInfo: {
        replicationTargetResults = []
      } = {}
    }: ObjectRunResult = reference;

    this.environment = environment as Environment;
    this.id = id;
    this.name = name;
    this.objectType = objectType;
    this.replications = (replicationTargetResults || []).map(
      rep => new ProtectionRunReplication(rep, this.environment, reference));
    this.sourceId = sourceId;
    this.osType = osType;
    this.childObjects = childObjects;

    const archivalTargets: ArchivalTarget[] = archivalTargetResults.map(target => new ArchivalTarget(target));

    if (isCloudArchivalDirect && archivalTargets.length) {
      const [{
        backupFileCount,
        bytesRead,
        durationMs: duration,
        endTimeUsecs,
        expiryTimeUsecs,
        isDeleted,
        physicalTransferred,
        message,
        size,
        snapshotId,
        startTimeUsecs,
        status,
        totalFileCount,
      }] = archivalTargets;

      this.dataRead = bytesRead;
      this.dataWritten = physicalTransferred;
      this.duration = duration;
      this.endTimeUsecs = endTimeUsecs;
      this.expiryTimeUsecs = expiryTimeUsecs;
      this.isDeleted = isDeleted;
      this.message = message;
      this.onLegalHold = onLegalHold;
      this.size = size;
      this.startTimeUsecs =  startTimeUsecs;
      this.latestAttemptObjectRunStartTime = startTimeUsecs;
      this.status = status;
      this.backupFileCount = backupFileCount;
      this.totalFileCount = totalFileCount;
      this.snapshotId = snapshotId;

      const [{
        progressTaskId,
      }] = archivalTargetResults;

      // File-based runs use progress monitor instead of run progress API
      this.progressTaskId = progressTaskId;

    } else {
      const backupSummary: BackupRun = originalBackupInfo || localSnapshotInfo || {};

      const {
        failedAttempts = [],
        snapshotInfo: {
          admittedTimeUsecs,
          permitGrantTimeUsecs,
          backupFileCount = 0,
          endTimeUsecs,
          expiryTimeUsecs,
          isManuallyDeleted,
          progressTaskId,
          snapshotId,
          startTimeUsecs,
          snapshotCreationTimeUsecs,
          status,
          statusMessage,
          totalFileCount = 0,
          warnings,
          stats: {
            logicalSizeBytes,
            bytesRead,
            bytesWritten
          } = {}
        } = {},
      }: BackupRun = backupSummary;

      this.progressTaskId = progressTaskId;
      this.snapshotId = snapshotId;
      this.status = status;
      this.statusMessage = statusMessage;
      this.warnings = warnings;
      this.admittedTime = admittedTimeUsecs;
      this.startTimeUsecs = permitGrantTimeUsecs || startTimeUsecs;
      this.latestAttemptObjectRunStartTime = startTimeUsecs;
      this.snapshotCreationTime = snapshotCreationTimeUsecs;
      this.endTimeUsecs = endTimeUsecs;
      this.expiryTimeUsecs = expiryTimeUsecs;

      if (endTimeUsecs) {
        this.duration = Math.round((endTimeUsecs - this.startTimeUsecs) / 1000);
      }

      this.size = logicalSizeBytes;
      this.dataRead = bytesRead;
      this.dataWritten = bytesWritten;
      this.onLegalHold = onLegalHold;
      this.isDeleted = isManuallyDeleted || expiryTimeUsecs === 0;

      this.backupFileCount = backupFileCount;
      this.totalFileCount = totalFileCount;

      if (Array.isArray(failedAttempts) && failedAttempts.length) {
        const len = failedAttempts.length;

        // display the latest failed attempt message
        if (status !== 'kSuccessful') {
          this.message = failedAttempts[len - 1].message;
        }

        // Override the object status, if the latest failed attempt status is Canceled
        // This is required to show canceled icon for canceled object
        if (status === 'kFailed' && failedAttempts[len - 1].status === 'Canceled') {
          this.status = 'kCanceled';
        }

        // store all failed attempts progress tasks IDs for progress monitor API
        this.failedTasks = failedAttempts
          .filter(attempt => attempt.progressTaskId !== this.progressTaskId)
          .map(failedTask => failedTask.progressTaskId);
      }

      this.isRunning = [
        'kCurrentAttemptPausing',
        'kCurrentAttemptResuming',
        'kInProgress',
        'kWaitingForNextAttempt',
        'kWaitingForOlderBackupRun'
      ].includes(this.status);

      if (this.isRunning) {
        this.duration = Math.max(0, new Date().getTime() * 1000 - this.startTimeUsecs) / 1000;
      }

      // For Office365 SharePoint site, capture the web url
      // since it is more important.
      if (this.isOffice365Workload &&
        objectType === Office365LeafNodeType.kSite &&
        isValidHttpURL(reference?.object?.sharepointSiteSummary?.siteWebUrl)) {
        this.nameAlias = new URL(reference.object.sharepointSiteSummary?.siteWebUrl)?.pathname;
      }
    }

    this.messageOrWarning = this.warnings?.length ? this.warnings[0] : this.message || '';
  }
}
