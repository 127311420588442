import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const ngReportAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngReport && (ctx.FEATURE_FLAGS.ngReportTenantView || !ctx.isHeliosTenantUser) &&
  getConfigByKey(ctx.irisContext, 'reports.features.allow', true);

const ngReportScheduleAccess = (ctx: StateAccessContext) => (
    ctx.FEATURE_FLAGS.ngReport &&
    ctx.FEATURE_FLAGS.ngReportSchedule &&
    (ctx.FEATURE_FLAGS.ngReportTenantView || !ctx.isHeliosTenantUser)
  );

const ngReportCustomizationAccess = (ctx: StateAccessContext) => (
    ctx.FEATURE_FLAGS.ngReport &&
    ctx.FEATURE_FLAGS.ngReportCustomization &&
    (ctx.FEATURE_FLAGS.ngReportTenantView || !ctx.isHeliosTenantUser)
  );

/**
 * Reports should not be visible for single cluster views
 */
const singleClusterState = 'dashboards';

export const ReportingConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'reporting.**',
      url: '^/reporting/',
      loadChildren: () => import('./reporting.module').then(m => m.ReportingModule),
    },
  ],

  allClusterMap: {
    'reporting.blank': {
      heliosOnly: false,
      globalContext: true,
      singleClusterState: 'reporting.blank',
      allClustersState: 'reporting.blank',
    },
    'reporting.list': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: singleClusterState,
      allClustersState: 'reporting.list',
    },
    'reporting.schedule': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: singleClusterState,
      allClustersState: 'reporting.schedule',
    },
    'reporting.activity': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: singleClusterState,
      allClustersState: 'reporting.activity',
    },
    'reporting.task': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: singleClusterState,
      allClustersState: 'reporting.task',
    },
    'reporting.add': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: singleClusterState,
      allClustersState: 'reporting.add',
    },
    'reporting.edit': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: singleClusterState,
      allClustersState: 'reporting.edit',
    },
    'reporting.detail': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: singleClusterState,
      allClustersState: 'reporting.detail',
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'reporting': ngReportAccess,
      'reporting.blank': ngReportAccess,
      'reporting.list': ngReportAccess,
      'reporting.schedule': ngReportScheduleAccess,
      'reporting.activity': ngReportScheduleAccess,
      'reporting.task': ngReportScheduleAccess,
      'reporting.detail': ngReportAccess,
      'reporting.add': ngReportCustomizationAccess,
      'reporting.edit': ngReportCustomizationAccess,
    };
  },
};
