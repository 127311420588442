<div class="protection-item" [ngClass]="{'no-padding-bottom no-border-bottom': readOnly}" cogFormGroup>
  <h3 *ngIf="title" class="protection-item-title" [class.no-margin-bottom]="readOnly">
    {{ title }}
  </h3>
  <div class="protection-item-content" [class.hidden]="loading">
    <!-- Render one protection item -->
    <ng-content></ng-content>
  </div>
  <cog-spinner *ngIf="loading"></cog-spinner>
</div>
