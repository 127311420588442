import { assign } from 'lodash-es';
// Controller: External targets settings page

;(function(angular, undefined) {
  'use strict';

  angular.module('C.externalTargets')
    .controller('externalTargetsSettingsController',
      externalTargetsSettingsControllerFn);

  function externalTargetsSettingsControllerFn(_, ExternalTargetService,
    FILESIZES, cMessage, evalAJAX) {

    var $ctrl = this;

    assign($ctrl, {
      $onInit: init,
      _bandwidthLimits: {
        archival: {},
        tiering: {},
      },
      bandwidthLimits: {
        archival: {},
        tiering: {},
      },
      getSetArchivalUploadRateLimit: getSetArchivalUploadRateLimit,
      getSetArchivalDownloadRateLimit: getSetArchivalDownloadRateLimit,
      getSetArchivalUploadThrottleData: getSetArchivalUploadThrottleData,
      getSetArchivalDownloadThrottleData: getSetArchivalDownloadThrottleData,
      getSetTieringUploadRateLimit: getSetTieringUploadRateLimit,
      getSetTieringDownloadRateLimit: getSetTieringDownloadRateLimit,
      getSetTieringUploadThrottleData: getSetTieringUploadThrottleData,
      getSetTieringDownloadThrottleData: getSetTieringDownloadThrottleData,
      loading: true,
      submitForm: submitForm,
    });

    var defaultBandwidthLimits = {
      bandwidthLimitOverrides: undefined,
      rateLimitBytesPerSec: 100 * FILESIZES.megabyte,
      timezone: undefined,
    };

    /**
     * initialize the page with data from server/default data
     *
     * @method   init
     */
    function init() {
      ExternalTargetService.getGlobalBandwidthSettings().then(
        function successCB(globalBandwidthLimits) {
          /**
           * The v1 API has been updated to include a new set of properties.
              {
                upload: {},             // existing archival upload
                download: {},           // existing archival download
                cloudTierUpload: {},    // new tiering upload
                cloudTierDownload: {},  // new tiering download
              }
          */
          Object.assign($ctrl.bandwidthLimits.archival, {
            upload: globalBandwidthLimits.upload,
            download: globalBandwidthLimits.download,
          });
          Object.assign(
            $ctrl.bandwidthLimits.tiering, {
              upload: globalBandwidthLimits.cloudTierUpload,
              download: globalBandwidthLimits.cloudTierDownload,
            }
          );
        }, evalAJAX.errorMessage
      ).finally(function finallyCB() {
        $ctrl.loading = false;
      });
    }

    /**
     * getter setter for setting rate limit on/off
     *
     * @method   _getSetRateLimitS
     * @param    {Boolean}  toSet                to set or get
     * @param    {String}   type         type = archival|tiering
     * @param    {String}   direction    direction = upload|download
     * @return   {Number}   The set rate limit.
     */
    function _getSetRateLimit(toSet, type, direction) {
      const _limitType = $ctrl._bandwidthLimits[type];
      const limitType = $ctrl.bandwidthLimits[type];

      // arguments list would be present if used as setter
      if (angular.isDefined(toSet)) {
        if (toSet) {
          // load the previous value if it exists, else load default
          limitType[direction] = _limitType[direction] || angular.copy(defaultBandwidthLimits);
        } else {
          // cache the current value
          _limitType[direction] = limitType[direction];
          limitType[direction] = undefined;
        }
      }

      return !!limitType && !!limitType[direction];
    }

    /**
     * getter setter for setting Archival upload rate limit on/off
     *
     * @method   getSetArchivalUploadRateLimit
     * @param    {Boolean}  toSet                to set or get
     * @return   {Number}   The set rate limit.
     */
    function getSetArchivalUploadRateLimit(toSet) {
      return _getSetRateLimit(toSet, 'archival', 'upload');
    }

    /**
     * getter setter for setting Archival download rate limit on/off
     *
     * @method   getSetArchivalUploadRateLimit
     * @param    {Boolean}  toSet        to set or get
     * @return   {Number}   The set rate limit.
     */
    function getSetArchivalDownloadRateLimit(toSet) {
      return _getSetRateLimit(toSet, 'archival', 'download');
    }

    /**
     * getter setter for setting Tiering upload rate limit on/off
     *
     * @method   getSetTieringUploadRateLimit
     * @param    {Boolean}  toSet                to set or get
     * @return   {Number}   The set rate limit.
     */
    function getSetTieringUploadRateLimit(toSet) {
      return _getSetRateLimit(toSet, 'tiering', 'upload');
    }

    /**
     * getter setter for setting Tiering download rate limit on/off
     *
     * @method   getSetTieringUploadRateLimit
     * @param    {Boolean}  toSet        to set or get
     * @return   {Number}   The set rate limit.
     */
    function getSetTieringDownloadRateLimit(toSet) {
      return _getSetRateLimit(toSet, 'tiering', 'download');
    }

    /**
     * getter setter for upload rate limit
     *
     * @method   getSetTieringUploadThrottleData
     * @param    {Boolean}  toSet        to set or get
     * @param    {String}   type         type = archival|tiering
     * @param    {String}   direction    direction = upload|download
     * @return   {Object}   The rate limit configuration.
     */
    function _getSetThrottleData(toSet, type, direction) {
      const _limitType = $ctrl._bandwidthLimits[type] || {};
      const limitType = $ctrl.bandwidthLimits[type] || {};

      // arguments list would be present if used as setter
      if (angular.isDefined(toSet)) {
        if (toSet) {
          // load the previous value if it exists, else load default
          limitType[direction].bandwidthLimitOverrides =
            (_limitType[direction] || {}).bandwidthLimitOverrides || [];
        } else {
          _limitType[direction] = _limitType[direction] || {};
          // cache the current value
          _limitType[direction].bandwidthLimitOverrides =
            limitType[direction].bandwidthLimitOverrides;
          limitType[direction].bandwidthLimitOverrides = undefined;
        }
      }

      return !!(limitType[direction] || {}).bandwidthLimitOverrides;
    }

    /**
     * getter setter for Archival upload rate limit
     *
     * @method   getSetArchivalDownloadThrottleData
     * @param    {Boolean}  toSet        to set or get
     * @return   {Object}   The rate limit configuration.
     */
    function getSetArchivalUploadThrottleData(toSet) {
      return _getSetThrottleData(toSet, 'archival', 'upload');
    }

    /**
     * getter setter for Archival download rate limit
     *
     * @method   getSetArchivalDownloadThrottleData
     * @param    {Boolean}  toSet        to set or get
     * @return   {Object}   The rate limit configuration.
     */
    function getSetArchivalDownloadThrottleData(toSet) {
      return _getSetThrottleData(toSet, 'archival', 'download');
    }

    /**
     * getter setter for Tiering upload rate limit
     *
     * @method   getSetTieringDownloadThrottleData
     * @param    {Boolean}  toSet        to set or get
     * @return   {Object}   The rate limit configuration.
     */
    function getSetTieringUploadThrottleData(toSet) {
      return _getSetThrottleData(toSet, 'tiering', 'upload');
    }

    /**
     * getter setter for Tiering download rate limit
     *
     * @method   getSetTieringDownloadThrottleData
     * @param    {Boolean}  toSet        to set or get
     * @return   {Object}   The rate limit configuration.
     */
    function getSetTieringDownloadThrottleData(toSet) {
      return _getSetThrottleData(toSet, 'tiering', 'download');
    }

    /**
     * submit form data to server
     *
     * @method   submitForm
     */
    function submitForm() {
      if ($ctrl.forms.externalTargetsSettingsForm.$invalid) {
        return;
      }

      // Transform to the updated v1 API.
      const params = {
        upload: $ctrl.bandwidthLimits.archival.upload,
        download: $ctrl.bandwidthLimits.archival.download,
        cloudTierUpload: $ctrl.bandwidthLimits.tiering.upload,
        cloudTierDownload: $ctrl.bandwidthLimits.tiering.download,
      };

      $ctrl.saving = true;
      ExternalTargetService
        .updateGlobalBandwidthSettings(params).then(
          function onSuccess() {
            cMessage.success({
              persist: false,
              titleKey: 'success',
              textKey: 'externalTargets.settings.successMsg',
            });
          }, evalAJAX.errorMessage
      ).finally(function finallyGotExternalTargets() {
        $ctrl.saving = false;
      });
    }
  }

})(angular);
