import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

// TODO: This guard redirects route from AJS policy page to Angular policy page
// Keeping this guard as is to ensure that any navigation referring to AJS policy state is properly routed
// Guard can be removed once we ensure that all state.go references using AJS states have been removed
// Check for following AngularJS states
// 1. policies (url: '/protection/policies')
// 2. policy (  url: '^/protection/policies/policy/{policyId}')

const ngPolicyState = 'protection-policy.details';
const nonNgPolicyState = 'policy';

/**
 * This guard listens for redirects to policy landing pages and forwards them to
 * the appropriate state.
 */
@Injectable({
  providedIn: 'root'
})
export class PolicyGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of
   * the main application after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Listen to all transitions to the policy state.
   */
  matchCriteria: any = {
    to: state => [ngPolicyState, nonNgPolicyState].includes(state.name),
  };

  /**
   * Redirect to policy with the params.
   *
   * @param   transition  The ui router transition
   * @returns Redirect to the correct policy page.
   */
  onStart(transition: Transition): GuardResult {
    const ngPolicyEnabled = true;
    const params = transition.params();
    const to = transition.to().name;

    if (to === nonNgPolicyState && ngPolicyEnabled && !params.force) {
      return transition.router.stateService.target(ngPolicyState, {
        ...params,
      });
    }

    // Follow the default behavior.
    return true;
  }
}
