import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FiltersComponent } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { debounceTime } from 'rxjs/operators';

import { SOURCE_TREE_CONTEXT, SourceTreeContext } from '../source-tree-context';
import { SourceTreeFilterComponent } from '../source-tree-filter/source-tree-filter.component';
import {
  ExcludeOptions,
  ExcludeResults,
  ExpandToOption,
  FilterOption,
  SourceTreeFilters,
  ViewFilterType,
} from '../source-tree-filters';

/**
 * Renders filter controls for a source tree.
 *
 * @example
 * <coh-source-tree-controls *ngIf="treeService" [filters]="filters"></coh-source-tree-controls>
 */
@Component({
  selector: 'coh-source-tree-controls',
  templateUrl: './source-tree-controls.component.html',
  styleUrls: ['./source-tree-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SourceTreeControlsComponent extends AutoDestroyable implements OnChanges, AfterViewInit, OnDestroy {
  /**
   * Expose ViewFilterTyp enum to the template.
   */
  ViewFilterType = ViewFilterType;

  /**
   * Show an expand to options menu button if set.
   */
  expandOptions: ExpandToOption<any>[];

  /**
   * Hold the value of selection option
   */
  selectedOption: string;

  /**
   * An instance of the filters class, which contains the filter config.
   */
  @Input() filters: SourceTreeFilters<any>;

  /**
   * Exclude options to be passed to exclude filters dialog
   */
  @Input() excludeOptions: ExcludeOptions;

  /**
   * Variable for whether to show the "Refresh" button for source tree.
   */
  @Input() showRefreshSourceTree: boolean;

  /**
   * If showRefreshSourceTree is set, we should also have the last refreshed time in order to
   * show the refresh time in the tooltip.
   */
  @Input() lastRefreshedUsecs: number;

  /**
   * If true, the actions (Show All, Expand To and Refresh) will be hidden
   * and toggle group will be differently spaced.
   */
  @Input() compactControls = false;

  /**
   * The component to render for exclude control
   */
  @Input() excludeComponent: Component;

  /**
   * Whether or not to show view filters.
   */
  @Input() showViewFilters = true;

  /**
   * Emits an event to refresh the source tree.
   */
  @Output() refreshSourceTree = new EventEmitter<undefined>();

  /**
   * Emit expand to event whenever a user selects the expand options.
   */
  @Output() expandTo = new EventEmitter<ExpandToOption<any>>();

  /**
   * Emit exclude results event when user closes the exclude filters dialog
   */
  @Output() excludeResults = new EventEmitter<ExcludeResults>();

  /**
   * Emit event to clear the current selection of objects.
   */
  @Output() clearSelection = new EventEmitter<void>();

  /**
   * Emit an event to clear the current selection of expand to dropdown objects.
   */
  @Output() clearExpandToSelection = new EventEmitter<any>();

  /**
   * The filters component
   */
  @ViewChild(FiltersComponent) filtersComponent: FiltersComponent;

  /**
   * The list of source tree filter components.
   */
  @ViewChildren(SourceTreeFilterComponent) sourceTreeFilters: QueryList<SourceTreeFilterComponent>;

  /**
   * Selected tag control
   */
  selectedTags = new UntypedFormControl([]);

  constructor(
    @Optional() @Inject(SOURCE_TREE_CONTEXT) private sourceTreeContext: SourceTreeContext,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Lock a filter value by id.
   *
   * @param id The id of the filter to lock.
   * @param lockMessage The lock message.
   */
  lockFilter(id: string, lockMessage: string) {
    this.filtersComponent.lockFilter(id, lockMessage);
  }

  /**
   * Unlock a filter value by id.
   *
   * @param id The id of the filter to unlock.
   */
  unlockFilter(id: string) {
    this.filtersComponent.unlockFilter(id);
  }

  /**
   * Manually set a filter value. Since the component tranforms the options into
   * cog filter values, we need to do some mapping heere to make sure we match
   * up the correct values.
   *
   * @param id         The id of the filter to set.
   * @param valueLabel The label for the option to select.
   */
  setFilterValue(id: string, valueLabel: any, forceSelection: boolean = false) {
    const filter = this.sourceTreeFilters.find(item => item.filterGroup.id === id);
    if (filter) {
      const filterValue = filter.filterValues.find(value => value.label === valueLabel);
      if (filterValue) {
        this.filtersComponent.setValue(id, [filterValue]);
      }
      filter.disableClear = forceSelection;
    }
  }

  ngAfterViewInit() {
    if (this.filtersComponent) {
      this.filtersComponent.filterValues$
        .pipe(this.untilDestroy(), debounceTime(300))
        .subscribe(filterValues => this.filters.setFilterValues(filterValues));
    }
  }

  /**
   * Update the filters based on the search control's value.
   */
  ngOnChanges() {
    this.cleanUpSubscriptions();

    this.filters.filterGroups$.pipe(this.untilDestroy()).subscribe(() => this.cdr.detectChanges());
    this.filters.selectedViewFilter$.pipe(this.untilDestroy()).subscribe(viewFilter => {
      this.expandOptions = viewFilter && viewFilter.expandToOptions;

      if (!viewFilter || !viewFilter.isTag) {
        // Reset the selected tags whenever a non-tag view filter is set
        this.filters.setSelectedTags([]);
        this.selectedTags.setValue([]);
      }
    });

    if (this.sourceTreeContext) {
      this.sourceTreeContext.searchQuery$ = this.filters.searchQuery$;
    }
  }

  ngOnDestroy(): void {
    this.filters.setFilterValues([]);
  }

  /**
   * Update the selected tags whenever the selected tags control is updated.
   */
  tagsChanged() {
    this.filters.setSelectedTags(this.selectedTags.value);
  }

  /**
   * Clears the current selection of objects if and only if a function by the
   * name of clearSelection is defined in the adapter's source tree service file.
   *
   * @param filterOption: This param is consumed by the setSelectedViewFilter of the SourceTreeFilters class.
   */
  clearSelectionAndSetNewFilter(filterOption: FilterOption) {
    this.clearSelection.emit();
    this.filters.setSelectedViewFilter(filterOption);
  }

  /**
   * Emits the selected option from dropdown and assigns it to selectedOption
   *
   * @param expandOption param holds the value of selected option from dropdown
   */
  onSelectOption(expandOption) {
    this.selectedOption = expandOption?.label;
    this.expandTo.emit(expandOption);
    this.clearExpandToSelection.emit(expandOption);
  }
 }
