/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FortknoxOnpremPrimaryClusters } from '../models/fortknox-onprem-primary-clusters';
import { FortknoxOnpremPrimaryCluster } from '../models/fortknox-onprem-primary-cluster';
import { CreateOrUpdateFortknoxOnpremPrimaryClusterParams } from '../models/create-or-update-fortknox-onprem-primary-cluster-params';
import { ClusterIdentifier } from '../models/cluster-identifier';
import { InitPairingParams } from '../models/init-pairing-params';
import { IsVaultClusterParams } from '../models/is-vault-cluster-params';
import { PrecheckOptions } from '../models/precheck-options';
import { FortknoxOnpremVaultClusters } from '../models/fortknox-onprem-vault-clusters';
import { FortknoxOnpremVaultCluster } from '../models/fortknox-onprem-vault-cluster';
import { CreateOrUpdateFortknoxOnpremVaultClusterParams } from '../models/create-or-update-fortknox-onprem-vault-cluster-params';
@Injectable({
  providedIn: 'root',
})
class FortknoxOnpremService extends __BaseService {
  static readonly GetFortknoxOnpremPrimaryClustersPath = '/fortknox-onprem-primary-clusters';
  static readonly RegisterFortknoxOnpremPrimaryClusterPath = '/fortknox-onprem-primary-clusters';
  static readonly InitPairingFortknoxOnpremClusterPath = '/fortknox-onprem-clusters/init-pairing';
  static readonly GetIsVaultClusterPath = '/fortknox-onprem-clusters/is-vault-cluster';
  static readonly UpdateIsVaultClusterPath = '/fortknox-onprem-clusters/is-vault-cluster';
  static readonly ListFortknoxVaultPrechecksPath = '/fortknox-onprem-clusters/precheck-options';
  static readonly GetFortknoxOnpremPrimaryClusterByIdPath = '/fortknox-onprem-primary-clusters/{clusterId}';
  static readonly UpdateFortknoxOnpremPrimaryClusterPath = '/fortknox-onprem-primary-clusters/{clusterId}';
  static readonly DeleteFortknoxOnpremPrimaryClusterPath = '/fortknox-onprem-primary-clusters/{clusterId}';
  static readonly GetFortknoxOnpremVaultClustersPath = '/fortknox-onprem-vault-clusters';
  static readonly RegisterFortknoxOnpremVaultClusterPath = '/fortknox-onprem-vault-clusters';
  static readonly GetFortknoxOnpremVaultClusterByIdPath = '/fortknox-onprem-vault-clusters/{clusterId}';
  static readonly UpdateFortknoxOnpremVaultClusterPath = '/fortknox-onprem-vault-clusters/{clusterId}';
  static readonly DeleteFortknoxOnpremVaultClusterPath = '/fortknox-onprem-vault-clusters/{clusterId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all registered Fortknox Onprem Primary Clusters.
   *
   * List the Fortknox Onprem Primary Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremPrimaryClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterNames`: Specifies a list of Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremPrimaryClustersResponse(params: FortknoxOnpremService.GetFortknoxOnpremPrimaryClustersParams): __Observable<__StrictHttpResponse<FortknoxOnpremPrimaryClusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterNames || []).forEach(val => {if (val != null) __params = __params.append('clusterNames', val.toString())});
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-primary-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremPrimaryClusters>;
      })
    );
  }
  /**
   * Get all registered Fortknox Onprem Primary Clusters.
   *
   * List the Fortknox Onprem Primary Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremPrimaryClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterNames`: Specifies a list of Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremPrimaryClusters(params: FortknoxOnpremService.GetFortknoxOnpremPrimaryClustersParams): __Observable<FortknoxOnpremPrimaryClusters> {
    return this.GetFortknoxOnpremPrimaryClustersResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremPrimaryClusters)
    );
  }

  /**
   * Register a Fortknox Onprem Primary Cluster.
   *
   * Register the Fortknox Onprem Primary Cluster on this cluster. This API can only be called on the Vault Clusters and the Vault Cluster will be registered on the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.RegisterFortknoxOnpremPrimaryClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register a Fortknox Onprem Primary Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterFortknoxOnpremPrimaryClusterResponse(params: FortknoxOnpremService.RegisterFortknoxOnpremPrimaryClusterParams): __Observable<__StrictHttpResponse<FortknoxOnpremPrimaryCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fortknox-onprem-primary-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremPrimaryCluster>;
      })
    );
  }
  /**
   * Register a Fortknox Onprem Primary Cluster.
   *
   * Register the Fortknox Onprem Primary Cluster on this cluster. This API can only be called on the Vault Clusters and the Vault Cluster will be registered on the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.RegisterFortknoxOnpremPrimaryClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register a Fortknox Onprem Primary Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterFortknoxOnpremPrimaryCluster(params: FortknoxOnpremService.RegisterFortknoxOnpremPrimaryClusterParams): __Observable<FortknoxOnpremPrimaryCluster> {
    return this.RegisterFortknoxOnpremPrimaryClusterResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremPrimaryCluster)
    );
  }

  /**
   * Initialize the pairing of a Fortknox Onprem Cluster.
   *
   * Initialize the pairing of Fortknox Onprem cluster. This API can only be called on the Vault Clusters. This API will exchange CA certs and fetch the API key of the primary cluster.
   * @param params The `FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to initialize the pairing of a Fortknox Onprem Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InitPairingFortknoxOnpremClusterResponse(params: FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams): __Observable<__StrictHttpResponse<ClusterIdentifier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fortknox-onprem-clusters/init-pairing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterIdentifier>;
      })
    );
  }
  /**
   * Initialize the pairing of a Fortknox Onprem Cluster.
   *
   * Initialize the pairing of Fortknox Onprem cluster. This API can only be called on the Vault Clusters. This API will exchange CA certs and fetch the API key of the primary cluster.
   * @param params The `FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to initialize the pairing of a Fortknox Onprem Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InitPairingFortknoxOnpremCluster(params: FortknoxOnpremService.InitPairingFortknoxOnpremClusterParams): __Observable<ClusterIdentifier> {
    return this.InitPairingFortknoxOnpremClusterResponse(params).pipe(
      __map(_r => _r.body as ClusterIdentifier)
    );
  }

  /**
   * Get whether the cluster is a vault cluster.
   *
   * Get whether the cluster is a vault cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIsVaultClusterResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<IsVaultClusterParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-clusters/is-vault-cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IsVaultClusterParams>;
      })
    );
  }
  /**
   * Get whether the cluster is a vault cluster.
   *
   * Get whether the cluster is a vault cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIsVaultCluster(regionId?: string,
    accessClusterId?: number): __Observable<IsVaultClusterParams> {
    return this.GetIsVaultClusterResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as IsVaultClusterParams)
    );
  }

  /**
   * Update whether the cluster is a vault cluster.
   *
   * Update whether the cluster is a vault cluster. If a cluster is already vault cluster, it cannot be deconfigured.
   * @param params The `FortknoxOnpremService.UpdateIsVaultClusterParams` containing the following parameters:
   *
   * - `body`: Params to update whether the cluster is a vault cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIsVaultClusterResponse(params: FortknoxOnpremService.UpdateIsVaultClusterParams): __Observable<__StrictHttpResponse<IsVaultClusterParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/fortknox-onprem-clusters/is-vault-cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IsVaultClusterParams>;
      })
    );
  }
  /**
   * Update whether the cluster is a vault cluster.
   *
   * Update whether the cluster is a vault cluster. If a cluster is already vault cluster, it cannot be deconfigured.
   * @param params The `FortknoxOnpremService.UpdateIsVaultClusterParams` containing the following parameters:
   *
   * - `body`: Params to update whether the cluster is a vault cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIsVaultCluster(params: FortknoxOnpremService.UpdateIsVaultClusterParams): __Observable<IsVaultClusterParams> {
    return this.UpdateIsVaultClusterResponse(params).pipe(
      __map(_r => _r.body as IsVaultClusterParams)
    );
  }

  /**
   * Get all precheck options required for vault configuration.
   *
   * List the Fortknox Onprem options which are prechecks for configuring fortknox onprem vault.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFortknoxVaultPrechecksResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<PrecheckOptions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-clusters/precheck-options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrecheckOptions>;
      })
    );
  }
  /**
   * Get all precheck options required for vault configuration.
   *
   * List the Fortknox Onprem options which are prechecks for configuring fortknox onprem vault.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFortknoxVaultPrechecks(regionId?: string,
    accessClusterId?: number): __Observable<PrecheckOptions> {
    return this.ListFortknoxVaultPrechecksResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as PrecheckOptions)
    );
  }

  /**
   * Get a Fortknox Onprem Primary Cluster by id.
   *
   * Get a Fortknox Onprem Primary Cluster by cluster id.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremPrimaryClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Fortknox Onprem Primary Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremPrimaryClusterByIdResponse(params: FortknoxOnpremService.GetFortknoxOnpremPrimaryClusterByIdParams): __Observable<__StrictHttpResponse<FortknoxOnpremPrimaryCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-primary-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremPrimaryCluster>;
      })
    );
  }
  /**
   * Get a Fortknox Onprem Primary Cluster by id.
   *
   * Get a Fortknox Onprem Primary Cluster by cluster id.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremPrimaryClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Fortknox Onprem Primary Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremPrimaryClusterById(params: FortknoxOnpremService.GetFortknoxOnpremPrimaryClusterByIdParams): __Observable<FortknoxOnpremPrimaryCluster> {
    return this.GetFortknoxOnpremPrimaryClusterByIdResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremPrimaryCluster)
    );
  }

  /**
   * Update the registration of a Fortknox Onprem Primary Cluster.
   *
   * Update the registration of the Fortknox Onprem Primary Cluster specified by the cluster id. This API can only be called on the Vault Clusters. The updates will be relayed to the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.UpdateFortknoxOnpremPrimaryClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Primary Cluster to update.
   *
   * - `body`: Specifies the request to update Fortknox Onprem Primary Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFortknoxOnpremPrimaryClusterResponse(params: FortknoxOnpremService.UpdateFortknoxOnpremPrimaryClusterParams): __Observable<__StrictHttpResponse<FortknoxOnpremPrimaryCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/fortknox-onprem-primary-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremPrimaryCluster>;
      })
    );
  }
  /**
   * Update the registration of a Fortknox Onprem Primary Cluster.
   *
   * Update the registration of the Fortknox Onprem Primary Cluster specified by the cluster id. This API can only be called on the Vault Clusters. The updates will be relayed to the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.UpdateFortknoxOnpremPrimaryClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Primary Cluster to update.
   *
   * - `body`: Specifies the request to update Fortknox Onprem Primary Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFortknoxOnpremPrimaryCluster(params: FortknoxOnpremService.UpdateFortknoxOnpremPrimaryClusterParams): __Observable<FortknoxOnpremPrimaryCluster> {
    return this.UpdateFortknoxOnpremPrimaryClusterResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremPrimaryCluster)
    );
  }

  /**
   * Unregister an Fortknox Onprem Primary Cluster.
   *
   * Unregister a Fortknox Onprem Primary Cluster. This API can only be called on the Vault Clusters. The Vault Cluster will be removed from the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.DeleteFortknoxOnpremPrimaryClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Primary Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteFortknoxOnpremPrimaryClusterResponse(params: FortknoxOnpremService.DeleteFortknoxOnpremPrimaryClusterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/fortknox-onprem-primary-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister an Fortknox Onprem Primary Cluster.
   *
   * Unregister a Fortknox Onprem Primary Cluster. This API can only be called on the Vault Clusters. The Vault Cluster will be removed from the Primary Cluster automatically.
   * @param params The `FortknoxOnpremService.DeleteFortknoxOnpremPrimaryClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Primary Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteFortknoxOnpremPrimaryCluster(params: FortknoxOnpremService.DeleteFortknoxOnpremPrimaryClusterParams): __Observable<null> {
    return this.DeleteFortknoxOnpremPrimaryClusterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all registered Fortknox Onprem Vault Clusters.
   *
   * List the Fortknox Onprem Vault Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremVaultClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterNames`: Specifies a list of Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremVaultClustersResponse(params: FortknoxOnpremService.GetFortknoxOnpremVaultClustersParams): __Observable<__StrictHttpResponse<FortknoxOnpremVaultClusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.clusterNames || []).forEach(val => {if (val != null) __params = __params.append('clusterNames', val.toString())});
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-vault-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremVaultClusters>;
      })
    );
  }
  /**
   * Get all registered Fortknox Onprem Vault Clusters.
   *
   * List the Fortknox Onprem Vault Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremVaultClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `clusterNames`: Specifies a list of Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremVaultClusters(params: FortknoxOnpremService.GetFortknoxOnpremVaultClustersParams): __Observable<FortknoxOnpremVaultClusters> {
    return this.GetFortknoxOnpremVaultClustersResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremVaultClusters)
    );
  }

  /**
   * Register a Fortknox Onprem Vault Cluster.
   *
   * Register the Fortknox Onprem Vault Cluster on this cluster. This API can only be called on the Primary Clusters and only be called from the Vault clusters.
   * @param params The `FortknoxOnpremService.RegisterFortknoxOnpremVaultClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register a Fortknox Onprem Vault Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterFortknoxOnpremVaultClusterResponse(params: FortknoxOnpremService.RegisterFortknoxOnpremVaultClusterParams): __Observable<__StrictHttpResponse<FortknoxOnpremVaultCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fortknox-onprem-vault-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremVaultCluster>;
      })
    );
  }
  /**
   * Register a Fortknox Onprem Vault Cluster.
   *
   * Register the Fortknox Onprem Vault Cluster on this cluster. This API can only be called on the Primary Clusters and only be called from the Vault clusters.
   * @param params The `FortknoxOnpremService.RegisterFortknoxOnpremVaultClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register a Fortknox Onprem Vault Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterFortknoxOnpremVaultCluster(params: FortknoxOnpremService.RegisterFortknoxOnpremVaultClusterParams): __Observable<FortknoxOnpremVaultCluster> {
    return this.RegisterFortknoxOnpremVaultClusterResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremVaultCluster)
    );
  }

  /**
   * Get a Fortknox Onprem Vault Cluster by id.
   *
   * Get a Fortknox Onprem Vault Cluster by cluster id.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremVaultClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Fortknox Onprem Vault Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremVaultClusterByIdResponse(params: FortknoxOnpremService.GetFortknoxOnpremVaultClusterByIdParams): __Observable<__StrictHttpResponse<FortknoxOnpremVaultCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fortknox-onprem-vault-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremVaultCluster>;
      })
    );
  }
  /**
   * Get a Fortknox Onprem Vault Cluster by id.
   *
   * Get a Fortknox Onprem Vault Cluster by cluster id.
   * @param params The `FortknoxOnpremService.GetFortknoxOnpremVaultClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Fortknox Onprem Vault Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFortknoxOnpremVaultClusterById(params: FortknoxOnpremService.GetFortknoxOnpremVaultClusterByIdParams): __Observable<FortknoxOnpremVaultCluster> {
    return this.GetFortknoxOnpremVaultClusterByIdResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremVaultCluster)
    );
  }

  /**
   * Update the registration of a Fortknox Onprem Vault Cluster.
   *
   * Update the registration of the Fortknox Onprem Vault Cluster specified by the cluster id. This API can only be called on the Primary Clusters and only be called from the Vault clusters.
   * @param params The `FortknoxOnpremService.UpdateFortknoxOnpremVaultClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Vault Cluster to update.
   *
   * - `body`: Specifies the request to update Fortknox Onprem Vault Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFortknoxOnpremVaultClusterResponse(params: FortknoxOnpremService.UpdateFortknoxOnpremVaultClusterParams): __Observable<__StrictHttpResponse<FortknoxOnpremVaultCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/fortknox-onprem-vault-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortknoxOnpremVaultCluster>;
      })
    );
  }
  /**
   * Update the registration of a Fortknox Onprem Vault Cluster.
   *
   * Update the registration of the Fortknox Onprem Vault Cluster specified by the cluster id. This API can only be called on the Primary Clusters and only be called from the Vault clusters.
   * @param params The `FortknoxOnpremService.UpdateFortknoxOnpremVaultClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Vault Cluster to update.
   *
   * - `body`: Specifies the request to update Fortknox Onprem Vault Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFortknoxOnpremVaultCluster(params: FortknoxOnpremService.UpdateFortknoxOnpremVaultClusterParams): __Observable<FortknoxOnpremVaultCluster> {
    return this.UpdateFortknoxOnpremVaultClusterResponse(params).pipe(
      __map(_r => _r.body as FortknoxOnpremVaultCluster)
    );
  }

  /**
   * Unregister an Fortknox Onprem Vault Cluster.
   *
   * Unregister a Fortknox Onprem Vault Cluster. This API can only be called on the Primary Clusters and only be called from the Vault clusters.
   * @param params The `FortknoxOnpremService.DeleteFortknoxOnpremVaultClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Vault Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteFortknoxOnpremVaultClusterResponse(params: FortknoxOnpremService.DeleteFortknoxOnpremVaultClusterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/fortknox-onprem-vault-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister an Fortknox Onprem Vault Cluster.
   *
   * Unregister a Fortknox Onprem Vault Cluster. This API can only be called on the Primary Clusters and only be called from the Vault clusters.
   * @param params The `FortknoxOnpremService.DeleteFortknoxOnpremVaultClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Fortknox Onprem Vault Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteFortknoxOnpremVaultCluster(params: FortknoxOnpremService.DeleteFortknoxOnpremVaultClusterParams): __Observable<null> {
    return this.DeleteFortknoxOnpremVaultClusterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FortknoxOnpremService {

  /**
   * Parameters for GetFortknoxOnpremPrimaryClusters
   */
  export interface GetFortknoxOnpremPrimaryClustersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of Cluster names to filter.
     */
    clusterNames?: Array<string>;

    /**
     * Specifies a list of Cluster ids to filter.
     */
    clusterIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterFortknoxOnpremPrimaryCluster
   */
  export interface RegisterFortknoxOnpremPrimaryClusterParams {

    /**
     * Specifies the request to register a Fortknox Onprem Primary Cluster.
     */
    body: CreateOrUpdateFortknoxOnpremPrimaryClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InitPairingFortknoxOnpremCluster
   */
  export interface InitPairingFortknoxOnpremClusterParams {

    /**
     * Specifies the request to initialize the pairing of a Fortknox Onprem Cluster.
     */
    body: InitPairingParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIsVaultCluster
   */
  export interface UpdateIsVaultClusterParams {

    /**
     * Params to update whether the cluster is a vault cluster.
     */
    body: IsVaultClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFortknoxOnpremPrimaryClusterById
   */
  export interface GetFortknoxOnpremPrimaryClusterByIdParams {

    /**
     * Specifies the cluster id of Fortknox Onprem Primary Cluster to fetch.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateFortknoxOnpremPrimaryCluster
   */
  export interface UpdateFortknoxOnpremPrimaryClusterParams {

    /**
     * Specifies the cluster id of the Fortknox Onprem Primary Cluster to update.
     */
    clusterId: number;

    /**
     * Specifies the request to update Fortknox Onprem Primary Cluster config.
     */
    body: CreateOrUpdateFortknoxOnpremPrimaryClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteFortknoxOnpremPrimaryCluster
   */
  export interface DeleteFortknoxOnpremPrimaryClusterParams {

    /**
     * Specifies the cluster id of the Fortknox Onprem Primary Cluster to unregister.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFortknoxOnpremVaultClusters
   */
  export interface GetFortknoxOnpremVaultClustersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of Cluster names to filter.
     */
    clusterNames?: Array<string>;

    /**
     * Specifies a list of Cluster ids to filter.
     */
    clusterIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterFortknoxOnpremVaultCluster
   */
  export interface RegisterFortknoxOnpremVaultClusterParams {

    /**
     * Specifies the request to register a Fortknox Onprem Vault Cluster.
     */
    body: CreateOrUpdateFortknoxOnpremVaultClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFortknoxOnpremVaultClusterById
   */
  export interface GetFortknoxOnpremVaultClusterByIdParams {

    /**
     * Specifies the cluster id of Fortknox Onprem Vault Cluster to fetch.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateFortknoxOnpremVaultCluster
   */
  export interface UpdateFortknoxOnpremVaultClusterParams {

    /**
     * Specifies the cluster id of the Fortknox Onprem Vault Cluster to update.
     */
    clusterId: number;

    /**
     * Specifies the request to update Fortknox Onprem Vault Cluster config.
     */
    body: CreateOrUpdateFortknoxOnpremVaultClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteFortknoxOnpremVaultCluster
   */
  export interface DeleteFortknoxOnpremVaultClusterParams {

    /**
     * Specifies the cluster id of the Fortknox Onprem Vault Cluster to unregister.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { FortknoxOnpremService }
