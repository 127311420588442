import { find } from 'lodash-es';
import { get } from 'lodash-es';


// MODULE: External Targets

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.externalTargets', ['C.throttleOverrides', 'C.kmsSelector'])
    .config(externalTargetConfigFn)
    .controller('externalTargetsDefaultController',
      externalTargetsDefaultControllerFn)
    .controller('externalTargetsParentController',
      externalTargetsParentControllerFn);

  function externalTargetConfigFn($stateProvider) {
    $stateProvider
      .state('external-targets', {
        url: '/protection/external',
        help: 'platform_external',
        canAccess: 'CLUSTER_EXTERNAL_TARGET_VIEW',
        controller: 'externalTargetsDefaultController',
        templateUrl: 'app/platform/external/targets.html',
      })
      .state('external-targets.list', {
        url: '^/protection/external/list',
        help: 'platform_external',
        title: 'External Targets',
        canAccess: 'CLUSTER_EXTERNAL_TARGET_VIEW',
        parentState: 'external-targets',
        views: {
          'tab-view': {
            controller: 'externalTargetsParentController',
            templateUrl: 'app/platform/external/targets-list.html',
          },
        },
      })
      .state('external-targets-new', {
        url: '^/protection/external/new',
        help: 'platform_external_new',
        title: 'Create New External Target',
        canAccess: 'CLUSTER_EXTERNAL_TARGET_MODIFY',
        parentState: 'external-targets',
        templateUrl: 'app/platform/external/target-modify.html',
        controller: 'externalTargetDetailController',
      })
      .state('external-targets-edit', {
        url: '^/protection/external/edit/{id}',
        help: 'platform_external',
        title: 'Edit External Target',
        canAccess: 'CLUSTER_EXTERNAL_TARGET_MODIFY',
        parentState: 'external-targets',
        params: {
          id: { type: 'string' },
        },
        templateUrl: 'app/platform/external/target-modify.html',
        controller: 'externalTargetDetailController',
        parentState: 'external-targets',
      })
      .state('external-targets-view', {
        url: '^/protection/external/view/{id}',
        help: 'platform_external',
        title: 'View External Target',
        canAccess: 'CLUSTER_EXTERNAL_TARGET_VIEW',
        parentState: 'external-targets',
        params: {
          id: { type: 'string' },
        },
        templateUrl: 'app/platform/external/target.html',
        controller: 'externalTargetDetailController',
        parentState: 'external-targets',
      })
      .state('external-targets.settings', {
        url: '^/protection/external/settings',
        help: 'platform_external',
        title: 'External Targets Settings',
        canAccess: 'CLUSTER_VIEW',
        parentState: 'external-targets',
        views: {
          'tab-view': {
            controller: 'externalTargetsSettingsController',
            templateUrl: 'app/platform/external/settings.html',
            controllerAs: '$ctrl',
          },
        },
      });
  }

  function externalTargetsDefaultControllerFn($rootScope, $scope, $state, FEATURE_FLAGS) {

    $scope.externalTargetsTabsConfig = [
      {
        'headingKey': 'targets',
        'route': 'external-targets.list',
        'default': true,
      }
    ];

    if (!FEATURE_FLAGS.ngCloudEditionEnabled) {
      $scope.externalTargetsTabsConfig.push({
        'headingKey': 'settings',
        'route': 'external-targets.settings',
      });
    }

    if ($state.current.name === 'external-targets') {
      gotoDefaultTab($scope.externalTargetsTabsConfig);
    }

    function gotoDefaultTab(tabsConfig) {
      /*
       * tells wheather tab is marked default or not.
       *
       * @method  isDefaultTab
       * @param   {object}    tab   tab config object
       * @return  {boolean}         true if tab is marked default else false
       */
      function isDefaultTab(tab) { return !!tab.default; }

      var defaultTab = tabsConfig.find(isDefaultTab);

      if (defaultTab) { $state.go(defaultTab.route); }
    }

  }

  function externalTargetsParentControllerFn(_, $scope, $state, evalAJAX,
    ExternalTargetService, SlideModalService, FEATURE_FLAGS, cMessage,
    EXTERNAL_TARGET_USAGE_TYPE_STRINGS, NgStorageDomainsService) {

    $scope.externalTargets = [];
    $scope.targetsReady = false;
    var viewBoxesList = [];

    /**
     * Fetches the display string for usageType based on it's type
     *
     * @method     getUsageTypeDisplayString
     * @param      {String}  usageType  ['kArchival', 'kCloudSpill']
     * @return     {String}             The string to display
     */
    $scope.getUsageTypeDisplayString =
      function getUsageTypeDisplayString(usageType) {

        var kType = (/spill/i.test(usageType)) ? 'kCloudSpill' : 'kArchival';
        return EXTERNAL_TARGET_USAGE_TYPE_STRINGS[kType];
      };

    /**
     * Initializes the data calls
     */
    function init() {
      if (FEATURE_FLAGS.ngCloudEditionEnabled) {
        NgStorageDomainsService.getStorageDomainList({}).toPromise()
          .then(function onSuccess(response) {
            viewBoxesList = get(response, 'storageDomains');
        })
      }
      getExternalTargets();
    }

    /**
     * Get a single storage domain
     *
     * @method   getViewBoxById
     * @param    {int}   id   The storage domain ID
     * @return  {string} Storage domain name
     */
    $scope.getViewBoxById = function getViewBoxById(id) {
      return get(find(viewBoxesList, function(obj) {
        return obj.id === id;
      }), 'name');
    }

    /**
     * Get a list of all external targets
     */
    function getExternalTargets() {
      // Reset externalTargets
      $scope.getExternalTargets = [];
      ExternalTargetService.getTargets().then(
        function getTargetsSuccess(targets) {
          targets.forEach(function eachTarget(target) {
            target._actionItems = [{
              translateKey: 'edit',
              icon: 'icn-edit',
              id: 'edit-target-anchor',
              action: function editTarget() {
                $state.go('external-targets-edit', {id: target.id});
              }
            }];

            if (FEATURE_FLAGS.unregisterVault &&
              !FEATURE_FLAGS.ngCloudEditionEnabled &&
              target.usageType === 'kArchival' &&
                target.removalState === 'kDontRemove') {

                target._actionItems.push({
                translateKey: 'unregister',
                icon: 'icn-delete',
                id: 'unregister-target-anchor',
                action: function unregisterTarget() {
                  var modalConfig = {
                    size: 'md',
                    resolve: {
                      innerComponent: 'unregisterTargetModal',
                      actionButtonKey: false,
                      closeButtonKey: false,
                      titleKey: false,
                      bindings: {
                        target: target,
                      },
                    },
                    autoHeight: true,
                  };

                  return SlideModalService.newModal(modalConfig)
                    .then(function modalClosed() {
                      $state.reload();
                    });
                }
              });
            }
          })

          $scope.externalTargets = targets;
        }).finally(
          function afterGetTargets() {
            $scope.targetsReady = true;
          }
        );

    }

    /**
     * Get the url for measuring the progress of unregistered of vaults
     *
     * @param  {Object}  target  The target object being unregistered
     * @return {String}} url for progress
     */
    $scope.getProgressUrl = function getProgressUrl(target) {
      return [
        'progressMonitors?taskPathVec=',
        'vault_unregisteration_', target.id,
        '&includeFinishedTasks=true&excludeSubTasks=false',
      ].join('');
    }

    /**
     * Trigger unregistration of a target
     *
     * @method  unregisterTarget
     * @param   {object}  target  The target object to be unregistered
     * @param   {boolean} force   Should "force unregister" flag be enabled
     *                            in API request
     */
    $scope.unregisterTarget = function(target, force) {
      target.deleteVaultError = undefined;

      var apiData = !!force ? {forceDelete: true} : {retry: true};

      ExternalTargetService.unregisterTarget(target.id, apiData)
        .then(function unregisterTargetSuccess() {
          cMessage.success({
            textKey: 'externalTargets.unregistrationRetriggered',
            textKeyContext: {name: target.name},
          });
        }, evalAJAX.errorMessage);
    }

    // Begin the Begin
    init();
  }

})(angular);
