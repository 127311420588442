import { Injectable } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';
import { from, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * Angular replacement for legacy ClusterService service.
 * Some methods are pass-through for now and will be replaced.
 */
@Injectable({
  providedIn: 'root'
})
export class AjsClusterService {

  /**
   * Legacy ClusterService AngularJS service.
   */
  private ajsClusterService: any;

  /**
   * Legacy clusterState AngularJS value.
   */
  private _clusterState: any;

  constructor(
    private ajsUpgrade: AjsUpgradeService,
    private http: HttpClient
  ) {
    this.ajsClusterService = ajsUpgrade.get('ClusterService');
    this._clusterState = ajsUpgrade.get('clusterState');
  }

  /**
   * Returns Cluster State object.
   */
  get clusterState(): any {
    return this._clusterState;
  }

  /**
   * Returns cluster info object.
   */
  get clusterInfo(): any {
    return this.ajsClusterService.clusterInfo;
  }

  /**
   * Returns cluster basic info object.
   */
  get basicClusterInfo(): any {
    return this.ajsClusterService.basicClusterInfo;
  }

  /**
   * Returns true if the cluster is new cluster.
   */
  isNewCluster(): boolean {
    return this.ajsClusterService.isNewCluster();
  }

  /**
   * Returns observable of boolean. True if cluster creation is in progress.
   */
  isClusterCreateInProgress(): Observable<any> {
    return from(this.ajsClusterService.isClusterCreateInProgress());
  }

  /**
   * Update cluster setting.
   *
   * @param   clusterInfo   Cluster information.
   */
  updateSettings(clusterInfo): Observable<any> {
    return of(this.ajsClusterService.updateClusterInfo(clusterInfo));
  }

  /**
   * Update License state.
   *
   * @param   state   License state.
   * @deprecated Use updateLicenseState from Angular Cluster service
   */
  updateLicenseState(state: string): Observable<any> {
    return of(this.ajsClusterService.updateLicenseState(state));
  }

  /**
   * Skip Licensing.
   */
  skipLicensing(): void {
    this.ajsClusterService.skipLicensing();
  }

  /**
   * Update Root With License Success.
   */
  updateRootWithLicenseSuccess(): void {
    this.ajsClusterService.updateRootWithLicenseSuccess();
  }

  /**
   * Download filtered cluster audit logs.
   *
   * TODO (David) remove this function after csv download is supported in v2
   * audit-logs API.
   *
   * @param   params   getAuditLogs request object.
   */
  downloadCsv(params): Observable<any> {
    return of(this.ajsClusterService.getAuditLogs(params));
  }

  /**
   * Sends the License file to the Nexus for cluster
   *
   * @param fileObj  file content of the License file
   */
  uploadLicenseFile(fileObj): Observable<any> {
    return of(this.ajsClusterService.uploadLicenseFile(fileObj));
  }

  /**
   * Updates clusterInfo object in the AJS cluster service
   * Use this method on need basis to keep AJS cluster info in sync with Angular cluster info
   *
   * @param clusterInfo Updated cluster info
   */
  updateClusterInfoInAjsService(clusterInfo) {
    this.ajsClusterService.updateClusterInfoInAjsService(clusterInfo);
  }
}
