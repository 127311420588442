import { remove } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: Notification Service.

;(function(angular, undefined) {
  'use strict';

  var _notificationsCache = {
    count: undefined,
    unreadCount: undefined,
    notificationList: [],
  };

  /**
   * @ngdoc module
   * @name      C.notificationService
   *
   * @description
   * Module for managing Notification Services.
   */
  angular.module('C.notificationService', [])
    .service('NotificationService', NotificationServiceFn);

  /**
   * @ngdoc service
   * @name      C.notificationService.NotificationService
   *
   * @function  NotificationServiceFn
   * @description
   *  Provides  the services for Notifications in the Nav-bar.
   */
  function NotificationServiceFn(_, $http, API, $rootScope) {

    return {
      getNotificationMetaInfo: getNotificationMetaInfo,
      getNotifications: getNotifications,
      markVisited: markVisited,
      markDismissed: markDismissed,
    };

    /**
     * Get the count of total unseen notifications.
     *
     * @method    getNotificationMetaInfo
     * @return    {Promise} Promise resolved with notifications count
     *  else rejected with an error.
     */
    function getNotificationMetaInfo() {
      return $http({
        method: 'get',
        url: $rootScope.basicClusterInfo?.mcmMode ?
          API.mcm('notifications') : API.public('sessionUser/notifications'),
        params: {
          count: true,
          excludeEventLogs: true
        },
      }).then(function onSuccess(resp) {
        // Assign response into the cache and return the cache. This will keep
        // implementations in sync by reference.
        return assign(_notificationsCache, resp.data);
      });
    }

    /**
     * Gets the total notifications to be displayed.
     *
     * @method    getNotifications
     * @param     {boolean}    [onlyReturnList]   Default: true. Indicates if
     *                                            promise should return just
     *                                            noticationsList[]. If false,
     *                                            will also resolve with meta
     *                                            info.
     * @return    {object}    Promise resolved with notifications list
     *  else rejected with an error.
     */
    function getNotifications(onlyReturnList) {
      onlyReturnList = arguments.length ? onlyReturnList : true;
      return $http({
        method: 'get',
        url: $rootScope.basicClusterInfo?.mcmMode ?
          API.mcm('notifications') : API.public('sessionUser/notifications'),
        params: {
          excludeEventLogs: true
        },
      }).then(function onSuccess(resp) {
        resp.data.notificationList =
          (resp.data.notificationList || []).map(_transformNotification);

        // cache the list locally so items can be updated/removed from the
        // service and changes be reflected by reference in
        // cohNotificationPanel.
        assign(_notificationsCache, resp.data);

        // TODO: when ng-ui is the only used UI. We can remove this boolean
        // param and eliminate AngularJS cNotication.
        return onlyReturnList ?
          _notificationsCache.notificationList : _notificationsCache;
      });
    }

    /**
     * Convert notification into human-readable forms.
     *
     * @method    _transformNotification
     * @param     {Object}    data    The notification to be converted.
     * @return    {Object}    The human readable message.
     */

    function _transformNotification(notification) {
      notification._isSuccess = (notification.status === 'kSuccess');
      switch (notification.taskType) {
        case 'restore':
          // deepLinking primitive for recovery task uses 'recover-detail-local'
          // state for local and 'recover-detail-archive' for cloud and tape.
          return assign(notification, {
            _notificationText: [
              'notification.recoveryTask',
              notification.status
            ].join('.'),
            _deepLink: [
              'recover-detail-',
              notification.recoveryTask.type,
              '({ id: ' + notification.recoveryTask.taskId + ' })'
            ].join(''),
          });

        case 'clone':
          return assign(notification, {
            _notificationText: [
              'notification.cloneTask',
              notification.status
            ].join('.'),
            _deepLink: 'clone-detail({ id: ' +
              notification.cloneTask.taskId + ' })',
          });

        case 'backupNow':
          return assign(notification, {
            _notificationText: [
              'notification.backupTask',
              notification.status
            ].join('.'),
            _deepLink: 'job-run-details.protection( { id: ' +
              notification.backupTask.taskId +
              ',instanceId: ' +
              notification.backupTask.instanceId +
              ',startTimeUsecs: ' +
              notification.backupTask.startTimeUsecs + ' })',
          });

        case 'tiering':
          return assign(notification, {
            _notificationText: [
              'notification.tieringTask',
              notification.status
            ].join('.'),
          });

        case 'analysis':
          return assign(notification, {
            _notificationText: [
              'notification.analysisTask',
              notification.status
            ].join('.'),
          });

        case 'fieldMessage':
          return assign(notification, {
            _notificationText: [
              'notification.fieldMessageTask',
              notification.status
            ].join('.'),
            _deepLink: 'field-messages({ id: \'' +
              notification.fieldMessageTask.taskId + '\' })',
          });

        case 'agentUpgrade':
        case 'bulkInstallApp':
          // Just return the undecorated item from here, as the logic to
          // generate the notification message is inside the
          // notifications-item component.
          return notification;
      }
    }


    /**
     * Mark the notification as visited.
     *
     * @method    markVisited
     * @param     {Number[]}   ids   List of notification ids to mark visited.
     * @returns   {object}     Promise to resolve request to mark ids visited.
     */
    function markVisited(ids) {
      return $http({
        method: 'patch',
        url: $rootScope.basicClusterInfo?.mcmMode ?
          API.mcm('notifications') : API.public('sessionUser/notifications'),
        data: {
          notificationIds: ids,
          action: 'visit',
        }
      }).then(
        function cleanUpCache(resp) {
          if (get(_notificationsCache, 'notificationList.length')) {
            _notificationsCache.notificationList.forEach(
              function wasViewed(notif) {
                if (ids.includes(notif.id) && !notif.visited) {
                  // This viewed notification was not previously read,
                  // reduce the cached unread count accordingly.
                  _notificationsCache.unreadCount--;
                  notif.visited = true;
                }
              }
            );
          }
          return resp;
        }
      );
    }

    /**
     * Dismiss the notifications.
     *
     * @method    markDismissed
     * @param     {Number[]}   ids   List of notification ids to dismiss.
     * @returns   {object}     Promise to resolve request to dismiss notifs.
     */
    function markDismissed(ids) {
      return $http({
        method: 'patch',
        url: $rootScope.basicClusterInfo?.mcmMode ?
          API.mcm('notifications') : API.public('sessionUser/notifications'),
        data: {
          notificationIds: ids,
          action: 'dismiss',
        },
      }).then(
        function cleanUpCache(resp) {
          if (get(_notificationsCache, 'notificationList.length')) {
            remove(
              _notificationsCache.notificationList,
              function wasDismissed(notif) {
                var foundIt = ids.includes(notif.id);
                if (foundIt && !notif.visited) {
                  // This dismissed notification was not explicitly viewed,
                  // reduce the  cached unread count accordingly.
                  _notificationsCache.unreadCount--;
                }
                return foundIt;
              }
            );

            // reduce the total cached notifcations count by the number of
            // dismissed notifcations.
            _notificationsCache.count -= ids.length;

            return resp;
          }
        }
      );
    }
  }
}(angular));
