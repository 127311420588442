import { identity } from 'lodash-es';
import { intersection } from 'lodash-es';
import { difference } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Recovery manager Controller

;(function(angular, undefined) {
  'use strict';

  angular.module('C.recovery')
    .controller('recoveryController', recoveryControllerFn);

  function recoveryControllerFn($scope, $state, RestoreService, DateTimeService,
    evalAJAX, ENV_GROUPS, DateRangerService, $timeout, _, FEATURE_FLAGS,
    TenantService, UserService, ENUM_RESTORE_TYPE, ENV_TYPE_CONVERSION) {

    /**
     * environment groups constants.
     *
     * @type {object}
     */
    $scope.ENV_GROUPS = ENV_GROUPS;

    /**
     * recoveryTargets config map.
     *
     * @type  {object}
     */
    $scope.recoveryTargetMap = {
      'recover-local': {
        nameKey: 'local',
        targetType: 'kLocal',
        value: 'recover-local',
        viewDetailStateName: 'recover-detail-local',
        tableConfig: {
          sortBy: {
            startTime: 'performRestoreTaskState.base.startTimeUsecs',
            duration: 'performRestoreTaskState.base.endTimeUsecs - performRestoreTaskState.base.startTimeUsecs'
          }
        }
      },
      'recover-cloud': {
        nameKey: 'cloud',
        targetType: 'kCloud',
        value: 'recover-cloud',
        viewDetailStateName: 'recover-detail-archive',
        tableConfig: {
          sortBy: {
            startTime: 'restoreTask.startTimeUsecs',
            duration: '_duration'
          }
        }
      },
      'recover-tape': {
        nameKey: 'tape',
        targetType: 'kTape',
        value: 'recover-tape',
        viewDetailStateName: 'recover-detail-archive',
        tableConfig: {
          sortBy: {
            startTime: 'restoreTask.startTimeUsecs',
            duration: '_duration'
          }
        }
      }
    };

    // TODO(Sam): Remove this when Multi-Tenancy is designed for Helios.
    // For now, GCPBaaS is the only Helios Multi-Tenant Scenario.
    // ETA for Helios Multi-Tenancy is HE-2019.07.2
    if (UserService.isHeliosTenantUser()) {
      $scope.recoveryTargetMap = {
        'recover-local': {
          nameKey: 'local',
          targetType: 'kLocal',
          value: 'recover-local',
          viewDetailStateName: 'recover-detail-local',
          tableConfig: {
            sortBy: {
              startTime: 'performRestoreTaskState.base.startTimeUsecs',
              duration: 'performRestoreTaskState.base.endTimeUsecs - performRestoreTaskState.base.startTimeUsecs'
            }
          }
        }
      };
    }

    /**
     * List of filter ENUMs for ui-select.
     *
     * @type   {array}
     */
    $scope.restoreTypes = [
      {
        enum: [],
        displayKey: 'all',
      },
      {
        icon: 'icn-type-vm',
        enum: ['kRecoverVMs', 'kRecoverDisks'],
        displayKey: 'vms',
      },
      {
        icon: 'icn-type-file',
        enum: ['kRestoreFiles', 'kDownloadFiles'],
        displayKey: 'filesAndFolders',
      },
      FEATURE_FLAGS.oracleSourcesEnabled && {
        icon: 'icn-type-oracle',
        enum: ['kRecoverApp', 'kOracle'],
        displayKey: 'oracle',
      },
      {
        icon: 'icn-type-sql',
        enum: ['kRecoverApp', 'kSQL'],
        displayKey: 'msSql',
      },
      FEATURE_FLAGS.activeDirectorySourceEnabled && {
        icon: 'icn-type-active-directory',
        enum: ['kRecoverApp', 'kAD'],
        displayKey: 'activeDirectory',
      },
      {
        icon: 'icn-type-mount',
        enum: ['kMountVolumes'],
        displayKey: 'instantVolumeMount'
      },
      {
        icon: 'icn-type-physical',
        enum: ['kSystem'],
        displayKey: 'physicalServer',
      },
      {
        icon: 'icn-type-pure',
        enum: ['kRecoverSanVolume'],
        displayKey: 'pureVolumes',
      },
      {
        // Added 'kRestoreFiles' as Efficient NAS Recovery flow uses files flow
        // in the magneto.
        icon: 'icn-type-nas',
        enum: ['kMountFileVolume', 'kRestoreFiles'],
        displayKey: 'nas',
      },
      FEATURE_FLAGS.office365MailboxEnabled && {
        icon: 'icn-type-office365',
        enum: ['kRecoverEmails'],
        displayKey: 'office365Outlook',
      },
      FEATURE_FLAGS.kubernetesEnabled && {
        icon: 'icn-type-kubernetes',
        enum: ['kKubernetes'],
        displayKey: 'kubernetes',
      },
      FEATURE_FLAGS.office365OneDriveSupportEnabled && {
        icon: 'icn-type-office365',
        enum: ['kRecoverO365Drive'],
        displayKey: 'office365OneDrive',
      },
      FEATURE_FLAGS.exchangeOnPrem && {
        icon: 'icn-type-exchange',
        enum: ['kRecoverApp', 'kExchange'],
        displayKey: 'enum.environment.kExchange',
      }
    ].filter(identity);

    /**
     * dates for display and interaction with cDateRanger.
     *
     * @type {object}
     */
    $scope.dates = DateRangerService.getDateRangerValues($state.params ?
      $state.params.recoveryDateRange : undefined);

    /**
     * Placeholder for counts of various status types. Populated on each
     * gerRestoreTasks update.
     *
     * @type  {object}
     */
    $scope.statusCounts = {};

    /**
     * Controller Methods
     */

    /**
     * Activate this controller.
     *
     * @method  activate
     */
    function activate() {
      $scope.currentRecoveryTarget = getCurrentRecoveryTarget();

      /**
       * parameters to be used in getRestoreTasks() API call.
       *
       * @type  {object}
       */
      $scope.restoreTasksParams = {
        endTimeUsecs: DateTimeService.dateToUsecs($scope.dates.endDate),

        // When empty, this list is maintained in RestoreService.getRestoreTasks.
        restoreTypes: [],
        startTimeUsecs: DateTimeService.dateToUsecs($scope.dates.startDate),
        targetType: [$scope.currentRecoveryTarget.targetType],
        _tenantIds: [],
        _includeTenantInfo: true,
      };

      $scope.getRestoreTasks();
    }

    /**
     * return the current recovery target config
     *
     * @method   getCurrentRecoveryTarget
     * @return   {object}   one of current recovery target from
     *                      recoveryTargetMap variable.
     */
    function getCurrentRecoveryTarget() {
      return $scope.recoveryTargetMap[$state.$current.name];
    }

    /**
     * calls the API and updates $scope values accordingly
     *
     * @method  getRestoreTasks
     * @param   {object}  customOptions modify default options by customOptions.
     */
    $scope.getRestoreTasks = function getRestoreTasks(customOptions) {
      var options = angular.extend({ spinner: true }, customOptions || {});
      var params = TenantService.extendWithTenantParams(
        cloneDeep($scope.restoreTasksParams),
        $scope.restoreTasksParams._tenantIds
      );

      // Check if there are any application-specific filters that were
      // passed with the parameters. If there were, then we should apply
      // those filters to any kRecoverApp tasks that are returned.
      // If no explicit filters have been applied, this list will be empty
      // and no additional filtering will be done.
      var restoreAppFilters =
        intersection(params.restoreTypes, ENV_GROUPS.applicationSources);


      if (options.spinner) {
        $scope.dataReady = false;
      }

      RestoreService.getRestoreTasks(params).then(
        function getRestoreTasksSuccess(restoreTasks) {
          $scope.restoreTasks = restoreTasks.reduce(
            function filterUnnecessaryAndAddActionsMenu(result, task) {
              var restoreType =
                get(task, 'performRestoreTaskState.base.type');
              var restoreAppType = get(task.performRestoreTaskState,
                'restoreAppTaskState.restoreAppParams.type');
              var _task;
              var isFileBasedVolumeRecovery;
              var isFilteredByFiles;
              var isFilteredByNas;

              if (restoreType === 3) {
                // restore type filter = NAS flag
                isFilteredByNas = isEmpty(difference(
                  params.restoreTypes, ['kMountFileVolume', 'kRestoreFiles']));

                // restore type filter = Files & Folders flag
                isFilteredByFiles = isEmpty(difference(
                  params.restoreTypes, ['kRestoreFiles', 'kDownloadFiles']));

                // file based volume recovery flag
                isFileBasedVolumeRecovery = get(task,
                  'performRestoreTaskState.base._isFileBasedVolumeRestore');

                // 1. If  restore type filter is "NAS"
                //    Choose only "file based volume recovered" tasks
                // 2. If restore type filter is "Files & folders"
                //    Choose tasks which aren't "file based volume recovered"
                // 3. If restore type filter is not "NAS" & "Files & Folders"
                //    Choose the task
                if ((isFilteredByNas && isFileBasedVolumeRecovery) ||
                  (isFilteredByFiles && !isFileBasedVolumeRecovery) ||
                  (!isFilteredByNas && !isFilteredByFiles)) {
                  _task = task;
                }
              } else if (restoreType === ENUM_RESTORE_TYPE.kRecoverApp &&
                // The API doesn't distinguish between different kRecoverApp
                // types and returns all of them. This applies an additional
                // filter if an application type (ie, oracle, sql, ad) was
                // explicitly set.
                !!restoreAppType && restoreAppFilters.length) {
                if (restoreAppFilters.includes(
                  ENV_TYPE_CONVERSION[restoreAppType])) {
                  _task = task;
                }
              } else {
                _task = task;
              }

              // Add actions menu for the task
              if (_task) {
                result.push(
                  assign(_task, {
                    _actionsMenu: getActionMenuItems(task),
                  })
                );
              }
              return result;
            }, []);

          $scope.statusCounts =
            RestoreService.getStatusCounts($scope.restoreTasks);
        },
        evalAJAX.errorMessage
      ).finally(function getRestoreTasksFinally() {
        if (options.spinner) {
          $scope.dataReady = true;
        }
      });

    };

    /**
     * Update the list & stats when polling finished for a task.
     * it does a silent update as we dont want to show spinner.
     *
     * @method   onTaskStatusUpdate
     * @param    {string}    pollingStatus    polling status
     */
    $scope.onTaskStatusUpdate = function onTaskStatusUpdate(pollingStatus) {
      if (['completed', 'error'].includes(pollingStatus)) {
        $scope.getRestoreTasks({ spinner: false });
      }
    };

    /**
     * respond to cDateRanger date changes updates restoreParams object
     * and then initiates an api call to update $scope data
     *
     * @method   updateDateParams
     */
    $scope.updateDateParams = function updateDateParams() {
      $scope.restoreTasksParams.startTimeUsecs = DateTimeService.dateToUsecs($scope.dates.startDate);
      $scope.restoreTasksParams.endTimeUsecs = DateTimeService.dateToUsecs($scope.dates.endDate);
      $scope.getRestoreTasks();
    };

    /**
     * Build the actionMenu for a given task.
     *
     * @method    getActionMenuItems
     * @param     {Object}   task   The task to construct actions for
     * @returns   {Array}           List of actions
     */
    function getActionMenuItems(task) {
      var config = [
        {
          icon: 'icn-view',
          translateKey: 'viewDetails',
          state: $scope.currentRecoveryTarget.viewDetailStateName,
          stateParams: {
            id: task.performRestoreTaskState.base.taskId,
            isMultiStageRestore: task._isMultiStageRestore
          }
        }
      ];

      if (RestoreService.canCancelTask(task)) {
        config.push(
          {
            icon: 'icn-delete',
            translateKey: 'cancel',
            action: function cancelTaskWrapper() {
              cancelTask(task);
            }
          }
        );
      }

      if (RestoreService.canRetryRestoreTask(task)) {
        config.push({
          icon: 'icn-recover',
          translateKey: 'resubmit',
          action: RestoreService.retryRestoreTask
            .bind(null, task.performRestoreTaskState),
        });
      }

      if (RestoreService.canReconfigureRestoreTask(task)) {
        config.push({
          icon: 'icn-recover',
          translateKey: 'reconfigure',
          action: function reConfigureRecoveryOptions() {
            var restoreAppParams = task.performRestoreTaskState
              .restoreAppTaskState.restoreAppParams;

            $state.go('recover-db.options', {
              dbType: 'oracle',
              jobId: restoreAppParams.ownerRestoreInfo.ownerObject.jobId,
              jobInstanceId: restoreAppParams.ownerRestoreInfo.ownerObject
                .jobInstanceId,
              entityId: restoreAppParams.restoreAppObjectVec[0].appEntity.id,
              sourceId: restoreAppParams.restoreAppObjectVec[0].appEntity
                .parentId,
              restoreParams: restoreAppParams.restoreAppObjectVec[0]
                .restoreParams,
            });
          },
        });
      }

      return config;
    }

    /**
     * Cancels a restore task.
     *
     * @method     cancelTask
     * @param      {object}  taskObj    The task object of task to cancel
     */
    function cancelTask(taskObj) {
      // Show a confirmation modal before canceling.
      RestoreService.cancelTaskModal({
        id: taskObj.performRestoreTaskState.base.taskId,
        entityType: taskObj.performRestoreTaskState.base.type,
      }).then(function cancelStarted() {
        // call for a reload so user can see that the cancel been initiated
        $timeout($scope.getRestoreTasks, 5000);
      });
    }

    // Activate go!
    activate();
  }

})(angular);
