import { sum } from 'lodash-es';
import { values } from 'lodash-es';
import { compact } from 'lodash-es';
import { concat } from 'lodash-es';
import { get } from 'lodash-es';
// View Service

;(function(angular, undefined) {
  'use strict';

  angular.module('C').service('ViewService', ViewServiceFn);


  /**
   * SMB Permissions for file access. Used in Create/Edit View page.
   *
   * Do not alpha sort each group of values. They are listed in desired display
   * order.
   */
  var SMB_PERMISSIONS = {
    types: {
      // V2 values.
      Allow: 'allow',
      Deny: 'deny',
    },
    _types: {
      // Deprecated values
      kAllow: 'allow',
      kDeny: 'deny',
    },
    modes: {
      // V2 values for modes.
      FolderSubFoldersAndFiles: 'smbPermissions.modes.folderSubFoldersAndFiles',
      FolderAndSubFolders: 'smbPermissions.modes.folderAndSubFolders',
      FolderAndFiles: 'smbPermissions.modes.folderAndFiles',
      FolderOnly: 'smbPermissions.modes.folderOnly',
      SubFoldersAndFilesOnly: 'smbPermissions.modes.subFoldersAndFilesOnly',
      SubFoldersOnly: 'smbPermissions.modes.subFoldersOnly',
      FilesOnly: 'smbPermissions.modes.filesOnly',

      // Deprecated kValues for modes.
      kFolderSubFoldersAndFiles: 'smbPermissions.modes.kFolderSubFoldersAndFiles',
      kFolderAndSubFolders: 'smbPermissions.modes.kFolderAndSubFolders',
      kFolderAndFiles: 'smbPermissions.modes.kFolderAndFiles',
      kFolderOnly: 'smbPermissions.modes.kFolderOnly',
      kSubFoldersAndFilesOnly: 'smbPermissions.modes.kSubFoldersAndFilesOnly',
      kSubFoldersOnly: 'smbPermissions.modes.kSubFoldersOnly',
      kFilesOnly: 'smbPermissions.modes.kFilesOnly',
    },
    _modes: {
      // Deprecated kValues for modes.
      kFolderSubFoldersAndFiles: 'smbPermissions.modes.kFolderSubFoldersAndFiles',
      kFolderAndSubFolders: 'smbPermissions.modes.kFolderAndSubFolders',
      kFolderAndFiles: 'smbPermissions.modes.kFolderAndFiles',
      kFolderOnly: 'smbPermissions.modes.kFolderOnly',
      kSubFoldersAndFilesOnly: 'smbPermissions.modes.kSubFoldersAndFilesOnly',
      kSubFoldersOnly: 'smbPermissions.modes.kSubFoldersOnly',
      kFilesOnly: 'smbPermissions.modes.kFilesOnly',
    },
    access: {
      // V2 values for access.
      FullControl: 'smbPermissions.access.fullControl',
      ReadWrite: 'smbPermissions.access.readWrite',
      Modify: 'smbPermissions.access.modify',
      ReadOnly: 'smbPermissions.access.readOnly',
      SpecialAccess: 'smbPermissions.access.specialAccess',
      SuperUser: 'smbPermissions.access.superUser',
    },
    accessOptions: {
      views: {

        // V2 values
        FullControl: 'smbPermissions.access.fullControl',
        ReadWrite: 'smbPermissions.access.readWrite',
        Modify: 'smbPermissions.access.modify',
        ReadOnly: 'smbPermissions.access.readOnly',

        // Deprecated kValues
        kFullControl: 'smbPermissions.access.kFullControl',
        kReadWrite: 'smbPermissions.access.kReadWrite',
        kModify: 'smbPermissions.access.kModify',
        kReadOnly: 'smbPermissions.access.kReadOnly',
      },
      _views: {

        // Deprecated kValues
        kFullControl: 'smbPermissions.access.kFullControl',
        kReadWrite: 'smbPermissions.access.kReadWrite',
        kModify: 'smbPermissions.access.kModify',
        kReadOnly: 'smbPermissions.access.kReadOnly',
      },
      shares: {

        // V2 values
        FullControl: 'smbPermissions.access.fullControl',
        Modify: 'smbPermissions.access.modify',
        ReadOnly: 'smbPermissions.access.readOnly',
        SuperUser: 'smbPermissions.access.superUser',
      },
      _shares: {

        // Deprecated kvalues
        kFullControl: 'smbPermissions.access.kFullControl',
        kModify: 'smbPermissions.access.kModify',
        kReadOnly: 'smbPermissions.access.kReadOnly',
      },
    },
  };

  function ViewServiceFn(_, $http, $q, $translate, $state, API, FetchService,
    ViewServiceFormatter, ActiveDirectoryService, cUtils, evalAJAX, cMessage,
    SlideModalService, WELL_KNOWN_PRINCIPALS, $rootScope,
    TenantService, GroupService, UserService, StateManagementService, $log,
    NgPassthroughOptionsService, NgViewsService) {

    var ViewService = {
      createShare: createShare,
      createUserQuotaOverride: createUserQuotaOverride,
      createView: createView,
      createViewModal: createViewModal,
      decodeDecimalNfsPermissions: decodeDecimalNfsPermissions,
      deleteShare: deleteShare,
      deleteUserQuotaOverride: deleteUserQuotaOverride,
      deleteView: deleteView,
      deleteNgView: deleteNgView,
      editUserQuotaOverride: editUserQuotaOverride,
      editUserQuotasSettings: editUserQuotasSettings,
      editView: editView,
      encodeDecimalNfsPermissions: encodeDecimalNfsPermissions,
      getAllLocalPrincipals: getAllLocalPrincipals,
      getClusterViewsCount: getClusterViewsCount,
      getDirectoryQuotas: getDirectoryQuotas,
      getOwnViews: getOwnViews,
      getQosPrincipals: getQosPrincipals,
      getQuotaPrincipals: getQuotaPrincipals,
      getQuotaUsageSummary: getQuotaUsageSummary,
      getShares: getShares,
      getUserQuotas: getUserQuotas,
      getView: getView,
      getNgView: getNgView,
      getViewById: getViewById,
      getViewCount: getViewCount,
      getViewNameWarnings: getViewNameWarnings,
      getViewPrincipals: getViewPrincipals,
      getViews: getViews,
      getQstarArchivalTapeDetails: getQstarArchivalTapeDetails,
      protectView: protectView,
      renameView: renameView,
      SMB_PERMISSIONS: SMB_PERMISSIONS,
      updateShare: updateShare,
      updateDirectoryQuota: updateDirectoryQuota,
    };

    var wellKnownSids = WELL_KNOWN_PRINCIPALS.map(
      function mapPrincipals(principal) {
        return principal.sid;
      }
    );

    /**
     * posts to the API to create or edit a new view
     * TODO: rename the controller to better represent the fact
     * that this controller handles create AND edit
     *
     * @param  {Object} data API required and optional params
     * @return {Object}      Promise to resolve the API request
     */
    function createView(data) {
      return $http({
        method: 'post',
        url: API.public('views'),
        data: ViewServiceFormatter.untransformView(data),
      }).then(
        function createViewSuccess(response) {
          return ViewServiceFormatter.transformView(response.data);
        }
      );
    }

    /**
     * Opens the create view modal.
     *
     * @method   createViewModal
     * @param    {Object}   options   the optional optionss used inside the
     *                                modal.
     * @returns  {Object}   Promise which resolves the created view.
     * @deprecated by https://gerrit.eng.cohesity.com/c/iris-ui/+/605099
     */
    function createViewModal(options) {
      return SlideModalService.newModal({
        templateUrl: 'app/platform/nfs/modify2/modify.html',
        controller: 'viewModify2Controller as $ctrl',
        size: 'lg',

        // disable keyboard so escape key doesn't screw things up
        // from the confirmation page of the flow
        keyboard: false,
        resolve: {
          options: function optionsResolveFn() {
            return options || {};
          },
        },
      });
    }

    /**
     * Posts to the API to create a Share.
     *
     * @method     createShare
     * @param      {Object}  body    API required and optional params
     * @return     {Object}  Promise to resolve the API request
     */
    function createShare(body) {
      return NgViewsService.createShare({ body }).toPromise();
    }

    /**
     * puts to the API to edit an existing view
     *
     * @method     editView
     *
     * @param      {Object}  data              API required and optional params
     * @return     {Object}  Promise to resolve the API request on success,
     *                       resolves with view obj on failure, rejects with API
     *                       response obj
     */
    function editView(data) {
      return $http({
        method: 'put',
        url: API.public('views'),
        data: ViewServiceFormatter.untransformView(data),
      }).then(
        function editViewSuccess(response) {
          return ViewServiceFormatter.transformView(response.data);
        }
      );
    }

    /**
     * Posts to the API which renames a View.
     *
     * @method     renameView
     *
     * @param      {String}  originalViewName  The original view name
     * @param      {Object}  data    API required and optional params
     * @return     {Object}  Promise to resolve the API request on success,
     *                       resolves with view obj on failure, rejects with API
     *                       response obj
     */
    function renameView(originalViewName, data) {
      return $http({
        method: 'post',
        url: API.public('views', 'rename', originalViewName),
        data: data
      }).then(
        function editViewNameSuccess(response) {
          return ViewServiceFormatter.transformView(response.data);
        }
      );
    }

    /**
     * posts to the API to delete an existing view
     *
     * @method     deleteView
     * @param      {string}  name    of the view to be deleted
     * @return     {Object}  Promise to resolve the API request
     */
    function deleteView(name) {
      return $http({
        method: 'delete',
        url: API.public('views', encodeURIComponent(name)),
      });
    }

    /**
     * Posts to the API to delete an a Share
     *
     * @method     deleteShare
     * @param      {String}  name    of the Share to be deleted
     * @return     {Object}  Promise to resolve the API request
     */
    function deleteShare(name) {
      return NgViewsService.deleteShare({ name }).toPromise();
    }

    /**
     * requests a specific view from the API
     *
     * @param  {String} name          name of the View to request
     * @param  {Object} [params = {}] params for API request
     * @return {Object}               Promise to resolve the API request
     *                                on success, resolves with View object
     *                                on failure, rejects with server response
     */
    function getView(name, params) {
      params = params || {};

      return $http({
        method: 'get',
        url: API.public('views', encodeURIComponent(name)),
        params: params,
      }).then(
        function getViewSuccess(response) {
          return _getViewSuccess(response, params);
        }
      ).then(ViewServiceFormatter.transformView);

    }

    /**
     * Requests qstar tape details from the API
     *
     * @method   getQstarArchivalTapeDetails
     * @param    {Object}   params   API required params
     * @returns  {Array}   Promise to resolve the API request
     */
     function getQstarArchivalTapeDetails(params) {
      return $http({
        method: 'get',
        url: API.public('vaults/archiveMediaInfo'),
        params: params,
      }).then(
        function successFn(response) {
          return response.data || {};
        }
      );
    }

    /**
     * requests a specific view from the API
     *
     * @param  {Object} [params = {}] params for API request
     * @return {Object}               Promise to resolve the API request
     *                                on success, resolves with View object
     *                                on failure, rejects with server response
     */
    function getNgView(params) {
      const requestParams = { ...params };
      const view = { data: undefined };
      const viewName = params.viewNames;
      const viewId = params.viewIds;
      const isDrView = !!params.jobIds;

      if (viewId) {
        // May only send one of viewIds or viewNames.
        requestParams.viewNames = undefined;
      }

      // For v2 API, jobIds is not a valid param anymore. Instead, we should use protectionGroupIds.
      if (isDrView) {
        requestParams.protectionGroupIds = params.jobIds;
        delete params.jobIds;
      }

      /**
       * Handles error cases when no matching View is found. Displays error
       * message and redirects to list view.
       */
      function handleError() {
        cMessage.error({
          textKey: $translate.instant(
            'requestedViewNotFound',
            { name: viewName || '', id: viewId || '' }
          ),
          persist: true,
        });
        return StateManagementService.goToPreviousState('ng-views.views');
      }

      return $http({
        method: 'get',
        url: '/v2/file-services/views',
        params: requestParams,
      }).then(
        function getViewSuccess(response) {
          const data = response.data;

          if (data === null || data.views === null) {
            $log.error(
              'No matching Views were returned from backend.',
              { name: viewName, id: viewId }
            );
            return handleError();
          }

          if (data.views.length === 1) {
            // Only one match, so use that one.
            view.data = data.views[0];
          } else if (isDrView) {
            // DR Views do not have viewId, so find the one with undefined viewId.
            view.data = data.views.find(view => !view.viewId);
          } else {
            // Otherwise find View with matching viewId.
            view.data = data.views.find(view => viewId === view.viewId);
          }

          if (!view.data) {
            $log.error(
              'More than one View was returned, but there was a problem finding the right match.',
              { name: viewName, id: viewId }
            );
            return handleError();
          }

          return _getViewSuccess(view, params, true);
        }
      ).then(ViewServiceFormatter.transformView);
    }

    /**
     * Request to delete specific view.
     *
     * @param  {Integer} id   View Id to request
     * @return {Object}       Promise to resolve the API request
     *                        on success, resolves with deleted View object
     *                        on failure, rejects with server response
     */
    function deleteNgView(id) {
      return $http({
        method: 'delete',
        url: `/v2/file-services/views/${id}`,
      });
    }

    /**
     * requests a specific view by Id from the API
     *
     * @param  {Number} id            id of the View to request
     * @param  {Object} [params = {}] params for API request
     * @return {Object}               Promise to resolve the API request
     *                                on success, resolves with View object
     *                                on failure, rejects with server response
     */
    function getViewById(id, params) {
      params = params || {};

      return $http({
        method: 'get',
        url: API.public('views/id', id),
        params: params,
      }).then(
        function getViewSuccess(response) {
          return _getViewSuccess(response, params);
        }).then(ViewServiceFormatter.transformView);
    }

    /**
     * Get list of views owned by logged in user organization.
     *
     * @method     getOwnViews
     * @param      {Object}  params  The request params.
     * @return     {Object}  Promise to resolve the API request
     */
    function getOwnViews(params) {
      return getViews(TenantService.extendWithEntityOwnerParams(params));
    }

    /**
     * Requests a list of Views from the API
     *
     * @method     getViews
     * @param      {Object}  params  API required and optional params
     * @return     {Object}  Promise to resolve the API request
     */
    function getViews(params) {
      params = params || {};
      cUtils.selfOrDefault(params, 'allUnderHierarchy', true);
      return $http({
        method: 'get',
        url: API.public('views'),
        params: params
      }).then(
        function getViewsSuccess(response) {
          var viewsList = (response.data.views || [])
            .sort(cUtils.sortObjectsByProp('name'))
            .map(ViewServiceFormatter.transformView);

          // Decorate the array with the `lastResult` so we know if there are
          // more records than returned.
          viewsList._lastResult = response.data.lastResult;

          if (params._includeTenantInfo) {
            return TenantService.resolveTenantDetails(viewsList);
          }

          return viewsList;
        }
      );

    }

    /**
     * Requests the total count of Views on the Cluster.
     *
     * @method     getClusterViewsCount
     * @return     {Object}  Promise to resolve the API request
     */
    function getClusterViewsCount() {
      return $http({
        method: 'get',
        url: API.public('views'),
        params: { viewCountOnly: true, includeInactive: true },
      }).then(
        function getViewsSuccess(response) {
          return response.data || {};
        }
      );
    }

    /**
     * // Note: this method is not in active use and only being referenced in a deprecated ajs component
     * @deprecated
     * Requests an aggregated list of all Shares from all the Views.
     *
     * @method     getShares
     * @param      {Object}  params  API required and optional params
     * @return     {Object}  Promise to resolve the API request
     */
    function getShares(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.public('shares'),
        params: params
      }).then(
        function getSharesSuccess(response) {
          var shares = (response.data.sharesList || []).map(
            ViewServiceFormatter.transformShare);

          if (params._includeTenantInfo) {
            return TenantService.resolveTenantDetails(shares);
          }
          return shares;
        }
      );
    }

    /**
     * requests count of all views
     *
     * @method     getViewCount
     * @return     {Object}  Promise to resolve the API request
     */
    function getViewCount() {

      return $http({
        method: 'get',
        url: API.public('views'),
        params: {
          viewCountOnly: true,
        },
      }).then(
        function getViewsSuccess(response) {
          return response.data.count;
        }
      );

    }

    /**
     * returns the list of qosPrincipals
     *
     * @method     getQosPrincipals
     * @return     {Object}  promise to resolve API call. on success, returns
     *                       array of qosPrincipals on failure, returns raw
     *                       server response
     */
    function getQosPrincipals() {

      return $http({
        method: 'get',
        url: API.public('qosPolicies'),
        headers: NgPassthroughOptionsService.requestHeaders
      }).then(
        function getQosSuccess(response) {

          var qosPrincipals = [];

          if (response.data || response.data.length) {
            qosPrincipals = response.data;
          }

          // Decorate with values expected by related API.
          qosPrincipals.forEach(item => {
            item.principalId = item.id;
            item.principalName = item.name;
          })

          return qosPrincipals;
        }
      );

    }

    /**
     * Returns a promise to return list of principals associated with a View.
     * And skip any Well-Known SIDs unless indicated true.
     *
     * @method     getViewPrincipals
     * @param      {object}   view                The View
     * @param      {boolean}  lookupWellKnowns    true if should lookup
     *                                            Well-Known sids
     * @return     {object}   Promise to resolve request for The view's
     *                        principals.
     */
    function getViewPrincipals(view, lookupWellKnowns) {
      var smbPermissionsInfo = view.smbPermissionsInfo || {};
      var sharePermissions = view.sharePermissions || {};
      var adEnabled = $rootScope.user.privs.AD_LDAP_VIEW;
      var ownerIsWellKnown = wellKnownSids.includes(smbPermissionsInfo.ownerSid);

      // Combine the view permissions and share permissions into one list.
      var allPermissions =
        compact(concat(sharePermissions.permissions || sharePermissions, smbPermissionsInfo.permissions));

      var params = {
        includeComputers: true,
        sids: [],
      };

      // Lookup the existing ownerSid.
      if (smbPermissionsInfo.ownerSid &&
        (!ownerIsWellKnown || (ownerIsWellKnown && lookupWellKnowns))) {
          params.sids.push(smbPermissionsInfo.ownerSid);
      }

      // Lookup the users with permissions.
      params.sids = params.sids.concat(
        allPermissions.reduce(function mapSids(acc, perm) {
          var sidIsWellKnown = wellKnownSids.includes(perm.sid);
          if (!sidIsWellKnown || (sidIsWellKnown && lookupWellKnowns)) {
            acc.push(perm.sid);
          }
          return acc;
        }, [])
      );

      // Add any super users.
      params.sids = params.sids.concat(view.superUserSids || []);
      params.sids = params.sids.concat(sharePermissions.superUserSids || []);

      // Add any users with snapshot permissions.
      const allowAccessSids = get(view, 'selfServiceSnapshotConfig.allowAccessSids', []);
      const denyAccessSids = get(view, 'selfServiceSnapshotConfig.denyAccessSids', []);
      params.sids = params.sids.concat(allowAccessSids, denyAccessSids);

      // If there are any sids to lookup return the API request promise,
      // otherwise resolve with an empty array immediately. Calling
      // ActiveDirectoryService.searchPricipals() with no sids specified will
      // return a result of all principals.
      return params.sids.length && adEnabled ?
        ActiveDirectoryService.getDomainPrincipals(params) :
        $q.resolve([]);
    }

    /**
     * Puts to the API to edit the User Quota Metadata for a View
     *
     * @method     editUserQuotasSettings
     *
     * @param      {Object}  data    API required and optional params
     * @return     {Object}  Promise to resolve the API request.
     */
    function editUserQuotasSettings(data) {
      return NgViewsService.updateViewDefaultUserQuota(data)
        .toPromise()
        .then((resp = {}) => {
          const v1Resp = {
            defaultUserQuotaPolicy: resp.defaultQuotaPolicy,
            enableUserQuota: resp.enabled,
          };
          return v1Resp;
        });
    }

    /**
     * Returns a promise to return a list of AD Principals based on the provided
     * list of quota overrides. This function ignores userIds other than
     * `userSid`.
     *
     * @method     getQuotaPrincipals
     *
     * @param      {Array}   overrides  The list of overrides
     * @return     {Object}  Promise to resolve request for the View's User
     *                       Quota Principals.
     */
    function getQuotaPrincipals(quotaPolicies) {
      var params = {
        includeComputers: false,
        sids: [],
      };

      if (Array.isArray(quotaPolicies)) {
        params.sids = quotaPolicies.reduce(function cullSides(sids, policy) {
          // Only include SIDs
          if (policy && policy.sid) {
            sids.push(policy.sid);
          }
          return sids;
        }, []);
      }

      // If there are any sids to lookup, then return the API request promise.
      // Otherwise, resolve with an empty array immediately because calling
      // ActiveDirectoryService.searchPricipals() with no sids specified will
      // return a result of all Principals. And that would be bad.
      return params.sids.length ?
        ActiveDirectoryService.getDomainPrincipals(params) :
        $q.resolve([]);
    }

    /**
     * Requests the complete list of User Quota Overrides for a specified View.
     *
     * @method     getUserQuotas
     *
     * @param      {Object}  requestObj  The request object
     * @return     {Object}  Promise to resolve the API request.
     */
    // todo: deprecated this after the v2 GET API is in par with the v1
    function getUserQuotas(requestObj) {
      var config = {
        requestParams: requestObj,
        endpoint: API.public('viewUserQuotas'),
        arrayPropertyKey: 'usersQuotaAndUsage',

        // Note: Bridge has a hard cap at 1024.
        numFetchFirst: 1000,
        numFetchMore: 1000,
      };
      return FetchService.incrementalFetch(config);
    }

    /**
     * Posts to the API to create a new User Quota Override for a View
     *
     * @method     createUserQuota
     *
     * @param      {Object}  data    API required and optional params
     * @return     {Object}  Promise to resolve the API request.
     */
    function createUserQuotaOverride(data) {
      var requestObj = {
        viewId: data.viewId,
        body: {
          userQuotas: [
            data.override
          ]
        }
      };
      const { sid, unixUid } = data.override;
      return NgViewsService.addViewUserQuota(requestObj)
        .toPromise()
        .then(resp => {
          return resp?.userQuotas?.find(quota =>
            (quota.sid && quota.sid === sid) || (quota.unixUid && quota.unixUid === unixUid)) || {};
        });
    }

    /**
     * Puts to the API to edit a User Quota Override for a View
     *
     * @method     editUserQuotaOverride
     *
     * @param      {Object}  data    API required and optional params
     * @return     {Object}  Promise to resolve the API request.
     */
    function editUserQuotaOverride(data) {
      return NgViewsService.updateViewUserQuota(data).toPromise();
    }

    /**
     * Deletes a User Quota Override.
     *
     * @method     deleteUserQuotaOverride
     *
     * @param      {Object}  data    API required and optional params
     * @return     {Object}  Promise to resolve the API request.
     */
    function deleteUserQuotaOverride(data) {
      return NgViewsService.deleteViewUserQuota(data).toPromise();
    }

    /**
     * Requests the Quota and Usage summary for a specified View or User.
     *
     * @method     getQuotaUsageSummary
     *
     * @param      {String}  params  request object params
     * @return     {Object}  Promise to resolve the API request.
     */
    // todo: deprecated this after the v2 GET API is in par with the v1
    function getQuotaUsageSummary(params) {
      return $http({
        method: 'get',
        url: API.public('viewUserQuotas'),
        params: angular.extend({summaryOnly: true}, params),
      }).then(
        function getQuotaUsageSummarySuccess(response) {
          return response.data || {};
        }
      );
    }

    /**
     * Initiates the View Job flow with provided View pre selected.
     *
     * @method   protectView
     * @param    {object}   view   The view
     */
    function protectView(view) {
      $state.go('job-modify', {
        environments: ['kView'],
        protectView: view,
      });
    }

    /**
     * Examines View/Share name and returns warnings for certain combinations of
     * special characters and protocols.
     *
     * @param      {String}  name      The View or Share name
     * @param      {String}  protocol  The View access protocol
     */
    function getViewNameWarnings(name, protocol) {
      var warnings = [];

      protocol = protocol || 'kAll';
      name = name || '';

      // Look for S3 restricted characters: whitespace
      if (['kAll', 'kS3Only'].includes(protocol) && name.match(/\s/)) {
        warnings.push('viewModify.nameWithSpaces');
      }

      // Look for SMB restricted characters: < : " \ | ? *
      if (['kAll', 'kSMBOnly'].includes(protocol) &&
        name.match(/[<:"\\|?*]/)) {
        warnings.push('viewModify.nameWithBackslashes');
      }

      return warnings;
    }

    /**
     * Returns a promise with a list of LOCAL principals on the Cluster.
     *
     * @method     getAllLocalPrincipals
     * @return     {Object}  Promise to resolve request for Local Principals
     */
    function getAllLocalPrincipals() {
      var promises = {
        users: UserService.getAllUsers({domain: 'LOCAL'}),
        groups: GroupService.getGroups({domain: 'LOCAL'}),
      };
      return $q.all(promises).then(function gotAllLocals(response) {
        return concat([], response.groups, response.users);
      });
    }

    /**
     * Decodes the decimal mode of the NFS permissions and returns an object.
     *
     * @function   decodeDecimalNfsPermissions
     * @param      {number}    decimalMode    Decimal (base 10) form of NFS
     *                                        permissions.
     * @returns    {object}    Decoded NFS permissions object.
     */
    function decodeDecimalNfsPermissions(decimalMode) {
      return {
        owner: {
          read: decimalMode & 256,
          write: decimalMode & 128,
          execute: decimalMode & 64,
        },
        group: {
          read: decimalMode & 32,
          write: decimalMode & 16,
          execute: decimalMode & 8,
        },
        others: {
          read: decimalMode & 4,
          write: decimalMode & 2,
          execute: decimalMode & 1,
        },
      };
    }

    /**
     * Calculates and returns the decimal mode of the NFS permissions.
     * An output of 493 (default perms) expects the following input param:
     * {
        owner: {
          read: 256,
          write: 128,
          execute: 64,
        },
        group: {
          read: 32,
          write: 0, // This value is off
          execute: 8,
        },
        others: {
          read: 4,
          write: 0, // This value is off
          execute: 1,
        },
      }
     *
     * @function   encodeDecimalNfsPermissions
     * @param      {object}    nfsPermissions   NFS permissions object.
     * @returns    {number}    Decimal (base 10) mode of NFS permissions.
     */
    function encodeDecimalNfsPermissions(nfsPermissions) {
      return sum(concat([],
        values(nfsPermissions.owner),
        values(nfsPermissions.group),
        values(nfsPermissions.others)
      ));
    }

    /**
     * // Note: this method is not in active use and only being referenced in a deprecated ajs component
     * @deprecated
     * Puts to the API to update an existing Share
     *
     * @method    updateShare
     * @param     {object}   share    Share object
     * @return    {object}   Promise to update the Share.
     */
    function updateShare(share) {
      return $http({
        method: 'put',
        url: API.public('viewAliases'),
        data: ViewServiceFormatter.untransformShare(share),
      }).then(
        function updateShareSuccess(response) {
          return ViewServiceFormatter.transformShare(response.data);
        }
      );
    }

    /**
     * Requests a list of Directory Quotas for a View.
     *
     * @method     getDirectoryQuotas
     * @param      {Object}  params  request params, including `viewName`.
     * @return     {Object}  Promise to resolve the API request
     */
    function getDirectoryQuotas(requestObj) {
      var config = {
        requestParams: requestObj || {},
        endpoint: API.public('viewDirectoryQuotas'),
        arrayPropertyKey: 'quotas',
        transformFn: ViewServiceFormatter.transformDirectoryQuota,

        // Note: Bridge has a hard cap at 1024.
        numFetchFirst: 1000,
        numFetchMore: 1000,
      };

      return FetchService.incrementalFetch(config);
    }

    /**
     * Puts to the API to create/edit/delete a logical quota for a directory in
     * a View. On the backend this is a PUT to the views API.
     *
     * @method     updateDirectoryQuota
     * @param      {Object}   data    API required and optional params
     * @return     {Object}   Promise to resolve the API request
     */
    function updateDirectoryQuota(data) {
      return NgViewsService.updateViewDirectoryQuota(data).toPromise();
    }

    /**
     * After receive the views successfully, process the receieved data if
     * necessary.
     *
     * @method  _getViewSuccess
     * @param   {Object}  response
     * @param   {Object}  params
     * @param   {Boolean} hideError
     * @return  {Object}  the wanted view
     */
    function _getViewSuccess(response, params, hideError) {
      var view = response.data;

      if (!view && !hideError) {
        return $q.reject({
          data: {
            message: $translate.instant(
              'requestedViewNotFound',
              { name: params.viewNames || '', id: params.viewIds || '' }
            )
          }
        });
      }

      if (params._includeTenantInfo) {
        return TenantService.resolveSingleTenantDetails(view);
      }

      return view;
    }

    return ViewService;
  }

})(angular);
