import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AlertNotificationRulesServiceApi } from '@cohesity/api/alert-server';
import { ConfigurationApiService, DataHawkConfig } from '@cohesity/api/argus';
import { AnomalyAlert, SecurityServiceApi } from '@cohesity/api/v2';
import { SnackBarService, WindowRef } from '@cohesity/helix';
import {
  dgProductPage,
  flagEnabled,
  hasDataClassificationSku,
  hasThreatProtectionSku,
  IrisContextService,
} from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';

/**
 * Ransomware setting dialog params
 */
export interface AntiRasnomwareSettingsDialogInput {
  description: string;
  showOtherScanOptions?: boolean;
}

/**
 * WIP
 * This component shows anti-ransomware settings dialog, includes
 * 1. Threshold slider
 * 2. Tag name text input
 * 3. Block recovery check box
 *
 * Usage:
 * <dg-ar-anti-ransomware-settings></dg-ar-anti-ransomware-settings>
 */
@Component({
  selector: 'dg-ar-anti-ransomware-settings',
  templateUrl: './anti-ransomware-settings.component.html',
  styleUrls: ['./anti-ransomware-settings.component.scss'],
})
export class AntiRansomwareSettingsComponent extends AutoDestroyable implements OnInit {
  /**
   * The form group for the dialog
   */
  form = new UntypedFormGroup({
    threshold: new UntypedFormControl(1),
    blockRestore: new UntypedFormControl(false),

    // TODO:
    // 1. Default flag name
    // 2. Validators to be decided
    tagName: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(15)]),
    threatScans: new FormControl(false),
    dataSensitivityScans: new FormControl(false),
  });

  /**
   * Indicates whether data is being fetched.
   */
  loading = false;

  /**
   * Returns boolean based on FF and SKU criteria
   */
  get showOtherScanOptions(): boolean {
    return (
      this.dialogParams.showOtherScanOptions && (this.disableDataSensitivityToggle || this.disableThreatHuntingToggle)
    );
  }

  /**
   * Returns boolean based on DC SKU
   */
  get disableDataSensitivityToggle(): boolean {
    return hasDataClassificationSku(this.irisCtx.irisContext);
  }

  /**
   * Returns boolean based on TH SKU
   */
  get disableThreatHuntingToggle(): boolean {
    return hasThreatProtectionSku(this.irisCtx.irisContext);
  }

  /**
   * Returns Dms account ID.
   */
  get dmsAccountId(): string {
    return this.irisCtx.irisContext.user?.profiles?.[0].tenantId?.split(':')[0];
  }

  /**
   * Returns Salesforce account ID.
   */
  get salesforceAccountId(): string {
    return this.irisCtx.irisContext.user?.salesforceAccount?.accountId;
  }

  /**
   * Checks if DataIngestAnomalyAlert alert notifications are enabled.
   * If not display a message suggesting user should configure alert notifications.
   */
  readonly hasNotificationsConfigured$: Observable<boolean> = forkJoin({
    mcmRules: this.alertNotificationRules.GetNotificationRules({ accountId: this.salesforceAccountId }),
    dmsRules: this.alertNotificationRules.GetNotificationRules({ accountId: this.dmsAccountId }),
  }).pipe(
    map(({ mcmRules = [], dmsRules = [] }) => {
      const rules = [].concat(mcmRules, dmsRules);
      return rules?.some(
        notification => isNil(notification?.alertNames) || notification?.alertNames?.includes('DataIngestAnomalyAlert')
      );
    }),
    shareReplay(1)
  );

  /**
   * Check if ransomware dashboard updates feature flag is enabled
   */
  readonly ransomwareUnifiedDashboardEnabled = flagEnabled(this.irisCtx.irisContext, 'ransomwareUnifiedDashboard');

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: AntiRasnomwareSettingsDialogInput,
    private ajaxService: AjaxHandlerService,
    private alertNotificationRules: AlertNotificationRulesServiceApi,
    private configurationApiService: ConfigurationApiService,
    private heliosSecurityService: SecurityServiceApi,
    private irisCtx: IrisContextService,
    private snackbar: SnackBarService,
    private translateService: TranslateService,
    private windowRef: WindowRef,
    readonly dialogRef: MatDialogRef<AntiRansomwareSettingsComponent>
  ) {
    super();
  }

  ngOnInit() {
    this.getCurrentSettings();
  }

  /**
   * Gets settings for logged in user
   */
  getCurrentSettings() {
    this.loading = true;
    combineLatest([
      this.heliosSecurityService.GetAnomalyAlertNotifLevel(),
      this.showOtherScanOptions ? this.configurationApiService.getConfig() : of({} as DataHawkConfig),
    ])
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.loading = false))
      )
      .subscribe(([{ notificationInfo, taggingInfo }, configs]) => {
        const { blockRestore, tagName } = taggingInfo || {};
        this.form.get('threshold').setValue(notificationInfo?.anomalyStrengthThreshold);
        this.form.get('blockRestore').setValue(blockRestore);
        this.form.get('tagName').setValue(tagName);

        this.form.get('dataSensitivityScans').setValue(configs?.classificationDetails?.triggerOnIncidence ?? false);
        this.form.get('threatScans').setValue(configs?.threatDetectionDetails?.triggerOnIncidence ?? false);
      }, this.ajaxService.handler);
  }

  /**
   * Update settings after user clicks save button.
   */
  onSubmit() {
    const { threshold, blockRestore, tagName } = this.form.value;
    this.loading = true;

    // Assign current form value
    const body: AnomalyAlert = {
      notificationInfo: { anomalyStrengthThreshold: threshold },
      taggingInfo: { blockRestore, tagName },
    };

    const configBody: DataHawkConfig = {
      threatDetectionDetails: {
        triggerOnIncidence: this.form.value.threatScans,
      },
      classificationDetails: {
        triggerOnIncidence: this.form.value.dataSensitivityScans,
      },
    };

    combineLatest([
      this.heliosSecurityService.UpdateAnomalyAlertNotifLevel(body),
      this.showOtherScanOptions && (this.form.get('threatScans').dirty || this.form.get('dataSensitivityScans').dirty)
        ? this.configurationApiService.updateConfig({ body: configBody })
        : of({}),
    ])
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        () => {
          this.snackbar.open(this.translateService.instant('settingsUpdated'));
          this.dialogRef.close(true);
        },
        error => this.ajaxService.handler(error)
      );
  }

  /** Take user to datahawk product page */
  upgradePlan() {
    this.windowRef.openExternalLink(dgProductPage);
  }
}
