import { isAllClustersScope, getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, CanAccess, StateAccessMap } from 'src/app/app-module.config';

const heliosSecurityDashboardAllAccess: CanAccess = ctx =>
  ctx.FEATURE_FLAGS.securityDashboardEnabled && ctx.isClusterUser &&
  !ctx.isTenantUser && isAllClustersScope(ctx.irisContext);

const heliosSecurityDashboardCyberScanAccess: CanAccess = ctx => {
  const hiddenItems = getConfigByKey(ctx.irisContext, 'securityDashboard.hiddenItems', []);
  return ctx.FEATURE_FLAGS.securityDashboardEnabled && ctx.isClusterUser &&
  !ctx.isHeliosTenantUser && isAllClustersScope(ctx.irisContext)
  && !hiddenItems.includes('cyberScan');
};

const heliosSecurityDashboardAdvisorOrgAccess: CanAccess = ctx =>
  ctx.FEATURE_FLAGS.securityDashboardEnabled && ctx.isClusterUser &&
  ctx.FEATURE_FLAGS.heliosSecurityAdvisorEnabled && !ctx.isHeliosTenantUser && isAllClustersScope(ctx.irisContext);

/**
 * Summary dashboard is single cluster state.
 * This tab should only be visible in all cluster state.
 */
const singleClusterState = 'dashboards';

export const SecurityConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'security.**',
      url: '/monitoring/security',
      loadChildren: () => import('./security.module').then(m => m.SecurityModule),
    },
    // We need to lazy load this state as well since its accessible from legacy UI.
    // Aggregates alerts page -> DataIngestAlert redirects to this state.
    {
      name: 'anti-ransomware-details.**',
      url: '/monitoring/security/anti-ransomware/{id: string}',
      loadChildren: () => import('./security.module').then(m => m.SecurityModule),
    },
  ],
  allClusterMap: {
    'security': {
      heliosOnly: true,
      allClustersState: 'security',
      singleClusterState,
    },
    'security.anti-ransomware': {
      heliosOnly: true,
      allClustersState: 'security.anti-ransomware',
      singleClusterState,
    },
    'anti-ransomware-details': {
      heliosOnly: true,
      allClustersState: 'anti-ransomware-details',
      singleClusterState,
    },
    'anti-ransomware-recovery': {
      heliosOnly: true,
      allClustersState: 'anti-ransomware-recovery',
      singleClusterState,
    },
    'security.cyberscan': {
      heliosOnly: true,
      allClustersState: 'security.cyberscan',
      singleClusterState,
    },
    'security.security-scores': {
      heliosOnly: true,
      allClustersState: 'security.security-scores',
      singleClusterState,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'security': heliosSecurityDashboardAllAccess,
      'security.anti-ransomware': heliosSecurityDashboardAllAccess,
      'anti-ransomware-details': heliosSecurityDashboardAllAccess,
      'anti-ransomware-recovery': heliosSecurityDashboardAllAccess,
      'security.cyberscan': heliosSecurityDashboardCyberScanAccess,
      'security.security-scores': heliosSecurityDashboardAdvisorOrgAccess,
    };
  }
};
