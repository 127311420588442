import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { ClearSubscriptions } from '@cohesity/utils';

import { OnChange, TypedControlValueAccessor } from '../typed-control-value-accessor';
import { DurationSelectorValue, DurationUnit } from './duration-selector.model';
import { convertValueToSeconds } from '../utils';

/**
 * Renders a CVA enabled control to select a duration.
 *
 * @example
 * Simple Usage:
 *   <coh-duration-selector
 *     [formControl]="formControl"
 *     [minValue]="min"
 *     [maxValue]="max">
 *   </coh-duration-selector>
 */
@Component({
  selector: 'coh-duration-selector',
  templateUrl: './duration-selector.component.html',
  styleUrls: ['./duration-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DurationSelectorComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: DurationSelectorComponent, multi: true },
  ],
})
export class DurationSelectorComponent
  extends ClearSubscriptions
  implements TypedControlValueAccessor<DurationSelectorValue>, OnInit, AfterViewInit, Validator {
  /**
   * The minimum allowed value.
   */
  @Input() minValue: DurationSelectorValue;

  /**
   * The maximum allowed value.
   */
  @Input() maxValue: DurationSelectorValue;

  /**
   * List of duration units.
   */
  @Input() durationUnits = new Set<DurationUnit>(['Hours', 'Minutes', 'Seconds']);

  /**
   * The form group to power up the UI controls.
   */
  readonly form = new UntypedFormGroup({
    measurement: new UntypedFormControl(null, Validators.required),
    unit: new UntypedFormControl(null, Validators.required),
  });

  /**
   * Callback to pass the change event back to a form control. Defaults to a noop.
   */
  onChange: OnChange<DurationSelectorValue>;

  /**
   * Returns whether any of the form group controls has errors.
   */
  get hasControlErrors(): boolean {
    return Object.values(this.form.controls).some(control => Boolean(control.errors));
  }

  ngOnInit(): void {
    this.form.addValidators(this.validate.bind(this));
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.form.valueChanges.subscribe(value => this.onChange(value)));
  }

  // Implementation of ControlValueAccessor interface.

  writeValue(value: DurationSelectorValue) {
    this.form.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: OnChange<DurationSelectorValue>) {
    this.onChange = fn;
  }

  registerOnTouched() {}

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  // Implementation of Validator interface.
  validate(form: UntypedFormGroup): ValidationErrors | null {
    if (!form.value || (!this.minValue && !this.maxValue) || (!form.touched && !form.dirty)) {
      return null;
    }

    const errors: ValidationErrors = {};
    const valueInSeconds = convertValueToSeconds(form.value);

    if (this.minValue && valueInSeconds < convertValueToSeconds(this.minValue)) {
      errors.min = true;
    }

    if (this.maxValue && valueInSeconds > convertValueToSeconds(this.maxValue)) {
      errors.max = true;
    }

    return Object.keys(errors).length ? errors : null;
  }
}
