import { isArray } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { assign } from 'lodash-es';
// MODULE: Alerts Settings Module
; (function (angular) {
  'use strict';

  angular
    .module('C.alerts')
    .controller('AlertsTabSettingsCtrl', AlertsTabSettingsCtrl)
    .component('cAlertsSettingComponent', {
      controller: 'AlertsTabSettingsCtrl as $ctrl',
      template: require('raw-loader!./settings.html').default,
    })
    .controller('NotificationRuleCtrl', NotificationRuleCtrl);

  function NotificationRuleCtrl(_, evalAJAX, ruleNotificationConfig,
    AlertService, ENUM_ALERTS_SEVERITY, $uibModalInstance, $rootScope) {
    var $ctrl = this;

    assign($ctrl, {
      alertNotificationForm: {},
      config: ruleNotificationConfig || {
        emailDeliveryTargets: [],
      },
      alertTypes: [],
      alertCategories: [],
      alertSeverities: Object.keys(ENUM_ALERTS_SEVERITY),
      isTenantUser: false,
      useWebhook: false,
      $onInit: $onInit,
      ok: ok,
      parseAlertRule: parseAlertRule,
      onCategoryChange: onCategoryChange,
      addCategory: addCategory,
      addAlertName: addAlertName,
      addAlertSeverity: addAlertSeverity,
      filterAlertTypes: filterAlertTypes,
      parseAlertTypes: parseAlertTypes,
      setWebhookToggle: setWebhookToggle,
      refreshItems: refreshItems
    });

    /**
     * Controller $onInit method.
     *
     * @method  $onInit
     */
    function $onInit() {
      parseAlertRule($ctrl.config);
      // Must first fetch AlertCategories to ensure the enum has been generated
      // and cached.
      AlertService.getAlertCategories(true).finally(
        function afterFetchedAlertCategories() {
          $ctrl.alertCategories =
            sortBy(AlertService.getCategoryFilters(), 'name');
        }
      );
      $ctrl.isTenantUser = $rootScope.isTenantUser();
      _getAlertTypes();
    }

    /**
     * Handles Alert Category selector onSelect and onRemove events.
     *
     * @method  onCategoryChange
     */
    function onCategoryChange() {
      _getAlertTypes();
    }

    function refreshItems(search) {
      $scope.items = [...$scope.allItems];
    }

    /**
     * Handles Alert Category change.
     *
     * @method  addCategory
     */
    function addCategory() {
      const unique = Array.from(new Set($ctrl.config.categories));
      $ctrl.config.categories = unique;
    }

    /**
     * Handles Alert Name change.
     *
     * @method  addAlertName
     */
    function addAlertName() {
      const unique = Array.from(new Set($ctrl.config.alertTypeList));
      $ctrl.config.alertTypeList = unique;
    }

    /**
     * Handles Alert Severities change.
     *
     * @method  addAlertSeverity
     */
    function addAlertSeverity() {
      const unique = Array.from(new Set($ctrl.config.severities));
      $ctrl.config.severities = unique;
    }
    /**
     * Filters configured Alert Types based on available Alert Type list.
     *
     * @method filterAlertTypes
     */
    function filterAlertTypes() {
      if (angular.isArray($ctrl.config.alertTypeList)) {
        [$ctrl.config.alertNames, $ctrl.config.alertTypeList] = AlertService.mapAlertNamesAndList($ctrl.alertTypes, $ctrl.config.alertTypeList, 'value', 'name');
      } else if(angular.isArray($ctrl.config.alertNames)) {
        [$ctrl.config.alertNames, $ctrl.config.alertTypeList] = AlertService.mapAlertNamesAndList($ctrl.alertTypes, $ctrl.config.alertNames, 'name', 'value');
      }
    }

    /**
     * Parses server-side Alert Types to UI Alert Types array.
     *
     * @mathod parseAlertTypes
     * @param  {Array}  data  Server-side Alert Type array.
     */
    function parseAlertTypes(data) {
      var alertTypes;
      if (angular.isArray(data)) {
        alertTypes = data.map(function alertTypesMap(alertType) {
          return {
            name: alertType.alertDocumentList?.[0].alertName,
            value: alertType.alertTypeId,
          };
        });

        alertTypes = sortBy(alertTypes, ['name']);

        $ctrl.alertTypes = alertTypes;
      }
    }

    function setWebhookToggle(webhookVal) {
      if(webhookVal) {
        $ctrl.useWebhook = true;
      } else {
        $ctrl.useWebhook = false;
        $ctrl.config.webHookDeliveryTargets = [];
      }
    }

    /**
     * Loads Alert Types from server.
     *
     * @method  _getAlertTypes
     */
    function _getAlertTypes() {
      var params = {};

      if (angular.isArray($ctrl.config.categories) &&
        $ctrl.config.categories.length > 0) {
        params.alertCategoryList = $ctrl.config.categories.join(',');
      }

      AlertService.getAlertTypes(params)
        .then(function getAlertTypesResolve(types) {
          parseAlertTypes(types);
          filterAlertTypes();
        });
    }

    /**
     * Parses alert notification rule with additional data.
     *
     * @method  parseAlertRule
     * @param   {object}  alertRule   Alert notification rule config.
     */
    function parseAlertRule(alertRule) {
      if (!angular.isArray(alertRule.webHookDeliveryTargets)) {
        alertRule.webHookDeliveryTargets = [];
      }

      if (alertRule.webHookDeliveryTargets.length > 0) {
        $ctrl.useWebhook = true;
      }

      if (!isArray(alertRule.emailDeliveryTargets)) {
        alertRule.emailDeliveryTargets = [];
      }
    }

    /**
     * Submits form and closes modal window.
     *
     * @method  ok
     */
    function ok() {
      $ctrl.submitting = true;
      delete $ctrl.config._emailListLimit;
      filterAlertTypes();
      const config = AlertService.transformAlertNotificationV1ToV2($ctrl.config);
      AlertService
        .saveAlertNotificationRule(config)
        .then(function alertNotificationSaveResolve(data) {
          $uibModalInstance.close(data);
        })
        .catch(evalAJAX.errorMessage)
        .finally(function alertNotificationSaveFinally() {
          $ctrl.submitting = false;
        });
    }
  }

  function AlertsTabSettingsCtrl(_, evalAJAX, $q, cModal, cUtils, AlertService, $translate,
    ENUM_ALERTS_SEVERITY) {
    var $ctrl = this;

    /**
     * Limit number of emails visible initially.
     * @const {number}
     */
    var EMAIL_LIST_LIMIT = 3;

    assign($ctrl, {
      alertNotificationRules: [],
      alertCategoriesMap: {},
      alertTypeMap: {},
      loadingAlertNotificationRules: true,
      openNotificationRuleModal: openNotificationRuleModal,
      deleteNotificationRule: deleteNotificationRule,
      $onInit: $onInit,
      parseAlertTypes: parseAlertTypes,
      parseAlertNotifications: parseAlertNotifications,
      parseAlertCategories: parseAlertCategories,
      listAlertCategories: listAlertCategories,
      listSeverities: listSeverities,
      listAlertTypes: listAlertTypes,
      toggleShowWebhookOption: toggleShowWebhookOption,
    });

    /**
     * Controller $onInit method.
     *
     * @method  $onInit
     */
    function $onInit() {
      $q.all([
        AlertService.getAlertCategories().then(parseAlertCategories),
        AlertService.getAlertTypes().then(parseAlertTypes),
      ]).finally(function alertsQueueResolve() {
        _loadAlertNotificationRules();
      });
    }

    /**
     * Parses alert types data to alertTypeMap hash map.
     *
     * @method parseAlertTypes
     * @param  {Array} alertTypesData  Alert types backend data.
     */
    function parseAlertTypes(alertTypesData) {
      alertTypesData.forEach(function forEachAlertType(alertType) {
        if (angular.isArray(alertType.alertDocumentList)) {
          $ctrl.alertTypeMap[alertType.alertTypeId] =
            alertType.alertDocumentList[0].alertName;
        }
      });
    }

    /**
     * Parses Alert Notification Rules data.
     *
     * @method parseAlertNotifications
     * @param  {Array} data  Alert notification rules data array.
     */
    function parseAlertNotifications(data) {
      if (angular.isArray(data)) {
        $ctrl.alertNotificationRules = data.map(
          function alertRuleForEach(alertRule) {
            alertRule._emailListLimit = EMAIL_LIST_LIMIT;
            if (!!alertRule.categories) {
              alertRule.categories = alertRule.categories
                .filter(function filterAlertRuleCategories(alertCategory) {
                  return !!$ctrl.alertCategoriesMap[alertCategory];
                })
            }
            return alertRule;
          });
      } else {
        $ctrl.alertNotificationRules = [];
      }
    }

    /**
     * Parses Alert Categories to alertCategoriesMap hash map.
     *
     * @method parseAlertCategories
     * @param  {Array} data  Alert categories data array.
     */
    function parseAlertCategories(data) {
      if (angular.isArray(data)) {
        data.forEach(function forEachFn(category) {
          $ctrl.alertCategoriesMap[category.category] = category.name;
        });
      }
    }

    /**
     * Reloads alert notification rules.
     *
     * @method  _loadAlertNotificationRules
     */
    function _loadAlertNotificationRules() {
      $ctrl.loadingAlertNotificationRules = true;
      AlertService.loadAlertNotificationRules()
        .then(function loadAlertNotificationRulesResolve(res) {
          res = AlertService.transformAlertNotificationV2ToV1(res);
          parseAlertNotifications(res?.data);
        })
        .catch(evalAJAX.errorMessage)
        .finally(function loadAlertNotificationRulesFinally() {
          $ctrl.loadingAlertNotificationRules = false;
        });
    }

    /**
     * Creates alert rule delete confirmation modal.
     *
     * @method  _openAlertRuleDeleteModal
     * @param   {object}  alertRule  Alert notification rule config.
     * @return  {object}             Angular $http promise.
     */
    function _openAlertRuleDeleteModal(alertRule) {
      return cModal.standardModal({}, {
        titleKey: 'alertNotificationRuleDeleteConfirm',
        contentKey: 'alertNotificationRuleDeleteText',
        contentKeyContext: {
          alertRuleName: cUtils.escapeHtml(alertRule.ruleName),
        },
        actionButtonKey: 'delete',
        closeButtonKey: 'cancel',
      });
    }

    /**
     * Deletes specified alert notification rule.
     *
     * @method  deleteNotificationRule
     * @param   {Object}  alertRule   Alert notification rule.
     */
    function deleteNotificationRule(alertRule) {
      _openAlertRuleDeleteModal(alertRule)
        .then(function alertDeleteConfirmResolve() {
          AlertService.deleteAlertNotificationRule(alertRule)
            .then(function deleteNotificationRuleResolve() {
              _loadAlertNotificationRules();
            })
            .catch(evalAJAX.errorMessage);;
        });
    }

    /**
     * Opens Alert Notification Rule config modal.
     *
     * @method  openNotificationRuleModal
     * @param   {Object}  [notificationConfig]     Optional alert notification
     *                                            rule config object.
     */
    function openNotificationRuleModal(notificationConfig) {
      var modalConfig = {
        size: 'lg',
        controller: 'NotificationRuleCtrl',
        templateUrl: 'app/monitoring/alerts/modals/' +
          'notification-rule-modal.html',
        resolve: {
          ruleNotificationConfig: function resolveRuleNotification() {
            return notificationConfig;
          },
        },
      };

      var modalOptions = {
        titleKey: notificationConfig ? 'editAlertNotificationRule' :
          'addAlertNotificationRule',
        actionButtonKey: 'save',
        closeButtonKey: 'cancel',
      };

      cModal
        .standardModal(modalConfig, modalOptions)
        .then(function resolveNotificationRule() {
          _loadAlertNotificationRules();
        }, angular.noop);
    }

    /**
     * Return list of comma-separated list of configured alert categories.
     *
     * @method  listAlertCategories
     * @param   {object}  alertRule  Alert notification rule config.
     * @return  {object}             Comma-separated list of alert categories.
     */
    function listAlertCategories(alertRule) {
      if (angular.isArray(alertRule.categories)) {
        return alertRule.categories
          .map(function mapAlertRuleCategories(alertCategory) {
            return $ctrl.alertCategoriesMap[alertCategory];
          }).join(', ');
      }

      return $translate.instant('all');
    }

    /**
     * Return list of comma-separated list of configured alert severities.
     *
     * @method  listSeverities
     * @param   {object}  alertRule  Alert notification rule config.
     * @return  {object}             Comma-separated list of alert severities.
     */
    function listSeverities(alertRule) {
      if (angular.isArray(alertRule.severities)) {
        return alertRule.severities
          .map(function severitiesTranslateMap(severityEnum) {
            var value = ENUM_ALERTS_SEVERITY[severityEnum];
            if (value) {
              return $translate.instant(value.toLowerCase());
            }
            return '';
          }).join(', ');
      }
      return $translate.instant('all');
    }

    /**
     * Return list of comma-separated list of configured alert types.
     *
     * @method  listAlertTypes
     * @param   {object}  alertRule  Alert notification rule config.
     * @return  {object}             Comma-separated list of alert types.
     */
    function listAlertTypes(alertRule) {
      if (angular.isArray(alertRule.alertTypeList)) {
        return alertRule.alertTypeList
          .map(function mapAlertTypeIds(alertTypeId) {
            return $ctrl.alertTypeMap[alertTypeId];
          }).join(', ');
      }

      if (angular.isArray(alertRule.alertNames)) {
        return alertRule.alertNames.join(', ');
      }

      return $translate.instant('all');
    }

    /**
     * Toggle curl option for a webhook target.
     *
     * @method  toggleShowWebhookOption
     * @param   {webHookDeliveryTarget}  WebhookDeliveryTarget Webhook object.
     */
    function toggleShowWebhookOption(webHookDeliveryTarget) {
      webHookDeliveryTarget._showOption = !webHookDeliveryTarget._showOption
    }
  }
})(angular);
