import { isObject } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { get } from 'lodash-es';
// Helper service for sharing clone functions among the controllers.
(function _CloneService(angular) {
  angular
    .module('C.CloneService', ['C.CloneServiceFormatter', 'C.searchService'])
    .service('CloneService', CloneService);

  /**
   * @ngdoc service
   * @name C.CloneService
   *
   * @description
   * A service for sharing clone operations.
   *
   * @param    {Object}   _                                App dependency.
   * @param    {Object}   API                              App dependency.
   * @param    {Object}   ENV_TYPE_CONVERSION              App dependency.
   * @param    {Object}   FEATURE_FLAGS                    App dependency.
   * @param    {Object}   AGENT_MIN_VERSION_PIT_RESTORE    App dependency.
   * @param    {Object}   RESTORE_TASK_UI_STATUS           App dependency.
   * @param    {Object}   CloneServiceFormatter            App dependency.
   * @param    {Object}   cMessage                         App dependency.
   * @param    {Object}   evalAJAX                         App dependency.
   * @param    {Object}   SearchService                    App dependency.
   * @param    {Object}   SlideModalService                App dependency.
   * @param    {Object}   moment                           App dependency.
   * @param    {Object}   $http                            App dependency.
   * @param    {Object}   $q                               App dependency.
   * @param    {Object}   $rootScope                       App dependency.
   *
   * @return   {Object}   The service object.
   */
  function CloneService(_, API, ENV_TYPE_CONVERSION, FEATURE_FLAGS,
    AGENT_MIN_VERSION_PIT_RESTORE, RESTORE_TASK_UI_STATUS,
    CloneServiceFormatter, cMessage, evalAJAX, SearchService,
    SlideModalService, moment, $http, $q, $rootScope) {
    return {
      confirmCloneRefresh: confirmCloneRefresh,
      isOlderAgent: isOlderAgent,
      isRefreshable: isRefreshable,
      openPitSelectionModal: openPitSelectionModal,
      refreshClone: refreshClone,
    };

    /**
     * Returns whether a clone can be refreshed.
     *
     * @method   isRefreshable
     * @param    {Object}    task   An object of the clone task.
     * @return   {boolean}   `true` if the clone is refreshable, `false`
     *                       otherwise.
     */
    function isRefreshable(task) {
      // For clone refresh, a task must be in these states.
      var refreshableTaskStates = [
        RESTORE_TASK_UI_STATUS.kSuccess,
        RESTORE_TASK_UI_STATUS.kRefreshError,
      ];

      // Check if feature flag is enabled.
      if (!FEATURE_FLAGS.oracleCloneRefresh) {
        return false;
      }

      // Only users belonging to task owner organization can issue refresh
      // request.
      if (!task._isTaskOwner) {
        return false;
      }

      // Check privileges.
      if (!$rootScope.user.privs.CLONE_MODIFY) {
        return false;
      }

      // Check for task type. Only Oracle DBs are allowed to be refreshed.
      if (get(task, '_cloneAppInfo._taskType') !== ENV_TYPE_CONVERSION
        .kOracle) {
        return false;
      }

      // Check for task status.
      if (!refreshableTaskStates.includes(task._status)) {
        return false;
      }

      return true;
    }

    /**
     * Shows a confirmation modal to the users asking to confirm the clone
     * refresh action.
     *
     * @method   confirmCloneRefresh
     * @param    {Object}   cloneTask  The clone task object. The source entity
     *                                 will be fetched based on this task.
     * @return   {Object}   A uibModal promise.
     */
    function confirmCloneRefresh(cloneTask) {
      var modalConfig = {
        resolve: {
          innerComponent: 'cloneRefreshConfirm',
          actionButtonKey: 'confirm',
          closeButtonKey: 'cancel',
          idKey: 'cloneRefresh',
          titleKey: 'cloneRefresh.confirmModal.title',
          bindings: {
            cloneTask: cloneTask,
          },
        },
        autoHeight: true,
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Creates a clone refresh task.
     *
     * Specifying `snapshot` means that the task will perform a point-in-time
     * clone refresh. If you want to refresh the clone with the latest snapshot
     * of the `entity`, don't specify `snapshot`.
     *
     * @method   refreshClone
     * @param    {Object}   cloneTask         An object of clone task which is
     *                                        to be refreshed.
     * @param    {Object}   entity            The source entity of the clone
     *                                        task.
     * @param    {Object}   snapshot          The selected entity snapshot.
     * @param    {number}   refreshTimeSecs   The point-in-time for refresh.
     * @return   {Object}   Promise which resolves when the clone refresh task
     *                      is created.
     */
    function refreshClone(cloneTask, entity, snapshot, refreshTimeSecs) {
      return $http.post(
        API.public('restore/applicationsClone/refresh'),
        CloneServiceFormatter.getRefreshPayload(cloneTask, entity, snapshot,
          refreshTimeSecs)
      );
    }

    /**
     * Opens modal to select a point-in-time in the backup snapshot for clone
     * refresh.
     *
     * @method   openPitSelectionModal
     * @param    {Object}   task   The clone task object.
     * @return   {Object}   A promise, which is resolved with the created task.
     */
    function openPitSelectionModal(task) {
      return _getSourceEntity(task)
        .then(function entityFound(entity) {
          // Extract the host object from task.
          var taskState = task.performRestoreTaskState.restoreAppTaskState;
          var app = taskState.restoreAppParams.restoreAppObjectVec[0];
          var host = app.restoreParams.targetHost;

          var modalOpts = {
            templateUrl: 'app/protection/recovery/db/db-modal-snapshot.html',
            controller: 'dbSnapshotModalController as $ctrl',
            size: 'full',
            resolve: {
              task: cloneDeep(
                task.performRestoreTaskState.restoreAppTaskState),
              timezone: { name: moment.tz.guess() },
              entity: entity,
              restoreTimeSecs: null,
              copyAttachOpts: {
                isClone: true,
                recoverToOriginalServer: false,
              },
              isOlderAgent: isOlderAgent(host),
            },
          };

          return SlideModalService
            .newModal(modalOpts)
            .then(function snapshotSelectedFn(response) {
              var refreshTimeSecs;

              if (response.isPIT) {
                refreshTimeSecs = get(response.task,
                  'restoreAppParams.restoreAppObjectVec[0].restoreParams' +
                  '.oracleRestoreParams.restoreTimeSecs');

                return refreshClone(task, entity, response.snapshot,
                  refreshTimeSecs);
              }

              return refreshClone(task, entity, response.snapshot);
            })
            .then(function taskCreated() {
              cMessage.success({
                textKey: 'cloneRefresh.tasks.created',
              });
            })
            .catch(function handleError(error) {
              // If this promise was rejected by API call, `error` will always
              // be an object. In such cases, we need to handle it. On the other
              // hand, this promise can also be rejected when the modal is
              // dismissed (using Cancel button, or by pressing escape key). In
              // such cases, we want to silently ignore the rejection.
              if (isObject(error)) {
                evalAJAX.errorMessage(error);
              }
            });
        })
        .catch(evalAJAX.errorMessage);
    }

    /**
     * Returns if the agent running on target server is outdated to support PIT
     * clone refresh.
     *
     * @method   isOlderAgent
     * @param    {Object}    host   The host object from the clone task.
     * @return   {boolean}   `true` if the agent is outdated, `false` otherwise.
     */
    function isOlderAgent(host) {
      var agentVersion = get(host,
        'physicalEntity.agentStatusVec[0].properties.agentSwVersionStr');

      // Agent version strings always start with the branch name. The release
      // branch names start with integer, while development branch names,
      // generally, don't.
      // Thus, compare the major version number, and return appropriately. Even
      // if the major version number is not found, assume that we're on dev
      // branch, and return `false`.
      var agentVersionRx =
        new RegExp('^[1-' + (AGENT_MIN_VERSION_PIT_RESTORE - 1) + '][\\\D]');

      return agentVersionRx.test(agentVersion);
    }

    /**
     * Returns the source entity for the clone task.
     *
     * @method   _getSourceEntity
     * @param    {Object}   task    The clone task object.
     * @return   {Object}   A promise, which is resolved with the entity object.
     */
    function _getSourceEntity(task) {
      return SearchService
        .dbSearch({entityIds: task._cloneAppInfo._sourceEntityId},
          'databases', false)
        .then(function searchSucceeded(response) {
          if (response && response.length) {
            return response[0];
          }

          return $q.reject({ data: { message: 'noSnapshotsFound' } });
        });
    }
  }
})(angular);
