import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: Recover Amazon RDS: Snapshot Selector Modal

(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.rdsRecovery';

  angular
    .module(moduleName)
    .component('rdsSnapshotSelector', {
      bindings: {
        /**
         * @type {Object} The restore task object
         */
        task: '<',
        /**
         * @type {String} The timezone to be used in timepicker
         */
        timezone: '<',
        /**
         * @type {Object} Entity for which snapshot is to be selected
         */
        entity: '<',
        /**
         * @type {Number} The present time selection
         */
        restoreTimeSecs: '<',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      },
      templateUrl: 'app/protection/recovery/rds/rds-snapshot-selector.html',
      controller: 'rdsSnapshotSelectorCtrl',
    })
    .controller('rdsSnapshotSelectorCtrl', rdsSnapshotSelectorCtrlFn);

  function rdsSnapshotSelectorCtrlFn(_, $filter, $q,
    RestoreService, SearchService, DateTimeService, JobService,
    LOCALE_SETTINGS) {

    var $ctrl = this;

    assign($ctrl, {
      // GENERAL VARS
      fullDateTimeFormat: DateTimeService.getFullDateTimeFormat(),
      restoreTime: {},
      showMetaRow: {},
      dateOptions: {},
      invalidRestoreTimeMessage: null,

      // Methods
      cancel: cancel,
      changeDate: changeDate,
      changeSlider: changeSlider,
      getRestoreTimeSecs: getRestoreTimeSecs,
      getSnapshotIndex: getSnapshotIndex,
      makeLabel: makeLabel,
      saveSnapshotSelection: saveSnapshotSelection,
      setInvalidPitMessage: setInvalidPitMessage,
      setSnapshot: setSnapshot,

      // Controller lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method $onInit
     */
    function $onInit() {
      var entity = $ctrl.entity;
      var promises = {
        job: JobService.getJob(entity.vmDocument.objectId.jobId),
      };

      assign($ctrl, {
        _snapshotIndex: entity._snapshotIndex,
        canUsePitCalendar: true,
        currentTimezone: $ctrl.timezone.name,
        preferredFormat: LOCALE_SETTINGS.preferredFormat,
        recoveryPointsCount: entity._versions ? entity._versions.length : 0,
        snapshotsDate: entity._snapshot.instanceId.jobStartTimeUsecs/1000 ||
          entity._restoreTimeSecs*1000,
      });

      promises.versions = $q.resolve(entity._versions);

      $q.all(promises)
        .then(function versionsReceivedFn(responses) {
          $ctrl.job = responses.job;

          if (responses.versions.length) {
            entity._versions = responses.versions;

            // Set the snapshot recovery points for the database
            $ctrl.recoveryPointsCount = responses.versions.length;
          }

          setSnapshot(
            entity._versions[entity._snapshotIndex || 0],
            entity._archiveTarget
          );
          setOptsForSelectedSnapshot();
        });
    }

    /**
     * Generates the label string for each cRangeSlider in the view.
     *
     * @method     makeLabel
     * @param      {string}  val     The label value
     * @return     {string}  The computed label string
     */
    function makeLabel(val) {
      return ['<small>', '</small>']
        .join($filter('usecsToDate')(val * 1000,
          $ctrl.fullDateTimeFormat,
          $ctrl.currentTimezone));
    }

    /**
     * Update restoreTime.date with a new value.
     *
     * @method     changeDate
     * @param      {date}  newDateTime  Slider's returned Date object
     */
    function changeDate(newDateTime) {
      $ctrl.restoreTime.date = new Date(+newDateTime || Date.clusterNow());
      $ctrl.setInvalidPitMessage(null);
    }

    /**
     * Update restoreTime.slider with the given value
     *
     * @method     changeSlider
     * @param      {Date}  date   Date object
     */
    function changeSlider(date) {
      $ctrl.restoreTime.slider = +date;
    }

    /**
     * Set the opts for any previously selected snapshot & restore time.
     *
     * @method     setOptsForSelectedSnapshot
     */
    function setOptsForSelectedSnapshot() {
      var entity = $ctrl.entity;
      var restoreTimeSecs = $ctrl.restoreTimeSecs;
      var timestamp =
        entity._versions[entity._snapshotIndex].instanceId.jobStartTimeUsecs;

      $ctrl.restoreTime.date = restoreTimeSecs ?
        DateTimeService.secsToDate(restoreTimeSecs) : undefined;

      changeSlider($ctrl.restoreTime.date);

      $ctrl.showMetaRow[timestamp] = !!restoreTimeSecs;
    }

    /**
     * Set the snapshotIndex and cleanup some things.
     *
     * @method   setSnapshot
     * @param    {object}   snapshot          The restore point selected.
     * @param    {object}   [archiveTarget]   The selected archive target.
     */
    function setSnapshot(snapshot, archiveTarget) {
      $ctrl._snapshotIndex = getSnapshotIndex(snapshot);
      $ctrl.snapshot = snapshot;
      $ctrl.restoreTime = {};
      $ctrl.showMetaRow = {};
    }

    /**
     * Get the snapshot index.
     *
     * @method   getSnapshotIndex
     * @param    {object}    snapshot   Snapshot version
     * @return   {integer}   The found index
     */
    function getSnapshotIndex(snapshot) {
      var index = -1;

      if (snapshot) {
        $ctrl.entity._versions.some(function findIndex(row, ii) {
          if (snapshot.instanceId.jobStartTimeUsecs ===
            row.instanceId.jobStartTimeUsecs) {
            index = ii;

            return true;
          }
          return false;
        });
      }

      return index;
    }

    /**
     * Update the modal-scoped copy of the task with updated restoreTime data &
     * the selected snapshot
     *
     * @return   {object}   The updated task object.
     */
    function updateTaskCopy() {
      $ctrl.task.restoreTimeSecs = getRestoreTimeSecs();
      return $ctrl.task;
    }

    /**
     * Gets the restore time seconds.
     *
     * @return   {integer}   The selected point-in-time restore time in seconds
     */
    function getRestoreTimeSecs() {
      return !$ctrl.canUsePitCalendar ?
        Math.round($ctrl.restoreTime.date / 1000) :
        $ctrl.selections.pointInTime;
    }


    /**
     * Check if the chosen restore point is valid and save if it is. If not,
     * show some messaging about why not and stay in this modal.
     *
     * @method   saveSnapshotSelection
     */
    function saveSnapshotSelection() {
      var selections;

      // This is the object returned by this modal
      var result;

      selections = get($ctrl, 'selections', {});

      assign($ctrl.entity, {
        _snapshot: selections.snapshot,
        _snapshotIndex: getSnapshotIndex(selections.snapshot),
        _archiveTarget: selections.replica,
      });

      result = {
        selectedIndex: getSnapshotIndex(selections.snapshot),
        snapshot: selections.snapshot,
        archiveTarget: selections.replica,
        pointInTime: selections.pointInTime,
        task: angular.copy(updateTaskCopy()),
        isPIT: selections.isPIT,
      };

      $ctrl.cModalHoc.save(result);
    }

    /**
     * Callback for the validate-pit directive. It is called with a message
     * received from API when the restore time data is not found.
     *
     * @method   setInvalidPitMessage
     * @param    {string}   message   The message to be set.
     */
    function setInvalidPitMessage(message) {
      $ctrl.invalidRestoreTimeMessage = message;
    }

    /**
     * Cancel form submission and close the modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.cModalHoc.close();
    }
  }
})(angular);
