import { some } from 'lodash-es';
import { find } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: PublicSourceServiceFormatter utility service.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.pubSourceServiceFormatter', [])
    .service('PubSourceServiceFormatter', PubSourceServiceFormatterFn);

  function PubSourceServiceFormatterFn(_, cUtils, ENV_TYPE_CONVERSION,
    ENUM_HOST_TYPE_CONVERSION, PUB_TO_PRIVATE_ENV_STRUCTURES) {

    // This Service's API
    return {
      extractAagInfo: extractAagInfo,
      hasNodeFailedHealthChecks: hasNodeFailedHealthChecks,
      isFilestreamDatabase: isFilestreamDatabase,
      isSameSource: isSameSource,
      isSystemDatabase: isSystemDatabase,
      transformSourceFromPublicToPrivate: transformSourceFromPublicToPrivate,
    };

    /**
     * Extracts AAG info from a given node in an EntityHierarchy.
     *
     * @method   extractAagInfo
     * @param    {object}   node   The node
     * @return   {object}   Map of AAGs this node is aware of (by aagId).
     */
    function extractAagInfo(node) {
      return _getSqlSourcesFromHost(node).reduce(
        function entitiesReducer(aags, sqlNode) {
          var aagId = sqlNode.sqlProtectionSource.id.databaseId;

          if (!aagId || !sqlNode.sqlProtectionSource.dbAagName) { return aags; }

          if (!aags[aagId]) {
            aags[aagId] = {
              // TODO(jeff): Investigate renaming this to nodes[] or similar.
              entities: [],
              id: aagId,
              dbAagName: sqlNode.sqlProtectionSource.dbAagName || aagId,
              databaseName: sqlNode.sqlProtectionSource.databaseName,
            };
          }

          // Will be true if any AAG nodes are unknown to Cohesity.
          // Using $prefix so ngRepeat ignores it. This is desired.
          aags.$hasUnknownAagNodes =
            aags.$hasUnknownAagNodes || !!sqlNode.unknownHostName;

          aags[aagId].entities.push(sqlNode);

          return aags;
        },
        {}
      );
    }

    /**
     * Gets the sql entities from host.
     *
     * @method   _getSqlSourcesFromHost
     * @param    {object}   node   EntityHierarchy node.
     * @return   {array}    Array of found SQL entities.
     */
    function _getSqlSourcesFromHost(node) {
      // This needs to work with multiple API calls, so it checks for either
      // applicationNodes or applications[].applicationTreeInfo.
      var applications = node.applicationNodes ||
        get(find(node.applications, {'environment': 'kSQL'}),
        'applicationTreeInfo');
      var foundSqlEntities = !!node &&
        Array.isArray(applications) &&
        applications.reduce(
          function reduceToSqlEntities(sqlSourcesHash, childNode) {
            if (childNode.protectionSource.environment !== 'kSQL') {
              return sqlSourcesHash;
            }

            (childNode.nodes || []).forEach(
              function eachSubNode(subNode) {
                if (sqlSourcesHash[subNode.protectionSource.id]) { return; }

                sqlSourcesHash[subNode.protectionSource.id] =
                  subNode.protectionSource;
              }
            );

            return sqlSourcesHash;
          },
          {}
        );

      return Object.values(foundSqlEntities);
    }

    /**
     * Utility function to compare two sources and determine if they represent
     * the same source
     *
     * @method   isSameSource
     * @param    {object}    source1   source object
     * @param    {object}    source2   source object
     * @return   {boolean}   match or no
     */
    function isSameSource(source1, source2) {
      return !!(source1 && source2 && source1.id === source2.id);
    }

    /**
     * construct private source from public source used when trying
     * source re-registration.
     * compatible only with kPhysical and KO365
     * TODO(Tauseef): Remove this when public API for update protection
     * source is available.
     *
     * @method   transformSourceFromPublicToPrivate
     * @param    {Object}   source   The public source
     * @return   {Object}   private source object for the public source
     */
    function transformSourceFromPublicToPrivate(source) {
      var node = cUtils.simpleCopy(source);
      if (!node._environment) {
        node._environment = node.environment;
      }
      var envStructure = PUB_TO_PRIVATE_ENV_STRUCTURES[node._environment];
      var privNode = { entity: {} };
      var privEntity = privNode.entity[envStructure.privateEntityKey] = {};

      // re-construct entity
      angular.extend(privNode.entity, {
        displayName: node.protectionSource.name,
        id: node.protectionSource ?
          node.protectionSource.id : node.id,
        type: ENV_TYPE_CONVERSION[node._environment],
      });

      // Default to name for all sources.
      privEntity.name = privNode.entity.displayName;

      // re-construct Env specific entity
      if (node._environment === 'kPhysical') {
        assign(privEntity, {
          type: envStructure.sourceEntityTypes.kHost,
          hostType: ENUM_HOST_TYPE_CONVERSION[node._envProtectionSource.hostType],
          name: node._envProtectionSource.name,
          uid: {
            part1: node._envProtectionSource.id.clusterId,
            part2: node._envProtectionSource.id.clusterIncarnationId,
            part3: node._envProtectionSource.id.id,
          },
        });

        assign(privNode, {
          entityInfo: {
            endpoint: node.registrationInfo.accessInfo.endpoint,
            hostType: privNode.entity.physicalEntity.hostType,
            type: privNode.entity.type,
          },
        });
      }

      if (node._environment === 'kO365') {
        var office365CredentialsList = [];
        assign(office365CredentialsList, node.office365CredentialsList);
        assign(privNode, {
          entityInfo: {
            credentials: {
              username: node.username,
              password: node.password,
              msGraphCredentialsVec: !!node.office365CredentialsList ?
                office365CredentialsList : {},
            },
            endpoint: node.endpoint,
            useOutlookEwsOauth: node.useOAuthForExchangeOnline,
            office365Region: node.office365Region,
          },
        });

        let publicType = get(node.protectionSource, 'type',
          get(node.protectionSource.office365ProtectionSource, 'type'));
        assign(privEntity, {
          type: PUB_TO_PRIVATE_ENV_STRUCTURES.kO365.entityTypes[publicType],
          driveId: get(node.protectionSource.office365ProtectionSource,
            'userInfo.oneDriveId'),
        });
      }

      return privNode;
    }

    /**
     * Determines if the given entity is a System Database or not.
     *
     * NOTE: This is presently untested and unused.
     *
     * @method   isSystemDatabase
     * @param    {object}     protectionSource   The entity to check
     * @return   {boolean}    True if is system DB. False otherwise.
     */
    function isSystemDatabase(protectionSource) {
      return (/\/(master|msdb|model)$/i).test(protectionSource.name);
    }

    /**
     * Determines whether a given entity is a file stream database or not.
     *
     * @method    isFilestreamDatabase
     * @param     {object}    envProtectionSource   The entity to check.
     * @return    {boolean}   True if is a file stream db. False otherwise.
     */
    function isFilestreamDatabase(envProtectionSource) {
      return some(get(envProtectionSource, 'dbFiles', []),
        function isFilestream(file) {
          return file.fileType === 'kFileStream';
        });
    }

    /**
     * Determines if the given node has failed health checks.
     *
     * @method    hasNodeFailedHealthChecks
     * @param     {Object}    node   The node to inspect.
     * @returns   {boolean}   True if this node has failed health checks.
     */
    function hasNodeFailedHealthChecks(node) {
      // registrationInfo.registeredAppsInfo[].hostSettingsCheckResults[]
      return some(node.registrationInfo.registeredAppsInfo, appInfo =>
        some(appInfo.hostSettingsCheckResults, test =>
            test.resultType !== 'kPass')
      );
    }
  }

})(angular);
