import { pullAt } from 'lodash-es';
import { chain } from 'lodash-es';
import { orderBy } from 'lodash-es';
import { mapKeys } from 'lodash-es';
import { inRange } from 'lodash-es';
import { partial } from 'lodash-es';
import { merge } from 'lodash-es';
import { groupBy } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { filter } from 'lodash-es';
import { reduce } from 'lodash-es';
import { forEach } from 'lodash-es';
import { map } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
import { multiply } from 'lodash-es';
// Service: Snapshot Selection (shared by calendar widget and PIT range widget)

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.snapshotSelection', ['rzModule'])
    .constant('UI_PIT_SUPPORTED_ENVIRONMENTS', ['kSQL', 'kOracle', 'kVMware'])
    .service('SnapshotSelectionService', SnapshotSelectionServiceFn);

    /* @ngInject */
    function SnapshotSelectionServiceFn(
      _, DateTimeService, SearchService, PubJobService, $q, evalAJAX,
      RestoreService, moment, ENV_TYPE_CONVERSION, ENV_GROUPS, FEATURE_FLAGS, NgRecoveryServiceApi) {

      /**
       * Cache of calendar settings.
       */
      var calendarCache = resetCalendarCache();
      return {
        getCalendarDependencies: getCalendarDependencies,
        getEventsMap: getEventsMap,
        getMsecStartOfDay: getMsecStartOfDay,
        getRunsDatesRange: getRunsDatesRange,
        getSnapshotPickerDependencies: getSnapshotPickerDependencies,
        resetCalendarCache: resetCalendarCache,
        setCalendarDate: setCalendarDate,
      };

      /**
       * Generates a new calendar config cache object.
       *
       * @method   resetCalendarCache
       * @return   {object}   The new cache object.
       */
      function resetCalendarCache() {
        return calendarCache = {
          usableDates: [],
          pickerOptions: {
            dateDisabled: dateDisabled,
            // TODO (spencer): Uncomment this when we're ready to show tooltips
            // on each calendar day (ETA 6.2)
            // eventsTemplate:
            //   'app/global/c-snapshots-selector/c-snapshots-calendar-date-popup.html',
            maxMode: 'day',
            minMode: 'day',
            mode: 'day',
            ngModelOptions: {
              allowInvalid: false,
            },
          },
        };
      }

      /**
       * Sets the chosen calendar date in the cache.
       *
       * @method   setCalendarDate
       * @param    {object|number}   JS Date or ms/us number.
       * @return   {number}          The input date converted to ms.
       */
      function setCalendarDate(value) {
        return calendarCache.snapshotsDate = getMsecStartOfDay(value);
      }

      /**
       * List Restore Points i.e. returns the snapshots in in a given time range.
       *
       * @param params Specifies the request parameters to restore points for time range API.
       * @returns GetRestorePointsInTimeRange API Promise.
       */
      function getRestorePointsInTimeRange(params, jobUids, protectionGroupIds) {
        if(FEATURE_FLAGS.enableV2ApiForDbAdaptor) {
          return NgRecoveryServiceApi.GetRestorePointsInTimeRange({
            ...params,
            protectionGroupIds,
          })?.toPromise();
        }
        return RestoreService.getRestorePointsForTimeRange({
          ...params,
          jobUids,
          protectionSourceId: params.sourceId,
        });
      }

      /**
       * Fetch the dependencies as defined by type IDs.
       *
       * @method   getCalendarDependencies
       * @param    {object}           options                  Options object:
       * @param    {number}           options.entityId         Entity ID to get.
       * @param    {object|object[]}  options.jobUids          Job UID(s) of
       *                                                       jobs protecting
       *                                                       the Entity.
       * @param    {string}           [options.timezone]       timezone for
       *                                                       display.
       * @param    {string|number}    options.entityEnvironment
       *                                                       envType i.e.
       *                                                       kOracle|kSQL
       * @param    {number|number[]}  [options.remoteJobIds]   Remote Job ID(s)
       *                                                       of jobs
       *                                                       protecting
       *                                                       the Entity.
       * @return   {object}   Promise to resolve with the calendarConfig object.
       */
      function getCalendarDependencies(options) {
        var jobIds = options.remoteJobIds || map(options.jobUids, 'id');
        var hasDateOverrides = !!get(options, 'dates[1]');
        var dateRange;
        var depsPromises;

        // If dates are passed as options, use those.
        if (hasDateOverrides) {
          dateRange = [
            options.dates[0] * 1000,
            options.dates[1] * 1000,
          ].sort();
        } else {
          // Default dates to fetch.
          dateRange = [
            moment().subtract(1, 'year').toUsecDate(),
            moment().toUsecDate(),
          ];
        }

        var groupIds = options.jobUids.map(jobUid => {
          return `${jobUid.clusterId}:${jobUid.clusterIncarnationId}:${jobUid.objectId}`;
        });

        const protectionGroupIds = groupIds;
        const jobUids = options.jobUids;
        const params = {
          endTimeUsecs: dateRange[1],
          sourceId: options.entityId,
          environment: typeof options.entityEnvironment === 'string' ?
            options.entityEnvironment :
            ENV_TYPE_CONVERSION[options.entityEnvironment || 3],
          jobUids: options.jobUids,
          startTimeUsecs: dateRange[0],
        };

        depsPromises = {
          job: jobIds.length && PubJobService.getJob(jobIds[0].id),
          pit: getRestorePointsInTimeRange(params, jobUids, protectionGroupIds),
        };

        return $q.all(depsPromises)
          .then(function handleDepsResponses(resp) {
            // @type  {object}  Lodash chainable object of the possible
            //                  timeRanges array. These are sorted oldest to
            //                  newest.
            var _timeRanges = resp?.pit?.timeRangeInfo?.timeRanges || resp?.pit?.timeRanges || [];

            // @type  {object}  Lodash chainable object of the possible
            //                  fullSnapshotInfo array. These are sorted newest
            //                  to oldest
            var _fullSnapshots =
              chain(get(resp.pit, 'fullSnapshotInfo', []));

            // @type  {number}  The start time in ms of the oldest snapshot.
            //                  Defaults to cluster's "now."
            var oldestSnapshotStartTime = _fullSnapshots.last()
              .get('restoreInfo._startTimeMsecs', Date.clusterNow() * 1)
              .value();

            // @type  {number}  The start time in ms of the latest snapshot.
            //                  Defaults to the oldest snapshot time.
            var latestSnapshotStartTime = _fullSnapshots.first()
              .get('restoreInfo._startTimeMsecs', oldestSnapshotStartTime)
              .value();

            // @type  {number}  The start time in ms of the oldest log time
            //                  range. Defaults to the oldest snapshot time.
            var oldestTimeRangeStartTime = _timeRanges.first()
              .get('_startTimeMsecs', oldestSnapshotStartTime).value();

            // NOTE: Since the timeRanges array contains disjoint set of time
            // ranges which are non-overlapping and sorted by increasing start
            // time, hence the latestTimeRangeEndTime should pick the last
            // item's end time.
            //
            // @type  {number}  The end time in ms of the latest log time range.
            //                  Defaults to the latest snapshot time.
            var latestTimeRangeEndTime = _timeRanges.last()
              .get('_endTimeMsecs', latestSnapshotStartTime).value();

            assign(resetCalendarCache(), resp, {
              usableDates: _getUniqueDatesList(resp.pit),
              /**
               * These two properties represent the min and max dates to show on
               * the calendar. We must min/max compare timeRanges and
               * fullSnapshots from the PIT API in order to show dates with no
               * Full nor Incremental snapshots, only log snapshots (timeRanges
               * only) because Yoda search results do not contain log runs.
               */
              // Take the lesser of these two dates.
              // Note: We do this below conversions as calendar takes in
              //       JS Date object(for the user selected timezone)
              //       as input but sadly JS Date object doesn't support
              //       timezones. So we convert the timestamp to user selected
              //       timezone using momentjs and them extract just the
              //       dateTime from it(excluding tz) and create a new JS date
              //       object and paas it to the calendar.
              minDate: new Date(moment(
                Math.min(oldestSnapshotStartTime, oldestTimeRangeStartTime))
                  .tz(options.timezone)
                  .format(DateTimeService.getDateTimeInputFormat())
              ),

              // Take the greater of these two dates.
              maxDate: new Date(moment(
                Math.max(latestSnapshotStartTime, latestTimeRangeEndTime))
                  .tz(options.timezone)
                  .format(DateTimeService.getDateTimeInputFormat())
              ),
            });

            // This relies on calculations made in the previous assign block
            // so it has to execute separately.
            merge(calendarCache.pickerOptions, {
              maxDate: calendarCache.maxDate,
              minDate: calendarCache.minDate,
              minMode: 'day',
              maxMode: 'day',
              ngModelOptions: {
                allowInvalid: false,
              }
            });

            return calendarCache;
          });
      }

      /**
       * Gets all unique dates (start-of-day) with more than 0 runs of any kind,
       * including log data from the PIT response. This represents all the dates
       * with restorable data.
       *
       * @function   _getUniqueDatesList
       * @param      {Object}  [pit]   The PIT data from the pointsForTimeRange
       *                               API.
       * @returns    {Number[]}        The list of unique start-of-day dates
       *                               with restorable data.
       */
      function _getUniqueDatesList(pit) {
        var usableDates = [];

        if (isEmpty(pit)) { return usableDates; }

        // Extract each unique snapshot date at 12:00am
        usableDates = reduce(
          pit.fullSnapshotInfo,
          function eachSnapshot(dates, snapshot) {
            var startTime =
              getMsecStartOfDay(snapshot.restoreInfo._startTimeMsecs);

            if (!dates.includes(startTime)) {
              dates.push(startTime);
            }
            return dates;
          },
          []
        );

        // Look at timeRanges and extract each discrete date within each range.
        forEach(
          pit.timeRanges,
          function eachTimeRange(timeRange) {
            usableDates = usableDates.concat(
              DateTimeService.getDatesInRange(
                getMsecStartOfDay(timeRange._startTimeMsecs),
                getMsecStartOfDay(timeRange._endTimeMsecs)
              )
            );
          }
        );

        // Return unique dates as millisecond integers.
        return chain(usableDates)
          // This ensures the ranges of dates are integers before detecting
          // uniqueness.
          .map(partial(multiply, 1))
          .uniq()
          .value();
      }

      /**
       * Generates an events map from the given runs list from Elastic Search.
       *
       * The index key is the start of day for a date in ms, and can represent
       * 1-n number of runs on that date.
       *
       * Output = {
       *   '1531206000000': [ all runs on this date... ],
       *   ...
       * }
       *
       * @method   getEventsMap
       * @param    {array}    runs   The list of runs/versions.
       * @return   {object}   The map of events, indexed by common start of day
       *                      date.
       */
      function getEventsMap(runs) {
        return groupBy(runs, function dayMapper(version) {
          // Coerce the date to milliseconds for uibDatepicker compatibility.
          return getMsecStartOfDay(version.startedTimeUsecs);
        });
      }

      /**
       * Gets the min and max dates from the given map of events.
       *
       * @method   getRunsDatesRange
       * @param    {object}   events   The map of events (indexed by common date
       *                               in ms).
       * @return   {array}    A 2 element array of JS Date objects representing
       *                      the earliest and latest found dates in the events
       *                      map.
       */
      function getRunsDatesRange(events) {
        var range = Object.keys(events).sort();

        // Return an array of the first & last keys of the events map (earliest
        // and latest dates).
        return pullAt(range, [0, range.length - 1])
          // Convert the "string" date to a Date object.
          .map(function makeDates(date) { return new Date(+date); });
      }

      /**
       * Converts a microseconds date into a milliseconds date at the start of
       * that day (12:00am).
       *
       * @method   getMsecStartOfDay
       * @param    {number}   date   The microsecond date.
       * @return   {number}   The millisecond, start-of-day for the given
       *                      microsecond date.
       */
      function getMsecStartOfDay(date) {
        var conversionFactor =
          DateTimeService.isDateMicroseconds(date) ? 1000 : 1;

        // Massage the date to the start of the day. This way, if there are
        // multiple runs in a day, they will be grouped by this common base
        // date.
        return Number(
          DateTimeService.beginningOfDay(new Date(date / conversionFactor))
        );
      }

      /**
       * uibDatepicker handler for each date to determine if the date is
       * disabled or not selectable.
       *
       * @method   dateDisabled
       * @param    {object}    dateData   { date: JSdate, mode: 'mode string' }
       * @return   {boolean}   True if the calendar date is disabled. False
       *                       otherwise.
       */
      function dateDisabled(dateData) {
        var date = getMsecStartOfDay(dateData.date);

        return !calendarCache.usableDates.includes(date);
      }

      /**
       * Gets events specific uibDatepicker config options from the map of
       * events.
       *
       * @method   _getEventsOptions
       * @return   {object}   Event specific options.
       */
      function _getEventsOptions() {
        return {
          events: calendarCache.events,
          maxDate: calendarCache.maxDate,
          minDate: calendarCache.minDate,
        };
      }

      /**
       * Fetch the dependencies for selecting a snapshot or PIT.
       *
       * @method   getSnapshotPickerDependencies
       * @param    {object}            options            Query params
       * @param    {number}            options.entityId   Entity ID to query.
       * @param    {number|number[]}   [options.jobIds]   JobIds protecting
       *                                                  entity.
       * @param    {object}            [options.date]     Date to use (will
       *                                                  expand to start and
       *                                                  EOD).
       * @param    {boolean}           [options.noPit]    Whether PIT ranges are
       *                                                  shown. Shown by
       *                                                  default.
       * @param    {boolean}           [jobIdsHaveChanged=false]   Determines if
       *                                                           jobs data is
       *                                                           fetched again
       *                                                           or not.
       * @param    {array}           [jobUids]            The jobUid of the
       *                                                  recovered entity.
       * @return   {object}   Promise to resolve with the dependencies data.
       */
      function getSnapshotPickerDependencies(options, jobIdsHaveChanged, jobUids) {
        var entityId = options.entityId;
        var jobIds = [].concat(options.jobIds);
        var deferred = $q.defer();
        var ranges = [];
        var snapshotsHashByJobId = {};
        var pitPromise = $q.resolve({});
        var selectedDate = moment(options.date).tz(options.timezone);
        var depsPromises = {
          entity: SearchService.entitySearch({
            entityIds: entityId,
            jobIds: jobIds,

            // Add these dates to restrict results, and aid cachability. This
            // will query for objects who's runs have completed after the start
            // of the given date, but before the end of the following day.
            fromTimeUsecs: selectedDate.clone().startOf('day').toUsecDate(),
            toTimeUsecs:
              selectedDate.clone().add(1, 'day').endOf('day').toUsecDate(),
          }),
        };
        var versionsHash;
        var pitResponse;

        // Only fetch Jobs data if IDs have changed.
        if (jobIdsHaveChanged) {
          depsPromises.jobs = PubJobService.getJobs({
            ids: jobIds,
            onlyReturnBasicSummary: true,
            includeLastRunAndStats: false,
          });
        }

        $q.allSettled(depsPromises).then(function reduceVersions(results) {
          var aggregatedYodaResult;
          var typedDocumentKey;
          var startOfDay = selectedDate.startOf('day').valueOf();
          var endOfDay = selectedDate.endOf('day').valueOf();

          // Since we used allSettled, we need to pull each response up a level
          // from the alLSettled wrapper object. And surface any errors.
          forEach(results, function eachResponse(resp, promiseName) {
            if (resp.$status === 'error') {
              return evalAJAX.errorMessage(resp.resp);
            }

            results[promiseName] = resp.resp;
          });

          aggregatedYodaResult = reduce(
            results.entity,
            function mergeYodaResults(aggregatedResult, row, yy) {
              var thisTypedDoc;
              var inRangeVersions;

              typedDocumentKey =
                row.fileDocument ? 'fileDocument' : 'vmDocument';
              thisTypedDoc = row[typedDocumentKey];

              if (thisTypedDoc.objectId.entity.id !== entityId) {
                return aggregatedResult;
              }

              snapshotsHashByJobId[thisTypedDoc.objectId.jobId] =
                thisTypedDoc.versions;

              // Add this Job ID to each version, since versions only have
              // runId (instanceId).
              inRangeVersions = filter(
                thisTypedDoc.versions,
                function eachVersion(version, vv, list) {
                  var runMsecs = Math.floor(
                    version.instanceId.jobStartTimeUsecs/1000);
                  var inRange = inRange(runMsecs, startOfDay, endOfDay);

                  if (inRange) {
                    assign(version, {
                      _jobId: thisTypedDoc.objectId.jobId,
                      _jobUid: thisTypedDoc.objectId.jobUid,
                      _startTimeMsecs: runMsecs,
                    });
                  }

                  return inRange;
                }
              );

              thisTypedDoc.versions = inRangeVersions;

              if (!yy) {
                assign(aggregatedResult, row);
              } else {
                // add subsequent rows' versions into the aggregated versions.
                Array.prototype.push.apply(
                  aggregatedResult[typedDocumentKey].versions,
                  thisTypedDoc.versions
                );
              }

              aggregatedResult._jobUidsProtectingMe.push(
                thisTypedDoc.objectId.jobUid);

              return aggregatedResult;
            },
            { _jobUidsProtectingMe: [],
              _envType: options.entityEnvironment,
              _entityId: options.entityId,
            }
          );

          if (aggregatedYodaResult[typedDocumentKey]) {
            // Sort the aggregated versions by start time.
            aggregatedYodaResult[typedDocumentKey].versions = orderBy(
              aggregatedYodaResult[typedDocumentKey].versions,
              ['instanceId.jobStartTimeUsecs'],
              ['desc']
            );

            // Here we hash the reduced snapshot runs by their startTime in ms
            // for quick lookup.
            versionsHash =
              keyBy(aggregatedYodaResult[typedDocumentKey].versions,
                'instanceId.jobInstanceId');
          }

          if (!options.noPit) {
            // Now lets get the PIT ranges, if any are available.
            pitPromise = RestoreService.getRestorePointsForTimeRange(
              _getTimeRangesRequest(aggregatedYodaResult, options.date,
                options.timezone, jobUids)
            );
          }

          pitPromise.then(
            function pitRangesReceieved(results) {
              pitResponse = results;
              forEach(pitResponse.fullSnapshotInfo,
                function decorateEachSnapshot(snapshot) {
                  var yodaSnapshot = versionsHash[snapshot.restoreInfo.jobRunId];
                  if (yodaSnapshot) {
                    merge(
                      snapshot,
                      { restoreInfo: yodaSnapshot }
                    );
                  }
                }
              );
            })
            .catch(function handleRequestFail(response) {
              var entityType = get(
                aggregatedYodaResult[typedDocumentKey], 'objectId.entity.type'
              );

              if (ENV_GROUPS.databaseSources.includes(entityType)) {
                evalAJAX.errorMessage(response);
              }
            })

            // Resolving in the .finally handler because this can legitimately
            // fail and it doesn't prevent the user from interacting with the
            // snapshots list. The outer promise is resolved in this block.
            .finally(function pitFinally() {
              // Resolve with the data, as we have it. May or may not
              // contain `PITRanges` data.
              deferred.resolve({
                entity: aggregatedYodaResult,
                jobs: jobIdsHaveChanged ? results.jobs : undefined,
                PITRanges: ranges,
                pitResponse: pitResponse,
                versionsHash: versionsHash,
              });
            });
        });

        return deferred.promise;
      }

      /**
       * Gets/constructs the PIT timeRanges query params.
       *
       * @method   _getTimeRangesRequest
       * @param    {array}           entity               The entity to request
       *                                                  PIT ranges for.
       * @param    {object|number}   [fallbackDate=now]   A fallback date in ms.
       * @param    {string}          [timezone='Asia/Kolkata']
       *                                                  timezone
       * @param    {array}          [jobUid]             The jobUid of the
       *                                                  recovered entity.
       * @return   {object}          The timeRanges request object.
       */
      function _getTimeRangesRequest(entity, fallbackDate, timezone, jobUids) {
        var typedDoc = entity.fileDocument || entity.vmDocument;
        var isRds = entity._jobType
          === ENV_TYPE_CONVERSION.kRDSSnapshotManager;
        var _startTimeUsecs = get(
          typedDoc,
          'versions[0].instanceId.jobStartTimeUsecs',
          fallbackDate * 1000
        );
        var startTime =
          moment(_startTimeUsecs/1000).tz(timezone).startOf('day');
        var endTime = moment(_startTimeUsecs/1000).tz(timezone).endOf('day');

        var jobUidsProtectingMe = entity._jobUidsProtectingMe.length ?
          entity._jobUidsProtectingMe : jobUids;

        // Remap objectId to id for compatibility with uidProto.UniversalIdProto
        var mappedJobUids = map(
          jobUidsProtectingMe,
          function eachJobUid(jobUid) {
            return mapKeys(jobUid, function keyMapper(value, key) {
              if (key === 'objectId') {
                return 'id';
              }
              return key;
            });
          }
        );

        var environment = isRds ? 'kRDSSnapshotManager' : entity._envType;

        return {
          endTimeUsecs: endTime.toUsecDate(),
          protectionSourceId: entity._entityId,
          environment: environment,
          jobUids: mappedJobUids,
          startTimeUsecs: startTime.toUsecDate(),
        };
      }
    }

})(angular);
