import { ComponentType } from '@angular/cdk/portal';
import { Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { CreateRecoveryRequest, ProtectionGroupServiceApi, RecoveryServiceApi } from '@cohesity/api/v2';
import { SnackBarService, ValueFilterSelection } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { ControlsType } from 'ngx-sub-form';
import { BehaviorSubject, combineLatest, from, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  last,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { RpaasRecoveryUtilsService } from 'src/app/app-services/rpaas/shared';
import { PassthroughOptionsService, StateManagementService } from 'src/app/core/services';
import {
  Environment,
  RecoveryAction,
  Office365CSMBackupTypes,
  Office365SearchType,
  Office365SearchTypeMultipelSnapshotGlrEnabled
} from 'src/app/shared';

import { ObjectSearchService } from '../services/object-search.service';
import { RestoreConfigService } from '../services/restore-config.service';
import { RestoreService } from '../services/restore.service';
import { CreateRecoveryForm } from './create-recovery-form';
import { CreateRecoveryFormTransformer } from './create-recovery-form-transformer';
import { DynamicFormComponent } from './dynamic-form-component';
import { RecoveryFormConfig } from './recovery-form-options-provider';
import { RecoveryTransformerProperties } from './recovery-transformer-properties';
import {RecoverToOptions} from '../../restore-shared';

/**
 * This class contains common logic that can be used across recovery pages. It is intended to simplify and normalize
 * some of the logic across recovery pages, though it is not required to be used.
 * * This is fairly tightly coupled to the recover page components and many of the properties here
 * are intended to be accessed and modified directly from a component's template.
 *
 * In order for this to work, onInit() and onDestroy() must be called from ngOnInit and ngOnDestroy
 * in the main component.
 */
export class RecoveryComponentUtil {
  /**
   * This is set to true if the objects section is currently in edit mode. When this is true, the form
   * save and cancel buttons should be hidden as well as the recovery options section.
   */
  private _editingObjects = true;

  /**
   * This can be set to force the editing objects property to always be true. This can be used to prevent a workflow
   * from continuing when a snapshot selection is invalid - for isntance, if a user is attempting to do a fort knox
   * recovery from on prem.
   */
  private _blockContinueRecover = false;

  /**
   * This can be used to mark the state where one of the selected restore point cannot be recovered. If this is set,
   * recovery will be blocked
   */
  invalidRestorePointSelected = false;

  /**
   * This can be used to mark the state where for one of the selected restore point validity check is in progress.
   */
  restorePointValidityCheckInProgress = false;

  /**
   * The selected environment is derived from the object selection. Only one type of environment
   * can be active at a time.
   */
  environment$ = new BehaviorSubject<Environment>(undefined);

  /**
   * This is set to true when the page is first loaded via an abbreviated flow and the object details
   * are still being fetched. This should cause a spinner to show for the entire page content.
   */
  initializing$ = new BehaviorSubject<boolean>(false);

  /**
   * True if the recovery submission is in progress.
   */
  inProgress$ = new Subject<boolean>();

  /**
   * This displays the restore params form inside of a cdk component portal.
   */
  paramsComponent: ComponentType<DynamicFormComponent<any>>;

  /**
   * Request params to pass to the protection group filter.
   */
  protectionGroupRequestParams: ProtectionGroupServiceApi.GetProtectionGroupsParams;

  /**
   * Keep track of the sourceFilterValues, this can't be easily tracked by the view child
   * since those components aren't guaranteed to be set when the component initializes.
   * This uses the output event on the component to set the values which is more reliable.
   */
  sourceFilterValues = new BehaviorSubject<ValueFilterSelection[]>([]);

  /**
   * Request params to pass to the source filter.
   */
  sourceRequestParams: ProtectionSourcesServiceApi.ListProtectionSourcesRootNodesParams;

  /**
   * Keep track of the storageDomainFilterValues, this can't be easily tracked by the view child
   * since those components aren't guaranteed to be set when the component initializes.
   * This uses the output event on the component to set the values which is more reliable.
   */
  storageDomainFilterValues = new BehaviorSubject<ValueFilterSelection[]>([]);

  /**
   * Translate service to translate tokens.
   */
  translateService: TranslateService;

  /**
   * Snackbar service for showing success message.
   */
  snackBarService: SnackBarService;


  downloadService: RecoveryServiceApi;

  /**
   * Passthruogh options service for tracking the current cluster or region.
   */
  passthroughOptionsService: PassthroughOptionsService;

  /**
   * Utils service to check for rpaas recoveries.
   */
  rpaasRecoveryUtilsService: RpaasRecoveryUtilsService;

  /**
   * Selector for ngx-sub-form invalid control validation/error highlight.
   */
  invalidControlElementSelector = '.mat-form-field .ng-invalid';

  /**
   * The iris context service;
   */
  private irisCtx: IrisContextService;

  /**
   * Flag to check whether the recovery is for a CSM type of backup
   */
  private isCSM: boolean;

  /**
   * Provides access to the search service search pending value.
   */
  get searchPending$() {
    return this.searchService.searchPending$;
  }
  /**
   * Provides access to the search service search results.
   */
  get searchResults$() {
    return this.searchService.searchResults$;
  }

  /**
   * Used to clear the subscriptions in onDestroy()
   */
  private destroy = new Subject<void>();

  constructor(
    private ajaxService: AjaxHandlerService,
    private injector: Injector,
    private recoverService: RestoreService,
    private _recoveryAction: RecoveryAction | Function,
    private restoreConfig: RestoreConfigService,
    private searchService: ObjectSearchService,
    private stateManagementService: StateManagementService,
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    searchEnvironments: Environment[],
  ) {
    this.sourceRequestParams = {
      environments: (searchEnvironments || []).filter(
        env =>
          // kPhysicalFiles filter is not supported by the backend.
          ![Environment.kPhysicalFiles].includes(env)
      ) as any,
    };

    // Get the workload from state params
    const workloadType = this.uiRouterGlobals.params.office365WorkloadType;

    if (Office365CSMBackupTypes.includes(workloadType)) {
      this.isCSM = true;
    }

    this.protectionGroupRequestParams = {
      environments: searchEnvironments as any,
      office365Workloads: [workloadType],
    };

    this.translateService = this.injector.get(TranslateService);
    this.snackBarService = this.injector.get(SnackBarService);
    this.downloadService = this.injector.get(RecoveryServiceApi);
    this.passthroughOptionsService = this.injector.get(PassthroughOptionsService);
    this.rpaasRecoveryUtilsService = this.injector.get(RpaasRecoveryUtilsService);
    this.irisCtx = this.injector.get(IrisContextService);
  }

  /**
   * This can be set to force the editing objects property to always be true. This can be used to prevent a workflow
   * from continuing when a snapshot selection is invalid - for isntance, if aa user is attempting to do a fort knox
   * recovery from on prem.
   */
  get blockContinueRecover(): boolean {
    return this._blockContinueRecover || this.invalidRestorePointSelected;
  }

  /**
   * This can be set to force the editing objects property to always be true. This can be used to prevent a workflow
   * from continuing when a snapshot selection is invalid - for isntance, if aa user is attempting to do a fort knox
   * recovery from on prem.
   */
  set blockContinueRecover(blockContinueRecover: boolean) {
    this._blockContinueRecover = blockContinueRecover;
    if (this.blockContinueRecover) {
      this._editingObjects = true;
    }
  }

  /**
   * This is set to true if the objects section is currently in edit mode. When this is true, the form
   * save and cancel buttons should be hidden as well as the recovery options section.
   */
  get editingObjects(): boolean {
    return this._editingObjects;
  }

  /**
   * This is set to true if the objects section is currently in edit mode. When this is true, the form
   * save and cancel buttons should be hidden as well as the recovery options section.
   */
  set editingObjects(editingObjects: boolean) {
    // Don't allow editing if the flow is locked.
    this._editingObjects = this.blockContinueRecover || editingObjects;
  }

  /**
   * Whether to allow search from within the recovery flow or not. This is allowed in on prem, but not dms.
   */
  get hideSearch(): boolean {
    return isDmsScope(this.irisCtx.irisContext) || !!this.uiRouterGlobals.params.restorePoints;
  }

  /**
   * Get recovery action value based on what was supplied to the constructor.
   */
  get recoveryAction(): RecoveryAction {
    if (typeof this._recoveryAction === 'function') {
      // If recoveryAction is a function, call that to get the value.
      return this._recoveryAction();
    }

    return this._recoveryAction;
  }

  /**
   * Get the form configuration for the specified environment.
   *
   * @param   environment   The current environment.
   * @param   action   The recovery action.
   * @returns Configuration used to load the form component and transform the data model.
   */
  getRecoveryFormConfig(environment: string, action: RecoveryAction = this.recoveryAction): RecoveryFormConfig {
    return this.restoreConfig.getRecoveryFormConfig(environment, action);
  }

  /**
   * Look up info for a given source from the filter
   *
   * @param   id The source id to lookup
   * @returns The value of source filter entry
   */
  getSourceInfo(id: number): ValueFilterSelection {
    return this.sourceFilterValues.value.find(option => option.value === id);
  }

  /**
   * Look up info for a given storage domain from the filter
   *
   * @param   id The storage dmoain to lookup
   * @returns The value of storage domain filter entry
   */
  getStorageDomainInfo(id: number): ValueFilterSelection {
    return this.storageDomainFilterValues.value.find(option => option.value === id);
  }

  /**
   * Function to return the form transformer for the recovery adapter.
   *
   * @param environment Environment to return the form transformer of.
   * @param action The recovery action.
   * @return The form transformer.
   */
  getTransformer(environment, action: RecoveryAction = this.recoveryAction): CreateRecoveryFormTransformer<any> {
    return this.restoreConfig.getTransformer(environment, action);
  }
  /**
   * Go back to the previous page when the recovery is canceled.
   */
  goBack() {
    this.stateManagementService.goToPreviousState('recover');
  }

  /**
   * Looks up, creates, and inserts the form options component for the current environment.
   *
   * @param   objectsControl      Objects control.
   * @param   environment         The current form environment.
   * @param   objectParamsValue   Value for object params control.
   * @param   action              The recovery action.
   */
  insertFormOptions(
    objectsControl: AbstractControl,
    environment: Environment,
    objectParamsValue = null,
    action: RecoveryAction = this.recoveryAction
  ) {
    objectsControl.setValue(objectParamsValue);
    objectsControl.markAsPristine();

    this.paramsComponent = null;

    // TODO(maulik): Figure out non setTimeout solution to prevent
    // the recovery form to become invalid when restore object is
    // changed.
    window.setTimeout(() => {
      const {formComponent, invalidControlSelector} = this.getRecoveryFormConfig(environment, action);
      this.paramsComponent = formComponent;
      if(invalidControlSelector) {
        this.invalidControlElementSelector = invalidControlSelector;
      }
    });
  }

  /**
   * This must be called from the component's ngOnDestroy so that subscriptions are cleared.
   */
  onDestroy() {
    this.destroy.next();
  }

  /**
   * This must be called from the components ngOnInit to set up the environment observable
   *
   * @param   formGroupControls   The formGroupControls object.
   */
  onInit(formGroupControls: ControlsType<CreateRecoveryForm<any>>) {
    // search service can be null for some recoveries.
    const protectionType$ =
      this.searchService ? this.searchResults$.pipe(map(results => results?.[0]?.protectionType)) : of(null);
    // Select the correct form options whenever the selected environment or protection type changes.
    combineLatest([this.environment$, protectionType$])
      .pipe(
        takeUntil(this.destroy),
        filter(([environment]) => !!environment),
        distinctUntilChanged((previous, current) => previous[0] === current[0] && previous[1] === current[1]),
      ).subscribe(([environment]) => {
        this.insertFormOptions(formGroupControls.objectParams, environment);
      });
  }

  /**
   * Initiate the file recovery.
   *
   * @param   formValue   Recovery form value.
   * @param   messageText Recovery message info text.  Or pass undefined (not null) for the default message.
   * @param   properties  Any additional environment specific properties for
   *                      the transformer.
   */
  recover(
    formValue: CreateRecoveryForm<any>,
    messageText = this.translateService.instant('recoveryTaskSuccessfullyCreated'),
    properties: RecoveryTransformerProperties = {}
  ) {
    // Copy the router params as when used in the snack bar message, the user
    // is redirected out of the recovery page, which may not have the
    // passthrough router params present.
    const routerParams = {...this.passthroughOptionsService.routerParams};

    this.inProgress$.next(true);

    this.rpaasRecoveryUtilsService.quorumGroupsNeeded$(formValue).pipe(
      switchMap(quorumGroupsNeeded => {
        if (quorumGroupsNeeded) {
          this.inProgress$.next(false);

          return this.snackBarService.openWithAction(
            this.translateService.instant('rpaas.onboarding.quorumGroup.subtitle'),
            this.translateService.instant('goBack'),
          );
        }

        // We need to call the downloadFilesAndFoldersRecovery api to
        // initiate a download task for teams
        if ([Office365SearchType.kTeamContentSearch,
          Office365SearchType.kGroupSiteItemSearch].includes((formValue as any)?.recoveryType) &&
            formValue?.objectParams?.recoveryTarget?.type === RecoverToOptions.download) {
          // Get the api params for each distinct snapshot id
          const paramsList = this.transformFromFormGroup(formValue, true, properties) as any;

          return this.createDownloadFilesAndFoldersRecoveryTasks(paramsList);
        }

        const params = this.transformFromFormGroup(formValue, true, properties) as any;

        if(Array.isArray(params) &&
          Office365SearchTypeMultipelSnapshotGlrEnabled.includes((formValue as any)?.recoveryType)) {
          return this.createMutipleSnapshotsRecoveryTasks(params);
        }

        return this.recoverService
          .createRecovery(params)
          .pipe(
            takeUntil(this.destroy),
            finalize(() => this.inProgress$.next(false)),
            tap(recoverTask => {
              if ((recoverTask as any)?.quorumResponse?.id) {
                // Don't show a regular snackbar message if the recovery
                // task is a quorum request.
                return;
              }

              this.snackBarService
                .openWithAction(
                  recoverTask?.isMultiStageRestore ?
                    this.translateService.instant('migrationTaskSuccessfullyCreated') :
                    messageText,
                  this.translateService.instant('viewProgress')
                )
                .subscribe(() =>
                  this.stateService.go('recovery.detail', {
                    id: recoverTask.id,
                    ...routerParams,
                  })
                );
            })
          );
      }),
    ).subscribe(
      () => {
        this.goBack();
      },
      error => this.ajaxService.errorMessage(error)
    );
  }

  /**
   * Trigger creation of download tasks consecutively.
   *
   * @param   paramsList   Arrray of InternalApiCreateDownloadFilesAndFoldersRecoveryParams.
   */
  createDownloadFilesAndFoldersRecoveryTasks(paramsList:
    RecoveryServiceApi.InternalApiCreateDownloadFilesAndFoldersRecoveryParams[]) {
    const routerParams = {...this.passthroughOptionsService.routerParams};
    return from(paramsList)
    .pipe(
      takeUntil(this.destroy),
      // Making consecutive calls for each snapshotid
      concatMap((params, idx) =>
        this.downloadService.CreateDownloadFilesAndFoldersRecovery(params as any)
        .pipe(delay(idx < paramsList.length - 1 ? 300 : 0)) // add delay except for the last call
      ),
      last(),
      finalize(() => this.inProgress$.next(false)),
      catchError(error => {
        if (paramsList.length > 1) {
          // Show error for partial failure in case of multiple snapshots
          this.snackBarService.open(
            this.translateService.instant('office365Restore.msTeams.downloadMultipleSnapshotsFailure'), 'error', true);
          return of(error);
        }
        return throwError(error);
      }),
      tap(recoverTask => {
        this.snackBarService
          .openWithAction(
            this.translateService.instant('recoveryTaskSuccessfullyCreated'),
            this.translateService.instant('viewProgress')
          )
          .subscribe(() =>
            this.stateService.go('recovery.detail', {
              id: recoverTask.id,
              ...routerParams,
            })
          );
      })
    );
  }

  /**
   * Trigger creation of recovery tasks consecutively.
   *
   * @param   paramsList   Arrray of CreateRecoveryRequest.
   */
  createMutipleSnapshotsRecoveryTasks(paramsList:
    CreateRecoveryRequest[]) {
    const messageText = this.translateService.instant('recoveryTaskSuccessfullyCreated');
    const routerParams = {...this.passthroughOptionsService.routerParams};
    return from(paramsList)
    .pipe(
      takeUntil(this.destroy),
      // Making consecutive calls for each snapshotid
      concatMap((params, idx) =>
        this.recoverService.createRecovery(params as any)
        .pipe(delay(idx < paramsList.length - 1 ? 300 : 0)) // add delay except for the last call
      ),
      last(),
      finalize(() => this.inProgress$.next(false)),
      tap(recoverTask => {
        this.snackBarService
          .openWithAction(
            messageText,
            this.translateService.instant('viewProgress')
          )
          .subscribe(() =>
            this.stateService.go('recovery.detail', {
              id: recoverTask.id,
              ...routerParams,
            })
          );
      }),
      catchError(error => {
        if (paramsList.length > 1) {
          // Show error for partial failure in case of multiple snapshots
          this.snackBarService.open(
            this.translateService.instant('office365Restore.createMultipleRecoveriesFailure'), 'error', true);
          return of(error);
        }
        return throwError(error);
      })
    );
  }

  /**
   * Trigger a search on the search service when the search query changes.
   *
   * @param   query   The query to search for.
   */
  search(query: string) {
    this.searchService.searchQuery = query;
  }

  /**
   * Converts from the internal form group representation to the api model.
   *
   * @param   formValue   The form value.
   * @param   force       Without this flag, transform will not get through.
   * @param   properties  Any additional environment specific properties for
   *                      the transformer.
   *
   * @returns The api value.
   */
  transformFromFormGroup(
    formValue: CreateRecoveryForm<any>,
    force = false,
    properties: RecoveryTransformerProperties = {}
  ): CreateRecoveryRequest | null {
    if (!force) {
      // TODO(pg-vmr): Remove force. Currently, even with a manual save and non
      //  automatic NGX sub form, the transform is triggered with every change,
      //  and since several fields do not exist yet when transforming, this
      //  results in JS console errors.
      return;
    }

    if (!formValue.objects[0]) {
      return null;
    }

    const environment = formValue?.objects?.[0]?.objectInfo?.environment ||
      this.environment$.getValue();

    if (!environment) {
      return null;
    }

    properties = properties || {};
    properties.isCSM = !!this.isCSM;

    return this.getTransformer(environment).transformFromRecoveryForm(formValue, properties);
  }

  /**
   * Converts from the api model to the internal form group data representation.
   *
   * @param   obj   The api value.
   * @returns The form value.
   */
  transformToFormGroup(obj: CreateRecoveryRequest | null): CreateRecoveryForm<any> {
    if (!obj || !obj.snapshotEnvironment) {
      return null;
    }
    return this.getTransformer(obj.snapshotEnvironment).transformToRecoveryForm(obj);
  }
}
