import { isEqual } from 'lodash-es';
import { isEmpty } from 'lodash-es';
// Module: compile component

;(function (angular, undefined) {

  angular.module('C.compileComponent', [])
    .service('CompileComponentService', CompileComponentService)
    .component('cCompileComponent', {
      bindings:{
        /**
         * @type   {String}   component   The component name to render eg.
         *                                jobRuns, job-run, demoWidget
         */
        component: '@',

        /**
         * Optional bindings
         * @type   {Object}   bindings   The component bindings object
         * @example
         * bindings = {
         *    viewType:     'pie',
         *    data:         '$ctrl.chartData',
         *    titleKey:     'The title is {{$ctrl.name}}',
         *    onClick:      '$ctrl.handleOnClick(message)',
         *    required:     '',
         * }
         *
         * <c-compile-component
            component="jobRun"
            bindings="{{$ctrl.bindings}}"></c-compile-component>
         *
         * @result
         * <job-run
            required
            view-type="pie"
            data="$ctrl.chartData"
            title-key="The title is {{$ctrl.name}}"
            on-click="$ctrl.handleOnClick(message)"></job-run>
         */
        bindings: '@?',

        /**
         * @type   {Object}   compileScope   The scope Object to be used to
         *                                   compile the component else it will
         *                                   use the parent scope.
         */
        compileScope: '=?',
      },
      transclude: true,
      controller: cCompileComponentCtrl,
    });

  function cCompileComponentCtrl(_, $element, $attrs, $compile, $transclude,
    $log, CompileComponentService) {
    var $ctrl = this;
    var childScope;

    angular.extend($ctrl, {
      // component life cycle methods
      $onChanges: $onChanges,
      $onDestroy: $onDestroy,
    });

    /**
     * on destroy of c-compile component remove the child component properly
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      destroyComponent();
    }

    /**
     * Watch for binding changes and act on them accordingly.
     *
     * @method   $onChanges
     * @param    {Object}   changes   The changes
     */
    function $onChanges(changes) {
      var componentChanged = false;
      var bindingsChanged = false;

      componentChanged = changes.component && (
        changes.component.isFirstChange() ||
        !isEmpty(changes.component.currentValue) ||
        !isEqual(
          changes.component.currentValue,
          changes.component.previousValue
        )
      );

      try {
        bindingsChanged = changes.bindings && (
          changes.bindings.isFirstChange() ||
          !isEmpty(changes.bindings.currentValue) ||
          !isEqual(
            JSON.parse(changes.bindings.currentValue),
            JSON.parse(changes.bindings.previousValue)
          )
        );
      } catch(ex) {
        $log.error(
          'Component: c-compile-component',
          'error while parsing the component bindings',
          ex
        );
        destroyComponent();
        return;
      }

      // destroy & render when component or its bindings got updated.
      if (componentChanged || bindingsChanged) {
        destroyComponent();
        renderComponent();
      }
    }

    /**
     * render the component under c-compile-component.
     *
     * @method   renderComponent
     */
    function renderComponent() {
      // render the component when component name is present.
      if (isEmpty($ctrl.component)) {
        return;
      }

      // use parent scope for compiling and get it from transclude hook else use
      // provided compileScope scope.
      $transclude(function getParentScope(innerEl, parentScope) {
        // creating an inherited scope so that child component can be destroyed
        // on bindings or componentName updates.
        if (Object.hasOwnProperty.call($ctrl, 'compileScope')) {
          childScope = $ctrl.compileScope.$new();
        } else {
          childScope = parentScope.$new();
        }

        // attach the component html into the DOM.
        $element.html(
          CompileComponentService.buildHtml($ctrl.component, $ctrl.bindings)
        );

        try {
          // compile child element with correct scope and if error while
          // compiling destroy the component.
          $compile($element.contents())(childScope);
        } catch(ex) {
          $log.error(
            'Component: c-compile-component',
            'error while compiling the component',
            ex
          );
          destroyComponent();
          return;
        }

        // attach the rendered transcluded element inside child element if
        // present.
        if (innerEl.length) {
          $element.contents().html(innerEl);
        }
      });
    }

    /**
     * destroy rendered component under c-compile-component.
     *
     * @method   destroyComponent
     */
    function destroyComponent() {
      // empty inner html
      $element.html('');

      // destroy the child scope created for child component which will properly
      // remove the child component w/o any memory leaks.
      if (childScope) {
        childScope.$destroy();
      }
    }

  }

  /**
   * c-compile-component internal service used to build html from object
   * bindings
   *
   * @class    CompileComponentService (name)
   * @return   {Object}   Service's methods exposed
   */
  function CompileComponentService(_) {

    return {
      toLowerDash: toLowerDash,
      buildHtml: buildHtml,
    };

    /**
     * converted camelCase string to hyphen separated string.
     *
     * @example
     *   jobRuns   =>   job-runs
     *   isIE      =>   is-ie
     *
     * @method   toLowerDash
     * @param    {string}   string   The input string to convert
     * @return   {string}   The input hyphen separated string.
     */
    function toLowerDash(string) {
      return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    /**
     * Builds a component html from provided componentName and bindings.
     *
     * @method   buildHtml
     * @param    {String}   componentName   The component name
     * @param    {Object}   bindings        The bindings object
     * @return   {string}   The component html.
     */
    function buildHtml(componentName, bindings) {
      var attrs = [];
      var _bindings = {};

      // try parsing the bindings as JSON if error use default empty object
      try {
        _bindings = JSON.parse(bindings);
        _bindings = isEmpty(_bindings) ? {} : _bindings;
      } catch(ex) {
        _bindings = {};
      }

      angular.forEach(_bindings, function forEachBinding(value, key) {
        attrs.push(
          [
            toLowerDash(key),
            value === '' ? undefined : (
              '="' + (angular.isFunction(value) ? value() : value) + '"'
            )
          ].join('')
        );
      });

      return '<{component} {attrs}></component>'.
        replace('{attrs}', attrs.join(' ')).
        replace('{component}', toLowerDash(componentName));
    }
  }

})(angular);
