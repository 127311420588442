import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ClusterConfigList } from '@cohesity/api/argus';
import {
  BlankCardModule,
  DataFiltersModule,
  DataFilterValue,
  DataIdModule,
  IconModule,
  SpinnerModule,
  TableModule,
  ValueFilterSelection,
} from '@cohesity/helix';
import { NaLabelModule, YesNoModule } from '@cohesity/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash-es';

import { ClusterConfigFiltersComponent, setLocationOptions, setSoftwareVersionOptions } from '../cluster-config-filters';
import { ClusterHealthStatusIconPipe } from '../cluster-health-status-icon-pipe';

@Component({
  selector: 'dg-cr-cluster-order-list',
  templateUrl: './cluster-order-list.component.html',
  styleUrls: ['./cluster-order-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BlankCardModule,
    CommonModule,
    ClusterConfigFiltersComponent,
    ClusterHealthStatusIconPipe,
    DataIdModule,
    DataFiltersModule,
    IconModule,
    NaLabelModule,
    MatDividerModule,
    SpinnerModule,
    TableModule,
    TranslateModule,
    YesNoModule,
  ]
})
export class ClusterOrderListComponent {

  @Input() set clusterOrder(order: ClusterConfigList) {
    this.allClusters = order;
    this.softwareVersionOptions = setSoftwareVersionOptions(this.allClusters, this.translateService);
    this.locationOptions = setLocationOptions(this.allClusters, this.translateService);
    this.applyFilters(this.currentFilters);
  };

  @Input() scanOnReplicaEnabled: boolean;

  @Input() showScanOnReplicaEnabled = true;

  @Input() isMinimalMode = false;

  @Input() loading: boolean;

  filteredOrder: ClusterConfigList = [];

  get displayColumns(): string[] {
    return [
      'scanOrder',
      'clusterName',
      this.isMinimalMode ? null : 'connectionStatus',
      this.isMinimalMode ? null : 'datahawkAppStatus',
    ].filter(Boolean);
  }

  softwareVersionOptions: ValueFilterSelection[] = [];

  locationOptions: ValueFilterSelection[] = [];

  private allClusters: ClusterConfigList = [];

  private currentFilters: DataFilterValue<any, any>[] = [];

  constructor(
    private translateService: TranslateService
  ) { }

  applyFilters(filters: DataFilterValue<any, any>[]) {
    this.currentFilters = filters;
    this.filteredOrder = [...(this.allClusters || [])];
    filters.forEach(filter => {
      const filterValues = isArray(filter.value) ? filter.value.map(item => item.value) : [filter.value];

      switch (filter.key) {
        case 'clusterName':
          this.filteredOrder = this.allClusters.filter(cluster => cluster.clusterName.includes(filterValues[0]));
          break;
        case 'connectionStatus':
          if (filterValues.length) {
            this.filteredOrder = this.allClusters.filter(cluster => filterValues.includes(cluster.isConnectedToHelios));
          }
          break;
        case 'datahawkAppStatus':
          if (filterValues.length) {
            this.filteredOrder = this.allClusters
              .filter(cluster => filterValues.includes(cluster.isDataHawkAppStatusHealthy));
          }
          break;
        case 'softwareVersion':
          if (filterValues.length) {
            this.filteredOrder = this.allClusters
              .filter(cluster => filterValues.includes(cluster.currentVersion ?? 'unknown'));
          }
          break;
        case 'location':
          if (filterValues.length) {
            this.filteredOrder = this.allClusters
              .filter(cluster => filterValues.includes(cluster.location ?? 'unknown'));
          }
          break;
      }
    });
  }
}
