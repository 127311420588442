import { Injectable } from '@angular/core';
import { McmClusterServiceApi, McmUpgradeInfo } from '@cohesity/api/private';
import {
  ClusterScanResult,
  ClusterScanResults,
  SecurityAdvisorServiceApi,
  SecurityCriteriaResultList,
} from '@cohesity/api/secops';
import { clusterIdentifiers, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { mean } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { getRiskProperties } from '../utils';
import { securityCenterDashboards, SecurityCenterDashboardService } from './security-center-dashboard.service';

/**
 * Contains the context related to Security Posture Dashboard.
 */
@Injectable()
export class SecurityPostureDashboardService {
  /**
   * Indicates that the data is loading
   */
  isLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * The risk score (how much is the system at risk) of the system
   */
  riskScore = 0;

  /**
   * The time at which last scan was performed
   */
  lastScanTime: number;

  /**
   * The results of scans performed on system
   */
  scanResults: ClusterScanResult[];

  /**
   * Top Issues found in Scans
   */
  scanIssues: SecurityCriteriaResultList;

  /**
   * No of clusters connected to helios
   */
  totalClusterCount = 0;

  constructor(
    readonly ajaxHandlerService: AjaxHandlerService,
    private clusterService: McmClusterServiceApi,
    private irisCtx: IrisContextService,
    private securityAdvisorServiceApi: SecurityAdvisorServiceApi,
    readonly securityCenterDashboardService: SecurityCenterDashboardService,
    private translateService: TranslateService,
  ) {}

  /**
   * Load data for consumption
   */
  loadData() {
    const { irisContext } = this.irisCtx;

    // If there are no cluster identifiers, avoid calling the subsequent API calls. These
    // APIs depend on the availabillity of the cluster identifiers, and if they are not available
    // they fail.
    if (!clusterIdentifiers(irisContext).length) {
      return;
    }

    this.isLoading$.next(true);

    combineLatest([
      this.securityAdvisorServiceApi.GetScanResults(),
      this.securityAdvisorServiceApi.GetScanIssuesSummary({}),
      this.clusterService.getUpgradeInfo(),
    ]).pipe(
      finalize(() => this.isLoading$.next(false))
    ).subscribe(([scanResults, scanIssues, {upgradeInfo}]:
        [ClusterScanResults, SecurityCriteriaResultList, McmUpgradeInfo]) => {
      if (scanResults) {
        this.scanResults = scanResults.results;
        this.riskScore = Math.round(mean(this.scanResults.map(result => result.currentScore)));
        this.lastScanTime = Math.max(...this.scanResults.map(result => result.lastScanTime));
      }

      if (scanIssues) {
        this.scanIssues = scanIssues;
      }

      if (upgradeInfo) {
        this.totalClusterCount = upgradeInfo.length || 0;
      }

      this.computeDashboardStatus();

    }, error => this.ajaxHandlerService.handler(error));
  }

  /**
   * Determine the color and icon for the dashboard's tab
   */
  computeDashboardStatus() {
    this.securityCenterDashboardService.setDashboardTabStatus(
      securityCenterDashboards.securityPosture,
      getRiskProperties(this.translateService, this.riskScore).class
    );
  }
}
