import { forEach } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Field Messages

; (function (angular, undefined) {
  'use strict';

  angular
    .module('C.fieldMessages', [])
    .config(configFn)
    .controller('fieldMessagesCtrl', fieldMessagesCtrlFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('field-messages', {
        url: '^/monitoring/field-messages?' + '{id}',
        canAccess: '!$root.isHeliosTenantUser()',
        params: {
          id: {
            dynamic: true,
            value: undefined,
            squash: true,
          },
        },
        help: 'monitoring_field_messages',
        allClustersSupport: {
          heliosOnly: true,
          allClustersState: 'field-messages',
        },
        templateUrl: 'app/monitoring/field-messages/field-messages.html',
        controller: 'fieldMessagesCtrl as $ctrl',
      });
  }

  function fieldMessagesCtrlFn(_, evalAJAX,
    FieldMessageService, $translate, $transition$, moment) {
    var $ctrl = this;

    assign($ctrl, {
      loading: true,
      qParamId: undefined,

      // setup default filter values for start, end and max date.
      startDate: moment().startOf('day').subtract(1,'month').toDate(),
      endDate: moment().endOf('day').toDate(),
      maxDate: new Date(),

      // List of selected filters.
      activeFilters: [],

      // List of all field messages.
      fieldMessages: [],

      // Filter names with translations.
      filterTypes: {
        search: $translate.instant('search'),
        types: $translate.instant('type'),
      },

      // Model for all of the filters.
      // Dates are taken directly and hence not part of filters.
      filters: {
        types: [],
        search: '',
      },

      // List of functions.
      $onInit: $onInit,
      onFilter: onFilter,
      removeFilter: removeFilter,
      rowSelected: rowSelected,
    });

    /**
     * Initialize this component.
     *
     * @method   onInit
     */
    function $onInit() {
      // Get type filter
      _getFieldMessageFilters();

      // Check if query parameter is present
      _getQparamId();

      // Get all field messages
      _loadFieldMessages();
    }

    /**
     * Get field message filters.
     *
     * @method   getFieldMessageFilters
     */
    function _getFieldMessageFilters() {
      FieldMessageService.getFieldMessageFilters().then(
        function getFiltersSuccess(response) {
          // Backend hash of filter options.
          $ctrl.filterLabels = response;

          // Populate the filterOptions hash used by ui-select
          $ctrl.filterOptions = {};
          forEach($ctrl.filterLabels,
            function compileFilterTypes(values, key) {
              $ctrl.filterOptions[key] = [];
              forEach(values, function
                compileFilterTypeValues(name, value) {
                $ctrl.filterOptions[key].push({
                  value: value,
                  name: name
                });
              });
            }
          );
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Update active filters and load filtered messages.
     *
     * @method     onFilter
     */
    function onFilter() {
      _updateActiveFilters();
      _loadFieldMessages();
    }

    /**
     * Update the activeFilters list which is the model for the set of pills.
     *
     * @method     _updateActiveFilters
     */
    function _updateActiveFilters() {
      $ctrl.activeFilters = [];
      forEach($ctrl.filters, function forEachUpdateFilters(type, key) {
        switch (key) {
          case 'search':
            if (type) {
              $ctrl.activeFilters.push({ type: key, value: type });
            }
            break;
          case 'types':
            forEach(type, function forEachUpdateOtherFilter(filter) {
              $ctrl.activeFilters.push({ type: key, value: filter.value });
            });
            break;
        }
      });
    }

    /**
     * Remove filters.
     *
     * @method     removeFilter
     */
    function removeFilter(filter) {
      switch (filter.type) {
        case 'search':
          $ctrl.filters[filter.type] = undefined;
          break;
        case 'types':
          $ctrl.filters[filter.type].some(
            function removeFromFilters(item, index) {
              if (item.value === filter.value) {
                // Remove the clicked filter
                $ctrl.filters[filter.type].splice(index, 1);
                return;
              }
            }
          );

          // Also remove from the filterOptions used by c-multiselect
          $ctrl.filterOptions[filter.type].some(
            function removeFromFilterOptions(item, index) {
              if (item.value === filter.value) {
                $ctrl.filterOptions[filter.type][index].selected = false;
                return;
              }
            }
          );
          break;
      }

      // Refresh view according to new filters.
      $ctrl.onFilter();
    }

    /**
     * Get id from query param.
     *
     * @method     _getQparamId
     */
    function _getQparamId() {
      var qParams;
      qParams = $transition$.params();

      // Check if query param is present
      $ctrl.qParamId = qParams.id;
    }

    /**
     * Row selection to show modal.
     *
     * @method     rowSelected
     * @param      {object}  fieldMessage
     */
    function rowSelected(fieldMessage) {
      if (!fieldMessage) {
        return;
      }

      // Since description contains lot of unnecessary things
      // Extracting only required contents so that it can be rendered directly in modal.
      var result = fieldMessage.description.match('(?=<div id="description">)(.*)(<div id="endDesc">)');
      if (result) {
        fieldMessage.description = result[1];
      }
      FieldMessageService.openDetailModal(fieldMessage);
    }

    /**
     * Loads all fields messages according to filters.
     *
     * @method    _loadFieldMessages
     */
    function _loadFieldMessages() {
      var params = _assembleRequestObj($ctrl.filters);
      $ctrl.loading = true;
      FieldMessageService.getFieldMessages(params).then(
        function onSuccess(response) {
          $ctrl.fieldMessages = response;

          // Check if query param is present
          if ($ctrl.qParamId) {
            // Find element within response by id
            $ctrl.rowSelected($ctrl.fieldMessages.find(
              function getMessageById(message) {
                return message.id == $ctrl.qParamId;
              }
            ));
            $ctrl.qParamId = undefined;
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function onComplete() {
          $ctrl.loading = false;
        }
      );
    }

    /**
    * Build request object from model.
    *
    * @method     _assembleRequestObj
    * @param      {object}  filtersObj  The filter model object
    * @return     {object}  proper request object
    */
    function _assembleRequestObj(filtersObj) {
      var requestObj = {
        startTimeUsecs: $ctrl.startDate.getTime() * 1000,
        endTimeUsecs: $ctrl.endDate.getTime() * 1000,
      };

      // Add search text
      if (filtersObj.search) {
        requestObj.search = filtersObj.search;
      }

      // Add message type
      requestObj.types = (filtersObj.types || []).map(function mapTypes(type) {
        return type.value;
      });

      return requestObj;
    }
  }

})(angular);
