/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Recoveries } from '../models/recoveries';
import { Recovery } from '../models/recovery';
import { CreateRecoveryRequest } from '../models/create-recovery-request';
import { DownloadFilesAndFoldersRequestParams } from '../models/download-files-and-folders-request-params';
import { FetchUptierDataResponse } from '../models/fetch-uptier-data-response';
import { PvcInformationResponseParams } from '../models/pvc-information-response-params';
import { GetRestorePointsInTimeRangeResponse } from '../models/get-restore-points-in-time-range-response';
import { GetRestorePointsInTimeRangeParams } from '../models/get-restore-points-in-time-range-params';
import { McmRecoveryTasks } from '../models/mcm-recovery-tasks';
import { McmRecoveryTask } from '../models/mcm-recovery-task';
@Injectable({
  providedIn: 'root',
})
class RecoveryService extends __BaseService {
  static readonly GetRecoveriesPath = '/data-protect/recoveries';
  static readonly CreateRecoveryPath = '/data-protect/recoveries';
  static readonly CreateDownloadFilesAndFoldersRecoveryPath = '/data-protect/recoveries/download-files-folders';
  static readonly InternalApiCreateDownloadFilesAndFoldersRecoveryPath = '/data-protect/recoveries/downloadFilesAndFoldersRecovery';
  static readonly FetchUptierDataPath = '/data-protect/recoveries/fetch-uptier-data';
  static readonly PvcInformationPath = '/data-protect/pvc-information';
  static readonly GetRecoveryByIdPath = '/data-protect/recoveries/{id}';
  static readonly CancelRecoveryByIdPath = '/data-protect/recoveries/{id}/cancel';
  static readonly GetRecoveryDebugLogsPath = '/data-protect/recoveries/{id}/debug-logs';
  static readonly DownloadFilesFromRecoveryPath = '/data-protect/recoveries/{id}/download-files';
  static readonly GetRecoveryErrorsReportPath = '/data-protect/recoveries/{id}/download-messages';
  static readonly InternalApiDownloadFilesFromRecoveryPath = '/data-protect/recoveries/{id}/downloadFiles';
  static readonly TearDownRecoveryByIdPath = '/data-protect/recoveries/{id}/tear-down';
  static readonly DownloadIndexedFilePath = '/data-protect/snapshots/{snapshotsId}/download-file';
  static readonly InternalApiDownloadIndexedFilePath = '/data-protect/snapshots/{snapshotsId}/downloadFile';
  static readonly GetRestorePointsInTimeRangePath = '/data-protect/snapshots/restore-points';
  static readonly GetMcmRecoveriesPath = '/mcm/data-protect/recoveries';
  static readonly GetMcmRecoveriesByIdPath = '/mcm/data-protect/recoveries/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the Recoveries.
   *
   * Lists the Recoveries.
   * @param params The `RecoveryService.GetRecoveriesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the organizations for which recoveries are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only recoveries writing data to this Storage Domain will be returned.
   *
   * - `status`: Specifies the list of run status to filter Recoveries. If empty, Recoveries with all run status will be returned.
   *
   * - `startTimeUsecs`: Returns the recoveries which are started after the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `snapshotTargetType`: Specifies the snapshot's target type from which recovery has been performed.
   *
   * - `snapshotEnvironments`: Specifies the list of snapshot environment types to filter Recoveries. If empty, Recoveries related to all environments will be returned.
   *
   * - `returnOnlyChildRecoveries`: Returns only child recoveries if passed as true. This filter should always be used along with 'ids' filter.
   *
   * - `returnChildTasks`: If set to true, also allows child tasks created by restore jobs or multi-state restores to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `recoveryActions`: Specifies the list of recovery actions to filter Recoveries. If empty, Recoveries related to all actions will be returned.
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `ids`: Filter Recoveries for given ids.
   *
   * - `endTimeUsecs`: Returns the recoveries which are started before the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `archivalTargetType`: Specifies the snapshot's archival target type from which recovery has been performed. This parameter applies only if 'snapshotTargetType' is 'Archival'.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRecoveriesResponse(params: RecoveryService.GetRecoveriesParams): __Observable<__StrictHttpResponse<Recoveries>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.storageDomainId != null) __params = __params.set('storageDomainId', params.storageDomainId.toString());
    (params.status || []).forEach(val => {if (val != null) __params = __params.append('status', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    (params.snapshotTargetType || []).forEach(val => {if (val != null) __params = __params.append('snapshotTargetType', val.toString())});
    (params.snapshotEnvironments || []).forEach(val => {if (val != null) __params = __params.append('snapshotEnvironments', val.toString())});
    if (params.returnOnlyChildRecoveries != null) __params = __params.set('returnOnlyChildRecoveries', params.returnOnlyChildRecoveries.toString());
    if (params.returnChildTasks != null) __params = __params.set('returnChildTasks', params.returnChildTasks.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.recoveryActions || []).forEach(val => {if (val != null) __params = __params.append('recoveryActions', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.archivalTargetType || []).forEach(val => {if (val != null) __params = __params.append('archivalTargetType', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/recoveries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Recoveries>;
      })
    );
  }
  /**
   * Lists the Recoveries.
   *
   * Lists the Recoveries.
   * @param params The `RecoveryService.GetRecoveriesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the organizations for which recoveries are to be returned.
   *
   * - `storageDomainId`: Filter by Storage Domain id. Only recoveries writing data to this Storage Domain will be returned.
   *
   * - `status`: Specifies the list of run status to filter Recoveries. If empty, Recoveries with all run status will be returned.
   *
   * - `startTimeUsecs`: Returns the recoveries which are started after the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `snapshotTargetType`: Specifies the snapshot's target type from which recovery has been performed.
   *
   * - `snapshotEnvironments`: Specifies the list of snapshot environment types to filter Recoveries. If empty, Recoveries related to all environments will be returned.
   *
   * - `returnOnlyChildRecoveries`: Returns only child recoveries if passed as true. This filter should always be used along with 'ids' filter.
   *
   * - `returnChildTasks`: If set to true, also allows child tasks created by restore jobs or multi-state restores to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `recoveryActions`: Specifies the list of recovery actions to filter Recoveries. If empty, Recoveries related to all actions will be returned.
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `ids`: Filter Recoveries for given ids.
   *
   * - `endTimeUsecs`: Returns the recoveries which are started before the specific time. This value should be in Unix timestamp epoch in microseconds.
   *
   * - `archivalTargetType`: Specifies the snapshot's archival target type from which recovery has been performed. This parameter applies only if 'snapshotTargetType' is 'Archival'.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRecoveries(params: RecoveryService.GetRecoveriesParams): __Observable<Recoveries> {
    return this.GetRecoveriesResponse(params).pipe(
      __map(_r => _r.body as Recoveries)
    );
  }

  /**
   * Performs a Recovery.
   *
   * Performs a Recovery.
   * @param params The `RecoveryService.CreateRecoveryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a Recovery.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRecoveryResponse(params: RecoveryService.CreateRecoveryParams): __Observable<__StrictHttpResponse<Recovery>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/recoveries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Recovery>;
      })
    );
  }
  /**
   * Performs a Recovery.
   *
   * Performs a Recovery.
   * @param params The `RecoveryService.CreateRecoveryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a Recovery.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRecovery(params: RecoveryService.CreateRecoveryParams): __Observable<Recovery> {
    return this.CreateRecoveryResponse(params).pipe(
      __map(_r => _r.body as Recovery)
    );
  }

  /**
   * Create a download files and folders recovery.
   *
   * Creates a download files and folders recovery.
   * @param params The `RecoveryService.CreateDownloadFilesAndFoldersRecoveryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a download files and folder recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDownloadFilesAndFoldersRecoveryResponse(params: RecoveryService.CreateDownloadFilesAndFoldersRecoveryParams): __Observable<__StrictHttpResponse<Recovery>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/recoveries/download-files-folders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Recovery>;
      })
    );
  }
  /**
   * Create a download files and folders recovery.
   *
   * Creates a download files and folders recovery.
   * @param params The `RecoveryService.CreateDownloadFilesAndFoldersRecoveryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a download files and folder recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDownloadFilesAndFoldersRecovery(params: RecoveryService.CreateDownloadFilesAndFoldersRecoveryParams): __Observable<Recovery> {
    return this.CreateDownloadFilesAndFoldersRecoveryResponse(params).pipe(
      __map(_r => _r.body as Recovery)
    );
  }

  /**
   * Create a download files and folders recovery.
   *
   * Creates a download files and folders recovery.
   * @param params The `RecoveryService.InternalApiCreateDownloadFilesAndFoldersRecoveryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a download files and folder recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiCreateDownloadFilesAndFoldersRecoveryResponse(params: RecoveryService.InternalApiCreateDownloadFilesAndFoldersRecoveryParams): __Observable<__StrictHttpResponse<Recovery>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/recoveries/downloadFilesAndFoldersRecovery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Recovery>;
      })
    );
  }
  /**
   * Create a download files and folders recovery.
   *
   * Creates a download files and folders recovery.
   * @param params The `RecoveryService.InternalApiCreateDownloadFilesAndFoldersRecoveryParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a download files and folder recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiCreateDownloadFilesAndFoldersRecovery(params: RecoveryService.InternalApiCreateDownloadFilesAndFoldersRecoveryParams): __Observable<Recovery> {
    return this.InternalApiCreateDownloadFilesAndFoldersRecoveryResponse(params).pipe(
      __map(_r => _r.body as Recovery)
    );
  }

  /**
   * Fetches the uptier data.
   *
   * Fetches the uptier data for a restore job.
   * @param params The `RecoveryService.FetchUptierDataParams` containing the following parameters:
   *
   * - `archiveUId`: Archive UID of the current restore.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  FetchUptierDataResponse(params: RecoveryService.FetchUptierDataParams): __Observable<__StrictHttpResponse<FetchUptierDataResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.archiveUId != null) __params = __params.set('archiveUId', params.archiveUId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/recoveries/fetch-uptier-data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FetchUptierDataResponse>;
      })
    );
  }
  /**
   * Fetches the uptier data.
   *
   * Fetches the uptier data for a restore job.
   * @param params The `RecoveryService.FetchUptierDataParams` containing the following parameters:
   *
   * - `archiveUId`: Archive UID of the current restore.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  FetchUptierData(params: RecoveryService.FetchUptierDataParams): __Observable<FetchUptierDataResponse> {
    return this.FetchUptierDataResponse(params).pipe(
      __map(_r => _r.body as FetchUptierDataResponse)
    );
  }

  /**
   * Fetches the pvc info of protected namespace.
   *
   * Fetches pvc info for a restore job.
   * @param params The `RecoveryService.PvcInformationParams` containing the following parameters:
   *
   * - `startTimeUsecs`:
   *
   * - `sourceId`:
   *
   * - `jobRunId`:
   *
   * - `jobId`:
   *
   * - `clusterIncarnationId`:
   *
   * - `clusterId`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PvcInformationResponse(params: RecoveryService.PvcInformationParams): __Observable<__StrictHttpResponse<PvcInformationResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.jobRunId != null) __params = __params.set('jobRunId', params.jobRunId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.clusterIncarnationId != null) __params = __params.set('clusterIncarnationId', params.clusterIncarnationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/pvc-information`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PvcInformationResponseParams>;
      })
    );
  }
  /**
   * Fetches the pvc info of protected namespace.
   *
   * Fetches pvc info for a restore job.
   * @param params The `RecoveryService.PvcInformationParams` containing the following parameters:
   *
   * - `startTimeUsecs`:
   *
   * - `sourceId`:
   *
   * - `jobRunId`:
   *
   * - `jobId`:
   *
   * - `clusterIncarnationId`:
   *
   * - `clusterId`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PvcInformation(params: RecoveryService.PvcInformationParams): __Observable<PvcInformationResponseParams> {
    return this.PvcInformationResponse(params).pipe(
      __map(_r => _r.body as PvcInformationResponseParams)
    );
  }

  /**
   * Get Recovery for a given id.
   *
   * Get Recovery for a given id.
   * @param params The `RecoveryService.GetRecoveryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `returnChildTasks`: If set to true, also allows child tasks created by restore jobs or multi-state restores to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRecoveryByIdResponse(params: RecoveryService.GetRecoveryByIdParams): __Observable<__StrictHttpResponse<Recovery>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnChildTasks != null) __params = __params.set('returnChildTasks', params.returnChildTasks.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/recoveries/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Recovery>;
      })
    );
  }
  /**
   * Get Recovery for a given id.
   *
   * Get Recovery for a given id.
   * @param params The `RecoveryService.GetRecoveryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `returnChildTasks`: If set to true, also allows child tasks created by restore jobs or multi-state restores to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRecoveryById(params: RecoveryService.GetRecoveryByIdParams): __Observable<Recovery> {
    return this.GetRecoveryByIdResponse(params).pipe(
      __map(_r => _r.body as Recovery)
    );
  }

  /**
   * Cancel Recovery for a given id.
   *
   * Cancel Recovery for a given id.
   * @param params The `RecoveryService.CancelRecoveryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelRecoveryByIdResponse(params: RecoveryService.CancelRecoveryByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/recoveries/${encodeURIComponent(params.id)}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel Recovery for a given id.
   *
   * Cancel Recovery for a given id.
   * @param params The `RecoveryService.CancelRecoveryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelRecoveryById(params: RecoveryService.CancelRecoveryByIdParams): __Observable<null> {
    return this.CancelRecoveryByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the debug logs for a particular recovery operation.
   *
   * Get the debug logs for a particular recovery operation.
   * @param params The `RecoveryService.GetRecoveryDebugLogsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery job.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRecoveryDebugLogsResponse(params: RecoveryService.GetRecoveryDebugLogsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/recoveries/${encodeURIComponent(params.id)}/debug-logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get the debug logs for a particular recovery operation.
   *
   * Get the debug logs for a particular recovery operation.
   * @param params The `RecoveryService.GetRecoveryDebugLogsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery job.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRecoveryDebugLogs(params: RecoveryService.GetRecoveryDebugLogsParams): __Observable<null> {
    return this.GetRecoveryDebugLogsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Download files from the given download file recovery.
   *
   * Download files from the given download file recovery.
   * @param params The `RecoveryService.DownloadFilesFromRecoveryParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `startTime`: Specifies the start time of restore task
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `sourceName`: Specifies the name of the source on which restore is done
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `fileType`: Specifies the downloaded type, i.e: error, success_files_list
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DownloadFilesFromRecoveryResponse(params: RecoveryService.DownloadFilesFromRecoveryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    if (params.startOffset != null) __params = __params.set('startOffset', params.startOffset.toString());
    if (params.sourceName != null) __params = __params.set('sourceName', params.sourceName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.length != null) __params = __params.set('length', params.length.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/recoveries/${encodeURIComponent(params.id)}/download-files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Download files from the given download file recovery.
   *
   * Download files from the given download file recovery.
   * @param params The `RecoveryService.DownloadFilesFromRecoveryParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `startTime`: Specifies the start time of restore task
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `sourceName`: Specifies the name of the source on which restore is done
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `fileType`: Specifies the downloaded type, i.e: error, success_files_list
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DownloadFilesFromRecovery(params: RecoveryService.DownloadFilesFromRecoveryParams): __Observable<null> {
    return this.DownloadFilesFromRecoveryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the CSV of errors/warnings for a given recovery operation.
   *
   * Get a CSV error report for given recovery operation. Each row in CSV report contains the File Path, error/warning code and error/warning message.
   * @param params The `RecoveryService.GetRecoveryErrorsReportParams` containing the following parameters:
   *
   * - `id`: Specifies a unique ID of a Recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRecoveryErrorsReportResponse(params: RecoveryService.GetRecoveryErrorsReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/recoveries/${encodeURIComponent(params.id)}/download-messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Get the CSV of errors/warnings for a given recovery operation.
   *
   * Get a CSV error report for given recovery operation. Each row in CSV report contains the File Path, error/warning code and error/warning message.
   * @param params The `RecoveryService.GetRecoveryErrorsReportParams` containing the following parameters:
   *
   * - `id`: Specifies a unique ID of a Recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  GetRecoveryErrorsReport(params: RecoveryService.GetRecoveryErrorsReportParams): __Observable<null> {
    return this.GetRecoveryErrorsReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Download files from the given download file recovery.
   *
   * Download files from the given download file recovery.
   * @param params The `RecoveryService.InternalApiDownloadFilesFromRecoveryParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  InternalApiDownloadFilesFromRecoveryResponse(params: RecoveryService.InternalApiDownloadFilesFromRecoveryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startOffset != null) __params = __params.set('startOffset', params.startOffset.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.length != null) __params = __params.set('length', params.length.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/recoveries/${encodeURIComponent(params.id)}/downloadFiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Download files from the given download file recovery.
   *
   * Download files from the given download file recovery.
   * @param params The `RecoveryService.InternalApiDownloadFilesFromRecoveryParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `includeTenants`: Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  InternalApiDownloadFilesFromRecovery(params: RecoveryService.InternalApiDownloadFilesFromRecoveryParams): __Observable<null> {
    return this.InternalApiDownloadFilesFromRecoveryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Tear down Recovery for a given id.
   *
   * Tear down Recovery for a given id.
   * @param params The `RecoveryService.TearDownRecoveryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  TearDownRecoveryByIdResponse(params: RecoveryService.TearDownRecoveryByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/recoveries/${encodeURIComponent(params.id)}/tear-down`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Tear down Recovery for a given id.
   *
   * Tear down Recovery for a given id.
   * @param params The `RecoveryService.TearDownRecoveryByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Recovery.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  TearDownRecoveryById(params: RecoveryService.TearDownRecoveryByIdParams): __Observable<null> {
    return this.TearDownRecoveryByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Download an indexed file.
   *
   * Download an indexed file from a snapshot.
   * @param params The `RecoveryService.DownloadIndexedFileParams` containing the following parameters:
   *
   * - `snapshotsId`: Specifies the snapshot id to download from.
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `retryAttempt`: Specifies the number of attempts the protection run took to create this file.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `filePath`: Specifies the path to the file to download. If no path is specified and snapshot environment is kVMWare, VMX file for VMware will be downloaded. For other snapshot environments, this field must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DownloadIndexedFileResponse(params: RecoveryService.DownloadIndexedFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startOffset != null) __params = __params.set('startOffset', params.startOffset.toString());
    if (params.retryAttempt != null) __params = __params.set('retryAttempt', params.retryAttempt.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.length != null) __params = __params.set('length', params.length.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/snapshots/${encodeURIComponent(params.snapshotsId)}/download-file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Download an indexed file.
   *
   * Download an indexed file from a snapshot.
   * @param params The `RecoveryService.DownloadIndexedFileParams` containing the following parameters:
   *
   * - `snapshotsId`: Specifies the snapshot id to download from.
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `retryAttempt`: Specifies the number of attempts the protection run took to create this file.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `filePath`: Specifies the path to the file to download. If no path is specified and snapshot environment is kVMWare, VMX file for VMware will be downloaded. For other snapshot environments, this field must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DownloadIndexedFile(params: RecoveryService.DownloadIndexedFileParams): __Observable<null> {
    return this.DownloadIndexedFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Download an indexed file.
   *
   * Download an indexed file from a snapshot.
   * @param params The `RecoveryService.InternalApiDownloadIndexedFileParams` containing the following parameters:
   *
   * - `snapshotsId`: Specifies the snapshot id to download from.
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `retryAttempt`: Specifies the number of attempts the protection run took to create this file.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `filePath`: Specifies the path to the file to download. If no path is specified and snapshot environment is kVMWare, VMX file for VMware will be downloaded. For other snapshot environments, this field must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  InternalApiDownloadIndexedFileResponse(params: RecoveryService.InternalApiDownloadIndexedFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startOffset != null) __params = __params.set('startOffset', params.startOffset.toString());
    if (params.retryAttempt != null) __params = __params.set('retryAttempt', params.retryAttempt.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.length != null) __params = __params.set('length', params.length.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/snapshots/${encodeURIComponent(params.snapshotsId)}/downloadFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Download an indexed file.
   *
   * Download an indexed file from a snapshot.
   * @param params The `RecoveryService.InternalApiDownloadIndexedFileParams` containing the following parameters:
   *
   * - `snapshotsId`: Specifies the snapshot id to download from.
   *
   * - `startOffset`: Specifies the start offset of file chunk to be downloaded.
   *
   * - `retryAttempt`: Specifies the number of attempts the protection run took to create this file.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `length`: Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
   *
   * - `filePath`: Specifies the path to the file to download. If no path is specified and snapshot environment is kVMWare, VMX file for VMware will be downloaded. For other snapshot environments, this field must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  InternalApiDownloadIndexedFile(params: RecoveryService.InternalApiDownloadIndexedFileParams): __Observable<null> {
    return this.InternalApiDownloadIndexedFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List Restore Points in a given time range
   *
   * List Restore Points i.e. returns the snapshots in in a given time range
   * @param body Specifies the request parameters to restore points for time range API
   * @return Success
   */
  GetRestorePointsInTimeRangeResponse(body: GetRestorePointsInTimeRangeParams): __Observable<__StrictHttpResponse<GetRestorePointsInTimeRangeResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/snapshots/restore-points`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetRestorePointsInTimeRangeResponse>;
      })
    );
  }
  /**
   * List Restore Points in a given time range
   *
   * List Restore Points i.e. returns the snapshots in in a given time range
   * @param body Specifies the request parameters to restore points for time range API
   * @return Success
   */
  GetRestorePointsInTimeRange(body: GetRestorePointsInTimeRangeParams): __Observable<GetRestorePointsInTimeRangeResponse> {
    return this.GetRestorePointsInTimeRangeResponse(body).pipe(
      __map(_r => _r.body as GetRestorePointsInTimeRangeResponse)
    );
  }

  /**
   * Get Recoveries on Helios.
   *
   * Get Recoveries on Helios.
   * @param params The `RecoveryService.GetMcmRecoveriesParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started before this value.
   *
   * - `statuses`: Specifies the list of statuses to filter.
   *
   * - `rpaasRegionIds`: Filter by a list of region ids. This is used for Rpaas only.
   *
   * - `rpaasGlobalVaultIds`: Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `recoveryTypes`: Specifies the recovery types to filter recoveries.
   *
   * - `recoveryIdentifiers`: Specifies the list of Recovery identifiers to filter.
   *
   * - `isRpaas`: Specifies whether the recovery is recovered from a RPaaS snapshot or not. If this is true then only recoveries from RPaaS snapshots runs are returned. Default is false.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started after this value.
   *
   * - `environments`: Specifies the list of environments of recovered objects.
   *
   * - `clusterIdentifiers`: Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetMcmRecoveriesResponse(params: RecoveryService.GetMcmRecoveriesParams): __Observable<__StrictHttpResponse<McmRecoveryTasks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.rpaasRegionIds || []).forEach(val => {if (val != null) __params = __params.append('rpaasRegionIds', val.toString())});
    (params.rpaasGlobalVaultIds || []).forEach(val => {if (val != null) __params = __params.append('rpaasGlobalVaultIds', val.toString())});
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.recoveryTypes || []).forEach(val => {if (val != null) __params = __params.append('recoveryTypes', val.toString())});
    (params.recoveryIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('recoveryIdentifiers', val.toString())});
    if (params.isRpaas != null) __params = __params.set('isRpaas', params.isRpaas.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/recoveries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmRecoveryTasks>;
      })
    );
  }
  /**
   * Get Recoveries on Helios.
   *
   * Get Recoveries on Helios.
   * @param params The `RecoveryService.GetMcmRecoveriesParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started before this value.
   *
   * - `statuses`: Specifies the list of statuses to filter.
   *
   * - `rpaasRegionIds`: Filter by a list of region ids. This is used for Rpaas only.
   *
   * - `rpaasGlobalVaultIds`: Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `recoveryTypes`: Specifies the recovery types to filter recoveries.
   *
   * - `recoveryIdentifiers`: Specifies the list of Recovery identifiers to filter.
   *
   * - `isRpaas`: Specifies whether the recovery is recovered from a RPaaS snapshot or not. If this is true then only recoveries from RPaaS snapshots runs are returned. Default is false.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started after this value.
   *
   * - `environments`: Specifies the list of environments of recovered objects.
   *
   * - `clusterIdentifiers`: Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetMcmRecoveries(params: RecoveryService.GetMcmRecoveriesParams): __Observable<McmRecoveryTasks> {
    return this.GetMcmRecoveriesResponse(params).pipe(
      __map(_r => _r.body as McmRecoveryTasks)
    );
  }

  /**
   * Get Recovery by Id on Helios.
   *
   * Get Recovery on Helios by ID.
   * @param id Specifies the id of the recovery.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetMcmRecoveriesByIdResponse(id: string,
    regionId?: string): __Observable<__StrictHttpResponse<McmRecoveryTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/recoveries/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmRecoveryTask>;
      })
    );
  }
  /**
   * Get Recovery by Id on Helios.
   *
   * Get Recovery on Helios by ID.
   * @param id Specifies the id of the recovery.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetMcmRecoveriesById(id: string,
    regionId?: string): __Observable<McmRecoveryTask> {
    return this.GetMcmRecoveriesByIdResponse(id, regionId).pipe(
      __map(_r => _r.body as McmRecoveryTask)
    );
  }
}

module RecoveryService {

  /**
   * Parameters for GetRecoveries
   */
  export interface GetRecoveriesParams {

    /**
     * TenantIds contains ids of the organizations for which recoveries are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter by Storage Domain id. Only recoveries writing data to this Storage Domain will be returned.
     */
    storageDomainId?: number;

    /**
     * Specifies the list of run status to filter Recoveries. If empty, Recoveries with all run status will be returned.
     */
    status?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * Returns the recoveries which are started after the specific time. This value should be in Unix timestamp epoch in microseconds.
     */
    startTimeUsecs?: number;

    /**
     * Specifies the snapshot's target type from which recovery has been performed.
     */
    snapshotTargetType?: Array<'Local' | 'Archival' | 'RpaasArchival' | 'StorageArraySnapshot'>;

    /**
     * Specifies the list of snapshot environment types to filter Recoveries. If empty, Recoveries related to all environments will be returned.
     */
    snapshotEnvironments?: Array<'kVMware' | 'kHyperV' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kPhysical' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kMongoDBPhysical' | 'kSAPHANA'>;

    /**
     * Returns only child recoveries if passed as true. This filter should always be used along with 'ids' filter.
     */
    returnOnlyChildRecoveries?: boolean;

    /**
     * If set to true, also allows child tasks created by restore jobs or multi-state restores to be returned.
     */
    returnChildTasks?: boolean;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the list of recovery actions to filter Recoveries. If empty, Recoveries related to all actions will be returned.
     */
    recoveryActions?: Array<'RecoverVMs' | 'RecoverFiles' | 'InstantVolumeMount' | 'RecoverVmDisks' | 'RecoverVApps' | 'RecoverVAppTemplates' | 'UptierSnapshot' | 'RecoverRDS' | 'RecoverRDSPostgres' | 'RecoverAwsDynamoDB' | 'RecoverAurora' | 'RecoverS3Buckets' | 'RecoverAzureSQL' | 'RecoverAzureEntraID' | 'RecoverApps' | 'CloneApps' | 'RecoverNasVolume' | 'RecoverPhysicalVolumes' | 'RecoverSystem' | 'RecoverExchangeDbs' | 'CloneAppView' | 'RecoverSanVolumes' | 'RecoverSanGroup' | 'RecoverMailbox' | 'RecoverOneDrive' | 'RecoverSharePoint' | 'RecoverPublicFolders' | 'RecoverMsGroup' | 'RecoverMsTeam' | 'ConvertToPst' | 'DownloadChats' | 'RecoverNamespaces' | 'RecoverObjects' | 'RecoverSfdcObjects' | 'RecoverSfdcOrg' | 'RecoverSfdcRecords' | 'RecoverMongodbClusters' | 'DownloadFilesAndFolders' | 'CloneVMs' | 'CloneView' | 'CloneRefreshApp' | 'CloneVMsToView' | 'ConvertAndDeployVMs' | 'DeployVMs'>;

    /**
     * Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Filter Recoveries for given ids.
     */
    ids?: Array<string>;

    /**
     * Returns the recoveries which are started before the specific time. This value should be in Unix timestamp epoch in microseconds.
     */
    endTimeUsecs?: number;

    /**
     * Specifies the snapshot's archival target type from which recovery has been performed. This parameter applies only if 'snapshotTargetType' is 'Archival'.
     */
    archivalTargetType?: Array<'Tape' | 'Cloud' | 'Nas'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRecovery
   */
  export interface CreateRecoveryParams {

    /**
     * Specifies the parameters to create a Recovery.
     */
    body: CreateRecoveryRequest;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDownloadFilesAndFoldersRecovery
   */
  export interface CreateDownloadFilesAndFoldersRecoveryParams {

    /**
     * Specifies the parameters to create a download files and folder recovery.
     */
    body: DownloadFilesAndFoldersRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InternalApiCreateDownloadFilesAndFoldersRecovery
   */
  export interface InternalApiCreateDownloadFilesAndFoldersRecoveryParams {

    /**
     * Specifies the parameters to create a download files and folder recovery.
     */
    body: DownloadFilesAndFoldersRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for FetchUptierData
   */
  export interface FetchUptierDataParams {

    /**
     * Archive UID of the current restore.
     */
    archiveUId: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PvcInformation
   */
  export interface PvcInformationParams {
    startTimeUsecs: number;
    sourceId: number;
    jobRunId: number;
    jobId: number;
    clusterIncarnationId: number;
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRecoveryById
   */
  export interface GetRecoveryByIdParams {

    /**
     * Specifies the id of a Recovery.
     */
    id: string;

    /**
     * If set to true, also allows child tasks created by restore jobs or multi-state restores to be returned.
     */
    returnChildTasks?: boolean;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelRecoveryById
   */
  export interface CancelRecoveryByIdParams {

    /**
     * Specifies the id of a Recovery.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRecoveryDebugLogs
   */
  export interface GetRecoveryDebugLogsParams {

    /**
     * Specifies the id of a Recovery job.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DownloadFilesFromRecovery
   */
  export interface DownloadFilesFromRecoveryParams {

    /**
     * Specifies the id of a Recovery.
     */
    id: string;

    /**
     * Specifies the start time of restore task
     */
    startTime?: string;

    /**
     * Specifies the start offset of file chunk to be downloaded.
     */
    startOffset?: number;

    /**
     * Specifies the name of the source on which restore is done
     */
    sourceName?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
     */
    length?: number;

    /**
     * Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies the downloaded type, i.e: error, success_files_list
     */
    fileType?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRecoveryErrorsReport
   */
  export interface GetRecoveryErrorsReportParams {

    /**
     * Specifies a unique ID of a Recovery.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InternalApiDownloadFilesFromRecovery
   */
  export interface InternalApiDownloadFilesFromRecoveryParams {

    /**
     * Specifies the id of a Recovery.
     */
    id: string;

    /**
     * Specifies the start offset of file chunk to be downloaded.
     */
    startOffset?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
     */
    length?: number;

    /**
     * Specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for TearDownRecoveryById
   */
  export interface TearDownRecoveryByIdParams {

    /**
     * Specifies the id of a Recovery.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DownloadIndexedFile
   */
  export interface DownloadIndexedFileParams {

    /**
     * Specifies the snapshot id to download from.
     */
    snapshotsId: string;

    /**
     * Specifies the start offset of file chunk to be downloaded.
     */
    startOffset?: number;

    /**
     * Specifies the number of attempts the protection run took to create this file.
     */
    retryAttempt?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
     */
    length?: number;

    /**
     * Specifies the path to the file to download. If no path is specified and snapshot environment is kVMWare, VMX file for VMware will be downloaded. For other snapshot environments, this field must be specified.
     */
    filePath?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InternalApiDownloadIndexedFile
   */
  export interface InternalApiDownloadIndexedFileParams {

    /**
     * Specifies the snapshot id to download from.
     */
    snapshotsId: string;

    /**
     * Specifies the start offset of file chunk to be downloaded.
     */
    startOffset?: number;

    /**
     * Specifies the number of attempts the protection run took to create this file.
     */
    retryAttempt?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the length of bytes to download. This can not be greater than 8MB (8388608 byets)
     */
    length?: number;

    /**
     * Specifies the path to the file to download. If no path is specified and snapshot environment is kVMWare, VMX file for VMware will be downloaded. For other snapshot environments, this field must be specified.
     */
    filePath?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetMcmRecoveries
   */
  export interface GetMcmRecoveriesParams {

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started before this value.
     */
    toTimeUsecs?: null | number;

    /**
     * Specifies the list of statuses to filter.
     */
    statuses?: null | Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * Filter by a list of region ids. This is used for Rpaas only.
     */
    rpaasRegionIds?: Array<string>;

    /**
     * Filter by a list of FortKnox global vault ids. This is used for FortKnox only. When rpaasGlobalVaultIds is specified, rpaasRegionIds will be ignored.
     */
    rpaasGlobalVaultIds?: Array<string>;

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the recovery types to filter recoveries.
     */
    recoveryTypes?: null | Array<'RecoverVMs' | 'RecoverFiles' | 'InstantVolumeMount' | 'RecoverVmDisks' | 'RecoverVApps' | 'RecoverVAppTemplates' | 'UptierSnapshot' | 'RecoverRDS' | 'RecoverRDSPostgres' | 'RecoverAwsDynamoDB' | 'RecoverAurora' | 'RecoverS3Buckets' | 'RecoverAzureSQL' | 'RecoverAzureEntraID' | 'RecoverApps' | 'CloneApps' | 'RecoverNasVolume' | 'RecoverPhysicalVolumes' | 'RecoverSystem' | 'RecoverExchangeDbs' | 'CloneAppView' | 'RecoverSanVolumes' | 'RecoverSanGroup' | 'RecoverMailbox' | 'RecoverOneDrive' | 'RecoverSharePoint' | 'RecoverPublicFolders' | 'RecoverMsGroup' | 'RecoverMsTeam' | 'ConvertToPst' | 'DownloadChats' | 'RecoverNamespaces' | 'RecoverObjects' | 'RecoverSfdcObjects' | 'RecoverSfdcOrg' | 'RecoverSfdcRecords' | 'RecoverMongodbClusters' | 'DownloadFilesAndFolders' | 'CloneVMs' | 'CloneView' | 'CloneRefreshApp' | 'CloneVMsToView' | 'ConvertAndDeployVMs' | 'DeployVMs'>;

    /**
     * Specifies the list of Recovery identifiers to filter.
     */
    recoveryIdentifiers?: null | Array<string>;

    /**
     * Specifies whether the recovery is recovered from a RPaaS snapshot or not. If this is true then only recoveries from RPaaS snapshots runs are returned. Default is false.
     */
    isRpaas?: null | boolean;

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the recoveries started after this value.
     */
    fromTimeUsecs?: null | number;

    /**
     * Specifies the list of environments of recovered objects.
     */
    environments?: null | Array<'kVMware' | 'kHyperV' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kPhysical' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kMongoDBPhysical' | 'kSAPHANA'>;

    /**
     * Filter by a list of cluster identifiers in format of clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }
}

export { RecoveryService }
