<button
  id="select-scope"
  class="select-scope-button"
  mat-button
  *ngIf="!needMoreClusters && isSpogSupported">
  <span>{{selectedScope?.name}}</span>
  <mat-icon>filter_list</mat-icon>
</button>

<!-- If there aren't enough clusters to show cluster selector, simply display the cluster name. -->
<div class="standalone-cluster-name" [attr.tabindex]="0" *ngIf="needMoreClusters || !isSpogSupported">
  {{selectedScope?.name}}
</div>
