import { isObject } from 'lodash-es';
import { isEqual } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Snapshot selector.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.restoreCommon')
    .controller('SnapshoptSelectorCtrl', snapshotSelectorCtrlFn)
    .component('snapshotSelector', {
      bindings: {
        // @type   {object}   Object specifying resolvers.
        resolve: '=',

        // @type   {object}   Object specifying $uibModalInstance.
        modalInstance: '<?',
      },
      templateUrl:
        'app/protection/recovery/common/snapshot-selector/snapshot-selector-public.html',
      controller: 'SnapshoptSelectorCtrl',
    });

  function snapshotSelectorCtrlFn(_, ExternalTargetService) {
    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,

      // Template methods.
      close: close,
      getTargetName: ExternalTargetService.getTargetName,
      getTargetType: ExternalTargetService.getTargetType,
      handleArchivalSelection: handleArchivalSelection,
      isReplicaInfoSelected: isReplicaInfoSelected,

      // State variables.
      entity: {},
      selectedReplicaInfo: {},
    });

    /**
     * Component initialization hook.
     *
     * @method   $onInit
     */
    function $onInit() {
      if (isObject($ctrl.resolve.entity)) {
        $ctrl.entity = $ctrl.resolve.entity;
        $ctrl._currentSnapshot = $ctrl.entity._snapshot;

        // Set the default target type to the 1st item in the replicationInfoList.
        // This will mostly default to local snapshots unless they are deleted.
        handleArchivalSelection($ctrl._currentSnapshot.replicaInfoList[0]);
      }
    }

    /**
     * Dismisses the snapshot selector modal.
     *
     * @method   close
     * @param    {boolean}   saveNewRecoverPoint   True if the selected recover
     *                                             point is to be saved.
     */
    function close(saveNewRecoverPoint) {
      if (!saveNewRecoverPoint) {
        // Fall back to the default latest time stamp.
        $ctrl._currentSnapshot = $ctrl.entity.versions[0];
      }
      $ctrl.modalInstance.close($ctrl._currentSnapshot);
    }

    /**
     * Handles the replica selection for snapshot target.
     *
     * @param   {object}   replicaInfo   Specifies the replica info.
     */
    function handleArchivalSelection(replicaInfo) {
      $ctrl.selectedReplicaInfo = replicaInfo;
      $ctrl._currentSnapshot.archivalTarget =
        get(replicaInfo, 'snapshotTargetSettings.archivalTarget');
    }

    /**
     * Determine whether the current replica is selected.
     *
     * @param     {object}    replicaInfo   Specifes the replica info.
     * @returns   {boolean}   True, if replicaInfo is the one selected.
     */
    function isReplicaInfoSelected(replicaInfo) {
      return isEqual(replicaInfo, $ctrl.selectedReplicaInfo);
    }
  }
})(angular);