import { Component, Inject, Optional, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MsalBroadcastService, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { PopupRequest, SilentRequest } from '@azure/msal-browser';
import { DmsServiceApi } from '@cohesity/api/dms';
import { McmSource, McmSourceInfo, SourceServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { flagEnabled, getUserTenantId, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { StateService } from '@uirouter/core';
import { DmsAwsService } from '../../../aws/services/aws.service';
import { AzureRegistrationLevelType, AzureRegistrationType, Environment, ExpressRegistrationStatus, ExpressRegistrationStep, GraphScope, ManagementScope } from 'src/app/shared';
import { AzureExpressRegistrationService } from 'src/app/modules/helios-source-registration/azure-registration/azure-express-registration.service';

/**
 * This is a dialog component that unregister source.
 *
 * Ex. this.dialogService.open('unregister-source')
 */
@Component({
  selector: 'coh-dms-unregister-source',
  templateUrl: './dms-unregister-source.component.html',
  styleUrls: ['./dms-unregister-source.component.scss']
})
export class DmsUnregisterSourceComponent extends MsalRedirectComponent
  implements OnInit, OnDestroy {

  /**
   * Observable for cleaning up subscriptions
   */
  private destroyed$ = new Subject<void>();

  /**
   * Enum for registration steps
   */
  protected readonly ExpressRegistrationStep = ExpressRegistrationStep;

  /**
   * Enum for registration status
   */
  protected readonly ExpressRegistrationStatus = ExpressRegistrationStatus;

  /**
   * Holds the current status of the unregistration process for various steps.
   *
   * This object maps each `ExpressRegistrationStep` to its corresponding status.
   * The status indicates the progress of the unregistration for each step, and
   * can be one of the values defined in `ExpressRegistrationStatus`.
   *
   */
  protected unRegistrationStatus = {
    [ExpressRegistrationStep.AUTHORIZATION]: ExpressRegistrationStatus.PENDING,
    [ExpressRegistrationStep.DELETE_SOURCE]: ExpressRegistrationStatus.PENDING
  };

  /**
   * Holds the name of the source.
   */
  name: string;

  /**
   * Holds the type of the source.
   */
  type: string;

  /**
   * Holds the name of the rigel.
   */
  rigelName = '';

  /**
   * Remove rigel with the source.
   */
  removeRigel = new UntypedFormControl(false);

  /**
   * Indicates whether any API call is processing.
   */
  loading = false;

  /**
   * Specifies the type of Azure registration.
   *
   * This property defines the kind of registration process being used,
   * such as a specific type of Azure service or configuration.
   */
  registrationType: AzureRegistrationType;

  /**
   * Determines whether a loading spinner should be displayed.
   *
   * This boolean flag controls the visibility of a spinner or loading indicator
   * in the user interface. When set to `true`, the spinner is shown to indicate
   * that a background operation is in progress. When set to `false`, the spinner
   * is hidden, indicating that the operation is complete or not active.
   *
   */
  showSpinner = false;

  /**
   * Form group that represents the source
   */
  unregisterForm: UntypedFormGroup = new UntypedFormGroup({
    removeRigel: this.removeRigel,
    confirmUnregister: new UntypedFormControl(null, [Validators.pattern('^([yY][eE][sS])$')]),
  });

  /**
   * Specifies if UI workflow for Azure express Registration is enabled
   */
  readonly isExpressRegistrationEnabled =  flagEnabled(this.irisCtx.irisContext, 'azureExpressRegistration');

  /**
   * Checks if the registration is both Express and Azure.
   * Returns true if Express registration is enabled, the environment is Azure,
   * and the registration type is EXPRESS.
   */
  isExpressAndAzure(): boolean {
    return this.isExpressRegistrationEnabled &&
      this.data.source.environment === Environment.kAzure &&
      this.registrationType === AzureRegistrationType.EXPRESS;
  }

  /**
   * Checks if the authorization step is in a pending state.
   * Returns true if the authorization status is PENDING.
   */
  isAuthPending(): boolean {
    return this.unRegistrationStatus[ExpressRegistrationStep.AUTHORIZATION] === ExpressRegistrationStatus.PENDING;
  }

  /**
   * constructor
   *
   * @param   data   The Dialog data.
   */
  constructor(
    @Optional() private dialogRef: MatDialogRef<DmsUnregisterSourceComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      source: McmSource;
      sourceInfo: McmSourceInfo;
    },
    private sourceService: SourceServiceApi,
    private ajaxHandler: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private snackbarService: SnackBarService,
    readonly dmsServiceApi: DmsServiceApi,
    readonly dmsAwsService: DmsAwsService,
    private irisCtx: IrisContextService,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private stateService: StateService,
    protected expRegService: AzureExpressRegistrationService,
  ) {
    super(msalAuthService);
    const {environment, type= 'kVCenter', name} = data.source;
    this.name = name;
    this.type = this.translate.instant(`enum.sourceType.${environment}.${type}`);
  }

  /**
   * Component initialization lifecycle hook.
   * - If the source type is 'kTenant', retrieves the Azure registration workflow and
   *   sets up a subscription to handle MSAL authentication events.
   * - If the source type is not 'kTenant', hides the spinner immediately as
   *   no additional setup is needed.
   */
  ngOnInit() {
    super.ngOnInit();

    // Check if the source type is 'kTenant'. This indicates that Azure source
    // registration workflow needs to be fetched.
    if (this.data?.source?.type === AzureRegistrationLevelType.kTenant) {
      // Fetch the registration workflow type for the Azure source.
      this.getRegistrationWorkflow();

      // Subscribe to MSAL broadcast events to handle authentication events.
      // This subscription will be automatically cleaned up when the component
      // is destroyed.
      if (this.registrationType === AzureRegistrationType.EXPRESS) {
        this.showSpinner = true;

        this.msalBroadcastService.msalSubject$
          .pipe(
            takeUntil(this.destroyed$),
            finalize(() => {
              this.showSpinner = false;
            })
          )
          .subscribe(eventMessage =>
            this.expRegService.handleAuthEvent(eventMessage, this.unRegistrationStatus)
          );
      }
    }
  }


  /**
   * Triggers the unregister the source.
   */
  unregister() {
    this.loading = true;
    const params: SourceServiceApi.McmDeleteProtectionSourceRegistrationParams = {
      id: this.data.sourceInfo.registrationId,
      regionId: this.data.sourceInfo.regionId
    };

    // For AWS, two Api calls have to be made.
    if (this.data.source.environment === Environment.kAWS) {
      this.deleteAwsSource(params);
      return;
    }

    if (this.isExpressAndAzure()) {
      return this.deleteAzureSource(params);
    }

    if (this.unregisterForm.value.removeRigel) {
      // TODO(Sam): Handle unregister for rigel as well.
      // The API is not yet ready.
    } else {
      this.deleteMcmSource(params);
    }
  }

  /**
   * Unregisters an Azure source. The handling differs based on the registration workflow:
   * 1. If the workflow is not 'Express', the source is unregistered directly.
   * 2. If the workflow is 'Express', the method acquires necessary Azure tokens
   *    before proceeding with the unregistration.
   *
   * @param params - Contains details for identifying the source:
   *  - `id`: Unique identifier of the source.
   *  - `regionId`: Region ID where the source is registered.
   */
  deleteAzureSource(params: SourceServiceApi.McmDeleteProtectionSourceRegistrationParams) {
    this.loading = true;

    // Define the authority URL used for the Azure authentication requests.
    const authority = this.msalAuthService.instance.getConfiguration().auth.authority;
    const graphAuthority = this.name ? authority.replace('organizations', this.name) : authority;

    // Prepare the authentication request for obtaining the Graph API token.
    const graphAuthRequest: PopupRequest = {
      authority: graphAuthority,
      scopes: [GraphScope, ManagementScope],
      prompt: 'select_account', // Force user to select an account.
    };

    // Prepare the authentication request for obtaining the Management API token silently.
    const managementAuthRequest: SilentRequest = {
      authority: graphAuthority,
      scopes: [ManagementScope],
      account: this.msalAuthService.instance.getActiveAccount(),
    };

    // Initiate the process to acquire the Graph API token via a popup.
    this.msalAuthService.acquireTokenPopup(graphAuthRequest).pipe(
      switchMap((graphAuthResponse) =>
        this.msalAuthService.acquireTokenSilent(managementAuthRequest).pipe(

          // If silent token acquisition fails, fall back to a popup
          catchError(() =>
            this.msalAuthService.acquireTokenPopup({
              scopes: [ManagementScope],
              prompt: 'select_account',
              account: this.msalAuthService.instance.getActiveAccount(),
            })
          ),

          // Once the Management API token is acquired, proceed with
          // unregistering the source
          switchMap((managementAuthResponse) => {
            this.expRegService.updateStatus(
              this.unRegistrationStatus,
              ExpressRegistrationStep.DELETE_SOURCE,
              ExpressRegistrationStatus.IN_PROGRESS
            );
            // Create the Azure parameters object containing both tokens
            const azureParams = {
              azureParams: {
                graphAccessToken: graphAuthResponse.accessToken,
                managementAccessToken: managementAuthResponse.accessToken,
              },
            };

            // Pass the tokens in the request body to unregister the Azure source
            return this.sourceService.McmDeleteProtectionSourceRegistration({
              ...params,
              body: azureParams,
            });
          })
        )
      ),
      // Unsubscribe from the observable chain when the component is destroyed to prevent memory leaks.
      takeUntil(this.destroyed$),
      // Finalize by setting the loading state back to false, indicating the process has completed.
      finalize(() => this.loading = false)
    )
    .subscribe(
      // On success, show a success message and close the dialog, returning a positive result.
      () => {
        this.expRegService.updateStatus(
          this.unRegistrationStatus,
          ExpressRegistrationStep.DELETE_SOURCE,
          ExpressRegistrationStatus.SUCCESS
        );
        this.snackbarService.open(this.translate.instant('unregisterSourceSuccessful'), 'success');
        // Delay the dialog close by 4 seconds
        setTimeout(() => {
          this.dialogRef.close(true);
        }, 4000);
      },
      // On error, handle it by showing an error message.
      err => this.ajaxHandler.errorMessage(err)
    );
  }

  /**
   * Delete MCM Protection
   */
  deleteMcmSource(params: SourceServiceApi.McmDeleteProtectionSourceRegistrationParams) {
    this.sourceService.McmDeleteProtectionSourceRegistration(params)
     .pipe(
      takeUntil(this.destroyed$),
      finalize(() => this.loading = false),
      )
      .subscribe(
        () => {
          this.snackbarService.open(
            this.translate.instant('unregisterSourceSuccessful'),
            'success',
          );

          if (this.data.source.environment === Environment.kPhysical) {
            this.stateService.go('dms-sources.landing');
          }

          this.dialogRef.close(true);
        },
        err => this.ajaxHandler.errorMessage(err),
      );
  }

  /**
   * Delete AWS source.
   */
  deleteAwsSource(params: SourceServiceApi.McmDeleteProtectionSourceRegistrationParams) {
    this.sourceService.McmDeleteProtectionSourceRegistration(params)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.loading = false),
      )
      .subscribe(
        () => {
          // Can't track this call because source is already unregistered.
          this.dmsAwsService.deleteCft({
            tenantId: getUserTenantId(this.irisCtx.irisContext),
            destinationRegionId: this.data.sourceInfo.regionId,
            awsAccountNumber: this.name.split('/', 1)[0],
          }).pipe( takeUntil(this.destroyed$)).subscribe();

          this.snackbarService.open(
            this.translate.instant('unregisterSourceSuccessful'),
            'success',
          );
          this.dialogRef.close(true);
        },
        err => this.ajaxHandler.errorMessage(err),
      );
  }

  /**
   * Retrieves the registration workflow from the source service.
   * Updates `registrationType` based on the retrieved workflow and hides the spinner.
   */
  getRegistrationWorkflow(): void {
    const { registrationId, regionId } = this.data.sourceInfo;
    this.sourceService.McmGetProtectionSourceRegistration(registrationId, regionId)
      .pipe(
        map(response => {
          const workflow = response.azureParams?.registrationWorkflow as AzureRegistrationType;
          return workflow || AzureRegistrationType.MANUAL; // Default to 'MANUAL' if undefined
        }),
        takeUntil(this.destroyed$),
        finalize(() => {
          this.showSpinner = false; // Ensure spinner is hidden when the observable completes
          this.cdr.detectChanges(); // Trigger change detection to update the UI
        })
      )
      .subscribe({
        next: workflow => {
          this.registrationType = workflow;
        },
        error: err => {
          this.ajaxHandler.errorMessage(err);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
