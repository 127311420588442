import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Chart } from 'angular-highcharts';
import {
  Options,
  PointerEventObject,
  PointInteractionEventObject,
  SelectEventObject,
  SeriesArearangeOptions,
  SeriesBubbleOptions,
  SeriesLineOptions,
  SeriesSplineOptions,
} from 'highcharts';
import { findIndex, get, isEmpty, last, merge } from 'lodash-es';

import { customDateTimeLabelFormats } from '../core/index';

/**
 * Custom interface to capture co-ordinates when point is selected.
 */
interface PointSelectEventObject extends SelectEventObject {
  x?: number;
  y?: number;
  chartRef?: Object;
}

export type SeriesOptions = SeriesLineOptions | SeriesArearangeOptions
  | SeriesBubbleOptions | SeriesSplineOptions;

@Component({
  selector: 'cog-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LineChartComponent implements OnInit, OnDestroy {
  // Add class to host for highcharts styling purposes.
  @HostBinding('attr.class') cssClass = 'cog-chart';

  /**
   * Custom chart options.
   * This will extend default base configuration.
   */
  @Input() chartOptions: Options = {};

  // If non-default chart colors are desired, provide a colorSetClass for an
  // existing colorSet or define a new set in the stylesheet.
  @Input() colorSetClass: string;

  /**
   * Chart height.
   */
  @Input() height = 175;

  /**
   * chart title.
   */
  @Input() title: string;

  /**
   * The viewport size do we want to add horizontal scrolling to the component
   */
  @Input() reflowScrollSize: 'xs' | 'sm' | 'disabled' | '' = '';

  /**
   * Conditionally add horizontal scrolling at extra small viewport
   */
  @HostBinding('class.reflow-chart-x-scrollable@xs')
  get reflowScrollAtXs() {
    return this.reflowScrollSize === 'xs' || this.reflowScrollSize === '' ;
  }

  /**
   * Conditionally add horizontal scrolling at small viewport
   */
  @HostBinding('class.reflow-chart-x-scrollable@sm')
  get reflowScrollAtSm() {
    return this.reflowScrollSize === 'sm';
  }

  /**
   * Conditionally disable the reflow horizontal scrolling
   */
  @HostBinding('class.reflow-chart-x-scrollable-disabled')
  get reflowScrollDisabled() {
    return this.reflowScrollSize === 'disabled';
  }

  /**
   * Line series data.
   */
  private _seriesData: SeriesOptions[] = [];

  /**
   * Returns line series data.
   */
  @Input() get seriesData(): SeriesOptions[] {
    return this._seriesData;
  }

  /**
   * Sets line series data and redraws chart.
   */
  set seriesData(lineSeriesData: SeriesOptions[]) {
    this._seriesData = lineSeriesData;
    this.createChart();
  }

  /**
   * Updates the output value.
   */
  @Output() hoverPositionChange = new EventEmitter();

  /**
   * Updated point selection.
   */
  @Output() pointSelect = new EventEmitter<PointSelectEventObject>();

  /**
   * Line chart base configuration
   */
  baseConfiguration: Options;

  /**
   * The line chart configuration
   */
  chart: Chart;

  ngOnInit() {
    this.setChartBaseConfiguration();
    this.createChart();
  }

  /**
   * This sets base configuration for line chart.
   */
  setChartBaseConfiguration() {
    const defaultPlotOptions = {
      marker: {
        enabled: true,
        radius: 0,
      },
      allowPointSelect: false,
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
    };

    this.baseConfiguration = {
      chart: {
        height: this.height,
        backgroundColor: 'transparent',
        styledMode: true,
      },
      title: null,
      credits: {
        enabled: false,
      },
      time: {
        useUTC: false,
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: customDateTimeLabelFormats,
        tickLength: 0,
      },
      yAxis: {
        title: {
          text: '',
        },
        min: 0,
      },
      tooltip: {
        useHTML: true,
        pointFormat: '',
      },
      plotOptions: {
        series: {
          point: {
            events: {
              // when mouse hover over, emit current time point.
              mouseOver: (e) => {
                this.mouseOverLinePoint(e);
              },
              // when mouse leave, emit end time point.
              mouseOut: () => {
                this.mouseLeaveLinePoint();
              },
              // emitted on point selection.
              select: (e: PointInteractionEventObject) => {
                this.selectLinePoint(e);
              },
            },
          },
        },
        arearange: defaultPlotOptions,
        bubble: {
          minSize: 10,
          maxSize: 10,
        },
        line: defaultPlotOptions,
        spline: defaultPlotOptions,
        area: defaultPlotOptions,
        areaspline: defaultPlotOptions
      },
      series: [],
    };
  }

  /**
   * If custom configuration is passed, it merges with base configuration.
   * Creates chart and adds series.
   */
  createChart() {
    if (!isEmpty(this.chartOptions)) {
      this.baseConfiguration = merge(this.baseConfiguration, this.chartOptions);
    }

    this.chart = new Chart(this.baseConfiguration);

    this._seriesData.forEach((series: SeriesOptions) => {
      this.chart.addSeries(series, true, true);
    });
  }

  /**
   * Called when mouse over line chart's point.
   * Foreach the chart.series and push each series's hovered element's data
   * to point array and then omit the mouse over event with point object.
   *
   * @param   e    mouse over event
   */
  mouseOverLinePoint(e: Event) {
    const chart = this.chart.ref;
    const normalizedEvent = chart.pointer.normalize(e as PointerEvent) as PointerEventObject;
    const timeStamp = (normalizedEvent.target as any).x;
    const points = [];
    chart.series.forEach((serie: any) => {
      const index = findIndex(serie.data, { x: timeStamp});
      if (index > -1) {
        points.push(serie.data[index]);
      }
    });
    this.hoverPositionChange.emit(points);
  }

  /**
   * Called when mouse leave line chart's point.
   * Foreach the chart.series and push each series's last element's data
   * to point array and then omit the mouse over event with point object.
   */
  mouseLeaveLinePoint() {
    const chart = this.chart.ref;
    const points = [];
    (chart.series || []).forEach((series: any) => {
      // Exit if there is no series data.
      if (!get(series, 'data')) {
        return;
      }
      points.push(last(series.data));
    });
    this.hoverPositionChange.emit(points);
  }

  /**
   * Called when point gets selected on line graph.
   */
  selectLinePoint(event: any) {
    // Add appropriate properties and emit a PointSelectEventObject.
    this.pointSelect.emit(Object.assign(event, {
      x: event.target.x,
      y: event.target.y,
      chartRef: this.chart.ref,
    }));
  }

  /**
   * OnDestroy lifecycle hook.
   */
  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
