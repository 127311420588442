import { Injectable, Injector, Type } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { StateGuard } from './state-guard';

/**
 * A map of state guard names to function that can be used
 * to unregister each guard.
 */
export interface StateGuardMap {
  [key: string]: Function;
}
/**
 * This guard creates and registers state guards for UI Router.
 */
@Injectable({
  providedIn: 'root',
})
export class StateGuardService {

  /**
   * A map of state guard class to function that can be used to unregister each guard.
   */
  private registeredGuards = new Map<Type<StateGuard>, Function>();


  constructor(private injector: Injector, private logger: NGXLogger, private transitions: TransitionService) { }

  /**
   * Check if a state gaurd has already been registered
   *
   * @param guardClass  The state guard to check
   * @returns True if it has already been registered.
   */
  hasGuard<S extends StateGuard>(guardClass: Type<S>): boolean {
    return this.registeredGuards.has(guardClass);
  }

  /**
   * Registers a list state guard classes with ui-router on start transition hooks. The state guard
   * will be run on every transition. These run throughout the lifetime of the application and
   * typically do not need to be unregistered.
   *
   * @param   guardClasses  The state guards to register.
   * @param   injector  Optional injector to use to instantiate the service. This is useful if the guard is
   *                    defined in another module and has dependencies on services which are not provided in root.
   */
  registerStateGuards<S extends StateGuard>(guardClasses: Type<S>[], injector: Injector = this.injector) {
    const logPrefix = 'StateGuardService registerStateGuards method:';

    guardClasses.forEach(guardClass => {
      if (this.hasGuard(guardClass)) {
        this.logger.error(logPrefix, `${guardClass} is already registered.`);
        this.unregisterStateGuard(guardClass);
      }

      // Use the injector to create an instance of the service.
      const guardInstance: StateGuard = injector.get(guardClass);

      this.registeredGuards.set(
        guardClass,
        this.transitions.onStart(
          // Register for specific criteria if set, or for all states by default.
          guardInstance.matchCriteria || { },

          // Call the guard instance and return the result
          transition => {
            const result = guardInstance.onStart(transition);

            if (result instanceof Observable) {
              return result.pipe(take(1)).toPromise();
            }
            return result;
          },

          // Set the priority based on the guard itself.
          { priority: guardInstance.guardPriority }
        )
      );
    });
  }

  /**
   * Unregisters a state guard class from ui-router. If the state guard is not currently
   * registered, it will not do anything.
   *
   * @param   guardClass  The state guard to unregister.
   */
  unregisterStateGuard<S extends StateGuard>(guardClass: Type<S>) {
    if (guardClass && this.registeredGuards.has(guardClass)) {
      this.registeredGuards.get(guardClass)();
      this.registeredGuards.delete(guardClass);
    }
  }
}
