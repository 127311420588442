import { identity } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Directive c-field.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters')
    .controller('FieldCtrl', FieldCtrlFn)
    .directive('cField', function cFieldDirective() {
      return {
        require: {
          // find c-filters controller which maintain a list of filter fields
          // interface API's.
          cFilters: '?^^cFilters',
        },
        restrict: 'A',
        bindToController: true,
        controller: 'FieldCtrl',
      };
    });

  /**
   * $ngdoc Directive
   * @name C:cFilters.cField
   *
   * @requires cFilters
   * @description
   *   This directive works as a interface b/w c-filters and filter field and
   *   allow them communicate via cField API interface having methods like
   *   apply, clear, abort etc.
   *
   *   provided c-field-label translation key is used as label for filter pills
   *   and provided c-field-value path is used for filter pills value.
   *
   * @example
      <ui-select
        c-field
        c-field-label="source"
        c-field-value="protectionSource.name"
        name="sourceFilter"
        ng-model="$ctrl.filters.sourceFilter">
        ...
      </ui-select>

      <select-tenant
        multiple
        c-field
        c-field-label="organization"
        c-field-value="name"
        result-as="tenantId"
        name="jobTenantFilter"
        ng-model="$ctrl.filters.jobTenantFilter">
        ...
      </select-tenant>
   */
  function FieldCtrlFn(_, $attrs) {
    var $ctrl = this;

    // default c-field interfaces definitions.
    var cFieldInterface = {
      abort: angular.noop,
      apply: angular.noop,
      clear: angular.noop,
      disableAlwaysOpen: angular.noop,
      enableAlwaysOpen: angular.noop,
      getSelectedValue: angular.noop,
      getPillValues: getPillValues,
      multiple: false,
    };

    assign($ctrl, {
      actions: {},
      registeredUiSelectMap: {},

      // component methods
      startLoading: startLoading,
      endLoading: endLoading,
      registerUiSelect: registerUiSelect,
      deRegisterUiSelect: deRegisterUiSelect,
      getFieldValueKey: getFieldValueKey,

      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    /**
     * Initialize the c-field interface and register it to c-filters.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.name = $attrs.name;

      // init c-field interface with default values.
      assign($ctrl.actions, cFieldInterface);
      if ($ctrl.cFilters) {
        $ctrl.cFilters.registerField($ctrl.name, $ctrl.actions);
      }
    }

    /**
     * Clean up the c-field interface registration.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      if ($ctrl.cFilters) {
        $ctrl.cFilters.deRegisterField($ctrl.name);
      }
    }

    /**
     * Register c-field interface API's used to provide custom definitions used
     * by ui-select currently.
     *
     * @method   registerUiSelect
     */
    function registerUiSelect(fieldInterface) {
      assign($ctrl.actions, fieldInterface);
      $ctrl.cFilters.registerField($ctrl.name, $ctrl.actions);
    }

    /**
     * Mark field loading and to prevent applying filter if all fields are not
     * ready.
     *
     * @method   startLoading
     */
    function startLoading() {
      $ctrl.cFilters.startLoading($ctrl.name);
    }

    /**
     * Mark field loading and to prevent applying filter if all fields are not
     * ready.
     *
     * @method   endLoading
     */
    function endLoading() {
      $ctrl.cFilters.endLoading($ctrl.name);
    }

    /**
     * de-register c-field interface API's by setting it to default interfaces
     * values.
     *
     * @method   deRegisterUiSelect
     */
    function deRegisterUiSelect() {
      $ctrl.cFilters.deRegisterField($ctrl.name);
    }

    /**
     * returns the cFieldValue attribute
     *
     * @method   getFieldValueKey
     * @return   {String}    cFieldValue attribute
     */
    function getFieldValueKey() {
      return $attrs.cFieldValue;
    }

    /**
     * Get pills values for selected c-field value.
     *
     * @method   getPillValues
     */
    function getPillValues() {
      var fieldValueKey = getFieldValueKey();
      var selected = $ctrl.actions.getSelectedValue();
      var selectedValues = [];

      if (selected) {
        if ($ctrl.actions.multiple) {
          selectedValues = map(selected, fieldValueKey);
        } else {
          selectedValues = [
            fieldValueKey ? get(selected, fieldValueKey) : selected
          ];
        }
        selectedValues = selectedValues.filter(identity);
      }

      return {
        _original: selected,

        // translation key used to shown in the filter pills like 'jobType' etc
        label: $attrs.cFieldLabel,

        // path to reach field value shown in the pills and provided via
        // c-field-value property.
        fieldValueKey: fieldValueKey,

        // the selected c-field values to show in filter pills.
        values: selectedValues,

        // c-field API interface.
        actions: $ctrl.actions,
      };
    }
  }
})(angular);
