<cog-dashcard
  [title]="'dg.sc.dashboard.userActivity' | translate"
  [subtitle]="timeFrameLabel">
  <cog-dashcard-header-aux>
    <cog-icon shape="helix:help" [matTooltip]="'dg.sc.dashboard.userActivityHelp' | translate"></cog-icon>
  </cog-dashcard-header-aux>
  <ng-container *ngIf="!loading; else spinner">
    <ng-container *ngTemplateOutlet="topPatternsContent"></ng-container>
  </ng-container>
</cog-dashcard>

<ng-template #topPatternsContent>
  <mat-card-content>
    <mat-tab-group (selectedTabChange)="createChartData($event.index)">
      <mat-tab [label]="'dg.sc.dashboard.filesDeleted' | translate">
        <ng-template matTabContent>
          <ng-container *ngTemplateOutlet="chart"></ng-container>
        </ng-template>
      </mat-tab>

      <mat-tab [label]="'dg.sc.dashboard.filesOpened' | translate">
        <ng-template matTabContent>
          <ng-container *ngTemplateOutlet="chart"></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</ng-template>

<ng-template #chart>
  <div class="chart-container">
    <ng-container *ngIf="!barSeriesData.data?.length && !lineSeriesData.data?.length">
      <ng-container *ngTemplateOutlet="blankCard"></ng-container>
    </ng-container>
    <div class="chart-element" *ngIf="barSeriesData.data?.length">
      <h5>{{'dg.sc.dashboard.topUsers' | translate}}</h5>
      <cog-bar-chart
        [seriesData]="[barSeriesData]"
        [categories]="categories"
        [customChartOptions]="customOptions"
        [reflowOnFirstRender]="true">
      </cog-bar-chart>
    </div>
    <div class="chart-element" *ngIf="lineSeriesData.data?.length">
      <h5>{{'dg.sc.dashboard.byTime' | translate}}</h5>
      <cog-line-chart
        [seriesData]="[lineSeriesData]"
        colorSetClass="performance-card-chart-colors"
        [chartOptions]="options">
      </cog-line-chart>
    </div>
  </div>
</ng-template>

<ng-template #blankCard>
  <div class="full-width">
    <cog-blank-card type="helix:no-data-cluster-cohesity" size="sm"></cog-blank-card>
  </div>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
