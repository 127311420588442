import { ReportEnvironment } from '../environment.constants';

/**
 * Map environment types to labels for chart series titles. The typing will cause compile
 * errors if there are any environments missing here.
 */
const environmentTypes: {
  [k in ReportEnvironment]: {
    label: string;
  }
} = {
  kAcropolis: {
    label: 'enum.environment.kAcropolis',
  },
  kAD: {
    label: 'enum.environment.kAD',
  },
  kAgent: {
    label: 'enum.environment.kAgent',
  },
  kAWS: {
    label: 'enum.environment.kAWS',
  },
  kAWSNative: {
    label: 'enum.environment.kAWSNative',
  },
  kAWSSnapshotManager: {
    label: 'enum.environment.kAWSSnapshotManager',
  },
  kAwsRDSPostgresBackup: {
    label: 'enum.environment.kAwsRDSPostgresBackup'
  },
  kAwsRDSPostgres: {
    label: 'enum.environment.kAwsRDSPostgres'
  },
  kAwsAuroraPostgres: {
    label: 'enum.environment.kAwsAuroraPostgres'
  },
  kAwsDynamoDB: {
    label: 'enum.environment.kAwsDynamoDB'
  },
  kAwsS3: {
    label: 'enum.environment.kAwsS3',
  },
  kAzure: {
    label: 'enum.environment.kAzure',
  },
  kAuroraSnapshotManager: {
    label: 'enum.environment.kAuroraSnapshotManager',
  },
  kAzureNative: {
    label: 'enum.environment.kAzureNative',
  },
  kAzureSnapshotManager: {
    label: 'enum.environment.kAzureSnapshotManager',
  },
  kAzureSQL: {
    label: 'enum.environment.kAzureSQL',
  },
  kCassandra: {
    label: 'enum.environment.kCassandra',
  },
  kCouchbase: {
    label: 'enum.environment.kCouchbase',
  },
  kElastifile: {
    label: 'enum.environment.kElastifile',
  },
  kAzureEntraID: {
    label: 'enum.environment.kAzureEntraID',
  },
  kEntraID: {
    label: 'enum.environment.kAzureEntraID',
  },
  kExchange: {
    label: 'enum.environment.kExchange',
  },
  kFlashBlade: {
    label: 'enum.environment.kFlashBlade',
  },
  kGCP: {
    label: 'enum.environment.kGCP',
  },
  kGCPNative: {
    label: 'enum.environment.kGCPNative',
  },
  kGenericNas: {
    label: 'enum.environment.kGenericNas',
  },
  kGPFS: {
    label: 'enum.environment.kGPFS',
  },
  kHBase: {
    label: 'enum.environment.kHBase',
  },
  kHdfs: {
    label: 'enum.environment.kHdfs',
  },
  kHive: {
    label: 'enum.environment.kHive',
  },
  kHyperFlex: {
    label: 'enum.environment.kHyperFlex',
  },
  kHyperV: {
    label: 'enum.environment.kHyperV',
  },
  kHyperVVSS: {
    label: 'enum.environment.kHyperVVSS',
  },
  kIbmFlashSystem: {
    label: 'enum.environment.kIbmFlashSystem',
  },
  kIsilon: {
    label: 'enum.environment.kIsilon',
  },
  kKubernetes: {
    label: 'enum.environment.kKubernetes',
  },
  kKVM: {
    label: 'enum.environment.kKVM',
  },
  kMongoDB: {
    label: 'enum.environment.kMongoDB',
  },
  kMongoDBPhysical: {
    label: 'enum.environment.kMongoDBPhysical',
  },
  kNetapp: {
    label: 'enum.environment.kNetapp',
  },
  kNimble: {
    label: 'enum.environment.kNimble',
  },
  kHpe3Par: {
    label: 'enum.environment.kHpe3Par',
  },
  kO365: {
    label: 'enum.environment.kO365',
  },
  kO365Outlook: {
    label: 'enum.environment.kO365Outlook',
  },
  kO365Exchange: {
    label: 'enum.environment.kO365Exchange',
  },
  kO365ExchangeCSM: {
    label: 'enum.environment.kO365ExchangeCSM',
  },
  kO365Group: {
    label: 'enum.environment.kO365Group',
  },
  kO365OneDrive: {
    label: 'enum.environment.kO365OneDrive',
  },
  kO365OneDriveCSM: {
    label: 'enum.environment.kO365OneDriveCSM',
  },
  kO365PublicFolders: {
    label: 'enum.environment.kO365PublicFolders',
  },
  kO365Sharepoint: {
    label: 'enum.environment.kO365Sharepoint',
  },
  kO365SharepointCSM: {
    label: 'enum.environment.kO365SharepointCSM',
  },
  kO365Teams: {
    label: 'enum.environment.kO365Teams',
  },
  kOracle: {
    label: 'enum.environment.kOracle',
  },
  kOther: {
    label: 'enum.environment.kOther',
  },
  kPhysical: {
    label: 'enum.environment.kPhysical',
  },
  kPhysicalFiles: {
    label: 'enum.environment.kPhysicalFiles',
  },
  kPuppeteer: {
    label: 'enum.environment.kPuppeteer',
  },
  kPure: {
    label: 'enum.environment.kPure',
  },
  kRDSSnapshotManager: {
    label: 'enum.environment.kRDSSnapshotManager',
  },
  kRemoteAdapter: {
    label: 'enum.environment.kRemoteAdapter',
  },
  kSAPHANA: {
    label: 'enum.environment.kSAPHANA',
  },
  kSfdc: {
    label: 'enum.environment.kSfdc',
  },
  kSite: {
    label: 'enum.environment.kSite',
  },
  kSQL: {
    label: 'enum.environment.kSQL',
  },
  kStorageSnapshotProvider: {
    label: 'enum.environment.kStorageSnapshotProvider'
  },
  kUDA: {
    label: 'enum.environment.kUDA',
  },
  kUnknown: {
    label: 'enum.environment.kUnknown',
  },
  kVCD: {
    label: 'enum.environment.kVCD',
  },
  kView: {
    label: 'enum.environment.kView',
  },
  kVMware: {
    label: 'enum.environment.kVMware',
  },
};

/**
 * Map target types to labels for chart series titles.
 */
const targetTypes: {
  [k: string]: {
    label: string;
  };
} = {
  Amazon: {
    label: 'enum.targetType.Amazon',
  },
  AmazonC2S: {
    label: 'enum.targetType.AmazonC2S',
  },
  AmazonS3StandardIA: {
    label: 'enum.targetType.AmazonS3StandardIA',
  },
  AWSGovCloud: {
    label: 'enum.targetType.AWSGovCloud',
  },
  Azure: {
    label: 'enum.targetType.Azure',
  },
  AzureArchive: {
    label: 'enum.targetType.AzureArchive',
  },
  AzureGovCloud: {
    label: 'enum.targetType.AzureGovCloud',
  },
  AzureStandard: {
    label: 'enum.targetType.AzureStandard',
  },
  Coldline: {
    label: 'enum.targetType.Coldline',
  },
  Glacier: {
    label: 'enum.targetType.Glacier',
  },
  Google: {
    label: 'enum.targetType.Google',
  },
  GoogleDRA: {
    label: 'enum.targetType.GoogleDRA',
  },
  GoogleStandard: {
    label: 'enum.targetType.GoogleStandard',
  },
  NAS: {
    label: 'enum.targetType.NAS',
  },
  Nearline: {
    label: 'enum.targetType.Nearline',
  },
  Oracle: {
    label: 'enum.targetType.Oracle',
  },
  OracleTierArchive: {
    label: 'enum.targetType.OracleTierArchive',
  },
  OracleTierStandard: {
    label: 'enum.targetType.OracleTierStandard',
  },
  QStarTape: {
    label: 'enum.targetType.QStarTape',
  },
  S3: {
    label: 'enum.targetType.S3',
  },
  S3Compatible: {
    label: 'enum.targetType.S3Compatible',
  },
};

/**
 * This is a map of all of th new report values that would show for a report. It maps each
 * value to a translation key to use for labels, and can be used to include additional
 * metadata info and chart styling.
 */
export const reportDataTypes = {
  ...environmentTypes,
  ...targetTypes,
  0: {
    label: 'enum.number.0',
    className: 'chart-series-success',
  },
  1: {
    label: 'enum.number.1',
    className: 'chart-series-warning-low',
  },
  2: {
    label: 'enum.number.2',
    className: 'chart-series-warning',
  },
  3: {
    label: 'enum.number.3',
    className: 'chart-series-critical',
  },
  countObjectUuid: {
    label: 'objectCount',
  },
  Protected: {
    label: 'reporting.protected',
    className: 'chart-series-success',
  },
  Unprotected: {
    label: 'reporting.unprotected',
    className: 'chart-series-critical',
  },
  kAccepted: {
    label: 'enum.backupJob.kAccepted',
    className: 'chart-series-info',
  },
  kCanceled: {
    label: 'enum.backupJob.kCanceled',
    className: 'chart-series-warning-low',
  },
  kCanceling: {
    label: 'enum.backupJob.kCanceling',
    className: 'chart-series-warning-low',
  },
  kFailure: {
    label: 'enum.backupJob.kFailure',
    className: 'chart-series-critical',
  },
  kMissed: {
    label: 'enum.backupJob.kMissed',
    className: 'chart-series-warning',
  },
  kOnHold: {
    label: 'enum.backupJob.kOnHold',
    className: 'chart-series-info',
  },
  kRunning: {
    label: 'enum.backupJob.kRunning',
    className: 'chart-series-info',
  },
  kSkipped: {
    label: 'enum.backupJob.kSkipped',
    className: 'chart-series-warning',
  },
  kSuccess: {
    label: 'enum.backupJob.kSuccess',
    className: 'chart-series-success',
  },
  kWarning: {
    label: 'enum.backupJob.kWarning',
    className: 'chart-series-warning',
  },
  Success: {
    label: 'enum.backupJob.kSuccess',
    className: 'chart-series-success',
  },
  Failed: {
    label: 'enum.backupJob.kFailure',
    className: 'chart-series-critical',
  },
  Successful: {
    label: 'reporting.successful',
    className: 'chart-series-success',
  },
  Unsuccessful: {
    label: 'reporting.failure',
    className: 'chart-series-critical',
  },
  HasSuccessfulBackups: {
    label: 'reporting.hasBackups',
    className: 'chart-series-success'
  },
  HasNoSuccessfulBackups: {
    label: 'reporting.hasNoBackups',
    className: 'chart-series-critical',
  },
  totalDataWrittenSizeBytes: {
    label: 'reporting.dataWritten'
  },
  totalSourceDeltaSizeBytes: {
    label: 'reporting.dataRead'
  },
  usagePercent: {
    label: 'reporting.usagePercentage'
  },
  vms: {
    label: 'enum.envGroup.vms',
  },
  nas: {
    label: 'enum.envGroup.nas',
  },
  dbs: {
    label: 'enum.envGroup.dbs',
  },
  views: {
    label: 'enum.envGroup.views',
  },
  applications: {
    label: 'enum.envGroup.applications',
  },
  other: {
    label: 'reporting.other',
  },
  Archival: {
    label: 'enum.activityType.Archival',
  },
  Backup: {
    label: 'enum.activityType.Backup',
  },
  Replication: {
    label: 'enum.activityType.Replication',
  },
  Vault: {
    label: 'enum.activityType.Vault',
  },
  sumDataIngestedRetainedBytes: {
    label: 'sourceDataRetainedEnd',
  },
  sumScRetainedBytes: {
    label: 'storageConsumedForRetainedDataEnd.chart',
  }
};
