import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RegionClusterScanResult } from '@cohesity/api/secops';
import { IsAllSelectedFn } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { meanBy } from 'lodash-es';

/**
 * Security advisor scores list table component.
 */
@Component({
  selector: 'dg-pa-scores-list-table',
  templateUrl: './scores-list-table.component.html',
  styleUrls: ['./scores-list-table.component.scss'],
})
export class ScoresListTableComponent {
  /**
   * Scores data to be displayed in table.
   */
  @Input() scoresData: RegionClusterScanResult[];

  /**
   * True if table has to be in loading state.
   */
  @Input() loading: boolean;

  /**
   * Disable row actions if true.
   */
  @Input() disableRowActions: boolean;

  /**
   * Stores the selected rows.
   */
  @Input() selection: SelectionModel<any>;

  /**
   * True if row click is enabled.
   */
  @Input() isRowClickable = false;
  /**
   * Dispatched event when table row is clicked.
   */
  @Output() readonly rowClicked = new EventEmitter();

  /**
   * Determines if all of the selectable rows are selected or not.
   */
  @Input() isAllSelectedFn: IsAllSelectedFn;

  /**
   * Determines if specific colum width is to apply or not in the table.
   */
  @Input() customWidth = false;

  /**
   * Holds score table column names for standard presentation.
   */
  readonly columns = ['lowestScore', 'security-risk', 'regionName', 'clusterNames', 'planName', 'lastScanTime', 'action'];

  /**
   * Dispatched event when table action row is clicked
   */
  @Output() fixIssueActionClicked = new EventEmitter<RegionClusterScanResult>();

  constructor(private translate: TranslateService) {}

  /**
   * Handle click of individual action for an issue.
   *
   * @param rowData Scan result for which inspect issues has to be performed.
   */
  handleViewIssues(rowData: RegionClusterScanResult, event: MouseEvent) {
    this.fixIssueActionClicked.emit(rowData);
    event.stopPropagation();
  }

  /**
   * Gets the average of the results of the cluster
   *
   * @param scanResult is a single cluster scan result
   */
  getAverage(scanResult: RegionClusterScanResult): number {
    return scanResult.results ? Math.round(meanBy(scanResult.results, (results) => results.currentScore)) : undefined;
  }

  /**
   * Gets the security risk based on the score.
   *
   * @param lowestScore
   */
  getSecurityRisk(lowestScore: number): string {
    if (lowestScore < 70) {
      return this.translate.instant('highRisk');
    } else if (lowestScore <= 90) {
      return this.translate.instant('mediumRisk');
    } else {
      return this.translate.instant('lowRisk');
    }
  }
}
