import { inject, Injectable } from '@angular/core';
import { EventTrackingService } from '@cohesity/helix';
import { heliosProductionHostnames } from '@cohesity/iris-shared-constants';
import { datadogRum } from '@datadog/browser-rum';
import { debounce, isEmpty, startCase } from 'lodash-es';
import mixpanel from 'mixpanel-browser';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import {
  dmsAwsFreeTrialExpired,
  dmsAzureFreeTrialExpired,
  dmsTenantId,
  dmsTenantName,
  flagEnabled,
  hasClusters,
  IrisContextService,
  isAccountLocked,
  isAwsColdFreeTrialUser,
  isAwsWarmFreeTrialUser,
  isAzureColdFreeTrialUser,
  isAzureHotFreeTrialUser,
  isClusterScope,
  isDGaasScope,
  isDGaaSUser,
  isDmsFreeTrialExpired,
  isDmsFreeTrialUser,
  isDmsOnlyUser,
  isDmsUser,
  isDraasFreeTrialExpired,
  isDraasFreeTrialUser,
  isDraasScope,
  isDraasUser,
  isGaiaUser,
  isHeliosTenantUser,
  isHybridUser,
  isMcm,
  isMcmGovCloud,
  isMcmOnPrem,
  isMcmSaaS,
  isPrivilegedUser,
  isRestrictedUser,
  isRpaasFreeTrialUser,
  isRpaasUser,
  isSaasServiceUser,
  isTenantUser,
} from '../iris-context';
import { HeliosAnalyticsUser } from './helios-analytics-user.model';
import {
  DataIdTrackingEventMap,
  StartsWithDataIdsMap,
  TrackingEvent,
  TrackingEventKeys,
} from './tracking-events.constants';

/**
 * Overwrite global window object to add keys which will be dynamically added.
 */
declare global {
  interface Window {
    /**
     * Intercom's javascript bundle is stored in the "Intercom" variable in
     * window object.
     */
    Intercom: any;

    /**
     * Window variable for intercom settings.
     */
    intercomSettings: any;

    /**
     * Window variable for intercom app id.
     */
    intercomAppId: any;
  }
}

/**
 * Different environments under which analytics can be loaded.
 */
export enum AnalyticsEnvironment {
  /**
   * Used when proxying local code to a cluster.
   */
  development,

  /**
   * Used when the build is not internal.
   */
  production,
}

/**
 * Properties associcated with the captured event
 */
export interface EventProperties {
  /**
   * Current scope of the user
   */
  scopeName: 'allClusters' | 'cluster' | string;
  /**
   * Query params associated with the event page
   */
  searchParams?: Record<string, unknown>;
  /**
   * Name of the UI router state
   */
  stateName?: string;
  /**
   * URL of the event page
   */
  pageUrl?: string;
}

/**
 * Keys used by new mixpanel projects to load the SDK.
 */
const mixpanelKeys = {
  [AnalyticsEnvironment.development]: '0c556dde792e18b848c1f5a61740108f',
  [AnalyticsEnvironment.production]: '6e300a6ea55f900b117b6d74ae36eaa5',
};

/**
 * Keys used by legacy mixpanel projects to load the SDK.
 */
const mixpanelLegacyKeys = {
  [AnalyticsEnvironment.development]: '75b38e2251f4e0e27c9c4cff3405b1ca',
  [AnalyticsEnvironment.production]: '26ad2ca9c45c0eac7cc55e6a5c4ce18a',
};

/**
 * Base analytics service to set up Intercom messenger and Mixpanel analytics.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class CoreAnalyticsService {
  /**
   * Cache the user details object. This doesn't change until the user logs in
   * again.
   */
  private userDetails: HeliosAnalyticsUser;

  /**
   * Function to call the track event in a debounced way.
   */
  debouncedTrackDataIdEvent: (item: string, eventTrackingId: string, element: HTMLElement) => void;

  /**
   * Service to track events generated by helix component interactions
   */
  readonly eventTrackingService = inject(EventTrackingService);

  /**
   * Iris context service
   */
  protected irisContextService = inject(IrisContextService);

  /**
   * Logger service
   */
  readonly ngxLogger = inject(NGXLogger);

  /**
   * Whether the user is identified.
   */
  protected userIdentified = new BehaviorSubject<boolean>(false);

  /**
   * subscription reference to perform the cleanup later
   */
  private eventsSubscription: Subscription = null;

  /**
   * subscription reference to perform the cleanup later
   */
  private userSetupSubscription: Subscription = null;

  /**
   * subscription reference to perform the cleanup later
   */
  private userIndentifiedSubscription: Subscription = null;

  /**
   * Function to determine whether analytics is enabled.
   *
   * @return Whether analytics is enabled.
   */
  get analyticsEnabled(): boolean {
    const irisContext = this.irisContextService.irisContext;

    // The order of checks here is important since both isMcmGovCloud and
    // isMcmOnPrem will also return true for isMcm.

    if (isMcmGovCloud(irisContext)) {
      // Whether Helios is hosted in gov cloud.
      return flagEnabled(irisContext, 'heliosAnalyticsGovCloudEnabled');
    }

    if (isMcm(irisContext) || isDGaasScope(irisContext) || isDraasScope(irisContext)) {
      // Regular Helios.
      return flagEnabled(irisContext, 'heliosAnalyticsEnabled');
    }

    return false;
  }

  /**
   * Whether intercom is enabled.
   */
  get intercomEnabled(): boolean {
    return this.analyticsEnabled && flagEnabled(
      this.irisContextService.irisContext,
      'heliosAnalyticsIntercomEnabled'
    );
  }

  /**
   * Whether mixpanel is enabled.
   */
  get mixpanelEnabled(): boolean {
    return this.analyticsEnabled && flagEnabled(
      this.irisContextService.irisContext,
      'heliosAnalyticsMixpanelEnabled'
    );
  }

  /**
   * Whether datadog RUM is enabled.
   */
  get datadogRumEnabled(): boolean {
    return this.analyticsEnabled && flagEnabled(
      this.irisContextService.irisContext,
      'datadogRumEnabled'
    );
  }


  /**
   * Function to return the formatted event based on the cog data id.
   *
   * @param item The cog data id value.
   * @param eventTrackingId The event tracking id value.
   * @param element The element which was interacted with.
   *
   * @return The formatted event.
   */
  getFormattedEvent(
    item: string,
    eventTrackingId: string,
    element: HTMLElement
  ): {
    event: string;
    properties: { optionValue: string };
  } {
    let value;
    let optionValue;

    if (eventTrackingId) {
      value = eventTrackingId;
    } else {
      const indexOfAnchor = item.indexOf('anchor');
      const indexOfOption = item.indexOf('option');
      value = item;

      if (indexOfAnchor > -1) {
        // Ignore anything after "anchor" in cog data ids.
        value = item.slice(0, indexOfAnchor + 6);
      }

      if (indexOfOption > -1) {
        // Ignore anything after "option" in cog data ids.
        // But store the value to pass to analytics properties.
        value = item.slice(0, indexOfOption + 6);
        optionValue = item.slice(indexOfOption + 7);
      }
    }

    // Container path is the prefix which contains information for where
    // the tracking event occurred. This is derived from the URL of the app.
    let containerPath = '';

    if (['mat-sidenav', 'mat-toolbar'].every(selector => !document.querySelector(selector)?.contains(element))) {
      // Don't add container path to navigation and toolbar clicks as they are
      // not part of any page.
      containerPath = window.location.pathname
        .split('/')
        .filter(pathValue => !/\d/.test(pathValue))
        .join('-');
    }
    // Remove multiple occurrences of - with one.
    return {
      event: containerPath
        ? [startCase(containerPath), startCase(value.replace(/-+/g, ' '))].join(' - ')
        : startCase(value.split('-').join('-').replace(/-+/g, ' ')),
      properties: { optionValue },
    };
  }

  /**
   * The default properties for a tracking event.
   *
   * @return The default properties.
   */
  getDefaultProperties(): EventProperties {
    const fromUrlObject = new URL(window.location.href);

    return {
      scopeName: 'allClusters',
      searchParams: undefined,
      stateName: '',
      pageUrl: `${fromUrlObject.origin}${fromUrlObject.pathname}`,
    };
  }

  /**
   * Function to call to track an event and send it to mixpanel.
   *
   * @param key Key of the analytics event.
   * @param properties Any associated properties of the analytics event.
   * @param legacy Whether the destination is legacy mixpanel or new.
   */
  track(key: string, properties?: any, legacy = true) {
    if (!key || !this.userIdentified.value) {
      return;
    }

    const eventName = TrackingEventKeys[key];

    if (!eventName) {
      // There are two maps DataIdTrackingEventMap and TrackingEventKeys. This
      // warning will show up when someone has added this to
      // `DataIdTrackingEventMap` but not `TrackingEventKeys`, which should
      // typically be an error.
      this.ngxLogger.warn(`A key name is missing for the event key ${key}`);
    }

    // Put this data everywhere except Mixpanel New
    this.analyticsTrack(eventName || key, properties, legacy);
  }

  /**
   * Function to make the analytics based on the given keys.
   *
   * @param event The event to be tracked.
   * @param properties The properties of the event.
   * @param legacy Whether the destination is legacy mixpanel or new.
   */
  analyticsTrack<T>(event: string, properties: T, legacy: boolean = false) {
    if (isClusterScope(this.irisContextService.irisContext)) {
      // Don't send analytics tracking events in cluster scope.
      return;
    }

    if (!this.userIdentified.value) {
      // piggyback on user identified to check if mixpanel is initialised.
      // if user is not identified, don't send the analytics tracking events
      return;
    }

    if (this.mixpanelEnabled) {
      if (legacy) {
        // Legacy events are the events which are hardcoded in the codebase (as
        // opposed to cogDataId based auto tracking). No new legacy events are
        // added, but to ensure the existing Mixpanel dashboards and reports
        // continue to work, send these events to a separate project.
        mixpanel.legacy.track(event, {
          ...properties,

          // While this key is not needed for Mixpanel's tracking purposes,
          // having this key show up when looking at events in a Mixpanel
          // debugger helps distinguish which project the event is going in.
          legacyEvent: true,
        });
      } else {
        mixpanel.track(event, properties);
      }
    }
  }

  /**
   * Function to setup tracking.
   */
  setupTracking(): Subscription | null {
    if (this.eventsSubscription) {
      // tracking is already setup
      return null;
    }

    // Multiple calls are fired to this. Only track the first call and ignore rest.
    this.debouncedTrackDataIdEvent = debounce<typeof this.debouncedTrackDataIdEvent>(
      (item, eventTrackingId, element) => {
        if (!item) {
          return;
        }
        const { event, properties } = this.getFormattedEvent(item, eventTrackingId, element);
        // Put this data everywhere except classic Mixpanel
        this.analyticsTrack(
          event,
          {
            ...properties,
            ...this.getDefaultProperties(),
          },
          false
        );
      },
      0,
      {
        leading: true,
        trailing: false,
      }
    );

    // Listen to tracking events coming from helix components and track them for
    // analytics.
    this.eventsSubscription = this.eventTrackingService.event$.subscribe(event => {
      const { id, key, properties, element, dataId, eventTrackingId } = event;

      this.debouncedTrackDataIdEvent(dataId, eventTrackingId, element);

      if (key) {
        // Bypass trying to lookup analytics key and properties.
        this.track(key, properties);

        return;
      }

      const analyticsEvents = this.getAnalyticsEvent(id);

      if (!analyticsEvents.length) {
        return;
      }

      for (const analyticsEvent of analyticsEvents) {
        if (
          analyticsEvent.parentSelector &&
          !document.querySelector(analyticsEvent.parentSelector)?.contains(element)
        ) {
          // If the event has a parentSelector restriction, make sure the event's
          // element is within the specified parent selector.
          continue;
        }

        this.track(typeof analyticsEvent === 'string' ? analyticsEvent : analyticsEvent.key, {
          ...analyticsEvent.properties,
          ...properties,
        });
      }
    });

    return this.eventsSubscription;
  }

  /**
   *
   * @param userName username of the logged-in user
   * @param userDetails details of the user that might be useful for analytics
   */
  identifyAnalyticsUser(userName: string, userDetails: HeliosAnalyticsUser) {
    if (this.intercomEnabled && typeof window.Intercom === 'function') {
      // Initialize intercom with the logged-in user and set the user attributes.
      window.Intercom('boot', {
        ...userDetails,
        app_id: window.intercomAppId,
        user_id: userName,
      });
    }

    if (this.mixpanelEnabled) {
      // Identify the logged-in user for both mixpanel projects.
      mixpanel.identify(userName);
      mixpanel.legacy.identify(userName);

      // Set the user attributes for the logged-in user for both mixpanel projects.
      mixpanel.people.set(userDetails);
      mixpanel.legacy.people.set(userDetails);
    }

    if (this.datadogRumEnabled) {
      datadogRum.setUser({
        ...userDetails,
        id: userName,
        email: userDetails.email,
        name: userDetails.name,
      });
    }
  }
  /**
   * Resets user identity.
   * Useful when the user logs out.
   */
  resetAnalyticsUser(): void {
    if (this.mixpanelEnabled) {
      mixpanel.reset();
      mixpanel.legacy.reset();
    }

    this.userDetails = null;
    this.userIdentified.next(false);
  }

  /**
   * Function to return all the associated tracking events of a click event with
   * a data id.
   */
  getAnalyticsEvent(id: string): TrackingEvent[] {
    const exactMatches = [].concat(DataIdTrackingEventMap[id]);
    const startsWithMatches = [];

    for (const [key, value] of Object.entries(StartsWithDataIdsMap)) {
      if (id.startsWith(key)) {
        startsWithMatches.push(...value);
      }
    }

    return [...exactMatches, ...startsWithMatches].filter(Boolean);
  }

  /**
   * Method to create a user persona initially and then identify them in subsequent logins.
   *
   * @param scopeName name for the current scope
   */
  protected identifyUser(scopeName?: string) {
    const irisContext = this.irisContextService.irisContext;
    const user = irisContext.user;
    let email = user.username;

    const currentScopeName = scopeName || this.getDefaultProperties().scopeName;

    if (email.endsWith('.trial')) {
      // For specific scenarios, an existing Cohesity customer will get a new
      // account just for DMaaS trial. In this case, a ".trial" is appended to
      // the username, which is otherwise generally the email address. This
      // ".trial" needs to be removed to make sure the email address remains
      // valid.
      email = email.slice(0, email.lastIndexOf('.trial'));
    }

    this.userDetails = this.userDetails || {
      // Generic information
      name: `${user.firstName} ${user.lastName}`,
      roles: user.roles,
      rolesValue: user.roles.join(', '),
      privilegeIds: user.privilegeIds,
      privilegeIdsValue: user.privilegeIds.join(', '),
      email,
      salesforceAccountId: user.salesforceAccount?.accountId,
      salesforceUserId: user.salesforceAccount?.userId,
      salesUser: isPrivilegedUser(irisContext, 'sales'),
      supportUser: isPrivilegedUser(irisContext, 'support'),
      saasServiceUser: isSaasServiceUser(irisContext),
      tenantUser: isTenantUser(irisContext),
      heliosTenantUser: isHeliosTenantUser(irisContext),
      restrictedUser: isRestrictedUser(irisContext),
      hasClusters: hasClusters(irisContext),
      accountLocked: isAccountLocked(irisContext),
      dmsTenantId: dmsTenantId(irisContext),
      dmsTenantName: dmsTenantName(irisContext),

      // MCM mode information
      mcmSaasUser: isMcmSaaS(irisContext),
      mcmOnPremUser: isMcmOnPrem(irisContext),
      mcmGovCloudUser: isMcmGovCloud(irisContext),

      // Set MCM scope name below.
      activeMcmScope: '',

      // DMS (DataProtect) related user attributes
      dmsAwsFreeTrialExpired: dmsAwsFreeTrialExpired(irisContext),
      dmsAzureFreeTrialExpired: dmsAzureFreeTrialExpired(irisContext),
      dmsFreeTrialUser: isDmsFreeTrialUser(irisContext),
      dmsOnlyUser: isDmsOnlyUser(irisContext),
      dmsUser: isDmsUser(irisContext),

      // DRaaS (SiteContinuity) related user attributes
      draasFreeTrialExpired: isDraasFreeTrialExpired(irisContext),
      draasFreeTrialUser: isDraasFreeTrialUser(irisContext),
      draasUser: isDraasUser(irisContext),
      hybridUser: isHybridUser(irisContext),

      // RPaaS (FortKnox) related user attributes
      rpaasFreeTrialUser: isRpaasFreeTrialUser(irisContext),
      rpaasAwsWarmTrialUser: isAwsWarmFreeTrialUser(irisContext),
      rpaasAwsColdTrialUser: isAwsColdFreeTrialUser(irisContext),
      rpaasAzureHotTrialUser: isAzureHotFreeTrialUser(irisContext),
      rpaasAzureColdTrialUser: isAzureColdFreeTrialUser(irisContext),
      rpaasUser: isRpaasUser(irisContext),

      // DGaaS (Data Governance) related user attributes
      dgaasUser: isDGaaSUser(irisContext),

      // Data Insights aka gaia related user attributes
      diaasUser: isGaiaUser(irisContext),

      // Deprecated
      trialUser: isDmsFreeTrialUser(irisContext),
      dmsFreeTrialExpired: isDmsFreeTrialExpired(irisContext),
    };

    // MCM scope is not a user attribute, but this is useful for showing
    // dynamic widgets in intercom based on the user's current scope.
    this.userDetails.activeMcmScope = currentScopeName;

    // Identify the logged-in user for analytics.
    this.identifyAnalyticsUser(user.username, this.userDetails);

    // Mark user as identified.
    this.userIdentified.next(true);
  }

  /**
   * Function to return which analytics environment the app should be loaded in.
   *
   * @param isProductionEnv true if its a production environment
   * @returns analytics environment
   */
  protected getAnalyticsEnvironment(isProductionEnv: boolean): AnalyticsEnvironment {
    if (heliosProductionHostnames.includes(window.document.location.hostname) && isProductionEnv) {
      // Use production key if UI is deployed in production.
      return AnalyticsEnvironment.production;
    }

    // Otherwise use the development key.
    return AnalyticsEnvironment.development;
  }

  /**
   * Function to setup user details for analytics.
   */
  protected setupUser(): Subscription {
    return this.irisContextService.irisContext$.pipe(
      // Only setup user details when a scope is selected (app is fully loaded).
      filter((irisContext) =>
        !isEmpty(irisContext.selectedScope)
      ),

      // Only emit when scope is changed
      distinctUntilChanged(),
    ).subscribe(() => {
      // Wait on the user to be identified.
      this.identifyUser();
    });
  }

  /**
   * Initializes the analytics for the application
   *
   * @param isProductionEnv true, if its a production environment
   */
  initializeAnalytics(isProductionEnv: boolean) {
    const analyticsEnvironment = this.getAnalyticsEnvironment(isProductionEnv);

    if (this.mixpanelEnabled) {
      // Initialize mixpanel
      const mixpanelAppId = mixpanelKeys[analyticsEnvironment];
      const mixpanelLegacyAppId = mixpanelLegacyKeys[analyticsEnvironment];
      mixpanel.init(mixpanelAppId);
      mixpanel.init(mixpanelLegacyAppId, {}, 'legacy');
    }

    // Initialize user attributes.
    this.userSetupSubscription = this.setupUser();

    this.userIndentifiedSubscription = this.userIdentified.pipe(
      filter((value) => !!value),
      take(1),
    ).subscribe(() => {
      this.setupTracking();
    });
  }

  /**
   * Function to terminate analytics.
   */
  terminateAnalytics() {
    this.resetAnalyticsUser();

    // cancel all the subsciptions
    [
      this.userSetupSubscription,
      this.eventsSubscription,
      this.userIndentifiedSubscription
    ].forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
