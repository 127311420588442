import { intersectionWith } from 'lodash-es';
import { some } from 'lodash-es';
import { last } from 'lodash-es';
import { intersection } from 'lodash-es';
import { set } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { get } from 'lodash-es';
// COMPONENT: Recover DB

// NOTE: Support for restore of SQL VMs was removed in 6.0. However, because
// that change landed late and with the hightened need to minimize unknown
// regressions, all the code support for that is left in tact. Only the SQL
// search query params were changed to remove them from search results. Remove
// VM support from DB restore JS & templates at or around 6.1 time frame.

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.dbRestore';
  var moduleDeps = ['C.jobRunsService'];
  var flowTypes = ['recover', 'clone'];
  var dbs = ['sql', 'oracle'];
  var flowType = flowTypes[0];
  var db = dbs[0];

  // Is overwritten in activate() and exposed on the $scope
  var isClone = false;
  var isSQL = false;
  var isOracle = false;

  angular
    .module(moduleName, moduleDeps)
    .config(configFn)
    .controller('restoreDBParentController', restoreDBParentControllerFn);

  function configFn($stateProvider) {
    var canAccessGenerator = (privilege, workflow) => ctx => {
      const appEnv = (dbType => {
        switch (dbType) {
          case 'sql':
            return ['kSQL'];
          case 'oracle':
            return ['kOracle'];
          default:
            return ['kSQL', 'kOracle'];
        }
      })(ctx.stateParams.dbType || db);

      return ctx[privilege] && ctx.canAccessSomeEnv(appEnv, workflow);
    };

    var restoreModifyAccess = canAccessGenerator('RESTORE_MODIFY');
    var cloneModifyAccess = canAccessGenerator('CLONE_MODIFY', 'clone');

    // Configure the Recover states & routes
    $stateProvider
      .state('recover-db', {
        url: '^/protection/recovery/db/{dbType}/',

        // CSH (help) keys are set dynamically in the method `updateStep` in
        // this file
        title: 'Recover Databases',
        canAccess: restoreModifyAccess,
        parentState: 'recover',
        redirectTo: 'recover-db.search',
        params: {
          dbType: { type: 'string' },
          flowType: flowTypes[0],
        },
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'restoreDBParentController as $ctrl',
          },
          'col-l@recover-db': {
            templateUrl: 'app/protection/recovery/db/db-parent.html',
          }
        }
      })
      .state('recover-db.search', {
        url: 'search',

        // CSH (help) keys are set dynamically in the method `updateStep` in
        // this file
        title: 'Recover Databases: Select Object',
        canAccess: restoreModifyAccess,
        parentState: 'recover-db',
        views: {
          'canvas@recover-db': {
            templateUrl: 'app/protection/recovery/db/db-search.html',
            controller: 'restoreDBSearchController as $ctrl',
          }
        }
      })
      .state('recover-db.options', {
        url: 'options?{jobId}&{entityId}&{jobInstanceId}&{archiveId}&{logTimeMs}&{runType}',

        // CSH (help) keys are set dynamically in the method `updateStep` in
        // this file
        title: 'Recover Databases: Options',
        canAccess: restoreModifyAccess,
        parentState: 'recover-db',
        params: {
          archiveId: { type: 'string' },
          entityId: { type: 'string' },
          failover: false,
          jobRunStartTime: { type: 'any' },
          jobUid: { type: 'any' },
          sourceId: { type: 'any' },
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
          logTimeMs: { type: 'string' },
          restoreParams: null,
          resubmit: { type: 'any' },
          resubmitRecoveryObject: { type: 'any' },
          runType: { type: 'string' },
        },
        views: {
          'canvas@recover-db': {
            templateUrl: 'app/protection/recovery/db/db-options.html',
            controller: 'restoreDBOptionsController as $ctrl',
          }
        }
      })
      .state('clone-db', {
        url: '/devops/clone/db/{dbType}/',

        // CSH (help) keys are set dynamically in the method `updateStep` in
        // this file
        title: 'Clone Databases',
        canAccess: cloneModifyAccess,
        parentState: 'devops',
        redirectTo: 'clone-db.search',
        params: {
          dbType: { type: 'string' },
          flowType: flowTypes[1],
        },
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'restoreDBParentController as $ctrl',
          },
          'col-l@clone-db': {
            templateUrl: 'app/protection/recovery/db/db-parent.html',
          }
        }
      })
      .state('clone-db.search', {
        url: 'search',

        // CSH (help) keys are set dynamically in the method `updateStep` in
        // this file
        title: 'Clone Databases: Select Object',
        help: 'testdev_clone_database_search',
        canAccess: cloneModifyAccess,
        parentState: 'clone-db',
        views: {
          'canvas@clone-db': {
            templateUrl: 'app/protection/recovery/db/db-search.html',
            controller: 'restoreDBSearchController as $ctrl',
          }
        }
      })
      .state('clone-db.options', {
        url: 'options?{jobId}&{entityId}&{jobInstanceId}&{logTimeMs}&{archiveId}',

        // CSH (help) keys are set dynamically in the method `updateStep` in
        // this file
        title: 'Clone Databases: Options',
        canAccess: cloneModifyAccess,
        parentState: 'clone-db',
        params: {
          jobId: { type: 'string' },
          entityId: { type: 'string' },
          jobInstanceId: { type: 'string' },
          logTimeMs: { type: 'string' },
          archiveId: { type: 'string' },
        },
        views: {
          'canvas@clone-db': {
            templateUrl: 'app/protection/recovery/db/db-options.html',
            controller: 'restoreDBOptionsController as $ctrl',
          }
        }
      });
  }

  function restoreDBParentControllerFn($rootScope, $scope, $state, $timeout, _,
    SearchService, $q, DB_RECOVER_TYPE, DB_RECOVER_APP_TYPE, ClusterService,
    ViewBoxService, JobService, evalAJAX, SourceService, RestoreService, cModal,
    cMessage,RemoteClusterService, ENV_GROUPS, cUtils, FEATURE_FLAGS,
    StateManagementService, ENV_TYPE_CONVERSION, FILESIZES, PubSourceService) {

    var taskCartWatcher;
    var taskWatcher;

    /**
     * RestoreAppArg represents the argument to recover/clone an
     * application. Example of application includes a SQL DB.
     * @type       {RestoreAppArg}
     */
    $scope.defaultRestoreTask = {
      // @type {String}
      name: undefined,

      /** Captures pfile Parameter which is converted to a map and send to
       *  magneto.
       */
      pfileTextarea: undefined,

      // @type {string}  - Action applies to the app (even if recovering/cloning
      // only a VM). Will always be one of 'kRecoverApp', or 'kCloneApp',
      action: DB_RECOVER_APP_TYPE[flowType],

      /**
       * This message captures all the necessary arguments specified by
       * the user to restore an application.
       * @type       {RestoreAppParams}
       */
      restoreAppParams: {
        /**
         * The application environment.
         * @type       {Environment_Type}
         */
        type: 3,
        /**
         * Credentials proto for a single VM/app host. Required if the host
         * is remote (replication), otherwise it's optional. If provided,
         * these credentials will be used instead of those registered on the
         * VM.
         * @type       {Credentials}
         */
        credentials: {
          // username: undefined,
          // password: undefined
        },
        /**
         * The restore information about the application's owner.
         * @type       {AppOwnerRestoreInfo}
         */
        ownerRestoreInfo: {
          /**
           * The information about the owner object and its job details. This
           * should be set to an object such as a VM.
           *
           * For SQL application, this will point to the full/incremental
           * snapshot which anchors the SQL restore operation.
           * @type       {RestoreObject}
           */
          ownerObject: {
            /**
             * The universal id of the job from which to restore.
             * @type       {UniversalIdProto}
             */
            jobUid: undefined,
            // JobId is the job id from which to restore.
            // @type       {Integer}
            jobId: undefined,
            // JobInstanceId identifies a specific run to restore from. If this
            // is not specified, the latest run is used.
            // @type       {Integer}
            jobInstanceId: undefined,
            // StartTimeUsecs specifies the start time of the specific job run.
            // If 'job_instance_id' is set, this field must be set because the
            // Magneto API requires it (they index the job instances by start
            // time).
            // @type       {Integer}
            startTimeUsecs: undefined,
            // Entity specifies the entity to restore, and is required to be a
            // VM for restore single VM operations. If this is not specified,
            // this is a clone job, and all entities from the job will be
            // restored.
            // @type       {EntityProto}
            entity: undefined,
            // This field must be set if the object is to be restored/retrieved
            // from an archive.
            // @type       {ArchivalTarget}
            // archivalTarget: {
            //     // The id of the archival vault.
            //     // @type       {Integer}
            //     vaultId: undefined
            // }
          },
          /**
           * If this is set to true, then the owner object needs to be
           * restored. The restore options that follow only apply if this
           * field is set to true. If this field is not set, then the
           * application objects will be restored to the original owner from
           * where they were backed up.
           * @type       {Bool}
           */
          // performRestore: undefined,
          /**
           * The params to restore the owner object. Only required if
           * performRestore is true.
           * @type       {RestoreObjectParams}
           */
          ownerRestoreParams: {
            /**
             * The action to perform.
             *
             * RestoreType_Type Enum: ['kRecoverVMs', 'kCloneVMs']
             * @type {RestoreType_Type}
             */
            action: undefined,
            /**
             * Optional: EntityProto
             *
             * An optional registered parent source to which objects are to be
             * restored. If not specified, objects are restored back to the
             * original source that was managing the objects.
             * @type       {EntityProto}
             */
            restoreParentSource: undefined,
            /**
             * Optional: RenameObjectParamProto
             *
             * By default, objects are restored with their original name. This
             * field can be used to specify the transformation ( i.e
             * prefix/suffix) to be applied to the source object name to derive
             * the new name of the restored object.
             * @type       {RenameObjectParamProto}
             */
            renameRestoredObjectParam: {
              // // @type {String}
              // prefix: undefined,
              // // @type {String}
              // suffix: undefined
            },
            /**
             * The resource pool entity where the restored objects will be
             * attached. This field is mandatory for a kCloneVMs task.
             *
             * This field is optional for a kRecoverVMs task if the objects are
             * being restored to its original parent source. If not specified,
             * restored objects will be attached to its original resource pool.
             * This field is mandatory if objects are being restored to a
             * different parent source.
             * @type       {EntityProto}
             */
            resourcePoolEntity: undefined,
            /**
             * The network configuration to be applied to the restored object.
             *
             * Semantics for kCloneVMs task: By default, if objects are being
             * restored to their original location, then network will be
             * disabled. If objects are being restored to a different location
             * (i.e., either to different resource pool or to different parent
             * source), then network will be detached.
             *
             * Semantics for kRecoverVMs task: By default, if objects are being
             * restored to their original location, then original network
             * configuration will be preserved. If objects are being restored to
             * different location, (i.e., either to different resource pool or
             * to different parent source), then network will be detached.
             *
             * Below field can be used to override the default network
             * configuration of the restored objects.
             *
             * If user want to keep the original network setting for kRecoverVMs
             * task, then this proto should not be set.
             *
             * @type       {RestoredObjectNetworkConfigProto}
             */
            // restoredObjectsNetworkConfig: {
            //     networkEntity: undefined
            // },
            /**
             * The power state configuration to be applied to the restored
             * object.
             *
             * Semantics for kCloneVMs task: By default, objects are restored in
             * the powered off state.
             *
             * Semantics for kRecoverVMs task: By default, objects are restored
             * in the powered on state.
             *
             * This proto can be used to override the default behavior for
             * kCloneVMs or kRecoverVMs task.
             * @type       {PowerStateConfigProto}
             */
            powerStateConfig: {
              // powerOn: Bool
            },
            /**
             * A datastore entity where the object's files should be restored
             * to. This field is optional if object is being restored to its
             * original parent source. If not specified, the object's files will
             * be restored to their original datastore locations. This field is
             * mandatory if object is being restored to a different resource
             * pool or to a different parent source. Only needed for recover
             * and not clone.
             * @type {EntityProto}
             */
            // datastoreEntity: {},
            // @type {String}
            // viewName: undefined
          }
        },
        /**
         * The application level objects that needs to be restored. If this
         * object is specified without its 'app_entity', all the application
         * objects of the owner will be restored.
         * @type       {RestoreAppObjectVec}
         */
        restoreAppObjectVec: [
          {
            /**
             * The application entity to restore (for example, kSQL). If this is
             * not set, all the apps in the owning entity will be restored.
             * @type       {EntityProto}
             */
            appEntity: undefined,
            /**
             * The restore params for the RestoreAppObject.
             * @type       {RestoreAppObjectParams}
             */
            restoreParams: {
              /**
               * The SQL specific application object restore params. Only
               * applicable if the RestoreAppObject.app_entity is of type kSQL.
               * @type       {RestoreSqlAppObjectParams}
               */
              sqlRestoreParams: {
                /**
                 * The time to which the SQL database needs to be restored. This
                 * allows for granular recovery of SQL databases. If this is not
                 * set, the SQL database will be recovered to the
                 * full/incremental snapshot (specified in the owner's restore
                 * object in AppOwnerRestoreInfo).
                 * @type       {Integer}
                 */
                restoreTimeSecs: undefined,

                /**
                 * Set to true if tail logs are to be captured before the
                 * restore operation. This is only applicable if we are
                 * restoring the SQL database to its original source.
                 * @type       {Bool}
                 */
                captureTailLogs: true,

                /**
                 * The name of the SQL instance that we restore database to. If
                 * target_host is not empty, this also cannot be empty.
                 * @type       {String}
                */
                // instanceName: undefined,

                /**
                 * Which directory to put the database data files. Missing
                 * directory will be automatically created. Cannot be empty
                 * if not restoring to the original SQL instance.
                 * @type       {String}
                */
                // dataFileDestination: undefined,

                /**
                 * Which directory to put the database log files. Missing
                 * directory will be automatically created. Cannot be
                 * empty if not restoring to the original SQL instance.
                 * @type       {String}
                */
                // logFileDestination: undefined,

                /**
                 * Which directory to put the secondary data files of the
                 * database. Secondary data files are optional and are user
                 * defined. The recommended file name extension for these is
                 * ".ndf".
                 *
                 * If this option is specified, the directory will be
                 * automatically created if its missing.
                 * @type       {String}
                 */
                // secondaryDataFileDestination: undefined,

                /*
                 * Set to true if we want to recover the database in
                 * "NO_RECOVERY" mode which does not bring it online after
                 * restore.
                 *
                 * Note: This is only applicable if we are restoring the
                 * database back to its original location.
                 */
                // withNoRecovery: undefined,

                /**
                 * The new name of the database, if it is going to be renamed.
                 * app_entity in RestoreAppObject has to be non-empty for the
                 * renaming, otherwise it does not make sense to rename all
                 * databases in the owner.
                 * @type       {String}
                */
                // newDatabaseName: ''
              },
              oracleRestoreParams: {},
            },
          },
        ],
      },
    };

    /**
     * SCOPE
     ******************************************************************/
    angular.merge($scope, {
      // GENERAL SCOPE VARS
      // This gets updated in the init function
      flowType: flowType,
      isDbMigration: !!$state.params.dbMigration,

      /**
       * Ordered list of state names in this recovery flow
       *
       * @type {Array}
       */
      stateNames: getFlowStateNames(),

      /**
       * Hash of known Clusters' info for lookup
       *
       * @type {Object}
       */
      clusters: {},

      /**
       * Object for sharing within and across descendant $scopes
       *
       * @type {Object}
       */
      shared: {
        isAbbreviatedFlow: false,
        isFailover: false,
        isOlderAgent : false,
        isPIT : false,

        // NOTE: This is a single object for reference by all
        // restoreAppObjectVec[] objects since all objects there share this
        // value (was converted from a single object to a Vec in 4.0).
        restoreAppObject: {
          restoreParams: {
            sqlRestoreParams: {
              captureTailLogs: true,
              dataFileDestination: undefined,
              instanceName: undefined,
              logFileDestination: undefined,
              secondaryDataFileDestination: undefined,
              newDatabaseName: undefined,
              restoreTimeSecs: undefined,
            },
            oracleRestoreParams: {
              alternateLocationParams: {
                dataFileDestination: undefined,
                newDatabaseName: undefined,
                homeDir: undefined,
                baseDir: undefined,
                newSid: undefined,
                nodeIpVec: undefined,
              },
              instanceName: undefined,

              // TODO (Tauseef): Uncomment this when we have PIT for Oracle.
              // restoreTimeSecs: undefined,
            },
            targetHost: undefined,
            targetHostParentSource: undefined,
          },
        },
        dbCredentials: null,
        filterLookups: {},
        selectedVlanTarget: undefined,
        showDbs: {},
        task: {},
        taskCart: [],
      },

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoverySql_recoverSqlText,

      // SCOPE METHODS
      addToCart: addToCart,
      cancel: cancel,
      fetchDependencies: fetchDependencies,
      fetchSingleEntity: fetchSingleEntity,
      filterCloneOrRecover: filterCloneOrRecover,
      generateDefaultTaskName: generateDefaultTaskName,
      getSetWithNoRecovery: getSetWithNoRecovery,
      getOracleHost: getOracleHost,
      getSqlHost: getSqlHost,
      getViewBox: getViewBox,
      hasLocationOptions: hasLocationOptions,
      hasNetworkOptions: hasNetworkOptions,
      hasRenameOptions: hasRenameOptions,
      isCartEmpty: isCartEmpty,
      isRemoteObject: isRemoteObject,
      removeFromCart: removeFromCart,
      setParametersBasedOnDbType: setParametersBasedOnDbType,
      showAagDetails: showAagDetails,
      showRestorePoints: showRestorePoints,
      startFlowOver: startFlowOver,
      submitTask: submitTask,
      taskHasOptions: taskHasOptions,
      updateStep: updateStep,
      withNoRecoveryChanged: withNoRecoveryChanged,
    });


    /**
     * WATCHERS
     ******************************************************************/

    // Watch for additions to the taskCart. Should only ever have 0 or 1 items
    // at this time.
    taskCartWatcher = $scope.$watch('shared.taskCart',
      function taskCartWatcherFn(cart) {
      /**
       * There are three properties in a SQL restore task request arg with a
       * complicated relationship based on the type of object(s) we're
       * restoring, and whether this is a Recover or Clone task flow:
       * task.action, ownerRestoreParams.action and
       * ownerRestoreInfo.performRestore. That relationship is clearly outlined
       * at
       * https://cohesity.atlassian.net/browse/ENG-19069?focusedCommentId=116148&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-116148
       */
      var task = $scope.shared.task;
      var params = task.restoreAppParams;
      var snapshot;
      var entity;
      var appOwner;

      $scope.shared.areSystemDbsSelected = areSystemDbsSelected();
      $scope.shared.areTdeDbsSelected = areTdeDbsSelected();

      // The cart has one item, lets update the task and move to the next step.
      if (cart && cart.length) {
        entity = cart[0];

        // If the snapshot index is -1, default to the most recent snapshot
        // available, which will be at index 0.
        snapshot = entity._versions[Math.max(entity._snapshotIndex, 0)];

        // Make sure that the snapshot passed to the recovery point is correct
        entity._snapshot = snapshot;
        $scope.shared.restoreObjectType = entity._type;
        $scope.shared.restoreObject = entity;

        task.action = getTaskAction(entity);

        $scope.shared.numberOfAagMembers = getAagMembersTally();
        $scope.shared.hasAagMembers = !!$scope.shared.numberOfAagMembers;

        // Capture Tail Logs is disabled if any fo the following conditions are
        // true: The job is deleted,
        $scope.shared.isCaptureTailLogsDisabled = entity._isDeletedJob ||

          // The job is paused, or
          entity._isPausedJob ||

          /**
           * Because an AAG is a single DB/app reference which can exist on 2 or
           * more hosts, and because we require the user to manually break the
           * AAG association before performing a restore, Capture Tail Logs no
           * longer applies in this scenario. The Host to DB relationship is
           * gone until the DBA reassociates the AAG to the hosts.
           */
          $scope.shared.numberOfAagMembers === $scope.shared.taskCart.length;

        angular.merge(params, {
          type: $scope._isSQL ? ENV_TYPE_CONVERSION['kSQL'] :
            ENV_TYPE_CONVERSION['kOracle'],
          ownerRestoreInfo: {
            ownerObject: {
              jobInstanceId: snapshot.instanceId.jobInstanceId,
              startTimeUsecs: +snapshot.instanceId.jobStartTimeUsecs,
              jobUid: entity.vmDocument.objectId.jobUid,
              jobId: entity.vmDocument.objectId.jobId,

              // NOTE: Leaving this for reference when we do ultimately enable
              // recover from cloud here.
              // archivalTarget: {},
            },

            // NOTE: This bool is always true for any SQL VM restoration, and
            // always false for any SQL DB (app) restoration.
            performRestore: entity._isHost,
            ownerRestoreParams: {
              /**
               * NOTE: This setting is wonky: When the above `performResore` is
               * true, this action is reqiured. When the above is false, this
               * property is ignored, yet it must simply be defined. This is a
               * restriction imposed by Magneto and will not be changed because
               * it would require upgrading existing tasks.
               */
              action: DB_RECOVER_TYPE[flowType],
            },
          },
          restoreAppObjectVec: convertCartToTaskObjects(),
        });

        if (entity._isHost) {
          // VM specific updates to the task
          angular.merge(params, {
            ownerRestoreInfo: {
              ownerObject: {
                entity: entity.vmDocument.objectId.entity,
              },
              ownerRestoreParams: {
                powerStateConfig: {
                  powerOn: true,
                },
              },
            },
          });
        } else {
          // DB specific updates to the task
          appOwner = $scope.appOwner = $scope._isSQL ?
            getSqlHost(entity.vmDocument) : getOracleHost(entity.vmDocument);

          angular.merge(params, {
            ownerRestoreInfo: {
              ownerObject: {
                entity: appOwner,
              },
            },
            restoreAppObjectVec: convertCartToTaskObjects(),
          });
        }

        // Go to the next step
        $state.go($scope.stateNames[2]);

        // Close all the showDbs panels
        return $scope.shared.showDbs = {};
      }
    }, true);

    // Watch the task for changes. Do some things.
    taskWatcher = $scope.$watch('shared.task',
      function taskWatcherFn(task, prevTask) {
        // @type  {object}  - Cache for shortcut to sqlParams
        var sqlParams;
        var newFirstObject;

        // If the cart is not empty and params have been set
        if (!isCartEmpty() && $scope.shared.restoreAppObject.restoreParams) {
          sqlParams =
            $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams;

          // Update the task objects
          updateRestoreAppObjectVec();

          // Cache references to the first objects in each rev of the task.
          newFirstObject = task.restoreAppParams.restoreAppObjectVec[0];

          // Show additional options if this entity is NOT being renamed, and NOT
          // restoring to a new location.
          $scope.showAdditionalOptions =
            !hasRenameOptions() && !hasLocationOptions();

          if (newFirstObject.appEntity) {
            // Specifics for DBs

            $scope.appOwner = getSqlHost($scope.shared.taskCart[0].vmDocument);

            if ($scope.shared.isCaptureTailLogsDisabled ||
              $scope.shared.taskCart[0]._isMasterDatabase) {
              // This is a deleted or paused job item, or is a master SQL db.
              // Explicitly set capture tail logs false.
              sqlParams = {
                ...sqlParams,
                captureTailLogs: false
              };
            }
          } else {
            // Specifics for VMs

            // This setting doesn't apply to VMs
            sqlParams = {
              ...sqlParams,
              captureTailLogs: undefined
            };
          }
        }
      }, true);

    // Watch the singular restoreAppObject for changes and update the objects in
    // the task.
    $scope.$watch('shared.restoreAppObject', updateRestoreAppObjectVec, true);


    /**
     * METHODS
     ******************************************************************/

    /**
     * Initialize all the things!
     *
     * @method     $onInit
     */
    this.$onInit = function $onInit() {
      // Update the global flowType for this instance
      flowType = flowTypes.includes($state.params.flowType) ?
        $state.params.flowType : flowType;
      db = $state.params.dbType;
      isClone = flowType === 'clone';
      angular.merge($scope, {
        flowType: flowType,
        isClone: isClone,
        stateNames: getFlowStateNames(flowType),
      });
      setParametersBasedOnDbType(db);
      initRecoverTask(db);
      fetchDependencies();

      if ($scope.isDbMigration) {
        SearchService.cacheSqlJobs();
      }
    };

    /**
     * set the flags based on the database type
     *
     * @method   setParametersBasedOnDbType
     * @param    {string}   db  database type oracle or sql
     */
    function setParametersBasedOnDbType(db) {
      isSQL = db === 'sql';
      isOracle = db === 'oracle';
      angular.merge($scope, {
        db: db,
        _isSQL: isSQL,
        _isOracle: isOracle,
      });
      setupStepper(db);

      if (isSQL) {
        withNoRecoveryChanged();
      }
    }

    /**
     * Determines if any System DBs are selected.
     *
     * @method   areSystemDbsSelected
     * @return   {boolean}   True if System DBs are selected. False otherwise.
     */
    function areSystemDbsSelected() {
      // Since this only applies to MS SQL, we can return early if this is
      // another app workflow.
      return db !== 'sql' ?
        false : some($scope.shared.taskCart, '_isSystemDatabase');
    }

    /**
     * Determines if any TDE(Transparent Data Encryption) DBs are selected.
     *
     * @method   areTdeDbsSelected
     * @return   {boolean}   True if at least 1 TDE DBs are selected.
     */
    function areTdeDbsSelected() {
      return $scope.db !== 'oracle' ?
        false : some($scope.shared.taskCart, '_isTdeDatabase');
    }

    /**
     * Controller for the modal to show nodes in an AAG network.
     *
     * @method   aagModalDetailsController
     */
    /* @ngInject */
    function aagModalDetailsController($scope, evalAJAX, SourceService, row) {
      /**
       * Init this controller.
       *
       * @method   $onInit
       */
      this.$onInit = function $onInit() {
        if (row._aagDetails) { return; }

        SourceService
          .bindAagDetailsToNode(row.vmDocument.objectId)
          .then(function nodeUpdated(node) {
            $scope.node = node;
          },
          evalAJAX.errorMessage);
      };
    }

    /**
     * Shows the aag details modal.
     *
     * @method   showAagDetails
     * @param    {object}   result   The yoda search result row.
     * @return   {object}   Promise to resolve with true or false.
     */
    function showAagDetails(result) {
      var config = {
        controller: aagModalDetailsController,
        resolve: { row: function() { return result; } },
        templateUrl: 'app/global/c-source-tree/aag-network-details.html',
      };

      return cModal.standardModal(config, {
        titleKey: 'aagNetwork',
        closeButtonKey: false,
        okButtonKey: false,
      });
    }

    /**
     * Gets the number of selected objects in this task that are AAG members.
     *
     * @method   getAagMembersTally
     * @return   {number}   The number of AAG members in the cart
     */
    function getAagMembersTally() {
      return $scope.shared.taskCart.reduce(function eachItem(tally, item) {
        if (item._isAagMember) {
          tally++;
        }
        return tally;
      },
      0);
    }

    /**
     * Gets the task action.
     *
     * @method     getTaskAction
     * @param      {object}  entity  The selected search result.
     * @return     {string}  The task.action Enum (see SQL_RECOVER_APP_TYPE).
     */
    function getTaskAction(entity) {
      // Logic based on outline here:
      // https://cohesity.atlassian.net/browse/ENG-19069?focusedCommentId=116148&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-116148
      // where `app_action` refers to `task.action` in the UI.
      return $scope.isClone && !entity._isHost ?
        // Only when cloning DBs is this a clone-centric task action,
        DB_RECOVER_APP_TYPE['clone'] :

        // otherwise all other scenarios are recover-centric task actions.
        DB_RECOVER_APP_TYPE['recover'];
    }

    /**
     * GetterSetter for
     * $scope.shared.restoreAppObject.restoreParams
     * .sqlRestoreParams.withNoRecovery.
     *
     * NOTE: This getter/setter represents the inverse boolean value of the form
     * control displayed in the UI, also noted there. When the toggle is true,
     * this is false/undefined. And when the toggle is false, this is true.
     *
     * @method     getSetWithNoRecovery
     * @param      {bool|undefined}   [val]   The new value when used as setter.
     *                                        Note: This input val is the
     *                                        inverse of the UI checkbox value.
     * @return     {bool}             The currently set value of withNoRecovery.
     */
    function getSetWithNoRecovery(val) {
      /*
       This getter/setter represents 3 states of a binary form control, 2 of
       which are equivalent: undefined/false, and true, thus `undefined` is a
       discreet value we need to deal with. But for the purposes of the visible
       template, this always returns a boolean.

       Rationale: `undefined` is the default setting for `withNoRecovery`, which
       I understand to be equiv. to false. When the user doesn't interact with
       the toggle switch control, Magneto said don't pass the property at all
       and rely on the server default. Once the user interacts with the toggle,
       it will be a boolean. The only way to reset `withNoRecovery` is to toggle
       the "Restore to Original SQL Server Instance?" control to NO. That change
       clears the `withNoRecovery` property.
      */
      var sqlRestoreParams =
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams;

      // Run as a setter. We're only concerned with explicitly set values.
      if (val !== undefined) {
        sqlRestoreParams.withNoRecovery = !val;
      }

      // Return the current bool value
      return !sqlRestoreParams.withNoRecovery;
    }

    /**
     * Handles changes to the withNoRecovery model.
     */
    function withNoRecoveryChanged() {
      var withRecovery;

      // Escape if this is a Clone workflow, or the CDC flag is disabled.
      if (isClone || !FEATURE_FLAGS.sqlCdc) {
        return;
      }

      withRecovery = getSetWithNoRecovery()

      $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams
        // If withRecovery is true, show keepCdc and keep it off by default.
        // Otherwise hide the Keep CDC option. Only applies to SQL Recover.
        .keepCdc = withRecovery ? false : undefined;

      // Also, if withRecovery is true, show the UI setting for keepCdc
      $scope.isCdcAvailable = withRecovery;
    }

    /**
     * Reusable convenience function to update the task's objects vec.
     *
     * @method    updateRestoreAppObjectVec
     */
    function updateRestoreAppObjectVec() {
      // Don't modify restoreAppObjectVec during submission of either
      // clone or recover task, since if this restoreAppObjectVec gets updated
      // at the time of submission, some of additional params added as part of
      // clone/restore goes missing as part of request payload.
      if (!$scope.isSubmitting) {
        $scope.shared.task.restoreAppParams.restoreAppObjectVec =
          convertCartToTaskObjects();
      }
    }

    /**
     * Converts the cart + the common restoreAppObject to the 4.0 compatible
     * restoreAppObjectVec. In 4.0+, restoreAppObject was converted to
     * restoreAppObjectVec.
     *
     * @method    convertCartToTaskObjects
     * @returns   {array}   The 4.0+ compatible array of restoreAppObjects.
     */
    function convertCartToTaskObjects() {
      return $scope.shared.taskCart.map(function eachObject(object) {
        return {
          // Because `appEntity` only applies for DB restore, don't assign this
          // if the object is a host.
          appEntity: !object._isHost ?
            object.vmDocument.objectId.entity : undefined,
          restoreParams: $scope.shared.restoreAppObject.restoreParams,
        };
      });
    }

    /**
     * Determines if the given entity is a remote entity or not
     *
     * @method     isRemoteObject
     * @param      {object}   entity  The entity
     * @return     {boolean}  True if remote object, False otherwise.
     */
    function isRemoteObject(entity) {
      return (entity && entity.vmDocument) ?
        (ClusterService.clusterInfo.id !==
        entity.vmDocument.objectId.jobUid.clusterId) :
        false;
    }

    /**
     * Dynamic one-off filter depending on the type of flow we're in. Filter
     * out App Hosts (VMs) if cloning.
     *
     * @method     filterCloneOrRecover
     * @param      {object}   row     The db or vm entity
     * @param      {integer}  index   index in the list
     * @return     {boolean}  True if it matches the desired filter
     */
    function filterCloneOrRecover(row, index) {
      if ('recover' === flowType) {
        // if we're recovering, everything is shown
        return true;
      }

      // If we're cloning, we only need to see app hosts (VMs)
      return (1 === row.vmDocument.objectId.entity.type);
    }

    /**
     * Determine if the task has any configured options.
     *
     * @method     taskHasOptions
     * @return     {boolean}  True if any configured options are set, False
     *                        if not.
     */
    function taskHasOptions() {
      return $scope.hasLocationOptions() ||
          $scope.hasRenameOptions() ||
          $scope.hasNetworkOptions();
    }

    /**
     * Determine if the task has configured location options. Always true
     * for remote VMs.
     *
     * @method     hasLocationOptions
     * @return     {boolean}  True if options found
     */
    function hasLocationOptions() {
      var deepOption = $scope.shared.task.restoreAppParams.ownerRestoreInfo;
      return $scope.isRemoteObject($scope.shared.taskCart[0]) ||
          'clone' === flowType ||
          (deepOption && deepOption.ownerRestoreParams &&
           deepOption.ownerRestoreParams.restoreParentSource);
    }

    /**
     * Determine if task has configured rename options.
     *
     * @method     hasRenameOptions
     * @return     {boolean}  True if rename options found
     */
    function hasRenameOptions() {
      var deepOption = $scope.shared.task.restoreAppParams.ownerRestoreInfo;
      return (deepOption && deepOption.ownerRestoreParams &&
        deepOption.ownerRestoreParams.renameRestoredObjectParam &&
        Object.keys(deepOption.ownerRestoreParams.renameRestoredObjectParam).length
      );
    }

    /**
     * Determine if task has configured network port group config options.
     *
     * @method     hasNetworkOptions
     * @return     {boolean}  True if network configured
     */
    function hasNetworkOptions() {
      var deepOption =
        $scope.shared.task.restoreAppParams.ownerRestoreInfo.ownerRestoreParams;
      return ('clone' === flowType ||
          deepOption && deepOption.restoredObjectsNetworkConfig &&
          deepOption.restoredObjectsNetworkConfig.networkEntity);
    }

    /**
     * Removes the given Entity from the taskCart
     *
     * @method     removeFromCart
     * @param      {Entity}  row     Entity to remove
     *
     * TODO: Revisit this when enabling multi-item restore SQL flow.
     */
    function removeFromCart(row) {
      // Shortcut to empty the cart.
      $scope.shared.taskCart.length = 0;
    }

    /**
     * Fetch and display the SQL DBs for each version of this VM
     *
     * @method     showRestorePoints
     * @param      {Entity}  row     VM Entity (search result)
     */
    function showRestorePoints(row) {
      var modalConf = {
        templateUrl: 'app/protection/recovery/db/db-modal-snapshot-dbs.html',
        controller: 'dbSnapshotDBsModalController',
        size: 'lg',
        resolve: {
          sqlHost: row
        }
      };

      if (!row._ownerId || $scope.shared.showDbs[row._id]) {
        $scope.shared.showDbs[row._id] = false;
        return;
      }

      // Fetch versions with ownerId
      getDBHistory(row, 'sql')
        .then(undefined, evalAJAX.errorMessage)
        .finally(function getDbVersionsFinally() {
          // Pop modal with sqlHost dep
          cModal
            .showModal(modalConf)
            .then(function dbsListClosedFn() {
              $scope.shared.showDbs[row._id] = !!row._versions.length;
            });
        });
    }

    /**
     * Fetch the db-centric (application-centric) history of the given VM
     *
     * @method   getDBHistory
     * @param    {object}   entity   The entity we're interested in
     * @param    {string}   db       'sql' or 'oracle'
     * @return   {object}   Promise carrying the server's response
     */
    function getDBHistory(entity, db) {
      return SearchService.getDBVersions({
          ownerEntityId: entity._ownerId,
          jobIds: [entity.vmDocument.objectId.jobId]
        }, db)
        .then(function dbHistoryReceived(versions) {
          // Attach the versions to the entity
          entity._versions = versions;
          return versions;
        });
    }

    /**
     * Determine if the taskCart empty.
     *
     * @method     isCartEmpty
     * @return     {boolean}  True if empty, False if populated
     */
    function isCartEmpty() {
      return !$scope.shared || !$scope.shared.taskCart ||
          !$scope.shared.taskCart.length;
    }

    /**
     * Look up the app owner of this SQL entity
     *
     * @method   getSqlHost
     * @param    {Object}   vmDocument   from the entity proto
     * @return   {Object}   The SQL host entity
     */
    function getSqlHost(vmDocument) {
      var sqlEntity = vmDocument && vmDocument.objectId.entity.sqlEntity;
      var owner = {};

      if (!vmDocument || !sqlEntity) {
        return owner;
      }

      if ($scope.shared.filterLookups.entityIds) {
        $scope.shared.filterLookups.entityIds.some(function findHostFn(host) {
          if (sqlEntity.ownerId === host.id) {
            return owner = host;
          }
        });
      }

      owner.id = owner.id || sqlEntity.ownerId;

      if (!owner._typeEntity) {
        // Replicated jobs do not match a local host (from above findHostFn) and
        // therefore the owner object is still empty. Therefore, we need to stub
        // out the basic proto so we can add the server name.
        owner._typeEntity = {};

        // Search through the vmDocument.attributeMap for the 'owner_name'
        // property, whose value is the name of the Physical Server
        // which is being backed up by a protection on the tx cluster. This
        // attributeMap is a collection of values { xKey: 'keyname', xValue:
        // 'value' } commonly consumed by Yoda and Bridge who do not want the
        // full proto. If you find yourself working on this, talk to Abhijit if
        // you need more understanding of the attributeMap or replicated oracle.
        vmDocument.attributeMap.some(function findOwnerName(obj) {
          return owner._typeEntity.name =
            obj.xKey === 'owner_name' ? obj.xValue : '';
        });
      }

      return owner;
    }

    /**
     * Look up the app owner of this Oracle entity
     *
     * @method   getOracleHost
     * @param    {Object}   vmDocument   from the entity proto
     * @return   {Object}   The Oracle host entity
     */
    function getOracleHost(vmDocument) {
      var oracleEntity = vmDocument && vmDocument.objectId.entity.oracleEntity;
      var owner = {};

      if (!vmDocument || !oracleEntity) {
        return owner;
      }

      if ($scope.shared.filterLookups.entityIds) {
        $scope.shared.filterLookups.entityIds.some(function findHostFn(host) {
          if (oracleEntity.ownerId === host.id) {
            return owner = host;
          }
        });
      }

      owner.id = owner.id || vmDocument.objectId.entity.parentId;

      if (!owner._typeEntity) {
        // Replicated jobs do not match a local host (from above findHostFn) and
        // therefore the owner object is still empty. Therefore, we need to stub
        // out the basic proto so we can add the server name.
        owner._typeEntity = {};

        // Search through the vmDocument.attributeMap for the 'owner_name'
        // property, whose value is the name of the server (VM or Physical)
        // which is being backed up by a protection on the tx cluster. This
        // attributeMap is a collection of values { xKey: 'keyname', xValue:
        // 'value' } commonly consumed by Yoda and Bridge who do not want the
        // full proto. If you find yourself working on this, talk to Abhijit if
        // you need more understanding of the attributeMap or replicated sql.
        vmDocument.attributeMap.some(function findOwnerName(obj) {
          return owner._typeEntity.name =
            obj.xKey === 'owner_name' ? obj.xValue : '';
        });
      }

      return owner;
    }

    /**
     * Lookup a viewbox by id
     *
     * @method     getViewBox
     * @param      {integer}  id      The view box id
     * @return     {object}   The found view box entity
     */
    function getViewBox(id) {
      var out = {};
      if (id) {
        $scope.shared.filterLookups.viewBoxIds.some(function findViewboxFn(vb) {
          if (id === vb.id) {
            out = vb;
            return true;
          }
          return false;
        });
      }
      return out;
    }

    /**
     * Generate a default task name.
     *
     * @method     generateDefaultTaskName
     * @return     {string}  The updated default taskName
     */
    function generateDefaultTaskName(db) {
      var _flowType = flowType;

      if ($scope.isDbMigration) {
        _flowType = 'migrate';
      } else if ($scope.shared.isFailover) {
        _flowType = $scope.text.failover;
      }

      return RestoreService.getDefaultTaskName(
        _flowType,
        db,
        $scope.shared.taskCart[0]
      );
    }

    /**
     * Sets the state names depending on which flowType we're in: recover,
     * clone, or database migration (sql only).
     *
     * @method   getFlowStateNames
     * @param    {String}   [flowType='recover]   'recover' or 'clone'
     * @return   {Array}    The list of stateNames
     */
    function getFlowStateNames(flowType) {
      flowType = flowType || flowTypes[0];
      if ($scope.isDbMigration) {
        return [
          'migrate-db',
          'migrate-db.search',
          'migrate-db.options',
        ];
      }
      switch (flowType) {
        case 'clone':
          return [
            'clone',
            'clone-db.search',
            'clone-db.options',
          ];

        default:
          return [
            'recover',
            'recover-db.search',
            'recover-db.options',
          ];
      }
    }

    /**
     * Initializes recover task with defaults
     *
     * @method     initRecoverTask
     */
    function initRecoverTask(db) {
      $scope.shared.restoreAppObject.restoreParams
        .sqlRestoreParams.captureTailLogs = !isClone;
      $scope.shared.task = angular.merge({}, $scope.defaultRestoreTask, {
        name: generateDefaultTaskName(db)
      });
    }

    /**
     * Sets up the cStepper steps array
     *
     * @method   setupStepper
     * @param    {string}   db   sql or oracle
     */
    function setupStepper(db) {
      var isRecover = flowType === 'recover';
      $scope.taskSteps = [
        {
          help: {
            clone: 'testdev_clonenew_new_' + db + '_select',
            migrate: 'migrate_sql_select',
            recover: 'protection_recovery_' + db + '_search',
          },
          title: $scope.text.steps.selectEntities,
          active: $state.current.name === $scope.stateNames[1],
        },
        {
          help: {
            clone: 'testdev_clonenew_new_' + db + '_options',
            failover: 'protection_recovery_' + db + '_options',
            migrate: 'migrate_sql_options',
            recover: 'protection_recovery_' + db + '_options',
          },
          title: (isRecover) ? $scope.text.steps.taskOptionsRecover :
              $scope.text.steps.taskOptionsClone,
          active: $state.current.name === $scope.stateNames[2],
        },
      ];
    }

    /**
     * Updates the current cStepper step based on the current state.
     *
     * @method     updateStep
     */
    function updateStep() {
      var offset = $scope.stateNames.length - $scope.taskSteps.length;

      $scope.taskSteps.forEach(function activateStepFn(step, ii) {
        step.active = $state.current.name === $scope.stateNames[ii + offset];

        // Update the CSH keys based on this step and flow
        if (step.active) {
          switch (true) {
            case $scope.isFailover:
              $state.current.help = step.help.failover;
              break;

            case $scope.isDbMigration:
              $state.current.help = step.help.migrate;
              break;

            default:
              $state.current.help = step.help[flowType];
           }
        }
      });

      $scope.firstStep = $state.current.name === $scope.stateNames[1];
      $scope.lastStep = $state.current.name === last($scope.stateNames);
    }

    /**
     * Shared function to start the flow over and replaces the browser history
     *
     * @method     startFlowOver
     */
    function startFlowOver() {
      $state.go($scope.stateNames[1], undefined, {
        location: 'replace'
      });
    }
    /**
     * Adds the given entity to the task Cart. Currently enforces single
     * item restrictions
     *
     * @method     addToCart
     * @param      {Object}  entity  SQL Entity
     */
    function addToCart(entity) {
      if (entity._isHost && !entity._versions) {
        // This is a VM, if no history attached yet, go get it, then add it
        // to the cart
        getVmSqlHistory(entity)
          .then(function historyGotten(versions) {
            entity._versions = versions;
            $scope.shared.taskCart[0] = entity;
          });
      } else {
        // This is a db. Just add it to the cart
        $scope.shared.taskCart[0] = entity;
        $scope.shared.task.name = generateDefaultTaskName(db);
      }
    }

    /**
     * Cancels the flow and returns to the previous state
     *
     * @method cancel
     */
    function cancel() {
      StateManagementService.goToPreviousState($scope.stateNames[0]);
    }

    /**
     * Sets up the lookups skeleton needed for this flow
     *
     * @method     initFilterLookups
     */
    function initFilterLookups() {
      $scope.shared.filterLookups = $scope.shared.filterLookups || {
        entityIds: [],
        viewBoxIds: [],
        registeredSourceIds: [],
        jobIds: []
      };
    }

    /**
     * Given a jobId and sourceEntityId, get and return the full entity
     * requested
     *
     * @method     fetchSingleEntity
     * @param      {object}   opts              $stateParams with jobId and
     *                                          sourceEntityId
     * @param      {boolean}  fetchAllVersions  True if fetching all versions
     * @return     {promise}  Promise carrying the entity
     */
    function fetchSingleEntity(opts, fetchAllVersions) {
      var params = {
        // A specific entity
        entityIds: opts.entityId,

        // From a specific job
        jobIds: opts.jobId,
        onlyLatestVersion: !fetchAllVersions,
      };

      return SearchService.dbSearch(params, $scope.db)
        .then(function jobResultsReceivedFn(results) {
          return results.find(function findEntityFn(entity) {
            if (entity.vmDocument.objectId.entity.id === +opts.entityId) {
                // Return first result if no jobId param was defined. otherwise,
                // use strict jobId comparison.
                return !opts.jobId ||
                  +opts.jobId === entity.vmDocument.objectId.jobId;
            }
          });
        });
    }

    /**
     * Gets the environment types.
     *
     * @method   _getEnvTypes
     * @return   {Array}   The environment types.
     */
    function _getEnvTypes() {
      if (isClone) {
        return [1, 6];
      }

      return isSQL ? cUtils.onlyNumbers(ENV_GROUPS.sqlHosts) :
        cUtils.onlyNumbers(ENV_GROUPS.oracleHosts);
    }

    /**
     * Gets the environment types.
     *
     * @method   _getEnvironmentTypes
     * @return   {Array}   The environment types.
     */
    function _getEnvironmentTypes() {
      if (isClone) {
        return ['kPhysical', 'kVMware'];
      }

      return isSQL ? ['kVMware', 'kPhysical'] : ['kPhysical'];
    }

    /**
     * Gets the registered DB entities according to the recover or clone environment.
     *
     * @method   _getRegisteredDbEntities
     * @return   {Array}   The registered DB entities list.
     */
    function _getRegisteredDbEntities(){
      switch (true) {
        case isClone:
          return PubSourceService.getSourcesInfo({environments: ['kSQL', 'kOracle']});

        case isOracle:
          return PubSourceService.getSourcesInfo({environments: ['kOracle']}, 'kOracle');

        case isSQL:
          return PubSourceService.getSourcesInfo({environments: ['kSQL']}, 'kSQL');

        default:
          return $q.resolve([]);
      }
    }

    /**
     * Fetch dependencies from the server. Mostly for filter lookups.
     *
     * @method     fetchDependencies
     */
    function fetchDependencies() {
      var clusters = [];
      var jobEnvTypes = [];
      var promises = {

        // viewBoxes
        viewBoxes: ViewBoxService.getOwnViewBoxes(),

        // Registered DB entities
        registeredDbEntities: _getRegisteredDbEntities(),

        // All protected Servers
        // TODO: request only Servers with SQL instance
        servers: SourceService.getEntitiesOfType({
          environmentTypes: _getEnvironmentTypes(),
          isProtected: true,
          physicalEntityTypes: ['kHost', 'kWindowsCluster'],
          vmwareEntityTypes: (isSQL || isClone) ? ['kVirtualMachine'] : undefined,
        }),

        // Get remote clusters
        remoteClusters: RemoteClusterService.getRemoteClusters(),
      };

      switch (true) {
        case isSQL:
          // isSQL will be true during sql clone and recovery workflow.
          jobEnvTypes.push(ENV_TYPE_CONVERSION.kSQL);
          break;
        case isOracle:
          // isOracle will be true during recovery workflow.
          jobEnvTypes.push(ENV_TYPE_CONVERSION.kOracle);
          break;
        default:
          // In case of /clone/db/databases, both isSQL and isOracle will be
          // false. In this case, we need jobs for both sql and oracle if
          // oracleClone feature flag is on.
          jobEnvTypes.push(ENV_TYPE_CONVERSION.kSQL);
          if (FEATURE_FLAGS.oracleClone) {
            jobEnvTypes.push(ENV_TYPE_CONVERSION.kOracle);
          }
      }

      promises.jobs = JobService.getJobs({ envTypes: jobEnvTypes });

      return $q.all(promises)
        .then(function allFetched(responses) {
          initFilterLookups();

          // View Boxes
          if (Array.isArray(responses.viewBoxes)) {
            $scope.shared.filterLookups.viewBoxIds = responses.viewBoxes;
          }

          // Registered Protected DB Entities
          if (responses.registeredDbEntities &&

            // These are Registered DB entities
            Array.isArray(responses.registeredDbEntities.rootNodes) &&

            // These are protected servers including DB and physical sources.
            Array.isArray(responses.servers)) {

              // The intersection will filter unprotected sources and physical sources
              // and will result in protected DB entities.
              $scope.shared.filterLookups.entityIds = intersectionWith(
                responses.servers,
                responses.registeredDbEntities.rootNodes,
                function getProtectedEntities(server, entity) {
                  if (entity.stats && entity.stats.protectedCount >= 1) {
                    return server.id === entity.rootNode.id;
                  }
                });
          }

          // Jobs
          if (Array.isArray(responses.jobs)) {
            $scope.shared.filterLookups.jobIds = responses.jobs.map(
              function augmentJobsFn(job) {
                return angular.merge(job, {
                  jobName: job.name
                });
              }
            );
          }

          // Aggregate local and remote Clusters so we can also identify the
          // remote cluster for replicated jobs.
          clusters.push(ClusterService.clusterInfo);

          if (Array.isArray(responses.remoteClusters)) {
            clusters = clusters.concat(responses.remoteClusters.map(
              function augmentClustersFn(cluster) {
                return angular.merge(cluster, {
                  id: cluster.clusterId,
                });
              }
            ));
          }
          $scope.shared.filterLookups.clusters =
            $scope.clusters = ClusterService.updateClusterHash(clusters);
        });
    }

    /**
     * Submit the task for restoration
     *
     * @method     submitTask
     */
    function submitTask() {
      if ($scope._isOracle && $scope.shared.isPIT &&
        $scope.shared.isOlderAgent) {
        cMessage.error({
          textKey: "recovery.database.helpPointInTimeAgentDisabledText",
        });
        return;
      }

      var submitPromise;
      var isRecover = 'recover' === flowType;
      var serviceMethod = isRecover ? 'recoverApplication' : 'cloneApplication';
      var restoreAppParams = $scope.shared.task.restoreAppParams;
      var ownerRestoreInfo = restoreAppParams.ownerRestoreInfo;
      var ownerRestoreParams = ownerRestoreInfo.ownerRestoreParams;
      var sqlRestoreParams =
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams;
      var oracleRestoreParams =
        $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams;
      var selectedDatabase = $scope.shared.taskCart[0];

      $scope.isSubmitting = true;

      // If renameRestoredObjectParam is defined and has no keys, let's delete
      // the empty object so magneto doesn't throw an error.
      if (ownerRestoreParams &&
        ownerRestoreParams.renameRestoredObjectParam &&
        !Object.keys(ownerRestoreParams.renameRestoredObjectParam).length) {
        ownerRestoreParams.renameRestoredObjectParam = undefined;
      }

      // If it's a physical host (6) remove the targetHostParentSource param
      // from each restore object since it doesn't apply.
      if (ownerRestoreInfo.ownerObject.entity.type === 6) {
        restoreAppParams.restoreAppObjectVec.forEach(
          function eachRestoreObject(object) {
            object.restoreParams.targetHostParentSource = undefined;
          }
        );
      }

      // If this is a DB restore task, unset ownerRestoreParams because it's
      // unnecessary.
      if (ownerRestoreParams && !ownerRestoreParams.action) {
        ownerRestoreInfo.ownerRestoreParams = undefined;
      }

      // If the user has entered credentials, then deleted them, this will clear
      // the credentials object for us since angular leaves empty strings
      // behind.
      if (restoreAppParams.credentials &&
        !(restoreAppParams.credentials.username &&
        restoreAppParams.credentials.password)) {
        restoreAppParams.credentials = undefined;
      }

      // For SQL copy Attach jobs, clear the newDatabaseName property if the
      // switch was toggled OFF.
      if ($scope.shared.copyAttachOpts &&
        !$scope.shared.copyAttachOpts.showDatabaseRename && $scope._isSQL) {
        sqlRestoreParams.newDatabaseName = undefined;
      }

      $scope.shared.task.restoreVlanParams = RestoreService.getVlanParams(
        $scope.shared.selectedVlanTarget
      );

      // In case of expose view though we consider it as recovery,
      // App Type is kCloneAppView and Recover Type is kCloneVMs.
      if ($scope._isOracle && $scope.shared.copyAttachOpts &&
        $scope.shared.copyAttachOpts.exposeView) {
        $scope.shared.task.action = DB_RECOVER_APP_TYPE.exposeView;
        set($scope.shared.task,
          'restoreAppParams.ownerRestoreInfo.ownerRestoreParams.action',
          DB_RECOVER_TYPE.clone);

        // In case of expose view alternate Location params should be
        // flushed else magneto will throw an error.
        set($scope.shared.restoreAppObject,
          'restoreParams.oracleRestoreParams.alternateLocationParams',
          undefined);
      }

      // Remove irrelevant properties based on FEATURE_FLAGS
      if ($scope._isSQL) {
        if (FEATURE_FLAGS.multipleNdfFiles) {
          sqlRestoreParams.secondaryDataFileDestination = undefined;

          // Remove any copy instructions from the
          // secondaryDataFileDestinationVec that are empty (default seeds from
          // template).
          if (Array.isArray(sqlRestoreParams.secondaryDataFileDestinationVec)) {
            sqlRestoreParams.secondaryDataFileDestinationVec =
              sqlRestoreParams.secondaryDataFileDestinationVec.reduce(
                function vecReducer(accumulator, instruction, ii, origList) {
                  if (instruction.filePattern) {
                    accumulator.push(instruction);
                  }

                  // If, at the end of the loop the accumulator is empty, return
                  // undefined. Otherwise return the accumulator.
                  return instruction === origList.slice(-1) &&
                    !accumulator.length ? undefined : accumulator;
                },
                []
              );
          }
        } else {
          sqlRestoreParams.secondaryDataFileDestinationVec = undefined;
        }

        ownerRestoreInfo.ownerObject.archivalTarget =
          get(selectedDatabase._archiveTarget, 'target.archivalTarget');

        // Parse and set pre/post script details.
        if ($scope.shared._preScript) {
          set(restoreAppParams,
            'restoreAppObjectVec[0].additionalParams.preScript.script',
            _convertPrePostScript($scope.shared._preScript));
        }

        if ($scope.shared._postScript) {
          set(restoreAppParams,
            'restoreAppObjectVec[0].additionalParams.postScript.script',
            _convertPrePostScript($scope.shared._postScript));
        }
      }

      if ($scope._isOracle) {
        ownerRestoreInfo.ownerObject.archivalTarget =
          get(selectedDatabase._archiveTarget, 'target.archivalTarget');

        // Parse and set the SGA value.
        var sga = $scope.shared.task._sgaTargetSize;

        if (sga > 0) {
          // SGA value, if set, should be in bytes (due to `c-bytes`). Convert
          // it to oracle configuration compatible string.
          sga = Math.floor(sga / (FILESIZES.gigabyte)) + 'G';

          set(restoreAppParams, 'restoreAppObjectVec[0]' +
            '.restoreParams.oracleRestoreParams.alternateLocationParams' +
            '.oracleDbConfig.sgaTargetSize', sga);
        }

        if ($scope.shared.pfileTextarea && FEATURE_FLAGS.oracleRestorePfileEnabled
          && !FEATURE_FLAGS.ngRecoverOraclePfileEnabled) {
          // To send a Map in protobuff, u need to create an array of keys and
          // values intead of directly sending a map.
          var pfileParameterMap = [];
          var lines = $scope.shared.pfileTextarea.split('\n');
          var i = 0;

          for (i; i < lines.length; i++) {
            var pfileParameter = lines[i].split('=');
            if (pfileParameter[0] && pfileParameter[1]) {
              pfileParameterMap.push({
                // Since parameter of pfile are case insensitive, to avoid
                // confusion at backend, we convert all keys to upper case.
                // Rejoining the pfile values after fetching the key value.
                key: pfileParameter[0].toUpperCase(),
                value: pfileParameter.slice(1).join('=')
              });
            }
          }

          set(restoreAppParams, 'restoreAppObjectVec[0]' +
            '.restoreParams.oracleRestoreParams.alternateLocationParams' +
            '.oracleDbConfig.pfileParameterMap', pfileParameterMap);
        }

        if ($scope.shared.pfileTextarea && FEATURE_FLAGS.ngRecoverOraclePfileEnabled) {
          set(restoreAppParams, 'restoreAppObjectVec[0]' +
            '.restoreParams.oracleRestoreParams.alternateLocationParams' +
            '.oracleDbConfig.pfileParameterMap', $scope.shared.pfileTextarea);
        }

        // Parse and set pre/post script details.
        if ($scope.shared._preScript) {
          set(restoreAppParams,
            'restoreAppObjectVec[0].additionalParams.preScript.script',
            _convertPrePostScript($scope.shared._preScript));
        }

        if ($scope.shared._postScript) {
          set(restoreAppParams,
            'restoreAppObjectVec[0].additionalParams.postScript.script',
            _convertPrePostScript($scope.shared._postScript));
        }
      }

      if (!$scope._isSQL) {
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams =
          undefined;
      }

      if (!$scope._isOracle) {
        $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams =
          undefined;
      }

      submitPromise = {
        updateDbCredentials: $scope._isOracle && $scope.shared.dbCredentials
          && !FEATURE_FLAGS.oracleDbCredentialsPerHostEnabled ?
          SourceService.updateAppOwner($scope.shared.dbCredentials) :
          $q.resolve(),
        pfileParams: getPfileParams(),
      };

      // Kick off the task
      return $q.all(submitPromise)
        .catch(evalAJAX.errorMessage)
        .finally(_restoreOrCloneDatabase.bind(this, serviceMethod));
    }

    /**
     *  Restore/Clone database(s)
     *
     * @method  _restoreOrCloneDatabase
     * @param   {string}   serviceMethod   service name
     * @return  {Object}   a promise to be resolved after creating
     *                     clone/restore task.
     */
    function _restoreOrCloneDatabase(serviceMethod) {
      return RestoreService[serviceMethod]($scope.shared.task)
      .then(
        function taskAcceptedFn(restoreTask) {
          if ($scope.isDbMigration) {
            _showMigrationTaskSuccessMessage();
          }

          // Show info msg if clone task is created without default pfile params.
          if (!get($scope.shared.task.restoreAppParams, 'restoreAppObjectVec[0]' +
            '.restoreParams.oracleRestoreParams.alternateLocationParams' +
            '.oracleDbConfig.pfileParameterMap') &&
            $scope._isOracle &&
            isClone &&
            !$scope.shared.restoreObject._isWindowsHost) {
            cMessage.success({
              textKey: 'oracle.cloneTaskSuccessfullyCreatedWithoutPfile'
            });
          }

          // Add an artificial delay to give time for the task to kick off
          // before redirecting to the detail page. This will (hopefully)
          // allow cPulse to be active on users arrival at the state.
          $timeout(function delayFn() {
            $state.go(_getTaskDetailStateName(restoreTask), {
              id: restoreTask.performRestoreTaskState.base.taskId,

              // Redirect user to ajs migration details page.
              force: $scope.isDbMigration
            });
            $scope.isSubmitting = false;
          }, 1000);
        },
        function taskRejectedFn(resp) {
          evalAJAX.errorMessage(resp);

          // not handling this in a finally so success function can add an
          // artificial delay.
          $scope.isSubmitting = false;
        });
    }

    /**
     * Returns default pfile params generated based on selected Oracle
     * db setup configuration
     *
     * @method  getPfileParams
     * @return  {Object}   a promise to be resolved with generated
     *                     pfile parameters.
     */
    function getPfileParams() {
      if (!$scope.shared.pfileTextarea &&
        FEATURE_FLAGS.ngRecoverOraclePfileEnabled &&
        $scope._isOracle &&
        isClone &&
        !$scope.shared.restoreObject._isWindowsHost) {
        var cloneObject = $scope.shared.restoreObject ||
          $scope.shared.taskCart[0];
        var snapshotParams = {
          runInstanceIds: [cloneObject._snapshot.instanceId.jobInstanceId],
        };
        var entityId = get(cloneObject, 'vmDocument.objectId.entity.id');

        return RestoreService.getObjectSnapshots(parseInt(entityId, 10), snapshotParams)
          .then(function getObjectSnapshotsResponse(resp) {
            return generatePfileConfig(get(resp, 'snapshots[0].id'));
          })
          .then(function getPfileMetadata(metaInfo) {

            // Populating default pfile metadata based on db setup configuration.
            var pfileConfig = [];

            Object.values(metaInfo.oracleParams).forEach(
              function populatePfileConfigFn(config) {
                if (config && config.length) {
                  pfileConfig = pfileConfig.concat(config);
                }
              }
            );

            set($scope.shared.task.restoreAppParams, 'restoreAppObjectVec[0]' +
              '.restoreParams.oracleRestoreParams.alternateLocationParams' +
              '.oracleDbConfig.pfileParameterMap', pfileConfig);
          });
      }

      return $q.resolve();
    }

    /**
     * Method called to generate pfile parameters
     *
     * @method  generatePfileConfig
     * @param   {integer}   snapshotId selected db object snapshot id
     * @return  {Object}    a promise to be resolved with generated pfile
     *                      information.
     */
    function generatePfileConfig(snapshotId) {
      var dbConfig = $scope.shared.restoreAppObject.restoreParams
        .oracleRestoreParams.alternateLocationParams;
      var metaInfoParams = {
        environment: 'kOracle',
        oracleParams: {
          baseDir: dbConfig.baseDir,
          dbName: dbConfig.newDatabaseName,
          homeDir: dbConfig.homeDir,
          isClone: true
        }
      };

      return RestoreService.getPfileMetadata(metaInfoParams, snapshotId);
    }

    /**
     * Constructs the appropriate state name for the detail view of this task,
     * targetType, and flowType.
     *
     * @method   _getTaskDetailStateName
     * @param    {object}   restoreTask   The created restore task
     * @return   {string}   The detail state name.
     */
    function _getTaskDetailStateName(restoreTask) {
      var targetType =
        restoreTask.performRestoreTaskState.retrieveArchiveTaskUidVec ?
          'archive' : 'local';

      var stateNamePieces = [$scope.flowType, 'detail'];
      var stateName;

      if ($scope.flowType === 'recover') {
        stateNamePieces.push(targetType);
      }

      // Outputs 'clone-detail', 'recover-detail-local', or
      // 'recover-detail-archive'
      stateName = stateNamePieces.join('-');

      // Target the child-state for DB Migration tasks.
      if ($scope.isDbMigration) {
        stateName += '.db-migration-details';
      }

      return stateName;
    }

    /**
     * Shows the success message for successfully submitted DB Migration tasks.
     *
     * @method   _showMigrationTaskSuccessMessage
     */
    function _showMigrationTaskSuccessMessage() {
      cMessage.success({
        titleKey: 'dbMigrationTaskCreated.title',
        titleKeyContext: {entity:
          $scope.shared.taskCart[0].vmDocument.objectId.entity},
        textKey: 'dbMigrationTaskCreated.text',
        persist: true,
      });
    }

    /**
     * Converts the UI pre/post script object to API compatible pre/post script
     * object.
     *
     * @method   _convertPrePostScript
     * @param    {Object}   prePostScript   The pre/post script object.
     * @return   {Object}   The API compatible pre/post script object.
     */
    function _convertPrePostScript(prePostScript) {
      var script = cloneDeep(prePostScript);

      if (script.timeoutMins) {
        script.timeoutSecs = script.timeoutMins * 60;
        delete script.timeoutMins;
      }

      return script;
    }
  }

})(angular);
