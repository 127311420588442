/**
 * Backint protocol version.
 */
export const backIntProtocolVersion = '1.0';

/**
 * Enum for delta type
 */
export enum DeltaType {
  incrementalBackups = 'incremental',
  differentialBackups = 'differential',
}

/**
 * Consolidated type for all the sap hana object types.
 */
export type SapHanaObjectType = 'Database' | 'Table' | 'Bucket';

/**
 * Sap hana object type to object icon map.
 */
export const SapHanaObjectTypeIconMap: {[k in SapHanaObjectType]?: string} = {
  'Database': 'helix:object-db',
  'Table': 'helix:object-table',
  'Bucket': 'helix:object-db',
};
