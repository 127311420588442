import { assign } from 'lodash-es';
// Module: Remote Cluster Connection

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      cluster: '=',
      inEditMode: '=?',
    },
    controller: 'ClusterConnectionCtrl',
    templateUrl: 'app/remote-clusters/modify/cluster-connection.html',
  };

  angular.module('C.remoteClusters')
    .controller('ClusterConnectionCtrl', clusterConnectionCtrlFn)
    .component('clusterConnection', configOptions);

  function clusterConnectionCtrlFn(_, cUtils, evalAJAX, RemoteClusterService) {
    var $ctrl = this;

    assign($ctrl, {
      // UI states
      state: {
        isValidating: false,
      },

      clusterConnectionForm: undefined,

      // component methods used in the view
      validateConnection: validateConnection,
      uiSelectIPValidator: cUtils.uiSelectIPValidator,
      changeConnectionCredentials: changeConnectionCredentials,
    });

    /**
     * On changing connection credentials cache the cluster config and update
     * the cluster config.
     *
     * @method   changeConnectionCredentials
     */
    function changeConnectionCredentials() {
      assign($ctrl.cluster._cache, {
        purposeRemoteAccess: $ctrl.cluster.purposeRemoteAccess,
        purposeReplication: $ctrl.cluster.purposeReplication,
      });
      assign($ctrl.cluster, {
        _validConnection: false,
        purposeRemoteAccess: false,
        purposeReplication: false,
      });
    }

    /**
     * Validates connection to remote cluster.
     *
     * @method   validateConnection
     */
    function validateConnection() {
      // early exit if form is invalid
      if ($ctrl.clusterConnectionForm.$invalid) {
        return;
      }

      $ctrl.state.isValidating = true;
      RemoteClusterService.validateConnection($ctrl.cluster).then(
        function validationSuccess() {
          assign(
            $ctrl.cluster,
            $ctrl.cluster._cache,
            { _validConnection: true }
          );
        },
        function validationFailed(errorResponse) {
          $ctrl.cluster._validConnection = false;
          evalAJAX.errorMessage(errorResponse);
        }
      ).finally(function onValidationEnd() {
        $ctrl.state.isValidating = false;
      });
    }
  }

})(angular);
