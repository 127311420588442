import { assign } from 'lodash-es';
// Component: cVlan

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.vlan:cVlan
   * @function
   *
   * @description
   * Reusable component provides an easy means of selecting a Vlan Target
   *
   * @example
   * <c-vlan selected-vlan-target="selectedVlanTarget"></c-vlan>
   *
   */
  var options = {
    bindings: {
      // @type   {object}   object that will contain the selected vlan target
      selectedVlanTarget: '=',

      // @type   {number}   optional attribute to set default vlan target
      defaultTargetId: '<?',

      // @type   {[string]="interfaceSettings"}   optional heading
      heading: '<?',
    },
    controller: 'CVlanControllerFn',
    templateUrl: 'app/global/c-vlan/c-vlan.html',
  };

  angular
    .module('C.vlanSelector', [])
    .controller('CVlanControllerFn', cVlanControllerFn)
    .component('cVlan', options);

  function cVlanControllerFn(VlanService, NETWORK) {
    var $ctrl = this;

    assign($ctrl, {
      vlans: [],

      $onInit: $onInit,
      onAutoSelectChange: onAutoSelectChange,
    });

    /**
     * Executed when component loads. Initializes variables to be used in
     * template.
     */
    function $onInit() {
      $ctrl.autoSelect = true;

      getVlanTargets();
    };

    /**
     * When using the toggle if the toggle is set to true empty the
     * selectedVlanTarget so nothing is passed to the API.
     *
     * If the toggle is set to True it means the cluster will automatically
     * set the VLAN.
     *
     * @method   onAutoSelectChange
     */
    function onAutoSelectChange() {
      if ($ctrl.autoSelect) {
        $ctrl.selectedVlanTarget = undefined;
      } else {
        $ctrl.selectedVlanTarget = $ctrl.vlans[0];
      }
    }

    /**
     * When the component loads make a call to getVlans
     *
     * @method   getVlanTargets
     */
    function getVlanTargets() {
      $ctrl.vlansLoaded = false;
      $ctrl.vlans.length = 0;

      VlanService.getVlans()
        .then(function gotVlans(vlans) {
          $ctrl.vlans = vlans;
          $ctrl.vlansLoaded = true;

          selectVlanTarget();
      });
    }

    /**
     * Sets selected vlan target based on default index if defined.
     *
     * @method   selectVlanTarget
     */
    function selectVlanTarget() {
      if (angular.isUndefined($ctrl.defaultTargetId)) {
        return;
      }

      var targetIndex = $ctrl.vlans.findIndex(function findTarget(target) {
        return target.id === $ctrl.defaultTargetId;
      });
      if (targetIndex > -1) {
        $ctrl.autoSelect = false;
        $ctrl.selectedVlanTarget = $ctrl.vlans[targetIndex];
      }
    }
  }
})(angular);
