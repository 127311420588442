import { assign } from 'lodash-es';
// COMPONENT: Modify / display VIP

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.vip:cVip
   * @function
   *
   * @description
   * Reusable component provides an easy means of create/update/display VIP
   *
   * @example
   * <c-vip ng-model="$ctrl.selectedVips"
   *  ng-change="Fn"></c-vip>
   *
   */

  var options = {
    templateUrl: 'app/global/c-vip/c-vip.html',
    controller: 'ModifyVipCtrl',
    bindings: {
      // @type {boolean} - mode of display, if the VIP is in view mode
      isViewMode: '<?',
      // @type {boolean} - If it's displayed in networking
      isNetworking: '<?',
      // @type {number } - ipv4(1)/ipv6(2) address family.
      ipFamily: '<?',
      // @type {boolean} - If Add button should be disabled.
      isDisabled: '<?'
    },
    require: {
      ngModel: 'ngModel',
    },
  };

  angular
    .module('C.vip', [])
    .component('cVip', options)
    .controller('ModifyVipCtrl', modifyVipFn);

  /**
   * Controller function
   *
   * @param   {object}  $scope    Controller scope
   * @param   {object}  cUtils    Utility functions
   * @param   {object}  cMessage  Message function to show error
   */
  function modifyVipFn($scope, cUtils, cMessage, NgIpUtilsService, FORMATS, IP_FAMILY) {
    var $ctrl = this;
    var defaultVipRange = {
      vipLow: undefined,
      vipCount: undefined
    };
    var collectSelectedVipsListener;

    assign($ctrl,{
      vipRange: angular.copy(defaultVipRange),
      selectedVips: [],
      FORMATS: FORMATS,
      IP_FAMILY: IP_FAMILY,

      $onInit: $onInit,
      addVIPRange: addVIPRange,
      removeVIP: removeVIP,
    });

    /**
     * Calls when component is initialized
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.ipFamily = $ctrl.ipFamily || IP_FAMILY.IPv4;
      collectSelectedVipsListener = $scope.$on('selectedVipsUpdate', addVIPRange);
    }

    /**
     * Add VIP range or a single VIP to the vlan if range validates.
     *
     * @method     addVIPRange
     */
    function addVIPRange() {

      if ($scope.frmVip.$invalid) {
        return;
      }

      var vipRangeList = [];
      var duplicateIPs = [];
      var found = false;
      var newRange;

      // If it's networking, the selected vips are imported from outside.
      if ($ctrl.isNetworking) {
        $ctrl.selectedVips = $ctrl.ngModel.$viewValue;
      }

      if ($ctrl.vipRange.vipCount) {
        newRange = NgIpUtilsService.generateIps($ctrl.vipRange.vipLow, $ctrl.vipRange.vipCount);

        for (var i = 0; i < newRange.length; i++) {
          found = false;

          loop2:
            for (var x = 0; x < $ctrl.selectedVips.length; x++) {
              if (newRange[i] === $ctrl.selectedVips[x]) {
                found = true;
                break loop2;
              }
            }

          if (found === false) {
            vipRangeList.push(newRange[i]);
          } else {
            duplicateIPs.push(newRange[i]);
          }
        }

        $ctrl.selectedVips = vipRangeList.concat($ctrl.selectedVips);
        _resetVipForm();
      } else if ($ctrl.vipRange.vipLow) {
        // single IP address
        for (var y = 0; y < $ctrl.selectedVips.length; y++) {
          if ($ctrl.selectedVips[y] === $ctrl.vipRange.vipLow) {
            duplicateIPs.push($ctrl.vipRange.vipLow);
            break;
          }
        }

        if (!duplicateIPs.length) {
          $ctrl.selectedVips.unshift($ctrl.vipRange.vipLow);
          _resetVipForm();
        }
      }

      if (duplicateIPs.length) {
        // duplicate IPs were found (and not added)
        // surface a message informing user.
        cMessage.error({
          textKey: 'partitionDetails.duplicateIPs',
          textKeyContext: {
            duplicateIPs: duplicateIPs,
          },
        });
      } else if ($ctrl.selectedVips.length !== 0) {
        $ctrl.ngModel.$setViewValue($ctrl.selectedVips);
        _resetVipForm();
      }
    }

    /**
     * Remove a VIP from the list of VIPs
     *
     * @method  removeVIP
     * @param   {number}  index  the place to be removed
     */
    function removeVIP(index) {
      $ctrl.selectedVips.splice(index, 1);
    }

    /**
     * Reset the Vip Form inputs as empty.
     *
     * @method  _resetVipForm
     */
    function _resetVipForm() {
      $ctrl.vipRange = angular.copy(defaultVipRange);
      $scope.frmVip.$setPristine(true);
    }
  }

})(angular);
