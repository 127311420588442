import { isNumber } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { isArray } from 'lodash-es';
import { set } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Remote Cluster Service
import { getRemoteAccessClusterId } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  /* Modal implementations that need proxy support (without altClusterSelector
   * context change, from global search for example) can leverage this service's
   * setModalClusterContext(id) and clearModalClusterContext() to lock the
   * cluster API interactions to a specific cluster.
   */
  var _modalContextClusterId;

  /* a list of api/url fragments that should not be proxied when using
   * _modalContextClusterId. This would include anything that might be
   * happenning on the page below the modal or in the header.
   */
  var _modalContextBlackList = [
    'sessionUser/notifications',
    'progressMonitoring',
  ];

  /* _forceClusterId is used to lock/force the UI into a single cluster mode in
   * which the nav cluster selector isn't shown to the user and all API calls
   * have the clusterId header value added. This mechanism was added to support
   * iframe-based UI version loading, but can be leveraged indepedently to
   * access a single cluster if desired.
   */
  var _forceClusterId;

  angular.module('C')
    .service('RemoteAccessClusterService', RemoteAccessClusterServiceFn)
    .service('RemoteClusterService', RemoteClusterServiceFunction)
    .service('RemoteClusterHttpRequestInterceptor', RemoteClusterInterceptorFn)
    .config(addRequestHeadersConfigFn);

  function addRequestHeadersConfigFn($httpProvider) {
    $httpProvider.interceptors.push('RemoteClusterHttpRequestInterceptor');
  }

  function RemoteClusterInterceptorFn($injector) {
    return {
      request: function addRequestHeaders(requestConfig) {
        var isModalContextBlacklisted;

        /* If the request includes the query param of noClusterIdHeader=true
         * then the addition of the clusterId to the request header will be
         * skipped. This allows a request to be certain it is getting a
         * 'local' response if needed.
         */
        if (!_forceClusterId &&
          requestConfig.params && requestConfig.params.noClusterIdHeader) {
          // clean up the flag and then exit early.
          requestConfig.params.noClusterIdHeader = undefined;
          return requestConfig;
        }

        /* If the clusterId is "locked"/forced, add its value to the request
         * header and stop further processing.
         */
        if (_forceClusterId) {
          requestConfig.headers = requestConfig.headers || {};
          requestConfig.headers.clusterId = String(_forceClusterId);
        }

        if (_modalContextClusterId === 0) {
          requestConfig.headers.clusterId = undefined;
        }

        /*
         * Handle modal scenario, in which a cluster has been specificed for
         * use within a modal.
         */
        if (_modalContextClusterId &&
          !requestConfig.headers.hasOwnProperty('clusterId') &&
          !requestConfig.url.includes('/mcm/') &&

          // /data-protect/search/objects is actually an MCM API but without
          // '/mcm/' in the URL.
          !requestConfig.url.includes('/data-protect/search/objects')) {
          isModalContextBlacklisted = _modalContextBlackList.some(
            function checkBlackList(blackListItem) {
              return requestConfig.url.includes(blackListItem);
            }
          );

          if (!isModalContextBlacklisted) {
            requestConfig.headers.clusterId = String(_modalContextClusterId);
          }
        }

        const ctx = $injector.get('NgIrisContextService')?.irisContext;
        const clusterInTransition = $injector.get('NgAppStateService')?.clusterInTransition;
        let remoteAccessClusterId = getRemoteAccessClusterId(ctx);

        // in MCM, if scope transition is underway,
        // re-evaluate clusterId header for the API calls during
        // the transition. This is to avoid dependence on selectedScope$ which is
        // watched at a lot of places and can induce undesired side effects.
        if (ctx?.basicClusterInfo?.mcmMode &&
          clusterInTransition?.clusterId &&
          clusterInTransition.clusterId !== ctx?.accessClusterId) {

            remoteAccessClusterId = clusterInTransition.clusterId;
        }

        /* If a remote access cluster is defined and it is not an mcm endpoint,
         * add its clusterId to the request header, otherwise leave the request untouched.
         *
         * Don't set a clusterId in header for mcm endpoint because backend service will check
         * cluster identifiers in query params instead to maintain backward compatibility for older clusters.
         * Don't set a clusterId if the request is explicitly setting one.
         * This mechanism can also be used to bypass the clusterId header by
         * providing an undefined clusterId value in the request header.
         */
        if (remoteAccessClusterId &&
          !requestConfig.headers.hasOwnProperty('clusterId') &&
          !requestConfig.url.includes('/mcm/') &&

          // /data-protect/search/objects is actually an MCM API but without
          // '/mcm/' in the URL.
          !requestConfig.url.includes('/data-protect/search/objects')) {
          requestConfig.headers = requestConfig.headers || {};
          requestConfig.headers.clusterId =
            String(remoteAccessClusterId);
        }

        return requestConfig;
      },
    };
  }

  function RemoteClusterServiceFunction(_, $rootScope, $http, $q,
    $state, $filter, cModal, API, ClusterService, RemoteClusterServiceFormatter,
    SlideModalService, UserScopeService, NgRemoteClusterService,
    NgStateManagementService, TenantService, NgPassthroughOptionsService,
    NgAppStateService, $injector) {

    return {
      broadcastLocalUpdates: broadcastLocalUpdates,
      changeClusterContext: changeClusterContext,
      clearModalClusterContext: clearModalClusterContext,
      deleteRemoteCluster: deleteRemoteCluster,
      generateEncryptionKey: generateEncryptionKey,
      getAllClusters: getAllClusters,
      getAllClustersHash: getAllClustersHash,
      getApiProxyHeader: getApiProxyHeader,
      getRemoteAccessClusters: getRemoteAccessClusters,
      getRemoteCluster: getRemoteCluster,
      getRemoteClusterHash: getRemoteClusterHash,
      getRemoteClusters: getRemoteClusters,
      isClusterLocked: isClusterLocked,
      lockClusterId: lockClusterId,
      newRemoteCluster: newRemoteCluster,
      registerRemoteSlider: registerRemoteSlider,
      resolveClusterDetails: resolveClusterDetails,
      setModalClusterContext: setModalClusterContext,
      updateRemoteCluster: updateRemoteCluster,
      validateConnection: validateConnection,
      validateVersion: validateVersion,
    };

    /**
     * Indicates if a provided cluster id is that of the accessed/local cluster.
     *
     * @param {number}    clusterId   The id to check against local cluster id.
     * @returns {boolean} True if the provided cluster id belongs to the local
     *                    cluster. False if not.
     */
    function _isLocalClusterId(clusterId) {
      const ctx = $injector.get('NgIrisContextService')?.irisContext;

      return (ctx.accessClusterId && clusterId === ctx.accessClusterId) ||
        (!ctx.accessClusterId && clusterId === ClusterService.clusterInfo.id);
    }

    /**
     *
     * @param  {number}   clusterId   Cluster id for which to get api proxy
     *                                headers.
     * @returns object    The header params for API proxy based on provided id.
     */
    function getApiProxyHeader(clusterId) {
      var headers = {};

      if (!_isLocalClusterId(clusterId)) {
        headers.clusterId = clusterId;
      }

      return headers;
    }

    /**
     * Sets a cluster context for use with modals.
     *
     * @param {number}   clusterId   Sets the provided clusterId as temporary
     *                               context for API proxying.
     */
    function setModalClusterContext(clusterId) {
      // If the provided id is the local cluster, set modal context to 0 so that
      // API proxy will not be attempted and API proxy will be skipped.
      if (_isLocalClusterId(clusterId)) {
        _modalContextClusterId = 0;
      } else {
        _modalContextClusterId = clusterId;
      }
    }

    /**
     * Clears the modal cluster context (if set).
     */
    function clearModalClusterContext() {
      _modalContextClusterId = undefined;
    }

    /**
     * Indicates if the cluster is "locked", i.e. the UI is locked to interact
     * with a parictularly cluster id only.
     */
    function isClusterLocked() {
      return isNumber(_forceClusterId);
    }

    /**
     * Saves a clusterId in a local var, effectively "locking" the UI into
     * interacting with the designated Cluster.
     *
     * @method lockClusterId
     * @param {number} clusterId   The clusterId to lock the UI to
     */
    function lockClusterId(clusterId) {
      if (!isNumber(+clusterId)) {
        return;
      }

      _forceClusterId = +clusterId;
    }

    /**
     * returns a promise to resolve a request for a list of remote clusters
     * @param      {object}  [params]   optional query params
     * @return     {object}  Promise to resolve request for remote clusters.
     *                       Resolves with array of remote clusters, rejects
     *                       with raw server response.
     */
    function getRemoteClusters(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.public('remoteClusters'),
        params: params,
      }).then(function getRemoteClustersSuccess(response) {
        var remoteClusters = returnMultipleRemoteResp(response);

        if(params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(remoteClusters);
        }

        return remoteClusters;
      });
    }

    /**
     * Resolve the clusterIdentifier in the provided list of items with its
     * details.
     *
     * @method   resolveClusterDetails
     * @param    {Object[]}  list   The list of items having clusterIdentifier
     * that need to resolved with cluster details.
     * @param    {String}    [pathToClusterIdentifier='clusterIdentifier']   The
     * path to reach clusterIdentifier inside a item in the list and by default
     * considered as 'clusterIdentifier'.
     * @return   {Object}    Promise resolved with list having detailed cluster
     * info if present else rejected with error.
     */
    function resolveClusterDetails(list, pathToClusterIdentifier) {
      pathToClusterIdentifier = pathToClusterIdentifier || 'clusterIdentifier';

      // construct path to reach cluster info or list.
      var parts = pathToClusterIdentifier.split('.');

      // removing last part which is the clusterIdentifier to get the target
      // location where we will be placing the resolved cluster info.
      parts.pop();
      var pathToInfo = parts.concat('_cluster').join('.');
      var pathToInfoList = parts.concat('_clusters').join('.');

      return getRemoteAccessClusters(true).then(
        function gotClusters(clusterList) {
          var clustersMap = keyBy(clusterList, 'clusterIdentifier');

          /**
           * Return cluster info for provided identifier from received
           * clustersMap.
           *
           * @method  _getInfo
           * @param   {String}   identifier   The identifier.
           * @return  {Object}   Return cluster info for the provided identifier
           */
          function _getInfo(identifier) {
            return clustersMap[identifier];
          }

          // loop over list and add clusterInfo.
          return list.map(function eachItem(item) {
            var clusterIdentifiers = get(item, pathToClusterIdentifier);

            // resolving specially for single or multiple case.
            if (isArray(clusterIdentifiers)) {
              set(
                item, pathToInfoList, clusterIdentifiers.map(_getInfo));
            } else {
              set(item, pathToInfo, _getInfo(clusterIdentifiers));
            }

            return item;
          });
      });
    }

    /**
     * returns a promise to resolve a request for a list of remote clusters
     * registered for remote access
     *
     * @method   getRemoteAccessClusters
     * @param    {Boolean}  [includeSelf=false]   If provided and true then
     *                                            include access cluster info.
     * @return   {object}   Promise to resolve request for remote clusters.
     *                      Resolves with array of remote clusters, rejects with
     *                      raw server response.
     */
    function getRemoteAccessClusters(includeSelf=false) {
      // TODO: replace this manual filtering with params based call to
      // getRemoteClusters when the filters are committed/propagated.
      // https://cohesity-review.appspot.com/252547003/
      return getRemoteClusters().then(
        function filterClusters(clusters) {
          clusters = clusters.filter(function onlyRemoteAccess(cluster) {
            return cluster.purposeRemoteAccess;
          });

          // including access cluster info.
          if (includeSelf) {
            clusters.push(cloneDeep(assign(
              {clusterId: $rootScope.clusterInfo.id},
              $rootScope.clusterInfo
            )));
          }

          // alpha sort the list
          return $filter('orderBy')(clusters, 'name');
        }
      );
    }

    /**
     * Updates the cluster currently being accessed via single-pane-of-glass
     * functionality (altClusterSelector).
     *
     * @method   changeClusterContext
     * @param    {object}    cluster          The cluster to switch to.
     * @param    {boolean}   suppressReload   Indicates if state reload should
     *                                        be suppressed.
     * @param    {object}   [toState=self]    Optionally if provide then goto
     *                                        provided state and reload the page
     *                                        else reload the current state.
     * @return   {object}    Promise to resolve change of cluster.
     */
    function changeClusterContext(cluster, suppressReload, toState) {
      // var prevRemoteAccessCluster = NgAppStateService.selectedScope;

      // update cluster in transition for use in the request intercept above.
      // This will ensure promises{} will resolve with the selected cluster's
      // information.
      NgAppStateService.clusterInTransition = cluster;

      // After promises resolve, reload the current state so that clusters info
      // is display.
      return UserScopeService.changeUserContext(
        // default cluster state with all clusters flag if preset in then
        // cluster to switch to.
        {
          _allClusters: !!cluster._allClusters,
          _cohesityService: !!cluster._cohesityService,
          _globalContext: !!cluster._globalContext,
          _nonCluster: cluster._nonCluster,
          _serviceType: cluster._serviceType,
          id: cluster.id,
        }
      )
      .toPromise()
      .catch(function onError(error) {
        // rejecting the promise just to keep promise chain working.
        return $q.reject(error);
      })
      .finally(() => {
        // reset the cluster in transition so that future calls take
        // selectedScope$ for evaluating clusterId header
        NgAppStateService.clusterInTransition = {};

        if (!suppressReload) {
          _safeReload(toState);
        }
      });
    }

    /**
     * Handles reload of the state when switching between cluster contexts. If
     * the state is a detail page its $state config should include a
     * `fallbackState` property that defines the appropriate fallback state.
     * This is necessary/preferred because the detail page will almost certainly
     * throw API errors since the particular item won't exist on the loading
     * cluster.
     *
     * @method   _safeReload
     * @param    {object}   [toState=self]    Optionally if provide then goto
     *                                        provided state and reload the page
     *                                        else reload the current state.
     */
    function _safeReload(toState) {
      var fallbackState = NgStateManagementService.getCurrentFallbackState();

      if (fallbackState) {
        if ($rootScope.basicClusterInfo.mcmMode) {
          $state.goHome({reload: true});
          return;
        }
        $state.go(fallbackState);
      } else {
        // toState will be provided only for nextgen coh-cluster-scope-selector
        // component which internally uses getSafeContextSwitchState utility to
        // determine the safe state.
        if (toState) {
          $state.go(toState.name, toState.params, {reload: true});
        } else {
          $state.reload();
        }
      }
    }

    /**
     * Loads Remote Clusters from API and hashes them based on clusterId
     *
     * @method   getRemoteClusters
     * @param    {boolean}  [getLocalCluster]   If true, get local clusters hash
     *                                          otherwise get hash for current
     *                                          cluster context.
     * @return   {object}   Promise to resolve request for remote clusters hash
     */
    function getRemoteClusterHash(getLocalCluster) {
      var params = {
        // This param will prevent clusterId header from being added.
        noClusterIdHeader: getLocalCluster,
      };

      return getRemoteClusters(params).then(
        function getRemoteClustersSuccess(remoteClusters) {
          return (remoteClusters || []).reduce(
            function hashRemotes(hash, remote) {
              hash[remote.clusterId] = remote;
              return hash;
            },
            {}
          );
        }
      );
    }

    /**
     * Gets the remote cluster hash along with local cluster.
     *
     * @method   getAllClustersHash
     * @param    {boolean}  [getLocalCluster]   If true, get local based hash
     *                                          otherwise get hash for current
     *                                          cluster context.
     * @return   {Object}   The Promise resolved with clusters hash or rejected
     *                      with error.
     */
    function getAllClustersHash(getLocalCluster) {
      var params = {
        // This param will prevent clusterId header from being added.
        noClusterIdHeader: getLocalCluster,
      };

      return getRemoteClusterHash(params).then(
        function gotRemoteClusterHash(clustersHash) {
          // add local cluster since api only returns remote clusters.
          clustersHash[$rootScope.clusterInfo.id] = $rootScope.clusterInfo;
          return clustersHash;
        }
      );
    }

    /**
     * returns a promise to resolve a request for a single remote cluster
     *
     * @param      {integer}  id         The identifier
     * @param      {object}   [params]   optional query params
     * @return     {object}   Promise to resolve request for remote clusters.
     *                        Resolves with array of remote clusters, rejects
     *                        with raw server response.
     */
    function getRemoteCluster(id, params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.public('remoteClusters', id),
      }).then(
        function getRemoteClustersSuccess(response) {
          var remoteCluster;

          if (response.data && response.data.length) {
            remoteCluster =
              RemoteClusterServiceFormatter.transformRemote(response.data[0]);
          }

          if (params._includeTenantInfo) {
            return TenantService.resolveSingleTenantDetails(remoteCluster);
          }

          return remoteCluster;
        }
      );
    }

    /**
     * returns a promise to resolve a request for an object hash of all clusters
     * with key of of clusterId/id
     *
     * @return     {object}  to resolve request for all clusters. Resolves with
     *                       object hash of all clusters, rejects with raw
     *                       server response.
     */
    function getAllClusters() {
      var deferred = $q.defer();
      var clusters = {};
      var promiseArray = [
        getRemoteClusters(),
        ClusterService.getClusterInfo(),
      ];

      $q.all(promiseArray).then(
        function promiseArraySuccess(response) {

          // Let's add each remote Cluster to our parent Clusters object
          // with a key based on clusterId for easy parsing later
          returnMultipleRemoteResp(response[0]).forEach(
            function parseResponse(clstr) {
              clusters[clstr.clusterId] = clstr;
            }
          );

          // Let's add the local Cluster to our parent Clusters object
          // with a key based on id for easy parsing later
          clusters[response[1].data.id] = response[1].data;

          // Resolve the promise
          deferred.resolve(clusters);
        },
        // Reject directly with error response
        deferred.reject
      );

      return deferred.promise;
    }

    /**
     * Submits a request to add a new remote cluster, returning a promise to
     * resolve request.
     *
     * @param      {object}  remoteCluster  The remote cluster to be added
     * @return     {object}  promise to resolve the API request
     */
    function newRemoteCluster(remoteCluster) {

      // Set the interface properties
      _setRemoteClusterInterface(remoteCluster);

      return $http({
        method: 'post',
        data: remoteCluster,
        url: API.public('remoteClusters'),
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(_returnSingularRemoteResp);
    }

    /**
     * validate connection with remote cluster
     *
     * @method   validateConnection
     * @param      {object}  remoteCluster  The remote cluster to be added
     * @return     {object}  promise to resolve the API request
     */
    function validateConnection(remoteCluster) {
      var payload = {
        validateOnly: true,
        clusterId: remoteCluster.clusterId,
        userName: remoteCluster.userName,
        password: remoteCluster.password,
        remoteIps: remoteCluster.remoteIps,
      };

      var actionFn =
        remoteCluster.clusterId ? updateRemoteCluster : newRemoteCluster;

      return actionFn(payload);
    }

    /**
     * validate the remote cluster version comparability for remote access
     * resolved with true if comparable else false or rejected with error if
     * not able to validate because of connection or invalid credentials.
     *
     * @method   validateVersion
     * @param    {Object|String}   cluster/clusterId   The new remote cluster or
     *                                                 remote cluster id.
     * @return   {Object}   returns the promise resolve to true if remote
     *                      cluster is comparability else false or rejected with
     *                      error if not able to connect to remote cluster.
     */
    function validateVersion(cluster) {
      return ClusterService.getRemoteClusterInfo(cluster)
        .then(isRemoteAccessAllowed);
    }

    /**
     * Perform Test for remote cluster version comparability Test.
     * allow remote access to cluster version > 6.x or master
     *
     * @method   isRemoteAccessAllowed
     * @param    {Object}    clusterInfo   The cluster Info
     * @return   {boolean}   True if remote access allowed, False otherwise.
     */
    function isRemoteAccessAllowed(clusterInfo) {
      return clusterInfo._clusterSoftwareVersion.versionObj.isMaster ||
        clusterInfo._clusterSoftwareVersion.versionObj.major >= 6;
    }

    /**
     * Submits a request to update an existing remote cluster, returning a
     * promise to resolve request.
     *
     * @param      {object}  remoteCluster  The remote cluster to be updated
     * @return     {object}  promise to resolve the API request
     */
    function updateRemoteCluster(remoteCluster) {

      // Set the interface properties
      _setRemoteClusterInterface(remoteCluster);

      return $http({
        method: 'put',
        data: remoteCluster,
        url: API.public('remoteClusters', remoteCluster.clusterId),
      }).then(_returnSingularRemoteResp);
    }

    /**
     * Added correct interface properties for the remote cluster request
     *
     * @method   _setRemoteClusterInterface
     * @param    {object}   cluster   The remote cluster to be updated
     */
    function _setRemoteClusterInterface(cluster) {
      var iface = cluster._selectedInterface;

      // If undefined, remove interface properties
      if (!iface) {
        cluster.networkInterfaceGroup = undefined;
        return;
      }

      cluster.networkInterfaceGroup = iface.ifaceGroup;
      cluster.networkInterface = iface.ifaceGroup;
    }

    /**
     * Submits a request to delete an existing remote cluster, returning a
     * promise to resolve request.
     *
     * @param      {object}  remoteCluster  The remote cluster to be deleted
     * @return     {object}  promise to resolve the API request
     */
    function deleteRemoteCluster(remoteCluster) {

      var modalConfig = {
        modalFade: true,
        backdrop: 'static',
        controller: DeleteRemoteModalCtrlFn,
      };

      var windowOptions = {
        titleKey: 'replication.deleteConnectionTitle',
        contentKey: 'replication.deleteConnectionConfirmText',
        contentKeyContext: {
          local: ClusterService.clusterInfo.name,
          remote: remoteCluster.name,
        },
      };

      DeleteRemoteModalCtrlFn.$inject = [
        '$scope', 'cMessage', 'evalAJAX', '$uibModalInstance'];
      function DeleteRemoteModalCtrlFn(
          $scope, cMessage, evalAJAX, $uibModalInstance) {

        $scope.ok = function ok() {

          $scope.submitting = true;

          $http({
            method: 'delete',
            data: remoteCluster,
            url: API.public('remoteClusters', remoteCluster.clusterId),
          }).then(
            function deleteRemoteSuccess(resp) {
              cMessage.success({
                textKey: 'replication.deleteConnectionSuccess',
              });

              if (remoteCluster.purposeRemoteAccess) {
                broadcastLocalUpdates();
              }

              $uibModalInstance.close(resp);
            },
            evalAJAX.errorMessage
          ).finally(
            function deleteRemoteFinally() {
              $scope.submitting = false;
            }
          );

        };

        $scope.cancel = function cancel() {
          $uibModalInstance.dismiss('cancel');
        };
      }

      return cModal.standardModal(modalConfig, windowOptions);
    }

    /**
     * launches a modal version of create or edit an remote connection.
     *
     * @param    {Object}   [cluster]      The cluster to edit
     * @param    {string}   [customClass]  The custom class name.
     * @return   {object}   promise resolved with created or updated remote
     *                        connection.
     */
    function registerRemoteSlider(cluster, customClass) {
      let openedClassName = 'c-slide-modal-open';
      if (customClass) {
        openedClassName += ' ' + customClass;
      }
      const modalConfig = {
        component: 'remoteClustersModify',
        size: 'xl',
        openedClass: openedClassName,
        resolve: {
          cluster: function getCluster() {
            return cluster;
          },
          clusterId: function getClusterId() {
            return cluster && cluster.clusterId;
          },
        },
      };

      return SlideModalService.newModal(modalConfig).catch($q.reject);
    }

    /**
     * Submits a request to generate a new replication key, returning a promise
     * to resolve the request
     *
     * @return     {object}  promise to resolve request. Resolves with new
     *                       encryption key. Rejects with raw server response.
     */
    function generateEncryptionKey() {
      return $http({
        method: 'get',
        url: API.public('replicationEncryptionKey'),
      }).then(
        function getRemoteClustersSuccess(response) {
          return response.data.encryptionKey;
        }
      );
    }

    /**
     * handles successful server response for an API call that returns a single
     * remote cluster.
     *
     * @method _returnSingularRemoteResp
     * @param    {object}   resp   The server response object
     * @return   {object}   the remote cluster, or empty object
     */
    function _returnSingularRemoteResp(resp) {
      var cluster = RemoteClusterServiceFormatter.transformRemote(resp.data);

      broadcastLocalUpdates();

      return cluster;
    }

    /**
     * handles successful server response for an API call that returns a single
     * remote cluster.
     *
     * @param      {object}  resp    The server response object
     * @return     {object}  the remote cluster, or empty object
     */
    function returnMultipleRemoteResp(resp) {
      return (resp.data || [])
        .map(RemoteClusterServiceFormatter.transformRemote);
    }

    /**
     * Determines if the user is interacting with the locally accessed cluster
     * and if so, broadcasts a message that remote clusters have been updated.
     *
     * @method   broadcastLocalUpdates
     * @param  {boolean}  [forceBroadcast=false]  Optionally if present and true
     * then force update the list else allow update only for access cluster only
     */
    function broadcastLocalUpdates(forceBroadcast = false) {
      const ctx = $injector.get('NgIrisContextService')?.irisContext;

      // Informing angular to update the remote clusters list.
      NgRemoteClusterService.updateRemoteClusterList(forceBroadcast);

      // If user is not currently interacting with the local cluster do nothing.
      if (!forceBroadcast && ctx.accessClusterId &&
        ctx.accessClusterId !== ClusterService.clusterInfo.id) {
        return;
      }

      $rootScope.$broadcast('localRemoteClustersUpdate');
    }
  }

  /**
   * RemoteAccessClusterService is used to get selected remote cluster and there
   * related operations.
   * NOTE:- move all get selected remote cluster related methods in here so
   * that we can access this service inside $http interceptor w/o running into
   * cyclic dependency issues for $http
   *
   * @class    RemoteAccessClusterServiceFn (RemoteAccessClusterService)
   * @param    {Object}   API   The api service
   * @return   {Object}   The Remote cluster service.
   */
  function RemoteAccessClusterServiceFn(API, $injector) {
    return {
      addClusterIdParam: addClusterIdParam,
      decorateApiPathForRemote: decorateApiPathForRemote,
    };

    /**
     * Conditionally adds "cluster/[remoteAccessClusterId]/" to an api url and
     * returns updated url string. NOTE Because header clusterId value can't be
     * injected when using $window.open, etc, backend provides special URL
     * handling which allows the clusterId to be specified via the URL
     * construction.
     * @example
     *  Downloading a file via recovery gets opened in a new window. For local
     *  cluster the standard API url will work fine:
     *  /irisservices/api/v1/downloadfiles?etc=1&etc2=2
     *  When using SPOG/altClusterSelector to switch cluster context, this
     *  request needs a clusterId added via the URL (normally added via header):
     *  /irisservices/api/v1/cluster/11111111/downloadfiles?etc=1&etc2=2
     *
     *  For a public API url, the cluster/id/ needs to come before public/
     *  /irisservices/api/v1/public/actionPath
     *  For a remote cluster, this Would become:
     *  /irisservices/api/v1/cluster/11111111/public/actionPath
     *
     * NOTE:- This is retired for report csv download and is replaced by
     * addClusterIdParam below which uses URL parameter instead of modify URL.
     *
     * @method   decorateApiPathForRemote
     * @param    {string}   path   The API path to conditionally modify
     * @return   {Array}    The (possibly) updated API path.
     */
    function decorateApiPathForRemote(path) {
      const ctx = $injector.get('NgIrisContextService')?.irisContext;
      const remoteAccessClusterId = getRemoteAccessClusterId(ctx);

      if (!remoteAccessClusterId) {
        return path;
      }

      const remoteClusterPathSegment = '/cluster/' + remoteAccessClusterId;

      return path.splice(API.root.length, 0, remoteClusterPathSegment);
    }

    /**
     * Add clusterId param when access via Helios
     *
     * @method  addClusterIdParam
     * @param   {object} params  Original parameters object.
     * @returns {object} Updated parameters object.
     */
    function addClusterIdParam(params) {
      const ctx = $injector.get('NgIrisContextService')?.irisContext;
      const remoteAccessClusterId = getRemoteAccessClusterId(ctx);

      if (remoteAccessClusterId) {
        params.clusterId = remoteAccessClusterId;
      }
      return params;
    }
  }

}(angular));
