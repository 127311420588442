import { noop } from 'lodash-es';
import { get } from 'lodash-es';
// Component: Select or Add View Box

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectViewBox';
  var configOptions = {
    controller: 'SelectViewBoxCtrl',
    templateUrl: 'app/global/select-view-box/select-view-box.html',

    // Attribute bindings
    bindings: {
      /**
       * if specified then select the default View Boxes if found in the list.
       *
       * @type   {number}
       */
      defaultIds: '<?',

      /**
       * if specified with tenants. Optional.
       *
       * @type   {number}
       */
      tenantIds: '<?',

      /**
       * Optional function for filtering out the view boxes.
       *
       * @type   {function}
       */
      filter: '<?',

      /**
       * Optional attribute sniffed value, if attribute is present the uiSelect
       * component will open automatically on initialization if the model is
       * empty.
       */
      // autoOpenIfEmpty: '?'

      /**
       * Optional attribute if present the uiSelect will allow multiple
       * selection else by default single selection.
       */
      // multiple: '?'

      /**
       * Optional provide a function used to determine if selected viewbox
       * can be removed or not.
       * Return true if you don't want selected viewbox to be removed.
       *
       * to be removed.
       */
      isSelectedViewboxLocked: '&?',

      /**
       * Optional attribute sniffed value, if attribute is present then list all
       * viewboxes owned by the logged in user organization.
       */
      // only-own: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       * select all viewBoxes options and would work only with multiple
       * selection mode.
       */
      // showSelectAllOption: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then dont
       * show the add new viewbox option.
       */
      // addNewDisabled: '?'

      /**
       * Optional and used to control the ui-select remove selection default
       * behavior selected choices removal behavior.
       */
      removeSelected: '<?',

      /**
       * Optional string to be used as placeholder for the dropdown
       *
       * type  {String}
       */
      placeholder: '<?'
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  };

  angular.module('C.selectViewBox', ['ui.select', 'C.viewBoxMeta'])
    .controller('SelectViewBoxCtrl', selectViewBoxCtrlFn)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectViewBox:selectViewBox
   * @scope
   * @link
   *
   * @requires ngModel
   * @methodOf angular.Module|AUTO.
   * @function
   * @description
   *   Provides a dropdown component for selecting or adding a new View Box.
   *
   * @example
       <select-view-box ng-model="$ctrl.selectedViewBox"></select-view-box>
   */
  function selectViewBoxCtrlFn($attrs, ViewBoxService, evalAJAX, NgClusterService, ngDialogService, featureFlagsService,
    $state, _) {
    var $ctrl = this;

    angular.extend($ctrl, {
      // binding $attrs for disabled state pass through
      $attrs: $attrs,
      multiple: $attrs.hasOwnProperty('multiple'),
      onlyOwn: $attrs.hasOwnProperty('onlyOwn'),
      viewBoxes: [],
      $onInit: $onInit,
      selectAll: selectAll,
      onViewBoxSelection: onViewBoxSelection,
    });

    /**
     * Initialize this component.
     *
     * @method     init
     */
    function $onInit() {
      angular.extend($ctrl, {
        id: [($attrs.id || componentName), 'ui-select'].join('-'),
        name: [componentName, $attrs.name, Date.now()].join('-'),
        showSelectAllOption: $attrs.hasOwnProperty('showSelectAllOption'),
        addNewViewBox:
          $attrs.hasOwnProperty('addNewDisabled') ? undefined : addNewViewBox,
      });

      // by default remove selected choices for multi selection mode unit it
      // is overridden by providing removeSelected.
      if ($ctrl.multiple && !$ctrl.$attrs.hasOwnProperty('removeSelected')) {
        $ctrl.removeSelected = true;
      }

      getViewBoxes();
    }

    /**
     * Set the model with selected viewboxes.
     *
     * @method   onViewBoxSelection
     */
    function onViewBoxSelection() {
      $ctrl.ngModel.$setViewValue($ctrl.selectedViewBox);
    }

    /**
     * Launch add new viewbox. and select newly created viewboxes on successful
     * creation.
     *
     * @method   addNewViewBox
     */
    function addNewViewBox() {
      if (featureFlagsService.enabled('ngStorageDomainsModify')) {
        return ngDialogService.showDialog('ng-modify-storage-domain-dialog', {
          tenantIds: $ctrl.tenantIds
        }, {
          panelClass: 'modify-storage-domain-container',
          minWidth: '100vw',
          height: '100vh'
        })
          .toPromise()
          .then(function modalResolved(newViewBox) {
            $ctrl.viewBoxes.unshift(newViewBox);
            setSelection(newViewBox);
          }, noop)
          .finally(function modalCloseFinally() {
            $ctrl.loading = false;
          });
      }

      if (cloudEditionEnabled()) {
        return ngDialogService.showDialog('create-storage-domain-dialog')
        .toPromise()
        .then(function dialogResolved(target) {
          const viewBoxId = get(target, 'cloudDomains[0].viewBoxId');
          if (viewBoxId) {
            getViewBoxDetailsById(viewBoxId);
          }
        })
        .catch(Promise.reject);
      } else {
        $ctrl.loading = true;
        ViewBoxService.newViewBoxModal().then(
          function modalResolved(newViewBox) {
            $ctrl.viewBoxes.unshift(newViewBox);
            setSelection(newViewBox);
          },
          noop
        )
        .finally(function modalCloseFinally() {
          $ctrl.loading = false;
        });
      }
    }

    /**
     * Fetches the storage domain details from API.
     * This is required as NGCE uses external target PUT
     * API for creation/updation of storage domains.
     *
     * @method   getViewBoxDetailsById
     * @param    {number}   [id]   View Box Id.
     */
    function getViewBoxDetailsById(id) {
      ViewBoxService.getViewBox(id).then(
        function onSuccess(newViewBox) {
          $ctrl.viewBoxes.unshift(newViewBox);
          setSelection(newViewBox);
        }
      )
    }

    /**
     * Return true if cluster is NGCE.
     *
     * @method  cloudEditionEnabled
     * @return  {Boolean}   True if cluster is NGCE
     */
    function cloudEditionEnabled() {
      return NgClusterService.isClusterNGCE;
    }

    /**
     * Sets the selection in this component and in ngModel simultaneously.
     *
     * @method   setSelection
     * @param    {object}   [viewBox]   The View Box.
     * @return   {object}   The same View Box.
     */
    function setSelection(viewBox) {
      if ($ctrl.multiple) {
        if (!angular.isArray($ctrl.selectedViewBox)) {
          $ctrl.selectedViewBox = [];
        }

        $ctrl.selectedViewBox = $ctrl.selectedViewBox.concat(viewBox);
      } else {
        $ctrl.selectedViewBox = viewBox;
      }

      return $ctrl.ngModel.$setViewValue($ctrl.selectedViewBox);
    }

    /**
     * Gets the View Boxes
     *
     * @method   getViewBoxes
     * @return   {object}   Promise to resolve with the requested data.
     */
    function getViewBoxes() {
      $ctrl.loading = true;

      return ViewBoxService[$ctrl.onlyOwn ?
        'getOwnViewBoxes' : 'getViewBoxes']().then(
        function thenHandler(viewBoxes) {
          $ctrl.viewBoxes = typeof $ctrl.filter === 'function' ?
            $ctrl.filter(viewBoxes) : viewBoxes;

          // if a defaultId was provided, find it in the list of View Boxes and
          // assign it to the internal model
          if ($ctrl.defaultIds) {
            if ($ctrl.multiple) {
              setSelection($ctrl.viewBoxes.filter(
                function findDefault(viewBox) {
                  return $ctrl.defaultIds.includes(viewBox.id);
                }
              ));
            } else {
              setSelection($ctrl.viewBoxes.find(
                function findDefault(viewBox) {
                  return viewBox.id === $ctrl.defaultIds;
                }
              ));
            }
          }
        },
        evalAJAX.errorMessage
      ).finally(function getViewBoxesFinally() {
        $ctrl.loading = false;
      });
    }

    /**
     * Select all available viewboxes.
     *
     * @method   selectAll
     * @param    status    True, if select all is selected, else false.
     */
    function selectAll(status) {
      $ctrl.isAllSelected = status;
      setSelection(status ? $ctrl.viewBoxes : []);
    }
  }


})(angular);
