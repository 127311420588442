import { isNumber } from 'lodash-es';
import { set } from 'lodash-es';
import { clone } from 'lodash-es';
import { isNaN } from 'lodash-es';
import { isEmpty } from 'lodash-es';
// View Box Service Formatter
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C')
    .service('ViewBoxServiceFormatter', ViewBoxServiceFormatterFn);

  function ViewBoxServiceFormatterFn($rootScope, UserService, TIME, NgIrisContextService) {

    var ViewBoxServiceFormatter = {
      transformViewBox: transformViewBox,
      untransformViewBox: untransformViewBox,
    };


    // Utility functions for transforming v1 viewbox to v2 storage domain

    /**
     * Trasnfrosm stats from v1 (type: ViewBoxStats) to v2 (type: DataUsageStats)
     * @param {*} stats
     * @returns
     * @private
     */
    function transformStatsFromV1ToV2(stats = {}) {
      return {
        ...stats,
        cloudTotalPhysicalUsageBytes: stats?.cloudUsagePerfStats.totalPhysicalUsageBytes,
        dataInBytes: stats?.dataUsageStats?.dataInBytes,
        dataInBytesAfterDedup: stats?.dataUsageStats?.dataInBytesAfterDedup,
        dataWrittenBytes: stats?.dataUsageStats?.dataWrittenBytes,
        localTierResiliencyImpactBytes: stats?.dataUsageStats?.localTierResiliencyImpactBytes,
        totalLogicalUsageBytes: stats?.logicalStats?.totalLogicalUsageBytes,
      };
    }

    /**
     * Trasnfrosm storage policy object from v1 to v2 typedef
     * @param {*} storagePolicy
     * @returns
     * @private
     */
    function transformStoragePolicyFromV1ToV2(storagePolicy = {}) {
      return {
        ...storagePolicy,
        appMarkerDetectionEnabled: storagePolicy.appMarkerDetection,
        cloudSpillVaultId: storagePolicy.cloudSpillVaultId,
        compressionParams: {
          type: storagePolicy?.compressionPolicy?.replace('kCompression', '') || undefined,
          inlineEnabled: storagePolicy.inlineCompress,
        },
        deduplicationCompressionDelaySecs: storagePolicy.deduplicateCompressDelaySecs,
        deduplicationParams: {
          enabled: storagePolicy.deduplicationEnabled,
          inlineEnabled: storagePolicy.inlineDeduplicate,
        },
        encryptionType: storagePolicy?.encryptionPolicy?.replace('kEncryption', '') || undefined,
        erasureCodingParams: {
          ...storagePolicy.erasureCodingInfo,
          enabled: storagePolicy.erasureCodingInfo?.erasureCodingEnabled ?? false,
          inlineEnabled: storagePolicy.erasureCodingInfo?.inlineErasureCoding,
          numCodedStripes: storagePolicy.erasureCodingInfo?.numCodedStripes ?? 0,
          numDataStripes: storagePolicy.erasureCodingInfo?.numDataStripes ?? 0,
        },
        numDiskFailuresTolerated: storagePolicy.numFailuresTolerated,
        numNodeFailuresTolerated: storagePolicy.numNodeFailuresTolerated,
      };
    }

    /**
     * Trasnfrosm viewbox object from v1 to v2 storage domain typedef
     * @param {*} viewBox
     * @returns
     */
    function tranformV1ToV2(viewBox = {}) {
      const storageDomain = {
        ...viewBox,
        adDomainName: viewBox.adDomainName,
        subnetWhitelist: viewBox.clientSubnetWhiteList,
        cloudDownWaterFallParams: {
          thresholdPercentage: viewBox.cloudDownWaterfallThresholdPct,
          thresholdSecs: viewBox.cloudDownWaterfallThresholdSecs,
        },
        clusterPartitionId: viewBox.clusterPartitionId,
        clusterPartitionName: viewBox.clusterPartitionName,
        defaultUserQuota: viewBox.defaultUserQuotaPolicy,
        defaultViewQuota: viewBox.defaultViewQuotaPolicy,
        directArchiveEnabled: viewBox.directArchiveEnabled,
        id: viewBox.id,
        recommended: viewBox.isRecommended,
        lastKeyRotationTimestampMsecs: viewBox.lastKeyRotationTimestampMsecs,
        ldapProviderId: viewBox.ldapProviderId,
        name: viewBox.name,
        physicalQuota: viewBox.physicalQuota,
        removalState: viewBox?.removalState?.replace('k','') || undefined,
        s3BucketsEnabled: viewBox.s3BucketsAllowed,
        schemas: viewBox.schemaInfoList,
        tenantIds: viewBox.tenantIdVec,
        treatFileSyncAsDataSync: viewBox.treatFileSyncAsDataSync,
      };
      if (viewBox.stats) {
        storageDomain.stats = transformStatsFromV1ToV2(viewBox.stats);
      }
      if (viewBox.storagePolicy) {
        storageDomain.storagePolicy = transformStoragePolicyFromV1ToV2(viewBox.storagePolicy);
      }
      return storageDomain;
    }

    // Utility functions for transforming v2 storage domain type to v1 viewbox

    /**
     * Trasnfrosm stats from v2 (type: DataUsageStats) to v1 (type: ViewBoxStats)
     * @param {*} stats
     * @returns
     * @private
     */
    function transformStatsFromV2ToV1(stats = {}) {
      return {
        ...stats,
        cloudUsagePerfStats: {
          totalPhysicalUsageBytes: stats?.cloudTotalPhysicalUsageBytes,
        },
        dataUsageStats: {
          dataInBytes: stats?.dataInBytes,
          dataInBytesAfterDedup: stats?.dataInBytesAfterDedup,
          dataWrittenBytes: stats?.dataWrittenBytes,
          localTierResiliencyImpactBytes: stats?.localTierResiliencyImpactBytes,
        },
        logicalStats: {
          totalLogicalUsageBytes: stats?.totalLogicalUsageBytes,
        },
      };
    }

    /**
     * Trasnfrosm storage policy object from v1 to v2 typedef
     * @param {*} storagePolicy
     * @returns
     * @private
     */
    function transformStoragePolicyFromV2ToV1(storagePolicy = {}) {
      return {
        ...storagePolicy,
        appMarkerDetection: storagePolicy.appMarkerDetectionEnabled,
        cloudSpillVaultId: storagePolicy.cloudSpillVaultId,
        compressionPolicy: storagePolicy.compressionParams?.type ? `kCompression${storagePolicy.compressionParams?.type}` : undefined,
        inlineCompress: storagePolicy.compressionParams?.inlineEnabled,
        deduplicateCompressDelaySecs: storagePolicy.deduplicationCompressionDelaySecs,
        deduplicationEnabled: storagePolicy.deduplicationParams?.enabled,
        inlineDeduplicate: storagePolicy.deduplicationParams?.inlineEnabled,
        encryptionPolicy: storagePolicy.encryptionType ? `kEncryption${storagePolicy.encryptionType}` : undefined,
        erasureCodingInfo: {
          ...storagePolicy.erasureCodingParams,
          erasureCodingEnabled: storagePolicy.erasureCodingParams?.enabled ?? false,
          inlineErasureCoding: storagePolicy.erasureCodingParams?.inlineEnabled,
          numCodedStripes: storagePolicy.erasureCodingParams?.numCodedStripes ?? 0,
          numDataStripes: storagePolicy.erasureCodingParams?.numDataStripes ?? 0,
        },
        numFailuresTolerated: storagePolicy.numDiskFailuresTolerated,
        numNodeFailuresTolerated: storagePolicy.numNodeFailuresTolerated,
      };
    }

    /**
     * Trasnfrosm storage domain object from v1 to v2 viewbox typedef
     * @param {*} storageDomain
     * @returns
     * @private
     */
    function tranformV2ToV1(storageDomain = {}) {
      const viewBox = {
        ...storageDomain,
        adDomainName: storageDomain.adDomainName,
        clientSubnetWhiteList: storageDomain.subnetWhitelist,
        cloudDownWaterfallThresholdPct: storageDomain.cloudDownWaterFallParams?.thresholdPercentage,
        cloudDownWaterfallThresholdSecs: storageDomain.cloudDownWaterFallParams?.thresholdSecs,
        clusterPartitionId: storageDomain.clusterPartitionId,
        clusterPartitionName: storageDomain.clusterPartitionName,
        defaultUserQuotaPolicy: storageDomain.defaultUserQuota,
        defaultViewQuotaPolicy: storageDomain.defaultViewQuota,
        directArchiveEnabled: storageDomain.directArchiveEnabled,
        id: storageDomain.id,
        isRecommended: storageDomain.recommended,
        lastKeyRotationTimestampMsecs: storageDomain.lastKeyRotationTimestampMsecs,
        ldapProviderId: storageDomain.ldapProviderId,
        name: storageDomain.name,
        physicalQuota: storageDomain.physicalQuota,
        removalState: storageDomain.removalState ? `k${storageDomain.removalState}` : undefined,
        s3BucketsAllowed: storageDomain.s3BucketsEnabled,
        schemaInfoList: storageDomain.schemas,
        tenantIdVec: storageDomain.tenantIds,
        treatFileSyncAsDataSync: storageDomain.treatFileSyncAsDataSync,
      };
      if (storageDomain.stats) {
        viewBox.stats = transformStatsFromV2ToV1(storageDomain.stats);
      }
      if (storageDomain.storagePolicy) {
        viewBox.storagePolicy = transformStoragePolicyFromV2ToV1(storageDomain.storagePolicy);
      }
      return viewBox;
    }


    /**
     * Transforms an API provided View Box to be more UI friendly
     *
     * @param      {object}    _viewBox    The View Box
     * @return     {object}    the updated/transformed View Box
     */
    function transformViewBox(_viewBox) {
      // converting v2 api resposne to v1 viewbox
      const viewBox = tranformV2ToV1(_viewBox);
      viewBox.storagePolicy = viewBox.storagePolicy || {};

      viewBox.storagePolicy.erasureCodingInfo =
        viewBox.storagePolicy.erasureCodingInfo || {
          erasureCodingEnabled: false,
        };

      // Backend sends this value but front-end does not consume it and should
      // not send it back.
      //
      // TODO (David): Remove this line when backend stops sending
      // `erasureCodeDelaySecs` in the GET response. Reference ENG-50449 for why
      // we still need this line.
      viewBox.storagePolicy.erasureCodingInfo.erasureCodeDelaySecs = undefined;

      // Set default value for Cloud Tiering Threshold: 60 days. We have to test
      // isNumber because zero is a valid value.
      viewBox.cloudDownWaterfallThresholdSecs =
        isNumber(viewBox.cloudDownWaterfallThresholdSecs) ?
        viewBox.cloudDownWaterfallThresholdSecs : TIME.secsPerDay * 60;

      viewBox.views = viewBox.views || [];

      // Check if the viewbox is owned by the current user.
      viewBox._isViewBoxOwner = isEntityOwner(NgIrisContextService.irisContext, viewBox.tenantIdVec);

      // Only allow the creation of a new view if the view is owned by the
      // cuurent user or the current user is the SP admin and viewbox sharing is
      // enabled.
      viewBox._allowViewCreation =
        viewBox._isViewBoxOwner || (!UserService.isTenantUser() &&
        $rootScope.clusterInfo.tenantViewboxSharingEnabled);

      // Stub the list of NIS Providers mapped to this Storage Domain.
      viewBox.nisDomainNameVec = viewBox.nisDomainNameVec || [];

      return viewBox;
    }

    /**
     * Untransforms a View Box for the PUT API
     *
     * @param      {object}    _viewBox    The View Box
     * @return     {object}    the updated/untransformed View Box
     */
    function untransformViewBox(_viewBox) {
      // converting v1 viewbox to v2 api request
      const viewBox = tranformV1ToV2(_viewBox);
      var copiedViewBox = clone(viewBox);
      var storagePolicy = copiedViewBox.storagePolicy;
      var thresholdPct =
        parseInt(copiedViewBox.cloudDownWaterfallThresholdPct, 10);

      if (isEmpty(storagePolicy.erasureCodingInfo)) {
        set(storagePolicy, 'erasureCodingInfo.erasureCodingEnabled', false);
      }

      // If value is NaN, set undefined. We have to test NaN because zero is a
      // valid value.
      copiedViewBox.cloudDownWaterfallThresholdPct =
        isNaN(thresholdPct) ? undefined : thresholdPct;

      return copiedViewBox;
    }

    return ViewBoxServiceFormatter;
  }

})(angular);
