import { assign } from 'lodash-es';
// Component: Connection Status

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.connectionStatus';
  var componentName = 'cConnectionStatus';

  /**
   * @ngdoc component
   * @name C.connectionStatus:cConnectionStatus
   * @function
   *
   * @description
   * Displays the connection status based on the binding
   *
   * @example
      <example module="C">
        <c-connection-status
          single-line="true"
          ng-if="$ctrl.ldap._fetchedStatus"
          value="$ctrl.ldap._status.success"
          subtext="$ctrl.ldap._status.msg | translate">
        </c-connection-status>
      </example>
   */
  var options = {
    bindings: {
      // @showStatusIcon   {boolean}   flag to show the status icon or not
      showStatusIcon: '<?',

      // @subtext   {string}    holds the value of the subtext
      subtext: '<?',

      // @value     {boolean}   value holds the booleanLabel
      value: '<?',

      // @warning   {boolean}   Optional override to set color to orange
      warning: '<?',

      // @isDisabled  {boolean}   Optional override to set color to gray
      isDisabled: '<?',

      // @singleLine  {boolean}   Optional styling for single line display
      singleLine: '<?',
    },
    transclude: {
      //  slot to show a label value. if not present, defaults to yes/no value
      statusText: '?statusText',
    },
    controller: 'ConnectionStatusController',
    templateUrl: 'app/global/c-connection-status/c-connection-status.html',
  };

  angular
    .module(modName, [])
    .controller('ConnectionStatusController', ConnectionStatusCtrlFn)
    .component(componentName, options);

  function ConnectionStatusCtrlFn($transclude) {

    var $ctrl = this;

    assign($ctrl, {
      // Component life-cycle methods
      $onInit: $onInit,
      isSlotFilled: $transclude.isSlotFilled,
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.showStatusIcon = $ctrl.showStatusIcon || true;
      $ctrl.subtext = $ctrl.subtext || '';
    }

  }

})(angular);
