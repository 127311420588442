import { UniversalId } from '@cohesity/api/v1';
import { DataLockConstraints, GetProtectionRunProgressBody, ProgressTaskEvent, Tenant } from '@cohesity/api/v2';
import { StateParams } from '@uirouter/core';
import { Environment, StatusObject, StatusType } from 'src/app/shared';

import { ArchivalTarget } from './archival-target.models';
import { ProtectionRunReplication } from './protection-run-replication.models';

/**
 * Run backup types. Values should match API run type values.
 */
export enum RunType {
  Full = 'kFull',
  HydrateCDP = 'kHydrateCDP',
  Log = 'kLog',
  Regular = 'kRegular',
  System = 'kSystem',
  StorageArraySnapshot = 'kStorageArraySnapshot'
}

/**
 * Available group modifier actions.
 */
export enum GroupAction {
  Run = 'runNow',
  Pause = 'pauseFutureRuns',
  Resume = 'resume',
  Cancel = 'cancel',
  Failover = 'failover',
  Deactivate = 'deactivate',
  Edit = 'edit',
  Delete = 'delete',
  DeleteSnapshots = 'deleteSnapshots',
  SelectSnapshotsForDelete = 'selectSnapshotsForDelete',
  ExportToCsv = 'exportToCsv',
  SetPrimary = 'setPrimary',
}

/**
 * Available cancel task types.
 */
export enum CancelTaskType {
  Backup = 'backup',
  Replication = 'replication',
  Archival = 'archival',
  CloudSpin = 'cloudSpin',
  Cad = 'CAD',
}

/**
 * Type of Protection Run Data Lock.
 */
export type DataLock = null | 'Compliance' | 'Administrative';

/**
 * Data structure of selected source IDs for protection group.
 */
export interface SelectedSourceIds {
  sourceIds: number[];
}

/**
 * Protection Run task cancellation type.
 */
export type CancelTask = 'backup' | 'replication' | 'archival' | 'cloudSpin' | 'CAD';

/**
 * Protection Run Archival Target type.
 */
export type ArchivalTargetType = 'Tape' | 'Cloud' | 'Nas';

/**
 * Protection Run Archival Target type.
 */
export type RetentionUnit = 'Days' | 'Weeks' | 'Months' | 'Years';

/**
 * Common properties for run, run object and target that have progress information from run progress API.
 */
export interface RunProgress {
  /**
   * Specifies the start time of the progress task in Unix epoch Timestamp(in microseconds).
   */
  startTimeUsecs?: number;

  /**
   * Specifies the end time of the progress task in Unix epoch Timestamp(in microseconds).
   */
  endTimeUsecs?: number;

  /**
   * Specifies the event log created for progress Task.
   */
  events?: ProgressTaskEvent[];

  /**
   * Specifies the expected remaining time of the progress task in Unix epoch Timestamp(in microseconds).
   */
  expectedRemainingTimeUsecs?: number;

  /**
   * Specifies the current completed percentage of the progress task.
   */
  percentageCompleted?: number;

  /**
   * The total number of file and directory entities visited in this backup.
   * Only applicable to file based backups.
   */
  totalFileCount?: number;

  /**
   * The total number of file and directory entities that are backed up in this run.
   * Only applicable to file based backups.
   */
  backupFileCount?: number;

  /**
   * Number of granular objects added/deleted/modified since the last backup.
   */
  numChangedGranularObjects?: number;

  /**
   * Specifies total number of granular objects protected in this backup.
   */
  numProtectedGranularObjects?: number;

  /**
   * Specifies number of changed granular objects which were backed up succesfully.
   */
  numSuccessfulBackedGranularObjects?: number;

  /**
   * Update progress fields from API response.
   */
  updateProgress?: (runProgress: GetProtectionRunProgressBody) => void;
}

/**
 * Interface for Protection Run shared by Protection Group and Protected Object run.
 */
export interface IProtectionRun extends RunProgress {
  /**
   * Run ID.
   */
  runId?: string;

  /**
   * Group ID for this run.
   */
  groupId?: string;

  /**
   * Group name.
   */
  name?: string;

  /**
   * Indicated if last snapshot was deleted in this run.
   */
  isLocalSnapshotsDeleted?: boolean;

  /**
   * Indicates if run is in progress.
   */
  isInProgress?: boolean;

  /**
   * Cluster ID.
   */
  clusterId?: number;

  /**
   * Cluster incarnation ID.
   */
  clusterIncarnationId?: number;

  /**
   * Specifies the list of tenants that have permissions for this protection group run.
   */
  permissions?: Tenant[];

  /**
   * Indicates if run has legal hold flag applied.
   */
  onLegalHold?: boolean;

  /**
   * Backup run status.
   */
  backupStatus?: StatusObject;

  /**
   * Local backup or CAD status.
   */
  status?: StatusType;

  /**
   * Run start date.
   */
  startDate?: Date;

  /**
   * Group end date.
   */
  endDate?: Date;

  /**
   * Run bytes read.
   */
  readBytes?: number;

  /**
   * Total bytes written.
   */
  writeBytes?: number;

  /**
   * Backup task ID for querying backup status.
   */
  progressTaskId?: string;

  /**
   * Archival status.
   */
  archivalStats?: StatusObject[];

  /**
   * Archival Target results.
   */
  archivalTargets?: ArchivalTarget[];

  /**
   * Maps Archival Target ArchivalTarget instance StatusObject instance.
   */
  archivalTargetStats?: WeakMap<ArchivalTarget, StatusObject>;

  /**
   * Cloud spin status.
   */
  cloudSpinStats?: StatusObject[];

  /**
   * Protection Run Environment.
   */
  environment?: Environment;

  /**
   * Indicates if this archive is cloud direct archive (CAD).
   */
  isDirectArchive?: boolean;

  /**
   * Flag to indicate if SLA passed.
   */
  isSlaViolated?: boolean;

  /**
   * Replication status.
   */
  replicationStats?: StatusObject[];

  /**
   * Run replication targets.
   */
  replicationTargets?: ProtectionRunReplication[];

  /**
   * Vault status.
   */
  vaultStats?: StatusObject[];

  /**
   * Run vault targets.
   */
  vaultTargets?: ProtectionRunReplication[];

  /**
   * SLA status.
   */
  slaStatus?: StatusObject;

  /**
   * Returns default router state for this run instance.
   */
  defaultState?: string;

  /**
   * Returns default router state params for this run instance.
   */
  defaultStateParams?: StateParams;

  /**
   * Indicates if Protection Run has DataL Lock applied.
   */
  dataLock?: DataLock;

  /**
   * Milliseconds between startDate and endDate.
   */
  duration?: number;

  /**
   * The tag of externally triggered backup job.
   */
  externallyTriggeredBackupTag?: string;

  /**
   * Specifies whether the run has a local snapshot. For cloud retrieved runs there may not be local snapshots.
   */
  hasLocalSnapshot?: null | boolean;

  /**
   * Type of run.
   */
  runType?: string;

  /**
   * Run total bytes.
   */
  totalBytes?: number;

  /**
   * True if backup run is for db sources i.e sql/oracle (except noSql sources).
   */
  isDbRun?: boolean;

  /**
   * Object successfully protected by run.
   */
  successfulObjectsCount?: number;

  /**
   * App object successfully protected by run.
   */
  successfulAppObjectsCount?: number;

  /**
   * Object failed to be protected by run.
   */
  failedObjectsCount?: number;

  /**
   * Number of objects skipped in a protection run.
   */
  skippedObjectsCount?: number;

  /**
   * App object failed to be protected by run.
   */
  failedAppObjectsCount?: number;

  /**
   * App object canceled to be protected by run.
   */
  cancelledAppObjectsCount?: number;

  /**
   * Object canceled to be protected by run.
   */
  cancelledObjectsCount?: number;

  /**
   * Total object protected by run.
   */
  totalObjectsCount?: number;

  /**
   * Total app object protected by run.
   */
  totalAppObjectsCount?: number;

  /**
   * Specifies if this protection run is a replication run.
   */
  isReplicationRun?: boolean;

  /**
   * Original cluster universal id for V1 Protection Job.
   */
  originalJobUid?: UniversalId;

  /**
   * Returns true if run is CDP Hydrate run.
   */
  isCdpHydrate?: boolean;

  /**
   * Returns true if run is log run.
   */
  isLogRun?: boolean;

  /**
   * Set the backup status name.
   *
   * @param  name  Name of backup status.
   */
  setBackupStatusName?: (name: string) => void;

  /**
   * DataLock Constraints for the run.
   */
  dataLockConstraints?: DataLockConstraints;

  /**
   * A user-provided note explaining the reason for pausing future runs, if applicable.
   */
  pausedNote?: null | string;

  /**
   * Time when the job was last paused or unpaused.
   */
  userInitiatedPauseRequestedTimeUsecs?: null | number;

  /**
   * The user who last paused this protection group.
   */
  lastPausedByUsername?: null | string;
}
