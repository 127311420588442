import { noop } from 'lodash-es';
import { get } from 'lodash-es';
// Module: Viewbox Detail Page

;(function(angular, undefined) {
  'use strict';

  angular.module('C.viewBoxes')
    .controller('viewboxDetailController', viewboxDetailControllerFn);

  function viewboxDetailControllerFn($scope, $state, $q, $translate, cMessage, featureFlagsService,
    ViewBoxService, ViewBoxModalService, ClusterService, StatsService, evalAJAX,
    cUtils, ExternalTargetService, CHART_COLORS, ENUM_ENTITY_ICON_CLASS,
    LdapService, NgClusterService, ngDialogService, _) {

    var chartCommon = {
      loading: true,
      chart: {
        height: 225,
        marginBottom: 25
      }
    };

    $scope.ENUM_ENTITY_ICON_CLASS = ENUM_ENTITY_ICON_CLASS;

    $scope.createViewInViewBox = ViewBoxService.createViewInViewBox;

    /**
     * presents a confirmation modal for deleting a viewbox,
     * redirects via $scope.goBack() on successful confirmation
     *
     * @return {Void}
     */
    $scope.deleteViewBox = function deleteViewBox() {

      ViewBoxModalService.showDeleteModal($scope.viewBox).then(
        function deleteViewBoxCompleted() {
          // this viewbox was deleted, nothing to see on this page.
          // pop a message
          cMessage.success({
            textKey: 'viewBoxDetail.deleteSuccess.title',
          });

          $state.go('cluster.viewboxes');
        },
        noop
      );
    };

    /**
     * presents the user with a modal for editing the current viewbox
     *
     * @return {Void}
     */
    $scope.editViewBox = function editViewBox() {
      if (featureFlagsService.enabled('ngStorageDomainsModify')) {
        return $state.go('ng-storage-domains-modify', {
          viewBoxId: $scope.viewBox.id,
        });
      }

      if ($scope.cloudEditionEnabled()) {
        return ngDialogService.showDialog('create-storage-domain-dialog', $scope.viewBox)
        .toPromise()
        .then(function dialogResolved(confirmed) {
          if (confirmed) {
            getData();
          }
        });
      }
      ViewBoxService.editViewBoxModal($scope.viewBox.id).then(
        function editViewBoxSuccess(updatedViewBox) {
          // keep the stats around, they won't have changed
          // and aren't returned with the updatedViewBox
          // object anyway
          updatedViewBox.stats = $scope.viewBox.stats;
          $scope.viewBox = updatedViewBox;

        },
        noop
      );
    };

    // Local Storage Chart
    $scope.localStorageChart = angular.merge({}, chartCommon, {
      chartType: 'storageDonut',
      titleText: 'viewBoxDetail.storageCharts.localTitle',
      series: [{
        type: 'pie',
        name: 'Storage',
        data: []
      }]
    });

    // Cloud Storage Chart
    $scope.cloudStorageChart = angular.merge({}, chartCommon, {
      chartType: 'storageDonut',
      titleText: 'viewBoxDetail.storageCharts.cloudTitle',
      colors: [CHART_COLORS.brand, CHART_COLORS.brandDark],
      series: [{
        type: 'pie',
        name: 'Storage',
        data: []
      }]
    });

    // Performance Chart
    $scope.performanceChart = angular.merge({}, chartCommon, {
      chartType: 'bytesLine',
      compact: true,
      stacked: false,
      yAxisLabelAppend: $translate.instant('perSec'),
      xAxisDateTime: true,
      series: [{
        name: $translate.instant('read'),
        type: 'line',
        data: []
      }, {
        name: $translate.instant('write'),
        type: 'line',
        data: []
      }]
    });

    /**
     * Return true if cluster is NGCE.
     *
     * @method  cloudEditionEnabled
     * @return  {Boolean}   True if cluster is NGCE
     */
     $scope.cloudEditionEnabled = function cloudEditionEnabled() {
      return NgClusterService.isClusterNGCE;
    }

    /**
     * builds the local and cloud storage chart and calculates storage stats
     * @return {Void}
     */
    function buildStorageCharts() {

      var viewBoxStats = $scope.viewBox?.stats || {};
      var clusterStats = $scope.clusterStats || {};

      var clusterCapacityUsed = 0;
      var viewBoxCloudUsed = 0;
      var otherCloudUsed = 0;

      var localUsagePerfStats = viewBoxStats.localUsagePerfStats || {};
      var logicalStats = viewBoxStats.logicalStats || {};
      var totalUsagePerfStats = viewBoxStats.usagePerfStats || {};
      var viewBoxCapacityUsed =
        localUsagePerfStats.totalPhysicalUsageBytes || 0;
      var viewBoxTotalCapacityUsed =
        totalUsagePerfStats.totalPhysicalUsageBytes || 0;

      $scope.localStorageChart.loading = true;
      $scope.cloudStorageChart.loading = true;

      // clear our storage charts and viewboxStorageStats
      $scope.cloudStorageChart.series[0].data.length = 0;
      $scope.localStorageChart.series[0].data.length = 0;
      $scope.viewboxStorageStats = {};

      // if View Box supports cloud tier, lets figure
      // out the details and setup the chart
      if ($scope.viewBox.storagePolicy.cloudSpillVaultId) {
        viewBoxCloudUsed =
          get(viewBoxStats, 'cloudUsagePerfStats.totalPhysicalUsageBytes', 0);
        otherCloudUsed =
          get(clusterStats, 'cloudUsagePerfStats.totalPhysicalUsageBytes', 0)
          - viewBoxCloudUsed;

        $scope.cloudStorageChart = angular.merge($scope.cloudStorageChart, {
          series: [{
            data: [[
                $translate.instant('viewBoxDetail.storageCharts.otherUsed'),
                otherCloudUsed
              ], [
                $scope.viewBox.name,
                viewBoxCloudUsed
              ],
            ]
          }]
        });
      }

      // glance bar stats
      $scope.viewboxStorageStats.totalUsed =
        viewBoxCapacityUsed + viewBoxCloudUsed;
      $scope.viewboxStorageStats.logicalUsed =
        logicalStats.totalLogicalUsageBytes || 0;
      $scope.viewboxStorageStats.redux = viewBoxTotalCapacityUsed ?
        cUtils.round(logicalStats.totalLogicalUsageBytes /
          viewBoxTotalCapacityUsed, 2) : null;

      clusterCapacityUsed =
        clusterStats.localUsagePerfStats.totalPhysicalUsageBytes;

      $scope.localStorageChart = angular.merge($scope.localStorageChart, {
        series: [{
          data: [[
              $translate.instant('viewBoxDetail.storageCharts.clusterAvailable'),
              clusterStats.localUsagePerfStats.physicalCapacityBytes -
              clusterCapacityUsed
            ],
            [
              $scope.viewBox.name,
              viewBoxCapacityUsed
            ],
          ]
        }]
      });

      $scope.localStorageChart.loading = false;
      $scope.cloudStorageChart.loading = false;
    }

    /**
     * calls API and constructs data for throughput chart
     * @return {void}
     */
    function buildPerformanceChart() {

      var schema = 'kBridgeViewBoxLogicalStats';

      var throughputWriteParams = {
        entityId: $scope.viewBox.id,
        schemaName: schema,
        metricName: 'kNumBytesWritten',
        rollupFunction: 'rate',
        range: 'day'
      };

      var throughputReadParams = {
        entityId: $scope.viewBox.id,
        schemaName: schema,
        metricName: 'kNumBytesRead',
        rollupFunction: 'rate',
        range: 'day'
      };

      var promiseArray = [];

      $scope.averageThroughputRead = null;
      $scope.averageThroughputWrite = null;

      // set chart as loading
      $scope.performanceChart.loading = true;

      // Request throughput data
      promiseArray.push(
        StatsService.getTimeSeries(throughputWriteParams),
        StatsService.getTimeSeries(throughputReadParams)
      );

      $q.all(promiseArray).then(function qAllSuccess(response) {
        var readData = [];
        var writeData = [];
        if (response[0].data && response[0].data.dataPointVec) {
          writeData = StatsService.buildIntervalDataSet(
            response[0].data.dataPointVec, throughputWriteParams);
        }
        if (response[1].data && response[1].data.dataPointVec) {
          readData = StatsService.buildIntervalDataSet(
            response[1].data.dataPointVec, throughputReadParams);
        }
        processData(readData, writeData);
      },
      function qAllError(error) {
        // clear data since it won't be replaced
        // and set loading to false
        $scope.performanceChart.series[0].data.length = 0;
        $scope.performanceChart.series[1].data.length = 0;
      }).finally(function qAllFinally() {
        $scope.performanceChart.loading = false;
      });

      function processData(readData, writeData) {
        if (readData.length !== writeData.length) {
          $log.error('throughput dataset length mismatch', readData, writeData);
        }

        // update chartConfig object with new values
        $scope.performanceChart.series[0].data = readData;
        $scope.performanceChart.series[0].pointInterval =
          throughputWriteParams.rollupIntervalSecs * 1000;
        $scope.performanceChart.series[0].pointStart =
          throughputWriteParams.startTimeMsecs;

        $scope.performanceChart.series[1].data = writeData;
        $scope.performanceChart.series[1].pointInterval =
          throughputReadParams.rollupIntervalSecs * 1000;
        $scope.performanceChart.series[1].pointStart =
          throughputReadParams.startTimeMsecs;

        $scope.performanceChart.loading = false;
      }
    }

    // TODO: Add Cloud chartBuildFuncs when viewBox.stats.cloudUsagePerfStats
    // is available.

    /**
     * calls the API and gets the current list viewbox and cluster info
     * @return {Void}
     */
    function getData() {
      var params = {
        fetchStats: true,
        // ViewBox may or may not be hidden/internal,
        // provide this flag just in case
        includeHidden: true
      };

      // The LDAP response is not consumed by this controller but this ensures
      // the LDAP cache is updated for LDAP resolution in the template filter.
      // We need to issue and resolve/catch this API silently and still allow
      // the SDs to be rendered in the event of an LDAP API error.
      LdapService.getLdapProviders().catch(noop);

      var promises = {
        clusterInfo: ClusterService.getClusterInfo(params, {}, false),
        viewBox: ViewBoxService.getViewBox($state.params.id, { includeStats: true }),
        hardwareInfo: ClusterService.getHardwareInfo(),
      };

      $scope.dataLoaded = false;

      $q.all(promises).then(
        function promisesSuccess(response) {

          if (response.clusterInfo) {
            $scope.clusterInfo = ClusterService.clusterInfo;
            $scope.clusterStats = response.clusterInfo.data.stats;
          }

          // response from getViewBox should be a View Box object,
          // if its falsy the View Box wasn't found.
          if (!response.viewBox) {
            // no viewbox with the provided ID
            cMessage.error({
              titleKey: 'viewBoxDetail.viewBoxNotFound.title',
              textKey: 'viewBoxDetail.viewBoxNotFound.text',
              persist: true,
              timeout: 6000,
            });
            return $state.go('cluster.viewboxes');
          } else {
            $scope.viewBox = response.viewBox;
            if ($scope.viewBox.storagePolicy &&
              $scope.viewBox.storagePolicy.cloudSpillVaultId) {
              getExternalTarget();
            }
          }

        },
        evalAJAX.errorMessage
      ).finally(
        function promisesFinally() {
          buildStorageCharts();
          buildPerformanceChart();
          $scope.dataLoaded = true;
        }
      );
    }

    function getExternalTarget() {
      return ExternalTargetService
        .getTarget($scope.viewBox.storagePolicy.cloudSpillVaultId)
        .then(function vaultDataArrived(resp) {
          $scope.externalTarget = resp;
        });
    }

    $scope.getAuthProviderName = function getAuthProviderName(viewBox) {
      var name = '';

      switch (true) {
        case !!viewBox.ldapProviderId && !!viewBox.adDomainName:
          name = viewBox.adDomainName + ' + ' +
            LdapService.getLdapProvider(viewBox.ldapProviderId).name;
          break;

        case !!viewBox.nisDomainNameVec[0] && !!viewBox.adDomainName:
          name = viewBox.adDomainName + ' + ' + viewBox.nisDomainNameVec[0];
          break;

        case !!viewBox.adDomainName:
          name = viewBox.adDomainName;
          break;

        case !!viewBox.ldapProviderId && !viewBox.kerberosRealmName:
          name = LdapService.getLdapProvider(viewBox.ldapProviderId).name;
          break;

        case !!viewBox.ldapProviderId && !!viewBox.kerberosRealmName:
          name = viewBox.kerberosRealmName + ' + ' +
            LdapService.getLdapProvider(viewBox.ldapProviderId).name;
          break;

        case !!viewBox.nisDomainNameVec[0]:
          name = viewBox.nisDomainNameVec[0];
          break;
      }

      return name;
    };


    getData();
  }


})(angular);
