import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';

import { EventTrackingService } from '../../../../event-tracking.service';
import { HelixIntlService } from '../../../../helix-intl.service';
import { KeyedSelectionModel } from '../../../../util/keyed-selection-model';
import { alphaSortValueFilterSelection, CanSelect, DataFilter, ValueFilterSelection } from '../../comparators';
import { FilterDefParams } from '../../filters/filter-def.directive';
import { FiltersComponent } from '../../filters/filters.component';
import { QuickFilterComponent } from '../../filters/quick-filter/quick-filter.component';

/** Maintains counter for unique id generation. */
let nextId = 0;

@Component({
  selector: 'cog-value-property-filter',
  templateUrl: './value-property-filter.component.html',
  styleUrls: ['./value-property-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ValuePropertyFilterComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * The quick filter component.
   */
  @ViewChild(QuickFilterComponent) quickFilter: QuickFilterComponent;

  /**
   * The filter associated with this component
   */
  @Input() filter: DataFilter<ValueFilterSelection[]>;

  /**
   * Params from `FilterDefDirective` instance.
   */
  @Input() filterDefParams: FilterDefParams;

  /**
   * Whether to allow multiple or single selection for the component.
   * The component will use either radio buttons or checkboxes based on this
   * value.
   */
  @Input() allowMultiple = true;

  /**
   * Whether to allow select all checkbox. Only applicable when allowMultiple is true.
   */
  @Input() allowSelectAll: boolean;

  /**
   * Whether to show clear button.
   */
  @Input() noClear = false;

  /**
   * Whether the filter should have at least one value present.
   */
  @Input() isRequired = false;

  /**
   * Automatically apply values without the user having to click apply
   */
  @Input() autoApply = false;

  /**
   * Provides a mechanism to allow for opting out of alpha sorting filter options.
   */
  @Input() alphaSort = true;

  /**
   * When clicked, this controls whether to show the menu or not.
   */
  @Input() showMenu = true;

  /**
   * Sets filter values.
   */
  @Input() set filterValues(values: ValueFilterSelection[]) {
    const valuesCopy = [...(values || [])];

    if (this.alphaSort && valuesCopy.length) {
      valuesCopy.sort(alphaSortValueFilterSelection);
    }

    this.filterValues$.next(valuesCopy);
  }

  /**
   * Returns current filter values as provided externally.
   */
  get filterValues(): ValueFilterSelection[] {
    return this.filterValues$.value;
  }

  /**
   * Unique id of the filter, defaults to internally generated id.
   */
  @Input() id = `value-property-filter-${nextId++}`;

  /**
   * Whether to make api call when search input change. Defaults to false.
   */
  @Input() asyncSearch = false;

  /**
   * Total number from async search which is either the same or greater than
   * the filter options. When it is greater, there can be more filter options.
   * When it is the same, the filter options is the same as all search result.
   */
  @Input() searchTotal = 0;

  /**
   * Do not emit a tracking event if track filters is set to false.
   */
  @Input() trackFilters = false;

  /**
   * Whether its form style filter i.e without borders
   */
  @Input() formStyle = false;

  /**
   * Static label to preface the dynamic chip label.
   */
  @Input() preLabel = '';

  /**
   * Show label as tooltip if true.
   */
  @Input() showLabelTooltip = false;

  @Input() labelTooltipPosition?: TooltipPosition = 'below';

  /**
   * Show an action button.
   */
  @Input() showActionButton = false;

  /**
   * Action button label.
   */
  @Input() actionButtonLabel = '';

  /**
   * EventEmitter for action button.
   */
  @Output() readonly actionButtonEmitter = new EventEmitter();

  /**
   * EventEmitter to broadcast search filed update to parent component.
   */
  @Output() readonly filterSearchInputChange = new EventEmitter<string>();

  /**
   * Filter button click event emitter.
   */
  @Output() filterClicked = new EventEmitter<MouseEvent>();

  isMenuOpened = false;

  /**
   * Select all checkbox value. The checkbox is only visible when allowMultiple & allowSelectAll are true.
   */
  selectAllCheckboxValue = false;

  /**
   * Indicates if the filter should close automatically or not.
   */
  get autoClose(): boolean {
    return this.autoApply && !this.allowMultiple;
  }

  get hideButtons(): boolean {
    return this.autoApply;
  }

  /**
   * Behavior subject for updating filter values.
   */
  private readonly filterValues$ = new BehaviorSubject<ValueFilterSelection[]>([]);

  /*
   * Determines when searching should be displayed for the given filter. Once there are more filter values than this
   * number the string search will be available for the filter.
   */
  filterValuesSearchThreshold = 10;

  /**
   * A search form control for filtering the list based on string input.
   */
  readonly stringSearchControl = new UntypedFormControl('');

  /**
   * Observable stream of filter values, filtered based on the stringSearchControl FormControl value.
   */
  displayedValues$: Observable<ValueFilterSelection[]>;

  /**
   * A selection model to track items which are selected.
   */
  selectionModel: SelectionModel<ValueFilterSelection>;

  /**
   * The currently applied filters.
   */
  appliedFilters: ValueFilterSelection[];

  /**
   * Clean up observable subscriptions when component is destroyed.
   */
  private readonly destroy$ = new Subject();

  @Input() canSelect: CanSelect = _item => true;

  constructor(
    private eventTrackingService: EventTrackingService,
    private filters: FiltersComponent,
    public intlSvc: HelixIntlService,
  ) {}

  /**
   * Handles select all checkbox change event
   *
   * @param event click event
   * @returns
   */
  handleSelectAllCheckboxChange(event: any) {
    const value = !this.selectAllCheckboxValue;
    this.selectAllCheckboxValue = value;
    if (value) {
      this.selectionModel.select(...this.filterValues);
    } else {
      this.selectionModel.clear();
    }
    if (this.autoApply) {
      this.applyFilters();
    }
    event.stopPropagation();
  }

  /**
   * Syncs the selection model with the filter
   */
  ngOnInit() {
    this.selectionModel = this.createSelectionModel();
    // update selectAllCheckboxValue when selectionModel changes
    this.selectionModel.changed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.selectAllCheckboxValue = this.selectionModel.selected.length === this.filterValues.length;
    });
    combineLatest([this.filter.currentValue$, this.filterValues$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([newFilterValue]) => {
        this.selectionModel.clear();
        if (this.asyncSearch) {
          if (newFilterValue?.value) {
            this.selectionModel.select(...newFilterValue.value);
          }
        } else {
          if (newFilterValue && newFilterValue.value) {
            // Check for object equality and then fall back to matching on the labels before
            // updating the selection model.
            const matchingItems = newFilterValue.value.map(
              selected => this.filterValues.find(
                existingValue => existingValue === selected || existingValue.label === selected.label)
            );

            this.selectionModel.select(...matchingItems);

            // populating selected values to appliedFilters from filter.setValue()
            if (!isEqual(this.appliedFilters, matchingItems)) {
              this.appliedFilters = matchingItems;
            }
          }
        }
      });

    this.filter.clear$.pipe(takeUntil(this.destroy$)).subscribe(this.clearFilters);
    this.filter.apply$.pipe(takeUntil(this.destroy$)).subscribe(this.applyFilters);

    const searchInputValue$ = this.stringSearchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    this.displayedValues$ = combineLatest([this.filterValues$, searchInputValue$]).pipe(map(([values, search]) => {
      if (!this.asyncSearch) {
        // If doing client-side filtering, just filter values to match search string.
        return values.filter(value => (value.label || '').toLowerCase().includes(search.toLowerCase()));
      } else {
        // If doing server-side filtering, return values, which is passed into component from API response.
        return values;
      }
    }));

    // Subscribe to the search input and emit filterSearchInputChange event only when asyncSearch is true.
    if (this.asyncSearch) {
      searchInputValue$
        .pipe(takeUntil(this.destroy$))
        .subscribe((searchString: string) => this.filterSearchInputChange.emit(searchString));
    }
  }

  /**
   * Update the selectionModel whenever allowMultipleChanges or filterValuesChanges.
   *
   * @param changes simple changes object
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowMultiple) {
      this.selectionModel = this.createSelectionModel();
    }

    // If filterValues changed, maintain matched selection and update selectionModel.
    if (changes.filterValues &&
      !this.asyncSearch &&
      this.selectionModel?.selected?.length &&
      this.selectionModel?.selected[0] !== undefined) {
      // get current value of filter selection.
      const currentValue = this.selectionModel.selected;

      // Clear selection.
      this.selectionModel.clear();

      // Find the matching values from filterValues.
      const newSelection = this.filterValues.filter(newValue =>
        currentValue.find(current => current === newValue || current?.label === newValue?.label)
      );

      // Update selection model.
      this.selectionModel.select(...newSelection);

      // Apply new selection.
      this.applyFilters();
    }
  }

  /**
   * Clean up the filter subscription on destroy.
   */
  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Creates a new selection model and returns it.
   *
   * @returns Newly created selection model.
   */
  createSelectionModel(): SelectionModel<ValueFilterSelection> {
    if (this.filterDefParams.allowSelectionFromSingleFilter) {
      return this.filters.selectionModelByFilterDef.get(this.filter);
    }

    if (this.asyncSearch) {
      return new KeyedSelectionModel<ValueFilterSelection>(
        (selection) => selection.value,
        this.allowMultiple
      );
    }
    return new SelectionModel<ValueFilterSelection>(this.allowMultiple);
  }

  /**
   * Toggles the selection for a given item.
   *
   * @param   value   The value of the selected item.
   */
  toggleSelection(value: ValueFilterSelection) {
    this.selectionModel.toggle(value);

    const { quickFilter, filterGroup } = this.filterDefParams;
    if (!quickFilter && !filterGroup || this.autoApply) {
      this.applyFilters();
    }

    if (this.trackFilters) {
      this.eventTrackingService.send({
        id: 'value-property-list-item',
        properties: {
          name: value.label,
        },
      });
    }
  }

  /**
   * Check if the item can be selected or not. Should have atleast one filter
   * value selected
   *
   * @param item  The item to check
   * @returns True, if the selection is disabled.
   */
  isSelectionDisabled(item: ValueFilterSelection): boolean {
    const canSelectItem = this.canSelect(item);

    if (canSelectItem && this.isRequired && this.selectionModel.isSelected(item)) {
      return this.selectionModel.selected.length === 1;
    }

    return !canSelectItem;
  }

  /**
   * Applies all filters at once.
   */
  applyFilters = () => {
    const { selected } = this.selectionModel;
    const { value: currentValue } = this.filter.currentValue$;

    // Cache the applied filters.
    this.appliedFilters = selected;

    // If there is no currentValue and has selectedValue
    // OR if there is currentValue and its different from selected value
    // prevent setting filter value if nothing has changed
    if ((!currentValue && selected.length > 0) ||
      (currentValue && !isEqual(currentValue.value, selected))) {
      this.filter.setValue(selected);
    }

    if (this.autoClose && this.quickFilter) {
      this.quickFilter.dismiss();
    }
  };

  isFirstItemSelected(item: ValueFilterSelection) {
    return this.selectionModel.selected?.[0] === item && this.selectionModel.isSelected(item);
  }

  menuOpened() {
    this.isMenuOpened = true;
  }

  /**
   * Handle function for when quick filter menu is closed.
   */
  handleMenuClose() {
    this.isMenuOpened = false;
    // When the menu is closed, clear selection which were never applied.
    this.selectionModel.clear();
    this.selectionModel.select(...(this.appliedFilters || []));
    this.stringSearchControl.setValue('');
  }

  /**
   * Clears all selected filters.
   */
  clearFilters = () => {
    this.selectionModel.clear();
    this.stringSearchControl.setValue('');
    if (this.autoApply) {
      this.applyFilters();
    }
  };

  /**
   * Returns sanitized id for given value
   *
   * @param   value   unique value.
   * @param   label   the value's label
   * @return   Id string.
   */
  sanitizeId(value: any, label: string) {
    const itemId = ['object', 'function'].includes(typeof value) ? label : value;
    return `${this.id}-${itemId}`.replace(/\s/g, '');
  }

  /**
   * Button action to emit on click.
   */
  buttonAction() {
    this.actionButtonEmitter.emit();
  }

  /**
   * Return the tooltip text for a given filter item/option.
   *
   * @param item The filter item
   * @returns the tooltip text.
   */
  getTooltipText(item: ValueFilterSelection) {
    return item.customTooltip || item.hintText || (this.showLabelTooltip ? item.label : undefined);
  }
}
