<mat-form-field
  [class.loading]="loading"
  [class.mat-form-field-invalid]="ngControl.touched && ngControl.invalid"
  [floatLabel]="floatLabel">
  <mat-label>{{label}}</mat-label>
  <cog-spinner
    *ngIf="loading"
    [inline]="true"
    matPrefix
    size="xs">
  </cog-spinner>
  <mat-select
    (valueChange)="onSelect($event)"
    cogDataId="select-parent-source"
    panelClass="cog-mat-select-panel-lg"
    [required]="required"
    [disabled]="readOnly"
    [value]="value"
    id="select-parent-source"
    (openedChange)="openedChange($event)">
    <mat-select-trigger *ngIf="value">
      {{value?.protectionSource?.customName ? value.protectionSource.customName : value?.protectionSource?.name}}
    </mat-select-trigger>

    <!-- Show search filter if the parent component sets the allowSearch -->
    <mat-option *ngIf="allowSearch" [inheritDataId]="true" cogDataId="option-search">
      <ngx-mat-select-search
        [formControl]="searchCtrl"
        [noEntriesFoundLabel]="'noMatchingItems' | translate"
        [placeholderLabel]="'search' | translate">
      </ngx-mat-select-search>
    </mat-option>
    <mat-optgroup *ngFor="let group of filteredSources$ | async; index as i" [label]="group.groupName">
      <mat-option *ngFor="let source of group.sources; index as j"
        id="select-source-{{i}}-{{j}}"
        [cogDataId]="'option-' + source.protectionSource.name"
        [inheritDataId]="true"
        [disabled]="sourceMaintenanceStatus(source) === SourceStatus.UNDER_MAINTENANCE_INFINITE ||
          sourceMaintenanceStatus(source) === SourceStatus.UNDER_MAINTENANCE_FINITE"
        [value]="source">
        <div class="source-info">
          <div>
            <strong>{{ source.protectionSource.customName ? source.protectionSource.customName : source.protectionSource.name }}</strong>
            <ul class="c-ul-inline no-margin meta-data">
              <li>
                {{'objectsColon' | translate}} {{source._numLeaves}}
              </li>
              <li>
                {{'sizeColon' | translate}}
                {{!noSizeAdapters.includes(source._environment) ?
                  (source._logicalSize | byteSize) : ('naNotApplicable' | translate) }}
              </li>
            </ul>
          </div>
          <cog-icon
            size="md"
            cogDataId="scheduled-maintenance-icon"
            *ngIf="sourceMaintenanceStatus(source) === SourceStatus.SCHEDULED_MAINTENANCE"
            class="margin-left-sm"
            shape="helix:status-source-scheduled-maintenance">
          </cog-icon>
          <cog-tag
            status="warning"
            cogDataId="under-maintenance-tag"
            *ngIf="sourceMaintenanceStatus(source) === SourceStatus.UNDER_MAINTENANCE_FINITE ||
              sourceMaintenanceStatus(source) === SourceStatus.UNDER_MAINTENANCE_INFINITE"
            class="margin-left-sm">
            {{ 'maintenance' | translate }}
          </cog-tag>
        </div>
      </mat-option>
    </mat-optgroup>
    <mat-option #add
      (click)="!add.disabled && registerNewSource()"
      *ngIf="addNewEnable && 'PROTECTION_SOURCE_MODIFY' | userPrivileges">
      <coh-add-item-option>
        {{'registerSource' | translate}}
      </coh-add-item-option>
    </mat-option>
  </mat-select>
</mat-form-field>
<!-- TODO (Tung): move error inside mat-form-field when component implements MatFormFieldControl -->
<mat-error *ngIf="ngControl.touched && ngControl.invalid">{{'errors.required' | translate}}</mat-error>
