import { find } from 'lodash-es';
// MODULE: Add Nodes via Discovery

;(function(angular, undefined) {

  angular.module('C.addNodes')
    .controller('addNodesDiscoverController', addNodesDiscoverControllerFn);

  function addNodesDiscoverControllerFn($rootScope, $scope, $state,
    NodeService, evalAJAX, FORMATS, FEATURE_FLAGS, LIMIT_NODES, $translate,
    ClusterService, NgClusterService, $q, NgIpmiService) {

    $scope.FORMATS = FORMATS;
    $scope.FEATURE_FLAGS = FEATURE_FLAGS;
    $scope.clusterFound = $rootScope.clusterInfo.isNodeInCluster;

    $scope.text = angular.extend($scope.text, $rootScope.text.discoverNodes);

    $scope.errorText = $rootScope.text.discoverNodes.errorText;

    // counter for the number of nodes user has selected
    $scope.selectedNodesCount = 0;
    $scope.nodeListReady = false;

    // If true, disable node level ipmi ip input.
    $scope.shared.disableIpmiNetworkingConfig = false;

    // The remaining number of nodes which can be added in the case of virtual
    // edition cluster.
    $scope.maxNodes = LIMIT_NODES.virtualEditionCluster.max -
     $rootScope.clusterInfo.nodeCount;

    $scope.shared.customFailureDomainsEnabled =
      // Feature Flag is enabled AND
      FEATURE_FLAGS.customFailureDomain &&

      // Not in setup mode AND
      !$scope.setupMode &&

      // Cluster is physical. It needs to have chassis and racks.
      ClusterService.clusterInfo._isPhysicalInstall &&

      // Cluster is in rack fault tolerance mode.
      ClusterService.clusterInfo.faultToleranceLevel === 'kRack';

    /**
     * Discover nodes success handler.
     *
     * @param {*} response
     */
    function discoverSuccess(response) {
      var chassisDataTemplate = {};
      var currentChassisSerial;

      if (response.data.freeNodes && response.data.freeNodes.length) {
        $scope.shared.discoveredNodes = response.data.freeNodes;
        $scope.shared.discoveredNodes.forEach(
          function isNewChassisSerial(node) {
            var foundChassis;

            // Extract IP values, if they exist.
            if (!node.missingNode) {
              var ips = NodeService.getIps(node);
              node._ipmiIp = ips._ipmiIp || null;
              node._ipv4 = ips._ipv4 || null;
              node._ipv6 = ips._ipv6 || null;
              node._ipmiGateway = $scope.ipmiGateway || null;
              node._ipmiSubnetMask = $scope.ipmiSubnetMask || null;
            }

            // Add missing nodes
            if (currentChassisSerial !== node.chassisSerial &&
              !node.missingNode) {
              NodeService.addMissingNodes(node,
                $scope.shared.discoveredNodes);
              currentChassisSerial = node.chassisSerial;
            }

            // Add chassisSerial and its productModel to
            // shared.discoveredChassis
            chassisDataTemplate = {
              chassisSerial: node.chassisSerial,
              productModel: node.productModel,
              unknownPlatform: node.productModel === 'Unknown Platform',
              assignedRack: null,
            };

            // Loop through chassis in cluster to decorate chassisDataTemplate
            // with boolean to check if rack assigned already.
            if ($scope.shared.customFailureDomainsEnabled &&
              $scope.shared.chassis) {
              foundChassis = find($scope.shared.chassis,
                {serialNumber: node.chassisSerial});
              if (foundChassis && foundChassis.rackId > 0) {
                chassisDataTemplate.isRackPreAssigned = true;

                // Assign assignedRack value with rack object.
                chassisDataTemplate.assignedRack = find($scope.shared.racks,
                  {id: foundChassis.rackId});
              }
            }
            if (!find($scope.shared.discoveredChassis,
              {chassisSerial: node.chassisSerial})) {
              $scope.shared.discoveredChassis.push(chassisDataTemplate);
            }
          });
      } else {
        $scope.noNodesFound = true;
      }
    }

    /**
     * Filter nodes that have same chassis serial number.
     *
     * @method   filterWithChassisSerial
     * @param    {Object}   chassis   The chassis object to filter.
     * @returns  Comparision function which compares chassis serial with node serial.
     */
    $scope.filterWithChassisSerial = function filterWithChassisSerial(chassis) {
      return function(node) {
        return node.chassisSerial === chassis.chassisSerial;
      };
    };

    /**
     *
     */
    $scope.getNodesRacksInfo = function getNodesRacksInfo() {
      // Get free nodes and racks list to assign rack to newly added chassis.
      var promises = {
        discoverNodes: NodeService.discoverNodes(),
      };
      if ($scope.shared.customFailureDomainsEnabled) {
        Object.assign(promises, {
          getRacks: NgClusterService.getRacks().toPromise(),
          getChassis: NgClusterService.getChassis().toPromise(),
        });
      }

      // If in add node flow, get ipmi network info to enable/disable ipmi inputs.
      if (!$scope.setupMode && ClusterService.clusterInfo._isPhysicalInstall) {
        Object.assign(promises, {
          getIpmiNetworkInfo: NgIpmiService.getIpmiLanInfo().toPromise()});
      }

      $q.allSettled(promises).then(
        function gotFreeNodes(response) {
          if (response.getRacks && response.getRacks.resp) {
            $scope.shared.racks = response.getRacks.resp.racks;
          }
          if (response.getChassis && response.getChassis.resp) {
            $scope.shared.chassis = response.getChassis.resp.chassis;
          }
          if (response.getIpmiNetworkInfo && response.getIpmiNetworkInfo.resp) {
            $scope.ipmiSubnetMask = response.getIpmiNetworkInfo.resp.ipmiSubnetMask;
            $scope.ipmiGateway = response.getIpmiNetworkInfo.resp.ipmiGateway;

            $scope.shared.disableIpmiNetworkingConfig =
              !response.getIpmiNetworkInfo.resp ||
              !response.getIpmiNetworkInfo.resp.ipmiSubnetMask;
          }
          if (response.discoverNodes && response.discoverNodes.resp) {
            discoverSuccess(response.discoverNodes.resp);
          }

      }, function discoverNodesError(response) {
        evalAJAX.errorMessage(response);
        $scope.noNodesFound = true;
      }).finally(
        function discoverNodesFinally() {
          $scope.nodeListReady = true;
        });
    };

    /**
     * toggle the selected state (Boolean) for the given disovered node,
     * and increment/reduce the number of selected nodes as appropriate
     * @param  {Object} node the node to be toggled
     */
    $scope.toggleNode = function toggleNode(node) {
      node.$selected = !node.$selected;
      if (node.$selected) {
        $scope.selectedNodesCount++;
      } else {
        $scope.selectedNodesCount--;
      }
    };

    /**
     * for discovered node submission, ensure nodes are selected and
     * ipmi ip's are provided for each selected node (i.e. form being valid)
     * and then give selected nodes to our service
     */
    $scope.validateNodes = function validateNodes() {
      var selectedNodes = [];
      if ($scope.frmDiscoverNodes.$valid) {
        // pass the data for selected nodes into the service,
        // add selected node information into our cluster object
        angular.forEach($scope.discoveredNodes, function(node, index) {
          if (node.$selected) {
            selectedNodes.push({
              ip: node.ipAddresses[0],
              ipmiIp: node.ipmiIp
            });
          }
        });
      } else {
        // this shouldn't be displayed, as form button is
        // disabled as long as the form isnt valid
        cMessage.error({
          textKey: 'discoverNodes.checkIpmiIpsError'
        });
      }
    };

    /**
     * Takes the user to the next step for setting up the cluster.
     * @method   goToNextStep
     */
    $scope.goToNextStep = function goToNextStep() {
      var target = $rootScope.clusterInfo._isVirtualEditionCluster ?
        'add-nodes.vips' : 'add-nodes.node-setup';
      $state.go(target);
    };

    /**
     * Select all discovered nodes.
     * @method  selectAll
     */
    $scope.selectAll = function selectAll() {
      angular.forEach($scope.shared.discoveredNodes, function eachNode(node) {
        node.selected = !node.missingNode;
      });
    };

    // kick things off by getting the list of discovered nodes
    $scope.getNodesRacksInfo();
  }

})(angular);
