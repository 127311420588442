import { Injectable } from '@angular/core';
import { AdaptorAccessService, flagEnabled, AdaptorCanAccessFnMap } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';

/**
 * Defines source accessor and gets supported sources which are dependent on feature flags.
 */
@Injectable({
  providedIn: 'root',
})
export class ThAdapterAccessService extends AdaptorAccessService {
  config: AdaptorCanAccessFnMap = {
    [Environment.kAcropolis]: ctx => flagEnabled(ctx, 'datahawkThreatHuntingkAcropolisEnabled'),
    [Environment.kAD]: this.defaultConfigFn,
    [Environment.kAgent]: this.defaultConfigFn,
    [Environment.kAWS]: this.defaultConfigFn,
    [Environment.kAWSNative]: this.defaultConfigFn,
    [Environment.kAuroraSnapshotManager]: this.defaultConfigFn,
    [Environment.kAWSSnapshotManager]: this.defaultConfigFn,
    [Environment.kAwsS3]: this.defaultConfigFn,
    [Environment.kAzure]: this.defaultConfigFn,
    [Environment.kAzureNative]: this.defaultConfigFn,
    [Environment.kAzureSnapshotManager]: this.defaultConfigFn,
    [Environment.kCassandra]: this.defaultConfigFn,
    [Environment.kCouchbase]: this.defaultConfigFn,
    [Environment.kElastifile]: this.defaultConfigFn,
    [Environment.kExchange]: this.defaultConfigFn,
    [Environment.kFlashBlade]: this.defaultConfigFn,
    [Environment.kGCP]: this.defaultConfigFn,
    [Environment.kGCPNative]: this.defaultConfigFn,
    [Environment.kGenericNas]: ctx => flagEnabled(ctx, 'datahawkThreatHuntingkGenericNasEnabled'),
    [Environment.kGPFS]: this.defaultConfigFn,
    [Environment.kHBase]: this.defaultConfigFn,
    [Environment.kHdfs]: this.defaultConfigFn,
    [Environment.kHive]: this.defaultConfigFn,
    [Environment.kHpe3Par]: this.defaultConfigFn,
    [Environment.kHyperFlex]: this.defaultConfigFn,
    [Environment.kHyperV]: ctx => flagEnabled(ctx, 'datahawkThreatHuntingkHyperVEnabled'),
    [Environment.kHyperVVSS]: this.defaultConfigFn,
    [Environment.kIbmFlashSystem]: this.defaultConfigFn,
    [Environment.kIsilon]: ctx => flagEnabled(ctx, 'datahawkThreatHuntingkIsilonEnabled'),
    [Environment.kKubernetes]: this.defaultConfigFn,
    [Environment.kKVM]: this.defaultConfigFn,
    [Environment.kMongoDB]: this.defaultConfigFn,
    [Environment.kNetapp]: ctx => flagEnabled(ctx, 'datahawkThreatHuntingkNetappEnabled'),
    [Environment.kNimble]: this.defaultConfigFn,
    [Environment.kO365]: this.defaultConfigFn,
    [Environment.kO365Exchange]: this.defaultConfigFn,
    [Environment.kO365ExchangeCSM]: this.defaultConfigFn,
    [Environment.kO365Group]: this.defaultConfigFn,
    [Environment.kO365OneDrive]: this.defaultConfigFn,
    [Environment.kO365OneDriveCSM]: this.defaultConfigFn,
    [Environment.kO365Outlook]: this.defaultConfigFn,
    [Environment.kO365PublicFolders]: this.defaultConfigFn,
    [Environment.kO365Sharepoint]: this.defaultConfigFn,
    [Environment.kO365SharepointCSM]: this.defaultConfigFn,
    [Environment.kO365Teams]: this.defaultConfigFn,
    [Environment.kOracle]: this.defaultConfigFn,
    [Environment.kPhysical]: ctx => flagEnabled(ctx, 'datahawkThreatHuntingkPhysicalEnabled'),
    [Environment.kPhysicalFiles]: this.defaultConfigFn,
    [Environment.kPuppeteer]: this.defaultConfigFn,
    [Environment.kPure]: this.defaultConfigFn,
    [Environment.kRDSSnapshotManager]: this.defaultConfigFn,
    [Environment.kRemoteAdapter]: this.defaultConfigFn,
    [Environment.kSQL]: this.defaultConfigFn,
    [Environment.kStorageSnapshotProvider]: this.defaultConfigFn,
    [Environment.kUDA]: this.defaultConfigFn,
    [Environment.kVCD]: this.defaultConfigFn,
    [Environment.kSAPHANA]: this.defaultConfigFn,
    [Environment.kView]: ctx => flagEnabled(ctx, 'datahawkThreatHuntingkViewEnabled'),
    [Environment.kVMware]: () => true,
    [Environment.kSfdc]: this.defaultConfigFn,
    [Environment.kAwsRDSPostgresBackup]: this.defaultConfigFn,
    [Environment.kAwsRDSPostgres]: this.defaultConfigFn,
    [Environment.kAwsAuroraPostgres]: this.defaultConfigFn,
    [Environment.kAwsDynamoDB]: this.defaultConfigFn,
    [Environment.kAzureSQL]: this.defaultConfigFn,
    [Environment.kAzureEntraID]: this.defaultConfigFn,
    [Environment.kEntraID]: this.defaultConfigFn,
    [Environment.kMongoDBPhysical]: this.defaultConfigFn,
  };

  supportedAdaptors = this.getSupportedEnvironments();
}
