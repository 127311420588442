import { assign } from 'lodash-es';
// Component: sql alerts widget

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'SqlAlertsControl',
    templateUrl: 'app/global/c-widgets/sql/alerts/alerts.html',
    bindings: {
      data: '<',
    },
  };

  angular.module('C.widgets')
    .controller('SqlAlertsControl', sqlAlertsCtrlFn)
    .component('wSqlAlerts', componentConfig);

  /**
   * @ngdoc component
   * @name C.widgets:wSqlAlerts
   *
   * @param {Object} data alerts data should look like this:
   *                      {
   *                        criticalCount: string;
   *                        warningCount: string;
   *                      }
   *
   * @description
   * Shows an alerts widget with critical and warning alerts
   *
   * @example
   * <w-sql-alerts data="$ctrl.data"></w-sql-alerts>
   */
  function sqlAlertsCtrlFn() {
    var $ctrl = this;

    assign($ctrl, {
      $onChanges: $onChanges
    });

    /**
     * On changes, update the stat-list config
     *
     * @param    {object}   changes   The AngularJS provided changes object.
     */
    function $onChanges(changes) {
      if (changes.data) {
        $ctrl.statusConfig = !$ctrl.data ? [] : [{
          label: 'critical',
          value: $ctrl.data.criticalCount,
          classes: {
            'status-critical': _isStatusCritical(),
            'status-ok': _isStatusOk(),
          },
          link: _isStatusCritical() ?
            `alerts({alertsSeverity: 'kCritical', alertsDateRange: 'last24Hours'})` :
            'null',
          iconClasses: {
            'icn-health-hex-critical': _isStatusCritical(),
            'icn-green-tick': _isStatusOk(),
            'icn-health-hex-warning': _isStatusWarning(true),
          },
        },
        {
          label: $ctrl.data.warningCount === 1 ?
            'warning' : 'warnings',
          value: $ctrl.data.warningCount,
          link: _isStatusWarning(false) ?
            `alerts({alertsSeverity: 'kWarning', alertsDateRange: 'last24Hours'})` :
            'null',
          classes: {
            'status-ok': _isStatusOk(),
            'status-warning': _isStatusWarning(false),
          },
        },
        ];
      }
    }

    /**
     * Is Status Ok?
     *
     * @returns true if no warning or critical errors
     */
    function _isStatusOk() {
      return $ctrl.data ?
        $ctrl.data.criticalCount === 0 &&
          $ctrl.data.warningCount === 0 :
          false;
    }

    /**
     * Is Status Critical?
     *
     * @returns true if there are critical errors
     */
    function _isStatusCritical() {
      return $ctrl.data ? $ctrl.data.criticalCount > 0 : true;
    }

    /**
     * Is Status Warning?
     *
     * @param    {boolean}   includeCritical  Optional. only true
     *                                        if no critical alerts
     * @returns true if no warning or critical errors
     */
    function _isStatusWarning(includeCritical) {
      var hasWarnings = $ctrl.data ? $ctrl.data.warningCount > 0 : false;
      includeCritical = !!includeCritical;
      return includeCritical ?
        hasWarnings && !_isStatusCritical() : hasWarnings;
    }

  }

})(angular);
