import { assign } from 'lodash-es';
// Component: List of ICAP Servers

;(function(angular, undefined) {
  'use strict';

  angular.module('C.icapServers', [])
    .component('icapServers', {
      templateUrl: 'app/antivirus/providers/icap-servers.html',
      controller: IcapServersCtrlFn,
      bindings: {
        // @type   {object}   AV Provider Group.
        provider: '=',

        // @type   {string}   Indicates whether to render view mode, edit mode,
        //                    or create mode. Default is 'create' mode.
        mode: '@?',

        // @type   {boolean}  Indicates whether the ICAP connection status has
        //                    been fetched at least once.
        fetchedOnce: '<?',
      },
    });

  /**
   * @ngdoc component
   * @name C.icapServers
   * @function IcapServersCtrlFn
   *
   * @description
   * Provides component which manages the list of ICAP Servers for a Provider
   * group.
   *
   * @example
   * <icap-servers
   *   provider="$ctrl.provider"
   *   mode="edit"></icap-servers>
   */
  function IcapServersCtrlFn(_, AntivirusService) {
    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
      checkIcapConnectionStatus: checkIcapConnectionStatus,
    });

    /**
     * Initializes and fetches data.
     *
     * @method   $onInit
     */
    function $onInit() {
      switch ($ctrl.mode) {
        case 'edit':
          $ctrl.editMode = true;
          break;

        case 'view':
          $ctrl.viewMode = true;
          break;

        default:
          $ctrl.createMode = true;
          break;
      }

      if (!$ctrl.createMode) {
        checkIcapConnectionStatus($ctrl.provider);
      }
    }

    /**
     * Checks connection status of ICAP servers.
     *
     * @method   checkIcapConnectionStatus
     * @param    {Object}    provider    Provider group config object.
     */
    function checkIcapConnectionStatus(provider) {
      provider._fetchingIcapStatus = true;
      AntivirusService.checkIcapConnectionStatus(provider).finally(
        function checkIcapStatusFinally() {
          provider._fetchingIcapStatus = false;
          $ctrl.fetchedOnce = true;
        }
      );
    }

  }

})(angular);
