import { BannerConfig, CloseCallbackFn } from '@cohesity/helix';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';

import { IrisContext } from '../../iris-context';

export type BannerData = { [k in string]: boolean };

export abstract class BaseBannerConfig {
  /**
   * Unique banner indentifier
   */
  abstract readonly id: string;

  /**
   * provides banner config as observable
   */
  abstract bannerInfo$: Observable<BannerConfig[]>;

  /**
   * The local storage key used to store the acknowledgement of the banners.
   */
  readonly bannerAcknowledgementKey = 'C.cohesityBannerAccepted';

  /**
   * @returns the data of the stored acknowledgement from LOCAL STORAGE.
   */
  getBannerData(): BannerData {
    return JSON.parse(localStorage.getItem(this.bannerAcknowledgementKey)) || {};
  }

  upodateBannerData(bannerData: BannerData) {
    localStorage.setItem(this.bannerAcknowledgementKey, JSON.stringify(bannerData));
  }

  /**
   * Determines user login state
   *
   * @param ctx Iris context
   * @returns true if user logged in
   */
  protected isLoggedIn(ctx: IrisContext) {
    return !isEmpty(ctx?.user);
  }

  /**
   * Common banner acknowledged callback
   */
  protected closeCallback: CloseCallbackFn = (id) => {
    this.updateBannerAcknowledged(true, id);
  };

  /**
   * Returns whether the banner has been acknowledged.
   *
   * @returns True, if banner has already been accepted.
   */
  protected isBannerAcknowledged(id: string): boolean {
    const storageData = this.getBannerData();
    return Boolean(storageData[id]);
  }

  /**
   * Updates the banner acknowledgement for a banner.
   *
   * @param acknowledged  True if the banner was acknowledged by the user.
   */
  protected updateBannerAcknowledged(acknowledged: boolean, id: string) {
    const storageData = this.getBannerData();
    storageData[id] = acknowledged;
    this.upodateBannerData(storageData);
  }
}
