<button
  type="button"
  mat-icon-button
  class="pin-report-button"
  cohStopPropagation
  [matTooltip]="(isRemove ? 'removeFromLandingPage' : 'pinToLandingPage') | translate"
  matTooltipPosition="above"
  (click)="toggle()">
  <cog-icon [shape]="isRemove ? 'remove' : 'add'" class="pin-report-icon"></cog-icon>
</button>
