/**
 * Specifies the environment.
 *
 * Note: If any environments are added/modified, also add/update the
 * translation in ui.json under enum.environment.*
 */

export enum Environment {
  kAcropolis = 'kAcropolis',
  kAD = 'kAD',
  kAgent = 'kAgent',
  kAWS = 'kAWS',
  kAWSNative = 'kAWSNative',
  kAwsAuroraPostgres = 'kAwsAuroraPostgres',
  kAuroraSnapshotManager = 'kAuroraSnapshotManager',
  kAwsRDSPostgresBackup = 'kAwsRDSPostgresBackup',
  kAwsRDSPostgres = 'kAwsRDSPostgres',
  kAwsDynamoDB = 'kAwsDynamoDB',
  kAWSSnapshotManager = 'kAWSSnapshotManager',
  kAwsS3 = 'kAwsS3',
  kAzure = 'kAzure',
  kAzureNative = 'kAzureNative',
  kAzureSnapshotManager = 'kAzureSnapshotManager',
  kAzureSQL = 'kAzureSQL',
  kCassandra = 'kCassandra',
  kCouchbase = 'kCouchbase',
  kEntraID = 'kEntraID',
  kAzureEntraID = 'kAzureEntraID',
  kElastifile = 'kElastifile',
  kExchange = 'kExchange',
  kFlashBlade = 'kFlashBlade',
  kGCP = 'kGCP',
  kGCPNative = 'kGCPNative',
  kGenericNas = 'kGenericNas',
  kGPFS = 'kGPFS',
  kHBase = 'kHBase',
  kHdfs = 'kHdfs',
  kHive = 'kHive',
  kHpe3Par = 'kHpe3Par',
  kHyperFlex = 'kHyperFlex',
  kHyperV = 'kHyperV',
  kHyperVVSS = 'kHyperVVSS',
  kIbmFlashSystem = 'kIbmFlashSystem',
  kIsilon = 'kIsilon',
  kKubernetes = 'kKubernetes',
  kKVM = 'kKVM',
  kMongoDB = 'kMongoDB',
  kMongoDBPhysical = 'kMongoDBPhysical',
  kNetapp = 'kNetapp',
  kNimble = 'kNimble',
  kO365 = 'kO365',
  kO365Exchange = 'kO365Exchange',
  kO365Group = 'kO365Group',
  kO365OneDrive = 'kO365OneDrive',
  kO365Outlook = 'kO365Outlook',
  kO365PublicFolders = 'kO365PublicFolders',
  kO365Sharepoint = 'kO365Sharepoint',
  kO365Teams = 'kO365Teams',
  kO365ExchangeCSM = 'kO365ExchangeCSM',
  kO365OneDriveCSM = 'kO365OneDriveCSM',
  kO365SharepointCSM = 'kO365SharepointCSM',
  kOracle = 'kOracle',
  kPhysical = 'kPhysical',
  kPhysicalFiles = 'kPhysicalFiles',
  kPuppeteer = 'kPuppeteer',
  kPure = 'kPure',
  kRDSSnapshotManager = 'kRDSSnapshotManager',
  kRemoteAdapter = 'kRemoteAdapter',
  kSQL = 'kSQL',
  kStorageSnapshotProvider = 'kStorageSnapshotProvider',
  kUDA = 'kUDA',
  kVCD = 'kVCD',
  kView = 'kView',
  kVMware = 'kVMware',
  kSfdc = 'kSfdc',
  kSAPHANA = 'kSAPHANA'
}

/**
 * Environment data type.
 */
// NOTE: For now we will have to manually update this list until we get a
// discreet environmentType in our generated models.
export type EnvironmentType =
  'kAD' |
  'kAWS' |
  'kAWSNative' |
  'kAwsRDSPostgresBackup' |
  'kAwsAuroraPostgres' |
  'kAwsRDSPostgres' |
  'kAwsDynamoDB' |
  'kAWSSnapshotManager' |
  'kAwsS3' |
  'kAcropolis' |
  'kAgent' |
  'kAuroraSnapshotManager' |
  'kAzure' |
  'kAzureNative' |
  'kAzureSnapshotManager' |
  'kCassandra' |
  'kCouchbase' |
  'kElastifile' |
  'kExchange' |
  'kFlashBlade' |
  'kGCP' |
  'kGCPNative' |
  'kGPFS' |
  'kGenericNas' |
  'kHBase' |
  'kHdfs' |
  'kHive' |
  'kHpe3Par' |
  'kHyperFlex' |
  'kHyperV' |
  'kHyperVVSS' |
  'kIsilon' |
  'kKVM' |
  'kKubernetes' |
  'kMongoDB' |
  'kNetapp' |
  'kNimble' |
  'kO365' |
  'kO365Exchange' |
  'kO365Group' |
  'kO365OneDrive' |
  'kO365Outlook' |
  'kO365PublicFolders' |
  'kO365Sharepoint' |
  'kO365Teams' |
  'kO365ExchangeCSM' |
  'kO365OneDriveCSM' |
  'kO365SharepointCSM' |
  'kOracle' |
  'kPhysical' |
  'kPhysicalFiles' |
  'kPure' |
  'kIbmFlashSystem'|
  'kRDSSnapshotManager' |
  'kRemoteAdapter' |
  'kSQL' |
  'kStorageSnapshotProvider' |
  'kSfdc' |
  'kUDA' |
  'kVCD' |
  'kVMware' |
  'kView' |
  'kSfdc' |
  'kSAPHANA'|
  'kMongoDBPhysical';

/**
 * Array of environments.
 */
export const environmentList = Object.values(Environment).sort();

/**
 * List of environments which are considered as NAS sources.
 */
export const nasEnvironments: Environment[] = [
  Environment.kElastifile,
  Environment.kFlashBlade,
  Environment.kGenericNas,
  Environment.kGPFS,
  Environment.kIsilon,
  Environment.kNetapp,
];

/**
 * List of environments whose sources can be refreshed.
 */
export const refreshableSourceEnvs: Environment[] = [
  Environment.kHyperV,
  Environment.kVMware,
  Environment.kO365,
  Environment.kAWS,
  Environment.kAzure,
  ...nasEnvironments,
];

/**
 * List of environments which are considered as storage volume sources.
 */
export const storageVolumeEnvironments: Environment[] = [
  ...nasEnvironments,
  Environment.kPure,
  Environment.kIbmFlashSystem,
  Environment.kHpe3Par,
];



/**
 * List of environments which are supported by CAD v2 policies.
 */
export const CADSupportedEnvironments: Environment[] = [
  ...nasEnvironments,
  Environment.kVMware,
];

/**
 * Environments which support crash consistent backups.
 */
export const crashConsistentBackupEnvs = [
  Environment.kAcropolis,
  Environment.kVMware,
  Environment.kHyperV
];

/**
 * Environments which support cloud migration.
 */
export const cloudMigrationEnvs = [
  Environment.kVMware,
  Environment.kHyperV,
  Environment.kHyperVVSS,
];

/**
 * Environments which support sync replication.
 */
export const cdpSyncReplicationEnvs = [
  Environment.kVMware,
];

/**
 * Sources with a wrapping container.
 */
export const cohesityGroups = [
  Environment.kSQL,
  Environment.kPhysical,
  Environment.kGenericNas,
  Environment.kOracle,
  Environment.kAD,
  Environment.kExchange,
];

/**
 * indexableEnvironmentsExposedAsServers specifies the environments which are
 * exposed as volumes/servers and are indexed by Yoda. These can be browsed
 * by mounting the volumes.
 */
export const indexableEnvironmentsExposedAsServers = [
  Environment.kAcropolis,
  Environment.kAWS,
  Environment.kAWSNative,
  Environment.kAWSSnapshotManager,
  Environment.kAzure,
  Environment.kAzureNative,
  Environment.kAzureSnapshotManager,
  Environment.kExchange,
  Environment.kGCP,
  Environment.kGCPNative,
  Environment.kHyperV,
  Environment.kHyperVVSS,
  Environment.kKVM,
  Environment.kPhysical,
  Environment.kSQL,
  Environment.kVMware,
  Environment.kKubernetes,
];

/**
 * indexableEnvironmentsExposedAsViews specifies the entities which are exposed
 * as views and are indexed by Yoda. These can be browsed by the directory
 * structure instead of volumes.
 */
export const indexableEnvironmentsExposedAsViews = [
  Environment.kElastifile,
  Environment.kFlashBlade,
  Environment.kGenericNas,
  Environment.kGPFS,
  Environment.kIsilon,
  Environment.kNetapp,
  Environment.kPhysicalFiles,
  Environment.kView,
  Environment.kO365,
  Environment.kHdfs,
];

/**
 * indexableDmsEnvironments specifies the environments which are indexable
 * in DMS context. This will be a subset of indexableEnvironmentsExposedAsServers
 * or indexableEnvironmentsExposedAsViews.
 */
export const indexableDmsEnvironments = [
  Environment.kO365,
];

/**
 * List of environments which are considered as Hadoop sources.
 */
export const hadoopEnvironments: Environment[] = [
  Environment.kHBase,
  Environment.kHdfs,
  Environment.kHive,
];

/**
 * List of environments which are considered as NoSQL sources.
 */
export const noSqlEnvironments: Environment[] = [
  Environment.kCassandra,
  Environment.kCouchbase,
  Environment.kMongoDB,
];

/**
 * List of environments whose backups can be browsed.
 *
 * NOTE: the list can be found inside ENV_GROUPS.fileBrowsable too.
 */
export const fileBrowsableEnvironments: Environment[] = [
  Environment.kAcropolis,
  Environment.kAWS,
  Environment.kAWSNative,
  Environment.kAWSSnapshotManager,
  Environment.kAzure,
  Environment.kAzureNative,
  Environment.kAzureSnapshotManager,
  Environment.kElastifile,
  Environment.kFlashBlade,
  Environment.kGCP,
  Environment.kGenericNas,
  Environment.kGPFS,
  Environment.kHyperV,
  Environment.kHyperVVSS,
  Environment.kIsilon,
  Environment.kKVM,
  Environment.kNetapp,
  Environment.kPhysical,
  Environment.kRDSSnapshotManager,
  Environment.kView,
  Environment.kVMware,
  Environment.kKubernetes,
];

/**
 * List of environment which support instant volume mount.
 *
 * NOTE: This is different from ENV_GROUPS.instantMount.
 */
export const instantVolumeMountEnvironments: Environment[] = [
  Environment.kHyperV,
  Environment.kPhysical,
  Environment.kVMware,
];

/**
 * List of environments currently supported in DMS.
 * This excludes the sub-environments used by AWS and O365.
 */
export const dmsEnvironments: Environment[] = [
  Environment.kAWS,
  Environment.kGenericNas,
  Environment.kHyperV,
  Environment.kIsilon,
  Environment.kNetapp,
  Environment.kO365,
  Environment.kOracle,
  Environment.kPhysical,
  Environment.kPhysicalFiles,
  Environment.kSQL,
  Environment.kVMware,
  Environment.kSfdc,
  Environment.kUDA,
  Environment.kSAPHANA,
];
