import { Injectable } from '@angular/core';
import { IrisContextService, isGaiaScope } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { GaiaAppConfig } from 'src/app/app-modules';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

/**
 * The set of app states.
 */
const appStates = new Set([
  ...Object.keys(GaiaAppConfig.getStateAccessMap()),
]);

/**
 * State guard to ensure the iris service context is set to data insights for data insights routes.
 */
@Injectable({ providedIn: 'root' })
export class DataInsightsGuard implements StateGuard {
  /**
   * Run this at app priority
   */
  guardPriority = GuardPriority.App;

  constructor(private irisCtx: IrisContextService) { }

  /**
   * Run the data insights context guard.
   *
   * @param transition ui router transition
   * @returns allow data insights route, otherwise redirect with serviceType to switch to
   * @returns Allow data insights route when the serviceType is set to data insights
   * else initiate a switch to data insights scope.
   */
  onStart(transition: Transition): GuardResult {
    const toState = transition.to();
    const toParams = transition.params();
    const isDataInsightsState = appStates.has(toState.name);
    const hasDataInsightsServiceType = toParams.serviceType === 'diaas';
    const diaasScope = isGaiaScope(this.irisCtx.irisContext);

    if (isDataInsightsState && diaasScope && !hasDataInsightsServiceType) {
      return transition.targetState().withParams({ serviceType: 'diaas' });
    }
  }
}
