import { ProtectionSourceNode } from '@cohesity/api/v1';
import {
  DataTreeControl,
  DataTreeFilter,
  DataTreeFilterUtils,
  DataTreeNode,
  DataTreeTransformer
} from '@cohesity/helix';
import { SourceTreeFilters, TagAttribute, ViewFilterOption, ViewFilterType } from '@cohesity/iris-source-tree';
import { map } from 'rxjs/operators';
import { CloudJobType, Environment } from 'src/app/shared/constants';
import { AwsTagTypes } from 'src/app/shared/constants/cloud.constants';

import { AwsSourceDataNode } from './aws-source-data-node';

/**
 * Provide view filters for aws sources. This includes options to filter for
 * physical, or flat hierarchies as well as by tags.
 */
export class AwsViewFilters<T extends AwsSourceDataNode> {
  /**
   * These are the default view filters.
   */
  defaultViewFilters: ViewFilterOption[];

  /**
   * This will be added/removed conditionally to viewFilters
   */
  tagFilter: ViewFilterOption;

  constructor(
    private filters: SourceTreeFilters<T>,
    private treeControl: DataTreeControl<T>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
    useSimpleTags: boolean,
  ) {

    this.tagFilter = {
      // The filter for tags is an observable based on the selected tags observable
      // in source tree filters.
      filter: this.filters.selectedTags$.pipe(map((tags: TagAttribute[]) => this.getTagFilter(tags))),
      tooltip: 'tags',
      icon: 'helix:hierarchy-tag',
      isTag: true,
      id: ViewFilterType.Tag,
    };

    this.defaultViewFilters = [
      {
        id: ViewFilterType.Flat,
        tooltip: 'sourceTreePub.tooltips.flatVmView',
        filter: this.filterVMChildren,
        icon: 'helix:hierarchy-flat',
      },
      {
        id: ViewFilterType.Physical,
        tooltip: 'sourceTreePub.tooltips.physicalView',
        filter: this.filterPhysicalChildren,
        icon: 'helix:hierarchy-physical',
      },
      useSimpleTags && {
        id: ViewFilterType.SimpleTag,
        tooltip: 'tags',
        icon: 'helix:hierarchy-tag',
        filter: this.simpleTagFilter,
        isTag: true,
      },
      !useSimpleTags && this.tagFilter,
    ].filter(Boolean);

    this.filters.setViewFilters(this.defaultViewFilters);
    // Enable tag based exclusion for AWS.
    this.filters.setEnableTagExclusion(true);
  }

  /**
   * Filter callback function to show the phsyical tree hierarchy of a vcenter.
   */
  filterPhysicalChildren: DataTreeFilter<any> = (nodes: AwsSourceDataNode[]) =>
    DataTreeFilterUtils.hierarchyExcludeFilter(nodes, node => {
      const env = node.envSource;
      return AwsTagTypes.includes(env.type);
    });

  /**
   * Filter callback to show a flat list of vms from a vcenter.
   */
  filterVMChildren: DataTreeFilter<any> = (nodes: AwsSourceDataNode[]) => {
    const seenNodes = new Set<number | string>();
    return nodes
      .filter(node => {
        const matched = node.isLeaf;
        if (!matched || seenNodes.has(node.id)) {
          return false;
        }
        seenNodes.add(node.id);
        return true;
      })
      .map(node => this.dataTransformer.transformData(node.data, 0));
  };

  /**
   * This is a simpler version of the tag filter, which does not support combined tag protection.
   * It should output every tag at level 0, with all of the matching virtual machines at level 1.
   *
   * @param   nodes  All nodes in the vm hierarchy.
   * @returns A Tag view of the nodes.
   */
  simpleTagFilter: DataTreeFilter<any> = (nodes: AwsSourceDataNode[]) => {
    // Map of tag names to array of nodes
    // Array of All Tags
    // Assumption is that tags are applied _only_ to leaf nodes
    const taggedNodes = new Map<number, AwsSourceDataNode[]>();

    // Some source trees have duplicate children in physical and folder hiearchies. Use a set to
    // make sure that we only add them to the child list once.
    const seenChildren = new Map<number, Set<number>>();
    const tagNodes = [];

    // Leaf nodes and tags are each listed  separately in the list. Each VM has a list of tag
    // ids that are associated with it. This loop makes one pass through the tree, finds all
    // of the tag nodes, and uses vm's tagIds to build a map of tag ids to children.
    nodes.forEach(node => {
      if (node.isTag) {
        tagNodes.push(node);
      } else if (node.tagIds) {
        node.tagIds.forEach(tagId => {
          if (!taggedNodes.has(tagId)) {
            taggedNodes.set(tagId, []);
            seenChildren.set(tagId, new Set());
          }
          if (!seenChildren.get(tagId).has(node.protectionSource.id)) {
            taggedNodes.get(tagId).push(node);
            seenChildren.get(tagId).add(node.protectionSource.id);
          }
        });
      }
    });

    // Now that we have the tags and their children, convert them into new data tree nodes at the appropriate
    // levels and sort the children by name.
    const filteredView = [];
    tagNodes.sort((a: AwsSourceDataNode, b: AwsSourceDataNode) => a.name.localeCompare(b.name));
    tagNodes.forEach(tagNode => {
      const children = taggedNodes.get(tagNode.id) || [];

      filteredView.push(this.dataTransformer.transformData(
        {
          ...tagNode.data,
          protectionSource: {
            ...tagNode.data.protectionSource,

            // Tag ids are stored as strings in the source tree because of the need to combine them
            // on the fly. This filter doesn't allow for that, but we still need this to keep everything
            // consistent with the rest of the tree.
            id: tagNode.id.toString(),
          },
          nodes: children.map(child => child.data)
        },
        0
      ));
      children.sort((a: AwsSourceDataNode, b: AwsSourceDataNode) => a.name.localeCompare(b.name));
      children.forEach(child => {
        // Push transformed data of the current child node into filteredView array (depth 1)
        filteredView.push(this.dataTransformer.transformData(child.data, 1));

        // Check if the current child has children (is not a leaf node)
        if (!child.isLeaf) {
          // If it has children, map through each child and push their
          // transformed data into filteredView array (depth 2)
          child.children?.map(childData => {
            // The children of the tagged node doesn't have the tags associateed
            // So we manually add the tag info to the second level children from
            // the parent.
            const childDataWithTags = { ...childData };
            childDataWithTags.protectionSource.awsProtectionSource.tagAttributes =
              child.data.protectionSource.awsProtectionSource.tagAttributes;

            filteredView.push(this.dataTransformer.transformData(childDataWithTags, 2));
          });
        }
      });
    });
    return filteredView;
  };

  /**
   * Create a data tree filter from a set of selected tags
   *
   * @param   tags   The tags to filter by
   * @returs  A filter function that will select the desired tags.
   */
  getTagFilter(tags: TagAttribute[]): DataTreeFilter<any> {
    // If there are no tags specified yet, show a flat list of all vms
    if (!tags || !tags.length) {
      return this.simpleTagFilter;
    }
    return (nodes: T[]) => {
      const tagIds = tags.map(tag => Number(tag.id));
      const tagNames = tags.map(tag => tag.name);

      const tagNode = this.createTagNode(tagIds, nodes, tagNames);
      const taggedNodes: DataTreeNode<any>[] = [tagNode];
      taggedNodes.push(...tagNode.data.nodes.map(node => this.dataTransformer.transformData(node, 1)));
      this.treeControl.expand(tagNode);

      return taggedNodes;
    };
  }

  /**
   * Create a dummy node to add to the root of the tree. This should have a level of 0 and all
   * of it's children will be at level 1.
   *
   * @param   tags         One more tags represented by the node.
   * @param   taggedNodes  Any nodes that are matched by this one.
   * @returns A new tag node that can be added to the tree.
   */
  createTagNode(tagIds: number[], allNodes: T[], tagNames: string[] = []): T {
    if (!tagNames || !tagNames.length && tagIds.length) {
      tagNames = tagIds.map(tagId => allNodes.find(node => node.isTag && node.id === tagId))
        .map(node => node?.name)
        .filter(Boolean);
    }

    // Find all of the matching tagged nodes
    const taggedNodes = this.filterTaggedNodes(allNodes, tagIds);

    return this.dataTransformer.transformData(
      {
        protectionSource: {
          // The tag id  may contain ids for multiple tags
          id: tagIds.join('_') as any,

          // Include all of the tags in the name
          name: tagNames.join(', '),
          environment: Environment.kAWS,
          awsProtectionSource: {
            type: 'kTag',
          },
        },
        nodes: taggedNodes.map(node => node.data),
      },
      0
    ) as T;
  }

  /**
   * Filter a list of nodes for all nodes that match all of the tag ids.
   *
   * @param   nodes  Any nodes that are matched by this one.
   * @param   tagIds         One more tags represented by the node.
   * @returns A new tag node that can be added to the tree.
   */
  private filterTaggedNodes(nodes: T[], tagIds: number[]): T[] {
    const seenNodes = new Set<number | string>();
    return nodes.filter(node => {
      // Make sure that we don't return duplicates.
      if (!node.tagIds || seenNodes.has(node.id)) {
        return false;
      }
      seenNodes.add(node.id);

      // Must match all of the requested tags.
      return tagIds.every(searchTag => node.tagIds.find(tagId => tagId === searchTag));
    });
  }

  /**
   * Remove tag filter from list of filters
   */
  removeTagFilter() {
    this.defaultViewFilters = this.defaultViewFilters.filter(filter => !filter.isTag);

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Add tag filter to list of filters
   */
  addTagFilter() {
    if (!this.defaultViewFilters.find(filter => filter.isTag)) {
      this.defaultViewFilters.push(this.tagFilter);
    }

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Updates the label for the flat filter icon depending on the type of job type.
   *
   * @param   cloudJobType  The job type currently being shown in the source tree.
   */
  updateFlatFilterLabel(cloudJobType: CloudJobType) {
    this.defaultViewFilters[1].tooltip =
      [CloudJobType.kRDSSnapshotManager, CloudJobType.kAuroraSnapshotManager]
        .includes(cloudJobType) ?
          'sourceTreePub.tooltips.flatDbView' :
            'sourceTreePub.tooltips.flatVmView';
  }
}
