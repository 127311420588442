import { assign } from 'lodash-es';
// Controller: Storage Snapshot Provider

;(function(angular, undefined) {
  angular
    .module('C.storageSnapshotProvider', [])
    .controller('StorageSnapshotProviderController',
      storageSnapshotProviderControllerFn);

  function storageSnapshotProviderControllerFn(_, $state, $uibModalInstance,
    ENUM_ENV_TYPE, ENUM_ENTITY_TYPE, SourceService, cMessage, evalAJAX,
    ENV_TYPE_CONVERSION) {

    var $ctrl = this;

    assign($ctrl, {
      $onInit: onInit,
      cancel: cancel,
      registerStorageSnapshot: registerStorageSnapshot,
      storageSnapshotSource: {
        entity: {
          type: 26,
          hyperflexEntity: {
            type: 0,
          },
        },
        entityInfo: {
          endpoint: undefined,
          type: 26,
          credentials: {
            username: undefined,
            password: undefined,
          },
        },
      },
      state: {
        editMode: $state.params.id,
        inModal: typeof $uibModalInstance === 'object',
        selectedStorageSnapshotProvider: {},
        submitting: false,
      },
    });

    $ctrl.storageSnapshotProviderTypes = [
      {
        name: [ENUM_ENV_TYPE['kStorageSnapshotProvider'],
          ENUM_ENTITY_TYPE[26][0]].join(': '),
        value: {
          type: 26,
          hyperFlexEntity: {
            type: 0,
          },
        },
      }
    ];

    $ctrl.state.selectedStorageSnapshotProvider =
      $ctrl.storageSnapshotProviderTypes[0];

    /**
     * controller init
     */
    function onInit() {
      if ($ctrl.state.editMode) {
        _getHyperflexSource($state.params.id);
      } else {
        $ctrl.storageSnapshotSourceReady = true;
      }
    }

    /**
     * when in edit mode get hyperflex source
     * @param    {Number}    id    id of source being edited
     */
    function _getHyperflexSource(id) {
      SourceService.getSources({
        onlyReturnOneLevel: true,
        entityId: id
      }).then(function getSourceSuccess(data) {
        if (data && data.entityHierarchy) {
          assign($ctrl.storageSnapshotSource, {
            entity: data.entityHierarchy.entity,
            entityInfo: data.entityHierarchy.registeredEntityInfo.connectorParams,
          });
        }
      }, evalAJAX.errorMessage)
      .finally(function() {
        $ctrl.storageSnapshotSourceReady = true;
      });
    }

    /**
     * when submiting form register/update the source
     *
     * @param    {Object}    form    form object
     */
    function registerStorageSnapshot(form) {
      if (form.$invalid) {
        return;
      }

      var actionFn = $ctrl.state.editMode ?
        SourceService.updateSource : SourceService.createSource;

      $ctrl.state.submitting = true;

      actionFn($ctrl.storageSnapshotSource)
        .then(function createSuccess(source) {
          if ($ctrl.state.inModal) {
            return $uibModalInstance.close(source);
          }

          $state.go('sources-new', {
            scrollToEnvironment: ENV_TYPE_CONVERSION[source.entity.type],
          });
        }, evalAJAX.errorMessage)
        .finally(function createFinally() {
          $ctrl.state.submitting = false;
        });
    }

    /**
     * cancel / exit
     */
    function cancel() {
      if ($ctrl.state.inModal) {
        $uibModalInstance.dismiss('user.cancel');

        return;
      }

      $state.go('sources-new');
    }
  }
})(angular);