/**
 * Recovery action enum (should match GET recoveries API recoveryAction filter enum).
 */
export enum RecoveryAction {
  CloneAppView = 'CloneAppView',
  CloneVMs = 'CloneVMs',
  ConvertToPst = 'ConvertToPst',
  DownloadFilesAndFolders = 'DownloadFilesAndFolders',
  DownloadChats = 'DownloadChats',
  DownloadTeamsPosts = 'DownloadTeamsPosts',
  InstantVolumeMount = 'InstantVolumeMount',
  RecoverApps = 'RecoverApps',
  RecoverAurora = 'RecoverAurora',
  RecoverMailbox = 'RecoverMailbox',
  RecoverMailboxCSM = 'RecoverMailboxCSM',
  RecoverMails = 'RecoverMails',
  RecoverFiles = 'RecoverFiles',
  MountVolumes = 'MountVolumes',
  RecoverMsGroup = 'RecoverMsGroup',
  RecoverMsGroupDocuments = 'RecoverMsGroupDocuments',
  RecoverMsTeam = 'RecoverMsTeam',
  RecoverNamespaces = 'RecoverNamespaces',
  RecoverNasVolume = 'RecoverNasVolume',
  RecoverObjects = 'RecoverObjects',
  RecoverOneDrive = 'RecoverOneDrive',
  RecoverOneDriveCSM = 'RecoverOneDriveCSM',
  RecoverOneDriveDocuments = 'RecoverOneDriveDocuments',
  RecoverPublicFolders = 'RecoverPublicFolders',
  RecoverSharePoint = 'RecoverSharePoint',
  RecoverSharePointCSM = 'RecoverSharePointCSM',
  RecoverSharePointDocuments = 'RecoverSharePointDocuments',
  RecoverTeamsDocuments = 'RecoverTeamsDocuments',
  RecoverPhysicalVolumes = 'RecoverPhysicalVolumes',
  RecoverRDS = 'RecoverRDS',
  RecoverRDSPostgres = 'RecoverRDSPostgres',
  RecoverAwsDynamoDB = 'RecoverAwsDynamoDB',
  RecoverS3 = 'RecoverS3Buckets',
  RecoverSanVolumes = 'RecoverSanVolumes',
  RecoverSystem = 'RecoverSystem',
  RecoverVApps = 'RecoverVApps',
  RecoverVAppTemplates = 'RecoverVAppTemplates',
  RecoverVmDisks = 'RecoverVmDisks',
  RecoverVMs = 'RecoverVMs',
  RecoverSfdcObjects = 'RecoverSfdcObjects',
  RecoverSfdcRecords = 'RecoverSfdcRecords',
  RecoverSfdcOrg = 'RecoverSfdcOrg',
  RecoverExchangeDbs = 'RecoverExchangeDbs',
  RecoverSanGroup = 'RecoverSanGroup',
  RecoverAzureSQL = 'RecoverAzureSQL',
  RecoverAzureEntraID = 'RecoverAzureEntraID',
  RecoverMongodbClusters = 'RecoverMongodbClusters',
}
