import { some } from 'lodash-es';
import { merge } from 'lodash-es';
import { isEqual } from 'lodash-es';
import { find } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { assign } from 'lodash-es';
// Directive & config: for persistence st-table & ui-select into local storage
// and the url.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.persistConfig')
    .config(cPersistConfig)
    .directive('cPersist', cPersistFn);

  /**
   * c-persist module config.
   *
   * @method   cPersistConfig
   * @param    {Object}   $provide   The provide service.
   */
  function cPersistConfig($provide) {
    $provide.decorator('stTableDirective', stTableDirective);
    $provide.decorator('stSortDirective', stSortDirective);
  }

  /**
   * st-table decorator to allow sort value sync b/w url and model.
   *
   * @method   stTableDirective
   * @param    {Object}   $delegate      The delegate service.
   * @param    {Object}   $stateParams   The state parameters service.
   * @return   {Object}   The decorated directive.
   */
  function stTableDirective($delegate, $stateParams) {
    var originalLinkFn = $delegate[0].link;

    // deleting the old link fn.
    delete $delegate[0].link;

    // decorating compile fn.
    $delegate[0].compile = function newCompileFn() {
      return {
        pre: stTablePreLinkFn,
        post: stTablePostLinkFn,
      };
    };

    /**
     * pre link function for st-table directive.
     *
     * @method   stTablePreLinkFn
     * @param    {Object}   scope         The scope object.
     * @param    {Object}   element       The element reference.
     * @param    {Object}   attr          The attributes set object.
     * @param    {Object}   stTableCtrl   The st-table controller.
     */
    function stTablePreLinkFn(scope, element, attr, stTableCtrl) {
      // counter used to maintain unique reference of stSortApi.
      var counter = 0;

      // collect all stSortApi's reference to be used by c-persist to sync
      // url and table state.
      stTableCtrl.cSync = {
        // stSortApi's reference map
        sort: {},

        // cSync API's
        addChildStSort: addChildStSort,
        getTableStateFromUrl: getTableStateFromUrl,
        getDefaultSortParams: getDefaultSortParams,
      };

      /**
       * Returns the default state param for registered stSortApi references
       *
       * @method   getDefaultSortParams
       * @return   {Object}   Returns the default state param object.
       */
      function getDefaultSortParams() {
        var params = {};

        // loop over stSortApi's reference and construct the default params.
        angular.forEach(stTableCtrl.cSync.sort,
          function eachSortInstance(sortApi) {
            params[sortApi.getSearchKey()] = undefined;
          }
        );

        return params;
      }

      /**
       * Adds a child stSortApi reference into collection for later use &
       * return the fn which can will remove the added stSortApi reference.
       *
       * @method   addChildStSort
       * @param    {Object}     sortApi   The st sort api reference
       * @return   {function}   The fn which can will remove the added
       *                        stSortApi reference.
       */
      function addChildStSort(sortApi) {
        var instance = counter++;

        stTableCtrl.cSync.sort[instance] = sortApi;

        /**
         * When called will remove the added stSortApi reference from the
         * collection.
         *
         * @method   removeAddedStSort
         */
        return function removeAddedStSort() {
          delete stTableCtrl.cSync.sort[instance];
        };
      }

      /**
       * Returns the table state from url $stateParams.
       *
       * @method   getTableStateFromUrl
       * @return   {Object}   The table state from url $stateParams.
       */
      function getTableStateFromUrl() {
        var tableState = {
          sort: {},
          pagination: { start: 0 },
        };

        // loop over stSort collection and get table state object.
        Object.keys(stTableCtrl.cSync.sort).some(
          function eachSortInstance(sortKey) {
            var sortApi = stTableCtrl.cSync.sort[sortKey];
            var searchVal = $stateParams[sortApi.getSearchKey()];

            // use searchVal if it is boolean else reject.
            if (typeof searchVal === 'boolean') {
              tableState = {
                sort: {
                  predicate: sortApi.getPredicates(),
                  reverse: searchVal,
                },

                // keep sortApi reference for further use.
                sortApi: sortApi,
              };

              return true;
            }
          }
        );

        return tableState;
      }
    }

    /**
     * post link function for st-table directive.
     *
     * @method   stTablePreLinkFn
     * @param    {Object}   scope         The scope object.
     * @param    {Object}   element       The element reference.
     * @param    {Object}   attr          The attributes set object.
     * @param    {Object}   stTableCtrl   The st-table controller.
     */
    function stTablePostLinkFn(scope, element, attr, stTableCtrl) {
      var initTableStateFromUrl = stTableCtrl.cSync.getTableStateFromUrl();

      stTableCtrl.cSync.initBySearch =
        Object.keys(initTableStateFromUrl.sort).length !== 0;
      stTableCtrl.cSync.initTableStateFromUrl = initTableStateFromUrl;

      originalLinkFn.apply(this, arguments);
    }

    return $delegate;
  }

  /**
   * st-sort decorator to allow sort value sync b/w url and model.
   *
   * @method   stSortDirective
   * @param    {Object}     $delegate      The delegate service.
   * @param    {Object}     $state         The state service object.
   * @param    {Object}     $stateParams   The state parameters service object.
   * @param    {Function}   $parse         The parse service function.
   * @param    {Function}   $timeout       The timeout service function.
   * @return   {Object}     The decorated directive.
   */
  function stSortDirective($delegate, $state, $stateParams, $parse, $timeout) {
    var originalLinkFn = $delegate[0].link;

    // deleting the old link fn.
    delete $delegate[0].link;

    // decorating compile fn.
    $delegate[0].compile = function newCompileFn() {
      return {
        pre: angular.noop,
        post: stSortPostLinkFn,
      };
    };

    /**
     * post link function for st-sort directive.
     *
     * @method   stSortPostLinkFn
     * @param    {Object}   scope         The scope object.
     * @param    {Object}   element       The element reference.
     * @param    {Object}   attr          The attributes set object.
     * @param    {Object}   stTableCtrl   The st-table controller.
     */
    function stSortPostLinkFn(scope, element, attr, stTableCtrl) {
      var cSyncApi = {
        updateUrl: updateUrl,
        getSearchKey: getSearchKey,
        getPredicates: getPredicates,
        getSearchValue: getSearchValue,
        isDefault: isDefault,
      };
      var getter = $parse(attr.stSort);
      var removeAddedStSort = stTableCtrl.cSync.addChildStSort(cSyncApi);

      // remove the added stSortApi reference on scope destroy.
      scope.$on('$destroy', function doCleanUpOnDestroy() {
        removeAddedStSort();
      });

      // on click cache the clicked stSortApi reference for further use.
      element.on('click', function handleOnClick() {
        stTableCtrl.cSync.selectedSort = cSyncApi;
      });

      /**
       * update the url search param with st-sort order value.
       *
       * @method   updateUrl
       */
      function updateUrl() {
        var params = stTableCtrl.cSync.getDefaultSortParams();
        var searchKey = getSearchKey();
        var sortOrder = stTableCtrl.tableState().sort.reverse;

        // set url only when attribute st-sort is present.
        if (getPredicates()) {
          // set only valid sort order in state prams.
          if (typeof sortOrder === 'boolean') {
            params[searchKey] = sortOrder;
          }

          // goto current state with selected item in the url.
          $state.go('.', assign({}, $stateParams, params));
        }
      }

      /**
       * Return the evaluated c-sync-search-key attribute value.
       *
       * @method   getSearchKey
       * @return   {String}   The evaluated c-sync-search-key attribute value.
       */
      function getSearchKey() {
        return scope.$eval(attr.cSyncSearchKey);
      }

      /**
       * Return the evaluated st-sort expression value used as a predicates
       * for table sorting.
       *
       * @method   getPredicates
       * @return   {String}   An evaluated value for attribute st-sort.
       */
      function getPredicates() {
        return (angular.isFunction(getter(scope)) ||
          angular.isArray(getter(scope)) ? getter(scope) : attr.stSort);
      }

      /**
       * Return the search value from the $stateParams.
       *
       * @method   getSearchValue
       * @return   {Any}   The search value from the $stateParams.
       */
      function getSearchValue() {
        return $stateParams[getSearchKey()];
      }

      /**
       * Returns true, if the given sort is default sort with the attribute -
       * c-persist-sort-default="true"
       *
       * @method   isDefault
       * @return   {Boolean | undefined}   True, if default sort.
       */
      function isDefault() {
        return attr.cPersistSortDefault;
      }

      // call for original post link function.
      originalLinkFn.apply($delegate[0], arguments);
    }

    return $delegate;
  }

  /**
   * @ngdoc directive
   * @name cPersist
   *
   * @description
   * This directive provide a means of persisting the config state of
   * various ui components in local storage based on attribute called
   * 'name'.
   *
   * @example
   * <div st-table="myTable" c-persist="myTable"></div>
   *
   * @example
   * <!-- fallback on stTable name as persist key -->
   * <div st-table="myTable" c-persist></div>
   *
   * @example
   * <!--
   *  set c-persist-sort-default instead of st-sort for compatibility with
   *  c-persist
   * -->
   * <div st-table="myTable" c-persist>
   *  <td c-persist-sort-default="true" st-sort="name"></td>
   * </div>
   */
  function cPersistFn(_, $state, $stateParams, $interval, cUtils,
    cPersistConfigService) {

    return {
      require: ['?^stTable', '?uiSelect'],
      restrict: 'A',
      link: linkFn,
    };

    // the link function c-persist directive
    function linkFn(scope, element, attr, ctrls) {
      var stTableCtrl = ctrls[0];
      var uiSelectCtrl = ctrls[1];

      if (stTableCtrl && !uiSelectCtrl) {
        stTableLinkFn(scope, element, attr, stTableCtrl);
      }

      if (uiSelectCtrl) {
        uiSelectLinkFn(scope, element, attr, uiSelectCtrl, stTableCtrl);
      }
    }

    /**
     * Link function for st-table case for c-persist
     *
     * @method   stTableLinkFn
     * @param    {Object}   scope          The scope object
     * @param    {Object}   element        The element
     * @param    {Object}   attr           The attributes object
     * @param    {Object}   uiSelectCtrl   The st-table controller
     */
    function stTableLinkFn(scope, element, attr, stTableCtrl) {
      var key = _getKey('stTable', attr.cPersist || attr.stTable);
      var originalPipeFn = stTableCtrl.pipe;
      var tableState;
      var savedState = cPersistConfigService.get(key);
      var silentLocationChange = false;

      // load the table state with saved state if present.
      if (savedState) {
        tableState = stTableCtrl.tableState();

        // don't load the table state from local storage if loading the table
        // state from search param.
        if (!stTableCtrl.cSync.initBySearch) {
          assign(tableState.sort, savedState.sort || {});

          // Save the selected sort to update the url.
          stTableCtrl.cSync.selectedSort = find(stTableCtrl.cSync.sort,
            function findSortKey(sortKey) {
              return sortKey.getPredicates() === tableState.sort.predicate;
            });
        }

        if (savedState.pagination.hasOwnProperty('disabled')) {
          tableState.pagination.disabled = savedState.pagination.disabled;
        }
      }

      // loading the table state from url.
      if (stTableCtrl.cSync.initBySearch) {
        merge(
          stTableCtrl.tableState(),
          stTableCtrl.cSync.initTableStateFromUrl
        );

        // cache the selected soring api column.
        stTableCtrl.cSync.selectedSort =
          stTableCtrl.cSync.initTableStateFromUrl.sortApi;

        // prevent this model change to update the state change.
        silentLocationChange = true;
      } else if (!savedState || isEmpty(savedState.sort)) {
        // If saved state doesn't exist, then set the default sort.
        var tempTableState = {
          sort: {},
          pagination: { start: 0 },
        };

        some(stTableCtrl.cSync.sort,
          function eachSortInstance(sortApi) {

            // Check if the given sort is the default sort
            // (c-persist-sort-default="true")
            if (sortApi.isDefault()) {
              tempTableState = {
                sort: {
                  predicate: sortApi.getPredicates(),
                  reverse: sortApi.getSearchValue(),
                }
              };

              return true;
            }
          }
        );

        merge(
          stTableCtrl.tableState(),
          tempTableState
        );
      }

      // decorate the pipe function to persist sort state on change.
      stTableCtrl.pipe = function newPipeFn(skipStoring) {
        // skip storing the table state when changes are from url i.e. when
        // skipStoring is true.
        if (!skipStoring) {
          // persist state in the local storage
          cPersistConfigService.set(key, stTableCtrl.tableState());
        }

        // don't update the url when this flag is set.
        if (silentLocationChange) {
          silentLocationChange = false;
        } else if(stTableCtrl.cSync.selectedSort &&
          stTableCtrl.cSync.selectedSort.updateUrl) {
          // updating the url with selected sorting column value.
          stTableCtrl.cSync.selectedSort.updateUrl();
        }

        // continue with original st-table pipe fn.
        return originalPipeFn();
      };

      // watch for state param change and update the table state accordingly.
      scope.$watch(
        function getStateParams() { return $stateParams; },
        function onStateParamChanges(newValue, oldValue) {
          if (newValue !== oldValue) {
            var tableState = stTableCtrl.tableState();
            var tableStateFromUrl = stTableCtrl.cSync.getTableStateFromUrl();

            // merge table state from state from url.
            merge(tableState, tableStateFromUrl);

            // prevent model change to update the url once again.
            silentLocationChange = true;
            stTableCtrl.pipe(true);
          }
        },

        // use deep comparison for comparison.
        true
      );
    }

    /**
     * Link function for ui-select case for c-persist
     *
     * @method   uiSelectLinkFn
     * @param    {Object}   scope          The scope object
     * @param    {Object}   element        The element
     * @param    {Object}   attr           The attributes object
     * @param    {Object}   uiSelectCtrl   The ui-select controller
     * @param    {Object}   stTableCtrl    The st-table controller
     */
    function uiSelectLinkFn(scope, element, attr, uiSelectCtrl, stTableCtrl) {
      var key = _getKey('uiSelect', attr.cPersist);
      var interval;
      var initBySearch = false;
      var originalSelectFn = uiSelectCtrl.select;
      var silentLocationChange = false;
      var noPersist = attr.hasOwnProperty('noPersist');
      var cSyncOnInit = attr.hasOwnProperty('cSyncOnInit') ?
        scope.$eval(attr.cSyncOnInit) : angular.noop;

      // try reading state from search query of the url.
      var savedState = _readSearchQueryValue(scope, attr);

      // use the saved state from local storage when state not found in url.
      if (!isEmpty(savedState) || stTableCtrl.cSync.initBySearch) {
        initBySearch = true;
        silentLocationChange = true;
        stTableCtrl.cSync.initBySearch = true;
      } else if (!noPersist) {
        savedState = cPersistConfigService.get(key);
      }

      // ui-select has a child directive ui-select-choices which has to load
      // first before model on ui-select can be set so poll to check that.
      if (!isEmpty(savedState)) {
        interval = $interval(function poll() {
          if (uiSelectCtrl.parserResult) {
            uiSelectCtrl.select(savedState, undefined, undefined, initBySearch);
            cSyncOnInit();

            $interval.cancel(interval);
          }
        }, 200);
      } else {
        cSyncOnInit();
      }

      // wrap original ui-select controller to capture when user had selected
      // item by clicking ui-select.
      uiSelectCtrl.select =
        function cPersistProxySelect(items, skipFocusser, $event, skipStoring) {
          // if setting multiple items then loop over items and select each item
          if (angular.isArray(items)) {
            items.forEach(function eachItem(item) {
              originalSelectFn(item, true, $event);
            });
          } else {
            originalSelectFn(items, skipFocusser, $event);
          }

          // persist state in the local storage.
          if (!skipStoring && !noPersist) {
            cPersistConfigService.set(key, uiSelectCtrl.selected);
          }
        };

      // watch for model change and save them in local storage and update the
      // url accordingly.
      // We cant use $viewChangeListeners here since ui-select does view value
      // parsing and formatting before setting value in the model but for
      // persisting we want the whole selected item.
      scope.$watch(
        function getModelValue() { return uiSelectCtrl.selected; },
        function onUiSelectModelChange(newValue, oldValue) {
          if (newValue && !isEqual(newValue, oldValue)) {
            // update local store when user updates the model externally.
            if (attr.hasOwnProperty('cPersistOnModelChange') && !noPersist) {
              // persist state in the local storage
              cPersistConfigService.set(key, uiSelectCtrl.selected);
            }

            // prevent model update
            silentLocationChange = true;

            // goto current state with selected item in the url.
            $state.go('.', _getSearchParams(scope, attr));
          }
        },
        // force deep equality check
        true
      );

      // watch for search query value changes from thew url.
      scope.$watch(
        function searchValue() {
          // check does search key present in the url.
          return $stateParams[scope.$eval(attr.cSyncSearchKey)];
        },
        function onSearchValChange(newValue, oldValue) {
          var selectedValue = _readSearchQueryValue(scope, attr);

          if (newValue && selectedValue && !isEqual(newValue, oldValue)) {
            // don't update the model if url changed because of model changes.
            if (silentLocationChange) {
              silentLocationChange = false;
              return;
            }

            uiSelectCtrl.select(
              selectedValue,
              undefined,
              undefined,
              // to prevent model watch above to update modal again
              true
            );
          }
        },
        // force deep equality check
        true
      );
    }

    /**
     * Returns the key to be used to store an item in the local storage.
     *
     * @method   _getKey
     * @param    {String}   prefix   The control type
     * @param    {String}   suffix   The control name
     * @return   {String}   The key.
     */
    function _getKey(prefix, suffix) {
      return [prefix, $state.current.name, suffix].join('.');
    }

    /**
     * Returns the new search params with selected search key/values.
     *
     * @method   _getSearchParams
     * @param    {Object}   scope   The scope object.
     * @param    {Object}   attr    The element attributes map object.
     * @return   {Object}   Returns a new set of search parameters with selected
     *                      ui-select value.
     */
    function _getSearchParams(scope, attr) {
      var params = {};

      params[scope.$eval(attr.cSyncSearchKey)] =
        scope.$eval(attr.cSyncSearchValue);

      return assign({}, $stateParams, params);
    }

    /**
     * Returns the item to be selected from the URL search query values.
     *
     * This fn will evaluate the c-sync-search-selected attribute in current
     * scope with extra params like $lodash, $ng, $stateParams and evaluated
     * value of the c-sync-search-key attribute.
     *
     * c-sync-search-selected is used to know selected item in the list of items
     * over which ui-select is iterating to select one of the selected item from
     * iterating list.
     *
     * @example
       <ui-select
          ng-model="filters.jobTypeFilter"
          c-persist="jobTypeFilter"
          c-sync-search-key="'protected-object'"
          c-sync-search-key-value="$select.selected._kVal"
          c-sync-search-selected="

            // write your own logic to find the selected item in the iterating
            // list.
            $lodash.find(jobTypesList, {
              '_kVal': $stateParams[$cSyncSearchKey]
            })
          ">
          <ui-select-choices
            repeat="type.enum as type in jobTypesList track by $index">
          </ui-select-choices>
        </ui-select>
     *
     *
     * @method   _readSearchQueryValue
     * @param    {Object}   scope   The scope object.
     * @param    {Object}   attr    The element attributes map object.
     * @return   {Any}      Return the item to be selected from URL search query
     *                      values.
     */
    function _readSearchQueryValue(scope, attr) {
      var cSyncSearchKey = scope.$eval(attr.cSyncSearchKey);

      return scope.$eval(attr.cSyncSearchSelected, {
        _: _,
        cUtils: cUtils,
        $stateParams: $stateParams,
        $cSyncSearchKey: cSyncSearchKey,
        $cSyncSearchValue: $stateParams[cSyncSearchKey],
      });
    }
  }
}(angular));
