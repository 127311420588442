import { assign } from 'lodash-es';
// module: Cluster-Connect for Dark Site

;(function (angular) {
  'use strict';

  angular
    .module('C.clusterClaim')
    .config(configFn)
    .component('clusterConnectDarkSite', {
      controller: 'clusterConnectDarkSiteController',
      templateUrl: 'app/cluster-connect/cluster-connect-dark-site.html',
    })
    .controller('clusterConnectDarkSiteController',
      clusterConnectDarkSiteControllerFn);

  /**
   * Config Fn for this module.
   */
  function configFn($stateProvider) {
    $stateProvider
      .state('cluster-connect-dark-site', {
        title: 'Cluster Connect Dark Site',
        url: '^/cluster-connect-dark',
        help: 'cluster_connect_dark_site',
        component: 'clusterConnectDarkSite',
        canAccess: 'true',
      });
  }

  /**
   * Controller function
   */
  function clusterConnectDarkSiteControllerFn(_, SlideModalService, $rootScope,
    ClusterService) {
    var $ctrl = this;

    assign($ctrl, {
      //proprties
      config: {
        mainDescription: 'clusterClaim.manualLicensing',

        // set clusterId from root scope to allow copying
        clusterId : ClusterService.clusterInfo.id,
        steps: [
          {
            img: 'icn-helios-blue',
            description: 'clusterConnect.darkSite.step1'
          },
          {
            img: 'icn-config-file',
            description: 'clusterConnect.darkSite.step2'
          },
          {
            img: 'icn-upload',
            description: 'clusterClaim.submitLicensingInformation',
            button: {
              text: 'submit',
              callback: getLicenseKey
            }
          }
        ],
        skipUpload: ClusterService.skipLicensing,
      },
    });

    /**
     * This method open the modal to either upload modal to either
     * get the license file or license key
     *
     * @method   getLicenseKey
     *
     * @returns {Object} Promise to resolve when modal is closed
     */
    function getLicenseKey() {
      var modalConfig = {
        autoHeight: true,
        resolve: {
          innerComponent: 'uploadLicenseModal',
          actionButtonKey: false,
          closeButtonKey: false,
        },
      };
      return SlideModalService.newModal(modalConfig);
    }
  }
})(angular);
