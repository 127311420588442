import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const clusterAccess = (ctx: StateAccessContext) => ctx.STORAGE_VIEW &&
  ctx.FEATURE_FLAGS['ngView'] && getConfigByKey(ctx.irisContext, 'views.features.allow', true);
const storageAccess = (ctx: StateAccessContext) => ctx.STORAGE_VIEW && getConfigByKey(ctx.irisContext, 'views.features.allow', true);
const storageModify = (ctx: StateAccessContext) => ctx.STORAGE_MODIFY && getConfigByKey(ctx.irisContext, 'views.features.allow', true);
const showGlobalSettings = (ctx: StateAccessContext) => (ctx.CLUSTER_VIEW || ctx.STORAGE_VIEW || ctx.NIS_VIEW) &&
  (!ctx.isTenantUser || !ctx.FEATURE_FLAGS['irisExecDisableTenantSubnetAllowlist'])
 ;

const fileServiceOverviewAccess = (ctx: StateAccessContext) =>
  clusterAccess(ctx) &&
  ctx.FEATURE_FLAGS.ngFileServicesDashboardV2 &&
  !ctx.isTenantUser &&
  !ctx.isHeliosTenantUser;
const viewTemplatesTabAccess = (ctx: StateAccessContext) =>
  clusterAccess(ctx) && ctx.FEATURE_FLAGS.ngViewTemplates &&
  ctx.FEATURE_FLAGS.ngViewTemplatesTab;
const viewMigrationTabAccess = (ctx: StateAccessContext) =>
  clusterAccess(ctx) && ctx.FEATURE_FLAGS.ngS3ViewMigrationTab;
export const ViewsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng-views.**',
      url: '^/platform/views',
      loadChildren: () => import('./views.module').then(m => m.ViewsModule)
    },
    {
      name: 'ng-templates.**',
      url: '^/platform/templates',
      loadChildren: () => import('./views.module').then(m => m.ViewsModule)
    },
    {
      name: 'view.**',
      url: '^/platform/nfs/view/{jobId}/{viewName}',
      loadChildren: () => import('./views.module').then(m => m.ViewsModule)
    },
    {
      name: 'file-services-consumption-details.**',
      url: '/dashboards/file-services/consumption',
      loadChildren: () => import('./views.module').then(m => m.ViewsModule)
    },
    {
      name: 'file-services-performance-details.**',
      url: '/dashboards/file-services/performance',
      loadChildren: () => import('./views.module').then(m => m.ViewsModule)
    },
    {
      name: 'file-services-client-distribution-details.**',
      url: '/dashboards/file-services/client-distribution',
      loadChildren: () => import('./views.module').then(m => m.ViewsModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'file-services-client-distribution-details': fileServiceOverviewAccess,
      'file-services-client-distribution-details.clients': fileServiceOverviewAccess,
      'file-services-client-distribution-details.nodes': fileServiceOverviewAccess,
      'file-services-consumption-details': fileServiceOverviewAccess,
      'file-services-consumption-details.total': fileServiceOverviewAccess,
      'file-services-consumption-details.growth': fileServiceOverviewAccess,
      'file-services-performance-details': fileServiceOverviewAccess,
      'ng-views': clusterAccess,
      'ng-templates': clusterAccess,
      'ng-templates.modify': clusterAccess,
      'ng-templates.modify-new': clusterAccess,
      'ng-views.views': clusterAccess,
      'ng-views.views.all-views': clusterAccess,
      'ng-views.views.all-shares': clusterAccess,
      'ng-views.views.global-settings': showGlobalSettings,
      'ng-views.views.replicated-views': clusterAccess,
      'ng-views.views.templates': viewTemplatesTabAccess,
      'ng-views.views.migration': viewMigrationTabAccess,
      'ng-views.modify': storageModify,
      'ng-views.modify-new': storageModify,
      'ng-views.share-detail': storageAccess,
      'ng-views.view-detail': storageAccess,
      'ng-views.view-detail.consumption': storageAccess,
      'ng-views.view-detail.quotas': storageAccess,
      'ng-views.view-detail.trend': storageAccess,
      'ng-views.view-detail.settings': storageAccess,
      'ng-views.view-detail.shares': storageAccess,
      'ng-views.view-detail.protection': storageAccess,
      'ng-views.view-disaster-recovery-runs': clusterAccess,
      'ng-views.disaster-recoveries': clusterAccess,
    };
  }
};
