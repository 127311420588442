import { assign } from 'lodash-es';
import {
  hypervisorSources,
  nasSources,
  sqlApplicationSources,
  storageSnapshotProvidersList,
} from 'src/app/shared/constants';
import { hasSourceModifyPrivilege } from 'src/app/shared/helper-utils';

// MODULE: Sources
;(function(angular, undefined) {
  'use strict';

  angular.module('C.sources',
    ['C.db-manager', 'smoothScroll', 'C.office365Manager',
    'C.storageSnapshotProvider', 'C.sourcesUtil'])
    .config(configFn);

  function configFn($stateProvider) {
    var viewAccess = 'PROTECTION_VIEW';
    var modifyAccess = (type) => (ctx) => hasSourceModifyPrivilege(ctx, type);

    const canAccessHypervisor = (ctx) => {
      return modifyAccess('hypervisor') && ctx.canAccessSomeEnvItems(hypervisorSources);
    };

    const canAccessGenerator = (env) => (ctx) => {
      // removing the leading `k` character to make it compatible with hasSourceModifyPrivilege().
      const key = env.slice(1).toLowerCase();
      return modifyAccess(key) && ctx.workflow.backupAndRecovery[env];
    };

    const canAccessSql = (ctx) => {
      return modifyAccess('sql') && ctx.canAccessSomeEnvItems(sqlApplicationSources);
    };

    const canAccessOracle = (ctx) => {
      return modifyAccess('oracle') && ctx.workflow.backupAndRecovery.kOracle.host.kPhysical;
    };

    const canAccessNas = (ctx) => {
      return modifyAccess('nas') && ctx.canAccessSomeEnvItems(nasSources);
    };

    const canAccessStorageSnapshotProviders = (ctx) => {
      return modifyAccess('storageSnapshot') && ctx.canAccessSomeEnvItems(storageSnapshotProvidersList);
    };

    // object to resolve uibModalInstance for state controller use
    var fauxResolves = {
      $uibModalInstance: function resolveUibModalInstance() {
        return false;
      },
      envType: function() { return; },
      envTypes: function() { return; },
      entityType: function() { return; },

      // PageConfig is used to inject properties either through state or modal
      // resolvers.
      pageConfig: function() { return {}; },

      connectionId: function() { return; },
    };

    $stateProvider
      .state('sources-new', {
        url: '/protection/sources',
        allClustersSupport: {
          singleClusterState: 'source-new',
          allClustersState: 'source-new',
          globalContext: true,
        },
        params: {
          // If specified, this environment will be auto scrolled to on load.
          scrollToEnvironment: '',

          // If true value provided, state will refresh the list of sources
          // after a delay. This allows sources to load quickly, but new
          // information to be loaded after the fact (for instance, if
          // registration is known or likely to be in progress).
          delayedRefresh: false,
        },
        help: 'protection_sources',
        title: 'Sources Management',
        canAccess: viewAccess,
        templateUrl: 'app/protection/sources/sources-new.html',
        controller: 'sourcesControllerNew as $ctrl',
      })
      .state('hypervisor-new', {
        url: '/protection/sources/new/hypervisor',
        help: 'protection_sources_hypervisor',
        title: 'Register HyperVisor',
        canAccess: canAccessHypervisor,
        parentState: 'sources-new',
        templateUrl: 'app/protection/sources/modify/hypervisor/hypervisor.html',
        controller: 'hypervisorModifyController',
        controllerAs: '$ctrl',
        resolve: fauxResolves,
      })
      .state('hypervisor-edit', {
        url: '/protection/sources/edit/hypervisor/{id}',
        help: 'protection_sources_hypervisor',
        title: 'Edit HyperVisor',
        canAccess: canAccessHypervisor,
        params: {
          id: { type: 'string' },
        },
        templateUrl: 'app/protection/sources/modify/hypervisor/hypervisor.html',
        controller: 'hypervisorModifyController',
        controllerAs: '$ctrl',
        resolve: fauxResolves,
        parentState: 'sources-new',
      })
      .state('physical-new', {
        url: '/protection/sources/new/physical?{forceRegister}',
        params: {
          parentId: undefined,
          forceRegister: { type: 'string', _persistOnImpersonation: true, },
        },
        help: 'protection_sources_physical',
        title: 'Register Physical Servers',
        canAccess: canAccessGenerator('kPhysical'),
        parentState: 'sources-new',
        templateUrl: 'app/protection/sources/modify/physical/physical.html',
        controller: 'physicalModifyController',
        resolve: fauxResolves,
      })
      .state('physical-edit', {
        url: '/protection/sources/edit/physical/{id}',
        params: {
          parentId: undefined,
          env: undefined,
          id: { type: 'string' },
        },
        help: 'protection_sources_physical',
        title: 'Edit Windows Physical Servers',
        canAccess: canAccessGenerator('kPhysical'),
        templateUrl: 'app/protection/sources/modify/physical/physical.html',
        controller: 'physicalModifyController',
        resolve: fauxResolves,
        parentState: 'sources-new',
      })
      .state('sql-cluster-new', {
        url: '/protection/sources/new/sql-cluster?{forceRegister}',
        params: {
          parentId: undefined,
          forceRegister: { type: 'string', _persistOnImpersonation: true, },
        },
        help: 'protection_sources_sqlcluster_register',
        title: 'Register Windows Physical SQL Cluster',
        canAccess: canAccessSql,
        parentState: 'sources-new',
        templateUrl: 'app/protection/sources/modify/physical/physical.html',
        controller: 'physicalModifyController',
        resolve: fauxResolves,
      })
      .state('sql-cluster-edit', {
        url: '/protection/sources/edit/sql-cluster/{id}',
        params: {
          parentId: undefined,
          id: { type: 'string' },
        },
        help: 'protection_sources_sqlcluster_register',
        title: 'Edit Windows Physical SQL Cluster',
        canAccess: canAccessSql,
        templateUrl: 'app/protection/sources/modify/physical/physical.html',
        controller: 'physicalModifyController',
        resolve: fauxResolves,
        parentState: 'sources-new',
      })
      .state('oracle-register', {
        url: '/protection/sources/new/oracle-source',
        params: {
          parentId: undefined,

          /**
           * For capturing Physical host when Direct Oracle registration on the
           * same is initiated
           */
          hostName: undefined,

          // Specifies the oracle registration type.
          registrationType: undefined,
        },
        help: 'protection_sources_oracle',
        title: 'Register Oracle Source',
        canAccess: canAccessOracle,
        parentState: 'sources-new',
        templateUrl: 'app/protection/sources/db-manager/oracle-modify.html',
        controller: 'oracleModificationController as $ctrl',
        resolve: fauxResolves,
      })
      .state('oracle-edit', {
        url: '/protection/sources/edit/oracle-source/{id}',
        params: {
          id: { type: 'string' },
          parentId: undefined,

          // Specifies the host name for updating the credentials.
          hostName: undefined,

          // Specifies if source is db authenticated or os.
          isDbAuthenticated: undefined,

          // Specifies the oracle registration type.
          registrationType: undefined,

          // Specifies oracle cluster scan name/vip address used for registration.
          scanName: undefined,
        },
        help: 'protection_sources_oracle',
        title: 'Edit Oracle Source',
        canAccess: canAccessOracle,
        parentState: 'sources-new',
        templateUrl: 'app/protection/sources/db-manager/oracle-modify.html',
        controller: 'oracleModificationController as $ctrl',
        resolve: fauxResolves,
      })
      .state('nas-new', {
        url: '/protection/sources/new/nas',
        params: {
          parentId: undefined,
          nasType: undefined,
        },
        help: 'protection_sources_nas',
        title: 'Register NAS',
        canAccess: canAccessNas,
        parentState: 'sources-new',
        templateUrl: 'app/protection/sources/modify/nas/nas.html',
        controller: 'nasModifyController',
        resolve: fauxResolves,
      })
      .state('nas-edit', {
        url: '/protection/sources/edit/nas/{id}',
        params: {
          id: { type: 'string' },
          parentId: undefined,
        },
        help: 'protection_sources_nas',
        title: 'Edit NAS',
        canAccess: canAccessNas,
        templateUrl: 'app/protection/sources/modify/nas/nas.html',
        controller: 'nasModifyController',
        resolve: fauxResolves,
        parentState: 'sources-new',
      })
      .state('office365-register', {
        url: '/protection/sources/new/microsoft365',
        params: {},
        help: 'protection_sources_office',
        title: 'Register Microsoft 365 Source',
        canAccess: canAccessGenerator('kO365'),
        parentState: 'sources-new',
        component: 'cOffice365Manager',
        resolve: fauxResolves,
      })
      .state('office365-edit', {
        url: '/protection/sources/modify/microsoft365/{id}',
        params: {
          id: { type: 'string' },
          source: {
            type: 'any',
            value: undefined,
          },
        },
        help: 'protection_sources_office',
        title: 'Update Microsoft 365 Source',
        canAccess: canAccessGenerator('kO365'),
        parentState: 'sources-new',
        component: 'cOffice365Manager',
        resolve: assign({}, fauxResolves, {
          pageConfig: /*@ngInject*/
                      function getPageConfig($transition$) {
                        return {
                          source: $transition$.params().source,
                        };
                      },
        }),
      })
      .state('storage-snapshot-new', {
        url: '/protection/sources/new/storage-snapshot',
        params: {
          id: undefined,
        },
        help: 'source_snapshot_provider',
        title: 'Register Storage Snapshot Provider',
        canAccess: canAccessStorageSnapshotProviders,
        parentState: 'sources-new',
        templateUrl:
          'app/protection/sources/storage-snapshot/storage-snapshot.html',
        controller: 'StorageSnapshotProviderController as $ctrl',
        resolve: fauxResolves,
      });
  }

})(angular);
