import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';

import { EventTrackingService } from '../../../../event-tracking.service';
import { HelixIntlService } from '../../../../helix-intl.service';
import { DataFilter, DataFilterValue, ValueFilterSelection } from '../../comparators';
import { FilterDefParams } from '../../filters/filter-def.directive';
import { QuickFilterComponent } from '../../filters/quick-filter/quick-filter.component';
import { TranslateService } from '@ngx-translate/core';

/** Maintains counter for unique id generation. */
let nextId = 0;

@Component({
  selector: 'cog-nested-value-property-filter',
  templateUrl: './nested-value-property-filter.component.html',
  styleUrls: ['./nested-value-property-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NestedValuePropertyFilterComponent implements OnInit, OnDestroy {
  /**
   * The matMenuTriggers in play. Used to close menus on selection.
   */
  @ViewChildren(MatMenuTrigger) triggers: MatMenuTrigger[];

  /**
   * The quick filter component.
   */
  @ViewChild(QuickFilterComponent) quickFilter: QuickFilterComponent;

  /**
   * The filter associated with this component
   */
  @Input() filter: DataFilter<ValueFilterSelection[]>;

  /**
   * Params from `FilterDefDirective` instance.
   */
  @Input() filterDefParams: FilterDefParams;

  /**
   * Whether to show clear button.
   */
  @Input() noClear = false;

  /**
   * Returns current filter values as provided externally.
   */
  @Input() get filterValues(): ValueFilterSelection[] {
    return this.filterValues$.value;
  }

  /**
   * Sets filter values.
   */
  set filterValues(values: ValueFilterSelection[]) {
    this.filterValues$.next(values);
  }

  /**
   * Unique id of the filter, defaults to internally generated id.
   */
  @Input() id = `value-property-filter-${nextId++}`;

  /**
   * Whether to make api call when search input change. Defaults to false.
   */
  @Input() asyncSearch = false;

  /**
   * Do not emit a tracking event if track filters is set to false.
   */
  @Input() trackFilters = false;

  /**
   * EventEmitter to broadcast search filed update to parent component.
   */
  @Output() readonly filterSearchInputChange = new EventEmitter<string>();

  /**
   * Array of mat list options components.
   */
  @ViewChildren(MatListOption) matListOptions: QueryList<MatListOption>;

  /**
   * Array of mat list options elements.
   */
  @ViewChildren(MatListOption, {read: ElementRef}) matListOptionsElements: QueryList<ElementRef>;

  /**
   * Behavior subject for updating filter values.
   */
  private readonly filterValues$ = new BehaviorSubject<ValueFilterSelection[]>([]);

  /*
   * Determines when searching should be displayed for the given filter. Once there are more filter values than this
   * number the string search will be available for the filter.
   */
  filterValuesSearchThreshold = 10;

  /**
   * A search form control for filtering the list based on string input.
   */
  readonly stringSearchControl = new UntypedFormControl('');

  /**
   * Observable stream of filter values, filtered based on the stringSearchControl FormControl value.
   */
  displayedValues$: Observable<ValueFilterSelection[]>;

  /**
   * A selection model to track items which are selected.
   */
  selectionModel = new SelectionModel<ValueFilterSelection>(false);

  /**
   * Clean up observable subscriptions when component is destroyed.
   */
  private readonly destroy$ = new Subject();


  /**
   * Listen to enter keydown to select a nested filter.
   */
  @HostListener('document:keydown.enter', ['$event.target']) onKeydownEnter(target: HTMLElement) {
    // Get the intended target option for selection.
    const targetOption = target.querySelector('mat-list-option');

    if (!targetOption) {
      return;
    }

    // Get the target mat list option component from the element.
    const optionIndex = Array.from(this.matListOptionsElements).findIndex(
      optionElement => optionElement.nativeElement === targetOption
    );
    const option = this.matListOptions.get(optionIndex);

    if (!option) {
      // Nothing to select.
      return;
    }

    if (this.isSelected(option.value)) {
      // If the option is already selected, unselect it.
      this.clearFilters();
    } else {
      // Otherwise select the option.
      this.clickHandler(option.value);
    }
  }

  constructor(public intlSvc: HelixIntlService,
    private eventTrackingService: EventTrackingService,
    private translate: TranslateService) {}

  /**
   * Syncs the selection model with the filter
   */
  ngOnInit() {
    this.filter.currentValue$.pipe(takeUntil(this.destroy$)).subscribe(newFilterValue => {
      this.setSelection(newFilterValue, this.filterValues);
    });

    // Handle cases when this.filterValues$ (all available filter options) is set
    // after this.filter (selected filter option) is set.
    this.filterValues$.pipe(takeUntil(this.destroy$)).subscribe(filterValues => {
      if (filterValues?.length && this.filter?.currentValue$.value && !this.selectionModel.selected?.length) {
        this.setSelection(this.filter.currentValue$.value, filterValues);
      }
    });

    this.filter.clear$.pipe(takeUntil(this.destroy$)).subscribe(this.clearFilters);
    this.filter.apply$.pipe(takeUntil(this.destroy$)).subscribe(this.applyFilters);

    const searchInputValue$ = this.stringSearchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    this.displayedValues$ = combineLatest([this.filterValues$, searchInputValue$]).pipe(map(([values, search]) => {

      const virtualMachinesLabel = this.translate.instant('enum.envGroup.longName.vms');
      const vcdLabel = this.translate.instant('enum.environment.kVCD');

      // Remove "VCD" from subItems of the "Virtual Machines" object using existing enums
      values = values.map(value => {
        if (
          value.label === virtualMachinesLabel &&
          value.subItems.some(subItem => subItem.label === vcdLabel)
        ) {
          // Only create a new object if "VCD" is present
          return {
            ...value,
            subItems: value.subItems.filter(
              subItem => subItem.label !== vcdLabel
            )
          };
        }
        return value; // Return the original object if no changes are needed
      });

      if (!this.asyncSearch) {
        // If doing client-side filtering, just filter values to match search string.
        return values.filter(value => value.label.toLowerCase().includes(search.toLowerCase()));
      } else {
        // If doing server-side filtering, return values, which is passed into component from API response.
        return values;
      }
    }));

    // Subscribe to the search input and emit filterSearchInputChange event only when asyncSearch is true.
    if (this.asyncSearch) {
      searchInputValue$
        .pipe(takeUntil(this.destroy$))
        .subscribe((searchString: string) => this.filterSearchInputChange.emit(searchString));
    }
  }

  /**
   * Clean up the filter subscription on destroy.
   */
  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Sets selection model for filters.
   *
   * @param newFilterValue  Newly set filter value.
   * @param filterValues    All available filter options.
   */
  setSelection(
    newFilterValue: DataFilterValue<any, ValueFilterSelection[]>,
    filterValues: ValueFilterSelection[]
  ) {
    this.selectionModel.clear();
    if (newFilterValue && newFilterValue.value) {
      const newSelections: ValueFilterSelection[] = [];
      let setNextValue = false;

      newFilterValue.value.find(
        selected => filterValues.find(existingValue => {
          // Check for match of the filter.
          if (existingValue.value === selected.value) {
            if (existingValue !== selected) {
              setNextValue = true;
            }
            newSelections.push(existingValue);
            return true;
          }

          // Check the filters possible subItems for a match.
          for (const subItem of existingValue.subItems || []) {
            if (subItem.value === selected.value) {
              if (subItem !== selected) {
                setNextValue = true;
              }
              newSelections.push(subItem);
              return true;
            }
          }
        })
      );

      // Handle case when the value set is not the same object in the selection.
      if (setNextValue) {
        this.filter.setValue(newSelections);
      } else {
        this.selectionModel.select(...newSelections);
      }
    }
  }

  /**
   * Indicates if provided filter (or any of its children) are actively applied.
   *
   * @param item   Filter item to be evaluated.
   * @returns True if "selected", false otherwise.
   */
  isSelected(item: ValueFilterSelection): boolean {
    return this.selectionModel.isSelected(item) || this.isChildSelected(item);
  }

  /**
   * Indicates if one of the provided filter's children are selected.
   *
   * @param item   Filter item to be evaluated.
   * @returns True if children of the filter are selected, false otherwise.
   */
  isChildSelected(item: ValueFilterSelection): boolean {
    return (item.subItems || []).some(subItem => this.selectionModel.isSelected(subItem));
  }

  /**
   * Handles clicks on a particular filter item.
   *
   * @param item   The filter item that was click.
   */
  clickHandler(item: ValueFilterSelection) {
    this.selectionModel.select(item);

    if (this.trackFilters) {
      this.eventTrackingService.send({
        // NOTE: Intentionally pretending this is the same action as seen in
        // ValuePropertyFilterComponent as theres probably no good reason to
        // distinguish between the two from a track perspective.
        id: 'value-property-list-item',
        properties: {
          name: item.label,
        },
      });
    }

    // Due to its nature, this component should only be used as a quick filter.
    // Apply the filter instantly.
    this.applyFilters();
  }

  /**
   * Applies all filters at once.
   */
  applyFilters = () => {
    const { selected } = this.selectionModel;
    const { value } = this.filter.currentValue$;

    // prevent setting filter value if nothing has changed
    if (value || selected.length > 0) {
      this.filter.setValue(selected);
    }

    // Close menus in a setTimeout, otherwise a click on a mat-menu-item will
    // trigger the opening of its child menu after this code is executed.
    setTimeout(() => {
      this.triggers.forEach(trigger => trigger.closeMenu());
    });
  };

  /**
   * Clears all selected filters.
   */
  clearFilters = () => {
    this.selectionModel.clear();
    this.applyFilters();
  };

  /**
   * Returns sanitized id for given value
   *
   * @param   value   unique value.
   * @param   label   the value's label
   * @return   Id string.
   */
  sanitizeId(value: any, label: string) {
    const itemId = ['object', 'function'].includes(typeof value) ? label : value;
    return `${this.id}-${itemId}`.replace(/\s/g, '');
  }
}
