<cog-page>
  <cog-page-header>
    <cog-page-toolbar>
      <cog-page-title class="page-title">
        <h1>{{ (isSecurityCenterEnabled ? 'dg.postureAdviser' : 'securityAdvisor') | translate }}</h1>
        <cog-icon
          [cogPopover]="securityInfoTemplate"
          color="primary"
          class="info-icon clickable margin-left"
          shape="info_outline"></cog-icon>
      </cog-page-title>

      <cog-page-breadcrumbs>
        <coh-app-breadcrumbs></coh-app-breadcrumbs>
      </cog-page-breadcrumbs>

      <cog-page-actions>
        <button
          [dgHasPermissions]="['SECOPS_MANAGE_POSTURE_ADVISOR', 'DGAAS_MODIFY']"
          cogDataId="perform-scan-button"
          mat-flat-button color="primary"
          (click)="openPerformScanDialog()">
          {{ 'performScan' | translate }}
        </button>
      </cog-page-actions>

      <cog-page-nav>
        <div class="pa-page-tabs">
          <mat-tab-group
            [selectedIndex]="routes.indexOf(route)"
            (selectedIndexChange)="route = routes[$event]; tabSelect.emit(route)">

            <mat-tab *ngFor="let tab of tabs" [label]="tab.name | translate"></mat-tab>
          </mat-tab-group>
        </div>
      </cog-page-nav>

    </cog-page-toolbar>
  </cog-page-header>

  <!-- Feature Info Template-->
  <ng-template #securityInfoTemplate class="margin-top-lg">
    <cog-icon color="primary" class="info-icon-msg" shape="info"></cog-icon>
    <div class="info-template-container">
      <h4 class="title">{{ 'important' | translate }}</h4>
      {{ 'securityAdvisor.infoMsg' | translate }}
    </div>
  </ng-template>

  <ng-container>
    <dg-pa-scores-page
      *ngIf="!route || route === 'scores'"
      (scoreDrilldownClick)="scoreDrilldownClick.emit($event)">
    </dg-pa-scores-page>
    <dg-pa-all-issues *ngIf="route === 'allIssues'"></dg-pa-all-issues>
    <dg-pa-security-rules-page *ngIf="route === 'rules'"
      (ruleDetailClick)="ruleDetailClick.emit($event)"
      (editRuleClick)="editRuleClick.emit($event)"
      (createRuleClick)="createRuleClick.emit()">
    </dg-pa-security-rules-page>
    <dg-pa-scan-results-page *ngIf="route === 'results'"
      (scanResultClick)="scanResultClick.emit($event)"
      (dateRangeChange)="dateRangeChange.emit($event)">
    </dg-pa-scan-results-page>
  </ng-container>
</cog-page>
