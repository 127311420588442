import { flagEnabled, IrisContext, isIbmBaaSEnabled } from '@cohesity/iris-core';

/**
 * Util to check if connector based source registration is enabled for IBM tenant user
 *
 * @param irisContext The current iris context.
 * @returns True if connector based workflow is enabled
 */
export const isConnectorBasedSourceRegistrationEnabled = (irisContext: IrisContext) =>
  isIbmBaaSEnabled(irisContext) &&
  flagEnabled(irisContext, 'connectorBasedSourceRegistrationEnabled') &&
  flagEnabled(irisContext, 'unifiedClusterSourceRegistration');
