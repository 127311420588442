import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AutoDestroyable } from '@cohesity/utils';
import { isEqual } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ExcludeOptions, ExcludeResults } from '../../source-tree-filters';

/**
 * This component can be used to dynamically load an exclude component and
 * display it along source tree controls.
 *
 * @example
 *   <coh-source-tree-exclude-control-outlet
 *     [component]="nodeDetailComponent"
 *     [excludeOptions]="excludeOptions"
 *     (excludeResults)="excludeResults">
 *   </coh-source-tree-exclude-control-outlet>
 */
@Component({
  selector: 'coh-source-tree-exclude-control-outlet',
  template: '<ng-template [cdkPortalOutlet]="portal" (attached)="onAttached($event)"></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceTreeControlExcludeOutletComponent extends AutoDestroyable implements OnChanges {
  /**
   * The current node being rendered.
   */
  @Input() excludeOptions: ExcludeOptions;

  /**
   * A reference to the component to render for this node.
   */
  @Input() component: ComponentType<any>;

  /**
   * Emit exclude results event when user closes the exclude filters dialog
   */
  @Output() excludeResults = new EventEmitter<ExcludeResults>();

  /**
   * A subject to pass to the portal which will get populated with output
   */
  excludeResults$ = new BehaviorSubject<ExcludeResults>(undefined);

  /**
   * The component's instantiated CDK portal.
   */
  portal: ComponentPortal<any>;

  /**
   * A reference to the created component
   */
  private componentRef: ComponentRef<any>;

  constructor() {
    super();
  }

  /**
   * Handle the component being attacked.
   *
   * @param   componentRef   A reference to the newly created component
   */
  onAttached(componentRef: ComponentRef<any>) {
    this.componentRef = componentRef;
    this.excludeResults$.pipe(
      filter((results) => !!results),
      takeUntil(this._destroy)
    ).subscribe(results => {
      this.excludeResults.emit(results);
    });
    this.updateComponentContext();
  }

  /**
   * Update the node context on the data.
   */
  private updateComponentContext() {
    if (!this.componentRef) {
      return;
    }
    const { instance } = this.componentRef;

    if (isEqual(instance.excludeOptions, this.excludeOptions)) {
      return;
    }

    // It turns out that componentRef.changeDetectorRef is a difference detector from
    // the one you would get by calling the component's injector. This method actually works
    // to detect and update the component.
    instance.excludeOptions = this.excludeOptions;
    instance.excludeResults$ = this.excludeResults$;
    this.componentRef.injector.get(ChangeDetectorRef).detectChanges();
  }

  /**
   * Recreate the portal whenever the node or portal changes
   *
   * @param   changes   A map of changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.component && changes.component.previousValue !== changes.component.currentValue) {
      this.portal = this.component ? new ComponentPortal(this.component) : undefined;
    }

    // Update node context data based on exclude options
    if (changes?.excludeOptions) {
      this.updateComponentContext();
    }
  }
}
