<a
  cogDashcard
  [title]="title"
  [subtitle]="'lastRunStatus' | translate"
  uiSref="jobs"
  uiParams="{ slaViolation: true }">
  <ng-container *ngTemplateOutlet="groupRunComplianceCardTemplate"></ng-container>
</a>
<ng-template #groupRunComplianceCardTemplate>
  <cog-dashcard-layout-standard>
    <cog-dashcard-chart>
      <cog-donut-chart
        colorSetClass="group-run-compliance-card-donut-colors"
        [seriesData]="[chartSeries]">
        {{ totalAlerts | number }}
      </cog-donut-chart>
    </cog-dashcard-chart>
    <cog-dashcard-legend>
      <cog-statlist class="group-run-compliance-card-donut-colors" [vertical]="true">
        <cog-statlist-item cogStatlistItem *ngFor="let legend of legends; index as i"
          [label]="legend.label"
          labelLegend="highcharts-color-{{i}}">
          {{ legend.count | number }}
        </cog-statlist-item>
      </cog-statlist>
    </cog-dashcard-legend>
    <cog-dashcard-footer>
    </cog-dashcard-footer>
  </cog-dashcard-layout-standard>
  <cog-spinner *ngIf="isLoading"></cog-spinner>
</ng-template>
