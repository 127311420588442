import { assign } from 'lodash-es';
// Component: Upload App package

;(function(angular) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('uploadAppCtrl', UploadAppCtrlFn);

  function UploadAppCtrlFn(_, $state, AppsService, evalAJAX,
    $uibModalInstance) {

    var $ctrl = this;

    assign($ctrl, {
      // state object for the app upload
      appUploadState:  {
        uploadInProgress: false,
        uploadPercentageComplete: 0,
        uploadCompleted: false,
        uploadError: false,
      },

      file: undefined,
      upload: undefined,

      // Methods
      uploadApp: uploadApp,
      appUploadClose: appUploadClose,
    });

    /**
    * Starts uploading the app package
    *
    * @method  uploadApp
    */
    function uploadApp() {
      $ctrl.appUploadState.uploadInProgress = true;

      $ctrl.upload = AppsService.uploadAppPackage($ctrl.file);

      $ctrl.upload.progress(function uploadPackagePogress(evt) {
          $ctrl.appUploadState.uploadPercentageComplete =
            parseInt(100.0 * evt.loaded / evt.total, 10);
        }).success(function uploadPackageSuccess(response) {
          $ctrl.appUploadState.uploadCompleted = true;
          appUploadClose();
        }).error(function uploadPackageError(response) {
          $ctrl.appUploadState.uploadError = true;
          evalAJAX.errorMessage({data: response});
        }).finally(function uploadPackageFinally() {
          $ctrl.appUploadState.uploadInProgress = false;
        });
    }

    /**
    * Closes the modal
    *
    * @method  appUploadClose
    */
    function appUploadClose() {
      if($ctrl.upload) {
        $ctrl.upload.abort();
      }

      $uibModalInstance.close();
    }

  }
})(angular);
