import { merge } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: KMS

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.kms', [])
    .config(configFn)
    .controller('kmsController', kmsControllerFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('kms', {
        url: '^/platform/kms',
        help: 'platform_kms',
        canAccess: 'FEATURE_FLAGS.kmsEnabled && CLUSTER_VIEW',
        parentState: 'cluster',
        templateUrl: 'app/platform/kms/kms.html',
        controller: 'kmsController as $ctrl',
      });
  }

  function kmsControllerFn(_, $rootScope, $timeout, $stateParams,
    ClusterService, evalAJAX, StateManagementService) {

    var $ctrl = this;

    assign($ctrl, {
      // Parameters for KMS configuration.
      kms: {
        // Specifies the type of key mangement system.
        // 0 indicates an internal KMS object.
        // 1 indicates an Aws KMS object.
        // 2 indicates a Cryptsoft KMS object.
        // With this workflow we support only Cryptsoft (2) which is default
        serverType: 2,
        serverName: undefined,
        // KMIP compliant KMS.
        cryptsoftKms : {
          serverIp: undefined,

          // Specifies port on which the server is listening.
          // Default port is 5696.
          serverPort: 5696,

          // Specifies the client certificate.
          clientCertificate: undefined,

          // Specifies the client private key.
          clientKey: undefined,

          // Specifies the CA certificate in PEM format.
          caCertificate: undefined,

          // Specifies protocol version used to communicate with the KMS.
          kmipProtocolVersion: undefined,
        },
        // Aws KMS.
        awsKms : {
          // CMK Key Id.
          cmkKeyId: undefined,

          // Access Key Id.
          accessKeyId: undefined,

          // Secret Access Key.
          secretAccessKey: undefined,

          // Region.
          region: undefined,

          // Verify ssl certificate.
          verifySSL: true,

          // CA certificate.
          caCertificatePath: undefined,
        },
      },

      file:  {
        clientCertificate: undefined,
        clientKey: undefined,
        caCertificate: undefined,
      },

      isEditMode: false,
      server: 'cryptsoftKms',

      cancel: cancel,
      caCertificateOnLoad: caCertificateOnLoad,
      clientCertificateOnLoad: clientCertificateOnLoad,
      clientKeyOnLoad: clientKeyOnLoad,
      configureExternalKms: configureExternalKms,
      $onInit: onInit,
    });

    /**
     * Controller on load initialization
     *
     * @method   onInit
     */
    function onInit() {
      var kmsConfig;

      ClusterService.getExternalKms().then(function kmsSuccess(kmsConfigs) {
        // get the kms config which has connection status set to true and is not
        // an internal KMS
        (kmsConfigs || []).some(function eachConfig(config) {
          // Its external KMS if it has ServerIp
          if (config.serverType !== "kInternalKMS") {
            kmsConfig = config;
            return true;
          }
        });

        // merge the found kmsConfig
        merge($ctrl.kms, kmsConfig);

      }).finally(function kmsFinally() {
        // If any kmsConfig is found, we assume the page is in edit mode in which
        // we avoid the user makes change on some fields.
        $ctrl.isEditMode = !!kmsConfig;
        if (kmsConfig && kmsConfig.serverType === 'kAwsKMS') {
          $ctrl.server = 'awsKms';

          $ctrl.kms.awsKms.cmkKeyId = kmsConfig.awsKms.cmkKeyId;
        } else {
          $ctrl.server = 'cryptsoftKms';
        }
      });
    }

    /**
     * Cancels the create/edit
     *
     * @method     cancel
     */
    function cancel() {
      StateManagementService.goToPreviousState('cluster.summary');
    }

    /**
     * Reads the client certificate on loading file
     *
     * @method     clientCertificateOnLoad
     */
    function clientCertificateOnLoad() {
      var clientCertificateReader = new FileReader();
      clientCertificateReader.readAsText($ctrl.file.clientCertificate);
      clientCertificateReader.onload = function onFileLoad() {
        $ctrl.kms.cryptsoftKms.clientCertificate = clientCertificateReader.result;
      };
    }

    /**
     * Reads the client key on loading file
     *
     * @method     clientKeyOnLoad
     */
    function clientKeyOnLoad() {
      var clientKeyReader = new FileReader();
      clientKeyReader.readAsText($ctrl.file.clientKey);
      clientKeyReader.onload = function onFileLoad() {
        $ctrl.kms.cryptsoftKms.clientKey = clientKeyReader.result;
      };
    }

    /**
     * Reads the ca certificate on loading file
     *
     * @method     caCertificateOnLoad
     */
    function caCertificateOnLoad(serverType) {
      var caCertificateReader = new FileReader();
      caCertificateReader.readAsText($ctrl.file.caCertificate);
      caCertificateReader.onload = function onFileLoad() {
        $ctrl.kms[serverType].caCertificate = caCertificateReader.result;
      };
    }

    /**
     * Configure an external KMS.
     *
     * @method   configureExternalKms
     */
    function configureExternalKms() {
      var promise;

      if ($ctrl.frmKms.$invalid) {
        return;
      }

      $ctrl.submitting = true;

      // Depends on the serverType, remove another object unselected.
      if ($ctrl.server === 'awsKms') {
        $ctrl.kms.serverType = 1;
        delete $ctrl.kms.cryptsoftKms;
      } else if ($ctrl.server === 'cryptsoftKms') {
        delete $ctrl.kms.awsKms;
      }

      promise = $ctrl.isEditMode ?
        ClusterService.updateKmsConfig($ctrl.kms) :
        ClusterService.configureExternalKms($ctrl.kms);

        promise.then(function configKmsSuccess() {
          StateManagementService.goToPreviousState('cluster.summary');
        }, evalAJAX.errorMessage)
        .finally(function configKmsFinally() {
            $ctrl.submitting = false;
          });
    }


  }

})(angular);
