import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataSourceId } from '@cohesity/api/argus';
import { DataPool } from '@cohesity/api/inventory-mgr';
import { AutoDestroyable } from '@cohesity/utils';
import { flatten } from 'lodash-es';
import { combineLatest, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DataPoolAssociationDialogService } from '../data-pool-association-dialog/data-pool-association-dialog.service';
import { getDataPoolsByDataSourceIdMap } from '../data-pool-list';
import { SourceLayout } from './data-pool-selector.model';

/**
 * The selected data pools for governance and classification.
 */
interface Selection {
  /** The selected data pools for governance */
  governance: SelectionModel<DataPool>;

  /** The selected data pools for classification */
  classification: SelectionModel<DataPool>;
}

/**
 * The shield's data pool across all sources selector component.
 *
 * @example
 * <dg-dc-data-pool-selector></dg-dc-data-pool-selector>
 */
@Component({
  selector: 'dg-dc-data-pool-selector',
  templateUrl: './data-pool-selector.component.html',
  styleUrls: ['./data-pool-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPoolSelectorComponent extends AutoDestroyable implements OnChanges {
  /**
   * The selected data pools for governance.
   */
  @Input() governance?: SelectionModel<DataPool>;

  /**
   * The selected data pools for classification.
   */
  @Input() classification?: SelectionModel<DataPool>;

  /**
   * The list of data sources.
   */
  @Input() sources: SourceLayout[];

  /**
   * The list of data pools.
   */
  @Input() dataPools: DataPool[];

  /**
   * Disabled the Data Pool section.
   */
  @Input() disabled = false;

  /**
   * The list of all data sources.
   */
  dataSources: SourceLayout[] = [];

  /**
   * The data pools by data source ids map.
   */
  dataPoolsByDataSourceId = new Map<string, DataPool[]>();

  /**
   * The map selected data pools per data source.
   */
  selectionMap = new Map<DataSourceId, Selection>();

  /**
   * The subscription watching for data sources selection model changes and update external this.selection model.
   */
  subscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private dataPoolAssociationDialogService: DataPoolAssociationDialogService,
  ) {
    super();
  }

  /**
   * Update chart data with input changes.
   *
   * @param changes Input changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.sources || changes.dataPools) {
      this.setup();
    }
  }

  /**
   * Setup data source selection model changes subscription.
   */
  setup() {
    this.dataPoolsByDataSourceId = getDataPoolsByDataSourceIdMap(this.dataPools || []);
    this.dataSources = this.pruneDataSources(this.sources || []);

    // initialize the selection model per data source.
    this.selectionMap.clear();
    this.dataSources.forEach(({ id }) => this.selectionMap.set(id, {
      governance: new SelectionModel<DataPool>(true, [], true),
      classification: new SelectionModel<DataPool>(true, [], true),
    }));

    // end any existing subscription for selection model changes.
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // subscribe to selection model per data sources changes and update the external model.
    const changesSubjects = flatten([...this.selectionMap.values()].map(
      ({ governance, classification }) => ([governance.changed, classification.changed]))
    );
    this.subscription = combineLatest(changesSubjects)
      .pipe(
        this.untilDestroy(),
        tap(() => {
          let dataPoolsToGovern = [];
          let dataPoolsToClassify = [];

          [...this.selectionMap.values()].forEach(({ governance, classification }) => {
            dataPoolsToGovern = dataPoolsToGovern.concat(...governance.selected);
            dataPoolsToClassify = dataPoolsToClassify.concat(...classification.selected);
          });

          this.governance.clear();
          this.governance.select(...dataPoolsToGovern);

          this.classification?.clear();
          this.classification?.select(...dataPoolsToClassify);
        }),
      )
      .subscribe();

    // initialize the the selection model per data sources.
    this.dataSources.forEach(({ id }) => {
      // adding null value and clearing it immediately to emit default value from selection changed rxjs Subject
      // because combineLatest() requires us to emit at least 1 event.
      const dataPoolsToGovern = (this.dataPoolsByDataSourceId.get(id) || [])
        .filter(dataPool => this.governance.selected.includes(dataPool));

      this.selectionMap.get(id).governance.select(null);
      this.selectionMap.get(id).governance.clear();
      this.selectionMap.get(id).governance.select(...dataPoolsToGovern);

      const dataPoolsToClassify = (this.dataPoolsByDataSourceId.get(id) || [])
        .filter(dataPool => this.classification?.selected.includes(dataPool));

      this.selectionMap.get(id).classification.select(null);
      this.selectionMap.get(id).classification.clear();
      this.selectionMap.get(id).classification.select(...dataPoolsToClassify);
    });
  }

  /**
   * Prune the data sources list by keeping data sources having at least 1 data pool which can be associated
   * with the shield and if the shield is not present then show all data sources.
   *
   * @param dataSources The data sources.
   * @returns The pruned list of data sources.
   */
  pruneDataSources(dataSources: SourceLayout[]): SourceLayout[] {
    return dataSources.filter(({ id }) => this.dataPoolsByDataSourceId.has(id));
  }

  /**
   * Launch the dialog to update whether data pool should be governed and classified.
   *
   * @param dataPool The data pool.
   * @param dataSource The data source.
   */
  editDataPool(dataPool: DataPool, dataSource: SourceLayout) {
    const selection = this.selectionMap.get(dataSource.id);

    this.dataPoolAssociationDialogService.launchDataPoolAssociationDialog({
      dataPool,
      governance: selection.governance.isSelected(dataPool),
      classification: this.classification ? selection.classification.isSelected(dataPool) : null,
      updateAssociation: (governance, classification, dialogRef) => {
        this.selectionMap.get(dataSource.id).governance[governance ? 'select' : 'deselect'](dataPool);
        this.selectionMap.get(dataSource.id).classification[classification ? 'select' : 'deselect'](dataPool);
        dialogRef.close();
        this.cdr.detectChanges();
      }
    });
  }
}
