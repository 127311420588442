import { MaintenanceModeConfigProto, ProtectionSourceTreeInfo } from '@cohesity/api/v1';
import { SourceStatus } from '@cohesity/iris-shared-constants';

/**
 * the string that is displayed as a tooltip and as a message in the settings
 * tab.
 */
interface TranslatableString {
  /**
   * the string to be translated.
   */
  message: string;

  /**
   * start time of the maintenance mode.
   */
  startTime?: number;

  /**
   * end time of the maintenance mode.
   */
  endTime?: number;
}

/**
 * Returns if the source is under/ scheduled/ not in maintenance/ expired.
 */
export function getSourceMaintenanceStatus(maintenanceModeConfig: MaintenanceModeConfigProto): string {
  if (maintenanceModeConfig?.activationTimeIntervals?.length) {
    const startTime = maintenanceModeConfig?.activationTimeIntervals[0].startTimeUsecs;
    const endTime = maintenanceModeConfig?.activationTimeIntervals[0].endTimeUsecs;
    const currentTime = Date.now() * 1000;
    if (startTime <= currentTime) {
      if (endTime === -1 ) {
        return SourceStatus.UNDER_MAINTENANCE_INFINITE;
      }
      if (currentTime < endTime) {
        return SourceStatus.UNDER_MAINTENANCE_FINITE;
      }
      if (endTime < currentTime) {
        return SourceStatus.MAINTENANCE_EXPIRED;
      }
    }
    if (startTime > currentTime) {
      return SourceStatus.SCHEDULED_MAINTENANCE;
    }
  }
  return SourceStatus.NO_MAINTENANCE_CONFIGURED;
}

/**
 * Checks if a source is under maintenance
 *
 * @param source the source summary object
 * @returns true/ false
 */
export function isSourceUnderMaintenance(source: ProtectionSourceTreeInfo | any) {
  return getSourceMaintenanceStatus(source.maintenanceModeConfig) === SourceStatus.UNDER_MAINTENANCE_FINITE ||
    getSourceMaintenanceStatus(source.maintenanceModeConfig) === SourceStatus.UNDER_MAINTENANCE_INFINITE;
}

/**
 * Returns the maintenance mode of a source in human readable format to be
 * consumed by the settings tab of source details.
 */
export function getMaintenanceModeText(maintenanceModeConfig: MaintenanceModeConfigProto): TranslatableString {
  let startTimeUsecs;
  let endTimeUsecs;
  let endTime;
  let startTime;

  if (maintenanceModeConfig?.activationTimeIntervals?.length) {
    startTimeUsecs = maintenanceModeConfig?.activationTimeIntervals[0].startTimeUsecs;
    endTimeUsecs = maintenanceModeConfig?.activationTimeIntervals[0].endTimeUsecs;
    if (endTimeUsecs !== -1) {
      endTimeUsecs *= 0.001;
      endTime = new Date(endTimeUsecs);
    }
    startTimeUsecs *= 0.001;
    startTime = new Date(startTimeUsecs);
  }

  switch (getSourceMaintenanceStatus(maintenanceModeConfig)) {
    case SourceStatus.UNDER_MAINTENANCE_INFINITE:
      return { message: 'source.maintenance.settings.indefinitely' };
    case SourceStatus.UNDER_MAINTENANCE_FINITE:
      return { message: 'source.maintenance.settings.finiteEndTime', endTime };
    case SourceStatus.SCHEDULED_MAINTENANCE:
      return { message: 'source.maintenance.settings.scheduled', startTime, endTime };
    case SourceStatus.NO_MAINTENANCE_CONFIGURED:
      return { message: 'source.maintenance.settings.off' };
    default:
      return { message: 'source.maintenance.settings.off' };
  }
}
