import { get } from 'lodash-es';
// Component: Oracle Manager - Register/Modify

(function(angular, undefined) {
  'use strict';

  var modName = 'C.db-manager';

  angular
    .module(modName, [])
    .controller('oracleModificationController',
      oracleModificationControllerFn);

  /**
   * Controller Fn
   * Note: PageConfig is either passed through the state resolver or through the
   * modal resolver with pageConfig consisiting of an Object with the host name
   * to be updated.
   *
   * @method    oracleModificationControllerFn
   */
  function oracleModificationControllerFn(_, StateManagementService,
    SourceService, evalAJAX, $stateParams, cMessage,
    ENV_TYPE_CONVERSION, $uibModalInstance, pageConfig, FEATURE_FLAGS,
    NgOracleRegistrationService, ENUM_HOST_TYPE_CONVERSION, connectionId, UserService,
    $q, ORACLE_CLUSTER_TYPE) {
    var $ctrl = this;

    angular.extend($ctrl, {
      state: {
        // Specifies whether OS or DB Authentication is being used.
        oracleDBAuth: false,
        hostName: undefined,
        physicalServers: {},

        // Specifies whether Oracle server credentials edit is initiated.
        isEditMode: false,
      },
      host: {
        credentials: undefined,

        // env vec initialised to kOracle
        appEnvVec: [19],
        usesPersistentAgent: true,
        ownerEntity: undefined,

        // for registering oracle db with credentials populate this field
        appCredentialsVec: [
          {
            envType: 19,
            credentials: {},
          }
        ],
      },
      isOracleCluster: false,
      physicalServer: {},
      handleSubmit: handleSubmit,
      cancel: cancel,
      browseForLeafEntities: browseForLeafEntities,
      downloadAgent: SourceService.downloadAgentsModal,
      pageConfig: pageConfig || {},
    });

    /**
     * Initializer Fn
     *
     * @method    onInit
     */
    $ctrl.$onInit = function onInit() {

      // Check for Oracle registration inside Modal
      $ctrl.inModal = typeof $uibModalInstance === 'object';

      $ctrl.oracleSources = ['singleInstance', 'oracleRAC'];

      // Initialize preferred option for Oracle source type
      $ctrl.state.oracleSource = $ctrl.oracleSources[0];

      // Initialize oracle registration type
      $ctrl.isOracleCluster = $stateParams.registrationType === 'kOracleCluster';

      // Initialize Host address if stateParams has hostAddress defined
      $ctrl.state.hostName = $stateParams.hostName;

      // Initialize connect endpoint details requiredduring oracle cluster registration.
      $ctrl.state.scanName = $stateParams.scanName;

      // Set only if $stateParams.isDbAuthenticated is true or false
      if ($stateParams.isDbAuthenticated != undefined) {
        $ctrl.state.oracleDBAuth = $stateParams.isDbAuthenticated;
      }

      // Check whether an Oracle credentials update or registration retry is
      // initiated.
      if ($stateParams.id || $ctrl.pageConfig.hostName) {
        $ctrl.state.isEditMode = true;

        // During edit mode fetch the oracle physical server info and populate
        // registration type and host endpoint details.
        SourceService.getSources({
          entityId: $stateParams.id,
          excludeTypes: [5],
          includeVMFolders: false,
          onlyReturnOneLevel: true
        }).then(
          function fetchOracleSourceInfo(resp) {
            const entityHierarchy = resp?.entityHierarchy;
            const registeredEntityInfo = entityHierarchy?.registeredEntityInfo;
            $ctrl.physicalServer = {
              entity: entityHierarchy?.entity,
              entityInfo: registeredEntityInfo?.connectorParams,
              sourceSideDedupEnabled: entityHierarchy?.registeredEntityInfo?.sourceSideDedupEnabled,
              throttlingPolicy: registeredEntityInfo?.throttlingPolicy || {},
              registeredEntityParams: registeredEntityInfo?.registeredEntityParams || {},
              connectionId: resp?.connectionId
            };
            $ctrl.state.hostName = get(registeredEntityInfo, 'connectorParams.endpoint');
            $ctrl.state.scanName = get(entityHierarchy, 'entity.physicalEntity.name');
            const registrationType = get(entityHierarchy, 'entity.physicalEntity.type');

            // Show connect endpoint for Oracle RAC/AP/Cluster registration.
            if (ORACLE_CLUSTER_TYPE.includes(registrationType) && FEATURE_FLAGS.oracleClusterRegistration) {
              $ctrl.state.registrationType = 'kOracleCluster';
              $ctrl.isOracleCluster = true;
            } else {
              $ctrl.state.registrationType = 'kHost';
              $ctrl.isOracleCluster = false;
            }
          },
          evalAJAX.errorMessage
        );
      }

      if ($ctrl.pageConfig.hostName) {
        $ctrl.state.hostName = $ctrl.pageConfig.hostName;
      }

      /**
       * If Oracle registration is initiated from sources page then fetch entity
       * id first and then use the same for fetching physical servers registered
       */
      if (!$stateParams.parentId) {
        // Fetch the sources for envType kPhysical
        SourceService.getSources({
          envTypes: 6,
          excludeTypes: [5],
          includeVMFolders: true,
        }).then(
            function populatePhysicalServers(res) {
              /**
               * Populate Physical Servers for validating against the
               * server entered.
               */
              $ctrl.state.physicalServers =
                get(res, 'entityHierarchy.children[0].children', []);
            },
            evalAJAX.errorMessage
          );
      } else {
        /**
         * If Oracle registration is initiated from physical server pages then
         * entity id is already populated, the same is used for fetching
         * physical servers.
         */
        SourceService.getSources({
          entityId: $stateParams.parentId,
          excludeTypes: [5],
          includeVMFolders: true
        }).then(
            function populatePhysicalServers(res) {
              $ctrl.state.physicalServers = res.entityHierarchy.children;
          },
          evalAJAX.errorMessage);
        }

        // TODO(Tauseef): Determine whether the entity is RAC when RAC support
        // is enabled.
    };

    /**
     * Determines if the physical host can be registered as Oracle server.
     *
     * @method   _canOracleRegisterOrUpdate
     * @param    {Object}   server   object containing physical servers details
     *
     * @return   {Boolean}  true if the Physical host can be registered as
     *                      Oracle server.
     */
    function _canOracleRegisterOrUpdate(server) {
      switch(true) {
        // Windows physical server registration is controlled by feature flag.
        case get(server,'entity.physicalEntity.hostType') === ENUM_HOST_TYPE_CONVERSION.kWindows &&
          !FEATURE_FLAGS.oracleOnWindowsEnabled:
          return false;

        // In case of edit mode, register or update can be triggered.
        case $ctrl.state.isEditMode:

        // Incase of no aux children, register or update can be triggered.
        case !server.auxChildren:

        // Incase of aux children present & no application environment, register
        // or update can be triggered.
        case server.auxChildren && !server.registeredEntityInfo.appEnvVec:

        // Incase of aux children present & the application environment not
        // as kOracle, register or update can be triggered.
        case server.auxChildren && !server.registeredEntityInfo.appEnvVec
          .includes(ENV_TYPE_CONVERSION.kOracle):
          return true;

        default:
          return false;
      }
    }

    /**
     * Handle Form submission
     *
     * @method    handleSubmit
     */
    function handleSubmit() {
      var serverAvailable = false;
      var isOracleEntity = false;

      // check for credentials
      if (!$ctrl.state.oracleDBAuth) {
        $ctrl.host.appCredentialsVec = undefined;
      }

      // iterate over registered physical servers to find server data
      serverAvailable = $ctrl.state.physicalServers.some(
        function registerOrUpdateServer(server) {
          const entityName = _.get(server, 'entity.physicalEntity.name');
          const endpoint = _.get(server, 'registeredEntityInfo.connectorParams.endpoint');
          const serverInfoList = [$ctrl.state.hostName, $ctrl.state.scanName];

          if (serverInfoList.includes(entityName) || serverInfoList.includes(endpoint)) {
            $ctrl.host.ownerEntity = server.entity;
            const entityType = _.get(server, 'entity.physicalEntity.type');

            if (FEATURE_FLAGS.oracleClusterRegistration) {
              // If entity is already register as kHost, and user is trying to register this as Oracle Cluster.
              if ($ctrl.isOracleCluster && entityType === 1) {
                cMessage.error({
                  textKey: 'oracleSources.cmessages.oracleStandaloneServerDetected',
                  timeout: 5000,
                });
                return true;
              } else if (!$ctrl.isOracleCluster && ORACLE_CLUSTER_TYPE.includes(entityType)) {
                cMessage.error({
                  textKey: 'oracleSources.cmessages.oracleClusterDetected',
                  timeout: 5000,
                });
                return true;
              }
            }

            // Determine if the register or update is possible.
            if (_canOracleRegisterOrUpdate(server)) {
              _registerOrUpdateAppOwner();
              return true;
            }
            isOracleEntity = true;
            return false;
          }
        }
      );

      if (!serverAvailable) {
        // Allow 1-step registration
        if (FEATURE_FLAGS.oracleOneStepRegister) {
          const defaultConnectionId = UserService.getDefaultBifrostConnectionId();
          const assignConnectionId = connectionId ? connectionId : defaultConnectionId;
          $ctrl.submitting = true;
          NgOracleRegistrationService.registerPhysicalAndPolling($ctrl.state.hostName, assignConnectionId || null,
            $ctrl.state.scanName)
            .toPromise().then(function success(server) {
            $ctrl.host.ownerEntity = server.entity;
            if (_canOracleRegisterOrUpdate(server)) {
              _registerOrUpdateAppOwner();
            } else {
              showErrorMessage(true);
            }
          }, evalAJAX.errorMessage
          ).finally(function registrationDoneFn() {
            $ctrl.submitting = false;
          });
        } else {
          // Show error if source is not registered as physical server yet.
          showErrorMessage(isOracleEntity);
        }
      }
    }

    /**
     * Register or Update Oracle Application
     *
     * @method   _registerOrUpdateAppOwner
     */
    function _registerOrUpdateAppOwner() {
      $ctrl.submitting = true;
      var submitAppFn = $ctrl.state.isEditMode ? SourceService.updateAppOwner :
        SourceService.registerAppOwner;
      var isOracleClusterRegistration = FEATURE_FLAGS.oracleClusterRegistration && $ctrl.state.isEditMode &&
        $ctrl.isOracleCluster;
      if (isOracleClusterRegistration) {
        $ctrl.physicalServer.entityInfo.endpoint = $ctrl.state.hostName;
      }
      var promises = {
        updateSource: isOracleClusterRegistration ? SourceService.updateSource($ctrl.physicalServer) : $q.resolve(null),
        appOwner: submitAppFn($ctrl.host)
      };

      $q.all(promises).then(
        function _handleResponse(response) {
          // This server response is a simple boolean.
          if (response?.appOwner) {
            // TODO: This is a positive/successful response and should not get
            // routed through the cancel function for resolution.

            cMessage.success({
              textKey: $ctrl.state.isEditMode ?
                'oracleSources.cmessages.success.update' :
                'oracleSources.cmessages.success.create',
              timeout: 3000,
            });
            $ctrl.cancel();
            return;
          }

          // Show error if request fails.
          // Enhance the backend API to give more info as to why the registration
          // failed. Eg: Databases could be shutdown.
          showErrorMessage();
        }, evalAJAX.errorMessage
      ).finally(
        function registrationDoneFn() {
          $ctrl.submitting = false;
        }
      );
    }

    /**
     * handles redirecting user to the proper place on cancel or submit
     *
     * @method     cancel
     */
    function cancel() {
      if ($ctrl.inModal) {
        $uibModalInstance.dismiss('user.cancel');
        return;
      }

      // NOTE: Sending user to sources landing page with a delayed refresh so
      // so registration will hopefully complete and the related data will be
      // shown.
      StateManagementService.goToPreviousState('sources-new', { delayedRefresh: true });
    }

    /**
     * handles physical server browse
     *
     * @param    {string}    envType          The env types to browse
     * @method   browseForLeafEntities
     */
    function browseForLeafEntities() {

      var filters = {
        requireVmTools: false,
        singleSelect: true,
        registeredApp: ENV_TYPE_CONVERSION.kOracle,
        canSelectNode: function disableNodes(node) {
          return !node._isWindows || FEATURE_FLAGS.oracleOnWindowsEnabled;
        },
      };

      SourceService.browseForLeafEntities(6, undefined, filters).then(
        function selectServerSuccess(selectedNodes) {
          $ctrl.selectedNodes = selectedNodes;

          // set the input field to the selected host
          $ctrl.state.hostName = selectedNodes.physicalEntity.name;
        }
      );
    }

    /**
     * Show an error cMessage on no physical server match found
     *
     * @method    showErrorMessage
     * @param     {Boolean}   isOracleEntity   true if entity is oracle Server
     */
    function showErrorMessage(isOracleEntity) {
      cMessage.error({
        textKey: isOracleEntity ?
          'oracleSources.cmessages.alreadyRegisteredServer' :
          'oracleSources.cmessages.invalidPhysicalServer',
        textKeyContext: { entity: $ctrl.host.ownerEntity },
        timeout: 5000,
      });
    }
  }

})(angular);
