import { Office365BackupType, Office365LeafNodeType, RecoveryAction } from '@cohesity/iris-shared-constants';

/**
 * Specifies the Office365 entity collection for different groups.
 *
 * Office365 EH has the following structure within Magneto's EH forest of
 * trees encapsulated within Super root node:
 * ----------------------------------------------------------------------------
 *                             MSFT Entity Hierarchy
 * ----------------------------------------------------------------------------
 *
 *                      kSuperRootNode
 *                            |
 *                         kDomain
 *                            |
 *     _______________________|________________________
 *    |         |             |             |          |
 *    |         |             |             |          |
 *  kUsers   kGroups    kPublicFolders    kSites     kTeams
 *    |         |             |             |          |
 *    |         |             |             |          |
 * kUser[]   kGroup[]   kPublicFolder[]  Tree<kSite>  kTeam[]
 *
 *
 * In the above EH, kUsers, kGroups, kSites, kTeams & kPublicFolders are dummy
 * containers having children as multiple kUser, kGroup, kSite, kTeam &
 * kPublicFolder respectively.
 *
 * Additionally, all container entities except kSites have a flat list of
 * children. The kSites container can have its own kSite hierarchy in the form
 * of a m-ary tree where all nodes(internal and leaf) are of type kSite.
 *
 * NOTE: Deprecated EH had just kOutlook container for kMailbox.
 *
 * Refer EntityHierarchyProto in main/magneto/base/magneto.proto for details.
 * Refer enum Type within main/magneto/base/entities/o365.proto for details.
 *
 * ----------------------------------------------------------------------------
 *                             MSFT Cloud Hierarchy
 * ----------------------------------------------------------------------------
 *                                Microsoft 365
 *                                      |
 *       --------------------------------------------------------------
 *      |                    |                       |                 |
 *    Global          US Government                China            Germany
 *      |                    |                       |                 |
 *      |          -------------------------         |                 |
 *      |         |          |              |        |                 |
 *  Office365    GCC      GCC High         DoD       |                 |
 *      |         |          |              |        |                 |
 * [ Azure Commercial ]    [ Azure Government ]  [ 21Vianet ]     [ Closed ]
 *
 * MSFT cloud instances comprises of Global Azure Cloud and individual National
 * clouds. The National clouds include:
 * - Azure Government
 * - Azure China 21Vianet
 * - Azure Germany(Closed)
 *
 * National clouds are physically isolated instances of Azure. These regions of
 * Azure are designed to make sure that data residency, sovereignty, and
 * compliance requirements are honored within geographical boundaries.
 *
 * Root endpoints Info
 * ----------------------------------------------------------------------------
 * Cloud         | Azure AD portal endpoint | Azure AD endpoint
 * ----------------------------------------------------------------------------
 * Global      	 | https:*portal.azure.com | https:*login.microsoftonline.com
 * US Government | https:*portal.azure.us	| https:*login.microsoftonline.us
 * China21Vianet | https:*portal.azure.cn	| https:*login.chinacloudapi.cn
 *
 * Office365 GCC is paired with Azure AD in Azure Public. This environment
 * helps customers comply with US government requirements,
 * including FedRAMP High, CJIS, and IRS 1075.
 * Office365 GCC High & DoD are paired with Azure AD in Azure Government and
 * these environments support customers who need compliance with DoD IL4/5,
 * DFARS 7012, NIST 800-171, and ITAR.
 *
 * This service implements a func GetM365SourceRegion(...) which fetches the
 * M365 tenant region along with Azure AD endpoints for the given M365 tenant.
 * If 'FLAGS_AzureAutoDetectRegion' is set to true then device
 * authorization flow ensures that the region is fetched using the above
 * method and corresponding endpoints are used.
 *
 * For more details refer:
 * - https:*bit.ly/3lZmxkz
 * - https:*bit.ly/3nEzel6
 * - https:*bit.ly/3G56WqD
 */
export const OFFICE365_GROUPS = {
  // Specifies the Office365 leaf entities.
  office365Leaves: [
    'kMailbox',
    'kUser',
    'kGroup',
    'kSite',
    'kPublicFolder',
    'kTeam',

    // The followings are not actual entity in magneto' EH but within
    // global_entity index and reporting DB only.
    'kO365Exchange',
    'kO365OneDrive',
    'kO365Sharepoint',
  ],

  // Specifies the Office365 leaf entities int vals.
  office365LeafInts: [
    3 /* kMailbox(deprecated) */,
    7 /* kUser */,
    8 /* kGroup */ ,
    9 /* kSite */,
    13 /* kPublicFolder */,
    15 /* kTeam */,
    17 /* kO365Exchange */,
    18 /* kO365OneDrive */,
    19 /* kO365Sharepoint */,
  ],

  // Specifies the following dummy containers for their Office365 leaf
  // entities:
  //   Dummy container -> Leaf
  //   kOutlook        -> kMailbox
  //   kUsers          -> kUser
  //   kGroups         -> kGroup
  //   kSites          -> kSite
  //   kPublicFolders  -> kPublicFolder
  //   kTeams          -> kTeam
  office365EntityContainers: [
    'kOutlook', 'kGroups', 'kSites', 'kUsers', 'kPublicFolders', 'kTeams'
  ],

  // Specifies the types of recovery supported within Office365.
  // TODO(tauseef): Segregate enums with ints if needed in future.
  office365RecoveryTypes: [
    'kRecoverEmails', 15,
    'kRecoverO365Drive', 20,
    'kRecoverSites', 23,
    'kConvertToPst', 25,
  ],
};

/**
 * Specifies the root node for O365 hierarchy.
 */
export const Office365DomainType = 'kDomain';

/**
 * Specifies the type of container entities for Office365. Refer teh above entity hierarchy diagram for details.
 */
export enum Office365ContainerNodeType {
  /**
   * Specifies the dummy container within Magneto's EH for all users.
   */
  kUsers = 'kUsers',

  /**
   * Specifies the dummy container within Magneto's EH for all users.
   */
  kOutlook = 'kOutlook',

  /**
   * Specifies the dummy container within Magneto's EH for all Groups.
   */
  kGroups = 'kGroups',

  /**
   * Specifies the dummy container within Magneto's EH for all SharePoint Sites.
   */
  kSites = 'kSites',

  /**
   * Specifies the dummy container within Magneto's EH for all PublicFolders.
   */
  kPublicFolders = 'kPublicFolders',

  /**
   * Specifies the dummy container within Magneto's EH for all Teams.
   */
  kTeams = 'kTeams',
}

/**
 * Specifies the backup subtype an Office365 source.
 */
export enum Office365BackupSubtype {
  kPrivateChats = 'kPrivateChats',
  kTeamPosts = 'kTeamPosts',
}

/**
 * Specifies the download item type for an Office365 backup type.
 */
export enum Office365DownloadItemType {
  kTeamPosts = 'kTeamPosts',
  kTeamChannelPosts = 'kTeamChannelPosts',
  kUserPrivateChats = 'kUserPrivateChats',
}

/**
 * Office365 Recovery flows can be visualized through the below tree:
 *
 *                                                        Office365
 *                                                            |
 *              ______________________________________________|______________________________________
 *             |                                              |                                     |
 *             |                                              |                                     |
 *        Object level                                 Granular level                         Download items
 *             |                                              |                                     |
 *             |                               _______________|_____________               _________|
 *             |                              |                             |             |
 *           Search                        Search                        Browse           |--- kChatsDownload
 *         (objindex)                    (cfileindex)      (cfileindex/rocksDB)           |--- kTeamPostsDownload
 *       (ElasticSearch)                  (Librarian)                       |             |--- kTeamChannelPostsDownload
 *        _____|                          ____|                             |
 *       |                               |                                  |
 *       |--- kMailboxSearch             |--- kEmailSearch                  |
 *       |--- kOneDriveSearch            |--- kOneDriveDocumentSearch       |
 *       |--- kSharePointSiteSearch      |--- kSharePointSiteDocumentSearch |
 *       |--- kPublicFolderSearch        |--- kPublicFolderContentSearch    |
 *       |--- kTeamSearch                |--- kTeamChannelSearch            |
 *       |--- kGroupSearch               |--- kTeamContentSearch            |
 *                                       |--- kGroupMailboxItemSearch       |
 *                                       |--- KGroupSiteItemSearch          |
 *                                                                          |
 *                                        __________________________________|
 *                                       |
 *                                       |--- kOneDriveBrowse
 *                                       |--- kSharePointSiteBrowse
 *
 *
 * Looking from a product perspective, the recovery flows can be classified as:
 *      -- Mailbox Recovery
 *         -- Entire Mailbox                   -> kMailboxSearch
 *         -- Mailbox items                    -> kEmailSearch (Office365MailboxItemType(s))
 *         -- User private chats               -> kChatsDownload
 *
 *      -- OneDrive Recovery
 *         -- Entire OneDrive                  -> kOneDriveSearch
 *         -- OneDrive items via Browse        -> kOneDriveBrowse
 *         -- OneDrive items via Search        -> kOneDriveDocumentSearch
 *
 *      -- SharePoint Recovery
 *         -- Entire Site                      -> kSharePointSiteSearch
 *         -- Site items via Browse            -> kSharePointSiteBrowse
 *         -- Site items via Search            -> kSharePointSiteDocumentSearch
 *
 *      -- Public Folders Recovery
 *         -- Root public folders              -> kPublicFolderSearch
 *         -- Public folder contents           -> kPublicFolderContentSearch
 *                                           (Office365PublicFolderItemType(s))
 *
 *      -- Teams Recovery
 *         -- Entire Team                      -> kTeamSearch
 *         -- Channels within Team             -> kTeamChannelSearch
 *         -- Conversations & Documents        -> kTeamContentSearch
 *         -- Team Posts                       -> kTeamPostsDownload
 *         -- Team Channel Posts               -> kTeamChannelPostsDownload
 *
 *      -- Groups Recovery
 *         -- Entire Group                     -> kGroupSearch
 *         -- Group Mailbox Items              -> kGroupMailboxItemSearch
 *         -- Group Site Items                 -> kGroupSiteItemSearch
 *
 * Specifies the type of recovery flow for an Office365 source.
 */
export enum Office365SearchType {
  /**
   * Specifies the search type when recovering the entire Outlook mailbox.
   * This search is powered through 'objindex' within ES.
   */
  kMailboxSearch = 'kMailboxSearch',

  /**
   * Specifies the search type when downloading private chats in user mailbox
   */
  kChatsDownload = 'kChatsDownload',

  /**
   * Specifies the search type when recovering Outlook mailbox items such as
   * emails & email folders.
   * This search is powered through 'cfileindex' within Librarian.
   */
  kEmailSearch = 'kEmailSearch',

  /**
   * Specifies the search type when recovering the entire OneDrive.
   * This search is powered through 'objindex' within ES.
   */
  kOneDriveSearch = 'kOneDriveSearch',

  /**
   * Specifies the search type when recovering OneDrive items through browse.
   * This browse is powered by Yoda treating the snapshot as either indexed
   * View or through RocksDB read dir.
   */
  kOneDriveBrowse = 'kOneDriveBrowse',

  /**
   * Specifies the search type when recovering OneDrive files and folders.
   * This search is powered through 'cfileindex' within Librarian.
   */
  kOneDriveDocumentSearch = 'kOneDriveDocumentSearch',

  /**
   * Specifies the search type when recivering entire SharePoint Site.
   * This search is powered through 'objindex' within ES.
   */
  kSharePointSiteSearch = 'kSharePointSiteSearch',

  /**
   * Specifies the search type when recovering Site items within SharePoint
   * document repository(OneDrive) through browse.
   * This browse is powered by Yoda treating the snapshot as either indexed
   * View or through RocksDB read dir.
   */
  kSharePointSiteBrowse = 'kSharePointSiteBrowse',

  /**
   * Specifies the search type when recovering Site's files and folders.
   * This search is powered through 'cfileindex' within Librarian.
   */
  kSharePointSiteDocumentSearch = 'kSharePointSiteDocumentSearch',

  /**
   * Specifies the search type when recovering Root Public folders.
   * This search is powered through 'objindex'
   */
  kPublicFolderSearch = 'kPublicFolderSearch',

  /**
   * Specifies the search type when recovering contents within Public Folders.
   * This search is powered through 'cfileindex'.
   */
  kPublicFolderContentSearch = 'kPublicFolderContentSearch',

  /**
   * Specifies the search type when recovering MS team entity.
   * This search is powered through 'objindex'.
   */
  kTeamSearch = 'kTeamSearch',

  /**
   * Specifies the search type when recovering MS team's channels.
   * This search is powered through 'cfileindex'.
   */
  kTeamChannelSearch = 'kTeamChannelSearch',

  /**
   * Specifies the search type when recovering MS team channels' contents such
   * as conversations, documents, etc.
   * This search is powered through 'cfileindex'.
   */
  kTeamContentSearch = 'kTeamContentSearch',

  /**
   * Specifies the search type when downloading MS team channel posts
   */
  kTeamChannelPostsDownload = 'kTeamChannelPostsDownload',

  /**
   * Specifies the search type when downloading MS team posts
   */
  kTeamPostsDownload = 'kTeamPostsDownload',

  /**
   * Specifies the search type when recovering Group entity.
   * This search is powered through 'objindex'.
   */
  kGroupSearch = 'kGroupSearch',

  /**
   * Specifies the search type for searching Group mailbox items such as
   * emails, notes, journals, site docs, etc.
   * This search is powered through 'cfileindex'.
   */
  kGroupMailboxItemSearch = 'kGroupMailboxItemSearch',

  /**
   * Specifies the search type for searching Group Site items such as documents
   * within the Site DocLib.
   * This search is powered through 'cfileindex'.
   */
  kGroupSiteItemSearch = 'kGroupSiteItemSearch',
}

/**
 * Specifies the Attribute type available for filtering Office365 entities.
 */
export enum Office365AttributeType {
  /**
   * Specifies the Cogfilter property for searching by username.
   */
  kSearchUsername = 'searchUsername',

  /**
   * Specifies the Cogfilter property for filtering by protection status.
   */
  kProtectionStatus = 'protectionStatus',

  /**
   * Specifies the Cogfilter property for filtering by user's department.
   */
  kUserDepartment = 'userDepartment',

  /**
   * Specifies the Cogfilter property for filtering by user's title/designation.
   */
  kUserTitle = 'userTitle',

  /**
   * Specifies the Cogfilter property for searching by users's country.
   */
  kUserCountry = 'userCountry',
}

/**
 * Specifies the Filter attribute key within API response.
 */
export enum Office365FilterAPIKey {
  /**
   * Specifies the key for all available departments.
   */
  kUserDepartmentList = 'departments',

  /**
   * Specifies the key for all available countries.
   */
  kUserCountryList = 'countries',

  /**
   * Specifies the key for all available titles.
   */
  kUserTitleList = 'titles',
}

/**
 * Region to endpoint map translates region names to the respective endpoint
 * which should be used to access that region.
 * TODO (Tauseef): move to backend instead of keeping records in UI.
 */
export const Office365EndpointMap: { [index: string]: string} = {
  'kCommercial': 'https://outlook.office365.com/EWS/Exchange.asmx',
  'kChina': 'https://partner.outlook.cn/EWS/Exchange.asmx',
  'kGermany': 'https://outlook.office.de/EWS/Exchange.asmx',
  'kUSDoD': 'https://outlook.office365.us/EWS/Exchange.asmx',
  'kUSGccHigh': 'https://outlook.office365.us/EWS/Exchange.asmx',
};

/**
 * Specifies the user-visible(external) region mapping to its corresponding API
 * type.
 */
export enum Office365ExternalRegionType {
  // API treats 'Commercial' as Default while 'Commercial' is kept only within
  // frontend.
  kCommercial = 'Default',
  kChina = 'China',
  kGermany = 'Germany',
  kUSDoD = 'UsDoD',
  kUSGccHigh = 'UsGccHigh'
}

/**
 * Specifies the API mappings for the M365 source region which are not user
 * exposed but only used for internal mappings.
 */
export enum Office365InternalRegionType {
  // API treats kDefault as the default region which for the client is renamed
  // to kCommercial.
  kDefault = 'kCommercial',
  kChina = 'kChina',
  kGermany = 'kGermany',
  kUSDoD = 'kUSDoD',
  kUSGccHigh = 'kUSGccHigh'
}

/*
 * Specifies Office365 Public folder document enum mappings.
 */
export enum Office365PublicFolderItemType {
  kCalendar = 'Calendar',
  kContact = 'Contact',
  kPost = 'Post',
  kFolder = 'Folder',
  kTask = 'Task',
  kJournal = 'Journal',
  kNote = 'Note',
}

/**
 * Specifies Office Public folder document type string mapping.
 */
export enum Office365PublicFolderItemTypeStr {
  kCalendar = 'events',
  kContact = 'contacts',
  kPost = 'emailsAndPosts',
  kFolder = 'subfolders',
  kTask = 'tasks',
  kJournal = 'journals',
  kNote = 'notes',
}

/**
 * Specifies the Office365 Mailbox item type mappings.
 */
export enum Office365MailboxItemType {
  kEmail = 'Email',
  kFolder = 'Folder',
  kCalendar = 'Calendar',
  kContact = 'Contact',
  kNote = 'Note',
  kTask = 'Task',
}

/**
 * Specifies the Office365 Groups Mailbox folder type mappings.
 */
export enum Office365GroupMailboxFolderType {
  kEmailFolder = 'EmailFolder',
  kCalendarFolder = 'CalendarFolder',
  kContactFolder = 'ContactFolder',
  kSearchFolder = 'SearchFolder',
  kTaskFolder = 'TaskFolder',
}

/**
 * Specifies the Office365 Teams item type mappings.
 */
export enum Office365TeamItemType {
  kFile = 'File',
  kFolder = 'Folder',
  kChannel = 'Channel',
}

/**
 * Specifies the OFfice365 Doc lib item type mappings.
 */
export enum Office365DocLibItemType {
  File = 'File',
  Directory = 'Directory',
}

/**
 * Specifies the Office365 MS Group Item types.
 */
export enum Office365GroupItemType {
  Email = 'Email',
  EmailFolder = 'EmailFolder',
  SiteFile = 'SiteFile',
  SiteFolder = 'SiteFolder',
}

/**
 * Specifies the mappings for Office365 granular search types within the v2
 * search API. Refer 'SearchIndexedObjectsResponseBody' for all mappings.
 */
export enum Office365GranularSearchResponseType {
  kEmails = 'emails',
  kPublicFolderItems = 'publicFolderItems',
  kSharePointItems = 'sharepointItems',
  kOneDriveItems = 'oneDriveItems',
  kTeamsItems = 'teamsItems',
  kMsGroupItems = 'msGroupItems',

  // TODO(tauseef): Introduce other params as the v2 API is wired up for other
  // workloads.
}

export const Office365GroupsSearchResultItemMap: Partial<Record<Office365SearchType, string>> = {
  [Office365SearchType.kGroupMailboxItemSearch]: 'mailboxItem',
  [Office365SearchType.kGroupSiteItemSearch]: 'siteItem',
};

/**
 * Specifies the search types for which multiple requests for different snapshots are enabled.
 */
export const Office365SearchTypeMultipelSnapshotGlrEnabled = [
  Office365SearchType.kEmailSearch,
  Office365SearchType.kGroupMailboxItemSearch,
  Office365SearchType.kGroupSiteItemSearch
];

/**
 * Specifies the restore types for Office365 groups. An Office365 group has
 * a corresponding group mailbox and a sharepoint site, each of which could
 * be restored fully or granularly(partially). See magneto/base/magneto.proto
 * in the main repo for reference.
 */
export enum Office365GroupRestoreType {
  kFull = 'kFull',
  kPartial = 'kPartial',
}

/**
 * Specifies the name/title for Office365 workload types.
 */
export const Office365ObjectTypeToTitle: { [index: string]: string } = {
  kGroup: 'group',
  kGroups: 'groups',
  kMailbox: 'mailbox',
  kOneDrive: 'oneDrive',
  kPublicFolders: 'publicFolders',
  kSharePoint: 'site',
  kSite: 'site',
  kSites: 'sites',
  kTeam: 'team',
  kTeams: 'teams',
};

/**
 * Specifies whether an Office365 workload types is in early access.
 */
export const Office365ObjectTypeEarlyAccess: { [index: string]: boolean } = {
  kGroups: true,
};

/**
 * Specifies the container entity label for granular search types.
 */
export const Office365GranularSearchTypeToObjectTitle: Partial<Record<Office365SearchType, string>> = {
  [Office365SearchType.kEmailSearch]: 'mailbox',
  [Office365SearchType.kOneDriveDocumentSearch]: 'onedrive',
  [Office365SearchType.kSharePointSiteDocumentSearch]: 'site',
  [Office365SearchType.kGroupMailboxItemSearch]: 'group',
  [Office365SearchType.kPublicFolderContentSearch]: 'publicFolder',
  [Office365SearchType.kGroupSiteItemSearch]: 'group',
  [Office365SearchType.kTeamContentSearch]: 'team',
};

/**
 * Specifies the Office365 object types mapping to action key.
 * This is used for object type other than kUser since kUser is not 1:1 mapping to actionKey
 * but the rest of O365 object type always is 1:1 mapping.
 */
export const Office365ObjectTypeToActionKey: { [index: string]: string } = {
  kGroup: 'kO365Group',
  kPublicFolders: 'kO365PublicFolders',
  kSite: 'kO365Sharepoint',
  kTeam: 'kO365Teams',
  kMailbox: 'kO365Exchange',
  kOneDrive: 'kO365OneDrive',
  kO365Exchange: 'kO365Exchange',
  kO365OneDrive: 'kO365OneDrive',
  kO365Sharepoint: 'kO365Sharepoint',
  kO365ExchangeCSM: 'kO365ExchangeCSM',
  kO365OneDriveCSM: 'kO365OneDriveCSM',
  kO365SharepointCSM: 'kO365SharepointCSM',
};

/**
 * Specifies the Protection group env mapping for Office365 workload types.
 */
export const Office365ProtectionGroupEnvMap: { [index: string]: string } = {
  kGroups: 'kO365Group',
  kMailbox: 'kO365Exchange',
  kOneDrive: 'kO365OneDrive',
  kPublicFolders: 'kO365PublicFolders',
  kSharePoint: 'kO365Sharepoint',
  kTeams: 'kO365Teams',
  kMailboxCSM: 'kO365ExchangeCSM',
  kOneDriveCSM: 'kO365OneDriveCSM',
  kSharePointCSM: 'kO365SharepointCSM',
};

/**
 * Specified the protection type for Office365 workload environment.
 */
export const Office365ActionKeyProtectionTypeMap: { [index: string]: Office365BackupType } = {
  kO365Group: Office365BackupType.kGroups,
  kO365Exchange: Office365BackupType.kMailbox,
  kO365OneDrive: Office365BackupType.kOneDrive,
  kO365PublicFolders: Office365BackupType.kPublicFolders,
  kO365Sharepoint: Office365BackupType.kSharePoint,
  kO365Teams: Office365BackupType.kTeams,
  kO365ExchangeCSM: Office365BackupType.kMailboxCSM,
  kO365OneDriveCSM: Office365BackupType.kOneDriveCSM,
  kO365SharepointCSM: Office365BackupType.kSharePointCSM,
};

/**
 * Specifies a mapping from Object action key to its corresponding recovery.
 */
export const Office365ActionKeyToRecoveryTypeMap: { [index: string]: RecoveryAction} = {
  kO365Group: RecoveryAction.RecoverMsGroup,
  kO365Exchange: RecoveryAction.RecoverMailbox,
  kO365OneDrive: RecoveryAction.RecoverOneDrive,
  kO365PublicFolders: RecoveryAction.RecoverPublicFolders,
  kO365Sharepoint: RecoveryAction.RecoverSharePoint,
  kO365Teams: RecoveryAction.RecoverMsTeam,
  kO365ExchangeCSM: RecoveryAction.RecoverMailboxCSM,
  kO365OneDriveCSM: RecoveryAction.RecoverOneDriveCSM,
  kO365SharepointCSM: RecoveryAction.RecoverSharePointCSM,
};

/**
 * Specifies the object protection params for Office365 workload types.
 * TODO: add group and publicFolders when they support objectProtection.
 */
export const Office365ObjectProtectionEnvParams = {
  kMailbox: 'userMailboxObjectProtectionParams',
  kOneDrive: 'userOneDriveObjectProtectionParams',
  kSharePoint: 'sharepointSiteObjectProtectionParams',
  kTeams: 'teamsObjectProtectionParams',
  kGroups: 'groupsObjectProtectionParams',
  kMailboxCSM: 'userMailboxObjectProtectionParams',
  kOneDriveCSM: 'userOneDriveObjectProtectionParams',
  kSharePointCSM: 'sharepointSiteObjectProtectionParams',
};

/**
 * Provides configuration data for source detail workload tabs.
 */
export const office365WorkloadConfigs = [
  {
    // Enabled Always.
    dmsFlag: null,
    onPremFlag: null,
    displayName: 'mailbox',
    workloadType: Office365BackupType.kMailbox,
  },
  {
    dmsFlag: 'dmsOffice365OneDriveWorkload',
    onPremFlag: 'office365OneDriveSupportEnabled',
    displayName: 'oneDrive',
    workloadType: Office365BackupType.kOneDrive,
  },
  {
    dmsFlag: 'dmsOffice365SharepointWorkload',
    onPremFlag: 'office365SharePointSupportEnabled',
    displayName: 'site',
    workloadType: Office365BackupType.kSharePoint,
  },
  {
    dmsFlag: 'dmsOffice365TeamsWorkload',
    onPremFlag: 'office365TeamsSupportEnabled',
    displayName: 'teams',
    workloadType: Office365BackupType.kTeams,
  },
  {
    dmsFlag: 'dmsOffice365GroupsWorkload',
    onPremFlag: 'office365GroupSupportEnabled',
    displayName: 'group',
    workloadType: Office365BackupType.kGroups,
  },
  {
    dmsFlag: 'dmsOffice365PublicFoldersWorkload',
    onPremFlag: 'office365PublicFolderSupportEnabled',
    displayName: 'publicFolders',
    workloadType: Office365BackupType.kPublicFolders,
  },
];


/**
 * Provides configuration data for source detail workload tabs for o365 CSM type.
 */
export const office365CSMWorkloadConfigs = [
  {
    // Enabled Always.
    dmsFlag: null,
    onPremFlag: null,
    displayName: 'mailbox',
    workloadType: Office365BackupType.kMailboxCSM,
  },
  {
    dmsFlag: 'dmsOffice365OneDriveWorkload',
    onPremFlag: 'office365OneDriveSupportEnabled',
    displayName: 'oneDrive',
    workloadType: Office365BackupType.kOneDriveCSM,
  },
  {
    dmsFlag: 'dmsOffice365SharepointWorkload',
    onPremFlag: 'office365SharePointSupportEnabled',
    displayName: 'site',
    workloadType: Office365BackupType.kSharePointCSM,
  },
];


/**
 * List of o365 CSM backup types.
 */
export const Office365CSMBackupTypes =
  [
    Office365BackupType.kMailboxCSM,
    Office365BackupType.kOneDriveCSM,
    Office365BackupType.kSharePointCSM,
  ];

/**
 * List of o365 CSM backup types.
 */
export const Office365CSMEnvironmentTypes =
  [
    Office365ProtectionGroupEnvMap[Office365BackupType.kMailboxCSM],
    Office365ProtectionGroupEnvMap[Office365BackupType.kOneDriveCSM],
    Office365ProtectionGroupEnvMap[Office365BackupType.kSharePointCSM],
  ];

/**
 * Specifies the various steps within M365 registration flow.
 */
export const M365RegistrationSteps = {
  /**
   * Specifies new source registration flow.
   */
  kAddNewSourceDetails: 'kAddNewSourceDetails',

  /**
   * Specifies update of an existing source.
   */
  kUpdateSourceDetails: 'kUpdateSourceDetails',

  /**
   * Specifies manual update of Azure Apps.
   */
  kManualCreateAzureApp: 'kManualCreateAzureApp',

  /**
   * Specifies auto creation of Azure Apps.
   */
  kAutoCreateAzureApp: 'kAutoCreateAzureApp',

  /**
   * Specifies auto updation of Azure Apps.
   */
  kAutoUpdateAzureApp: 'kAutoUpdateAzureApp',

  /**
   * Specifies progress view for Azure Apps creation
   */
  kAzureAppCreationProgress: 'kAzureAppCreationProgress',

  /**
   * Specifies progress view while updating permissions on Azure apps
   */
  kAzureAppUpdateProgress: 'kAzureAppUpdateProgress',

  kRegisterBackupController: 'kRegisterBackupController',

  kDeleteBackupController: 'kDeleteBackupController',
};

/**
 * MS Team items that can be created in alternate recovery.
 */
export enum TeamItems {
  kTeam = 'team',
  kChannel = 'channel',
}

/**
 * Enum for channel type of teams.
 */
 export enum MsTeamChannelType {
  /**
   * This option will set the channel type as public.
   */
  kPublic = 'Public',

  /**
   * This option will set the channel type as private.
   */
  kPrivate = 'Private',
}

/*
 * Specifies workload specific mapping for granular recovery to their parent
 * entities.
 */
export const M365GranularRecoveryEntityParamsMapping: Partial<Record<Office365SearchType, string>> = {
  [Office365SearchType.kEmailSearch]: 'mailboxIds',
  [Office365SearchType.kGroupMailboxItemSearch]: 'groupIds',
  [Office365SearchType.kSharePointSiteDocumentSearch]: 'siteIds',
  [Office365SearchType.kGroupSiteItemSearch]: 'groupIds',
  [Office365SearchType.kOneDriveDocumentSearch]: 'userIds',
  [Office365SearchType.kTeamContentSearch]: 'teamsIds',
  [Office365SearchType.kTeamChannelSearch]: 'teamsIds',
};

/**
 * Specifies the types of credentials supported within M365.
 */
export enum Office365CredentialType {
  /**
   * Specifies the Azure App credentials used for MSFT Graph APIs.
   */
  kAzureApp = 'kAzureApp',

  /**
   * Specifies the Service Account credentials used for MSFT EWS APIs.
   */
  kServiceAccount = 'kServiceAccount',
}

/**
 * Specifies the Mapping from the Granular search type to the container entity
 * parameter. The parameters contain corresponding workload(objectActionKey) &
 * the snapshot type.
 */
export const M365GranularSearchTypeToContainerObjectParamsMap = {
  [Office365SearchType.kEmailSearch]: {
    objectActionKey: Office365ObjectTypeToActionKey[
      Office365LeafNodeType.kO365Exchange],
    snapshotActions: [RecoveryAction.RecoverMailbox]
  },
  [Office365SearchType.kOneDriveDocumentSearch]: {
    objectActionKey: Office365ObjectTypeToActionKey[
      Office365LeafNodeType.kO365OneDrive],
    snapshotActions: [RecoveryAction.RecoverOneDrive]
  },
  [Office365SearchType.kSharePointSiteDocumentSearch]: {
    objectActionKey: Office365ObjectTypeToActionKey[
      Office365LeafNodeType.kO365Sharepoint],
    snapshotActions: [RecoveryAction.RecoverSharePoint]
  },
  [Office365SearchType.kTeamContentSearch]: {
    objectActionKey: Office365ObjectTypeToActionKey[
      Office365LeafNodeType.kTeam],
    snapshotActions: [RecoveryAction.RecoverMsTeam]
  },
  [Office365SearchType.kTeamChannelSearch]: {
    snapshotActions: [RecoveryAction.RecoverMsTeam]
  },
  [Office365SearchType.kGroupMailboxItemSearch]: {
    objectActionKey: Office365ObjectTypeToActionKey[
      Office365LeafNodeType.kGroup],
    snapshotActions: [RecoveryAction.RecoverMsGroup]
  },
  [Office365SearchType.kGroupSiteItemSearch]: {
    objectActionKey: Office365ObjectTypeToActionKey[
      Office365LeafNodeType.kGroup],
    snapshotActions: [RecoveryAction.RecoverMsGroup]
  },
};

/**
 * Specifies the different views that Office365 Entity Hierarchy supports.
 */
export enum Office365HierarchyType {
  /**
   * Specifies the default representation of all sub trees within Office365
   * This resembles the way Magneto stores the EH.
   */
  Default = 'Default',
  /**
   * Specifies the view containing kGroup entities which are security-enabled
   * and can only have a mailbox/onedrive protection applied on the member
   * user entities.
   */
  SecurityGroup = 'SecurityGroup',
}

/**
 * Specifies the different attributes which can be patched on a given M365
 * source.
 */
export enum Office365HierarchyMetadataActionType {
  /**
   * Specifies whether users' mailbox info including the provisioning status,
   * mailbox type & in-place archival support will be fetched and processed.
   */
  FetchMailboxInfo = 'fetchMailboxInfo',

  /**
   * Specifies whether users' onedrive info including the provisioning status &
   * storage quota will be fetched and processed.
   */
  FetchOneDriveInfo = 'fetchOneDriveInfo',

  /**
   * Specifies whether to skip processing user who have uninitialized OneDrive
   * or are without MySite.
   */
  IncludeUsersWithoutMySite = 'includeUsersWithoutMySite',

  /**
   * Specifies whether the SHarePoint Sites will be tagged whether they belong
   * to a group site or teams site.
   */
  EnableSiteTagging = 'enableSiteTagging',
}

/**
 * Specifies the various states of M365 Domain validation.
 * Domain validation can be thought of a State Machine consisting of 5 states.
 */
export enum M365DomainValidationSteps {
  /**
   * S0 is the state where UI waits for the user to input the domain.
   */
  kWaitingForUserInput = 'kWaitingForUserInput',
  /**
   * S1 is the state where UI triggers HTTP call to the /region-info endpoint
   * to get the metadata about the domain.
   */
  kValidationInProgress = 'kValidationInProgress',
  /**
   * S2 is the state where the validation completes but with error and hence
   * the domain is considered invalid. This can happen if the domain belongs to
   * Azure Gov Cloud and the environment is DMaaS
   */
  kInvalid = 'kInvalid',
  /**
   * S3 is the state where the domain does not exist.
   */
  kNotFound = 'kNotFound',
  /**
   * S4 is the state where the validation completes successfully.
   */
  kNoError = 'kNoError',
}

/**
 * Within Office365, exclusions within any backup job can be done for 2
 * categories of folder, namely, default folder names & custom folder names.
 * API supports an option to include/exclude folders as separate parameters.
 *
 * All custom paths provided are EXCLUDED and are driven through the API param
 * of 'excludeFolders' which translates to DENY filters within Magneto.
 *
 * All default paths provided except the following 2 are driven through the API
 * param of 'excludedFolders' which translates to DENY filters within Magneto.
 *
 * For the below set of folders, exclusion are driven through the API param
 * of 'includeFolders' which translates to ALLOW filters within Magneto.
 * [Recoverable Items, ArchiveRecoverableItems]. They work through inclusion
 * because they were never backed up previously and the default is to continue
 * the same.
 *
 */
export enum Office365FolderExclusionMode {
  /**
   * Translates to API param of 'includeFolders' which populates the ALLOW
   * filters for Magneto to include folders in backup. This mode can be used by
   * those folders which are by default excluded.
   */
  kAllow = 'kAllow',
  /**
   * Translates to API param of 'excludeFolders' which populates the DENY
   * filters for Magneto to exclude folders in backup. This mode can be used by
   * those folders which are by default included.
   */
  kDeny = 'kDeny',
}
