import { union } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { values } from 'lodash-es';
import { isArray } from 'lodash-es';
import { find } from 'lodash-es';
import { forEach } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
import { PublicStatusMapping } from 'src/app/shared/constants/public-status-mapping.constants';
// Reports Service
;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('ReportsService', reportsServiceFn);

  function reportsServiceFn(_, moment, $http, $q, API, ReportsServiceFormatter,
    PolicyService, cReportsControlsUtil, TenantService, JobRunsService,
    ReportsUtil, $translate, RUN_STATUS, NavStateService, ViewBoxService,
    $rootScope) {

    return {
      createReportSchedule: createReportSchedule,
      deleteReportSchedule: deleteReportSchedule,
      extendWithTenantParams: extendWithTenantParams,
      getAgentStatusReport: getAgentStatusReport,
      getArchivalSummary: getArchivalSummary,
      getClusterStorage: getClusterStorage,
      getDataTransferredToVaults: getDataTransferredToVaults,
      getJobInventory: getJobInventory,
      getGdprReport: getGdprReport,
      getMultipleJobs: getMultipleJobs,
      getObjectsToJobs: getObjectsToJobs,
      getProtectedObjectsTrends: getProtectedObjectsTrends,
      getProtectionSourceObjects: getProtectionSourceObjects,
      getReportSchedules: getReportSchedules,
      getRunsSummary: getRunsSummary,
      getSourcesJobsRun: getSourcesJobsRun,
      getStorageByFileCategory: getStorageByFileCategory,
      getStorageByFileTop: getStorageByFileTop,
      getStorageByJobs: getStorageByJobs,
      getStorageBySources: getStorageBySources,
      getStorageByTenants: getStorageByTenants,
      getStorageByTenantsAdvancedStats: getStorageByTenantsAdvancedStats,
      getStorageByViewbox: getStorageByViewbox,
      getStorageByVM: getStorageByVM,
      getVmsByProtection: getVmsByProtection,
      updateReportSchedule: updateReportSchedule,
    };

    /**
     * @param  {object} params  ClusterStorageReportParams {
     *                            StartTimeMsecs *int64
     *                            EndTimeMsecs *int64
     *
     *                            Percentage of physical space used target,
     *                            defaults to 80% if not specified,
     *                            Used to compute target date when cluster will
     *                            hit this utilization given current average
     *                            growth.
     *                            PercentageTarget *int64
     *                         }
     */
    function getClusterStorage(params) {
      return $http({
        method: 'get',
        url: API.private('reports/cluster/storage'),
        params: params,
      });
    }

    function getStorageByTenants(params) {
      return $http({
        method: 'get',
        url: API.private('reports/tenantStorage'),
        params: assign({allUnderHierarchy: true}, params),
      });
    }

    function getStorageByTenantsAdvancedStats(params) {
      return $q.all({
        viewboxes: ViewBoxService.getViewBoxes(),
        tenantsMap: $rootScope.user.privs.ORGANIZATION_VIEW ?
          TenantService.getTenantsMap() :
          Promise.resolve({}),
        report: $http({
          method: 'get',
          url: API.public('stats/tenants'),
          params: assign({allUnderHierarchy: true}, params),
        }),
      }).then(function gotData(responses) {
        var viewBoxesMap = keyBy(responses.viewboxes, 'id');
        var tenantsMap = responses.tenantsMap || {};
        var statsList = responses.report.data.statsList || [];
        var groupByTenant = {};

        statsList.forEach(function eachStat(stats) {
          stats._tenant = tenantsMap[stats.id];
          stats.groupList = (stats.groupList || []).map(
            function eachViewboxStats(viewBoxStats) {
              return assign(viewBoxStats, {
                _tenant: tenantsMap[viewBoxStats.tenantId],
                _viewbox: viewBoxesMap[viewBoxStats.viewBoxId],
              });
            }
          );
          _addStats(groupByTenant, stats);
        });

        return { statsList: statsList, _groupByTenant: values(groupByTenant) };
      });

      /**
       * Add stats to the provided group.
       *
       * @param {Object} groupByTenant The group by tenants object.
       * @param {*}      stats         The stats which will be added to the group states.
       */
      function _addStats(groupByTenant, stats) {
        var target = groupByTenant[stats.id];

        if (!target) {
          target = groupByTenant[stats.id] = cloneDeep(stats);
          return;
        }

        Object.keys(target.stats || {}).forEach(function eachKey(key) {
          if (typeof target.stats[key] !== 'number') {
            target.stats[key] = 0;
          }
          target.stats[key] += stats.stats[key] || 0;
        });
      }
    }

    function getStorageByViewbox(params) {
      return $http({
        method: 'get',
        url: API.private('reports/viewboxes/storage'),
        params: params,
      });
    }

    function getStorageByVM(params) {
      return $http({
        method: 'get',
        url: API.private('reports/objects/storage'),
        params: params,
      }).then(
        function addTenantDetails(resp){
          return TenantService.resolveTenantDetails(resp.data || []);
        }
      );
    }

    function getStorageByJobs(params) {
      return $http({
        method: 'get',
        url: API.private('reports/backupjobs/storage'),
        params: params,
      });
    }

    function getStorageBySources(params) {
      return $http({
        method: 'get',
        url: API.private('reports/backupsources/storage'),
        params: params,
      });
    }

    function getStorageByFileCategory(params) {
      return $http({
        method: 'get',
        url: API.private('reports/filecategories/storage'),
        params: params,
      });
    }

    function getStorageByFileTop(params) {
      return $http({
        method: 'get',
        url: API.private('reports/files/storage'),
        params: params,
      });
    }

    function getObjectsToJobs(params) {
      return $http({
        method: 'get',
        url: API.private('reports/objectsToJobs'),
        params: params,
      }).then(
        function objsToJobsSuccess(resp) {
          var data = resp.data || {};

          data.jobDescriptionList = data.jobDescriptionList || [];

          return data;
        }
      );
    }

    function getJobInventory(params) {
      return $http({
        method: 'get',
        url: API.private('reports/backupjobs/schedule'),
        params: params,
      }).then(
        function getJobInvSuccess(resp) {
          var data = (resp.data || []).map(function addPolicyToJobFn(job) {
            job._policy =
              PolicyService.policyCache[job.backupJob.policyId] || undefined;
            return job;
          });

          return TenantService.resolveTenantDetails(
            data || [], 'backupJob.userInfo.tenantIdVec');
        }
      );
    }

    /**
     * Gets a list of VMs and their protected status
     *
     * @param  {Object} params
     *
     * @return {Promise}
     */
    function getVmsByProtection(params) {
      return $http({
        method: 'get',
        url: API.private('reports/objects/vmsProtectionStatus'),
        params: params,
      });
    }

    /**
     * gets a list of protection source objects by job
     *
     * @param  {object} params {
     *                           jobId: Number
     *                           startTImeUsecs: Number
     *                           endTImeUsecs: Number
     *                          }
     *
     * @return {array} list of protection source objects
     */
    function getProtectionSourceObjects(params) {
      if (Array.isArray(params.statuses)) {
        params.statuses =
          ReportsServiceFormatter.convertStatuses(params, 'statuses');
      }

      return $http({
        method: 'get',
        url: API.public('reports/protectionSourcesJobsSummary'),
        params: params,
      }).then(function successResponse(res) {
        return res.data.protectionSourcesJobsSummary || [];
      });
    }

    /**
     * gets list of jobs and their snapshots by protection source
     *
     * @param  {object} params {
     *                           protectionSourceIds: Number
     *                           startTimeUsecs: Number,
     *                           endTImeUsecs: Number,
     *                           runStatus: Array
     *                          }
     *
     * @return {array} list of jobs and their snapshots
     */
    function getSourcesJobsRun(params) {
      if (get(params, 'runStatus.length')) {
        params.runStatus =
          ReportsServiceFormatter.convertStatuses(params);
      }

      return $http({
        method: 'get',
        url: API.public('reports/protectionSourcesJobRuns'),
        params: params
      }).then(function successResponse(res) {
        if (res.data.protectionSourceJobRuns &&
          res.data.protectionSourceJobRuns.length) {

          return parseSnapshotsCopyTasks(res.data.protectionSourceJobRuns);
        }

        return [];
      });
    }

    /**
     * get list of transferred data to different targets
     *
     * @param     {object}    params    params object
     * @return    {array}
     */
    function getDataTransferredToVaults(params) {
      return $http({
        method: 'get',
        url: API.public('reports/dataTransferToVaults'),
        params: params,
      }).then(function successResponse(res) {
        return res.data.dataTransferSummary || [];
      });
    }

    /**
     * get archival summary list
     *
     * @param     {object}    params    params object
     * @return    {array}
     */
    function getArchivalSummary(params) {
      return $http({
        method: 'get',
        url: API.public('reports/cloudArchiveReport'),
        // url: API.public('reports/dataTransferToVaults'),
        params: params,
      }).then(function successResponse(res) {
        return res.data || [];
      });
    }

    /**
     * deletes a report schedule by id
     *
     * @param     {Number}    id    id of report to delete
     * @return    {array}
     */
    function deleteReportSchedule(id) {
      return $http({
        method: 'delete',
        url: API.public('scheduler'),
        data: [id],
      }).then(function deleteSuccess(res) {
        return res.data || [];
      });
    }

    /**
     * gets list of all report schedules
     *
     * @param     {Object}   params   The request params.
     * @return    {array}    array of report schedule objects
     */
    function getReportSchedules(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.public('scheduler'),
      }).then(function successResponse(res) {
        var schedulers = res.data ? res.data.schedulerJobs : [];

        if (params._includeTenantInfo) {
          return resolveReportScheduleTenantDetails(schedulers);
        }
        return schedulers;
      }).then(function modifiedSchedules(schedules) {
        return _modifyShedules(schedules, params);
      });
    }

    /**
     * Adds the tagged enitites to the schedules by making the required API
     * calls with the params specified.
     *
     * NOTE - The params can be of type -
     * {
     *    _includeJobInfo: true,
     *    _includeSourceInfo: true,
     *    _includeObjectInfo: true,
     *    _includePrincipalInfo: true,
     *    _includeViewBoxInfo: true,
     *    _includeVaultInfo: true,
     * }
     *
     * @method   _modifyShedules
     * @param    {Array}   schedules   The list of schedules to modify.
     * @param    {Object}  params      An object which specifies what all
     *                                 entities to get by making the specific
     *                                 API call.
     * @return   {Object}  Promis resolved with the modified schedules.
     */
    function _modifyShedules(schedules, params) {
      // Store all the required ids/sids for the tagged entities.
      var jobIds = [];
      var sourceIds = [];
      var objectIds = [];
      var viewBoxIds = [];
      var vaultIds = [];
      var sids = [];

      var promises = {};

      forEach(schedules, function forEachSchedule(schedule) {
        // Get the reports filter
        var reportsFilter = schedule.scheduleJobParameters.reportJobParameter
          .reports[0].parameters;

        // Update the enitity ids to make the required API call.
        if (reportsFilter.jobId) {
          jobIds.push(reportsFilter.jobId);
        }
        if (reportsFilter.registeredSourceIds) {
          sourceIds = union(sourceIds, reportsFilter.registeredSourceIds);
        }
        if (reportsFilter.registeredSourceId) {
          sourceIds.push(reportsFilter.registeredSourceId);
        }
        if (reportsFilter.objectIds) {
          objectIds = union(objectIds, reportsFilter.objectIds);
        }
        if (reportsFilter.viewBoxId) {
          viewBoxIds.push(reportsFilter.viewBoxId);
        }
        if (reportsFilter.sid) {
          sids.push(reportsFilter.sid);
        }
        if (reportsFilter.vaultIds) {
          vaultIds = union(vaultIds, reportsFilter.vaultIds);
        }
      });

      // Check if the API call is actually required by checking the params and
      // the tagged entities.
      if (params._includeJobInfo && jobIds.length) {
        promises.jobs = ReportsUtil.getJobs(jobIds);
      }
      if (params._includeSourceInfo && sourceIds.length) {
        promises.sources = ReportsUtil.getSources(sourceIds);
      }
      if (params._includeObjectInfo && objectIds.length) {
        promises.objects = ReportsUtil.getEntities(objectIds);
      }
      if (params._includePrincipalInfo && sids.length) {
        promises.principals = ReportsUtil.getPrincipals(sids);
      }
      if (params._includeViewBoxInfo && viewBoxIds.length) {
        promises.viewBoxes = ReportsUtil.getViewBoxes(viewBoxIds);
      }
      if (params._includeVaultInfo && vaultIds.length) {
        promises.vaults = ReportsUtil.getExternalTargets(vaultIds);
      }

      return $q.all(promises).then(function transformSchedulers(res) {
        // Save all the enitities as a map to access it later.
        var maps = {
          jobs: keyBy(res.jobs, 'id'),
          sources: keyBy(res.sources, 'value.id'),
          objects: keyBy(res.objects, 'id'),
          principals: keyBy(res.principals, 'sid'),
          viewBoxes: keyBy(res.viewBoxes, 'value'),
          vaults: keyBy(res.vaults, 'id'),
        }

        return _transformSchedules(schedules, maps);
      });
    }

    /**
     * Transforms the schedules with the tagged enitities using the map of
     * entities.
     *
     * @method   _transformSchedules
     * @param    {Array}   schedules   The list of schedules to transform.
     * @param    {Object}  maps        The minimal entities map.
     * @return   {Array}   The transformed schedules with the proper entity
     *                     name tagged
     */
    function _transformSchedules(schedules, maps) {
      return map(schedules, function mapSchedules(schedule) {
        const report = schedule.scheduleJobParameters.reportJobParameter
          .reports[0];
        var reportsFilter = report.parameters;

        // Convert the runstatus values from normalized format
        // to specific format for UI
        if (reportsFilter.runStatus) {
          reportsFilter.runStatus =
            ReportsServiceFormatter.convertStatuses({
              reportType: report.type,
              runStatus: reportsFilter.runStatus
            });
        }

        // If single select is there for registered sources, then
        // registeredSourceId will be present in reports filter else
        // registeredSourceIds will be present as key.
        var sources =map(reportsFilter.registeredSourceIds,
          function mapSources(sourceId) {
            return get(maps.sources[sourceId], 'value.displayName');
          });

        if (reportsFilter.registeredSourceId) {
          sources = get(maps.sources[reportsFilter.registeredSourceId],
            'value.displayName');
        }

        // Assign the entity names using the map provided.
        assign(reportsFilter, {
          _jobNames: get(maps.jobs[reportsFilter.jobId], 'name'),
          _sourcesNames: sources,
          _objectNames: map(reportsFilter.objectIds,
            function mapObjects(objectId) {
              return get(maps.objects[objectId], 'name');
            }),
          _viewBoxNames: get(maps.viewBoxes[reportsFilter.viewBoxId],
            'name'),
          _vaultNames:  map(reportsFilter.vaultIds,
            function mapVaults(id) {
              return get(maps.vaults[id], 'name');
            }),
          _principalNames: get(maps.principals[reportsFilter.sid],
            'fullName'),
          _runStatusStrings: _getRunStatusStrings(reportsFilter.runStatus),
          // read from environment in the case of ObjHeatmapReport
          _objectType: _getObjectType(reportsFilter.objectType || reportsFilter.environment)
        });

        return schedule;
      });
    }


    /**
     * translates selected run status filters from kValues to human readable
     * strings
     *
     * @method   _getRunStatusStrings
     * @param    {Array}    runStatus   The list of run Statuses.
     * @return   {string}    comma-delimited list of run statuses
     */
    function _getRunStatusStrings(runStatus) {
      return map(runStatus, function mapStatus(status) {
        return $translate.instant(RUN_STATUS[status]);
      });
    }

    /**
     * return ObjectType name from ObjectType enum
     *
     * @method   _getObjectType
     * @param    {string}    objectType   ObjectType Enum
     * @return   {string}    ObjectType Name
     */
    function _getObjectType(objectTypeEnum) {
      const objectType =
        find(NavStateService.getObjectTypes(), { enum: objectTypeEnum });
      return objectType ? objectType.name : '';
    }

    /**
     * creates a report schedule
     *
     * @param     {object}    reportFilters     object containing all filters
     *                                          selected in reports sidebar
     *
     * @param     {object}    scheduleFilters    object containing all values
     *                                           selected in scheduler modal
     *
     * @return    {object}                       newly created report schedule
     *                                           object
     */
    function createReportSchedule(reportFilters, scheduleFilters) {
      return $http({
        method: 'post',
        url: API.public('scheduler'),
        data: ReportsServiceFormatter.formatReportScheduleParams(
          reportFilters, scheduleFilters),
      }).then(function successResponse(res) {
        return resolveReportScheduleTenantDetails(res.data || {});
      });
    }

    /**
     * updates and already created report schedule
     *
     * @param     {object}    reportFilters     object containing all filters
     *                                          selected in reports sidebar
     *
     * @param     {object}    scheduleFilters    object containing all values
     *                                           selected in scheduler modal
     *
     * @return    {object}                       newly created report schedule
     *                                           object
     */
    function updateReportSchedule(reportFilters, scheduleFilters) {
      return $http({
        method: 'put',
        url: API.public('scheduler'),
        data: ReportsServiceFormatter.formatReportScheduleParams(
          reportFilters, scheduleFilters),
      }).then(function successResponse(res) {
        return resolveReportScheduleTenantDetails(res.data || {});
      });
    }

    /**
     * Resolve the list of email schedulers with tenant info.
     *
     * @method    resolveReportScheduleTenantDetails
     * @param     {Object|Array}    schedulers     The list of schedulers or a
     *                                             scheduler to be resolved with
     *                                             tenant Info.
     * @return    {object}    Promise resolved with resolved tenant info else
     *                        rejected with error.
     */
    function resolveReportScheduleTenantDetails(schedulers) {
      var isList = isArray(schedulers);

      return TenantService.resolveTenantDetails(
        isList ? schedulers : [schedulers],
        'scheduleJobParameters.reportJobParameter.reports[0].parameters.tenantIds')
        .then(function gotResult(result) {
          return isList ? result : (result[0] || {});
        });
    }

    /**
     * parses the copyTasks array in each snapshot object and adds the
     * proper keys and values for status-icon-container to work correctly
     *
     * @param  {array} objects array of protectionSourceObjects
     *
     * @return {array}         the same protectionSourceObjects array with
     *                         copy tasks parsed
     */
    function parseSnapshotsCopyTasks(objects) {
      objects.forEach(function loopObjects(object) {
        var snapshots = object.snapshotsInfo;

        snapshots.forEach(function loopSnapshots(snapshot) {
          (snapshot.copyTasks || []).forEach(function parseTasks(task) {
            var copyStatus = task.copyStatus;
            var type;
            var tooltip;

            type = task.snapshotTarget.archivalTarget ?
              task.snapshotTarget.archivalTarget.vaultType : task.snapshotTarget.type;

            if (task.snapshotTarget.replicationTarget) {
              tooltip = task.snapshotTarget.replicationTarget.clusterName;
            } else if (task.snapshotTarget.archivalTarget) {
              tooltip = task.snapshotTarget.archivalTarget.vaultName;
            }

            task.classNameSuffix = _getTaskStatusString(copyStatus)
            task.taskType = type.substr(1, type.length).toLowerCase();

            task.tooltip = tooltip;
          });
        });
      });

      return objects;
    }

    /**
     * Converts task copy run status to UI status
     *
     * @param    copyStatus   The copy run staus
     * @return   The task status string value of the copy run status
     */
    function _getTaskStatusString(copyStatus) {
      return PublicStatusMapping[copyStatus];
    }

    /**
     * Requests GDPR Report data from the API
     *
     * @method   getGdprReport
     * @param    {object}   params   The API call parameters
     * @return   {object}   Promise to resolve the request for report data.
     */
    function getGdprReport(params) {
      return $http({
        method: 'get',
        url: API.public('reports/gdpr'),
        params: params,
      }).then(function successResponse(resp) {
        var data = resp.data || {};
        return data.objects || [];
      });
    }

    /**
     * Requests Runs Summary data from the API
     *
     * @method   getRunsSummary
     * @param    {object}   params   The API call parameters
     * @return   {object}   Promise to resolve the request for report data.
     */
    function getRunsSummary(params) {
      return JobRunsService.getJobRuns(params).then(
        function successResponse(runs) {
          return ReportsServiceFormatter.formatBackupJobRuns(runs || []);
        }
      );
    }

    /**
     * Request for agent status report
     *
     * @method   getAgentStatusReport
     * @param    {object}   params    API filter parameters
     * @return   {object}   Promise to resolve the request fot report data.
     */
    function getAgentStatusReport(params) {
      return $http({
        method: 'get',
        url: API.public('reports/agents'),
        params: params,
      }).then(function successResponse(resp) {
        return resp.data || [];
      });
    }

    /**
     * Request for protected objects trends report data.
     *
     * @method   getProtectedObjectsTrends
     * @param    {object}   params    API filter parameters
     * @return   {object}   Promise to resolve the request fot report data.
     */
    function getProtectedObjectsTrends(params) {
      // Postgres/backend uses isoWeek (starting monday). Adjust accordingly.
      var momentRollup = params.rollup === 'week' ? 'isoWeek' : params.rollup;

      // Leveraging intended ending day of year to ensure the correct day is
      // maintained ater adjusting for timezone.
      var endDayOfYear = moment(params.endTimeUsecs / 1000).dayOfYear();

      params.rollup = params.rollup || 'day';
      params.startTimeUsecs = moment(params.startTimeUsecs / 1000)
        .tz(params.timezone).startOf(momentRollup).valueOf() * 1000;

      params.endTimeUsecs = moment(params.endTimeUsecs / 1000)
        .tz(params.timezone).dayOfYear(endDayOfYear)
        .endOf(momentRollup).valueOf() * 1000;

      return $http({
        method: 'get',
        url: API.public('reports/protectedObjectsTrends'),
        params: params,
      }).then(function successResponse(resp) {
        return ReportsServiceFormatter.transformTrendObjects(resp.data, params);
      });
    }

    /**
     * Gets Objects Protected by Multiple Protection Jobs report
     *
     * @param  {Object} params
     *
     * @return {Promise}
     */
    function getMultipleJobs(params) {
      return $http({
        method: 'get',
        url: API.private('reports/objectsToJobs'),
        params: params,
      });
    }

    /**
     * Extend provided params with selected tenants params.
     *
     * @method   extendWithTenantParams
     * @param    {object}   params    API filter parameters
     * @return   {object}   Return modified params with tenants params.
     */
    function extendWithTenantParams(params) {
      return TenantService.extendWithTenantParams(
        params, cReportsControlsUtil.filters.tenantIds);
    }
  }
})(angular);
