<cog-settings-list-item [invalid]="formGroupErrors" cogFormGroup [label]="'authorisedUser' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="formGroupValues.type === AuthorisedUserType.Automatic">
      {{'automatic' | translate}}
    </ng-container>
    <ng-container *ngIf="formGroupValues.type === AuthorisedUserType.LocalUser">
      {{'localUser' | translate}}
    </ng-container>
    <ng-container *ngIf="formGroupValues.type === AuthorisedUserType.SpecificActiveDirectoryIdentity">
      {{'specificActiveDirectoryIdentity' | translate}}
    </ng-container>
  </cog-settings-list-item-value-display>

  <cog-settings-list-item-value-editor>
    <mat-radio-group class="margin-bottom-lg" [formControl]="formGroupControls.type">
      <mat-radio-button cogDataId="item-type-automatic" class="margin-right" [value]="AuthorisedUserType.Automatic">
        {{'automatic' | translate}}
      </mat-radio-button>
      <mat-radio-button cogDataId="item-type-local-user" class="margin-right" [value]="AuthorisedUserType.LocalUser">
        {{'localUser' | translate}}
      </mat-radio-button>
      <mat-radio-button cogDataId="item-type-specific-directory" class="margin-right"
        [value]="AuthorisedUserType.SpecificActiveDirectoryIdentity">
        {{'specificActiveDirectoryIdentity' | translate}}
      </mat-radio-button>
    </mat-radio-group>

    <!-- Info banner for authorisation type - Automatic -->
    <cog-banner type="info" class="margin-top" *ngIf="formGroupValues.type === AuthorisedUserType.Automatic">
      <div>
        {{'authorisedUserAutomaticTypeHelp' | translate}}
      </div>
    </cog-banner>

    <!-- Local User Selection -->
    <div class="margin-top" *ngIf="formGroupValues.type === AuthorisedUserType.LocalUser">
      <mat-form-field>
        <input id="local-user-input" cogDataId="local-user-input" [inheritDataId]="true" matInput required
          [formControl]="formGroupControls.user" placeholder="{{'user' | translate}}"
          [matAutocomplete]="localUserSuggestion">
        <mat-autocomplete #localUserSuggestion="matAutocomplete" [displayWith]="displayLocalUser">
          <mat-option *ngFor="let option of users$ | async" [value]="option">
            {{option.username}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="formGroupErrors?.user?.required">
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Specific Active Directory Identity -->
    <div class="row margin-top" *ngIf="formGroupValues.type === AuthorisedUserType.SpecificActiveDirectoryIdentity">
      <!--
        `coh-user-at-domain-picker` needs `domainPrincipalsHash` before mounting.
        In case of resubmit, render `coh-user-at-domain-picker` only after `domainPrincipalsHash` fetched
       -->
      <div class="col-sm-9" *ngIf="!isResubmit || domainPrincipalsHash">
        <coh-user-at-domain-picker cogDataId="domain-selector" (selectPrincipal)="selectPrincipal($event)"
          [sid]="formGroupControls.sid.value" [domains]="availableDomains" [principalsHash]="domainPrincipalsHash">
        </coh-user-at-domain-picker>
        <mat-error *ngIf="formGroupErrors?.principal?.required">
          {{'errors.required' | translate}}
        </mat-error>
      </div>
      <div class="col-sm-3">
        <mat-form-field>
          <input type="password" [formControl]="formGroupControls.password" [inheritDataId]="true" cogDataId="password"
            [placeholder]="'password' | translate" matInput>
          <mat-error *ngIf="formGroupErrors?.password?.required">
            {{'errors.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>