import { merge } from 'lodash-es';
import { get } from 'lodash-es';
// HTTP Interceptor Service: 404 checker
// Intercept 404 http response errors

;(function(angular, undefined) {
  'use strict';

  angular.module('C')
  .service('Http404Interceptor', Http404InterceptorFn)
  .config(function Intercept404Config($httpProvider) {
    $httpProvider.interceptors.push('Http404Interceptor');
  });

  function Http404InterceptorFn(_, $q, $injector) {
    var genericProxied404Message = 'errors.genericProxied404Message';
    var $rootScope;
    var NgRemoteClusterService;

    return {
      responseError: function errorInterceptor(resp) {
        var isClusterProxied = _isClusterProxied();
        var message = get(resp, 'data.message', '');
        $rootScope = $rootScope || $injector.get('$rootScope');
        NgRemoteClusterService =
          NgRemoteClusterService || $injector.get('NgRemoteClusterService');

        if (isClusterProxied &&
          // Merging these both into a single string and doing a String#includes
          // because Remote Cluster REST response 404s are proxied back to us as
          // 400 :(
          (message + resp.status).includes(404)) {
          merge(resp, {
            status: 404,
            data: {
              message: genericProxied404Message,

              // Retain the original message
              _message: message,
            },
          });
        }

        return $q.reject(resp);
      }
    };

    /**
     * Determines if the request was made via proxy to a remote cluster (Helios,
     * SPOG, etc).
     *
     * @method   _isClusterProxied
     * @return   {boolean}   True if request was made via proxy. False
     *                       otherwise.
     */
    function _isClusterProxied() {
      var originClusterId = get($rootScope, 'originClusterInfo.id') ||
        get($rootScope, 'originClusterInfo.clusterId');
      var viewingClusterId = get(NgRemoteClusterService, 'selectedScope.clusterId') ||
        get($rootScope, 'currentCluster.clusterId') ||
        originClusterId;

      return originClusterId !== viewingClusterId;
    }
  }

})(angular);
