import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const runbookAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.drRunbookEnabled && getConfigByKey(ctx.irisContext, 'runbooks.features.allow', true);

export const RunbooksConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'dr-runbook.**',
      url: '/dr-runbooks',
      loadChildren: () => import('./runbooks.module').then(m => m.RunbooksModule)
    },
  ],
  allClusterMap: {
    'dr-runbook': false,
    'dr-runbook-execution': false,
    'dr-runbook-execution-details': false,
    'dr-runbook-prepare-for-failback': false,
    'dr-runbook-runs': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'dr-runbook': runbookAccess,
      'dr-runbook-execution': runbookAccess,
      'dr-runbook-execution-details': runbookAccess,
      'dr-runbook-prepare-for-failback': runbookAccess,
      'dr-runbook-runs': runbookAccess,
    };
  }
};
