import { Injectable } from '@angular/core';
import { HeliosTaggingApiService, TagCategory, TagType } from '@cohesity/api/helios-metadata';
import { SearchServiceApi } from '@cohesity/api/v2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterFieldOption, IFieldFilterService } from '../../constants';
import { getCombinedUniqueString } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class TagsFilterService implements IFieldFilterService {

  constructor(
    private heliosTaggingService: HeliosTaggingApiService,
  ) { }

  /**
   * Tags filter value generator. This queries all the tags and generates a list of tags that can be
   * shown in the UI for user selection.
   *
   * * @returns observable with filter options
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    return this.heliosTaggingService.listTagsOp({
      categories: [TagCategory.Security],
      types: [TagType.Custom, TagType.ThirdParty],
      pageSize: 1000,
    }).pipe(
      map((response) => response.tags.map(tag => ({
        key: tag.name,
        labelKey: tag.name,
      }))),
      map((tags) => {
        tags.sort((a, b) => a.labelKey.localeCompare(b.labelKey));
        return tags;
      }),
    );
  }

  /**
   * consolidated source filters into a format that API understands
   *
   * @param selectedValues currently applied source filters
   * @param appliedApiFilters currently applied api filters
   * @returns source filter that API understands
   */
  transformFilterValues(
    selectedValues: string[],
    appliedApiFilters: SearchServiceApi.SearchObjectsParams
  ): SearchServiceApi.SearchObjectsParams {
    const apiFilters: SearchServiceApi.SearchObjectsParams = {};

    if (selectedValues.length) {
      apiFilters.tagTypes = [getCombinedUniqueString(appliedApiFilters.tagTypes, TagType.Custom)];
      apiFilters.tagNames = [getCombinedUniqueString(appliedApiFilters.tagNames, ...selectedValues)];
    }

    return apiFilters;
  }
}
