import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api, McmClusterInfo, McmUpgradeInfo } from '@cohesity/api/private';
import {
  BasicClusterInfo,
  Cluster,
  ClusterServiceApi,
  LicenseState,
  NodesServiceApi,
} from '@cohesity/api/v1';
import {
  ChassisList,
  FortknoxOnpremServiceApi,
  HeliosLoginConfiguration,
  HeliosLoginConfigurationServiceApi,
  IsVaultClusterParams,
  Node,
  OneHeliosServiceApi,
  PlatformServiceApi,
  Racks,
  UpgradeChecksResults
} from '@cohesity/api/v2';
import { AsyncBehaviorSubject, ClusterType, executeWithFallback, REGEX_FORMATS, updateWithStatus } from '@cohesity/utils';
import { uniq } from 'lodash-es';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { flagEnabled, isOneHeliosAppliance } from '../iris-context';

/**
 * This service is used for cluster related apis, and to cache application wide
 * settings. It is downgraded to Angular JS as NgClusterService.
 */
@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  /**
   * Observable to the basic cluster info async behavior subject.
   */
  private _basicClusterInfo$ = new AsyncBehaviorSubject<BasicClusterInfo>();

  /**
   * Expose basic cluster info as an observable.
   */
  readonly basicClusterInfo$ = this._basicClusterInfo$.pipe(map(info => info.result));

  /**
   * Observable to the list of Chassis async behavior subject.
   */
  private _chassisList$ = new AsyncBehaviorSubject<ChassisList>();

  /**
   * Observable variable to store and return hardwareEncryption status of the cluster if already fetched.
   */
  private _hardwareEncryptionEnabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Expose chassis list as an observable.
   */
  readonly chassisList$ = this._chassisList$.pipe(map(info => info.result));

  /**
   * Observable of cluster object. Value is set in getClusterInfo().
   */
  private _clusterInfo$ = new BehaviorSubject<Cluster>(undefined);

  /**
   * Expose clusterInfo$ as an observable.
   */
  readonly clusterInfo$ = this._clusterInfo$.asObservable();

  /**
   * The currently cached value of cluster info.
   */
  get clusterInfo() {
    return this._clusterInfo$.value;
  }

  /**
   * The currently cached value of basic cluster info.
   */
  get basicClusterInfo() {
    return this._basicClusterInfo$.value.result;
  }

  /**
   * Returns true if cluster is in MCM mode.
   *
   * @deprecated   Use isMcm() iris context util.
   */
  get isMcm(): boolean {
    if (!this.basicClusterInfo) {
      return false;
    }

    return this.basicClusterInfo.mcmMode;
  }

  /**
   * Returns true if cluster is running in MCM SaaS mode.
   *
   * @deprecated   Use isMcmSaaS() iris context util.
   */
  get isMcmSaaS(): boolean {
    if (!this.basicClusterInfo) {
      return false;
    }

    return this.basicClusterInfo.mcmMode && !this.basicClusterInfo.mcmOnPremMode;
  }

  /**
   * Returns true if hardware model of cluster includes PXG1.
   * This value is used to enable/disable remote disks tab in cluster page.
   *
   * TODO: move this to a context util.
   *
   * @deprecated   This will be moved to a context util soon. Or you should do it.
   */
  get isDisaggregatedStorage(): boolean {
    if (!this._clusterInfo$.value || !this._clusterInfo$.value.hardwareInfo) {
      return false;
    }
    return ((this._clusterInfo$.value.hardwareInfo.hardwareModels || []).includes('PXG1') ||
      (this._clusterInfo$.value.hardwareInfo.hardwareModels || []).includes('PXG2'));
  }

  /**
   * Return true if cluster is in MCM on-prem mode.
   *
   * @deprecated   Use isMcmOnPrem() iris context util.
   */
  get isMcmOnPrem(): boolean {
    if (!this.basicClusterInfo) {
      return false;
    }

    return this.basicClusterInfo.mcmMode && this.basicClusterInfo.mcmOnPremMode;
  }

  /**
   * Return true if cluster is NGCE.
   */
  get isClusterNGCE(): boolean {
    return this._clusterInfo$.value?.clusterSize === 'kNextGen';
  }

  /**
   * Internal variable to store and return cluster type of the cluster if already fetched.
   */
  private _clusterType: ClusterType;

  /**
   * Vault cluster info.
   */
  readonly vaultClusterInfo$: Observable<IsVaultClusterParams> = this.fortknoxOnprem.GetIsVaultCluster().pipe(
    catchError(() =>
      of({
        isVaultCluster: false,
      })
    ),
    shareReplay(1)
  );

  /**
   * Determines whether the cluster needs to be setup or configured.
   */
  isNewCluster(clusterInfo: Cluster): boolean {
    const {eulaConfig = null, licenseState = null} = clusterInfo;

    // If a cluster dont have eula config, its definitely a new cluster
    if (!eulaConfig) {
      return true;
    }

    // If the user accepted the eula config, and not accepted the
    // licensing, its licenseState.state will be started.
    if (eulaConfig.signedVersion && licenseState?.state === 'kStarted') {
      return true;
    }

    return false;
  }

  constructor(
    private clusterApi: ClusterServiceApi,
    private fortknoxOnprem: FortknoxOnpremServiceApi,
    private platformApi: PlatformServiceApi,
    private nodeServiceApi: NodesServiceApi,
    private heliosLoginService: HeliosLoginConfigurationServiceApi,
    private oneHeliosService: OneHeliosServiceApi,
    private http: HttpClient) {}

  /**
   * Fetch the cluster info and cache it.
   *
   * @param      forceQuery  force an API query  even if the cache is populated.
   * @return     An observable of the response.
   */
  getBasicClusterInfo(forceQuery: boolean = false): Observable<BasicClusterInfo> {
    if ((!this.basicClusterInfo && !this._basicClusterInfo$.value.loading) || forceQuery) {
      this.clusterApi.GetBasicClusterInfo().pipe(
        // Remove duplicate domains
        tap(clusterInfo => clusterInfo.domains = uniq(clusterInfo.domains)),
        updateWithStatus(this._basicClusterInfo$)
      ).subscribe();
    }
    // Rather than returning the subscription to the api call, this returns an observable of
    // the behavior subject. Since this may need to be converted to a promise from angular
    // js code. The return is a configured to emit a single value once cluster info is avialble.
    // This ends the stream, making it easy to convert to a promise.
    return this.basicClusterInfo$.pipe(
      filter(info => !!info),
      take(1)
    );
  }

  /**
   * Reset basic cluster info.
   */
  clearBasicClusterInfo() {
    this._basicClusterInfo$.reset();
  }

  /**
   * Fetch the cluster info and cache it.
   *
   * @param      noRackAssigned  Filter chassis based on racks assigned.
   * @return     An observable of the response.
   */
  getChassisInfo(noRackAssigned: boolean = false): Observable<ChassisList> {
    this.platformApi.GetChassis({noRackAssigned}).pipe(
      updateWithStatus(this._chassisList$)
    ).subscribe();

    // Rather than returning the subscription to the api call, this returns an
    // observable of the behavior subject. Since this may need to be converted
    // to a promise from angular js code, the return is a configured to emit a
    // single value once cluster info is avialble. This ends the stream, making
    // it easy to convert to a promise.
    return this.chassisList$.pipe(
      filter(info => !!info),
      take(1)
    );
  }

  /**
   * Get list of racks in cluster.
   *
   * @returns   Observable of racks object.
   */
  getRacks(): Observable<Racks> {
    return this.platformApi.GetRacks();
  }

  /**
   * Get list of racks in cluster.
   *
   * @returns   Observable of racks object.
   */
  getUpgradeCheckResults(): Observable<UpgradeChecksResults> {
    return this.platformApi.UpgradeCheckGetResults(-1);
  }


  /**
   * Get list of chassis in cluster.
   *
   * @returns   Observable of chassisList object.
   */
  getChassis(): Observable<ChassisList> {
    return this.platformApi.GetChassis({});
  }

  /**
   * Request nexus private api to get hardware info.
   */
  getHardwareInfo(): Observable<any> {
    return this.http.get<any>(Api.private('nexus/node/hardware_info'));
  }

  /**
   * Request to get the embedded login configuration for Helios.
   *
   * @returns   Promise with Helios Login Configuration.
   */
  getMcmConfiguration(): Observable<HeliosLoginConfiguration> {
    return this.heliosLoginService.GetHeliosLoginConfig();
  }

  /**
   * Get cluster info using empty query params.
   */
  getClusterInfo(forceQuery: boolean = true, params: ClusterServiceApi.GetClusterParams = {}): Observable<Cluster> {
    if (forceQuery || !this._clusterInfo$.value) {
      return this.clusterApi.GetCluster(params).pipe(
        tap(clusterInfo => this._clusterInfo$.next(clusterInfo))
      );
    } else {
      return of(this._clusterInfo$.value);
    }
  }


  /**
   * Update license state in the cluster info
   *
   * @param state Current license state to be updated
   * @returns Observable of updated cluster info
   */
  updateLicenseState(state: LicenseState['state']): Observable<Cluster> {
    const clusterInfo = {
      ...this.clusterInfo,
      licenseState: {
        ...this.clusterInfo.licenseState,
        state: state,
      },
    };
    return this.updateClusterInfo(clusterInfo as ClusterServiceApi.UpdateClusterParams);
  }

  /**
   * Update cluster info
   *
   * @param clusterInfo Updated clusterInfo
   * @returns Observable of updated cluster info
   */
  updateClusterInfo(clusterInfo: ClusterServiceApi.UpdateClusterParams): Observable<Cluster> {
    return this.clusterApi
      .UpdateCluster({ Body: clusterInfo })
      .pipe(tap(response => this._clusterInfo$.next(response)));
  }

  /**
   * Returns whether hardware encryption is enabled or not.
   *
   * @returns An observable with hardware encryption status.
   */
  getHardwareEncryptionStatus(): Observable<boolean> {
    if (!this._clusterInfo$.value) {
      return this.getClusterInfo().pipe(
        tap(clusterInfo => this._hardwareEncryptionEnabled$.next(clusterInfo.hardwareEncryptionEnabled ?? false)),
        map(clusterInfo => clusterInfo.hardwareEncryptionEnabled));
    } else {
      this._hardwareEncryptionEnabled$.next(this._clusterInfo$.value.hardwareEncryptionEnabled);
    }
    return this._hardwareEncryptionEnabled$.asObservable();
  }

  /**
   * Returns whether cluster creation is in progress or not.
   *
   * This function checks 3 sources to determine if cluster creation is in progress:
   * 1. It checks the cluster bringup status is in progress.
   * 2. It checks if a Helios setup is in progress.
   * 3. It checks if a Kubernetes setup is in progress.
   *
   * @returns An observable that emits true if either the Helios setup or cluster
   * creation is in progress, otherwise false.
   */
  isClusterCreateInProgress(): Observable<boolean> {
    const ctx = {
      basicClusterInfo: this.basicClusterInfo
    };
    const isOneHeliosPlatform = isOneHeliosAppliance(ctx as any);
    const heliosSetup$ = isOneHeliosPlatform ? this.isOneHeliosSetupInProgress() : of(false);

    // Observable for checking cluster bringup status from the API
    const clusterBringupStatus$ = this.http
      .get<any>(Api.private('nexus/cluster/bringup_status'))
      .pipe(map(data => !!data?.inProgress));

    // Combine both observables and return an observable that emits `true` if either is in progress
    return forkJoin([heliosSetup$, clusterBringupStatus$]).pipe(
      map(([heliosInProgress, clusterInProgress]) => heliosInProgress || clusterInProgress),
      catchError(() => of(true)),
    );
  }

  /**
   * Get cluster type based on hardware info.
   *
   * @returns   Observable of cluster type of the node.
   */
  getClusterType(): Observable<ClusterType> {
    let clusterType$: Observable<any>;
    if (this._clusterType === undefined) {
      clusterType$ = this.getHardwareInfo().pipe(map(this.mapHardwareInfoToClusterType));
    } else {
      clusterType$ = of(this._clusterType);
    }
    return clusterType$.pipe(tap(clusterType => this._clusterType = clusterType));
  }

  /**
   * Check if a cluster is an all flash cluster.
   *
   * @params The params for node service api.
   * @return Whether the cluster is all flash.
   */
  isAllFlashCluster(
    params: NodesServiceApi.GetNodesParams | PlatformServiceApi.GetNodesParams = {}
  ): Observable<boolean> {
    const ctx = {
      basicClusterInfo: this.basicClusterInfo,
    };
    const shouldUseV2 = flagEnabled(ctx as any, 'platformBucket2v2ApiMigration');
    const fetchNodesV1 = () => this.nodeServiceApi.GetNodes(params) as unknown as Observable<Node[]>;
    const fetchNodesV2 = () => this.platformApi.GetNodes(params);
    const fetchNodes$ = shouldUseV2 ? executeWithFallback<Node[]>(fetchNodesV2, fetchNodesV1) : fetchNodesV1();

    return fetchNodes$.pipe(
      // Go through all node types to determine if all flash cluster.
      map(nodes => nodes.every(node => node.nodeType === 'AllFlashNode')),

      // If the GetNodes() errors out (like for tenants), return false.
      catchError(() => of(false))
    );
  }

  /**
   * Return true if all nodes on the cluster belong to C6k platform.
   *
   * @return Whether the cluster is C6k.
   */
  isC6kCluster(): Observable<boolean> {
    return this.getClusterInfo(false).pipe(
      map(clusterInfo => {
        const hardwareModels = clusterInfo?.hardwareInfo?.hardwareModels || [];

        if (hardwareModels.length === 0) {
          return false;
        }

        return hardwareModels.every(hardwareModel => REGEX_FORMATS.c6kPlatform.test(hardwareModel));
      }),

      // If the getClusterInfo() errors out, return false.
      catchError(() => of(false))
    );
  }

  /**
   * Map hardwareInfo to cluster type.
   *
   * @param   hardwareInfo Response of get hardware info api request.
   * @returns ClusterType of the cluster to be created.
   */
  private mapHardwareInfoToClusterType(hardwareInfo: any): ClusterType {
    let clusterType: ClusterType;
    switch (true) {
      case REGEX_FORMATS.virtualRobo.test(hardwareInfo.productModel):
        clusterType = ClusterType.VmRobo;
        break;
      case REGEX_FORMATS.virtualEditionCluster.test(hardwareInfo.chassisModel):
        clusterType = ClusterType.VirtualEdition;
        break;
      case REGEX_FORMATS.physicalRobo.test(hardwareInfo.productModelType):
        clusterType = ClusterType.PhysicalRobo;
        break;
      case REGEX_FORMATS.virtual.test(hardwareInfo.chassisType):
        clusterType = ClusterType.CloudEdition;
        break;
      default:
        clusterType = ClusterType.Physical;
    }
    return clusterType;
  }

  /**
   * Get a list of all registered clusters in Mcm
   *
   * @returns An observable to be resolved with a list of cluster objects
   */
  getAllClusters(): Observable<McmUpgradeInfo> {
    return this.http.get(Api.mcm('upgradeInfo'));
  }

  /**
   * Get a list of all registered clusters
   *
   * @returns An observable to be resolved with a list of cluster objects
   */
  getTransformedClusters(): Observable<McmClusterInfo[]> {
    return this.getAllClusters().pipe(
      map(infos => infos?.upgradeInfo ?? [])
    );
  }

  /**
   * Determines if the setup for Helios and Kubernetes is still in progress.
   *
   * @returns An observable that emits true if the setup is still in progress, otherwise false.
   */
  isOneHeliosSetupInProgress(): Observable<boolean> {
    return forkJoin({
      heliosInstallationLogs: this.oneHeliosService.InstallLogs(),
      kubernetesHealthStatus: this.platformApi.GetKubernetesInfraHealthStatus(),
    }).pipe(
      map(({ heliosInstallationLogs, kubernetesHealthStatus }) => {
        const isHeliosInstallationIncomplete = heliosInstallationLogs.heliosInstallStatus !== 'Success';
        const isKubernetesSetupInProgress =
          (
            kubernetesHealthStatus.overallK8SState === 'Pending' ||
            kubernetesHealthStatus.overallK8SState === 'Initializing' ||
            kubernetesHealthStatus.overallK8SState === 'Unknown'
          ) || (
            !!kubernetesHealthStatus.overallK8SHealthStatus &&
            kubernetesHealthStatus.overallK8SHealthStatus !== 'Healthy'
          );
        return isHeliosInstallationIncomplete || isKubernetesSetupInProgress;
      }),
      // In case of any error, return false
      catchError(() => of(false))
    );
  }
}
