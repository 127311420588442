import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: IDP Service Formatter

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('IdpServiceFormatter', IdpServiceFormatterFn);

  function IdpServiceFormatterFn(_) {

    return {
      untransformIdpConfig: untransformIdpConfig,
    };

    /**
     * Untransforms an Identity Provider prior to posting to API.
     *
     * @method     untransformIdpConfig
     * @param      {Object}   idpConfig       The IDP Provider config object
     * @return     {Object}  A copy with untransformed data
     */
    function untransformIdpConfig(idpConfig) {
      var idpRoles;
      if (!idpConfig) {
        return;
      }

      if (idpConfig.idpRoles) {
        idpRoles = [];
        idpRoles.push(idpConfig.idpRoles.name);
      }

      assign(idpConfig, {
        certificateFilename: idpConfig.ssoCertificate.name,
        roles: idpRoles,
        tenantId: get(idpConfig, 'tenant.tenantId'),
      });

      return idpConfig;
    }
  }

})(angular);
