<div class="cohesity-and-veritas-container">
  <img
    class="cohesity-and-veritas-img-light"
    alt="Cohesity & Veritas"
    src="/assets/i/cohesity-veritas-login-{{ isLightTheme ? 'light' : 'dark' }}.svg"
    draggable="false" />
  <img
    class="we-ve-joined-forces-img margin-top-xl padding-top-sm"
    alt="We've joined forces!"
    src="/assets/i/we-ve-joined-forces-login-{{ isLightTheme ? 'light' : 'dark' }}.svg"
    draggable="false" />
  <div class="cohesity-and-veritas-description margin-top-sm padding-bottom-sm">
    <p class="mat-h3">{{'login.veritasDescription1' | translate}}</p>
    <p class="mat-h3">{{'login.veritasDescription2' | translate}}</p>
  </div>
  <div class="cohesity-and-veritas-buttons margin-top-lg">
    <a mat-flat-button color="primary" class="margin-right-lg" target="_blank" [href]="welcomeUrl">
      {{'login.watchWelcomeVideo' | translate}}
    </a>
    <a mat-button target="_blank" [href]="detailUrl">{{'login.getTheDetails' | translate}}</a>
  </div>
</div>
