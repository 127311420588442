import { get } from 'lodash-es';
// Service: PublicSourceService utility service.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.pubSourceServiceUtil', ['C.pubJobServiceFormatter'])
    .service('PubSourceServiceUtil', PubSourceServiceUtilFn);

  function PubSourceServiceUtilFn(_, PubJobServiceFormatter, SOURCE_KEYS) {
    return {
      getEnvProtectionSource: getEnvProtectionSource,
      getEnvProtectionSourceKey: getEnvProtectionSourceKey,
      getProtectionSourceType: getProtectionSourceType,
      isSameSqlInstance: isSameSqlInstance,
      transformSource: transformSource,
    };

    /**
     * decorates our source tree without extra job logic
     *
     * @param  {Array} source   source tree
     * @return {Array}          modified source tree
     */
    function transformSource(source) {
      source.forEach(function loopRootNodes(sourceNode) {
        PubJobServiceFormatter.decorateSource(sourceNode);

        if (get(sourceNode, 'nodes.length')) {
          sourceNode.nodes = transformSource(sourceNode.nodes);
        }
      });

      return source;
    }

    /**
     * Gets the node type of a given protectionSource.
     *
     * @method   getProtectionSourceType
     * @param    {object}   protectionSource   The protectionSource to look at.
     * @return   {string}   The kType of the given protectionSource.
     */
    function getProtectionSourceType(protectionSource) {
      return getEnvProtectionSource(protectionSource).type;
    }

    /**
     * Gets the environment-specific protectionSource object.
     *
     * @method   getEnvProtectionSource
     * @param    {object}   protectionSource   The protectionSource to look at.
     * @return   {object}   The environment typed protectionSource object.
     */
    function getEnvProtectionSource(protectionSource) {
      return protectionSource[getEnvProtectionSourceKey(protectionSource)];
    }

    /**
     * Gets the key of the typed envPerotectionSource for the given
     * protectionSource.
     *
     * @method   getEnvProtectionSourceKey
     * @param    {object}   protectionSource   The protectionSource to look at.
     * @return   {string}   The evironment typed key of for this
     *                      protectionSource.
     */
    function getEnvProtectionSourceKey(protectionSource) {
      return SOURCE_KEYS[protectionSource.environment];
    }

    /**
     * Determine if 2 given nodes share the same SQL Instance.
     *
     * @method   isSameSqlInstance
     * @param    {object}    source1   One source.
     * @param    {object}    source2   One source.
     * @return   {boolean}   True if the are from teh same SQL Instance. False
     *                       otherwise.
     */
    function isSameSqlInstance(source1, source2) {
      var thisSqlSource = source1.protectionSource.sqlProtectionSource;
      var thatSqlSource = source2.protectionSource.sqlProtectionSource;

      // Fail early if either `sqlProtectionSource` is undefined (one or more of
      // the inputs isn't a SQL source).
      return ![thisSqlSource, thatSqlSource].includes(undefined) &&
        // Otherwise, proceed with the actual check.
        thatSqlSource.ownerId === thisSqlSource.ownerId &&
        thatSqlSource.id.instanceId === thisSqlSource.id.instanceId;
    }
  }
})(angular);
