import { fill } from 'lodash-es';
import { merge } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Oracle recovery settings

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.dbRestore')
    .controller('OracleSettingsCtrl', OracleSettingsCtrlFn)
    .component('oracleSettings', {
      bindings: {
        // @type   {Object}   specifies the Oracle specific restore parameters
        restoreParams: '=',
      },
      templateUrl:
        'app/protection/recovery/db/oracle-settings/oracle-settings.html',
      controller: 'OracleSettingsCtrl',
    });

  /**
   * @ngdoc component
   * @name C.dbRestore:oracleSettings
   * @function
   *
   * @description
   * Component to render Oracle specific recovery settings.
   *
   * @example
   * <oracle-settings restore-params=
       "shared.restoreAppObject.restoreParams.oracleRestoreParams">
     </oracle-settings>
   *
   */
  function OracleSettingsCtrlFn(_, cUtils, FILESIZES) {
    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,

      // Variables exposed to Template.
      state: {},
      useDBConfigFilePath: true,

      // Methods exposed to Template.
      analyzeRedoLogGroupCount: analyzeRedoLogGroupCount,
      handleFRASizeChange: handleFRASizeChange,
      handleNumTempFilesChange: handleNumTempFilesChange,
      handleRedoLogGroupSizeChange: handleRedoLogGroupSizeChange,
      initRecoveryConfig: initRecoveryConfig,

      // Methods used to validate the inputs.
      isPositiveInteger: isPositiveInteger,
      isPathToFile: isPathToFile,
      isDistinctPath: isDistinctPath,
    });

    /**
     * @method   $onInit
     *
     * Component initialization hook.
     */
    function $onInit() {
      $ctrl.initRecoveryConfig();
    }

    /**
     * Checks whether the input path is unique and non-empty.
     *
     * @method   isDistinctPath
     * @param    {string}   path  view value from the input field.
     * @return   {boolean}  returns false if path is already given by the
     *                      user or the path is empty, true otherwise.
     */
    function isDistinctPath(path) {
      return !$ctrl.oracleDBConfig.controlFilePathVec.includes(path) && path;
    }

    /**
     * Determines whether the input path is ending with "/" or not.
     *
     * @method   isPathToFile
     * @param    {string}   path  view value from the input field.
     * @return   {boolean}  returns false only if path is ending with "/",
     *                      true otherwise.
     */
    function isPathToFile(path) {
      if (path) {
        return path.charAt(path.length-1) !== '/';
      }
      return true;
    }

    /**
     * Determines whether the input path is ending with "/" or not.
     *
     * @method   isPositiveInteger
     * @param    {string}   num  view value from the input field.
     * @return   {boolean}  returns true only if num is a positive integer or
     *                      not defined, false otherwise.
     */
    function isPositiveInteger(num) {
      if (!num) {
        return true;
      }
      var val = Number(num);
      if (val && val>0 && Number.isInteger(val)) {
        return true;
      }
      return false;
    }

    /**
     * Handles the update of the Oracle DB Config with the Number of Temp files
     * to rename.
     *
     * @method   handleNumTempFilesChange
     */
    function handleNumTempFilesChange() {
      if ($ctrl.oracleDBConfig.numTempfiles <= 0) {
        $ctrl.oracleDBConfig.numTempfiles = undefined;
      }
    }

    /**
     * Handles the update of the Oracle DB Config with the FRA size.
     *
     * @method   handleFRASizeChange
     */
    function handleFRASizeChange() {
      $ctrl.oracleDBConfig.fraSizeMb = _convertBytesToMBs($ctrl.state.fraSize);
    }

    /**
     * Handles the update of the Oracle DB Config with the Redo Log Group size.
     *
     * @method   handleRedoLogGroupSizeChange
     */
    function handleRedoLogGroupSizeChange() {
      $ctrl.oracleDBConfig.redoLogConf.sizeMb =
        _convertBytesToMBs($ctrl.state.redoLogGroupSize);
    }

    /**
     * Handles the conversion of the bytes to MBs as required by Magneto.
     *
     * @method   _convertBytesToMBs
     * @param    {Number}   bytes   Specifies the number of bytes.
     * @return   {Number}   Specifies the number of Megabytes.
     */
    function _convertBytesToMBs(bytes) {
      return  cUtils.bytesToUnit(bytes, 'MiB');
    }

    /**
     * Analyzes the Redo Log Group Count to decide whether multiplexing of Redo
     * Log Files should be enabled.
     * Reference for Managing Redo Log.
     * https://docs.oracle.com/cd/B19306_01/server.102/b14231/onlineredo.htm
     *
     * @method   analyzeRedoLogGroupCount
     */
    function analyzeRedoLogGroupCount() {
      // Redo Log consists of 2 or more preallocated files.
      if ($ctrl.oracleDBConfig.redoLogConf.numGroups > 1) {
        $ctrl.enableRedoLogFileMultiplexing = true;

        // Oracle Database allows a multiplexed redo log, meaning that two or
        // more identical copies of the redo log can be automatically
        // maintained in separate locations.
        $ctrl.oracleDBConfig.redoLogConf.groupMemberVec = fill(Array(2),'');
      } else {
        $ctrl.enableRedoLogFileMultiplexing = false;
        $ctrl.oracleDBConfig.redoLogConf.groupMemberVec = undefined;
      }
    }

    /**
     * Initializes the Oracle Recovery Configurations.
     *
     * @method   initRecoveryConfig
     */
    function initRecoveryConfig() {
      $ctrl.state = {
        // NOTE: Default values are sync with OracleDBConfig defined in
        // magneto/base/magneto.proto:
        // Default value for FRA Size.
        fraSize: 2 * FILESIZES.gigabyte,

        // Default value for Redo Log Group Size.
        redoLogGroupSize: 20 * FILESIZES.megabyte,
      };

      // Initialize default oracle restore params.
      $ctrl.oracleDBConfig = {
        controlFilePathVec: [],

        // Archive Log Mode is enabled by default.
        enableArchiveLogMode: true,
        redoLogConf: {
          groupMemberVec: [],
          memberPrefix: 'redo',
          sizeMb: _convertBytesToMBs($ctrl.state.redoLogGroupSize),
        },
        fraSizeMb: _convertBytesToMBs($ctrl.state.fraSize),
      };

      if ($ctrl.restoreParams.alternateLocationParams) {
        merge($ctrl.oracleDBConfig,
          $ctrl.restoreParams.alternateLocationParams.oracleDBConfig);

        // Point both to same object so that updates in $ctrl.oracleDBConfig
        // are reflected in restoreParams.
        $ctrl.restoreParams.alternateLocationParams.oracleDBConfig =
          $ctrl.oracleDBConfig;

        // Disable config file path setting, if Oracle DB Config is present.
        // This can happen only when a re-configure was triggerred on an
        // existing recovery job.
        if ($ctrl.restoreParams.alternateLocationParams.oracleDbConfig) {
          $ctrl.useDBConfigFilePath = false;
        }

      }
    }
  }
})(angular);
