// Module: Policy Manager

;(function(angular) {
  'use strict';

  angular.module('C.policies', ['C.policyService', 'C.jobRunsService',
    'C.focus'])
    .config(policiesModuleConfigFn);

  /**
   * policy module configuration
   */
  function policiesModuleConfigFn($stateProvider) {
    $stateProvider
      .state('policies', {
        url: '/protection/policies',
        help: 'protection_policies',
        title: 'Policy Manager',
        canAccess: 'PROTECTION_POLICY_VIEW',
        allClustersSupport: {
          singleClusterState: 'policies',
          allClustersState: 'global-policies',
        },
        // Note: Policies controller is removed.
        // But retaining policies state because it is parent state of policy
        template: '<div>policies</div>',
      })
      .state('policy', {
        url: '^/protection/policies/policy/{policyId}',
        help: 'protection_policies_policy',
        title: 'Policy Details',
        canAccess: 'PROTECTION_POLICY_VIEW',
        params: {
          policyId: { type: 'string' },
        },
        parentState: 'policies',
        // Note: policyController is removed. But this state is retained as
        // there are still links in ui.json that refer to this state.
        // For eg. jobDescWithSourcesNew.kVMware
        template: '<div>policy</div>',
      })
  }

}(angular));
