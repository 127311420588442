import { includes } from 'lodash-es';
import { filter } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { concat } from 'lodash-es';
// Service: Network Service
import { getConfigByKey } from '@cohesity/iris-core';

;(function (angular, undefined) {

  'use strict';

  angular
    .module('C')
    .service('NetworkService', networkServiceFn);

  function networkServiceFn(_, $http, API, NETWORK, NETWORK_INTERFACE_TYPE,
    NgPassthroughOptionsService, FEATURE_FLAGS, NgIrisContextService) {

    return {
      getClusterSubnets: getClusterSubnets,
      getAthenaClusterSubnets: getAthenaClusterSubnets,
      createClusterSubnets: createClusterSubnets,
      getInterfaces: getInterfaces,
      ifaceNameToVlan: ifaceNameToVlan,
      vlanToIfaceName: vlanToIfaceName,
      getInterfaceGroups: getInterfaceGroups,
      getClusterAirgapStatus: getClusterAirgapStatus,
      updateClusterAirgapStatus: updateClusterAirgapStatus,
      isIpV6Supported: isIpV6Supported,
      isAirgapSupported: isAirgapSupported
    };


    /**
     * Fetch network interfaces
     * API is expected to return bond and vlan interfaces
     *
     * @method    getInterfaces
     * @param     {array}   [types=['bond', 'vlan']]   filter by interface type
     * @param     {object}  headers Any headers to pass in the request
     * @returns   {array}   network interfaces
     */
    function getInterfaces(types, headers) {
      types = types || ['bond', 'vlan'];

      return $http({
        method: 'get',
        url: API.private('nexus/node/list_network_interfaces?cache=true'),
        headers: Object.assign({}, headers, NgPassthroughOptionsService.requestHeaders),
      }).then(function gotInterfaces(resp) {

        var ifaces = [];
        var filteredInterfaces = [];

        if (resp.data && resp.data.networkInterfaces.length) {
          ifaces = resp.data.networkInterfaces;
        }

        filteredInterfaces = _filterInterfaces(ifaces, types);

        return sortBy(filteredInterfaces, ['ifaceType', 'ifaceName']);
      });
    }

    /**
     * Fetch Airgap Status
     *
     * @method    getClusterAirgapStatus
     * @returns   {boolean}  airgap status
     */
    function getClusterAirgapStatus(){
      return $http({
        method: 'get',
        url: API.public('cluster/status'),
      }).then(function gotDataSuccess(response) {
        const currentStatus = response.data.airgapConfig.airgapStatus;
        return currentStatus==='kEnable';
      });
    }

    /**
     * Update Airgap Status
     *
     * @method    updateClusterAirgapStatus
     * @param     {status}   [values=['enable', 'disable']]   update status to provided one
     * @returns   {object}  returns updated status cluster data
     */
    function updateClusterAirgapStatus(status) {
      return $http({
        method: 'put',
        url: '/v2/clusters/airgap',
        data: {"airgapStatus": status}
      }).then(function getUpdatedStatus(response) {
        return response.data || [];
      });
    }

    /**
     * Flag indicating whether IPv6 support controls to be displayed in
     * VLAN and VIPs page.
     *
     * It depends on feature flag enableIpv6, and also whether the
     * cluster is not a OneHelios appliance manager which does not support
     * Ipv6 yet.
     *
     * @method    isIpV6Supported
     * @param     None
     * @returns   {boolean}  true if supported, else false
     */
    function isIpV6Supported() {
       return FEATURE_FLAGS.enableIpv6 && getConfigByKey(NgIrisContextService.irisContext, 'networking.ipv6', true);
    }

    function isAirgapSupported() {
      return FEATURE_FLAGS.airgapUpdate && getConfigByKey(NgIrisContextService.irisContext, 'networking.airgap', true);
    }

    /**
     * filter list of interfaces as per input
     *
     * @method    _filterInterfaces
     * @param     {array}   interfaces                 network interfaces
     * @param     {array}   [types=['bond', 'vlan']]   filter by interface type
     * @returns   {array}   network interfaces
     */
    function _filterInterfaces(interfaces, types) {
      var needBonds = includes(types, 'bond');
      var needVlans = includes(types, 'vlan');
      var filteredInterfaces = [];

      if (needBonds) {
        filteredInterfaces = filter(interfaces,
          ['ifaceType', NETWORK_INTERFACE_TYPE.KIfBondMaster]);
      }

      if (needVlans) {
        filteredInterfaces = concat(filteredInterfaces, filter(interfaces,
          ['ifaceType', NETWORK_INTERFACE_TYPE.KIfVlanTagged]));
      }

      // If empty, return a default type bond0 for backwards compatibility
      if (needBonds && !filteredInterfaces.length) {
        filteredInterfaces = [
          {'ifaceName': NETWORK.defaultInterface,
           'ifaceType': NETWORK_INTERFACE_TYPE.KIfBondMaster,
           'speed': 'UNKNOWN' }];
      }

      return filteredInterfaces;
    }

    /**
     * Convert vlan interface Name to separate parent interface name and vlanId
     *
     * @method    ifaceNameToVlan
     * @param     {string}   ifaceName   vlan interface name.
     *                                   assumes <inteface>.<vlanId>
     * @returns   {object}   object with ifaceName and vlanId
     */
    function ifaceNameToVlan(ifaceName) {
      var ifaceNameVec = ifaceName.split('.');

      // Use default interface if name not present
      if (ifaceNameVec.length === 1) {
        return {'ifaceName': NETWORK.defaultInterface,
          'vlanId': Number(ifaceNameVec[0])};
      }

      return {'ifaceName': ifaceNameVec[0], 'vlanId': Number(ifaceNameVec[1])};
    }

    /**
     * Builds vlan interface name from vlanId and parent interface
     *
     * @method    vlanToIfaceName
     * @param     {string}   parentIfaceName   parent interface name of vlan
     * @param     {number}   vlanId            vlan ID
     * @returns   {string}   vlan interface name
     */
    function vlanToIfaceName(parentIfaceName, vlanId) {

      // If invalid, use vlanId for backwards compatibility
      if (!parentIfaceName) {
        return vlanId.toString();
      }

      return parentIfaceName + '.' + vlanId;
    }

    /**
     * Get the list of subnets for the cluster
     *
     * @method    getClusterSubnets
     * @returns   {object}   Promise to return list of subnets or error
     */
    function getClusterSubnets() {
      const clusterSubnetsURL = FEATURE_FLAGS.platformBucket2v2ApiMigration
        ? API.privateV2('clusters/subnets')
        : API.private('clusterSubnets');
      return $http({
        method: 'get',
        url: clusterSubnetsURL,
      }).then(function gotSubnets(resp) {
        return resp.data || [];
      });
    }

    /**
     * Get athena subnet from the list of the cluster subnets
     *
     * @method    getAthenaClusterSubnets
     * @param     {array}   subnets   List of cluster subnets
     * @returns   {array}   Athena cluster subnets
     */
    function getAthenaClusterSubnets(subnets) {
      return subnets.filter(subnet => subnet.component === 'athena');
    }

    /**
     * Create cluster subnets.
     * Make sure you do a get before put since this api removes subnets that
     * are not in the list.
     *
     * @method   createClusterSubnets
     * @param    {object}   subnets   subnets that needs to be added
     * @return   {object}   promise to return success or error
     */
    function createClusterSubnets(subnets) {
      return $http({
        method: 'put',
        url: API.private('clusterSubnets'),
        data: subnets
      }).then(function createClusterSubnetSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * Get network interface groups.
     * The response data include bond and vlan interfaces.
     *
     * @method  getInterfaceGroups
     * @return  {array} An array of interface groups
     */
    function getInterfaceGroups() {
      return $http({
        method: 'get',
        url: API.public('interfaceGroups'),
      }).then(function gotInterfaceGroupsSuccess(resp) {
        return resp.data || [];
      });
    }
  }
})(angular);
