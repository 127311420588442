import { assign } from 'lodash-es';
// Service: LDAP Service Formatter

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('LdapServiceFormatter', LdapServiceFormatterFn);

  function LdapServiceFormatterFn() {

    // This Service's API
    return {
      transformConnectionStatus: transformConnectionStatus,
      transformLdapProvider: transformLdapProvider,
      untransformLdapProvider: untransformLdapProvider,
    };


    /**
     * Transforms an LDAP Provider by converting v2 response to v1, defaulting values and adding convenience
     * properties and returns the same object.
     *
     * @method     transformLdapProvider
     * @param      {Object}  ldapConfig    The LDAP Provider config
     * @return     {Object}  The same object with added and transformed data
     */
    function transformLdapProvider(ldapConfig) {
      var stub = {
        name: '',
        baseDistinguishedName: '',
        userDistinguishedName: '',
        preferredLdapServerList: [],
      };

      if (!ldapConfig) {
        return;
      }

      // v2 to v1
      if (ldapConfig.authType) {
        ldapConfig.authType = `k${ldapConfig.authType}`;
      }
      if (ldapConfig.simpleAuthParams) {
        ldapConfig.useSsl = ldapConfig.simpleAuthParams.useSsl;
        ldapConfig.userDistinguishedName = ldapConfig.simpleAuthParams.userDistinguishedName;
        ldapConfig.userPassword = ldapConfig.simpleAuthParams.userPassword;
      }
      if (ldapConfig.attributeUsername) {
        ldapConfig.attributeUserName = ldapConfig.attributeUsername;
      }
      if (ldapConfig.preferredLdapServers) {
        ldapConfig.preferredLdapServerList = ldapConfig.preferredLdapServers;
      }

      // Stub fields which may be missing because of CLI usage.
      ldapConfig = assign(stub, ldapConfig);

      // Determine type.
      ldapConfig._type = !!ldapConfig.domainName ? 'kDomainName' : 'kPreferred';

      // Add translation key for authType kValue.
      ldapConfig._authTypeKey = 'enum.ldapAuthType.' + ldapConfig.authType;

      return ldapConfig;
    }

    /**
     * Untransforms an LDAP Provider prior to posting to API.
     *
     * @method     untransformLdapProvider
     * @param      {Object}  ldapConfig    The LDAP Provider config object
     * @return     {Object}  A copy with untransformed data
     */
    function untransformLdapProvider(ldapConfig) {
      var ldapConfigCopy = angular.copy(ldapConfig);

      if (!ldapConfigCopy) {
        return;
      }

      // v1 to v2
      if (ldapConfigCopy.authType) {
        ldapConfigCopy.authType = ldapConfigCopy.authType.replace(/^k/, '');
      }
      if (ldapConfigCopy.attributeUserName) {
        ldapConfigCopy.attributeUsername = ldapConfigCopy.attributeUserName;
      }
      if (ldapConfigCopy.preferredLdapServerList) {
        ldapConfigCopy.preferredLdapServers = ldapConfigCopy.preferredLdapServerList;
      }
      ldapConfigCopy.simpleAuthParams = {
        useSsl: ldapConfigCopy.useSsl,
        userDistinguishedName: ldapConfigCopy.userDistinguishedName,
        userPassword: ldapConfigCopy.userPassword,
      }

      // Set adDomainName as undefined if selected 'None'.
      if (ldapConfigCopy.adDomainName === 'none') {
        ldapConfigCopy.adDomainName = undefined;
      }

      // Backend only wants one of the two properties. Remove the unused one.
      if (ldapConfigCopy._type === 'kDomainName') {
        ldapConfigCopy.preferredLdapServerList = undefined;
      } else {
        ldapConfigCopy.domainName = undefined;
      }

      // Remove decorations.
      ldapConfigCopy._type = ldapConfigCopy._authTypeKey = undefined;

      return ldapConfigCopy;
    }

    /**
     * Transforms the connection status object which is a raw error message from
     * Bridge with no errorCode.
     *
     * @method      transformConnectionStatus
     * @param       {Object}    status    object with connection status and
     *                                    message.
     */
    function transformConnectionStatus(response) {
      var status = {
        success: (response?.connectionStatus === 'NoError') ? true : false,
      };

      // error
      if (!status.success) {
        switch (response.message) {
          case 'LDAP connection failed. Error: Can\'t contact LDAP server':
            status.msg = 'enum.connectionStatus.kUnableToConnect';
            break;

          case 'LDAP connection failed. Error: Invalid credentials':
            status.msg = 'enum.connectionStatus.kInvalidCredentials';
            break;

          case 'LDAP connection failed. Error: Bad parameter to an ldap routine':
            status.msg = 'enum.connectionStatus.kInvalidLdapConfiguration';
            break;

          default:
            status.msg = 'enum.connectionStatus.kUnknownError';
        }
      }

      return status;
    }
  }

})(angular);
