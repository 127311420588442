import { assign } from 'lodash-es';
// Controller: Physical Server: Search step

; (function (angular, undefined) {
  'use strict';

  var moduleName = 'C.vmdkRecovery';

  angular
    .module(moduleName)
    .controller('VmdkRecoverySearchController',
      VmdkRecoverySearchControllerFn);

  function VmdkRecoverySearchControllerFn($scope, SearchService,
    VmdkRecoveryService) {
    var $ctrl = this;

    assign($ctrl, {
      // General scope vars
      contextActions: {},

      // Scope methods
      preProcessSearchResults: preProcessSearchResults,
      addToCart: VmdkRecoveryService.addToCart,
    });


    // watcher
    // Update the contextActions object for the new paged set
    $scope.$watchCollection('shared.pagedResults', buildContextActions);


    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     onInit
     */
    $ctrl.$onInit = function onInit() {
      VmdkRecoveryService.fetchDependencies();
      VmdkRecoveryService.initSearchConfig();
    };

    /**
     * Builds context actions with the given entities.
     *
     * @method     buildContextActions
     * @param      {object}  entities  One or more Entities
     * @return     {object}        The updated context actions.
     */
    function buildContextActions(entities) {
      entities = [].concat(entities || []);
      entities.forEach(function eachEntityFn(entity) {
        $ctrl.contextActions[entity._id] = [
          {
            translateKey: 'selectServer',
            action: function selectEntityAction() {
             VmdkRecoveryService.addToCart(entity);
            },
          },
        ];
      });
      return $ctrl.contextActions;
    }

    /**
     * Pre-process the search results. Uses the SearchService's transform
     * method under the hood + a little more.
     *
     * @method     preProcessSearchResults
     * @param      {object}  results  The server's raw response object
     * @return     {array}   The results of the SearchService's transformer
     */
    function preProcessSearchResults(results) {
      var resp = SearchService.processVmsSearchResults(results, true);
      if (!resp.length) {
        return [{
          isEmpty: true
        }];
      }
      return resp;
    }

  }

})(angular);
