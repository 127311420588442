import { Pipe, PipeTransform } from '@angular/core';
import { ScanHealthStatus } from '@cohesity/api/argus';

export type IconType = 'solid' | 'outline';

@Pipe({
  name: 'scanHealthStatusIcon',
})
export class ScanHealthStatusIconPipe implements PipeTransform {
  /**
   * Scan Health Status Icon Map
   */
  iconMap: Record<IconType, Record<ScanHealthStatus, string>> = {
    solid: {
      [ScanHealthStatus.Queued]: 'helix:status-queue-alt!default',
      [ScanHealthStatus.Accepted]: 'helix:status-queue-alt!default',
      [ScanHealthStatus.Canceled]: 'helix:status-cancel-alt!default',
      [ScanHealthStatus.Canceling]: 'helix:status-cancel-alt!default',
      [ScanHealthStatus.Failed]: 'helix:status-error-alt!critical',
      [ScanHealthStatus.Running]: 'helix:status-in-progress-alt!info',
      [ScanHealthStatus.Succeeded]: 'helix:status-success-alt!success',
      [ScanHealthStatus.SucceededWithWarning]: 'helix:status-warning-alt!warning',
      [ScanHealthStatus.Skipped]: 'helix:status-skip-alt!warning',
    },
    outline: {
      [ScanHealthStatus.Queued]: 'helix:status-queue!default',
      [ScanHealthStatus.Accepted]: 'helix:status-queue!default',
      [ScanHealthStatus.Canceled]: 'helix:status-cancel!default',
      [ScanHealthStatus.Canceling]: 'helix:status-cancel!default',
      [ScanHealthStatus.Failed]: 'helix:status-error!critical',
      [ScanHealthStatus.Running]: 'helix:status-in-progress!info',
      [ScanHealthStatus.Succeeded]: 'helix:status-success!success',
      [ScanHealthStatus.SucceededWithWarning]: 'helix:status-warning!warning',
      [ScanHealthStatus.Skipped]: 'helix:status-skip!warning',
    },
  };

  /**
   * Transform health status type to corresponding icon
   *
   * @param value scan health status type
   * @returns health icon
   */
  transform(value: ScanHealthStatus, isOutline = false): string {
    return this.iconMap[isOutline ? 'outline' : 'solid'][value] ?? 'helix:status-info';
  }
}
