import { assign } from 'lodash-es';
// Module: Kuiper VM details tooltip

;(function(angular) {
  'use strict';

  angular
    .module('C.devOps')
    .controller('KuiperVmDetailsCtrl', KuiperVmDetailsCtrlFn)
    .component('cKuiperVmDetails', {
      bindings:{
        /**
         * Provides the information about the VM
         *
         * @type  {Object}  [vm=undefined]
         */
        vm: '<',
      },
      templateUrl:
      'app/devops/hypervisor/kuiper-vm-details/kuiper-vm-details.html',
      controller: 'KuiperVmDetailsCtrl',
    });

  function KuiperVmDetailsCtrlFn(HypervisorVmService) {

    var $ctrl = this;

    // Declare component methods and variable.
    assign($ctrl, {
      // Component life cycle methods.
      $onInit: getVmStatus,

      // UI States
      currentVmStatus: {},
      isStatusCallRunning: false,
    });

    /**
     * Gets the status information of specified VM
     *
     * @method   getVmStatus
     * @return   {Void}
     */
    function getVmStatus() {
      $ctrl.currentVmStatus = {};
      $ctrl.isStatusCallRunning = true;
      HypervisorVmService.getVmStatus($ctrl.vm).then(
        function vmStatusSuccess(status) {
          $ctrl.currentVmStatus = status;
      }).finally(
        function getVmStatusFinally() {
          $ctrl.isStatusCallRunning = false;
      });
    }
  }
})(angular);