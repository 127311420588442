import { noop } from 'lodash-es';
import { isArray } from 'lodash-es';
import { filter } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: RDS Recovery Options

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.rdsRecovery')
    .controller('rdsRecoveryOptionsCtrl', rdsRecoveryOptionsCtrlFn)
    .component('rdsRecoveryOptions', {
      templateUrl: 'app/protection/recovery/rds/rds.options.html',
      controller: 'rdsRecoveryOptionsCtrl',
      bindings: {
        // @type {Number}  The job id for abbreviated flow
        jobId: '<',

        // @type {Number}  The job instance id for abbreviated flow
        jobId: '<',

        // @type {Number}  The entity id for abbreviated flow
        entityId: '<',
      }
    });

  /**
   * @ngdoc component
   * @name C.rdsRecoveryOptions:crdsRecoveryOptions
   * @function
   *
   * @description
   * Component to provide recovery options for RDS entities.
   */
  function rdsRecoveryOptionsCtrlFn(_, $q, RecoveryStore, RestoreService,
    FORMATS, ENV_TYPE_CONVERSION, SourceService, SlideModalService,
    PubSourceService, SearchService, evalAJAX, $state, cMessage, moment) {

    var $ctrl = this;

    assign($ctrl, {
      // properties
      restoreConfig: {
        deployVmsToCloudParams: {
          deployVmsToAwsParams: {
            rdsParams: {
              pointInTimeParams: {},
            },
          },
        },
        name: RestoreService.getDefaultTaskName('Restore', 'RDS'),
        objects: [],
        restoreParentSource: undefined,
      },

      // Lifecycle methods.
      $onInit: $onInit,

      // Methods exposed to template.
      fetchAwsEntities: fetchAwsEntities,
      getDBOptionGroups: getDBOptionGroups,
      regionSelected: regionSelected,
      vpcSelected: vpcSelected,
      selectRDSRestorePoint: selectRDSRestorePoint,
      submit: submit,

      // ENUMS.
      ENV_TYPE_CONVERSION: ENV_TYPE_CONVERSION,
      FORMATS: FORMATS,
    });

    /**
     * Component initialization hook.
     *
     * @method   $onInit
     */
    function $onInit() {
      var rdsPromise;
      var reqParams;

      // Alias for easy access in html
      $ctrl.rdsParams = $ctrl.restoreConfig
        .deployVmsToCloudParams.deployVmsToAwsParams.rdsParams;

      $ctrl.shared = RecoveryStore.get();
      $ctrl.currentTimezone = moment.tz.guess();

      // Abbreviated Flow
      if ($ctrl.jobId) {
        reqParams = {
          entityIds: $ctrl.entityId,
          jobIds: $ctrl.jobId,
        };

        rdsPromise = SearchService.rdsSearch(reqParams)
          .then(function fetchedVMs(foundVMs) {
            $ctrl.cartItem = foundVMs.find(function findJob(item) {
              // We matched a VM. Ensure it's the same VM entity.
              return (item._type === 'vm' &&
                item._jobId == reqParams.jobIds &&
                reqParams.entityIds == item.vmDocument.objectId.entity.id);
            });

            // We've specified a jobInstanceId AND entityId, so identify its
            // snapshot.
            if ($ctrl.jobInstanceId) {
              $ctrl.cartItem._snapshot = object.vmDocument.versions
                .find(function eachRun(run, ii) {
                  if ($ctrl.jobInstanceId == run.instanceId.jobInstanceId) {
                    object._snapshotIndex = ii;
                    return true;
                  }
                });
            }
          }, evalAJAX.errorMessage);

      // Standard Flow
      } else {
        $ctrl.cartItem = $ctrl.shared.cart[0];
        rdsPromise = $q.resolve();
      }

      rdsPromise.then(function vmFound() {
        $ctrl.dataReady = true;

        // The dbEngineId is of the form 'type' + 'version'.
        // Type is string and version is alphanumeric.
        // Extract the 'type' from the string
        $ctrl.dbEngineString = (get($ctrl.cartItem,
          'vmDocument.objectId.entity.awsEntity.dbEngineId', '')
            .replace(/[0-9.].*/g, ''));
      });
    }

    /**
     * Fetch the AWS entity hierarchy to fill the required recovery options
     *
     * @method  fetchAwsEntities
     * @param   {Number}  id  ID of the AWS Source to be fetched
     */
    function fetchAwsEntities(id) {
      $ctrl.regions = $ctrl.region = undefined;
      $ctrl.vpcs = $ctrl.vpc = undefined;
      $ctrl.subnets = $ctrl.subnet = undefined;
      $ctrl.dbEngines = $ctrl.dbEngines = undefined;
      $ctrl.dbOptionGroupsAll = $ctrl.dbOptionGroupAll = undefined;
      $ctrl.dbOptionGroupsVpc = $ctrl.dbOptionGroupVpc = undefined;
      $ctrl.dbParameterGroups = $ctrl.dbParameterGroup = undefined;

      $ctrl.fetchingRegions = true;

      PubSourceService.getSource(id)
        .then(function dataFetched(sources) {
          var cloudNodes = get(sources, '[0].nodes');
          $ctrl.regions = _filterNodes(cloudNodes, 'kRegion');
        }, function getSourceError(resp) {
          cMessage.error({
            acknowledgeTextKey: 'ok',
            persist: true,
            textKey: 'cloudDeploy.launchInstance.sourceError',
            titleKey: 'error',
          });
        }).finally(function afterSourceFetch() {
          $ctrl.fetchingRegions = false;
        });
    }

    /**
     * Selected child entities based on the region selected
     *
     * @method  regionSelected
     */
    function regionSelected() {
      $ctrl.vpcs = $ctrl.vpc = undefined;
      $ctrl.subnets = $ctrl.subnet = undefined;
      $ctrl.zones = $ctrl.zone = undefined;
      $ctrl.dbOptionGroupsAll = $ctrl.dbOptionGroupAll = undefined;
      $ctrl.dbOptionGroupsVpc = $ctrl.dbOptionGroupVpc = undefined;
      $ctrl.dbParameterGroups = $ctrl.dbParameterGroup = undefined;

      $ctrl.vpcs = _filterNodes($ctrl.region.nodes, 'kVPC');
      $ctrl.zones = _filterNodes($ctrl.region.nodes, 'kAvailabilityZone');
      $ctrl.dbOptionGroupsAll = _filterByDBEngine(
        _filterNodes($ctrl.region.nodes, 'kRDSOptionGroup'));
      $ctrl.dbParameterGroups = _filterByDBEngine(
        _filterNodes($ctrl.region.nodes, 'kRDSParameterGroup'));
    }

    /**
     * Selected child entities based on the vpc selected
     *
     * @method  vpcSelected
     */
    function vpcSelected() {
      $ctrl.subnets = $ctrl.subnet = undefined;
      $ctrl.nsgs = $ctrl.networkSecurityGroups = undefined;
      $ctrl.dbOptionGroupsVpc = $ctrl.dbOptionGroupVpc = undefined;

      $ctrl.subnets = _filterNodes($ctrl.vpc.nodes, 'kRDSSubnet');
      $ctrl.nsgs = _filterNodes($ctrl.vpc.nodes, 'kNetworkSecurityGroup');
      $ctrl.dbOptionGroupsVpc = _filterByDBEngine(
        _filterNodes($ctrl.vpc.nodes, 'kRDSOptionGroup'));
    }

    /**
     * Filter nodes of a given type from a list of nodes
     *
     * @method _filterNodes
     * @param {Object[]} nodes  The list of nodes to filter
     * @param {String}   type   The type to filter against
     */
    function _filterNodes(nodes, type) {
      return nodes.filter(function eachNode(node) {
        return node._type === type;
      })
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method     selectRDSRestorePoint
     */
    function selectRDSRestorePoint() {
      var modalConfig = {
        resolve: {
          innerComponent: 'rdsSnapshotSelector',
          actionButtonKey: false,
          closeButtonKey: false,
          titleKey: '',
          bindings: {
            task: angular.copy($ctrl.restoreConfig),
            timezone: {name: $ctrl.currentTimezone},
            entity: assign($ctrl.cartItem, {
              _versions: $ctrl.cartItem.vmDocument.versions,
            }),
            restoreTimeSecs: $ctrl.cartItem._restoreTimeSecs,
          },
        },
        size: 'full',
        autoHeight: true,
      };

      SlideModalService.newModal(modalConfig).then(function onDelete(resp) {
        assign($ctrl.cartItem, {
          _snapshotIndex: resp.selectedIndex === -1 ? 0 : resp.selectedIndex,
          _snapshot: resp.snapshot,
          _archiveTarget: resp.archiveTarget,
          _restoreTimeSecs: resp.task.restoreTimeSecs,
        });

        $ctrl.isPIT = resp.isPIT;

        if ($ctrl.isPIT) {
          $ctrl.recoverToOriginalLocation = true;
        }
      }).catch(noop);
    }

    /**
     * Submit the form to the server
     *
     * @method submit
     */
    function submit() {
      var rdsParams = $ctrl.rdsParams;
      var awsParams =
        $ctrl.restoreConfig.deployVmsToCloudParams.deployVmsToAwsParams;
      var object;

      if (!$ctrl.recoverToOriginalLocation) {
        $ctrl.restoreConfig.restoreParentSource =
          _convertPublicEntityToPrivate($ctrl.source);
        awsParams.region =
          _convertPublicEntityToPrivate($ctrl.region);
        awsParams.vpc =
          _convertPublicEntityToPrivate($ctrl.vpc);
        awsParams.subnet =
          _convertPublicEntityToPrivate($ctrl.subnet);
        awsParams.networkSecurityGroups = _convertPublicEntityToPrivate($ctrl.networkSecurityGroups);
        if (!$ctrl.rdsParams.multiAzDeployment) {
          rdsParams.availabilityZone =
            _convertPublicEntityToPrivate($ctrl.zone);
        }
        rdsParams.dbOptionGroup =
          _convertPublicEntityToPrivate($ctrl.dbOptionGroup);
        rdsParams.dbParameterGroup =
          _convertPublicEntityToPrivate($ctrl.dbParameterGroup);
      } else {
        $ctrl.restoreConfig.restoreParentSource = undefined;
      }

      object = angular.copy($ctrl.cartItem.vmDocument.objectId);
      object.startTimeUsecs =
        $ctrl.cartItem._snapshot.instanceId.jobStartTimeUsecs ||
        $ctrl.cartItem._versions[0].instanceId.jobStartTimeUsecs;
      object.jobInstanceId =
        $ctrl.cartItem._snapshot.instanceId.jobInstanceId ||
        $ctrl.cartItem._versions[0].instanceId.jobInstanceId;

      $ctrl.restoreConfig.objects[0] = object;

      rdsParams.pointInTimeParams.timestampMsecs =
        $ctrl.isPIT ? $ctrl.cartItem._restoreTimeSecs * 1000 : undefined;

      $ctrl.submitting = true;

      RestoreService.restoreVM($ctrl.restoreConfig)
        .then(function restoreSuccess(resp) {
          // navigate to deploy task detail
          $state.go('recover-detail-local', {
            id: resp.performRestoreTaskState.base.taskId,
          });
        }, evalAJAX.errorMessage)
        .finally(function deployFinally() {
          $ctrl.submitting = false;
        });
    }

    /**
     * Convert a public entityType to private entityType
     *
     * @method  _convertPublicEntityToPrivate
     * @param   {Object}  entity  The entity object to convert
     * @return  {Object}  the converted entity object
     */
    function _convertPublicEntityToPrivate(entity) {
      var convertedEntitiy = SourceService.publicEntitiesToPrivateEntities(entity);

      return isArray(entity) ? entity.map(item => item.protectionSource) : entity.protectionSource;
    }

    /**
     * Get the list of dbOptionGroups to be displayed in the dropdown
     *
     * @method  getDBOptionGroups
     * @return  {Object[]} List of dbOptionGroup entities
     */
    function getDBOptionGroups () {
      return (($ctrl.dbOptionGroupsAll || [])
        .concat($ctrl.dbOptionGroupVpc || []));
    }

    /**
     * Filter a list based on selected item's dbEngine
     *
     * @method  _filterByDBEngine
     * @param   {Object[]}  entities  List of entities to be filtered
     * @return  {Object[]}  List of filtered entities
     */
    function _filterByDBEngine (entities) {
      return filter(entities, function filterEntity(entity) {
        return entity.protectionSource
          .awsProtectionSource.dbEngineId.includes($ctrl.dbEngineString);
      });
    }
  }
})(angular);