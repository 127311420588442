import { Injectable } from '@angular/core';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import {
  BaseBannerConfig,
  flagEnabled,
  getConfigByKey,
  IrisContext,
  IrisContextService,
  isMcm,
  isMcmOnPrem,
  isMcmSaaS
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { isEmpty } from 'lodash-es';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnableMfaBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'enable-mfa-banner';

  /**
   * This flag checks condition whether
   * MFA should be hidden.
   */
  hideMfaBanner = false;

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    map(ctx => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx),
      status: 'warn',
      allowClose: false,
      text: this.translateService.instant('mfa.enable.banner'),
      actionText: this.translateService.instant('manageSettings'),
      actionCallback: () => {
        if (isMcmOnPrem(ctx)) {
          this.stateService.go('helios-access-management.onprem-mfa');
        } else {
          this.stateService.go('access-management.secure-login');
        }
      },
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private stateService: StateService,
    private translateService: TranslateService
  ) {
    super();
    this.hideMfaBanner = getConfigByKey(this.irisCtx.irisContext, 'accessManagement.hideMfa', this.hideMfaBanner);
  }

  /**
   * Allow banner based dms context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext): boolean {
    if (isMcmSaaS(ctx) || this.hideMfaBanner) {
      // MCM SaaS MFA banner is handled separately.
      return false;
    }

    // special case for onPrem or HeliosOnPrem
    const isMfaEnabled =
      (isMcmOnPrem(ctx) ? ctx.user.isAccountMfaEnabled : ctx.clusterInfo.isClusterMfaEnabled);
    const isMfaSupported = getConfigByKey(this.irisCtx.irisContext, 'accessManagement.features.secureLogin.mfa', true);
    const hasPrivilege =
      (flagEnabled(ctx, 'mfaLoginBannerEnabled') && !isMcm(ctx)) ||
      (flagEnabled(ctx, 'mfaHeliosOnPrem') && isMcmOnPrem(ctx) && ctx.privs.PRINCIPAL_MODIFY);
    const showBanner = Boolean(ctx.user?.roles?.length && !ctx.user.isAccountLocked && !ctx.user.forcePasswordChange);
    return hasPrivilege && showBanner && !isEmpty(ctx.clusterInfo) && !isMfaEnabled && isMfaSupported;
  }
}
