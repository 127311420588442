import { Injectable } from '@angular/core';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, IrisContext, IrisContextService, isCarrEnabled, getConfigByKey } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CarrRestrictedUiBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'carr-restricted-ui-banner';

  /**
   * Where to send the user if they click the action button on the banner.
   */
  bannerLink: string;

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    map(ctx => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx),
      status: 'warn',
      allowClose: false,
      text: this.translateService.instant('carr.banner.msg'),
      actionText: this.translateService.instant('goToHelios'),
      actionCallback: () => {
        document.location.href = this.bannerLink;
      },
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private translateService: TranslateService
  ) {
    super();
    this.bannerLink = getConfigByKey(this.irisCtx.irisContext, 'banner.link', '');
  }

  /**
   * Allow banner based on context
   *
   * @param ctx Iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return isCarrEnabled(ctx);
  }
}
