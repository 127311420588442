import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

/**
 * Directive for form group element to set active state.
 * This is used together with FormFocusedHighlight directive.
 *
 * @example
 *   <mat-card cogFormGroup>
 *     <mat-form-field>
 *       <mat-label for="first-name-input">First Name</mat-label>
 *       <input type="text" id="first-name-input" matInput>
 *     </mat-form-field>
 *   </mat-card>
 */
@Directive({
  selector: '[cogFormGroup]',
  exportAs: 'cogFormGroup'
})
export class FormGroupDirective {

  /**
   * Used to set the active state.
   */
  @HostBinding('class.active') public isActive = false;

  /**
   * Whether form group should have a floating menu attached.
   */
  @Input() hasAttachedMenu = false;

  /**
   * Form group's optional unique identifier.
   */
  @Input() id?: string;

  /**
   * Form group's section identifier.
   */
  @Input() section = '';

  constructor(private elementRef: ElementRef) { }

  /**
   * Checks if an element is contained within the host.
   *
   * @param    element   HTML element.
   * @returns  true if the host contains the element.
   */
  containsElement(element: HTMLElement | Element): boolean {
    return this.elementRef.nativeElement.contains(element);
  }

  /**
   * Gets host's position relative to the viewport using getBoundingClientRect.
   */
  getPosition(): ClientRect {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }
}
