import { identity } from 'lodash-es';
import { values } from 'lodash-es';
import { forEach } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Helios Reports Service Formatter

; (function (angular, undefined) {
  'use strict';

  angular
    .module('C.HeliosReportsServiceFormatter', [])
    .service('HeliosReportsServiceFormatter', HeliosReportsServiceFormatterFn);

  /**
   * @ngdoc service
   * @name      C.HeliosReportsServiceFormatter
   *
   * @function  HeliosReportsServiceFormatterFn
   * @description
   *  Provides  the services for Helios Reports Formatter from the JReports.
   */
  function HeliosReportsServiceFormatterFn(_, $rootScope, $window, cUtils,
    $translate, DateTimeService, ENV_TYPE_CONVERSION, ENUM_ENV_TYPE, ENUM_DAY,
    TASK_TYPE_CONVERSION, ENUM_TASK_TYPE, FETCH_TYPE_CONVERSION,
    ENUM_FETCH_TYPE, TIMESPAN_TYPE_CONVERSION, ENUM_TIMESPAN_TYPE,
    DB_RUN_STATUS_TEXT, DB_ENUM_RUN_STATUS_CONVERSION, DAY_PERIODICITY,
    moment) {

    return {
      decorateWithHumanizedFilterValue: decorateWithHumanizedFilterValue,
      getFilterParams: getFilterParams,
      getJobRunStatus: getJobRunStatus,
      getObjectTypes: getObjectTypes,
      getTaskTypes: getTaskTypes,
      getFetchType: getFetchType,
      getTimespanType: getTimespanType,
      getScheduleReportParams: getScheduleReportParams,
      transformSchedule: transformSchedule,
    };

    /**
     * Transform provided jReport task info into iris scheduler proto.
     *
     * @method    transformSchedule
     * @param     {Object}    taskInfo         The taskInfo object.
     * @return    {Object}    The transformed task info into iris scheduler
     *                        proto.
     */
    function transformSchedule(taskInfo) {
      var out = {};

      // extracting report type and schedule created at time.
      var scheduleNameRegex = /report:(.*)\)\(created at:(.*)\)/g;
      taskInfo['jrs.schedule_name'].replace(scheduleNameRegex,
        function eachMatch(match, reportType) {
          out.reportType = reportType;
        }
      );

      // constructing email schedule params.
      out.schedule = assign({
        // unique task ID.
        id: taskInfo['jrs.task_id'],
        enableRecurringEmail: true,
        scheduleDays: taskInfo['jrs.weekdays'].split('').map(
          function eachDay(dayIndex) {
            return DAY_PERIODICITY[dayIndex];
          }
        ),
        scheduleTime: {
          hour: (+taskInfo['jrs.hour']) +
            (taskInfo['jrs.is_pm'] === 'true' ? 12 : 0),
          minute: (+taskInfo['jrs.min']),
        },
        timezone: taskInfo['jrs.timezone'],
      }, getEmailInfo(taskInfo));
      out.periodicityString = getPeriodicityString(out.schedule);

      // Construct filter params.
      out.filterParams = constructFilterParams(taskInfo);

      return out;
    }

    /**
     * Construct filter params for the task.
     *
     * @method    constructFilterParams
     * @param     {Object}    taskInfo       The task info object.
     * @return    {String}    Construct the filter params by parsing task info.
     */
    function constructFilterParams(taskInfo) {
      var filterParams = {};
      var filterParamsRegex = /^jrs\.param\$(.*)/g;

      forEach(taskInfo, function eachParam(value, key) {
        // looking for filter params keys.
        key.replace(filterParamsRegex, function eachMatch(match, paramName) {
          filterParams[paramName] = value;
        });
      });
      // Returns timespan and timespan type according to the jreport
      // expression used.
      filterParams = convertJReportExprToReportTimeSpan(
        filterParams, taskInfo);

      return filterParams;
    }

    /**
     * Construct email info from provided task info.
     *
     * @method    getEmailInfo
     * @param     {Object}    taskInfo       The task info object.
     * @return    {Object}    Return Constructed email info.
     */
    function getEmailInfo(taskInfo) {
      var out = {};

      // constructing key value maps from  jReport email properties.
      out._emailParams = taskInfo['jrs.jrmail0'].split('&').reduce(
        function eachEmailParam(acc, emailParam) {
          var paramParts = emailParam.split('=');

          acc[paramParts[0]] = paramParts[1];
          return acc;
        }, {});

      // decoded list of target emails ids.
      out.receiverEmails = $window.decodeURIComponent(
        out._emailParams['jrs.mailto']).split(',');
      // decoded email subject.
      out.mailSubject = $window.decodeURIComponent(
        out._emailParams['jrs.mailsubject'].split('+').join(' ')
      );

      if (out._emailParams['jrs.to_mail_pdf'] === 'true') {
        out.outputFormat = 'pdf';
      } else if (out._emailParams['jrs.to_mail_html'] === 'true') {
        out.outputFormat = 'html';
      } else if (out._emailParams['jrs.to_mail_txt'] === 'true') {
        out.outputFormat = 'csv';
      }

      return out;
    }

    /**
     * Get humanized string for provide schedule.
     *
     * @method    getPeriodicityString
     * @param     {Object}    schedule       The scheduled report config.
     * @return    {String}    Return humanized string for provide schedule.
     */
    function getPeriodicityString(schedule) {
      var out = schedule.scheduleDays.map(function eachDay(day) {
        return ENUM_DAY[day];
      });

      // adding the scheduled time converted into logged in user timezone.
      out.push(DateTimeService.formatDate(
        DateTimeService.objectTimeToMsecs(schedule.scheduleTime), 'hh A'));

      return out.join(', ');
    }

    /**
     * Construct params for scheduling an email report.
     *
     * @method    getScheduleReportParams
     * @param     {Object}    filter         The applied filters.
     * @param     {Object}    schedule       The scheduled report config.
     * @param     {Object}    reportConfig   The selected report config.
     * @return    {Object}    The report scheduler request params.
     */
    function getScheduleReportParams(filter, schedule, reportConfig) {
      filter['report'] = reportConfig.selectedReport.params;

      const out = {
        taskId: schedule.id,
        isRecurring: schedule.enableRecurringEmail,
        mailIds: schedule.emails,
        mailSubject: schedule.mailSubject,
        timezone: schedule.timezone,
        reportInfo: {
          reportType: reportConfig.selectedReport.params.reportType,
          outputFormat: schedule.format,
          ...getFilterParams(filter),
        }
      };

      if (schedule.enableRecurringEmail) {
        out.schedule = {
          periodicity: 'kWeekly',
          weeklySchedule: {
            days: getScheduledDays(schedule.scheduleDays),
          },
          time: {
            hour: schedule.scheduleTime.hour,
            minute: schedule.scheduleTime.minute || 0,
          },
        };

        out.reportInfo = convertReportTimeSpanToJReportExpr(filter, out);

      }

      // Set the timezone for showing reports data as the timezone set
      // by customer.
      out.reportInfo.timezone = schedule.timezone;

      return out;
    }

    /**
     * Get Scheduled Days as needed by jReports.
     *
     * @exammple
       getScheduledDays(['kSunday', 'kTuesday']) will return '02'
     *
     * @method    getScheduledDays
     * @return    {Object}   Returns scheduled days as a string.
     */
    function getScheduledDays(scheduleDays) {
      const reference = {
        kSunday: 0, kMonday: 1, kTuesday: 2, kWednesday: 3,
        kThursday: 4, kFriday: 5, kSaturday: 6,
      };

      return scheduleDays.map(day => reference[day]);
    }

    /**
     * Get the list of object types.
     *
     * @method  getObjectTypes
     * @return  {Array}     List of object types.
     */
    function getObjectTypes() {
      return cUtils.onlyStrings(values(ENV_TYPE_CONVERSION)).map(
        function eachItem(kEnv) {
          return {
            label: ENUM_ENV_TYPE[kEnv],

            // constant string value.
            value: kEnv,

            // constant numerical value.
            numericalValue: ENV_TYPE_CONVERSION[kEnv],
          };
        }
      ).filter(function eachItem(item) {
        // filter out kKuiper env having -1 as numerical value.
        return item.numericalValue >= 0;
      });
    }

    /**
     * Get the list of task types.
     *
     * @method  getTaskTypes
     * @return  {Array}     List of task types.
     */
    function getTaskTypes() {
      return cUtils.onlyStrings(values(TASK_TYPE_CONVERSION)).map(
        function eachItem(kTask) {
          return {
            label: ENUM_TASK_TYPE[kTask],

            // constant string value.
            value: kTask,

            // constant string value.
            stringValue: ENUM_TASK_TYPE[kTask],
          };
        }
      );
    }

    /**
     * Get the list of fetch type for failed objects.
     *
     * @method  getFetchType
     * @return  {Array}     List of fetch type for failed objects.
     */
    function getFetchType() {
      return cUtils.onlyStrings(values(FETCH_TYPE_CONVERSION)).map(
        function eachItem(kMethod) {
          return {
            label: ENUM_FETCH_TYPE[kMethod],

            // constant string value.
            value: kMethod
          };
        }
      );
    }

    /**
     * Get the list of timespan type for scheduling reports.
     *
     * @method  getTimespanType
     * @return  {Array}     List of all timespan Types.
     */
    function getTimespanType() {
      return cUtils.onlyStrings(values(TIMESPAN_TYPE_CONVERSION)).map(
        function eachItem(timespanType) {
          return {
            label: ENUM_TIMESPAN_TYPE[timespanType],

            // constant string value.
            value: timespanType,
          };
        }
      );
    }

    /**
     * Get the list of job run status.
     *
     * @method  getJobRunStatus
     * @return  {Array}     List of job run status.
     */
    function getJobRunStatus() {
      return map(DB_RUN_STATUS_TEXT, function eachItem(status, kVal) {
        return {
          label: $translate.instant(status),

          // constant string value.
          value: kVal,

          // constant numerical value.
          numericalValue: DB_ENUM_RUN_STATUS_CONVERSION[kVal],
        };
      });
    }

    /**
     * Return the applied filter params for selected report.
     *
     * @method    getFilterParams
     * @return    {Object}   Returns the filter params object.
     */
    function getFilterParams(filter) {
      // Subtracting the current hour to set the start time as 12 am the
      // browser timezone.
      const currentHours =  (new Date().getHours());
      const startHoursFromNow = 0  - currentHours - (getDayDifferenceFromNow(
        +get(filter, 'dateRange.start')
      ) * 24);
      // Adding 24 since end time needs to be the end of the day and
      // dateRange.end gives the start of the ending day.
      const endHoursFromNow = 24 - currentHours - (getDayDifferenceFromNow(
        +get(filter, 'dateRange.end')
      ) * 24);

      return {
        startTime: `{"exp":"dateadd('h',${startHoursFromNow},currenttime())"}`,
        endTime: `{"exp":"dateadd('h',${endHoursFromNow},currenttime())"}`,
        clusterIdentifiers: map(
          filter.clusters, 'clusterIdentifier').filter(identity),
        registeredSourceIds: filter.isAllSourcesSelected ? [] : map(
          filter.sources, 'uuid').filter(identity),
        objectTypes: filter.isAllObjectTypesSelected ? [] : map(
          filter.objectTypes, 'numericalValue').filter(identity),
        taskTypes: filter.isAllTaskTypesSelected ? [] : map(
          filter.taskTypes, 'stringValue').filter(identity),
        runStatus: filter.isAllRunStatusSelected ? [] : map(
          filter.runStatus, 'numericalValue').filter(identity),
        varianceOverDays: get(filter, 'lastNDays'),
        // This timezone is used to display the time series data in reports in
        // the required timezone. If we are changing this to get a timezone
        // from a new filter, do change the logic in calculating start time
        // and endtime.
        timezone: moment.tz.guess(),
        fetchType: get(filter, 'fetchType.value'),
        ...(filter.report.reportType === 'protection-summary-cluster' ? {} :
          { taskType: get(filter, 'taskType.stringValue')}),
      };
    }

    /**
     * Calculate number of full days between now and given date
     *
     * @method    getDayDifferenceFromNow
     * @param     {Number}    timeMsecs    Date in milliseconds
     * @return    {Number}   Returns the number of full days.
     */
    function getDayDifferenceFromNow(timeMsecs) {
      const timeNow = Date.clusterNow();
      return Math.floor((timeNow - timeMsecs) / 86400000);
    }

    /**
     * Decorate filter values with there humanize value.
     *
     * @method    decorateWithHumanizedFilterValue
     * @param     {Object}    schedule    The schedule info.
     * @return    {Object}    The decorated schedule info with humanizedFilters
     *                        key having humanize filter value.
     */
    function decorateWithHumanizedFilterValue(schedule) {
      var humanizedFilters = [
        {
          labelKey: 'clusters',
          value: map(
            schedule.filterParams._clusters,
            'name'
          ).filter(identity).join(', '),
        },
        {
          labelKey: 'sources',
          value: map(
            schedule.filterParams._rootNodes,
            'protectionSource.name'
          ).filter(identity).join(', '),
        },
        {
          labelKey: 'viewBoxes',
          value: map(
            schedule.filterParams._viewBoxes,
            'name'
          ).filter(identity).join(', '),
        },
        {
          labelKey: 'organizations',
          value: map(
            schedule.filterParams._tenants,
            'name'
          ).filter(identity).join(', '),
        },
        {
          labelKey: 'jobName',
          value: map(
            schedule.filterParams._jobs,
            'name'
          ).filter(identity).join(', '),
        },
        {
          labelKey: 'reports.reportTimeSpan',
          value: (() => {
            if (schedule.filterParams.reportTimeSpanType.value == 'kHours') {
              const hours = schedule.filterParams.reportTimeSpan;
              return `${hours} ${$translate.instant(
                hours === 1 ? 'hour' : 'hours')}`;
            }
            const days = schedule.filterParams.reportTimeSpan;
            return `${days} ${$translate.instant(days === 1 ? 'day' : 'days')}`;
          })(),
        },
        {
          labelKey: 'runStatus',
          value: schedule.filterParams._runStatus &&
            schedule.filterParams._runStatus.map(e => e.label).join(', '),
        },
        {
          labelKey: 'objectType',
          value: schedule.filterParams._objectType &&
            schedule.filterParams._objectType.map(e => e.label).join(', '),
        },
        {
          labelKey: 'taskType',
          value: schedule.filterParams._taskType &&
            schedule.filterParams._taskType.map(e => e.label).join(', '),
        },
        {
          labelKey: 'fetchType',
          value: schedule.filterParams.fetchType ?
            schedule.filterParams.fetchType.label : null,
        },
        {
          labelKey: 'taskType',
          value: schedule.filterParams.taskType ?
            schedule.filterParams.taskType.label : null,
        },
        {
          labelKey: 'reportsControls.nDaysVariance',
          value: schedule.filterParams.nDaysVariance ? (
            schedule.filterParams.nDaysVariance + ' ' +
            $translate.instant(
              schedule.filterParams.nDaysVariance === 1 ? 'day' : 'days')
          ) : null,
        },
      ].filter(function eachFilter(filter) {
        return !!filter.value;
      });

      return assign(schedule, { humanizedFilters: humanizedFilters });
    }

    /**
     * Parses ReportTimeSpan from the Jreport Expression. JReport uses certain
     * expressions for calculating the timespan in a scheduled report. We
     * leverage three types of expressions to render two types of timespan in
     * schedules. The two types of timespan is Last n days and Last n hours.
     * The three expressions in use are
     * 1. {"exp": "dateadd('d', -n, today())"}
     *    Here jreport uses today() to  estimate the timerange as 12am - 11:59
     *    pm. This was the initial concept used. It will show the report
     *    of data from 12am GMT - 11:59pm GMT regardless of the users timezone.
     *    This is erraneous and needs to be deprecated.
     *
     * 2. {"exp":"dateadd('h', -n-s, currenttime())"}
     *    Here, the above problem of not taking users timezone is taken into
     *    consideration. Since jreports uses currenttime for running the
     *    report, we will need to subtract the hours in current time (which is
     *    the schedule time) to get 12 am in users timezone. So this
     *    expression gives the report data in timerange 12am-11:59pm user's
     *    timezone. Note that n and s are in hours. This is used when user
     *    select "Last n days" timespan. Days are converted into hours before
     *    creating the expression.
     *
     * 3. {"exp": "dateadd('n', -n, currenttime())"}
     *    Here, the report is supposed to give the timerange of n hours from
     *    the scheduled time.Thus the method used will be "Last n hours".
     *    Since jreports doesnt provide any way of differentiating between
     *    last n days and last n hours, we resorted to using minutes in
     *    expression for last n hours and using hours in expression for
     *    last n days.
     *
     * @method    convertJReportExprToReportTimeSpan
     * @param     {Object}    filterParams   The filter parameters.
     * @param     {Object}    taskInfo       Contains the schedule time needed
     *                                       for the second case calculation.
     * @return    {Object}    The modified filter parameters.
     */
    function convertJReportExprToReportTimeSpan(filterParams, taskInfo) {
      const oldExprRegex = /\{"exp":"dateadd\('d',(.*?),today()\(\)\)"\}/;
      const hourRegex = /\{"exp":"dateadd\('h',(.*?),currenttime()\(\)\)"\}/;
      const minuteRegex = /\{"exp":"dateadd\('n',(.*?),currenttime()\(\)\)"\}/;
      // This is for the old schedules which used the oldExprRegex to create
      // schedules. The problem of this schedule is that the data will be
      // displayed from 12 am GMT timezone for the specified time range.
      // User expects the timerange to start from 12am users timezone.
      filterParams.reportTimeSpan =
        filterParams.StartTime.match(oldExprRegex) || 0;
      if (filterParams.reportTimeSpan == 0) {
        // This means that the new hour/day timespan system is used. For days
        // system, the report data will represent 12 am user timezone. For
        // hour system, the report data will represent a timerange starting
        // from the schedule time of a report. To differentiate between both,
        // we are using minutes for expressing hour scheme and hours for
        // expressing day scheme.
        filterParams.reportTimeSpan =
        filterParams.StartTime.match(minuteRegex) || 0;
        if (filterParams.reportTimeSpan != 0) {
          // Hour System is used which means minutes are used for expression.
          if (!!filterParams.reportTimeSpan &&
            filterParams.reportTimeSpan.length > 1) {
            filterParams.reportTimeSpan = 0 - parseInt(
              filterParams.reportTimeSpan[1], 10);
          }

          filterParams.reportTimeSpan =
            Math.floor((filterParams.reportTimeSpan)/60);

          filterParams.reportTimeSpanType = getTimespanType().find(
            function isHourSpanType(timespanType) {
              return timespanType.value == 'kHours';
            }
          );

        } else {
          // Day system is used which means hours are used for expression.
          filterParams.reportTimeSpan =
            filterParams.StartTime.match(hourRegex) || 0;

          if (!!filterParams.reportTimeSpan &&
            filterParams.reportTimeSpan.length > 1) {
            filterParams.reportTimeSpan = 0 - parseInt(
              filterParams.reportTimeSpan[1], 10);
          }
          // Using to calculate the offset when the report time is calculated
          // from last day 12 am. Hence the time difference between 12 am and
          // and the scheduled time will be included in the expression for
          // StartTime and EndTime.
          const offset = (+taskInfo['jrs.hour']) +
          (taskInfo['jrs.is_pm'] === 'true' ? 12 : 0);

          filterParams.reportTimeSpan =
            Math.floor((filterParams.reportTimeSpan - offset)/24);
            filterParams.reportTimeSpanType = getTimespanType().find(
              function isDaySpanType(timespanType) {
                return timespanType.value == 'kDays';
              }
            );
        }
      } else {
        if (!!filterParams.reportTimeSpan &&
          filterParams.reportTimeSpan.length > 1) {
          filterParams.reportTimeSpan = 0 - parseInt(
            filterParams.reportTimeSpan[1], 10);
        }

        filterParams.reportTimeSpanType = getTimespanType().find(
          function isDaySpanType(timespanType) {
            return timespanType.value == 'kDays';
          }
        );
      }

      return filterParams;
    }

    /**
     * Parses JReport Expression from the ReportTimeSpan. JReport uses certain
     * expressions for calculating the timespan in a scheduled report. We
     * leverage three types of expressions to render two types of timespan in
     * schedules. The two types of timespan is Last n days and Last n hours.
     * The three expressions in use are
     * 1. {"exp": "dateadd('d', -n, today())"}
     *    Here jreport uses today() to  estimate the timerange as 12am - 11:59
     *    pm. This was the initial concept used. It will show the report
     *    of data from 12am GMT - 11:59pm GMT regardless of the users timezone.
     *    This is erraneous and needs to be deprecated and hence wont be used
     *    further.
     *
     * 2. {"exp":"dateadd('h', -n-s, currenttime())"}
     *    Here, the above problem of not taking users timezone is taken into
     *    consideration. Since jreports uses currenttime for running the
     *    report, we will need to subtract the hours in current time (which is
     *    the schedule time) to get 12 am in users timezone. So this
     *    expression gives the report data in timerange 12am-11:59pm user's
     *    timezone. Note that n and s are in hours. This is used when user
     *    select "Last n days" timespan. Days are converted into hours before
     *    creating the expression.
     *
     * 3. {"exp": "dateadd('n', -n, currenttime())"}
     *    Here, the report is supposed to give the timerange of n hours from
     *    the scheduled time.Thus the method used will be "Last n hours".
     *    Since jreports doesnt provide any way of differentiating between
     *    last n days and last n hours, we resorted to using minutes in
     *    expression for last n hours and using hours in expression for
     *    last n days.
     *
     * @method    convertReportTimeSpanToJReportExpr
     * @param     {Object}    filterParams    The filter parameters.
     * @param     {Object}    out             The final output which needs to
     *                                        modified.
     * @return    {Object}                    The modified filter parameters.
     */
    function convertReportTimeSpanToJReportExpr(filter, out) {
      var reportInfo = out.reportInfo;
      if (filter.reportTimeSpanType.value == 'kHours') {
        // The report when scheduled at X time on Y date for last n hours,
        // is supposed to run on X time with the report representing the
        // data from X - n hours to X hours. To differentiate both days
        // and hours, the hour case is send as an expression in minutes.
        reportInfo.startTime = (() => {
          const minutes = 0 - (filter.reportTimeSpan * 60);
          return `{"exp":"dateadd('n',${minutes},currenttime())"}`;
        })();

        reportInfo.endTime = (() => {
          return `{"exp":"currenttime()"}`;
        })();

      } else {
        // The report when scheduled at X time on Y date for last n days,
        // is supposed to run on X time with the report representing the
        // data from Y-n day's 12 am to Yth day 12am Users Timezone. Hence
        // we need to subtract X hours from the time range to get the 12 am
        // time correctly.
        reportInfo.startTime = (() => {
          const hours = 0 - (filter.reportTimeSpan * 24) - out.schedule.
            time.hour;
          return `{"exp":"dateadd('h',${hours},currenttime())"}`;
        })();

        reportInfo.endTime = (() => {
          const hours = 0 - out.schedule.time.hour;
          return `{"exp":"dateadd('h',${hours},currenttime())"}`;
        })();
      }

      return reportInfo;
    }
  }
})(angular);
