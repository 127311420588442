import { omit } from 'lodash-es';
import { isNil } from 'lodash-es';
import { get } from 'lodash-es';
// Module: Recover Files to Source

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.fileRecovery';
  var moduleDeps = [];

  angular
    .module(moduleName, moduleDeps)
    .config(configFn)
    .controller('recoverFilesParent', recoverFilesParentFn);


  function configFn($stateProvider) {
    // Configure the states & routes
    $stateProvider
      .state('recover-files', {
        url: '/protection/recovery/files?{environments}',
        help: 'protection_recovery_new',
        title: 'Recover Files and Folders',
        canAccess: 'RESTORE_MODIFY',
        parentState: 'recover',
        params: {
          environments: { type: 'string', array: true },
          recoveryType: { type: 'string', value: 'recover-files'}
        },
        controller: 'recoverFilesParent',
        templateUrl: 'app/protection/recovery/files/files.parent.html',
        redirectTo: 'recover-files.search'
      })
      .state('recover-files.search', {
        // browseSourceName ad browseSourceId should both be provided in order
        // to launch the file browse with a predeterimed source object.
        url: '^/protection/recovery/files/search' +
          '?{browseSourceId}&{browseSourceName}',
        help: 'protection_recovery_files_search',
        canAccess: 'RESTORE_MODIFY',
        parentState: 'recover-files',
        params: {
          browseSourceId: { type: 'string' },
          browseSourceName: { type: 'string' },
          disableFileBrowseModal: { type : 'bool', value: false },
        },
        views: {
          'canvas@recover-files': {
            controller: 'fileSearchController',
            templateUrl: 'app/protection/recovery/files/files.search.html',
          },
        },
      })
      .state('recover-files.options', {
        url: '^/protection/recovery/files/options',
        help: 'protection_recovery_files_options',
        params: {
          entityId: 0,
          taskId: -1,
          jobInstanceId: 0,
          jobStartTimeUsecs: 0,
          resubmit: false,
          resubmitRecoveryObject: { type: 'any' },
        },
        canAccess: 'RESTORE_MODIFY',
        parentState: 'recover-files',
        views: {
          'canvas@recover-files': {
            controller: 'recoverFilesOptionsController',
            templateUrl: 'app/protection/recovery/files/files.recover-options.html',
          },
        },
      });
  }


  function recoverFilesParentFn($rootScope, $scope, $state, $filter,
    $timeout, SearchService, cSearchService, $q, cModal, cMessage,
    SourceService, RestoreService, evalAJAX, $interpolate, cUtils,
    SlideModalService, ExternalTargetService, ENV_GROUPS, _, FEATURE_FLAGS,
    ENUM_ARCHIVAL_TARGET, HOST_TYPE_GROUPS, $stateParams, FLR_RESTORE_METHOD) {

    var defaultFileRestoreTask = {
      // Array of file-path strings (1 per cart item)
      filenames: [],
      // Source VM (RestoreObject Proto)
      sourceObjectInfo: undefined,
      // (RestoreFilesParams)
      params: {
        // Dest VM (EntityProto)
        targetEntity: undefined,
        // Dest vCenter/ESXi (EntityProto)
        targetEntityParentSource: undefined,
        // Credentials for dest/target Entity
        targetEntityCredentials: {
          username: '',
          password: ''
        },
        restoreFilesPreferences: {
          restoreToOriginalPaths: true,
          overrideOriginals: Boolean(FEATURE_FLAGS.overwriteOriginalFLRByDefault),
          preserveTimestamps: true,
          preserveAcls: true,
          preserveAttributes: true,
          continueOnError: false
        },
      }
    };
    var alternateRestoreBaseDirectory = '';
    var cachedVmForBrowsing;
    var taskDestroyer;

    angular.extend($scope, {
      // GENERAL SCOPE VARS

      // Disables form pieces when submitting the task (because it can
      // take several seconds)
      // @type  {Bool}
      isSubmitting: false,
      shared: {
        // Default targetOS is 1 (Windows) but can be detected and
        // changed to 0 (Linux)
        // @type    {integer}
        targetOS: SourceService.getEntityHostOSType(),

        // @type    {boolean}   Whether the original target still exists
        originalTargetExists: true,

        // @type    {boolean}  Whether disable ACL toggle.
        disableACL: $scope.user.restricted,
      },

      FLR_RESTORE_METHOD: FLR_RESTORE_METHOD,

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoveryFiles_filesText,

      entityType: {
        1: 'Virtual Machine',
        6: 'Physical Server'
      },

      // SCOPE METHODS
      addToCart: addToCart,
      browseVm: browseVm,
      destroyTask: destroyTask,
      formatFilterDate: formatFilterDate,
      getCartPhysicalUsage: getCartPhysicalUsage,
      getDefaultAlternativeDirectory: getDefaultAlternativeDirectory,
      getSearchUrl: SearchService.getSearchUrl,
      getTargetTooltip: ExternalTargetService.getTargetName,
      getTargetType: ExternalTargetService.getTargetType,
      goToOptions: goToOptions,
      goToSelectObjects: goToSelectObjects,
      initSharedTaskConfig: initSharedTaskConfig,
      isForbidden: isForbidden,
      isInCart: isInCart,
      isRestoreToOriginalPathsDisabled: isRestoreToOriginalPathsDisabled,
      isSelected: isSelected,
      jobIdFromName: jobIdFromName,
      openCart: openCart,
      openSnapshotSelector: openSnapshotSelector,
      removeFromCart: removeFromCart,
      resetDefaultAlternativeDirectory: resetDefaultAlternativeDirectory,
      sourceIdFromName: sourceIdFromName,
      submitTask: submitTask,
      toggleAttribsValues: toggleAttribsValues,
      transformEntityTypeFilter: transformEntityTypeFilter,
      transformFolderFilter: transformFolderFilter,
      transformVMnamesToIds: transformVMnamesToIds,
      updateRecoverTask: updateRecoverTask,
      updateTaskFilenames: updateTaskFilenames,
      viewBoxIdFromName: viewBoxIdFromName,
      changeOnRestoreMethod: changeOnRestoreMethod,
    });

    /**
     * @type Array - An ordered list of state names in this recover
     * to source flow.
     */
    $scope.stateNames = [
      'recover',
      'recover-files.search',
      'recover-files.options',
    ];

    /**
     * Transforms a single or list of EntityProto(s) into a flat list of
     * Entity IDs
     *
     * @method     transformVMnamesToIds
     * @param      {Array|Object}  entities  Single or list of VM EntityProtos
     * @return     {Array}                   Array of IDs
     */
    function transformVMnamesToIds(entities) {
      var out = [];

      if (entities) {
        entities = [].concat(entities);
      }
      // Optimize this lookup if there's one item
      if (entities.length === 1) {
        $scope.shared.filterPropLookups.entityIds.some(
          function vmFinderFn(server) {
            if (entities[0] && (entities[0] === server.displayName)) {
              out[0] = server.id;
              return true;
            }
            return false;
          }
        );
      } else {
        out = $scope.shared.filterPropLookups.entityIds.reduce(
          function reduceToIdsFn(ids, server) {
            if (entities.includes(server.displayName)) {
              ids.push(server.id);
            }
            return ids;
          },
        []);
      }
      return out;
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function jobIdFromName(names) {
      return $scope.shared.filterPropLookups.jobIds.reduce(function jobNameMapperFn(ids, job) {
        if (names.includes(job.jobName)) {
          ids.push(job.jobId);
        }
        return ids;
      }, []);
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     viewBoxIdFromName
     * @param      {Object}  viewBoxes  The viewBox names to get the IDs for
     * @return     {Array}              The viewBox ids
     */
    function viewBoxIdFromName(viewBoxes) {
      var out = [];
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        viewBoxes.forEach(function viewBoxLookup(viewBoxName) {
          $filter('filter')($scope.shared.filterPropLookups.viewBoxIds, {name: viewBoxName})
            .forEach(function idMapper(item) {
              out.push(item.id);
            });
        });
      }
      return out;
    }

    /**
     * Transform an array of Source names to Source IDs
     *
     * @method     sourceIdFromName
     * @param      {Array}  names   Array Source names
     * @return     {Array}          Array of Source IDs
     */
    function sourceIdFromName(names) {
      if (!Array.isArray(names)) {
        return [];
      }

      return names.reduce(
        function eachSourceName(sourceIds, name) {
          $scope.shared.filterPropLookups.registeredSourceIds.some(
            function eachSource(source) {
              if (source._typeEntity && source._typeEntity.name === name) {
                // If we have a match, add it to the accumulator and stop
                // looking for this ID.
                return sourceIds.push(source.id);
              }
            }
          );
          return sourceIds;
        },
        []
      );
    }

    /**
     * Transforms file/folder filter string into a Bool.
     * True == folders only; False == files only; undefined == all
     *
     * @method     transformFolderFilter
     * @param      {String}  choice  File or Folder only string
     * @return     {Bool}  The result value
     */
    function transformFolderFilter(choice) {
      return choice.value;
    }

    /**
     * Transforms an entityType filter object to its filter key
     *
     * @method     transformEntityTypeFilter
     * @param      {Object}  selections  selected Entity type filters
     * @return     {Array}   Array of filter keys
     */
    function transformEntityTypeFilter(selections) {
      var out = [];
      if (selections) {
        selections = [].concat(selections);
        selections.forEach(function getEntityTypeKeys(entityTypeFilter) {
          out.push(entityTypeFilter.value);
        });
      }
      return out;
    }

    /**
     * Transforms a date into microseconds
     *
     * @method     formatFilterDate
     * @param      {String}  date    Date String to transform
     * @return     {Int}             Date in microseconds
     */
    function formatFilterDate(date) {
      return $filter('dateToUsecs')(date);
    }


    // WATCHERS

    // When adding an entity to the cart, we show the cart immediately.
    $scope.$watchCollection('shared.taskCart', function cartWatcherFn(nv, ov) {
      var newTargetOS;

      updateTaskFilenames();

      if (nv && !nv.length && ov.length) {
        // We've just emptied the cart.

        $scope.shared.isArchiveTargetSelected = false;

        // Unlock the filters (doesn't trigger a re-query).
        cSearchService.unlockFilters($scope.shared.searchId, false);
      } else if (nv && nv.length) {
        // We only modified the cart.

        // Get the OS of the first entity.
        newTargetOS = nv[0]._hostType;
        if (angular.isDefined(newTargetOS) &&
          $scope.shared.targetOS !== newTargetOS) {
            $scope.shared.targetOS = newTargetOS;
        }

        if (!$stateParams.entityId) {
          resetDefaultAlternativeDirectory();
        }

        // Check if any selected files are unknown volumes: those named lvol_#
        // or vol_#. This means Yoda can't determine the volume name and we
        // can't restore to the original target.
        $scope.hasUnknownVolumes = nv.some(function unknownVolumeChecker(file) {
          return (/^.?vol_/i).test(file._path);
        });
      }
    });

    // When we reach the last step of the flow, lets teardown a couple
    // things to prevent backing up in the history and resubmitting the
    // same data
    taskDestroyer = $scope.$watch('lastStep', function lastStepWatcherFn(nv) {
      if (nv) {
        destroyTask();
        taskDestroyer();
      }
    });

    $scope.$on('$destroy', destroyFlowFn);


    // METHODS

    /**
     * Initialize all the things!
     *
     * @method     fileRestoreInit
     */
    // TODO: Get rid of this in favor of the one in file.search partial
    // (preprefetch Fn)
    function fileRestoreInit() {
      initSharedTaskConfig();
      getSources();
      $scope.preserveAttribs = !!$scope.shared.task.params.restoreFilesPreferences.preserveAttributes;

      ExternalTargetService.getTargets().then((vaults) =>
        {$scope.shared.vaults = vaults;});
    }

    /**
     * Utter annihilation
     *
     * @method     destroyFlowFn
     */
    function destroyFlowFn() {
      destroyTask();
      taskDestroyer();
      cSearchService.destroy($scope.shared.searchId);
    }

    /**
     * Fetches the list of vCenters
     *
     * @method     getSources
     * @return     {Promise}  $Q promise carrying the server's response
     */
    function getSources() {
      return SourceService
        .getSources({
          onlyReturnOneLevel: true,
          envType: cUtils.onlyNumbers(ENV_GROUPS.sqlHosts)
        })
        .finally(function sourcesGottenFn(resp) {
          $scope.Sources = SourceService.flatSources || [];
        });
    }

    /**
     * Submit the task to the server!
     *
     * @method     submitTask
     * @return     {Promise}  $Q Promise carrying the server's response (the
     *                        restoreTask)
     */
    function submitTask() {
      $scope.isSubmitting = true;

      preflightTask();

      return RestoreService.restoreFiles($scope.shared.task).then(
        function taskCreatedFn(restoreTask) {
          $state.go('recover-detail-local', {
            id: restoreTask.performRestoreTaskState.base.taskId,
          });
          return restoreTask;
        },
        evalAJAX.errorMessage
      ).finally(
        function taskCreatedFinallyFn(restoreTask) {
          $scope.isSubmitting = false;
        }
      );
    }

    /**
     * Perform preflight operations on the task before submission.
     *
     * @method    preflightTask
     */
    function preflightTask() {

      if ($scope.shared.taskCart[0]._archiveTarget &&
        $scope.shared.taskCart[0]._archiveTarget.archiveUid) {

        // When this archivalTarget is a remote archive, set up the task param.
        // `archivalTarget` can be undefined.
        $scope.shared.task.sourceObjectInfo.archivalTarget =
          $scope.shared.taskCart[0]._archiveTarget.target.archivalTarget;
      }

      $scope.shared.task.restoreVlanParams = RestoreService.getVlanParams(
        $scope.shared.selectedVlanTarget
      );

      $scope.shared.task.params.restoredFileInfoVec =
        $scope.shared.taskCart.map(function filenameMapperFn(fileEntity) {
          return {
            absolutePath: fileEntity.fileDocument.filename,
            isDirectory: fileEntity.isDirectory,
          };
        });

      // Reliably set targetHostType.
      // Its only relevant to vmware, hyperv, acropolis and cloud entity types.
      var targetHostEnvs = [1, 2, 8, 12, 16, 20, 'kVMware', 'kHyperV', 'kAzure',
        'kAcropolis', 'kAWS', 'kGCP'];
      var targetTypedEntity =
        SourceService.getTypedEntity($scope.shared.task.params.targetEntity);
      if (targetHostEnvs.includes($scope.shared.task.params.targetEntity.type)
        && targetTypedEntity) {
        $scope.shared.task.params.targetHostType = targetTypedEntity.hostType;
      }
    }

    /**
     * Creates a file download task.
     *
     * @method   createFileDownloadTask
     */
    function createFileDownloadTask() {
      // cloud download task specific settings
      performDownloadTaskSetting()
        .then(function createDownloadTask() {
          RestoreService.restoreFiles($scope.shared.task)
            .then(function taskCreatedFn(restoreTask) {
              $scope.shared.restoreTask = restoreTask;

              cMessage.success({
                titleKey:
                  'protectionRecoveryFiles.downloadFileCloud.modalTitle',
                textKey:
                  'protectionRecoveryFiles.downloadFileCloud.downloadTaskSuccessText',
                textKeyContext: {
                  restoreTaskId: $scope.shared.restoreTask.performRestoreTaskState.base.taskId
                },
              });

              $state.go('recover-cloud');
            }, evalAJAX.errorMessage);
        });
    }

    /**
     * set download task specific settings
     *
     * @method   performDownloadTaskSetting
     * @return   {Object}  $Q Promise to create download task after setting up
     *                     required properties.
     */
    function performDownloadTaskSetting() {
      // update task name to download task
      $scope.shared.task.name = RestoreService.getDefaultTaskName(
        'Download', $scope.text.files);
      updateTaskFilenames();
      return updateRecoverTask()
        .then(function updateDownloadTask() {
          // update directory name
          angular.merge($scope.shared.task, {
            params: {
              restoreFilesPreferences: {
                alternateRestoreBaseDirectory:
                  $scope.getDefaultAlternativeDirectory($scope.shared.task.name)
              }
            }
          });

          // Magneto makes decision based on targetEntity to create download
          // task. If there's no targetEntity on params, magneto creates a
          // download task, otherwise a file recover task.
          $scope.shared.task.params.targetEntity =
            $scope.shared.task.params.targetEntityCredentials =
            $scope.shared.task.params.targetEntityParentSource = undefined;

          $scope.shared.task.sourceObjectInfo.archivalTarget =
            $scope.shared.taskCart[0]._archiveTarget.target.archivalTarget;
        });
    }

    /**
     * Creates a file download task.
     *
     * @method   createPublicFileDownloadTask
     */
    function createPublicFileDownloadTask() {
      performPublicDownloadTaskSetting()
        .then(function createDownloadTask() {
          RestoreService.downloadFilesAndFolders($scope.shared.downloadTask)
            .then(function taskCreatedFn(resp) {
              cMessage.success({
                titleKey:
                  'protectionRecoveryFiles.downloadFileCloud.modalTitle',
                textKey:
                  'protectionRecoveryFiles.downloadFileCloud.downloadTaskSuccessText',
                textKeyContext: {
                  restoreTaskId: resp.id
                },
              });

              $state.go(
                resp.archiveTaskUid ? 'recover-cloud' : 'recover-local'
              );
            }, evalAJAX.errorMessage);
        });
    }

    /**
     * set download task specific settings
     *
     * @method   performPublicDownloadTaskSetting
     * @return   {Object}  $Q Promise to create download task after setting up
     *                     required properties.
     */
    function performPublicDownloadTaskSetting() {
      var task;
      var sourceInfo;
      var archiveTarget;
      if (!$scope.shared.taskCart.length) {
        return;
      }

      // update task name to download task
      $scope.shared.task.name = RestoreService.getDefaultTaskName(
        'Download', $scope.text.files);
      return updateRecoverTask()
        .then(function updateDownloadTask() {
          updatePublicTaskFilenames();
          task = $scope.shared.task;
          sourceInfo = $scope.shared.task.sourceObjectInfo;
          archiveTarget = get($scope.shared.taskCart,
            '[0]._archiveTarget.target.archivalTarget');
          $scope.shared.downloadTask = {
            name: task.name,
            filesAndFoldersInfo: task.filenames,
            sourceObjectInfo: {
              jobId: sourceInfo.jobId,
              jobRunId: sourceInfo.jobInstanceId,
              jobUid: {
                clusterId: sourceInfo.jobUid.clusterId,
                clusterIncarnationId: sourceInfo.jobUid.clusterIncarnationId,
                id: sourceInfo.jobUid.objectId,
              },
              protectionSourceId: sourceInfo.entity.id,
              startedTimeUsecs: sourceInfo.startTimeUsecs,
            }
          };

          if (archiveTarget) {
            $scope.shared.downloadTask
              .sourceObjectInfo.archivalTarget = {
              vaultName: archiveTarget.name,
              vaultType: ENUM_ARCHIVAL_TARGET[archiveTarget.type],
              vaultId: archiveTarget.vaultId,
            };
          }
        });
    }

    /**
     * Updates the filenames in the Recover Task object with the contents
     * of the taskCart according to the public API request
     *
     * @method     updatePublicTaskFilenames
     */
    function updatePublicTaskFilenames() {
      $scope.shared.task.filenames =
        $scope.shared.taskCart.map(function filenameMapperFn(fileEntity, ii) {
          return {
            absolutePath: fileEntity.fileDocument.filename,
            isDirectory: fileEntity._type !== 'file',
          };
        });
    }

    /**
     * Updates the filenames in the Recover Task object with the contents
     * of the taskCart
     *
     * @method     updateTaskFilenames
     */
    function updateTaskFilenames() {
      if ($scope.shared && $scope.shared.task) {
        $scope.shared.task.filenames = getTaskFilenames();
      }
    }

    /**
     * Method that updates two properties simultaneously on the task. Both
     * are concerned with preserving file attributes, though the UI
     * normalizes them into a single control.
     *
     * @method     toggleAttribsValues
     * @param      {Bool}  val   The value to set the 2 attributes to
     */
    function toggleAttribsValues(val) {
      $scope.shared.task.params.restoreFilesPreferences.preserveAcls =
        $scope.shared.task.params.restoreFilesPreferences.preserveAttributes =
        $scope.shared.task.params.restoreFilesPreferences.preserveTimestamps = !!val;
    }

    /**
     * Gets the default task.name based on the date at time of calling.
     *
     * @method     getDefaultTaskName
     * @return     {String}  The default task.name
     */
    function getDefaultTaskName() {
      return RestoreService.getDefaultTaskName($scope.text.recover, $scope.text.files);
    }

    /**
     * Generates the predefined task name as a Windows compatible file path.
     *
     * @method     getDefaultAlternativeDirectory
     * @param      {String=}  taskName  The taskName, or use default
     * @return     {String}             The default folder path for restore
     */
    function getDefaultAlternativeDirectory(taskName) {
      var rootPath = 'C:';
      var delimiter = '\\';
      var isNAS = $scope.shared.taskCart[0] && ENV_GROUPS.nas.includes(
        $scope.shared.taskCart[0].fileDocument.objectId.entity.type);
      var targetTypedEntity =
        SourceService.getTypedEntity($scope.shared.task.params.targetEntity) ||

        // -1 hostType indicates "Unknown OS". This fallback entity ensures the
        // following doesn't fail noisily.
        { hostType: -1 };

      // If the hostType is undefined, check another place.
      var hostType = isNil(targetTypedEntity.hostType) ?
        $scope.shared.task.params.targetHostType : targetTypedEntity.hostType;

      // For Linux (0), AIX (3), Solaris (4) hosts and NAS entities, use
      // different root and delimiter.
      if (HOST_TYPE_GROUPS.nixFilesystems.includes(hostType) ||
        isNAS) {
        rootPath = '/tmp';
        delimiter = '/';
      }

      return [rootPath, taskName || getDefaultTaskName(), ''].join(delimiter);
    }

    /**
     * Resets the task alternative file directory to the default.
     *
     * @method    resetDefaultAlternativeDirectory
     */
    function resetDefaultAlternativeDirectory() {
      angular.merge($scope.shared.task, {
        params: {
          restoreFilesPreferences: {
            alternateRestoreBaseDirectory: getDefaultAlternativeDirectory()
          }
        }
      });
    }

    /**
     * Initialize the base task object (time dependent data within)
     *
     * @method     initSharedTaskConfig
     */
    function initSharedTaskConfig() {
      $scope.shared = {
        taskCart: [],
        selectedRows: [],
        searchDisplay: [],
        searchData: [],
        selectedVlanTarget: undefined,
        searchId: 'fileSearch',
        task: angular.merge({}, defaultFileRestoreTask, {
          name: getDefaultTaskName()
        })
      };
    }

    /**
     * Updates the recover task with changes in the taskCart list
     *
     * @method     updateRecoverTask
     * @return     {Object}  Promise to resolve
     */
    function updateRecoverTask() {
      // First cart entity
      var firstEntity =
        ($scope.shared.taskCart && $scope.shared.taskCart.length) ?
          $scope.shared.taskCart[0] : undefined;

      // Previously selected/configured targetEntity
      var workingTarget = $scope.shared.task.params.targetEntity;
      var taskUpdate;
      var firstEntityVersion;
      var source;
      var osTypeRestriction;

      //  If firstEntity was not defined, we can use the values of
      //  $scope.vmBrowserSelectedInfo as a substitute (if they are available)
      //  This case should only apply if the user clicked 'save and continue' within vmBrowser
      if (!angular.isDefined(firstEntity)) {
        if (!$scope.vmBrowserSelectedInfo ||
          !$scope.vmBrowserSelectedInfo.fileDocument) {
          // $scope.vmBrowserSelectedInfo.fileDocument is not defined, Leave
          return $state.go('recover');
        } else {
          firstEntity = angular.copy($scope.vmBrowserSelectedInfo);
        }
      }

      // If we have a cart entity and no previously selected targetEntity
      if (firstEntity && !workingTarget) {
        // Set the workingTarget to the entity of the first cart Entity
        workingTarget = firstEntity.fileDocument.objectId.entity;
      }

      if (workingTarget) {
        firstEntityVersion = firstEntity._versions[firstEntity._snapshotIndex];

        taskUpdate = {
          sourceObjectInfo: RestoreService.getRestoreObjectProto(firstEntity.fileDocument, firstEntityVersion)
        };

        return SourceService.getEntitiesById(workingTarget.id).then(
          function getEntitiesSuccess(resp) {
            // The queried entity is registered with this cluster. Therefore,
            // set it as the default targetEntity.
            var entity = Array.isArray(resp) && resp[0];
            var netappVolumeType = get(entity.netappEntity, 'volumeInfo.type');
            if (angular.isObject(entity)) {
              angular.merge($scope.shared.task, {
                params: {
                  targetEntity: entity,
                  // parentId will exist for VM but not physical.
                  targetEntityParentSource:
                    entity.parentId && {id: entity.parentId},
                }
              });
              $scope.shared.originalTargetExists = true;

              // This captures the initial targetEntity as pre-populated. And
              // once set, it remains unchanged. This is for use when resetting
              // other selections back to "original."
              if (!$scope.shared.originalTarget) {
                $scope.shared.originalTarget = entity;
              }

              // Megneto only supports Windows now. When Linux is supported,
              // turn persistentAgentForWindowsOnly feature flag to false.
              // Also check if the target host type is Windows(1).
              osTypeRestriction = FEATURE_FLAGS.persistentAgentForWindowsOnly ?
                $scope.shared.task.params.targetHostType === 1 : true;

              // If the restored entity is a vm, set the default restore method
              // to Auto Deploy.
              if (get($scope.shared.task.params, 'targetEntity.vmwareEntity')) {
                $scope.shared.task.params.restoreMethod = $scope.FLR_RESTORE_METHOD.kAutoDeploy;
              }
            }
            if (netappVolumeType === 2) {
              clearTargetEntity();
            }
            return resp;
          },
          function getEntitiesError(resp) {
            // The queried entity is not registered with this cluster, or there
            // was an error. Either way, clear the targetEntity so the user is
            // forced to select one from the list of registered targets.
            clearTargetEntity();
          }
        ).finally(
          function afterGetEntities() {
            return angular.merge($scope.shared.task, taskUpdate);
          }
        );
      }

    }

    function clearTargetEntity() {
      var taskParams = $scope.shared.task.params;
      $scope.shared.originalTargetExists = false;
      $scope.shared.remoteObject = true;
      taskParams.targetEntity = undefined;
      taskParams.restoreFilesPreferences.restoreToOriginalPaths = false;
    }

    /**
     * Change parameters when users select different restore method.
     *
     * @method  changeOnRestoreMethod
     */
    function changeOnRestoreMethod() {
      if ($scope.shared.task.params.restoreMethod ===
        $scope.FLR_RESTORE_METHOD.kUseHypervisorAPIs &&
        [1, 'kWindows'].includes(get($scope.shared.task.params.targetEntity, 'vmwareEntity.hostType'))) {

        $scope.shared.task.params.restoreFilesPreferences.preserveAcls =
          $scope.shared.task.params.restoreFilesPreferences.preserveAttributes =
          $scope.shared.task.params.restoreFilesPreferences.preserveTimestamps = false;
        $scope.shared.disableACL = true;
      } else {
        $scope.shared.task.params.restoreFilesPreferences.preserveAcls =
          $scope.shared.task.params.restoreFilesPreferences.preserveAttributes =
          $scope.shared.task.params.restoreFilesPreferences.preserveTimestamps = true;
        $scope.shared.disableACL = false;
      }
    }

    /**
     * Determines if the toggle-switch for restoreToOriginalPath should be
     * disabled.
     *
     * @method     isRestoreToOriginalPathsDisabled
     * @return     {Bool}                             True if toggle is disabled,
     *                                                False otherwise
     */
    function isRestoreToOriginalPathsDisabled() {
      var task = $scope.shared.task;

      // Return true if it is a remote object.
      if ($scope.shared.remoteObject) {
        return true;
      }

      // Quick sanity check for object
      if (!task.params.targetEntity || !task.sourceObjectInfo) {
        return false;
      }

      // True if hasUnknownVolumes is true, or...
      return $scope.hasUnknownVolumes ||

        // Volume type is Data-Protect Volume (Read-Only)
        get($scope.shared.taskCart, '0._volumeType') === 2 ||

        // if the entities are different
        !SourceService.isSameEntity(task.params.targetEntity, task.sourceObjectInfo.entity);
    }

    /**
     * Browse a VM
     *
     * @param      {Object}  [vm]    Optional vm to browse, or cached vm if not
     *                               provided
     */
    function browseVm(vm) {

      vm = vm || cachedVmForBrowsing;

      SlideModalService.newModal({
        templateUrl: 'app/global/c-vm-browser/c-vm-browser.html',
        controller: 'cVMBrowserParentController',
        size: 'full',
        // prevent escape key from closing modal
        keyboard: false,
        fullHeight: true,
        resolve: {
          pageConfig: {
            mode: 'file',
            vm: vm,
            lockedSnapshot: $scope.shared.lockedSnapshot,
            taskCart: function resolveTaskCart() {
              return $scope.shared.taskCart || [];
            },
          },
        },
      }).then(
        function browseSuccess(resp) {

          var vmFilter;
          var snapshotIndex;

          // since we send the cart into the file browser, empty the taskCart
          // and related properties and rebuild according to the returned cart
          $scope.shared.taskCart.length = 0;
          $scope.shared.task.params.targetEntity = undefined;
          $scope.shared.lockedSnapshot = undefined;

          if (!resp || !resp.files) {
            return;
          }

          // Add _archiveTarget to Server object.
          vm._archiveTarget = resp._archiveTarget;

          // cache the VM for future browsing.
          cachedVmForBrowsing = vm;

          // Assign Default target entity
          $scope.shared.task.params.targetEntity = vm.vmDocument.objectId.entity;

          // Get the corrosponding snapshot index from versions
          snapshotIndex = getSnapshotIndex(vm.vmDocument.versions, resp.snapshot);

          // Add files to cart
          addToCartFromBrowser(vm, resp.files, resp.volumeName, snapshotIndex);

          if (resp.snapshot && !$scope.shared.lockedSnapshot) {
            $scope.shared.lockedSnapshot = resp.snapshot;
          }

          // Configure Vm Filter object
          vmFilter = {
            display: $scope.text.serverName,
            property: 'entityIds',
            locked: true,
            transformFn: $scope.transformVMnamesToIds,
            value: [SourceService.getTypedEntity(resp.files[0].fileDocument.objectId.entity).name]
          };

          // Manually set Vm Filter
          cSearchService.addFilter($scope.shared.searchId, vmFilter);

          // Switch back to File Search
          $scope.shared.searchType = 'file';

          // Skip directly to options page if saveAndContinue flag is true
          if (resp.saveAndContinue) {
            // Because we are bypassing the cart directly from vmBrowser, we
            // need to set some temp vars on scope to be consumed by
            // updateRecoverTask on the next step.
            $scope.vmBrowserSelectedInfo = {
              _versions: vm.vmDocument.versions,
              _snapshotIndex: snapshotIndex,
              fileDocument: resp.files[0].fileDocument
            };
            goToOptions();
          }

          // After closing vm-browser, the "selection" screen appears which
          // only has "Continue To Options" and "Cancel" options.
          // If the selected snapshot is an archived snapshot, then "Continue
          // To Options" remains disabled and user doesn't know what to do.
          // The Cart Modal however has "Download" and "Add More" option in
          // addition to "Continue" and "Cancel". So it is prudent to show the
          // cart modal after returning from vm-browser selection.
          openCart();
        }
      );
    }

    /**
     * Format files returned from cVmBrowser for consumption by the Cart
     *
     * @method   addToCartFromBrowser
     * @param    {object}    server          The Server entity from which the
     *                                       files are coming.
     * @param    {array}     items           Files/Folders returned from
     *                                       cVmBrowser.
     * @param    {integer}   volumeName      the name of the disk volume.
     * @param    {integer}   snapshotIndex   The index of the selected
     *                                       Snapshot.
     */
    function addToCartFromBrowser(server, items, volumeName, snapshotIndex) {
      (items || []).forEach(function entityMaker(item) {
        addToCart({
          _name: item.name,
          isDirectory: item.isFolder,

          // original item retained for easy rebuilding of file browser cart
          // when/if user returns to add more files from the file browser
          _originalItem: item,
          _path: item.path,
          _type: item.isFolder ? 'directory' : 'file',
          _snapshotIndex: snapshotIndex || 0,
          _snapshot: server.vmDocument.versions[snapshotIndex],
          _server: server,
          _archiveTarget: server._archiveTarget,
          _hostType: server._hostType,
          _jobType: server._jobType,
          _versions: server.vmDocument.versions,
          _serverType: item.fileDocument.objectId.entity.physicalEntity ?
            'physical' : 'vm',
          _volumeType: get(server.vmDocument.objectId.entity, 'netappEntity.volumeInfo.type'),
          fileDocument: item.fileDocument,
          entityId: item.fileDocument.objectId.entity.id,
        }, true);
      });
    }

    /**
     * Get the flat list of Filename strings for task submission
     *
     * @method     getTaskFilenames
     * @param      {Array=}  cart    Optional Array of file Entities.
     *                               Defaults to the taskCart
     * @return     {Array}           Array of filename (path) strings
     */
    function getTaskFilenames(cart) {
      cart = cart || $scope.shared.taskCart || [];
      return cart.map(function filenameMapperFn(fileEntity, ii) {
        return fileEntity.fileDocument.filename;
      });
    }

    /**
     * Open the snapshot selector for the given entity
     *
     * @method     openSnapshotSelector
     * @param      {Object}  entity  File Entity
     * @return     {Promise}         Modal close promise
     */
    function openSnapshotSelector(entity) {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/files/files.snapshot-modal.html',
        controller: 'recoverFilesSnapshotModal',
        size: 'lg',
        resolve: {
          fileEntity: function fileEntityDep() {
            var jobType;

            if (get($scope.shared, 'filterPropLookups.jobIds')) {
              $scope.shared.filterPropLookups.jobIds
                .some(function jobFinder(_job) {
                  if (_job.jobId === entity._jobId) {
                    jobType = _job.type;
                  }
                  return !!jobType;
                });
            }

            entity._jobType = jobType;
            entity._vaults = $scope.shared.vaults;

            return entity;
          },
        },
      };

      // Using this to propagate failure (dismiss) modal results out to
      // listening functions.
      var deferred = $q.defer();
      var params = {};

      SlideModalService
        .newModal(modalOpts)
        .then(
          function selectorClosedFn(resp) {
            angular.extend(entity, resp.fileEntity);

            $scope.shared.isArchiveTargetSelected =
              (get(resp.fileEntity, '_archiveTarget.target.type') === 3);

            if (resp.isFileDownloadTask) {
              $scope.shared.taskCart = [entity];

              return FEATURE_FLAGS.downloadFilesAndFoldersEnabled ?
                createPublicFileDownloadTask() :
                createFileDownloadTask();
            }

            switch (true) {
              case (!resp):
                break;

              case (resp.multiFile):
                $scope.shared.multiFile = true;
                break;

              case (!resp.multiFile):
                params = angular.merge({}, entity._versions[entity._snapshotIndex].instanceId, {
                  entityId: entity.fileDocument.objectId.entity.id
                });
                // Empty the search results
                $scope.shared.searchData.length = 0;
                params.resubmit = false;
                goToOptions(params);
                break;
            }
            return deferred.resolve(resp);
          },
          deferred.reject
        );

        return deferred.promise;
    }

    /**
     * Open the taskCart modal
     *
     * @method     openCart
     * @return     {Promise}  Modal response promise
     */
    function openCart() {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/files/files.cart-modal.html',
        controller: 'recoverFilesCartController',
        size: 'lg',
        resolve: {
          taskCart: function taskCartDep() {
            return $scope.shared.taskCart;
          },
        },
      };
      return SlideModalService
        .newModal(modalOpts)
        .then(function cartClosedFn(resp) {
          switch (resp) {
            case 'next':
              goToOptions();
              break;

            case 'downloadNow':
              createPublicFileDownloadTask();
              break;

            case 'close':
              goToSelectObjects();
              break;
          }
        });
    }

    /**
     * Reusable state transition: to options step
     *
     * @method     goToOptions
     * @param      {Object}  params  $stateParams map
     */
    function goToOptions(params) {
      $state.go($scope.stateNames[2], params);
      // Empty the search results
      $scope.shared.searchData.length = 0;
    }

    /**
     * Reusable state transition: to search + select step
     *
     * @method     goToSelectObjects
     */
    function goToSelectObjects() {
      $state.go($scope.stateNames[1]);
    }

    /**
     * Sum up the taskCart's file sizes (as available). This does not
     * include folder sizes(!!) because we don't get that info from Yoda.
     *
     * @method     getCartPhysicalUsage
     * @return     {Integer}  The byte size of all items in the cart
     *                        (as available)
     */
    function getCartPhysicalUsage() {
      return $scope.shared.taskCart
        .reduce(function cartSum(sum, entity) {
          if (entity._versions) {
            sum += entity._versions[entity._snapshotIndex].sizeBytes || 0;
          }
          return sum;
        }, 0);
    }

    /**
     * Destroys the search & task config.
     *
     * @method     destroyTask
     */
    function destroyTask() {
      // Brief timeout to allow states to render
      // with dependencies before cleanup
      $timeout(
        function destroyTaskTimeout() {
          // Gut the task config
          delete $scope.shared.task;
          $scope.$broadcast('destroy-task');
        },
        200
      );
    }

    /**
     * Adds the given entity to the taskCart
     *
     * @method     addToCart
     * @param      {Object}    entity              File Entity
     * @param      {Boolean}   addFromVmBrowser    Suppress add to cart
     *                                             confirmation modal if
     *                                             adding from vmBrowser.
     */
    function addToCart(entity, addFromVmBrowser) {
       if(entity._locked){
         return;
       } else {
         entity._locked = true;
       }
      var hasCompatibleSnapshot = false;
      var snapshotPromise;
      var params;

      /*
       * @type {object}  - Placeholder for a Host entity for which all
       *                   subsequent searches are filter-restricted to.
       */
      var restrictedHost;

      /*
       * @type {object}  - Placeholder for a selected Snapshotfor which all
       *                   subsequent file entity selections will be restricted
       *                   to.
       */
      var restrictedSnapshot;
      var incompatibleModalOptions;

      // Nothing in our cart yet.
      if (!$scope.shared.taskCart.length) {

        entity._selected = true;

        SearchService.entitySearch(
          {entityIds: entity.fileDocument.objectId.entity.id})
          .then(function gotEntity(resp) {
            // If this is empty, we can't infer the hostType. Skip it.
            if (!resp.length) {
              return;
            }
            $scope.shared.targetOS = resp[0]._hostType;
            entity._hostType = resp[0]._hostType;
            $scope.shared.task.params.targetHostType =
              ('vm' === entity._serverType) ? entity._hostType : undefined;
            entity._volumeType = resp[0]._volumeType;

            if (!$stateParams.entityId) {
              resetDefaultAlternativeDirectory();
            }

            snapshotPromise = addFromVmBrowser ?
              // A snapshot was already selected in the vmBrowser, resolve
              // immediately with that entity.
              $q.resolve(entity) :

              // Show the snapshot selector before adding it to the cart.
              openSnapshotSelector(entity);

            // With the promise resolved above, put the entity in the cart and
            // set some restriction properties for additional file selection
            // filtering.
            snapshotPromise.then(
              function snapshotSelectedFn(resp) {
                $scope.shared.taskCart.push(entity);
                $scope.shared.lockedSnapshot = entity._snapshot;
                $scope.shared.lockedArchivalTarget = entity._archiveTarget;
                return resp;
              }
            ).finally(function snapshotSelectedFinally() {
              entity._locked = false;
            });
          });

      } else {

        // We have something in the cart already (multiFile). Lets check that
        // there is a compatible version available before we add this file to
        // the cart.
        // NOTE: This is a "hack" because server-side filtering of results by
        // snapshot isn't and likely won't ever be available.
        params = RestoreService.fileVersionsArg(entity);

        // If the cart is empty, set snapshot restriction logic based on the
        // entity object, else reference the first item in the cart to set
        // restriction logic.
        restrictedHost = $scope.shared.taskCart[0] || entity;
        restrictedSnapshot = restrictedHost._snapshot;

        // Fetch versions
        RestoreService.getFileVersions(params).then(
          function versionsGottenFn(resp) {
            var versions = entity._versions = RestoreService.getRestorableVersions(resp.data.versions);

            // Here we're going to determine if the returned list of file
            // versions contains a compatible snapshot (jobRun) AND
            // archiveTarget (vault). Using Array#some to terminate looping if
            // we find the desired result.
            hasCompatibleSnapshot = versions.some(
              function locateCompatibleSnapshotFn(ver, ii) {
                var isSame = angular.equals(ver.instanceId, restrictedSnapshot.instanceId);

                // If this snapshot iteration is incompatible with the one
                // previously selected, Exit and keep looking.
                if (!isSame) { return; }

                // If they're equivalent, assign it to this new file object.
                angular.extend(entity, {
                  _snapshot: ver,
                  _snapshotIndex: ii,
                });

                // We have a matching snapshot. Now see if we can check for
                // archiveTargets. If not (`!ver.replicaInfo`), stop looping
                // (successfully) because we did find a compatible snapshot and
                // this cluster isn't returning replicaInfo (gflag
                // yoda_provide_replica_info_in_version=off).
                return !ver.replicaInfo ||
                  // If we *do* have replicaInfo in the response, when a
                  // matching archiveTarget is found, we attach it directly to
                  // the selected file object and terminate this sub-loop to
                  // continue. Note: when no compatible archiveTarget is found,
                  // this additional file object can not be recovered in the
                  // same task as the first one selected.
                  ver.replicaInfo.replicaVec.some(
                    function eachReplicaVec(repVec) {
                      // foundMatch is True when A) a remote archiveTarget was
                      // previously selected and is the same as this one, OR B)
                      // when no archiveTarget was previously selected
                      // (indicating a local snapshot) and this one is also type
                      // 1 (local).
                      var foundMatch = (!!restrictedHost._archiveTarget &&
                        angular.equals(repVec.target, restrictedHost._archiveTarget.target)) ||
                        (!restrictedHost._archiveTarget && repVec.target.type === 1);

                      // Only remote archiveTargets will be assigned. By
                      // re-checking restrictedHost._archiveTarget here we
                      // maintain the pattern where `undefined` represents a
                      // local archiveTarget.
                      entity._archiveTarget = restrictedHost._archiveTarget && foundMatch ?
                        repVec : undefined;

                      return foundMatch;
                    }
                  );
              }
            );

            // We have a fully compatible file snapshot & archival target! Add
            // it to the cart and open the cart UI.
            if (hasCompatibleSnapshot) {
              entity._selected = true;
              $scope.shared.taskCart.push(entity);
              $scope.shared.lockedSnapshot = entity._snapshot;

              // Open the cart if not adding from Vm Browser
              if (!addFromVmBrowser) {
                openCart();
                entity._locked = false;
              }
            } else {
              // No match: Show modal telling the user this file has no
              // compatible snapshot.
              incompatibleModalOptions = {
                title: $interpolate($scope.text.incompatibleSnapshotModal.title)($scope),
                content: $interpolate($scope.text.incompatibleSnapshotModal.content)(entity),
                hideCancelButton: true,
                actionButtonText: $scope.text.iUnderstand,
              };
              cModal.showModal({}, incompatibleModalOptions);
              entity._locked = false;
            }
          }
        );

      }
    }

    /**
     * Remove files from the cart.
     *
     * @method     removeFromCart
     * @param      {Object}  entity  File entity
     * @return     {Array}           TaskCart in it's updated state
     */
    function removeFromCart(entity) {
      $scope.shared.taskCart.some(
        function cartItemLocatorFn(cartEntity, ii) {
          // Compare filename & entityId
          var match = (entity.fileDocument.filename === cartEntity.fileDocument.filename) &&
                (entity.fileDocument.objectId.entity.id === cartEntity.fileDocument.objectId.entity.id);
          if (match) {
            delete entity._selected;
            $scope.shared.taskCart.splice(ii, 1);
          }
          // When match == true, this loop terminates
          return match;
        });

      return $scope.shared.taskCart;
    }

    /**
     * Determines if the current item is selected. Also uses isInCart under
     * the hood.
     *
     * @method     isSelected
     * @param      {Object}  entity  File Entity
     * @return     {Bool}    True if the Entity is selected, False if not
     */
    function isSelected(entity) {
      return entity._selected || isInCart(entity);
    }

    /**
     * Determines if a given entity is forbidden from being added to the
     * cart.
     *
     * @method     isForbidden
     * @param      {Object}   entity   File entity
     * @return     {Bool}
     */
    function isForbidden(entity) {
      return $scope.shared.taskCart.some(function findEntityInCart(cartEntity) {
        switch (true) {
          case compareOneEntityFromCart(cartEntity, entity):
            return true;

          case (entity.isDirectory):
            return folderContainsFile(entity, cartEntity);

          default:
            return false;
        }
      });
    }

    /**
     * Determines if a given entity is already in the taskCart.
     *
     * @method     isInCart
     * @param      {Object}   entity  File entity
     * @return     {Bool}
     */
    function isInCart(entity) {
      return $scope.shared.taskCart.some(function findEntityInCart(cartEntity) {
        return compareOneEntityFromCart(cartEntity, entity);
      });
    }

    /**
     * getSnapShotIndex
     * @param  {Array} versions
     * @param  {Object} snapshot
     * @return {integer} Snapshot Index
     */
    function getSnapshotIndex(versions, snapshot) {
      var i = 0;
      var len = versions.length;

      for (i; i < len; i++) {
        // compare version[i].instanceId.jobStartTimeUsecs to that of
        // snapshot.instanceId.jobStartTimeUsecs
        if (versions[i].instanceId.jobStartTimeUsecs ===
          snapshot.instanceId.jobStartTimeUsecs) {
            // return the index if it matches
            return i;
        }
      }

      return 0;
    }

    /**
     * Function to compare one entity from the cart with another entity to
     * determine if there's ~equality. Generally for use within a loop.
     *
     * @method     compareOneEntityFromCart
     * @param      {Object}   cartEntity  File Entity from the taskCart
     * @param      {Object}   entity      another File Entity
     * @return     {Bool}                 True if they are ~equal
     */
    function compareOneEntityFromCart(cartEntity, entity) {
      switch (true) {
        // Is the leaf (file entity) in the cart
        case (fileEntityEquality(entity.fileDocument, cartEntity.fileDocument)):
          return true;

        // Is the file already included by a folder in the cart?
        case (cartEntity.isDirectory && !entity.isDirectory):
          return folderContainsFile(cartEntity, entity);

        default:
          return false;
      }
    }

    /**
     * Quick detection if a folder path contains the given file's path
     *
     * @method     folderContainsFile
     * @param      {Object}  folder  FileEntity of the folder
     * @param      {Object}  file    FileEntity of the file we're checking
     * @return     {Bool}            If the file is under the folder
     */
    function folderContainsFile(folder, file) {
      return (folder.fileDocument.filename === file._path) &&
        (folder.fileDocument.objectId.entity.id === file.fileDocument.objectId.entity.id);
    }

    /**
     * Dirty checks if two fileDocuments are equal
     *
     * @method     fileEntityEquality
     * @param      {Object}   ent1    fileDocument 1
     * @param      {Object}   ent2    fileDocument 2
     * @return     {Bool}             If they are equal
     */
    function fileEntityEquality(ent1, ent2) {
      var objectId1;
      var objectId2;

      if (!ent1 || !ent2) {
        return false;
      }

      // 'backupType' may not always be avaialble.
      // This check fails as a result. So use only 'entity', 'jobId' and
      // 'jobUid' from the entity for comparison
      objectId1 = omit(ent1.objectId, 'backupType');
      objectId2 = omit(ent2.objectId, 'backupType');

      return (ent1.filename === ent2.filename) &&
        angular.equals(objectId1, objectId2);
    }

    // Kick it off
    fileRestoreInit();
  }

})(angular);
