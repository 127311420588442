<div class="actions-row" cogTableActionsRow
  [ngClass]="{'actions-margin' : addMarginToActionsRow}" [hidden]="hidden">
  <div class="actions">
    <div class="checkbox-container">
      <mat-checkbox
        [checked]="hasValue() && isAllSelected()"
        [indeterminate]="hasValue() && !isAllSelected()"
        [disabled]="!table.canSelectAnyRows()"
        (change)="toggleSelection()"
        cogDataId="multi-action-checkbox"
        [inheritDataId]="true">
      </mat-checkbox>
    </div>
    <div class="actions-container">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="actions-message" *ngIf="displayStatus">
    <span class="selection-message">
      {{ isAllSelected() ?
          (allSelectedLabel || intl.table.multiAction.allItemsSelected) :
          (anySelectedLabel || intl.table.multiAction.selectedItems(selectedCount))
      }}
    </span>
    <span class="message-container">
      <button *ngIf="!isAllSelected() && table.canSelectAnyRows()"
        mat-button
        color="primary"
        (click)="selectAll()"
        cogDataId="multi-action-select-all"
        [inheritDataId]="true">
        {{ selectAllLabel || intl.table.multiAction.selectAllItems(sourceLength) }}
      </button>
      <button *ngIf="isAllSelected()"
        mat-button
        color="primary"
        (click)="clearAll()"
        cogDataId="multi-action-clear-all"
        [inheritDataId]="true">
        {{ clearLabel || intl.table.multiAction.clearAll }}
      </button>
    </span>
  </div>
</div>
