import { forEach } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
// Service: ActiveDirectory
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular) {
  'use strict';

  angular
    .module('C')
    .service('ActiveDirectoryService', ActiveDirectoryServiceFn);

  function ActiveDirectoryServiceFn(_, $http, $q, API, cUtils, ClusterService,
    AdServiceFormatter, SlideModalService, FORMATS, TenantService, UserService,
    LdapService, NgIrisContextService) {

    // Global cache of principals.
    var globalPrincipalsCache = {};

    var adService = {
      // Cached hash of AD domains
      activeDirectoriesCache: {},
      addDomain: addDomain,
      addPrincipals: addPrincipals,
      addPrincipalsToHash: addPrincipalsToHash,
      addPrincipalToCache: addPrincipalToCache,
      allTrustedDomainsCache: [],
      fetchDomainControllers: fetchDomainControllers,
      getActiveDirectories: getActiveDirectories,
      getActiveDirectoryByDomainName: getActiveDirectoryByDomainName,
      getCentrifyZones: getCentrifyZones,
      getDomainNames: getDomainNames,
      getDomainPrincipals: getDomainPrincipals,
      getPrincipalName: getPrincipalName,
      getPrincipals: getPrincipals,
      getTrustedDomains: getTrustedDomains,
      globalPrincipalsCache: globalPrincipalsCache,
      leaveDomain: leaveDomain,
      machineNamesHandler: machineNamesHandler,
      newAdModal: newAdModal,
      saveLdapProvider: saveLdapProvider,
      saveMachineAccounts: saveMachineAccounts,
      searchPrincipals: searchPrincipals,
      setTrustedDomainsState: setTrustedDomainsState,
      updateDomain: updateDomain,
      updateIgnoredTrustedDomains: updateIgnoredTrustedDomains,
      updatePreferredDomainControllers: updatePreferredDomainControllers,
      updateUserIdMapping: updateUserIdMapping,
      viewADModal: viewADModal,
    };

    var adEndpoint = API.public('activeDirectory');
    var principalsEndpoint = [adEndpoint, '/principals'].join('');

    // API METHODS

    /**
     * Fetch hash of all configured AD domains
     *
     * @method     getActiveDirectories
     * @param      {Object}  params   The params for the request.
     * @return     {object}  Q promise carrying the server's response
     */
    function getActiveDirectories(params) {
      params = params || {};
      var opts = {
        method: 'GET',
        url: adEndpoint,
        params: params,
      };
      return $http(opts).then(
        function getDomainsSuccess(response) {
          var ads = (response.data || [])
            .map(AdServiceFormatter.transformDomain);

          // List of promises to resolve based on the params.
          var promises = {};

          // Update the caches of ADs
          adService.activeDirectoriesCache = _hashAds(ads);
          adService.allTrustedDomainsCache = ads.reduce(
            function (acc, ad){
              acc.push(ad.domainName);
              acc = acc.concat(ad.trustedDomains);
              return acc;
            }, []
          );

          forEach(ads, function forEachAd(ad) {
            ad._isAdOwner = isEntityOwner(NgIrisContextService.irisContext, ad.tenantId);
          });

          // Update the list of promises based on the params.
          if (params._includeTenantInfo) {
            promises.tenantAds = TenantService.resolveTenantDetails(ads);
          }
          if (params._includeLdapInfo) {
            promises.ldapAds = LdapService.resolveLdapInfo(ads);
          }

          // If promises are not empty then resolve the merged results.
          return isEmpty(promises) ? ads : $q.allWithMerge(promises);
        }
      );
    }

    /**
     * Gets the detailed AD info for the provided domainName.
     *
     * @method   getActiveDirectoryByDomainName
     * @param    {String}   domainName   The domain name of the AD to get.
     * @return   {Object}   Promise resolving to the AD with the given id.
     */
    function getActiveDirectoryByDomainName(domainName) {
      // Set allUnderHierarchy true, to view the ADs assigned to a tenant.
      var params = {
        allUnderHierarchy: true,
        domains: domainName,
      };

      var opts = {
        method: 'GET',
        url: adEndpoint,
        params: params,
      };

      return $http(opts).then(function getActiveDirectorySuccess(res) {
          var ad = AdServiceFormatter.transformDomain(res.data[0]);
          ad._isAdOwner = isEntityOwner(NgIrisContextService.irisContext, ad.tenantId)
          return ad;
        });
    }

    /**
     * Fetch list of configured AD domain names
     *
     * @method     getDomainNames
     * @return     {Object}  Q promise carrying the server's response
     */
    function getDomainNames() {
      var opts = {
        method: 'GET',
        url: adEndpoint
      };
      return $http(opts).then(
        function getDomainNamesSuccess(response) {
          var ads = (response.data || [])
            .map(AdServiceFormatter.transformDomain);

          // Update the cache of ADs
          adService.activeDirectoriesCache = _hashAds(ads);

          return ads.map(
            function mapDomains(domain) {
              return domain.domainName;
            }
          );
        }
      );
    }

    /**
     * Delete the given AD directory from the registry
     *
     * @method     leaveDomain
     * @param      {Object}   data   Data for domain to delete. Expects
     *                               {username, password, domain}
     * @return     {Object}  Q promise carrying the server's response
     */
    function leaveDomain(data) {
      var opts = {
        method: 'DELETE',
        url: adEndpoint,
        data: data
      };
      return $http(opts).then(_updateBasicClusterInfo);
    }

    /**
     * Adds an AD directory to the registry
     *
     * @method     addDomain
     * @param      {Object}   data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function addDomain(data) {
      return $http({
        method: 'POST',
        url: adEndpoint,
        data: AdServiceFormatter.untransformDomain(data)
      }).then(function addDomainSuccess(response) {
        _updateBasicClusterInfo(response);
        return AdServiceFormatter.transformDomain(response.data || {});
      });
    }

    /**
     * Updates the AD directory stored in the registry.
     *
     * @method     updateDomain
     *
     * @param      {Object}  data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function updateDomain(data) {
      return $http({
        method: 'put',
        url: API.public('activeDirectory', data.domainName),
        data: AdServiceFormatter.untransformDomain(data)
      }).then(function updateDomainSuccess(response) {
        return AdServiceFormatter.transformDomain(response.data || {});
      });
    }

    /**
     * Updates the AD directory with new userIdMappingInfo.
     *
     * @method     updateUserIdMapping
     *
     * @param      {Object}  data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function updateUserIdMapping(data) {
      return $http({
        method: 'put',
        url: API.public('activeDirectory', data.domainName, 'idMappingInfo'),
        data: AdServiceFormatter.untransformDomain(data)
      }).then(function updateUserIdMappingSuccess(response) {
        return AdServiceFormatter.transformDomain(response.data || {});
      });
    }

    /**
     * Fetches Centrify Zones for selected domain.
     *
     * @method     getCentrifyZones
     *
     * @param      {Object}  params  Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function getCentrifyZones(params) {
      var opts = {
        method: 'GET',
        params: params,
        url: API.public('activeDirectory/centrifyZones'),
      };

      return $http(opts).then(
        function transformCentrifyZones(response) {
          return response.data || [];
        }
      );
    }

    /**
     * Add AD principals. This is a consolidated service which handles the
     * individual createUser and createGroup services on the backend.
     *
     * @method     addPrincipals
     * @param      {Array}    principals  to be added
     * @return     {Object}  promise to resolve the request. resolves with
     *                        list of added principals, rejects with raw
     *                        server response
     */
    function addPrincipals(principals) {
      return $http({
        method: 'post',
        url: principalsEndpoint,
        data: principals
      }).then(function addPrincipalsSuccess(resp) {
        return Array.isArray(resp.data) ? resp.data : [];
      });
    }

    /**
     * Fetch all partially matched principals from a specified domain
     *
     * @method  getDomainPrincipals
     * @param   {Object}   paramsObj  Request object
     * @return  {Object}  Q promise carrying the server's response
     */
    function getDomainPrincipals(paramsObj) {
      var opts = {
        method: 'GET',
        url: principalsEndpoint,
      };
      opts.params = paramsObj;

      return $http(opts).then(
        function transformDomainPrincipals(response) {
          return map(response.data, AdServiceFormatter.transformPrincipal);
        }
      );
    }

    /**
     * Fetch all partially matched principals from both LOCAL and all trusted AD
     * domains
     *
     * @method     searchPrincipals
     * @param      {Object}   paramsObj  Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function searchPrincipals(paramsObj) {
      var opts = {
        method: 'GET',
        params: paramsObj,
        url: API.public('principals/searchPrincipals'),
      };

      return $http(opts).then(
        function transformDomainPrincipals(response) {
          return map(response.data, AdServiceFormatter.transformPrincipal);
        }
      );
    }

    /**
     * Adds a provided list of Principals to a provided domain principals hash.
     *
     * @method     addPrincipalsToHash
     *
     * @param      {Array}   principalsList  A list of principals
     * @param      {Object}  domainHash      A nested hash of principals per
     *                                       domain
     */
    function addPrincipalsToHash(principalsList, domainHash) {
      principalsList.forEach(function loopPrincipals(principal) {
        // Update the global cache.
        addPrincipalToCache(principal);

        // Stub the domain in the domain/users hash.
        if (domainHash) {
          domainHash[principal.domain] = domainHash[principal.domain] || {};

          // Insert the principal in its respective domain.
          domainHash[principal.domain][principal.sid] = principal;
        }
      });
    }

    /**
     * Given a list of SIDs, returns a list of Principal objects. If any SIDs
     * are not yet cached, first fetches those principals and adds them to the
     * cache. Then returns the requested principals.
     *
     * @method     getPrincipals
     *
     * @param      {Array}   sids    List of SIDs.
     * @return     {Object}  Q promise with the assembled list of principals.
     */
    function getPrincipals(sids) {
      var opts = {
        method: 'GET',
        url: principalsEndpoint,
      };

      var resolvedPrincipals = [];
      var sidsToFetch = [];

      // Group the SIDs into two buckets: cached or uncached
      sids.forEach(function strainSids(sid) {
        if (globalPrincipalsCache[sid]) {
          // This SID has already been cached. Add it to the `resolvedPrincipals`
          // bucket.
          resolvedPrincipals.push(globalPrincipalsCache[sid]);
        } else {
          // This SID has not yet been cached. Add it to the `sidsToFetch`
          // bucket.
          sidsToFetch.push(sid);
        }
      });

      if (!sidsToFetch[0]) {
        // There are no new SIDs to be fetched. Simply return the list of
        // principals from cache.
        return $q.resolve(resolvedPrincipals);
      } else {
        // There are SIDs yet to be fetched. Prepare query params.
        opts.params = {sids: sidsToFetch};
      }

      return $http(opts).then(
        function transformPrincipals(response) {
          (response.data || []).forEach(
            function loopPrincipals(principal) {
              addPrincipalToCache(principal);
              resolvedPrincipals.push(principal);
            }
          );
          return resolvedPrincipals;
        }
      );
    }

    /**
     * Returns the name of a principal, given its SID.
     *
     * @method     getPrincipalName
     *
     * @param      {String}  sid     The sid
     * @return     {String}  The principal name or original sid.
     */
    function getPrincipalName(sid) {
      return globalPrincipalsCache[sid] &&
        globalPrincipalsCache[sid].fullName || sid;
    }

    /**
     * Updates the list of Machine Accounts
     *
     * @method     saveMachineAccounts
     * @param      {Object}   data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function saveMachineAccounts(data) {
      return $http({
        method: 'POST',
        url: API.public('activeDirectory', data.domainName, 'machineAccounts'),
        data: data,
      }).then(function saveMachineAccountsSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * Updates the LDAP Provider mapping.
     *
     * @method     saveLdapProvider
     * @param      {Object}   data    Request object
     * @return     {Object}  Q promise carrying the server's response
     */
    function saveLdapProvider(data) {
      return $http({
        method: 'PUT',
        url: API.public('activeDirectory', data.domainName, 'ldapProvider'),
        data: data,
      }).then(function saveMachineAccountsSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * Return a list of trusted domains for a given AD domain, or return
     * trusted domains for all ADs.
     *
     * @param      {Array}            adList    A list of AD config objects
     * @param      {String}           adDomain  An AD domain
     * @return     {Array|undefined}  list of trusted domains.
     */
    function getTrustedDomains(adDomain) {
      var trustedDomains;
      var adConfig = adService.activeDirectoriesCache[adDomain];

      if (adConfig) {
        // View Box is constrained to a single Active Directory, so only return
        // its trusted domains.
        trustedDomains = [].concat(adConfig.domainName);
        if (adConfig.trustedDomains) {
          trustedDomains = trustedDomains.concat(adConfig.trustedDomains);
        }
      } else {
        // View Box is not constrained to any Active Directory, so return all
        // trusted domains.
        trustedDomains = clone(adService.allTrustedDomainsCache);
      }

      return trustedDomains;
    }

    /**
     * Creates a hash of Active Directories using domain name as key.
     *
     * @method     _hashAds
     *
     * @param      {Array}   ads     The active directories
     * @return     {Object}  The ads hash else empty object.
     */
    function _hashAds(ads) {
      var out = {};

      if (!ads) {
        return out;
      }

      [].concat(ads).forEach(function eachAD(ad) {
        out[ad.domainName] = ad;
      });

      return out;
    }

    /**
     * Handles validation of entered tags and self manages adding them to the
     * model array. Self managing as ui-select is not handling adding multiple
     * values on paste, but does provide them to this function individually
     *
     * @method     machineNamesHandler
     * @param      {String}   value         The entered string value
     * @param      {Array}    validList     List of valid names
     * @param      {Array}    invalidList   List of invalid names
     * @return     {Boolean}  returns false to notify ui-select not to add the
     *                        name
     */
    function machineNamesHandler(value, validList, invalidList) {
      value = cUtils.cleanUiSelectTag(value);
      invalidList.length = 0;

      if (!value || arguments.length < 3) {
        return false;
      }

      if (value.length < 16 && FORMATS.netBIOScomputerName.test(value)) {
        if (!validList.includes(value)) {
          validList.push(value);
        }
      } else {
        if (!invalidList.includes(value)) {
          invalidList.push(value);
        }
      }

      // this prevents ui-select from adding the value
      // since it is added manually in this function
      return false;
    }

    /**
     * Fetches Domain Controllers for selected domain.
     *
     * @method     fetchDomainControllers
     *
     * @param      {Object}  params  Request object { domainName: domainName }
     * @return     {Object}  Q promise carrying the server's response
     */
    function fetchDomainControllers(params) {
      var opts = {
        method: 'GET',
        params: params,
        url: API.public('activeDirectory/domainControllers'),
      };

      return $http(opts).then(
        function transformDomainControllers(response) {
          return AdServiceFormatter.transformDomainControllers(response.data.domainControllers);
        }
      );
    }

    /**
     * Updates the list of Preferred Domain Controllers for an AD.
     *
     * @method     updatePreferredDomainControllers
     * @param      {Object}   data    request object params.
     * @return     {Object}   Q promise carrying the server's response
     */
    function updatePreferredDomainControllers(data) {
      return $http({
        method: 'PUT',
        url: API.public('activeDirectory', data.domainName, 'preferredDomainControllers'),
        data: data.preferredDomainControllers,
      }).then(function transformPreferredDomainControllers(response) {
        return response.data || {};
      });
    }

    /**
     * Updates the list of Blacklisted Trusted Domains for an AD.
     *
     * @method  updateIgnoredTrustedDomains
     * @param      {Object}   data    request object params.
     * @return     {Object}   Q promise carrying the server's response
     */
    function updateIgnoredTrustedDomains(data) {
      return $http({
        method: 'PUT',
        url: API.public('activeDirectory', data.domainName, 'ignoredTrustedDomains'),
        data: AdServiceFormatter.untransformIgnoredTrustedDomains(data),
      }).then(function transformIgnoredTrustedDomains(response) {
        return response.data || {};
      });
    }

    /**
     * Opens and slide modal with "Add Active Directory" view.
     *
     * @method     newAdModal
     * @return     {Object}  promise to resolve the cSlideModal
     */
    function newAdModal() {
      return SlideModalService.newModal({
        templateUrl: 'app/admin/access-management/active-directory/new.html',
        controller: 'addAdController as $ctrl',
        size: 'xl',
        keyboard: false,
      });
    }

    /**
     * Opens the view AD details component inside a modal.
     *
     * @method   viewADModal
     * @param    {Object}   ad   The ad whose details are to be viewed.
     * @returns  {Object}   Promise to resolve the cSlideModal.
     */
    function viewADModal(ad) {
      return SlideModalService.newModal({
        size: 'xl',
        keyboard: false,
        resolve: {
          innerComponent: 'activeDirectoryView',
          idKey: 'view-ad',
          bindings: {
            adDomainName: ad.domainName,
            disableModifications: true,
          },
        },
      });
    }

    /**
     * Adds/Updates an entry in the cache of AD Principals.
     *
     * @method   addPrincipalToCache
     * @param    {Object}    principal    A principal object.
     */
    function addPrincipalToCache(principal) {
      if (get(principal, 'sid')) {
        globalPrincipalsCache[principal.sid] = principal;
      }
    }

    /**
     * Sets the Discover Trusted Domains state for an Active Directory.
     *
     * @method     setTrustedDomainsState
     * @param      {Object}   data    Request object
     * @return     {Object}   Q promise carrying the server's response
     */
    function setTrustedDomainsState(data) {
      return $http({
        method: 'post',
        url: API.public(
          'activeDirectory',
          data.domainName,
          'enableTrustedDomainState'
        ),
        data: data,
      }).then(function addDomainSuccess(response) {
        _updateBasicClusterInfo(response);
        return AdServiceFormatter.transformDomain(response.data || {});
      });
    }

    /**
     * pass through function for AD update calls which updates
     * basicClusterInfo, as it caches a list of domains
     *
     * @param      {object}  resp    The server resp from API call
     * @return     {object}  same server response
     */
    function _updateBasicClusterInfo(resp) {
      ClusterService.getBasicClusterInfo(true);
      return resp;
    }

    return adService;
  }
})(angular);
