import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { get, isFunction } from 'lodash-es';
import { Subscription } from 'rxjs';

import { DataFilterValue } from '../data-filters/index';

/**
 * The Material Table Data Source provides pagination, sorting, but only
 * filtering for simple key string values. This version implements a
 * per-property filter that can be configured to work with any filter method.
 *
 * The gist is to convert a filter object to a string, to trigger the filter
 * changes, then convert that back to an object when applying the filters.
 */
export class TableDataSource<T> extends MatTableDataSource<T> {
  private _dataFilters: DataFilterValue<any, T>[];
  /**
   * A reference to the original filter method that we can fall back to if
   * the supplied filter is a simple string.
   */
  private _stringFilterPredicate;

  /**
   * Table data source subscription to be destroyed when source is disconnected.
   */
  private dataSourceSubscription: Subscription;

  /**
   * Creates an instance of TableDataSource.
   *
   * @param   initialData   Initial data to use
   */
  constructor(initialData: T[]) {
    super(initialData);

    // Save the original filter predicate to fall back to if a simple filter
    // is being used. Then replace the filter with property based filter.
    this._stringFilterPredicate = this.filterPredicate;
    this.filterPredicate = this.dataFilterPredicate;
    this.sortingDataAccessor = this.caseInsensitiveSortingDataAccessor;
  }
  public set dataFilters(dataFilters: DataFilterValue<any, T>[]) {
    this._dataFilters = dataFilters;
    // If the filter is empty or non-truthy, clear the filter
    // Otherwise, convert it to a string and save it.
    // This triggers the filter logic on the table.
    if (!dataFilters || !dataFilters.length) {
      this.filter = undefined;
    } else {
      this.filter = JSON.stringify(dataFilters);
    }
  }

  public get dataFilters(): DataFilterValue<any, T>[] {
    return this._dataFilters;
  }

  /**
   * Implement this function to return nil value such as '' or -1 when data value is undefined or null.
   * Otherwise sorting will fail when string or number is compared to null or undefined values.
   *
   * @param  fieldName  Data field name that have nil value.
   * @param  data  Data that has `fieldName` that is being processed by sortData function.
   * @return  Returns nil value to be used. Usually empty string or negative number.
   */
  getNilValue: ((fieldName: string, data: T) => string | number);

  /**
   * Override default sort function to handle null and undefined values, and provide
   * case insensitive sorting by default.
   *
   * @param  data  Data that will be sorted.
   * @param  sort  Material sort directive that contains info about sorting direction and property.
   * @returns  Returns sorted data.
   */
  sortData = (data: T[], sort: MatSort): T[] => {
    const { active, direction } = sort;

    if (!direction) {
      return data;
    }

    return data.sort((a: T, b: T) => {
      let aVal = get(a, active);
      let bVal = get(b, active);

      if (!aVal && isFunction(this.getNilValue)) {
        aVal = this.getNilValue(active, a);
      }

      if (!bVal && isFunction(this.getNilValue)) {
        bVal = this.getNilValue(active, b);
      }

      if (typeof aVal === 'string') {
        aVal = aVal.toLocaleLowerCase();
      }

      if (typeof bVal === 'string') {
        bVal = bVal.toLocaleLowerCase();
      }

      const dir = aVal > bVal ? -1 : aVal < bVal ? 1 : 0;

      return direction === 'asc' ? dir * -1 : dir;
    });
  };

  /**
   * Applies a separate filter for each filter property. Filter property methods can be
   * specified with the dataFilterPredicates property to provide custom sorting for
   * any property. If no predicate for a property is specified, this will use a default
   * predicate that uses a string comparision.
   */
  dataFilterPredicate = (data: T, filter: string): boolean => {
    // If the filter is a simple string rather than an object, default back to the
    // MatTableDataSource filter method
    if (!this.dataFilters) {
      return this._stringFilterPredicate(data, filter);
    }

    // Run the filter for each property
    return !this.dataFilters.some(dataFilter => !dataFilter.predicate(data, dataFilter.value, dataFilter.key));
  };

  /**
   * Provides natural sort order for locally sorted tables
   *
   * @param   data           The data row being sorted
   * @param   sortHeaderId   The sort header id to access the data
   * @return  If the data value is a string, this returns that value lowercased
   *          otherwise the value is returned as is.
   */
  caseInsensitiveSortingDataAccessor(data: T, sortHeaderId: string): string {
    if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }
    return data[sortHeaderId];
  }

  /**
   * Called when table source is connected. Will listen for data changes and updates paginator length.
   */
  connect() {
    const renderData = super.connect();
    this.dataSourceSubscription = renderData.subscribe(() => {
      if (this.paginator) {
        this.paginator.length = this.data.length;
      }
    });
    return renderData;
  }

  /**
   * Used by the MatTable. Called when it is destroyed.
   */
  disconnect() {
    super.disconnect();
    this.dataSourceSubscription?.unsubscribe();
    this.dataSourceSubscription = null;
  }
}
