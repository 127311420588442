import { uniq } from 'lodash-es';
import { isBoolean } from 'lodash-es';
import { some } from 'lodash-es';
import { set } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module and Component: jobModifySettings

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobModify')
    .controller('jobModifySettingsCtrl', jobModifySettingsCtrlFn)
    .component('jobModifySettings', {
      bindings: {
        job: '=',
        policy: '=',
        jobTree: '=',
        isEditMode: '<',
        backupType: '<?',
        remotelyManagedSnapshots: '<?'
      },
      controller: 'jobModifySettingsCtrl',
      templateUrl: 'app/protection/jobs/modify2/settings.html'
    });

  /**
   * @ngdoc component
   * @name C.jobModify:jobModifySettings
   * @function
   *
   * @description
   * Provides interface and logic for managing job settings in the job flow.
   *
   * @example
     <job-modify-settings job="$ctrl.job"></job-modify-settings>
   */
  function jobModifySettingsCtrlFn(_, moment, SlideModalService, PubJobService,
    PubJobServiceFormatter, PubSourceServiceFormatter, SourceService, evalAJAX,
    QuiesceConflictModalService, CONTROLLER_TYPE, ENV_GROUPS, FEATURE_FLAGS,
    PubSourceService, cUtils, $scope, TIME, $rootScope, DateTimeService) {

    var $ctrl = this;

    /* Default setting. Will be overwritten if user inputs a custom value and
     * toggles between all snapshots. This is only used for kPure Jobs so far.
     */
    var _defaultMaxSnapshotsOnPrimary = 5;

    // This is used to cache the indexing policy when the user populates the
    // same in case the toggle is switched OFF after user edited the
    // indexing inclusions/exclusions paths.
    var cachedIndexingPolicy = {
      // cacheEnabled: true - Cache has data and can be used
      // cacheEnabled: false - Fallback on default as cache does not have data.
      cacheEnabled: false,
      allowPrefixes: [],
      denyPrefixes: [],
    };

    // This value is inside $ctrl.job. So $onChanges doesn't get called when
    // this gets set on job object from outside the component because of nested
    // key. So need this to make comaparison in $doCheck
    var isDirectArchiveEnabled;

    // Default deny prefixes
    var defaultDenyPaths = [
      '/$Recycle.Bin',
      '/Windows',
      '/Program Files',
      '/Program Files (x86)',
      '/ProgramData',
      '/System Volume Information',
      '/Users/*/AppData',
      '/Recovery',
      '/var',
      '/usr',
      '/sys',
      '/proc',
      '/lib',
      '/grub',
      '/grub2',
      '/opt',
      '/splunk',
    ];

    assign($ctrl, {
      // Used to prevent user from selecting an end date in the past.
      today: moment().format(),
      uiSelectEmailValidator: cUtils.uiSelectEmailValidator,
      openDownloadAgentsModal: SourceService.downloadAgentsModal,
      CONTROLLER_TYPE: CONTROLLER_TYPE,
      isEnvironmentIndexable: PubJobServiceFormatter.isEnvironmentIndexable,
      alertingOptions: ['kSuccess', 'kFailure'],
      priorityOptions: ['kLow', 'kMedium', 'kHigh'],

      // Default options for Storage Snapshot Providers
      storageSnapshotProviderOptions: [],

      // Controller methods
      $doCheck: $doCheck,
      $onChanges: $onChanges,
      $onInit: $onInit,

      addDisk: addDisk,
      canConfigureSourceSideDedup: canConfigureSourceSideDedup,
      checkQuiesceCompatability: checkQuiesceCompatability,
      deleteDisk: deleteDisk,
      endDateGetterSetter: endDateGetterSetter,
      endDateToggled: endDateToggled,
      getCalendarMinDate: getCalendarMinDate,
      getSelectedSources: getSelectedSources,
      getSetOverrideDate: getSetOverrideDate,
      isFileDataLockDisabled: isFileDataLockDisabled,
      isSqlFileBased: isSqlFileBased,
      manageSnapshotsChanged: manageSnapshotsChanged,
      onChangeAutolockType: onChangeAutolockType,
      onChangeRetention: onChangeRetention,
      onLeverageStorageSnapshotsChange: onLeverageStorageSnapshotsChange,
      onQuiesceChange: onQuiesceChange,
      onStorageSnapshotProviderChange: onStorageSnapshotProviderChange,
      onToggleFileDataLock: onToggleFileDataLock,
      onToggleNasClusions: onToggleNasClusions,
      postScriptTimeoutGetterSetter: postScriptTimeoutGetterSetter,
      prePostScriptsSupported: prePostScriptsSupported,
      prePostScriptToggled: prePostScriptToggled,
      preScriptTimeoutGetterSetter: preScriptTimeoutGetterSetter,
      remotePrePostScriptsSupported: remotePrePostScriptsSupported,
      remotePreScriptHostGetterSetter: remotePreScriptHostGetterSetter,
      remotePreScriptUsernameGetterSetter: remotePreScriptUsernameGetterSetter,
      showAppConsistentBackupSetting: showAppConsistentBackupSetting,
      showQosPolicySetting: showQosPolicySetting,
      showVlanSetting: showVlanSetting,
      toggleAlertingOption: toggleAlertingOption,
      toggleIndexing: toggleIndexing,
      updateExcludeDisks: updateExcludeDisks,
    });

    /**
     * Component initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      var terminateTreeWatcher;

      $ctrl.endDateEnabled = !!$ctrl.job.endTimeUsecs;
      $ctrl.excludeDisks = !!$ctrl.job._envParams.excludedDisks;
      $ctrl.manageSnapshots =
        angular.isDefined($ctrl.job._envParams.maxSnapshotsOnPrimary);

      if ($ctrl.job.environment !== 'kView') {
        $ctrl.alertingOptions.push('kSlaViolation');
      }

      _cacheIndexingPolicyIfEnabled();

      if (FEATURE_FLAGS.leverageStorageSnapshots) {
        // leverageStorageSnapshots setting can be set using 2 different
        // properties. in edit mode set the toggle to the correct setting
        // according to the job properties
        $ctrl.leverageStorageSnapshotsEnabled =
          $ctrl.job.leverageStorageSnapshots ||
          $ctrl.job.leverageStorageSnapshotsForHyperflex;

        _getStorageSnapshotEnvironments();
      }

      if (FEATURE_FLAGS.sqlSourceSideDedup &&
        $ctrl.job.environment === 'kSQL') {
        terminateTreeWatcher = $scope.$watch(
          function treeWatcher() {
            // Return true when both of these objets are initialized.
            return !!($ctrl.jobTree.tree && $ctrl.job._selectedSources);
          },
          function treeChangeHandler(requirementsMet) {
            if (!requirementsMet) { return; }
            terminateTreeWatcher();
            $ctrl.sqlHostsMap = _getMapOfSqlHosts();
            $ctrl.getSelectedSources();
          }
        );
      }

      _initializeQoSPolicy();

      if (FEATURE_FLAGS.nasFileDataLock &&
        ENV_GROUPS.nas.includes($ctrl.job.environment)) {
        _setupFileDataLock();
      }

      // All done if in edit mode!
      if ($ctrl.isEditMode) {
        return;
      }

      // NOTE: Any conditional update to the indexing policy, if to be done,
      // should be added within _getDefaultIndexingPolicy().
      _detectAndSetIndexingConfig();

      // Make additional default settings configurations based on environment
      switch (true) {

        case $ctrl.job.environment === 'kVMware':
          $ctrl.job._envParams.fallbackToCrashConsistent =
            FEATURE_FLAGS.crashConsistentEnabled;
          break;

        case $ctrl.job.environment === 'kSQL':
          // Do nothing. Keeping this case for self-documentation.
          break;

        case $ctrl.job.environment === 'kView':
          $ctrl.job.createRemoteView = !!$ctrl.job.createRemoteView;
          break;

        // Remote Adapter, aka Puppeteer
        case $ctrl.job.environment === 'kPuppeteer':
          // Only Linux is supported for Remote Adapater Jobs so far.
          $ctrl.job.remoteScript.remoteHost.type = 'kLinux';

          // new Remote Adapter Jobs are Paused to start off to allow user
          // time to mount the View on the host system
          $ctrl.job.isPaused = true;
          break;

        case $ctrl.job.environment === 'kPhysical':
          $ctrl.job._envParams.incrementalSnapshotUponRestart = true;
          break;

        case $ctrl.job.environment === 'kPure':
          $ctrl.manageSnapshots = true;
          $ctrl.job._envParams.maxSnapshotsOnPrimary =
            _defaultMaxSnapshotsOnPrimary;
          break;

        case $ctrl.job.environment === 'kO365Outlook':
          break;

        case $ctrl.job.environment === 'kOracle':
          $ctrl.job._envParams = {
            persistMountpoints: true,
          };
          break;

        case ENV_GROUPS.nas.includes($ctrl.job.environment):
          $ctrl.job._envParams.continueOnError = true;
          break;
      }

      // Cache any adjustments to indexing made based on job environment.
      _cacheIndexingPolicyIfEnabled();
    }

    /**
     * Detects if file indexing is available for this job environment type, and
     * enables or disables it accordingly.
     *
     * @method   _detectAndSetIndexingConfig
     */
    function _detectAndSetIndexingConfig() {
      if (PubJobServiceFormatter.isEnvironmentIndexable(
          $ctrl.job.environment)) {
        $ctrl.job.indexingPolicy = _getDefaultIndexingPolicy();

        // Initialize cachedIndexingPolicy.
        cachedIndexingPolicy.allowPrefixes =
          [...$ctrl.job.indexingPolicy.allowPrefixes];
        cachedIndexingPolicy.denyPrefixes =
          [...$ctrl.job.indexingPolicy.denyPrefixes];
        cachedIndexingPolicy.cacheEnabled =
          $ctrl.job.indexingPolicy.cacheEnabled;
      } else {
        disableIndexing();
      }
    }

    /**
     * Fetches the default indexing policy for the given indexable
     * environment/backup type.
     *
     * NOTE: If any more cases are added to this function make similar changes
     * to enableIndexing function. In case of any changes added, verify for
     * view based and server based indexing flows with create/edit job cases.
     *
     * @method   _getDefaultIndexingPolicy
     * @return   {Object}   Specifies the indexing policy
     */
    function _getDefaultIndexingPolicy() {

      // Conditional modifications to the default indexing config based on
      // Job environments & backup type.
      switch(true) {

        // Direct Archive Enabled NAS is treated differently from regular NAS
        case $ctrl.job.isDirectArchiveEnabled:
          return {
            disableIndexing: false,
            denyPrefixes: [],
            allowPrefixes: ['/'],
          };

        // Refer ENV_GROUPS.indexableEntitiesExposedAsViews for details.
        // View indexing is costly as they may contain large no. of files
        // hence it is disabled by default.
        case ENV_GROUPS.indexableEntitiesExposedAsViews.includes(
          $ctrl.job.environment):

        // SQL volume-based jobs permit indexing, but not SQL file-based.
        case ['kSqlVSSFile', 'kSqlNative'].includes($ctrl.backupType):
          return {
            allowPrefixes: [],
            denyPrefixes: [],
            disableIndexing: true,

            // For these environments, cache should be disabled so that
            // when enableIndexing() is called for the 1st time, cache is not
            // read. Subsequent calles to enableIndexing() should read the
            // cache.
            cacheEnabled: false,
          };

        // NOTE: Additional cases to handle updates to default indexing
        // policy can be added here.

        // The default indexing policy for
        // ENV_GROUPS.indexableEntitiesExposedAsServers requires no change
        // which are a subset of ENV_GROUPS.indexableKvals.
        // Refer ENV_GROUPS.indexableEntitiesExposedAsServers for details.
        default:
          return {
            disableIndexing: false,
            allowPrefixes: ['/'],
            denyPrefixes: defaultDenyPaths,
            cacheEnabled: true,
          };
      }
    }

    /**
     * Initializes the QoS policy list for the backup job.
     *
     * @method   _initializeQoSPolicy
     */
    function _initializeQoSPolicy() {
      // Initialize Backup QoS policy setttings.
      $ctrl.backupQoSPolicyList = [
        'kBackupHDD',
        'kBackupSSD'
      ];

      if ($ctrl.job.environment === 'kOracle') {
        $ctrl.backupQoSPolicyList.push('kTestAndDevHigh');
      }

      if (FEATURE_FLAGS.enableBtAllQoSForBackups) {
        $ctrl.backupQoSPolicyList.push('kBackupAll');
      }

      // TODO(tauseef): Add custom QoS principals for other adaptors if needed.
    }

    /**
     * Bindings change listener.
     * 1.In case of SQL jobs, the indexing option needs
     *   to be turned on for SQL file based jobs and need to be turned off for
     *   SQL volume based jobs.
     *
     * 2.For AWS,
     *   'kAWSNative'          indexing is SUPPORTED
     *   'kAWSSnapshotManager' indexing is UNSUPPORTED
     *   'kRDSSnapshotManager' indexing is UNSUPPORTED
     *
     * @method   $onChanges
     * @param    {object}   changesObj   The changes object
     */
    function $onChanges(changesObj) {
      if (changesObj.backupType && !(changesObj.backupType.isFirstChange())
        || _checkRemotelyManagedSnapshotsIndexability(changesObj)) {

        _detectAndSetIndexingConfig();

      }

      // Everything after this is effective only in create/new mode.
      if ($ctrl.isEditMode) { return; }

      if (FEATURE_FLAGS.sqlIncrementalAfterRestartEnabled &&
        $ctrl.job.environment === 'kSQL' &&
        changesObj.backupType) {
          $ctrl.job._envParams.incrementalSnapshotUponRestart =
            // Set true if volume-based SQL job, otherwise undefined to not send
            // this in the request.
            changesObj.backupType.currentValue === 'kSqlVSSVolume' || undefined;
      }
    }

    /**
     * Some changes inside $ctrl.job object won't get caught in $onChanges.
     * So $doCheck is needed.
     */
    function $doCheck() {
      if (get($ctrl, 'job.isDirectArchiveEnabled')
        !== isDirectArchiveEnabled) {

        _detectAndSetIndexingConfig();

        set(isDirectArchiveEnabled, $ctrl, 'job.isDirectArchiveEnabled');
      }
    }

    /**
     * Returns whether remotelyManagedSnapshots should be checked for
     * indexability.
     * If a source is selected which can have remote and local snapshots and
     * one or the other is toggled, then indexing check must be performed
     * again. This function checks for such a scenario.
     *
     * @method  _checkRemotelyManagedSnapshotsIndexability
     * @param   {Object}  changesObj The object containing compnent changes
     * @return  {Boolean} True if remotelyManagedSnapshots should be checked for
     *                    indexability
     */
    function _checkRemotelyManagedSnapshotsIndexability(changesObj) {
      if (!changesObj) {
        return false;
      }

      return (changesObj.remotelyManagedSnapshots &&
        !changesObj.remotelyManagedSnapshots.isFirstChange() &&
        isBoolean(changesObj.remotelyManagedSnapshots.currentValue) &&
        isBoolean(changesObj.remotelyManagedSnapshots.previousValue) &&
        (changesObj.remotelyManagedSnapshots.currentValue !==
          changesObj.remotelyManagedSnapshots.previousValue));
    }

    /**
     * Turns on or off inclusions/exclusions for NAS Jobs.
     *
     * @method     onToggleNasClusions
     */
    function onToggleNasClusions() {
      var protectFilters;
      var filtersStub = {
        filePathFilters: {
          protectFilters: [],
          excludeFilters: [],
        },
      };

      var envParams = $ctrl.job._envParams;

      if (!$ctrl.job._hasFilePathFilters) {
        // Delete the filePathFilters attribute when Clusions is disabled.
        $ctrl.job._envParams.filePathFilters = undefined;
        return;
      }

      // If filePathFilters is undefined, then stub it out.
      if (!envParams.filePathFilters) {
        angular.merge(envParams, filtersStub);
      }

      protectFilters = envParams.filePathFilters.protectFilters;

      // At least one allow filter is required. Add '/' as default.
      if (!protectFilters.length) {
        protectFilters.push('/');
      }
    }

    /**
     * when leverageStorageSnapshots setting is updated, reset model values
     * or get appropriate options for user to select
     *
     * @method     onLeverageStorageSnapshotsChange
     */
    function onLeverageStorageSnapshotsChange() {
      if (!$ctrl.leverageStorageSnapshotsEnabled) {
        $ctrl.job.leverageStorageSnapshots = undefined;
        $ctrl.job.leverageStorageSnapshotsForHyperflex = undefined;
        $ctrl.selectedStorageSnapshotProvider = undefined;
      } else {
        _checkStorageSnapshotOptions();
      }
    }

    /**
     * when user selects a storage snapshot provider from dropdown call
     * appropriate method to set model value
     *
     * @method     onStorageSnapshotProviderChange
     */
    function onStorageSnapshotProviderChange() {
      _setStorageSnapshotValue($ctrl.selectedStorageSnapshotProvider.alias);
    }

    /**
     * according to selected storage type set appropriate value on job object
     *
     * @method   _setStorageSnapshotValue
     * @param    {String}   storageType    string indicating the selected
     *                                     storage type
     */
    function _setStorageSnapshotValue(storageType) {
      $ctrl.job.leverageStorageSnapshots = ['kPure', 'kNimble'].includes(storageType);
      $ctrl.job.leverageStorageSnapshotsForHyperflex =
        storageType === 'hyperFlex';
    }

    /**
     * when leverageStorageSnapshots is set to true check if user has any
     * registered Storage Snapshot sources and set appropriate values
     * on job object
     *
     * @method     _getStorageSnapshotEnvironments
     */
    function _getStorageSnapshotEnvironments() {
      PubSourceService.getSourcesInfo({
        environments: ['kPure', 'kHyperFlex', 'kNimble']
      }).then(function getSourcesSuccess(data) {
        // Check if we have kPure and kHyperFlex sources registered in the
        // cluster. This is an array with distinct values of storage snapshot
        // environments.
        $ctrl.storageSnapshotEnvironments =
          uniq(map(data.rootNodes || [], '_environment'));

        if ($ctrl.storageSnapshotEnvironments.includes('kPure') ||
          $ctrl.storageSnapshotEnvironments.includes('kNimble')) {
            $ctrl.storageSnapshotProviderOptions.push({
              name: "enum.envGroup.san",
              alias: 'san',
            });
        }

        if ($ctrl.storageSnapshotEnvironments.includes('kHyperFlex')) {
          $ctrl.storageSnapshotProviderOptions.push({
            name: 'hyperFlex',
            alias: 'hyperFlex',
          });
        }

        // For handling edit job scenarios:
        // If we have both Pure and Hyperflex sources registered in the cluster
        // and the leverage snapshots option is turned on, we preselect the
        // dropdown with property from the backend.
        if ($ctrl.storageSnapshotEnvironments[1] &&
          $ctrl.leverageStorageSnapshotsEnabled) {
          $ctrl.showSnapshotProviderSelector = true;
          $ctrl.selectedStorageSnapshotProvider =
            // SAN property is true
            $ctrl.job.leverageStorageSnapshots ?

            // Select SAN option in dropdown
            $ctrl.storageSnapshotProviderOptions[0] :

            // Since one property is true on basis of condition as line 320,
            // kHyperFlex options is selected in dropdown.
            $ctrl.storageSnapshotProviderOptions[1];
        }
      }, evalAJAX.errorMessage);
    }

    /**
     * according to the amount of Storage Snapshot sources registered, display
     * a dropdown to allow the user to pick their preferred option or auto
     * set the value if there is only one option to pick from.
     *
     * @method     _checkStorageSnapshotOptions
     */
    function _checkStorageSnapshotOptions() {
      // if the user has more than 1 Storage Snapshot environment registered
      // allow them to choose which one they want to use.
      if ($ctrl.storageSnapshotEnvironments[1]) {
        $ctrl.showSnapshotProviderSelector = true;
      } else {
        // if the user only has one Storage Snapshot source registered,
        // automatically set the option for the user.
        var storageType = $ctrl.storageSnapshotEnvironments[0];

        _setStorageSnapshotValue(storageType);
      }
    }

    /**
     * removes/disables indexing for the job
     *
     * @method     disableIndexing
     */
    function disableIndexing() {
      $ctrl.job.indexingPolicy = {
        disableIndexing: true,
      };
    }

    /**
     * Cache current indexing policy values if it is enabled else keep the
     * default values cached to pre-populate indexing when user enables it.
     *
     * @method   _cacheIndexingPolicyIfEnabled
     * @param    {boolean}   [forceCache]   Indicates if caching of indexing
     *                                      config should be forced.
     */
    function _cacheIndexingPolicyIfEnabled(forceCache) {
      var indexingPolicy = $ctrl.job.indexingPolicy;

      if (forceCache ||
        (indexingPolicy && !indexingPolicy.disableIndexing)) {
        cachedIndexingPolicy.allowPrefixes =
          [...indexingPolicy.allowPrefixes];
        cachedIndexingPolicy.denyPrefixes =
          [...indexingPolicy.denyPrefixes];
      }
    }

    /**
     * Determines if job is SQL file based job
     *
     * @method   isSqlFileBased
     * @return   {boolean}   True if sql file based job, False otherwise.
     */
    function isSqlFileBased() {
      // Indexing is disabled for file based SQL jobs
      return $ctrl.job.environment === 'kSQL' &&
        $ctrl.job._envParams.backupType !== 'kSqlVSSVolume';
    }

    /**
     * Populates the end date based on enabled state. Fired via ngChange.
     */
    function endDateToggled() {
      // Set the value on the Job accordingly.
      $ctrl.job.endTimeUsecs = $ctrl.endDateEnabled ?
        moment().endOf('day').add(1, 'year').toUsecDate() : undefined;
    }

    /**
     * Getter/setter for $ctrl.job.endDateUsecs to proxy usecs and msecs. Job's
     * end date is saved in usecs and the directive (cDatePicker) works with
     * msecs.
     *
     * @param      {String}   newDate  new msec value following change made via
     *                                 datepicker
     * @return     {Integer}  current value (msecs) to display in the date
     *                        picker
     */
    function endDateGetterSetter(newDate) {

      if (arguments.length) {
        // called as a setter
        $ctrl.job.endTimeUsecs =
          moment(newDate).endOf('day').toUsecDate();
      }

      return $ctrl.job.endTimeUsecs ?
        $ctrl.job.endTimeUsecs / 1000 : undefined;

    }

    /*
     * Adds another disk in exclude Disks settings
     *
     * @method     addDisk
     */
    function addDisk() {
      $ctrl.job._envParams.excludedDisks.push({});
    }

    /**
     * Deletes the disk in exclude Disk settings
     *
     * @method     deleteDisk
     * @param      {number}  type    index of the disk in list
     */
    function deleteDisk(index) {
      $ctrl.job._envParams.excludedDisks.splice(index, 1);
    }

    /**
     * update the envbackupParams when excludeDisks switch changes value
     *
     * @method     updateExcludeDisks
     */
    function updateExcludeDisks() {

      var envBackupParams = $ctrl.job._envParams;

      if ($ctrl.excludeDisks) {
        envBackupParams.excludedDisks = envBackupParams.excludedDisks || [];
        envBackupParams.excludedDisks.push({});
      } else {
        envBackupParams.excludedDisks = undefined;
      }

    }

    /**
     * manages the numSnapshotsToKeepOnPrimary property based upon
     * radio input changes.
     */
    function manageSnapshotsChanged() {

      if ($ctrl.manageSnapshots &&
        !angular.isDefined($ctrl.job._envParams.maxSnapshotsOnPrimary)) {

        // User turned on management of snapshots on primary (retain X
        // snapshots). Copy the default value into the job object.
        $ctrl.job._envParams.maxSnapshotsOnPrimary =
          _defaultMaxSnapshotsOnPrimary;

      } else if (!$ctrl.manageSnapshots &&
        angular.isDefined($ctrl.job._envParams.maxSnapshotsOnPrimary)) {

        /**
         * user turned off management of snapshots on primary (retain all
         * snapshots). replace the default value with the user's (possibly)
         * custom value before removing the property from the job object
         */
        _defaultMaxSnapshotsOnPrimary =
          $ctrl.job._envParams.maxSnapshotsOnPrimary;
        $ctrl.job._envParams.maxSnapshotsOnPrimary = undefined;

      }
    }

    /**
     * Checks the job for quiecsce compatiability and presents a modal for
     * actions if incompatability is discovered. This is only relevant for jobs
     * being edited or if the user has navigated backward in the flow after
     * selecting servers
     */
    function checkQuiesceCompatability() {
      // For HyperV jobs it is not necessary to show confirmational modal
      if (ENV_GROUPS.hyperv.includes($ctrl.job.environment)) {
        return;
      }

      var quiesceIncompatibleNodes = _getQuiesceIncompatibleNodes();

      /**
       * if app-aware support is disabled OR Crash-consistent fallback is
       * enabled OR all VMs selected support App-Consistent backups, there's
       * nothing to do here. Exit function.
       */
      if (!$ctrl.job.quiesce ||
        $ctrl.job._envParams.allowCrashConsistentSnapshot ||
        !quiesceIncompatibleNodes.length) {
        return;
      }

      /**
       * Instantiate a new modal instance to present the user with the option to
       * either disable App-Consistent backups or remove offending VMs from the
       * Job
       */
      QuiesceConflictModalService.openModal(quiesceIncompatibleNodes).then(
        function removedCrashConsistentFallback(reason) {
          $ctrl.jobTree.tree.forEach(_removeNonAppAwareNodesFromJob);
        },
        function keptCrashConsistentFallback(reason) {
          $ctrl.job._envParams.fallbackToCrashConsistent = true;
        }
      );

    }

    /**
     * returns a list of quiesce incompatible nodes currently protected by the
     * job
     *
     * @return     {Array}  list of quiesce incompatible nodes
     */
    function _getQuiesceIncompatibleNodes() {
      var incompatibleNodes = [];

      if ($ctrl.jobTree.tree && $ctrl.jobTree.tree.length) {
        incompatibleNodes =
          $ctrl.jobTree.tree.reduce(_aggregateQuiesceIncompatibleNodes, []);
      }

      return incompatibleNodes;
    }

    /**
     * recursively parses through nodes to find those that don't support
     * app-aware backups.
     *
     * @method   _aggregateQuiesceIncompatibleNodes
     * @param    {array}    incompatibleNodes   WIP list of incompatible nodes
     * @param    {object}   node                The node to check
     * @return   {Array}    list of incompatible nodes
     */
    function _aggregateQuiesceIncompatibleNodes(incompatibleNodes, node) {

      var isNodeAlreadyRepresented;

      if (node._isLeaf && node._isSelected && !node._isQuiesceCompatible) {

        // Since a VM can be represented multiple times in the tree, if the node
        // is a known duplicate the array should be checked to ensure the node
        // isn't already represented before adding to the array.
        isNodeAlreadyRepresented =
          node._isDuplicate &&
          incompatibleNodes.some(function findDupe(node2) {
            return PubSourceServiceFormatter.isSameSource(node.source,
              node2.source);
          });

        if (!isNodeAlreadyRepresented) {
          incompatibleNodes.push(node);
        }

      }

      // if node has children, recurse through them
      if (Array.isArray(node.nodes)) {
        return node.nodes.reduce(
          _aggregateQuiesceIncompatibleNodes,
          incompatibleNodes
        );
      }

      return incompatibleNodes;

    }

    /**
     * recursively parses through nodes to find those that don't support
     * app-aware backups and removes them from the Job.
     *
     * @param      {Object}  node    The node to check
     */
    function _removeNonAppAwareNodesFromJob(node) {

      if (node._isLeaf && node._isSelected && !node._isQuiesceCompatible) {

        if (node._isAncestorAutoProtected || node._isTagAutoProtected) {
          // in cases where the node is selected/protected via auto protection,
          // it is necessary to add the node to excludeSources so the job will
          // be updated properly if user completes the flow without using
          // jobSourceTreeController successfully
          ctrl.job.excludeSourceIds =
            ($ctrl.job.excludeSourceIds || []).push(node.protectionSource.id);
        } else {
          // when the node is selected directly (not via auto protection), it is
          // necessary to remove the node from job.sources
          $ctrl.job.sourceIds.some(function findAndRemove(sourceId, index) {
            if (sourceId === node.protectionSource.id) {
              $ctrl.job.sourceIds.splice(index, 1);

              return true;
            }
          });

        }

        // it is not necessary to worry about duplicates, as
        // Source.unselectNode handles that
        PubJobServiceFormatter.unselectNode(node, {
          // autoProtection is only relevant if ancestor is autoprotected,
          // in which case, this node will be explictly excluded from the Job
          autoProtect: node._isAncestorAutoProtected,
          excluding: node._isAncestorAutoProtected,
          selectedObjectsCounts: $ctrl.jobTree.selectedCounts,
          tree: $ctrl.jobTree.tree,
        });
      }

      // if node has children, recurse through them
      if (Array.isArray(node.nodes)) {
        node.nodes.forEach(_removeNonAppAwareNodesFromJob);
      }

    }

    /**
     * Toggles the provided alerting option by adding it or removing it from the
     * the Job's alertingPolicy[]
     *
     * @method   toggleAlertingOption
     * @param    {string}   alertingOption   The alerting option kVal
     * @return   {array}    the updated alertingPolicy[]
     */
    function toggleAlertingOption(alertingOption) {
      var alertingPolicy = $ctrl.job.alertingPolicy;
      var optionIndex = alertingPolicy.indexOf(alertingOption);

      if (optionIndex === -1) {
        alertingPolicy.push(alertingOption);
      } else {
        alertingPolicy.splice(optionIndex, 1);
      }

      return alertingPolicy;
    }

    /**
     * turns indexing on or off depending on current value
     *
     * @method toggleIndexing
     */
    function toggleIndexing() {
      if ($ctrl.job.indexingPolicy.disableIndexing) {
        // Indexing has been disabled. Force caching of previous config so it
        // can be restored if user toggles indexing back on.
        _cacheIndexingPolicyIfEnabled(true);
        disableIndexing();
      } else {
        // Indexing was enabled. Restore the indexingPolicy from default/cache.
        enableIndexing();
      }
      cachedIndexingPolicy.cacheEnabled = true;
    }

    /**
     * Enables indexing when user enables the indexing setting in advanced
     * settings. Handles both create/edit job scenarios with adapter specific
     * settings.
     *
     * @method enableIndexing
     */
    function enableIndexing() {
      if (cachedIndexingPolicy.cacheEnabled) {
        $ctrl.job.indexingPolicy.allowPrefixes =
          cachedIndexingPolicy.allowPrefixes;
        $ctrl.job.indexingPolicy.denyPrefixes =
          cachedIndexingPolicy.denyPrefixes;
      } else if (ENV_GROUPS.indexableEntitiesExposedAsViews.includes(
        $ctrl.job.environment)) {
        $ctrl.job.indexingPolicy.allowPrefixes = ['/'];
        $ctrl.job.indexingPolicy.denyPrefixes = [];
      } else {
        $ctrl.job.indexingPolicy.allowPrefixes = ['/'];
        $ctrl.job.indexingPolicy.denyPrefixes = defaultDenyPaths;
      }
    }

    /**
     * Shared getterSetter function to handle minutes to seconds conversion for
     * the timeout interval for pre & post scripts.
     *
     * @method   _timeoutMinsToSecsGetterSetter
     * @param    {integer}   mins         The minutes (if called as a setter)
     * @param    {string}    scriptType   The script type. It can have two
     *                                    values 'preScript' or
     *                                    'postBackupScript'
     * @param    {boolean}   setter       True if called as a setter, false if
     *                                    called as a getter
     * @return   {integer}   The minutes representation of the stored seconds
     */
    function _timeoutMinsToSecsGetterSetter(mins, scriptType, setter) {
      var incrementalBackupScript;
      if (!$ctrl.job[scriptType]) {
        $ctrl.job[scriptType] = { incrementalBackupScript: {} };
      }

      incrementalBackupScript = $ctrl.job[scriptType].incrementalBackupScript;

      if (!incrementalBackupScript) {
        return;
      }

      if (setter) {
        incrementalBackupScript.timeoutSecs =
          Number.isInteger(mins) ? mins * 60 : undefined;
      }

      return incrementalBackupScript.timeoutSecs ?
        incrementalBackupScript.timeoutSecs / 60 : undefined;
    }

    /**
     * Getter setter for pre script timeout in seconds which proxies the UI
     * presented minute value into a seconds value on the model. Returning the
     * minutes value.
     *
     * @method   preScriptTimeoutGetterSetter
     * @param    {integer}   timeoutInMin   The new minute value
     * @return   {integer}   current minute value for timeout
     */
    function preScriptTimeoutGetterSetter(timeoutInMin) {
      return _timeoutMinsToSecsGetterSetter(
        timeoutInMin,
        'preBackupScript',
        !!arguments.length
      );
    }

    /**
     * Getter setter for post script timeout in seconds which proxies the UI
     * presented minute value into a seconds value on the model. Returning the
     * minutes value.
     *
     * @method   postScriptTimeoutGetterSetter
     * @param    {integer}   timeoutInMin   The new minute value
     * @return   {integer}   current minute value for timeout
     */
    function postScriptTimeoutGetterSetter(timeoutInMin) {
      return _timeoutMinsToSecsGetterSetter(
        timeoutInMin,
        'postBackupScript',
        !!arguments.length
      );
    }

    /**
     * Determines whether pre/post scripts is supported for the configured job
     * or not.
     *
     * Currently all physical jobs, SQL Physical, SQL VM (Persistent agent) and
     * Oracle jobs support Pre/post scripts. If there are new adapters coming
     * which are agent based, they should be added to the condition.
     *
     * This condition will likely change in recent future as more adapters are
     * added to this.
     *
     * @method   prePostScriptsSupported
     * @return   {boolean}   True if the pre/post scripts is supported, false
     *                       otherwise
     */
    function prePostScriptsSupported() {
      var source = get($ctrl.job, '_selectedSources[0]');
      var hasEphemeralSources;
      if (get(source, '_environment') === 'kVMware') {
        hasEphemeralSources = some($ctrl.job._selectedSources,
          function isEphemeralSource(source) {
            return !get(source, '_envProtectionSource.hasPersistentAgent');
          }
        );
      }

      return FEATURE_FLAGS.prePostScriptsEnabled &&

        // Physical sources, SQL Physical and Oracle sources OR.
        (['kPhysical'].concat(ENV_GROUPS.databaseSources).includes(
          get(source, '_environment')) ||

          //  None of the SQL VMs selected should have ephemeral agent
          (get(source,'_environment') === 'kVMware' &&
            !hasEphemeralSources)
        );
    }

    /**
     * Sets default values if prescript/postscript are enabled and clears out
     * out the object if a script is disabled.
     *
     * @method   prePostScriptToggled
     * @param    {string}    scriptTypes         The list of scripts to be
     *                                           toggled. It can have two values
     *                                           'preBackupScript' and
     *                                           'postBackupScript'
     * @param    {boolean}   [areScriptsEnabled] True if scripts are enabled,
     *                                           False if disabled after toggle
     */
    function prePostScriptToggled(scriptTypes, areScriptsEnabled) {
      if (areScriptsEnabled) {
        // Set the default values.
        scriptTypes.forEach(function setupDefaultProperties(scriptType) {
          $ctrl.job[scriptType] = {
            incrementalBackupScript: {
              timeoutSecs: 900
            }
          };
        });
        if (scriptTypes.includes("preBackupScript")) {
          $ctrl.job.preBackupScript.incrementalBackupScript.continueOnError = true;
        }
      } else {
        scriptTypes.forEach(function clearScripts(scriptType) {
          $ctrl.job[scriptType] = undefined;
        });
      }
    }

    /**
     * Determines whether remote Pre/Post Scripts are supported for the
     * configured job. Remote Pre/Post Scripts are for agentless adapters
     *
     * Currently only NAS is supported adapter.
     *
     * @method   remotePrePostScriptsSupported
     * @return   {boolean}   True if remote Pre/Post Scripts are supported,
     *                       False otherwise.
     */
    function remotePrePostScriptsSupported() {
      if ($ctrl.job._fileStubbing) {
        return false;
      }
      var environment = get($ctrl.job, '_selectedSources[0]._environment');
      return (FEATURE_FLAGS.remotePrePostScriptsEnabled &&
        ENV_GROUPS.nas.includes(environment)) ||

        // Pre/post scripts for Pure
        (FEATURE_FLAGS.sanPrePostScriptsEnabled &&
          ENV_GROUPS.san.includes(environment));
    }

    /**
     * Getter setter for remote host address for both pre and post script. It
     * sets the host for both pre and post script when set and clears both when
     * the input is cleared.
     *
     * @method   remotePreScriptHostGetterSetter
     * @param    {string}   [hostname]   The hostname
     * @return   {string}   Returns the host if the value is set, undefined
     *                      otherwise.
     */
    function remotePreScriptHostGetterSetter(hostname) {
      // If this is a setter
      if (arguments.length) {
        if (hostname) {
          assign($ctrl.job.preBackupScript, {
            remoteHost: {
              type: 'kLinux',
              address: hostname,
            },
          });

          assign($ctrl.job.postBackupScript, {
            remoteHost: {
              type: 'kLinux',
              address: hostname,
            },
          });
        } else {
          $ctrl.job.preBackupScript.remoteHost =
            $ctrl.job.postBackupScript.remoteHost = undefined;
        }
      }

      return get($ctrl.job.preBackupScript,'remoteHost.address');
    }

    /**
     * Getter setter for remote host username for both pre and post script. It
     * sets the username for both pre and post script when set and clears both
     * when the input is cleared.
     *
     * @method   remotePreScriptUsernameGetterSetter
     * @param    {string}   [username]   The username
     * @return   {string}   Returns the username if the value is set, undefined
     *                      otherwise
     */
    function remotePreScriptUsernameGetterSetter(username) {
      // If this is a setter
      if (arguments.length) {
        if (username) {
          $ctrl.job.preBackupScript.username = username;
          $ctrl.job.postBackupScript.username = username;
        } else {
          $ctrl.job.preBackupScript.username =
            $ctrl.job.postBackupScript.username = undefined;
        }
      }

      return $ctrl.job.preBackupScript.username;
    }

    /**
     * Set value of continueOnQuiesceFailure on quiesce change
     *
     * @method   onQuiesceChange
     */
    function onQuiesceChange(){
      $ctrl.job.continueOnQuiesceFailure = $ctrl.job.quiesce;
    }

    /**
     * Check if QoS policy setting has to be displayed or not
     *
     * @method  showQosPolicySetting
     * @return  {Boolean}  True if setting is to be displayed. False otherwise.
     */
    function showQosPolicySetting() {
      return FEATURE_FLAGS.qosEnabled &&
        !['kView', 'kAWSSnapshotManager',
          'kAzureSnapshotManager', 'kRDSSnapshotManager']
            .includes($ctrl.job.environment);
    }

    /**
     * Check if vlan setting has to be displayed or not
     *
     * @method  showVlanSetting
     * @return  {Boolean}  True if setting is to be displayed. False otherwise.
     */
    function showVlanSetting() {
      return FEATURE_FLAGS.vlanForOracleBackup &&
        ['kOracle'].includes($ctrl.job.environment);
    }

    /**
     * Check if App Consistent Backup setting has to be displayed or not
     *
     * @method  showAppConsistentBackupSetting
     * @return  {Boolean}  True if setting is to be displayed. False otherwise.
     */
    function showAppConsistentBackupSetting() {
      return ENV_GROUPS.hypervisor.includes($ctrl.job.environment) &&
        !ENV_GROUPS.cloudJobsWithoutPhysicalAgent
          .includes($ctrl.job.environment);
    }

    /**
     * Determines if the User can modify the source-side dedup configuration for
     * this Job.
     *
     * @function   canConfigureSourceSideDedup
     * @returns    {boolean}   True if configuration can be modified. False
     *                         otherwise.
     */
    function canConfigureSourceSideDedup() {
      if ($ctrl.job.environment === 'kSQL' &&
        FEATURE_FLAGS.sqlSourceSideDedup) {
          // NOTE: The OS-specific checks below don't apply for MS SQL because
          // the host will always be a Windows host.
        return $ctrl.job._envParams.backupType !== 'kSqlVSSVolume';
      }

      return ($ctrl.job._isLinuxSourceSelected ||
        $ctrl.job._isWindowsSourceSelected) &&
        !['kSQL', 'kAD'].includes($ctrl.job.environment)
    }

    /**
     * Gets the objects to list in Source-side dedup select widget.
     *
     * @function  _getSourceSideDedupSources
     * @returns   {Array}   List of sources (hosts) to select from for SSDd
     *                      exclusion.
     */
    function _getSourceSideDedupSources() {
      return $ctrl.job.environment !== 'kSQL'
        ? $ctrl.job._selectedSources
        : _getSqlHostsForSelectedObjects();
    }

    /**
     * Resets the list of selected sources dfor use by the SSDd exclusions
     * picker.
     *
     * @function   getSelectedSources
     */
    function getSelectedSources() {
      $ctrl.selectedSources = _getSourceSideDedupSources();
    }

    /**
     * Returns a list of SQL hosts representing whatever is selected in the
     * current job. So for every SQL DB or SQL Instance selected, this list will
     * contain the host of those DBs, or multiple hosts if selection spans
     * multiple hosts.
     *
     * @function   _getSqlHostsForSelectedObjects
     * @returns    {Array}   The list of SQL hosts representing anything
     *                       selected in the current job.
     */
    function _getSqlHostsForSelectedObjects() {
      return $ctrl.job._selectedSources
        .reduce(function sqlHosts(hosts, selected) {
          var thisObjectsHost = $ctrl.sqlHostsMap[
            // DB entity's parent
            selected.protectionSource.parentId ||

            // Self id since this is a host
            selected.protectionSource.id
          ];

          if (!hosts.includes(thisObjectsHost)) {
            hosts.push(thisObjectsHost);
          }

          return hosts;
        }, []);
    }

    /**
     * Generates a map of all the hosts in the SQL tree, keyed by host id.
     *
     * @function   _getMapOfSqlHosts
     * @returns    {Object}   The map of SQL hosts in the tree.
     */
    function _getMapOfSqlHosts() {
      if ($ctrl.job.environment !== 'kSQL') { return {}; }

      return $ctrl.jobTree.tree
        .reduce(function sqlHostsMapper(mapResults, host) {
          mapResults[host.protectionSource.id] = host;
          return mapResults;
        }, {});
    }

    /**
     * Calculate and return the minimum allowed date for the File DataLock
     * override date picker.
     *
     * @method   getCalendarMinDate
     * @returns  {Number}   Minimum date allowed.
     */
    function getCalendarMinDate() {
      var minDate = $ctrl.job._fileLockConfig.calendar.tomorrow;

      if ($ctrl.job._envParams.fileLockConfig.mode === 'kCompliance') {
        minDate = $ctrl.job._fileLockConfig.calendar.expiry;
      }

      return minDate;
    }

    /**
     * Determines whether the File DataLock settings should be disabled.
     *
     * @method   isFileDataLockDisabled
     * @returns  {Boolean}   True if the File DataLock settings should be
     *                       disabled from user interaction.
     */
    function isFileDataLockDisabled() {
      var envParams = $ctrl.job._envParams;
      var user = $rootScope.user;

      // It is Edit mode AND one of the following is true:
      return $ctrl.isEditMode &&

        // Compliance mode and not DSO OR
        ((envParams.fileLockConfig.mode === 'kCompliance' && !user._isDso) ||

        // Enterprise mode and neither DSO nor Local Admin
        (envParams.fileLockConfig.mode === 'kEnterprise' &&
          !user._isDso && !user._isLocalAdmin));
    }

    /**
     * Sets dependent Retention times.
     *
     * @method    onChangeRetention
     */
    function onChangeRetention() {
      var fileLockConfig = $ctrl.job._envParams.fileLockConfig;

      // Min Retention is Forever, then Default must be Forever.
      if (fileLockConfig.minRetentionDurationMsecs === -1) {
        fileLockConfig.defaultFileRetentionDurationMsecs = -1;
      }

      // Min or Default Retention is Forever, then Max must be None.
      if (fileLockConfig.defaultFileRetentionDurationMsecs === -1 ||
        fileLockConfig.minRetentionDurationMsecs === -1) {
        fileLockConfig.maxRetentionDurationMsecs = undefined;
      }
    }

    /**
     * Responds to changes to the AutoLock type ui-select and sets default value
     * if none exists.
     *
     * @method    onChangeAutolockType
     */
    function onChangeAutolockType() {
      var fileLockConfig = $ctrl.job._envParams.fileLockConfig;
      if ($ctrl.job._fileLockConfig.autolock === 'kAfter') {
        fileLockConfig.autoLockAfterDurationIdle =
          fileLockConfig.autoLockAfterDurationIdle || 180000;
      }
    }

    /**
     * Getter/setter function for fileLockConfig.expiryTimestampMsecs
     *
     * @param      {Date}    newDate  new JavaScript Date Object following
     *                                change made via cDatePicker
     * @return     {Number}  current value (msecs) to display in the date picker
     */
    function getSetOverrideDate(newDate) {
      var fileLockConfig = $ctrl.job._envParams.fileLockConfig;

      if (newDate) {
        newDate = DateTimeService.endOfDay(newDate);
        fileLockConfig.expiryTimestampMsecs =
          DateTimeService.dateToUsecs(newDate) / 1000;
      }

      return fileLockConfig.expiryTimestampMsecs;
    }

    /**
     * Responds to the toggle switch for File DataLock section and bootstraps
     * the related model if not yet done.
     *
     * @method    onToggleFileDataLock
     */
    function onToggleFileDataLock() {
      var fileLockConfig = $ctrl.job._envParams.fileLockConfig;
      if ($ctrl.job._fileLockConfig.isEnabled) {
        fileLockConfig.mode = fileLockConfig.mode || 'kCompliance';
        fileLockConfig.defaultFileRetentionDurationMsecs =
          fileLockConfig.defaultFileRetentionDurationMsecs || -1;
        _setupFileDataLock();
      }
    }

    /**
     * Sets up auxillary model for File DataLock section.
     *
     * @method    _setupFileDataLock
     */
    function _setupFileDataLock() {
      // Cache the actual File DataLock model from the View object.
      var fileLockConfig = $ctrl.job._envParams.fileLockConfig =
        $ctrl.job._envParams.fileLockConfig || clone({
          lockingProtocol: 'kSetReadOnly',
        });

      $ctrl.job._fileLockConfig = {
        autolock: 'kNo',
        autolockTypes: [
          {
            kValue: 'kNo',
            nameKey: 'no',
          },
          {
            kValue: 'kAfter',
            nameKey: 'after',
          },
        ],
        calendar: {
          expiry: fileLockConfig.expiryTimestampMsecs,
          today: Date.clusterNow(),
          tomorrow: Date.clusterNow() + TIME.msecsPerDay,
        },
        editingManualLock: false,
        isEnabled: !!fileLockConfig.defaultFileRetentionDurationMsecs,
        override: 'kOff',
        overrideTypes: [
          {
            kValue: 'kOff',
            nameKey: 'off',
          },
          {
            kValue: 'kDate',
            nameKey: 'specificDate',
          },
        ],
      };

      // Autolock
      if (fileLockConfig.autoLockAfterDurationIdle) {
        $ctrl.job._fileLockConfig.autolock = 'kAfter';
      }

      // Manual Lock
      if (fileLockConfig.minRetentionDurationMsecs ||
        fileLockConfig.maxRetentionDurationMsecs ||
        fileLockConfig.lockingProtocol === 'kSetAtime') {
          $ctrl.job._fileLockConfig.editingManualLock = true;
      }

      // Override til Date
      if (fileLockConfig.expiryTimestampMsecs) {
        $ctrl.job._fileLockConfig.override = 'kDate';
      }
    }
  }
})(angular);
