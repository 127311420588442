import { DurationSelectorValue } from '../duration-selector/duration-selector.model';

/**
 * Utility method to convert the form value to seconds.
 *
 * @param value The form value to convert.
 * @returns The duration in seconds represented by the specified value.
 */
export const convertValueToSeconds = (value: DurationSelectorValue): number => {
  let seconds = value.measurement;

  switch (value.unit) {
    case 'Minutes':
      seconds *= 60;
      break;
    case 'Hours':
      seconds *= 60 * 60;
      break;
    case 'Days':
      seconds *= 24 * 60 * 60;
      break;
  }

  return seconds;
};
