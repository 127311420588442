import {
  Environment,
  EnvironmentType,
  EnvItems,
  fileBrowsableEnvironments,
  nasEnvironments,
  storageVolumeEnvironments,
} from '@cohesity/iris-shared-constants';

/**
 * Sources that can be cloud spun
 */
export const CloudDeploySources: Environment[] = [
  Environment.kVMware,
];

/**
 * The various Protection Types for Cloud Jobs
 */
export enum CloudJobType {
  kAgent = 'kAgent',
  kNative = 'kNative',
  kSnapshotManager = 'kSnapshotManager',
  kRDSSnapshotManager = 'kRDSSnapshotManager',
  kAuroraSnapshotManager = 'kAuroraSnapshotManager',
  kAwsRDSPostgresBackup = 'kAwsRDSPostgresBackup',
  kAwsDynamoDB = 'kAwsDynamoDB',
  kAwsRDSPostgres = 'kAwsRDSPostgres',
  kAwsAuroraPostgres = 'kAwsAuroraPostgres',
  kAwsS3 = 'kAwsS3',
  kAzureSQL = 'kAzureSQL',
  kAzureEntraID = 'kAzureEntraID',
}

/**
 * List of valid environment parameter values for protection group.
 * Use a mapping here for faster check.
 */
export const protectionGroupEnvValues: {[key in EnvironmentType]?: boolean} = {
  kAcropolis: true,
  kAD: true,
  kAWS: true,
  kAzure: true,
  kCassandra: true,
  kCouchbase: true,
  kElastifile: true,
  kExchange: true,
  kFlashBlade: true,
  kGCP: true,
  kGenericNas: true,
  kGPFS: true,
  kHBase: true,
  kHdfs: true,
  kHive: true,
  kHyperV: true,
  kIsilon: true,
  kKubernetes: true,
  kKVM: true,
  kMongoDB: true,
  kNetapp: true,
  kO365: true,
  kOracle: true,
  kPhysical: true,
  kPure: true,
  kIbmFlashSystem: true,
  kRemoteAdapter: true,
  kSQL: true,
  kUDA: true,
  kView: true,
  kVMware: true,
  kAwsS3: true
};

/**
 * The type of recovery groups.
 */
export type RecoveryGroup =
  'vm' |
  'vmdk' |
  'kubernetes' |
  'physicalServer' |
  'view' |
  'pure' |
  'ibmFlashSystem'|
  'sql' |
  'oracle' |
  'cassandra' |
  'couchbase' |
  'databases' |
  'O365' |
  'rds' |
  'activeDirectory' |
  'storageVolume' |
  'nas' |
  'mountPoint' |
  'browsableEntities' |
  'dbMigration' |
  's3';

/**
 * The type of recovery job groups.
 */
export type RecoveryJobGroup = 'rds';

/**
 * The data structure for the map of restore type to there respective environments.
 */
export type RecoveryGroupMap = {
  [K in RecoveryGroup]:
    K extends RecoveryJobGroup ? EnvItems[] : Environment[];
};

/**
 * The map of restore type to there respective environments.
 */
export const recoveryGroup: RecoveryGroupMap = {
  vm: [
    Environment.kAcropolis,
    Environment.kAWS,
    Environment.kAzure,
    Environment.kGCP,
    Environment.kHyperV,
    Environment.kHyperVVSS,
    Environment.kKVM,
    Environment.kVMware,
  ],
  vmdk: [
    Environment.kVMware,
  ],
  kubernetes: [
    Environment.kKubernetes,
  ],
  physicalServer: [
    Environment.kPhysical,
  ],
  view: [
    Environment.kView,
  ],
  pure: [
    Environment.kPure,
  ],
  ibmFlashSystem: [
    Environment.kIbmFlashSystem,
  ],
  sql: [
    Environment.kSQL,
    Environment.kVMware,
    // TODO: should add kPhysical here when supported
  ],
  oracle: [
    Environment.kOracle,
  ],
  cassandra: [
    Environment.kCassandra
  ],
  couchbase: [
    Environment.kCouchbase
  ],
  get dbMigration() {
    return this.sql;
  },
  get databases() {
    return this._databases || (this._databases = [...this.sql, ...this.oracle, ...this.cassandra, ...this.couchbase]);
  },
  O365: [
    Environment.kO365,
  ],
  rds: [{
    environment: Environment.kRDSSnapshotManager,
    sourceEnvironment: Environment.kAWS,
  }] as EnvItems[],
  s3: [
    Environment.kAwsS3,
  ],
  activeDirectory: [
    Environment.kAD,
  ],
  storageVolume: [
    ...storageVolumeEnvironments,
  ],
  nas: [
    ...nasEnvironments,
  ],
  mountPoint: [
    ...fileBrowsableEnvironments
  ],
  browsableEntities: [
    ...fileBrowsableEnvironments
  ],
};

/**
 * Aggregated data which is an array of environment group objects. Each object
 * stores the sum of environment data which belongs to the same group.
 * It is usually used for HighchartCharts or as a list for display purpose.
 */
export interface DataByGroup {
  /**
   * ID of object which is also the Environment group name.
   */
  id: string;

  /**
   * Parent ID of the object.
   */
  parent?: string;

  /**
   * Numeric value of the group which is sum of environment data belongs to the same group.
   */
  value: number;

  /**
   * Name is used externally to store the displayed text
   */
  name?: string;
}

// Provides icon type based on envtype.
export const ENUM_ENV_ICON_TYPE_MAPPING = {
  job: {
    1: 'vm',
    2: 'vm',
    3: 'databases',
    4: 'view',
    5: 'remote-adapter',
    6: 'physical-server',
    7: 'storage-volumes',
    8: 'vm',
    9: 'nas',
    11: 'nas',
    12: 'vm',
    13: 'physical-server',
    14: 'nas',
    15: 'vm',
    16: 'vm',
    18: 'vm',
    19: 'databases',
    20: 'vm',
    21: 'nas',
    22: 'vm',
    24: 'applications',
    25: 'applications',
    27: 'vm',
    28: 'vm',
    29: 'applications',
    30: 'vm',
    31: 'nas',
    32: 'databases',
    33: 'databases',
    34: 'applications',
    37: 'nas',
    38: 'databases',
    39: 'databases',
    40: 'databases',
    41: 'databases',
    42: 'databases',
    43: 'databases',
    46: 'databases',
    kAD: 'applications',
    kActiveDirectory: 'applications',
    kAcropolis: 'vm',
    kAzure: 'vm',
    kAzureNative: 'vm',
    kAzureSnapshotManager: 'vm',
    kAWS: 'vm',
    kAWSNative: 'vm',
    kAWSSnapshotManager: 'vm',
    kCassandra: 'databases',
    kCouchbase: 'databases',
    kElastifile: 'nas',
    kFlashBlade: 'nas',
    kGCP: 'vm',
    kGCPNative: 'vm',
    kGenericNas: 'nas',
    kGPFS: 'nas',
    kHBase: 'databases',
    kHdfs: 'databases',
    kHive: 'databases',
    kHyperV: 'vm',
    kHyperVVSS: 'vm',
    kIsilon: 'nas',
    kKubernetes: 'kubernetes',
    kKVM: 'vm',
    kMongoDB: 'databases',
    kNetapp: 'nas',
    kO365: 'applications',
    kO365Outlook: 'applications',
    kOracle: 'databases',
    kPhysical: 'physical-server',
    kPhysicalFiles: 'physical-server',
    kPuppeteer: 'remote-adapter',
    kPure: 'storage-volumes',
    kIbmFlashSystem: 'storage-volumes',
    kRDSSnapshotManager: 'databases',
    kSQL: 'databases',
    kUDA: 'databases',
    kView: 'view',
    kVMware: 'vm',
    kSfdc: 'salesforce'
  },

};

// This ENUM is used for specifying the timespan type in scheduling of reports.
export enum timeSpanType {
  kDays = 1,
  kHours = 2,
}

// ENUM_TIMESPAN_TYPE is used to setup label valus for timespanType.
export const ENUM_TIMESPAN_TYPE = {
  1: 'Days',
  2: 'Hours',
  kDays: 'Days',
  kHours: 'Hours',
};

// This ENUM is for bi-directional conversion between numbers and kValues.
// NOTE: This constant has only one intended use: transitional methods which
// need to handle both number and kValue taskTypes until the APIs are all
// standardized to accept/return vKvalues.
export enum taskTypes {
  kBackup = 1,
  kReplication = 2,
  kArchival = 3
}

export const ENUM_TASK_TYPE = {
  1: 'Backup',
  2: 'Replication',
  3: 'Archival',
  kBackup: 'Backup',
  kReplication: 'Replication',
  kArchival: 'Archival'
};

// FETCH_TYPE is used to render the failed objects report based on the'kAll'
// and 'kLatest'. kAll represents the reports which will show objects that
// failed in all attempts in a day. kLatest represents the reports which will
// only show objects that failed in the latest attempt in a day.
export enum FETCH_TYPE {
  kAll = 1,
  kLatest = 2
}

// ENUM_FETCH_TYPE is used setup a label value for the FETCH_TYPE.
export const ENUM_FETCH_TYPE = {
  1: 'All',
  2: 'Latest',
  kAll: 'All Failures',
  kLatest: 'Latest Failures'
};

// This ENUM is for bi-directional conversion between numbers and kValues.
// NOTE: This constant has only one intended use: transitional methods which
// need to handle both number and kValue envTypes until the APIs are all
// standardized to accept/return vKvalues.
export enum envTypes {
  _kKuiper = '-1',
  kAcropolis = 12,
  kAD = 29,
  kAgent = 10,
  kAuroraSnapshotManager = 44,
  kAWS = 16,
  kAWSNative = 22,
  kAWSSnapshotManager = 30,
  kAzure = 8,
  kAzureNative = 28,
  kAzureSnapshotManager = 36,
  kCassandra = 38,
  kCouchbase = 43,
  kElastifile = 37,
  kExchange = 17,
  kFlashBlade = 21,
  kGCP = 20,
  kGCPNative = 27,
  kGenericNas = 11,
  kGPFS = 31,
  kHBase = 40,
  kHdfs = 42,
  kHive = 41,
  kHpe3Par = 53,
  kHyperFlex = 26,
  kHyperV = 2,
  kHyperVVSS = 18,
  kIsilon = 14,
  kKubernetes = 34,
  kKVM = 15,
  kMongoDB = 39,
  kNetapp = 9,
  kNimble = 35,
  kO365 = 24,
  kO365Exchange = 49,
  kO365Group = 48,
  kO365OneDrive = 50,
  kO365Outlook = 25,
  kO365PublicFolder = 45,
  kO365Sharepoint = 51,
  kO365Teams = 47,
  kOracle = 19,
  kPhysical = 6,
  kPhysicalFiles = 13,
  kPuppeteer = 5,
  kPure = 7,
  kIbmFlashSystem = 52,
  kRDSSnapshotManager = 32,
  kRDSAurora = 33,
  kSQL = 3,
  kUDA = 46,
  kVcloudDirector = 23,
  kView = 4,
  kVMware = 1,
  kMongoDBPhysical = 68,
}

/**
 * JOB_TYPEs almost mirror this ENUM. Some job types support multiple
 * envTypes. For example. SQL Jobs (type 3) support VMware, HyperV, Acropolis,
 * and Physical envTypes.
 *
 * NOTE: When updating this hash, also update the following:
 * ENV_TYPE_CONVERSION, ENV_GROUPS, PUB_TO_PRIVATE_ENV_STRUCTURES
 */
export const ENUM_ENV_TYPE = {
  '-1': 'Cohesity Cluster',
  1: 'VMware',
  2: 'HyperV',
  3: 'MS SQL',
  4: 'View',
  5: 'Remote Adapter',
  6: 'Physical',
  7: 'Pure',
  8: 'Azure',
  9: 'NetApp',
  10: 'Agent',
  11: 'NAS',
  12: 'Acropolis',
  13: 'Physical Files',
  14: 'Isilon',
  15: 'RHV',
  16: 'AWS',
  17: 'MS Exchange',
  18: 'HyperVVSS',
  19: 'Oracle',
  20: 'GCP',
  21: 'Pure Storage FlashBlade',
  22: 'AWS Native',
  23: 'VCloud Director',
  24: 'Office 365',
  25: 'Outlook',
  27: 'GCP Native',
  28: 'Azure Native',
  29: 'Active Directory',
  30: 'AWS Snapshot Manager',
  31: 'GPFS',
  32: 'Amazon RDS',
  34: 'Kubernetes',
  35: 'Nimble',
  36: 'Azure Snapshot Manager',
  37: 'Elastifile',
  38: 'Cassandra',
  39: 'MongoDB',
  40: 'HBase',
  41: 'Hive',
  42: 'HDFS',
  43: 'Couchbase',
  44: 'Aurora',
  45: 'O365 Public Folder',
  46: 'Universal Data Adapter',
  52: 'IBM FlashSystem',
  53: 'HPE Primera / Alletra',
  kAcropolis: 'Acropolis',
  kAD: 'Active Directory',
  kAgent: 'Agent',
  kAuroraSnapshotManager: 'Amazon Aurora',
  kAzure: 'Azure',
  kAzureNative: 'Azure Native',
  kAzureSnapshotManager: 'Azure Snapshot Manager',
  kAWS: 'AWS',
  kAWSNative: 'AWS Native',
  kAWSSnapshotManager: 'AWS SnapshotManager',
  kCassandra: 'Cassandra',
  kCouchbase: 'Couchbase',
  kElastifile: 'Elastifile',
  kExchange: 'MS Exchange',
  kFlashBlade: 'Pure Storage FlashBlade',
  kGCP: 'GCP',
  kGCPNative: 'GCP Native',
  kGenericNas: 'Generic NAS',
  kGPFS: 'GPFS',
  kHBase: 'HBase',
  kHdfs: 'HDFS',
  kHive: 'Hive',
  kHyperV: 'HyperV',
  kHyperVVSS: 'HyperVVSS',
  kIsilon: 'Isilon',
  kKVM: 'RHV',
  kMongoDB: 'MongoDB',
  kNetapp: 'NetApp',
  kO365: 'Office 365',
  kO365Outlook: 'Outlook',
  kOracle: 'Oracle',
  kPhysical: 'Physical',
  kPhysicalFiles: 'Physical Files',
  kPuppeteer: 'Remote Adapter',
  kPure: 'Pure',
  kHyperFlex: 'HyperFlex',
  kRDSSnapshotManager: 'Amazon RDS',
  kStorageSnapshotProvider: 'Storage Snapshot Provider',
  kSQL: 'MS SQL',
  kUDA: 'Universal Data Adapter',
  kO365PublicFolder: 'O365 Public Folder',
  kView: 'View',
  kVMware: 'VMware',
  kVcloudDirector: 'vCloud Director',
  kKubernetes: 'Kubernetes',
  kNimble: 'Nimble',
  kHpe3Par: 'HPE Primera / Alletra',
  kIbmFlashSysmtem: 'IBM FlashSystem',

  // UI only env/type
  _kAwsGov: 'AWS (Gov)',
  _kAzureGov: 'Azure (Gov)',
  _kKuiper: 'Cohesity Cluster',
};

export const ENV_GROUPS = {
  // Primary sources which are browsable via c-source-tree
  // TODO: Can be renamed to 'sources'
  browsable: [1, 2, 4, 6, 7, 9, 11, 12, 13, 14, 15, 18, 21],

  // Sources which has applications
  applicationSources: [
    3, 'kSQL',
    17, 'kExchange',
    19, 'kOracle',
    29, 'kAD',
  ],

  // Sources which supports auto protect feature
  autoProtectSupported: [
    'kAcropolis',
    'kAWS',
    'kAWSNative',
    'kAWSSnapshotManager',
    'kGCPNative',
    'kHyperV',
    'kHyperVVSS',
    'kKubernetes',
    'kKVM',
    'kNetapp',
    'kO365Outlook',
    'kSQL',
    'kVMware',
  ],

  // environments which support azure managed disks while clone/deploy
  azureManagedDisksSupported: [1, 'kVMware'],

  // These environment types should not be displayed in global search filters.
  // For example, HyperVVSS is just a subset of HyperV and is not an actual
  // independent object type.
  notGlobalSearchFilterable: ['kHyperVVSS'],

  // External Targets which support bulk retrieval
  vaultsSupportBulkRetrieval:
    ['kAmazonGlacier', 'kAmazonS3GlacierDeepArchive'],

  // Sources that don't support 'clone' operation
  cloneUnsupported: ['kAcropolis', 'kAWSNative', 'kAzureNative',
    'kGCP', 'kAWS', 'kAzure',
    'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager'],

  // Sources which support cloud deploy
  cloudDeploySources: ['kAzure', 'kAWS', 8, 16],

  // Cloud Migration is supported for these sources while backup
  cloudMigrationSupported: ['kVMware', 'kHyperV', 'kHyperVVSS'],

  // Cloud Sources
  cloudSources: ['kAzure', 'kAWS', 'kGCP', 8, 16, 20],

  // Cloud Edition Sources -> Sources which support
  // Cohesity DataPlatform on Cloud
  cloudEditionSources: ['aws', 'azure', 'gcp'],

  // Holds the job's environment types for cloud backup
  // which doesn't need a physical agent to be installed on VMs.
  cloudJobsWithoutPhysicalAgent: [22, 27, 28, 30, 32, 36,
    'kAWSNative', 'kGCPNative', 'kAzureNative',
    'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager'],

  // These Cloud sources support prtection using native APIs provided by
  // cloud provider.
  cloudSourcesWithNativeSupport: ['kAWS', 'kAzure', 'kGCP', 8, 16, 20],

  // Jobs which do not create any local snapshots
  cloudJobsWithoutLocalSnapshot: [30, 32, 36,
    'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager'],

  // These cloud sources support native as well as physical agent based
  // snapshots
  awsTypes: ['kAWS', 'kAWSNative',
    'kAWSSnapshotManager', 'kRDSSnapshotManager'],
  azureTypes: ['kAzure', 'kAzureNative', 'kAzureSnapshotManager'],

  // AWS DB job types
  awsDBTypes: [
    'kRDSSnapshotManager',
    'kAuroraSnapshotManager',
    'kAwsRDSPostgresBackup',
    'kAwsRDSPostgres',
    'kAwsAuroraPostgres',
    'kAwsDynamoDB',
  ],

  // AWS DB recovery types
  awsDBRecoveryTypes: ['RecoverRDS', 'RecoverAurora', 'RecoverRDSPostgres', 'RecoverAwsDynamoDB'],

  // AWS S3 job types
  awsS3Types: ['kAwsS3'],

  // AWS S3 recvoery types
  awsS3RecoveryTypes: ['RecoverS3Buckets'],

  // Azure DB backup types
  azureSqlBackupTypes: ['kAzureSQL'],

  // Azure AD backup types
  azureAdBackupTypes: ['kAzureEntraID'],

  // Azure AD recovery types
  azureAdRecoveryTypes: ['RecoverAzureEntraID'],

  // Azure DB recovery types
  azureSqlRecoveryTypes: ['RecoverAzureSQL'],

  // cloud native snapshot types
  nativeSnapshotTypes: [22, 27, 28,
    'kAWSNative', 'kGCPNative', 'kAzureNative'],

  // job types which support remote snapshot management
  remoteSnapshotManagementSupported: [30, 36,
    'kAzureSnapshotManager', 'kAWSSnapshotManager'],

  // Job types with tag support which do not have categories
  tagTypesWithoutCategories: ['kGCPNative', 'kAWSNative',
    'kAWSSnapshotManager'],

  // Sources which supports special parameters
  sourceSpecialParamSupported: ['kVMware', 'kPhysical', 'kAD', 'kKubernetes'],

  // environments that use or can use a Cohesity agent/binary.
  usesAgent: ['kPhysical', 'kHyperV', 'kVMware'],

  // Hypervisor types
  hypervisor: [1, 2, 8, 12, 15, 16, 18, 20, 22, 27, 28, 30, 36,
    'kVMware', 'kHyperV', 'kGCP', 'kAzure', 'kHyperVVSS', 'kKVM', 'kAWS',
    'kAcropolis', 'kAWSNative', 'kGCPNative', 'kAzureNative',
    'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager'],

  // Physical types
  physical: [6, 13, 'kPhysical', 'kPhysicalFiles'],

  // Environments which support PIT (Point in Time) API
  pitSupported: ['kOracle', 'kRDSSnapshotManager', 'kSQL', 'kVMware'],

  // hyperv types
  hyperv: [2, 18, 'kHyperV', 'kHyperVVSS'],

  // kubernetes environment type
  kubernetes: [34, 'kKubernetes'],

  // Sources which are NAS variants
  nas: nasEnvironments.reduce((out, env) => out.concat(env, envTypes[env]), []),

  // Sources for NAS Adapters
  nasAdapters: [9, 14, 21, 31, 37, 'kNetapp', 'kIsilon', 'kFlashBlade', 'kGPFS', 'kElastifile'],

  // Sources for NoSQL Adapters
  nosqlAdapters: [38, 39, 43, 'kCassandra', 'kMongoDB', 'kCouchbase'],

  // Sources for NoSQL Adapters
  hadoopAdapters: [40, 41, 42, 'kHBase', 'kHive', 'kHdfs'],

  // TODO(tauseef): Move Office365 constants within app/shared/constants.
  // Office 365 environment types.
  office365: [24, 25, 'kO365', 'kO365Outlook'],

  san: [7, 'kPure', 52, 'kIbmFlashSystem', 53, 'kHpe3Par'],

  // Generic grouping for NAS and SAN types.
  storageVolumes: storageVolumeEnvironments.reduce((out, env) => out.concat(env, envTypes[env]), []),

  // Remote Pre/Post Script Adapters
  remotePrePostScriptAdapters: [7, 9, 14, 21, 31, 37, 'kNetapp', 'kIsilon', 'kFlashBlade',
    'kGPFS', 'kElastifile', 'kPure'],

  // Sources which can host SQL
  sqlHosts: [1, 6, 'kVMware', 'kPhysical'],

  // Sources which can host Oracle
  oracleHosts: [6, 'kPhysical'],

  // Composite SQL source
  sqlSources: [1, 6, 3, 'kVMware', 'kPhysical', 'kSQL'],

  // Oracle Sources
  oracleSources: [6, 19, 'kPhysical', 'kOracle'],

  // Database hosts: includes SQL, Oracle, etc. Does not include the app
  // entities.
  databaseHosts: [1, 6, 'kVMware', 'kPhysical'],

  // Database restore types for Oracle and SQL.
  // 'kCloneAppView' is for expose as a cohesity view.
  databaseRestoreTypes: [4, 18, 'kRecoverApp', 'kCloneAppView'],

  // Database sources
  databaseSources: [3, 19, 'kSQL', 'kOracle'],

  // Sources which can recover as instant volume mount
  instantMount: [1, 6, 8, 16, 22,
    'kVMware', 'kPhysical', 'kAWS', 'kAWSNative', 'kAzure', 'kAzureNative'],

  // these sources have specials cases when being displayed, currently
  // being used in c-source-table
  individuallyListed: ['kPhysical', 'kView', 'kSQL', 'kAD'],

  // Sources from which can backup files directly
  fileBackups: [9, 11, 13, 14, 21, 31, 37],

  // Any Sources which can be browsed.
  // NOTE: Do not use this as a source of truth, as it does not account for
  // FEATURE_FLAGS. Instead use SearchService.getBrowsableEnvironments() to
  // retrieve a copy of this array modified to account for FEATURE_FLAGS
  fileBrowsable: fileBrowsableEnvironments,

  // Should include every ENV_TYPE which can be registered. The only excluded
  // types are: Remote Adapter (5), Azure (8), Agent (10), MS Exchange (17)
  // TODO: Consolidate this with 'browsable' group
  objectTypes: [1, 2, 3, 4, 6, 7, 9, 11, 12, 13, 14, 18, 19, 21],

  // Sources with a wrapping container:
  //   SQL Hosts (3)
  //   Physical Servers (6)
  //   NAS Mount Points (11)
  //   Oracle Servers (19)
  cohesityGroups: [3, 6, 11, 19, 29, 'kSQL', 'kPhysical', 'kGenericNas',
    'kOracle', 'kAD'],

  // Any Sources which can be indexed.
  // NOTE: Do not use this as a source of truth, as it does not account for
  // FEATURE_FLAGS. Instead use JobService.isEnvTypeIndexable(envType) to test
  // a specific envType or JobService.getIndexableEnvTypes() to retrieve a
  // copy of this array modified to account for FEATURE_FLAGS
  indexable: [1, 2, 3, 4, 5, 6, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    24, 25, 27, 29, 31, 37],

  // NOTE: same note as above, but use
  // PubJobServiceFormatter.isEnvironmentIndexable()
  indexableKvals: [
    'kAcropolis',
    'kAWS',
    'kAWSNative',
    'kAzure',
    'kAzureNative',
    'kElastifile',
    'kFlashBlade',
    'kGCP',
    'kGCPNative',
    'kGenericNas',
    'kGPFS',
    'kHyperV',
    'kHyperVVSS',
    'kIsilon',
    'kKVM',
    'kNetapp',
    'kO365',
    'kO365Outlook',
    'kPhysical',
    'kPhysicalFiles',
    'kPuppeteer',
    'kSQL',
    'kView',
    'kVMware',
    'kAD',
    'kExchange',
  ],

  // Indexable entities which do not support Indexing rules of
  // Inclusions or Exclusions of paths within the entity.
  indexableWithoutRules: [24, 35, 29, 'kO365', 'kO365Outlook', 'kAD'],

  // Indexable entities differentiation:

  // indexableEntitiesExposedAsServers specifies the entities which are
  // exposed as volumes/servers and are indexed by Yoda. These can be browsed
  // by mounting the volumes.
  //
  // This group is based on the method -- IsIndexableServer(...) within
  // yoda/util/utils.cc
  //
  // Note:
  //  - In case of kHyperV entities the job type should be kHyperVVSS.
  //  - In case of kPhysical entities the job type should *not* be
  //    kPhysicalFiles.
  //
  // These special conditions are handled within
  // JobServiceFormatter.differentiateIndexableEntity()
  //
  indexableEntitiesExposedAsServers: [
    1,
    6,
    8,
    12,
    15,
    16,
    18,
    20,
    22,
    27,
    28,
    30,
    36,
    'kAcropolis',
    'kAWS',
    'kAWSNative',
    'kAWSSnapshotManager',
    'kAzure',
    // TODO(Tauseef) Investigate if the 'native' values are needed here
    'kAzureNative',
    'kAzureSnapshotManager',
    'kGCP',
    'kGCPNative',
    'kHyperVVSS',
    'kKVM',
    'kPhysical',
    'kVMware',
  ],

  // indexableEntitiesExposedAsViews specifies the entities which are exposed
  // as views and are indexed by Yoda. These can be browsed by the directory
  // structure instead of volumes.
  //
  // This group is based on the method -- IsIndexableView(...) within
  // yoda/util/utils.cc
  //
  // Note:
  //  - In case of kPhysical entities the job type should be kPhysicalFiles.
  //
  // This special condition is handled within
  // JobServiceFormatter.differentiateIndexableEntity()
  indexableEntitiesExposedAsViews: [
    4,
    9,
    11,
    13,
    14,
    21,
    31,
    37,
    'kElastifile',
    'kFlashBlade',
    'kGenericNas',
    'kGPFS',
    'kIsilon',
    'kNetapp',
    'kPhysicalFiles',
    'kView',
  ],

  /**
   * Environment types that support browsing for leaf entities
   */
  entityBrowsable: [1, 2, 4, 6, 7, 8, 9, 11, 12, 14, 16, 18, 20, 21, 31, 37],

  // For these sources we do not support Recover Files to Source. Protected
  // Files may only be downloaded.
  downloadOnly: [
    'viewEntity',
    'kvmEntity',
  ],

  /**
   * View and RA jobs/envs that are SpanFS things. They have no "objects" when
   * backed up.
   */
  spanFS: [4, 5, 'kPuppeteer', 'kView'],

  /* Sources which support tagging of entities for autoprotect, ex. KVMware
   * represent 1. kHyperV represent 2.
   */
  taggable: ['kVMware', 'kHyperV', 'kHyperVVSS', 'kAWSNative',
    'kAWSSnapshotManager', 'kGCPNative', 1, 2, 18, 22, 27, 30],

  // List of Entities for which credentials are non-mandatory in
  // file and folder recovery.
  entitiesWithNonMandatoryCredentials: [
    8,
    16,
    20,
    22,
    27,
    28,
    30,
    'kAzure',
    'kAWS',
    'kGCP',
    'kAWSNative',
    'kGCPNative',
    'kAzureNative',
    'kAWSSnapshotManager',
    'kAzureSnapshotManager',
  ],

  // List of Entities(kHyperV, kAcropolis, kHyperVVS) for which credentials
  // are required on Linux in file and folder recovery.
  entitiesWithNonMandatoryCredentialsOnLinuxHost: [
    2,
    12,
    18,
  ],

  /**
   * Types of oracle clusters.
   */
  oracleClusterTypes: ['kOracleAPCluster', 'kOracleRACCluster'],

  // RPO Adapters
  rpoAdapters: [1, 'kVMware'],

  // Type of enitities for which refresh is shown in source tree.
  sourceRefreshSupported: [
    1,
    2,
    18,
    'kHyperV',
    'kHyperVVSS',
    'kVMware',
  ]
};
export enum EnvironmentSourceSpecialParameters {
  kAD = 'adSpecialParameters',
  kExchange = 'exchangeSpecialParameters',
  kOracle = 'oracleSpecialParameters',
  kPhysical = 'physicalSpecialParameters',
  kSQL = 'sqlSpecialParameters',
  kVMware = 'vmwareSpecialParameters',
  kSfdc = 'sfdcSpecialParameters',
}

// Environment to display name mapping, used in Register Nas Source
export const NasSourceNames = {
  kIsilon: 'isilon',
  kNetapp: 'netapp',
  kGenericNas: 'genericNas',
  kFlashBlade: 'pureFlashBlade',
  kGPFS: 'gpfs',
  kElastifile: 'elastifile'
};

// Environment type to Group name mapping, used in Register Hypervisor source
export const HypervisorEnvGroupNames = {
  kVMware: 'VMware',
  kHyperV: 'HyperV',
  kAcropolis: 'Acropolis',
  kGCP: 'Cloud',
  kAWS: 'Cloud',
  kAzure: 'Cloud',
  kKVM: 'RHV',
};

/**
 * Mapping of policy periodicity.
 */
export const SchedulingPolicyPeriodicity = {
  continuous: 'kContinuous',
  daily: 'kDaily',
  monthly: 'kMonthly',
  continuousRPO: 'kContinuousRPO',
  cdp: 'kCDP',
};

/**
 * Mapping of legacy numeric entity type values for different adapters.
 */
export const NumericEntityTypes = {
  [Environment.kKVM]: {
    cluster: 3,
    network: 6,
    storageDomain: 7,
    vnicProfile: 8,
  },
};


/**
 * Mapping of number to FLR restore method
 */
export enum FlrRestoreMethod {
  kAutoDeploy,
  kUseExistingAgent,
  kUseHypervisorAPIs,
}

/**
 * The maximum allowed length for a VM name on a given Environment.
 * Useful when VMs are renamed while recovery.
 */
export const MaxVmNameLength = {
  kAzure: 63,
  kGCP: 63,
};

// Enum for AWS source types.
export enum SourceTypes {
  KRegion = 'kRegion',
  KKMSKey = 'kKmsKey',
  KVPC = 'kVPC',
  KSubnet = 'kSubnet',
  KNetworkSecurityGroup = 'kNetworkSecurityGroup',
  KAvailabilityZone = 'kAvailabilityZone',
}

export enum TenantStatuses {
  Active = '0',
  Inactive = '1',
  MarkedForDeletion = '2',
  Deleted = '3',
  Unregistered = '4',
  TenantMigrationInProgress = '5',
}
