import { assign } from 'lodash-es';
// Module: Active Directory

;(function(angular) {
  'use strict';

  angular
    .module('C.activeDirectory', ['C.breakAuthMappingController'])
    .config(configFn)
    .controller('leaveAdController', LeaveAdControllerFn);

  function configFn($stateProvider) {
    var viewAccess = 'AD_LDAP_VIEW';
    var modifyAccess = 'AD_LDAP_MODIFY';

    $stateProvider
      .state('access-management.active-directory', {
        title: 'Active Directory Management',
        url: '^/admin/access/active-directory',
        help: 'admin_activedirectory',
        canAccess: viewAccess,
        parentState: 'access-management',
        views: {
          'tab-view': {
            component: 'activeDirectoryList',
          },
        },
      })

      // The add-ad state can also be loaded in a slide modal using
      // ActiveDirectoryService.newAdModal()
      .state('add-ad', {
        title: 'Active Directory Management',
        url: '^/admin/access/active-directory/add',
        help: 'admin_activedirectory',
        canAccess: modifyAccess,
        parentState: 'access-management.active-directory',
        controller: 'addAdController as $ctrl',
        templateUrl: 'app/admin/access-management/active-directory/new.html',
        resolve: {
          // object to resolve $uibModalInstance for state controller use
          $uibModalInstance: function resolveUibModalInstance() {
            return false;
          },
        },
      })
      .state('view-domain', {
        title: 'Active Directory Management',
        url: '^/admin/access/active-directory/{domain}',
        help: 'admin_activedirectory',
        canAccess: viewAccess,
        params: {
          domain: { type: 'string' },
        },
        component: 'activeDirectoryView',
        parentState: 'access-management.active-directory',
        resolve: {
          // Resolve the state params as a binding to work with both modal and
          // page view
          adDomainName: function resolveAdDomainName($transition$) {
            return $transition$.params().domain;
          },
        },
      });
  }

  /**
   * Controller for the Leave (Delete) modal
   */
  function LeaveAdControllerFn($uibModalInstance, _, cMessage, evalAJAX,
    ActiveDirectoryService, activeDirectory) {

    var $ctrl = this;

    assign($ctrl, {
      activeDirectory: activeDirectory,
      forms: {
        adDelete: {},
      },
      leavingDomain: false,

      // Public Methods
      cancel: cancelModal,
      submit: leaveDomain,
    });

    /**
     * Removes machine accounts from the indicated Active Directory.
     *
     * @method     leaveDomain
     * @return     {Object}  Promise of the modal result
     */
    function leaveDomain() {
      if (!$ctrl.forms.adDelete.$valid) {
        return;
      }

      $ctrl.leavingDomain = true;

      return ActiveDirectoryService.leaveDomain($ctrl.activeDirectory)
        .then(
          function leaveDomainSuccess(response) {
            cMessage.success({
              titleKey: 'ad.delete.removedTitle',
              textKey: 'ad.delete.removedText',
              textKeyContext: $ctrl.activeDirectory,
            });
            return $uibModalInstance.close(response);
          },
          function leaveDomainError(response) {
            if (response.errorCode === 'KUnjoinIncomplete') {
              cMessage.warn({
                textKey: response.message,
              });
              return $uibModalInstance.close(response);
            }

            evalAJAX.errorMessage(response);
          })
        .finally(
          function leaveDomainFinally() {
            $ctrl.leavingDomain = false;
          }
        );
    }

    /**
     * Cancels this modal
     *
     * @method     cancelModal
     * @return     {Object}  $uibModalInstance with the modal dismissal result
     */
    function cancelModal() {
      return $uibModalInstance.dismiss(false);
    }
  }

})(angular);
