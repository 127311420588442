import { fill } from 'lodash-es';
import { sumBy } from 'lodash-es';
import { orderBy } from 'lodash-es';
import { identity } from 'lodash-es';
import { merge } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { reduce } from 'lodash-es';
import { forEach } from 'lodash-es';
import { set } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Dashboard Service

;(function(angular, undefined) {

  angular.module('C.dashboardService', [])
    .service('DashboardService', dashboardServiceFn);

  function dashboardServiceFn(_, $q, $http, API, cUtils, $rootScope, ENV_GROUPS,
    RemoteClusterService) {
    var pendingRequestPromise;

    /**
     * contains mapping of protected objects groups and environment type.
     * objects groups creates an logical abstraction over environment types.
     */
    var OBJECT_GROUPS_HASH = {
      kDB: cUtils.onlyStrings(ENV_GROUPS.databaseSources),
      kFile: ['kPhysicalFiles'],
      kServer: ['kPhysical'],
      kVM: cUtils.onlyStrings(ENV_GROUPS.hypervisor),
      kVolume: cUtils.onlyStrings(ENV_GROUPS.nas).concat('kPure'),
      kOther: [],
    };

    return {
      getDashboardData: getDashboardData,
      getEnvironmentGroup: getEnvironmentGroup,
      OBJECT_GROUPS_HASH: OBJECT_GROUPS_HASH,
      smartGroupByProtectedObjects: smartGroupByProtectedObjects,
    };

    /**
     * Gets the environment group.
     *
     * @method   getEnvironmentGroup
     * @param    {String}   env   The environment
     * @return   {String}   The group for the provided environment.
     */
    function getEnvironmentGroup(env) {
      var result = env;

      Object.keys(OBJECT_GROUPS_HASH).some(function eachGroup(groupName) {
        if (angular.isArray(OBJECT_GROUPS_HASH[groupName]) &&
          OBJECT_GROUPS_HASH[groupName].includes(env)) {
          result = groupName;
          return true;
        }
      });

      return result;
    }

    /**
     * Returns the dashboard widgets data for local/remote or all cluster.
     *
     * @method   getDashboardData
     * @return   {Object}   Promise Resolved the dashboard data else rejected
     *                      with error
     */
    function getDashboardData() {
      var getDataPromise = {};

      var clusterInfo = $rootScope.clusterInfo;
      var allClusters = get(clusterInfo, '_allClusters');

      // return the pending request promise if exist to share data b/w multiple
      // request made before 1st request has responded.
      if (pendingRequestPromise) {
        return pendingRequestPromise;
      }

      // get remote clusters only for consolidated dashboard case.
      if (allClusters) {
        getDataPromise.clustersHash =
          RemoteClusterService.getRemoteClusterHash();
      }

      // get dashboard data
      getDataPromise.dashboard = $http({
        method: 'get',
        url: API.public('dashboard'),
        headers: {
          // get remote cluster data from accessed cluster cache.
          clusterId: undefined,
        },

        // read all clusters state from clusterInfo
        params: {
          allClusters: allClusters,
          clusterId: allClusters ? undefined : clusterInfo.id,
        },
      });

      pendingRequestPromise = $q.all(getDataPromise).then(
        function gotData(resp) {
          // Add the local cluster to the clustersHash.
          set(resp.clustersHash, clusterInfo.id, $rootScope.basicClusterInfo);

          return transformDashboardData(
            resp.dashboard.data,
            resp.clustersHash
          );
        }
      ).finally(function finallyGotData() {
        pendingRequestPromise = undefined;
      }).onAbort(function onRequestAbort() {
        // perform cleanups when request is aborted
        pendingRequestPromise = undefined;
      });

      return pendingRequestPromise;
    }

    /**
     * transform dashboard data for quick lookups of dashboard data.
     *
     * @method   transformDashboardData
     * @param    {Object}   data           The dashboard data
     * @param    {Object}   clustersHash   The remote clusters hash
     * @return   {Object}   The transformed dashboard data
     */
    function transformDashboardData(data, clustersHash) {
      var out = {};

      clustersHash = clustersHash || {};

      // removing one level of nesting to have all widgets data into top level
      out = transformWidgetData(data.dashboard || {});


      if (data.dashboards && data.dashboards.length) {
        // transform remote clusters dashboard and resolved cluster id from
        // clustersHash
        out._dashboards = data.dashboards.map(
          function eachDashboard(dashboard) {
            var outDashboard = transformWidgetData(dashboard);

            forEach(outDashboard, function eachWidget(widgetData) {
              assign(widgetData, {
                clusterId: outDashboard.clusterId,
                _clusterInfo: clustersHash[outDashboard.clusterId],
              });
            });
            return outDashboard;
          }
        );

        // collect all remote cluster data into top level widget under
        // _remoteClusterData for quick lookup.
        forEach(out, function eachWidget(widgetData, widgetName) {
          // skip _dashboards key added for quick access of dashboard data.
          if (widgetName === '_dashboards') {
            return;
          }
          widgetData._remoteClusterData = out._dashboards.map(
            function eachRemoteClusterData(remoteClusterData) {
              return remoteClusterData[widgetName];
            }
          ).filter(identity);
        });
      }

      return out;
    }

    /**
     * transform the widget data and add default values
     *
     * @method   transformWidgetData
     * @param    {Object}   data   The dashboard data
     * @return   {Object}   The transformed dashboard data
     */
    function transformWidgetData(data) {
      var out = {};

      if (!data) {
        return {};
      }

      assign(out, data);

      assign(out, {
        iops: transformIopsData(data.iops),
        throughput: transformThroughputData(data.throughput),
        protection: transformProtectionData(data.protection),
        protectedObjects: transformProtectedObjectsData(data.protectedObjects),
        storageEfficiency:
          transformStorageEfficiencyData(data.storageEfficiency),
        jobRuns: transformJobRunsData(data.jobRuns),
        health: transformHealthData(data.health),
        recoveries: transformRecoveriesData(data.recoveries),
      });

      return out;
    }

    /**
     * transform health widget data.
     *
     * @method   transformHealthData
     * @param    {Object}   data   The health widget data
     * @return   {Object}   The transformed health widget data
     */
    function transformHealthData(data) {
      var out = {
        lastDayNumCriticals: 0,
        lastDayNumWarnings: 0,
        numNodes: 0,
        numNodesWithIssues: 0,
        capacityBytes: 0,
        rawUsedBytes: 0,
        clusterCloudUsageBytes: 0,
        _clusterUsedPercentage: 0,
      };

      merge(out, data);

      if (out.capacityBytes !== 0) {
        out._clusterUsedPercentage =
          cUtils.round((out.rawUsedBytes / out.capacityBytes) * 100, 2);
      }

      return out;
    }

    /**
     * transform job runs widget data.
     *
     * @method   transformJobRunsData
     * @param    {Object}   data   The job runs widget data
     * @return   {Object}   The transformed job runs widget data
     */
    function transformJobRunsData(data) {
      var out = {
        lastDayNumJobRuns: 0,
        lastDayNumJobErrors: 0,
        lastDayNumJobSlaViolations: 0,
        numJobRunning: 0,
        objectsProtectedByPolicy: [],
      };

      assign(out, data);

      out.objectsProtectedByPolicy = sortBy(
        out.objectsProtectedByPolicy,
        ['objectsProtected']
      );

      out.objectsProtectedByPolicy.forEach(
        function eachPolicyStats(policyStats) {
          var tempObjectsProtected = policyStats.objectsProtected;

          // TODO(veetesh): ENG-35755 remove this hack by standardizing the
          // backend response across different tiles 6.0.1.
          if (tempObjectsProtected) {
            tempObjectsProtected = tempObjectsProtected.map(
              function eachStats(stats) {
                return assign({
                  protectedCount: stats.numObjects,
                }, stats);
              }
            );
            tempObjectsProtected =
              getGroupedProtectedObjects(tempObjectsProtected);
            tempObjectsProtected = tempObjectsProtected.map(
              function eachStats(stats) {
                return assign({
                  numObjects: stats.protectedCount,
                }, stats);
              }
            );
            policyStats.objectsProtected = tempObjectsProtected;
          }
        }
      );

      return out;
    }

    /**
     * transform recoveries widget data.
     *
     * @method   transformRecoveriesData
     * @param    {Object}   data   The recoveries widget data
     * @return   {Object}   The transformed recoveries widget data
     */
    function transformRecoveriesData(data) {
      var out = assign({
        lastMonthNumRecoveries: 0,
        lastMonthRecoveriesByType: undefined,
        lastMonthRecoverySizeBytes: null,
        recoveryNumRunning: 0,
      }, data);
      var tempRecoveriesByType = out.lastMonthRecoveriesByType;

      // TODO(veetesh): ENG-35755 remove this hack by standardizing the backend
      // response across different tiles in 6.0.1
      if (tempRecoveriesByType) {
        tempRecoveriesByType = tempRecoveriesByType.map(
          function eachStats(stats) {
            return assign({
              envType: stats.objectType,
              protectedCount: stats.objectCount,
            }, stats);
          }
        );
        tempRecoveriesByType = getGroupedProtectedObjects(tempRecoveriesByType);
        tempRecoveriesByType = tempRecoveriesByType.map(
          function eachStats(stats) {
            return assign({
              objectType: stats.envType,
              objectCount: stats.protectedCount,
            }, stats);
          }
        );
        out.lastMonthRecoveriesByType = tempRecoveriesByType;
      }

      return out;
    }

    /**
     * transform protection widget data.
     *
     * @method   transformProtectionData
     * @param    {Object}   data   The protection widget data
     * @return   {Object}   The transformed protection widget data
     */
    function transformProtectionData(data) {
      var out = {
        stats: {
          lastDayBackup: {},
          lastDayReplicationOut: {},
          lastDayArchival: {},
          lastDayReplicationIn: {},
        },
      };

      return  merge(out, { stats: data });
    }

    /**
     * transform protected objects widget data.
     *
     * @method   transformProtectedObjectsData
     * @param    {Object}   data   The protected objects widget data
     * @return   {Object}   The transformed protected objects widget data
     */
    function transformProtectedObjectsData(data) {
      return assign({
        protectedCount: 0,
        unprotectedCount: 0,
        objectsProtected: [],
      }, data);
    }

    /**
     * Return protected objects grouped by there type.
     *
     * @method   getGroupedProtectedObjects
     * @param    {Object}   protectedObjects               The protected objects
     *                                                     list
     * @param    {Boolean}  [mergeWithDefault=undefined]   If True then merge
     *                                                     default protected
     *                                                     objects
     * @return   {Array}    The grouped protected objects.
     */
    function getGroupedProtectedObjects(protectedObjects, mergeWithDefault) {
      var out = {};

      // merge an empty protected objects with count zero list with provided
      // protected objects.
      if (mergeWithDefault) {
        protectedObjects = mergeWithDefaultProtectedObjects(protectedObjects);
      }

      // create a map of protected objects by their group and accumulate the
      // count
      protectedObjects.forEach(function eachObject(object) {
        var envGroup = getEnvironmentGroup(object.envType);

        if (!out[envGroup]) {
          out[envGroup] = 0;
        }

        out[envGroup] += object.protectedCount;
      });

      // create an array from the map.
      out = reduce(out, function(acc, value, key) {
        return acc.concat({
          envType: key,
          protectedCount: value,
        });
      }, []);

      // sort in descending order of count.
      out = orderBy(out, ['protectedCount'], ['desc']);

      return out;
    }

    /**
     * Return merged & ordered protected objects List.
     *
     * @method   mergeWithDefaultProtectedObjects
     * @param    {Array}   [protectedObjects=[]]   The list of protected objects
     * @return   {Array}   Return merged & ordered protected objects List.
     */
    function mergeWithDefaultProtectedObjects(protectedObjects) {
      var protectedObjectsByKey = keyBy(protectedObjects || [], 'envType');

      // ordering precedence used to sort list for better UX.
      var envTypeOrder = [
        'kVMware',
        'kSQL',
        'kPhysical',
        'kView',
        'kAcropolis',
        'kAgent',
        'kAWS',
        'kAWSNative',
        'kAWSSnapshotManager',
        'kAzure',
        'kAzureNative',
        'kAzureSnapshotManager',
        'kElastifile',
        'kExchange',
        'kFlashBlade',
        'kGCP',
        'kGenericNas',
        'kHyperV',
        'kHyperVVSS',
        'kIsilon',
        'kGPFS',
        'kKVM',
        'kNetapp',
        'kOracle',
        'kPhysicalFiles',
        'kPuppeteer',
        'kPure',
        'kNimble',
      ];

      return envTypeOrder.map(function eachEnvType(envType) {
        return assign({
          envType: envType,
          protectedCount: 0,
        }, protectedObjectsByKey[envType]);
      });
    }

    /**
     * Returns the best fit of protected objects list.
     * 1. if all objects count are zero then return only minAllowed objects list
     * 2. if exactly minAllowed objects are protected then return only them.
     * 3. if more than minAllowed objects are protected then group extra objects
     * into kOther and keep the extra objects are child of kOther group.
     *
     * @method   smartGroupByProtectedObjects
     * @param    {Array}     protectedObjects   The protected objects list
     * @param    {number}    minAllowed         The minimum allowed objects
     * @return   {Array}     Return the best fit of protected objects list.
     */
    function smartGroupByProtectedObjects(protectedObjects, minAllowed) {
      // get group objects and have it merged with default list.
      var groups = getGroupedProtectedObjects(protectedObjects, true);
      var moreObjects;

      // return only minAllowed objects as per case 1 & 2 described above.
      if (groups[minAllowed - 1].protectedCount === 0 ||
        groups[minAllowed].protectedCount === 0) {
        return groups.slice(0, minAllowed);
      }

      // return objects grouped into kOther stats & containing extra objects as
      // a children and with accumulated protected count.
      moreObjects = groups.splice(minAllowed - 1, groups.length);
      groups.push({
        envType: 'kOther',
        protectedCount: sumBy(moreObjects, 'protectedCount'),
        children: moreObjects,
      });

      return groups;
    }

    /**
     * transform throughput widget data.
     *
     * @method   transformThroughputData
     * @param    {Object}   data   The throughput widget data
     * @return   {Object}   The transformed throughput widget data
     */
    function transformThroughputData(data) {
      var out = {
        maxReadThroughput: 0,
        maxWriteThroughput: 0,
        _numberOfSample: 24,
      };

      if (!data) {
        return out;
      }

      assign(out, data);

      out._readThroughputSamples = transformDataSample(
        out.readThroughputSamples,
        out._numberOfSample,
        getValueOfSample
      );

      out._writeThroughputSamples = transformDataSample(
        out.writeThroughputSamples,
        out._numberOfSample,
        getValueOfSample
      );

      return out;
    }

    /**
     * transform iops widget data.
     *
     * @method   transformIopsData
     * @param    {Object}   data   The iops widget data
     * @return   {Object}   The transformed iops widget data
     */
    function transformIopsData(data) {
      var out = {
        maxReadIops: 0,
        maxWriteIops: 0,
        _numberOfSample: 24,
      };

      if (!data) {
        return out;
      }

      assign(out, data);

      out._readIopsSamples = transformDataSample(
        out.readIopsSamples,
        out._numberOfSample,
        getValueOfSample
      );

      out._writeIopsSamples = transformDataSample(
        out.writeIopsSamples,
        out._numberOfSample,
        getValueOfSample
      );

      return out;
    }

    /**
     * transform storage efficiency widget data.
     *
     * @method   transformStorageEfficiencyData
     * @param    {Object}   data   The storage efficiency widget data
     * @return   {Object}   The transformed storage efficiency widget data
     */
    function transformStorageEfficiencyData(data) {
      var out = {
        logicalUsedBytes: 0,
        physicalUsedBytes: 0,
        storageReductionRatio: 0,
        dataInBytes: 0,
        dataInDedupedBytes: 0,
        dedupeRatio: 0,
        _numberOfSample: 7,
      };

      if (!data) {
        return out;
      }

      assign(out, data);

      assign(out, {
        dedupeRatio: cUtils.round(out.dedupeRatio, 2),
        storageReductionRatio: cUtils.round(out.storageReductionRatio, 2),

        _logicalUsedBytesSamples: transformDataSample(
          out.logicalUsedBytesSamples,
          out._numberOfSample,
          getValueOfSample
        ),

        _physicalUsedBytesSamples: transformDataSample(
          out.physicalUsedBytesSamples,
          out._numberOfSample,
          getValueOfSample
        ),

        _dataInBytesSamples: transformDataSample(
          out.dataInBytesSamples,
          out._numberOfSample,
          getValueOfSample
        ),

        _dataInDedupedBytesSamples: transformDataSample(
          out.dataInDedupedBytesSamples,
          out._numberOfSample,
          getValueOfSample
        ),

        _dedupeRatioSamples: transformDataSample(
          out.dedupeRatioSamples,
          out._numberOfSample,
          getFloatValueOfSample
        ),

        _storageReductionSamples: transformDataSample(
          out.storageReductionSamples,
          out._numberOfSample,
          getFloatValueOfSample
        ),
      });

      return out;
    }

    /**
     * Transformed data sample and fill up the missing samples or return empty
     * data series.
     *
     * @method   transformDataSample
     * @param    {Array}      data             The data to transform.
     * @param    {Number}     numberOfSample   The number of data samples.
     * @param    {Function}   getValueFn       The get value of the data sample.
     * @return   {Array}   Return the Transformed data samples.
     */
    function transformDataSample(data, numberOfSample, getValueFn) {
      var missingSamples = [];

      if (!data) {
        return getEmptySeriesData(numberOfSample);
      }

      if (numberOfSample > data.length) {
        missingSamples = getEmptySeriesData(numberOfSample - data.length);
      }

      return missingSamples.concat(data.map(getValueFn));
    }

    /**
     * Generates a constant series of data use when back-end doesn't send you
     * and data for example in DB we don't have read throughput stats info
     * because it was zero/null in that case IRIS backend would not send us the
     * data so use this fn to generate the default constant series of data.
     *
     * @param      {Object}  numberOfSample    The number of sample
     * @param      {number}  [fillValue=0]     The series fill value
     * @return     {Array}   The series data filled with 0 or passed fillValue.
     */
    function getEmptySeriesData(numberOfSample, fillValue) {
      return fill(new Array(numberOfSample), fillValue || 0);
    }

    /**
     * Returns value of sample data
     *
     * @method   getValueOfSample
     * @param    {Object}   sample   The data sample
     * @return   {Number}   The value sample data
     */
    function getValueOfSample(sample) {
      return sample.value || 0;
    }

    /**
     * Returns float value of sample data
     *
     * @method   getFloatValueOfSample
     * @param    {Object}   sample   The data sample
     * @return   {Number}   The float value sample data
     */
    function getFloatValueOfSample(sample) {
      return cUtils.round(sample.floatValue || 0, 2);
    }
  }

})(angular);
