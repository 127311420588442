import { assign } from 'lodash-es';
// Component: Antivirus Providers

;(function(angular, undefined) {
  'use strict';

  angular.module('C.antivirus')
    .controller('DeleteAvProviderCtrl', DeleteProviderControllerFn)
    .component('providers', {
      templateUrl: 'app/antivirus/providers/providers.html',
      controller: providersCtrlFn,
    });

  /**
   * @ngdoc component
   * @name C.antivirus:providers
   * @function providersCtrlFn
   *
   * @description
   * Provides component which manages the AV Providers.
   *
   * @example
     <providers></providers>
   */
  function providersCtrlFn(_, evalAJAX, AntivirusService) {
    var $ctrl = this;

    assign($ctrl, {
      // data
      providers: [],
      loadingProviders: true,
      loadingViews: true,
      securedViewsCount: 0,

      // Component lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Initialize and fetch data
     *
     * @method   $onInit
     */
    function $onInit() {
      _fetchSecuredViews();
    }

    /**
     * Fetches the list of AV Provider Groups
     *
     * @method   _fetchProviders
     */
    function _fetchProviders() {
      $ctrl.loadingProviders = true;

      AntivirusService.getAvProviderList().then(
        function fetchAvProvidersSuccess(providers) {
          $ctrl.providers = _addContextMenus(providers);
        },
        evalAJAX.errorMessage
      ).finally(function fetchAvProvidersDone() {
        $ctrl.loadingProviders = false;
      });
    }

    /**
     * Fetches the list of Secured Views
     *
     * @method   _fetchSecuredViews
     */
    function _fetchSecuredViews() {
      $ctrl.loadingViews = true;

      AntivirusService.getSecuredViews().then(
        function getSecuredViewsSuccess(views) {
          $ctrl.securedViewsCount = views.length;
        },
        evalAJAX.errorMessage
      ).finally(function getSecuredViewsDone() {
        $ctrl.loadingViews = false;
        _fetchProviders();
      });
    }

    /**
     * Removes a provider and updates the list of providers.
     *
     * @method    _removeProvider
     * @param     {Object}   provider    Provider group to be deleted.
     */
    function _removeProvider(provider) {
      AntivirusService.confirmDeleteAvProvider(provider).then(_fetchProviders);
    }

    /**
     * Adds context menus to each Provider Group
     *
     * @method   _addContextMenus
     * @param    {array}   providers   List of Provider Groups.
     * @return   {array}    List of Provider Group.
     */
    function _addContextMenus(providers) {
      providers.forEach(function addProviderContextMenu(provider) {
        provider._contextMenus = [
          {
            icon: provider.isEnabled ? 'icn-toggle-off' : 'icn-toggle-on',
            disabled: provider.isEnabled && $ctrl.securedViewsCount,
            disabledTooltipKey: 'antivirus.disable.provider.protectedViews.text',
            translateKey: provider.isEnabled ? 'disable' : 'enable',
            action: function enableDisableProviderAction() {
              if (provider.isEnabled) {
                // If enabled, then simply disable.
                return _toggleProvider(provider);
              }
              // Otherwise check ICAP connections and enable.
              provider._fetchingIcapStatus = true;
              AntivirusService.checkConnectionAndEnableAvProvider(
                provider, _toggleProvider).finally(
                  function fetchedStatusFinally() {
                    provider._fetchingIcapStatus = false;
                  }
                );
            }
          },
        ];

        if (!provider.isInternal) {
          provider._contextMenus.unshift(
            {
              icon: 'icn-edit',
              translateKey: 'edit',
              state: 'edit-av-provider',
              stateParams: { id: provider.id },
            }
          );
          provider._contextMenus.push(
            {
              icon: 'icn-delete',
              translateKey: 'delete',
              disabled: provider.isEnabled,
              disabledTooltipKey: 'antivirus.delete.provider.active.text',
              action: function removeProvider() {
                _removeProvider(provider)
              }
            }
          );
        }
      });

      return providers;
    }

    /**
     * Toggles the state of a provider and updates the list of providers.
     *
     * @method    _toggleProvider
     * @param     {Object}     provider    Provider group to be toggled.
     */
    function _toggleProvider(provider) {
      AntivirusService.setAvProviderState({
        id: provider.id,
        enable: !provider.isEnabled,
      }).then(_fetchProviders, evalAJAX.errorMessage);
    }
  }

  /* @ngInject */
  function DeleteProviderControllerFn($uibModalInstance,
    AntivirusService, evalAJAX, avProvider) {

    var $ctrl = this;

    /**
     * Handles onClick the primary button in the standardModal.
     */
    $ctrl.ok = function okayThen() {
      $ctrl.submitting = true;
      AntivirusService.deleteAvProvider(avProvider.id).then(
        $uibModalInstance.close,
        evalAJAX.errorMessage
      ).finally(function deleteFinally() {
        $ctrl.submitting = false;
      });
    };
  }

})(angular);
