import {
  ArchivalTargetResult,
  BackupRun,
  CloudSpinTargetResult,
  GetProtectionRunProgressBody,
  McmObjectActivity,
  ObjectProgressInfo,
  ObjectProtectionRunSummary,
  ReplicationTargetResult,
} from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';
import { StateParams } from '@uirouter/core';
import { Environment, StatusObject, StatusType } from 'src/app/shared';

import { ArchivalTarget } from './archival-target.models';
import { DataLock, IProtectionRun, RunProgress } from './common.models';
import { ProtectionRunReplication } from './protection-run-replication.models';

/**
 * Returns `StatusObject` based on params provided.
 *
 * @param  status       Object status.
 * @param  objectId     Object ID.
 * @param  runId        Run ID.
 * @param  type         Type of `StatusObject` icon.
 * @param  stateParams  Optional additional state params.
 * @param  name         Name label for `StatusObject`.
 * @param  state        Router state to navigate to on icon click.
 * @param  id           Entity ID like archival target ID, replication target ID, etc.
 */
export function createStat(
  status: StatusType,
  objectId: number,
  runId: string,
  type: string,
  stateParams = {},
  name?: string,
  id?: number
): StatusObject {
  return {
    id,
    name,
    stateParams: {
      objectId,
      runId,
      ...stateParams,
    },
    status,
    type,
  };
}

/**
 * @description
 *
 * Base object to create object run information based on activity or passthrough APIs.
 */
export class BaseObjectRun extends decorateInterface<IProtectionRun>() {
  /**
   * Object ID.
   */
  id: number;

  get status(): StatusType {
    if (this.isDirectArchive) {
      return this.archivalTargets?.[0]?.status || this.archivalStats?.[0]?.status;
    }

    return this.backupStatus?.status;
  }

  /**
   * Indicates if run is in progress.
   */
  get isInProgress(): boolean {
    if (this.isDirectArchive) {
      return this.archivalStats?.[0]?.status === 'Running';
    }

    return this.backupStatus?.status === 'Running';
  }

  /**
   * Returns default router state for this run instance.
   */
  get defaultState(): string {
    if (this.isDirectArchive) {
      return this.archivalStats?.[0]?.state;
    }

    return this.backupStatus?.state;
  }

  /**
   * Returns default router state params for this run instance.
   */
  get defaultStateParams(): StateParams {
    if (this.isDirectArchive) {
      return this.archivalStats?.[0]?.stateParams;
    }

    return this.backupStatus?.stateParams;
  }

  /**
   * Maps Archival Target ArchivalTarget instance StatusObject instance.
   */
  archivalTargetStats = new WeakMap<ArchivalTarget, StatusObject>();

  /**
   * Local backup messages.
   */
  backupWarnings: string[];

  /**
   * Archival info messages.
   */
  archivalMessages: string[];

  /**
   * Display error messages when backup has failed.
   */
  errorMessages: string[];

  /**
   * Previously failed tasks IDs.
   */
  failedTasks: string[];

  /**
   * Run archival targets.
   */
  archivalTargets: ArchivalTarget[];

  /**
   * Run replication targets.
   */
  replicationTargets: ProtectionRunReplication[];

  /**
   * Run vault targets.
   */
  vaultTargets: ProtectionRunReplication[];

  /**
   * Archival run progress info.
   */
  archivalProgress: RunProgress = {};

  /**
   * Local backup run progress info.
   */
  backupProgress: RunProgress = {};

  /**
   * Replication run progress info.
   */
  replicationProgress: RunProgress = {};

  /**
   * Specifies the type of protection environment.
   */
  protectionEnvironmentType?: string;

  constructor() {
    super({});
  }

  /**
   * Updates progress info for this object run.
   *
   * @param  runProgress Protection run progress data.
   */
  updateProgress = (runProgress: GetProtectionRunProgressBody) => {
    const runObject = runProgress.localRun?.objects?.find(({ id }) => id === this.id);
    this.backupProgress.events = runObject?.events;
    this.backupProgress.percentageCompleted = runObject?.percentageCompleted;
    this.backupProgress.totalFileCount = runObject?.stats?.totalFileCount || 0;
    this.backupProgress.backupFileCount = runObject?.stats?.backupFileCount || 0;

    let archivalProgress: ObjectProgressInfo;
    runProgress.archivalRun?.find(archival =>
      (archivalProgress = archival.objects?.find(({ id }) => id === this.id)));
    this.archivalProgress.events = archivalProgress?.events;
    this.archivalProgress.percentageCompleted = archivalProgress?.percentageCompleted;
    this.archivalProgress.totalFileCount = archivalProgress?.stats?.totalFileCount || 0;
    this.archivalProgress.backupFileCount = archivalProgress?.stats?.backupFileCount || 0;
    this.archivalTargets?.forEach(archivalTarget => archivalTarget.updateProgress(runProgress));

    let replicationProgress: ObjectProgressInfo;
    runProgress.replicationRun?.find(
      replication => (replicationProgress = replication.objects?.find(({ id }) => id === this.id))
    );
    this.replicationProgress.events = replicationProgress?.events;
    this.replicationProgress.percentageCompleted = replicationProgress?.percentageCompleted;
    this.replicationProgress.totalFileCount = replicationProgress?.stats?.totalFileCount || 0;
    this.replicationProgress.backupFileCount = replicationProgress?.stats?.backupFileCount || 0;
    this.replicationTargets?.forEach(replicationTarget => replicationTarget.updateProgress(runProgress));
  };
}

/**
 * @description
 *
 * Object run information based on activity API.
 */
export class ObjectRunActivity extends BaseObjectRun {
  constructor(reference: McmObjectActivity) {
    super();

    const {
      archivalRunParams,
      object: { id: objectId, name: objectName },
      backupRunParams,
      clusterId,
      clusterIncarnationId,
      type,
    }: McmObjectActivity = reference;

    const nowUsecs = new Date().getTime() * 1000;

    let protectionGroupName: string;
    let errorMessage: string;

    this.id = objectId;
    this.clusterId = +clusterId;
    this.clusterIncarnationId = +clusterIncarnationId;

    if (type === 'ArchivalRun') {
      protectionGroupName = archivalRunParams?.protectionGroupName;
      errorMessage = archivalRunParams?.errorMessage;

      this.endTimeUsecs = archivalRunParams?.endTimeUsecs;
      this.groupId = archivalRunParams?.protectionGroupId;
      this.isSlaViolated = archivalRunParams?.isSlaViolated;
      this.progressTaskId = archivalRunParams?.progressTaskId;
      this.readBytes = archivalRunParams?.bytesRead;
      this.runId = archivalRunParams?.runId;
      this.startTimeUsecs = archivalRunParams?.startTimeUsecs;
      this.totalBytes = archivalRunParams?.logicalSizeBytes;
      this.isDirectArchive = archivalRunParams?.isCloudArchivalDirect;
      this.protectionEnvironmentType = archivalRunParams?.protectionEnvironmentType;

      this.archivalStats = [
        createStat(
          archivalRunParams?.status,
          objectId,
          this.runId,
          'cloud',
          {},
          archivalRunParams?.archivalTargetName,
          archivalRunParams.archivalTargetId
        ),
      ];
    } else if (type === 'BackupRun') {
      protectionGroupName = backupRunParams?.protectionGroupName;
      errorMessage = backupRunParams?.errorMessage;

      this.endTimeUsecs = backupRunParams?.endTimeUsecs;
      this.groupId = backupRunParams?.protectionGroupId;
      this.isSlaViolated = backupRunParams?.isSlaViolated;
      this.progressTaskId = backupRunParams?.progressTaskId;
      this.readBytes = backupRunParams?.bytesRead;
      this.runId = backupRunParams?.runId;
      this.startTimeUsecs = backupRunParams?.startTimeUsecs;
      this.totalBytes = backupRunParams?.logicalSizeBytes;

      this.backupStatus = createStat(backupRunParams?.status, objectId, this.runId, 'cluster');
    }

    this.name = protectionGroupName ? protectionGroupName : objectName;

    const isRunning = ['Accepted', 'Canceling', 'Running'].includes(status);

    const durationUsecs = Math.max(0, (isRunning ? nowUsecs : this.endTimeUsecs) - this.startTimeUsecs);
    const durationMs = durationUsecs / 1000;
    this.duration = durationMs;

    this.slaStatus = {
      status: this.isSlaViolated ? 'kWarning' : 'kSuccess',
      type: 'sla',
    };

    if (errorMessage) {
      this.errorMessages = [errorMessage];
    }
  }
}

/**
 * @description
 *
 * Transformer class for API response for ObjectProtectionRunSummary.
 * It will parse ObjectProtectionRunSummary to make it more usable in UI components.
 */
export class ProtectedObjectRun extends BaseObjectRun {
  constructor(reference: ObjectProtectionRunSummary, useStates = false, private stateParams: any = {}) {
    super();

    const {
      archivalInfo,
      cloudSpinInfo,
      dataLock,
      environment,
      id,
      isCloudArchivalDirect,
      isLocalSnapshotsDeleted = false,
      isReplicationRun = false,
      isSlaViolated,
      localSnapshotInfo,
      onLegalHold = false,
      originalBackupInfo,
      originClusterIdentifier,
      originClusterIdentifier: { clusterId, clusterIncarnationId, clusterName } = {},
      originProtectionGroupId,
      permissions = [],
      protectionGroupId,
      protectionGroupName,
      replicationInfo,
      runId,
      runType,
    }: ObjectProtectionRunSummary = reference;

    this.clusterId = +clusterId;
    this.clusterIncarnationId = +clusterIncarnationId;
    this.dataLock = dataLock as DataLock;
    this.environment = environment as Environment;
    this.groupId = protectionGroupId;
    this.id = id;
    this.isLocalSnapshotsDeleted = isLocalSnapshotsDeleted;
    this.isReplicationRun = isReplicationRun;
    this.isSlaViolated = isSlaViolated;
    this.name = protectionGroupName && protectionGroupName !== '' ? protectionGroupName : reference.name;
    this.onLegalHold = onLegalHold;
    this.permissions = permissions;
    this.runId = runId;
    this.runType = runType;

    const backupSummary: BackupRun = originalBackupInfo || localSnapshotInfo || { snapshotInfo: {} };

    if (archivalInfo?.archivalTargetResults?.length) {
      this.archivalStats = [];
      this.archivalMessages = [];
      this.archivalTargets = [];
      this.archivalTargets = archivalInfo.archivalTargetResults.map((ar: ArchivalTargetResult) => {
        const type = ar.targetType === 'Tape' ? 'tape' : 'cloud';

        const status = createStat(ar.status, this.id, this.runId, type, this.stateParams, ar.targetName, ar.targetId);
        const archivalTarget = new ArchivalTarget(ar, !!originClusterIdentifier);
        this.archivalTargets.push(archivalTarget);
        this.archivalTargetStats.set(archivalTarget, status);
        this.archivalStats.push(status);

        if (ar.message) {
          this.archivalMessages.push(ar.message);
        }

        return archivalTarget;
      });
    }

    if (isCloudArchivalDirect) {
      this.isDirectArchive = isCloudArchivalDirect;

      if (this.archivalTargets?.length) {
        const archivalTarget = {
          // if API still sends local backup info for CAD, combine it with archival info
          ...backupSummary.snapshotInfo,
          ...this.archivalTargets[0],
        };

        const {
          bytesRead,
          cancelledAppObjectsCount,
          cancelledObjectsCount,
          dataLockConstraints: archivalTargetDataLock,
          durationMs: duration,
          endTimeUsecs,
          failedAppObjectsCount,
          failedObjectsCount,
          isDeleted,
          logicalTransferred,
          progressTaskId,
          queuedTimeUsecs,
          size,
          slaStatus,
          startTimeUsecs,
          successfulAppObjectsCount,
          successfulObjectsCount,
          warnings,
        } = archivalTarget;

        this.dataLock = archivalTargetDataLock?.mode as DataLock;
        this.startTimeUsecs = startTimeUsecs;
        this.endTimeUsecs = endTimeUsecs;
        this.failedAppObjectsCount = failedAppObjectsCount;
        this.failedObjectsCount = failedObjectsCount;
        this.successfulAppObjectsCount = successfulAppObjectsCount;
        this.successfulObjectsCount = successfulObjectsCount;
        this.slaStatus = slaStatus;
        this.startDate = new Date((startTimeUsecs || queuedTimeUsecs) / 1000);
        this.endDate = new Date(endTimeUsecs / 1000);
        this.duration = duration;
        this.totalBytes = size;
        this.readBytes = bytesRead;
        this.writeBytes = logicalTransferred;
        this.progressTaskId = progressTaskId;
        this.backupWarnings = [...(warnings ?? [])];

        // when task failed, treat all messages as errors
        if (this.status === 'Failed') {
          this.errorMessages = [...this.backupWarnings, ...this.archivalMessages];
          this.archivalMessages = [];
          this.backupWarnings = [];
        }

        // for CAD runs override local snapshot expiration info since there are no local snapshots
        this.isLocalSnapshotsDeleted = isDeleted;

        this.totalObjectsCount = successfulObjectsCount + failedObjectsCount + cancelledObjectsCount;
        this.totalAppObjectsCount = successfulAppObjectsCount + failedAppObjectsCount + cancelledAppObjectsCount;
      }
    } else {
      const {
        failedAttempts,
        snapshotInfo: {
          dataLockConstraints: localSnapshotDataLock,
          endTimeUsecs,
          progressTaskId,
          startTimeUsecs,
          stats: { bytesWritten = 0, logicalSizeBytes = 0, bytesRead = 0 } = {},
          status: backupStatus,
          warnings,
          isManuallyDeleted,
          expiryTimeUsecs,
        },
      }: BackupRun = backupSummary;

      const startDate = new Date(startTimeUsecs / 1000);
      const endDate = new Date(endTimeUsecs / 1000);
      const nowUsecs = new Date().getTime() * 1000;

      this.dataLock = localSnapshotDataLock?.mode as DataLock;
      this.duration = Math.max(0, endTimeUsecs - startTimeUsecs) / 1000;
      this.endDate = endDate;
      this.endTimeUsecs = endTimeUsecs;
      this.progressTaskId = progressTaskId;
      this.readBytes = bytesRead;
      this.startDate = startDate;
      this.startTimeUsecs = startTimeUsecs;
      this.totalBytes = logicalSizeBytes;
      this.writeBytes = bytesWritten;
      this.backupWarnings = warnings;

      this.backupStatus = createStat(backupStatus, this.id, this.runId, 'cluster', this.stateParams);
      this.isLocalSnapshotsDeleted =
        isManuallyDeleted ||
        (!['Running', 'Canceled'].includes(this.backupStatus.status) &&
          (expiryTimeUsecs === 0 || expiryTimeUsecs <= nowUsecs));

      if (typeof isSlaViolated === 'boolean') {
        this.slaStatus = createStat(
          isSlaViolated ? 'kWarning' : 'kSuccess',
          this.id,
          this.runId,
          'sla',
          this.stateParams
        );
      }

      if (failedAttempts?.length) {
        // store all failed attempts progress tasks IDs for progress monitor API
        this.failedTasks = failedAttempts.map(failedTask => failedTask.progressTaskId);
      }

      if (isReplicationRun) {
        this.replicationStats = [
          createStat(backupStatus, this.id, this.runId, 'replication', this.stateParams, clusterName),
        ];
      }
    }

    if (originProtectionGroupId) {
      const [originalClusterId, originalClusterIncarnationId, originalJobId] = originProtectionGroupId
        .split(':')
        .map(Number);

      this.originalJobUid = {
        clusterId: originalClusterId,
        clusterIncarnationId: originalClusterIncarnationId,
        id: originalJobId,
      };
    }

    if (!isReplicationRun && replicationInfo?.replicationTargetResults?.length) {
      const cloudReplicationConfig =
        replicationInfo.replicationTargetResults[0].awsTargetConfig ||
        replicationInfo.replicationTargetResults[0].azureTargetConfig;

      this.replicationStats = [];
      this.replicationTargets = [];
      this.vaultStats = [];
      this.vaultTargets = [];

      replicationInfo.replicationTargetResults.forEach((rr: ReplicationTargetResult) => {
        const name = cloudReplicationConfig ? cloudReplicationConfig.name : rr.clusterName;
        if (rr.ownershipContext !== 'FortKnoxOnprem') {
          this.replicationStats.push(
            createStat(rr.status, this.id, this.runId, this.stateParams, 'replication', name, rr.clusterId)
          );
          this.replicationTargets.push(new ProtectionRunReplication(rr, this.environment));
        } else if (rr.ownershipContext === 'FortKnoxOnprem') {
          this.vaultStats.push(
            createStat(rr.status, this.id, this.runId, this.stateParams, 'vault', name, rr.clusterId)
          );
          this.vaultTargets.push(new ProtectionRunReplication(rr, this.environment));
        }
      });
    }

    if (cloudSpinInfo?.cloudSpinTargetResults) {
      this.cloudSpinStats = cloudSpinInfo.cloudSpinTargetResults.map((cs: CloudSpinTargetResult) =>
        createStat(cs.status, this.id, this.runId, 'cloud-spin', this.stateParams, cs.name, cs.id)
      );
    }

    if (useStates) {
      this.useStates();
    }
  }

  /**
   * Sets ui router state on run status icons to be clickable to navigate to run pages.
   */
  private useStates() {
    if (this.backupStatus) {
      this.backupStatus.state = 'object-run.landing.backup';
    }

    if (this.archivalStats) {
      this.archivalStats.forEach(
        stat => (stat.state = this.isDirectArchive ? 'object-run.landing.cloud-archive' : 'object-run.landing.archive')
      );
    }
  }

  /**
   * Set the backup status name.
   *
   * @param  name  Name of backup status.
   */
  setBackupStatusName = (name: string) => {
    if (this.backupStatus) {
      this.backupStatus.name = name;
    }
  };
}
