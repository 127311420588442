import { assign } from 'lodash-es';
// Module: Audit Logs

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.audit', ['C.seeMore'])
    .config(AuditConfigFn)
    .controller('auditController', AuditControllerFn);

  function AuditConfigFn($stateProvider) {

    $stateProvider
      .state('audit', {
        url: '^/monitoring/audit',
        help: 'admin_audit',
        title: 'Audit Logs',
        canAccess: 'CLUSTER_AUDIT',
        templateUrl: 'app/admin/audit/_audit.html',
        controller: 'auditController as $ctrl',
      })
      .state('audit-edit', {
        url: '^/monitoring/audit/edit',
        help: 'admin_audit',
        title: 'Edit Audit Settings',
        canAccess: 'CLUSTER_MODIFY',
        parentState: 'audit',
        templateUrl: 'app/admin/audit/edit.html',
        controller: 'auditEditSettingsController as $ctrl',
      });
  }

  function AuditControllerFn($scope, $state, FEATURE_FLAGS) {

    var $ctrl = this;

    var auditTabs = [
      {
        headingKey: 'cluster',
        route: 'audit.summary',
      },
      {
        headingKey: 'settings',
        route: 'audit.settings',
      },
    ];

    assign($ctrl, {
      auditTabs: auditTabs,

      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {

      // Add the Filesystem tab only if supported.
      if (FEATURE_FLAGS.auditFileSystem) {
        $ctrl.auditTabs.splice(1, 0, {
          headingKey: 'fileSystem',
          route: 'audit.filesystem',
        });
      }

      // Set default tab state.
      if ($state.current.name === 'audit') {
        $state.go('audit.cluster');
      }
    }
  }

})(angular);
