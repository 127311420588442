import { filter } from 'lodash-es';
import { find } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: App instances

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .component('instances', {
      templateUrl: 'app/apps-management/instances/instances.html',
      controller: instancesCtrlFn,
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:instances
   * @function instancesCtrlFn
   *
   * @description
   * Provides component which manages the instances.
   *
   * @example
     <instances></instances>
   */
  function instancesCtrlFn(_, $state, AppsService, ViewService, evalAJAX,
    $translate, $q, cMessage, $log, $rootScope, PollTaskStatus, FEATURE_FLAGS,
    VlanService, NodeService, $window, FORMATS, ngDialogService) {

    var $ctrl = this;

    var pollIntervalSec = 15;
    var isDoneDeferred = $q.defer();

    assign($ctrl, {
      singleAppMode: false,
      app: {},
      appUid: 0,
      appVersion: 0,
      instances: [],
      views: [],
      loadingInstances: false,
      firstFetch: true,
      summary: {},

      // Component lifecycle methods
      $onInit: $onInit,
      $onDestroy: $onDestroy
    });

    /**
     * Initialize and fetch data
     *
     * @method   $onInit
     */
    function $onInit() {
      if($state.params.appUid && $state.params.version) {
        $ctrl.singleAppMode = true;
        $ctrl.appUid = $state.params.appUid;
        $ctrl.appVersion = $state.params.version;

        _getApp();
      }

      // Get interfaces and vlans to get primary interface first and then
      // get vips of that interface.
      $ctrl.loading = true;
      var promises = {
        vlans: VlanService.getVlans(),
      };
      $q.all(promises).then(
        function gotInterfaces(response) {
          var vlanIface;
          var hostName = $window.location.hostname;
          // If hostname is localhost, then app instance ip is used.
          if (hostName !== 'localhost') {
            if (hostName.match(FORMATS.IPv4AndIPv6)) {
              $ctrl.vip = hostName;
              return;
            } else {
              // Iterate through vlans to find matching vlan with hostname.
              vlanIface = find(response.vlans, {'hostname': hostName});
              if (vlanIface && vlanIface.ips && vlanIface.ips.length) {
                $ctrl.vip = vlanIface.ips[0];
                return;
              } else {
                // Unable to get vip from vlan interface with matching hostname,
                // Fetch one of the cluster node ip which is not used to access cluster.
                return _getClusterNodeIp().then(function gotNodeIp(nodeIp) {
                  $ctrl.vip = nodeIp;
                });
              }
            }
          }

        }).finally(function getInterfacesDone() {
        _getAppInstances();
        $ctrl.loading = false;
      });
    }

    /**
     * Get one of the cluster node ip.
     */
    function _getClusterNodeIp() {
      var nodeIp;
      return NodeService.getClusterNodes().then(
        function gotClusterNodes(nodesResponse) {
          nodeIp = nodesResponse.data.length ? nodesResponse.data[0].ip : undefined;
          return nodeIp;
        }
      );
    }

    function _getAppInstances() {
      if(FEATURE_FLAGS.appsManagementPollingEnabled) {
        $log.info('Fetching instances with polling enabled.');
        PollTaskStatus.createPoller({
          interval: pollIntervalSec,
          isDonePromise: isDoneDeferred.promise,
          iteratorFn: _fetchAppInstances,
        });
      } else {
        _fetchAppInstances();
      }
    }

    /**
     * Do cleanup on component destroy
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      isDoneDeferred.resolve();
    }

    /**
     * Cancel page
     *
     * @method   _cancel
     */
    function _cancel() {
      $state.go('apps-management');
    }

    /**
     * Get the app information
     *
     * @method   _getApp
     */
    function _getApp() {
      $ctrl.loading = true;
      AppsService.getApp($ctrl.appUid, $ctrl.appVersion)
        .then(function gotApp(app) {
          if(!app) {
            $log.info('App not found, cancelling showing instances.');
            _cancel();
            return;
          }

          $ctrl.app = app;

        })
        .finally(function getAppDone(){
          $ctrl.loading = false;
        });
    }

    /**
     * Fetches the list of appInstances
     *
     * @method   _fetchAppInstances
     */
    function _fetchAppInstances() {
      $ctrl.loadingInstances = $ctrl.firstFetch;
      return AppsService.fetchAppInstances($ctrl.vip)
        .then(function fetchAppInstancesSuccess(instances) {
          if($ctrl.singleAppMode) {
            instances = filter(
              instances, {appUid: $ctrl.appUid});
          }

          $ctrl.instances = _addContextMenus(instances);
          $ctrl.summary = AppsService.getAppInstancesSummary(instances);

          // Asynchronously fetch Views to show View names.
          // This is not done in parellel since info is only required
          // if row expanded.
          // Also fetch only once and skip if views are already fetched
          // since likelyhood of changing views when on athena page is very low.
          if(!$ctrl.views.length) {
            var params = {
              allUnderHierarchy: false,
              matchPartialNames: false,
              //maxCount: 1000,
            };

            ViewService.getViews(params)
              .then(function getViewsSuccess(views) {
                $ctrl.views = views || [];
                _updateViewInfo();
              });
          } else {
            _updateViewInfo();
          }

        }, evalAJAX.errorMessage)
        .finally(function fetchAppInstancesDone() {
          $ctrl.loadingInstances = false;
          $ctrl.firstFetch = false;
        });
    }

    /**
     * Fetches the list of Views and updates relavant info in instances.
     *
     * @method   _updateViewInfo
     */
    function _updateViewInfo() {
      var viewIndex = _createViewIndex($ctrl.views);

      $ctrl.instances = map(
        $ctrl.instances, function addViewNames(instance) {
          var readViewIds = get(
            instance.settings, 'readViewPrivileges.viewIds', []);
          var writeViewIds = get(
            instance.settings, 'readWriteViewPrivileges.viewIds', []);

          instance.settings._readViewNames =
            _convertViewIdsToNames(readViewIds, viewIndex);
          instance.settings._writeViewNames =
            _convertViewIdsToNames(writeViewIds, viewIndex);

          // Invalidate object refs to force update.
          return clone(instance);
        });
    }

    /**
     * Convert list of viewIds to View names
     *
     * @method    _convertViewIdsToNames
     * @param     {array}    ids         list of View ids
     * @param     {object}   viewIndex   map of viewIds and names
     * @returns   {array}    List of View names. '-' is used if name not found.
     */
    function _convertViewIdsToNames(ids, viewIndex) {
      return ids.reduce(function getName(names, id) {
        var view = viewIndex[id];
        names.push(view? view.name: '-');
        return names;
      }, []);
    }

    /**
     * Creates a index for Views by viewIds
     *
     * @method    _createViewIndex
     * @param     {array}    views   list of Views
     * @returns   {object}   Map of viewIds and Views
     */
    function _createViewIndex(views) {
      return views.reduce(function setIndex(idx, view) {
        idx[view.viewId] = view;
        return idx;
      }, {});
    }

    /**
     * Pause the app instance
     *
     * @method    _pauseAppInstance
     * @param     {object}   appInstance   app instance object
     */
    function _pauseAppInstance(appInstance) {
      ngDialogService
        .showDialog(
          'apps-confirmation-dialog',
          {
            title: $translate.instant('apps.pause.dialog.title', { appName: appInstance.appName }),
            message: $translate.instant('apps.pause.dialog.description'),
            confirmLabel: $translate.instant('confirm'),
          },
          {
            panelClass: 'apps-confirmation-dialog',
            width: '28rem',
          }
        )
        .toPromise()
        .then(function modalResolved(dialogResponse) {
          if (dialogResponse) {
            AppsService.pauseAppInstance(appInstance.appInstanceId).then(function pauseAppInstanceSuccess(resp) {
              cMessage.success({
                titleKey: 'apps.pauseSuccessTitle',
                textKey: 'apps.pauseSuccessDetail',
                textKeyContext: { name: appInstance.appName },
              });

              _fetchAppInstances();
            }, evalAJAX.errorMessage);
          }
        });
    }

    /**
     * Resume the app instance
     *
     * @method    _resumeAppInstance
     * @param     {object}   appInstance   app instance object
     */
    function _resumeAppInstance(appInstance) {
      AppsService.resumeAppInstance(appInstance.appInstanceId)
        .then(function resumeAppInstanceSuccess(resp) {
          cMessage.success({
            titleKey: 'apps.resumeSuccessTitle',
            textKey: 'apps.resumeSuccessDetail',
            textKeyContext: {name: appInstance.appName},
          });

          _fetchAppInstances();

        }, evalAJAX.errorMessage);
    }

    /**
     * Stop the app instance
     *
     * @method    _terminateAppInstance
     * @param     {object}   appInstance   app instance object
     */
    function _terminateAppInstance(appInstance) {
      ngDialogService
        .showDialog(
          'apps-confirmation-dialog',
          {
            title: $translate.instant('apps.stop.dialog.title', { appName: appInstance.appName }),
            message: $translate.instant('apps.stop.dialog.description'),
            confirmLabel: $translate.instant('stop'),
            confirmButtonType: 'warn',
          },
          {
            panelClass: 'apps-confirmation-dialog',
            width: '28rem',
          }
        )
        .toPromise()
        .then(function modalResolved(dialogResponse) {
          if (dialogResponse) {
            // Terminates app instance
            AppsService.terminateAppInstance(appInstance.appInstanceId).then(function terminateAppInstanceSuccess(
              resp
            ) {
              cMessage.success({
                titleKey: 'apps.terminateSuccessTitle',
                textKey: 'apps.terminateSuccessDetail',
                textKeyContext: { name: appInstance.appName },
              });

              _fetchAppInstances();
            },
            evalAJAX.errorMessage);
          }
        });
    }

    /**
     * Add context menu to app instances
     *
     * @method    _addContextMenus
     * @param     {array}   appInstance   list of app instances
     * @return    {array}   list of app instances
     */
    function _addContextMenus(appInstances) {
      var privs = $rootScope.user.privs;

      appInstances.forEach(function (instance) {

        var terminateMenu = {
          icon: 'icn-stop',
          translateKey: 'stop',
          action: function terminateAppInstance() {
            _terminateAppInstance(instance);
          },
        };

        var pauseMenu = {
          icon: 'icn-pause',
          translateKey: 'pause',
          action: function pauseAppInstance() {
            _pauseAppInstance(instance);
          },
        };

        var resumeMenu = {
          icon: 'icn-play',
          translateKey: 'resume',
          action: function resumeAppInstance() {
            _resumeAppInstance(instance);
          },
        };

        var copyAppUrlMenu = {
          clipboardText: instance._appUrl,
          icon: 'icn-copy',
          translateKey: 'copyAppUrl',
          action: function showMessage() {
            cMessage.success({
              textKey: 'copiedToClipboard',
              textKeyContext: { item: instance._appUrl },
            });
          }
        };

        var downloadLogMenu = {
          clipboardText: instance._appUrl,
          icon: 'icn-download',
          translateKey: 'downloadLog',
          action: function downloadLog() {
            AppsService.downloadAppInstanceLog(instance.appInstanceId);
          }
        };

        // Conditional context menus
        instance._contextMenus = [];

        switch(true) {
          case instance.state === 'kRunning' && privs.APP_LAUNCH:
            instance._contextMenus.push(pauseMenu);
            instance._contextMenus.push(terminateMenu);
            if (instance._appUrl) {
              instance._contextMenus.push(copyAppUrlMenu);
            }
            instance._contextMenus.push(downloadLogMenu);
            break;

          case ['kUnschedulable', 'kInitializing', 'kNotHealthy'].includes(instance.state) && privs.APP_LAUNCH:
            instance._contextMenus.push(pauseMenu);
            instance._contextMenus.push(terminateMenu);
            break;

          case instance.state === 'kPaused' && privs.APP_LAUNCH:
            instance._contextMenus.push(resumeMenu);
            instance._contextMenus.push(terminateMenu);
            if (instance._appUrl) {
              instance._contextMenus.push(copyAppUrlMenu);
            }
            instance._contextMenus.push(downloadLogMenu);
            break;
        }
      });

      return appInstances;
    }
  }
})(angular);
