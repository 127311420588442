import { assign } from 'lodash-es';
// Component: Recover Office 365
import { recoveryGroup } from 'src/app/shared/constants';

;(function (angular, undefined) {
  'use strict';

  var moduleName = 'C.office365Recovery';
  var moduleDeps = [
    'C.cart',
    'C.emailSelector',
    'C.office365RecoveryCart',
    'C.office365RecoveryOptions',
    'C.recoveryStore',
    'C.recoveryObjects',
    'C.restoreCommon',
  ];

  angular
    .module(moduleName, moduleDeps)
    .config(ConfigFn)
    .controller('Office365RecoveryParentCtrl', office365RecoveryParentCtrlFn)
    .component('office365Recovery', {
      bindings: {
        // @type   {object}   Specifies the config for the page.
        pageConfig: '<?',
      },
      templateUrl:
        'app/protection/recovery/office365/recover-office365.html',
      controller: 'Office365RecoveryParentCtrl',
    });

  /**
   * Specifies the state names along with their URL mappings.
   *
   * @method   ConfigFn
   * @param   {object}   $stateProvider       Provides interface to declare
   *                                          custom states.
   */
  function ConfigFn($stateProvider) {
    var restorePrivilege = ctx => {
      // Evaluate condition for kO365 based on O365WorkloadType.
      const { office365WorkloadType } = ctx.stateParams;
      if (office365WorkloadType) {
        var envItems = recoveryGroup.O365.reduce((out, env) => (
          out.concat([office365WorkloadType].map(workloadType => (
            { environment: env, entity: workloadType }
          )))), []);
        return ctx.RESTORE_MODIFY  && ctx.canAccessSomeEnvItems(envItems);
      }
      return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.O365);
    };

    $stateProvider
      .state('recover-office365', {
        url: '/protection/recovery/microsoft365',
        title: 'Recover Microsoft 365',
        canAccess: restorePrivilege,
        parentState: 'recover',
        component: 'office365Recovery',
      })
      .state('recover-office365.search', {
        url: '/search',
        title: 'Recover  Microsoft 365',
        canAccess: restorePrivilege,
        parentState: 'recover-office365',
        views: {
          'office365Canvas@recover-office365': {
            component: 'office365RecoverySearch',
          },
        },
        redirectTo: function determineDefaultSearchState() {
          return 'recover-office365.search.mailboxes';
        },
      }).state('recover-office365.search.mailboxes', {
        // Within this state, search of mailbox entities under 'objindex' and
        // granular mail search under 'cfileindex' are supported.
        url: '/mailboxes',
        title: 'Recover Microsoft 365 Object mailboxes',
        help: 'restore_exchange_online',
        canAccess: restorePrivilege,
        parentState: 'recover-office365.search',
        views: {
          'search-canvas': {
            component: 'office365MailboxesSearch',
          },
        },
        params: {
          shouldPreserveCart: false,
        },
      }).state('recover-office365.search.onedrives', {
        // Within this state, search of one_drive entities under 'objindex' and
        // search and browse of one-drive files under 'cfileindex' are
        // supported.
        url: '/onedrives',
        title: 'Recover Microsoft 365 Object onedrives',
        help: 'restore_onedrive_for_business_data',
        canAccess: restorePrivilege,
        parentState: 'recover-office365.search',
        views: {
          'search-canvas': {
            component: 'office365OneDrivesSearch',
          },
        },
        params: {
          // This parameter is required to differentiate between User OneDrive
          // & SharePoint Doc Repository recovery since they both have
          // OneDrives internally.
          isSharePointSearch: false,
          shouldPreserveCart: false,
        },
      }).state('recover-office365.search.sharePoint', {
        // Within this state, search of sharepoint sites entities under
        // 'objindex' and search of sharepoint document repository files under
        // 'cfileindex' are supported.
        url: '/sharepoint',
        title: 'Recover Microsoft 365 SharePoint Sites',
        help: 'restore_sharepoint_online',
        canAccess: restorePrivilege,
        parentState: 'recover-office365.search',
        views: {
          'search-canvas': {
            // Currently the SharePoint doc repository is only needed which is
            // very similar to OneDrive. Hence the same component is utilized.
            component: 'office365OneDrivesSearch',
          },
        },
        params: {
          // This parameter is required to differentiate between User OneDrive
          // & SharePoint Doc Repository recovery since they both have
          // OneDrives internally.
          isSharePointSearch: true,
          shouldPreserveCart: false,
        },
      })
      .state('recover-office365.options', {
        url: '/options',
        title: 'Recover Microsoft 365',
        canAccess: restorePrivilege,
        parentState: 'recover-office365',
        views: {
          'office365Canvas@recover-office365': {
            component: 'office365RecoveryOptions',
          },
        },
      });
  }

  /**
   * @ngdoc component
   * @name C.office365Recovery:office365Recovery
   * @function
   *
   * @description
   * Component for handling the Office 365 recovery.
   */
  function office365RecoveryParentCtrlFn(_, SearchService, RecoveryStore,
    ViewBoxService, SourceService, JobService, evalAJAX, cUtils,
    DateTimeService, ENV_GROUPS, Cart, FEATURE_FLAGS, AdaptorAccessService) {
    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    /**
     * Component initialization hook.
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeSharedData();
    }

    /**
     * Component destructor hook.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      RecoveryStore.clear();
    }

    /**
     * Initializes shared data.
     *
     * @method   _initializeSharedData
     */
    function _initializeSharedData() {
      RecoveryStore.clear();
      assign($ctrl, {
        shared: RecoveryStore.init(_getDefaultSharedData()),
      });
      _fetchDependencies();
    }

    /**
     * Fetches the default shared data.
     *
     * @method   _getDefaultSharedData
     * @return   {object}   default shared data object.
     */
    function _getDefaultSharedData() {
      const searchTabsState = [];
      // Get User Access permissions for O365 adapter.
      const entity = AdaptorAccessService.ctxService.workflowAccessContext
                         .backupAndRecovery.kO365.entity;
      if (entity.kMailbox) {
        searchTabsState.push({
          headingKey: 'office365Restore.searchType.kMailboxSearch',
          route: 'recover-office365.search.mailboxes',
          default: true,
        });
      }

      if (entity.kOneDrive) {
        searchTabsState.push({
          headingKey: 'office365Restore.searchType.kOneDriveSearch',
          route: 'recover-office365.search.onedrives',
        });
      }

      if (entity.kSharePoint) {
        searchTabsState.push({
          headingKey: 'office365Restore.searchType.kSharePointSearch',
          route: 'recover-office365.search.sharePoint',
        });
      }

      return {
        // Specifies the applications supported within Office365 adapter.
        applicationSupport: {
          oneDriveEnabled: FEATURE_FLAGS.office365OneDriveSupportEnabled,
        },

        // NOTE: The init method takes in 2 arguments:
        cart: Cart.newCart(
          /**
           * Specifies the method to generate a key, unique to cart item.
           *
           * @method   generateKey
           * @param    {object}   item   Specifies the item held in cart.
           * @return   {string}   Specifies the key for the item.
           */
          function generateKey(item) {
            return [item._id, item._jobId].join('');
          },

          /**
           * Determines whether provided item can be added in the cart or not.
           *
           * @method   isForbiddenFn
           * @param    {object}   item   Specifies the item held in cart.
           * @return   {Boolean}  Return True if provided item can not be kept
           *                      in the cart else return false.
           */
          function isForbiddenFn(item) {
            // allowing addition of any object in the cart if it is the 1st
            // object after that allow objects from same job hence matching
            // there _jobId with 1st object in the cart.
            if ($ctrl.shared.cart.size === 0) {
              return false;
            }

            return $ctrl.shared.cart.cartList[0]._jobId !== item._jobId;
          }
        ),
        defaultTask: {},
        fetchingVersions: false,

        // Initialize default filters.
        objectFilters: [{
          property: 'registeredSourceIds',
          display: 'sourceName',
          transformFn: _transformSources,
          locked: false,
        }, {
          property: 'viewBoxIds',
          display: 'storageDomain',
          transformFn: _transformStorageDomains,
          locked: false,
        }, {
          property: 'jobIds',
          display: 'protectionJob',
          transformFn: _transformJobs,
          locked: false,
        }, {
          property: 'fromTimeUsecs',
          display: 'startDate',
          transformFn: DateTimeService.dateToUsecs,
          locked: false,
        }, {
          property: 'toTimeUsecs',
          display: 'endDate',
          transformFn: DateTimeService.dateToUsecs,
          locked: false,
        }],
        granularFilters: [{
          property: 'domainIds',
          display: 'sourceName',
          transformFn: _transformSources,
          locked: false,
        }, {
          property: 'protectionJobIds',
          display: 'protectionJob',
          transformFn: _transformJobs,
          locked: false,
          },

          // TODO(tauseef): Add more filters.
        ],
        filterLookups: {
          // Object Maps are maintained along with the list to improve lookups
          // while transforming selected filters to their IDs.
          //
          // Object {<jobName>: <jobId>}
          jobMap: {},

          // Object {<sourceName>: <sourcId>}
          registeredSourceMap: {},

          // Object {<viewBoxName>: <viewBoxId>}
          viewBoxMap: {},
          jobs: [],
          registeredSources: [],
          viewBoxes: [],
        },
        jobsCart: {},
        searchData: [],
        searchEndpoint: SearchService.getPublicSearchURL('office365'),
        searchEnvironment: 'office365',
        searchId: 'office365-search',
        searchItemType: 'kMailboxSearch',
        searchQuery: undefined,
        searchResults: [],
        searchTabs: searchTabsState,
      };
    }

    /**
     * Transforms Storage Domain objects into just IDs.
     *
     * @method   _transformStorageDomains
     * @param    {string[]}   selectedSDNames   Array of storage domain names
     * @return   {number[]}   Array of Storage domain IDs.
     */
    function _transformStorageDomains(selectedSDNames) {
      return selectedSDNames.map(function fetchSDId(selectedSDName) {
        return $ctrl.shared.filterLookups.viewBoxMap[selectedSDName];
      });
    }

    /**
     * Transforms Source objects into just IDs.
     *
     * @method   _transformSources
     * @param    {string[]}   selectedSourceNames   Array of source names.
     * @return   {number[]}   Array of source IDs.
     */
    function _transformSources(selectedSourceNames) {
      return selectedSourceNames.map(function fetchSourceId(sourceName) {
        return $ctrl.shared.filterLookups.registeredSourceMap[sourceName];
      });
    }

    /**
     * Transforms Job objects into just IDs.
     *
     * @method   _transformJobs
     * @param    {string[]}   selectedJobNames   Array of Job names
     * @return   {number[]}   Array of Job IDs.
     */
    function _transformJobs(selectedJobNames) {
      return selectedJobNames.map(function fetchJobId(jobName) {
        return $ctrl.shared.filterLookups.jobMap[jobName];
      });
    }

    /**
     * @method   _fetchDependencies
     *
     * Fetches the list of Jobs, Storage Domains & Sources for Office 365
     * and populates the filetr look ups for c-search.
     */
    function _fetchDependencies() {
      // Fetch Storage domains.
      ViewBoxService.getOwnViewBoxes().then(
        function successfulResponse(viewBoxes) {
          $ctrl.shared.filterLookups.viewBoxes = viewBoxes;
          viewBoxes.forEach(function processViewBox(viewBox) {
            $ctrl.shared.filterLookups.viewBoxMap[viewBox.name] = viewBox.id;
          });
        }, evalAJAX.errorMessage
      );

      // Fetch Office 365 Sources.
      SourceService.getEntitiesOfType({
        environmentTypes: ['kO365'],
        office365EntityTypes: ['kDomain'],
      }).then(
        function successfulResponse(sources) {
          $ctrl.shared.filterLookups.registeredSources = sources;
          sources.forEach(function processSource(source) {
            $ctrl.shared.filterLookups
              .registeredSourceMap[source._typeEntity.name] = source.id;
          });
        }, evalAJAX.errorMessage
      );

      // Fetch Office 365 Jobs.
      JobService.getJobs({envTypes:
        cUtils.onlyNumbers(ENV_GROUPS.office365), pruneSources: true,
          pruneExclusionSources: true}).then(
          function successfulResponse(jobs) {
            // Prepare Map<JobId, JobDetails> using the Cart DS for optimized
            // lookup for fetching the job name for each Outlook item as in
            // case of Emails/Folders, Librarian doesn't return Job name.
            $ctrl.shared.jobsCart = Cart.newCart(
              /**
               * Specifies the method to generate key for items within the Cart
               *
               * @method   keyGeneratorFn
               * @param    {object}    item   Specifies the Job Object to be
               *                              added within the cart.
               * @return   {number}    Specifies the Job ID.
               */
              function keyGeneratorFn(item) {
                return item.jobId;
              },

              /**
               * Specifies the method to forbid the addition of an item in Cart
               *
               * @method   isForbiddenFn
               * @param    {object}    item   Specifies the item to be added to
               *                              the Cart.
               * @return   {boolean}   True, if the item is forbidden from
               *                       addition to the cart.
               */
              function isForbiddenFn(item) {
                // Since Magneto returns all unique job list, hence all can be
                // safely added.
                return false;
              }
            );

            $ctrl.shared.jobsCart.addAll(jobs);
            $ctrl.shared.filterLookups.jobs = jobs;
            jobs.forEach(function processJob(job) {
              $ctrl.shared.filterLookups.jobMap[job.name] = job.jobId;
            });
          }, evalAJAX.errorMessage
        );
    }
  }
})(angular);
