/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { McmActiveAlertsStats } from '../models/mcm-active-alerts-stats';
@Injectable({
  providedIn: 'root',
})
class HeliosStatsService extends __BaseService {
  static readonly GetHeliosAlertsStatsPath = '/mcm/stats/alerts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Compute the stats on active alerts.
   *
   * Compute the stats on active alerts.
   * @param params The `HeliosStatsService.GetHeliosAlertsStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the active alerts stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the active alerts stats are computed.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `excludeStatsByCluster`: Specifies if stats of active alerts per cluster needs to be excluded. If set to false (default value), stats of active alerts per cluster is included in the response. If set to true, only aggregated stats summary will be present in the response.
   *
   * - `clusterIds`: Specifies the list of cluster IDs.
   *
   * - `alertSource`: Specifies a list of alert origination source. If not specified, all alerts from all the sources are considered in the response.
   *
   * @return Success
   */
  GetHeliosAlertsStatsResponse(params: HeliosStatsService.GetHeliosAlertsStatsParams): __Observable<__StrictHttpResponse<McmActiveAlertsStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.excludeStatsByCluster != null) __params = __params.set('excludeStatsByCluster', params.excludeStatsByCluster.toString());
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.alertSource != null) __params = __params.set('alertSource', params.alertSource.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/stats/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmActiveAlertsStats>;
      })
    );
  }
  /**
   * Compute the stats on active alerts.
   *
   * Compute the stats on active alerts.
   * @param params The `HeliosStatsService.GetHeliosAlertsStatsParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time Unix time epoch in microseconds from which the active alerts stats are computed.
   *
   * - `endTimeUsecs`: Specifies the end time Unix time epoch in microseconds to which the active alerts stats are computed.
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `excludeStatsByCluster`: Specifies if stats of active alerts per cluster needs to be excluded. If set to false (default value), stats of active alerts per cluster is included in the response. If set to true, only aggregated stats summary will be present in the response.
   *
   * - `clusterIds`: Specifies the list of cluster IDs.
   *
   * - `alertSource`: Specifies a list of alert origination source. If not specified, all alerts from all the sources are considered in the response.
   *
   * @return Success
   */
  GetHeliosAlertsStats(params: HeliosStatsService.GetHeliosAlertsStatsParams): __Observable<McmActiveAlertsStats> {
    return this.GetHeliosAlertsStatsResponse(params).pipe(
      __map(_r => _r.body as McmActiveAlertsStats)
    );
  }
}

module HeliosStatsService {

  /**
   * Parameters for GetHeliosAlertsStats
   */
  export interface GetHeliosAlertsStatsParams {

    /**
     * Specifies the start time Unix time epoch in microseconds from which the active alerts stats are computed.
     */
    startTimeUsecs: number;

    /**
     * Specifies the end time Unix time epoch in microseconds to which the active alerts stats are computed.
     */
    endTimeUsecs: number;

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * Specifies if stats of active alerts per cluster needs to be excluded. If set to false (default value), stats of active alerts per cluster is included in the response. If set to true, only aggregated stats summary will be present in the response.
     */
    excludeStatsByCluster?: null | boolean;

    /**
     * Specifies the list of cluster IDs.
     */
    clusterIds?: Array<number>;

    /**
     * Specifies a list of alert origination source. If not specified, all alerts from all the sources are considered in the response.
     */
    alertSource?: string;
  }
}

export { HeliosStatsService }
