/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProtectionRunResponse } from '../models/protection-run-response';
import { ProtectionSourceResponse } from '../models/protection-source-response';
@Injectable({
  providedIn: 'root',
})
class SearchService extends __BaseService {
  static readonly SearchProtectionRunsPath = '/public/search/protectionRuns';
  static readonly SearchProtectionSourcesPath = '/public/search/protectionSources';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the information of latest snapshot of a particular object across
   * all snapshot target locations.
   * @param params The `SearchService.SearchProtectionRunsParams` containing the following parameters:
   *
   * - `uuid`: Specifies the unique id of the Protection Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProtectionRunsResponse(params: SearchService.SearchProtectionRunsParams): __Observable<__StrictHttpResponse<ProtectionRunResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uuid != null) __params = __params.set('uuid', params.uuid.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/search/protectionRuns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionRunResponse>;
      })
    );
  }
  /**
   * Returns the information of latest snapshot of a particular object across
   * all snapshot target locations.
   * @param params The `SearchService.SearchProtectionRunsParams` containing the following parameters:
   *
   * - `uuid`: Specifies the unique id of the Protection Source.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProtectionRuns(params: SearchService.SearchProtectionRunsParams): __Observable<ProtectionRunResponse> {
    return this.SearchProtectionRunsResponse(params).pipe(
      __map(_r => _r.body as ProtectionRunResponse)
    );
  }

  /**
   * Performs search on all the objects which are registered to a cluster.
   *
   * Returns list of all the objects along with the protection status information.
   * @param params The `SearchService.SearchProtectionSourcesParams` containing the following parameters:
   *
   * - `titleList`: Specifies the list of titles/desgination applicable to Office365 users.
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests by setting pageCount
   *   and using startIndex to return a subsets of items.
   *
   *   For example, set startIndex to 0 to get the first set of items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of items for a next request.
   *
   * - `searchString`: Specifies the search string to query the name of the Protection Source or
   *   the name of the job protecting a Protection Source.
   *
   * - `registeredSourceUuids`: Specifies the list of Registered Sources Uuids. Only items from the listed
   *   Registered Sources are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionStatus`: Specifies the protection status of the object. If specified, the objects
   *   are filtered based on current protection status of that object on the
   *   cluster. Possible values that can be passed are "protected", "unprotected"
   *   or both. If not specified, all the objects are returned irrespective of
   *   protection status of the object.
   *
   * - `physicalServerHostTypes`: Specifies physical server host OS type. If specified, the physical server
   *   objects will be filtered based on OS type of the server.
   *   'kLinux' indicates the Linux operating system.
   *   'kWindows' indicates the Microsoft Windows operating system.
   *   'kAix' indicates the IBM AIX operating system.
   *   'kSolaris' indicates the Oracle Solaris operating system.
   *   'kSapHana' indicates the Sap Hana database system developed by SAP SE.
   *   'kOther' indicates the other types of operating system.
   *
   * - `pageCount`: Specifies the number of items to return in the response for pagination
   *   purposes. Default the pageCount to MaxSearchResponseSize if this is
   *   unspecified.
   *
   * - `office365ProtectionSourceTypes`: Specifies the Array of Office365 source types.
   *   Specifies the type of Office 365 entity
   *   'kDomain' indicates the O365 domain through which authentication occurs.
   *   'kOutlook' indicates the Exchange online entities.
   *   'kMailbox' indicates the user's mailbox account.
   *   'kUsers' indicates the container for User entities.
   *   'kGroups' indicates the container for Group entities.
   *   'kSites' indicates the container for Site entities.
   *   'kUser' indicates an Office365 User entity.
   *   'kGroup' indicates an Office365 Group entity.
   *   'kSite' indicates an Office365 SharePoint Site entity.
   *
   * - `lastProtectionJobRunStatus`: Specifies the last Protection Job run status of the object. If specified,
   *   objects will be filtered based on last job run status.
   *
   * - `environments`: Specifies the environment type by which Protection Sources will be
   *   filtered.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `departmentList`: Specifies the list of departments to which an Office365 user may belong.
   *
   * - `countryList`: Specifies the list of countries to which Office365 user belongs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProtectionSourcesResponse(params: SearchService.SearchProtectionSourcesParams): __Observable<__StrictHttpResponse<Array<ProtectionSourceResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.titleList || []).forEach(val => {if (val != null) __params = __params.append('titleList', val.toString())});
    if (params.startIndex != null) __params = __params.set('startIndex', params.startIndex.toString());
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    (params.registeredSourceUuids || []).forEach(val => {if (val != null) __params = __params.append('registeredSourceUuids', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectionStatus || []).forEach(val => {if (val != null) __params = __params.append('protectionStatus', val.toString())});
    (params.physicalServerHostTypes || []).forEach(val => {if (val != null) __params = __params.append('physicalServerHostTypes', val.toString())});
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    (params.office365ProtectionSourceTypes || []).forEach(val => {if (val != null) __params = __params.append('office365ProtectionSourceTypes', val.toString())});
    (params.lastProtectionJobRunStatus || []).forEach(val => {if (val != null) __params = __params.append('lastProtectionJobRunStatus', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    (params.departmentList || []).forEach(val => {if (val != null) __params = __params.append('departmentList', val.toString())});
    (params.countryList || []).forEach(val => {if (val != null) __params = __params.append('countryList', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/search/protectionSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionSourceResponse>>;
      })
    );
  }
  /**
   * Performs search on all the objects which are registered to a cluster.
   *
   * Returns list of all the objects along with the protection status information.
   * @param params The `SearchService.SearchProtectionSourcesParams` containing the following parameters:
   *
   * - `titleList`: Specifies the list of titles/desgination applicable to Office365 users.
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests by setting pageCount
   *   and using startIndex to return a subsets of items.
   *
   *   For example, set startIndex to 0 to get the first set of items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of items for a next request.
   *
   * - `searchString`: Specifies the search string to query the name of the Protection Source or
   *   the name of the job protecting a Protection Source.
   *
   * - `registeredSourceUuids`: Specifies the list of Registered Sources Uuids. Only items from the listed
   *   Registered Sources are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionStatus`: Specifies the protection status of the object. If specified, the objects
   *   are filtered based on current protection status of that object on the
   *   cluster. Possible values that can be passed are "protected", "unprotected"
   *   or both. If not specified, all the objects are returned irrespective of
   *   protection status of the object.
   *
   * - `physicalServerHostTypes`: Specifies physical server host OS type. If specified, the physical server
   *   objects will be filtered based on OS type of the server.
   *   'kLinux' indicates the Linux operating system.
   *   'kWindows' indicates the Microsoft Windows operating system.
   *   'kAix' indicates the IBM AIX operating system.
   *   'kSolaris' indicates the Oracle Solaris operating system.
   *   'kSapHana' indicates the Sap Hana database system developed by SAP SE.
   *   'kOther' indicates the other types of operating system.
   *
   * - `pageCount`: Specifies the number of items to return in the response for pagination
   *   purposes. Default the pageCount to MaxSearchResponseSize if this is
   *   unspecified.
   *
   * - `office365ProtectionSourceTypes`: Specifies the Array of Office365 source types.
   *   Specifies the type of Office 365 entity
   *   'kDomain' indicates the O365 domain through which authentication occurs.
   *   'kOutlook' indicates the Exchange online entities.
   *   'kMailbox' indicates the user's mailbox account.
   *   'kUsers' indicates the container for User entities.
   *   'kGroups' indicates the container for Group entities.
   *   'kSites' indicates the container for Site entities.
   *   'kUser' indicates an Office365 User entity.
   *   'kGroup' indicates an Office365 Group entity.
   *   'kSite' indicates an Office365 SharePoint Site entity.
   *
   * - `lastProtectionJobRunStatus`: Specifies the last Protection Job run status of the object. If specified,
   *   objects will be filtered based on last job run status.
   *
   * - `environments`: Specifies the environment type by which Protection Sources will be
   *   filtered.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `departmentList`: Specifies the list of departments to which an Office365 user may belong.
   *
   * - `countryList`: Specifies the list of countries to which Office365 user belongs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProtectionSources(params: SearchService.SearchProtectionSourcesParams): __Observable<Array<ProtectionSourceResponse>> {
    return this.SearchProtectionSourcesResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionSourceResponse>)
    );
  }
}

module SearchService {

  /**
   * Parameters for SearchProtectionRuns
   */
  export interface SearchProtectionRunsParams {

    /**
     * Specifies the unique id of the Protection Source.
     */
    uuid: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchProtectionSources
   */
  export interface SearchProtectionSourcesParams {

    /**
     * Specifies the list of titles/desgination applicable to Office365 users.
     */
    titleList?: Array<string>;

    /**
     * Specifies an index number that can be used to return subsets of items
     * in multiple requests.
     * Break up the items to return into multiple requests by setting pageCount
     * and using startIndex to return a subsets of items.
     *
     * For example, set startIndex to 0 to get the first set of items
     * for the first request. Increment startIndex by pageCount
     * to get the next set of items for a next request.
     */
    startIndex?: number;

    /**
     * Specifies the search string to query the name of the Protection Source or
     * the name of the job protecting a Protection Source.
     */
    searchString?: string;

    /**
     * Specifies the list of Registered Sources Uuids. Only items from the listed
     * Registered Sources are returned.
     */
    registeredSourceUuids?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the protection status of the object. If specified, the objects
     * are filtered based on current protection status of that object on the
     * cluster. Possible values that can be passed are "protected", "unprotected"
     * or both. If not specified, all the objects are returned irrespective of
     * protection status of the object.
     */
    protectionStatus?: Array<string>;

    /**
     * Specifies physical server host OS type. If specified, the physical server
     * objects will be filtered based on OS type of the server.
     * 'kLinux' indicates the Linux operating system.
     * 'kWindows' indicates the Microsoft Windows operating system.
     * 'kAix' indicates the IBM AIX operating system.
     * 'kSolaris' indicates the Oracle Solaris operating system.
     * 'kSapHana' indicates the Sap Hana database system developed by SAP SE.
     * 'kOther' indicates the other types of operating system.
     */
    physicalServerHostTypes?: Array<'kLinux' | 'kWindows' | 'kAix' | 'kSolaris' | 'kSapHana' | 'kHpe3Par' | 'kOther'>;

    /**
     * Specifies the number of items to return in the response for pagination
     * purposes. Default the pageCount to MaxSearchResponseSize if this is
     * unspecified.
     */
    pageCount?: number;

    /**
     * Specifies the Array of Office365 source types.
     * Specifies the type of Office 365 entity
     * 'kDomain' indicates the O365 domain through which authentication occurs.
     * 'kOutlook' indicates the Exchange online entities.
     * 'kMailbox' indicates the user's mailbox account.
     * 'kUsers' indicates the container for User entities.
     * 'kGroups' indicates the container for Group entities.
     * 'kSites' indicates the container for Site entities.
     * 'kUser' indicates an Office365 User entity.
     * 'kGroup' indicates an Office365 Group entity.
     * 'kSite' indicates an Office365 SharePoint Site entity.
     */
    office365ProtectionSourceTypes?: Array<'kDomain' | 'kOutlook' | 'kMailbox' | 'kUsers' | 'kGroups' | 'kSites' | 'kUser' | 'kGroup' | 'kSite' | 'kPublicFolder' | 'kTeam'>;

    /**
     * Specifies the last Protection Job run status of the object. If specified,
     * objects will be filtered based on last job run status.
     */
    lastProtectionJobRunStatus?: Array<number>;

    /**
     * Specifies the environment type by which Protection Sources will be
     * filtered.
     * Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     * 'kVMware' indicates the VMware Protection Source environment.
     * 'kHyperV' indicates the HyperV Protection Source environment.
     * 'kSQL' indicates the SQL Protection Source environment.
     * 'kView' indicates the View Protection Source environment.
     * 'kPuppeteer' indicates the Cohesity's Remote Adapter.
     * 'kPhysical' indicates the physical Protection Source environment.
     * 'kPure' indicates the Pure Storage Protection Source environment.
     * 'Nimble' indicates the Nimble Storage Protection Source environment.
     * 'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
     * 'kAzure' indicates the Microsoft's Azure Protection Source environment.
     * 'kNetapp' indicates the Netapp Protection Source environment.
     * 'kAgent' indicates the Agent Protection Source environment.
     * 'kGenericNas' indicates the Generic Network Attached Storage Protection
     * Source environment.
     * 'kAcropolis' indicates the Acropolis Protection Source environment.
     * 'kPhsicalFiles' indicates the Physical Files Protection Source environment.
     * 'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
     * 'kGPFS' indicates IBM's GPFS Protection Source environment.
     * 'kKVM' indicates the KVM Protection Source environment.
     * 'kAWS' indicates the AWS Protection Source environment.
     * 'kExchange' indicates the Exchange Protection Source environment.
     * 'kHyperVVSS' indicates the HyperV VSS Protection Source
     * environment.
     * 'kOracle' indicates the Oracle Protection Source environment.
     * 'kGCP' indicates the Google Cloud Platform Protection Source environment.
     * 'kFlashBlade' indicates the Flash Blade Protection Source environment.
     * 'kAWSNative' indicates the AWS Native Protection Source environment.
     * 'kO365' indicates the Office 365 Protection Source environment.
     * 'kO365Outlook' indicates Office 365 outlook Protection Source environment.
     * 'kHyperFlex' indicates the Hyper Flex Protection Source environment.
     * 'kGCPNative' indicates the GCP Native Protection Source environment.
     * 'kAzureNative' indicates the Azure Native Protection Source environment.
     * 'kKubernetes' indicates a Kubernetes Protection Source environment.
     * 'kElastifile' indicates Elastifile Protection Source environment.
     * 'kAD' indicates Active Directory Protection Source environment.
     * 'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
     * 'kCassandra' indicates Cassandra Protection Source environment.
     * 'kMongoDB' indicates MongoDB Protection Source environment.
     * 'kCouchbase' indicates Couchbase Protection Source environment.
     * 'kHdfs' indicates Hdfs Protection Source environment.
     * 'kHive' indicates Hive Protection Source environment.
     * 'kHBase' indicates HBase Protection Source environment.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kSAPHANA' | 'kMongoDBPhysical'>;

    /**
     * Specifies the list of departments to which an Office365 user may belong.
     */
    departmentList?: Array<string>;

    /**
     * Specifies the list of countries to which Office365 user belongs.
     */
    countryList?: Array<string>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { SearchService }
