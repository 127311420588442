import { FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NavItem, SnackBarService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { isPlainObject } from 'lodash-es';
import moment from 'moment';

/**
 * Class to download a csv file generated from an array of objects.
 */
@Injectable({
  providedIn: 'root'
})
export class ExportToCsvService {
  /**
   * Cache of items which have already been flattened.
   */
  itemCache = new WeakMap<any, any>();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private snackBarService: SnackBarService,
    private translateService: TranslateService) {
  }

  /**
   * Function to accept an item, and flatten it if it is a plain object.
   *
   * @param item The variable for item passed.
   * @return The flattened item.
   */
  flattenItem(item: any): any {
    if (this.itemCache.has(item)) {
      return this.itemCache.get(item);
    }

    if (!isPlainObject(item)) {
      return item;
    }

    // Variable to store the flattened object.
    const flattenedItem = {};

    for (const [key, value] of Object.entries(item)) {
      if (isPlainObject(value)) {
        // Flatten the item recursively if a plain object.
        for (const [innerKey, innerValue] of Object.entries(this.flattenItem(value))) {
          flattenedItem[`${key}.${innerKey}`] = innerValue;
        }
      } else {
        flattenedItem[key] = value;
      }
    }

    // Set the item in cache.
    this.itemCache.set(item, flattenedItem);

    return flattenedItem;
  }

  /**
   * Function to take a value and stringify it to be used as a cell value in
   * the csv file.
   *
   * @param value The value to be stringified.
   * @return The stringified value.
   */
  stringifyValue(value: any): string {
    // This line generates a safe string for a csv cell based on following -
    // 1) JSON.stringify wraps the result in double quotes for primitive values.
    //    So to avoid two double quotes, remove the quotes in beginning and
    //    ending of the string.
    // 2) If there are double quotes inside the string, according to CSV spec,
    //    that needs to be replaces with two double quotes.
    // 3) Wrap the entire value in double quotes, this way things like commas
    //    inside do not need to be escaped.
    return `"${JSON.stringify(value ?? '').replace(/^"(.*)"$/, '$1').replace(/"/g, '""')}"`;
  }

  /**
   * Function to generate a string for csv file for an array of objects.
   * The objects passed here should be plain objects such as ones returned
   * from API responses.
   *
   * @param objects The array of objects which need to be converted to csv
   *                format.
   * @return The csv string for the array of objects.
   */
  getCsvString(objects: any[]): string {
    // Variable to store all headers for the csv file.
    const headers = new Set<string>();

    // Variable to store all rows for the csv file.
    const rows = [];

    // First loop through all the objects to determine all the headers
    // needed for the csv file.
    for (const object of objects) {
      for (const [key] of Object.entries(this.flattenItem(object))) {
        headers.add(key);
      }
    }

    // Sort the headers alphabetically.
    const sortedHeaders = Array.from(headers).sort();

    // Push the header row for the csv file first.
    rows.push(sortedHeaders.map(header => this.stringifyValue(header)).join());

    // Loop through all the objects again to build the rows for the csv file.
    for (const object of objects) {
      const rowParts = [];

      // Build row parts, that is each cell, for a csv file row.
      for (const header of sortedHeaders) {
        rowParts.push(this.stringifyValue(this.flattenItem(object)[header]));
      }

      // Finish the row by joining the parts.
      rows.push(rowParts.join());
    }

    // Join all rows on the new line character which is the separator for
    // csv rows in a string.
    return rows.join('\n');
  }

  /**
   * Function to download a list objects as a csv file.
   *
   * @param objects The array of objects to download as csv.
   * @param filename The filename to use for download.
   */
  downloadCsv(objects: any[], filename = 'download') {
    if (!objects?.length) {
      this.snackBarService.open(this.translateService.instant('noDataAvailable'), 'error');
      return ;
    }

    // Generate the csv string and create the object url for csv download.
    const csvString = this.getCsvString(objects);
    const anchorElement = document.createElement('a');
    const blob = new Blob([csvString], {type: 'text/csv'});
    const objectUrl = window.URL.createObjectURL(blob);
    const dateFormat = this.dateTimeFormat();

    // Create the anchor element for download using the object url.
    anchorElement.href = objectUrl;
    anchorElement.download = `${filename}_${moment().format(dateFormat)}.csv`;
    anchorElement.click();
    anchorElement.remove();

    // Release the object url to remove reference to the file and free memory.
    window.URL.revokeObjectURL(objectUrl);
  }

  /**
   * Function to get a nav item to download an array of objects as a csv file.
   *
   * @param objects The array of objects to download as csv.
   * @param filename The filename to use for download.
   * @param displayNameKey The key for display name of the nav item.
   * @return The nav item to download the csv file.
   */
  getNavItem(objects: any[], filename = 'download', displayNameKey = 'exportToCsv'): NavItem {
    return {
      displayName: this.translateService.instant(displayNameKey),
      icon: 'file_download',
      action: () => {
        this.downloadCsv(objects, filename);
      },
    };
  }

  /**
   * A util function which work similar to getLocaleDateTimeFormat
   *
   * @returns formated time string
   */
  private dateTimeFormat(): string {
    const dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Short).toUpperCase();
    const timeFormat = getLocaleTimeFormat(this.locale, FormatWidth.Short);
    return dateFormat + '/'+ timeFormat;
  }
}
