import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: tenants storage consumption by physical usage report

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    transclude: true,
    controller: 'TenantsPhysicalUsageCtrlFn',
    templateUrl: 'app/reports/storage/tenants/tenants-physical-usage.html',
  };

  angular
    .module('C.reports')
    .controller('TenantsPhysicalUsageCtrlFn', tenantsPhysicalUsageCtrlFn)
    .component('tenantsPhysicalUsage', configOptions);

  function tenantsPhysicalUsageCtrlFn(_, $filter, evalAJAX, cUtils, $translate,
    CHART_COLORS, cReportsControlsUtil, ReportsUtil, ReportsService) {
    var $ctrl = this;

    // declare component methods
    assign($ctrl, {
      lastNumDays: 7,
      dataset: {},
      storageTenantsConfig: ReportsUtil.getReportConfig(
        'kStorageConsumedByTenantPerViewBoxReport')(),
      downloadReport: downloadReport,
      getData: getData,
      tenantStorageInfo: [],
      chartOne: {
        chartType: 'storageDonut',
        loading: true,
        colors: CHART_COLORS.tenColorPalette(),
        series: [{
          type: 'pie',
          name: 'tenants',
          data: []
        }],
        chart: {
          height: 225
        },
        title: {
          text: null
        },
        legend: {
          y: 0,
          itemMarginBottom: 8
        }
      },
      chartTwo: {
        chartType: 'donut',
        loading: true,
        colors: CHART_COLORS.tenColorPalette(),
        series: [{
          type: 'pie',
          name: 'tenants',
          data: []
        }],
        title: {
          text: null
        },
        legend: {
          y: 0,
          itemMarginBottom: 8,
          labelFormatter: function labelFormatterFn() {
            this.name = $translate.instant(this.name);
            var labelName = this.name;
            if (labelName.length > 20) {
              labelName = [labelName.slice(0, 18), '...'].join('');
            }
            return [
              '<div style="position: relative; top: -1px;" title="',
              this.name,
              '">',
              labelName,
              ' <span class="legend-count">',
              $translate.instant('nTimesX', {n: this.y}),
              '</span></div>',
            ].join('');
          },
        }
      },

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * initialize the component & fetch the data.
     *
     * @method   $onInit
     */
    function $onInit() {
      var defaultFilters = cReportsControlsUtil.getDefaultFilters({
        reportType: $ctrl.storageTenantsConfig.reportType,
      });

      getData(defaultFilters);
    }

    /**
     * builds a params object based on current filter settings
     *
     * @param  {Object} filter   The filter for the API request.
     * @return {Object} params object for API submission
     */
    function getParams(filters) {
      filters = filters || {};

      var params = {
        maxCount: 1000,
        skipGroupByTenant: true,
        msecsBeforeCurrentTimeToCompare: $ctrl.lastNumDays * 86400000,
        viewBoxesIdList: (filters.viewBoxes || []).map(
          function eachViewbox(viewbox) {
            return viewbox.value;
          }
        ),
      };

      return ReportsService.extendWithTenantParams(params);
    }

    /**
     * gets and parses the report data from the API
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Void}
     */
    function getData(filters) {
      $ctrl.dataReady = false;
      $ctrl.chartOne.loading = true;
      $ctrl.chartTwo.loading = true;

      ReportsService.getStorageByTenantsAdvancedStats(getParams(filters)).then(
        function onSuccess(result) {
          var storageStatsList = result.statsList || [];
          var groupByTenant = result._groupByTenant || [];

          $ctrl.storageStatsList = transformStorageStatsList(storageStatsList);
          $ctrl.groupByTenant = transformStorageStatsList(groupByTenant);

          parseDataForCharts($ctrl.groupByTenant);
        },
        evalAJAX.errorMessage
      ).finally(
        function finallyGotData() {
          $ctrl.chartOne.loading = false;
          $ctrl.chartTwo.loading = false;
          $ctrl.dataReady = true;
        }
      );
    }

    /**
     * requests a csv download via ReportsService
     *
     * @return {Void}
     */
    function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);

      params.outputFormat = 'csv';
      ReportsService.getStorageByTenantsAdvancedStats(params);
    }

    function transformStorageStatsList(statsList) {
      return statsList.reduce(function (acc, storageInfo) {
        var stats = storageInfo.stats = storageInfo.stats || {};

        assign(stats, {
          _deduplicationRatio: cUtils.getRatio(
            stats.dataInBytes, stats.dataInBytesAfterDedup),
          _compressionRatio: cUtils.getRatio(
            stats.dataInBytesAfterDedup, stats.dataWrittenBytes),
          _storageReductionRatio: cUtils.getRatio(
            stats.outdatedLogicalUsageBytes, stats.storageConsumedBytes),
        });
        if (stats.storageConsumedBytesPrev) {
          var storageGrowthRate = ((stats.storageConsumedBytes || 0) - stats.storageConsumedBytesPrev)
            * 100 / stats.storageConsumedBytesPrev;
          stats.dailyStorageGrowthRate = cUtils.round(storageGrowthRate / $ctrl.lastNumDays, 2) + '%';
          stats.storageGrowthRate = cUtils.round(storageGrowthRate, 2) + '%';
        }
        var viewboxStatsList = (storageInfo.groupList || []).map(
          function eachViewboxStats(viewboxStats) {
            return assign({
              _viewboxStats: viewboxStats,
            }, storageInfo);
          }
        );

        return acc.concat(viewboxStatsList);
      }, []);
    }

    /**
     * generates chart data based for provided tenants storage info
     *
     * @param  {Array} storageStatsList   The tenant Storage info
     */
     function parseDataForCharts(storageStatsList) {
      var maxChartObjects = 10;
      var chartOneDataList = [];
      var chartTwoDataList = [];

      if (!storageStatsList.length) {
        return;
      }

      $ctrl.dataReady = false;
      $ctrl.chartOne.series[0].data = [];
      $ctrl.chartTwo.series[0].data = [];

      storageStatsList.forEach(function eachInfo(storage) {
        chartOneDataList.push({
          name: $filter('naFilter')(get(storage, '_tenant.name')),
          value: storage.stats.storageConsumedBytes,
        });

        chartTwoDataList.push({
          name: $filter('naFilter')(get(storage, '_tenant.name')),
          value: typeof storage.stats._storageReductionRatio === 'number' ?
            storage.stats._storageReductionRatio : 0,
        });
      });

      // sort data arrays in descending order and limit to 10
      chartOneDataList = $filter('orderBy')(chartOneDataList, '-value');
      chartTwoDataList = $filter('orderBy')(chartTwoDataList, '-value');
      chartOneDataList = $filter('limitTo')(chartOneDataList, maxChartObjects);
      chartTwoDataList = $filter('limitTo')(chartTwoDataList, maxChartObjects);

      // transform our data for use with highcharts
      chartOneDataList = chartOneDataList.map(function(tenantStorage) {
        return [tenantStorage.name, tenantStorage.value];
      });
      chartTwoDataList = chartTwoDataList.map(function(tenantStorage) {
        return [tenantStorage.name, tenantStorage.value];
      });

      // Add data to the charts
      $ctrl.chartOne.series[0].data = chartOneDataList;
      $ctrl.chartTwo.series[0].data = chartTwoDataList;
    }
  }
})(angular);
