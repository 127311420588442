import { isFunction } from 'lodash-es';
import { isEqual } from 'lodash-es';
import { find } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Select Tenant

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectTenant';
  var configOptions = {
    controller: 'SelectTenantCtrl',
    templateUrl: 'app/global/select-tenant/select-tenant.html',

    // Attribute bindings
    bindings: {
      /**
       * Optional. If provided then the provided function will the used to get
       * the list of tenants instead if using the default get tenants method.
       *
       * @type   {Function}
       */
      getDateFn: '=?',

      /**
       * Optional. If provided then ngModel will be set with the value of the
       * property looked up inside tenant info for example
       * if 'tenantId' is provided then ngModel will contain tenantId of
       * selected tenant.
       *
       * @type   {String}
       */
      resultAs: '@?',

      /**
       * Optional. If provided then will be used to determine if a tenant can be
       * selected or not else by default all tenants are selectable.
       *
       * @type   {Function}
       */
      isChoiceDisabled: '&?',

      /**
       * Optional. If provided then will be called with fetched tenantInfo
       *
       * @type   {Function}
       */
      onTenantFetch: '&?',

      /**
       * Optional. If provided then will be called when add new is clicked
       *
       * @type   {Function}
       */
      onAddNew: '&?',

      /**
       * Optional attribute sniffed value, if attribute is present then allow
       * multiple tenants selection.
       */
      // multiple: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       * select all tenants options and would work only with multiple selection
       * mode.
       */
      // showSelectAllOption: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       * tenant's meta info.
       */
      // showMetaInfo: '?'

      /**
       * Optional attribute sniffed value, if attribute is present the uiSelect
       * component will open automatically on initialization if the model is
       * empty.
       */
      // autoOpenIfEmpty: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       *  add new tenant option.
       */
      // addNewEnabled: '?'

      /**
       * Optional attribute sniffed value, if present then include the deleted
       * tenants.
       */
      // includeDeletedTenants: '?',
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  };

  angular.module('C.selectTenant', ['ui.select'])
    .controller('SelectTenantCtrl', SelectTenantCtrlFn)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectTenant:selectTenant
   * @scope
   * @link
   *
   * @requires ngModel
   * @function
   * @description
   *   Provides a dropdown list of tenants and allow selection of one or many
   *   based on provided configuration.
   *
   * @example
      <select-tenant
        name="tenant"
        required
        ng-model="$ctrl.selectedTenant"></select-tenant>

      <select-tenant
        name="tenant"
        result-as="tenantId"
        ng-model="$ctrl.selectedTenantId"></select-tenant>
   */
  function SelectTenantCtrlFn(_, $attrs, $state, evalAJAX, TenantService,
    NgTenantService, $q) {

    var $ctrl = this;

    assign($ctrl, {
      // binding $attrs for disabled state pass through
      $attrs: $attrs,

      // component properties
      tenantsList: [],
      selectedTenant: undefined,
      selectedTenants: [],

      // component methods
      selectAll: selectAll,
      updateExternalModel: updateExternalModel,

      // lifecycle hooks
      $onInit: $onInit,
    });

    /**
     * Initialize this component.
     *
     * @method     $onInit
     */
    function $onInit() {
      assign($ctrl, {
        id: [($attrs.id || componentName), 'ui-select'].join('-'),
        showMetaInfo: $attrs.hasOwnProperty('showMetaInfo'),
        showSelectAllOption: $attrs.hasOwnProperty('showSelectAllOption'),
        multiple: $attrs.hasOwnProperty('multiple'),
        addNewTenant:
          $attrs.hasOwnProperty('addNewEnabled') ? addNewTenant : undefined,
        includeDeletedTenants: $attrs.hasOwnProperty('includeDeletedTenants'),
      });

      // fetch list of tenants
      getTenants();
    }

    /**
     * Fetches list of tenants.
     *
     * @method     getTenants
     */
    function getTenants() {
      // If the attribute is passed, then include the deleted tenants along with
      // the other Active/Inactive tenants.
      var params = $ctrl.includeDeletedTenants && {
        status: ['Active', 'Deleted', 'Deactivated'],
      };

      $ctrl.loading = true;

      (isFunction($ctrl.getDateFn) ? $ctrl.getDateFn() :
        TenantService.getTenants(null, params))
        .then(function gotTenants(tenants) {
          $ctrl.tenantsList = tenants;
          $ctrl.onTenantFetch && $ctrl.onTenantFetch({ tenants });

          // setup external model update hook when select-tenants is ready.
          // update internal model on external model changes.
          $ctrl.ngModel.$render = updateInternalModel;

          // manually update the internal model to initialize them.
          updateInternalModel();
        }, evalAJAX.errorMessage).finally(function finallyGetTenants() {
          $ctrl.loading = false;
        });
    }

    /**
     * Update internal model with external model changes.
     *
     * @method   updateInternalModel
     */
    function updateInternalModel() {
      $ctrl.selectedTenants = [];
      $ctrl.selectedTenant = undefined;

      if ($ctrl.ngModel.$isEmpty($ctrl.ngModel.$viewValue)) {
        return;
      }

      if ($ctrl.multiple) {
        $ctrl.tenantsList.forEach(function eachTenant(tenant) {
          if (find($ctrl.ngModel.$viewValue, getEqualSelectionFn(tenant))) {
            $ctrl.selectedTenants.push(tenant);
          }
        });
      } else {
        $ctrl.tenantsList.some(function eachTenant(tenant) {
          if (isEqual($ctrl.ngModel.$viewValue, getProperty(tenant))) {
            $ctrl.selectedTenant = tenant;
            return true;
          }
          return false;
        });
      }
    }

    /**
     * Return a fn used to determine if selected item is equals to provided
     * tenant.
     *
     * @method   getEqualSelectionFn
     * @param    {Object}     tenant   The Tenant info.
     * @return   {function}   The function used to determine if selected item
     *                        is equals to provided tenant.
     */
    function getEqualSelectionFn(tenant) {
      /**
       * Determines if selected item is equals to tenant.
       *
       * @method   isEqualSelection
       * @param    {Object}    item   The item to test.
       * @return   {boolean}   Return True if selected item equals to tenant.
       */
      return function isEqualSelection(item) {
        return isEqual(item, getProperty(tenant));
      };
    }

    /**
     * Update external model value on internal model changes.
     *
     * @method   updateExternalModel
     */
    function updateExternalModel() {
      var value;
      if ($ctrl.multiple) {
        value = map($ctrl.selectedTenants, getProperty);
      } else {
        value = getProperty($ctrl.selectedTenant);
      }

      $ctrl.isAllSelected =
        $ctrl.selectedTenants.length === $ctrl.tenantsList.length;

      // update external mode
      $ctrl.ngModel.$setViewValue(value);
    }

    /**
     * Returns the tenant property that needed to be in the selected tenant info
     *
     * @method   getProperty
     * @param    {Object}    tenant   The tenant info.
     * @return   {any}       Returns the tenant property that needed to be in
     *                       the selected tenant info.
     */
    function getProperty(tenant) {
      return $ctrl.resultAs ? get(tenant, $ctrl.resultAs) : tenant;
    }

    /**
     * Select all tenants.
     *
     * @method   selectAll
     * @param    status   True, if select all is selected, else false.
     */
    function selectAll(status) {
      $ctrl.isAllSelected = status;
      $ctrl.selectedTenants = status ? $ctrl.tenantsList : [];
      updateExternalModel();
    }

    /**
     * Launch add tenants workflow.
     *
     * @method   addNewTenant
     */
    function addNewTenant() {
      // Resets the impersonated tenant.
      if (NgTenantService.impersonatedTenant) {
        NgTenantService.clearImpersonatedTenant();
      }

      $ctrl.onAddNew && $ctrl.onAddNew();

      // TODO(veetesh): create tenant in modal
      $state.go('add-tenant');
    }
  }
})(angular);
