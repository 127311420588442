import { Injectable } from '@angular/core';
import { DmaasRegion } from '@cohesity/api/dms';
import { McmPhysicalSourceInfo, McmSource, McmSourceInfo, SourceRegistration } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { filter } from 'lodash-es';
import { AppStateService } from 'src/app/core/services';
import { AgentUpgradable, Environment, ObjectTypeToIconMap, OsVariantIconMap } from 'src/app/shared';
import { ProtectionInfo, SourceLayout } from '../interfaces/source-layout';
import { getProtectionInfoFromMcmSourceInfo, mapProtectionInfoFromProtectionStats } from '../models';

@Injectable()
export class McmSourcesService {
  constructor(
    private irisContextService: IrisContextService,
    private appStateService: AppStateService,
  ) {}

  /**
   * Transforms a source into a SourceLayout interface to be rendered by the
   * source table or source card layout.
   *
   * @param   source   Source information
   * @return   A list of sources to be rendered.
   */
  transformSource(source: McmSource, regions: DmaasRegion[]): SourceLayout[] {
    const transformedSource = source.sourceInfoList.map(
      (src): SourceLayout => {
        const protectionStats = src.protectionStats?.find(s => s?.environment === source.environment);
        const iconName = this.getDmsSourceIconName(source);
        let upgradeInfo = {};
        const enableSetupSaasConnection =
          isDmsScope(this.irisContextService.irisContext) &&
          source.environment === Environment.kAWS &&
          flagEnabled(this.irisContextService.irisContext, 'showCsmIngestProtectionPolicy');

        if (source.environment === Environment.kPhysical) {
          const sourceInfo = (source?.sourceInfoList?.[0] as any)?.physicalSourceInfo;
          upgradeInfo = {
            isUpgradable: sourceInfo?.upgradability === AgentUpgradable,
            upgradeStatus: sourceInfo?.upgradeStatus,
            isAgentUpgrading: ['kAccepted', 'kStarted'].includes(sourceInfo?.upgradeStatus),
          };
        }

        const sourceLayout: SourceLayout = {
          applications: src.applications,
          environment: source.environment,
          id: src.sourceId.toString(),
          lastRefreshTime: src.registrationDetails?.lastRefreshTimeUsecs,
          name: source.name,
          icon: iconName,
          sourceType: Environment[source.environment],
          sourceId: src.sourceId,
          protectionInfo: mapProtectionInfoFromProtectionStats(protectionStats) || {} as ProtectionInfo,
          groupName: source.environment,
          regionId: src.regionId,
          regionName: regions?.find(r => r.id === src.regionId)?.name,
          clusterName: src.clusterId
            ? this.appStateService.remoteClusterList?.find(cluster => cluster.clusterId === Number(src.clusterId))?.name
            : '',
          clusterId: src.clusterId,
          ...upgradeInfo,
          enableSetupSaasConnection,
          registrationId: src.registrationId,
          sourceInfo: src,
          mcmSourceId: source.id,
        };
        sourceLayout.registeredOn = sourceLayout.regionName || sourceLayout.clusterName;
        sourceLayout.errors = {
          refreshError: src.registrationDetails?.refreshError,
          registrationError: src.registrationDetails?.registrationError,
          missingObjectsMessageKey: flagEnabled(this.irisContextService.irisContext, 'dmsSourceMissingCount') &&
            Boolean(protectionStats?.deletedProtectedCount) ? 'dms.source.missingObjects' : undefined,
        };
        sourceLayout.showAdditionalInformation = Boolean(filter(sourceLayout.errors, (value) => !!value).length);
        sourceLayout.maintenanceModeConfig = src.maintenanceModeConfig;
        return sourceLayout;
      }
    );

    return transformedSource;
  }

  /**
   * This method adds the protection stats to the available sources.
   *
   * @param availableSources - sources without stats.
   * @param sourcesWithStats - sources with stats.
   * @returns void
   */
  populateStats(availableSources: SourceLayout[], sourcesWithStats: McmSource[]) {
    // create a lookup map for performance
    const sourcesWithStatsLookupMap = sourcesWithStats.reduce(function (map, source) {
      map[source.id] = source;
      return map;
    }, {});
    // loop over available sources and find the correct stats for the environment.
    availableSources.forEach(eachAvailableSource => {
      let protectionInfo;
      const sourceWithStats: McmSource = sourcesWithStatsLookupMap[eachAvailableSource.mcmSourceId.toString()];
      if (sourceWithStats) {
        const mcmSourceInfo = sourceWithStats.sourceInfoList.find(
          si => si.sourceId.toString() === eachAvailableSource.id
        );
        protectionInfo = getProtectionInfoFromMcmSourceInfo(mcmSourceInfo, eachAvailableSource.environment);
      }
      eachAvailableSource.protectionInfo = protectionInfo || {};
    });
  }

  /**
   * Transforms a SourceRegistration object into a SourceLayout interface to be rendered by the
   * source table or source card layout.
   *
   * @param   source   SourceRegistration information
   * @return   A list of sources to be rendered.
   */
  transformSourceForCluster(source: SourceRegistration): SourceLayout[] {
    const sourceInfoList: McmSourceInfo[] = [];
    const mcmSourceInfo: McmSourceInfo = {
      protectionStats: source.sourceInfo.protectionStats,
      applications: source.physicalParams?.applications,
      physicalSourceInfo: {
        hostType: source.physicalParams.hostType
      }
    };

    sourceInfoList.push(mcmSourceInfo);
    const mcmSource: McmSource = {
      environment: source.environment,
      id: source.id.toString(),
      sourceInfoList: sourceInfoList,
      type: source.physicalParams.physicalType
    };

    const iconName = this.getDmsSourceIconName(mcmSource);
    const sourceLayout: SourceLayout[] = [];
    const sourceLayoutObj = {
      mcmSourceId: source.id.toString(),
      sourceId: source.sourceId,
      id: source.sourceId.toString(),
      icon: iconName,
      sourceInfo: {
        protectionStats: source.sourceInfo.protectionStats,
        applications: source.physicalParams?.applications,
        physicalSourceInfo: {
          hostType: source.physicalParams.hostType
        }
      },
      environment: source.environment,
      lastRefreshTime: (source.lastRefreshedTimeMsecs*1000),
      applications: source?.physicalParams?.applications,
      name: source.sourceInfo.name,
      sourceType: Environment[source.environment],
      groupName: source.environment,
    };
    sourceLayout.push(sourceLayoutObj);
    return sourceLayout;
  }

  /**
   * Returns dms source icon name based on source environment.
   *
   * @param   source   Source information
   * @returns  icon name based on source environment.
   */
  getDmsSourceIconName(source: McmSource): string {
    const envIconMap = ObjectTypeToIconMap[source.environment];
    let iconName = envIconMap?.[source.type];
    let sourceInfo: McmPhysicalSourceInfo;

    switch (source.environment) {
      case Environment.kPhysical:
      case Environment.kOracle: {
        sourceInfo = (source.sourceInfoList?.[0] as McmSourceInfo)?.physicalSourceInfo;

        // Update icon from a generic physical host to OS specific icon.
        let hostType = sourceInfo?.hostType;
        if (sourceInfo?.hostType === undefined) {
          // For cluster apis, hostType is present under physicalSourceInfo
          hostType = source.sourceInfoList?.[0].physicalSourceInfo.hostType;
        }
        if (iconName && OsVariantIconMap[iconName]) {
          iconName = OsVariantIconMap[iconName][hostType];
        } else {
          iconName = envIconMap?.[hostType];
        }
        break;
      }
    }
    return iconName;
  }
}
