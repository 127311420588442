import { flagEnabled, IrisContext, isDmsScope } from '@cohesity/iris-core';
import { Office365BackupType, RecoveryAction } from 'src/app/shared';

/**
 * Returns a list of recovery actions based on the current workload.
 *
 * @param irisContext The iris context.
 * @param workloadType Current workload type.
 * @returns  An array of recovery actions.
 */
export function geO365RecoveryActions(irisContext: IrisContext, workloadType: Office365BackupType): RecoveryAction[] {
  const mailboxGranularEnabled = flagEnabled(irisContext, 'dmsOffice365MailboxGranularRecoveryEnabled');
  const oneDriveGranularEnabled = flagEnabled(irisContext, 'dmsOffice365OneDriveGranularRecoveryEnabled');
  const sharePointGranularEnabled = flagEnabled(irisContext, 'dmsOffice365SharePointGranularRecoveryEnabled');
  const teamsGranularEnabled = flagEnabled(irisContext, 'dmsOffice365TeamGranularRecoveryEnabled');
  const groupsGranularEnabled = isDmsScope(irisContext) ?
    flagEnabled(irisContext, 'dmsOffice365GroupsGranularRecoveryEnabled') :
    (flagEnabled(irisContext, 'office365GroupGranularRecoveryEnabledv2') ||
    flagEnabled(irisContext, 'office365GroupGranularRecoveryEnabled'));
  const downloadTeamItemsFeatureEnabled = isDmsScope(irisContext)
    ? flagEnabled(irisContext, 'dmsOffice365TeamPostAndChatDownloadEnabled')
    : flagEnabled(irisContext, 'office365TeamPostAndChatDownloadEnabled');

  switch (workloadType) {
    case Office365BackupType.kGroups:
      return [
        RecoveryAction.RecoverMsGroup,
        ...groupsGranularEnabled ? [RecoveryAction.RecoverMsGroupDocuments]: []
      ];
    case Office365BackupType.kMailbox:
      return [
        RecoveryAction.RecoverMailbox,
        ...mailboxGranularEnabled ? [RecoveryAction.RecoverMails] : [],
        ...downloadTeamItemsFeatureEnabled ? [RecoveryAction.DownloadChats]: []
      ];
    case Office365BackupType.kMailboxCSM:
      return [
        RecoveryAction.RecoverMailboxCSM
      ];
    case Office365BackupType.kOneDrive:
      return [
        RecoveryAction.RecoverOneDrive,
        ...oneDriveGranularEnabled ? [RecoveryAction.RecoverOneDriveDocuments] : [],
      ];
    case Office365BackupType.kOneDriveCSM:
      return [
        RecoveryAction.RecoverOneDriveCSM
      ];
    case Office365BackupType.kPublicFolders:
      return [RecoveryAction.RecoverPublicFolders];
    case Office365BackupType.kSharePoint:
      return [
        RecoveryAction.RecoverSharePoint,
        ...sharePointGranularEnabled ? [RecoveryAction.RecoverSharePointDocuments] : [],
      ];
    case Office365BackupType.kSharePointCSM:
      return [
        RecoveryAction.RecoverSharePointCSM
      ];
    case Office365BackupType.kTeams:
      return [
        RecoveryAction.RecoverMsTeam,
        ...teamsGranularEnabled ? [RecoveryAction.RecoverTeamsDocuments] : [],
        ...downloadTeamItemsFeatureEnabled ? [RecoveryAction.DownloadTeamsPosts]: []
      ];
  }
}
