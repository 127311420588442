import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @description
 *
 * Progress bar that displays duration label and percent progress bar.
 */
@Component({
  selector: 'cog-duration-progress',
  templateUrl: './duration-progress.component.html',
  styleUrls: ['./duration-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationProgressComponent  {

  /**
   * Label for the progress bar. 'durationMs' will be ignored if this is set.
   */
  @Input() label: string;

  /**
   * Duration in milliseconds. This input will be ignored if 'label' is set.
   */
  @Input() durationMs: number;

  /**
   * Progress bar percentage.
   */
  @Input() progress = 0;
}
