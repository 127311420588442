import { isDate } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: c-inline-date-picker component

;(function(angular, undefined) {

  angular.module('C.inlineDatePicker', ['ui.bootstrap', 'C.uibDropdown'])
    .config(configFn)
    .controller('InlineDatePickerCtrl', InlineDatePickerCtrlFn)
    .component('cInlineDatePicker', {
      bindings: {
        /**
         * Provide label translatable key for date-picker.
         */
        label: '@',

        /**
         * Optional provided default options to override uib-datepicker options.
         * options are documented here
         * https://angular-ui.github.io/bootstrap/#!#datepicker
         * options = {
         *   dateFormat: DateTimeService.getDatePickerFormat(),
         * }
         */
        options: '<?',
      },
      require: {
        // require model controller to spit out selected date.
        ngModel: 'ngModel',
      },
      controller: 'InlineDatePickerCtrl',
      templateUrl: 'app/global/cDatePicker/c-inline-date-picker.html',
    });

  /**
   * Config fn to decorator uib-date-picker with isMoveDisabled utility fn.
   *
   * @method    configFn
   */
  function configFn($provide) {
    // decorator uib-date-picker with isMoveDisabled fn used internally to
    // enable/disable previous and next button of the date picker.
    $provide.decorator('uibDatepickerDirective', addingIsMoveDisabled);
  }

  function InlineDatePickerCtrlFn(_, $timeout, DateTimeService) {
    var $ctrl = this;

    var defaultOptions = {
      dateFormat: DateTimeService.getDatePickerFormat(),
    };

    assign($ctrl, {
      // internal raw date object.
      rawDate: null,
      placeholder: DateTimeService.getDatePickerPlaceholder(),

      // Component life cycle methods.
      $onInit: $onInit,

      // Component methods.
      propagateClick: propagateClick,
      updateExternalModel: updateExternalModel,
    });

    /**
     * Initialize this component.
     *
     * @method     $onInit
     */
    function $onInit() {
      $ctrl.datePickerOptions = assign(defaultOptions, $ctrl.options);
      $ctrl.ngModel.$render = updateInternalModel;
    }

    /**
     * Update internal model with external model changes.
     *
     * @method   updateInternalModel
     */
    function updateInternalModel() {
      var modelVal = $ctrl.ngModel.$viewValue;

      if ($ctrl.ngModel.$isEmpty(modelVal) && !isDate(modelVal)) {
        return;
      }

      $ctrl.rawDate = modelVal;
    }

    /**
     * Update external model value on internal model changes.
     *
     * @method   updateExternalModel
     */
    function updateExternalModel() {
      $ctrl.ngModel.$setViewValue($ctrl.rawDate);
    }

    /**
     * Captures and stop propagation of original click event from propagation
     * and dispatch a new click event from c-inline-date-picker so that
     * parentElement.contains(event.target) from c-inline-date-range can
     * determine inside click.
     *
     * @method   propagateClick
     */
    function propagateClick($event) {
      // preventing original date cell click propagation event because
      // parentElement.contains(event.target) fails to determines that parent
      // element contains the target event.
      $event.stopPropagation();
      $event.preventDefault();

      // dispatching a new click event from c-inline-date-picker.
      $timeout(function lazyPropagation() {
        // running into Illegal invocation error if proving click fn directly
        // to $timeout.
        $event.currentTarget.parentElement.click();
      });
    }
  }

  /**
   * @ngdoc decorator
   * @name        ui.bootstrap.uibDatepicker
   * @method      addingIsMoveDisabled
   *
   * @description
   * Decorator uib-date-picker with isMoveDisabled fn used internally to
   * enable/disable previous and next button of the date picker.
   */
  function addingIsMoveDisabled(_, $delegate) {
    var directive = $delegate[0];
    var compile = directive.compile;

    directive.compile = function addingIsMoveDisabledCompileFn() {
      // Cache the existing linkFn to execute after our decorator.
      var linkFn = compile.apply(this, arguments);

      return function addingIsMoveDisabledLinkFn(scope, elem, attrs, ctrls) {
        var self = ctrls[0];

        /**
         * Determines whether a move is allowed or not used to disable the
         * next and previous buttons.
         *
         * internally uses provided dateDisabled options and if it not provided
         * all moves are allowed.
         *
         * @method   isMoveDisabled
         * @param    {Number}    direction    Move direction for next +1 and for
         *                                    previous it would be -1.
         * @returns  {Boolean}   Returns True the provided move is disabled or
         *                       not else return false.
         */
        scope.isMoveDisabled = function isMoveDisabled(direction) {
          // getting rows from uib-daypicker/monthpicker/yearpicker scope
          // this will be scope for above directive.
          var rows = this.rows;
          var calenderDate;

          // don't disable move if no rendered row or dateDisabled fn is not
          // provided.
          if (!rows || !rows.length || !rows[0].length || !scope.dateDisabled) {
            return false;
          }

          if (direction === -1) {
            // going back so find 1st shown date.
            calenderDate = new Date(rows[0][0].date);
          } else {
            // going next so find last shown date.
            calenderDate = new Date(
              // getting last row's last column date.
              rows[rows.length - 1][rows[0].length - 1].date);
          }

          // disable going back when 1st shown date - 1 is disabled.
          // disable going next when last shown date + 1 is disabled.
          calenderDate.setDate(calenderDate.getDate() + direction);
          return scope.dateDisabled({
            date: calenderDate,
            mode: scope.datepickerMode,
          });
        };

        // Execute the default linkFn now.
        return linkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }

})(angular);
