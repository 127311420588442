import { assign } from 'lodash-es';
// Component: snmp-user component

;(function(angular, undefined) {
  'use strict';

  angular.module('C.snmp')
    .component('snmpUser', {
      controller: snmpUserCtrlFn,
      templateUrl: 'app/admin/snmp/snmp-user/snmp-user.html',

      bindings: {
        // @type   {string}   Id suffix to use for elements
        userId: '@',

        // @type   {object}   Snmp user object
        user: '=',

        // @type   {string}   One of the SNMP_CONST.version
        version: '=',

        // @type   {function}   Callback function when remove icon is clicked
        onRemove: '&',
      }
    });

  /**
   * @ngdoc component
   * @name C.snmp:snmpUser
   * @function
   *
   * @description
   * Provides form for accepting snmp user config
   * values
   *
   * @example
     <snmp-user
       user="$ctrl.config.trapUser"
       version="$ctrl.config.version"
       user-id="trapUser">
     </snmp-user>
   */
  function snmpUserCtrlFn(_, $attrs, SNMP_CONST, FORMATS) {
    var $ctrl = this;

    assign($ctrl, {
      canRemove: false,
      FORMATS: FORMATS,
      SNMP_CONST: SNMP_CONST,

      // Component lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Lifecycle init method for component
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.canRemove = !!$attrs.onRemove;
    }
  }

})(angular);
