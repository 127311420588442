<cog-statlist [title]="'runFailures' | translate">
  <a cogStatlistItem *ngFor="let stat of stats"
    uiSref="jobs"
    [uiParams]="getStatSrefParams(stat)"
    [label]="stat.nameKey | translate">
    {{ stat.value }}
    <small *ngIf="stat.change !== 0"
      [ngClass]="stat.change > 0 ? 'status-critical' : 'status-ok'">
      {{ stat.change | valueChangeTrend }}%
    </small>
  </a>
</cog-statlist>
