import { map } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Office365 Email and Folders search component.

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.office365Recovery')
    .controller('Office365EmailSearchCtrl', office365EmailSearchCtrlFn)
    .component('office365EmailSearch', {
      templateUrl:
        'app/protection/recovery/office365/search/emails/emails.html',
      controller: 'Office365EmailSearchCtrl',
    });

  /**
   * @ngdoc component
   * @name C.office365Recovery:office365EmailSearch
   * @function
   * @param   {object}   _                      Lodash Library
   * @param   {object}   RecoveryStore          Cart service
   * @param   {object}   SearchService          Search service
   * @param   {object}   DateTimeService        Date time service
   * @param   {object}   evalAJAX               evalAJAX Service
   * @param   {object}   cUtils                 Utilities
   * @param   {object}   $filter                Object holding filter functions
   * @param   {object}   GlobalSearchService    Global search service
   *
   * @description
   *  Component to provide search functionality for emails and folders.
   *
   */
  function office365EmailSearchCtrlFn(_, RecoveryStore, SearchService,
    DateTimeService, evalAJAX, cUtils, $filter, GlobalSearchService,
    FEATURE_FLAGS) {
    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,

      state: {
        mailboxesNotFound: false,

        selectedJobs: [],
        selectedMailboxes: [],
        selectedServer: undefined,

        // Object to hold Email/Folders search
        parameters: {
          // Specifies domain IDs to search for emails within Librarian.
          domainIds: [],
          environments: ['kO365'],
          hasAttachments: false,
          mailboxIds: [],
          protectionJobIds: [],
          recipientAddresses: [],
          showAdditionalFilters: false,
          showEmailSearchParameters: false,
          showOnlyEmailFolders: false,
        },
      },

      // Methods exposed to template.
      canQueryOnlyFolders: canQueryOnlyFolders,
      getUserMailboxes: getUserMailboxes,
      populateDate: populateDate,
      preProcessRequestParam: preProcessRequestParam,
      searchEmails: searchEmails,
      toggleSearchParametersVisibility: toggleSearchParametersVisibility,
      toggleAdditionalFiltersVisibility: toggleAdditionalFiltersVisibility,
      validateEmail: validateEmail,
    });

    /**
     * @method   $onInit
     *
     * Initializes component.
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();

      // Set the search type for differentiation with OneDrive flow.
      $ctrl.shared.searchItemType = 'kEmailSearch';
      clearCartAndSearch();
    }

    /**
     * Clears the recovery cart and the search results when the search type is
     * changed.
     * NOTE: Although both iris and magneto support recovery of mailbox,
     * folders & emails in a single recovery job but currently this is just to
     * segregate the flow. This method may be removed in future.
     *
     * @method   clearCartAndSearch
     */
    function clearCartAndSearch() {
      $ctrl.shared.cart.removeAll();
      _resetOffice365EmailSearch();
    }

    /**
     * Populates the date range for searching emails.
     *
     * @method   populateDate
     * @param    {date}      dateObject     Specifies the date object.
     * @param    {string}    dateRangeKey   Specifies the start/end date key.
     * @param    {boolean}   isEndDate      Specifies if the date is for end of
     *                                      range.
     */
    function populateDate(dateObject, dateRangeKey, isEndDate) {
      if (!dateObject) {
        $ctrl.state.parameters[dateRangeKey] = undefined;
      } else {
        // Set the end date as a day ahead to incorporate end of the day.
        if (isEndDate) {
          dateObject = DateTimeService.endOfDay(dateObject);
        }
        $ctrl.state.parameters[dateRangeKey] = DateTimeService.dateToMsecs(
          dateObject) / 1000;
      }
    }

    /**
     * Vaidates email and returns either boolean or the valid email if the flag
     * is true.
     *
     * @method   validateEmail
     * @param    {string}    email         Specifies Email Address
     * @return   {boolean}   Returns false if the string is an invalid email
     *                       and the string itself if valid email.
     */
    function validateEmail(email) {
      return cUtils.uiSelectEmailValidator(email);
    }

    /**
     * Pre processes the optional parameter for outlook search.
     *
     * @method   preProcessRequestParam
     * @param    {string}   param    Specifies the parameter in request.
     */
    function preProcessRequestParam(param) {
      if (!$ctrl.state.parameters[param]) {
        $ctrl.state.parameters[param] = undefined;
      }
    }

    /**
     * Checks whether the query can fetch only folders.
     * Incase of searching emails & folders, query for only folders can only be
     * done if the user has not specified any filter or specified only folder
     * name. Otherwise, Librarian will be queried on mututally exclusive
     * filters resulting in empty response.
     *
     * @method   canQueryOnlyFolders
     * @return   {boolean}   True if search only folders can be done
     */
    function canQueryOnlyFolders() {
      var currentState = $ctrl.state.parameters;
      if (!currentState.hasAttachments &&
        !currentState.senderAddress &&
        !$ctrl.state.emailReceivedStartDate &&
        !$ctrl.state.emailReceivedEndDate &&
        !currentState.recipientAddresses.length &&
        !currentState.emailSubject) {
        return true;
      } else {
        return $ctrl.state.parameters.showOnlyEmailFolders = false;
      }
    }

    /**
     * Toggles the visibility of the Email search parameter box.
     *
     * @method   toggleSearchParametersVisibility
     */
    function toggleSearchParametersVisibility() {
      $ctrl.state.showEmailSearchParameters =
        !$ctrl.state.showEmailSearchParameters;
    }

    /**
     * Toggles the visibility of additional search filters.
     *
     * @method   toggleAdditionalFiltersVisibility
     */
    function toggleAdditionalFiltersVisibility() {
      $ctrl.state.showAdditionalFilters = !$ctrl.state.showAdditionalFilters;
    }

    /**
     * Resets the office365 Email search results.
     *
     * @method   _resetOffice365EmailSearch
     */
    function _resetOffice365EmailSearch() {
      $ctrl.shared.searchData.length = 0;
    }

    /**
     * Populates Office365 Server IDs from the selected servers for additional
     * Email search filters.
     *
     * @method   _preProcessServerSelection
     */
    function _preProcessServerSelection() {
      $ctrl.state.parameters.domainIds.length = 0;
      if ($ctrl.state.selectedServer !== undefined) {
        $ctrl.state.parameters.domainIds.push($ctrl.state.selectedServer.id);
      }
    }

    /**
     * Populates Mailbox IDs from the selected mailboxes for additional Email
     * search filters.
     *
     * @method   _preProcessMailboxSelection
     */
    function _preProcessMailboxSelection() {
      if (FEATURE_FLAGS.office365FetchUsersFromObjIndexInEmailSearchEnabled) {
        $ctrl.state.parameters.mailboxIds =
        map($ctrl.state.selectedMailboxes,
          'source.id');
        return;
      }

      $ctrl.state.parameters.mailboxIds =
        map($ctrl.state.selectedMailboxes,
          // NOTE: The default source Id corresponds to the current cluster.
          'protectionSourceUidList[0].sourceId');
    }

    /**
     * Populates Job IDs from the selected Protection Jobs for additional Email
     * search filters.
     *
     * @method   _preProcessJobSelection
     */
    function _preProcessJobSelection() {
      $ctrl.state.parameters.protectionJobIds =
        map($ctrl.state.selectedJobs, 'jobId');
    }

    /**
     * Fetches the list of mailboxes matching the search string sent
     * as the input to this function.
     * Mailbox list is fetched from ES from either of the 2 indices:
     * - global_entity - default
     * - obj_index - office365FetchUsersFromObjIndexInEmailSearchEnabled
     *
     * @method   getUserMailboxes
     * @param    {string}     [mailboxName]   Specifies the name of
     *                                                 the mailbox
     * @return   {object}     Promise resolving to array of protected Mailbox
     *                        objects
     */
    function getUserMailboxes(mailboxName = '*') {
      $ctrl.state.fetchingMailboxes = true;

      // Dont make the call to magneto if server is not selected.
      if ($ctrl.state.selectedServer === undefined) {
        $ctrl.state.fetchingMailboxes = false;
        return;
      }

      // Search through 'objindex' to only return protected sources.
      if (FEATURE_FLAGS.office365FetchUsersFromObjIndexInEmailSearchEnabled) {
        $ctrl.shared.filterLookups.mailboxes = [];
        var params = {
          environments: ['kO365'],
          office365SourceTypes:[
            'kUser',
            'kMailbox',
          ],
          search: mailboxName,
          registeredSourceIds: [$ctrl.state.selectedServer.id]
        };

        return SearchService.searchForObjects(params).then(res => {
          $ctrl.state.mailboxesNotFound = !res.length;
          if (res.length) {
            res.forEach((node) => {
              $ctrl.shared.filterLookups.mailboxes.push({
                source: {
                  id: node.snapshottedSource.id,
                  name: node.snapshottedSource.name
                }
              });
              return $ctrl.shared.filterLookups.mailboxes;
            });
          }
        }, evalAJAX.errorMessage).finally(() => {
          $ctrl.state.fetchingMailboxes = false;
        });
      }

      // Search through 'global_entity' index.
      var params = {
        environments: 'kO365',

        // Specifies the count of mailbox within each page.
        pageCount: 20,
        registeredSourceUuids: [],
        searchString: mailboxName,
        office365ProtectionSourceTypes: ['kUser'],
      };

      // Add Office365 server filter for fetching mailboxes within the same if
      // selected.
      if ($ctrl.state.selectedServer !== undefined) {
        params.registeredSourceUuids =  $filter('o365DomainIdentifier')($ctrl.state.selectedServer);
      }

      return GlobalSearchService.getResults(params).then(
        function onSuccessfulRequest(mailboxes) {
          $ctrl.shared.filterLookups.mailboxes = mailboxes;
          $ctrl.state.mailboxesNotFound = !mailboxes.length;
          return mailboxes;
        }, evalAJAX.errorMessage
      ).finally(
        function onCompletion() {
          $ctrl.state.fetchingMailboxes = false;
        }
      );
    }

    /**
     * Requests iris_exec to search within Librarian.
     *
     * Emails(kEmail) and Email Folders(kEmailFolder) are indexed within
     * Librarian under 'cfileindex' similar to files/folders/symlinks.
     * src - CrackedFileDocument in main/yoda/db/documents.proto
     *
     * Iris_exec exposes public API - /public/restore/office365/outlook/emails
     * to enable search on the same.
     *
     * @method   searchEmails
     * @return   {object}   Promise containing queried emails.
     */
    function searchEmails() {
      $ctrl.state.searching = true;

      // Populate Date range.
      populateDate($ctrl.state.emailReceivedStartDate, 'receivedStartTime');
      populateDate($ctrl.state.emailReceivedEndDate, 'receivedEndTime', true);
      _preProcessMailboxSelection();
      _preProcessJobSelection();
      _preProcessServerSelection();

      return SearchService.searchEmails($ctrl.state.parameters).then(
        function onSuccessfulRequest(response) {
          return $ctrl.shared.searchData = response;
          }, evalAJAX.errorMessage)
      .finally(
        function onCompletion() {
          $ctrl.state.searching = false;
          toggleSearchParametersVisibility();
        }
      );
    }
  }
})(angular);