import { assign } from 'lodash-es';
// Component: Stat Value Component

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'StatValueController',
    templateUrl: 'app/global/c-stat-list/c-stat-value.html',
    bindings: {
      label: '<',
      labelContext: '<?',
      value: '<?',
      valueKey: '<?',
      valueContext: '<?',
      filterName: '<?',
    },
  };

  angular.module('C.statList')
    .controller('StatValueController', statValueCtrlFn)
    .component('cStatValue', componentConfig);

  /**
   * @ngdoc component
   * @name cStatValue
   * @description
   * Displays a label and value for a stat list
   *
   *
   * @param   {string}          label          translation key for the value
   * @param   {string}          labelContext   translation key for the value
   * @param   {number|string}   value          numeric or string value to show
   *                                           if numeric, will automatically
   *                                             use the number filter
   * @param   {string}          valueKey       translation key for value
   * @param   {object}          valueContext   translation context for the
   *                                             value
   * @param   {string}          filterName     filter to be applied on the value
   *
   * @example
   * <c-stat-value label="labelKey" value="3"</c-stat-value>
   */
  function statValueCtrlFn(_, $filter) {
    var $ctrl = this;

    assign($ctrl, {
      applyFilter: applyFilter,
    });

    /**
     * Applies the specified filter like byteSize, naFilter etc.
     *
     * @method    applyFilter
     * @return    {string}     returns the filtered value
     */
    function applyFilter() {
      if ($ctrl.filterName) {
        return $filter($ctrl.filterName)($ctrl.value, true);
      }
      if (typeof($ctrl.value) === 'number') {
        return $filter('number')($ctrl.value);
      }
      return $ctrl.value;
    }
  }

})(angular);