import { last } from 'lodash-es';
import { forEach } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
// Service: Restore Service Formatters

;(function(angular, undefined) {
  'use strict';

  angular.module('C.restoreServiceFormatter', [])
    .service('RestoreServiceFormatter', ServiceFn);

  function ServiceFn(ENUM_UI_RESTORE_TASK_STATUS,
    ENUM_UI_RESTORE_TASK_STATUS_LABEL_CLASSNAME, ENUM_RESTORE_TASK_STATUS,
    RESTORE_TASK_UI_STATUS, ENV_TYPE_CONVERSION, DateTimeService, $translate) {
    // The Service's API
    return {
      decorateCloneAppInfo: decorateCloneAppInfo,
      decorateCloneRefreshTask: decorateCloneRefreshTask,
      getProgressMonitorUrl: getProgressMonitorUrl,
      getTaskErrorMessage: getTaskErrorMessage,
      getTaskStatusString: getTaskStatusString,
      getTaskStatusClass: getTaskStatusClass,
      getTaskWarningMessage: getTaskWarningMessage,
      transformRemoteTasks: transformRemoteTasks,
    };

    /**
     * Promise handler for Remote Restore download tasks.
     *
     * @method   transformRemoteTasks
     * @param    {object}   [resp]   The raw server response.
     * @return   {array}    The list of transformed remote download tasks.
     */
    function transformRemoteTasks(resp) {
      return (resp.data || []).map(
        function taskMapper(task, ii) {
          var currentIndexingStatus = task.currentIndexingStatus;
          return !task ? task : angular.extend(task, {
            _hasIndexingTask: currentIndexingStatus.startTimeUsecs > 0,
            _hasSnapshottingTask: task.currentSnapshotStatus !== undefined,
            _indexingTaskVec: {},
            _snapshotTaskVec: {},
            _uniqueId: Object.values(currentIndexingStatus.indexingTaskUid)
              .join('-'),
          });
        }
      );
    }

    /**
     * Returns the error message that caused the restore task to fail. This
     * method relies on the `_status` field of the restore task.
     *
     * @method   getTaskErrorMessage
     * @param    {Object}   restoreTask   The restore task object.
     * @return   {string}   The error message if available, `null` otherwise.
     */
    function getTaskErrorMessage(restoreTask) {
      var lastDestroy = last(restoreTask.destroyClonedTaskStateVec);
      var lastRefresh = last(restoreTask.performRefreshTaskStateVec);
      var restore = restoreTask.performRestoreTaskState;

      switch(restoreTask._status) {
        case RESTORE_TASK_UI_STATUS.kDestroyError:
          return get(lastDestroy, 'error.errorMsg', null);
        case RESTORE_TASK_UI_STATUS.kRefreshError:
          return get(lastRefresh, 'base.error.errorMsg', null);
        case RESTORE_TASK_UI_STATUS.kError:
          return get(restore, 'base.error.errorMsg', null);
        default:
          return null;
      }
    }

    /**
     * Returns the warning message, if any, for the restore task.
     *
     * @method   getTaskErrorMessage
     * @param    {Object}   restoreTask   The restore task object.
     * @return   {string}   The warning message if available, `null` otherwise.
     */
    function getTaskWarningMessage(restoreTask) {
      var warnings = restoreTask.performRestoreTaskState.base.warnings;
      if (!isEmpty(warnings)) {

        // warnings array will be of the form
        // [{
        //    type: [1-9],
        //    errorMsg: 'Warning Text'
        // }]
        // Combine the warnings into a single string
        return restoreTask.performRestoreTaskState.base.warnings.reduce(
          (previous, current) => previous + ' ' + current.errorMsg,
          ''
        );
      }

      // Return BCT file recreation warning message during Oracle CDB non-smart clone task.
      const restoreAppParams = get(restoreTask,
        'performRestoreTaskState.restoreAppTaskState.restoreAppParams');
      const restoreAppObjectVec = get(restoreAppParams, 'restoreAppObjectVec');
      const taskType = get(restoreAppParams, 'type');
      if (!isEmpty(restoreAppObjectVec) && taskType === ENV_TYPE_CONVERSION.kOracle) {
        const isCdbClone = get(restoreAppObjectVec[0],
          'appEntity.oracleEntity.dbEntityInfo.cdbEntityInfo.pdbEntityInfoVec')?.length > 0;

        if (isCdbClone && restoreAppObjectVec[0].restoreParams.targetHost.id ===
          restoreAppObjectVec[0].appEntity.parentId) {
          return $translate.instant('oracle.recreateBctFileWarningMsg', {
            dbName: restoreAppObjectVec[0].appEntity.oracleEntity.name.toLowerCase(),
            taskType: $translate.instant('clone').toLowerCase()
          });
        }
      }
    }

    /**
     * provide the class name to be applied to status label based on the task
     * status
     *
     * @param      {Number}   calculatedTaskStatus   task status as computed by
     *                                                getTaskStatus
     * @return     {String}   class name to apply to status label
     */
    function getTaskStatusString(calculatedTaskStatus) {
      return ENUM_UI_RESTORE_TASK_STATUS[calculatedTaskStatus] ||
        $translate.instant('unknown');
    }

    /**
     * provide the class name to be applied to status label based on the task
     * status
     *
     * @param      {Number}    restoreTask   The restore task object.
     * @return     {String}    class name to apply to status label
     */
    function getTaskStatusClass(restoreTask) {
      // return warning if restoreTask has warnings.
      if (restoreTask._warningMessage) {
        return 'status-warning';
      }

      return ENUM_UI_RESTORE_TASK_STATUS_LABEL_CLASSNAME[
        restoreTask._status];
    }

    /**
     * Returns the endpoint to fetch the pulse events for a restore task.
     *
     * @method   getProgressMonitorUrl
     * @param    {string}   taskPath   The progress monitor task path.
     * @return   {string}   The restore task's progress monitor endpoint.
     */
    function getProgressMonitorUrl(taskPath) {
      return [
        'progressMonitors?taskPathVec=',
        taskPath,
        '&includeFinishedTasks=true&excludeSubTasks=true'
      ].join('');
    }

    /**
     * Decorates the restore task with clone app information.
     *
     * @method   decorateCloneAppInfo
     * @param    {Object}   restoreTask   The restore task object.
     */
    function decorateCloneAppInfo(restoreTask) {
      var refreshes;
      var restoreParams = get(restoreTask.performRestoreTaskState,
        'restoreAppTaskState.restoreAppParams');
      var restoredApp = get(restoreParams, 'restoreAppObjectVec[0]');

      if (restoreParams && restoredApp) {
        restoreTask._cloneAppInfo = {
          _taskType: restoreParams.type,
          _sourceEntityId: restoredApp.appEntity.id,
        };
      }

      // Detect if clone refresh is performed.
      refreshes = restoreTask.performRefreshTaskStateVec;

      if (!isEmpty(refreshes)) {
        restoreTask._refreshInfo = {
          _tasks: refreshes,
          _latest: last(refreshes),
        };

        forEach(refreshes, decorateCloneRefreshTask);
      }
    }

    /**
     * Decorates a clone refresh task with convenience properties.
     *
     * @method   decorateCloneRefreshTask
     * @param    {Object}   task   The clone refresh task object.
     */
    function decorateCloneRefreshTask(task) {
      var restoreAppParams = task.restoreAppTaskState.restoreAppParams;

      var snapshotUsecs = get(restoreAppParams,
        'ownerRestoreInfo.ownerObject.startTimeUsecs');

      var pitSecs = get(restoreAppParams, 'restoreAppObjectVec[0]' +
        '.restoreParams.oracleRestoreParams.restoreTimeSecs');

      switch(task.base.status) {
        case ENUM_RESTORE_TASK_STATUS.kFinished:
          task._status = task.base.error ?
            RESTORE_TASK_UI_STATUS.kError :
            RESTORE_TASK_UI_STATUS.kSuccess;
          break;
        case ENUM_RESTORE_TASK_STATUS.kCancelled:
          task._status = RESTORE_TASK_UI_STATUS.kCanceled;
          break;
        default:
          task._status = RESTORE_TASK_UI_STATUS.kRunning;
      }

      task._uiStatus = getTaskStatusString(task._status);
      task._uiStatusClass = getTaskStatusClass(task._status);

      task._progressMonitorURL =
        getProgressMonitorUrl(task.progressMonitorTaskPath);

      task._snapshotUsecs = pitSecs ?
        DateTimeService.secsToUsecs(pitSecs) :
        snapshotUsecs;

      task._startTimeUsecs = task.base.startTimeUsecs;
      task._endTimeUsecs = task.base.endTimeUsecs || Date.clusterNow() * 1000;

      if (task._startTimeUsecs) {
        task._durationUsecs = task._endTimeUsecs - task._startTimeUsecs;
      }
    }
  }

})(angular);
