import { isArray } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Email Selector

;(function(angular, undefined) {

  'use strict';

  angular
    .module('C.emailSelector', [])
    .controller('EmailSelectorCtrl', emailSelectorCtrlFn)
    .component('emailSelector', {
      bindings: {},
      require: {
        ngModel: 'ngModel'
      },
      templateUrl:
        'app/global/c-email-selector/c-email-selector.html',
      controller: 'EmailSelectorCtrl'
    });

    /**
     * @ngdoc component
     * @name C.emailSelector:emailSelector
     * @function
     *
     * @description
     * Component for selecting email addresses.
     *
     * @example
     * <email-selector
         id="recipient-address-input"
         name="recipientAddressInput"
         ng-model="$ctrl.state.recipientAddresses">
       </email-selector>
     *
     */
    function emailSelectorCtrlFn(_, cUtils) {
      var $ctrl = this;

      assign($ctrl, {
        // Methods exposed to template.
        syncModel: syncModel,
        validateEmail: validateEmail,

        // Component life cycle methods.
        $onInit: $onInit,
      });

      /**
       * Component initialisation hook.
       *
       * @method   $oninit
       */
      function $onInit() {
        $ctrl.ngModel.$render = $ctrl.syncModel;

        // Default the tagging tokens to 'SPACE|,|/'
        $ctrl.taggingTokens = 'SPACE|,|/';
      }

      /**
       * Synchronize internal and the external model.
       *
       * @method   syncModel
       * @param    {string[]}   values   Specifies the array of Email addresses
       */
      function syncModel(values) {
        if (arguments.length) {
          $ctrl.ngModel.$setViewValue(
            isArray(values) && values.length ? values : null
          );
        } else {
          $ctrl.internalModel = $ctrl.ngModel.$viewValue;
        }
      }

      /**
       * Vaidates email and returns either boolean or the valid email if the
       * flag is true.
       *
       * @method   validateEmail
       * @param    {string}    email         Specifies Email Address
       * @return   {boolean}   Returns false if the string is an invalid email
       *                       and the string itself if valid email.
       */
      function validateEmail(email) {
        return cUtils.uiSelectEmailValidator(email);
      }
    }
})(angular);