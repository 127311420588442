import { uniqBy } from 'lodash-es';
// Controller: VMs by Protection

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsVmsByProtectionController',
      reportsVmsByProtectionControllerFn);

  function reportsVmsByProtectionControllerFn($rootScope, $scope, $state,
    $stateParams, ReportsService, cUtils, evalAJAX, ReportsUtil) {

    $scope.cUtils = cUtils;

    $scope.unprotectedVmsConfig =
      ReportsUtil.getReportConfig('kUnprotectedVMsReport')();

    $scope.downloadReport = downloadReport;
    $scope.getData = getData;

    /**
     * Tab Configuration
     * @type {Array}
     */
    $scope.cTabConfig = [{
      nameKey: 'unprotectedVms',
      value: 'unprotected',
    }, {
      nameKey: 'protectedVms',
      value: 'protected',
    }];

    $scope.displayProtectedType = $scope.cTabConfig[0].value;

    /**
     * activate controller
     */
    function activate() {
      getData();
    }

    /**
     * returns params object for api call
     *
     * @return    {object}               filter values to be passed to API
     */
    function getParams() {
      return ReportsService.extendWithTenantParams({});
    }

    /**
     * Get Data from API
     */
     function getData() {
      var params = getParams();

      $scope.dataReady = false;
      initModel();
      ReportsService.getVmsByProtection(params).then(
        function getDataSuccess(r) {

          // Remove duplicate entries.
          var uniqueVms = uniqBy(r.data, 'vmId');

          angular.forEach(uniqueVms, function parseVms(vm) {
            var vCenterId = vm.registeredSourceId;

            // Create a vCenter object if it does not already exist
            if (!$scope.vCenters[vCenterId]) {
              $scope.vCenters[vCenterId] = {
                protected: [],
                unprotected: [],
              };
            }

            if (vm.protected) {
              // Increment Protected Totals
              $scope.totals.protected = $scope.totals.protected + 1;
              // Push VM to protected array
              $scope.vCenters[vCenterId].protected.push(vm);
            }


            if (!vm.protected) {
              // Increment UnProtected Totals
              $scope.totals.unprotected = $scope.totals.unprotected + 1;
              // Increment UnProtected Bytes Total
              $scope.totals.unprotectedBytes =
                $scope.totals.unprotectedBytes + vm.vmSizeBytes;
              // Push VM to unprotected array
              $scope.vCenters[vCenterId].unprotected.push(vm);
            }

            // Increment total vms count
            $scope.totals.vms = $scope.totals.vms + 1;
          });

          $scope.dataReady = true;
        },
        evalAJAX.errorMessage
      ).finally(function finallyGotResponse() {
        $scope.dataReady = true;
      });
    }

    /**
     * initialize the model
     *
     * @method   initModel
     */
    function initModel() {
      $scope.dataReady = false;
      $scope.vCenters = {};
      $scope.vCentersTotals = {};
      $scope.totals = {
        vms: 0,
        protected: 0,
        unprotected: 0,
        unprotectedBytes: 0
      };
    }

    /**
     * requests a csv download via ReportsService
     *
     * @return {Void}
     */
    function downloadReport() {
      var params = getParams();
      params.outputFormat = 'csv';

      ReportsService.getVmsByProtection(params);
    }

    /**
     * On click handler for the tabs.
     *
     * @method   selectTab
     * @param    {Object}   tab  The selected tab config.
     */
    $scope.selectTab = function selectTab(tab) {
      $scope.displayProtectedType = tab.value;
    };

    activate();
  }
})(angular);
