import { noop } from 'lodash-es';
// Reports: Quotas Per View

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsQuotasController', reportsQuotasControllerFn);

  function reportsQuotasControllerFn($state, $q, $filter, ViewService,
    ReportsService, ReportsUtil, evalAJAX, SourceService,
    cReportsControlsUtil) {

    var quotasCtrl = this;

    // Hash of Principals being fetched. Used as a lookup so the API is only
    // fired once.
    var fetching = {};

    angular.extend(quotasCtrl, {
      filters: cReportsControlsUtil.filters,
      userQuotasConfig: ReportsUtil.getReportConfig('kUserQuotasReport')(),

      // Public Methods
      downloadUserQuotasReport: downloadUserQuotasReport,
      getPrincipal: getPrincipal,
      getUserQuotas: getUserQuotas,
      updateFiltersCb: updateFiltersCb,
      sortByUser: sortByUser,

      // List of Quotas & Usage for display in the report
      quotaList: [],

      // Hash of all principals fetched by this controller
      principals: {},
    });

    /**
     * Loads Controller Data
     *
     * If we are deep linking we need to load data based of query params in the
     * URL
     *
     * Calls API to populate data for Type Ahead
     *
     * @method     _activate
     */
    function _activate() {
      quotasCtrl.dataset = {};

      cReportsControlsUtil.getDefaultFilters({
        excludeUsersWithinAlertThreshold: true,
      });

      $q.all(quotasCtrl.userQuotasConfig.datasetPromiseObject).then(
        function getDataset(dataset) {
          quotasCtrl.dataset = dataset;
          quotasCtrl.dataset.principalsByDomain = {};

          /**
           * Convert unixUid to number.
           *
           * We can't simply coerce the value because +undefined is NaN. And
           * although NaN is considered a Number by JS, it is not valid in the
           * number form field.
           *   var unixUid = +$state.params.unixUid;
           *
           * And because zero (0) is a valid value, we can't simply use the
           * logical OR:
           *   var unixUid = +$state.params.unixUid || undefined;
           */
          var unixUid = angular.isDefined($state.params.unixUid) ?
            +$state.params.unixUid : undefined;

          // Put on scope any pre-filtered values.
          quotasCtrl.viewName = $state.params.viewName;
          quotasCtrl.viewId = $state.params.viewId;
          quotasCtrl.unixUid = unixUid;
          quotasCtrl.sid = $state.params.sid;
          quotasCtrl.excludeUsersWithinAlertThreshold = $state.params.excludeUsersWithinAlertThreshold;

          // Display Unix UID input if no Active Directories present or if a UID
          // is already filtered.
          quotasCtrl.userQuotasConfig.showUnixInput =
            !quotasCtrl.dataset.activeDirectories.length ||
            quotasCtrl.unixUid > -1;

          // Seed ui-select with all known SIDs.
          _addPrincipalsToHash(quotasCtrl.dataset.initialPrincipals);

          // Add to hash all the Local Principals on the Cluster.
          ViewService.getAllLocalPrincipals().then(_addPrincipalsToHash);

          // If any query params, then set the filters accordingly.
          if (quotasCtrl.viewName || quotasCtrl.viewId || quotasCtrl.sid ||
            quotasCtrl.unixUid > -1) {
            quotasCtrl.activeFilters = true;
            cReportsControlsUtil.updateFilters({
              typeAhead: {
                // The selectTypeaheadValue function of the c-reports-controls
                // is checking id.
                id: quotasCtrl.viewName,
                name: quotasCtrl.viewName
              },
              viewName: quotasCtrl.viewName,
              viewId: quotasCtrl.viewId,
              unixUid: quotasCtrl.unixUid,
              sid: quotasCtrl.sid,
              excludeUsersWithinAlertThreshold: quotasCtrl.excludeUsersWithinAlertThreshold,
            });

            getUserQuotas(cReportsControlsUtil.filters);
          }
        }, evalAJAX.errorMessage)
      .finally(function getDatasetFinally() {
        quotasCtrl.reportsControlsDataReady = true;
      });
    }

    /**
     * Returns params object for API Call
     *
     * @method     _getParams
     *
     * @param      {Object}  filters  filters obj from c-reports-controls
     * @return     {Object}  params object for API Call
     */
    function _getParams(filters) {
      return {
        viewName: filters.viewName,
        viewId: filters.viewId,
        includeUsage: true,
        unixUid: filters.unixUid,
        sid: filters.sid,
        excludeUsersWithinAlertThreshold: filters.excludeUsersWithinAlertThreshold,
        maxEntries: undefined,
        cookie: undefined,
      };
    }

    /**
     * Initiates CSV download.
     *
     * @method     downloadUserQuotasReport
     */
    function downloadUserQuotasReport() {
      var params = _getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ViewService.getUserQuotas(params);
    }

    /**
     * Refreshes data when applying new filters
     *
     * @method     getUserQuotas
     *
     * @param      {object}  filters  filters obj from c-reports-controls
     */
    function getUserQuotas(filters) {
      var requestParams = _getParams(filters);

      quotasCtrl.showReportPerUser = !requestParams.viewName;
      quotasCtrl.summaryReady = false;
      quotasCtrl.dataReady = false;
      quotasCtrl.quotaList.length = 0;

      // Get summary data
      ViewService.getQuotaUsageSummary(requestParams).then(
        function getQuotasSummarySuccess(summary) {
          quotasCtrl.summaryStats = summary.summaryForView ||
            summary.summaryForUser || {};
        },
        noop
      ).finally(function getQuotasSummaryFinally() {
        quotasCtrl.summaryReady = true;
      });

      // Get quotas and usage
      const userQuotaParams =
        {...requestParams,
          excludeUsersWithinAlertThreshold: !!filters.excludeUsersWithinAlertThreshold,
          topQuotas: (parseInt(filters.unixUid, 10) > -1 || !!filters.sid) ? undefined : 1000,
        };

      ViewService.getUserQuotas(userQuotaParams)
        .then(function getQuotasReportSuccess(quotas) {
          // Remap `quotaAndUsageInAllViews` to have same structure as
          // `usersQuotaAndUsage`. We do this here because we don't need this
          // transformation in any other context.
          if (quotas.quotaAndUsageInAllViews) {
            quotas.quotaAndUsageInAllViews.map(function(quota) {
              quota.quotaPolicy = quota.quota;
            });
          }

          quotasCtrl.quotaList = quotas.quotaAndUsageInAllViews ||
            quotas.usersQuotaAndUsage || [];

          _getQuotaPrincipals(quotas);

          // All quotas without a policy override should inherit from the View
          // for the purposes of this report.
          if (quotas.userQuotaSettings) {
            quotasCtrl.quotaList.forEach(function inheritQuotaPolicy(quota) {
              quota.quotaPolicy = quota.quotaPolicy ||
                angular.copy(quotas.userQuotaSettings.defaultUserQuotaPolicy || {});

              // Stub usageBytes and usagePercentage.
              quota.usageBytes = quota.usageBytes || 0;
              quota._usagePercentage = quota.usageBytes / quota.quotaPolicy.hardLimitBytes;
            });
          }
        },
        noop
      ).finally(function getQuotasReportFinally() {
        quotasCtrl.dataReady = true;
      });
    }

    /**
     * When new filters are set update the url for context
     *
     * This is how you update the browser URL without reloading the controller.
     *
     * Also we need to call new API query after filters are updated to get new
     * data based off updated filters.
     *
     * @param      {object}  filters  filters from c-reports-controls
     */
    function updateFiltersCb(filters) {
      quotasCtrl.activeFilters = true;

      // Transform value property name to match expected.
      filters.viewName = filters.typeAhead && filters.typeAhead.name;
      filters.viewId = filters.typeAhead && filters.typeAhead.viewId;

      // Put these on controller scope for broader access. Also, the filters
      // properties are strings so we convert empty strings to undefined` for
      // proper handling as query params. Also in the subsequent `if` block.
      quotasCtrl.viewName = filters.viewName = filters.viewName || undefined;
      quotasCtrl.viewId = filters.viewId = filters.viewId || undefined;

      // May only submit an SID or a UnixUID, so remove the other.
      if (quotasCtrl.userQuotasConfig.showUnixInput) {
        quotasCtrl.unixUid = filters.unixUid =
          parseInt(filters.unixUid, 10) > -1 ? filters.unixUid : undefined;
        quotasCtrl.sid = filters.sid = undefined;
      } else {
        quotasCtrl.sid = filters.sid = filters.sid || undefined;
        quotasCtrl.unixUid = filters.unixUid = undefined;
      }

      // Have to explicit test undefined here because UnixUID could be `0`.
      if (!filters.viewName && !filters.viewId && !filters.sid && filters.unixUid === undefined) {
        _resetReport();
        return;
      }

      $state.go('.', {
        viewName: filters.viewName,
        viewId: filters.viewId,
        sid: filters.sid,
        unixUid: filters.unixUid,
        excludeUsersWithinAlertThreshold: filters.excludeUsersWithinAlertThreshold,
      });

      getUserQuotas(filters);
    }

    /**
     * Clears the objects filter. This resets the scope values so the UI can go
     * back to its initial state.
     *
     * @method     _resetReport
     */
    function _resetReport() {
      quotasCtrl.quotaList.length = 0;
      quotasCtrl.activeFilters = false;

      $state.go('.', {
        viewName: undefined,
        sid: undefined,
        unixUid: undefined,
        excludeUsersWithinAlertThreshold: undefined,
      });
    }

    /**
     * Returns the Principal or sid/uid.
     *
     * @method     getPrincipal
     *
     * @param      {Object}  quota   The quota object
     * @return     {String}  The principal name or sid/uid
     */
    function getPrincipal(quota) {
      // Return the principal if found or return the sid.
      if (quota.sid) {
        return quotasCtrl.principals[quota.sid] || quota.sid;
      }

      return '';
    }

    /**
     * Adds Principals to both Principals hashes.
     *
     * @method     _addPrincipalsToHash
     *
     * @param      {Array}  principals  The list of principals
     */
    function _addPrincipalsToHash(principals) {
      principals.forEach(function loopPrincipals(principal) {
        // Stub the domain in the domain/users hash.
        quotasCtrl.dataset.principalsByDomain[principal.domain] =
          quotasCtrl.dataset.principalsByDomain[principal.domain] || {};

        // Insert the principal in its respective domain.
        quotasCtrl.dataset.principalsByDomain[principal.domain][principal.sid] =
          principal;

        // "Flat" hash for display-only when we don't know domain. We add to
        // this hash every Principal which are already associated with this
        // View.
        quotasCtrl.principals[principal.sid] = principal;
      });
    }

    /**
     * Pre-loads Principals with Overrides already in this View.
     *
     * @method     _getQuotaPrincipals
     *
     * @param      {Object}  quotas  The quotas response object
     */
    function _getQuotaPrincipals(quotas) {
      if (quotas.usersQuotaAndUsage) {
        const length = quotas.usersQuotaAndUsage.length;
        let quotaList = [];
        for (let i = 0; i < length; i += 100) {
          let tempQuotaList = quotas.usersQuotaAndUsage.slice(i, i + 100);
          ViewService.getQuotaPrincipals(tempQuotaList).then(function(quotas) {
            quotaList = quotaList.concat(quotas);
          }, evalAJAX.errorMessage
          ).finally(function getQuotaPrincipalsSuccess() {
            _addPrincipalsToHash(quotaList);
            quotasCtrl.principalsReady = true;
          });
        }
      } else {
        quotasCtrl.principalsReady = true;
      }
    }

    /**
     * st-sort function for the user column which is based on either one of two
     * possible properties.
     *
     * @param      {Object}  row     The row object
     * @return     {String}  The property value to sort by.
     */
    function sortByUser(row) {
      return row.unixUid ||
        $filter('principalName')(quotasCtrl.getPrincipal(row.sid));
    }

    _activate();
  }
})(angular);
