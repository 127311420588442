import { assign } from 'lodash-es';
// MODULE: Alerts Analytics Module
;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.alerts')
    .controller('alertsTabAnalyticsCtrl', alertsTabAnalyticsCtrlFn)
    .component('cAlertsAnalyticsComponent', {
      controller: 'alertsTabAnalyticsCtrl as $ctrl',
      template: require('raw-loader!./analytics.html').default,
    });

  function alertsTabAnalyticsCtrlFn(
    $translate, $scope, evalAJAX, DateTimeService, AlertService, CHART_COLORS) {

    var $ctrl = this;

    var alertCategoriesEnum = {};

    // Recent Alerts/Resolutions chart data
    $scope.recentAlertsChart = {
      chartType: 'stacked',
      loading: true,
      series: [{
        name: $translate.instant('openAlerts'),
        data: [],
      }, {
        name: $translate.instant('resolvedAlerts'),
        data: [],
      }],
      xAxis: {
        categories: [],
      },
      chart: {
        height: 275,
      },
      yAxis: {
        allowDecimals: false,
      },
      colors: [CHART_COLORS.red, CHART_COLORS.green],
    };

    // Open Alerts chart data
    $scope.openAlertsChart = {
      chartType: 'donut',
      loading: true,
      titleText: 'totalAlerts',
      chart: {
        height: 275,
      },
      series: [{
        type: 'pie',
        name: 'alerts',
        data: [],
      }],
      colors: CHART_COLORS.neutralPair4.concat(
        CHART_COLORS.neutralPair2,
        CHART_COLORS.neutralPair3,
        CHART_COLORS.neutralPair1
      )
    };

    assign($ctrl, {
      // Life cycle method
      $onInit: $onInit,
    });

    function $onInit() {
      AlertService.getAlertCategories(true).then(
        function fetchedAlertCategories(categoriesEnum) {
          alertCategoriesEnum = categoriesEnum;
        }
      ).finally(function fetchedDependencies() {
        // call chart building functions
        buildRecentChart();
        buildOpenChart();
      });
    }

    /**
     * queries the API and builds the data for the
     * recent alerts/resolution chart
     */
    function buildRecentChart() {
      // get alerts and resolutions for the past week.
      // and build them into two arrays for a stacked chart
      // plus a list of categories (days of the week)

      var weekAgo =
        DateTimeService.beginningOfDay(DateTimeService.getWeekAgo());
      // add a day to the weekAgo date, as we don't want a duplicate day of the week
      weekAgo.setDate(weekAgo.getDate() + 1);
      var today = DateTimeService.endOfDay(new Date());

      // initialize our counters, the index represents the day of the week
      // and the value represents the number of alerts/resolutions
      var chartAlerts = { 0:0, 1:0, 2:0, 3:0, 4:0, 5:0, 6:0 };
      var chartResolutions = { 0:0, 1:0, 2:0, 3:0, 4:0, 5:0, 6:0 };

      var requestParams = {
        maxAlerts: 1000,
        startDateUsecs: DateTimeService.dateToUsecs(weekAgo),
        endDateUsecs: DateTimeService.dateToUsecs(today)
      };

      $scope.recentAlertsChart.loading = true;

      AlertService.getAlerts(requestParams).then(function(result) {
        if (evalAJAX.success(result)) {
          var alertDay;

          // start our loop/chart seven days ago
          var loopDay = weekAgo.getDay();
          var loopDate = weekAgo;
          var dateFormat = DateTimeService.getShortMonthDayFormat();

          // temp arrays to build our chart data
          var alertsData = [];
          var resolutionsData = [];
          var categories = [];

          // loop through API results, incrementing alerts count for the
          // appropriate day of the week
          angular.forEach(result.data, function(alert, index) {
            alertDay =
              DateTimeService.usecsToDate(alert.latestTimestampUsecs).getDay();

            // if the alert has been resolved, increment the resolution count
            // for the appropriate day of the week.
            if (alert.hasOwnProperty('resolutionDetails')) {
              chartResolutions[alertDay]++;
            } else {
              chartAlerts[alertDay]++;
            }
          });

          // loop through our hashtable objects and build our
          // chart data into temporary arrays
          for (var index = 0; index < 7; index++) {
            categories.push(DateTimeService.formatDate(loopDate, dateFormat));
            alertsData.push(chartAlerts[loopDay]);
            resolutionsData.push(chartResolutions[loopDay]);

            // if we're on saturday (6), start back on sunday (0)
            // otherwise move to the next day of the week
            loopDay = loopDay === 6 ? 0 : loopDay + 1;

            // increment date
            loopDate.setDate(loopDate.getDate() + 1);
          }

          // update out chart object with the derived data
          $scope.recentAlertsChart.series[0].data = alertsData;
          $scope.recentAlertsChart.series[1].data = resolutionsData;
          $scope.recentAlertsChart.xAxis.categories = categories;
        }

        $scope.recentAlertsChart.loading = false;
      });
    }

    //queries the API and builds the open alerts chart data
    function buildOpenChart() {
      // get all open alerts (regardless of date)
      // and hashtable them based on category
      // then push them into our chart data and
      // category objects

      $scope.totalAlerts = 0;

      $scope.openAlertsChart.loading = true;

      AlertService.getAlerts({ alertStateList: ['kOpen'], maxAlerts: 1000 }).then(
        function getAlertsSuccess(result) {

        // this object will be used to hashtable the various categories
        // and count their occurences
        var chartAlerts = {};
        var sortedCategoryKeys = [];

        var newChartData = [];
        var currentCategoryIndex = 0;

        // if there are more categories than this max,
        // they will be combined into an "others" category
        var maxChartCategories = 5;

        // loop through the API results, incrementing category count
        // or initializing category count if it doesn't already exist
        if (result.data && result.data.length) {
          for (var index = 0; index < result.data.length; index++) {
            if (!chartAlerts[result.data[index].alertCategory]) {
              chartAlerts[result.data[index].alertCategory] = 1;
            } else {
              chartAlerts[result.data[index].alertCategory]++;
            }
          }
        }

        // create an array of categories in the chartAlerts hashtable
        for (var key in chartAlerts) {
          sortedCategoryKeys.push(key);
        }

        // sort it based on the number of alerts in the category in descending
        // order
        sortedCategoryKeys.sort(
          function categorySort(a, b) {
            return chartAlerts[a] < chartAlerts[b];
          }
        );

        sortedCategoryKeys.forEach(
          function chartAlertsLoop(category, index) {

            var count = chartAlerts[category];
            $scope.totalAlerts = $scope.totalAlerts + count;

            switch(true) {
              // only create an "others" category if there are at least two
              // more categories beyond the maximum display count
              case (index + 1 === maxChartCategories &&
                sortedCategoryKeys.length > maxChartCategories):
                newChartData.push([
                  $translate.instant('other'),
                  count
                ]);
                break;
              case index + 1 > maxChartCategories:
                newChartData[maxChartCategories - 1][1] =
                  newChartData[maxChartCategories - 1][1] + count;
                break;
              default:
                newChartData.push([alertCategoriesEnum[category], count]);
            }
          }
        );

        // update the chart data with constructed array
        $scope.openAlertsChart.series[0].data = newChartData;
      },
        evalAJAX.errorMessage
      ).finally(
        function getAlertsFinally() {
          $scope.openAlertsChart.loading = false;
        }
      );
    }
  }
})(angular);
