import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SnackBarService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { CustomizationService, HeliosUserPreferences } from 'src/app/core/services';
import { AjaxHandlerService, AutoDestroyable } from '../constants';

/**
 * Pin button to add/remove report in Reports card of landing page.
 */
@Component({
  selector: 'coh-pin-report-button',
  styleUrls: ['./pin-report-button.component.scss'],
  templateUrl: './pin-report-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PinReportButtonComponent extends AutoDestroyable implements OnInit {
  /**
   * Report ID.
   */
  @Input() reportId: string;

  /**
   * This will be a remove button if true.
   */
  isRemove: boolean;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private customizationService: CustomizationService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    // Use observable so that the button will be updated after report is added/removed.
    this.customizationService.userCustomizations$.pipe(
      this.untilDestroy(),
    ).subscribe(
      (userCustomizations) => {
        const displayedReports = userCustomizations?.heliosPreferences?.landingPageConfig?.reports || [];

        this.isRemove = displayedReports?.includes(this.reportId) || false;
      }
    );
  }

  /**
   * Add/Remove report to landing page.
   */
  toggle() {
    const preferences = this.customizationService.preferences || {} as HeliosUserPreferences;

    if (!preferences.landingPageConfig) {
      preferences.landingPageConfig = { reports: [] };
    } else if (!preferences.landingPageConfig.reports) {
      preferences.landingPageConfig.reports = [];
    }
    if (this.isRemove) {
      preferences.landingPageConfig.reports =
        preferences.landingPageConfig.reports.filter(report => report !== this.reportId);
    } else {
      preferences.landingPageConfig.reports.push(this.reportId);
    }
    this.customizationService.savePreferences(preferences).pipe(
      this.untilDestroy(),
      this.ajaxHandlerService.catchAndHandleError(),
    ).subscribe(
      () => {
        this.snackBarService.open(this.translateService.instant('changesHaveBeenSaved'), 'success');
      },
    );
  }
}
