import { assign } from 'lodash-es';
// Module: Antivirus

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.antivirus', ['C.viewAvSettings', 'C.icapServers'])
    .config(configFn)
    .component('antivirus', {
      templateUrl: 'app/antivirus/antivirus.html',
      controller: antivirusCtrlFn,
    });

  function configFn($stateProvider) {
    var antivirusAccess =
      '!$root.isTenantUser() && !$root.isHeliosTenantUser() && FEATURE_FLAGS.antivirusEnabled';
    var viewAccess = 'STORAGE_VIEW && ' + antivirusAccess;
    var modifyAccess = 'STORAGE_MODIFY && ' + antivirusAccess;

    $stateProvider
      .state('antivirus', {
        url: '^/antivirus',
        help: 'admin_cluster_antivirus',
        title: 'Antivirus',
        canAccess: viewAccess,
        allClustersSupport: false,
        component: 'antivirus',
        redirectTo: 'antivirus.threats',
      })
      .state('antivirus.threats', {
        url: '/threats/?' + [
          '{path}',
          '{viewNames}',
          '{includeQuarantinedFiles}',
          '{includeUnquarantinedFiles}'
        ].join('&'),
        help: 'admin_cluster_antivirus',
        title: 'Virus Threats',
        canAccess: viewAccess,
        parentState: 'antivirus',
        params: {
          path: { type: 'string' },
          viewNames: { type: 'string' },
          includeQuarantinedFiles: { type: 'string' },
          includeUnquarantinedFiles: { type: 'string' },
        },
        allClustersSupport: false,
        views: {
          'tab-view@antivirus': 'threats',
        }
      })
      .state('antivirus.secured-views', {
        url: '/secured-views',
        help: 'admin_cluster_antivirus',
        title: 'Secured Views',
        canAccess: viewAccess,
        parentState: 'antivirus.threats',
        allClustersSupport: false,
        views: {
          'tab-view@antivirus': 'securedViews',
        }
      })
      .state('add-secured-view', {
        url: '/antivirus/secured-view/add',
        help: 'admin_cluster_antivirus',
        title: 'Enable AV on a View',
        canAccess: modifyAccess,
        allClustersSupport: false,
        parentState: 'antivirus.secured-views',
        controller: 'AddSecuredViewCtrl as $ctrl',
        templateUrl: 'app/antivirus/secured-views/add/add.html',
        resolve: {
          // mocking modal instance while rendering in page view
          $uibModalInstance: angular.noop,
        },
      })
      .state('edit-secured-view', {
        url: '/antivirus/secured-view/edit/{id}',
        help: 'admin_cluster_antivirus',
        title: 'Enable AV on a View',
        canAccess: modifyAccess,
        parentState: 'antivirus.secured-views',
        params: {
          id: { type: 'string' },
        },
        allClustersSupport: false,
        controller: 'AddSecuredViewCtrl as $ctrl',
        templateUrl: 'app/antivirus/secured-views/add/add.html',
        resolve: {
          // mocking modal instance while rendering in page view
          $uibModalInstance: angular.noop,
        },
      })
      .state('antivirus.providers', {
        url: '/providers',
        help: 'admin_cluster_antivirus',
        title: 'Antivirus Providers',
        canAccess: viewAccess,
        parentState: 'antivirus.threats',
        allClustersSupport: false,
        views: {
          'tab-view@antivirus': 'providers',
        }
      })
      .state('add-av-provider', {
        url: '/antivirus/providers/add',
        help: 'admin_cluster_antivirus',
        title: 'Add External Provider',
        canAccess: modifyAccess,
        parentState: 'antivirus.providers',
        allClustersSupport: false,
        controller: 'AddAvProviderCtrl as $ctrl',
        templateUrl: 'app/antivirus/providers/add/add.html',
      })
      .state('edit-av-provider', {
        url: '/antivirus/providers/edit/{id}',
        help: 'admin_cluster_antivirus',
        title: 'Edit External Provider',
        canAccess: modifyAccess,
        parentState: 'antivirus.providers',
        params: {
          id: { type: 'string' },
        },
        allClustersSupport: false,
        controller: 'AddAvProviderCtrl as $ctrl',
        templateUrl: 'app/antivirus/providers/add/add.html',
      })
      .state('view-av-provider', {
        url: '/antivirus/providers/view/{id}',
        help: 'admin_cluster_antivirus',
        title: 'View External Provider',
        canAccess: viewAccess,
        parentState: 'antivirus.providers',
        params: {
          id: { type: 'string' },
        },
        allClustersSupport: false,
        controller: 'ViewAvProviderCtrl as $ctrl',
        templateUrl: 'app/antivirus/providers/view/view.html',
      });
  }

  function antivirusCtrlFn(_, $state, $q, evalAJAX, AntivirusService,
    ClusterService) {
    var $ctrl = this;
    var tabConfig = [
      {
        headingKey: 'threats',
        route: 'antivirus.threats',
        hidden: ClusterService.clusterInfo._allClusters
      },
      {
        headingKey: 'securedViews',
        route: 'antivirus.secured-views',
        hidden: ClusterService.clusterInfo._allClusters
      },
      {
        headingKey: 'providers',
        route: 'antivirus.providers',
        hidden: ClusterService.clusterInfo._allClusters
      },
    ];

    assign($ctrl, {
      $onInit: $onInit,
      $state: $state,
      tabConfig: tabConfig,
      providersConfigured: false,

      addSecuredView: addSecuredView,
    });

    /**
     * Initializes antivirus.
     *
     * @method   $onInit
     */
    function $onInit() {
      _checkProviders();
    }

    /**
     * Check if provider is configured or not.
     *
     * @method   _checkProviders
     * @returns  {boolean}  True if at least one Provider configured.
     */
    function _checkProviders() {
      AntivirusService.getAvProviderList().then(
        function gotProviders(providers) {
          if (providers.length) {
            $ctrl.providersConfigured = true;
          }
        }
      );
    }

    /**
     * Opens modal to enable AV on a selected View.
     *
     * @method     addSecuredView
     */
    function addSecuredView() {
      AntivirusService.addSecuredView().then(
        function addedSecuredView() {
          $state.reload();
        }
      );
    }
  }

})(angular);
