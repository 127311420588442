import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LineChartComponent } from '@cohesity/helix';
import { Options, SeriesLineOptions } from 'highcharts';
import { clamp, debounce } from 'lodash-es';
import { ObservableInput } from 'ngx-observable-input';
import { concat, interval, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { FortknoxStorageClass } from '../../../constants';

/**
 * Data used to populate chart and subscription info.
 */
export interface StorageClassUsage {
  series?: SeriesLineOptions[];
  options?: Options;
  subscriptions?: {
    label: string;
    storageClass?: FortknoxStorageClass;
    totalBytes: number;
    usedBytes: number;
  }[];
}

/**
 * @description
 *
 * Fortknox dashboard Data Stored card storage class line chart and usage info.
 */
@Component({
  selector: 'dg-sc-rpaas-storage-class-usage',
  templateUrl: './rpaas-storage-class-usage.component.html',
  styleUrls: ['./rpaas-storage-class-usage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RpaasStorageClassUsageComponent implements AfterViewInit, OnInit {
  /**
   * A reference to the line chart
   */
  @ViewChild(LineChartComponent) private lineChart: LineChartComponent;

  /**
   * Storage usage information
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('data') data$: Observable<StorageClassUsage>;

  /**
   * Color theme
   */
  @Input() color: 'warm' | 'cold' = 'warm';

  /**
   * Sets warm theme to host element
   */
  @HostBinding('class.warm-theme') get warmTheme() {
    return this.color === 'warm';
  }

  /**
   * Sets cold theme to host element
   */
  @HostBinding('class.cold-theme') get coldTheme() {
    return this.color === 'cold';
  }

  /**
   * Notify parent to visit External Targets report page.
   */
  @Output() readonly gotoReport = new EventEmitter<null>();

  /**
   * Resize the chart after the view initializes or the window resizes
   */
  @HostListener('window:resize')
  readonly resizeChart = debounce(() => {
    // This uses a timeout because it can take a moment for the dom to stabilize and if we run this too soon,
    // highcharts will calculate the size of the chart incorrectly.
    setTimeout(() => this.lineChart?.chart?.ref?.reflow(), 100);
  }, 100);

  /**
   * Chart data from `data$` input
   */
  chartData$: Observable<StorageClassUsage>;

  ngAfterViewInit() {
    this.resizeChart();
  }

  ngOnInit() {
    // set initial value to null to reinitialize chart inside of DOM
    this.chartData$ = this.data$.pipe(switchMap(data =>
      concat(of(null), interval(0).pipe(take(1), map(() => data)))));
  }

  /**
   * Returns percentage of used bytes with minimum value to always show progress bar color.
   *
   * @param used Used bytes
   * @param total Total bytes
   * @returns Percentage of used bytes
   */
  getUsageBytes(used: number, total: number): number {
    if (total === 0) {
      return 0;
    }

    // min value is greater than 0 to display progress bar color
    return clamp((used / total) * 100, 10, 100);
  }
}
