import { Recipe } from '@cohesity/api/stark';
import { ConfigExportRun, ConfigExportRunStatus } from '@cohesity/api/import-export';
import { ClusterInfo, SPClusterInfo, UpgradeInfo } from '@cohesity/api/v2';
import { Percentage } from '@cohesity/helix';
import { decorateInterface } from '@cohesity/utils';
import { HasClusterIdentifier } from 'src/app/shared';
import { healthIconMap, upgradeStatusIconMap, CLUSTER_USAGE_WARN_THRESHOLD } from '../software-upgrades.constant';
import { ClusterHealth } from './misc.model';
import { UpdateType } from './software-upgrades-dialog-params.model';

/**
 * This class extends the response schema for GET cluster upgrade detail
 * and augments the schema with information required for rendering purposes.
 */
export class ClusterUpgradeInfo
  extends decorateInterface<ClusterInfo & SPClusterInfo>()
  implements HasClusterIdentifier {
  /**
   * Number of gflag recipes associated
   * with this cluster
   */
  private _gFlagRecipes?: Recipe[];

  /**
   * available storage in percentage
   */
  readonly availableStorageInPerc?: number | null;

  /**
   * Storage usage warn level
   */
  readonly usageWarnLevel: Exclude<Percentage['status'], 'info-1' | 'info-2' | 'info-3' | 'info-4'> = 'success';

  /**
   * Specifies the health of the cluster.
   * This field was added since 'Warning' attribute
   * could not be added to the original 'health' variable
   * that is in the parent class i.e. ClusterInfo
   */
  clusterHealth?: ClusterHealth;

  /**
   * icon representing cluster health
   */
  healthIcon?: string | null;

  /**
   * Number of Views belongs to the cluster.
   */
  numOfViews?: number;

  /**
   * Total logical data ingested for all Views on the cluster
   */
  clusterLogicalOfAllViews?: number;

  /**
   * 30 day growth rate of total logical data for all Views on the cluster.
   * Displayed as a percentage with one decimal place.
   */
  thirtyDaysGrowthRate?: number;

  /**
   * Total data reduction of logical data for all Views on the cluster.
   * Displayed as a multiplier with one decimal place.
   */
  dataReduction?: number;

  /**
   * Last Config Export Run info.
   */
  lastRun?: ConfigExportRun;

  /**
   * Config backup Last Run status.
   */
  lastRunStatus?: ConfigExportRunStatus;

  /**
   * Config backup Last Run status string key.
   */
  lastRunStatusKey?: string;

  /**
   * Config backup Last Run end time in usecs.
   */
  lastRunUsecs?: number;

  /**
   * Backup status icon.
   */
  backupStatus: 'backedup' | 'notbackedup';

  /**
   * icon representing upgrade status
   */
  readonly upgradeStatusIcon: string | null;

  /**
   * used capacity of cluster in GB. round off upto
   * 2 decimal places
   */
  readonly usedCapacityInGBs?: string;

  /**
   * total capacity of cluster in GB. round off upto
   * 2 decimal places
   */
  readonly totalCapacityInGBs?: string;

  /**
   * Holds the information regarding cluster upgrade. Keeping it
   * private to not allow modifications from outer environment.
   * Use corresponding getter to fetch this information.
   */
  private _upgradeDetails: UpgradeInfo;

  /**
   * Bool specifying if cluster can support authHeaders for upgrade.
   */
  authSupportForPkgDownloads?: boolean;

  /**
   * An array of update types available for the cluster.
   */
  availableUpdateTypes: UpdateType[];

  /**
   * Getter for fetching upgrade info of this cluster.
   */
  get upgradeDetails(): UpgradeInfo {
    return this._upgradeDetails;
  }

  /**
   * Setter for upgrade info for this cluster
   */
  set upgradeDetails(upgradeDetails: UpgradeInfo) {
    this._upgradeDetails = upgradeDetails;
  }

  /**
   * Get gFlagRecipes in the cluster
   */
  get gflagRecipes(): Recipe[] {
    return this._gFlagRecipes;
  }

  /**
   * Set number of gflagRecipes in the cluster
   */
  set gflagRecipes(recipes: Recipe[]) {
    this._gFlagRecipes = recipes;
  }

  /**
   * Helper to list all recipe names
   */
  get recipeNames(): string {
    return this._gFlagRecipes.map(recipe => recipe.name).join(', ');
  }

  constructor(clusterInfo: ClusterInfo, upgradeDetails?: UpgradeInfo) {
    super(clusterInfo);

    this.currentVersion = clusterInfo.currentVersion || '';
    this.usedCapacityInGBs = this.removeRedundantDecimals(Number(this.usedCapacity / 1_000_000_000).toFixed(2));
    this.totalCapacityInGBs = this.removeRedundantDecimals(Number(this.totalCapacity / 1_000_000_000).toFixed(2));
    this.availableStorageInPerc = this.calculateAvailableStorage();
    this.authSupportForPkgDownloads = clusterInfo.authSupportForPkgDownloads ?? false;

    const usageInPerc = 100 - this.availableStorageInPerc;
    this.usageWarnLevel = usageInPerc < CLUSTER_USAGE_WARN_THRESHOLD ? 'success' : 'critical';

    this.healthIcon = healthIconMap[this.health];
    this.upgradeStatusIcon = upgradeStatusIconMap[this.status];
    this._upgradeDetails = upgradeDetails;
    this.gflagRecipes = [];
  }

  /**
   * Utility to calculate available storage in percentage
   *
   * @returns a valid number value indicating available
   * storage in percentage or null indicating a failed operation
   */
  private calculateAvailableStorage(): number | null {
    const nTotalCapacity = Number(this.totalCapacityInGBs);
    const nUsedCapacity = Number(this.usedCapacityInGBs);

    if (Number.isNaN(nTotalCapacity) || Number.isNaN(nUsedCapacity)) {
      return null;
    }

    const availCapacity = nTotalCapacity - nUsedCapacity;

    if (nTotalCapacity !== 0) {
      const availInPerc = Number((availCapacity / nTotalCapacity) * 100).toFixed(2);
      // trim "XX.00" to "XX" to make it more readable
      return Number(this.removeRedundantDecimals(availInPerc));
    }

    return null;
  }

  /**
   * This utility remove extra zeros at the end of decimal
   * number which are passed in as string.
   *
   * @param numberAsString decimal number given in string format.
   * @returns string with redundant 0s removed
   */
  private removeRedundantDecimals(numberAsString: string): string {
    if (numberAsString?.endsWith('00')) {
      return numberAsString.substring(0, numberAsString.indexOf('.'));
    }

    return numberAsString;
  }
}
