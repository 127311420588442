import { Injectable } from '@angular/core';
import { McmClusterServiceApi } from '@cohesity/api/private';
import { SearchServiceApi } from '@cohesity/api/v2';
import { uniqBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterFieldOption, IFieldFilterService } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ClusterFilterService implements IFieldFilterService {
  constructor(
    private mcmClustersApi: McmClusterServiceApi,
  ) { }

  /**
   * Cluster filter value generator. This queries all the available clusters and generates a list of clusters that can
   * be shown in the UI for user selection.
   *
   * @returns observable with filter options
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    return this.mcmClustersApi.getUpgradeInfo().pipe(
      map((response) => response.upgradeInfo?.map(cluster => ({
        key: `${cluster.clusterId}:${cluster.clusterIncarnationId}`,
        labelKey: cluster.clusterName,
      })) || []),
      map(clusters => uniqBy(clusters, (cluster) => cluster.key)),
      map((clusters) => {
        clusters.sort((a, b) => a.labelKey.localeCompare(b.labelKey));
        return clusters;
      }),
    );
  }

  /**
   * consolidated cluster filters into a format that API understands
   *
   * @param selectedValues currently applied cluster filters
   * @returns cluster filter that API understands
   */
  transformFilterValues(selectedValues: string[]): SearchServiceApi.SearchObjectsParams {
    return {
      clusterIdentifiers: selectedValues.length ? [selectedValues.join()] : []
    };
  }
}
