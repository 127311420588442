import { keys } from 'lodash-es';
import { intersection } from 'lodash-es';
import { filter } from 'lodash-es';
import { get } from 'lodash-es';
// Service: Job
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.jobService', ['C.jobService.Formatter', 'C.policyService'])
    .service('JobService', JobServiceFn);

  function JobServiceFn($rootScope, $http, $q, $uibModal, API,
    ENUM_DAY_COUNT_IN_MONTH, ENUM_DAY, cUtils, SlideModalService,
    DateTimeService, PolicyService, ENV_GROUPS, JobActionFormatter,
    JobServiceFormatter, BACKUP_PARAMS_ENUM, FEATURE_FLAGS,
    ENV_TYPE_CONVERSION, NgPassthroughOptionsService, NgIrisContextService, _) {

    var jobSvc = {
      activateJob: activateJob,
      applyPolicy: applyPolicy,
      createJob: createJob,
      deactivateJob: deactivateJob,
      deleteJob: deleteJob,
      editBackupParams: editBackupParams,
      editJobRun: editJobRun,
      failoverJob: failoverJob,
      getBackupPolicyTime: getBackupPolicyTime,
      getEndString: getEndString,
      getErrorMsg: getErrorMsg,
      getIndexableEnvTypes: getIndexableEnvTypes,
      getJob: getJob,
      getJobs: getJobs,
      getLeafCountByEnvType: getLeafCountByEnvType,
      getScheduleKeysToScriptMapping: getScheduleKeysToScriptMapping,
      getRepeatString: getRepeatString,
      getScriptKeys: getScriptKeys,
      getStatus: getStatus,
      isEnvTypeIndexable: isEnvTypeIndexable,
      isJobTypeWithoutEntities: isJobTypeWithoutEntities,
      jobsHash: {},
      pauseOrResumeJob: pauseOrResumeJob,
      removeFromExistingJob: removeFromExistingJob,
      startJob: startJob,
      transformDBJob: transformDBJob,
      transformJob: transformJob,
      updateJob: updateJob,
      stubEnvBackupParams: stubEnvBackupParams,
      resumeSelectedJobs: resumeSelectedJobs,
      pauseSelectedJobs: pauseSelectedJobs,
    };

    var scheduleKeys = PolicyService.getScheduleKeys();

    var scheduleKeysToScriptMapping = {
      incrementalSchedulingPolicy: 'backupScript',
      logSchedulingPolicy: 'logBackupScript',
      fullSchedulingPolicy: 'fullBackupScript',
    };

    var scriptKeys = [
      'backupScript',
      'logBackupScript',
      'fullBackupScript',
    ];


    // Cached value, to be populated on first call to get getIndexableEnvTypes()
    var indexableEnvTypes;

    /**
     * submit an API request to create a new job
     *
     * @param      {Object}  jobConfig  job information as create in job flow
     * @return     {Object}  promise to resolve request
     */
    function createJob(jobConfig) {

      // Create endpoint expects an additional level in the object
      // compared to the updateJob endpoint. Adding that level here
      // so the data structure passed to the two functions can match.
      jobConfig.backupJob = {
        backupJob: untransformJob(jobConfig.backupJob)
      };

      return $http({
        method: 'post',
        url: API.private('backupjobs'),
        data: jobConfig,
        headers: NgPassthroughOptionsService.requestHeaders,
      });
    }

    /**
     * call the API to update a job's settings
     *
     * @param      {Object}   jobConfig  object of the job's settings
     * @return     {Object}  a promise to resolve the request
     */
    function updateJob(jobConfig) {
      jobConfig.backupJob = untransformJob(jobConfig.backupJob);

      return $http({
        method: 'put',
        url: API.private('backupjobs', jobConfig.backupJob.jobId),
        data: jobConfig,
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(
        function updateJobSuccess(response) {
          var updatedJob;

          if (response.data && response.data.backupJob) {
            updatedJob = transformJob(response.data.backupJob);
            jobSvc.jobsHash[updatedJob.jobId] = updatedJob;
          }
          return updatedJob;
        }
      );
    }

    /**
     * call the API to update the Job state
     *
     * @param  {object} jobConfig object that contains job details && id
     * @return {Object}          resolves API request which will update UI
     */
    function pauseOrResumeJob(jobConfig) {
      return $http({
        method: 'post',
        url: API.public('protectionJobState', jobConfig.backupJob.jobId),
        data: { pause: jobConfig.backupJob.isPaused },
        headers: NgPassthroughOptionsService.requestHeaders,
      });
    }

    /**
     * submit a request to the API to delete a particular job
     *
     * @param  {Integer} id   of the job to be delete
     * @param  {Object}  data options for delete
     * @return {Object}      promise to resolve the request
     */
    function deleteJob(id, data) {
      return $http({
        method: 'delete',
        url: API.private('backupjobs', id),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders,
      });
    }

    /**
     * Activate a specific job
     *
     * @param  {Integer} id of the job to request
     * @data   {Object}  parentEntityId
     * @return {Object}  http response
     */
    function activateJob(id, data) {
      return $http({
        method: 'post',
        url: API.private('activateBackupJob', id),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders,
      });
    }

    /**
     * Deactivate a specific job
     *
     * @param  {Integer} id of the job to request
     * @param  {Object} parameters of this post
     * @return {Object}  http response
     */
    function deactivateJob(id, data) {
      return $http({
        method: 'post',
        data: data,
        url: API.private('deactivateBackupJob', id),
        headers: NgPassthroughOptionsService.requestHeaders,
      });
    }

    /**
     * calls the API to get all jobs or a subset of jobs based on provided
     * params
     *
     * @params {Object}  [params={}] filter parameters{
     *   ids: get jobs details for provided job Ids.
     * }
     * @return {Object} a promise to resolve with API response, with possible
     *                  transformations
     */
    function getJobs(params) {
      params = params || {};
      cUtils.selfOrDefault(params,
        'allUnderHierarchy', !!get(params, 'ids.length'));
      return $http({
        method: 'get',
        url: API.private('backupjobs'),
        params: params,
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(
        function getJobsSuccess(response) {
          var jobs = [];

          if (response.data && response.data.length) {
            jobs = response.data.map(
              function jobMapFn(job) {
                // Add jobs to jobsHash
                jobSvc.jobsHash[job.backupJob.jobId] =
                  transformJob(job.backupJob);

                return job.backupJob;
              }
            );
          }

          return jobs;
        }
      );
    }

    /**
     * get a specific job from the API
     *
     * @param  {Integer} id of the job to request
     * @return {Object}    to resolve API request
     *                      resolves with job object (or undefined if job not found)
     *                      rejects with raw server response
     */
    function getJob(id, params) {
      return $http({
        method: 'get',
        url: API.private('backupjobs', id),
        params: params,
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(
        function getJobSuccess(response) {
          var job;

          if (response.data && response.data.length) {
            job = response.data[0].backupJob;
            jobSvc.jobsHash[job.jobId] = job;

            return transformJob(job);
          }
        }
      );
    }

    /**
     * Starts a job.
     *
     * @param    {Object}    backupNowResult    object containing selected modal
     *                                          data
     * @param    {Number}    id                 id of the job to run
     * @return   {Object}                       promise to resolve the request
     */
    function startJob(backupNowResult, id) {
      return $http({
        method: 'post',
        url: API.public('protectionJobs/run', id),
        data: JobActionFormatter.backupNowFormatter(backupNowResult),
        headers: NgPassthroughOptionsService.requestHeaders,
      });
    }

    /**
     * Edits a job run.
     *
     * @param    {Object}   editRunResult   object containing selected modal
     *                                      data
     * @return   {Object}   promise to resolve the request
     */
    function editJobRun(editRunResult) {
      return $http({
        method: 'put',
        url: API.public('protectionRuns'),
        data: JobActionFormatter.editRunFormatter(editRunResult),
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(function successEditJobRun(response) {
        return response.data;
      });
    }

    /**
     * getter method for accessing a list of scriptKeys which can be found in
     * Remote Adapter jobs
     *
     * @method   getScriptKeys
     * @return   {Array}   list of script keys
     */
    function getScriptKeys() {
      // return a copy so requesting code can't change original array
      return angular.copy(scriptKeys);
    }

    /**
     * getter method for accessing a hashmap of scheduleKeys to scriptKeys
     *
     * @method     getScheduleKeysToScriptMapping
     * @return     {Array}  a hashmap defining the relationship between
     *                      between scheduleKeys and scriptKeys
     */
    function getScheduleKeysToScriptMapping() {
      // return a copy so requesting code can't change original object
      return angular.copy(scheduleKeysToScriptMapping);
    }

    /**
     * applies a provided policy to the provided job and returns the updated
     * job. In addition to returning the updated job, it is also updated
     * directly, as its passed in by reference. This function provides no API
     * interaction, and only preps the object.
     *
     * @param      {object}  job     to be updated with the provided policy
     * @param      {object}  policy  to be applied to provided job
     * @return     {object}  job updated with provided policy
     */
    function applyPolicy(job, policy) {

      job.policyId = policy.id;
      job.policyName = policy.name;

      // if this is a Remote Adapter/Puppeteer Job, ensure script object is
      // in sync with the applied Policy
      if (job.type === 5) {
        job = updateScriptSettings(job, policy);
      }

      return job;
    }

    /**
     * ensures that the Job's schedules and preScript settings are in sync.
     *
     * @method     updateScriptSettings
     *
     * @param      {object}  job     to update
     * @param      {object}  policy  to update job based upon
     * @return     {object}  updated job
     */
    function updateScriptSettings(job, policy) {
      job.preScript = job.preScript || {};

      // loop through schedule keys and remove the appropriate script as needed
      // based on the absence of the scheduleKey and/or script. don't add the
      // missing script keys, as they can be absent to accommodate changes to
      // the policy without having to update job settings. The Job flow is
      // responsible for adding and enforcing the requirements of the individual
      // script objects
      scheduleKeys.forEach(function loopScheduleKeys(scheduleKey) {
        var scriptKey = scheduleKeysToScriptMapping[scheduleKey];
        if (!policy[scheduleKey] && job.preScript[scriptKey]) {
          job.preScript[scriptKey] = undefined;
        }
      });

      return job;
    }

    /**
     * returns a copy of the time object from the provided backupPolicy,
     * should only be called from a backupPolicy having periodicity > 1
     *
     * @param  {Object} backupPolicy to extract time object from
     * @return {Object}              time from backupPolicy
     */
    function getBackupPolicyTime(backupPolicy) {
      var timeObj = {};

      switch (backupPolicy.periodicity) {
        case 2:
          timeObj = angular.copy(backupPolicy.dailySchedule.time);
          break;
        case 3:
          timeObj = angular.copy(backupPolicy.monthlySchedule.time);
          break;
        case 4:
          timeObj = angular.copy(backupPolicy.oneOffSchedule.time);
          break;
      }

      return timeObj;
    }

    /**
     * Initiates a Job to failover, also known as 'activating' a Job.
     *
     * @method   failoverJob
     * @param    {integer}   jobId      The Job id of the Job to be failedover
     * @param    {integer}   sourceId   The Source id that job objects should be
     *                                  failed over to
     * @param    {string}    policyId   The Policy ID to be applied to the Job.
     * @return   {object}    promise to resolve failover request
     */
    function failoverJob(jobId, sourceId, policyId) {
      return $http({
        method: 'post',
        url: API.private('activateBackupJob', jobId),
        headers: NgPassthroughOptionsService.requestHeaders,
        data: {
          parentEntityId: sourceId,
          policyId: policyId,
        },
      });
    }

    /**
     * presents a slide modal for editing a given entities
     * custom backup params (exchange log truncation, volume selection, etc).
     *
     * @param      {object}  server  The server/entity
     * @param      {object}  job     The job
     * @return     {object}  promise object to resolve the slider
     */
    function editBackupParams(server, job) {
      return SlideModalService.newModal({
        templateUrl: 'app/protection/jobs/modify/server-options/server-options.html',
        controller: 'serverOptionsController',
        resolve: {
          theServer: server,
          theJob: job,
        }
      });
    }

    /**
     * Translates periodicity integer into human readable string
     *
     * @param  {Object} sched  backupSchedule object
     * @return {String}        Human readable periodicity string
     */
    function periodicityCheck(sched) {
      var string;
      switch (sched.periodicity) {
        case 'kContinuous':
          // Evaluate if the the backupIntervalMins / 60 is a whole number,
          // if not, we will assume that the case is minutes
          if ((sched.continuousSchedule.backupIntervalMins / 60) % 1 !== 0) {
            string = 'Minutes';
          } else {
            string = 'Hourly';
          }
          break;
        case 'kDaily':
          // dailySchedule.days denotes specific days of the week,
          // but if none is provided, then the API assumes to set the schedule
          // for EVERY day of the week.
          if (sched.dailySchedule.days && sched.dailySchedule.days.length) {
            string = 'Weekly';
          } else {
            string = 'Daily';
          }
          break;
        case 'kMonthly':
          string = 'Monthly';
          break;
      }
      return string;
    }

    /**
     * Returns a human readable string for a protection jobs frequency
     *
     * @method   getRepeatString
     * @param    {object}   sched   schedulingPolicy object, abstracted from the
     *                              Job's Policy
     * @param    {object}   job     The job
     * @return   {string}   Job Frequency String
     */
    function getRepeatString(sched, job) {

      var text = $rootScope.text.servicesJobService;
      var repeatPeriod = periodicityCheck(sched);
      var hours;

      switch (repeatPeriod) {
        case 'Minutes':
          return [text.every, sched.continuousSchedule.backupIntervalMins, text.minutes].join('');
        case 'Hourly':
          hours = sched.continuousSchedule.backupIntervalMins / 60;
          if (hours === 1) {
            return [text.every, text.hour].join('');
          }
          return [text.every, hours, text.hours].join('');
        case 'Daily':
          return [text.everyday, text.at, DateTimeService.objectTimeToString(job.startTime)].join('');
        case 'Weekly':
          return [text.every, getDaysString(sched.dailySchedule.days), text.at, DateTimeService.objectTimeToString(job.startTime)].join('');
        case 'Monthly':
          return [text.every, ENUM_DAY_COUNT_IN_MONTH[sched.monthlySchedule.count], ' ', ENUM_DAY[sched.monthlySchedule.day], text.at, DateTimeService.objectTimeToString(job.startTime)].join('');
      }

      return '';
    }

    /**
     * Generates a comma seperated string of day(s) of the week references
     * ENUM_DAY for translation.
     *
     * @method    getDaysString
     * @param     {array}    days   List of days (integers).
     * @returns   {string}   Human-readable list of day names corresponding to
     *                       the input array in the order of days in week
     */
    function getDaysString(days) {
      // intersection function returns keys in the order of ENUM_DAY
      var arr = intersection(keys(ENUM_DAY), days)
      return arr.map(function getDaysValues(day) { return ENUM_DAY[day]}).join(', ');
    }

    /**
     * Returns a human readable string for a protection jobs expiration date
     *
     * @method   getEndString
     * @param    {object}   job   Job object
     * @return   {string}   Job Expiration String
     */
    function getEndString(job) {

      var text = $rootScope.text.servicesJobService;

      return job.endTimeUsecs ?
        DateTimeService.usecsToFormattedDate(job.endTimeUsecs) : text.never;
    }

    /**
     * Receives a jobSummary object and returns a status enum value.
     * Evaluates enum and other data returned from API
     * Return to be translated by C.constant.ENUM_BACKUP_JOB_STATUS
     *
     * NOTE: it seems this is being leveraged to evaluate status
     * of job runs and individual tasks. Consider separating this
     * logic.
     *
     * @param  {object} jobRun object as returned from API
     * @return {number}     enum of the job status
     */
    function getStatus(jobRun) {
      var activeTasks = get(jobRun, 'activeAttempt.activeTasks', jobRun.activeTasks);
      var isTask = !!jobRun.taskVersion;
      var warningsFound = false;

      if (!jobRun.base) {
        return;
      }

      if (jobRun.base.status === 2) {
        // dealing with a finished job,
        // need to determine if it finished successfully or not
        if (jobRun.base.error) {
          // error
          return 2.2;
        } else if (jobRun.base.warnings) {
          // task level warning
          return 2.3;
        } else {
          if (!isTask && jobRun.latestFinishedTasks) {
            // only interested in the tasks from the most recent run attempt(?)
            warningsFound = !!jobRun.latestFinishedTasks[0].base.warnings;
          }
          // warning or success
          return warningsFound ? 2.3 : 2.1;
        }
      }

      if (![2, 3].includes(jobRun.base.status)) {
        // Dealing with a job that has neither finished nor been canceled. Need
        // to determine if cancel is in progress. Are there activeTasks?
        if (Array.isArray(activeTasks)) {
          // Evaluate each activeTask. Using for loop here for easy early
          // return.
          for (var i = 0, len = activeTasks.length; i < len; i++) {
            if (activeTasks[i].base.publicStatus === 'kCanceling') {
              // If cancellationRequest is true. cancel is in progress.
              return 3.1;
            }
          }
        }
      }

      // Else return one of the following status codes
      // 0: 'Ready to Schedule',
      // 1: 'Running'
      // 3: 'Canceled'
      return jobRun.base.status;
    }

    /**
     * Receives a jobSummary object and returns an error message if any.
     * @param  {Object} jobRun object as returned from API
     * @return {String} error message if any.
     */
    function getErrorMsg(jobRun) {
      if (!jobRun) {
        return '';
      }
      // If replication error occurred in DR job, we should show that.
      if (jobRun.rxReplicationError) {
        return jobRun.rxReplicationError.errorMsg;
      }
      // Handle when error occurred during backup.
      if (jobRun.base && jobRun.base.error) {
        return jobRun.base.error.errorMsg;
      }
      // No error.
      return '';
    }

    /**
     * Stub out the job-specific envBackupParams for the provided job object
     *
     * @method     stubEnvBackupParams
     * @param      {object}  jobObj  The job object
     */
    function stubEnvBackupParams(jobObj) {
      // Stubbed defaultBackupParams
      var defaultBackupParams = {
        envBackupParams: {},
      };

      // Add the key name of the job-specific backup params property
      jobObj._backupParamsKey = BACKUP_PARAMS_ENUM[jobObj.type];

      // Further stub defaultBackupParams with job-specific backupParams and
      // merge into job.
      defaultBackupParams.envBackupParams[jobObj._backupParamsKey] = {};
      angular.merge(jobObj, defaultBackupParams);

      // Add a convenience property since each job type has a deeply nested
      // backupParams object with a different path.
      jobObj._backupParams = jobObj.envBackupParams[jobObj._backupParamsKey];
    }

    /**
     * provides normalization of job object and adds derived
     * properties that are useful throughout multiple states/pages
     *
     * @param  {Object} job primary job object as provided by JobService functions
     *                      or on job.backupJobSummary.jobDescription when using
     *                      JobRunService functions
     * @return {Object}     returns the provided object, though
     *                      it is passed by reference and modified directly
     */
    function transformJob(job) {
      // if the job has no type, it was created before the
      // type property existed and represents a VM job (1)
      job.type = job.type || 1;

      // a job is active if the isActive property is missing
      job.isActive = job.isActive !== undefined ? job.isActive : true;

      // Populate default QoS policy since there is no database backfill
      job.backupQosPrincipal = job.backupQosPrincipal || 0;

      // ensure job.sources is an array.
      // if a job was failed over job.sources[] won't exist
      job.sources = job.sources || [];

      // If a Job has a Deletion status of 1, mark it as isDeleted
      job.isDeleted = (job.deletionStatus === 1);

      // If job has remoteJobUids, mark it as a remote job
      job._isRemoteJob = !!job.remoteJobUids;

      stubEnvBackupParams(job);

      job._supportsIndexing = ENV_GROUPS.indexable.includes(job.type);

      job._supportsSourceSideDedup = ENV_GROUPS.physical.includes(job.type);

      if ($rootScope.FEATURE_FLAGS.crashConsistentEnabled &&
        ENV_GROUPS.hypervisor.includes(job.type)) {
        if (job.type === 1) {
          // allowCrashConsistentSnapshot is a new property in 4.0 which is
          // undefined for existing VM jobs. If undefined, we need to set a
          // default value which will be the inverse of the 'quiesce' value
          // which is the App-Consistent Backup setting in order to not force
          // the feature on pre-existing app-consistent jobs
          //
          // For new jobs, the default allowCrashConsistentSnapshot = true.
          // For pre-4.0 jobs with undefined allowCrashConsistentSnapshot,
          // we populate it as follows:
          //   if App-Consistent is enabled (quiesce: true) then
          //     allowCrashConsistentSnapshot = false.
          //   if App-Consistent is disabled (quiesce: false) then
          //     allowCrashConsistentSnapshot = true.
          //
          // This only applies to VMware. Other Virtual Server products were
          // added AFTER this release which means there are no non-VMware legacy
          // jobs.
          job._backupParams.allowCrashConsistentSnapshot =
            job._backupParams.allowCrashConsistentSnapshot ||
            !job.quiesce;
        } else if (job.type === 12) {
          // Acropolis adapter uses different setting name.
          job._backupParams.allowCrashConsistentSnapshot =
            job.continueOnQuiesceFailure;
        } else {
          // For non-VMware VM jobs, Crash Consistent fallback is always on.
          job._backupParams.allowCrashConsistentSnapshot = true;
        }
      }

      // NAS Jobs do not have a simple flag to indicate there are
      // inclusions/exclusions
      job._hasClusions = !!(ENV_GROUPS.nas.includes(job.type) &&
        job.envBackupParams.nasBackupParams &&
        job.envBackupParams.nasBackupParams.filteringPolicy &&
        job.envBackupParams.nasBackupParams.filteringPolicy.allowFilters &&
        job.envBackupParams.nasBackupParams.filteringPolicy.allowFilters[0]);

      // add timezone offset string
      job._timezoneOffset = cUtils.getTimezoneOffset(job.timezone);

      if ((FEATURE_FLAGS.protectSqlFileBased && job.type === 3) ||
        (FEATURE_FLAGS.oracleSourcesEnabled && job.type === 19)) {
        job = transformDBJob(job);
      }

      // dig for tenantId inside and looking for 1st item since a job can be
      // owned by only one tenant and backend should be fixing that in future.
      job._tenantId = get(job, 'userInfo.tenantIdVec[0]');

      // True if the user belongs to job owner's organization. Used
      // to prevent the user from making run-now, pause, delete etc requests.
      job._isJobOwner = isEntityOwner(NgIrisContextService.irisContext, job._tenantId);

      job._isDownTieringJob = !!job.envBackupParams.fileStubbingParams;
      job._isUpTieringJob = !!job.envBackupParams.fileUptieringParams;
      job._isDataMigrationJob = job._isDownTieringJob || job._isUpTieringJob;

      job._isMsSql = job.type === 3;

      job._isPhysical = ENV_GROUPS.physical.includes(job.type);
      job._isKubernetes = ENV_GROUPS.kubernetes.includes(job.type);

      return job;
    }

    /**
     * Reverses transformations made for the UI back to a Magneto-friendly SQL
     * file-based Job.
     *
     * @method   untransformJob
     * @param    {object}   job   The Job object.
     * @return   {object}   The untransformed Job.
     */
    function untransformJob(job) {
      switch (job.type) {
        case 3:
          return JobServiceFormatter.untransformSqlFileBasedJob(job);

        case 19:
          return JobServiceFormatter.untransformOracleFileBasedJob(job);

        default:
          return job;
      }
    }

    /**
     * Transforms a Magneto DB file-based job to a UI-friendly job. This is
     * necessary because, while the UI treats SQL files as first class citizens,
     * Magneto does not.
     *
     * @method   transformDBJob
     * @param    {object}   job   The Job object.
     * @return   {object}   The transformed SQL job.
     */
    function transformDBJob(job) {
      switch (job.type) {
        case 3:
          return JobServiceFormatter.transformSqlFileBasedJob(job);

        case 19:
          return JobServiceFormatter.transformOracleFileBasedJob(job);

        default:
          return job;
      }
    }

    /**
     * Presents a modal to the user to confirm removal of Physical Server
     * from its current Protection Job.
     * Only works for Physical Jobs currently, as the Physical
     * Server is guaranteed to be included in the Job's sources[]
     * and so can easily be located and removed.
     *
     * @param      {object}  node    The server to be removed
     * @return     {Object}  promise to resolve the modal
     */
    function removeFromExistingJob(node) {

      var modalConfig = {
        modalFade: true,
        backdrop: 'static',
        templateUrl: 'app/protection/jobs/modals/remove-server-from-job.html',
        controller: 'RemoveServerFromJobController',
        resolve: {
          node: node
        }
      };

      return $uibModal.open(modalConfig).result;

    }

    /**
     * Gets the number of leaf entities from a given counts hash by environment
     * type.
     *
     * @method    getLeafCountByEnvType
     * @param     {integer}   envType  The env type
     * @param     {object}    counts   The Job objects count object
     * @returns   {integer}   The number of leaf entities selected.
     */
    function getLeafCountByEnvType(envType, counts) {

      // This represents the selected(checked) leaf nodes
      var out = 0;

      // Missing required arguments: exit early
      if (!envType || !counts) {
        return out;
      }

      // This switch is based on ENUM_ENTITY_TYPE. Because we're only interested
      // in leaf entities for this Fn we don't really care about the job type,
      // only the environment type.
      switch (envType) {
        // VMware
        case ENV_TYPE_CONVERSION.kVMware:
          out = counts[8];
          break;

        // HyperV
        case ENV_TYPE_CONVERSION.kHyperV:
          out = counts[6];
          break;

        // kSQL
        case 3:
          // Because the SQL EntityHierarchy is a hybrid one of hosts (VM &
          // Physical), with auxChildren of SQL entities, we need to tally the
          // counts of all these types.
          out = [1, 3, 6, 8].reduce(function sumCount(sum, type) {
            return sum += counts[type] || 0;
          }, 0);
          break;

        // Views
        case ENV_TYPE_CONVERSION.kView:
          out = counts[1];
          break;

        // Physical jobs need to count both Physical Servers (1) and Physical
        // Clusters (2) because they are all leaf nodes since all Physical types
        // are listed in an aggregated flat structure. This is different from
        // all other envType.
        case ENV_TYPE_CONVERSION.kPhysical:
          out = counts[1] + counts[2];
          break;

        // SAN (Pure, Nimble)
        case ENV_TYPE_CONVERSION.kPure:
        case ENV_TYPE_CONVERSION.kNimble:
          out = counts[1];
          break;

        // Azure
        case 8:
          out = counts[2];
          break;

        // NetApp
        case ENV_TYPE_CONVERSION.kNetapp:
          out = counts[2];
          break;

        // NAS
        case ENV_TYPE_CONVERSION.kGenericNas:
          out = counts[1] + counts[3];
          break;

        // Acropolis
        case ENV_TYPE_CONVERSION.kAcropolis:
          out = counts[4];
          break;

        // Isilon
        case ENV_TYPE_CONVERSION.kIsilon:
          out = counts[2];
          break;

        // Pure FlashBlade
        case ENV_TYPE_CONVERSION.kFlashBlade:
          out = counts[1];
          break;

        // KVM
        case ENV_TYPE_CONVERSION.kKVM:
          out = counts[5];
          break;

        // AWS
        case ENV_TYPE_CONVERSION.kAWS:
          out = counts[3];
          break;

        case ENV_TYPE_CONVERSION.kOracle:
          out = counts[3];
          break;
      }

      return out;
    }

    /**
     * Indicates if a particular env type support indexing, taking FEATURE_FLAGS
     * into account.
     *
     * @param      {integer}  envType  The environment type
     * @return     {boolean}  True if provided environment type is indexable,
     *                        False otherwise.
     */
    function isEnvTypeIndexable(envType) {
      return getIndexableEnvTypes().includes(envType);
    }

    /**
     * Returns an array of the indexable environment types.
     */
    function getIndexableEnvTypes() {

      // if this logic has already been run, returned the cached list.
      if (indexableEnvTypes) {
        return indexableEnvTypes;
      }

      indexableEnvTypes = cUtils.simpleCopy(cUtils.onlyNumbers(ENV_GROUPS.indexable));

      if (!FEATURE_FLAGS.indexingViewsEnabled) {
        // View indexing is not enabled. Remove View (4) and Remote Adapater (5)
        // from the list.
        indexableEnvTypes.splice(indexableEnvTypes.indexOf(4), 1);
        indexableEnvTypes.splice(indexableEnvTypes.indexOf(5), 1);
      }

      if (!FEATURE_FLAGS.indexingNasEnabled) {
        // NAS indexing is not enabled. Remove nas envTypes from the list.
        // Envtype 9: Netapp
        indexableEnvTypes.splice(indexableEnvTypes.indexOf(9), 1);

        // Envtype 11: GenericNas
        indexableEnvTypes.splice(indexableEnvTypes.indexOf(11), 1);

        // EnvType 14: Isilon
        indexableEnvTypes.splice(indexableEnvTypes.indexOf(14), 1);
      }

      if (!FEATURE_FLAGS.activeDirectorySearchObjects) {
        // Active Directory object search is not enabled.
        indexableEnvTypes.splice(indexableEnvTypes.indexOf(29), 1);
      }

      return indexableEnvTypes;
    }

    /**
     * Determines whether the given Job has parent object containing
     * children to be backed up.
     *
     * @method   isJobTypeWithoutEntities
     * @param    {Object}    jobConfig   Specifies the current job
     *                                   configuration
     * @return   {Boolean}   True if the Job type has no objects/entities;
     *                       False, otherwise.
     */
    function isJobTypeWithoutEntities(jobConfig) {
      switch(true) {
        // In case of Outlook entity having mailboxes greater than the
        // iris_exec flag 'iris_max_outlook_mailbox_supported_count' then the
        // backup job only contains the Outlook entity otherwise has mailboxes.
        // Hence when run now is triggered for any job, depending upon the
        // mailbox count while creating job, objectsList may or may not be
        // populated for Outlook, hence the run now needs to specifically check
        // for ObjectsList here.
        case ENV_GROUPS.office365.includes(jobConfig.job.type):
          return !jobConfig.objectsList.length;

        // View and RA jobs/envs that are SpanFS entities and don't have any
        // objects.
        case ENV_GROUPS.spanFSSansView.includes(jobConfig.job.type):
          return true;

        // Hdfs jobs/envs don't have objects or entities.
        case ENV_GROUPS.hdfs.includes(jobConfig.job.type):
          return true;
        default:
          return false;
      }
    }

    /**
     * Returns array of IDs for isSelected Protection Jobs.
     *
     * @param   {Array}  jobs   Array of Protection Jobs.
     * @return  {Array}  Array of selected Protection Job IDs.
     */
    function _getSelectedJobIds(jobs) {
      return filter(jobs, 'isSelected').map(function jobIdsMap(job) {
        return job.backupJobSummary.jobDescription.jobId;
      });
    }

    /**
     * Calls protection jobs states API with specified data.
     *
     * @param  {object}  data  Payload for protection jobs states API.
     * @return {object}        Angular HTTP Promise.
     */
    function _bulkUpdateProtectionJobStates(data) {
      return $http({
        method: 'post',
        url: API.public('protectionJobs/states'),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders,
      });
    }

    /**
     * Sends kResume action to server for all jobs with isSelected
     * set to true.
     *
     * @param   {Array}   jobs  Jobs array.
     * @return  {object}  Angular HTTP Promise.
     */
    function resumeSelectedJobs(jobs) {
      return _bulkUpdateProtectionJobStates({
        action: 'kResume',
        jobIds: _getSelectedJobIds(jobs),
      });
    }

    /**
     * Sends kPause command to server for all jobs with isSelected
     * set to true.
     *
     * @param   {Array}   jobs  Jobs array.
     * @return  {object}  Angular HTTP Promise.
     */
    function pauseSelectedJobs(jobs) {
      return _bulkUpdateProtectionJobStates({
        action: 'kPause',
        jobIds: _getSelectedJobIds(jobs),
      });
    }

    return jobSvc;
  }

}(angular));
