import { extend } from 'lodash-es';
import { merge } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Recover Storage Volume (Pure Storage Volume + NAS)
import { recoveryGroup } from 'src/app/shared/constants';

;(function (angular, undefined) {
  'use strict';

  var moduleName = 'C.storageVolumeRecovery';
  var moduleDeps = [
    'C.restoreCommon',
    'C.jobRunsService',
    'C.pubRestoreServiceFormatter',
    'C.storageVolumeRecoverySearch',
    'C.storageVolumePureRecoveryOptions',
    'C.storageVolumeNasRecoveryOptions',
    'C.recoveryStore',
  ];

  angular
    .module(moduleName, moduleDeps)
    .config(ConfigFn)
    .component('cStorageVolumeRecovery', {
      bindings: {
        /**
         * Page Config
         *
         * @param  pageConfig    routeParams and any other params required for
         *                       component init
         *                       {
         *                         entityId: '',
         *                         jobId: '',
         *                         jobInstanceId: '',
         *                         parentId: '',
         *                       }
         * @type   {object}
         */
        pageConfig: '<',
      },
      templateUrl:
        'app/protection/recovery/storage-volume/storage-volume.parent.html',
      controller: storageVolumeRecoveryParentCtrlFn,
    });

  /**
   * Config settings for Storage Volume
   *
   * @method   ConfigFn
   */
  function ConfigFn($stateProvider) {
    var restoreModifyAccess = ctx => {
      return ctx.RESTORE_MODIFY &&
        ctx.FEATURE_FLAGS.restoreStorageVolume &&
        ctx.canAccessSomeEnv(recoveryGroup.storageVolume);
    };

    $stateProvider
      .state('recover-storage-volume', {
        url: '^/protection/recovery/storage-volume',
        title: 'Recover Storage Volume:',
        canAccess: restoreModifyAccess,
        parentState: 'recover',
        component: 'cStorageVolumeRecovery',
        resolve: {
          pageConfig: function resolvePageConfig($transition$) {
            var _flowType = $transition$.params().flowType;
            var defaultFlowType = 'recover';
            return {
              flowType: _flowType || defaultFlowType,
            };
          },
        },
        redirectTo: 'recover-storage-volume.search'
      })
      .state('recover-storage-volume.search', {
        url: '/search',
        title: 'Recover Storage Volume: Select Volume',
        help: 'protection_recovery_storage_volume_search',
        canAccess: restoreModifyAccess,
        parentState: 'recover-storage-volume',
        component: 'cStorageVolumeRecoverySearch',
      })
      .state('recover-storage-volume.nas-options', {
        url: '/nas/options?{entityId}&{jobId}&{jobInstanceId}',
        title: 'Recover NAS: Task Options',
        help: 'protection_recovery_storage_volume_options',
        canAccess: restoreModifyAccess,
        parentState: 'recover-storage-volume',
        params: {
          entityId: { type: 'string' },
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
          resubmitRecoveryObject: { type: 'any' },
          jobRunStartTime: { type: 'any' },
          jobUid: { type: 'any' },
        },
        component: 'cStorageVolumeNasRecoveryOptions',
        resolve: {
          // Transition change won't happen if this view is opened in a modal.
          // Set the params via pageConfig manually in that case.
          pageConfig: function setPageConfig($transition$) {
            var routeParams = $transition$.params();
            return {
              entityId: +routeParams.entityId || undefined,
              jobId: +routeParams.jobId || undefined,
              jobInstanceId: +routeParams.jobInstanceId || undefined,
              parentId: +routeParams.parentId || undefined,
            };
          }
        }
      })
      .state('recover-storage-volume.pure-options', {
        url: '/pure/options?{entityId}&{parentId}&{jobId}&{jobInstanceId}',
        help: 'protection_recovery_pure_options',
        title: 'Recover Pure Storage Volumes: Task Options',
        canAccess: restoreModifyAccess,
        parentState: 'recover-storage-volume',
        params: {
          entityId: { type: 'string' },
          parentId: { type: 'string' },
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
          resubmitRecoveryObject: { type: 'any' },
          jobRunStartTime: { type: 'any' },
          jobUid: { type: 'any' },
        },
        component: 'cStorageVolumePureRecoveryOptions',
        resolve: {
          // Transition change won't happen if this view is opened in a modal.
          // Set the params via pageConfig manually in that case.
          pageConfig: function setPageConfig($transition$) {
            var routeParams = $transition$.params();
            return {
              entityId: +routeParams.entityId || undefined,
              jobId: +routeParams.jobId || undefined,
              jobInstanceId: +routeParams.jobInstanceId || undefined,
              parentId: +routeParams.parentId || undefined,
            };
          }
        },
      });
  }

  /**
   * Recover Storage Volume Controller
   *  - nas & pure
   *
   * @method   storageVolumeRecoveryParentCtrlFn
   */
  function storageVolumeRecoveryParentCtrlFn(_, $q, $state, ClusterService,
    cUtils, ENV_GROUPS, ENV_TYPE_CONVERSION, ExternalTargetService, JobService,
    RestoreService, SearchService, SearchServiceFormatter, SourceService,
    ViewBoxService, ViewService, PubRestoreServiceFormatter, evalAJAX,
    RecoveryStore) {
    var $ctrl = this;

    // declare component methods
    assign($ctrl, {
      // Component methods
      addToCart: addToCart,
      getModeTextKey: getModeTextKey,
      getViewBox: getViewBox,
      startFlowOver: startFlowOver,

      // Component life cycle methods
      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    /**
     * Initialize the data required for the component
     *
     * @method   _initializeCtrl
     */
    function _initializeCtrl() {
      assign($ctrl, {
        shared: RecoveryStore.init(_getDefaultSharedData()),
        isRecover: $ctrl.pageConfig.flowType === 'recover',
        isClone: $ctrl.pageConfig.flowType !== 'recover',
      });
    }

    /**
     * Component init function
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeCtrl();
      _fetchDependencies();
    }

    /**
     * Component destroy function
     *
     * @method $onDestroy
     */
    function $onDestroy() {
      RecoveryStore.clear();
    }

    /**
     * Fetch dependencies (Mostly filter lookups).
     *
     * @method   _fetchDependencies
     */
    function _fetchDependencies() {
      // Combination of NAS and Pure Storage.
      var _storageVolEnvTypes = cUtils.onlyNumbers(ENV_GROUPS.storageVolumes);
      var depsPromises = {
        viewBoxes: ViewBoxService.getOwnViewBoxes(),
        sources: SourceService.getSources({
          onlyReturnOneLevel: true,
          envTypes: _storageVolEnvTypes,
        }),
        entities: SourceService.getEntitiesOfType({
          environmentTypes: cUtils.onlyStrings(ENV_GROUPS.storageVolumes),
          netappEntityTypes: 'kVolume',
          pureEntityTypes: 'kVolume',
          isProtected: true,
        }),
        jobs: JobService.getJobs({
          envTypes: _storageVolEnvTypes,
        }),
        externalTargets: ExternalTargetService.getTargets(),
        qosPolicies: ViewService.getQosPrincipals(),
      };

      return $q.all(depsPromises).then(function allSuccess(responses) {
        _initFilterLookups();

        $ctrl.shared.filterLookups.viewBoxIds = responses.viewBoxes;

        if (responses.sources && responses.sources.entityHierarchy &&
          responses.sources.entityHierarchy.children) {
          $ctrl.shared.filterLookups.registeredSourceIds =
            responses.sources.entityHierarchy.children;
        }

        $ctrl.shared.filterLookups.entityIds = responses.entities;
        $ctrl.shared.filterLookups.jobIds = responses.jobs.map(
          function augmentJobsFn(job) {
            return merge(job, { jobName: job.name });
          }
        );

        if (Array.isArray(responses.externalTargets)) {
          $ctrl.shared.filterLookups.externalTargets =
            angular.copy(ExternalTargetService.targetNameMapById);
        }
        $ctrl.clusters = $ctrl.shared.filterLookups.clusters =
          ClusterService.updateClusterHash(ClusterService.clusterInfo);
        $ctrl.shared.filterLookups.qosPolicies = responses.qosPolicies;
      },evalAJAX.errorMessage);
    }

    /**
     * Get the init shared data which is used across entire storage volume
     * recovery flow.
     *
     * @method    _getDefaultSharedData
     * @return   {object}   sharedData
     */
    function _getDefaultSharedData() {
      return {
        // Params used in both options and search page.
        cart: [],
        filters: [],
        filterLookups: {},
        pagedResults: [],
        results: [],
        searchId: 'storage-volume-search',
        searchEndpoint: SearchService.getSearchUrl('storage-volume'),
        selectedVlanTarget: undefined,

        // specific to nas options
        name: undefined,
        defaultName: undefined,
        viewName: undefined,
        defaultViewName: undefined,
        continueOnError: false,
        overwrite: true,
        preserveAttributes: true,
        task: undefined,
        target: {
          alternate: undefined,
        },
        qos: undefined,
      };
    }

    /**
     * Get Pure Default Task
     *
     * @method     _getPureDefaultTask
     * @return     {object}
     */
    function _getPureDefaultTask() {
      var taskName =
        RestoreService.getDefaultTaskName($ctrl.pageConfig.flowType, 'pure');

      // pure Default RecoverArg object.
      // for private api.
      return {
        action: 8,
        defaultTaskName: taskName,
        name: taskName,
        objects: [],
        restoreParentSource: undefined,
        renameRestoredObjectParam: {
          prefix: undefined,
          suffix: undefined,
        },
      };
    }

    /**
     * Setup filter lookups skeleton
     *
     * @method   _initFilterLookups
     */
    function _initFilterLookups() {
      $ctrl.shared.filterLookups = extend({
        entityIds: [],
        viewBoxIds: [],
        registerSourceIds: [],
        jobIds: [],
      }, $ctrl.shared.filterLookups);
    }

    /**
     * Adds an item to the cart and transitions to the next step.
     *
     * @method   addToCart
     * @param    {object}    item      The item to add to the cart
     * @param    {boolean}   proceed   Proceed to next step when true.
     */
    function addToCart(item, proceed) {
      var nasEnvGroups = cUtils.onlyNumbers(ENV_GROUPS.nas);
      var pureEnvGroups = cUtils.onlyNumbers(ENV_GROUPS.san);
      proceed = angular.isDefined(proceed) ? proceed : true;
      var hasLocal;
      var firstReplica;

      // Validate that this is a useful result object.
      if (!item || !item.vmDocument || !item._snapshot) {
        return;
      }

      // Add _archiveTarget  and _archivalTarget if no local snapshots exist.
      hasLocal =
        get(item, '_snapshot.replicaInfo.replicaVec[0].target.type') === 1;
      firstReplica = item._snapshot.replicaInfo.replicaVec[0];
      assign(item, {
          // First replica will be local.
          // If not set the archiveTarget and archivalTarget
        _archiveTarget: !hasLocal ? firstReplica : undefined,

        // This is used in public api where as archive target in private API
        _archivalTarget: !hasLocal ? SearchServiceFormatter
          .getArchiveTargetPub(firstReplica) : undefined,
      });

      // Add item to the cart
      $ctrl.shared.cart = [item];

      // Add specific properties
      if (nasEnvGroups.includes(item._jobType)) {
        // gets filled in the nas.options setRequestParams method
        $ctrl.shared.task = {};
      } else if (pureEnvGroups.includes(item._jobType)) {
        $ctrl.shared.task = _getPureDefaultTask();
      }

      // 1. recover from search -> open the options page
      // 2. recover directly from url with jobId, jobInstanceId etc
      //    -> you are already on the options page so stay there.
      if (proceed) {
        if (nasEnvGroups.includes(item._jobType)) {
          $state.go([$ctrl.pageConfig.flowType, 'storage-volume.nas-options']
            .join('-'));
        } else if (pureEnvGroups.includes(item._jobType)) {
          $state.go([$ctrl.pageConfig.flowType, 'storage-volume.pure-options']
            .join('-'));
        }
      }
    }

    /**
     * Returns text key for FS Protocol mode: NFS, SMB, or Mixed Mode.
     * This is used only for NAS
     *
     * @method     getModeTextKey
     * @param      {object}  entity  The entity
     * @return     {string}  Mode string
     */
    function getModeTextKey(source) {
      if (source.isEmpty) {
        return;
      }

      var entity = source.vmDocument.objectId.entity;

      // The proto needs reconstruction to match what is expected by a shared
      // method in SourceService
      var constructedSource = {
        entity: source.registeredSource,
        type: source._type,
        _entityKey: source._entityKey,
      };

      switch (entity.type) {
        case ENV_TYPE_CONVERSION.kNetapp:
          return SourceService
            .areProtocolsSupported(constructedSource, [0, 1]) ?
              'mixedMode' :
              SourceService.getFSProtocols(
                entity.netappEntity.volumeInfo.dataProtocolVec, 9
              );

        case ENV_TYPE_CONVERSION.kGenericNas:
          return SourceService.getFSProtocols(
            [entity.genericNasEntity.protocol], 11
          );

        case ENV_TYPE_CONVERSION.kIsilon:
          return SourceService.areProtocolsSupported(constructedSource,
            [0, 1]) ?
              'mixedMode' :
              SourceService.getFSProtocols(
                entity.isilonEntity.mountPointInfo.supportedProtocolVec, 14
              );

        case ENV_TYPE_CONVERSION.kFlashBlade:
          return SourceService.areProtocolsSupported(constructedSource,
            [1, 2, 3]) ?
              'mixedMode' :
              SourceService.getFSProtocols(
                entity.flashbladeEntity.fileSystemInfo.supportedProtocolVec, 21
              );

        case ENV_TYPE_CONVERSION.kGPFS:
          return SourceService.areProtocolsSupported(constructedSource,
            [1, 2]) ?
              'mixedMode' :
              SourceService.getFSProtocols(
                entity.gpfsEntity.filesetInfo.supportedProtocolVec, 31
              );

        case ENV_TYPE_CONVERSION.kElastifile:
          return SourceService.areProtocolsSupported(constructedSource,
            [1, 2]) ?
              'mixedMode' :
              SourceService.getFSProtocols(
                entity.elastifileEntity.containerInfo.supportedProtocolVec, 37
              );
      }
    }

    /**
     * Get a viewbox object by id
     *
     * @method   getViewBox
     * @param    {integer}            id   A ViewBox id
     * @return   {object|undefined}   The matched ViewBox, or undefined if
     *                                not found.
     */
    function getViewBox(id) {
      if (isEmpty($ctrl.shared.filterLookups) || isNaN(id)) {
        return;
      }
      return $ctrl.shared.filterLookups.viewBoxIds.find(
        function viewBoxFinder(viewBox) {
          return id === viewBox.id;
        }
      );
    }

    /**
     * Convenience to start the flow over (first step, which can change
     * depending on how the flow is accessed).
     *
     * @method   startFlowOver
     */
    function startFlowOver() {
      $state.go(
        [$ctrl.pageConfig.flowType, 'storage-volume.search'].join('-'),
        { location: 'replace', reload: true, }
      );
    }
  }
})(angular);
