import { assign } from 'lodash-es';
// Module: Audit Logs - Settings

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.auditEditSettings', [])
    .controller('auditEditSettingsController', AuditEditSettingsControllerFn);

  function AuditEditSettingsControllerFn(
    $rootScope, $scope, $state, $q, ClusterService, evalAJAX,
    cMessage) {

    var $ctrl = this;

    assign($ctrl, {
      shared: {},
      clusterInfo: {},
      settings: {
        clusterAuditLogConfig: {
          enabled: true,
          retentionPeriodDays: 30,
        },
      },

      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method     $onInit
     */
    function $onInit() {
      getData();
    }

    /**
     * Redirect to list view
     *
     * @method     exitForm
     */
    $ctrl.exitForm = function exitForm() {
      $state.go('audit');
    };

    /**
     * Calls the API to update the Audit settings
     *
     * @method     updateSettings
     */
    $ctrl.updateSettings = function updateSettings() {
      if (!$ctrl.shared.settingsForm.$valid) {
        return;
      }

      $ctrl.submitting = true;
      assign($ctrl.clusterInfo.clusterAuditLogConfig,
        $ctrl.settings.clusterAuditLogConfig);
      ClusterService.updateClusterInfo($ctrl.clusterInfo).then(
        function updateClusterInfoSuccess(r) {
          cMessage.success({
            textKey: 'audit.settings.updated.text',
          });
          $ctrl.exitForm();
        },
        evalAJAX.errorMessage
      ).finally(
        function updateClusterInfoFinally() {
          $ctrl.submitting = false;
        }
      );
    };

    /**
     * Get the settings data
     *
     * @method     getData
     */
    function getData() {
      $ctrl.loading = true;

      ClusterService.getClusterInfo().then(
        function getClusterInfoSuccess(r) {
          assign($ctrl.settings.clusterAuditLogConfig,
            r.data.clusterAuditLogConfig);
          assign($ctrl.clusterInfo, r.data);
        },
        evalAJAX.errorMessage
      ).finally(
        function getCLusterInfoFinally() {
          $ctrl.loading = false;
        }
      );
    }
  }

})(angular);
