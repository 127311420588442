import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isDmsScope, isIbmBaaSEnabled } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { AccessContextType, Workflow } from 'src/app/app-module.config';
import { AdaptorAccessService, StateManagementService, UserService } from 'src/app/core/services';
import { AjsClusterService } from 'src/app/shared/ng-upgrade/services';

import {
  envGroups,
  Environment,
  EnvItems,
  envTypes,
  hadoopEnvItems,
  hypervisorSources,
  NasSourceNames,
  nasSources,
  Office365BackupType,
  recoveryGroup,
  storageArraySources,
} from '../constants';
import { hasSourceModifyPrivilege } from '../helper-utils';

/**
 * State data structure for the menu item of shared buttons, also used by AJS c-context-menu.
 */
interface BaseNavState {
  /**
   * Text to be translated for display.
   */
  translateKey: string;

  /**
   * Identifier of menu item.
   */
  id?: string;

  /**
   * Data ID of menu item.
   */
  dataId?: string;

  /**
   * UIRouter state parameters.
   */
  stateParams?: {
    environments?: string[];
    cloudJobType?: string;
    dbType?: string;
    isPhysicalDeploy?: boolean;
    isHotStandby?: boolean;
    isRecover?: boolean;
    ngEnvironments?: string[];
    office365WorkloadType?: string;
    /**
     * Only applies to:
     * 1. Bifrost tenant users,
     * 2. Bifrost aware sources
     * 3. Source Registration workflows
     */
    connectionId?: number | null;
  };

  /**
   * Add a Divider before?
   */
  dividerBefore?: boolean;
}

export interface RouteNavState extends BaseNavState {
  /**
   * UIRouter state.
   */
  state: string;

  /**
   * Specifies the list of Sub Menu states.
   */
  subStates?: NavState[] | RouteNavState[];
}

export interface DialogNavState extends BaseNavState {
  /**
   * Dialog id
   */
  dialog: string;

  /**
   * Optional Dialog data to be passed to dialog component.
   */
  dialogData?: any;

  /**
   * The environment supported by the above dialog.
   */
  envItems?: EnvItems[];
}

export type NavState = RouteNavState | DialogNavState;

/**
 * Provide the state data structure to menu of shared buttons.
 */
@Injectable({
  providedIn: 'root'
})
export class NavStateService {
  /**
   * Constructor.
   */
  constructor(
    private adaptorAccessService: AdaptorAccessService,
    private ajsClusterService: AjsClusterService,
    private irisCtx: IrisContextService,
    private stateManagementService: StateManagementService,
    private translateService: TranslateService,
    private userService: UserService,
    private irisContextService: IrisContextService,
  ) {}

  /**
   * Filter out non-accessible nav options from the provider list of nav options.
   *
   * @param   list                 The list of nav-states.
   * @param contextType
   * NOTE:  stateAccessContextFn and ajsStateAccessContextFn must be defined in state-management service.
   * @returns The filtered list.
   */
  filterByCanAccess(list: NavState[], contextType = AccessContextType.Default): NavState[] {
    list.forEach((nav, index, navs) => {
      // ignoring null element from list on PXG cluster
      if ((nav as RouteNavState)?.subStates) {
        (navs[index] as RouteNavState).subStates = (navs[index] as RouteNavState).subStates.filter(subState =>
          this.canAccessNavState(subState, contextType)
        );
      }
    });
    return list.filter((nav: RouteNavState) => nav.subStates?.length || this.canAccessNavState(nav, contextType));
  }

  /**
   * check if a nav state can be accessed using current perms.
   *
   * @returns boolean if nav state is allowed.
   */
  canAccessNavState(nav: NavState, contextType: AccessContextType): boolean {
    if (!nav) {
      return false;
    }

    let canAccessState = true;
    if ((nav as RouteNavState).state) {
      canAccessState = this.stateManagementService.canUserAccessState((nav as RouteNavState).state, nav.stateParams,
        false, contextType);
    }

    let canAccessEnvs = true;
    if ((nav as DialogNavState).envItems) {
      // if custom context fn provided, generate context and use workflow from that
      const workflowCtx = this.stateManagementService.getWorkflowContext('', undefined, contextType);
      const accessibleEnvs = this.adaptorAccessService.filterByAccessibleEnvItems((nav as DialogNavState).
        envItems, undefined, workflowCtx);
      canAccessEnvs = accessibleEnvs.length > 0;
    }

    return canAccessState && canAccessEnvs;
  }

  /**
   * Get the list of states for Recover button.
   *
   * @returns List of states for menu items of Recover button.
   */
  getRecoverStates(): NavState[] {
    const dmsScope = isDmsScope(this.irisContextService.irisContext);
    const result: NavState[] = [
      // Duplicating Files & Folders at top level because its a much loved feature
      // to be able to indiscriminately search files & folders across various sources.
      {
        translateKey: 'filesOrFolders',
        state: 'recover-files',
        dataId: 'recover-files-or-folders',
        envItems: [
          ...nasSources,
          ...envGroups.vms,
          { environment: Environment.kPhysical },
          { environment: Environment.kPhysicalFiles }
        ],
      },
      {
        translateKey: 'virtualMachines',
        dataId: 'recover-virtual-machines',
        state: 'recovery.list',
        subStates: [
          {
            translateKey: 'filesOrFolders',
            state: 'recover-files',
            dataId: 'recover-vm-files-or-folders',
            envItems: recoveryGroup.vm.filter(
              env => env !== Environment.kHyperVVSS).map(env => ({ environment: env })
            ),
            stateParams: {
              // kHyperVVSS is not supported for file recovery.
              environments: recoveryGroup.vm.filter(env => env !== Environment.kHyperVVSS),
            },
          },
          {
            translateKey: 'instantVolumeMount',
            envItems: envGroups.vms.map(env => ({ environment: env })),
            state: 'recover-mount-point',
            dataId: 'recover-vm-mount-point',
          },
          {
            translateKey: 'VMs',
            envItems: envGroups.vms.map(env => ({ environment: env })),
            state: 'recover-vm',
            dataId: 'recover-vm',
          },
          {
            translateKey: 'virtualDisks',
            envItems: [
              { environment: Environment.kVMware },
              { environment: Environment.kVCD },
            ],
            state: 'recover-vmdk',
            dataId: 'recover-virtual-disks',
          },
          {
            translateKey: 'migrateVM',
            envItems: [
              { environment: Environment.kVMware },
              { environment: Environment.kVCD },
            ],
            state: 'migrate-vm-ng',
            dataId: 'migrate-vm-ng',
          },
        ],
      },
      {
        translateKey: 'databases',
        dataId: 'recover-databases',
        state: 'recovery.list',
        subStates: this.getDatabaseRecoverSubStates(),
      },
      {
        translateKey: 'nas',
        state: 'recover-storage-volume',
        dataId: 'recover-nas',
        subStates: [
          {
            translateKey: 'filesOrFolders',
            envItems: nasSources,
            state: 'recover-files',
            dataId: 'recover-nas-files-or-folders',
            stateParams: {
              environments: recoveryGroup.nas
            },
          },
          {
            translateKey: 'storageVolume',
            envItems: nasSources,
            state: 'recover-storage-volume',
            dataId: 'recover-nas-storage-volume',
          }
        ]
      },
      flagEnabled(this.irisCtx.irisContext, 'ngRecoverOffice365') ? {
        translateKey: 'microsoft365',
        state: 'recover-office365.search',
        dataId: 'recover-office365',
        subStates: this.getRecoverOffice365SubStates(),
      } : undefined,
      {
        translateKey: 'physicalServer',
        state: 'recover-physical-server',
        dataId: 'recover-physical-server',
        subStates: [
          {
            translateKey: 'filesOrFolders',
            envItems: [
              { environment: Environment.kPhysicalFiles },
              { environment: Environment.kPhysical },
            ],
            state: 'recover-files',
            dataId: 'recover-physical-files-or-folders',
            stateParams: {
              environments: recoveryGroup.physicalServer
            },
          },
          !isIbmBaaSEnabled(this.irisContextService.irisContext) && {
            translateKey: 'instantVolumeMount',
            envItems: [{ environment: Environment.kPhysical }] as EnvItems[],
            state: 'recover-mount-point',
            dataId: 'recover-physical-mount-point',
          },
          {
            translateKey: 'physicalServer',
            envItems: [
              { environment: Environment.kPhysical },
              { environment: Environment.kPhysicalFiles },
            ] as EnvItems[],
            state: 'recover-physical-server',
            dataId: 'recover-physical-physical-server',
          }
        ],
      },
      {
        translateKey: 'applications',
        dataId: 'recover-applications',
        state: 'recovery.list',
        subStates: this.getAppRecoverSubStates(),
      },
      {
        translateKey: 'san',
        envItems: storageArraySources,
        state: 'recover-storage-volume',
        dataId: 'recover-san-storage-volume',
      },
      flagEnabled(this.irisContextService.irisContext, 'ngRecoverCohesityViewFilesFolders') ?
      {
        translateKey: 'cohesityView',
        state: 'recover-files',
        dataId: 'recover-cohesity-view-files-folders',
        subStates: [
          {
            translateKey: 'filesOrFolders',
            envItems: [{ environment: Environment.kView }] as EnvItems[],
            state: 'recover-files',
            dataId: 'recover-cohesity-view-files-or-folders',
            stateParams: {
              environments: recoveryGroup.view,
            },
          },
          {
            translateKey: 'cloneView',
            envItems: [{ environment: Environment.kView }] as EnvItems[],
            state: 'clone-view.search',
            dataId: 'clone-view',
          }
        ]
      } :
      {
        translateKey: 'cohesityViewClone',
        envItems: [{ environment: Environment.kView }] as EnvItems[],
        state: 'clone-view.search',
        dataId: 'clone-view',
      },
      {
        translateKey: 'hadoop',
        dataId: 'recover-hadoop',
        state: 'recovery.list',
        subStates: [
          {
            translateKey: 'hbase',
            envItems: [{ environment: Environment.kHBase }] as EnvItems[],
            state: 'recover-hbase-ng',
            dataId: 'recover-hbase',
          },
          {
            translateKey: 'hdfs',
            envItems: [{ environment: Environment.kHdfs }] as EnvItems[],
            state: 'recover-hdfs-ng',
            dataId: 'recover-hdfs',
          },
          {
            translateKey: 'hive',
            envItems: [{ environment: Environment.kHive }] as EnvItems[],
            state: 'recover-hive-ng',
            dataId: 'recover-hive',
          },
        ],
      },
      (flagEnabled(this.irisContextService.irisContext, 'kubernetesGranularRecoveryEnabled')) ? {
        translateKey: 'kubernetesCluster',
        state: 'kubernetes-recovery.search',
        dataId: 'recover-kubernetes',
        subStates: [
          {
            translateKey: 'filesOrFolders',
            envItems: [{ environment: Environment.kKubernetes }] as EnvItems[],
            state: 'recover-files',
            dataId: 'recover-kubernetes-files-or-folders',
            stateParams: {
              environments: recoveryGroup.kubernetes
            },
          },
          {
            translateKey: 'namespace',
            envItems: [{ environment: Environment.kKubernetes }] as EnvItems[],
            state: 'kubernetes-recovery.search',
            dataId: 'recover-kubernetes',
          },
        ]
      } : {
        translateKey: 'kubernetesCluster',
        envItems: [{ environment: Environment.kKubernetes }] as EnvItems[],
        state: 'kubernetes-recovery.search',
        dataId: 'recover-kubernetes',
      },
      {
        translateKey: 'universalDataAdapter',
        envItems: [{ environment: Environment.kUDA }] as EnvItems[],
        state: 'recover-uda-ng',
        dataId: 'recover-uda',
        stateParams: {
          environments: ['kUDA'],
        }
      },
      {
        translateKey: 'salesforce',
        envItems: [{ environment: Environment.kSfdc }] as EnvItems[],
        state: 'recover-sfdc-ng',
        dataId: 'recover-sfdc',
      },
      (!dmsScope && flagEnabled(this.irisContextService.irisContext, 's3OnPremProtection')) ? {
        translateKey: 'amazonS3',
        envItems: [{ environment: Environment.kAwsS3 }] as EnvItems[],
        state: 'recover-s3-ng',
        dataId: 'recover-s3',
        stateParams: {
          environments: ['kAwsS3'],
        }
      } : undefined,
    ].filter(Boolean);

    return this.filterByCanAccess(result);
  }

  /**
   * Get the list of states for Protect button.
   *
   * @returns List of states for menu items of Protect button.
   */
  getProtectStates(): NavState[] {
    const dmsScope = isDmsScope(this.irisContextService.irisContext);
    const {
      featureFlags: {
        dmsOffice365Registration,
        dmsOracleRegistration,
        dmsPhysicalRegistration,
        dmsSqlRegistration,
        s3OnPremProtection,
      },
    } = this.irisContextService.irisContext;

    const result: NavState[] = [
      {
        translateKey: 'virtualMachines',
        id: 'new-vm-job-anchor',
        dataId: 'create-vm-protection-group',
        state: 'protection-builder',
        envItems: envGroups.vms.map(env => ({ environment: env })),
        stateParams: {
          environments: envGroups.vms,
        },
      },
      (dmsScope ? (dmsSqlRegistration || dmsOracleRegistration) : true) && {
        translateKey: 'databases',
        id: 'new-database-group-anchor',
        dataId: 'create-database-protection-group',
        state: 'protection-group.groups',
        subStates: this.getProtectDatabaseSubStates(),
      },
      {
        translateKey: 'nas',
        id: 'new-nas-job-anchor',
        dataId: 'create-nas-protection-group',
        state: 'protection-builder',
        envItems: nasSources,
        stateParams: {
          environments: envGroups.nas,
        },
      },
      (dmsScope ? dmsOffice365Registration : true) && {
        translateKey: 'microsoft365',
        dataId: 'create-365-protection-group',
        state: 'protection-group.groups',
        subStates: this.getProtectOffice365SubStates(),
      },
      (dmsScope ? dmsPhysicalRegistration : true) && {
        translateKey: 'physicalServer',
        id: 'new-physical-group-anchor',
        dataId: 'create-physical-protection-group',
        state: 'protection-group.groups',
        subStates: [
          {
            translateKey: 'blockBased',
            id: 'new-physical-server-block-job-anchor',
            dataId: 'create-physical-block-protection-group',
            envItems: [{ environment: Environment.kPhysical }] as EnvItems[],
            state: 'protection-builder',
            stateParams: {
              environments: ['kPhysical'],
            },
          },
          {
            translateKey: 'fileBased',
            id: 'new-physical-server-file-job-anchor',
            dataId: 'create-physical-file-protection-group',
            envItems: [
              { environment: Environment.kPhysical },
              { environment: Environment.kPhysicalFiles }
            ] as EnvItems[],
            state: 'protection-builder',
            stateParams: {
              environments: ['kPhysicalFiles', 'kPhysical'],
            },
          },
        ]
      },
      !dmsScope && {
        translateKey: 'applications',
        id: 'new-application-group-anchor',
        dataId: 'create-application-protection-group',
        state: 'protection-group.groups',
        subStates: this.getAppProtectSubStates(),
      },
      !dmsScope && {
        translateKey: 'san',
        id: 'new-pure-job-anchor',
        dataId: 'create-pure-protection-group',
        envItems: [{ environment: Environment.kPure }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kPure'],
        },
      },
      {
        translateKey: 'cohesityView',
        id: 'new-view-job-anchor',
        dataId: 'create-view-protection-group',
        envItems: [{ environment: Environment.kView }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kView'],
        },
      },
      !dmsScope && {
        translateKey: 'hadoop',
        id: 'new-hadoop-group-anchor',
        dataId: 'create-hadoop-protection-group',
        state: 'protection-group.groups',
        subStates: this.getHadoopSubStates(),
      },
      !dmsScope && {
        translateKey: 'remoteAdapter',
        id: 'new-remote-adapter-job-anchor',
        dataId: 'create-remote-adapter-protection-group',
        envItems: [{ environment: Environment.kPuppeteer }, { environment: Environment.kRemoteAdapter }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kPuppeteer'],
          ngEnvironments: ['kRemoteAdapter'],
        },
      },
      !dmsScope && {
        translateKey: 'kubernetesCluster',
        dataId: 'create-kubernetes-protection-group',
        state: 'protection-builder',
        envItems: [{ environment: Environment.kKubernetes }] as EnvItems[],
        stateParams: {
          environments: ['kKubernetes'],
        },
      },
      !dmsScope && {
        translateKey: 'universalDataAdapter',
        dataId: 'create-uda-protection-group',
        envItems: [{ environment: Environment.kUDA }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kUDA'],
        }
      },
      !dmsScope && {
        translateKey: 'salesforce',
        dataId: 'create-sfdc-protection-group',
        envItems: [{ environment: Environment.kSfdc }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kSfdc'],
        }
      },
      (!dmsScope && s3OnPremProtection ) && {
        translateKey: 'amazonS3',
        id: 'new-s3-job-anchor',
        dataId: 'create-s3-protection-group',
        envItems: [{ environment: Environment.kAwsS3 }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kAwsS3'],
        }
      },
    ].filter(Boolean);

    return this.filterByCanAccess(result);
  }

  /**
   * Returns the sub states for NoSQL Databases.
   *
   * @returns   Array of NavState
   */
  getProtectDatabaseSubStates(): NavState[] {
    const dmsScope = isDmsScope(this.irisContextService.irisContext);
    const {
      featureFlags: {
        dmsSqlRegistration,
        dmsOracleRegistration,
        enableLegacyCouchbase,
      },
    } = this.irisContextService.irisContext;
    const dbSubsStates: NavState[] = [
      !dmsScope && {
        translateKey: 'amazonRDS',
        dataId: 'create-aws-rds-protection-group',
        envItems: [{ environment: Environment.kAWS }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kAWS'],
          cloudJobType: 'kRDSSnapshotManager',
        },
      },
      !dmsScope && {
        translateKey: 'amazonAurora',
        dataId: 'create-aws-aurora-protection-group',
        envItems: [{ environment: Environment.kAWS }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kAWS'],
          cloudJobType: 'kAuroraSnapshotManager',
        },
      },
      !dmsScope && {
        translateKey: 'cassandra',
        dataId: 'create-cassandra-protection-group',
        envItems: [{ environment: Environment.kCassandra }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kCassandra'],
        }
      },
      (!dmsScope && enableLegacyCouchbase) && {
        translateKey: 'couchbase',
        dataId: 'create-couchbase-protection-group',
        envItems: [{ environment: Environment.kCouchbase }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kCouchbase'],
        }
      },
      !dmsScope && {
        translateKey: 'mongodb',
        dataId: 'create-mongodb-protection-group',
        envItems: [{ environment: Environment.kMongoDB }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kMongoDB'],
        }
      },
      !dmsScope && {
        translateKey: 'mongodbOpsManager',
        dataId: 'create-mongodb-physical-protection-group',
        envItems: [{ environment: Environment.kMongoDBPhysical }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kMongoDBPhysical'],
        }
      },
      (dmsScope ? dmsSqlRegistration : true) && {
        translateKey: 'msSqlServer',
        id: 'new-database-job-anchor',
        dataId: 'create-ms-sql-protection-group',
        envItems: [{ environment: Environment.kSQL }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kSQL'],
        },
      },
      (dmsScope ? dmsOracleRegistration : true) && {
        translateKey: 'oracleDatabase',
        dataId: 'create-oracle-protection-group',
        envItems: [{ environment: Environment.kOracle }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kOracle'],
        },
      },
      (!dmsScope && flagEnabled(this.irisContextService.irisContext, 'ibmSapHanaEnabled') &&
        isIbmBaaSEnabled(this.irisContextService.irisContext)) && {
        translateKey: 'sapHana',
        dataId: 'create-sap-hana-protection-group',
        envItems: [{ environment: Environment.kSAPHANA }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kSAPHANA'],
        }
      },
    ].filter(Boolean);

    return this.filterByCanAccess(dbSubsStates);
  }

  /**
   * Returns the recovery sub states for SQL Databases.
   *
   * @returns   Array of NavState
   */
  getDatabaseRecoverSubStates(): NavState[] {
    const dmsScope = isDmsScope(this.irisContextService.irisContext);
    const subStates: NavState[] = [
      {
        translateKey: 'amazonDB',
        envItems: [{ environment: Environment.kAWS }] as EnvItems[],
        state: 'recover-rds.search',
        stateParams: {isRecover: true},
        dataId: 'recover-rds',
      },
      {
        translateKey: 'cassandra',
        envItems: [{ environment: Environment.kCassandra }] as EnvItems[],
        state: 'recover-cassandra-ng',
        dataId: 'recover-cassandra',
        stateParams: {isHotStandby: false},
      },
      flagEnabled(this.irisCtx.irisContext, 'nosqlHotStandby') && {
        translateKey: 'cassandraHotStandby',
        envItems: [{ environment: Environment.kCassandra }] as EnvItems[],
        state: 'recover-cassandra-ng',
        dataId: 'recover-cassandra-hotstandby',
        stateParams: {isHotStandby: true},
      },
      // Couchbase recovery support is deprecated in 7.2
      flagEnabled(this.irisCtx.irisContext, 'enableLegacyCouchbase') && {
        translateKey: 'couchbase',
        envItems: [{ environment: Environment.kCouchbase }] as EnvItems[],
        state: 'recover-couchbase-ng',
        dataId: 'recover-couchbase',
      },
      {
        translateKey: 'migrationMsSqlOnly',
        envItems: [{ environment: Environment.kSQL }] as EnvItems[],
        state: 'migrate-db',
        dataId: 'migrate-database',
      },
      {
        translateKey: 'mongodb',
        envItems: [{ environment: Environment.kMongoDB }] as EnvItems[],
        state: 'recover-mongodb-ng',
        dataId: 'recover-mongodb',
      },
      {
        translateKey: 'mongodbOpsManager',
        envItems: [{ environment: Environment.kMongoDBPhysical }] as EnvItems[],
        state: 'recover-mongodb-physical-ng',
        dataId: 'recover-mongodb-physical',
      },
      {
        translateKey: 'msSql',
        envItems: [{ environment: Environment.kSQL }] as EnvItems[],
        state: 'recover-db',
        stateParams: {dbType: 'sql'},
        dataId: 'recover-sql',
      },
      {
        translateKey: 'oracle',
        envItems: [{ environment: Environment.kOracle }] as EnvItems[],
        state: 'recover-db',
        stateParams: {dbType: 'oracle'},
        dataId: 'recover-oracle',
      },
      (!dmsScope && flagEnabled(this.irisContextService.irisContext, 'ibmSapHanaEnabled') &&
        isIbmBaaSEnabled(this.irisContextService.irisContext)) && {
        translateKey: 'sapHana',
        envItems: [{ environment: Environment.kSAPHANA }] as EnvItems[],
        state: 'recover-saphana',
        dataId: 'recover-sap-hana',
        stateParams: {
          environments: ['kSAPHANA'],
        }
      },
    ];

    return this.filterByCanAccess(subStates);
  }

  /**
   * Returns the sub states for Hadoop.
   *
   * @returns   Array of NavState
   */
  getHadoopSubStates(): NavState[] {
    const subStates: NavState[] = [
      {
        translateKey: 'hbase',
        dataId: 'create-hbase-protection-group',
        envItems: [{ environment: Environment.kHBase }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kHBase'],
        }
      },
      {
        translateKey: 'hdfs',
        dataId: 'create-hdfs-protection-group',
        envItems: [{ environment: Environment.kHdfs }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kHdfs'],
        }
      },
      {
        translateKey: 'hive',
        dataId: 'create-hive-protection-group',
        envItems: [{ environment: Environment.kHive }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kHive'],
        }
      },
    ];

    return this.filterByCanAccess(subStates);
  }

  /**
   * Returns the sub states for Applications.
   *
   * @returns   Array of NavState
   */
  getAppProtectSubStates(): NavState[] {
    const applicationsSubStates: NavState[] = [
      {
        translateKey: 'activeDirectory',
        dataId: 'create-ad-protection-group',
        envItems: [{ environment: Environment.kAD }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kAD'],
        },
      },
      {
        translateKey: 'exchangeServer',
        dataId: 'create-exchange-protection-group',
        envItems: [{ environment: Environment.kExchange }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kExchange'],
        },
      },
    ];

    return this.filterByCanAccess(applicationsSubStates);
  }

  getAppRecoverSubStates(): NavState[] {
    const subStates: NavState[] = [
      {
        translateKey: 'activeDirectory',
        envItems: [{ environment: Environment.kAD }] as EnvItems[],
        state: 'active-directory-recovery-search',
        dataId: 'recover-active-directory',
      },
      {
        translateKey: 'exchangeServer',
        envItems: [{ environment: Environment.kExchange }] as EnvItems[],
        state: 'recover-exchange-ng',
        dataId: 'recover-exchange',
      },
    ];

    return this.filterByCanAccess(subStates);
  }

  /**
   * Returns the sub application states for Office365.
   *
   * @returns   Array of NavState
   */
  getProtectOffice365SubStates(): NavState[] {
    const office365SubStates: NavState[] = [
      !isDmsScope(this.irisContextService.irisContext) && {
        translateKey: 'group',
        dataId: 'create-office365-group-protection-group',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Group }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kO365', 'kO365Group'],
          office365WorkloadType: 'kGroups',
        },
      },
      {
        translateKey: 'mailbox',
        dataId: 'create-office365-exchange-protection-group',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Group }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kO365', 'kO365Outlook'],
          office365WorkloadType: 'kMailbox',
        },
      },
      {
        translateKey: 'oneDrive',
        dataId: 'create-office365-oneDrive-protection-group',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Outlook }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kO365', 'kO365Outlook'],
          office365WorkloadType: 'kOneDrive',
        },
      },
      !isDmsScope(this.irisContextService.irisContext) && {
        translateKey: 'publicFolder',
        dataId: 'create-office365-public-folder-protection-group',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365PublicFolders }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kO365', 'kO365PublicFolders'],
          office365WorkloadType: 'kPublicFolders',
        },
      },
      {
        translateKey: 'sites',
        dataId: 'create-office365-sharepoint-protection-group',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Outlook }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kO365', 'kO365Outlook'],
          office365WorkloadType: 'kSharePoint',
        },
      },
      {
        translateKey: 'teams',
        dataId: 'create-office365-ms-teams-protection-group',
        envItems: [{ environment: Environment.kO365 }] as EnvItems[],
        state: 'protection-builder',
        stateParams: {
          environments: ['kO365'],
          office365WorkloadType: 'kTeams',
        },
      }
    ].filter(Boolean);
    return this.filterByCanAccess(office365SubStates);
  }

  /**
   * Returns the sub application states for Office365 recovery.
   *
   * @returns   Array of NavState
   */
  getRecoverOffice365SubStates(): NavState[] {
    const isSharePointNgRecoveryEnabled = flagEnabled(this.irisCtx.irisContext,
      isDmsScope(this.irisContextService.irisContext) ?
      'office365NgRecoverySharePointEnabled' :
      'office365NgRecoverSharePointOnPrem'
    );

    const office365RecoveryStates: NavState[] = [
      {
        translateKey: 'groups',
        dataId: 'recover-office365-groups',
        state: 'recover-office365-ng',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Group }] as EnvItems[],
        stateParams: {
          office365WorkloadType: Office365BackupType.kGroups,
        },
      },
      flagEnabled(this.irisCtx.irisContext, 'office365NgRecoveryMailboxesEnabled') ?
      {
        translateKey: 'mailboxes',
        dataId: 'recover-office365-mailboxes',
        envItems: [{ environment: Environment.kO365 }] as EnvItems[],
        state: 'recover-office365-ng',
        stateParams: {
          office365WorkloadType: Office365BackupType.kMailbox,
        },
      } : {
        translateKey: 'mailboxes',
        dataId: 'recover-office365-mailboxes',
        envItems: [{ environment: Environment.kO365 }] as EnvItems[],
        state: 'recover-office365.search.mailboxes',
        stateParams: {
          office365WorkloadType: Office365BackupType.kMailbox,
        },
      },
      flagEnabled(this.irisCtx.irisContext, 'office365NgRecoveryOneDrivesEnabled') ?
      {
        translateKey: 'oneDrive',
        dataId: 'recover-office365-one-drives',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365OneDrive }] as EnvItems[],
        state: 'recover-office365-ng',
        stateParams: {
          office365WorkloadType: Office365BackupType.kOneDrive,
        },
      } : {
        translateKey: 'oneDrive',
        dataId: 'recover-office365-one-drives',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365OneDrive }] as EnvItems[],
        state: 'recover-office365.search.onedrives',
        stateParams: {
          office365WorkloadType: Office365BackupType.kOneDrive,
        },
      },
      {
        translateKey: 'publicFolder',
        dataId: 'recover-office365-public-folder',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365PublicFolders }] as EnvItems[],
        state: 'recover-office365-ng',
        stateParams: {
          office365WorkloadType: Office365BackupType.kPublicFolders,
        },
      },
      isSharePointNgRecoveryEnabled ?
      {
        translateKey: 'sites',
        dataId: 'recover-office365-sharepoint',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Sharepoint }] as EnvItems[],
        state: 'recover-office365-ng',
        stateParams: {
          office365WorkloadType: Office365BackupType.kSharePoint,
        },
      } : {
        translateKey: 'sites',
        dataId: 'recover-office365-sharepoint-online',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Sharepoint }] as EnvItems[],
        state: 'recover-office365.search.sharePoint',
        stateParams: {
          office365WorkloadType: Office365BackupType.kSharePoint,
        },
      },
      {
        translateKey: 'teams',
        dataId: 'recover-office365-teams',
        envItems: [{ environment: Environment.kO365 }, { environment: Environment.kO365Teams }] as EnvItems[],
        state: 'recover-office365-ng',
        stateParams: {
          office365WorkloadType: Office365BackupType.kTeams,
        },
      }
    ];
    return this.filterByCanAccess(office365RecoveryStates);
  }


  /**
   * Get the list of states for Register Source button.
   *
   * @param   contextType   The type of context required.
   * @returns List of states for menu items of Register Source button.
   */
  getRegisterSourceStates(contextType = AccessContextType.Default): NavState[] {
    const result = [
      {
        id: 'register-hypervisor',
        translateKey: 'virtualMachines',
        state: 'hypervisor-new',
        envItems: envGroups.vms.map(env => ({ environment: env })),
      },
      {
        id: 'register-databases',
        translateKey: 'databases',
        state: 'sources.source-landing',
        subStates: [
          {
            id: 'register-cassandra',
            translateKey: 'cassandra',
            dialog: 'register-cassandra-dialog',
            envItems: [{ environment: Environment.kCassandra }] as EnvItems[],
          },
          flagEnabled(this.irisCtx.irisContext, 'enableLegacyCouchbase') && {
            id: 'register-couchbase',
            translateKey: 'couchbase',
            dialog: 'register-couchbase-dialog',
            envItems: [{ environment: Environment.kCouchbase }] as EnvItems[],
          },
          {
            id: 'register-mongodb',
            translateKey: 'mongodb',
            dialog: 'register-mongodb-dialog',
            envItems: [{ environment: Environment.kMongoDB }] as EnvItems[],
          },
          flagEnabled(this.irisCtx.irisContext, 'mongodbPhysicalProtection') && {
            id: 'register-mongodb-physical',
            translateKey: 'mongodbOpsManager',
            dialog: 'register-mongodb-physical-dialog',
            envItems: [{ environment: Environment.kMongoDBPhysical }] as EnvItems[],
          },
          {
            id: 'register-mssql',
            translateKey: 'msSqlServer',
            state: 'mssql-register',
            envItems: [{ environment: Environment.kSQL }] as EnvItems[],
          },
          flagEnabled(this.irisCtx.irisContext, 'sqlApplicationDiscovery') ? {
            id: 'register-mssql-new',
            translateKey: 'msSqlClusterEarlyAccess',
            dialog: 'register-sql-dialog',
            envItems: [{ environment: Environment.kSQL }] as EnvItems[],
          } : {
            id: 'register-mssql-cluster',
            translateKey: 'msSqlCluster',
            state: 'sql-cluster-new',
            envItems: [{ environment: Environment.kSQL }] as EnvItems[],
          },
          {
            id: 'register-oracle',
            translateKey: 'oracleSource',
            state: 'oracle-register',
            envItems: [{ environment: Environment.kOracle }] as EnvItems[],
          },
          flagEnabled(this.irisCtx.irisContext, 'oracleClusterRegistration') && {
            id: 'register-oracle-new',
            translateKey: 'oracleCluster',
            state: 'oracle-register',
            stateParams: {
              registrationType: 'kOracleCluster'
            },
            envItems: [{ environment: Environment.kOracle }] as EnvItems[],
          } as NavState
        ],
      },
      flagEnabled(this.irisCtx.irisContext, 'nasNgRegistration') ? {
        id: 'register-nas',
        translateKey: 'nas',
        dialog: 'register-nas-dialog',
        envItems: nasSources,
      } : {
        id: 'register-nas',
        translateKey: 'nas',
        state: 'nas-new',
        envItems: nasSources,
      },
      flagEnabled(this.irisCtx.irisContext, 'office365NgRegistration') ? {
        id: 'register-o365',
        translateKey: 'microsoft365',
        dialog: 'register-office365-dialog',
        envItems: [{ environment: Environment.kO365 }] as EnvItems[],
      } : {
        id: 'register-o365',
        translateKey: 'microsoft365',
        state: 'office365-register',
      },
      flagEnabled(this.irisCtx.irisContext, 'ngRegisterPhysicalDialogEnabled') ? {
        id: 'register-physical-server',
        translateKey: 'physicalServer',
        dialog: 'register-physical-dialog',
        envItems: [{ environment: Environment.kPhysical }] as EnvItems[],
      } : {
        id: 'register-physical-server',
        translateKey: 'physicalServer',
        state: 'physical-new',
      },
      {
        id: 'register-application',
        translateKey: 'applications',
        state: 'sources.source-landing',
        subStates: [
          {
            id: 'register-ad',
            translateKey: 'activeDirectory',
            state: 'active-directory-new',
          },
          {
            id: 'register-exchange',
            translateKey: 'exchangeServer',
            dialog: 'register-exchange-dialog',
            envItems: [{ environment: Environment.kExchange }] as EnvItems[],
          },
        ],
      },
      hasSourceModifyPrivilege(this.userService.privs, 'storageArray') && {
        id: 'storage-array',
        translateKey: 'san',
        dialog: 'register-san-dialog',
        envItems: storageArraySources,
      },
      {
        id: 'register-hadoop',
        translateKey: 'Hadoop',
        dialog: 'register-hadoop-dialog',
        envItems: hadoopEnvItems,
      },
      {
        id: 'register-kubernetes',
        translateKey: 'kubernetesCluster',
        dialog: 'register-kubernetes-dialog',
        envItems: [{ environment: Environment.kKubernetes }] as EnvItems[],
      },
      {
        id: 'register-storage-snapshot',
        translateKey: 'storageSnapshotProvider',
        state: 'storage-snapshot-new',
        envItems: [{ environment: Environment.kStorageSnapshotProvider }] as EnvItems[]
      },
      {
        id: 'register-uda',
        translateKey: 'universalDataAdapter',
        dialog: 'register-uda-dialog',
        envItems: [{ environment: Environment.kUDA }] as EnvItems[],
      },
      flagEnabled(this.irisCtx.irisContext, 'dmsSfdcRegistration') && {
        id: 'register-sfdc',
        translateKey: 'salesforce',
        dialog: 'add-dms-sfdc-dialog',
        envItems: [{ environment: Environment.kSfdc }] as EnvItems[],
      },
      flagEnabled(this.irisCtx.irisContext, 'agentManagementPhysicalDeployEnabled') && {
        id: 'deploy-agent',
        translateKey: 'deployCohesityAgent',
        state: 'agent.deploy',
        stateParams: {isPhysicalDeploy: true},
        dividerBefore: true,
      }
    ];
    return this.filterByCanAccess(result, contextType);
  }

  /**
   * Get the list of states for Clone button.
   *
   * @returns List of states for menu items of Clone button.
   */
  getCloneStates(): NavState[] {
    return this.filterByCanAccess([
      this.ajsClusterService.clusterInfo._isDisaggregatedStorage ? null : {
        translateKey: 'view',
        state: 'clone-view.search',
      },
      {
        translateKey: 'vms',
        state: 'clone-vms',
      },
      {
        translateKey: 'databases',
        state: 'clone-db',
        stateParams: {dbType: 'databases'},
      },
    ]);
  }

  /**
   * Get the list of source types for Nas.
   *
   * @params workflow The workflow.
   * @returns list of source types for Nas.
   */
  getNasSourceTypes(workflow?: Workflow) {
    return this.adaptorAccessService.filterByAccessibleEnvItems(nasSources, workflow);
  }

  /**
   * Get the list of source types for Hypervisor.
   *
   * @returns list of source types for Hypervisor.
   */
  getHypervisorSourceTypes() {
    return this.adaptorAccessService.filterByAccessibleEnvItems(hypervisorSources);
  }

  /**
   * Get the list of all object types.
   *
   * @returns list of all object types.
   */
  getObjectTypes() {
    const objectTypes = [
      {
        name: 'VMware',
        environment: Environment.kVMware,
      },
      {
        name: 'HyperV',
        environment: Environment.kHyperV,
      },
      {
        name: 'MS SQL',
        environment: Environment.kSQL,
      },
      {
        name: 'View',
        environment: Environment.kView,
      },
      {
        name: 'Physical Server',
        environment: Environment.kPhysical,
      },
      {
        name: 'Pure',
        environment: Environment.kPure,
      },
      ...nasSources.map(source => ({
        name: this.translateService.instant(NasSourceNames[source.environment]),
        environment: source.environment,
      })),
      {
        name: 'Acropolis',
        environment: Environment.kAcropolis,
      },
      {
        name: 'HyperVVSS',
        environment: Environment.kHyperVVSS,
      },
      {
        name: 'Oracle',
        environment: Environment.kOracle,
      },
      {
        name: 'Active Directory',
        environment: Environment.kAD,
      },
      {
        name: 'Kubernetes',
        environment: Environment.kKubernetes,
      },
      {
        name: 'Nimble',
        environment: Environment.kNimble,
      },
      {
        name: 'HPE Primera / Alletra',
        environment: Environment.kHpe3Par,
      },
      {
        name: 'Cassandra',
        environment: Environment.kCassandra,
      },
      {
        name: 'Couchbase',
        environment: Environment.kCouchbase,
      },
      {
        name: 'HBase',
        environment: Environment.kHBase,
      },
      {
        name: 'HDFS',
        environment: Environment.kHdfs,
      },
      {
        name: 'Hive',
        environment: Environment.kHive,
      },
      {
        name: 'MongoDB',
        environment: Environment.kMongoDB,
      },
      {
        name: 'Universal Data Adapter',
        environment: Environment.kUDA,
      },
      {
        name: 'Salesforce',
        environment: Environment.kSfdc,
      },
    ];

    return this.adaptorAccessService.filterByAccessibleEnvItems(<EnvItems[]>objectTypes)
      .map(({ name, environment }: any) => ({
        name,
        enum: environment,
        type: envTypes[environment]
      }));
  }

  /**
   * Comparator function to sort the sub menus alphabetically based on the
   * translate key
   *
   * @param     navStateA   Specifies an instance of NavState
   * @param     navStateB   Specifies an instance of NavState
   * @returns   Returns x where x is an integer within [-1, 1]
   */
  private compareNavState(navStateA: NavState, navStateB: NavState): number {
    if (navStateA.translateKey < navStateB.translateKey) {
      return -1;
    }
    if (navStateA.translateKey > navStateB.translateKey) {
      return 1;
    }
    return 0;
  }
}
