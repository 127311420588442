import { assign } from 'lodash-es';
// Component: SQL Management: Register/Modify SQL Server

(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.sql-manager';

  angular
    .module(moduleName)
    .controller('modifySQLRegistrationController',
      modifySQLRegistrationControllerFn);

  /**
   * Controller Fn
   *
   * @method   modifySQLRegistrationControllerFn
   */
  function modifySQLRegistrationControllerFn($rootScope, $scope, $interpolate,
    SourceService, $state, evalAJAX, $stateParams, cMessage,
    StateManagementService, AdaptorAccessService, ENV_TYPE_CONVERSION) {
    angular.extend($scope, {
      // General Scope Vars
      mode: $stateParams.mode || 'new',
      host: $stateParams.host || {
        credentials: undefined,
        appEnvVec: [3],
        usesPersistentAgent: true,
      },
      applicationEnvs: [],

      // Text Strings
      text: $rootScope.text.protectionSourcesSqlManagerSqlModifyText,

      // Errors
      errorText: $rootScope.text.protectionSourcesSqlManagerSqlModify1ErrorText,

      // Scope Methods
      cancel: goBack,
      downloadAgents: SourceService.downloadAgentsModal,
      isLoading: isLoading,
      registerAppOwner: registerAppOwner,
      toggleManagedAgent: toggleManagedAgent,

      // Applications
      apps: {
        msSql: false,
        msExchange: false,
      },
    });

    /**
     * Initialize all the things!
     *
     * @method     activate
     */
    function activate() {
      // Set the mode of this view+controller (New or Edit)
      $scope.isNewMode = $scope.mode === 'new';
      $scope.mode = $scope.isNewMode ? 'new' : 'edit';

      // If the host was provided then we don't need to look anything else up.
      // Exit early.
      if ($stateParams.host) {
        initialize();
        $scope.apps.msSql = $scope.host.appEnvVec.includes(3);
        $scope.apps.msExchange = $scope.host.appEnvVec.includes(17);
        return;
      }

      if ($stateParams.entityId) {
        // Fetch details of this entity?
        getEntityInfo($stateParams.entityId);
      }

      if (!$scope.isNewMode) {
        // If username is not stored, then persistent agent is enabled
        $scope.host.usesPersistentAgent = !$stateParams.username;

        $scope.host.credentials = $scope.host.usesPersistentAgent ?
          undefined :
          { username: $stateParams.username };
      }

    }

    /**
     * Toggles credentials object between {} and undefined when toggling
     * persisten agent (managed agent).
     *
     * @method    toggleManagedAgent
     * @param     {boolean}   agentIsManaged   The user's choice.
     */
    function toggleManagedAgent(agentIsManaged) {
      // If usesPersistentAgent is checked, then delete credentials. Otherwise
      // stub the object.
      $scope.host.credentials = !agentIsManaged ? {} : undefined;
    }

    /**
     * Get the info for the Entity in question by ID
     *
     * @method   getEntityInfo
     * @param    {Integer}   id   The Entity Id
     * @return   {object}    The request promise
     */
    function getEntityInfo(id) {
      $scope.loadingEntity = true;

      return SourceService.getEntitiesById(id)
        .then(function entityReceived(resp) {
          $scope.host.ownerEntity = resp[0];
          initialize();
          return resp;
        }, evalAJAX.errorMessage)
        .finally(function entityFinally() {
          $scope.loadingEntity = false;
        });
    }

    /**
     * Initialize the component.
     */
    function initialize() {
      const hostEnvironment = $scope.host.ownerEntity.environment || ENV_TYPE_CONVERSION[$scope.host.ownerEntity.type];
      const applicationEnvItems = AdaptorAccessService.filterByAccessibleEnvItems([
        { environment: 'kSQL', hostEnvironment },
        { environment: 'kExchange', hostEnvironment },
      ]);
      const applicationEnvs = applicationEnvItems.map(envItem => envItem.environment);

      assign($scope, {
        applicationEnvs,
        apps: {
          msSql: (applicationEnvs.includes('kSQL') ? true : false),
          msExchange: (!applicationEnvs.includes('kSQL') && applicationEnvs.includes('kExchange')) ? true : false,
          },
      });
    }

    /**
     * Submit function for the flow. Depends on mode
     *
     * @method     registerAppOwner
     */
    function registerAppOwner() {
      setApplications();
      var serviceMethod = $scope.isNewMode ?
        'registerAppOwner' :
        'updateAppOwner';

      $scope.submitting = true;
      SourceService[serviceMethod]($scope.host)
        .then(function registerSuccessFn(resp) {
          // This server response is a simple boolean
          if (resp) {
            showSuccess();
            goBack();
          } else {
            // In the off chance the request was successful, but the response is
            // false, show an error message.
            modifyFailureFn(resp);
          }
        }, evalAJAX.errorMessage)
        .finally(function registrationDoneFn() {
          $scope.submitting = false;
        });
    }

    /**
     * Sets the applications array from UI
     *
     * @method     setApplications
     */
    function setApplications() {
      var appEnvVec = [];
      if ($scope.apps.msSql) {
        appEnvVec.push(3);
      }

      if ($scope.apps.msExchange) {
        appEnvVec.push(17);
      }
      $scope.host.appEnvVec = appEnvVec;
    }

    /**
     * Show a success cMessage
     *
     * @method     showSuccess
     */
    function showSuccess() {
      var message = $scope.isNewMode ?
        'modifyApplicationRegistration.cmessages.successTextNew' :
        'modifyApplicationRegistration.cmessages.successTextEdit';

      cMessage.success({
        textKey: message,
        textKeyContext: { entity: $scope.host.ownerEntity },
      });
    }

    /**
     * Convenience function to handle non-server-side response error
     *
     * @method     modifyFailureFn
     * @param      {Object}  resp    The server response
     */
    function modifyFailureFn(resp) {
      cMessage.error({
        textKey: 'modifyApplicationRegistration.cmessages.errorText',
        textKeyContext: $scope,
        persist: true,
      });
    }

    /**
     * Go back to the previous view
     *
     * @method     goBack
     */
    function goBack() {
      StateManagementService.goToPreviousState('sources-new');
    }

    /**
     * Convenience function to determine if anything is loading in this
     * view. True if loadingEntity or registering are true;
     *
     * @method     isLoading
     * @return     {boolean}  True if something is loading
     */
    function isLoading() {
      return ($scope.registering || $scope.loadingEntity);
    }


    // Initialize!
    activate();
  }

})(angular);
