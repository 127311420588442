import { Pipe, PipeTransform } from '@angular/core';
import { has, get, isEqual, filter, reject } from 'lodash-es'; // Import lodash-es utilities

/**
 * @ngdoc Pipe
 * @description
 *    Filters or rejects items from a list based on the given predicate and mode.
 *    - 'filter': keeps items that match the predicate.
 *    - 'reject': removes items that match the predicate.
 *    Supports both object predicates (including nested paths) and custom function predicates.
 *
 * @example (basic)
 *    Input: [{ hidden: true }, { hidden: false }]
 *    Predicate: { hidden: true }
 *    Mode: 'filter'
 *    Output: [{ hidden: true }]
 *
 * @example (nested paths)
 *    Input: [{ user: { details: { age: 30 } } }, { user: { details: { age: 25 } } }]
 *    Predicate: { 'user.details.age': 25 }
 *    Mode: 'reject'
 *    Output: [{ user: { details: { age: 30 } } }]
 *
 * @example (arrays)
 *    Input: [{ tags: ['angular', 'typescript'] }, { tags: ['react'] }]
 *    Predicate: { tags: ['angular'] }
 *    Mode: 'reject'
 *    Output: [{ tags: ['react'] }] (removes items with 'angular' in tags array)
 *
 * @example (null values)
 *    Input: [{ age: null }, { age: undefined }, { age: 25 }]
 *    Predicate: { age: null }
 *    Mode: 'filter'
 *    Output: [{ age: null }] (retains items where age is exactly null)
 *
 *    Mode: 'reject'
 *    Output: [{ age: undefined }, { age: 25 }] (removes items where age is exactly null)
 */
@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform<T>(
    items: T[],
    predicate: Record<string, any> | ((item: T) => boolean),
    mode: 'filter' | 'reject' = 'filter'
  ): T[] {
    if (!items) {
      return [];
    }

    if (!predicate) {
      // Return all items for 'filter', empty array for 'reject'
      return mode === 'filter' ? items : [];
    }

    const filterLogic = (item: T): boolean => {
      // If predicate is a custom function, apply it directly
      if (typeof predicate === 'function') {
        return predicate(item);
      }

      // For each key in the predicate, ensure that:
      // 1. The key either doesn't exist on the item (i.e., item does not have the property), OR
      // 2. The value of the key on the item is not equal to the value in the predicate
      for (const key in predicate) {
        if (!has(item, key)) {
          continue;
        }

        const itemValue = get(item, key as keyof T);
        const predicateValue = get(predicate, key as keyof T);

        // Array handling: if both item and predicate values are arrays, check for matching values
        if (Array.isArray(predicateValue) && Array.isArray(itemValue)) {
          if (predicateValue.some(value => itemValue.includes(value))) {
            return true;
          }
        }

        // For primitive values or non-array types, match based on equality
        if (isEqual(itemValue, predicateValue)) {
          return true;
        }
      }

      // Item is included or excluded from the list based on the selected mode
      return false;
    };

    // Use lodash's filter or reject based on the mode
    return mode === 'reject' ? reject(items, filterLogic) : filter(items, filterLogic);
  }
}
