import { assign } from 'lodash-es';
// Component: c-pre-rendered-modal used to show pre-rendered element inside
// the modal and will prevent re-rendering of that element when showing in the
// modal.

// NOTE: the pre-rendered element will be manually removed from the its original
// location and will be placed in the modal and this will break any page level
// or contextual CSS styling done for that element or its descendent elements
// thats why ensure pre-rendered element uses the global styling or page level
// styling override classes are directly applied to that element.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters')
    .controller('PreRenderedModalCtrl', PreRenderedModalCtrlFn)
    .component('cPreRenderedModal', {
      bindings: {
        /**
         * selector to reach pre-rendered element that needed to be shown in the
         * modal and it should be compatible with angular.element(...)
         *
         * @type   {String}
         */
        selector: '<',
      },
      controller: 'PreRenderedModalCtrl',
    });

  /**
   * $ngdoc Component
   * @name C.cFilters:cPreRenderedModal
   *
   * @description
   *   Show pre-rendered element in the modal and put it back on modal close.
   */
  function PreRenderedModalCtrlFn(_, $scope, $element) {
    var $ctrl = this;

    var preRenderedEl;
    var preRenderedLocationEl;

    assign($ctrl, {
      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    /**
     * Initialize the c-pre-rendered-modal.
     *
     * @method   $onInit
     */
    function $onInit() {
      // finding pre-rendered element.
      preRenderedEl = angular.element($ctrl.selector);

      // wrapping original location to keep track of dropping location for
      // pre-rendered element.
      preRenderedEl.wrap('<c-pre-rendered-modal-location />');

      // cache the dropping location.
      preRenderedLocationEl = preRenderedEl.parent();

      // attaching pre-rendered element to the modal.
      $element.append(preRenderedEl);

      // put back the pre-rendered element to its original location before modal
      // closes to prevent it from destroying because of modal destroy event.
      $ctrl.deRegistration = $scope.$on('modal.closing', putItBack);
    }

    /**
     * Cleanup on c-pre-rendered-modal component destroy.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      $ctrl.deRegistration();
    }

    /**
     * Put the pre-rendered element to it original location.
     *
     * @method   putItBack
     */
    function putItBack() {
      preRenderedLocationEl.replaceWith($element.children());
    }
  }

})(angular);
