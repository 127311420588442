import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ClusterScanResult } from '@cohesity/api/secops';
import { MomentDatePipe } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { SeriesBarOptions } from 'highcharts';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { getRiskProperties } from '../../../utils';

/**
 * Interface for component data
 */
export interface ClusterRiskDistribution {
  scanResults: ClusterScanResult[];
  lastScanTime: number;
}

/**
 * @description
 * Cluster Risk Distribution component visualizes the system across connected clusters .
 *
 * @example
 *   <dg-sc-cluster-risk-distribution [data]="data"></dg-sc-cluster-risk-distribution>
 */
@Component({
  selector: 'dg-sc-cluster-risk-distribution',
  templateUrl: './cluster-risk-distribution.component.html',
  styleUrls: ['./cluster-risk-distribution.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClusterRiskDistributionComponent extends AutoDestroyable implements OnInit {
  /**
   * Bar chart series data.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('data') data$: BehaviorSubject<ClusterRiskDistribution>;

  /**
   * Loading state of the card.
   */
  @Input() loading = false;

  /**
   * Data for the chart.
   */
  seriesData: SeriesBarOptions = {type: 'bar'};

  /**
   * Categories (x axis) for chart data.
   */
  categories: string[];

  /**
   * Custom chart options.
   */
  customOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 220,
      styledMode: true,
      className: 'cluster-risk-distribution-chart',
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      visible: true,
      title: {
        text: '',
      },
      allowDecimals: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        pointWidth: 12,
        groupPadding: 1,
        pointPadding: 1,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none',
          },
          formatter() {
            return this.point.options?.custom?.yFormattedValue || this.point.y;
          },
        },
        cursor: 'pointer',
      },
    },
  };

  /**
   * The last scan time from data.
   */
  lastScanTime = '';

  constructor(readonly momentDatePipe: MomentDatePipe,readonly translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.data$.pipe(
      filter(data => !!data.scanResults),
      this.untilDestroy()
    ).subscribe(data => {
      this.createChartData(data.scanResults);
      this.lastScanTime = this.translate.instant('dg.sc.dashboard.latestScanColon', {
        time: this.momentDatePipe.transform(data.lastScanTime),
      });
    });
  }

  /**
   * Create data to be displayed in chart
   *
   * @param scanResults The scanResults which need to be converted to chart data
   */
  createChartData(scanResults: ClusterScanResult[]) {
    let highRiskCount = 0, mediumRiskCount = 0, lowRiskCount = 0;
    const highRiskStr = getRiskProperties(this.translate, 70)?.str;
    const mediumRiskStr = getRiskProperties(this.translate, 90)?.str;
    const lowRiskStr = getRiskProperties(this.translate, 100)?.str;

    this.categories = [highRiskStr, mediumRiskStr, lowRiskStr];

    scanResults.forEach(res => {
      switch (getRiskProperties(this.translate, res.currentScore)?.class) {
        case 'high':
          highRiskCount += 1;
          break;
        case 'medium':
          mediumRiskCount += 1;
          break;
        case 'low':
          lowRiskCount += 1;
          break;
      }
    });

    this.seriesData = {...this.seriesData, ...{
      data: [
        {
          y: highRiskCount,
          custom: {
            category: highRiskStr
          },
        },
        {
          y: mediumRiskCount,
          custom: {
            category: mediumRiskStr
          }
        },
        {
          y: lowRiskCount,
          custom: {
            category: lowRiskStr
          }
        },
      ]
    }};
  }
}
