import { BasicClusterInfo } from '@cohesity/api/v1';
import { get } from 'lodash-es';
import { IrisContext } from './iris-context.model';

/**
 * Returns true if the environment is IBM BaaS based on basicClusterInfo API call.
 *
 * @param basicClusterInfo BasicClusterInfo object.
 * @returns true if the environment is IBM BaaS.
 */
export function clusterIsIbmBaas(basicClusterInfo: BasicClusterInfo): boolean {
  return basicClusterInfo?.['clusterDeploymentType'] === 'kIBMBaaS';
}

/**
 * Get the remote access cluster id. This is only set if the current scope is differrent from the accessCluster.
 *
 * @param irisContext The current iris context.
 * @param key configuration key
 * @param defaults default value if there's no value in configuration
 *
 * @returns the id of the remote access cluster or undefined.
 */
export function getConfigByKey<T>(irisContext: IrisContext, key: string, defaults: T): T {
  const config = irisContext?.appConfiguration ?? {};
  return <T>get(config, key, defaults);
}
