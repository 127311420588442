import { merge } from 'lodash-es';
import { values } from 'lodash-es';
import { isArray } from 'lodash-es';
import { forEach } from 'lodash-es';
// Decorator: decorator $q to pass through .abort in http promise

;(function(angular, undefined) {

  angular.module('C.appConfigs')
    .config(promiseDecoratorConfig);

  function promiseDecoratorConfig($provide) {
    $provide.decorator('$q', promiseDecorator);
  }

  /**
   * decorate $q promise and allow us to add internal methods inside the a
   * promise object and pass through $http.abort from parent promise to child
   * if exist in parent.
   *
   * $http(reqConfig).then(...).catch(...).finally(...);
   * then/catch/finally will create and inherited new promise from there parent
   * so to pass through $http(...).abort we are using this decorator.
   */
  function promiseDecorator(_, $delegate) {
    var defer = $delegate.defer;
    var when = $delegate.when;
    var reject = $delegate.reject;
    var resolve = $delegate.resolve;
    var all = $delegate.all;

    // setup deferred promise creator methods
    $delegate.defer = function deferDecorated() {
      var deferred = defer();

      decoratePromise(deferred.promise);
      return deferred;
    };
    $delegate.when = function whenDecorated() {
      return decoratePromise(when.apply(this, arguments));
    };
    $delegate.reject = function rejectDecorated() {
      return decoratePromise(reject.apply(this, arguments));
    };
    $delegate.all = function allDecorated(promiseMapOrList) {
      var newPromise =  decoratePromise(all.apply(this, arguments));
      var isOnePromiseAborted = false;

      // abort all the promises when aggregated promise is aborted.
      newPromise.abort = function abortAllPromise() {
        forEach(promiseMapOrList, function eachPromise(promise) {
          if (promise.abort) {
            promise.abort();
          }
        });

        // to allow chaining.
        return this;
      };

      // by using onAbort capture the callback function which will be called
      // when the aggregated promise is aborted, use this to perform cleanups.
      newPromise.onAbort = function onAbortAllPromises(callback) {
        forEach(promiseMapOrList, function eachPromise(promise) {
          // abort aggregated promise when any one of child promise is aborted.
          promise.onAbort(function abortAllPromises() {
            if (!isOnePromiseAborted) {
              // don't allow other child promise to re-trigger call to onAbort.
              isOnePromiseAborted = true;

              // abort other child promises.
              newPromise.abort();

              // call for aggregated onAbort callback.
              callback();
            }
          });
        });

        // to allow chaining.
        return this;
      };

      return newPromise;
    };

    // Returns a promise which is resolved with array/object of each promise
    // state response when all the original promises have settled.
    // https://github.com/kriskowal/q/wiki/API-Reference#promiseallsettled
    $delegate.allSettled = function allSettled(promiseMapOrList) {
      var modPromiseMapOrList;

      // wrap original promises which will be resolved always
      if (isArray(promiseMapOrList)) {
        modPromiseMapOrList = promiseMapOrList.map(resolveAlways);
      } else {
        modPromiseMapOrList = {};
        forEach(promiseMapOrList, function eachPromise(value, key) {
          modPromiseMapOrList[key] = resolveAlways(value);
        });
      }

      return $delegate.all(modPromiseMapOrList);
    };

    /**
     * Returns the merged result (Object or List depending on the response) of
     * all the results of the promises which are provided as an object or list.
     *
     * @method   allWithMerge
     * @param    {Object | Array}   promiseMapOrList   The list/map of promises.
     * @returns  {Object | Array}   The merged results of all the promises.
     */
    $delegate.allWithMerge = function allWithMerge(promiseMapOrList) {
      return $delegate.all(promiseMapOrList)
        .then(function resolveAll(response) {
          // If the responses of the promises are array or not.
          var isArray = false;
          var result = {};

          forEach(response, function mapPromiseResults(res) {
            // Check if the response is an array.
            if (!isArray && isArray(res)) {
              isArray = true;
            }

            merge(result, res);
          });

          // If the responses are array, then return only the values, else
          // return the object
          return isArray ? values(result) : result;
        });
    };

    /**
     * Return a wrapped promise which will be resolved irrespective of original
     * promise resolution success, failed or abort.
     *
     * @method   resolveAlways
     *
     * @param    {Object}   promise
     * @return   {Object}   wrapped promise which will be resolve always
     *                      irrespective of provided promise resolution success,
     *                      failed or abort.
     */
    function resolveAlways(promise) {
      var newPromise = promise
        .then(function resolveOnSuccess(resp) {
          return resolve({resp: resp, $status: 'success'});
        })
        .catch(function resolveOnError(resp) {
          return resolve({resp: resp, $status: 'error'});
        });

      if (newPromise.onAbort) {
        newPromise = newPromise.onAbort(function resolveOnAbort(resp) {
          return resolve({resp: resp, $status: 'abort'});
        });
      }

      return newPromise;
    }

    // new promise constructor fn
    function decoratePromise(promise) {
      // alias of original promise.then
      promise._then = promise.then;

      promise.then = function thenDecorated() {
        var newPromise = decoratePromise(promise._then.apply(this, arguments));

        // inherit abort & onAbort from parent promise if exist else undefined
        newPromise.abort = promise.abort ? function newAbort() {
          promise.abort();
          return this;
        } : undefined;

        newPromise.onAbort = promise.onAbort ? function newOnAbort(callback) {
          promise.onAbort(callback);
          return this;
        } : undefined;

        return newPromise;
      };

      return promise;
    }

    return $delegate;
  }

})(angular);
