import { merge } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: View External Provider

;(function(angular) {
  'use strict';

  angular.module('C.antivirus')
    .controller('ViewAvProviderCtrl', ViewAvProviderCtrlFn);

  function ViewAvProviderCtrlFn(_, $state, evalAJAX, AntivirusService) {

    var $ctrl = this;

    assign($ctrl, {
      provider: {},
      loadingViews: true,
      securedViewsCount: 0,

      $onInit: $onInit,
      checkIcapConnectionStatus: checkIcapConnectionStatus,
      enableDisableProvider: enableDisableProvider,
      removeProvider: removeProvider,
    });

    /**
     * Calls when component is initialized
     *
     * @method   $onInit
     */
    function $onInit() {
      _fetchProvider();
      _fetchSecuredViews();
    }

    /**
     * Fetches details for the Antivirus Provider ID in state params.
     *
     * @method    _fetchProvider
     */
    function _fetchProvider() {
      $ctrl.fetchedProvider = false;
      AntivirusService.getAvProvider($state.params.id).then(
        function gotAvProviderSuccess(provider) {
          // Deep merge because we don't want to overwrite connection status
          // decorations on the `antivirusServices` property.
          merge($ctrl.provider, provider);
        },
        evalAJAX.errorMessage
      ).finally(function gotAvProviderFinally() {
        $ctrl.fetchedProvider = true;
      });
    }

    /**
     * Fetches the list of Secured Views
     *
     * @method   _fetchSecuredViews
     */
    function _fetchSecuredViews() {
      $ctrl.loadingViews = true;

      AntivirusService.getSecuredViews().then(
        function getSecuredViewsSuccess(views) {
          $ctrl.securedViewsCount = views.length;
        },
        evalAJAX.errorMessage
      ).finally(function getSecuredViewsDone() {
        $ctrl.loadingViews = false;
      });
    }

    /**
     * Checks connection status of ICAP servers.
     *
     * @method   checkIcapConnectionStatus
     * @param    {Object}    provider    Provider group config object.
     */
    function checkIcapConnectionStatus(provider) {
      provider._fetchingIcapStatus = true;
      AntivirusService.checkIcapConnectionStatus(provider).finally(
        function checkedStatusFinally() {
          provider._fetchingIcapStatus = false;
        }
      );
    }

    /**
     * Wrapper function for service function which Enables or Disables an
     * Antivirus provider, but before enabling first checks connection status of
     * ICAP servers.
     *
     * @method    enableDisableProvider
     * @param     {Object}   provider    Provider group to be toggled.
     */
    function enableDisableProvider(provider) {
      if (provider.isEnabled) {
        return;
      }
      // Otherwise check ICAP connections and enable.
      provider._fetchingIcapStatus = true;
      AntivirusService.checkConnectionAndEnableAvProvider(
        provider, _toggleProvider).finally(function fetchedStatusFinally() {
          provider._fetchingIcapStatus = false;
        });
    }

    /**
     * Toggles the state of an Antivirus Provider and updates the list of
     * providers.
     *
     * @method    _toggleProvider
     * @param     {Object}     provider    Provider group to be toggled.
     */
    function _toggleProvider(provider) {
      AntivirusService.setAvProviderState({
        id: provider.id,
        enable: !provider.isEnabled,
      }).then(_fetchProvider, evalAJAX.errorMessage);
    }

    /**
     * Removes an Antivirus Provider and redirects to list of providers.
     *
     * @method    removeProvider
     * @param     {Object}   provider    Provider group to be deleted.
     */
    function removeProvider(provider) {
      if (provider.isEnabled) {
        return;
      }

      AntivirusService.confirmDeleteAvProvider(provider).then(
        function removedProvider(resp) {
          if (resp) {
            $state.go('antivirus.providers');
          }
        }
      );
    }
  }

})(angular);
