import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ObjectEnvironmentArchivalStats } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';

@Component({
  selector: 'dg-sc-rpaas-protection-card',
  templateUrl: './rpaas-protection-card.component.html',
  styleUrls: ['./rpaas-protection-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RpaasProtectionCardComponent implements OnChanges {
  /**
   * Archival run stats used to build the chart.
   */
  @Input() envArchivalStats: ObjectEnvironmentArchivalStats[];

  /**
   * Indicates if the chart data is ready for display. This processing is nearly
   * instantaneous, but gating the content is necessary otherwise highcharts
   * does not display the chart correctly.
   */
  dataReady = false;

  /**
   * Aggregate number of CloudVault attempts that succeeded in the provided data.
   */
  successfulCloudVaults = 0;

  /**
   * Aggregate number of CloudVault attempts that failed in the provided data.
   */
  unsuccessfulCloudVaults = 0;


  /**
   * Bar chart categories to be populated based on data.
   */
  barChartCategories: string[] = [];

  /**
   * The bar chart series options.
   */
  barSeriesOptions = [
    {
      // Stashing environments here so data can easily be matched to an environment value.
      environments: [],
      data: [],
      colorByPoint: true,
      dataGrouping: {
        anchor: 'start',
      },
    },
  ];

  /**
   * Overridess for chart options.
   */
  barCustomOptions = {
    chart: {
      // Allow room for largest dataLabel.
      spacingTop: 24,
    },
    tooltip: {
      // Tooltips are unnecessary as number and label are presented on chart.
      enabled: false,
    },
    yAxis: {
      endOnTick: false,
    },
    plotOptions: {
      column: {
        pointWidth: null,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        borderRadius: 0,
        maxPointWidth: 100,
      },
      series: {
        dataLabels: {
          enabled: true,
          useHTML: true,
          inside: false,
          crop: false,
          overflow: 'allow',
          format: '{point.y}',
        },
        states: {
          hover: {
            enabled: false
          }
        }
      },
    },
  };

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.envArchivalStats?.currentValue) {
      this.transformData(changes.envArchivalStats.currentValue);
    } else if (changes.envArchivalStats?.firstChange && !changes.envArchivalStats?.currentValue) {
      // if we do not have any data due to api failure
      // set dataReady with zero stats.
      this.dataReady = true;
    }
  }

  /**
   * Takes data provided via Input() and transforms it for display.
   *
   * @param envArchivalStats   data provided to the component
   */
  private transformData(envArchivalStats: ObjectEnvironmentArchivalStats[]) {
    this.dataReady = false;

    // Reset the aggregate values.
    this.successfulCloudVaults = 0;
    this.unsuccessfulCloudVaults = 0;

    // Reset chart data
    this.barSeriesOptions[0].data.length = 0;

    envArchivalStats = sortBy(envArchivalStats, 'numSuccessfulObjects').reverse();

    envArchivalStats.filter(envStats => envStats.numSuccessfulObjects > 0).forEach(envStats => {
      const stringKey = envStats.environment === 'kO365'
        ? 'm365'
        : `enum.environment.${envStats.environment}`;

      const barItemsCount = this.barSeriesOptions[0].data.length;

      // Add the counts to the aggregate properties.
      this.successfulCloudVaults += envStats.numSuccessfulObjects || 0;
      this.unsuccessfulCloudVaults += envStats.numUnsuccessfulObjects || 0;

      if (barItemsCount < 3) {
        this.barSeriesOptions[0].data.push(envStats.numSuccessfulObjects || 0);
        this.barSeriesOptions[0].environments.push(envStats.environment);
        this.barChartCategories.push(this.translate.instant(stringKey));
      } else if (barItemsCount > 3) {
        // 'Other' has already been added, increment it.
        this.barSeriesOptions[0].data[3] += envStats.numSuccessfulObjects || 0;
      } else {
        this.barSeriesOptions[0].data.push(envStats.numSuccessfulObjects || 0);
        this.barChartCategories.push(this.translate.instant('other'));
        this.barSeriesOptions[0].environments.push('kOther');
      }
    });
    this.barSeriesOptions = [ ...this.barSeriesOptions ];

    this.dataReady = true;
  }


}
