/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ChangeProtectionJobStateParam } from '../models/change-protection-job-state-param';
import { ProtectionJob } from '../models/protection-job';
import { ProtectionJobRequestBody } from '../models/protection-job-request-body';
import { RunProtectionJobParam } from '../models/run-protection-job-param';
import { UpdateProtectionJobsState } from '../models/update-protection-jobs-state';
import { UpdateProtectionJobsStateRequestBody } from '../models/update-protection-jobs-state-request-body';
import { DeleteProtectionJobParam } from '../models/delete-protection-job-param';
import { ProtectionJobAuditTrail } from '../models/protection-job-audit-trail';
@Injectable({
  providedIn: 'root',
})
class ProtectionJobsService extends __BaseService {
  static readonly ChangeProtectionJobStatePath = '/public/protectionJobState/{id}';
  static readonly GetProtectionJobsPath = '/public/protectionJobs';
  static readonly CreateProtectionJobPath = '/public/protectionJobs';
  static readonly RunProtectionJobPath = '/public/protectionJobs/run/{id}';
  static readonly UpdateProtectionJobsStatePath = '/public/protectionJobs/states';
  static readonly GetProtectionJobByIdPath = '/public/protectionJobs/{id}';
  static readonly UpdateProtectionJobPath = '/public/protectionJobs/{id}';
  static readonly DeleteProtectionJobPath = '/public/protectionJobs/{id}';
  static readonly GetProtectionJobAuditPath = '/public/protectionJobs/{id}/auditTrail';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Pause future Runs or resume future Runs of the specified Protection Job.
   *
   * If the Protection Job is currently running (not paused) and true is passed in,
   * this operation stops any new Runs of this Protection Job
   * from stating and executing.
   * However, any existing Runs that were already executing will continue to run.
   * If this Projection Job is paused and false is passed in, this operation
   * restores the Job to a running state and new Runs are started as defined
   * by the schedule in the Policy associated with the Job.
   *
   * Returns success if the paused state is changed.
   * @param params The `ProtectionJobsService.ChangeProtectionJobStateParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  ChangeProtectionJobStateResponse(params: ProtectionJobsService.ChangeProtectionJobStateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionJobState/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Pause future Runs or resume future Runs of the specified Protection Job.
   *
   * If the Protection Job is currently running (not paused) and true is passed in,
   * this operation stops any new Runs of this Protection Job
   * from stating and executing.
   * However, any existing Runs that were already executing will continue to run.
   * If this Projection Job is paused and false is passed in, this operation
   * restores the Job to a running state and new Runs are started as defined
   * by the schedule in the Policy associated with the Job.
   *
   * Returns success if the paused state is changed.
   * @param params The `ProtectionJobsService.ChangeProtectionJobStateParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  ChangeProtectionJobState(params: ProtectionJobsService.ChangeProtectionJobStateParams): __Observable<null> {
    return this.ChangeProtectionJobStateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List Protections Jobs filtered by the specified parameters.
   *
   * If no parameters are specified, all Protection Jobs currently
   * on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ProtectionJobsService.GetProtectionJobsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneExcludedSourceIds`: If true, the list of exclusion sources will be omitted from the response.
   *   This can be used to improve performance when the exclusion sources are
   *   not needed.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protection Jobs.
   *   Only Jobs associated with the specified Policy ids, are returned.
   *
   * - `onlyReturnDataMigrationJobs`: OnlyReturnDataMigrationJobs specifies if only data migration jobs should be
   *   returned. If not set, no data migration job will be returned.
   *
   * - `onlyReturnBasicSummary`: if true then only job descriptions and the most recent run of the job
   *   will be returned.
   *
   * - `names`: Filter by a list of Protection Job names.
   *
   * - `isLastRunSlaViolated`: IsLastRunSlaViolated is the parameter to filter the Protection Jobs based
   *   on the SLA violation status of the last Protection Run.
   *
   * - `isDeleted`: If true, return only Protection Jobs that have been deleted but still
   *   have Snapshots associated with them.
   *   If false, return all Protection Jobs except those Jobs that have
   *   been deleted and still have Snapshots associated with them.
   *   A Job that is deleted with all its Snapshots is not returned for
   *   either of these cases.
   *
   * - `isActive`: Filter by Inactive or Active Jobs. If not set, all Inactive and
   *   Active Jobs are returned. If true, only Active Jobs are returned.
   *   If false, only Inactive Jobs are returned.
   *   When you create a Protection Job on a Primary Cluster
   *   with a replication schedule, the Cluster creates an
   *   Inactive copy of the Job on the Remote Cluster.
   *   In addition, when an Active and running Job is deactivated,
   *   the Job becomes Inactive.
   *
   * - `includeSourceNames`: If true, both names and ids of Sources inside the protection group will be
   *   sent in the response.
   *   If false, only Source ids will be sent in the response.
   *
   * - `includeRpoSnapshots`: If true, then the Protected Objects protected by RPO policies will also
   *   be returned.
   *
   * - `includeLastRunAndStats`: If true, return the last Protection Run of the Job and the summary stats.
   *
   * - `ids`: Filter by a list of Protection Job ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc.
   *   Only Jobs protecting the specified environment types are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionJobsResponse(params: ProtectionJobsService.GetProtectionJobsParams): __Observable<__StrictHttpResponse<Array<ProtectionJob>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pruneExcludedSourceIds != null) __params = __params.set('pruneExcludedSourceIds', params.pruneExcludedSourceIds.toString());
    (params.policyIds || []).forEach(val => {if (val != null) __params = __params.append('policyIds', val.toString())});
    if (params.onlyReturnDataMigrationJobs != null) __params = __params.set('onlyReturnDataMigrationJobs', params.onlyReturnDataMigrationJobs.toString());
    if (params.onlyReturnBasicSummary != null) __params = __params.set('onlyReturnBasicSummary', params.onlyReturnBasicSummary.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.isLastRunSlaViolated != null) __params = __params.set('isLastRunSlaViolated', params.isLastRunSlaViolated.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.includeSourceNames != null) __params = __params.set('includeSourceNames', params.includeSourceNames.toString());
    if (params.includeRpoSnapshots != null) __params = __params.set('includeRpoSnapshots', params.includeRpoSnapshots.toString());
    if (params.includeLastRunAndStats != null) __params = __params.set('includeLastRunAndStats', params.includeLastRunAndStats.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionJobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionJob>>;
      })
    );
  }
  /**
   * List Protections Jobs filtered by the specified parameters.
   *
   * If no parameters are specified, all Protection Jobs currently
   * on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ProtectionJobsService.GetProtectionJobsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pruneExcludedSourceIds`: If true, the list of exclusion sources will be omitted from the response.
   *   This can be used to improve performance when the exclusion sources are
   *   not needed.
   *
   * - `policyIds`: Filter by Policy ids that are associated with Protection Jobs.
   *   Only Jobs associated with the specified Policy ids, are returned.
   *
   * - `onlyReturnDataMigrationJobs`: OnlyReturnDataMigrationJobs specifies if only data migration jobs should be
   *   returned. If not set, no data migration job will be returned.
   *
   * - `onlyReturnBasicSummary`: if true then only job descriptions and the most recent run of the job
   *   will be returned.
   *
   * - `names`: Filter by a list of Protection Job names.
   *
   * - `isLastRunSlaViolated`: IsLastRunSlaViolated is the parameter to filter the Protection Jobs based
   *   on the SLA violation status of the last Protection Run.
   *
   * - `isDeleted`: If true, return only Protection Jobs that have been deleted but still
   *   have Snapshots associated with them.
   *   If false, return all Protection Jobs except those Jobs that have
   *   been deleted and still have Snapshots associated with them.
   *   A Job that is deleted with all its Snapshots is not returned for
   *   either of these cases.
   *
   * - `isActive`: Filter by Inactive or Active Jobs. If not set, all Inactive and
   *   Active Jobs are returned. If true, only Active Jobs are returned.
   *   If false, only Inactive Jobs are returned.
   *   When you create a Protection Job on a Primary Cluster
   *   with a replication schedule, the Cluster creates an
   *   Inactive copy of the Job on the Remote Cluster.
   *   In addition, when an Active and running Job is deactivated,
   *   the Job becomes Inactive.
   *
   * - `includeSourceNames`: If true, both names and ids of Sources inside the protection group will be
   *   sent in the response.
   *   If false, only Source ids will be sent in the response.
   *
   * - `includeRpoSnapshots`: If true, then the Protected Objects protected by RPO policies will also
   *   be returned.
   *
   * - `includeLastRunAndStats`: If true, return the last Protection Run of the Job and the summary stats.
   *
   * - `ids`: Filter by a list of Protection Job ids.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc.
   *   Only Jobs protecting the specified environment types are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionJobs(params: ProtectionJobsService.GetProtectionJobsParams): __Observable<Array<ProtectionJob>> {
    return this.GetProtectionJobsResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionJob>)
    );
  }

  /**
   * Create a Protection Job.
   *
   * Returns the created Protection Job.
   * @param params The `ProtectionJobsService.CreateProtectionJobParams` containing the following parameters:
   *
   * - `body`: Request to create a Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionJobResponse(params: ProtectionJobsService.CreateProtectionJobParams): __Observable<__StrictHttpResponse<ProtectionJob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionJobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionJob>;
      })
    );
  }
  /**
   * Create a Protection Job.
   *
   * Returns the created Protection Job.
   * @param params The `ProtectionJobsService.CreateProtectionJobParams` containing the following parameters:
   *
   * - `body`: Request to create a Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionJob(params: ProtectionJobsService.CreateProtectionJobParams): __Observable<ProtectionJob> {
    return this.CreateProtectionJobResponse(params).pipe(
      __map(_r => _r.body as ProtectionJob)
    );
  }

  /**
   * Immediately execute a single Protection Job Run.
   *
   * Immediately execute a single Job Run and ignore the schedule defined
   * in the Policy.
   * A Protection Policy associated with the Job may define up to three
   * backup run types:
   * 1) Regular (CBT utilized), 2) Full (CBT not utilized) and 3) Log.
   * The passed in run type defines what type of backup is done by the Job Run.
   * The schedule defined in the Policy for the backup run type is ignored but
   * other settings such as the snapshot retention and retry settings are used.
   * Returns success if the Job Run starts.
   * @param params The `ProtectionJobsService.RunProtectionJobParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `body`: Specifies the type of backup. If not specified, the 'kRegular'
   *   backup is run.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RunProtectionJobResponse(params: ProtectionJobsService.RunProtectionJobParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionJobs/run/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Immediately execute a single Protection Job Run.
   *
   * Immediately execute a single Job Run and ignore the schedule defined
   * in the Policy.
   * A Protection Policy associated with the Job may define up to three
   * backup run types:
   * 1) Regular (CBT utilized), 2) Full (CBT not utilized) and 3) Log.
   * The passed in run type defines what type of backup is done by the Job Run.
   * The schedule defined in the Policy for the backup run type is ignored but
   * other settings such as the snapshot retention and retry settings are used.
   * Returns success if the Job Run starts.
   * @param params The `ProtectionJobsService.RunProtectionJobParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `body`: Specifies the type of backup. If not specified, the 'kRegular'
   *   backup is run.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RunProtectionJob(params: ProtectionJobsService.RunProtectionJobParams): __Observable<null> {
    return this.RunProtectionJobResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Perform an action like pause, resume, activate, deactivate on all the specified
   * Protection Jobs.
   *
   * Note that the pause or resume actions will take effect from next Protection
   * Run. Also, user can specify only one type of action on all the Protection Jobs.
   * Deactivate and activate actions are independent of pause and resume state.
   * Deactivate and activate actions are useful in case of failover situations.
   *
   * Returns success if the state of all the Protection Jobs state is changed
   * successfully.
   * @param params The `ProtectionJobsService.UpdateProtectionJobsStateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionJobsStateResponse(params: ProtectionJobsService.UpdateProtectionJobsStateParams): __Observable<__StrictHttpResponse<UpdateProtectionJobsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionJobs/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateProtectionJobsState>;
      })
    );
  }
  /**
   * Perform an action like pause, resume, activate, deactivate on all the specified
   * Protection Jobs.
   *
   * Note that the pause or resume actions will take effect from next Protection
   * Run. Also, user can specify only one type of action on all the Protection Jobs.
   * Deactivate and activate actions are independent of pause and resume state.
   * Deactivate and activate actions are useful in case of failover situations.
   *
   * Returns success if the state of all the Protection Jobs state is changed
   * successfully.
   * @param params The `ProtectionJobsService.UpdateProtectionJobsStateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionJobsState(params: ProtectionJobsService.UpdateProtectionJobsStateParams): __Observable<UpdateProtectionJobsState> {
    return this.UpdateProtectionJobsStateResponse(params).pipe(
      __map(_r => _r.body as UpdateProtectionJobsState)
    );
  }

  /**
   * List details about single Protection Job.
   *
   * Returns the Protection Job corresponding to the specified Job id.
   * @param params The `ProtectionJobsService.GetProtectionJobByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionJobByIdResponse(params: ProtectionJobsService.GetProtectionJobByIdParams): __Observable<__StrictHttpResponse<ProtectionJob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionJobs/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionJob>;
      })
    );
  }
  /**
   * List details about single Protection Job.
   *
   * Returns the Protection Job corresponding to the specified Job id.
   * @param params The `ProtectionJobsService.GetProtectionJobByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionJobById(params: ProtectionJobsService.GetProtectionJobByIdParams): __Observable<ProtectionJob> {
    return this.GetProtectionJobByIdResponse(params).pipe(
      __map(_r => _r.body as ProtectionJob)
    );
  }

  /**
   * Update a Protection Job.
   *
   * Returns the updated Protection Job.
   * @param params The `ProtectionJobsService.UpdateProtectionJobParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `body`: Request to update a protection job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionJobResponse(params: ProtectionJobsService.UpdateProtectionJobParams): __Observable<__StrictHttpResponse<ProtectionJob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/protectionJobs/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionJob>;
      })
    );
  }
  /**
   * Update a Protection Job.
   *
   * Returns the updated Protection Job.
   * @param params The `ProtectionJobsService.UpdateProtectionJobParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `body`: Request to update a protection job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionJob(params: ProtectionJobsService.UpdateProtectionJobParams): __Observable<ProtectionJob> {
    return this.UpdateProtectionJobResponse(params).pipe(
      __map(_r => _r.body as ProtectionJob)
    );
  }

  /**
   * Delete a Protection Job.
   *
   * Returns Success if the Protection Job is deleted.
   * @param params The `ProtectionJobsService.DeleteProtectionJobParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete a protection job.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionJobResponse(params: ProtectionJobsService.DeleteProtectionJobParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/protectionJobs/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Protection Job.
   *
   * Returns Success if the Protection Job is deleted.
   * @param params The `ProtectionJobsService.DeleteProtectionJobParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete a protection job.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionJob(params: ProtectionJobsService.DeleteProtectionJobParams): __Observable<null> {
    return this.DeleteProtectionJobResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List a protection job audit.
   *
   * Returns the audit of specific protection job edit history.
   * @param params The `ProtectionJobsService.GetProtectionJobAuditParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionJobAuditResponse(params: ProtectionJobsService.GetProtectionJobAuditParams): __Observable<__StrictHttpResponse<Array<ProtectionJobAuditTrail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionJobs/${encodeURIComponent(params.id)}/auditTrail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionJobAuditTrail>>;
      })
    );
  }
  /**
   * List a protection job audit.
   *
   * Returns the audit of specific protection job edit history.
   * @param params The `ProtectionJobsService.GetProtectionJobAuditParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionJobAudit(params: ProtectionJobsService.GetProtectionJobAuditParams): __Observable<Array<ProtectionJobAuditTrail>> {
    return this.GetProtectionJobAuditResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionJobAuditTrail>)
    );
  }
}

module ProtectionJobsService {

  /**
   * Parameters for ChangeProtectionJobState
   */
  export interface ChangeProtectionJobStateParams {

    /**
     * Specifies a unique id of the Protection Job.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: ChangeProtectionJobStateParam;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionJobs
   */
  export interface GetProtectionJobsParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * If true, the list of exclusion sources will be omitted from the response.
     * This can be used to improve performance when the exclusion sources are
     * not needed.
     */
    pruneExcludedSourceIds?: boolean;

    /**
     * Filter by Policy ids that are associated with Protection Jobs.
     * Only Jobs associated with the specified Policy ids, are returned.
     */
    policyIds?: Array<string>;

    /**
     * OnlyReturnDataMigrationJobs specifies if only data migration jobs should be
     * returned. If not set, no data migration job will be returned.
     */
    onlyReturnDataMigrationJobs?: boolean;

    /**
     * if true then only job descriptions and the most recent run of the job
     * will be returned.
     */
    onlyReturnBasicSummary?: boolean;

    /**
     * Filter by a list of Protection Job names.
     */
    names?: Array<string>;

    /**
     * IsLastRunSlaViolated is the parameter to filter the Protection Jobs based
     * on the SLA violation status of the last Protection Run.
     */
    isLastRunSlaViolated?: boolean;

    /**
     * If true, return only Protection Jobs that have been deleted but still
     * have Snapshots associated with them.
     * If false, return all Protection Jobs except those Jobs that have
     * been deleted and still have Snapshots associated with them.
     * A Job that is deleted with all its Snapshots is not returned for
     * either of these cases.
     */
    isDeleted?: boolean;

    /**
     * Filter by Inactive or Active Jobs. If not set, all Inactive and
     * Active Jobs are returned. If true, only Active Jobs are returned.
     * If false, only Inactive Jobs are returned.
     * When you create a Protection Job on a Primary Cluster
     * with a replication schedule, the Cluster creates an
     * Inactive copy of the Job on the Remote Cluster.
     * In addition, when an Active and running Job is deactivated,
     * the Job becomes Inactive.
     */
    isActive?: boolean;

    /**
     * If true, both names and ids of Sources inside the protection group will be
     * sent in the response.
     * If false, only Source ids will be sent in the response.
     */
    includeSourceNames?: boolean;

    /**
     * If true, then the Protected Objects protected by RPO policies will also
     * be returned.
     */
    includeRpoSnapshots?: boolean;

    /**
     * If true, return the last Protection Run of the Job and the summary stats.
     */
    includeLastRunAndStats?: boolean;

    /**
     * Filter by a list of Protection Job ids.
     */
    ids?: Array<number>;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc.
     * Only Jobs protecting the specified environment types are returned.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kKubernetes' | 'kElastifile' | 'kSAPHANA' | 'kMongoDBPhysical'>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateProtectionJob
   */
  export interface CreateProtectionJobParams {

    /**
     * Request to create a Protection Job.
     */
    body: ProtectionJobRequestBody;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RunProtectionJob
   */
  export interface RunProtectionJobParams {

    /**
     * Specifies a unique id of the Protection Job.
     */
    id: number;

    /**
     * Specifies the type of backup. If not specified, the 'kRegular'
     * backup is run.
     */
    body: RunProtectionJobParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionJobsState
   */
  export interface UpdateProtectionJobsStateParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: UpdateProtectionJobsStateRequestBody;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionJobById
   */
  export interface GetProtectionJobByIdParams {

    /**
     * Specifies a unique id of the Protection Job.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionJob
   */
  export interface UpdateProtectionJobParams {

    /**
     * Specifies a unique id of the Protection Job.
     */
    id: number;

    /**
     * Request to update a protection job.
     */
    body: ProtectionJobRequestBody;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteProtectionJob
   */
  export interface DeleteProtectionJobParams {

    /**
     * Specifies a unique id of the Protection Job.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to delete a protection job.
     */
    body?: DeleteProtectionJobParam;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionJobAudit
   */
  export interface GetProtectionJobAuditParams {

    /**
     * Specifies a unique id of the Protection Job.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ProtectionJobsService }
