import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, CanAccess, StateAccessMap } from 'src/app/app-module.config';

const remoteClustersViewAccess: CanAccess = ctx => ctx.CLUSTER_REMOTE_VIEW &&
  ctx.FEATURE_FLAGS.ngRemoteClusters && getConfigByKey(ctx.irisContext, 'remoteClusters.features.allow', true);

export const RemoteClustersConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng-remote-clusters.**',
      url: '/remote-clusters',
      loadChildren: () => import('./remote-clusters.module').then(m => m.RemoteClustersModule),
    },
  ],
  allClusterMap: {
    'ng-remote-clusters': false,
    'ng-remote-clusters.details': false,
    'ng-remote-clusters.list': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'ng-remote-clusters': remoteClustersViewAccess,
      'ng-remote-clusters.details': remoteClustersViewAccess,
      'ng-remote-clusters.list': remoteClustersViewAccess,
    };
  },
};
