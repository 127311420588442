import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContext } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generates the navigation list for Sizer/Platform Insights based on the feature enablement.
 *
 * @param translate translation service
 * @param irisCtx iris context
 * @returns navigation list for Sizer/Platform Insights
 */
export const getNavList = (translate: TranslateService, irisCtx: IrisContext): NavItem[] => {
  /**
   * To determine live sizer support flow.
   * isSupportUser gets added to irisCtx, if the user is in sizer support.
   */
  const isSupportUser = !!irisCtx.isSupportUser;

  /**
   * Sizer will be available either if enabled from featureFlag (for helios) or the user is from support.
   * For support sizer's user other apps will not be there.
   */
  const navItems: NavItem[] = [
    (flagEnabled(irisCtx, 'liveSizerEnabled') || isSupportUser) && {
      displayName: translate.instant('forecast'),
      icon: 'insights',
      subNavList: [
        {
          displayName: translate.instant('resourcePlanning'),
          href: '/resource-planning',
        }
      ].filter(Boolean) as NavItem[],
    },
    !isSupportUser && flagEnabled(irisCtx, 'simulationEnabled') && {
      displayName: translate.instant('simulation'),
      state: 'simulation.list',
      href: '/../monitoring/simulation/list',
    },
    !isSupportUser && {
      displayName: translate.instant('reports'),
      state: 'reporting.list',
      href: '/../reporting/list',
      icon: 'assessment!outline',
      activeStates: ['reporting.detail', 'reporting.schedule'],
    }].filter(Boolean) as NavItem[];

  return navItems;
};
