<div *ngIf="databaseList?.length > 0" class="db-info-container"
    [ngClass]="{'adjust-container-width': adjustContainerWidth}">
  <div class="c-message-inline icon-message no-margin-bottom">
    {{'oracleRestore.pdbInfo' | translate}}
  </div>
  <mat-selection-list class="pluggable-db-container"
    [formControl]="matSelectionList"
    [disabled]="isPluggableDbSelectionHidden || pdbSelectionDisabled">
    <mat-list-option checkboxPosition="before"
      class="padding-left-xs"
      [ngClass]="{'hide-mat-checkbox': isPluggableDbSelectionHidden}"
      id="select-pluggable-db-{{index}}"
      *ngFor="let pdb of databaseList; let index = index;"
      (click)="handleDbSelection($event, pdb)"
      [selected]="pdb.isSelected"
      [value]="pdb">
      <div class="flex-row-xs col-xs-12 padding-top">
        <cog-icon shape="helix:database" size="md"></cog-icon>
        <h4>{{pdb.databaseName}}</h4>
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>
