import { each } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: Recover Files to Source: Task Options

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.fileRecovery')
    .controller('recoverFilesOptionsController', recoverFilesOptionsControllerFn);

  function recoverFilesOptionsControllerFn($rootScope, $scope, SourceService,
    RestoreService, $stateParams, ENUM_HOST_TYPE, ENV_GROUPS, FEATURE_FLAGS,
    SearchService, SNAPSHOT_TARGET_TYPE, FLR_RESTORE_METHOD, UserService,
    featureFlagsService) {

    angular.merge($scope, {
      // GENERAL SCOPE VARS
      stateParams: $stateParams,
      ENUM_HOST_TYPE: ENUM_HOST_TYPE,
      ENV_GROUPS: ENV_GROUPS,
      FLR_RESTORE_METHOD: FLR_RESTORE_METHOD,

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoveryFilesFilesRecoverOptionsText,

      // Errors
      errorText:
        $rootScope.text.protectionRecoveryFilesFilesRecoverOptions1ErrorText,

      // SCOPE METHODS
      displayTargetEntityCredentials: displayTargetEntityCredentials,
      removeProxy: removeProxy,
      emptyCredentials: emptyCredentials,
      proxyOptionEnabled: proxyOptionEnabled,
      selectProxy: selectProxy,
      selectTarget: selectTarget,
      resetTarget: resetTarget,
      isFileRecoveryDisabled: isFileRecoveryDisabled,
      locationDecision: locationDecision,
      displayAgentOption: displayAgentOption,

      // Hide the option to use VMWare tools if the user is a bifrost tenant user
      // and the corresponding backend feature flag is false.
      hideUseVmwareTools: UserService.isBifrostTenantUser() ?
        featureFlagsService.disabled('irisExecAllowBifrostTenantVmwareToolsBasedRecovery') : false,
    });


    // WATCHERS

    /**
     * Watch the taskName for changes. If the user hasn't modified the
     * alternateRestoreBaseDirectory option we will update it with taskName
     * changes. Otherwise we leave it alone and stop watching for changes
     * here.
     */
    $scope.$watch('shared.task', function taskWatcherFn(task, oldTask) {
      // Watch the task.name
      if (task.name !== oldTask.name) {
        // The alternateRestoreBaseDirectory field is still
        // untouched by the user, so lets update it with the
        // taskName as it changes.
        if (!$scope.formRecoverOptions.alternateRestoreBaseDirectory.$dirty) {
          task.params.restoreFilesPreferences.alternateRestoreBaseDirectory =
            $scope.getDefaultAlternativeDirectory(task.name);
        }
      }

      // Watch the task.params.restoreFilesPreferences.restoreToOriginalPaths
      if ($scope.isRestoreToOriginalPathsDisabled()) {
        task.params.restoreFilesPreferences.restoreToOriginalPaths = false;
      }

      // Did the target Entity change?
      if (!SourceService.isSameEntity(task.params.targetEntity, oldTask.params.targetEntity)) {
        // We need to update the targetEntityParentSource object too
        // Look for the matching full source entity using this entity's
        // parentId
        ($scope.Sources || []).some(function findParentSourceFn(_source) {
          var parentId = (!task.params.targetEntity) ? undefined :
            task.params.targetEntity.parentId || task.params.targetEntity.id;
          if (_source.entity.id === parentId) {
            // Found it. Set the param and bale from this loop
            task.params.targetEntityParentSource = angular.copy(_source.entity);
            return true;
          }
        });
      }
    }, true);

    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     recoverFilesOptionsInit
     */
    function recoverFilesOptionsInit() {
      var firstArchive;

      if ($stateParams.resubmit) {
        // re-submit workflow
        RestoreService.getTask($stateParams.taskId).then(
          function getTaskSuccess(task) {

            $scope.restoreTask = [].concat(task)[0];
            _resubmitWorkflow($scope.restoreTask);
          }
        );
      } else {
        // Empty the search results
        $scope.shared.searchData.length = 0;
        $scope.showRecoveryPoint = false;
        $scope.updateRecoverTask().then(function afterUpdateRecoverTask() {
          // Remove any "local" snapshots for directArchive data
          if ($scope.shared.taskCart[0]) {
            RestoreService.filterDirectArchiveSnapshots(
              $scope.shared.taskCart[0].fileDocument.objectId.jobId)
                .then(function promiseResolved(promiseResolver) {
                  var promiseObj = promiseResolver();
                  var filterDirectArchive = promiseObj.filterDirectArchive;
                  var isDirectArchiveEnabled = promiseObj.isDirectArchiveEnabled;

                  each($scope.shared.taskCart, function eachCartItem(item) {
                    item.fileDocument.versions =
                      filterDirectArchive(item.fileDocument.versions);
                  });

                  $scope.showRecoveryPoint = true;

                  // Make the first "archive" snapshot the default result
                  if (isDirectArchiveEnabled) {
                    firstArchive = get($scope.shared, [
                      'taskCart[0].fileDocument.versions[0]',
                      'replicaInfo.replicaVec[0]'
                    ].join('.'));

                    if (get(firstArchive, 'target.type') ===
                      SNAPSHOT_TARGET_TYPE.kArchival) {

                      $scope.shared.taskCart[0]._archiveTarget = firstArchive;
                    }
                  }
                });
          }
        });
      }
    }

    /**
     * Determines whether File Recovery is Disabled
     *
     * @return    {Boolean}    true if File Recovery should be disabled
     */
    function isFileRecoveryDisabled() {
      var formRecoverOptions = $scope.formRecoverOptions;
      var recoveryStateObj =
        RestoreService.getFileRecoveryDisabledState(
          $scope.shared.taskCart[0], $scope.shared.vaults);

      $scope.disableRecoveryMessage = recoveryStateObj.message;

      return recoveryStateObj.isDisabled || $scope.isSubmitting ||
        (formRecoverOptions.$submitted && formRecoverOptions.$invalid);
    }

    /**
     * checks whether target entity credintials should be displayed according
     * to targetEntity type
     *
     * @return   {Bool}    Indicates whether credentials are required
     */
    function displayTargetEntityCredentials() {
      // when feature flag is disabled no credintials are required during
      // FileLevelRecovery flow
      if (!FEATURE_FLAGS.flrCredentialsRequired) {
        return false;
      }

      // when the flag is enabled credentials are not required for HyperV
      // and Acropolis.
      // If the target source is:
      // 1. kHyperV or kAcropolis or kHyperVVS with Linux system
      // 2. ks vm
      // 3. cloud entity
      // Credential is hidden.
      // Else if Agent option is displayed, Credential is displayed when the user selects AutoDeploy or UseHypervisorAPIs
      // Else display the credential except recover source is NAS.

      var taskParams = $scope.shared.task.params;
      if ((taskParams.targetHostType === 0 &&
        ENV_GROUPS.entitiesWithNonMandatoryCredentialsOnLinuxHost.includes(taskParams.targetEntity.type)) ||
        (taskParams.targetEntity.type === 12 && taskParams.targetEntity === 1) ||
        ENV_GROUPS.entitiesWithNonMandatoryCredentials.includes(taskParams.targetEntity.type)) {
          return false;
      } else if (displayAgentOption()) {
        return [$scope.FLR_RESTORE_METHOD.kAutoDeploy, $scope.FLR_RESTORE_METHOD.kUseHypervisorAPIs]
          .includes($scope.shared.task.params.restoreMethod);
      } else {
        return $scope.shared.task.params.targetEntity && $scope.shared.taskCart[0] &&
          !ENV_GROUPS.nas.concat(6).includes($scope.shared.taskCart[0].fileDocument.objectId.entity.type);
      }
    }

    /**
     * Opens the Browse for Target modal and returns a selected target for
     * recovery *to*.
     *
     * @method   selectTarget
     * @return   {object}   Promise with the modal's response
     */
    function selectTarget() {
      var cartFileDocument = $scope.shared.taskCart[0] &&
        $scope.shared.taskCart[0].fileDocument;
      var filters = {
        // Manually excluding standalone ESXi targets until supported
        excludeTypes: [10],
        singleSelect: true,
        canSelectNode: function disableNodes(node) {
          return get(node._envProtectionSource, 'volumeInfo.type') !== 'kDataProtection';
        },
      };
      // default to VMware environment
      var envTypes = [1];
      var hostType;

      if (cartFileDocument) {
        // NAS file can restore to any NAS target
        envTypes = ENV_GROUPS.nas.includes(
          cartFileDocument.objectId.entity.type) ?
          ENV_GROUPS.nas :
          [cartFileDocument.objectId.entity.type];
        hostType = cartFileDocument.objectId.entity.physicalEntity &&
          cartFileDocument.objectId.entity.physicalEntity.hostType;
      }

      return SourceService.browseForLeafEntities(envTypes, hostType, filters)
        .then(
          function sourceSelected(selectedEntity) {
            var isAclDisabled = [1, 'kWindows'].includes(
              get(selectedEntity, 'vmwareEntity.hostType'));
            $scope.shared.task.params.targetEntity = selectedEntity;

            // Depends on the hostType of the selected target, if it's a Windows
            // VMware, set ACL attributes to be false; otherwise set to true by
            // default.
            $scope.shared.task.params.restoreFilesPreferences.preserveAcls =
              $scope.shared.task.params.restoreFilesPreferences.preserveAttributes =
              $scope.shared.task.params.restoreFilesPreferences.preserveTimestamps = !isAclDisabled;

            $scope.shared.isAclDisabled = isAclDisabled ? true : $scope.user.restricted;

            locationDecision();
          }
        );
    }

    /**
     * The proxy option is available in case if source is Windows Physical
     * Server. Opens the Browse for Proxy Server and returns a selected target
     * which can be used as a proxy for recovery.
     *
     * @method   selectProxy
     * @return   {object}   Promise with the modal's response
     */
    function selectProxy() {
      var filters = {
        // Manually excluding standalone ESXi targets until supported
        excludeTypes: [10],
        singleSelect: true,
      };

      // default to Physical Servers in case of Proxy Server
      var envType = 6;

      // default to Windows Physical Servers for Proxy Server
      var hostType = 1;

      return SourceService.browseForLeafEntities([envType], hostType, filters)
        .then(
          function sourceSelected(selectedEntity) {
            $scope.shared.task.params.proxyEntity = selectedEntity;
          }
        );
    }

    /**
     * Option of using a proxy server is shown in case the source is a windows
     * physical server.
     *
     * @method   proxyOptionEnabled
     * @return   {boolean}   true is returned in case of Windows Physical
     *                       Server.
     */
    function proxyOptionEnabled() {
      return FEATURE_FLAGS.proxyServerFileRecoveryEnabled &&
        $scope.shared.taskCart[0].fileDocument.objectId.entity.type === 6 &&
        $scope.shared.taskCart[0]._hostType === 1;
    }

    /**
     * Empty the credentials if the switch is turned off.
     *
     * @method   emptyCredentials
     * @param    {boolean}   credentialsEnabled   The model of switch to show
     *                                            credentials
     */
    function emptyCredentials(credentialsEnabled) {
      if (!credentialsEnabled) {
        $scope.shared.task.params.targetEntityCredentials = undefined;
      }
    }

    /**
     * Removes a proxy config if switch id turned off
     *
     * @method   removeProxy
     * @param    {boolean}   proxyEnabled   The model of switch to use proxy
     *                                      config
     */
    function removeProxy(proxyEnabled) {
      if (!proxyEnabled) {
        $scope.shared.task.params.proxyEntity = undefined;
      }
    }

    /**
     * If the host type is Unknow OS, let the recovery location as empty.
     *
     * @method  locationDecision
     */
    function locationDecision() {
      var directory;
      if (!$scope.shared.task.params.restoreFilesPreferences.restoreToOriginalPaths &&
        get($scope.shared, 'taskCart[0]._hostType') === -1) {
        directory = '';
      } else {
        directory = $scope.getDefaultAlternativeDirectory($scope.shared.task.name);
      }
      $scope.shared.task.params.restoreFilesPreferences.alternateRestoreBaseDirectory
        = directory;
    }

    /**
     * Resets any customization to the destination VM back to the default
     * (origin VM), if possible.
     *
     * @method     resetTarget
     */
    function resetTarget() {
      $scope.shared.task.params.targetEntity =
        $scope.shared.originalTargetExists ?
        angular.copy($scope.shared.originalTarget) :
        undefined;

      locationDecision();
    }

    /**
     * Adding formatted objects into cart and retain the previous settings.
     *
     * @method  formatTask
     * @param   {object} task
     */
    function _resubmitWorkflow(task) {
      var taskState = task.performRestoreTaskState;
      var restoreParams = taskState.restoreFilesTaskState.restoreParams;

      // Fetch the target entity.
      SearchService.entitySearch({entityIds: taskState.objects[0].entity.id})
        .then(function gotEntity(resp) {
            RestoreService.decorateFiles(task, resp[0]).forEach((file, index) => {
              // As waht we do in updateRecoverTask(), we get the first entity
              // and retrieve its `sourceObjectInfo` property from
              // getRestoreObjectProto().
              if (index === 0) {
                $scope.shared.task.sourceObjectInfo =
                  RestoreService.getRestoreObjectProto(
                    file.fileDocument, file._versions[file._snapshotIndex]);

                // We select 0th index as snapshot index in case of resubmit
                // which may not be case. Set jobInstanceId and
                // startTimeUsecs from $stateParmas if passed.
                if (![$stateParams.jobInstanceId,
                $stateParams.jobStartTimeUsecs].includes(0)) {
                  assign($scope.shared.task.sourceObjectInfo, {
                    jobInstanceId: $stateParams.jobInstanceId,
                    startTimeUsecs: $stateParams.jobStartTimeUsecs
                  });
                }
              }

              // Check for archival target on base task and assign that to target
              if (taskState.objects[0].archivalTarget) {
                file._archiveTarget.target = {
                  archivalTarget: taskState.objects[0].archivalTarget,
                  type: taskState.base.type,
                };

                $scope.shared.task.sourceObjectInfo.archivalTarget =
                  file._archiveTarget.target.archivalTarget;
              }

              $scope.addToCart(file, true);
            });

        }).finally(function entitySearchFinally() {

          assign($scope.shared.task.params, {
            targetEntity: restoreParams.targetEntity,
            restoreFilesPreferences: restoreParams.restoreFilesPreferences,
            restoreMethod: restoreParams.restoreMethod,
          });

          $scope.shared.disableACL = $scope.shared.task.params.restoreMethod ===
            $scope.FLR_RESTORE_METHOD.kUseHypervisorAPIs &&
            [1, 'kWindows'].includes(get($scope.shared.task.params.targetEntity,
              'vmwareEntity.hostType'));

          // allow to show recovery points
          $scope.showRecoveryPoint = true;
        });
    }

    /**
     * Determine to show agent option or not. Only show agent option if the
     * target server is a VMware.
     *
     * @method    displayAgentOption
     * @return    {Boolean}   Return true if it's a VM and the feature flag is
     *                        on.
     */
    function displayAgentOption() {
      var osTypeRestriction = FEATURE_FLAGS.persistentAgentForWindowsOnly ?
        $scope.shared.task.params.targetEntity.type === 1 : true;

      return FEATURE_FLAGS.enablePersistentAgentOption &&
       get($scope.shared.task.params, 'targetEntity.vmwareEntity') &&
       osTypeRestriction;
    }

    // Initialize!
    recoverFilesOptionsInit();
  }

})(angular);
