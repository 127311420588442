import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { getConfigByKey } from '../iris-context';
import { flagEnabled } from '../iris-context/iris-context-util';
import { IrisContextService } from '../iris-context/iris-context.service';
import { Pillar, pillars } from './app-pillars.model';
import { mergePillarsOverrides } from './app-pillars.utils';

/**
 * 5 pillars page.
 */
@Component({
  selector: 'coh-app-pillars',
  templateUrl: './app-pillars.component.html',
  styleUrls: ['./app-pillars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPillarsComponent {
  /**
   * Page title.
   */
  title: string;

  /**
   * All Pillars
   */
  pillars: Pillar[] = [];

  /**
   * Pillar selected by user.
   */
  selectedPillar: Pillar;

  /**
   * Data to store if hover on button.
   */
  isHover = {
    global: false,
    protection: false,
    security: false,
    mobility: false,
    access: false,
    insights: false,
  };

  /**
   * Whether global scope is globally enabled.
   */
  get globalEnabled(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'appPillarsGlobalScopeEnabled');
  }

  /**
   * Whether cards is enabled in landing page.
   */
  get cardsEnabled(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'heliosLandingPageWithCardsEnabled');
  }

  constructor(
    private state: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    private irisContextService: IrisContextService,
  ) {
    this.setPillars();

    const { category } = this.uiRouterGlobals.params;

    if (category) {
      const pillar = this.pillars.find(p => p.type === category);

      if (pillar) {
        this.selectedPillar = pillar;
        this.title = pillar.title;
      }
    } else {
      this.title = 'appPillars.title';
    }
  }

  /**
   * Set pillar selected by user and update URL.
   *
   * @param pillar  Pillar selected by user.
   */
  select(pillar: Pillar) {
    const params = this.uiRouterGlobals.params;

    params.category = pillar.type;
    this.state.go(this.uiRouterGlobals.current?.name, params, { notify: false });
  }

  goto(app: string) {
    this.state.go('', { app });
  }

  /**
   * Set the pillars for the pillars landing pages.
   */
  private setPillars() {
    const pillarOverrides = getConfigByKey<Partial<Pillar>[]>(this.irisContextService.irisContext, 'appPillarsOverrides', []);
    const mergedPillars = mergePillarsOverrides(pillars, pillarOverrides);

    for (const pillar of mergedPillars) {
      if (pillar.hidden) {
        continue ;
      }

      const pillarItem = { ...pillar };
      pillarItem.items = pillar.items.filter(item => !item.hidden);

      if (pillarItem.items.length) {
        // Don't show the pillar if it doesn't have any apps
        this.pillars.push(pillarItem);
      }
    }
  }
}
