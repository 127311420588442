import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Job Detail Tree

;(function(angular, undefined) {
  'use strict';

  angular.module('C.jobModify')
    .controller('jobModSourceTreeController', jobModSourceTreeControllerFn);

  function jobModSourceTreeControllerFn(
    $scope, cUtils, $uibModalInstance, opts, PubJobService, JobFlowService,
    ENV_GROUPS, FEATURE_FLAGS, _) {
    var $ctrl = this;

    angular.extend($ctrl, {
      $onInit: $onInit,
    });

    /**
     * initializes the source tree
     *
     * @method   onInit
     */
    function $onInit() {
      // Create a copy of options object so original options wont updated by
      // current component.
      // In the event that the user hits "cancel" the original
      // objects/selections will remain.
      opts = cloneDeep(opts);

      $scope.job = opts.job;

      // TODO: policy isn't necessarily selected yet in updated flow. How do we
      // account for policy related checks? Seems to only be relevant for NAS
      // archival.
      $scope.policy = opts.policy;
      $scope.jobTree = opts.jobTree;
      $scope.isEditMode = opts.isEditMode;

      $scope.sourceTreeOpts = {
        canSelect: true,
        singleSelect: false,
        updateTree: getUpdatedTree,
        cloudLeafType: opts.cloudLeafType,
        sqlAsyncMode: FEATURE_FLAGS.sqlAsyncMode && $scope.job.environment === 'kSQL',
      };

      // VMware Guest Tools are only required for VMware jobs, and only if Crash
      // Consistent setting is disabled.
      if ($scope.job.environment === 'kVMware' &&
        !$scope.job._envParams.fallbackToCrashConsistent) {
        $scope.sourceTreeOpts.vmwareToolsRequired = !!$scope.job.quiesce;
      }

      $scope.ENV_GROUPS = ENV_GROUPS;

      initializeTreeSelctionBehavior();

      initCountLabels();

      if ($scope.job.environment === 'kO365Outlook' &&
        FEATURE_FLAGS.office365NgSourceTree) {
        // SourceTree inputs for Office365NgSourceTree.
        $scope.sourceTreeInputs = {
          selectedSourceIds: $scope.job.sourceIds,
          entityHierarchy: $scope.jobTree.tree[0],
          office365JobParameters: $scope.job._envParams,

          // TODO(tauseef): Remove this once Office365 is migrated to NG with
          // its own state and then use StateAccessContext to fetch Feature
          // flags.
          applicationSupport: {
            oneDriveEnabled: FEATURE_FLAGS.office365OneDriveSupportEnabled,
          },
          isEditMode: $scope.isEditMode,
        };

        // SourceTree output callbacks for Office365NgSourceTree.
        $scope.sourceTreeOutputs = {
          updateJob: $scope.handleJobTreeUpdate,
          cancel: $scope.cancel,
        }
      }
    }

    /**
     * initialize tree selction behavior option
     *
     * @method   initializeTreeSelctionBehavior
     */
    function initializeTreeSelctionBehavior() {
      $scope.sourceTreeOpts.singleSelect = false;

      // If NAS with archival policy, then singleSelect (only if flag is toggled
      // off).
      if (!FEATURE_FLAGS.allNasMultipleViewsPerArchive &&
        ENV_GROUPS.nas.includes($scope.job.environment) &&
        $scope.policy &&
        $scope.policy._targets &&
        $scope.policy._targets.archival) {

        $scope.sourceTreeOpts.singleSelect = true;

        if ($scope.job.sourceIds.length) {
          $scope.sourceTreeOpts.singleSelectNode = {
            protectionSource: {
              id: $scope.job.sourceIds[0]
            }
          };
        }
      }

      // For data migration, only a single option should be selectable.
      if (ENV_GROUPS.nas.includes($scope.job.environment) &&
        $scope.job._fileStubbing) {
        $scope.sourceTreeOpts.singleSelect = true;
      }
    }

    /**
     * initialize the count labels based on environment type
     *
     * @method   initCountLabels
     */
    function initCountLabels() {
      switch (true) {
        case (ENV_GROUPS.hypervisor.includes($scope.job.environment)):
          $scope.countLabels = {
            selectedTextKey: 'selectedVms',
            totalTextKey: 'totalVms',
          };
          break;

        case $scope.job.environment === 'kPhysical':
          $scope.countLabels = {
            selectedTextKey: 'selectedServers',
            totalTextKey: 'totalServers',
          };
          break;

        case ENV_GROUPS.databaseSources.includes($scope.job.environment):
          $scope.countLabels = {
            selectedTextKey: 'selectedObjects',
            totalTextKey: 'totalObjects',
          };
          break;

        case (['kPure', 'kNetapp', 'kFlashBlade', 'kNimble'].includes(
          $scope.job.environment)):
          $scope.countLabels = {
            selectedTextKey: 'selectedVolumes',
            totalTextKey: 'totalVolumes',
          };
          break;

        case ENV_GROUPS.office365.includes($scope.job.environment):
          // Since Office365 currently supports both the deprecated EH having
          // mailboxes and the new EH having Users. There are 2 cases:
          //
          // CASE - Old EH for Office365
          //
          // Each Office365 Domain can have only one Outlook entity which
          // has multiple Mailbox entities. Depending upon the iris_exec flag
          // to remove the mailboxes over a threshold for handling scale
          // issues, there can be only 2 scenarios:
          //
          // 1. EH tree has no mailboxes present within the Outlook entity.
          // -- In this case Outlook entity can only be selected.
          //
          // 2. EH tree has mailboxes present within the Outlook entity.
          // -- In this case Mailbox entities can be selected.
          // ------------------------------------------------------------------
          //
          // CASE - New EH for Office365
          //
          // Since the backup job can have Users, Sites or Groups. Hence labels
          // will default to Objects.
          // ------------------------------------------------------------------
          var isMailboxSelectionEnabled =
            !!$scope.jobTree.totalCounts.kMailbox;

          $scope.countLabels = {
            selectedTextKey: isMailboxSelectionEnabled ?
              'selectedMailboxes' : 'selectedObjects',
            totalTextKey: isMailboxSelectionEnabled ?
              'totalMailboxes' : 'totalObjects',
          };
          break;

        case ENV_GROUPS.kubernetes.includes($scope.job.environment):
          $scope.countLabels = {
            selectedTextKey: 'selectedNamespaces',
            totalTextKey: 'totalNamespaces',
          };
      }
    }

    /**
     * Cancel the modal, dismisses/rejects the
     * uibModalInstances with a value of 'canceled'
     */
    $scope.cancel = function cancel() {
      $uibModalInstance.dismiss('canceled');
    };

    /**
     * Processes the user's selections and resolves the modal with the updated
     * options.
     *
     * @method   saveChanges
     * @param    {object}   sourceTreeForm   ng-form object
     */
    $scope.saveChanges = function saveChanges(sourceTreeForm) {
      if (!sourceTreeForm.$valid) {
        sourceTreeForm.$setSubmitted();
        return;
      }
      $scope.handleJobTreeUpdate();
    };

    /**
     * Updates the current protection job and dismisses the source-tree modal
     *
     * @method   handleJobTreeUpdate
     */
    $scope.handleJobTreeUpdate = function handleJobTreeUpdate() {
      JobFlowService.updateJobBasedOnTree($scope.job, $scope.jobTree.tree);
      $uibModalInstance.close(opts);
    };

    /**
     * Updates the number of Views selected.
     *
     * @method   updateViewCount
     */
    $scope.updateViewCount = function updateViewCount() {
      $scope.jobTree.selectedCounts = {
        kView: $scope.job._protectedView ? 1 : 0,
      };
    };

    /**
     * Gets the count of selected leaf entities for this given job environment.
     *
     * @method    getNumSelectedLeaves
     * @returns   {number}   The count found.
     */
    $scope.getNumSelectedLeaves = function getNumSelectedLeaves() {
      return PubJobService.getLeafCountByEnvironment(
        $scope.job.environment,
        $scope.jobTree.selectedCounts
      );
    };

    /**
     * Gets the count of leaf entities on a source for this given job
     * environment.
     *
     * @method    getNumTotalLeaves
     * @returns   {number}   The count found.
     */
    $scope.getNumTotalLeaves = function getNumTotalLeaves() {
      return PubJobService.getLeafCountByEnvironment(
        $scope.job.environment,
        $scope.jobTree.totalCounts
      );
    };

    /**
     * Determines if current protection job can use the default source tree.
     *
     * @method    canUseDefaultSourceTree
     * @returns   {boolean}   True if the current job environment can use
     *                        default source tree; false, otherwise.
     *
     */
    $scope.canUseDefaultSourceTree = function canUseDefaultSourceTree() {
      switch (true) {
        case !['kPure', 'kView', 'kO365Outlook', 'kNimble'].includes(
          $scope.job.environment):
          return true;

        case $scope.job.environment === 'kO365Outlook' &&
          !FEATURE_FLAGS.office365NgSourceTree:
          return true;

        default:
          return false;
      };
    }

    /**
     * Fetch the updated tree details.
     *
     * @method     getUpdatedTree
     * @returns    {Object}   Promise with updated tree data.
     */
    function getUpdatedTree() {
      return PubJobService.getJobTree($scope.job).then(
        function getTreeSuccess(jobTree) {
          assign($scope.jobTree, jobTree);

          // Remove any missing sourceIds from the Job configuration.
          JobFlowService.removeMissingSourceIds($scope.jobTree, $scope.job);
        }
      )
    };
  }

})(angular);
