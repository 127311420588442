<div class="stepper-header">
  <ng-container *ngFor="let step of matStepperRef.steps; let index=index">
    <div *ngIf="index >= selectedIndex" class="step-number"
      [class.step-number-active]="index === selectedIndex"
      [class.step-number-inactive]="index !== selectedIndex">
      {{ index + 1 }}
    </div>
    <div class="step-number step-number-past" *ngIf="index < selectedIndex">
      <cog-icon shape="check" size="sm"></cog-icon>
    </div>
    <span class="step-label" *ngIf="index === selectedIndex">
      {{ step.label }}
    </span>
    <div *ngIf="(matStepperRef.steps.length-1) !== index" class="stepper-horizontal-line"
      [class.stepper-item-selected]="index === selectedIndex"></div>
  </ng-container>
</div>
