/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AdRootTopologyObject } from '../models/ad-root-topology-object';
import { ComparedADObject } from '../models/compared-adobject';
import { CompareAdObjectsRequest } from '../models/compare-ad-objects-request';
import { ADObject } from '../models/adobject';
import { SearchProductionAdObjectsRequest } from '../models/search-production-ad-objects-request';
import { FileSearchResults } from '../models/file-search-results';
import { AdObjectsRestoreStatus } from '../models/ad-objects-restore-status';
import { RestoreTask } from '../models/restore-task';
import { ApplicationsRestoreTaskRequest } from '../models/applications-restore-task-request';
import { CloneTaskRequest } from '../models/clone-task-request';
import { DeployTaskRequest } from '../models/deploy-task-request';
import { DownloadFilesAndFoldersParams } from '../models/download-files-and-folders-params';
import { RestoreFilesTaskRequest } from '../models/restore-files-task-request';
import { FileFstatResult } from '../models/file-fstat-result';
import { FileSnapshotInformation } from '../models/file-snapshot-information';
import { ObjectSearchResults } from '../models/object-search-results';
import { RestorePointsForTimeRange } from '../models/restore-points-for-time-range';
import { RestorePointsForTimeRangeParam } from '../models/restore-points-for-time-range-param';
import { UpdateRestoreTaskParams } from '../models/update-restore-task-params';
import { RecoverTaskRequest } from '../models/recover-task-request';
import { VirtualDiskInformation } from '../models/virtual-disk-information';
import { VmDirectoryListResult } from '../models/vm-directory-list-result';
import { VmVolumesInformation } from '../models/vm-volumes-information';
@Injectable({
  providedIn: 'root',
})
class RestoreTasksService extends __BaseService {
  static readonly GetAdDomainRootTopologyPath = '/public/restore/adDomainRootTopology';
  static readonly CompareAdObjectsPath = '/public/restore/adObjectAttributes';
  static readonly SearchAdObjectsPath = '/public/restore/adObjects';
  static readonly SearchProductionAdObjectsPath = '/public/restore/adObjects';
  static readonly GetAdObjectsPath = '/public/restore/adObjects/searchResults';
  static readonly AdObjectsRestoreStatusPath = '/public/restore/adObjects/status';
  static readonly CreateApplicationsCloneTaskPath = '/public/restore/applicationsClone';
  static readonly CreateApplicationsRecoverTaskPath = '/public/restore/applicationsRecover';
  static readonly CreateCloneTaskPath = '/public/restore/clone';
  static readonly PublicDestroyCloneTaskPath = '/public/restore/clone/{id}';
  static readonly CreateDeployTaskPath = '/public/restore/deploy';
  static readonly CreateDownloadFilesAndFoldersPath = '/public/restore/downloadFilesAndFolders';
  static readonly SearchRestoredFilesPath = '/public/restore/files';
  static readonly CreateRestoreFilesTaskPath = '/public/restore/files';
  static readonly GetFileFstatInformationPath = '/public/restore/files/fstats';
  static readonly GetFileSnapshotsInformationPath = '/public/restore/files/snapshotsInformation';
  static readonly SearchObjectsPath = '/public/restore/objects';
  static readonly GetOneDriveDocumentsPath = '/public/restore/office365/onedrive/documents';
  static readonly GetOutlookEmailsPath = '/public/restore/office365/outlook/emails';
  static readonly GetRestorePointsForTimeRangePath = '/public/restore/pointsForTimeRange';
  static readonly UpdateRestoreTaskPath = '/public/restore/recover';
  static readonly CreateRecoverTaskPath = '/public/restore/recover';
  static readonly GetRestoreTasksPath = '/public/restore/tasks';
  static readonly CancelRestoreTaskPath = '/public/restore/tasks/cancel/{id}';
  static readonly GetRestoreTaskByIdPath = '/public/restore/tasks/{id}';
  static readonly GetVirtualDiskInformationPath = '/public/restore/virtualDiskInformation';
  static readonly GetVmDirectoryListPath = '/public/restore/vms/directoryList';
  static readonly GetVmVolumesInformationPath = '/public/restore/vms/volumesInformation';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets Root Topology for an AD Domain.
   *
   * Returns the root topology for an AD domain.
   * @param params The `RestoreTasksService.GetAdDomainRootTopologyParams` containing the following parameters:
   *
   * - `restoreTaskId`: Specifies the restoreTaskId corresponding to which we need to
   *   get the ad topology.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAdDomainRootTopologyResponse(params: RestoreTasksService.GetAdDomainRootTopologyParams): __Observable<__StrictHttpResponse<Array<AdRootTopologyObject>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.restoreTaskId != null) __params = __params.set('restoreTaskId', params.restoreTaskId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/adDomainRootTopology`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdRootTopologyObject>>;
      })
    );
  }
  /**
   * Gets Root Topology for an AD Domain.
   *
   * Returns the root topology for an AD domain.
   * @param params The `RestoreTasksService.GetAdDomainRootTopologyParams` containing the following parameters:
   *
   * - `restoreTaskId`: Specifies the restoreTaskId corresponding to which we need to
   *   get the ad topology.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAdDomainRootTopology(params: RestoreTasksService.GetAdDomainRootTopologyParams): __Observable<Array<AdRootTopologyObject>> {
    return this.GetAdDomainRootTopologyResponse(params).pipe(
      __map(_r => _r.body as Array<AdRootTopologyObject>)
    );
  }

  /**
   * Compares the AD Object from both Snapshot and Production AD and returns
   * the attributes with status whether they differ or not.
   *
   * Returns the list of AD Objects after comparing attributes of AD Object from
   * both Snapshot and Production AD.
   * @param params The `RestoreTasksService.CompareAdObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the Request to compare the AD Objects
   *   from both Snapshot and Production AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CompareAdObjectsResponse(params: RestoreTasksService.CompareAdObjectsParams): __Observable<__StrictHttpResponse<Array<ComparedADObject>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/adObjectAttributes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ComparedADObject>>;
      })
    );
  }
  /**
   * Compares the AD Object from both Snapshot and Production AD and returns
   * the attributes with status whether they differ or not.
   *
   * Returns the list of AD Objects after comparing attributes of AD Object from
   * both Snapshot and Production AD.
   * @param params The `RestoreTasksService.CompareAdObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the Request to compare the AD Objects
   *   from both Snapshot and Production AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CompareAdObjects(params: RestoreTasksService.CompareAdObjectsParams): __Observable<Array<ComparedADObject>> {
    return this.CompareAdObjectsResponse(params).pipe(
      __map(_r => _r.body as Array<ComparedADObject>)
    );
  }

  /**
   * Searches for AD Objects in both Production and Snapshot AD from given search
   * base dn and offset.
   *
   * Returns the list of AD Objects along with status whether they are missing in
   * Production AD, equal or not equal.
   * @param params The `RestoreTasksService.SearchAdObjectsParams` containing the following parameters:
   *
   * - `restoreTaskId`: Specifies the restoreTaskId corresponding to which we need to search
   *   AD objects.
   *
   * - `subtreeSearchScope`: Specifies the search scope for the request.
   *   If subtree search scope is true all the children of Search Base DN
   *   are returned from given offset. If subtree search scope is false
   *   only all objects which are one level from the Search Base DN are returned.
   *
   * - `searchBaseDn`: Specifies the search base distinguished name from where the search
   *   should begin in the hierarchy of the AD in both Production
   *   and Snapshot AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `recordOffset`: Specifies the offset from which AD objects should be searched in both
   *   the Snapshot and Production AD.
   *
   * - `numObjects`: Specifies the number of AD Objects to be fetched.
   *
   * - `filter`: Specifies the filter which can be used for searching the AD Objects from
   *   given Search Base DN.
   *   There are two types of filters supported. They are:
   *   1) If the string does not contain LDAP delimiters '(' and ')', then it is
   *   assumed to be ANR search "(anr=<ldap_filter>)"
   *   Eg: "a" will result in query to return all ANR fields with "a" characters
   *   (case insensitive) in them
   *   2) Search with OR and AND combination:
   *   "(|(&(objectClass=user)(distinguishedName=CN=Jone
   *   Doe,OU=Users,DC=corp,DC=cohesity,DC=com))(&(objectClass=user)
   *   (sAMAccountName=jdoe)))"
   *
   * - `excludeSystemProperties`: Specifies the option to exclude the system attributes while comparing the
   *   the objects from the Production AD and Snapshot AD.
   *
   * - `compareObjects`: Specifies the option to compare the properties from Snapshot AD and
   *   Production AD if specifed and sets kNotEqual flag in the result when
   *   there is mismatch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchAdObjectsResponse(params: RestoreTasksService.SearchAdObjectsParams): __Observable<__StrictHttpResponse<Array<ADObject>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.restoreTaskId != null) __params = __params.set('restoreTaskId', params.restoreTaskId.toString());
    if (params.subtreeSearchScope != null) __params = __params.set('subtreeSearchScope', params.subtreeSearchScope.toString());
    if (params.searchBaseDn != null) __params = __params.set('searchBaseDn', params.searchBaseDn.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.recordOffset != null) __params = __params.set('recordOffset', params.recordOffset.toString());
    if (params.numObjects != null) __params = __params.set('numObjects', params.numObjects.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    if (params.excludeSystemProperties != null) __params = __params.set('excludeSystemProperties', params.excludeSystemProperties.toString());
    if (params.compareObjects != null) __params = __params.set('compareObjects', params.compareObjects.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/adObjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ADObject>>;
      })
    );
  }
  /**
   * Searches for AD Objects in both Production and Snapshot AD from given search
   * base dn and offset.
   *
   * Returns the list of AD Objects along with status whether they are missing in
   * Production AD, equal or not equal.
   * @param params The `RestoreTasksService.SearchAdObjectsParams` containing the following parameters:
   *
   * - `restoreTaskId`: Specifies the restoreTaskId corresponding to which we need to search
   *   AD objects.
   *
   * - `subtreeSearchScope`: Specifies the search scope for the request.
   *   If subtree search scope is true all the children of Search Base DN
   *   are returned from given offset. If subtree search scope is false
   *   only all objects which are one level from the Search Base DN are returned.
   *
   * - `searchBaseDn`: Specifies the search base distinguished name from where the search
   *   should begin in the hierarchy of the AD in both Production
   *   and Snapshot AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `recordOffset`: Specifies the offset from which AD objects should be searched in both
   *   the Snapshot and Production AD.
   *
   * - `numObjects`: Specifies the number of AD Objects to be fetched.
   *
   * - `filter`: Specifies the filter which can be used for searching the AD Objects from
   *   given Search Base DN.
   *   There are two types of filters supported. They are:
   *   1) If the string does not contain LDAP delimiters '(' and ')', then it is
   *   assumed to be ANR search "(anr=<ldap_filter>)"
   *   Eg: "a" will result in query to return all ANR fields with "a" characters
   *   (case insensitive) in them
   *   2) Search with OR and AND combination:
   *   "(|(&(objectClass=user)(distinguishedName=CN=Jone
   *   Doe,OU=Users,DC=corp,DC=cohesity,DC=com))(&(objectClass=user)
   *   (sAMAccountName=jdoe)))"
   *
   * - `excludeSystemProperties`: Specifies the option to exclude the system attributes while comparing the
   *   the objects from the Production AD and Snapshot AD.
   *
   * - `compareObjects`: Specifies the option to compare the properties from Snapshot AD and
   *   Production AD if specifed and sets kNotEqual flag in the result when
   *   there is mismatch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchAdObjects(params: RestoreTasksService.SearchAdObjectsParams): __Observable<Array<ADObject>> {
    return this.SearchAdObjectsResponse(params).pipe(
      __map(_r => _r.body as Array<ADObject>)
    );
  }

  /**
   * Searches for AD Objects that match the list of object guids,
   * sam account names and distinguished names provided in the request.
   *
   * Returns the list of AD Objects that match the list of object guids,
   * sam account names and distinguished names provided in the request.
   * @param params The `RestoreTasksService.SearchProductionAdObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the Request to search the AD Objects from Production AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProductionAdObjectsResponse(params: RestoreTasksService.SearchProductionAdObjectsParams): __Observable<__StrictHttpResponse<Array<ADObject>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/adObjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ADObject>>;
      })
    );
  }
  /**
   * Searches for AD Objects that match the list of object guids,
   * sam account names and distinguished names provided in the request.
   *
   * Returns the list of AD Objects that match the list of object guids,
   * sam account names and distinguished names provided in the request.
   * @param params The `RestoreTasksService.SearchProductionAdObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the Request to search the AD Objects from Production AD.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProductionAdObjects(params: RestoreTasksService.SearchProductionAdObjectsParams): __Observable<Array<ADObject>> {
    return this.SearchProductionAdObjectsResponse(params).pipe(
      __map(_r => _r.body as Array<ADObject>)
    );
  }

  /**
   * Search for AD objects to recover that match the specified search and filter criterias
   * provided in the request.
   * @param params The `RestoreTasksService.GetAdObjectsParams` containing the following parameters:
   *
   * - `viewBoxIds`: Filter by a list of Domains (View Boxes) ids.
   *   Only items stored in the listed Domains (View Boxes) are returned.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `samAccountName`: Specifies the sam account name of the AD object.
   *
   * - `registeredSourceIds`: Specifies the Active Directory Application Server Ids which contains
   *   the AD objects.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectType`: Specifies the type of the AD Object. The type may be user, computer,
   *   group or ou(organizational unit).
   *
   * - `name`: Specifies the name of the AD object.
   *
   * - `jobIds`: Specifies the protection job Ids which have backed up Active Directory
   *   Application Server.
   *
   * - `email`: Specifies the email of the AD object of type user or group.
   *
   * - `domain`: domain of the AD object.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAdObjectsResponse(params: RestoreTasksService.GetAdObjectsParams): __Observable<__StrictHttpResponse<FileSearchResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewBoxIds || []).forEach(val => {if (val != null) __params = __params.append('viewBoxIds', val.toString())});
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.samAccountName != null) __params = __params.set('samAccountName', params.samAccountName.toString());
    (params.registeredSourceIds || []).forEach(val => {if (val != null) __params = __params.append('registeredSourceIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.objectType != null) __params = __params.set('objectType', params.objectType.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.jobIds || []).forEach(val => {if (val != null) __params = __params.append('jobIds', val.toString())});
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/adObjects/searchResults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileSearchResults>;
      })
    );
  }
  /**
   * Search for AD objects to recover that match the specified search and filter criterias
   * provided in the request.
   * @param params The `RestoreTasksService.GetAdObjectsParams` containing the following parameters:
   *
   * - `viewBoxIds`: Filter by a list of Domains (View Boxes) ids.
   *   Only items stored in the listed Domains (View Boxes) are returned.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `samAccountName`: Specifies the sam account name of the AD object.
   *
   * - `registeredSourceIds`: Specifies the Active Directory Application Server Ids which contains
   *   the AD objects.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectType`: Specifies the type of the AD Object. The type may be user, computer,
   *   group or ou(organizational unit).
   *
   * - `name`: Specifies the name of the AD object.
   *
   * - `jobIds`: Specifies the protection job Ids which have backed up Active Directory
   *   Application Server.
   *
   * - `email`: Specifies the email of the AD object of type user or group.
   *
   * - `domain`: domain of the AD object.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAdObjects(params: RestoreTasksService.GetAdObjectsParams): __Observable<FileSearchResults> {
    return this.GetAdObjectsResponse(params).pipe(
      __map(_r => _r.body as FileSearchResults)
    );
  }

  /**
   * Returns the Restore status of the AD objects which were restored from the
   * snapshot AD to production AD as part of the restore task id specified in the
   * parameters.
   * @param params The `RestoreTasksService.AdObjectsRestoreStatusParams` containing the following parameters:
   *
   * - `restoreTaskId`: Specifies the restoreTaskId corresponding to which we need to get
   *   information about the restore of the AD objects.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AdObjectsRestoreStatusResponse(params: RestoreTasksService.AdObjectsRestoreStatusParams): __Observable<__StrictHttpResponse<AdObjectsRestoreStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.restoreTaskId != null) __params = __params.set('restoreTaskId', params.restoreTaskId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/adObjects/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AdObjectsRestoreStatus>;
      })
    );
  }
  /**
   * Returns the Restore status of the AD objects which were restored from the
   * snapshot AD to production AD as part of the restore task id specified in the
   * parameters.
   * @param params The `RestoreTasksService.AdObjectsRestoreStatusParams` containing the following parameters:
   *
   * - `restoreTaskId`: Specifies the restoreTaskId corresponding to which we need to get
   *   information about the restore of the AD objects.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AdObjectsRestoreStatus(params: RestoreTasksService.AdObjectsRestoreStatusParams): __Observable<AdObjectsRestoreStatus> {
    return this.AdObjectsRestoreStatusResponse(params).pipe(
      __map(_r => _r.body as AdObjectsRestoreStatus)
    );
  }

  /**
   * Create a Restore Task for cloning Applications like SQL Databases.
   *
   * Returns the created Restore Task.
   * @param params The `RestoreTasksService.CreateApplicationsCloneTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for cloning Applications like SQL DB.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateApplicationsCloneTaskResponse(params: RestoreTasksService.CreateApplicationsCloneTaskParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/applicationsClone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Create a Restore Task for cloning Applications like SQL Databases.
   *
   * Returns the created Restore Task.
   * @param params The `RestoreTasksService.CreateApplicationsCloneTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for cloning Applications like SQL DB.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateApplicationsCloneTask(params: RestoreTasksService.CreateApplicationsCloneTaskParams): __Observable<RestoreTask> {
    return this.CreateApplicationsCloneTaskResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Create a Restore Task for recovering Applications like SQL Databases.
   *
   * Returns the created Restore Task.
   * @param params The `RestoreTasksService.CreateApplicationsRecoverTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for recovering Applications like SQL DB.
   *   volumes to mount points.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateApplicationsRecoverTaskResponse(params: RestoreTasksService.CreateApplicationsRecoverTaskParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/applicationsRecover`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Create a Restore Task for recovering Applications like SQL Databases.
   *
   * Returns the created Restore Task.
   * @param params The `RestoreTasksService.CreateApplicationsRecoverTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for recovering Applications like SQL DB.
   *   volumes to mount points.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateApplicationsRecoverTask(params: RestoreTasksService.CreateApplicationsRecoverTaskParams): __Observable<RestoreTask> {
    return this.CreateApplicationsRecoverTaskResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Create a Restore Task for cloning VMs or a View.
   *
   * Returns the created Restore Task that clones VMs or a View.
   * @param params The `RestoreTasksService.CreateCloneTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for cloning VMs or a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCloneTaskResponse(params: RestoreTasksService.CreateCloneTaskParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/clone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Create a Restore Task for cloning VMs or a View.
   *
   * Returns the created Restore Task that clones VMs or a View.
   * @param params The `RestoreTasksService.CreateCloneTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for cloning VMs or a View.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCloneTask(params: RestoreTasksService.CreateCloneTaskParams): __Observable<RestoreTask> {
    return this.CreateCloneTaskResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Destroy a clone task with specified id.
   * @param params The `RestoreTasksService.PublicDestroyCloneTaskParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Clone Task to destroy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  PublicDestroyCloneTaskResponse(params: RestoreTasksService.PublicDestroyCloneTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/restore/clone/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Destroy a clone task with specified id.
   * @param params The `RestoreTasksService.PublicDestroyCloneTaskParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Clone Task to destroy.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  PublicDestroyCloneTask(params: RestoreTasksService.PublicDestroyCloneTaskParams): __Observable<null> {
    return this.PublicDestroyCloneTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a Restore Task for deploying VMs or a View on cloud.
   *
   * Returns the created Restore Task that deploys VMs on cloud. This operation
   * returns the target where cloud is deployed. Currently, VMs can be deployed
   * in either AWS target or Azure target.
   * @param params The `RestoreTasksService.CreateDeployTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for deploying VMs or a View on cloud.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDeployTaskResponse(params: RestoreTasksService.CreateDeployTaskParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/deploy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Create a Restore Task for deploying VMs or a View on cloud.
   *
   * Returns the created Restore Task that deploys VMs on cloud. This operation
   * returns the target where cloud is deployed. Currently, VMs can be deployed
   * in either AWS target or Azure target.
   * @param params The `RestoreTasksService.CreateDeployTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for deploying VMs or a View on cloud.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDeployTask(params: RestoreTasksService.CreateDeployTaskParams): __Observable<RestoreTask> {
    return this.CreateDeployTaskResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Create a Download Task for downloading files and folders.
   *
   * Returns the created download Task information that downloads files and folders.
   * @param params The `RestoreTasksService.CreateDownloadFilesAndFoldersParams` containing the following parameters:
   *
   * - `body`: Request to create a task for downloading list of files or
   *   folders.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDownloadFilesAndFoldersResponse(params: RestoreTasksService.CreateDownloadFilesAndFoldersParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/downloadFilesAndFolders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Create a Download Task for downloading files and folders.
   *
   * Returns the created download Task information that downloads files and folders.
   * @param params The `RestoreTasksService.CreateDownloadFilesAndFoldersParams` containing the following parameters:
   *
   * - `body`: Request to create a task for downloading list of files or
   *   folders.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDownloadFilesAndFolders(params: RestoreTasksService.CreateDownloadFilesAndFoldersParams): __Observable<RestoreTask> {
    return this.CreateDownloadFilesAndFoldersResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Search for files and folders to recover that match the specified search
   * and filter criteria on the Cohesity Cluster.
   *
   * Use the files and folders returned by this operation to populate the
   * list of files and folders to recover in the
   * POST /public/restore/files operation.
   * If no search pattern or filter parameters are specified, all files
   * and folders currently found on the Cohesity Cluster are returned.
   * Specify a search pattern or parameters to filter the results that
   * are returned.
   *
   * The term "items" below refers to files and folders that are found
   * in the source objects (such as VMs).
   * @param params The `RestoreTasksService.SearchRestoredFilesParams` containing the following parameters:
   *
   * - `viewBoxIds`: Filter by a list of Domains (View Boxes) ids.
   *   Only items stored in the listed Domains (View Boxes) are returned.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `startTimeUsecs`: Filter by backup completion time by specifying a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests
   *   by setting pageCount and using startIndex to return a subsets of items.
   *   For example, set startIndex to 0 to get the first set of items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of items for a next request.
   *   Continue until all items are returned and therefore the total number of
   *   returned items is equal to totalCount.
   *
   * - `sourceIds`: Filter by source ids. Only files and folders found in the listed sources
   *   (such as VMs) are returned.
   *
   * - `search`: Filter by searching for sub-strings in the item name. The specified
   *   string can match any part of the item name.
   *   For example: "vm" or "123" both match the item name of "vm-123".
   *
   * - `registeredSourceIds`: Filter by a list of Registered Sources ids. Only items
   *   from the listed Registered Sources are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Limit the number of items to return in the response for pagination
   *   purposes.
   *
   * - `mustHaveTags`: Specifies tags which must be all present in the document.
   *
   * - `mustHaveSnapshotTags`: Specifies snapshot tags which must be all present in the document.
   *
   * - `mightHaveTags`: Specifies list of tags, one of which might be present in the document.
   *   These are OR'ed together and the resulting criteria AND'ed with the
   *   rest of the query.
   *
   * - `mightHaveSnapshotTags`: Specifies list of snapshot tags, one of which might be present in the
   *   document. These are OR'ed together and the resulting criteria AND'ed
   *   with the rest of the query.
   *
   * - `jobIds`: Filter by a list of Protection Job ids.
   *   Only items backed up by the specified Jobs are listed.
   *
   * - `folderOnly`: Filter by folders or files. If true, only folders are returned.
   *   If false, only files are returned. If not specified, both
   *   files and folders are returned.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc.
   *   Only items from the specified environment types are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by backup completion time by specify a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchRestoredFilesResponse(params: RestoreTasksService.SearchRestoredFilesParams): __Observable<__StrictHttpResponse<FileSearchResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewBoxIds || []).forEach(val => {if (val != null) __params = __params.append('viewBoxIds', val.toString())});
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.startIndex != null) __params = __params.set('startIndex', params.startIndex.toString());
    (params.sourceIds || []).forEach(val => {if (val != null) __params = __params.append('sourceIds', val.toString())});
    if (params.search != null) __params = __params.set('search', params.search.toString());
    (params.registeredSourceIds || []).forEach(val => {if (val != null) __params = __params.append('registeredSourceIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    (params.mustHaveTags || []).forEach(val => {if (val != null) __params = __params.append('mustHaveTags', val.toString())});
    (params.mustHaveSnapshotTags || []).forEach(val => {if (val != null) __params = __params.append('mustHaveSnapshotTags', val.toString())});
    (params.mightHaveTags || []).forEach(val => {if (val != null) __params = __params.append('mightHaveTags', val.toString())});
    (params.mightHaveSnapshotTags || []).forEach(val => {if (val != null) __params = __params.append('mightHaveSnapshotTags', val.toString())});
    (params.jobIds || []).forEach(val => {if (val != null) __params = __params.append('jobIds', val.toString())});
    if (params.folderOnly != null) __params = __params.set('folderOnly', params.folderOnly.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileSearchResults>;
      })
    );
  }
  /**
   * Search for files and folders to recover that match the specified search
   * and filter criteria on the Cohesity Cluster.
   *
   * Use the files and folders returned by this operation to populate the
   * list of files and folders to recover in the
   * POST /public/restore/files operation.
   * If no search pattern or filter parameters are specified, all files
   * and folders currently found on the Cohesity Cluster are returned.
   * Specify a search pattern or parameters to filter the results that
   * are returned.
   *
   * The term "items" below refers to files and folders that are found
   * in the source objects (such as VMs).
   * @param params The `RestoreTasksService.SearchRestoredFilesParams` containing the following parameters:
   *
   * - `viewBoxIds`: Filter by a list of Domains (View Boxes) ids.
   *   Only items stored in the listed Domains (View Boxes) are returned.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `startTimeUsecs`: Filter by backup completion time by specifying a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests
   *   by setting pageCount and using startIndex to return a subsets of items.
   *   For example, set startIndex to 0 to get the first set of items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of items for a next request.
   *   Continue until all items are returned and therefore the total number of
   *   returned items is equal to totalCount.
   *
   * - `sourceIds`: Filter by source ids. Only files and folders found in the listed sources
   *   (such as VMs) are returned.
   *
   * - `search`: Filter by searching for sub-strings in the item name. The specified
   *   string can match any part of the item name.
   *   For example: "vm" or "123" both match the item name of "vm-123".
   *
   * - `registeredSourceIds`: Filter by a list of Registered Sources ids. Only items
   *   from the listed Registered Sources are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Limit the number of items to return in the response for pagination
   *   purposes.
   *
   * - `mustHaveTags`: Specifies tags which must be all present in the document.
   *
   * - `mustHaveSnapshotTags`: Specifies snapshot tags which must be all present in the document.
   *
   * - `mightHaveTags`: Specifies list of tags, one of which might be present in the document.
   *   These are OR'ed together and the resulting criteria AND'ed with the
   *   rest of the query.
   *
   * - `mightHaveSnapshotTags`: Specifies list of snapshot tags, one of which might be present in the
   *   document. These are OR'ed together and the resulting criteria AND'ed
   *   with the rest of the query.
   *
   * - `jobIds`: Filter by a list of Protection Job ids.
   *   Only items backed up by the specified Jobs are listed.
   *
   * - `folderOnly`: Filter by folders or files. If true, only folders are returned.
   *   If false, only files are returned. If not specified, both
   *   files and folders are returned.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc.
   *   Only items from the specified environment types are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by backup completion time by specify a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchRestoredFiles(params: RestoreTasksService.SearchRestoredFilesParams): __Observable<FileSearchResults> {
    return this.SearchRestoredFilesResponse(params).pipe(
      __map(_r => _r.body as FileSearchResults)
    );
  }

  /**
   * Create a Restore Task for recovering files and folders.
   *
   * Returns the created Restore Task that recovers files and folders.
   * @param params The `RestoreTasksService.CreateRestoreFilesTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for recovering files or folders.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRestoreFilesTaskResponse(params: RestoreTasksService.CreateRestoreFilesTaskParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Create a Restore Task for recovering files and folders.
   *
   * Returns the created Restore Task that recovers files and folders.
   * @param params The `RestoreTasksService.CreateRestoreFilesTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for recovering files or folders.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRestoreFilesTask(params: RestoreTasksService.CreateRestoreFilesTaskParams): __Observable<RestoreTask> {
    return this.CreateRestoreFilesTaskResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Get the fstat information about file provided using query parameters.
   * @param params The `RestoreTasksService.GetFileFstatInformationParams` containing the following parameters:
   *
   * - `jobUidObjectId`: JobUidObjectId is the globally unique id of the job that the object
   *   originally belonged to. If this object originally belonged to a job from a
   *   remote cluster, this field will contain the JobId of the remote job, else
   *   it will contain the JobId of the local job.
   *
   * - `jobStartTimeUsecs`: JobStartTimeUsecs is the start time in usecs of the job run that backed up
   *   the entity.
   *
   * - `jobInstanceId`: JobInstanceId is the Id of the job run that backed up the entity.
   *
   * - `jobId`: JobId is the id of the local job that took the snapshot, which may or
   *   may not match the JobUidObjectId below depending on whether the object
   *   originally belonged to this local job or to a different remote job.
   *
   * - `filePath`: FilePath is the full path of the file or directory whose stat needed.
   *
   * - `entityId`: EntityId is the Id of the VM.
   *
   * - `volumeName`: VolumeName is the name of the volume that needs to be browsed. This should
   *   match the name returned in VolumeInfo.
   *
   * - `volumeInfoCookie`: VolumeInfoCookie is the cookie to be passed in calls to reading a VM dir
   *   for this volume.
   *
   * - `viewName`: Name of the View if a View is being browsed.
   *
   * - `viewBoxId`: Id of the View Box if a View is being browsed.
   *
   * - `useLibrarian`: Specifies whether to use Librarian for file stat. This will be true if the
   *   browse is enabled via librarian.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `attemptNum`: AttemptNum is the attempt number of the run that successfully created the
   *   snapshot.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFileFstatInformationResponse(params: RestoreTasksService.GetFileFstatInformationParams): __Observable<__StrictHttpResponse<FileFstatResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.jobUidObjectId != null) __params = __params.set('jobUidObjectId', params.jobUidObjectId.toString());
    if (params.jobStartTimeUsecs != null) __params = __params.set('jobStartTimeUsecs', params.jobStartTimeUsecs.toString());
    if (params.jobInstanceId != null) __params = __params.set('jobInstanceId', params.jobInstanceId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    if (params.volumeName != null) __params = __params.set('volumeName', params.volumeName.toString());
    if (params.volumeInfoCookie != null) __params = __params.set('volumeInfoCookie', params.volumeInfoCookie.toString());
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.viewBoxId != null) __params = __params.set('viewBoxId', params.viewBoxId.toString());
    if (params.useLibrarian != null) __params = __params.set('useLibrarian', params.useLibrarian.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.attemptNum != null) __params = __params.set('attemptNum', params.attemptNum.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/files/fstats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileFstatResult>;
      })
    );
  }
  /**
   * Get the fstat information about file provided using query parameters.
   * @param params The `RestoreTasksService.GetFileFstatInformationParams` containing the following parameters:
   *
   * - `jobUidObjectId`: JobUidObjectId is the globally unique id of the job that the object
   *   originally belonged to. If this object originally belonged to a job from a
   *   remote cluster, this field will contain the JobId of the remote job, else
   *   it will contain the JobId of the local job.
   *
   * - `jobStartTimeUsecs`: JobStartTimeUsecs is the start time in usecs of the job run that backed up
   *   the entity.
   *
   * - `jobInstanceId`: JobInstanceId is the Id of the job run that backed up the entity.
   *
   * - `jobId`: JobId is the id of the local job that took the snapshot, which may or
   *   may not match the JobUidObjectId below depending on whether the object
   *   originally belonged to this local job or to a different remote job.
   *
   * - `filePath`: FilePath is the full path of the file or directory whose stat needed.
   *
   * - `entityId`: EntityId is the Id of the VM.
   *
   * - `volumeName`: VolumeName is the name of the volume that needs to be browsed. This should
   *   match the name returned in VolumeInfo.
   *
   * - `volumeInfoCookie`: VolumeInfoCookie is the cookie to be passed in calls to reading a VM dir
   *   for this volume.
   *
   * - `viewName`: Name of the View if a View is being browsed.
   *
   * - `viewBoxId`: Id of the View Box if a View is being browsed.
   *
   * - `useLibrarian`: Specifies whether to use Librarian for file stat. This will be true if the
   *   browse is enabled via librarian.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `attemptNum`: AttemptNum is the attempt number of the run that successfully created the
   *   snapshot.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFileFstatInformation(params: RestoreTasksService.GetFileFstatInformationParams): __Observable<FileFstatResult> {
    return this.GetFileFstatInformationResponse(params).pipe(
      __map(_r => _r.body as FileFstatResult)
    );
  }

  /**
   * Get the information about snapshots that contain the specified file or folder. In addition, information about the file or folder is provided.
   * @param params The `RestoreTasksService.GetFileSnapshotsInformationParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the id of the Protection Source object (such as a VM) to search.
   *   When a Job Run executes, snapshots of the specified Protection Source
   *   object are captured. This operation searches the snapshots of the
   *   object for the file or folder. This field is required.
   *
   * - `jobId`: Specifies the id of the Job that captured the snapshots.
   *   These snapshots are searched for the specified files or folders.
   *   This field is required.
   *
   * - `filename`: Specifies the name of the file or folder to find in the snapshots.
   *   This field is required.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the Job
   *   was created.
   *   An incarnation id is generated when a Cohesity Cluster is initially
   *   created. This field is required.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the Job was created.
   *   This field is required.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFileSnapshotsInformationResponse(params: RestoreTasksService.GetFileSnapshotsInformationParams): __Observable<__StrictHttpResponse<Array<FileSnapshotInformation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.filename != null) __params = __params.set('filename', params.filename.toString());
    if (params.clusterIncarnationId != null) __params = __params.set('clusterIncarnationId', params.clusterIncarnationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/files/snapshotsInformation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FileSnapshotInformation>>;
      })
    );
  }
  /**
   * Get the information about snapshots that contain the specified file or folder. In addition, information about the file or folder is provided.
   * @param params The `RestoreTasksService.GetFileSnapshotsInformationParams` containing the following parameters:
   *
   * - `sourceId`: Specifies the id of the Protection Source object (such as a VM) to search.
   *   When a Job Run executes, snapshots of the specified Protection Source
   *   object are captured. This operation searches the snapshots of the
   *   object for the file or folder. This field is required.
   *
   * - `jobId`: Specifies the id of the Job that captured the snapshots.
   *   These snapshots are searched for the specified files or folders.
   *   This field is required.
   *
   * - `filename`: Specifies the name of the file or folder to find in the snapshots.
   *   This field is required.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the Job
   *   was created.
   *   An incarnation id is generated when a Cohesity Cluster is initially
   *   created. This field is required.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the Job was created.
   *   This field is required.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFileSnapshotsInformation(params: RestoreTasksService.GetFileSnapshotsInformationParams): __Observable<Array<FileSnapshotInformation>> {
    return this.GetFileSnapshotsInformationResponse(params).pipe(
      __map(_r => _r.body as Array<FileSnapshotInformation>)
    );
  }

  /**
   * Find backup objects that match the specified search and filter criteria
   * on the Cohesity Cluster.
   *
   * If no search pattern or filter parameters are specified, all backup objects
   * currently found on the Cohesity Cluster are returned.
   * Only leaf objects that have been protected by a Job are returned such as VMs,
   * Views and databases.
   * Specify a search pattern or parameters to filter the results that
   * are returned.
   *
   * The term "items" below refers to leaf backup objects such as VMs,
   * Views and databases.
   * @param params The `RestoreTasksService.SearchObjectsParams` containing the following parameters:
   *
   * - `viewBoxIds`: Filter by a list of Domains (View Boxes) ids.
   *   Only items stored in the listed Domains (View Boxes) are returned.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `startTimeUsecs`: Filter by backup completion time by specifying a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests
   *   by setting pageCount and using startIndex to return a subsets of items.
   *   For example, set startIndex to 0 to get the first set of items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of items for a next request.
   *   Continue until all items are returned and therefore the total number of
   *   returned items is equal to totalCount.
   *
   * - `search`: Filter by searching for sub-strings in the item name. The specified
   *   string can match any part of the item name.
   *   For example: "vm" or "123" both match the item name of "vm-123".
   *
   * - `registeredSourceIds`: Filter by a list of Registered Sources ids. Only items
   *   from the listed Registered Sources are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Limit the number of items to return in the response for pagination
   *   purposes.
   *
   * - `ownerEntityId`: Filter objects by the Entity id of the owner VM. For example, if a
   *   ownerEntityId is provided while searching for SQL databases,
   *   only SQL databases belonging to the VM with the specified id
   *   are returned.
   *   ownerEntityId is only significant if application is set to SQL.
   *
   * - `operatingSystems`: Filter by the Operating Systems running on VMs and Physical Servers.
   *   This filter is applicable only to VMs and physical servers.
   *
   * - `office365SourceTypes`: Filter by Office365 types such as 'kUser', 'kSite', etc.
   *   Only items from the specified source types are returned.
   *
   * - `jobIds`: Filter by a list of Protection Job ids.
   *   Only items backed up by the specified Jobs are listed.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc.
   *   Only items from the specified environment types are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by backup completion time by specify a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `application`: Filter by application when the environment type is kSQL.
   *   For example, if SQL is specified the SQL databases are returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchObjectsResponse(params: RestoreTasksService.SearchObjectsParams): __Observable<__StrictHttpResponse<ObjectSearchResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewBoxIds || []).forEach(val => {if (val != null) __params = __params.append('viewBoxIds', val.toString())});
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.startIndex != null) __params = __params.set('startIndex', params.startIndex.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    (params.registeredSourceIds || []).forEach(val => {if (val != null) __params = __params.append('registeredSourceIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.ownerEntityId != null) __params = __params.set('ownerEntityId', params.ownerEntityId.toString());
    (params.operatingSystems || []).forEach(val => {if (val != null) __params = __params.append('operatingSystems', val.toString())});
    (params.office365SourceTypes || []).forEach(val => {if (val != null) __params = __params.append('office365SourceTypes', val.toString())});
    (params.jobIds || []).forEach(val => {if (val != null) __params = __params.append('jobIds', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.application != null) __params = __params.set('application', params.application.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectSearchResults>;
      })
    );
  }
  /**
   * Find backup objects that match the specified search and filter criteria
   * on the Cohesity Cluster.
   *
   * If no search pattern or filter parameters are specified, all backup objects
   * currently found on the Cohesity Cluster are returned.
   * Only leaf objects that have been protected by a Job are returned such as VMs,
   * Views and databases.
   * Specify a search pattern or parameters to filter the results that
   * are returned.
   *
   * The term "items" below refers to leaf backup objects such as VMs,
   * Views and databases.
   * @param params The `RestoreTasksService.SearchObjectsParams` containing the following parameters:
   *
   * - `viewBoxIds`: Filter by a list of Domains (View Boxes) ids.
   *   Only items stored in the listed Domains (View Boxes) are returned.
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `startTimeUsecs`: Filter by backup completion time by specifying a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `startIndex`: Specifies an index number that can be used to return subsets of items
   *   in multiple requests.
   *   Break up the items to return into multiple requests
   *   by setting pageCount and using startIndex to return a subsets of items.
   *   For example, set startIndex to 0 to get the first set of items
   *   for the first request. Increment startIndex by pageCount
   *   to get the next set of items for a next request.
   *   Continue until all items are returned and therefore the total number of
   *   returned items is equal to totalCount.
   *
   * - `search`: Filter by searching for sub-strings in the item name. The specified
   *   string can match any part of the item name.
   *   For example: "vm" or "123" both match the item name of "vm-123".
   *
   * - `registeredSourceIds`: Filter by a list of Registered Sources ids. Only items
   *   from the listed Registered Sources are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Limit the number of items to return in the response for pagination
   *   purposes.
   *
   * - `ownerEntityId`: Filter objects by the Entity id of the owner VM. For example, if a
   *   ownerEntityId is provided while searching for SQL databases,
   *   only SQL databases belonging to the VM with the specified id
   *   are returned.
   *   ownerEntityId is only significant if application is set to SQL.
   *
   * - `operatingSystems`: Filter by the Operating Systems running on VMs and Physical Servers.
   *   This filter is applicable only to VMs and physical servers.
   *
   * - `office365SourceTypes`: Filter by Office365 types such as 'kUser', 'kSite', etc.
   *   Only items from the specified source types are returned.
   *
   * - `jobIds`: Filter by a list of Protection Job ids.
   *   Only items backed up by the specified Jobs are listed.
   *
   * - `environments`: Filter by environment types such as 'kVMware', 'kView', etc.
   *   Only items from the specified environment types are returned.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *
   * - `endTimeUsecs`: Filter by backup completion time by specify a backup
   *   completion start and end times. Specified as a Unix epoch
   *   Timestamp (in microseconds).
   *   Only items created by backups that completed between the
   *   specified start and end times are returned.
   *
   * - `application`: Filter by application when the environment type is kSQL.
   *   For example, if SQL is specified the SQL databases are returned.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchObjects(params: RestoreTasksService.SearchObjectsParams): __Observable<ObjectSearchResults> {
    return this.SearchObjectsResponse(params).pipe(
      __map(_r => _r.body as ObjectSearchResults)
    );
  }

  /**
   * Returns the OneDrive files and folders.
   *
   * Search for OneDrive files and folder to recover that match the specified
   * search and filter criterias on the Cohesity cluster.
   * @param params The `RestoreTasksService.GetOneDriveDocumentsParams` containing the following parameters:
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionJobIds`: Specifies the protection job Ids which have backed up mailbox(es)
   *   continaing emails/folders.
   *
   * - `mailboxIds`: Specifies the Office365 User Ids which is teh owner of the OneDrive.
   *
   * - `domainIds`: Specifies the domain Ids in which Users' OneDrives are registered.
   *
   * - `documentName`: Specifies the document(file/folder) name.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOneDriveDocumentsResponse(params: RestoreTasksService.GetOneDriveDocumentsParams): __Observable<__StrictHttpResponse<FileSearchResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectionJobIds || []).forEach(val => {if (val != null) __params = __params.append('protectionJobIds', val.toString())});
    (params.mailboxIds || []).forEach(val => {if (val != null) __params = __params.append('mailboxIds', val.toString())});
    (params.domainIds || []).forEach(val => {if (val != null) __params = __params.append('domainIds', val.toString())});
    if (params.documentName != null) __params = __params.set('documentName', params.documentName.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/office365/onedrive/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileSearchResults>;
      })
    );
  }
  /**
   * Returns the OneDrive files and folders.
   *
   * Search for OneDrive files and folder to recover that match the specified
   * search and filter criterias on the Cohesity cluster.
   * @param params The `RestoreTasksService.GetOneDriveDocumentsParams` containing the following parameters:
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `protectionJobIds`: Specifies the protection job Ids which have backed up mailbox(es)
   *   continaing emails/folders.
   *
   * - `mailboxIds`: Specifies the Office365 User Ids which is teh owner of the OneDrive.
   *
   * - `domainIds`: Specifies the domain Ids in which Users' OneDrives are registered.
   *
   * - `documentName`: Specifies the document(file/folder) name.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOneDriveDocuments(params: RestoreTasksService.GetOneDriveDocumentsParams): __Observable<FileSearchResults> {
    return this.GetOneDriveDocumentsResponse(params).pipe(
      __map(_r => _r.body as FileSearchResults)
    );
  }

  /**
   * Returns the Outlook emails and folders containing emails.
   *
   * Search for Emails and Emails' folders to recover that match the specified
   * search and filter criterias on the Cohesity cluster.
   * @param params The `RestoreTasksService.GetOutlookEmailsParams` containing the following parameters:
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `showOnlyEmailFolders`: Specifies whether the query result should include only Email folders.
   *
   * - `sentTimeSeconds`: Specifies the unix time when the email was sent.
   *
   * - `senderAddress`: Specifies the email address of the sender.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `recipientAddresses`: Specifies the email addresses of the recipients.
   *
   * - `receivedTimeSeconds`: Specifies the unix time when the email was received.
   *
   * - `receivedStartTime`: Specifies the unix start time for querying on email's received time.
   *
   * - `receivedEndTime`: Specifies the unix end time for querying on email's received time.
   *
   * - `protectionJobIds`: Specifies the protection job Ids which have backed up mailbox(es)
   *   continaing emails/folders.
   *
   * - `mailboxIds`: Specifies the mailbox Ids which contains the emails/folders.
   *
   * - `hasAttachments`: Specifies whether the emails have any attachments.
   *
   * - `folderName`: Specifies the parent folder name of the email.
   *
   * - `emailSubject`: Specifies the subject of the email.
   *
   * - `domainIds`: Specifies the domain Ids in which mailboxes are registered.
   *
   * - `ccRecipientAddresses`: Specifies the email addresses of the cc recipients.
   *
   * - `bccRecipientAddresses`: Specifies the email addresses of the bcc recipients.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOutlookEmailsResponse(params: RestoreTasksService.GetOutlookEmailsParams): __Observable<__StrictHttpResponse<FileSearchResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.showOnlyEmailFolders != null) __params = __params.set('showOnlyEmailFolders', params.showOnlyEmailFolders.toString());
    if (params.sentTimeSeconds != null) __params = __params.set('sentTimeSeconds', params.sentTimeSeconds.toString());
    if (params.senderAddress != null) __params = __params.set('senderAddress', params.senderAddress.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.recipientAddresses || []).forEach(val => {if (val != null) __params = __params.append('recipientAddresses', val.toString())});
    if (params.receivedTimeSeconds != null) __params = __params.set('receivedTimeSeconds', params.receivedTimeSeconds.toString());
    if (params.receivedStartTime != null) __params = __params.set('receivedStartTime', params.receivedStartTime.toString());
    if (params.receivedEndTime != null) __params = __params.set('receivedEndTime', params.receivedEndTime.toString());
    (params.protectionJobIds || []).forEach(val => {if (val != null) __params = __params.append('protectionJobIds', val.toString())});
    (params.mailboxIds || []).forEach(val => {if (val != null) __params = __params.append('mailboxIds', val.toString())});
    if (params.hasAttachments != null) __params = __params.set('hasAttachments', params.hasAttachments.toString());
    if (params.folderName != null) __params = __params.set('folderName', params.folderName.toString());
    if (params.emailSubject != null) __params = __params.set('emailSubject', params.emailSubject.toString());
    (params.domainIds || []).forEach(val => {if (val != null) __params = __params.append('domainIds', val.toString())});
    (params.ccRecipientAddresses || []).forEach(val => {if (val != null) __params = __params.append('ccRecipientAddresses', val.toString())});
    (params.bccRecipientAddresses || []).forEach(val => {if (val != null) __params = __params.append('bccRecipientAddresses', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/office365/outlook/emails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileSearchResults>;
      })
    );
  }
  /**
   * Returns the Outlook emails and folders containing emails.
   *
   * Search for Emails and Emails' folders to recover that match the specified
   * search and filter criterias on the Cohesity cluster.
   * @param params The `RestoreTasksService.GetOutlookEmailsParams` containing the following parameters:
   *
   * - `tenantId`: TenantId specifies the tenant whose action resulted in the audit log.
   *
   * - `showOnlyEmailFolders`: Specifies whether the query result should include only Email folders.
   *
   * - `sentTimeSeconds`: Specifies the unix time when the email was sent.
   *
   * - `senderAddress`: Specifies the email address of the sender.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `recipientAddresses`: Specifies the email addresses of the recipients.
   *
   * - `receivedTimeSeconds`: Specifies the unix time when the email was received.
   *
   * - `receivedStartTime`: Specifies the unix start time for querying on email's received time.
   *
   * - `receivedEndTime`: Specifies the unix end time for querying on email's received time.
   *
   * - `protectionJobIds`: Specifies the protection job Ids which have backed up mailbox(es)
   *   continaing emails/folders.
   *
   * - `mailboxIds`: Specifies the mailbox Ids which contains the emails/folders.
   *
   * - `hasAttachments`: Specifies whether the emails have any attachments.
   *
   * - `folderName`: Specifies the parent folder name of the email.
   *
   * - `emailSubject`: Specifies the subject of the email.
   *
   * - `domainIds`: Specifies the domain Ids in which mailboxes are registered.
   *
   * - `ccRecipientAddresses`: Specifies the email addresses of the cc recipients.
   *
   * - `bccRecipientAddresses`: Specifies the email addresses of the bcc recipients.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
   *   of tenant with id TenantId should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOutlookEmails(params: RestoreTasksService.GetOutlookEmailsParams): __Observable<FileSearchResults> {
    return this.GetOutlookEmailsResponse(params).pipe(
      __map(_r => _r.body as FileSearchResults)
    );
  }

  /**
   * List Restore Points in a give time range.
   *
   * Returns the snapshots in the time range specified.
   * @param params The `RestoreTasksService.GetRestorePointsForTimeRangeParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRestorePointsForTimeRangeResponse(params: RestoreTasksService.GetRestorePointsForTimeRangeParams): __Observable<__StrictHttpResponse<RestorePointsForTimeRange>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/pointsForTimeRange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestorePointsForTimeRange>;
      })
    );
  }
  /**
   * List Restore Points in a give time range.
   *
   * Returns the snapshots in the time range specified.
   * @param params The `RestoreTasksService.GetRestorePointsForTimeRangeParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRestorePointsForTimeRange(params: RestoreTasksService.GetRestorePointsForTimeRangeParams): __Observable<RestorePointsForTimeRange> {
    return this.GetRestorePointsForTimeRangeResponse(params).pipe(
      __map(_r => _r.body as RestorePointsForTimeRange)
    );
  }

  /**
   * Updates an existing restore task with additional params which are needed for
   * features like Hot-Standby.
   * @param params The `RestoreTasksService.UpdateRestoreTaskParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Specifies the response of the UpdateRestoreTask API.
   * Success
   */
  UpdateRestoreTaskResponse(params: RestoreTasksService.UpdateRestoreTaskParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/restore/recover`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Updates an existing restore task with additional params which are needed for
   * features like Hot-Standby.
   * @param params The `RestoreTasksService.UpdateRestoreTaskParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Specifies the response of the UpdateRestoreTask API.
   * Success
   */
  UpdateRestoreTask(params: RestoreTasksService.UpdateRestoreTaskParams): __Observable<RestoreTask> {
    return this.UpdateRestoreTaskResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Create a Restore Task for recovering VMs or instantly mounting volumes.
   *
   * Returns the created Restore Task. This operation returns the following
   * types of Restore Tasks: 1) A Restore Task that recovers VMs back to the
   * original location or a new location. 2) A Restore Task that mounts the
   * volumes of a Server (such as a VM or Physical Server) onto the specified
   * target system. The Snapshots of the Server that contains the volumes
   * that are mounted is determined by Array of Objects.
   * The content of the Server is available from the mount point
   * for the Granular Level Recovery (GLR) of application data. For example
   * recovering Microsoft Exchange data using Kroll Ontrack® PowerControls™.
   *
   * NOTE: Volumes are mounted "instantly" if the Snapshot is stored locally on the
   * Cohesity Cluster. If the Snapshot is archival target, it will take longer
   * because it must be retrieved.
   * @param params The `RestoreTasksService.CreateRecoverTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for recovering VMs or mounting
   *   volumes to mount points.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRecoverTaskResponse(params: RestoreTasksService.CreateRecoverTaskParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/restore/recover`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * Create a Restore Task for recovering VMs or instantly mounting volumes.
   *
   * Returns the created Restore Task. This operation returns the following
   * types of Restore Tasks: 1) A Restore Task that recovers VMs back to the
   * original location or a new location. 2) A Restore Task that mounts the
   * volumes of a Server (such as a VM or Physical Server) onto the specified
   * target system. The Snapshots of the Server that contains the volumes
   * that are mounted is determined by Array of Objects.
   * The content of the Server is available from the mount point
   * for the Granular Level Recovery (GLR) of application data. For example
   * recovering Microsoft Exchange data using Kroll Ontrack® PowerControls™.
   *
   * NOTE: Volumes are mounted "instantly" if the Snapshot is stored locally on the
   * Cohesity Cluster. If the Snapshot is archival target, it will take longer
   * because it must be retrieved.
   * @param params The `RestoreTasksService.CreateRecoverTaskParams` containing the following parameters:
   *
   * - `body`: Request to create a Restore Task for recovering VMs or mounting
   *   volumes to mount points.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRecoverTask(params: RestoreTasksService.CreateRecoverTaskParams): __Observable<RestoreTask> {
    return this.CreateRecoverTaskResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * List the Restore Tasks filtered by the specified parameters.
   *
   * If no parameters are specified, all Restore Tasks found
   * on the Cohesity Cluster are returned. Both running and completed
   * Restore Tasks are reported.
   * Specifying parameters filters the results that are returned.
   * @param params The `RestoreTasksService.GetRestoreTasksParams` containing the following parameters:
   *
   * - `taskTypes`: Filter by the types of Restore Tasks such as 'kRecoverVMs',
   *   'kCloneVMs', 'kCloneView' or 'kMountVolumes'.
   *
   * - `taskIds`: Filter by a list of Restore Task ids.
   *
   * - `startTimeUsecs`: Filter by a start time specified as a Unix epoch Timestamp
   *   (in microseconds). All Restore Tasks (both completed and running)
   *   on the Cohesity Cluster that started after the specified start time
   *   but before the specified end time are returned.
   *   If not set, the start time is creation time of the Cohesity Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the number of completed Restore Tasks to return in the response
   *   for pagination purposes. Running Restore Tasks are always returned.
   *   The newest completed Restore Tasks are returned.
   *
   * - `environment`: Specifies the environment like VMware, SQL, where the
   *   Protection Source exists.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `endTimeUsecs`: Filter by an end time specified as a Unix epoch Timestamp
   *   (in microseconds). All Restore Tasks (both completed and running)
   *   on the Cohesity Cluster that started after the specified start time
   *   but before the specified end time are returned.
   *   If not set, the end time is the current time.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRestoreTasksResponse(params: RestoreTasksService.GetRestoreTasksParams): __Observable<__StrictHttpResponse<Array<RestoreTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.taskTypes || []).forEach(val => {if (val != null) __params = __params.append('taskTypes', val.toString())});
    (params.taskIds || []).forEach(val => {if (val != null) __params = __params.append('taskIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pageCount != null) __params = __params.set('pageCount', params.pageCount.toString());
    if (params.environment != null) __params = __params.set('environment', params.environment.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RestoreTask>>;
      })
    );
  }
  /**
   * List the Restore Tasks filtered by the specified parameters.
   *
   * If no parameters are specified, all Restore Tasks found
   * on the Cohesity Cluster are returned. Both running and completed
   * Restore Tasks are reported.
   * Specifying parameters filters the results that are returned.
   * @param params The `RestoreTasksService.GetRestoreTasksParams` containing the following parameters:
   *
   * - `taskTypes`: Filter by the types of Restore Tasks such as 'kRecoverVMs',
   *   'kCloneVMs', 'kCloneView' or 'kMountVolumes'.
   *
   * - `taskIds`: Filter by a list of Restore Task ids.
   *
   * - `startTimeUsecs`: Filter by a start time specified as a Unix epoch Timestamp
   *   (in microseconds). All Restore Tasks (both completed and running)
   *   on the Cohesity Cluster that started after the specified start time
   *   but before the specified end time are returned.
   *   If not set, the start time is creation time of the Cohesity Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pageCount`: Specifies the number of completed Restore Tasks to return in the response
   *   for pagination purposes. Running Restore Tasks are always returned.
   *   The newest completed Restore Tasks are returned.
   *
   * - `environment`: Specifies the environment like VMware, SQL, where the
   *   Protection Source exists.
   *   Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
   *   NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
   *   'kVMware' indicates the VMware Protection Source environment.
   *   'kHyperV' indicates the HyperV Protection Source environment.
   *   'kSQL' indicates the SQL Protection Source environment.
   *   'kView' indicates the View Protection Source environment.
   *   'kPuppeteer' indicates the Cohesity's Remote Adapter.
   *   'kPhysical' indicates the physical Protection Source environment.
   *   'kPure' indicates the Pure Storage Protection Source environment.
   *   'Nimble' indicates the Nimble Storage Protection Source environment.
   *   'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
   *   'kAzure' indicates the Microsoft's Azure Protection Source environment.
   *   'kNetapp' indicates the Netapp Protection Source environment.
   *   'kAgent' indicates the Agent Protection Source environment.
   *   'kGenericNas' indicates the Generic Network Attached Storage Protection
   *   Source environment.
   *   'kAcropolis' indicates the Acropolis Protection Source environment.
   *   'kPhsicalFiles' indicates the Physical Files Protection Source environment.
   *   'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
   *   'kGPFS' indicates IBM's GPFS Protection Source environment.
   *   'kKVM' indicates the KVM Protection Source environment.
   *   'kAWS' indicates the AWS Protection Source environment.
   *   'kExchange' indicates the Exchange Protection Source environment.
   *   'kHyperVVSS' indicates the HyperV VSS Protection Source
   *   environment.
   *   'kOracle' indicates the Oracle Protection Source environment.
   *   'kGCP' indicates the Google Cloud Platform Protection Source environment.
   *   'kFlashBlade' indicates the Flash Blade Protection Source environment.
   *   'kAWSNative' indicates the AWS Native Protection Source environment.
   *   'kO365' indicates the Office 365 Protection Source environment.
   *   'kO365Outlook' indicates Office 365 outlook Protection Source environment.
   *   'kHyperFlex' indicates the Hyper Flex Protection Source environment.
   *   'kGCPNative' indicates the GCP Native Protection Source environment.
   *   'kAzureNative' indicates the Azure Native Protection Source environment.
   *   'kKubernetes' indicates a Kubernetes Protection Source environment.
   *   'kElastifile' indicates Elastifile Protection Source environment.
   *   'kAD' indicates Active Directory Protection Source environment.
   *   'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
   *   'kCassandra' indicates Cassandra Protection Source environment.
   *   'kMongoDB' indicates MongoDB Protection Source environment.
   *   'kCouchbase' indicates Couchbase Protection Source environment.
   *   'kHdfs' indicates Hdfs Protection Source environment.
   *   'kHive' indicates Hive Protection Source environment.
   *   'kHBase' indicates HBase Protection Source environment.
   *
   * - `endTimeUsecs`: Filter by an end time specified as a Unix epoch Timestamp
   *   (in microseconds). All Restore Tasks (both completed and running)
   *   on the Cohesity Cluster that started after the specified start time
   *   but before the specified end time are returned.
   *   If not set, the end time is the current time.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRestoreTasks(params: RestoreTasksService.GetRestoreTasksParams): __Observable<Array<RestoreTask>> {
    return this.GetRestoreTasksResponse(params).pipe(
      __map(_r => _r.body as Array<RestoreTask>)
    );
  }

  /**
   * Cancel a recover or clone task with specified id.
   * @param params The `RestoreTasksService.CancelRestoreTaskParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id for the Restore Task.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  CancelRestoreTaskResponse(params: RestoreTasksService.CancelRestoreTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/restore/tasks/cancel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel a recover or clone task with specified id.
   * @param params The `RestoreTasksService.CancelRestoreTaskParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id for the Restore Task.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  CancelRestoreTask(params: RestoreTasksService.CancelRestoreTaskParams): __Observable<null> {
    return this.CancelRestoreTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List details about a single Restore Task.
   *
   * Returns the Restore Task corresponding to the specified Restore Task id.
   * @param params The `RestoreTasksService.GetRestoreTaskByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id for the Restore Task.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRestoreTaskByIdResponse(params: RestoreTasksService.GetRestoreTaskByIdParams): __Observable<__StrictHttpResponse<RestoreTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/tasks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTask>;
      })
    );
  }
  /**
   * List details about a single Restore Task.
   *
   * Returns the Restore Task corresponding to the specified Restore Task id.
   * @param params The `RestoreTasksService.GetRestoreTaskByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id for the Restore Task.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRestoreTaskById(params: RestoreTasksService.GetRestoreTaskByIdParams): __Observable<RestoreTask> {
    return this.GetRestoreTaskByIdResponse(params).pipe(
      __map(_r => _r.body as RestoreTask)
    );
  }

  /**
   * Fetches information of virtual disk.
   * @param params The `RestoreTasksService.GetVirtualDiskInformationParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time of the job run as a Unix epoch Timestamp in
   *   microseconds.
   *
   * - `sourceId`: Specifies the Id of the Protection Source object.
   *
   * - `jobRunId`: Specifies the id of the Job Run that captured the snapshot.
   *
   * - `jobId`: Specifies the id of the Job that captured the snapshot.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the Job was
   *   created.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the Job was created.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pointInTimeUsecs`: PointInTimeUsecs is the time to get volume virtual disk info from
   *   previously available full/incremental snapshot.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVirtualDiskInformationResponse(params: RestoreTasksService.GetVirtualDiskInformationParams): __Observable<__StrictHttpResponse<Array<VirtualDiskInformation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.jobRunId != null) __params = __params.set('jobRunId', params.jobRunId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.clusterIncarnationId != null) __params = __params.set('clusterIncarnationId', params.clusterIncarnationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.pointInTimeUsecs != null) __params = __params.set('pointInTimeUsecs', params.pointInTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/virtualDiskInformation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VirtualDiskInformation>>;
      })
    );
  }
  /**
   * Fetches information of virtual disk.
   * @param params The `RestoreTasksService.GetVirtualDiskInformationParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Specifies the start time of the job run as a Unix epoch Timestamp in
   *   microseconds.
   *
   * - `sourceId`: Specifies the Id of the Protection Source object.
   *
   * - `jobRunId`: Specifies the id of the Job Run that captured the snapshot.
   *
   * - `jobId`: Specifies the id of the Job that captured the snapshot.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the Job was
   *   created.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the Job was created.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `pointInTimeUsecs`: PointInTimeUsecs is the time to get volume virtual disk info from
   *   previously available full/incremental snapshot.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVirtualDiskInformation(params: RestoreTasksService.GetVirtualDiskInformationParams): __Observable<Array<VirtualDiskInformation>> {
    return this.GetVirtualDiskInformationResponse(params).pipe(
      __map(_r => _r.body as Array<VirtualDiskInformation>)
    );
  }

  /**
   * Get the directory list based on the given directory name and other query parameters.
   * @param params The `RestoreTasksService.GetVmDirectoryListParams` containing the following parameters:
   *
   * - `jobUidObjectId`: JobUidObjectId is the globally unique id of the job that the object
   *   originally belonged to. If this object originally belonged to a job from a
   *   remote cluster, this field will contain the JobId of the remote job, else
   *   it will contain the JobId of the local job.
   *
   * - `jobStartTimeUsecs`: JobStartTimeUsecs is the start time in usecs of the job run that backed up
   *   the entity.
   *
   * - `jobInstanceId`: JobInstanceId is the Id of the job run that backed up the entity.
   *
   * - `jobId`: JobId is the id of the local job that took the snapshot, which may or
   *   may not match the JobUidObjectId below depending on whether the object
   *   originally belonged to this local job or to a different remote job.
   *
   * - `entityId`: EntityId is the Id of the VM.
   *
   * - `dirPath`: DirPath is the full path of the directory whose contents need to be listed.
   *
   * - `volumeName`: VolumeName is the name of the volume that needs to be browsed. This should
   *   match the name returned in VolumeInfo.
   *
   * - `volumeInfoCookie`: VolumeInfoCookie is the cookie to be passed in calls to reading a VM dir
   *   for this volume.
   *
   * - `viewName`: Name of the View if a View is being browsed.
   *
   * - `viewBoxId`: Id of the View Box if a View is being browsed.
   *
   * - `statFileEntries`: StatFileEntries specifies whether file stat data is returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxEntries`: MaxEntries is the maximum number of entries to return in this call. If
   *   there are more entries, server will return a cookie in the response that
   *   can be used to continue enumeration from the last call.
   *
   * - `cookie`: Cookie is used for paginating results. If ReadDirResult returned partial
   *   results, it will also return a cookie that can be used to resume the
   *   listing. The value returned in ReadDirResult should be passed in the next
   *   call. The first call should not have this value set. Note that this value
   *   is only a suggestion and server is free to do a short read (return fewer
   *   entries along with a cookie).
   *
   * - `browseIndexedData`: Specifies whether to use indexed data in Librarian for browse.
   *
   * - `attemptNum`: AttemptNum is the attempt number of the run that successfully created the
   *   snapshot.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVmDirectoryListResponse(params: RestoreTasksService.GetVmDirectoryListParams): __Observable<__StrictHttpResponse<VmDirectoryListResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.jobUidObjectId != null) __params = __params.set('jobUidObjectId', params.jobUidObjectId.toString());
    if (params.jobStartTimeUsecs != null) __params = __params.set('jobStartTimeUsecs', params.jobStartTimeUsecs.toString());
    if (params.jobInstanceId != null) __params = __params.set('jobInstanceId', params.jobInstanceId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    if (params.dirPath != null) __params = __params.set('dirPath', params.dirPath.toString());
    if (params.volumeName != null) __params = __params.set('volumeName', params.volumeName.toString());
    if (params.volumeInfoCookie != null) __params = __params.set('volumeInfoCookie', params.volumeInfoCookie.toString());
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.viewBoxId != null) __params = __params.set('viewBoxId', params.viewBoxId.toString());
    if (params.statFileEntries != null) __params = __params.set('statFileEntries', params.statFileEntries.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxEntries != null) __params = __params.set('maxEntries', params.maxEntries.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.browseIndexedData != null) __params = __params.set('browseIndexedData', params.browseIndexedData.toString());
    if (params.attemptNum != null) __params = __params.set('attemptNum', params.attemptNum.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/vms/directoryList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VmDirectoryListResult>;
      })
    );
  }
  /**
   * Get the directory list based on the given directory name and other query parameters.
   * @param params The `RestoreTasksService.GetVmDirectoryListParams` containing the following parameters:
   *
   * - `jobUidObjectId`: JobUidObjectId is the globally unique id of the job that the object
   *   originally belonged to. If this object originally belonged to a job from a
   *   remote cluster, this field will contain the JobId of the remote job, else
   *   it will contain the JobId of the local job.
   *
   * - `jobStartTimeUsecs`: JobStartTimeUsecs is the start time in usecs of the job run that backed up
   *   the entity.
   *
   * - `jobInstanceId`: JobInstanceId is the Id of the job run that backed up the entity.
   *
   * - `jobId`: JobId is the id of the local job that took the snapshot, which may or
   *   may not match the JobUidObjectId below depending on whether the object
   *   originally belonged to this local job or to a different remote job.
   *
   * - `entityId`: EntityId is the Id of the VM.
   *
   * - `dirPath`: DirPath is the full path of the directory whose contents need to be listed.
   *
   * - `volumeName`: VolumeName is the name of the volume that needs to be browsed. This should
   *   match the name returned in VolumeInfo.
   *
   * - `volumeInfoCookie`: VolumeInfoCookie is the cookie to be passed in calls to reading a VM dir
   *   for this volume.
   *
   * - `viewName`: Name of the View if a View is being browsed.
   *
   * - `viewBoxId`: Id of the View Box if a View is being browsed.
   *
   * - `statFileEntries`: StatFileEntries specifies whether file stat data is returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxEntries`: MaxEntries is the maximum number of entries to return in this call. If
   *   there are more entries, server will return a cookie in the response that
   *   can be used to continue enumeration from the last call.
   *
   * - `cookie`: Cookie is used for paginating results. If ReadDirResult returned partial
   *   results, it will also return a cookie that can be used to resume the
   *   listing. The value returned in ReadDirResult should be passed in the next
   *   call. The first call should not have this value set. Note that this value
   *   is only a suggestion and server is free to do a short read (return fewer
   *   entries along with a cookie).
   *
   * - `browseIndexedData`: Specifies whether to use indexed data in Librarian for browse.
   *
   * - `attemptNum`: AttemptNum is the attempt number of the run that successfully created the
   *   snapshot.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVmDirectoryList(params: RestoreTasksService.GetVmDirectoryListParams): __Observable<VmDirectoryListResult> {
    return this.GetVmDirectoryListResponse(params).pipe(
      __map(_r => _r.body as VmDirectoryListResult)
    );
  }

  /**
   * Get information about the logical volumes found in a VM.
   *
   * All required fields must be specified for this operation.
   * To get values for these fields, invoke the GET /public/restore/objects
   * operation.
   * A specific Job Run is defined by the jobRunId, startedTimeUsecs, and
   * attemptNumber fields.
   * @param params The `RestoreTasksService.GetVmVolumesInformationParams` containing the following parameters:
   *
   * - `startedTimeUsecs`: Specifies the time when the Job Run starts capturing a snapshot.
   *   Specified as a Unix epoch Timestamp (in microseconds).
   *
   * - `sourceId`: Specifies the id of the VM object to search for volumes.
   *
   * - `originalJobId`: Specifies the id for the Protection Job that originally captured
   *   the snapshots of the original object. If the object was backed up
   *   on a Primary Cluster replicated to this Cohesity Cluster,
   *   and a new Inactive Job is created,
   *   this field still contains the id of the original Job and NOT the
   *   id of the new Inactive Job.
   *   This field is used in combination with the clusterId and
   *   clusterIncarnationId to uniquely identify a Job.
   *
   * - `jobRunId`: Specifies the id of the Job Run that captured the snapshot.
   *
   * - `jobId`: Specifies the Job id for the Protection Job that is currently
   *   associated with the object.
   *   If the object was backed up on current Cohesity Cluster, this field
   *   contains the id for the Job that captured this backup object.
   *   If the object was backed up on a Primary Cluster and
   *   replicated to this Cohesity Cluster, a new Inactive Job is created,
   *   the object is now associated with new Inactive Job, and this field
   *   contains the id of the new Inactive Job.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the Job
   *   was created.
   *   An incarnation id is generated when a Cohesity Cluster is initially
   *   created.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the Job was created.
   *
   * - `supportedVolumesOnly`: Specifies to return only supported volumes information. Unsupported
   *   volumes are not returned if this flag is set to true.
   *   Default is false.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `computeVolumeInfo`: Specifies whether to compute volume information if it is not found.
   *   If `ComputeVolumeInfo` is false and volume information is not found it
   *   skips computation of volume information and returns KNotFound.
   *
   * - `attemptNumber`: Specifies the number of the attempts made by the Job Run
   *   to capture a snapshot of the object. For example, if an snapshot
   *   is successfully captured after three attempts, this field equals 3.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVmVolumesInformationResponse(params: RestoreTasksService.GetVmVolumesInformationParams): __Observable<__StrictHttpResponse<VmVolumesInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startedTimeUsecs != null) __params = __params.set('startedTimeUsecs', params.startedTimeUsecs.toString());
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.originalJobId != null) __params = __params.set('originalJobId', params.originalJobId.toString());
    if (params.jobRunId != null) __params = __params.set('jobRunId', params.jobRunId.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.clusterIncarnationId != null) __params = __params.set('clusterIncarnationId', params.clusterIncarnationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.supportedVolumesOnly != null) __params = __params.set('supportedVolumesOnly', params.supportedVolumesOnly.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.computeVolumeInfo != null) __params = __params.set('computeVolumeInfo', params.computeVolumeInfo.toString());
    if (params.attemptNumber != null) __params = __params.set('attemptNumber', params.attemptNumber.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/restore/vms/volumesInformation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VmVolumesInformation>;
      })
    );
  }
  /**
   * Get information about the logical volumes found in a VM.
   *
   * All required fields must be specified for this operation.
   * To get values for these fields, invoke the GET /public/restore/objects
   * operation.
   * A specific Job Run is defined by the jobRunId, startedTimeUsecs, and
   * attemptNumber fields.
   * @param params The `RestoreTasksService.GetVmVolumesInformationParams` containing the following parameters:
   *
   * - `startedTimeUsecs`: Specifies the time when the Job Run starts capturing a snapshot.
   *   Specified as a Unix epoch Timestamp (in microseconds).
   *
   * - `sourceId`: Specifies the id of the VM object to search for volumes.
   *
   * - `originalJobId`: Specifies the id for the Protection Job that originally captured
   *   the snapshots of the original object. If the object was backed up
   *   on a Primary Cluster replicated to this Cohesity Cluster,
   *   and a new Inactive Job is created,
   *   this field still contains the id of the original Job and NOT the
   *   id of the new Inactive Job.
   *   This field is used in combination with the clusterId and
   *   clusterIncarnationId to uniquely identify a Job.
   *
   * - `jobRunId`: Specifies the id of the Job Run that captured the snapshot.
   *
   * - `jobId`: Specifies the Job id for the Protection Job that is currently
   *   associated with the object.
   *   If the object was backed up on current Cohesity Cluster, this field
   *   contains the id for the Job that captured this backup object.
   *   If the object was backed up on a Primary Cluster and
   *   replicated to this Cohesity Cluster, a new Inactive Job is created,
   *   the object is now associated with new Inactive Job, and this field
   *   contains the id of the new Inactive Job.
   *
   * - `clusterIncarnationId`: Specifies the incarnation id of the Cohesity Cluster where the Job
   *   was created.
   *   An incarnation id is generated when a Cohesity Cluster is initially
   *   created.
   *
   * - `clusterId`: Specifies the Cohesity Cluster id where the Job was created.
   *
   * - `supportedVolumesOnly`: Specifies to return only supported volumes information. Unsupported
   *   volumes are not returned if this flag is set to true.
   *   Default is false.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `computeVolumeInfo`: Specifies whether to compute volume information if it is not found.
   *   If `ComputeVolumeInfo` is false and volume information is not found it
   *   skips computation of volume information and returns KNotFound.
   *
   * - `attemptNumber`: Specifies the number of the attempts made by the Job Run
   *   to capture a snapshot of the object. For example, if an snapshot
   *   is successfully captured after three attempts, this field equals 3.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVmVolumesInformation(params: RestoreTasksService.GetVmVolumesInformationParams): __Observable<VmVolumesInformation> {
    return this.GetVmVolumesInformationResponse(params).pipe(
      __map(_r => _r.body as VmVolumesInformation)
    );
  }
}

module RestoreTasksService {

  /**
   * Parameters for GetAdDomainRootTopology
   */
  export interface GetAdDomainRootTopologyParams {

    /**
     * Specifies the restoreTaskId corresponding to which we need to
     * get the ad topology.
     */
    restoreTaskId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CompareAdObjects
   */
  export interface CompareAdObjectsParams {

    /**
     * Specifies the Request to compare the AD Objects
     * from both Snapshot and Production AD.
     */
    body: CompareAdObjectsRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchAdObjects
   */
  export interface SearchAdObjectsParams {

    /**
     * Specifies the restoreTaskId corresponding to which we need to search
     * AD objects.
     */
    restoreTaskId: number;

    /**
     * Specifies the search scope for the request.
     * If subtree search scope is true all the children of Search Base DN
     * are returned from given offset. If subtree search scope is false
     * only all objects which are one level from the Search Base DN are returned.
     */
    subtreeSearchScope?: boolean;

    /**
     * Specifies the search base distinguished name from where the search
     * should begin in the hierarchy of the AD in both Production
     * and Snapshot AD.
     */
    searchBaseDn?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the offset from which AD objects should be searched in both
     * the Snapshot and Production AD.
     */
    recordOffset?: number;

    /**
     * Specifies the number of AD Objects to be fetched.
     */
    numObjects?: number;

    /**
     * Specifies the filter which can be used for searching the AD Objects from
     * given Search Base DN.
     * There are two types of filters supported. They are:
     * 1) If the string does not contain LDAP delimiters '(' and ')', then it is
     * assumed to be ANR search "(anr=<ldap_filter>)"
     * Eg: "a" will result in query to return all ANR fields with "a" characters
     * (case insensitive) in them
     * 2) Search with OR and AND combination:
     * "(|(&(objectClass=user)(distinguishedName=CN=Jone
     * Doe,OU=Users,DC=corp,DC=cohesity,DC=com))(&(objectClass=user)
     * (sAMAccountName=jdoe)))"
     */
    filter?: string;

    /**
     * Specifies the option to exclude the system attributes while comparing the
     * the objects from the Production AD and Snapshot AD.
     */
    excludeSystemProperties?: boolean;

    /**
     * Specifies the option to compare the properties from Snapshot AD and
     * Production AD if specifed and sets kNotEqual flag in the result when
     * there is mismatch.
     */
    compareObjects?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchProductionAdObjects
   */
  export interface SearchProductionAdObjectsParams {

    /**
     * Specifies the Request to search the AD Objects from Production AD.
     */
    body: SearchProductionAdObjectsRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAdObjects
   */
  export interface GetAdObjectsParams {

    /**
     * Filter by a list of Domains (View Boxes) ids.
     * Only items stored in the listed Domains (View Boxes) are returned.
     */
    viewBoxIds?: Array<number>;

    /**
     * TenantId specifies the tenant whose action resulted in the audit log.
     */
    tenantId?: string;

    /**
     * Specifies the sam account name of the AD object.
     */
    samAccountName?: string;

    /**
     * Specifies the Active Directory Application Server Ids which contains
     * the AD objects.
     */
    registeredSourceIds?: Array<number>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the type of the AD Object. The type may be user, computer,
     * group or ou(organizational unit).
     */
    objectType?: string;

    /**
     * Specifies the name of the AD object.
     */
    name?: string;

    /**
     * Specifies the protection job Ids which have backed up Active Directory
     * Application Server.
     */
    jobIds?: Array<number>;

    /**
     * Specifies the email of the AD object of type user or group.
     */
    email?: string;

    /**
     * domain of the AD object.
     */
    domain?: string;

    /**
     * AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
     * of tenant with id TenantId should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AdObjectsRestoreStatus
   */
  export interface AdObjectsRestoreStatusParams {

    /**
     * Specifies the restoreTaskId corresponding to which we need to get
     * information about the restore of the AD objects.
     */
    restoreTaskId?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateApplicationsCloneTask
   */
  export interface CreateApplicationsCloneTaskParams {

    /**
     * Request to create a Restore Task for cloning Applications like SQL DB.
     */
    body: ApplicationsRestoreTaskRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateApplicationsRecoverTask
   */
  export interface CreateApplicationsRecoverTaskParams {

    /**
     * Request to create a Restore Task for recovering Applications like SQL DB.
     * volumes to mount points.
     */
    body: ApplicationsRestoreTaskRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateCloneTask
   */
  export interface CreateCloneTaskParams {

    /**
     * Request to create a Restore Task for cloning VMs or a View.
     */
    body: CloneTaskRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PublicDestroyCloneTask
   */
  export interface PublicDestroyCloneTaskParams {

    /**
     * Specifies a unique id of the Clone Task to destroy.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDeployTask
   */
  export interface CreateDeployTaskParams {

    /**
     * Request to create a Restore Task for deploying VMs or a View on cloud.
     */
    body: DeployTaskRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDownloadFilesAndFolders
   */
  export interface CreateDownloadFilesAndFoldersParams {

    /**
     * Request to create a task for downloading list of files or
     * folders.
     */
    body: DownloadFilesAndFoldersParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchRestoredFiles
   */
  export interface SearchRestoredFilesParams {

    /**
     * Filter by a list of Domains (View Boxes) ids.
     * Only items stored in the listed Domains (View Boxes) are returned.
     */
    viewBoxIds?: Array<number>;

    /**
     * TenantId specifies the tenant whose action resulted in the audit log.
     */
    tenantId?: string;

    /**
     * Filter by backup completion time by specifying a backup
     * completion start and end times. Specified as a Unix epoch
     * Timestamp (in microseconds).
     * Only items created by backups that completed between the
     * specified start and end times are returned.
     */
    startTimeUsecs?: number;

    /**
     * Specifies an index number that can be used to return subsets of items
     * in multiple requests.
     * Break up the items to return into multiple requests
     * by setting pageCount and using startIndex to return a subsets of items.
     * For example, set startIndex to 0 to get the first set of items
     * for the first request. Increment startIndex by pageCount
     * to get the next set of items for a next request.
     * Continue until all items are returned and therefore the total number of
     * returned items is equal to totalCount.
     */
    startIndex?: number;

    /**
     * Filter by source ids. Only files and folders found in the listed sources
     * (such as VMs) are returned.
     */
    sourceIds?: Array<number>;

    /**
     * Filter by searching for sub-strings in the item name. The specified
     * string can match any part of the item name.
     * For example: "vm" or "123" both match the item name of "vm-123".
     */
    search?: string;

    /**
     * Filter by a list of Registered Sources ids. Only items
     * from the listed Registered Sources are returned.
     */
    registeredSourceIds?: Array<number>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Limit the number of items to return in the response for pagination
     * purposes.
     */
    pageCount?: number;

    /**
     * Specifies tags which must be all present in the document.
     */
    mustHaveTags?: Array<string>;

    /**
     * Specifies snapshot tags which must be all present in the document.
     */
    mustHaveSnapshotTags?: Array<string>;

    /**
     * Specifies list of tags, one of which might be present in the document.
     * These are OR'ed together and the resulting criteria AND'ed with the
     * rest of the query.
     */
    mightHaveTags?: Array<string>;

    /**
     * Specifies list of snapshot tags, one of which might be present in the
     * document. These are OR'ed together and the resulting criteria AND'ed
     * with the rest of the query.
     */
    mightHaveSnapshotTags?: Array<string>;

    /**
     * Filter by a list of Protection Job ids.
     * Only items backed up by the specified Jobs are listed.
     */
    jobIds?: Array<number>;

    /**
     * Filter by folders or files. If true, only folders are returned.
     * If false, only files are returned. If not specified, both
     * files and folders are returned.
     */
    folderOnly?: boolean;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc.
     * Only items from the specified environment types are returned.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kKubernetes' | 'kElastifile' | 'kSAPHANA'>;

    /**
     * Filter by backup completion time by specify a backup
     * completion start and end times. Specified as a Unix epoch
     * Timestamp (in microseconds).
     * Only items created by backups that completed between the
     * specified start and end times are returned.
     */
    endTimeUsecs?: number;

    /**
     * AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
     * of tenant with id TenantId should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRestoreFilesTask
   */
  export interface CreateRestoreFilesTaskParams {

    /**
     * Request to create a Restore Task for recovering files or folders.
     */
    body: RestoreFilesTaskRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFileFstatInformation
   */
  export interface GetFileFstatInformationParams {

    /**
     * JobUidObjectId is the globally unique id of the job that the object
     * originally belonged to. If this object originally belonged to a job from a
     * remote cluster, this field will contain the JobId of the remote job, else
     * it will contain the JobId of the local job.
     */
    jobUidObjectId: number;

    /**
     * JobStartTimeUsecs is the start time in usecs of the job run that backed up
     * the entity.
     */
    jobStartTimeUsecs: number;

    /**
     * JobInstanceId is the Id of the job run that backed up the entity.
     */
    jobInstanceId: number;

    /**
     * JobId is the id of the local job that took the snapshot, which may or
     * may not match the JobUidObjectId below depending on whether the object
     * originally belonged to this local job or to a different remote job.
     */
    jobId: number;

    /**
     * FilePath is the full path of the file or directory whose stat needed.
     */
    filePath: string;

    /**
     * EntityId is the Id of the VM.
     */
    entityId: number;

    /**
     * VolumeName is the name of the volume that needs to be browsed. This should
     * match the name returned in VolumeInfo.
     */
    volumeName?: string;

    /**
     * VolumeInfoCookie is the cookie to be passed in calls to reading a VM dir
     * for this volume.
     */
    volumeInfoCookie?: number;

    /**
     * Name of the View if a View is being browsed.
     */
    viewName?: string;

    /**
     * Id of the View Box if a View is being browsed.
     */
    viewBoxId?: number;

    /**
     * Specifies whether to use Librarian for file stat. This will be true if the
     * browse is enabled via librarian.
     */
    useLibrarian?: boolean;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * AttemptNum is the attempt number of the run that successfully created the
     * snapshot.
     */
    attemptNum?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFileSnapshotsInformation
   */
  export interface GetFileSnapshotsInformationParams {

    /**
     * Specifies the id of the Protection Source object (such as a VM) to search.
     * When a Job Run executes, snapshots of the specified Protection Source
     * object are captured. This operation searches the snapshots of the
     * object for the file or folder. This field is required.
     */
    sourceId: number;

    /**
     * Specifies the id of the Job that captured the snapshots.
     * These snapshots are searched for the specified files or folders.
     * This field is required.
     */
    jobId: number;

    /**
     * Specifies the name of the file or folder to find in the snapshots.
     * This field is required.
     */
    filename: string;

    /**
     * Specifies the incarnation id of the Cohesity Cluster where the Job
     * was created.
     * An incarnation id is generated when a Cohesity Cluster is initially
     * created. This field is required.
     */
    clusterIncarnationId: number;

    /**
     * Specifies the Cohesity Cluster id where the Job was created.
     * This field is required.
     */
    clusterId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchObjects
   */
  export interface SearchObjectsParams {

    /**
     * Filter by a list of Domains (View Boxes) ids.
     * Only items stored in the listed Domains (View Boxes) are returned.
     */
    viewBoxIds?: Array<number>;

    /**
     * TenantId specifies the tenant whose action resulted in the audit log.
     */
    tenantId?: string;

    /**
     * Filter by backup completion time by specifying a backup
     * completion start and end times. Specified as a Unix epoch
     * Timestamp (in microseconds).
     * Only items created by backups that completed between the
     * specified start and end times are returned.
     */
    startTimeUsecs?: number;

    /**
     * Specifies an index number that can be used to return subsets of items
     * in multiple requests.
     * Break up the items to return into multiple requests
     * by setting pageCount and using startIndex to return a subsets of items.
     * For example, set startIndex to 0 to get the first set of items
     * for the first request. Increment startIndex by pageCount
     * to get the next set of items for a next request.
     * Continue until all items are returned and therefore the total number of
     * returned items is equal to totalCount.
     */
    startIndex?: number;

    /**
     * Filter by searching for sub-strings in the item name. The specified
     * string can match any part of the item name.
     * For example: "vm" or "123" both match the item name of "vm-123".
     */
    search?: string;

    /**
     * Filter by a list of Registered Sources ids. Only items
     * from the listed Registered Sources are returned.
     */
    registeredSourceIds?: Array<number>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Limit the number of items to return in the response for pagination
     * purposes.
     */
    pageCount?: number;

    /**
     * Filter objects by the Entity id of the owner VM. For example, if a
     * ownerEntityId is provided while searching for SQL databases,
     * only SQL databases belonging to the VM with the specified id
     * are returned.
     * ownerEntityId is only significant if application is set to SQL.
     */
    ownerEntityId?: number;

    /**
     * Filter by the Operating Systems running on VMs and Physical Servers.
     * This filter is applicable only to VMs and physical servers.
     */
    operatingSystems?: Array<string>;

    /**
     * Filter by Office365 types such as 'kUser', 'kSite', etc.
     * Only items from the specified source types are returned.
     */
    office365SourceTypes?: Array<'kDomain' | 'kOutlook' | 'kMailbox' | 'kUsers' | 'kGroups' | 'kSites' | 'kUser' | 'kGroup' | 'kSite' | 'kPublicFolders' | 'kPublicFolder' | 'kTeams' | 'kTeam'>;

    /**
     * Filter by a list of Protection Job ids.
     * Only items backed up by the specified Jobs are listed.
     */
    jobIds?: Array<number>;

    /**
     * Filter by environment types such as 'kVMware', 'kView', etc.
     * Only items from the specified environment types are returned.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kVCD' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kKubernetes' | 'kElastifile' | 'kSAPHANA'>;

    /**
     * Filter by backup completion time by specify a backup
     * completion start and end times. Specified as a Unix epoch
     * Timestamp (in microseconds).
     * Only items created by backups that completed between the
     * specified start and end times are returned.
     */
    endTimeUsecs?: number;

    /**
     * Filter by application when the environment type is kSQL.
     * For example, if SQL is specified the SQL databases are returned.
     */
    application?: string;

    /**
     * AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
     * of tenant with id TenantId should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetOneDriveDocuments
   */
  export interface GetOneDriveDocumentsParams {

    /**
     * TenantId specifies the tenant whose action resulted in the audit log.
     */
    tenantId?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the protection job Ids which have backed up mailbox(es)
     * continaing emails/folders.
     */
    protectionJobIds?: Array<number>;

    /**
     * Specifies the Office365 User Ids which is teh owner of the OneDrive.
     */
    mailboxIds?: Array<number>;

    /**
     * Specifies the domain Ids in which Users' OneDrives are registered.
     */
    domainIds?: Array<number>;

    /**
     * Specifies the document(file/folder) name.
     */
    documentName?: string;

    /**
     * AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
     * of tenant with id TenantId should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetOutlookEmails
   */
  export interface GetOutlookEmailsParams {

    /**
     * TenantId specifies the tenant whose action resulted in the audit log.
     */
    tenantId?: string;

    /**
     * Specifies whether the query result should include only Email folders.
     */
    showOnlyEmailFolders?: boolean;

    /**
     * Specifies the unix time when the email was sent.
     */
    sentTimeSeconds?: number;

    /**
     * Specifies the email address of the sender.
     */
    senderAddress?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the email addresses of the recipients.
     */
    recipientAddresses?: Array<string>;

    /**
     * Specifies the unix time when the email was received.
     */
    receivedTimeSeconds?: number;

    /**
     * Specifies the unix start time for querying on email's received time.
     */
    receivedStartTime?: number;

    /**
     * Specifies the unix end time for querying on email's received time.
     */
    receivedEndTime?: number;

    /**
     * Specifies the protection job Ids which have backed up mailbox(es)
     * continaing emails/folders.
     */
    protectionJobIds?: Array<number>;

    /**
     * Specifies the mailbox Ids which contains the emails/folders.
     */
    mailboxIds?: Array<number>;

    /**
     * Specifies whether the emails have any attachments.
     */
    hasAttachments?: boolean;

    /**
     * Specifies the parent folder name of the email.
     */
    folderName?: string;

    /**
     * Specifies the subject of the email.
     */
    emailSubject?: string;

    /**
     * Specifies the domain Ids in which mailboxes are registered.
     */
    domainIds?: Array<number>;

    /**
     * Specifies the email addresses of the cc recipients.
     */
    ccRecipientAddresses?: Array<string>;

    /**
     * Specifies the email addresses of the bcc recipients.
     */
    bccRecipientAddresses?: Array<string>;

    /**
     * AllUnderHierarchy specifies if logs of all the tenants under the hierarchy
     * of tenant with id TenantId should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRestorePointsForTimeRange
   */
  export interface GetRestorePointsForTimeRangeParams {
    body: RestorePointsForTimeRangeParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRestoreTask
   */
  export interface UpdateRestoreTaskParams {
    body: UpdateRestoreTaskParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRecoverTask
   */
  export interface CreateRecoverTaskParams {

    /**
     * Request to create a Restore Task for recovering VMs or mounting
     * volumes to mount points.
     */
    body: RecoverTaskRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRestoreTasks
   */
  export interface GetRestoreTasksParams {

    /**
     * Filter by the types of Restore Tasks such as 'kRecoverVMs',
     * 'kCloneVMs', 'kCloneView' or 'kMountVolumes'.
     */
    taskTypes?: Array<string>;

    /**
     * Filter by a list of Restore Task ids.
     */
    taskIds?: Array<number>;

    /**
     * Filter by a start time specified as a Unix epoch Timestamp
     * (in microseconds). All Restore Tasks (both completed and running)
     * on the Cohesity Cluster that started after the specified start time
     * but before the specified end time are returned.
     * If not set, the start time is creation time of the Cohesity Cluster.
     */
    startTimeUsecs?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the number of completed Restore Tasks to return in the response
     * for pagination purposes. Running Restore Tasks are always returned.
     * The newest completed Restore Tasks are returned.
     */
    pageCount?: number;

    /**
     * Specifies the environment like VMware, SQL, where the
     * Protection Source exists.
     * Supported environment types such as 'kView', 'kSQL', 'kVMware', etc.
     * NOTE: 'kPuppeteer' refers to Cohesity's Remote Adapter.
     * 'kVMware' indicates the VMware Protection Source environment.
     * 'kHyperV' indicates the HyperV Protection Source environment.
     * 'kSQL' indicates the SQL Protection Source environment.
     * 'kView' indicates the View Protection Source environment.
     * 'kPuppeteer' indicates the Cohesity's Remote Adapter.
     * 'kPhysical' indicates the physical Protection Source environment.
     * 'kPure' indicates the Pure Storage Protection Source environment.
     * 'Nimble' indicates the Nimble Storage Protection Source environment.
     * 'kHpe3Par' indicates the Hpe 3Par Storage Protection Source environment.
     * 'kAzure' indicates the Microsoft's Azure Protection Source environment.
     * 'kNetapp' indicates the Netapp Protection Source environment.
     * 'kAgent' indicates the Agent Protection Source environment.
     * 'kGenericNas' indicates the Generic Network Attached Storage Protection
     * Source environment.
     * 'kAcropolis' indicates the Acropolis Protection Source environment.
     * 'kPhsicalFiles' indicates the Physical Files Protection Source environment.
     * 'kIsilon' indicates the Dell EMC's Isilon Protection Source environment.
     * 'kGPFS' indicates IBM's GPFS Protection Source environment.
     * 'kKVM' indicates the KVM Protection Source environment.
     * 'kAWS' indicates the AWS Protection Source environment.
     * 'kExchange' indicates the Exchange Protection Source environment.
     * 'kHyperVVSS' indicates the HyperV VSS Protection Source
     * environment.
     * 'kOracle' indicates the Oracle Protection Source environment.
     * 'kGCP' indicates the Google Cloud Platform Protection Source environment.
     * 'kFlashBlade' indicates the Flash Blade Protection Source environment.
     * 'kAWSNative' indicates the AWS Native Protection Source environment.
     * 'kO365' indicates the Office 365 Protection Source environment.
     * 'kO365Outlook' indicates Office 365 outlook Protection Source environment.
     * 'kHyperFlex' indicates the Hyper Flex Protection Source environment.
     * 'kGCPNative' indicates the GCP Native Protection Source environment.
     * 'kAzureNative' indicates the Azure Native Protection Source environment.
     * 'kKubernetes' indicates a Kubernetes Protection Source environment.
     * 'kElastifile' indicates Elastifile Protection Source environment.
     * 'kAD' indicates Active Directory Protection Source environment.
     * 'kRDSSnapshotManager' indicates AWS RDS Protection Source environment.
     * 'kCassandra' indicates Cassandra Protection Source environment.
     * 'kMongoDB' indicates MongoDB Protection Source environment.
     * 'kCouchbase' indicates Couchbase Protection Source environment.
     * 'kHdfs' indicates Hdfs Protection Source environment.
     * 'kHive' indicates Hive Protection Source environment.
     * 'kHBase' indicates HBase Protection Source environment.
     */
    environment?: 'kVMware' | 'kHyperV' | 'kSQL' | 'kView' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kAzure' | 'kNetapp' | 'kAgent' | 'kGenericNas' | 'kAcropolis' | 'kPhysicalFiles' | 'kIsilon' | 'kGPFS' | 'kKVM' | 'kAWS' | 'kExchange' | 'kHyperVVSS' | 'kOracle' | 'kGCP' | 'kFlashBlade' | 'kAWSNative' | 'kO365' | 'kO365Outlook' | 'kHyperFlex' | 'kGCPNative' | 'kAzureNative' | 'kKubernetes' | 'kElastifile' | 'kAD' | 'kRDSSnapshotManager' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kSAPHANA';

    /**
     * Filter by an end time specified as a Unix epoch Timestamp
     * (in microseconds). All Restore Tasks (both completed and running)
     * on the Cohesity Cluster that started after the specified start time
     * but before the specified end time are returned.
     * If not set, the end time is the current time.
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelRestoreTask
   */
  export interface CancelRestoreTaskParams {

    /**
     * Specifies a unique id for the Restore Task.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRestoreTaskById
   */
  export interface GetRestoreTaskByIdParams {

    /**
     * Specifies a unique id for the Restore Task.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVirtualDiskInformation
   */
  export interface GetVirtualDiskInformationParams {

    /**
     * Specifies the start time of the job run as a Unix epoch Timestamp in
     * microseconds.
     */
    startTimeUsecs: number;

    /**
     * Specifies the Id of the Protection Source object.
     */
    sourceId: number;

    /**
     * Specifies the id of the Job Run that captured the snapshot.
     */
    jobRunId: number;

    /**
     * Specifies the id of the Job that captured the snapshot.
     */
    jobId: number;

    /**
     * Specifies the incarnation id of the Cohesity Cluster where the Job was
     * created.
     */
    clusterIncarnationId: number;

    /**
     * Specifies the Cohesity Cluster id where the Job was created.
     */
    clusterId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * PointInTimeUsecs is the time to get volume virtual disk info from
     * previously available full/incremental snapshot.
     */
    pointInTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVmDirectoryList
   */
  export interface GetVmDirectoryListParams {

    /**
     * JobUidObjectId is the globally unique id of the job that the object
     * originally belonged to. If this object originally belonged to a job from a
     * remote cluster, this field will contain the JobId of the remote job, else
     * it will contain the JobId of the local job.
     */
    jobUidObjectId: number;

    /**
     * JobStartTimeUsecs is the start time in usecs of the job run that backed up
     * the entity.
     */
    jobStartTimeUsecs: number;

    /**
     * JobInstanceId is the Id of the job run that backed up the entity.
     */
    jobInstanceId: number;

    /**
     * JobId is the id of the local job that took the snapshot, which may or
     * may not match the JobUidObjectId below depending on whether the object
     * originally belonged to this local job or to a different remote job.
     */
    jobId: number;

    /**
     * EntityId is the Id of the VM.
     */
    entityId: number;

    /**
     * DirPath is the full path of the directory whose contents need to be listed.
     */
    dirPath: string;

    /**
     * VolumeName is the name of the volume that needs to be browsed. This should
     * match the name returned in VolumeInfo.
     */
    volumeName?: string;

    /**
     * VolumeInfoCookie is the cookie to be passed in calls to reading a VM dir
     * for this volume.
     */
    volumeInfoCookie?: number;

    /**
     * Name of the View if a View is being browsed.
     */
    viewName?: string;

    /**
     * Id of the View Box if a View is being browsed.
     */
    viewBoxId?: number;

    /**
     * StatFileEntries specifies whether file stat data is returned.
     */
    statFileEntries?: boolean;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * MaxEntries is the maximum number of entries to return in this call. If
     * there are more entries, server will return a cookie in the response that
     * can be used to continue enumeration from the last call.
     */
    maxEntries?: number;

    /**
     * Cookie is used for paginating results. If ReadDirResult returned partial
     * results, it will also return a cookie that can be used to resume the
     * listing. The value returned in ReadDirResult should be passed in the next
     * call. The first call should not have this value set. Note that this value
     * is only a suggestion and server is free to do a short read (return fewer
     * entries along with a cookie).
     */
    cookie?: string;

    /**
     * Specifies whether to use indexed data in Librarian for browse.
     */
    browseIndexedData?: boolean;

    /**
     * AttemptNum is the attempt number of the run that successfully created the
     * snapshot.
     */
    attemptNum?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVmVolumesInformation
   */
  export interface GetVmVolumesInformationParams {

    /**
     * Specifies the time when the Job Run starts capturing a snapshot.
     * Specified as a Unix epoch Timestamp (in microseconds).
     */
    startedTimeUsecs: number;

    /**
     * Specifies the id of the VM object to search for volumes.
     */
    sourceId: number;

    /**
     * Specifies the id for the Protection Job that originally captured
     * the snapshots of the original object. If the object was backed up
     * on a Primary Cluster replicated to this Cohesity Cluster,
     * and a new Inactive Job is created,
     * this field still contains the id of the original Job and NOT the
     * id of the new Inactive Job.
     * This field is used in combination with the clusterId and
     * clusterIncarnationId to uniquely identify a Job.
     */
    originalJobId: number;

    /**
     * Specifies the id of the Job Run that captured the snapshot.
     */
    jobRunId: number;

    /**
     * Specifies the Job id for the Protection Job that is currently
     * associated with the object.
     * If the object was backed up on current Cohesity Cluster, this field
     * contains the id for the Job that captured this backup object.
     * If the object was backed up on a Primary Cluster and
     * replicated to this Cohesity Cluster, a new Inactive Job is created,
     * the object is now associated with new Inactive Job, and this field
     * contains the id of the new Inactive Job.
     */
    jobId: number;

    /**
     * Specifies the incarnation id of the Cohesity Cluster where the Job
     * was created.
     * An incarnation id is generated when a Cohesity Cluster is initially
     * created.
     */
    clusterIncarnationId: number;

    /**
     * Specifies the Cohesity Cluster id where the Job was created.
     */
    clusterId: number;

    /**
     * Specifies to return only supported volumes information. Unsupported
     * volumes are not returned if this flag is set to true.
     * Default is false.
     */
    supportedVolumesOnly?: boolean;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies whether to compute volume information if it is not found.
     * If `ComputeVolumeInfo` is false and volume information is not found it
     * skips computation of volume information and returns KNotFound.
     */
    computeVolumeInfo?: boolean;

    /**
     * Specifies the number of the attempts made by the Job Run
     * to capture a snapshot of the object. For example, if an snapshot
     * is successfully captured after three attempts, this field equals 3.
     */
    attemptNumber?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RestoreTasksService }
