import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ArchivalCopy } from '@cohesity/api/v2';
import { EventTrackingService, SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isRpaasScope } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { keyBy, reduce, remove, uniq } from 'lodash-es';
import { forkJoin, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { SecurityDialogService } from '../../dialogs';
import {
  AnomalyAlert,
  AnomalyChartSeries,
  ChartPoint,
  RecoverySource,
  RestoreTaskInfo,
} from '../../security-shared.models';
import { RecoveryTaskService, SecurityService } from '../../services';

/**
 * This component handles recovery flow.
 */
@Component({
  selector: 'dg-ar-anomaly-recovery',
  templateUrl: './anomaly-recovery.component.html',
  styleUrls: ['./anomaly-recovery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnomalyRecoveryComponent extends AutoDestroyable implements OnInit {
  /**
   * List of anomalies to be recovered
   */
  @Input() anomalies: AnomalyAlert[] = [];

  /** Indicates whether to hide the recovery button or not */
  @Input() hideRecoveryBtn = false;

  /** Indicates whether to show anomalies to be recovered in vertical view or not */
  @Input() verticalView = false;

  /**
   * Cloud vault region selector for remote recovery source.
   */
  readonly vaultControl = new UntypedFormControl('');

  /**
   * Custom snapshot selection flag
   */
  customSelectionEnabled = false;

  /**
   * radio button options
   */
  recoveryOptions: string[] = ['custom', 'latestCleanSnapshot'];

  /**
   * temporary place to save selected snapshot.
   */
  tempSelectedPoint: ChartPoint;

  /**
   * To show / hide spinners
   */
  isLoading = false;

  /**
   * Indicates whether we are submitting recovery task or not.
   */
  isSubmitting = false;

  /**
   * Optional previous page function handler
   */
  @Output() back = new EventEmitter<void>();

  /**
   * Optional recoverability state change listener. Emits true if selected recovery points are recoverable for
   * respective anomalies
   */
  @Output() recoverabilityStateChanged = new EventEmitter<boolean>();

  /**
   * Snapshot tagging configuration
   */
  snapshotTaggingConfig = {
    tagManagement: this.irisCtx.irisContext.privs.SNAPSHOT_TAGS_MANAGE,
    restoreManagement: this.irisCtx.irisContext.privs.SNAPSHOT_TAGS_RESTORE,
  };

  /**
   * Holds whether user wants to chose a cloud snapshot or local snapshot.
   */
  restoreFrom = RecoverySource.local;

  /**
   * Entities that are supported for recovery
   * Update this to enable support for new entities.
   */
  readonly supportedEntities = new Set([
    Environment.kVMware,
    Environment.kPhysical,
    Environment.kView,
    Environment.kHyperV,
    Environment.kNetapp,
    Environment.kPure,
    Environment.kIsilon,
    ...(flagEnabled(this.irisCtx.irisContext, 'ngRecoveryForSupportedAdapter')
      ? [
        Environment.kSQL,
        Environment.kAzure,
        Environment.kAzureNative,
        Environment.kGenericNas,
        Environment.kAcropolis,
        Environment.kKVM,
        Environment.kAWS,
        Environment.kAWSNative,
        Environment.kExchange,
        Environment.kFlashBlade,
      ]
      : []),
  ]);

  /**
   * Whether the current context is in RPaaS or not.
   */
  get isRpaasScope(): boolean {
    return isRpaasScope(this.irisCtx.irisContext);
  }

  /**
   * flag to check if clean room recovery is enabled
   */
  get isCleanRoomRecoveryPhase1Enabled() {
    return this.securityService.isCleanRoomRecoveryPhase1Enabled;
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private eventTrackingService: EventTrackingService,
    private irisCtx: IrisContextService,
    private recoveryTaskService: RecoveryTaskService,
    private securityDialogService: SecurityDialogService,
    private securityService: SecurityService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    // By default we select to restore from rpaas vault in case of fortknox.
    // else, we default to local backups.
    this.restoreFrom = this.isRpaasScope ? RecoverySource.vault : RecoverySource.local;

    const anomalies$ = this.anomalies.map(anomaly => this.securityService.getAnomalyAlertStats(anomaly));

    this.isLoading = true;
    forkJoin(anomalies$)
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(anomalies => {
        anomalies.forEach((anomalySeries, idx) => {
          const anomaly = this.anomalies[idx];
          anomaly.seriesData = anomalySeries;
          anomaly.properties = {
            ...anomaly.properties,
            ...reduce(
              anomaly.propertyList || [],
              (result, item) => {
                result[item.key] = item.value;
                return result;
              },
              {}
            ),
          };
          this.updateSelectedSnapshotPoint(anomaly, anomaly.seriesData.latestCleanDataPoint);
          anomaly.isExpanded = false;
        });
      }, this.ajaxHandlerService.handler);
  }

  /**
   * Clean snapshot selection on line chart
   *
   * @param   point   Clean snapshot
   */
  snapshotSelected(point: ChartPoint, anomaly: AnomalyAlert) {
    this.tempSelectedPoint = anomaly.selectedSnapshotPoint;
    this.updateSelectedSnapshotPoint(anomaly, point);
    this.vaultControl.setValue('');
  }

  /**
   * revert current selection to last selected clean snapshot.
   */
  revertToLastCleanSnapshot(anomaly: AnomalyAlert) {
    anomaly.isExpanded = false;
    this.updateSelectedSnapshotPoint(anomaly, anomaly.seriesData.latestCleanDataPoint);
  }

  /**
   * Updates the given anomaly with the provided point as selected recovery point, and emits recoverability state
   * changed event.
   *
   * @param anomaly anomaly for which the selected recovery point need to be updated
   * @param point selected recovery point
   */
  updateSelectedSnapshotPoint(anomaly: AnomalyAlert, point: ChartPoint) {
    anomaly.selectedSnapshotPoint = point;

    // compute the combined recoverability status across all the anomalies and generate the consolidated
    // state
    const isNotRecoverable = this.anomalies.some((entry) => {
      const startTimeUsecs = entry.selectedSnapshotPoint.x * 1000;
      const selectedRecoveryPoint = anomaly.seriesData.timestampDataPoints[startTimeUsecs][0];

      return selectedRecoveryPoint.isRecoverable === false;
    });
    this.recoverabilityStateChanged.emit(!isNotRecoverable);
  }

  /**
   * Returns label for currently selected recovery point
   *
   * @param   anomaly  The selected anomaly
   * @returns label - latest clean/clean/anomalous etc
   */
  getSelectedSnapshotLabel(anomaly: AnomalyAlert): string {
    switch (true) {
      case anomaly.selectedSnapshotPoint?.x * 1000 === +anomaly.properties.jobStartTimeUsecs:
        return this.translateService.instant('latestCleanSnapshot');
      case anomaly.selectedSnapshotPoint?.x * 1000 === anomaly.latestTimestampUsecs:
        return this.translateService.instant('anomalousSnapshot');
      default:
        return this.translateService.instant('cleanSnapshot');
    }
  }

  /**
   * Returns icon for anomaly restore point
   *
   * @param anomaly Anomaly point which is being restored
   * @returns Icon name for specified anomaly
   */
  getIconName(anomaly: AnomalyAlert): string {
    if (anomaly?.incidence?.antiRansomwareDetails?.isBaas) {
      return 'helix:cloud';
    }

    return this.restoreFrom === 'local' ? 'helix:cluster' : 'helix:vault';
  }

  /**
   * Returns the vault name for the selected snapshot
   *
   * @param   anomaly  The selected anomaly
   * @returns The vault name
   */
  getVaultName(anomaly: AnomalyAlert): string {
    if (this.restoreFrom === RecoverySource.local) {
      if (anomaly.incidence?.antiRansomwareDetails?.isBaas) {
        return this.translateService.instant(anomaly.incidence.antiRansomwareDetails.baasRegionId);
      } else {
        return this.translateService.instant('localBackup');
      }
    }

    if (!anomaly.seriesData) {
      return null;
    }

    const anomalies = anomaly.seriesData.timestampDataPoints[anomaly.selectedSnapshotPoint.x * 1000];
    const archivalSnapshot = anomalies[0]?.snapshotInfo?.archivalSnapshot;

    if (!anomalies?.length || !archivalSnapshot?.length) {
      return this.translateService.instant('localBackup');
    }

    if (this.vaultControl.value) {
      const snapshotByVaultId = archivalSnapshot.find(snap => snap.globalVaultId === this.vaultControl.value);

      if (snapshotByVaultId) {
        return snapshotByVaultId.archivalTarget.name;
      }
    } else if (archivalSnapshot[0]) {
      this.vaultControl.setValue(archivalSnapshot[0].globalVaultId);
      return archivalSnapshot[0].archivalTarget?.name;
    }
  }

  /**
   * Returns region IDs from selected anomaly point.
   */
  getVaultRegions(anomaly: AnomalyAlert) {
    const anomalyPoint = anomaly?.seriesData?.timestampDataPoints?.[anomaly?.selectedSnapshotPoint?.x * 1000];
    return uniq(anomalyPoint?.[0]?.snapshotInfo?.archivalSnapshot?.map(snapshot => snapshot.rpaasRegion) || []);
  }

  /**
   * Returns vault names from selected anomaly point.
   *
   * @param anomaly Alert anomaly point
   * @returns List of vault names from anomaly point specified.
   */
  getArchivalTargets(anomaly: AnomalyAlert): ArchivalCopy[] {
    const anomalyPoint = anomaly?.seriesData?.timestampDataPoints?.[anomaly?.selectedSnapshotPoint?.x * 1000];
    const archivalTargetsByVaultId = keyBy(
      anomalyPoint?.[0]?.snapshotInfo?.archivalSnapshot?.filter(snapshot => snapshot?.isRPaas), 'globalVaultId');

    return Object.values(archivalTargetsByVaultId);
  }

  /**
   * On expansion, Get anomaly stats and create chart
   *
   * @param   anomaly   selected anomaly
   */
  expansionPanelOpened(anomaly: AnomalyAlert) {
    anomaly.isExpanded = this.customSelectionEnabled;

    if (anomaly.isExpanded) {
      this.securityService.getAnomalyAlertStats(anomaly, this.restoreFrom).subscribe(
        (data: AnomalyChartSeries) => {
          const index = this.anomalies.findIndex(ano => ano.id === anomaly.id);
          this.anomalies[index].seriesData = data;
          this.anomalies[index].selectedSnapshotPoint = data.latestCleanDataPoint;
        },
        error => this.ajaxHandlerService.handler(error)
      );
    }
  }

  /**
   * Handles radio button selection
   *
   * @param event   material radio select event
   */
  onRadioChange(event: MatRadioChange) {
    switch (event.value) {
      case 'custom':
        this.customSelectionEnabled = true;
        this.snackBarService.open(this.translateService.instant('anomalyRecovery.customPointSelection'));
        break;

      case 'latestCleanSnapshot':
        this.customSelectionEnabled = false;
        this.anomalies.forEach((anomaly: AnomalyAlert) => {
          anomaly.isExpanded = false;
          if (anomaly.seriesData) {
            this.updateSelectedSnapshotPoint(anomaly, anomaly.seriesData.latestCleanDataPoint);
          }
        });
        this.snackBarService.open(this.translateService.instant('anomalyRecovery.latestPointSelection'));
        break;
    }
  }

  /**
   * Opens recovery dialog to show default settings with which recovery will happen
   */
  openRecoveryDialog() {
    this.eventTrackingService.send({ key: 'anomalous_objects_instant_recovery_dialog_opened' });
    this.securityDialogService
      .launchRecoveryConfirmationDialog()
      .pipe(this.untilDestroy())
      .subscribe(() => this.recoveryClicked());
  }

  /**
   * Creates recovery task object according to anomalies in cart.
   */
  recoveryClicked() {
    const { restoreManagement } = this.snapshotTaggingConfig;
    const recoveryAllowed = restoreManagement;
    const requests: RestoreTaskInfo[] = [];
    this.anomalies.forEach((anomaly: AnomalyAlert) => {
      const startTimeUsecs = anomaly.selectedSnapshotPoint.x * 1000;
      const { properties } = anomaly;
      const isBaas = anomaly.incidence?.antiRansomwareDetails?.isBaas;
      const selectedRecoveryPoint = anomaly.seriesData.timestampDataPoints[startTimeUsecs][0];
      const selectedSnapshotInfo = selectedRecoveryPoint.snapshotInfo;

      // If recovery is not allowed and snapshot is tagged the return with error
      if ((this.isCleanRoomRecoveryPhase1Enabled && selectedRecoveryPoint.isRecoverable === false) ||
        (!recoveryAllowed && anomaly.seriesData?.timestampDataPoints[startTimeUsecs][0]?.tags?.length)) {
        this.snackBarService.open(
          this.translateService.instant('anomalyRecovery.taggedSnapshotError', {
            objectName: properties.object,
          }),
          'error'
        );
        return;
      }

      // If entity environment type is not supported for recovery,
      // return with warn.
      if (!this.supportedEntities.has(Environment[properties.environment])) {
        this.snackBarService.open(this.translateService.instant('anomalyRecovery.disallowedEntities'), 'warn');
        return;
      }

      const { task, operation } = this.recoveryTaskService.getRecoveryTask(
        Environment[properties.environment],
        isBaas ? RecoverySource.vault : this.restoreFrom,
        properties,
        selectedSnapshotInfo,
        this.vaultControl.value
      );

      const req: RestoreTaskInfo = {
        task,
        operation,
        environment: properties.environment as Environment,
      };

      if (isBaas) {
        req.regionId = anomaly.incidence?.antiRansomwareDetails.baasRegionId;
      } else if (anomaly.clusterId) {
        req.clusterId = anomaly.clusterId;
      }

      requests.push(req);
    });

    this.startRecoveryTasks(requests);
  }

  /**
   * Opens default recovery settings dialog
   * List all supported environments with recovery settings
   */
  openDefaultRecoverySettings() {
    const environments = this.anomalies.map(anomaly => anomaly?.properties?.environment as Environment).filter(Boolean);

    this.securityDialogService.launchRecoverySettingsDialog(environments)
      .afterClosed().pipe(this.untilDestroy()).subscribe();
  }

  /**
   * Starts the recoveries for all the selected anomalies.
   *
   * @param  requests  The list of recovery to be done.
   */
  startRecoveryTasks(requests: RestoreTaskInfo[]) {
    if (!requests?.length) {
      return;
    }
    let counter = requests.length;

    const recoveriesReq$ = requests.map(req =>
      this.recoveryTaskService.createRecovery(req).pipe(
        switchMap(() => this.resolveAnomaly(req.clusterId)),
        tap(() => counter--)
      )
    );

    this.isSubmitting = true;
    forkJoin(recoveriesReq$)
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isSubmitting = false;
          if (counter === 0) {
            this.back?.emit();
          }
        })
      )
      .subscribe(
        () => this.snackBarService.open(this.translateService.instant('anomalyRecovery.success')),
        err => this.ajaxHandlerService.errorMessage(err)
      );
  }

  /**
   * Resolve the anomaly after the recovery task has been submitted.
   *
   * @param   clusterId  The cluster id to resolve the anomaly
   * @returns An observable with anomaly status information.
   */
  private resolveAnomaly(clusterId) {
    const anomalyToRemove = remove(this.anomalies, anomaly => anomaly.clusterId === clusterId);

    if (!anomalyToRemove.length) {
      return of(false);
    }

    // In case of FortKnox we will not be resolving alerts.
    const canResolveAlerts = !this.isRpaasScope;
    return canResolveAlerts ? this.securityService.updateAnomalyState(anomalyToRemove[0], 'Resolved') : of(true);
  }

  /**
   * Choose which target to recover from.
   *
   * @param   event   The slide change event.
   */
  chooseSnapshot(event: MatSlideToggleChange, anomaly: AnomalyAlert) {
    this.restoreFrom = event.checked ? RecoverySource.vault : RecoverySource.local;

    // Reset the series so that it can be redrawn.
    anomaly.seriesData = null;
    this.expansionPanelOpened(anomaly);
  }
}
