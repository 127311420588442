import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeFilter, DataTreeFilterUtils, DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope, isDmsUser } from '@cohesity/iris-core';
import {
  FilterOption,
  SourceSelection,
  TagCategory
} from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { AWSEntities, AwsLeafType, AwsLeafTypes, AwsTagType, AwsTagTypes, CloudJobType, SourceTypes } from 'src/app/shared/constants';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { AwsEc2OptionsComponent } from './aws-ec2-options/aws-ec2-options.component';
import { AwsSourceDataNode } from './aws-source-data-node';
import { AwsViewFilters } from './aws-view-filters';

/**
 * Tree service for aws
 */
@Injectable({
  providedIn: 'root',
})
export class AwsSourceTreeService extends BaseProtectionSourceService<AwsSourceDataNode> {
  /**
   * A reference to vm view filters, to show a physical, folder, flat, and tagged list view.
   */
  awsViewFilters: AwsViewFilters<AwsSourceDataNode>;

  /**
   * Filter options for different AWS object types: EC2,RDS,Aurora and S3.
   */
  awsObjectOptions: FilterOption[];

  /**
   * Cloud Job Type.
   */
  private jobType$ = new BehaviorSubject<CloudJobType | ''>('');

  /**
   * Set the value for jobType Observable
   */
  set jobType(jobType: CloudJobType) {
    this.jobType$.next(jobType);

    if (jobType) {
      this.awsViewFilters.updateFlatFilterLabel(jobType);
      if ([CloudJobType.kAgent].includes(jobType)) {
        this.awsViewFilters.removeTagFilter();
      } else {
        this.awsViewFilters.addTagFilter();
      }
    }
  }

  /**
   * Returns the non-synchronous state of the treeService job type
   */
  get jobType(): CloudJobType {
    return this.jobType$.value || null;
  }

  constructor(
    readonly translate: TranslateService,
    private irisContext: IrisContextService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super();
    this.awsViewFilters = new AwsViewFilters(
      this.filters,
      this.treeControl,
      this.treeTransformer,

      // This configures the simple tag view to only be available on DMS. For on-prem, we still need the full tag view
      // to be able to protecting multiple tags at once.
      isDmsUser(this.irisContext.irisContext)
    );

    // Object type filter for AWS.
    this.awsObjectOptions = [
      {
        id: 'ec2',
        label: 'EC2',
        filter: this.filterByAwsObjectType({ isEc2: true }),
      },
      {
        id: 'rds',
        label: 'RDS',
        filter: this.filterByAwsObjectType({ isRds: true }),
      },
      {
        id: 'aurora',
        label: 'Aurora',
        filter: this.filterByAwsObjectType({ isAurora: true }),
      },
      {
        id: 's3',
        label: 'S3 Bucket',
        filter: this.filterByAwsObjectType({ isS3: true }),
      },
    ];

    if (flagEnabled(this.irisContext.irisContext, 's3OnPremProtection') &&
      !isDmsScope(this.irisContext.irisContext)) {
      this.filters.addFilterGroup({
        id: 'awsObjectTypeFilter',
        label: 'objectType',
        options: this.awsObjectOptions,
      });
    }
  }

  /**
   * Parse a list of tree nodes to determine available tags that can be filtered by.
   *
   * @param   allNodes   The complete list of nodes.
   * @param   selection  The current selection.
   * @returns A list of tag categories that can be filtered by.
   */
  getTagCategories(
    allNodes: AwsSourceDataNode[],
    selection: DataTreeSelection<AwsSourceDataNode>
  ): TagCategory[] {
    const tagNodes = allNodes.filter(node => {
      switch (this.jobType$.value) {
        case CloudJobType.kAuroraSnapshotManager:
          return node.envSource.type === AwsTagType.kAuroraTag;
        case CloudJobType.kRDSSnapshotManager:
          return node.envSource.type === AwsTagType.kRDSTag;
        case CloudJobType.kAwsS3:
          return node.envSource.type === AwsTagType.kS3Tag;
        default:
          return node.envSource.type === AwsTagType.kS3Tag;
      }
    });
    const tagMap: any = {};
    const tagGroups: any[] = [];
    const tags: TagCategory[] = [];

    // All available tags, grouped by category
    tagNodes.forEach(tagNode => {
      tagMap[tagNode.protectionSource.id] = tagNode.protectionSource.name;
      tagGroups.push([{
        id: tagNode.protectionSource.id,
        name: tagNode.protectionSource.name,
      }]);
    });

    tags.push({
      name: this.translate.instant('tags'),
      tagGroups: tagGroups,
    });

    // Add a category for currently excluded tags
    const excludedTags = (selection.excluded || []).filter(node => AwsTagTypes.includes(node.type));
    if (excludedTags.length) {
      tags.unshift({
        name: this.translate.instant('excluded'),
        tagGroups: excludedTags.map(tagNode =>
          tagNode.ids.map(tagId => ({
            id: Number(tagId),
            name: tagMap[tagId],
          }))
        ),
      });
    }

    // Add a category for currently auto protected tags
    const autoProtectedTags = (selection.autoSelected || []).filter(node => AwsTagTypes.includes(node.type));
    if (autoProtectedTags.length) {
      tags.unshift({
        name: this.translate.instant('autoProtected'),
        tagGroups: autoProtectedTags.map(tagNode =>
          tagNode.ids.reduce((tagsUnderCategory, tagId) => {
            if (tagMap[tagId]) {
              tagsUnderCategory.push({
                id: Number(tagId),
                name: tagMap[tagId],
              });
            }
            return tagsUnderCategory;
          }, [])
        ),
      });
    }

    return tags;
  }

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: AwsSourceDataNode): boolean {
    return treeNode.isLeaf;
  }

  /**
   * Filter callback function to filters the list of AWS object types based on
   * the selected object type.
   *
   * @param filterConfig Selected value for AWS object type.
   * @returns A DataTreeFilter that filters the node tree.
   */
  filterByAwsObjectType: (filterConfig: any) => DataTreeFilter<any> = filterConfig => (nodes: AwsSourceDataNode[]) => {
    nodes.forEach(node => {
      if (filterConfig.isS3) {
        if(node.envSource.type === AwsLeafType.kS3 ||
          [AWSEntities.kIAMUser, SourceTypes.KRegion].includes(node.type as AWSEntities|SourceTypes)) {
          node.workloadType = AwsLeafType.kS3;
        }
      } else {
          node.workloadType = null;
      }
    });

    return DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      node =>
        ((filterConfig.isEc2 && node.envSource.type === 'kEC2Instance') ||
          (filterConfig.isRds && node.envSource.type === 'kRDSInstance') ||
          (filterConfig.isAurora && node.envSource.type === 'kAuroraCluster') ||
          (filterConfig.isS3 && node.envSource.type === 'kS3Bucket')),
      false,
      true
    );
  };

  /**
   * Override the unwrap root contains to remove unnecessary nodes for source tree.
   *
   * @param  tree   The list of nodes from the raw api response.
   * @returns   The unwrapped root container, with system databases grouped together.
   *
   */
  unwrapRootContainers(tree?: ProtectionSourceNode[]): ProtectionSourceNode[] {
    const filteredTree = [];

    tree.forEach(node => {
      const nodeType = node.protectionSource.awsProtectionSource.type;
      const leafTypes = [...AwsLeafTypes, ...AwsTagTypes];
      const isLeaf = leafTypes.includes(nodeType);

      // '#~#' is a special identifier used by backend to separate key from
      // value. Replace it with a user friendly string.
      if (AwsTagTypes.includes(node.protectionSource.awsProtectionSource.type)) {
        node.protectionSource.name = node.protectionSource.name.replace('#~#', ': ');
      }

      if (!['kIAMUser', 'kRegion', 'kAvailabilityZone', 'kService', ...AwsTagTypes, ...AwsLeafTypes]
        .includes(node.protectionSource.awsProtectionSource.type)) {
        return;
      }

      if (node.nodes && node.nodes.length) {
        node.nodes = this.unwrapRootContainers(node.nodes);
      }

      // accept a node if it is a valid leaf, or has valid children
      if (isLeaf || (node.nodes && node.nodes.length)) {

        filteredTree.push(node);
      }
    });

    return super.unwrapRootContainers(filteredTree);
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: AwsSourceDataNode): boolean {
    const allowedTypes = treeNode.workloadType === 'kS3Bucket' ? ['kIAMUser'] : ['kIAMUser', 'kRegion'];
    return allowedTypes.includes(treeNode.type);
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An AwsSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): AwsSourceDataNode {
    const awsSourceDataNode =  new AwsSourceDataNode(
      node,
      level,
    );

    if (this.uiRouterGlobals?.params?.workload) {
      awsSourceDataNode.workloadType = this.uiRouterGlobals.params.workload;
    }
    this.jobType$.subscribe(value => awsSourceDataNode.setJobType(value));

    return awsSourceDataNode;
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<AwsSourceDataNode>): SourceSelection {
    // Sources include explicitly selected leaf node, and auto selected items
    const autoSelected = selection.autoSelected.filter(item => !item.isTag);
    const sources = selection.selected.filter(item => item.isLeaf).concat(autoSelected);

    return {
      // non-tag excluded items
      excludeSourceIds: selection.excluded.filter(item => !item.isTag).map(item => Number(item.id)),

      // excluded tags. The ids for these need to be converted to number since the ids property splits
      // a string and leaves them as strings. This comes out to an array of arrays.
      excludeVmTagIds: selection.excluded
        .filter(item => item.isTag)
        .map(item => item.ids.map(id => Number(id))),

      // non-tag source ids
      sourceIds: sources.map(item => Number(item.id)),

      // Special source params
      sourceSpecialParameters: Object.values(selection.options || {}).filter(Boolean),

      // tag source ids, an array of arrays.
      vmTagIds: selection.autoSelected.filter(item => item.isTag).map(item => item.ids.map(id => Number(id))),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: AwsSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<AwsSourceDataNode> {
    const treeSelection = super.transformToDataTreeSelection(allNodes, sourceSelection);

    if (sourceSelection.vmTagIds) {
      sourceSelection.vmTagIds.forEach(tagIds =>
        treeSelection.autoSelected.push(this.awsViewFilters.createTagNode(tagIds, allNodes))
      );
    }

    if (sourceSelection.excludeVmTagIds) {
      sourceSelection.excludeVmTagIds.forEach(tagIds =>
        treeSelection.excluded.push(this.awsViewFilters.createTagNode(tagIds, allNodes))
      );
    }
    return treeSelection;
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: AwsSourceDataNode): ComponentType<any> {
    if (
      flagEnabled(this.irisContext.irisContext, 'awsEnableEbsExclusion') &&
        node.type === 'kEC2Instance' &&
          CloudJobType.kAgent !== (this.jobType$.value as CloudJobType)
    ) {
      return AwsEc2OptionsComponent;
    }
    return null;
  }
}
