import { range } from 'lodash-es';
import { isBoolean } from 'lodash-es';
import { orderBy } from 'lodash-es';
import { mapKeys } from 'lodash-es';
import { inRange } from 'lodash-es';
import { last } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { first } from 'lodash-es';
import { find } from 'lodash-es';
import { map } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Snapshot picker

;(function(angular, undefined) {
  'use strict';

  var componentName = 'cSnapshotPicker';

  /**
   * @ngdoc      component
   * @name       C.snapshotSelection:cSnapshotsSelector
   * @requires   ngModel
   *
   * @param   {object}   id                      ID for this instance. Also used
   *                                             as a seed internally for IDs of
   *                                             internal elements.
   * @param   {object}   entityId                The Entity ID to get info about.
   * @param   {boolean}  [hideHeader]            Hide header with date title.
   * @param   {string}   entityEnvironment       This Entity's Environment, ie.
   *                                             kVMware, kSQL, etc.
   * @param   {object}   [jobIds]                One or more Job ids protecting
   *                                             this Entity.
   * @param   {boolean}  [embedRestore]          True to show restore button.
   *                                             This is a link to the
   *                                             abbreviated restore workflow.
   * @param   {boolean}  [compactView]           Show the list actions
   *                                             (pagination and recover button)
   *                                             in reversed order. By default
   *                                             recover button appears on the
   *                                             right side of pagination. If
   *                                             this is set to true, they
   *                                             appear in the opposite order.
   *                                             This helps when the component
   *                                             is used within narrow width
   *                                             container as without this, the
   *                                             recover button dropdown will be
   *                                             cut as the submenu will
   *                                             overflow.
   *                                             This also pushes the recover
   *                                             button in the pit-range view
   *                                             below the date and locations
   *                                             row to conserve width.
   * @param   {number}   [selectedPointInTime]   The starting selected PIT in
   *                                             whole seconds.
   * @param   {object}   [selectedSnapshot]      The starting selected snapshot.
   * @param   {object}   [selectedReplica]       The starting selected Replica
   *                                             (archive target).
   * @param   {boolean}  [showLogs=true]         Hide logs with `false` (List
   *                                             view only).
   * @param   {array}    [snapshots]             Array of pre populated
   *                                             snapshots.
   * @param   {object}   [snapshotsDate=today]   The calendar date to show
   *                                             snapshots and PIT ranges for.
   * @param   {string}   [timezone=local]        Timezone in which the user
   *                                             wants to see the snapshot
   *                                             times.
   * @param   {array}    [jobUids]               The jobUids of recovered VM/job.
   *
   * @description
   *   This component displays a range slider with Magneto snapshots and PIT
   *   range blobs plotted along it. It is used for selecting Snapshots and
   *   ArchiveTargets for a particular protected Entity. Primarily for use in
   *   Restore workflows, but is not exclusive to that.
   *
   *   The ngModel binding is the primary output mechanism of this
   *   component.
   *
   *   Model = {
   *     // PIT date in milliseconds
   *     pointInTime: 0,
   *
   *     // The selected Magneto snapshot
   *     snapshot: vmDocument.version[],
   *
   *     // The selected replica/archivTarget for the given snapshot
   *     replica: vmDocument.version[].replicaInfo.replicaVec[]
   *   }
   *
   * @example
   *   <c-snapshot-picker id="snapshot-pit-picker"
   *     ng-model="$ctrl.selections"
   *     entity-id="::row.vmDocument.objectId.entity.id"
   *     entity-environment="kSQL"
   *     job-ids="::row.vmDocument.objectId.jobId"
   *     snapshot-date="jsDateObject"></c-snapshot-picker>
   */
  var componentConfig = {
    bindings: {
      id: '@',
      compactView: '<?',
      embedRestore: '<?',
      entityEnvironment: '@?',
      entityId: '<',
      hideHeader: '<?',
      jobIds: '<?',
      selectedPointInTime: '<?',
      selectedReplica: '<?',
      selectedSnapshot: '<?',
      showLogs: '<?',
      snapshots: '<?',
      snapshotsDate: '<?',
      timezone: '<?',
      viewFormat: '<?',
      jobUids: '<?',
    },
    controller: 'SnapshotPickerController',
    require: { ngModelCtrl: 'ngModel' },
    templateUrl: 'app/global/c-snapshots-selector/c-snapshot-picker.html',
  };

  angular
    .module('C.snapshotSelection')
    .controller('SnapshotPickerController', cSnapshotPickerController)
    .component(componentName, componentConfig);

  function cSnapshotPickerController(
    _, $log, $filter, $scope, $state, moment, $interpolate, $timeout,
    SnapshotSelectionService, FEATURE_FLAGS, UI_PIT_SUPPORTED_ENVIRONMENTS,
    ENV_GROUPS, DateTimeService) {

    var $ctrl = this;
    var yodaDocumentKey = 'vmDocument';

    assign($ctrl, {
      // Controller lifecycle hooks
      $onChanges: $onChanges,
      $onInit: $onInit,

      // Controller values
      FEATURE_FLAGS: FEATURE_FLAGS,
      compactView: Boolean($ctrl.compactView),
      entityEnvironment: $ctrl.entityEnvironment || 'kVMware',
      PITRanges: [],
      showLogs: !isBoolean($ctrl.showLogs) || $ctrl.showLogs,
      sliderOptions: {},
      timepickerTime: undefined,

      // User has a choice to pass the timezone.
      // If not then we default it to the current browser
      // timezone.
      timezone: $ctrl.timezone || moment.tz.guess(),

      // Controller API
      canRestoreFromArchiveTarget: canRestoreFromArchiveTarget,
      changeSnapshot: changeSnapshot,
      changeTimepickerTime: changeTimepickerTime,
      getSliderDate: getSliderDate,
      getSnapshotTargetName: getSnapshotTargetName,
      getSnapshotTypeKey: getSnapshotTypeKey,
      getTzDate: getTzDate,
      isLogSnapshotSelected: isLogSnapshotSelected,
      selectSnapshot: selectSnapshot,
      selectReplica: selectReplica,
    });

    /**
     * Init routing for this controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      var startOfDay = moment().startOf('day');

      $ctrl.isRDS = $ctrl.entityEnvironment === 'kRDSSnapshotManager';

      if (!$ctrl.id) {
        $log.warn(
          'The "'+componentName+'" component requires an ID attribute for',
          'automation testing compliance.', $ctrl
        );
      }

      if ($ctrl.embedRestore) {
        $ctrl.restoreActions = [
          {
            translateKey: 'recover',
            action: function recoverAction() {
              $state.go(
                _getRestoreStateName(),
                _getRestoreStateParams()
              );
            },
          },
          {
            translateKey: 'clone',
            action: function recoverAction() {
              $state.go(
                _getRestoreStateName('clone'),
                _getRestoreStateParams()
              );
            },
          },
        ];
      }

      /*
       * Generates a 5 element array. The first index is the snapshotsDate
       * binding. Each subsequent index is 6 hours later than the previous
       * index. This generates the 6 hour ticks of:
       *
       * 12:00am, 6:00am, 12:00pm, 6:00pm, 12:00am
       */
      $ctrl.timelineTicks = range(5).map(function increaseDate(tick) {
        return startOfDay.add(tick ? 6 : 0, 'hours').toDate();
      });

      assign($ctrl.sliderOptions, {
        hideLimitLabels: true,
        showTicksValues: false,
        ticksTooltip: getSliderDate,
        translate: _sliderTooltip,

        // Function(sliderId, modelValue, highValue, pointerType)
        // onChange, onStart, onEnd
        onChange: function onSliderChange(sliderId, modelValue) {
          _setTimepickerTime(modelValue);
          $ctrl.snapshot = $ctrl.archiveTarget = undefined;
        },
        onEnd: function onSliderDrop(sliderId, modelValue) {
          var nearestSnapshot = _getNearestSnapshot(modelValue);

          if (nearestSnapshot) {
            selectSnapshot(nearestSnapshot);
          } else if (!_isSelectedPITAllowed(modelValue)) {
            // If clicked in invalid area, check which snapshot is latest
            if (isLatestSnapshotPIT()) {
              // Reset to latest PIT if that is latest snapshot or
              _setTimepickerTime(last($ctrl.PITRanges).endTimeUsecs/1000 - 1000);
            } else {
              // Reset to latest incremental snapshot
              selectSnapshot($ctrl.inRangeSnapshots[0]);
            }
          }

          _updateModel();
        },
      });

      if ($ctrl.snapshots) {
        // If an array of snapshots is provided, use that to populate the
        // inRangeSnapshots array. Otherwise this is populated via an additional
        // API call.
        $ctrl.inRangeSnapshots = sortBy(
          $ctrl.snapshots.filter(_onlyInRangeSnapshots),
          'restoreInfo.startTimeUsecs'
        );

        // Select the newest snapshot by default
        selectSnapshot($ctrl.inRangeSnapshots[0]);
      }
    }

    /**
     * Determines if the latest snapshot for the day is Point in time snapshot
     *
     * @method   isLatestSnapshotPIT
     */
    function isLatestSnapshotPIT() {
      // If no PIT exists in the day, return false
      if (!$ctrl.PITRanges || !$ctrl.PITRanges[0]) {
        return;
      }

      // If no incremental snapshots in the day, return true
      if (!$ctrl.inRangeSnapshots[0]) {
        return true;
      }

      return last($ctrl.PITRanges).endTimeUsecs >
        $ctrl.inRangeSnapshots[0].restoreInfo.startTimeUsecs;
    }

    /**
     * Handler for changes to one-way bound attributes.
     *
     * @method   $onChanges
     * @param    {object}   changes  AngularJS's changes object.
     */
    function $onChanges(changes) {
      var selectedDate = moment($ctrl.snapshotsDate).tz($ctrl.timezone);
      var jobIdsHaveChanged = !!changes.jobIds;
      var depsParams;

      if (!$ctrl.entityId &&
        !$ctrl.selectedPointInTime &&
        !$ctrl.snapshotsDate) { return; }

      $ctrl.noPit = !ENV_GROUPS.pitSupported.includes($ctrl.entityEnvironment);

      // Reset any selections because we're changing dates and scales and all
      // that.
      _resetSelectionValues();

      if (get(changes, 'selectedPointInTime.currentValue')) {
        selectedDate = moment($ctrl.selectedPointInTime * 1000)
          .tz($ctrl.timezone);
      }

      assign($ctrl, {
        _jobIds: $ctrl.jobIds && [].concat($ctrl.jobIds),
        overallRange: [
          selectedDate.clone().startOf('day').toDate(),
          selectedDate.clone().endOf('day').toDate(),
        ],
      });

      depsParams = {
        date: selectedDate.valueOf(),
        entityEnvironment: $ctrl.entityEnvironment,
        entityId: $ctrl.entityId,
        jobIds: $ctrl._jobIds,
        noPit: $ctrl.noPit,
        timezone: $ctrl.timezone,
      };

      _setLoadingBool(true);

      SnapshotSelectionService
        .getSnapshotPickerDependencies(depsParams, jobIdsHaveChanged,
          $ctrl.jobUids)
        .then(_processDependencies)
        .finally(function dependenciesProcessed() {
          // This is the list of non-log snapshots on this date to display in
          // the rzSlider.
          $ctrl.sliderOptions.ticksArray =
            map($ctrl.inRangeSnapshots, 'restoreInfo._startTimeMsecs');

          // Depending on the config options, select the snapshot, or
          // Point-in-Time.
          if (get(changes, 'selectedPointInTime.currentValue')) {
            $log.log('PIT changed, setting PIT');
            // Since the incoming PIT will be in seconds, expand it to
            // milliseconds here.
            _setTimepickerTime($ctrl.selectedPointInTime * 1000);
          } else if (get(changes, 'selectedSnapshot.currentValue')) {
            var current = changes.selectedSnapshot.currentValue;
            var prev = changes.selectedSnapshot.previousValue;

            // If there is no change in value, input is not changed.
            if (current && prev &&
              current.snapshotTimestampUsecs === prev.snapshotTimestampUsecs) {
              return;
            }
            $log.log('Selected Snapshot input changed');
            selectSnapshot(
              $ctrl.selectedSnapshot,
              $ctrl.selectedReplica
            );
          } else if (get(changes, 'snapshotsDate.currentValue') &&
            !$ctrl.selectedPITms) {
            $log.log('Snapshot date changed only. Selecting latest snapshot on this date');

            if (isLatestSnapshotPIT()) {
              _setTimepickerTime(last($ctrl.PITRanges).endTimeUsecs/1000 - 1000);
            } else {
              selectSnapshot(
                get($ctrl, 'versions[0]') ||
                  _getPitSnapshot(selectedDate.valueOf() / 1000)
              );
            }
          }

          _setLoadingBool(false);
        });
    }

    /**
     * callback for timepicker value chage
     *
     * @method   changeTimepickerTime
     */
    function changeTimepickerTime() {

      // Note: ng-model-options:{debounce: 750} make the timepicker to be empty
      // when giving the next input. The $timeput is used to wait for users
      // complete their typing.
      $timeout(function timeoutToChangeTimepickerTime() {
        // Exit without making changes if null (one f the visible inputs is
        // empty).
        if ($ctrl.timepickerTime === null) { return; }

          $ctrl.snapshot = undefined;

          // When the time selected is not valid the select the
          // latest snapshot for that date.
          if (_isSelectedPITAllowed($ctrl.timepickerTime)) {
            syncValues(_convertBrowserTzDateToSelectedTzEpoch($ctrl.timepickerTime));
          } else if (!_isSelectedPITAllowed($ctrl.timepickerTime)) {
            // If clicked in invalid area, check which snapshot is latest
            if (isLatestSnapshotPIT()) {
              // Reset to latest PIT if that is latest snapshot or
              _setTimepickerTime(last($ctrl.PITRanges).endTimeUsecs/1000 - 1000);
            } else {
              // Reset to latest incremental snapshot
              selectSnapshot($ctrl.inRangeSnapshots[0]);
            }
          }

          if ($ctrl.isRDS) {
            var nearestSnapshot = _getNearestSnapshot($ctrl.timepickerTime);

            if (nearestSnapshot) {
              selectSnapshot(nearestSnapshot);
            }
          }
      }, 750);

    }

    /**
     * Sets the timepicker time and sync the other values related to this
     * change.
     *
     * @param   {number}    time    time/datetime
     */
    function _setTimepickerTime(time) {
      var _time = moment(time).clone().tz($ctrl.timezone)
        .format(DateTimeService.getDateTimeInputFormat());
      $ctrl.timepickerTime = _time;
      syncValues(time);
    }

    /**
     * update the selectedPIT and selectedPITms values for the given datetime.
     *
     * @param   {number}   pitDate
     */
    function syncValues(pitDate) {
      if (pitDate) {
        // This ensures the model is set with a Date object, regardless of the
        // input type.
        $ctrl.selectedPIT = moment(pitDate).toDate();
        _setSelectedPITms(pitDate);
        _updateModel();
      }
    }

    /**
     * Get the nearest snapshot to the given date within a given threshold.
     * useful for quantizing a PIT selection to a meaningful snapshot.
     *
     * @method   _getNearestSnapshot
     * @param    {number|object}   date                The ms date or Date
     *                                                 object to search with.
     * @param    {number}          [msThreshold=10m]   Snap threshold in
     *                                                 milliseconds.
     * @return   {object}          The found snapshot, if any.
     */
    function _getNearestSnapshot(date, msThreshold) {
      var found;
      msThreshold = msThreshold || 10 * 60000;
      date = +date;

      var snapshots = $ctrl.noPit ? $ctrl.inRangeSnapshots :
        $ctrl.pitResponse.fullSnapshotInfo;

      found = find(snapshots, function eachJobRun(run) {
        return inRange(
          date,
          run.restoreInfo._startTimeMsecs - msThreshold,
          run.restoreInfo._startTimeMsecs + msThreshold
        );
        }
      );
      return found;
    }

    /**
     * Gets the tooltip template to display in rzSlider.
     *
     * @method   _sliderTooltip
     * @param    {number|date}   date   Millisecond date to show
     * @return   {string}        $interpolated & compiled template string to
     *                           display.
     */
    function _sliderTooltip(date) {
      var template = [
        '<aside class="flex-row center">',
          '<div>',
            '<div>' + getSliderDate(date, true) + '</div>',
            '<small class="metadata">{{::"time" | translate}}</small>',
          '</div>',
          '<div>',
            '<div>{{::$ctrl.getSnapshotTypeKey() | translate}}',
            '</div>',
            '<small class="metadata">{{::"snapshot" | translate}}</small>',
          '</div>',
        '</aside>'
      ];

      // Invalid PIT error messaging
      if (!$ctrl.snapshot && !_isSelectedPITAllowed(date)) {
        template = ['<aside class="flex-row center status-critical">',
          '<div>{{"errors.patterns.invalidPointInTime" | translate}}</div>',
        '</aside>'];
      }

      return $interpolate(template.join(''))($scope);
    }

    /**
     * Gets the snapshot type translation key for the appropriate for the
     * current selection.
     *
     * @method   getSnapshotTypeKey
     * @return   {string}   The translation key
     */
    function getSnapshotTypeKey() {
      if ($ctrl.isRDS) {
        if (!$ctrl.snapshot) {
          return 'automated';
        }
        return 'manual';
      }

      if (!get($ctrl.ngModelCtrl,
        '$modelValue.snapshot.replicaInfo.replicaVec[0]')) {
        return $filter('backupType')('kLog');
      }

      return $filter('backupType')(
        get($ctrl.snapshot, 'scheduledBackupType', 'kRegular'));
    }

    /**
     * Validates if the selected ms PIT is within a valid range blob. Function
     * signature is standard AngularJS model validator.
     *
     * @method   _validatePit
     * @param    {object}   [modelVal]  The current model value, if defined.
     * @param    {object}   [viewVal]   The current view value, if defined.
     * @return   {boolean}   True if within a valid range. False otherwise.
     */
    function _validatePit(modelVal, viewVal) {
      // The values we get should be converted into epoch as we get times in
      // epoch from the backend.
      var epoch = _convertBrowserTzDateToSelectedTzEpoch(modelVal || viewVal);
      return _isSelectedPITAllowed(epoch);
    }

    /**
     * Convert the date in the browser timezone to the selected timezones first
     * and then converts it to epoch.
     *
     * @method   _convertBrowserTzDateToSelectedTzEpoch
     * @param    {dateTime}    date   input date time  in browser timezone.
     * @returns  {number}             epoch time of the the selected timezone.
     */
    function _convertBrowserTzDateToSelectedTzEpoch(date) {
      var dateTimeString = moment(date)
        .format(DateTimeService.getDateTimeInputFormat());
      var unixTime = moment.tz(dateTimeString, $ctrl.timezone).valueOf();
      return unixTime;
    }

    /**
     * Reset all the models used in this component UI.
     *
     * @method   _resetSelectionValues
     */
    function _resetSelectionValues() {
      $ctrl.PITRanges.length = 0;
      $ctrl.selectedPIT =
        $ctrl.snapshot =
        $ctrl.archiveTarget =
        $ctrl.selectedPITms = undefined;
    }

    /**
     * Function to determine whether the given snapshot is within the range
     *
     * @param    {object}   snapshot   The selected snapshot (with replicaInfo)
     * @return   {boolean}   True if the snapshot is within range.
     * @private
     */
    function _onlyInRangeSnapshots(snapshot) {
      return inRange(
        snapshot.restoreInfo._startTimeMsecs,
        +$ctrl.overallRange[0],
        +$ctrl.overallRange[1]
      );
    }

    /**
     * Process the dependencies promise response for use in this controller.
     *
     * @method   _processDependencies
     * @param    {object}   result   The hash of request responses. Will include
     *                               the Entity found by Yoda search for the
     *                               given job, and possibly an array of PIT
     *                               range blobs.
     */
    function _processDependencies(result) {
      // Update the detected key with the new findings.
      yodaDocumentKey =
        result.entity.fileDocument ? 'fileDocument' : 'vmDocument';

      // If result.jobs doesn't exist, add it back from current known jobs, if
      // any. This can be undefined when the User selects a different date and the
      // jobIds input hasn't changed.
      if (!result.jobs && Array.isArray($ctrl.jobs)) {
        result.jobs = $ctrl.jobs.slice(0);
      }

      assign($ctrl, result);

      if (!$ctrl.noPit) {
        // The list of in-range snapshots. This is necessary, because when using
        // fullSnapshotInfo from PIT endpoint, there is always one, if any, that
        // is outside the requested range. That is necesssary for PIT selection on
        // dates with no Magneto Job runs, but on which a PIT range is available.
        $ctrl.inRangeSnapshots =
          get($ctrl, 'pitResponse.fullSnapshotInfo', [])
          .filter(_onlyInRangeSnapshots);
      }

      $ctrl.jobsHash = keyBy($ctrl.jobs, 'id');

      // Here we make sure the list is sorted in reverse chronological order by
      // start time.
      $ctrl.inRangeSnapshots =
        orderBy($ctrl.inRangeSnapshots, ['restoreInfo._startTimeMsecs'], ['desc']);

      $ctrl.versions = $ctrl.inRangeSnapshots ||
        result.entity[yodaDocumentKey].versions;

      $ctrl.PITRanges = get(result, 'pitResponse.timeRanges', []);

      // Calculate the X axis offset of each snapshot from the start of the day.
      $ctrl.versions.forEach(function eachVersion(version) {
        version.xOffset = getOffset(version.restoreInfo.startTimeUsecs);
      });

      // Calculate PIT starting offset (percentage along X axis), and durations
      // (percentage width)
      $ctrl.PITRanges.forEach(function(range) {
        assign(range, {
          xOffset: getOffset(range.startTimeUsecs),
          duration: ((range.endTimeUsecs - range.startTimeUsecs) /
            ($ctrl.overallRange[1] - $ctrl.overallRange[0])) / 10,
        });
      });

      // Update the sliderOptions with changed values
      assign($ctrl.sliderOptions, {
        ceil: +$ctrl.overallRange[1],
        floor: +$ctrl.overallRange[0],
        maxLimit: +$ctrl.overallRange[1],
        minLimit: +$ctrl.overallRange[0],
      });

      $ctrl.canUsePIT = $ctrl.noPit ? false : _canSelectLogsTimes();

      // Set the view to use. Honor any user selection already made.
      $ctrl.viewFormat = $ctrl.viewFormat ||
        ($ctrl.canUsePIT ? 'pit-range' : 'snapshot-list');

      if ($ctrl.snapshots) {
        // Show PIT Range by default if snapshots array is provided.
        // The slider view of c-snapshot-picker is called "pit-range" and also
        // the Oracle/SQL/RDS types support a PIT endpoint, these are different
        // things.
        $ctrl.viewFormat = 'pit-range';
      }
    }

    /**
     * Returns the label for rzSlider for each date.
     *
     * @method   getSliderDate
     * @param    {number}    date                      A Date in ms.
     * @param    {boolean}   isShownInSelectedTimezone Flag to specify if the
     *                                                 date time should be
     *                                                 displayed in selected
     *                                                 timezone.
     * @return   {string}    The formatted date for display (h:mm:ssa).
     */
    function getSliderDate(date, isShownInSelectedTimezone) {
      var _date = isShownInSelectedTimezone ?
        moment(date).tz($ctrl.timezone) : moment(date);
      return _date.format(DateTimeService.getPitTimeFormat());
    }

    /**
     * Returns timezone adjusted date for the given/default format.
     *
     * @method   getTzDate
     * @param    {number|object}   date     date object / unix time.
     * @param    {string}          [format] display format
     * @return   {string}          The formatted date for display.
     */
    function getTzDate(date, format) {
      return moment(date).tz($ctrl.timezone).format(
        format || DateTimeService.getPreferredDateFormat()
      )
    }

    /**
     * Determines the X axis offset for the given value along the range.
     * Calculates percentage.
     *
     * @method   getOffset
     * @param    {number}   point     The number to calculate the offset for.
     * @param    {number}   [start]   Optional: Starting point (0%) of range.
     *                                Uses global range start if undefined.
     * @param    {number}   [end]     Optional: Ending point (100%) of range.
     *                                Uses global range end if undefined.
     * @return   {number}   The percentage to offset the x-axis of a date.
     */
    function getOffset(point, start, end) {
      var absDistance;

      start = start || ($ctrl.overallRange[0] * 1000);
      end = end || ($ctrl.overallRange[1] * 1000);
      absDistance = Math.abs(end - start);

      // Calculate the percentage to offset along X axis for the range.
      return ((point - start) / absDistance) * 100;
    }

    /**
     * Set the selected Snapshot.
     *
     * @method   selectSnapshot
     * @param    {object}   snapshot    The selected snapshot.
     * @param    {object}   [replica]   The archive target replica to also
     *                                  select.
     */
    function selectSnapshot(snapshot, replica) {
      var _snapshot;

      // When the snapshot is coming in initially, it's not from the
      // pointsForTimeRange proto, but `restoreInfo` is.
      if (snapshot && snapshot.restoreInfo) {
        $ctrl.snapshot = snapshot.restoreInfo;
      } else {
        var snapshots = $ctrl.noPit ? $ctrl.inRangeSnapshots :
          $ctrl.pitResponse.fullSnapshotInfo;

        _snapshot = find(snapshots, function findSnapshot(_snapshot) {
          return _snapshot.restoreInfo.jobRunId
            === snapshot.instanceId.jobInstanceId;
        });
        $ctrl.snapshot = _snapshot ? _snapshot.restoreInfo : undefined;
      }

      if ($ctrl.snapshot) {
        // Scenario where we have atleast one full/incremental backup for
        // the selected date i.e pickers can be set from the snapshot.
        _setTimepickerTime($ctrl.snapshot._startTimeMsecs);
        selectReplica(replica);
      } else {
        // Scenario where there is no full/incremental backup for the selected
        // date but has only log backups.
        if ($ctrl.PITRanges.length && last($ctrl.PITRanges).endTimeUsecs) {
          _setTimepickerTime(last($ctrl.PITRanges).endTimeUsecs/1000 - 1000);
        }

        if (!$ctrl.PITRanges.length && $ctrl.entityEnvironment === 'kOracle' &&
          FEATURE_FLAGS.skipValidationOracleLogSnapshotPitSelectionEnabled) {
          _setTimepickerTime($ctrl.sliderOptions.minLimit);
        }
      }
    }

    /**
     * Wrapper around selectSnapshot to also set some other vars for use in
     * single snapshot mode (PIT view).
     *
     * @method   changeSnapshot
     * @param    {object}   snapshot   The Snapshot to select.
     */
    function changeSnapshot(snapshot) {
      selectSnapshot.apply(this, arguments);
    }

    /**
     * Set the selected ArchiveTarget replica.
     *
     * @method   setReplica
     * @param    {object}   [replica]   If defined, sets the replica. Otherwise
     *                                  clears it (local).
     */
    function selectReplica(replica) {
      if (!canRestoreFromArchiveTarget($ctrl.snapshot._jobId)) { return; }
      $ctrl.archiveTarget = replica || _getDefaultArchiveTarget($ctrl.snapshot);

      // Sync the model.
      _updateModel();
    }

    /**
     * Sets the selected PIT in ms model.
     *
     * @method   _setSelectedPITms
     * @param    {object|number}   ms   JS Date object, or ms date.
     */
    function _setSelectedPITms(ms) {
      $ctrl.selectedPITms = ms * 1;
    }

    /**
     * Determines if the given ms date is within a PIT range.
     *
     * @method   _isSelectedPITAllowed
     * @param    {number|object}   pitDate   The JS Date object, or ms integer
     *                                       date.
     * @return   {boolean}   True if within a PIT range. False if not.
     */
    function _isSelectedPITAllowed(pitDate) {
      if (!!pitDate && isLogSnapshotSelected()) {
        return true;
      }
      return !!_getTimeRangeByDate(pitDate);
    }

    /**
     * Returns true log backups snapshot is selected.
     *
     * @method   isLogSnapshotSelected
     * @return   {boolean}   True if snapshot is oracle log snapshot. False if not.
     */
    function isLogSnapshotSelected() {
      return ['kOracle'].includes($ctrl.entityEnvironment) &&
        !get($ctrl.ngModelCtrl, '$modelValue.snapshot.replicaInfo.replicaVec[0]') &&
        !$ctrl.PITRanges.length &&
        FEATURE_FLAGS.skipValidationOracleLogSnapshotPitSelectionEnabled;
    }

    /**
     * Sets the loading boolean.
     *
     * @method   _setLoadingBool
     * @param    {boolean}   [isLoading]   The truthy/falsey value to set.
     */
    function _setLoadingBool(isLoading) {
      $ctrl.depsLoading = !!isLoading;
    }

    /**
     * Updates the compound ngModel with the current values selected by the
     * user.
     *
     * @method   _updateModel
     */
    function _updateModel() {
      var newModel = {
        pointInTime: $ctrl.selectedPITms &&
          _clampSelectedPIT($ctrl.selectedPITms),
      };
      newModel.snapshot =
        $ctrl.snapshot || _getPitSnapshot(newModel.pointInTime);
      newModel.replica = $ctrl.archiveTarget ||
        _getDefaultArchiveTarget(newModel.snapshot);

      if ($ctrl.isRDS && !$ctrl.snapshot) {
        newModel.isPIT = true;
      }

      $ctrl.ngModelCtrl.$setViewValue(newModel);
    }

    /**
     * Gets the default replica target from the given snapshot. If no local
     * available, takes first remote target. Assumes replication targets are
     * pre-filtered.
     *
     * @method   _getDefaultArchiveTarget
     * @param    {object}   snapshot   The selected snapshot (with replicaInfo)
     * @return   {object}   The first usable replica target if other than local.
     *                      Undefined if local (default).
     */
    function _getDefaultArchiveTarget(snapshot) {
      return get(snapshot, 'replicaInfo.replicaVec', [])
        .find(function findFirstUsableTarget(target) {
          // Accept whatever is the first non-replication (!2) target in the
          // list.
          return target.target.type !== 2;
        });
    }

    /**
     * Clamp the user-selected millisecond PIT value within the Usecs range,
     * who's start point is rounded up to the nearest whole second, and who's
     * end point is rounded down to the nearest whole second. This ensures the
     * value lies within the range provided by Magneto.
     *
     * This is necessary because we have 3 levels of accuracy to contend with:
     *  1. SQL & JS in msecs
     *  2. The PIT property sent to Magneto in seconds
     *  3. the Magneto provided tiem ranges in Usecs
     *
     * @mathod   _clampSelectedPIT
     * @param    {number|date}   ms   PIT date in milliseconds or Date object.
     * @return   {number}   The input converted to seconds and clamped within
     *                      the matching Usec range.
     */
    function _clampSelectedPIT(ms) {
      var clamped;
      var range;
      var min;
      var max;

      // Ensure the input is an integer, even when receiving a Date
      // object.
      ms *= 1;
      range = _getTimeRangeByDate(ms) || {};
      min = range._startTimeMsecs;
      max = range._endTimeMsecs;

      switch (true) {
        case (ms < min):
          clamped = min;
          break;

        case (ms > max):
          clamped = max;
          break;

        default:
          clamped = ms;
      }

      return Math.round(clamped / 1000);
    }

    /**
     * Gets the range this PIT falls within from the list of ranges currently
     * known.
     *
     * @method   _getTimeRangeByDate
     * @param    {number|object}   ms   Millisecond date or Date object.
     * @return   {object}          The range blob this PIT falls within.
     */
    function _getTimeRangeByDate(ms) {
      return $ctrl.PITRanges.find(function eachRange(range) {
        return inRange(ms, range._startTimeMsecs, range._endTimeMsecs);
      });
    }

    /**
     * Gets the Magneto-supplied snapshot-like object nearest the User selected
     * PIT.
     *
     * @method   _getPitSnapshot
     * @param    {number}   dateSec   The date to find, in seconds.
     * @return   {object}   The nearest snapshot-like object the selected PIT
     *                      can start from. This is a partial snapshot object.
     */
    function _getPitSnapshot(dateSec) {
      var snapshots = get($ctrl, 'pitResponse.fullSnapshotInfo', []);

      // Oldest and latest will either both be defined, or both undefined. And
      // potentially both the same snapshot.
      var oldestSnapshot = last(snapshots);
      var latestSnapshot = first(snapshots);
      var pitFullSnapshot =
        // Find the nearest full snapshot.
        snapshots.find(
          function findNearestPreviousSnapshot(snapshot, ii, list) {
            var nextIndex = ii === list.length - 1 ? ii : ii + 1;

            // Quick exit if only one snapshot
            return list.length === 1 ||
              // Check if this date is within 2 snapshots
              inRange(
                dateSec,
                (snapshot.restoreInfo._startTimeMsecs/1000),
                (list[nextIndex].restoreInfo._startTimeMsecs/1000)
              );
          }
        );

      // If dateSec isn't within the range of snapshots, pick a default.
      if (!pitFullSnapshot && latestSnapshot) {
        pitFullSnapshot =
          // If the dateSec is greater than the most recent snapshot date, chose
          // the most recent snapshot. Otherwise take the oldest.
          (dateSec >= latestSnapshot.restoreInfo._startTimeMsecs/1000) ?
            latestSnapshot : oldestSnapshot;
      }

      return {
        instanceId: mapKeys(
          get(pitFullSnapshot, 'restoreInfo.instanceId'),
          function instanceKeyMapper(value, key) {
            switch (key) {
              case 'attemptNumber':
                return 'attemptNum';

              case 'jobRunId':
                return 'jobInstanceId';

              case 'startTimeUsecs':
                return 'jobStartTimeUsecs';
            }

            return key;
          }
        ),
      };
    }

    /**
     * Gets the state route params appropriate for recovering the entity used in
     * this widget. Typically, this will be an abbreviated restore flow.
     *
     * @method   _getRestoreStateParams
     * @return   {object}   The state params object.
     */
    function _getRestoreStateParams() {
      switch (get($ctrl.entity, '_type')) {
        // kSQL
        case 3:
          let jobRunStartTime;
          if ($ctrl.snapshot) {
            jobRunStartTime = $ctrl.snapshot.startTimeUsecs;
          } else {
            // If there is no selected snapshot, it should be a PIT restore.
            if (FEATURE_FLAGS.ngRestoreMsSql) {
              // NG Recovery needs the startTimeUsecs of the closest snapshot
              // in range before the PIT before proceeding to PIT selection.
              const snapshot = _getNearestSnapshotBeforePIT($ctrl.selectedPITms);
              jobRunStartTime = snapshot ?
                get(snapshot, 'restoreInfo.startTimeUsecs') :
                get(_getTimeRangeByDate($ctrl.selectedPITms), 'startTimeUsecs');
            } else {
              jobRunStartTime =
                get(_getTimeRangeByDate($ctrl.selectedPITms), 'startTimeUsecs');
            }
          }
          return {
            dbType: 'sql',
            entityId: $ctrl.entityId,
            archiveId: _getSelectedArchiveTargetId(),
            jobRunStartTime: jobRunStartTime,
            jobUid: $ctrl.snapshot ?
              $ctrl.snapshot.jobUid :
              get(_getTimeRangeByDate($ctrl.selectedPITms), 'jobUid'),
            jobId: $ctrl.snapshot ?
              // If there's a selected snapshot, use that jobId.
              get($ctrl.snapshot, 'jobUid.id') :

              // Otherwise, this a PIT restore so use the jobId of the range
              // selected.
              get(_getTimeRangeByDate($ctrl.selectedPITms), 'jobUid.id'),

            // This will be undefined in a PIT scenario, which is OK.
            jobInstanceId: get($ctrl.snapshot, 'jobRunId'),
            logTimeMs: !get($ctrl.snapshot, 'attemptNumber') ?
              $ctrl.selectedPITms : undefined,
            sourceId: get($ctrl.entity, 'registeredSource.id'),
          };
      }
    }

    /**
     * Gets the vaultId of the selected archiveTarget.
     *
     * @function   _getSelectedArchiveTargetId
     * @returns    {number}   The found vaultId, or undefined.
     */
    function _getSelectedArchiveTargetId() {
      return get($ctrl.archiveTarget, 'target.archivalTarget.vaultId');
    }

    /**
     * Gets the state route name appropriate for recovering the entity used in
     * this widget. Typically, this will be an abbreviated restore flow, ie.
     * 'recover-db.options'
     *
     * @method   _getRestoreStateName
     * @param    {string}   [flowType='recover']   The flow type to build the
     *                                             state for.
     * @return   {string}   The restore state name.
     */
    function _getRestoreStateName(flowType) {
      flowType = flowType || 'recover';
      switch (get($ctrl.entity, '_type')) {
        // kSQL
        case 3:
          return flowType + '-db.options';
      }
    }

    /**
     * Determines if this session can recover SQL DBs from a cloud archive.
     *
     * @method   canRestoreFromArchiveTarget
     * @param    {number}    id   The job id to look up.
     * @returns  {boolean}   True if can recover from Archive Targets.
     */
    function canRestoreFromArchiveTarget(id) {
      var backupType;
      var baseCondition;

      if ($ctrl.isRDS) {
        return true;
      }

      // TODO (spencer): Expand this to account for Oracle..
      backupType = get(
        $ctrl,
        'jobsHash['+id+'].environmentParameters.sqlParameters.backupType'
      );

      baseCondition = FEATURE_FLAGS.sqlRestoreFromArchive &&
        !$ctrl.isDbMigration;

      if (baseCondition) {
        return FEATURE_FLAGS.sqlVolumeRestoreFromArchive ||
          backupType !== 'kSqlVSSVolume';
      }

      return false;
    }

    /**
     * Determines if PIT selection is permitted in this flow.
     *
     * @method   _canSelectLogsTimes
     * @return   {boolean}   True if PIT selection is permitted.
     */
    function _canSelectLogsTimes() {
      return $ctrl.showLogs &&
        UI_PIT_SUPPORTED_ENVIRONMENTS.includes($ctrl.entityEnvironment);
    }

    /**
     * Find the latest snapshot occurred before the specified PIT timestamp.
     * If such snapshot can't be found, returns the oldest snapshot from the list.
     *
     * @param    selectedPITms   The selected PIT timestamp in msecs.
     * @returns  The nearest snapshot before PIT that is in range.
     */
    function _getNearestSnapshotBeforePIT(selectedPITms) {
      const len = $ctrl.inRangeSnapshots.length;

      if (!len) {
        return;
      }

      for (let i = 0; i < len; i++) {
        // Since inRangeSnapshots array is in reverse chronological order,
        // the first snapshot that satisfies the condition is the nearest snapshot
        // before selected PIT.
        if ($ctrl.inRangeSnapshots[i].restoreInfo.snapshotTimestampUsecs <= (selectedPITms * 1000)) {
          return $ctrl.inRangeSnapshots[i];
        }
      }

      // Return the oldest snapshot.
      return $ctrl.inRangeSnapshots[len - 1];
    }

    /**
     * Get the display name for replica target
     *
     * @param  {Object} replica The replica target object
     * @return {String} The name for the replica target
     */
    function getSnapshotTargetName(replica) {
      switch (true) {

        // Regular Archival Target
        case !!replica.target.archivalTarget:
          return replica.target.archivalTarget.name;

        // CSM/CloudDeploy Archival target
        case !!replica.target.cloudDeployTarget:
          return replica.target.cloudDeployTarget.targetEntity.displayName;

        // Remote Cluster Target
        case !!replica.target.replicationTarget:
          return replica.target.replicationTarget.clusterName;

        default:
          return 'local';
      }
    }
  }

})(angular);
