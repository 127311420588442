import { assign } from 'lodash-es';
// Component: Proxy configure modal component for connected site flow
;(function(angular) {
  'use strict';

  angular
    .module('C.clusterClaim')
    .controller('ProxyConfigCtrl', ProxyConfigCtrlFn)
    .component('proxyConfigModal', {
      templateUrl: 'app/cluster-connect/proxy-configure-modal.html',
      controller: 'ProxyConfigCtrl',
      require: {
        cModalHoc: '^^?cModalHoc',
      },
    });

  /**
   * This is the controller function for proxy configure modal
   * initializes the params and handles the selection
   *
   * @method   ProxyConfigCtrlFn
   */
  function ProxyConfigCtrlFn(_, FORMATS, ProxyService, ClusterService) {
    var $ctrl = this;

    assign($ctrl, {
      proxyConfig: {
        address: '',
        port: 0,
        requiresPassword: '',
        username: '',
        password: '',
        schemes: ['http'],
        services: ['kHelios'],
      },
      FORMATS: FORMATS,
      httpsConnection: false,

      //methods
      optionSelected: optionSelected,
      cancelModal: cancelModal,
      toggleConnectionType: toggleConnectionType
    });

    /**
     * This function closes the modal when continue is clicked
     *
     * @method   optionSelected
     */
    function optionSelected() {
      ProxyService.updateUIProxyConf($ctrl.proxyConfig)
        .then(function setRetriesToDefault() {
          ClusterService.updateLicenseState(undefined, 0);
        });
      $ctrl.cancelModal();
    }

    /**
     * function to toggle http or https in connection type
     *
     * @method toggleConnectionType
     */
    function toggleConnectionType() {
      $ctrl.proxyConfig.schemes[0] = $ctrl.httpsConnection ? 'https' : 'http'
    }

    /**
     * Cancels this modal
     *
     * @method     cancelModal
     * @return     {Object}  Promise with the modal dismissal result
     */
    function cancelModal() {
      return $ctrl.cModalHoc.close();
    }
  }
})(angular);
