/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DataSourceConnectorLogs } from '../models/data-source-connector-logs';
import { DataSourceConnectorRegistrationRequest } from '../models/data-source-connector-registration-request';
import { DataSourceConnectorLocalStatus } from '../models/data-source-connector-local-status';
@Injectable({
  providedIn: 'root',
})
class DataSourceConnectorLocalService extends __BaseService {
  static readonly GetDataSourceConnectorLogsPath = '/data-source-connector/logs';
  static readonly RegisterDataSourceConnectorPath = '/data-source-connector/registration';
  static readonly GetDataSourceConnectorStatusPath = '/data-source-connector/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the data-source connector logs.
   *
   * Lists the logs corresponding to the data-source connector creation and registration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetDataSourceConnectorLogsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<DataSourceConnectorLogs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-source-connector/logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSourceConnectorLogs>;
      })
    );
  }
  /**
   * Lists the data-source connector logs.
   *
   * Lists the logs corresponding to the data-source connector creation and registration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetDataSourceConnectorLogs(regionId?: string,
    accessClusterId?: number): __Observable<DataSourceConnectorLogs> {
    return this.GetDataSourceConnectorLogsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as DataSourceConnectorLogs)
    );
  }

  /**
   * Register a data-source connector.
   *
   * Register a data-source connector with a cluster using the supplied registration token. The registration token for the data-source connection with which this connector is to be registered has to be obtained by the user by invoking the relevant '/data-source-connections' APIs.
   * @param params The `DataSourceConnectorLocalService.RegisterDataSourceConnectorParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register the connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RegisterDataSourceConnectorResponse(params: DataSourceConnectorLocalService.RegisterDataSourceConnectorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-source-connector/registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register a data-source connector.
   *
   * Register a data-source connector with a cluster using the supplied registration token. The registration token for the data-source connection with which this connector is to be registered has to be obtained by the user by invoking the relevant '/data-source-connections' APIs.
   * @param params The `DataSourceConnectorLocalService.RegisterDataSourceConnectorParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register the connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RegisterDataSourceConnector(params: DataSourceConnectorLocalService.RegisterDataSourceConnectorParams): __Observable<null> {
    return this.RegisterDataSourceConnectorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Lists the data-source connector status.
   *
   * Lists the data-source connector status, which includes registration as well as cluster-connectivity status.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetDataSourceConnectorStatusResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<DataSourceConnectorLocalStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-source-connector/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSourceConnectorLocalStatus>;
      })
    );
  }
  /**
   * Lists the data-source connector status.
   *
   * Lists the data-source connector status, which includes registration as well as cluster-connectivity status.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetDataSourceConnectorStatus(regionId?: string,
    accessClusterId?: number): __Observable<DataSourceConnectorLocalStatus> {
    return this.GetDataSourceConnectorStatusResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as DataSourceConnectorLocalStatus)
    );
  }
}

module DataSourceConnectorLocalService {

  /**
   * Parameters for RegisterDataSourceConnector
   */
  export interface RegisterDataSourceConnectorParams {

    /**
     * Specifies the parameters to register the connector.
     */
    body: DataSourceConnectorRegistrationRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { DataSourceConnectorLocalService }
