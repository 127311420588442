// Service: GlobalSearchService
// TODO: GlobalSearchService.getResults is still being used in options.js and emails.js.
// Service can be removed once those references are removed

import { assign, get, map } from 'lodash-es';

;(function(angular, undefined) {
  'use strict';

  // Cache of the most recently run query value. Used to sync data between
  // implementation points.
  var currentQuery = '';
  var currentResults = [];
  var selectedResult;

  angular
    .module('C.globalSearch',
      [
        'C.sources',
        'C.pubRestoreService',
        'C.pubJobService',
        'C.pubJobServiceFormatter',
        'C.jobModify',
      ]
    )
    .service('GlobalSearchService', GlobalSearchServiceFn);

  function GlobalSearchServiceFn(_, $rootScope, $http, evalAJAX,
    DateTimeService, PubJobService, SearchService, JobRunsService,
    RestoreService, ENV_GROUPS, API, ENV_TO_DBTYPE, SOURCE_KEYS,
    ENUM_ARCHIVAL_TARGET, SNAPSHOT_TARGET_TYPE, LOCALE_SETTINGS,
    FEATURE_FLAGS, NgMcmViewService) {

    /**
     * A hash of SQL jobs keyed by id for property lookup.
     */
    var DB_JOBS_HASH_CACHE = {};

    $rootScope.$on('logout.initiated', _clearSearchCache);

    return {
      getResults: getResults,
    };

    /**
     * Calls the API to get global search results.
     *
     * @method   getResults
     * @param    {object}   params   The query params
     * @return   {object}   Promis to resolve the request for search results.
     */
    function getResults(params) {
      currentQuery = params.searchString;

      return $http({
        method: 'GET',
        url: $rootScope.basicClusterInfo.mcmMode ?
          API.mcm('globalSearch') : API.public('search/protectionSources'),
        params: params,
      }).then(function getSearchSuccess(resp) {
        return currentResults = (resp.data || []).map(_transformSearchResult);
      });
    }

    /**
     * Decorates the provided search result with convenience properties. NOTE:
     * Updates by reference in addition to returning the updated object.
     *
     * @method   _transformSearchResult
     * @param    {object}   result   The result
     * @return   {object}   The decorated result
     */
    function _transformSearchResult(result) {
      var sourceEnv = result.source.environment;
      var typedProtectionSource = SOURCE_KEYS[sourceEnv];
      var protectionSource = result.source[typedProtectionSource];

      return assign(result, {
        _sourceEnv: sourceEnv,
        _sourceType: !!protectionSource && protectionSource.type,
        _protectionSource: protectionSource,
        _clusterIds: map(result.protectionSourceUidList, 'clusterId'),
        _isProtected: !!get(result, 'jobs.length'),
        _isSystemDatabase: result.source.environment === 'kSQL' &&
          /^(master|msdb|model)$/i.test(
            get(result.source, 'sqlProtectionSource.databaseName')
          ),
      });
    }

    /**
     * Clears any cached data related to global search.
     *
     * @method _clearSearchCache
     */
    function _clearSearchCache() {
      currentQuery = '';
      currentResults.length = 0;
      selectedResult = undefined;
      DB_JOBS_HASH_CACHE = {};
    }
  }

})(angular);
