import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { SessionManagementServiceApi } from '@cohesity/api/v2';
import { DataIdDirective, FormsModule, SnackBarService } from '@cohesity/helix';
import { hasPrivilege, IrisContextService } from '@cohesity/iris-core';
import {
  CohesitySharedFormsModule,
  convertValueToSeconds,
  DurationSelectorValue,
  DurationUnit
} from '@cohesity/shared-forms';
import { AjaxHandlerService, ClearSubscriptions } from '@cohesity/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

/**
 * The default value of the inactivity Timeout.
 */
const defaultInactivityTimeout: DurationSelectorValue = { measurement: 60, unit: 'Minutes' };

/**
 * The default value of the absolute Timeout.
 */
const defaultAbsoluteTimeout: DurationSelectorValue = { measurement: 24, unit: 'Hours' };

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CohesitySharedFormsModule,
    DataIdDirective,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  selector: 'coh-session-management',
  templateUrl: './session-management.component.html',
  styleUrls: ['./session-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionManagementComponent extends ClearSubscriptions implements OnInit {
  /**
   * Defines the limits for inactivity timeout.
   */
  readonly inactivityLimits: Record<'min' | 'max', DurationSelectorValue> = {
    min: { measurement: 15, unit: 'Minutes' },
    max: { measurement: 24, unit: 'Hours' },
  };

  /**
   * Defines the limits for absolute timeout.
   */
  readonly absoluteLimits: Record<'min' | 'max', DurationSelectorValue> = {
    min: { measurement: 60, unit: 'Minutes' },
    max: { measurement: 24, unit: 'Hours' },
  };

  /**
   * List of duration units.
   */
  durationUnits = new Set<DurationUnit>(['Minutes', 'Hours', 'Days']);

  /**
   * Form group for the session management form
   */
  readonly form = new FormGroup(
    {
      inactivityTimeout: new FormControl<DurationSelectorValue>(defaultInactivityTimeout, Validators.required),
      absoluteTimeout: new FormControl<DurationSelectorValue>(defaultAbsoluteTimeout, Validators.required),
    },
    { validators: this.timeOutRangeValidator }
  );

  /**
   * Indicates whether form is being submitted.
   */
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  /**
   * Indicates whether API call is in progress.
   */
  readonly loading$ = new BehaviorSubject<boolean>(false);

  /**
   * Indicates whether user has privileges to manage session.
   */
  get isReadOnlyUser(): boolean {
    return !hasPrivilege(this.irisCtx.irisContext, 'USER_SESSION_CONFIG_MANAGE');
  }

  constructor(
    private ajaxServiceHandler: AjaxHandlerService,
    private irisCtx: IrisContextService,
    private sessionManagementService: SessionManagementServiceApi,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    if (this.isReadOnlyUser) {
      this.form.disable();
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    this.loading$.next(true);
    this.subscriptions.push(
      this.sessionManagementService
        .GetMcmSessionMgmtConfig()
        .pipe(finalize(() => this.loading$.next(false)))
        .subscribe(config => {
          if (config.absoluteTimeoutSeconds) {
            this.form.get('absoluteTimeout').setValue({
              measurement: config.absoluteTimeoutSeconds / 60,
              unit: 'Minutes',
            });
          }
          if (config.inactivityTimeoutSeconds) {
            this.form.get('inactivityTimeout').setValue({
              measurement: config.inactivityTimeoutSeconds / 60,
              unit: 'Minutes',
            });
          }
        }, this.ajaxServiceHandler.handler)
    );
  }

  /**
   * Callback for the "validatedSubmit" event on the form.
   */
  onFormSubmit(): void {
    this.submitting$.next(true);
    const formValue = this.form.value;

    this.subscriptions.push(
      this.sessionManagementService
        .SaveMcmSessionMgmtConfig({
          absoluteTimeoutSeconds: convertValueToSeconds(formValue.absoluteTimeout),
          inactivityTimeoutSeconds: convertValueToSeconds(formValue.inactivityTimeout),
        })
        .pipe(finalize(() => this.submitting$.next(false)))
        .subscribe(() => {
          this.snackBarService.open(this.translateService.instant('sessionManagement.successMessage'), 'success');
        }, this.ajaxServiceHandler.handler)
    );
  }

  /**
   * Reset form to default state
   */
  resetForm() {
    this.form.reset({
      inactivityTimeout: defaultInactivityTimeout,
      absoluteTimeout: defaultAbsoluteTimeout,
    });
    this.onFormSubmit();
  }

  /**
   * Validates whether inactivity timeout is less than absolute timeout
   *
   * @param formGroup form group to be validated
   * @returns error object if validation fails
   */
  timeOutRangeValidator(formGroup: FormGroup) {
    const formValue = formGroup.value;
    return formValue.absoluteTimeout &&
      formValue.inactivityTimeout &&
      convertValueToSeconds(formValue.inactivityTimeout) > convertValueToSeconds(formValue.absoluteTimeout)
      ? { timeoutInvalidError: true }
      : null;
  }
}
