import { AwsProtectionSource, EBSTag, EbsVolumeInfo, ProtectionSourceNode } from '@cohesity/api/v1';
import { isPostgresDb, isPostgresDbInstance } from 'src/app/modules/object-details-shared/aws-object-action-utils';
import { AWSEntities, CloudJobType, Environment } from 'src/app/shared/constants';
import { AwsLeafType, AwsLeafTypes, AwsTagTypes } from 'src/app/shared/constants/cloud.constants';
import { ConnectionState, HasConnectionState } from '../shared/behaviors/has-connection-state';

import { InvalidCloudVmState, InvalidCloudVmStateMessage } from '../shared/behaviors/invalid-cloud-vm-state';
import { ProtectionSourceDataNode, TooltipContext } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Extract tags from a aws ProtectionSourceNode. This is outside the class since it is
 * called from the constructor before the class has been completely initialized.
 *
 * @param   data   A protection source node
 * @returns Any applied tags ids or an empty list.
 */
function findTags(data: ProtectionSourceNode): number[] {
  return (data.protectionSource.awsProtectionSource.tagAttributes || []).map(tag => tag.id);
}

/**
 * Represents an active aws source node and job tree selection behavior.
 */
export class AwsSourceDataNode extends ProtectionSourceDataNode<AwsProtectionSource>
  implements InvalidCloudVmState, HasMetadata, HasConnectionState {
  readonly asConnectionState = this;

  readonly asInvalidCloudVmState = this as InvalidCloudVmState;

  /**
   * Node's metadata.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * Node's workload type, needs to be one of kEC2Instance and kRDSInstance.
   */
  workloadType: 'kEC2Instance' | 'kRDSInstance' | 'kS3Bucket';

  /**
   * The current selected jobType
   */
  private jobType: string;

  private _ebsVolumeTags: EBSTag[] = [];

  constructor(
    data: ProtectionSourceNode,
    readonly level: number,
  ) {

    super(Environment.kAWS, data, level, findTags(data));

    if (this.isTag) {
      this.metadata = {
        leafCount: this.children && this.children.length || 0
      };
    } else {
      this.metadata = {
        logicalSize: this.logicalSize,
        leafCount: !AwsLeafTypes.includes(this.type as AwsLeafType) ? this.leafCount : undefined,
      };
    }

    if (this.protectionSource.awsProtectionSource.volumes) {
      this.protectionSource.awsProtectionSource.volumes.forEach(volume => {
        if (volume.tags) {
          volume.tags.forEach(tag => this._ebsVolumeTags.push(tag));
        }
      });
    }
  }

  /**
   * Get lists of ebs volumes of a node if the node's type is kEC2Instance.
   *
   * @return   Array of ebs volumes.
   */
  get ebsVolumes(): EbsVolumeInfo[] | null {
    return this.protectionSource.awsProtectionSource && this.protectionSource.awsProtectionSource.volumes;
  }

  /**
   * Get lists of tags of ebs volumes of a node if the node's type is
   * kEC2Instance.
   *
   * @return   Array of ebs volumes.
   */
  get ebsVolumeTags(): EBSTag[] {
    return this._ebsVolumeTags;
  }

  /**
   * Whether the current node is a tag or not. This is determined by the node type.
   */
  get isTag(): boolean {
    return AwsTagTypes.includes(this.type);
  }

  /**
   * Is this a node without a cohesity agent installed?
   * Needed when the jobType is 'kAgent'
   *
   * @return   True if this node doesn't have physical agent installed and jobType is 'kAgent'.
   */
  isNodeWithoutPhysicalAgent = (): boolean => this.jobType === CloudJobType.kAgent &&
    this.type === 'kEC2Instance' && !this.protectionSource.awsProtectionSource.physicalSourceId;

  /**
   * Adds a property which tells if this Node is a RDS/AuroraPostgres DB Instance.
   */
  get isPostgresDbInstance(): boolean {
    return isPostgresDbInstance(this.data.protectionSource);
  }

  /**
   * Adds a property which tells if this Node is a RDS/Aurora Postgres DB.
   */
  get isPostgresDb(): boolean {
    return isPostgresDb(this.data.protectionSource);
  }

  /**
   * Check if the object is protected or not. For auto protected objects, some
   * of the children should be protected with the the parent as the
   * auto-protected entity.
   *
   * This is for case when a region is protected under the EC2 tab and then the
   * same region comes as protected under RDS tab.
   */
  get isObjectProtected() {
    const isObjectProtected = super.isObjectProtected;

    // For entities that can be autoprotected and is protected, check if the
    // children are mapped to the correct autoprotect parent id.
    if (isObjectProtected && this.canAutoSelect()) {
      return this.children?.some(node => node.objectProtectionInfo?.autoProtectParentId === this.id);
    }

    return isObjectProtected;
  }

  /**
   * A node can't be selected if its an agent based job and node doesn't have agent installed.
   * Moreover, in case of object based protection, only EC2, RDS and Aurora instances are
   * supported, and the selection should be uniform because of different configuration parameters.
   *
   * @param    currentSelection Current Selection.
   * @return   True if this node can be selected.
   */
  canSelect = (): boolean => !this.isNodeWithoutPhysicalAgent();

  /**
   * Return tooltip TranslationContext for node.
   *
   * @return  string  tooltip text to be displayed on checkbox hover
   */
  getCheckBoxToolTip(): TooltipContext {
    if (this.isNodeWithoutPhysicalAgent()) {
      return { translateKey: 'noPhysicalAgentInstalledMessage' };
    }
  }

  /**
   * Can this node be auto-protected?
   *
   * @return   True if this node can be autoSelected/auto-protected.
   */
  canAutoSelect(): boolean {
    if (this.workloadType === AwsLeafType.kS3 && this.type === AWSEntities.kIAMUser) {
      return false;
    }
    return (this.expandable || this.isTag) && !this.isUnSupportedJobType;
  }

  /*
   * Use this to indicate "no physical agent installed" message
   *
   * @return   True if this node can't be selected'.
   */
  get isInvalidCloudVm(): boolean {
    return !this.canSelect();
  }

  /**
   * The message to show for invalid VMs
   *
   * @return   String indicating why node can't be selected
   */
  get invalidCloudVmStateMessage(): InvalidCloudVmStateMessage {
    return (!this.canSelect() ? 'agentNotInstalled' : '');
  }

  /**
   * Using the conncetion we determine if the credentials provided are invalid.
   */
  get hasConnectionStateProblem(): boolean {
    return this.connectionState === 'kInvalidCredentials';
  }

  /**
   * Specifically for RDS/Aurora Postgres DB Instance, we can have mismatched/invalid
   * credentials provided. We populate the state in this connection info.
   *
   * TODO: Need to have the API response for this to work.
   */
  get connectionState(): ConnectionState {
    if (this.isPostgresDbInstance) {
      // return 'kInvalidCredentials' depending on how we get the info from the
      // API response.
    }

    return '';
  }

  // TODO: Since RDS Instance can be expanded. We need to disable it to show
  //       we need credentials to expand. Need to work with UX for this.
  // get expandable() {
  //   if (!this.isRdsPostgresDbInstance) {
  //     const children = this.children;
  //     return this.isRdsPostgresDbInstance || Array.isArray(children) && children.length > 0;
  //   }
  //   return true;
  // }

  /**
   * Validates if the node's jobType is not supported
   *
   * @returns True if list includes node jobType
   */
  get isUnSupportedJobType(): boolean {
    return [CloudJobType.kAgent as string].includes(this.jobType);
  }

  /**
   * For AWS nodes whose credentials are provided, we need to show
   * a tooltip with the info.
   */
  get moreInfoText(): string | null {
    // TODO: update YAML to remove 'any'
    // Only show this for RDS tab when credentials are provided for the node.
    if ((this.data as any).credentialList?.length && this.workloadType === 'kRDSInstance') {
      return 'awsDbCredentials.credentialsProvided';
    }

    // TODO: Add checks here if the credentials were not provided.
    // return 'awsDbCredentials.credentialsRequired';
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   *
   * If it's an RDS/Aurora Postgres Instance with no children,
   * we consider it as a leaf.
   */
  get isLeaf() {
    return this.isPostgresDbInstance ? !this.children?.length : AwsLeafTypes.includes(this.type as AwsLeafType);
  }

  /**
   * Set the current jobType
   *
   * @param jobType The current jobType
   */
  setJobType(jobType: string) {
    this.jobType = jobType;

    if (jobType === CloudJobType.kRDSSnapshotManager) {
      this.metadata.nodeIdentifierKey = CloudJobType.kRDSSnapshotManager;
    } else if (jobType === CloudJobType.kAuroraSnapshotManager) {
      this.metadata.nodeIdentifierKey = CloudJobType.kAuroraSnapshotManager;
    } else {
      delete this.metadata.nodeIdentifierKey;
    }
  }
}
