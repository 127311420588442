import { union } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { intersection } from 'lodash-es';
// Service: Job (Public API integration)

;(function(angular, undefined) {
  'use strict';

  angular.module('C.pubJobService', [
      'C.pubJobServiceFormatter',
      'C.pubSourceService',
    ])
    .service('PubJobService', JobServiceFn);

  function JobServiceFn(_, $http, PubJobServiceFormatter, PubSourceService,
    RemoteClusterService, SlideModalService, cUtils, API, ENV_GROUPS,
    JOB_GROUPS, OFFICE365_GROUPS, FEATURE_FLAGS, NgObjectServiceApi,
    SourceService, $q, NgPassthroughOptionsService) {

    var scheduleKeysToScriptMapping = {
      incrementalSchedulingPolicy: 'incrementalBackupScript',
      logSchedulingPolicy: 'logBackupScript',
      fullSchedulingPolicy: 'fullBackupScript',
    };

    return {
      createJob: createJob,
      editBackupParams: editBackupParams,
      getAssociatedJobs: getAssociatedJobs,
      getExcludeSourceIds: getExcludeSourceIds,
      getJob: getJob,
      getJobFromCluster: getJobFromCluster,
      getJobs: getJobs,
      getJobsByIds: getJobsByIds,
      getJobTree: getJobTree,
      getLeafCountByEnvironment: getLeafCountByEnvironment,
      getScheduleKeysToScriptMapping: getScheduleKeysToScriptMapping,
      jobHasDbSources: jobHasDbSources,
      modifyFileUpTiering: modifyFileUpTiering,
      updateJob: updateJob,
    };

    /**
     * getter method for accessing a hashmap of scheduleKeys to scriptKeys
     *
     * @method   getScheduleKeysToScriptMapping
     * @return   {Array}   a hashmap defining the relationship between between
     *                     scheduleKeys and scriptKeys
     */
    function getScheduleKeysToScriptMapping() {
      // return a copy so requesting code can't change original object
      return cUtils.simpleCopy(scheduleKeysToScriptMapping);
    }

    /**
     * calls the API to get all jobs or a subset of jobs based on provided
     * params
     *
     * @method   getJobs
     * @param    {object}   [params={}]   The parameters
     * @param    {object}   [headers]     The request headers
     * @return   {object}   a promise to resolve with API response, with
     *                      possible transformations
     */
    function getJobs(params, headers) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.public('protectionJobs'),
        params: params,
        headers: headers,
      }).then(
        function getJobsSuccess(resp) {
          return (resp.data || []).map(PubJobServiceFormatter.transformJob);
        }
      );
    }

    /**
     * Gets the jobs info for the provided job IDs.
     *
     * @method   getJobsByIds
     * @param    {Array}     jobIds   The job IDs.
     * @return   {promise}   returns a promise resolved with jobs or rejected
     *                       with error
     */
    function getJobsByIds(jobIds) {
      return getJobs({ids: jobIds});
    }

    /**
     * Gets the jobs info which are associated with provided sources, policies
     * and viewboxes
     *
     * @method   getAssociatedJobs
     * @param    {Array}    sourceIds        The source IDs
     * @param    {Array}    policyIds        The policy IDs
     * @param    {Array}    viewboxIds       The viewbox IDs
     * @param    {Array}    viewMagnetoIds   The linked magneto view IDs
     * @return   {promise}  returns a promise resolved with associated jobs or
     *                      rejected with error
     */
    function getAssociatedJobs(sourceIds, policyIds, viewboxIds, viewMagnetoIds) {
      return getJobs({policyIds: policyIds}).then(
        function gotJobs(jobs) {
          var sourceIdsMap = keyBy(sourceIds);
          var viewboxIdsMap = keyBy(viewboxIds);

          jobs = jobs.filter(function eachJob(job) {
            var jobHasProvidedViewbox = viewboxIdsMap[job.viewBoxId];

            // Check if the views are selected as the source and the job
            // corresponds to the selected view.
            var jobHasProvidedViewAsSource =
              !!intersection(viewMagnetoIds, job.sourceIds).length;

            // Include sources which are protected by current job.
            var sourceNotAssigned = job.sourceIds.concat(job.vmTagIds).some(
              function eachEntityId(entityId) {
                return !sourceIdsMap[entityId];
              }
            );

            return jobHasProvidedViewbox && (!sourceNotAssigned ||
              jobHasProvidedViewAsSource);
          });

          return jobs;
        }
      );
    }

    /**
     * get a specific job from the API
     *
     * @method   getJob
     * @param    {Integer}   id            of the job to request
     * @param    {Object}    [headers]     optional custom headers
     * @param    {Object}    [params]      Optional data migration job params.
     * @return   {Object}                  to resolve API request resolves with job object (or
     *                                     undefined if job not found) rejects with raw server
     *                                     response
     */
    function getJob(id, headers, params) {
      return $http({
        method: 'get',
        url: API.public('protectionJobs', id),
        params: params,
        headers: {
          ...NgPassthroughOptionsService.requestHeaders,
          headers,
        }
      }).then(
        function getJobSuccess(resp) {
          return PubJobServiceFormatter.transformJob(resp.data);
        }
      );
    }

    /**
     * Gets a Job from a particular cluster (via api proxy if necessary).
     *
     * @param {number} id the Job id to get.
     * @param {number} clusterId The cluster id to that the Job belongs to
     */
    function getJobFromCluster(id, clusterId) {
      return getJob(id, RemoteClusterService.getApiProxyHeader(clusterId));
    }

    /**
     * Submit an API request to create a new Job.
     *
     * @method   createJob
     * @param    {object}   job   Job configuration object
     * @return   {object}   promise to resolve request
     */
    function createJob(job) {
      return $http({
        method: 'post',
        url: API.public('protectionJobs'),
        data: PubJobServiceFormatter.untransformJob(job),
      }).then(
        function getJobsSuccess(resp) {
          return PubJobServiceFormatter.transformJob(resp.data);
        }
      );
    }

    /**
     * Submit an API request to update an existing Job.
     *
     * @method   updateJob
     * @param    {object}   job         Job configuration object.
     * @param    {object}   [headers]   Custom headers for API submission.
     * @return   {object}   Promise to resolve request.
     */
    function updateJob(job, headers) {
      return $http({
        method: 'put',
        url: API.public('protectionJobs', job.id),
        data: PubJobServiceFormatter.untransformJob(job),
        headers: headers,
      }).then(
        function getJobsSuccess(resp) {
          return PubJobServiceFormatter.transformJob(resp.data);
        }
      );
    }

    /**
     * Gets the Source tree for a given Job id, decorated with convenience
     * properties.
     *
     * @method   getJobTree
     * @param    {object}    job               The job
     * @param    {number}    [clusterId]       Optional clusterId that the Job
     *                                         belongs to. For use with API
     *                                         proxy sans cluster selection.
     * @param    {boolean}   [excludeLeaves=false]   Specifies whether leaves
     *                                               are to be fetched.
     * @return   {object}    Promise to resolve with the Source Tree, rejects
     *                       with raw server response
     */
    function getJobTree(job, clusterId, excludeLeaves) {
      var params = {};

      if (job.environment === 'kVMware') {
        params.includeVMFolders = true;
        params.excludeTypes = ['kResourcePool'];
      }

      // For Customers with more than 100k DBs, we support partial tree loading
      // for auto protection only. Load entire tree if manual selections are
      // done in the tree
      if (FEATURE_FLAGS.sqlAsyncMode && job.environment === 'kSQL') {
        params.numLevels = 2;
      }

      // Incase of the ngOffice365SourceTree, only the conatiners need to
      // fetched as the ngOffice365SourceTree is capable of pagination within
      // the leaf entities.
      // Incase of the protection job edit page, 'excludeLeaves' has to be
      // false as the job-objects component needs the whole EH.
      if (['kO365', 'kO365Outlook'].includes(job.environment) &&
        FEATURE_FLAGS.office365NgSourceTree && excludeLeaves) {
        params.excludeOffice365Types = OFFICE365_GROUPS.office365Leaves;
      }

      var options = {
        rootEnvironment: job.environment,
        clusterId: clusterId,
      };

      return PubSourceService.getOwnSource(
        job.parentSourceId, params, options).then(
          function getSourceSuccess(source) {
            /* These options will be updated by reference when passed to
             * transformTree(), and then will be resolved as part of the jobTree
             */
            var opts = {
              selectedCounts: {},
              totalCounts: {},

              /* To be used to make sourceIds as found in the tree. Keys will be
               * the Job's sourceIds and the value will indicated if the id is
               * missing from the tree. Will be updated by reference.
               */
              isSourceIdMissingHash: (job.sourceIds || []).reduce(
                function mapSourceIds(idHash, sourceId) {
                  // Assume the source is missing until proven otherwise.
                  idHash[sourceId] = true;
                  return idHash;
                },
                {}
              ),

              /* To be populated after the tree has been processed so
               * isSourceIdMissingHash will be a known accurate representation.
               * Will be updated by reference.
               */
              missingSourceIds: [],
              rootEnvironment: job.environment,
            };

            // If If SQL async mode is turned on and job has instance or db
            // selection, load those hosts entirely and merge with the partial
            // tree loaded for async mode. Used in case of edit sql job.
            if (FEATURE_FLAGS.sqlAsyncMode && job.environment === 'kSQL' &&
              (job.sourceIds[0] || job.sourceSpecialParameters[0])) {
              return getSqlAsyncJobTree(source, job, opts);
            } else {
              return {
                tree: PubJobServiceFormatter.transformTree(source, job, opts),
                selectedCounts: opts.selectedCounts,
                totalCounts: opts.totalCounts,
                missingSourceIds: opts.missingSourceIds,
              };
            }
          }
        );

    }

    /**
     * When SQL async mode is turned on, only host and instances are loaded.
     * This loads hosts which have protected nodes to show the
     * protected/auto-protected nodes. Loads rest of hosts when they are
     * interacted by user in the source tree.
     *
     * @method   getSqlAsyncJobTree
     * @param    {array}    source   The source
     * @param    {object}   job      The job
     * @param    {object}   opts     The options inclusive of rootEnviroment
     *                               and preventRootNodeSuppression.
     * @return   {object}   Promise to resolve with the Source Tree
     */
    function getSqlAsyncJobTree(source, job, opts) {
      // Collect the hosts which has protected nodes in the job
      var sourceIds = (job.sourceSpecialParameters || []).map(param => param.sourceId);
      sourceIds = union(job.sourceIds, sourceIds);

      // Get the hosts with all dbs and replace them with partial hosts in the
      // source tree. This helps in showing the protected nodes in the edit sql
      // job. Other hosts are not loaded with dbs.
      return PubSourceService.getSources(sourceIds).then(
        function getSourcesSuccess(hostsWithProtectedNodes) {
          // Replace the partial hosts with fully loaded hosts
          source[0].nodes = source[0].nodes.map(partialHost =>
            hostsWithProtectedNodes.find(host =>
              host.protectionSource.id === partialHost.protectionSource.id
            ) || partialHost
          );

          return {
            tree: PubJobServiceFormatter.transformTree(source, job, opts),
            selectedCounts: opts.selectedCounts,
            totalCounts: opts.totalCounts,
            missingSourceIds: opts.missingSourceIds,
          };
        }
      );
    }

    /**
     * Gets the number of leaf entities from a given counts hash by environment
     * type.
     *
     * @method   getLeafCountByEnvironment
     * @param    {string}    environment   The environment type
     * @param    {object}    counts        The Job objects count object
     * @return   {integer}   The number of leaf entities selected.
     */
    function getLeafCountByEnvironment(environment, counts) {
      var out = 0;

      // Missing required arguments: exit early
      if (!environment || !counts) {
        return out;
      }

      switch (environment) {
        case 'kAD':
          out = getLeafCountByEnvironment('kPhysical', counts);
          break;
        case 'kAWS':
        case 'kAWSNative':
        case 'kAWSSnapshotManager':
          out = counts.kEC2Instance;
          break;
        case 'kAzure':
        case 'kAzureNative':
        case 'kAzureSnapshotManager':
        case 'kGCP':
        case 'kGCPNative':
        case 'kVMware':
        case 'kHyperV':
        case 'kHyperVVSS':
        case 'kAcropolis':
        case 'kKVM':
          out = counts.kVirtualMachine;
          break;

        case 'kView':
          out = counts.kView;
          break;

        case 'kSQL':
          out = counts.kDatabase ||
            getLeafCountByEnvironment('kVMware', counts) ||
            getLeafCountByEnvironment('kPhysical', counts);
          break;

        case 'kOracle':
          out = counts.kDatabase;
          break;

        case 'kPhysical':
        case 'kPhysicalFiles':
          _getLeafEntitiesSupportingPhysicalJob().forEach(
            function aggregateLeaves(entityType) {
              out += counts[entityType] || 0;
            }
          );
          break;

        case 'kNimble':
        case 'kPure':
        case 'kNetapp':
          out = counts.kVolume;
          break;

        case 'kGenericNas':
          out = (counts.kHost || 0) + (counts.kDfsTopDir || 0);
          break;

        case 'kGPFS':
          out = counts.kFileset;
          break;

        case 'kIsilon':
          out = counts.kMountPoint;
          break;

        case 'kFlashBlade':
          out = counts.kFileSystem;
          break;

        case 'kO365':
        case 'kO365Outlook':
          // NOTE: Each Office365 server has only 1 Outlook entity which in
          // turn has multiple mailboxes.
          //
          // Within the EH, the user can always select Domain, Outlook or
          // Mailboxes(if present)
          //
          // Incase of Outlook containing mailboxes count greater than
          // iris_exec flag - 'iris_mailbox_discovery_threshold_count', the
          // iris_exec removes the kMailbox entities within Outlook without
          // traversing them hence mailbox selection is not permitted.
          //
          // Additionally, in either case, leaf entity is always kMailbox so as
          // to correctly populate protected/unprotected objects within
          // Office365 domain.
          if (counts.kOutlook || counts.kMailbox) {
            out = !!counts.kMailbox ? counts.kMailbox : counts.kOutlook;
            break;
          }

          // Support the new EH for Office365.
          // TODO(tauseef): Verify the same with the iris_exec flags for
          // maximum supported Users, groups and Sites.
          if (counts.kUsers || counts.kUser) {
            out += !!counts.kUser ? counts.kUser : counts.kUsers;
          }
          if (counts.kSites || counts.kSite) {
            out += !!counts.kSite ? counts.kSite : counts.kSites;
          }
          if (counts.kGroups || counts.kGroup) {
            out += !!counts.kGroup ? counts.kGroup : counts.kGroups;
          }
          break;

        case 'kRDSSnapshotManager':
          out = counts.kRDSInstance;
          break;

        case 'kKubernetes':
          out = counts.kNamespace;
          break;

        case 'kElastifile':
          out = counts.kContainer;
          break;
      }

      return out || 0;
    }

    /**
     * presents a slide modal for editing a given entities
     * custom backup params (exchange log truncation, volume selection, etc).
     *
     * @param      {object}  server  The server/entity
     * @param      {object}  job     The job
     * @return     {object}  promise object to resolve the slider
    */
    function editBackupParams(node, job) {
      return SlideModalService.newModal({
        templateUrl:
          'app/protection/jobs/modify2/server-options/server-options.html',
        controller: 'serverOptionsPubController',
        controllerAs: '$ctrl',
        resolve: {
          thisNode: function getNode() {
            // resolver function is needed since any modification from server
            // options modal should be reflected back to current scope.
            return node;
          },
          thisJob: function getJob() {
            return job;
          },
        }
      });
    }

    /**
     * Determines if the current job selection contains DB objects.
     *
     * @method   jobHasDbSources
     * @param    {object}    job   The job to check.
     * @return   {boolean}   True if DB objects found, false otherwise.
     */
    function jobHasDbSources(job) {
      return job._selectedSources.some(
        function locateDbSource(source) {
          return ENV_GROUPS.databaseSources
            .includes(source.protectionSource.environment);
        }
      );
    }

    /**
     * Returns the leaf entities supported for Physical block or file based
     * jobs.
     *
     * @method   _getLeafEntitiesSupportingPhysicalJob
     * @return   {[]string}   Array of leaf entities for Physical file/block
     *                        based job.
     */
    function _getLeafEntitiesSupportingPhysicalJob() {
      return JOB_GROUPS.leafEntitiesForPhysicalJob;
    }

    /**
     * Opens the create or edit up-tiering job modal.
     *
     * @method   modifyFileUpTiering
     * @param    {String}   jobInfo        The Job details.
     * @return   {Object}   Promise resolve on successfully modifying the ui-tiering job.
     */
    function modifyFileUpTiering(jobInfo) {
      var modalConfig = {
        size: 'md',
        keyboard: false,
        autoHeight: true,
        resolve: {
          innerComponent: 'fileUpTiering',
          idKey: 'modify-file-up-tiering',
          actionButtonKey: 'execute',
          titleKey: jobInfo.jobDescription._isDownTieringJob ? 'addUpTiering' : 'editUpTiering',
          bindings: {
            jobId: jobInfo.jobDescription.jobId,
          },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Find exclude source ids for the tree using the job source filters
     *
     * @method   getExcludeSourceIds
     */
    function getExcludeSourceIds(job) {
      return _getAutoProtectedIds(job).then(function getAutoProtectedIds(autoProtectIds) {
          // Conver private job filters to v2 filters
          const filters = job.sourceFilters.excludeSourceFilterVec.map(filter => {
            return {
              filterString: filter.sourceFilter,
              isRegularExpression: filter.isRegex
            };
          });

          const params = {
            filterType: 'exclude',
            filters,
            objectIds: autoProtectIds,
            applicationEnvironment: 'kSQL'
          };

          // Get excluded source ids by passing exclude filters
          return NgObjectServiceApi.FilterObjects({body: params}).toPromise()
            .then(function getFiltersSuccess(result) {
              return result.filteredObjects.map(node => node.id);
            });
        });
    }

    /**
     * Find auto protected source ids from the sources that are protected by the
     * job
     *
     * @method   _getAutoProtectedIds
     */
    function _getAutoProtectedIds(job) {
      var sourceIds = job.sources.map(source => source.entities[0].id);
      // No instances or dbs selected, only hosts are autoprotected
      if (!job.backupSourceParams) {
        return $q.resolve(sourceIds);
      }

      // Get the entities to check for entity type
      return SourceService.getEntitiesById(sourceIds)
        .then(function getEntitiesSuccess(sources) {
          return sources.reduce((acc, source) => {
            // If source is a host or an instance, return them as auto protected ids
            if (source.type === 6 || (source.type === 3 && source.sqlEntity.type === 0)) {
              acc.push(source.id);
            }
            return acc;
          }, [])
        });
    }
  }

}(angular));
