import { Injectable } from '@angular/core';
import { HeliosTaggingApiService, ListAssociatedTagsParams } from '@cohesity/api/helios-metadata';
import { chunk } from 'lodash-es';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * maximum number of snapshot/object ids supported by the API at a time
 */
const maxChunkSize = 200;

@Injectable({
  providedIn: 'root'
})
export class HmsTaggingWrapperService {

  constructor(
    private hmsService: HeliosTaggingApiService,
  ) { }

  /**
   * Fetch the associated tags in chunks for the cases where the input of snapshot/object ids is larger than the max
   * size supported by the api.
   *
   * @param param0 body for the list associated tags API
   * @returns list of associated tags
   */
  listAssociatedTags({ snapshotIds, globalObjectIds }: ListAssociatedTagsParams) {
    const chunks = chunk(snapshotIds || globalObjectIds || [], maxChunkSize);
    return forkJoin(
      chunks.map(idChunk => {
        const body: ListAssociatedTagsParams = snapshotIds ? {
          snapshotIds: idChunk,
        } : {
          globalObjectIds: idChunk,
        };
        return this.hmsService
          .listAssociatedTagsOp({
            body,
          });
      })
    ).pipe(
      map((responses) => responses.reduce((acc, r, index) => {
        if (index !== 0 && acc.snapshotTags) {
          acc.snapshotTags = [...acc.snapshotTags, ...r.snapshotTags].filter(Boolean);
        }

        if (index !== 0 && acc.globalObjectTags) {
          acc.globalObjectTags = [...acc.globalObjectTags, ...r.globalObjectTags].filter(Boolean);
        }
        return acc;
      }, responses[0])),
    );
  }
}
