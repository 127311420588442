/* tslint:disable */

/**
 * Specifies the DC scan method.
 */
export enum DcScanMethod {
  full = 'full',
  incremental = 'incremental',
  incremental_with_full = 'incremental_with_full',
  hyperscan = 'hyperscan'
}
