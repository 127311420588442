<mat-grid-list class="cog-dash-grid" cols="3"
  [rowHeight]="gridConfig.cyberVaultingRowHeight" [gutterSize]="gridConfig.gutterSize">
  <mat-grid-tile [colspan]="2" [rowspan]="summaryDashboardService.cardRowSpan$ | async">
    <cog-dashcard>
      <dg-sc-rpaas-data-card *ngIf="(summaryDashboardService.regionUsageLoading$ | async) === false; else spinner"
        [usageData]="summaryDashboardService.regionUsage$ | async"
        [showWarmSubscription]="summaryDashboardService.showWarmSubscription$ | async"
        [showColdSubscription]="summaryDashboardService.showColdSubscription$ | async"
        (gotoReport)="performGotoReport()">
      </dg-sc-rpaas-data-card>
    </cog-dashcard>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="summaryDashboardService.cardRowSpan$ | async">
    <cog-dashcard [title]="'vaultingActivity' | translate" class="activity-card">
      <ng-container *ngIf="(summaryDashboardService.topologyLoading$ | async) === false; else spinner">
        <ng-container *ngIf="summaryDashboardService.showWarmSubscription$ | async">
          <h2 class="activity-title">{{ 'warmVaults' | translate }}</h2>
          <dg-sc-rpaas-activities-card
            [activityStats]="summaryDashboardService.warmVaultsActivity$ | async"
            [regions]="summaryDashboardService.regionFilter$ | async"
            [dateRange]="summaryDashboardService.dateRangeFilter$ | async"
            (gotoVaultProtection)="gotoVaultProtection.emit($event)">
          </dg-sc-rpaas-activities-card>
        </ng-container>
        <ng-container *ngIf="summaryDashboardService.showColdSubscription$ | async">
          <h2 class="activity-title">{{ 'coldVaults' | translate }}</h2>
          <dg-sc-rpaas-activities-card
            [activityStats]="summaryDashboardService.coldVaultsActivity$ | async"
            [regions]="summaryDashboardService.regionFilter$ | async"
            [dateRange]="summaryDashboardService.dateRangeFilter$ | async"
            (gotoVaultProtection)="gotoVaultProtection.emit($event)">
          </dg-sc-rpaas-activities-card>
        </ng-container>
      </ng-container>
    </cog-dashcard>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="2" [rowspan]="3">
    <cog-dashcard [title]="'topology' | translate" class="topology-card">
      <dg-sc-rpaas-topology-card *ngIf="(summaryDashboardService.topologyLoading$ | async) === false; else spinner"
        [graphData]="summaryDashboardService.topologyData$ | async">
      </dg-sc-rpaas-topology-card>
    </cog-dashcard>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="3">
    <cog-dashcard [title]="'cloudVaultProtection' | translate">
      <dg-sc-rpaas-protection-card
        *ngIf="(summaryDashboardService.envArchivalStatsLoading$ | async) === false; else spinner"
        [envArchivalStats]="summaryDashboardService.envArchivalStats$ | async">
    </dg-sc-rpaas-protection-card>
    </cog-dashcard>
  </mat-grid-tile>
</mat-grid-list>

<ng-template #spinner><cog-spinner></cog-spinner></ng-template>
