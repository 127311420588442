import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: SNMP Service

;(function(angular, undefined) {
  'use strict';
  angular
    .module('C.snmp')
    .service('SNMPService', SNMPServiceFn);

  function SNMPServiceFn(_, $http, SNMP_CONST, API, cUtils) {

    // State object
    var state = _getInitialState();

    return {
      state: state,

      // Actions
      fetchConfig: fetchConfig,
      submitConfig: submitConfig,

      // Utils
      newSNMPConfig: newSnmpConfig,
      newSNMPUser: newSNMPUser,
    };

    /**
     * Get a new Snmp Config object
     *
     * @method    newSnmpConfig
     * @param     {object}   config   default config values
     * @returns   {object}            new snmp config object
     */
    function newSnmpConfig(config) {
      var defaultVersion = 'kSnmpV2';
      var snmpConfig = {
        version: defaultVersion,
        server: '',
        operation: 'kOperationDisable',
        trapUser: newSNMPUser(defaultVersion, 'kTrapUser'),
        agentPort: SNMP_CONST.defaultAgentPort,
        trapPort: SNMP_CONST.defaultTrapPort,
      };

      return assign(snmpConfig, config);
    }

    /**
     * new SNMP User object
     *
     * @method   newSNMPUser
     * @param    {string}      version     SNMP_CONST.version type, required
     * @param    {string}      userType    SNMP_CONST.userType type, required
     * @param    {object}      [user={}]   default values, optional
     * @returns  {object}                  SnmpUser for give version
     */
    function newSNMPUser(version, userType, user) {
      // Common for both V2 and V3
      var snmpUser = {
        userName: 'cohesityV2Public',
        userType: userType,
      };

      // V3 specific properties
      if (version === 'kSnmpV3') {
        assign(snmpUser, {
          userName: 'cohesityV3Public',
          authProtocol: 'kNone',
          authPassword: '',
          privProtocol: 'kNone',
          privPassword: '',
          securityLevel: '',
        });
      }

      return cUtils.assignValues(snmpUser, user || {});
    }

    /**
     * Initiates async fetch of snmp config.
     * Upon receiving data, it transforms it into object usable for view.
     *
     * @method    fetchConfig
     * @returns   {object}   Promise to return snmp config or error
     */
    function fetchConfig() {
      state.loading = true;
      return $http({
        method: 'get',
        url: API.private('snmp/config'),
      })
        .then(_prepareConfig)
        .then(_updateState)
        .finally(function fetchConfigDone() {
          state.loading = false;
        });
    }

    /**
     * post snmp config to server.
     *
     * @method     submitConfig
     * @param      {object}  config  The config object
     * @return     {object}          Promise to return updated config or error
     */
    function submitConfig(config) {
      var reqData = _prepareRequestData(config);

      state.submitting = true;

      return $http({
        method: 'put',
        data: reqData,
        url: API.private('snmp/config'),
      })
        .then(_prepareConfig)
        .then(_updateState)
        .finally(function postConfigDone() {
          state.submitting = false;
        });
    }

    /**
     * Returns a initial state
     *
     * @method    _getInitialState
     * @returns   {object}   initial store state
     */
    function _getInitialState() {
      var config = newSnmpConfig();
      return {
        config: config,
        hasConfig: false,
        isSNMPEnabled: false,
        loading: false,
        submitting: false,
      };
    }

    /**
     * Update state information based on config.
     * @method   _updateState
     * @param    {object}   config   The config object
     * @return   {object}            Config object for chaining
     */
    function _updateState(config) {
      var newState = {
        hasConfig: !!config,
        config: config || newSnmpConfig(),
        isSNMPEnabled: config && (
          config.operation === 'kOperationEnable'
        )
      };

      assign(state, newState);

      return newState.config;
    }

    /**
     * Transform the server's response when getting/setting an SNMP response
     *
     * @method     _prepareConfig
     * @param      {object}  resp    The server's response
     * @return     {object}          Snmp config object suitable for client
     */
    function _prepareConfig(resp) {
      // if there is no response, return false
      if (isEmpty(resp.data)) {
        return undefined;
      }

      // Copy properties from response to state config
      var config = assign(newSnmpConfig(), resp.data);

      // Users are handled differently in ui vs how they come from API
      SNMP_CONST.userKey.forEach(function setUser(user) {
        if (isEmpty(config[user])) {
          config[user] = undefined;
        } else {
          _setProtocols(config[user]);
        }
      });

      return config;
    }

    /**
     * Create request data for posting snmp config
     *
     * @method    _prepareRequestData
     * @param     {object}   config    snmp config object
     * @returns   {object}             valid server snmp config object
     */
    function _prepareRequestData(config) {
      var reqData = {};

      // For disable operation, use original state
      if (config.operation == 'kOperationDisable') {
        reqData = cloneDeep(state.config);
        reqData.operation = 'kOperationDisable';
      } else {
        reqData = cloneDeep(config);
      }

      SNMP_CONST.userKey.forEach(function copyUser(key) {
        var user = reqData[key];
        // set User's security level based on what auth/priv is selected
        if (user) {
          _setSecurityLevel(user);

          // backend doesn't have kNone, remove key
          if (user.authProtocol === 'kNone') {
            user.authProtocol = undefined;
          }

          if (user.privProtocol === 'kNone') {
            user.privProtocol = undefined;
          }
        }
      });

      return reqData;
    }

    /**
     * Sets security level for given user object
     *
     * @method    _setSecurityLevel
     * @param     {object}   user   object to set security level
     */
    function _setSecurityLevel(user) {
      switch (true) {
        case user.authProtocol !== 'kNone' &&
          user.privProtocol === 'kNone':
          user.securityLevel = 'kAuthNoPriv';
          break;

        case user.authProtocol !== 'kNone' &&
          user.privProtocol !== 'kNone':
          user.securityLevel = 'kAuthPriv';
          break;

        default:
          user.securityLevel = 'kNoAuthNoPriv';
          break;
      }
    }

    /**
     * Sets the protocols for user object based on its security level
     *
     * @method   _setProtocols
     * @param    {object}   user   snmp user
     */
    function _setProtocols(user) {
      switch (true) {
        case user.securityLevel == 'kNoAuthNoPriv':
          user.authProtocol = 'kNone';
          user.privProtocol = 'kNone';
          break;

        case user.securityLevel == 'kAuthNoPriv':
          user.privProtocol = 'kNone';
          break;
      }
    }
  }
})(angular);
