import { assign } from 'lodash-es';
// Component: cCarousel

;
(function (angular, undefined) {
  'use strict';

  angular
    .module('C.carousel', ['slickCarousel'])
    .component('cCarousel', {
      bindings: {

        // @type   {object []}   list of images links
        images: '<',

        /**
         * @type   string   prefix for image attribute.
         * @example
            @input
              <c-carousel
                image-alt-text="Application detail image"
              </c-carousel>

            @output
              <img alt="Application detail image">
        */
        imageAltText: '@?'
      },
      controller: cCarouselCtrlFn,
      templateUrl: 'app/global/c-carousel/c-carousel.html',
    });

  /**
   * @ngdoc component
   * @name C.carousel:cCarousel
   * @function
   *
   * @description
   * Reusable component provides a carousel for images
   *
   * @example
      <c-carousel
        images="imagesData"
      </c-carousel>
   */
  function cCarouselCtrlFn(_) {
    var $ctrl = this;

    $ctrl.slickConfig = {
      autoplay: false,
      infinite: true,
      method: {},
      arrows: false,
      centerMode: true,
      centerPadding: '15%',
      slidesToShow: 1
    };

    assign($ctrl, {
      lightBoxConfig: {
        autoplay: false,
        infinite: true,
        method: {},
        arrows: false,
      },
      showLightBox: false,
      slickConfigLoaded: true,

      // Methods
      openLightBox: openLightBox,
    })

    /**
     * Open image in light box overlay
     *
     * @method openLightBox
     * @param  {Number} index current active slide index
     */
    function openLightBox(index) {
      $ctrl.lightBoxConfig.method.slickGoTo(index);
      $ctrl.showLightBox = true;
    }
  }
})(angular);
