import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataTreeNodeContext } from '../data-tree-detail.directive';
import { DataTreeControl } from '../shared/data-tree-control';
import { DataTreeSelectionModel } from '../shared/data-tree-selection-model';
import { DataTreeSource } from '../shared/data-tree-source';
import { DataTreeNode } from '../shared/data-tree.model';

/**
 * This data source wraps a regular data tree source and maps rendered output to DataTreeNodeContext objects that can
 * be rendered directly inside of a mat table.
 */
export class DataTreeTableDataSource<T extends DataTreeNode<any>> implements DataSource<DataTreeNodeContext<T>> {
  /**
   * Data currently rendered by the tree table.
   */
  renderedData = new BehaviorSubject<DataTreeNodeContext<T>[]>([]);

  /**
   * Cache the context value for each node, When the list changes, this will compute the new context and compare it to
   * the cached version. If the objects are the same, it will return the cached version, this way the same object
   * instance will be used, which should reduce change detection overhead.
   */
  cachedNodeContext: {
    [id: string]: DataTreeNodeContext<T>;
  } = {};

  /**
   * The connection subscription
   */
  private subscription: Subscription = null;

  constructor(
    private treeSource: DataTreeSource<any>,
    private selection: DataTreeSelectionModel<T>,
    private treeControl: DataTreeControl<T>,
    private nodeDecoratorFn?: (ctx: DataTreeNodeContext<T>) => DataTreeNodeContext<T>
  ) {}

  /**
   * Connects the data source
   *
   * @param   collectionViewer   The connection viewer
   * @returns An observable of rendered table items.
   */
  connect(collectionViewer: CollectionViewer): Observable<DataTreeNodeContext<T>[]> {
    // combine the source and the changes, so that the tree will refresh when the selection changes.
    this.subscription = combineLatest([this.treeSource.connect(collectionViewer), this.selection.changes$])
      .pipe(
        map(([nodes]) => nodes.map((node: T) => {
          let ctx = {
            node: node,
            selection: this.selection,
            treeControl: this.treeControl,
            selected: this.selection.isSelected(node),
            autoSelected: this.selection.isAutoSelected(node),
            excluded: this.selection.isExcluded(node),
            ancestorAutoSelected: this.selection.isAncestorAutoSelected(node),
            ancestorExcluded: this.selection.isAncestorExcluded(node),
            canSelect: this.selection.canSelectNode(node),
            canAutoSelect: this.selection.canAutoSelectNode(node),
            canExclude: this.selection.canExcludeNode(node),
          };

          // Apply the node decorator function if it has been set, this gives components the ability to override
          // certain functions at a global level to alter the default functionality of the tree.
          ctx = this.nodeDecoratorFn ? this.nodeDecoratorFn(ctx) : ctx;
          if (!isEqual(ctx, this.cachedNodeContext[node.id])) {
            this.cachedNodeContext[node.id] = ctx;
          }
          return this.cachedNodeContext[node.id];
        })),
      )
      .subscribe(data => this.renderedData.next(data));
    return this.renderedData;
  }

  /**
   * Disconnects the data source.
   */
  disconnect() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
