import { Injectable, Injector } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeNode, DataTreeSelection, NavItem } from '@cohesity/helix';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateParams } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { RestorePointSelection } from 'src/app/modules/restore/restore-shared';
import { Environment, RouterTab } from 'src/app/shared';

import { AdObjectActionProvider } from './ad-object-action-provider';
import { AwsObjectActionProvider } from './aws-object-action-provider';
import { DbObjectActionProvider } from './db-object-action-provider';
import { HyperVObjectActionProvider } from './hyperv-object-action-provider';
import { KubernetesObjectActionProvider } from './kubernetes-object-action-provider';
import { O365ObjectActionProvider } from './o365-object-action-provider';
import { ObjectActionOptions } from './object-action-options.model';
import { ObjectActionProvider } from './object-action-provider';
import {
  isProviderWithContextData,
  MenuProviderWithContextData,
  ObjectMenuProvider,
  SimpleObjectInfo
} from './object-menu-provider';
import { PhysicalObjectActionProvider } from './physical-object-action-provider';
import { SfdcObjectActionProvider } from './sfdc-object-action-provider';
import { MongodbPhysicalObjectActionProvider } from './mongodb-physical-action-provider';
import { UdaObjectActionProvider } from './uda-object-action-provider';
import { SapHanaObjectActionProvider } from './saphana-object-action-provider';
import { ViewObjectActionProvider } from './view-object-action-provider';
import { VmwareObjectActionProvider } from './vmware-object-action-provider';
import { AzureObjectActionProvider } from 'src/app/modules/object-details-shared/azure-object-action-provider';
import { ActionContextData } from './object-actions-context-data.model';

/**
 * This class provides context and bulk menu actions for the source detail tree.
 * This service uses a delegate to provide actions based on the environment of the
 * selected object.
 */
@Injectable()
export class ObjectActionsMenuService implements ObjectMenuProvider {
  readonly supportsBulkActions = true;

  /**
   * A cache for menu providers keyed by environment type.
   */
  private delegateCache = new Map<Environment, ObjectMenuProvider>();

  /**
   * An injector configured to provide the necessary services.
   */
  private injector: Injector;

  constructor(parentInjector: Injector) {
    this.injector = Injector.create({
      providers: [
        {
          provide: AwsObjectActionProvider,
          deps: AwsObjectActionProvider.awsObjectActionProviderDependencies,
        },
        {
          provide: AzureObjectActionProvider,
          deps: AzureObjectActionProvider.azureObjectActionProviderDependencies,
        },
        {
          provide: ObjectActionProvider,
          deps: ObjectActionProvider.objectActionProviderDependencies,
        },
        {
          provide: VmwareObjectActionProvider,
          deps: VmwareObjectActionProvider.vmwareObjectActionProviderDependencies,
        },
        {
          provide: DbObjectActionProvider,
          deps:  DbObjectActionProvider.dbObjectActionProviderDependencies,
        },
        {
          provide: ViewObjectActionProvider,
          deps:  ViewObjectActionProvider.viewObjectActionProviderDependencies,
        },
        {
          provide: KubernetesObjectActionProvider,
          deps: KubernetesObjectActionProvider.kubernetesObjectActionProviderDependencies,
        },
        {
          provide: PhysicalObjectActionProvider,
          deps: PhysicalObjectActionProvider.physicalObjectActionProviderDependencies,
        },
        {
          provide: AdObjectActionProvider,
          deps: AdObjectActionProvider.adObjectActionProviderDependencies,
        },
        {
          provide: O365ObjectActionProvider,
          deps: O365ObjectActionProvider.o365ObjectActionProviderDependencies,
        },
        {
          provide: HyperVObjectActionProvider,
          deps: HyperVObjectActionProvider.hyperVObjectActionProviderDependencies,
        },
        {
          provide: SfdcObjectActionProvider,
          deps: SfdcObjectActionProvider.sfdcObjectActionProviderDependencies,
        },
        {
          provide: UdaObjectActionProvider,
          deps: UdaObjectActionProvider.udaObjectActionProviderDependencies,
        },
        {
          provide: SapHanaObjectActionProvider,
          deps: SapHanaObjectActionProvider.sapHanaObjectActionProviderDependencies,
        },
        {
          provide: MongodbPhysicalObjectActionProvider,
          deps: MongodbPhysicalObjectActionProvider.MongodbPhysicalObjectActionProviderDependencies,
        },
      ],
      parent: parentInjector,
    });
  }

  /**
   * Looks up the action provider based on the environment type.
   *
   * @param   environment   The object's environment.
   * @returns A menu provider implementation.
   */
  getDelegate(environment: Environment): ObjectMenuProvider {
    if (!this.delegateCache.has(environment)) {
      let provider: ObjectActionProvider;

      switch (environment) {
        case Environment.kVMware:
          provider = this.injector.get(VmwareObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kAWS:
          provider = this.injector.get(AwsObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kAzure:
          provider = this.injector.get(AzureObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kSQL:
        case Environment.kOracle:
          provider = this.injector.get(DbObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kView:
          provider = this.injector.get(ViewObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kKubernetes:
          provider = this.injector.get(KubernetesObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kPhysicalFiles:
        case Environment.kPhysical:
          provider = this.injector.get(PhysicalObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kAD:
          provider = this.injector.get(AdObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kO365:
          provider = this.injector.get(O365ObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kHyperV:
          provider = this.injector.get(HyperVObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kSfdc:
          provider = this.injector.get(SfdcObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kUDA:
          provider = this.injector.get(UdaObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

        case Environment.kSAPHANA:
          provider = this.injector.get(SapHanaObjectActionProvider);
          this.delegateCache.set(environment, provider);
          break;

          case Environment.kMongoDBPhysical:
            provider = this.injector.get(MongodbPhysicalObjectActionProvider);
            this.delegateCache.set(environment, provider);
            break;

        default:
          this.delegateCache.set(environment, this.injector.get(ObjectActionProvider));
      }
    }

    return this.delegateCache.get(environment);
  }

  getObjectActions(
    object: SimpleObjectInfo,
  ): Observable<NavItem[]> {
    return this.getDelegate(object.environment as Environment).getObjectActions(object);
  }

  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    if (!objects || !objects.length) {
      return of([]);
    }
    return this.getDelegate(objects[0].environment as Environment).getBulkObjectActions(objects);
  }

  getBulkTreeObjectActions(
    selection: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>,
    sourceSelection: SourceSelection,
    objectOptions?: ObjectActionOptions
  ): Observable<NavItem[]> {
    if (!selection || (!selection.selected.length && !selection.autoSelected.length)) {
      return of([]);
    }

    const object = selection.selected.length ? selection.selected[0] : selection.autoSelected[0];
    return this.getDelegate(object.data.protectionSource.environment as Environment).getBulkTreeObjectActions(
      selection,
      sourceSelection,
      objectOptions
    );
  }

  /**
   * Gets a list of tabs for different type of Storages.
   *
   * @param   stateParams   The router state paramaters
   * @param   newWorkload   The new workload
   * @returns Any applicable tab that is supported
   */
  getParentTabs(stateParams: StateParams, newWorkload?: string): RouterTab[] {
    return this.getDelegate(stateParams.environment as Environment).getParentTabs ?
      this.getDelegate(stateParams.environment as Environment).getParentTabs(stateParams, newWorkload) : [];
  }

  /**
   * Determines if Csm based backup is supported.
   *
   * @returns  True if Csm based backup is supported.
   */
   isCsmBasedBackupEnabled(env: string): boolean {
    return this.getDelegate(env as Environment).isCsmBasedBackupEnabled &&
      this.getDelegate(env as Environment).isCsmBasedBackupEnabled();
   }

  /**
   * For a given restore point, provide relevant actions.
   *
   * @param object The protected object.
   * @param restorePointSelection The Restore point selection of the selected object.
   */
  getRestorePointActions(object: SimpleObjectInfo, restorePointSelection: RestorePointSelection) {
    return this.getObjectActions({
      ...object,
      restorePointSelection: restorePointSelection || object.restorePointSelection,
    });
  }

  /**
   * Sets additional context information to determine available object actions for services that implement the method
   *
   * @param contextData The context data
   */
  setActionsContextData<T>(contextData: ActionContextData<T>): void {
    const delegate = this.getDelegate(contextData.environment);
    if(isProviderWithContextData(delegate)) {
      (delegate as MenuProviderWithContextData<T>).setContextData(contextData.data);
    }
  }

  /**
   * Returns additional context information to determine available object actions
   *
   * @param environment The context environment
   */
  getActionsContextData<T>(environment: Environment): Observable<T> {
    const delegate = this.getDelegate(environment);
    return isProviderWithContextData(delegate) ? (delegate as MenuProviderWithContextData<T>).contextData$ : null;
  }

}
