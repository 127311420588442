import { debounce } from 'lodash-es';
import { mapKeys } from 'lodash-es';
import { map } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: cSnapshotsCalendar

;(function(angular, undefined) {
  'use strict';

  var componentName = 'cSnapshotsCalendar';
  var componentConfig = {
    bindings: {
      /**
       * ID of the entity to view snapshots calendar for.
       *
       * @type  {number}
       */
      entityId: '<',

      /**
       * This Entity's Environment, ie kVMware, kSQL, kOracle etc.
       *
       * @type   {string|number}
       */
      entityEnvironment: '@?',

      /**
       * ID attribute of this component. Used internally as a unique seed for
       * other IDs to aid automation.
       *
       * @type  {*}
       */
      // id: '@',

      /**
       * One or more UIDs of the Protection Job(s) that we want snapshots from.
       *
       * @type  {number|number[]}
       */
      jobUids: '<',

      /**
       * Optionally hide the selected date display.
       *
       * @type  {boolean}
       */
      displaySelectedDate: '<?',

      /**
       * Optional list of remote Job Ids. This must be 1:1 parity with jobUids,
       * if specified. This is used for replication scenarios.
       */
      remoteJobIds: '<?',

      /**
       * Timezone.
       *
       * @type  {string}
       */
      timezone: '<?',

      /**
       * snapshotsDate
       */
      snapshotsDate: '=',
    },
    controller: 'SnapshotsCalendarCtrl',
    templateUrl: 'app/global/c-snapshots-selector/c-snapshots-calendar.html',
  };


  /**
   * @ngdoc component
   * @name C.snapshotSelection:cSnapshotsCalendar
   *
   * @description
   *   Calendar display of the snapshots/job runs for a given entity.
   *   Model output is the calendar day of snapshot(s), and not a snapshot time
   *   itself.
   *
   * @example
       <c-snapshots-calendar
         id="instance-id"
         entity-id="1"
         job-uids="::vmDocument.objectId.jobUid"
         display-selected-date="false"
         snapshots-date="$ctrl.snapshotsDate">
       </c-snapshots-calendar>
   */
  angular
    .module('C.snapshotSelection')
    .controller('SnapshotsCalendarCtrl', cSnapshotsCalendarCtrlFn)
    .component(componentName, componentConfig);

  function cSnapshotsCalendarCtrlFn(
    _, $scope, $attrs, evalAJAX, $log, SnapshotSelectionService,
    DateTimeService) {

    var $ctrl = this;
    var dependenciesMet = true;

    // User has a choice to pass the timezone. If not then we default it to the
    // current browser timezone.
    $ctrl.timezone = $ctrl.timezone || moment.tz.guess();

    assign($ctrl, {
      // Component lifecycle hooks
      $onInit: $onInit,
      $onChanges: $onChanges,
    });

    /**
     * Init this component.
     *
     * @method   $onInit
     */
    function $onInit() {
      if (!$ctrl.entityId) {
        dependenciesMet = false;
        return $log.error('"'+ componentName +
          '" requires an `entityId` attribute to work.');
      }

      assign($ctrl, {
        id: $attrs.id,
        shortDateFormat: DateTimeService.getLongDateFormat(),
        calendarDate: moment($ctrl.snapshotsDate).tz($ctrl.timezone).toDate(),
        onSelectDate: onSelectDate,
      });

      if (!$ctrl.id) {
        $log.warn(
          'Please define an `id` attribute for "' + componentName + '".',
          $ctrl
        );
      }

      // When the uibDatepicker month changes, reset the internal dates range to
      // match for dependencies re-fetch.
      $scope.$on('uibDaypicker.activeDateChanged',
        // Since we're now fetching PIT info for visible dates on the calendar
        // only, we can't let the User navigate with the month picker any
        // longer. As a compromise to reduce load on Magneto
        // (pointsForTimeRange) and let the user still page across months at a
        // reasonable speed, we debounce this to let them click across months
        // until they've found the one they want. Then make the re-fetch
        // requests.
        debounce(function onCalendarChange(event, newDate) {
          _configNewDates(newDate);
        }, 500)
      );
    }

    /**
     * Handles changes made to the selected calendar date.
     *
     * @function   onSelectDate
     */
    function onSelectDate() {
      // This is the 12:00am for the selected timezone
      var dateFromCalendar = moment($ctrl.calendarDate).clone()
        .startOf('day').format(DateTimeService.getDateTimeInputFormat());
      $ctrl.snapshotsDate = moment.tz(dateFromCalendar, $ctrl.timezone)
        .valueOf();
    }

    /**
     * Handle one-way bindings updates.
     *
     * @method   $onInit
     * @param    {object}   changes   Map of bindings with previous and current
     *                                bindings values.
     */
    function $onChanges(changes) {
      if (!dependenciesMet &&
        (!$ctrl.jobId || !$ctrl.jobUids) &&
        !$ctrl.entityId) { return; }

      assign($ctrl, {
        _jobUids: [].concat($ctrl.jobUids)
          .map(function jobUidCorrector(jobUid) {
            return mapKeys(jobUid, function mapper(val, key) {
              return key === 'objectId' ? 'id' : key;
            });
          }),
      });

      // Required to initialize uibdatepicker to initial properties.
      assign($ctrl, SnapshotSelectionService.resetCalendarCache());

      _configNewDates($ctrl.snapshotsDate);
    }

    /**
     * Sets up new dates for the calendar and fetches updated dependencies if
     * the selected date is out of range of the currently visible dates.
     *
     * @function   _configNewDates
     * @param      {Date}   newDate   MomentJS compatible date thingy.
     */
    function _configNewDates(newDate) {
      var mNewDate = moment(newDate);
      var isOutOfRange = true;

      if (!!$ctrl.selectableVisibleDates) {
        isOutOfRange = mNewDate.isBefore($ctrl.selectableVisibleDates[0]) ||
          mNewDate.isAfter($ctrl.selectableVisibleDates[1]);
      }

      // Fetch dependencies if selectedDate is out of range of currently
      // visible dates.
      if (isOutOfRange) {
        _setVisibleDates(newDate);
        _fetchDependencies();
      }
    }

    /**
     * Sets the visible dates controller property.
     *
     * @function  _setVisibleDates
     */
    function _setVisibleDates(newDate) {
      // Array of first and last selectable visible dates.
      // As it's a calendar view, the selectable dates are the whole month.
      $ctrl.selectableVisibleDates = [
        moment(newDate).startOf('month').toDate(),
        moment(newDate).endOf('month').toDate(),
      ];
    }

    /**
     * Fetch dependencies for this widget.
     *
     * @function   _fetchDependencies
     */
    function _fetchDependencies() {
      _setLoadingBool(false);
      SnapshotSelectionService
        .getCalendarDependencies({
          entityId: $ctrl.entityId,
          entityEnvironment: $ctrl.entityEnvironment,
          jobUids: $ctrl._jobUids,
          remoteJobIds: $ctrl.remoteJobIds,
          timezone: $ctrl.timezone,
          dates: $ctrl.selectableVisibleDates,
        })
        .then(function handleDepsResponses(resp) {
          // Assign the pre-prepared results into the controller.
          assign($ctrl, resp);
        }, evalAJAX.errorMessage)
        .finally(_setLoadingBool.bind(null, true));
    }

    /**
     * Sets the loading boolean.
     *
     * @method   _setLoadingBool
     * @param    {boolean}   [isLoading]   The truthy/falsey value to set.
     */
    function _setLoadingBool(isLoading) {
      $ctrl.depsLoaded = !!isLoading;
    }
  }

})(angular);
