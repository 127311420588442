import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { appConfigMap, getEffectiveAppConfig, isSizerUser } from '@cohesity/iris-core';
import { UIRouterModule } from '@uirouter/angular';

import { AppModuleConfig, CanAccess } from '../app-module.config';
import { AppStateDeclaration } from '../core';

const viewAccess: CanAccess = ctx => isSizerUser(ctx.irisContext);
const appConfig = getEffectiveAppConfig(appConfigMap.sizer);

/**
 * A placeholder component for the UI router.
 */
@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush, })
class DummyComponent {}

/**
 * The application states.
 */
export const SizerStates: AppStateDeclaration[] = [
  {
    name: 'sizer',
    url: `^${appConfig.urlSegment}`,
    title: 'Sizer',
    redirectTo: 'sizer.home',
  },
  {
    name: 'sizer.home',
    url: `^${appConfig.urlSegment}/:slug`,
    title: 'Sizer',
    params: {
      slug: {
        type: 'string',

        // enables ui-router params to accept `/`
        raw: true,

        // default page for sizer app
        value: '',

        // prevent slug changes from re-rendering the page because
        // internal routes are handled by the app.
        dynamic: true
      }
    },
    component: DummyComponent,
    data: { hideAppFrame: true },
  },
];

/**
 * Defines the Sizer app module. This is used as a placeholder module
 * to load the Sizer SPA within Iris.
 */
@NgModule({
  declarations: [DummyComponent],
  imports: [UIRouterModule.forChild({ states: SizerStates })],
})
export class SizerAppModule {}

/**
 * The application module config object.
 */
export const SizerAppConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'sizer.**',
      url: appConfig.urlSegment,
      loadChildren: () => SizerAppModule,
    },
  ],
  allClusterMap: {
    'sizer': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: 'dashboard',
      allClustersState: 'sizer',
    },
    'sizer.home': {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: 'dashboard',
      allClustersState: 'sizer.home',
    },
  },
  getStateAccessMap() {
    return {
      'sizer': viewAccess,
      'sizer.home': viewAccess,
    };
  }
};
