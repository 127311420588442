import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// MODULE: Physical Server Registration/Edit
// NOTE: cSlideModal support currently only works for registering a new Source

// TODO: Rename file and controller if there is a better generalized name for
// all physical source types.
;(function(angular, undefined) {
  'use strict';

  angular.module('C.sources')
    .controller('physicalModifyController', physicalModifyControllerFn);

  function physicalModifyControllerFn(_, StateManagementService, $scope, $q,
    $state, evalAJAX, cMessage, SourceService, $uibModalInstance, $filter,
    FORMATS, FEATURE_FLAGS, ENUM_HOST_TYPE_CONVERSION, connectionId, UserService) {

    var isSqlCluster = $state.current.name.includes('sql-cluster');

    var defaultServerConfig = {
      entity: {
        // Physical
        type: 6,
        physicalEntity: {
          // name will match endpoint
          name: undefined,

          // Physical host (1) or SQL CLuster (2)
          type: isSqlCluster ? 2 : 1,

          // 0 = Linux, 1 = Windows
          hostType: 1,

        }
      },
      entityInfo: {
        endpoint: undefined,
        type: 6,

        // 0 = Linux, 1 = Windows
        hostType: 1,
      },
      sourceSideDedupEnabled: true,
      forceRegister: angular.isDefined($state.params.forceRegister),

      // Encapsulates additional settings specified by user.
      // Refer RegisteredEntityParams within magneto/base/magneto.proto for
      // details.
      registeredEntityParams: {
        vlanParams: undefined,
        throttlingPolicy: {
          isThrottlingEnabled: false,
        },
      },
    };

    $scope.FORMATS = FORMATS;

    $scope.isSqlCluster = isSqlCluster;
    $scope.isOracleHost = $state.params.env === 'kOracle';

    // Whether the component is in edit mode.
    $scope.isEditMode = !!$state.params.id;

    /**
     * initialization/activation function
     *
     * @method     activate
     */
    function activate() {
      $scope.inModal = typeof $uibModalInstance === 'object';

      getServer().then(
        function getServerSuccess(server) {
          $scope.physicalServer = server;
        },
        evalAJAX.errorMessage
      ).finally(
        function getServerFinally() {
          $scope.physicalReady = true;
        }
      );

    }

    $scope.downloadAgent = SourceService.downloadAgentsModal;

    /**
     * This proto requires some properties to have duplicate values, when the
     * form exposed versions are updated by the user this function will be
     * called via ng-change, and all such properties will be synced as
     * appropriate
     *
     * @method     syncDuplicateProperties
     */
    $scope.syncDuplicateProperties = function syncDuplicateProperties() {
      $scope.physicalServer.entity.physicalEntity.hostType =
        $scope.physicalServer.entityInfo.hostType;
      $scope.physicalServer.entity.physicalEntity.name =
        $scope.physicalServer.entityInfo.endpoint;
    };

    /**
     * Cancel the flow/modal
     *
     * @method     cancel
     */
    $scope.cancel = function cancel() {

      if ($scope.inModal) {
        $uibModalInstance.dismiss('user.cancel');
        return;
      }

      redirect();
    };

    /**
     * handles form submission
     *
     * @method     submitForm
     * @param      {object}  theForm  the angular form object being submitted
     * @return     {object|undefined}  if in modal and form valid, returns
     *                                 $uibModalInstance.close otherwise
     *                                 undefined
     */
    $scope.submitForm = function submitForm(theForm) {

      var actionFn = $scope.isEditMode ?
        SourceService.updateSource : SourceService.createSource;

      if (theForm.$invalid) {
        return;
      }

      $scope.submitting = true;

      _maybeConfigureVLANParams();

      _assignConnectionId();

      actionFn($scope.physicalServer).then(
        function actionFnSuccess(source) {

          if ($scope.inModal) {
            return $uibModalInstance.close(source);
          }

          cMessage.success({
            textKey: $scope.isEditMode ?
              'physical.success.editText' : 'physical.success.newText',
            textKeyContext: $scope.physicalServer,
          });

          redirect();
        },
        evalAJAX.errorMessage
      ).finally(
        function actionFnFinally() {
          $scope.submitting = false;
        }
      );
    };

    /**
     * Whether to show the throttling controls.
     *
     * @method   showThrottlingControls
     * @param    {string}  type  cpu or network.
     * @returns  True if the throttling control should be shown.
     */
    $scope.showThrottlingControls = function showThrottlingControls(type) {

      // List of agent supported features
      var supportedFeatures = get($scope.physicalServer,
        'entity.agentEntity.supportedFeatures');

      // Physical entity host
      var hostType = get($scope.physicalServer,
        'entity.physicalEntity.hostType');
      var isFeatureSupported = false;

      if (supportedFeatures) {
        switch(type) {
          case 'cpu':
            isFeatureSupported =
              supportedFeatures.includes('source_cpu_throttling') &&

              // CPU throttling is only supported for Windows
              hostType === ENUM_HOST_TYPE_CONVERSION.kWindows;
            break;
          case 'network':
            isFeatureSupported =
              supportedFeatures.includes('source_network_throttling') &&

              // Network throttling is supported for Windows and linux hosts.
              [ENUM_HOST_TYPE_CONVERSION.kWindows, ENUM_HOST_TYPE_CONVERSION.kLinux]
                .includes(hostType);
            break;
        }
      }

      return FEATURE_FLAGS.physicalSourceThrottling &&
        $scope.isEditMode &&
        isFeatureSupported;
    };

    /**
     * Clears the threshold value on toggling off.
     *
     * @method  onToggleThrottling
     * @param   {string}  type  cpuThrottlingConfig or networkThrottlingConfig.
     */
    $scope.onToggleThrottling = function onToggleThrottleBandwidth(type) {
      var throttlingConfig = get($scope.physicalServer,
        'registeredEntityParams.physicalParams.sourceThrottlingConfig');
      if (throttlingConfig[type] && throttlingConfig[type].patternType === 0) {
        throttlingConfig[type].fixedThreshold = undefined;
      }
    }

    /**
     * Gets the Physical Servers object, either via API call or copying
     * the default object for new registration...
     *
     * @method     getServer
     * @return     {object}  resolves with vCenter object, or rejects with raw
     *                        server response
     */
    function getServer() {
      var deferred = $q.defer();
      var params;

      if ($state.params.id) {
        // this is an edit, we need to get
        // the source form the API

        params = {
          onlyReturnOneLevel: true,
          entityId: [$state.params.id]
        };

        SourceService.getSources(params).then(
          function getSourceSuccess(resp) {

            var entityHierarchy = resp.entityHierarchy;

            // it is necessary to rebuild/rearrange the server object,
            // as the backend response structure does not match up properly
            // with the necessary create/update object structure.
            var theServer = {
              entity: entityHierarchy.entity,
              entityInfo: entityHierarchy.registeredEntityInfo.connectorParams,
              sourceSideDedupEnabled:
                entityHierarchy.registeredEntityInfo.sourceSideDedupEnabled,
              throttlingPolicy:
                entityHierarchy.registeredEntityInfo.throttlingPolicy || {},
              registeredEntityParams:
                entityHierarchy.registeredEntityInfo.registeredEntityParams || {},
            };

            deferred.resolve(theServer);
          },
          deferred.reject
        );

      } else {
        // this is a new registration
        deferred.resolve(angular.copy(defaultServerConfig));
      }

      return deferred.promise;
    }

    /**
     * handles redirecting user to the proper place on cancel or submit
     *
     * @method     redirect
     */
    function redirect() {
      StateManagementService.goToPreviousState('sources-new');
    }

    /**
     * Sets the properties within registration arg - '$scope.physicalServer'
     * for VLAN to be used for backup and restore for this physical entity.
     *
     * @method   _maybeConfigureVLANParams
     */
    function _maybeConfigureVLANParams() {
      var vlanParams = get($scope.physicalServer, 'registeredEntityParams.vlanParams');
      if (vlanParams) {
        assign($scope.physicalServer.registeredEntityParams.vlanParams, {
          // Below properties are needed by iris_exec to correctly inform
          // Magneto of a VLAN user has preferred.
          vlanId: get(vlanParams, 'id'),
          interfaceName: $filter('ifaceGroupName')(get(vlanParams,
            'ifaceGroupName')),
        });
      }
    }

    /**
     * Assigns Bifrost Connection id with the source.
     */
    function _assignConnectionId() {
      // do not change source details pertaining
      // to connection in case of edit
      if ($scope.isEditMode) {
        return ;
      }

      // create case
      const defaultConnectionId = UserService.getDefaultBifrostConnectionId();
      const assignConnectionId = connectionId ? connectionId : defaultConnectionId;

      if (assignConnectionId) {
        $scope.physicalServer.connectionId = assignConnectionId;
      }
    }

    activate();
  }

}(angular));
