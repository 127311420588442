import {
  enumGroupMap,
  envGroups,
  Environment,
  hadoopEnvironments,
  noSqlEnvironments,
} from '@cohesity/iris-shared-constants';

import { DataByGroup } from '../shared/constants/env.constants';

/**
 * This function takes an array of envrionment objects and sum the data for
 * environments under the same group and returns an array of environment group
 * objects sorted in descending order by the aggregated data.
 *
 * @param    data           Array of objects that contains environment data
 * @param    attributeName  Attribute name of environment data object that contains data to be aggregated
 * @param    parent         Value of parent attribute of return environment group data
 * @param    size           If size is defined, remaining group data will be aggregated into one group,
 *                          returning data with size + 1
 * @returns  Aggregated data by envrionment group in descending order
 *
 * @example
 *   reduceByGroup(data, 'numObjectsMetSla', 'metSla', 3)
 */
export function reduceByGroup(data: any[], attributeName: string, parent?: string, size = 0): DataByGroup[] {
  const values = {};

  if (!data || !data.length) {
    return [];
  }

  // Calculates the sum of data for environments belong to the same group
  data.forEach(item => {
    const group = enumGroupMap[item.environment] || 'unknown';
    values[group] = (values[group] ? values[group] : 0) + item[attributeName];
  });

  // Change indexed data to array of objects and then sort it
  const result = Object.keys(values).map(group => ({
    parent,
    id: group,
    value: values[group]
  })).sort((a, b) => a.value > b.value ? -1 : 1);

  // If size is specified, group the remaining data into 'other' group
  if (size && result.length > size) {
    result.splice(size, result.length - size, {
      parent,
      id: 'other',
      value: result.slice(size).reduce((sum, item) => (sum + item.value), 0)
    });
  }
  return result;
}


/**
 * Converts a host specific path to unix path.
 *
 * @param path      host specific path
 * @param isWindows if path is windows path
 * @returns         unix path
 */
export function convertToUnixPath(path: string, isWindows: boolean) {
  let unixPath = path;
  if (isWindows) {
    // In case of windows replace the backslashes to forward slashes,
    // As magneto only accepts forward slashes.
    unixPath = unixPath.replace(/\\/g, '/');

    // Change the c: to /c as Magneto accepts only UNIX path
    if (unixPath.charAt(1) === ':') {
      unixPath = unixPath.replace(/:/, '');

      if (unixPath.charAt(0) !== '/') {
        unixPath = '/' + unixPath;
      }
    }
  }

  // '//' occurs when we have a volume name as '/' so ideally the path
  // is correct (ex: //home/). In these cases magneto requires the extra
  // slash to be removed so that it searches within root - '/'
  return unixPath.replace(/\/\//g, '/');
}

/**
 * Transforms a windows path which is stored as a unix path in magneto
 * to windows style path with backslashes and : for display purpose
 *
 * @param     path  unix path that needs to be converted to windows path
 * @returns         windows path
 * @example         transformToWindowsPath('/C/Users') returns 'C:\Users'
 */
export function transformToWindowsPath(path: string) {
  if (!path) {
    return path;
  }

  let windowsPath = path.replace(/\//g, '\\');

  if (windowsPath.charAt(0) === '\\' && windowsPath.charAt(2) === '\\') {
    windowsPath = windowsPath.replace(/\\/, '');
    windowsPath = windowsPath.slice(0, 1) + ':' + windowsPath.slice(1);
  }
  return windowsPath;
}

/**
 * Converts a unix path to windows path if host type is windows.
 *
 * @param path      unix style path
 * @param isWindows if path is windows path
 * @returns         os specific path
 */
export function convertToHostSpecificPath(path: string, isWindows: boolean) {
  if (isWindows) {
    return transformToWindowsPath(path);
  }
  return path;
}

/**
 * Checks if the environment is a relational database.
 * (This consists of all the environment in envGroups.dbs list
 * except for those part of the noSqlEnvironments list, UDA & SapHana)
 *
 * @param   environment   Environment to check for.
 * @returns True if environment is a relational database. False otherwise.
 */
export function isRelationalDatabase(environment: Environment): boolean {
  return envGroups.dbs.includes(environment) &&
    !noSqlEnvironments.includes(environment) &&
    environment !== Environment.kUDA &&
    environment !== Environment.kSAPHANA;
}

/**
 * Checks if the environment is a NoSql or Hadoop environment.
 *
 * @param   environment  Environment to check for.
 * @returns              true if the envionment is a NoSql or Hadoop Environment.
 */
export function isNoSqlHadoopSource(environment: Environment): boolean {
  return hadoopEnvironments.includes(environment) || noSqlEnvironments.includes(environment);
}
