import { some } from 'lodash-es';
import { isArray } from 'lodash-es';
import { find } from 'lodash-es';
import { get } from 'lodash-es';
// Service: JobBackupNowDbService contains methods that will assist with
// managing backup now runs for sql jobs.

(function _iife(angular) {
  'use strict';

  angular
    .module('C.backupNow')
    .service('JobBackupNowDbService', jobBackupNowDbServiceFn);

  /**
   * JobBackupNowDbService provides custom logic for managing the backup entity
   * tree for sql backup jobs.
   *
   * @param     {Object}   _ Lodash.
   * @param     {Object}   cModal C Modal.
   * @param     {Object}   SourceService Source service.
   * @returns   {Object}   The service object.
   */
  function jobBackupNowDbServiceFn(_, cModal, SourceService) {
    var JobBackupNowDbService = {
      getObjectsList: getObjectsList,
      updateExchangeNodeSelection: updateExchangeNodeSelection,
      updateNodeSelection: updateNodeSelection,
    };

    /**
     * Returns a tree structure of database objects in a job. Only protected
     * items will show in the structure along with their parents.
     *
     * @function getObjectsList
     * @param     {Array}   tree   The list of source nodes in a tree structure.
     *
     * @returns   {Array}   Array of node objects that have been selected for
     *                      the current job.
     */
    function getObjectsList(tree) {
      var objectsList = [];

      tree.forEach(function wrapperFn(node) {
        _traverseBranchForObjectList(node, objectsList, false);
      });

      return objectsList;
    }

    /**
     * Updates the _isSelectedForBackup property for any children or related
     * exchange nodes.
     *
     * @function   updateExchangeNodeSelection
     * @param    {Array}    tree   The list of source nodes in a tree structure.
     * @param    {Object}   node   The current node.
     */
    function updateExchangeNodeSelection(tree, node) {
      // Start by selecting/deselecting all children
      _selectNodeChildren(tree, node);

      // Update ancestor selections
      tree.forEach(_updateSelectionFromChildren);
    }

    /**
     * Updates the _isSelectedForBackup property for any children or related
     * nodes. Checks for system databases and aags.
     *
     * @function   updateNodeSelection
     * @param    {Array}    tree   The list of source nodes in a tree structure.
     * @param    {Object}   node   The current node.
     */
    function updateNodeSelection(tree, node) {
      // Start by selecting/deselecting all children
      _selectNodeChildren(tree, node);

      if (SourceService.isSystemDatabase(node.entity)) {
        if (node._isSelectedForBackup) {
          _explainSelectingAllSystemDbs();
        }
        _selectAllInstanceSystemDbs(tree, node);
      }

      // Update ancestor selections
      tree.forEach(_updateSelectionFromChildren);
    }

    /**
     * Update the selection for all node children to match the node's current
     * selection. Also updates related AAG's as necessary.
     *
     * @function   _selectNodeChildren
     * @param    {Array}    tree        The list of source nodes in a tree
     *                                  structure.
     * @param    {Object}   node        The node currently being processed.
     * @param    {Object}   [options]   Options.showAagMessage can track if the
     *                                  aag notification has already been shown
     *                                  in order to avoid showing it twice.
     */
    function _selectNodeChildren(tree, node, options) {
      var opts = options || {};
      var aagId;

      // There's currently no scenario in which selecting sqlEntities from a VM
      // is allowed for any DB workflow.
      if (node.children && !node.entity.vmwareEntity) {
        node.children.forEach(function handleChildren(child) {
          if (child._isSelected) {
            // Depending on whether or not this child node matches the applied
            // filter, when it does match the filter use the parent node's
            // selection state.
            child._isSelectedForBackup = child._matchesFilter ?
              node._isSelectedForBackup :

              // But when the child node doesn't match the filter, use own
              // current selection state (this means if a selected item is
              // filtered out, don't modify it).
              child._isSelectedForBackup;
            _selectNodeChildren(tree, child, opts || {});
          }
        });
      }

      aagId = get(node, 'entity.sqlEntity.dbAagEntityId');
      if (aagId) {
        if (node._isSelectedForBackup && !opts.shownAagMessage) {
          _explainSelectingAags(node);
          opts.shownAagMessage = true;
        }
        _selectAags(tree, node._isSelectedForBackup, aagId,
          node.entity.sqlEntity.databaseName);
      }
    }

    /**
     * Finds and updates all databases within the aag group.
     *
     * @function _selectAags
     * @param    {Array}     tree           The list of source nodes in a tree
     *                                      structure.
     * @param    {boolean}   select         Whether to select the node or not.
     * @param    {number}    aagId          The aag group id.
     * @param    {string}    databaseName   The database name to match.
     */
    function _selectAags(tree, select, aagId, databaseName) {
      tree.forEach(function findAagDb(source) {
        if (source._aags && some(source._aags, {id: aagId})) {
          source.children.forEach(function traverseInstance(instance) {
            instance.children.forEach(function checkDb(db) {
              if (get(db, 'entity.sqlEntity.dbAagEntityId') === aagId &&
                  get(db, 'entity.sqlEntity.databaseName') === databaseName &&
                  db._isSelected) {
                db._isSelectedForBackup = select;
              }
            });
          });
        }
      });
    }

    /**
     * Updates the selection status of a node based on its children. If all of
     * a node's children are selected, it should be selected as well, if any
     * nodes are unselected it should be deselected.
     *
     * @function   _updateSelectionFromChildren
     * @param    {Object}    node   The node to update.
     * @returns  {boolean}   Returns true if a node, or any of it's children
     *                       are unselected.
     */
    function _updateSelectionFromChildren(node) {
      if (!node.children) {
        return node._isSelected && node._isSelectedForBackup !== true;
      }
      node._isSelectedForBackup = node.entity.vmwareEntity ?
        // Bypass child detection if this is a VM. There's currently no scenario
        // where sqlEntities on a VM are selectable for any DB workflow.
        node._isSelectedForBackup :

        // Otherwise, check children as described in function description.
        !some(node.children, _updateSelectionFromChildren);

      return !node._isSelectedForBackup;
    }

    /**
     * Show a modal explaining the selection rules for system databases.
     *
     * @function   _explainSelectingAllSystemDbs
     * @returns   {Object} The cModal promise.
     */
    function _explainSelectingAllSystemDbs() {
      return cModal.standardModal(null, {
        id: 'select-sibling-system-dbs-modal',
        contentKey: 'cSourceTreePub.selectDeselectAllSystemDbsModal',
        closeButtonKey: false,
      });
    }

    /**
     * Show a modal explaining the selection rules for aag databases.
     *
     * @function   _explainSelectingAags
     * @returns   {Object} The cModal promise.
     */
    function _explainSelectingAags() {
      return cModal.standardModal(null, {
        id: 'select-all-aag-dbs-modal',
        contentKey: 'backupNowModal.aagDbSelection',
        closeButtonKey: false,
      });
    }


    /**
     * Given a single System DB node, select all system databases on the same
     * sql instance.
     *
     * @function selectAllInstanceSystemDbs
     * @param    {Array}    tree   The list of source nodes in a tree structure.
     * @param    {Object}   node   The selected system database node.
     */
    function _selectAllInstanceSystemDbs(tree, node) {
      // This is specific to sql for now, but using the entity key to keep the
      // logic reasonable generic for now.
      var envEntity = node.entity[node._normalizedEntity.entityKey] || {};

      // Find the source from the tree root nodes that matches the database.
      var source = find(tree, function findSource(item) {
        // node.entity.parentId is not the same for vm and physical based
        // entities. sqlEntity.ownerId is safer to use here.
        return item.entity.id === node.entity.sqlEntity.ownerId;
      });

      // Look for the instance. This matches based on the instance name rather
      // than the instance id since the system database entity doesn't include
      // the instance id. Sql Instance names are guaranteed to be unique within
      // a host system.
      var instance = source ?
        find(source.children, function findInstance(item) {
          return item.entity.displayName === envEntity.instanceName;
        }) : null;

      // After finding the instance, update is selected for backup for all of
      // of the system databases.
      if (instance) {
        instance.children.forEach(function selectSystemDbs(child) {
          if (SourceService.isSystemDatabase(child.entity)) {
            child._isSelectedForBackup = node._isSelectedForBackup;
          }
        });
      }
    }

    /**
     * Build the list of items that should show in the entity tree.
     * This should show the source host, the instance, and all of the databases
     * that are included in this run, regardless of whether the job selects
     * databases individually, or has autoprotected the host or instance.
     * The objectsList will include the source hosts only, with all of their
     * children's _isSelected property set correctly.
     *
     * @function   _traverseBranchForObjectList
     * @param    {Object}   node                The node currently being
     *                                          processed.
     * @param    {Array}    objectsList         The list of items that should
     *                                          show in the entity tree.
     * @param    {boolean}  ancestorProtected   Whether the parent node is
     *                                          protected. If true, the node
     *                                          will automatically be selected.
     */
    function _traverseBranchForObjectList(node, objectsList, ancestorSelected) {
      var inJobDescCount = get(node, '_inJobDescendants.length', 0);
      var isExchangeNode = node.entity.type === 17;
      var isAag = get(node, 'entity.sqlEntity.type') === 2;

      // To automatically select the children, the node should show as selected
      // but have no children in the in job descendants count. If it does have
      // children, we should make sure only those children are selected. If not
      // we are in an auto protect situation and can select all of them.
      ancestorSelected = (ancestorSelected || node._isSelected) &&
        !inJobDescCount;

      // Lookup isSelected status via isProtected for Exchange DAG database.
      if (isExchangeNode &&
          get(node, 'entity.exchangeEntity.type') === 'kExchangeDAGDatabase') {
        node._isSelected = node._isProtected;
      } else {
        node._isSelected = !!(node._inJob || inJobDescCount || ancestorSelected);
      }

      // If the source node is selected, add it to the object list. We will want
      // to make sure that all in job descendants are listed as selected
      if (node._isSelected &&
        (node._isSqlHost || (isExchangeNode && !!node.children) || (isAag))) {
        node._isExpanded = true;
        objectsList.push(node);
      }

      // An instance node will show as a non-leaf
      // Make sure that we update the number of children so that it displays
      // Correctly in the UI. If the in job descendant count is non zero, update
      // the number of entities to match that. If it's zero, the object is auto
      // protected and number of entities is correct
      if (node._isSelected && !node._isLeaf) {
        node._numEntities = inJobDescCount || node._numEntities;
        node._isExpanded = true;
      }

      if (isArray(node.children)) {
        node.children.forEach(function recursiveWrapperFn(child) {
          _traverseBranchForObjectList(
            child, objectsList, ancestorSelected);
        });
      }
    }

    return JobBackupNowDbService;
  }
})(angular);
