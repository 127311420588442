import { set } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: Formatter for Jobs Service (Private API)

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobService.Formatter', ['C.sourceService'])
    .service('JobServiceFormatter', JobServiceFormatter);

  /**
   * The Job Service Formatter service
   *
   * @method   JobServiceFormatter
   */
  function JobServiceFormatter(_, $q, cUtils, evalAJAX, FEATURE_FLAGS,
    SourceService, ENV_TYPE_CONVERSION, ENV_GROUPS) {

    // NOTE: Make sure all entries in this list are also accounted for in
    // Constant: ENTITY_KEYS
    var appHostTypedEntities = ['sqlEntity', 'oracleEntity'];

    // This Service's API
    return {
      transformSqlFileBasedJob: transformSqlFileBasedJob,
      transformOracleFileBasedJob: transformOracleFileBasedJob,
      untransformSqlFileBasedJob: untransformSqlFileBasedJob,
      untransformOracleFileBasedJob: untransformOracleFileBasedJob,
      differentiateIndexableEntity: differentiateIndexableEntity,
    };

    /**
     * Transforms the Magneto SQL file-based job to a UI friendly object. This
     * is necessary because, while the UI treats SQL files as first-class
     * citizens, they are not so in Magneto. So we need to transform the
     * job.sources list from hosts to appEntities, and rework the tree.
     *
     * @method   transformSqlFileBasedJob
     * @param    {object}   job   The SQL file-based Job.
     * @return   {object}   The ui-friendly SQL file-based Job.
     */
    function transformSqlFileBasedJob(job) {
      var backupType =
        // No default value here because it needs to be NaN if undefined.
        get(job.envBackupParams, 'sqlBackupJobParams.fullBackupType');
      var appEntities;

      if (job.type === ENV_TYPE_CONVERSION.kSQL && isNaN(backupType)) {
        // BackupType 0 = kSqlVSSVolume
        backupType = 0;

        // Make sure envBackupParams.sqlBackupJobParams.fullBackupType has
        // value. If undefined (a job created via private API) it is equivalent
        // to 0.
        set(
          job,
          'envBackupParams.sqlBackupJobParams.fullBackupType',
          backupType
        );
      }

      // Exit early if File-based SQL Jobs support is disabled,
      if (!FEATURE_FLAGS.protectSqlFileBased ||

        // Job is not SQL, or
        job.type !== ENV_TYPE_CONVERSION.kSQL ||

        // backup is already volume-based.
        backupType === 0) {
        return job;
      }

      // Identify appEntities from job.backupSourceParams[].appEntityIdVec
      appEntities = _getSelectedAppEntities(job);

      // With the list of job.sources, rebuild it to substitute appHosts
      // with their appEntities, if they were selected.
      job.sources = job.sources.reduce(
        function replaceAppHosts(_sources, host) {
          if (appEntities[host.entities[0].id]) {
            // If we know about this host by its appEntityIdVec, push them
            // all in instead of the host.
            Array.prototype.push.apply(
              _sources,
              appEntities[host.entities[0].id]
                .map(function translateAppEntity(appEntity) {
                  return { entities: [appEntity] };
                })
            );
          } else {
            // Otherwise keep the host and proceed because it was selected
            // by the User.
            _sources.push(host);
          }

          return _sources;
        },
        []
      );

      return job;
    }

    /**
     * Transforms the Magneto Oracle file-based job to a UI friendly object.
     * This is necessary because, while the UI treats Oracle files as
     * first-class citizens, they are not so in Magneto. So we need to
     * transform the job.sources list from hosts to appEntities, and rework the
     * tree.
     *
     * @method   transformOracleFileBasedJob
     * @param    {object}   job   The Oracle file-based Job.
     * @return   {object}   The ui-friendly Oracle file-based Job.
     */
    function transformOracleFileBasedJob(job) {
      var appEntities;

      // Sanity check.
      if (!FEATURE_FLAGS.oracleSourcesEnabled ||
        !job ||
        job.type !== 19) {
        return job;
      }

      // Identify appEntities from job.backupSourceParams[].appEntityIdVec
      appEntities = _getSelectedAppEntities(job);

      // With the list of job.sources, rebuild it to substitute appHosts
      // with their appEntities, if they were selected.
      job.sources = job.sources.reduce(
        function replaceAppHosts(_sources, host) {
          if (appEntities[host.entities[0].id]) {
            // If we know about this host by its appEntityIdVec, push them
            // all in instead of the host.
            Array.prototype.push.apply(
              _sources,
              appEntities[host.entities[0].id]
                .map(function translateAppEntity(appEntity) {
                  return { entities: [appEntity] };
                })
            );
          } else {
            // Otherwise keep the host and proceed because it was selected
            // by the User.
            _sources.push(host);
          }

          return _sources;
        },
        []
      );

      return job;
    }

    /**
     * Untransform the UI-created Job into a Magneto-friendly BackupJobProto.
     *
     * This is necessary because the UI treats SQL databases and other
     * appEntities as first-class citizens. but Magneto does not. So we need to
     * rearrange the User selected hosts into their appropriate proto locations
     * for acceptance.
     *
     * @method   untransformSqlFileBasedJob
     * @param    {object}   job   The UI-created Job object.
     * @return   {object}   The untransformed Job object.
     */
    function untransformSqlFileBasedJob(job) {
      var backupSourceParamsHash;
      var appHostsHash;

      // Sanity check.
      // job.envBackupParams.sqlBackupJobParams.fullBackupType
      // 0: Volume Based 1: File based
      if (!FEATURE_FLAGS.protectSqlFileBased ||
        job.type !== 3 ||
        job.envBackupParams.sqlBackupJobParams.fullBackupType === 0) {
        return job;
      }

      // generate sqlBackupSourceParams for the selected appEntities
      // TODO(spencer): Potentially refactor this method to return the list
      // and not a hash.
      backupSourceParamsHash = _generateSqlBackupSourceParams(job);
      job.backupSourceParams = (job.backupSourceParams || [])
        .concat(Object.values(backupSourceParamsHash));

      // remove appEntities from job.sources
      // Replace appEntities with their appHost
      job.sources = _getProtectableDBHosts(job);

      return job;
    }

    /**
     * Untransform the UI-created Job into a Magneto-friendly BackupJobProto.
     *
     * This is necessary because the UI treats Oracle databases and other
     * appEntities as first-class citizens. but Magneto does not. So we need to
     * rearrange the User selected hosts into their appropriate proto locations
     * for acceptance.
     *
     * @method   untransformOracleFileBasedJob
     * @param    {object}   job   The UI-created Job object.
     * @return   {object}   The untransformed Job object.
     */
    function untransformOracleFileBasedJob(job) {
      var backupSourceParamsHash;
      var appHostsHash;

      // Sanity check.
      if (!FEATURE_FLAGS.oracleSourcesEnabled || job.type !== 19) {
        return job;
      }

      // generate oracleBackupSourceParams for the selected appEntities
      backupSourceParamsHash = _generateOracleBackupSourceParams(job);
      job.backupSourceParams = (job.backupSourceParams || [])
        .concat(Object.values(backupSourceParamsHash));

      // remove appEntities from job.sources
      // Replace appEntities with their appHost
      job.sources = _getProtectableDBHosts(job);

      return job;
    }

    /**
     * Differentiates between the backed up entity as indexable server or view.
     * This information is necessary to process any AWB app run on files stored
     * either at indexable server or views.
     * The logic is similar to the 2 methods within yoda/util/utils.cc below:
     *
     * bool IsIndexableServer(const magneto::Environment::Type& entity_type,
     *                 const magneto::Environment::Type& backup_type,
     *                 bool has_backup_type)
     *
     * bool IsIndexableView(const magneto::Environment::Type& entity_type,
     *                 const magneto::Environment::Type& backup_type,
     *                 bool has_backup_type)
     *
     * @method   differentiateIndexableEntity
     * @param    {Object}   job   Specifies the job object.
     * @return   {Object}   Job object with decorators to differentiate
     *                      indexable server and view.
     */
    function differentiateIndexableEntity(job) {
      assign(job, {
        _isIndexableEntityExposedAsServer: false,
        _isIndexableEntityExposedAsView: false,
      });

      // Determine indexable entities exposed as servers/volumes.
      // Refer ENV_GROUPS.indexableEntitiesExposedAsServers for details.
      if ((ENV_GROUPS.indexableEntitiesExposedAsServers.includes(job.type) &&
          job.parentSource.type === ENV_TYPE_CONVERSION.kHyperV) ||
          (ENV_GROUPS.indexableEntitiesExposedAsServers.includes(job.type) &&
          ENV_GROUPS.indexableEntitiesExposedAsServers
            .includes(job.parentSource.type))) {
        job._isIndexableEntityExposedAsServer = true;
      }

      // Determine indexable entities exposed as views.
      // Refer ENV_GROUPS.indexableEntitiesExposedAsViews for details.
      if ((ENV_GROUPS.indexableEntitiesExposedAsViews.includes(job.type) &&
          job.parentSource.type === ENV_TYPE_CONVERSION.kPhysical) ||
          (ENV_GROUPS.indexableEntitiesExposedAsViews.includes(job.type) &&
          ENV_GROUPS.indexableEntitiesExposedAsViews
            .includes(job.parentSource.type))) {
        job._isIndexableEntityExposedAsView = true;
      }
      return job;
    }

    /**
     * Gets the list of protectable Database job hosts. This includes hosts
     * determined by the selected apps, or hosts selected directly.
     *
     * @method   _getProtectableDBHosts
     * @param    {object}   job   The Job object.
     * @return   {array}    The list of unique hosts that were selected.
     */
    function _getProtectableDBHosts(job) {
      return Object.values(job.sources.reduce(
        function sourcesReducer(_sources, source) {
          var isAppEntity;
          var appHostId;

          source = source.entities[0];
          isAppEntity = _isAppEntity(source);
          appHostId = isAppEntity ?
            // If this source is an app, find its parent id.
            SourceService.getTypedEntity(source).ownerId :

            // otherwise use this source's id because it is a host.
            source.id;

          // If this source isn't already in the list...
          if (!_sources[appHostId]) {
            _sources[appHostId] = {
              entities: [ SourceService.dbHostsCache[appHostId] ],
            };
          }

          return _sources;
        },
        {}
      ));
    }

    /**
     * Determines if application entity.
     *
     * @method   _isAppEntity
     * @param    {object}    entity   The entity
     * @return   {boolean}   True if application entity, False otherwise.
     */
    function _isAppEntity(entity) {
      return appHostTypedEntities
        .includes(SourceService.getEntityKey(entity.type));
    }

    /**
     * Generates the Backup params for SQL sources.
     *
     * @method   _generateSqlBackupSourceParams
     * @param    {object}   job   The Job object.
     * @return   {object}   The hash of sqlBackupSourceParams keyed by sourceId.
     */
    function _generateSqlBackupSourceParams(job) {
      return job.sources.reduce(
        function reduceSourcesToSourceParams(params, source) {
          var sourceEntity = source.entities[0];
          var sourceHostId = sourceEntity.sqlEntity ?
            sourceEntity.sqlEntity.ownerId : sourceEntity.id;

          if (sourceEntity.type === 3) {
            params[sourceHostId] = params[sourceHostId] || {
              sourceId: sourceHostId,
              appEntityIdVec: [],
            };

            Array.prototype.push.apply(
              params[sourceHostId].appEntityIdVec,
              _getAppEntityIdVec(sourceEntity)
            );
          }

          /* If no appEntities were found for this host, remove the entry
           * because we only need appEntity entries.
           *
           * No backupSourceParams generated by other means are modified here.
           * However, if SQL sources eventually get Source options, we may need
           * to revisit this generator function.
           */
          if (params[sourceHostId] &&
            !params[sourceHostId].appEntityIdVec.length) {
            // Using delete because when converting via Object.values, simply
            // undefined values will get added to the resulting array.
            delete params[sourceHostId];
          }

          return params;
        },
        {}
      );
    }

    /**
     * Generates the Backup params for Oracle sources.
     *
     * @method   _generateOracleBackupSourceParams
     * @param    {object}   job   The Job object.
     * @return   {object}   The hash of oracleBackupSourceParams keyed by
     *                      sourceId.
     */
    function _generateOracleBackupSourceParams(job) {
      return job.sources.reduce(
        function reduceSourcesToSourceParams(params, source) {
          var sourceEntity = source.entities[0];
          var sourceHostId = sourceEntity.oracleEntity ?
            sourceEntity.oracleEntity.ownerId : sourceEntity.id;

          if (sourceEntity.type === 19 || sourceEntity.type === 6) {
            params[sourceHostId] = params[sourceHostId] || {
              sourceId: sourceHostId,
              appEntityIdVec: [],
            };

            Array.prototype.push.apply(
              params[sourceHostId].appEntityIdVec,
              _getAppEntityIdVec(sourceEntity)
            );
          }

          /* If no appEntities were found for this host, remove the entry
           * because we only need appEntity entries.
           *
           * No backupSourceParams generated by other means are modified here.
           * However, if SQL sources eventually get Source options, we may need
           * to revisit this generator function.
           */
          if (!params[sourceHostId].appEntityIdVec.length) {
            // Using delete because when converting via Object.values, simply
            // undefined values will get added to the resulting array.
            delete params[sourceHostId];
          }

          return params;
        },
        {}
      );
    }

    /**
     * Gets the DB application entity identifier vector from the database nodes
     * tree.
     *
     * @method   _getAppEntityIdVec
     * @param    {object}   node   The node to get the id of, and it's
     *                             descendants.
     * @return   {array}    The list of App Entity IDs.
     */
    function _getAppEntityIdVec(node) {
      /* Descend into the tree and collect child IDs. Start at auxChildren
       * because at the host-level of the tree, the DB hierarchy begins there.
       * If no auxChildren are found, use the children sub-branch instead,
       * because DB entities reside there within a DB hierarchy.
       */
      return (node.auxChildren || node.children || [])
        .reduce(
          function eachDBChild(ids, subNode) {
            return ids.concat(
              // Collect each subNode's id, and...
              subNode.entity.id,

              // Any descendant nodes' ids.
              _getAppEntityIdVec(subNode)
            );
          },
          []
        )

        // And finally this node's id.
        // TODO(spencer): Why is this different??
        .concat(node.entity ? node.entity.id : node.id);
    }

    /**
     * Gets the appEntities' EntityProtos from the EntityHierarchy for the given
     * Protection Job.
     *
     * This is necessary because appEntities are not treated as Backup Sources
     * in Magneto, but we need to present it that way in the UI.
     *
     * @method   _getSelectedAppEntities
     * @param    {object}   job   The Job object.
     * @return   {object}   Hash of appEntities by appHost ID.
     */
    function _getSelectedAppEntities(job) {
      return (job.backupSourceParams || []).reduce(
        function eachSourceParam(accumulator, sourceParam) {
          // When the sourceId and appEntityIdVec are defined...
          if (sourceParam.sourceId &&
            Array.isArray(sourceParam.appEntityIdVec) &&
            sourceParam.appEntityIdVec.length) {

            accumulator[sourceParam.sourceId] =
              sourceParam.appEntityIdVec.map(
                function appEntityIdHydrator(appId) {
                  var appEntity;
                  if (job.type === 3) {
                    appEntity = {
                      id: appId,
                      type: 3,
                      sqlEntity: {
                        ownerId: sourceParam.sourceId,
                      },
                    };
                  } else if (job.type === 19) {
                    appEntity = {
                      id: appId,
                      type: 19,
                      oracleEntity: {
                        ownerId: sourceParam.sourceId,
                      },
                    };
                  }
                  return SourceService.dbHostsCache[appId] ||

                  // Just enough to not break immediately.
                  // TODO(spencer): Delete after getEntitiesOfType returns
                  // appEntities (SQL).
                  appEntity;
                }
              );
          }

          return accumulator;
        },
        {}
      );
    }
  }

})(angular);
