<ng-container *ngIf="policy">

  <!-- Backup Options -->
  <div>
    <mat-card class="summary-card backup-summary-card">
      <!-- Backup Schedule -->
      <div class="c-media"
        *ngIf="policy.backupPolicy?.regular as regular">
        <cog-icon class="c-media-object"
          [shape]="isCadPolicy() ? 'helix:cloud' : 'helix:cluster'">
        </cog-icon>
        <div class="c-media-body">
          <span class="title">{{'Backup' | translate}} {{policyType}}</span>
          <ul class="c-ul-inline">
            <ng-container *ngTemplateOutlet="scheduleTemplate;
              context: {
                backupPolicy: {
                  schedule: regular.incremental?.schedule,
                  retention: regular.retention,
                  externallyTriggered: isExternallyTriggered,
                  smartRetention: !!policy.enableSmartLocalRetentionAdjustment
                }
              }">
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- Data Movement -->
      <div class="c-media margin-top" *ngIf="(isCadPolicy() || isDmsScope) && groupDataMovement().length">
        <cog-icon class="c-media-object" shape="helix:cloud"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'dataMovement' | translate}}</span>
          <ul class="c-ul-inline" *ngFor="let dataMovement of groupDataMovement()">
            <li>{{constructDataMovementLabel(dataMovement)}}</li>
          </ul>
        </div>
      </div>

      <!-- Extended Retention -->
      <div class="c-media margin-top"
        *ngIf="policy.extendedRetention as extendedRetention">
        <cog-icon shape="helix:extended-retention" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'extendedRetention' | translate}}</span>
          <ul class="c-ul-inline" *ngFor="let schedule of extendedRetention">
            <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: schedule}">
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- Periodic Full Backup -->
      <div class="c-media margin-top"
        *ngIf="policy.backupPolicy?.regular?.full as full">
        <cog-icon shape="helix:cluster-full" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'periodicFullBackup' | translate}}</span>
          <ul class="c-ul-inline">
            <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: full}">
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- Periodic Full Backup Arrays -->
      <ng-container *ngIf="policy.backupPolicy?.regular?.fullBackups as fullBackups">
        <div class="c-media margin-top" *ngIf="fullBackups.length">
          <cog-icon shape="helix:cluster-full" class="c-media-object"></cog-icon>
          <div class="c-media-body">
            <span class="title">{{'periodicFullBackup' | translate}}</span>
            <ul class="c-ul-inline" *ngFor="let backup of sortFullSchedules(fullBackups)">
              <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: backup}">
              </ng-container>
            </ul>
          </div>
        </div>
      </ng-container>

      <!-- Continuous Data Protection Backup -->
      <div class="c-media margin-top"
        *ngIf="policy.backupPolicy?.cdp as cdp">
        <cog-icon shape="helix:cdp-backup" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'continuousDataProtection' | translate}}</span>
          <ul class="c-ul-inline">
            <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: cdp}">
            </ng-container>
          </ul>
        </div>
      </div>


      <!-- Blackouts -->
      <div class="c-media margin-top"
        *ngIf="policy.blackoutWindow as blackoutWindow">
        <cog-icon shape="helix:blackout" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'blackoutWindows' | translate}}</span>
          <div class="details-body" *ngFor="let blackout of groupBlackouts(blackoutWindow)">
            {{ blackout.startTime | timeObjectToDate }}
            &mdash;
            {{ blackout.endTime | timeObjectToDate }}
            {{ blackout.days | humanizeFromDays}}
          </div>
        </div>
      </div>

      <!-- Retries -->
      <div class="c-media margin-top"
        *ngIf="policy.retryOptions as retryOptions">
        <cog-icon shape="helix:retry" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'retryOptions' | translate}}</span>
          <div class="details-body">
            <span *ngIf="retryOptions.retries === 0">{{'noRetry' | translate}}</span>
            <span *ngIf="retryOptions.retries > 0"
              [translate]="retryOptions.retries === 1 ? 'retryOnce' : 'retryNTimes'"
              [translateParams]="retryOptions">
            </span>
          </div>
        </div>
      </div>

      <!-- BMR Backup -->
      <div class="c-media margin-top"
        *ngIf="policy.backupPolicy?.bmr as bmr">
        <cog-icon shape="helix:bmr-backup" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'bmrBackupPhysicalServer' | translate}}</span>
          <ul class="c-ul-inline">
            <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: bmr}">
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- Log Backup -->
      <div class="c-media margin-top"
        *ngIf="policy.backupPolicy?.log as log">
        <cog-icon shape="helix:log-backup" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'logBackupDatabases' | translate}}</span>
          <ul class="c-ul-inline">
            <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: log}">
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- Storage array snapshot -->
      <div class="c-media margin-top"
        *ngIf="policy.backupPolicy?.storageArraySnapshot as storageArraySnapshot">
        <cog-icon shape="helix:storage-array-snapshot" class="c-media-object"></cog-icon>
        <div class="c-media-body">
          <span class="title">{{'storageArraySnapshot' | translate}}</span>
          <ul class="c-ul-inline">
            <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: storageArraySnapshot}">
            </ng-container>
          </ul>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- Replications -->
  <ng-container *ngIf="policy.remoteTargetPolicy?.replicationTargets as replications">
    <div *ngIf="replications.length">
      <mat-card class="summary-card replication-summary-card"
        *ngFor="let replication of replications">
        <div class="c-media">
          <cog-icon shape="helix:replication" class="c-media-object"></cog-icon>
          <div class="c-media-body">
            <span class="title" *ngIf="!isHeliosPolicy" [ngSwitch]="replication.targetType">
              <span *ngSwitchCase="'RemoteCluster'" [matTooltip]="replication.remoteTargetConfig?.clusterName">
                {{'replicateToClusterName' | translate : {clusterName: replication.remoteTargetConfig?.clusterName} }}
              </span>
              <span *ngSwitchCase="'AWS'" [matTooltip]="replication.awsTargetConfig?.name">
                {{'replicateToClusterName' | translate : {clusterName: replication.awsTargetConfig?.name} }}
              </span>
            </span>
            <span class="title" *ngIf="isHeliosPolicy">{{'replicateToClusterSpecifiedTarget' | translate}}</span>
            <ul class="c-ul-inline">
              <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: replication}">
              </ng-container>
            </ul>
          </div>
        </div>
        <!-- Log Backup Retention -->
        <div class="c-media margin-top" *ngIf="replication?.logRetention?.duration > 0">
          <cog-icon shape="helix:log-backup" class="c-media-object"></cog-icon>
          <div class="c-media-body">
            <span class="title">{{'logBackupDatabases' | translate}}</span>
            <ul class="c-ul-inline">
              <!-- Log Retention -->
              <li *ngIf="replication.logRetention as logRetention"
                [translate]="logRetention.duration === 1 ?
                'policyDetails.retain.' + logRetention.unit + '.singular' :
                'policyDetails.retain.' + logRetention.unit + '.plural'"
                [translateParams]="logRetention">
              </li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
  </ng-container>

  <!-- Combining Archival and Cloudspin into a single column -->
  <div *ngIf="policy.remoteTargetPolicy?.archivalTargets || policy.remoteTargetPolicy?.cloudSpinTargets ||
    policy.remoteTargetPolicy?.rpaasTargets">
    <!-- Archives -->
    <ng-container *ngIf="policy.remoteTargetPolicy?.archivalTargets as archives">
      <ng-container *ngIf="archives.length">
        <mat-card class="summary-card cloud-archives-summary-card"
          *ngFor="let archive of archives">
          <div class="c-media">
            <cog-icon class="c-media-object"
              [shape]="archive?.targetType === 'Tape' ? 'helix:tape' : 'helix:cloud'">
            </cog-icon>
            <div class="c-media-body">
              <span class="title" *ngIf="archive.targetName && !isHeliosPolicy"
                [matTooltip]="archive.targetName">
                {{'archiveToTargetName' | translate : {targetName: archive.targetName} }}
              </span>
              <span class="title" *ngIf="isHeliosPolicy">{{'archiveToClusterSpecifiedTarget' | translate}}</span>
              <ul class="c-ul-inline">
                <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: archive}">
                </ng-container>
              </ul>
            </div>
          </div>
          <!-- Log Backup Retention -->
          <div class="c-media margin-top" *ngIf="archive?.logRetention?.duration > 0">
            <cog-icon shape="helix:log-backup" class="c-media-object"></cog-icon>
            <div class="c-media-body">
              <span class="title">{{'logBackupDatabases' | translate}}</span>
              <ul class="c-ul-inline">
                <!-- Log Retention -->
                <li *ngIf="archive.logRetention as logRetention"
                  [translate]="logRetention.duration === 1 ?
                  'policyDetails.retain.' + logRetention.unit + '.singular' :
                  'policyDetails.retain.' + logRetention.unit + '.plural'"
                  [translateParams]="logRetention">
                </li>
              </ul>
            </div>
          </div>
        </mat-card>
      </ng-container>
    </ng-container>

    <!-- CloudSpin -->
    <ng-container *ngIf="policy.remoteTargetPolicy?.cloudSpinTargets as cloudSpins">
      <ng-container *ngIf="cloudSpins.length">
        <mat-card class="summary-card cloud-spin-summary-card"
          *ngFor="let cloudSpin of cloudSpins">
          <div class="c-media">
            <cog-icon shape="helix:cloud-spin" class="c-media-object"></cog-icon>
            <div class="c-media-body">
              <span class="title" *ngIf="cloudSpin.target?.name && !isHeliosPolicy"
                [matTooltip]="cloudSpin.target.name">
                {{'cloudDeployToDisplayName' | translate : {displayName: cloudSpin.target.name} }}
              </span>
              <span class="title" *ngIf="isHeliosPolicy">{{'cloudDeployToClusterSpecifiedTarget' | translate}}</span>
              <ul class="c-ul-inline">
                <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: cloudSpin}">
                </ng-container>
              </ul>
            </div>
          </div>
        </mat-card>
      </ng-container>
    </ng-container>

    <!-- CloudVault -->
    <ng-container *ngIf="policy.remoteTargetPolicy?.rpaasTargets as cloudVaults">
      <ng-container *ngIf="cloudVaults.length">
        <mat-card class="summary-card cloud-vault-summary-card"
          *ngFor="let cloudVault of cloudVaults">
          <div class="c-media">
            <cog-icon shape="helix:vault" class="c-media-object"></cog-icon>
            <div class="c-media-body">
              <span class="title" *ngIf="cloudVault.targetName"
                [matTooltip]="cloudVault.targetName">
                {{'cloudVaultSummaryTitle' | translate : {targetName: cloudVault.targetName} }}
              </span>
              <ul class="c-ul-inline">
                <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: cloudVault}">
                </ng-container>
                <li *ngIf="rpaasRegionById$ | async as rpaasRegionById">
                  {{ rpaasRegionById[cloudVault.targetId] | translate }}
                </li>
                <ng-container *ngIf="storageClassById$ | async as storageClassById">
                  <li *ngIf="storageClassById[cloudVault.targetId]">
                    {{ 'externalTargets.storageClass.Rpaas.' + storageClassById[cloudVault.targetId] | translate }}
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </mat-card>
      </ng-container>
    </ng-container>

     <!-- Vault Cluster -->
     <ng-container *ngIf="policy.remoteTargetPolicy?.onpremVaultTargets as vaultTargets">
      <ng-container *ngIf="vaultTargets?.length > 0">
        <mat-card class="summary-card vault-cluster-summary-card"
          *ngFor="let vault of vaultTargets">
          <div class="c-media">
            <cog-icon shape="helix:vault" class="c-media-object"></cog-icon>
            <div class="c-media-body">
              <span class="title" *ngIf="vault.clusterName"
                [matTooltip]="vault.clusterName">
                {{'vaultClusterSummaryTitle' | translate : {targetName: vault.clusterName} }}
              </span>
              <ul class="c-ul-inline">
                <ng-container *ngTemplateOutlet="scheduleTemplate; context: {backupPolicy: vault}">
                </ng-container>
              </ul>
            </div>
          </div>
        </mat-card>
      </ng-container>
    </ng-container>
  </div>

</ng-container>

<!-- Schedule Template -->
<ng-template #scheduleTemplate let-backupPolicy="backupPolicy">
  <!-- Schedule -->
  <li [class.hidden]="backupPolicy?.externallyTriggered" *ngIf="backupPolicy?.schedule as schedule">
    {{constructScheduleLabel(schedule)}}
  </li>

  <!-- Externally triggered backup -->
  <li *ngIf="backupPolicy?.externallyTriggered">
    {{'externallyTriggered' | translate}}
  </li>

  <!-- Retention -->
  <li *ngIf="backupPolicy?.retention as retention"
    [translate]="retention.duration === 1 ?
    'policyDetails.retain.' + retention.unit + '.singular' :
    'policyDetails.retain.' + retention.unit + '.plural'"
    [translateParams]="retention">
  </li>

  <!-- DataLock -->
  <li *ngIf="backupPolicy?.retention?.dataLockConfig as lockConfig"
    [translate]="lockConfig.duration === 1 ?
    'policyDetails.datalock.' + lockConfig.unit + '.singular' :
    'policyDetails.datalock.' + lockConfig.unit + '.plural'"
    [translateParams]="lockConfig">
  </li>

  <li *ngIf="backupPolicy?.runType as runType">
    {{ 'applyToFullBackup' | translate }}
  </li>

  <!--Smart Retention-->
  <li *ngIf="backupPolicy?.smartRetention && isReplicatedPolicy()">
    {{'policyModify.smartRetentionAdjustment' | translate}}
  </li>
</ng-template>
