import { merge } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: PublicRestoreServiceFormatter utility service.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.pubRestoreServiceFormatter', ['C.pubServiceUtil'])
    .service('PubRestoreServiceFormatter', PubRestoreServiceFormatterFn);

  function PubRestoreServiceFormatterFn(_, PubServiceUtil, $filter, ENV_GROUPS,
    DateTimeService, ENUM_ARCHIVAL_TARGET) {
    // This Service's API
    return {
      decoratePublicFileVersions: decoratePublicFileVersions,
      generateRestoreTaskObjects: generateRestoreTaskObjects,
      getRestoreTaskDetailStateName: getRestoreTaskDetailStateName,
      getResubmitRestoreTask: getResubmitRestoreTask,
      transformPrivateBrowseResultToPublic:
        transformPrivateBrowseResultToPublic,
      transformPointsForTimeRange: transformPointsForTimeRange,
      transformSharePointMetadata: transformSharePointMetadata,
    };

    /**
     * Generates a restore task detail state name (Clone or Recover).
     *
     * @method     getRestoreTaskDetailStateName
     * @param      {object}   task    recovered / cloned task
     * @return     {string}   The restore task detail state name.
     */
    function getRestoreTaskDetailStateName(task) {
      var targetType = task.objects[0].archivalTarget ? 'archive' : 'local';
      var flowType =
        task.type.toLowerCase().includes('clone') ? 'clone' : 'recover';
      return [flowType, 'detail', targetType].join('-');
    }

    /**
     * Generates restore task compatible objects array from the given list of
     * selected search result entities.
     *
     * @method     generateRestoreTaskObjects
     * @param      {object|array}   objects   Single or array of search result
     *                                        objects.
     * @return     {array}          The generated list of objects
     */
    function generateRestoreTaskObjects(objects) {
      return (objects || []).map(function serializeRestoreObject(obj) {
        // Data Type: RestoreObject
        // File: iris/go/server/data/public/restore_data.go
        // check above file for param definitions.
        return {
          jobId: obj._jobId,
          jobUid: PubServiceUtil.transformJobUidFromPrivateToPublic(obj._jobUid),
          jobRunId: obj._pubSnapshot.jobRunId,
          startedTimeUsecs: obj._pubSnapshot.startedTimeUsecs,
          protectionSourceId: obj._protectionSourceId,
          archivalTarget: obj._archivalTarget,
        };
      });
    }

    /**
     * get resubmit name
     *
     * @example
     * // returns Resubmit-cv2recovery-Oct_10_2018_9_57am
     * _getResubmitName('test')
     * @example
     * // returns Resubmit-cv2recovery-Oct_21_2018_10_57am
     * _getResubmitName('Resubmit-cv2recovery-Oct_10_2018_9_57am')
     *
     * @method   _getResubmitName
     * @param    {string}   name   name / view name etc
     * @return   {string}   name with resubmit prefix
     */
    function _getResubmitName(name) {
      var curTime = $filter('usecsToDate')(Date.clusterNow() * 1000);
      var nameArray = [];

      // If resubmitting an already resubmitted task.
      // Just change the resubmit timestamp suffix.
      // else add resubmit prefix and timestamp suffix
      if (name.includes('Resubmit')) {
        nameArray = name.split('-');
        nameArray[nameArray.length - 1] = curTime;
      } else {
        nameArray = ['Resubmit', name, curTime];
      }

      return nameArray.join('-')
        // replaces commas with spaces
        .replace(/,/g, '')

        // replaces : and spaces with _
        .replace(/[\s]+|:/g, '_');
    }

    /**
     *  Returns resumbit request task from an existing recovery task.
     *
     * @method   getResubmitRestoreTask
     * @param    {object}   task   restore task
     * @return   {object}   request params task for aresubmit
     */
    function getResubmitRestoreTask(task) {
      var outTask = cloneDeep(task);

      if (outTask.type === 'kMountFileVolume') {
        assign(outTask, {
          restoreViewParameters: outTask.cloneViewParameters,
          viewName: _getResubmitName(outTask.fullViewName),
          name: _getResubmitName(outTask.name),
        });

        // set the params which aren't required to undefined
        assign(outTask, {
          cloneViewParameters: undefined,
          id: undefined,
          status: undefined,
          endTimeUsecs: undefined,
          fullViewName: undefined,
          viewBoxId: undefined,
          targetViewCreated: undefined,
          restoreObjectState: undefined,
        });
      }

      return outTask;
    }

    /**
     *
     * @method   transformPointsForTimeRange
     * @param    {object}   data   The response response.data object.
     * @return   {object}   The transformed/decorated data.
     */
    function transformPointsForTimeRange(data) {
      // AngularJS's default transformResponse method does this for us,
      // but when we define a custom transformer, the default is bypassed.
      data = angular.fromJson(data);

      // If this is an error response, return that unaltered. Otherwise
      // decorate the success response.
      return data.errorCode ? data : merge(data, {
        timeRanges: map(data.timeRanges, function timeRangeIterator(range) {
          return assign(range, {
            _startTimeMsecs: DateTimeService.usecsToMsecs(range.startTimeUsecs),
            _endTimeMsecs: DateTimeService.usecsToMsecs(range.endTimeUsecs),
          });
        }),
        fullSnapshotInfo: map(
          data.fullSnapshotInfo,
          function snapshotIterator(snapshotInfo) {
            return merge(snapshotInfo, {
              restoreInfo: {
                _startTimeMsecs: DateTimeService
                  .usecsToMsecs(snapshotInfo.restoreInfo.startTimeUsecs),
              },
            });
          }
        ),
      });
    }

    /**
     * Decorates the snapshot version with properties otherwise nested deeper.
     *
     * @method   decoratePublicFileVersions
     * @param    {object[]}   versions   Specifies the Array of snapshot
     *                                   versions returned from public API.
     * @return   {object[]}   Array of decorated snapshot versions.
     */
    function decoratePublicFileVersions(versions) {
      return (versions || []).map(
        function decorateVersion(version) {
          return assign(version, {
            jobRunId: version.snapshot.jobRunId,
            startedTimeUsecs: version.snapshot.startedTimeUsecs,
          });
        }
      );
    }

    /**
     * Generates the relative path of a file/folder within a given source
     * environment.
     *
     * Office365's OneDrive is handled specially here as the relative path
     * for a given file/folder has to generated from the root directory.
     *
     * @method    _generateRelativePath
     * @param    {string}   fullPath     Specifies the full path of a
     *                                   document.
     * @param    {string}   environment  Specifies the source environment.
     * @param    {Boolean}  useLibrarian Specifies the browse type of item.
     * @param    {Boolean}  isOneDriveFileStructureFlat Specifies Onedrive's
     *                                                  file structure.
     */
    function _generateRelativePath(fullPath, environment, useLibrarian,
      isOneDriveFileStructureFlat) {
      // OneDrive Paths within a snapshot are of the following pattern -
      // For OneDrive with Flat file structure:
      // '/OneDrives/OneDrive-<driveId>/metadata/rocksdb/folders/...'
      // For OneDrive with Non-flat file structure:
      // '/OneDrives/OneDrive-<driveId>/data/root/folders/...'

      // For restoring files or folders, the relative path within the root or
      // rocksdb folder has to be sent. If we are using librarian to browse,
      // then fullPath will be the relative path only.
      if (ENV_GROUPS.office365.includes(environment) && !useLibrarian) {
        if (isOneDriveFileStructureFlat) {
          return fullPath.substring(fullPath.
            indexOf('/metadata/rocksdb/') + 17);
        } else {
          return fullPath.substring(fullPath.indexOf('/data/root/') + 11);
        }
      }
      return fullPath;
    }

    /**
     * Transforms the cart items selected within the cVmBrowser to the struct
     * obtained from the public file/object search result.
     * For adapters which are using public APIs for search, cVmBrowser still
     * returns items in the private API structure as the API
     *
     * TODO(tauseef): Add support for other adapters once they migrate to using
     * public APIs.
     *
     * @method   transformPrivateBrowseResultToPublic
     * @param    {object[]}      items   Specifies items returned by cVmBrowser
     * @param    [environment]   environment   Specifies the source environment
     * @return   {object[]}      Specifies the item in the structure compatible
     *                           with the recovery flow.
     */
    function transformPrivateBrowseResultToPublic(items, environment) {
      return (items || []).map(function eachItem(item) {
        // Items selected after the browse flow can only be recovered to the
        // snapshot selected while browse. Hence, the versions array within
        // 'item.fileDocument' will always be of size 1.
        let currentVersion = item.fileDocument.versions[0];
        let transformedDocumentItem =  {
          fullPath: item.fullPath,
          jobId: item.fileDocument.objectId.jobId,
          type: item.type,

          versions: [
            {
              attemptNumber: currentVersion.instanceId.attemptNum,
              jobRunId: currentVersion.instanceId.jobInstanceId,
              startedTimeUsecs: currentVersion.instanceId.jobStartTimeUsecs,
              replicaInfoList: currentVersion.replicaInfoList,
              isFullBackup: currentVersion.isFullBackup,
            },
          ],

          // Additional internal fields.
          _id: item.fullPath,
          _relativePath: _generateRelativePath(item.fullPath, environment,
            item.useLibrarian,
            item.oneDriveSnapshotMetadata.isFileStructureFlat),
          _isFile: item.type === 'kFile',
          _isDirectory: item.type === 'kDirectory',
          _jobId: item.fileDocument.objectId.jobId,
          _jobUid: item.fileDocument.objectId.jobUid,
          _name: item.name,
          _iconClass: item.type === 'kDirectory' ? 'icn-folder' : 'icn-file',

          // Add the _snapshot with the default snapshot for recovery for its
          // usage within:
          // protection/recovery/common/recovery-objects/recovery-objects.js
          _snapshot: {
            attemptNumber: currentVersion.instanceId.attemptNum,
            jobRunId: currentVersion.instanceId.jobInstanceId,
            startedTimeUsecs: currentVersion.instanceId.jobStartTimeUsecs,
            replicaInfoList: currentVersion.replicaInfoList,
            isFullBackup: currentVersion.isFullBackup,
          },
          _sourceId: item.fileDocument.objectId.entity.id,

          // Adapter specific properties.
          _oneDriveId: item.oneDriveSnapshotMetadata.oneDriveId,
        };

        if (item.oneDriveSnapshotMetadata.isSharePointDrive){
          return transformSharePointMetadata(transformedDocumentItem,
            item.useLibrarian);
        } else {
          return transformedDocumentItem;
        }
      });
    }

    /**
     * Decorates the site item with relevant properties for restore.
     * The Site item can either be a Document Library or a file/folder within
     * the document library.
     *
     * @param     {object}   siteItem       Specifies a SharePoint Site item.
     * @param     {Boolean}  useLibrarian   If item is browsed via indexed/
     *                                      non-indexed browse. Undefined
     *                                      for search.
     * @returns   {object}   Transformed Site item
     */
    function transformSharePointMetadata(siteItem, useLibrarian) {
      if (!siteItem || (!siteItem.filename && !siteItem.fullPath)) {
        return;
      }

      let itemPath = siteItem.filename || siteItem.fullPath;

      // NOTE: This is valid only incase where the item is a document
      // library which is treated as a folder within search/browse results.
      // (In case of via Indexed browse / via search)
      if (itemPath.lastIndexOf('/') === 0) {
        siteItem._siteDriveName = itemPath.substring(1);
        siteItem._isSiteDrive = true;

        // Return early if the item is a document library,
        return siteItem;
      }
      var splittedFilenameArr = itemPath.split('/');

      // SharePoint doc path is of the form for non-indexed browse-
      // /OneDrives/doc-lib-name/metadata/rocksdb/{relative-path}
      if (useLibrarian === false) {
        if (splittedFilenameArr.length > 4) {
          siteItem._siteDriveName = splittedFilenameArr[2];
          siteItem._isSiteDrive = splittedFilenameArr.length === 5;

          // _generateRelativePath matches with "/metadata/rocksdb/", but
          // complete doc-lib restore has path of the form "/metadata/rocksdb"
          // A missing '/' at the end will tamper the relative path.
          // Correcting it here therefore.
          if (siteItem._isSiteDrive) {
            siteItem._relativePath = "";
          }
        }
        return siteItem;
      }

      // NOTE: This is for both directory & file within a document
      // library.
      // Sharepoint path for indexed browse or via search is of the form -
      // /Doc-lib-name/{relative-path}
      // splittedFilenameArr[0] = ""
      // splittedFilenameArr[1] = {Doc-lib-name}
      if (splittedFilenameArr.length > 2) {
        siteItem._siteDriveName = splittedFilenameArr[1];
        siteItem._isSiteDrive = false;
        const indexOfSecondSlash = itemPath.indexOf('/',1);
        if (indexOfSecondSlash !== -1) {
          siteItem._relativePath = itemPath.substring(indexOfSecondSlash);
        }
      }
      return siteItem;
    }
  }
})(angular);