import { union } from 'lodash-es';
import { chain } from 'lodash-es';
import { some } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { isArray } from 'lodash-es';
import { reduce } from 'lodash-es';
import { set } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: Leaf-level Entity Search Service
import { recoveryGroup } from 'src/app/shared/constants';

/**
 * TODO: Extract transformer/formatting Fns to the SearchServiceFormatter
 * service. Some will be tricky because they use methods contained here which
 * will need to be either duplicated, or extracted to the formamtter (and watch
 * out for other modules using those methods if they're exposed on THIS
 * service's API). Proceed with extreme caution.
 */

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.searchService', ['C.searchFormatters'])
    .service('SearchService', SearchServiceFn);

  /**
   * @ngdoc service
   * @service
   * @name  C.SearchService
   * @description
   *   Service responsible for VM and file searching, and some utility
   *   functions for good measure.
   */
  function SearchServiceFn(
    _, $rootScope, $http, $filter, SearchServiceFormatter, SourceService,
    RestoreService, DateTimeService, cUtils, PubJobService, API, ENV_GROUPS,
    FEATURE_FLAGS, SNAPSHOT_TARGET_TYPE, ENV_TYPE_CONVERSION, SOURCE_KEYS,
    ENUM_ENTITY_ICON_CLASS, ENUM_FILE_DATA_TYPE, $translate, ENUM_ENV_TYPE,
    INDEXING_STATUS_TYPE, SOURCE_TYPE_DISPLAY_NAME, PubSourceService,
    AdaptorAccessService, PubRestoreServiceFormatter, OFFICE365_SEARCH_TYPE,
    ENUM_HOST_TYPE_CONVERSION) {

    /**
     * A hash of SQL jobs keyed by id for property lookup.
     */
    var SQL_JOBS_HASH_CACHE = {};

    var service = {
      cacheSqlJobs: cacheSqlJobs,
      commonProcessSearchResult: commonProcessSearchResult,
      dbSearch: dbSearch,
      entitySearch: entitySearch,
      fileSearch: fileSearch,
      generateDbVersionsList: generateDbVersionsList,
      getBrowsableEnvironments: getBrowsableEnvironments,
      getBrowsableSnapshotVersions: getBrowsableSnapshotVersions,
      getDBVersions: getDBVersions,
      getJobId: getJobId,
      getPublicSearchURL: getPublicSearchURL,
      getSearchTaskName: getSearchTaskName,
      getSearchUrl: getSearchUrl,
      getTypedDocument: getTypedDocument,
      nasSearch: nasSearch,
      processFileSearchResults: processFileSearchResults,
      processPublicFileSearchResults: processPublicFileSearchResults,
      processVmsSearchResults: processVmsSearchResults,
      pureSearch: pureSearch,
      rdsSearch: rdsSearch,
      searchEmails: searchEmails,
      serverSearch: serverSearch,
      transformDBResults: transformDBResults,
      transformDbMigrateResults: transformDbMigrateResults,
      transformNasResults: transformNasResults,
      transformPublicSearchResults: transformPublicSearchResults,
      transformPureResults: transformPureResults,
      transformServerResults: transformServerResults,
      transformStorageVolumeResults: transformStorageVolumeResults,
      transformViewResults: transformViewResults,
      viewSearch: viewSearch,
      vmSearch: vmSearch,

      // New Recover (public APIs)
      searchForObjects: searchForObjects,

      // Remote search
      getRemoteVaultSearches: getRemoteVaultSearches,
      getRemoteVaultSearch: getRemoteVaultSearch,
      getRemoteVaultSearchResults: getRemoteVaultSearchResults,
      submitRemoteSearch: submitRemoteSearch,
    };

    /**
     * List of known possible typeDocument properties (Strings).
     *
     * @type {Array}
     */
    var DOC_TYPES = [
      'vmDocument',
      'fileDocument',
      'viewDocument'
    ];

    /**
     * Gets the typedDocument for a given search result.
     *
     * @method    getTypedDocument
     * @param     {object}    result    A single search result
     * @return    {object}              The typeDocument object, or
     *                                  undefined if not found.
     */
    function getTypedDocument(result) {
      var match;
      if (result) {
        DOC_TYPES.some(function typedDocFinder(type, ii) {
          if (!!result[type]) {
            // We found it. Assign match and terminate this loop.
            return match = result[type];
          }
        });
      }
      return match;
    }

    /**
     * Construct the URL for the provided path and query params object.
     *
     * @method   joinPathAndParams
     * @param    {String}    path          The URL path params.
     * @param    {Object}    [params={}]   The URL search query params.
     * @return   {Boolean}   The URL having provided path and query params.
     */
    function joinPathAndParams(path, params) {
      var paramString = map(params, function eachParam(value, key) {
        return `${key}=` + (isArray(value) ? value.join(`&${key}=`) : value);
      }).join('&');

      return [path, paramString].filter(Boolean).join('?');
    }

    /**
     * Retrieve the appropriate search URL for a given type: [vm, file, etc].
     *
     * Prefix the type with 'clone' for proper results. Default result is for
     * recovery flows, ie. 'clonevm'.
     *
     * @method   getSearchUrl
     * @param    {string}    [type]                  'vm', 'clonevm',
     *                                               'clone-sql', 'file', etc
     * @param    {boolean}   [onlyLatestRun=false]   Onbly get the latest run.
     * @return   {string}    The search URL (default: vm search url).
     */
    function getSearchUrl(type, onlyLatestRun) {
      // URL path minus the API root
      var path = 'searchvms';
      var params = {};

      var isClone = /clone/i.test(type);
      var excludeJobTypes;

      switch (true) {
        case /activedirectory/i.test(type):
          params = {
            entityTypes: recoveryGroup.activeDirectory,
          };
          break;

        case /rds/i.test(type):
          params = recoveryGroup.rds.reduce((out, envItem) => {
            out.entityTypes.push(envItem.sourceEnvironment);
            out.jobTypes.push(envItem.environment);
            return out;
          }, { entityTypes: [], jobTypes: []});
          break;

        case /file/i.test(type):
          path = 'searchfiles';
          break;

        case /databases/i.test(type):
          params = {
            showAll: false,
            entityTypes: recoveryGroup.databases.filter(env => {
              return env !== 'kVMware' || FEATURE_FLAGS.sqlRestoreVMs;
            }),
          };
          break;

        case /sql|migration/i.test(type):
          let [matchKey] = type.match(/sql|migration/i);
          // In case of sql type, type string is SQL. Need to lower its case.
          matchKey = matchKey.toLowerCase();

          if (matchKey === 'migration') {
            matchKey = 'dbMigration';
          }

          // NOTE: If we ever support some form of Physical SQL host restore (in
          // the context of SQL restoration), remove these `entityTypes`
          // filters.
          params = {
            showAll: false,
            environment: 'SQL',
            entityTypes: recoveryGroup[matchKey].filter(env => {
              return env !== 'kVMware' || FEATURE_FLAGS.sqlRestoreVMs;
            }),
          };
          break;

        case /office365/i.test(type):
          // NOTE: This is deprecated.
          params = {
            entityTypes: recoveryGroup.O365,
          };
          break;

        case /oracle/i.test(type):
          // NOTE: If we ever support some form of Physical Oracle host restore
          // (in the context of Oracle restoration), remove these `entityTypes`
          // filters.
          params = {
            entityTypes: recoveryGroup.oracle,
          };
          break;

        case /storageVolume/i.test(type):
          // NOTE: storage volume is a combination of nas and pure volume
          //       storage. We currently use storage volume 'term' only for
          //       recovery flows. Once we use it all the flows like backup etc.
          //       We have to remove the pure and nas types.
          params = {
            entityTypes: recoveryGroup.storageVolume,
          };
          break;

        case /pure/i.test(type):
          params = {
            entityTypes: recoveryGroup.pure,
          };
          break;

        case /view/i.test(type):
          params = {
            entityTypes: recoveryGroup.view,
          };
          break;

        case /nas/i.test(type):
          params = {
            entityTypes: recoveryGroup.nas,
          };
          break;

        case /physicalserver/i.test(type):
          params = {
            entityTypes: recoveryGroup.physicalServer,
          };
          break;

        case /mountpoint/i.test(type) || /browsableentities/i.test(type):
          // Needs entityTypes provided (kVMware, kPhysical, etc), but they are
          // provided dynamically in cSearchService so specific server types
          // can be searched/filtered for.
          // Snapshot Manager jobs store snapshots remotely in cloud.
          // We don't index these volumes for browsing.
          excludeJobTypes = [
            'kRDSSnapshotManager',
            'kAWSSnapshotManager',
            'kAzureSnapshotManager'
          ];

          if (/mountpoint/i.test(type)) {
            excludeJobTypes.push('kAWSNative', 'kAzureNative', 'kGCPNative');
          }

          params = {
            excludeJobTypes: excludeJobTypes,
            entityTypes: getBrowsableEnvironments(),
          };
          break;

        case /kubernetes/i.test(type):
          params = {
            entityTypes: recoveryGroup.kubernetes,
          };
          break;

        case /vmdk/i.test(type):
          params = {
            entityTypes: recoveryGroup.vmdk,
          };
          break;

        case /vm/i.test(type):
          // Add the conditional entityTypes params to the path. Remove Windows
          // OS type from path.
          // CloudTypes backed up with Cohesity Agent are not recoverable.
          // CloudTypes backed up with native snapshots are.
          // So Cloud job types have to be excluded as well.
          // kRDSSnapshotManager is a "jobType" which exists with entityType
          // of kAWS. Since RDS deals with DBs, we need to exclude this jobType
          // while searching for VMs
          excludeJobTypes = union(['kRDSSnapshotManager'],
            cUtils.onlyStrings(ENV_GROUPS.cloudSources));

          params = {
            entityTypes: recoveryGroup.vm.filter(env => {
              if (env === 'kHyperV' || env === 'kHyperVVSS') {
                return isClone || FEATURE_FLAGS.hyperVRecover;
              }

              if (env === 'kAcropolis') {
                return !isClone && FEATURE_FLAGS.acropolisSupport;
              }

              if (env === 'kKVM') {
                return !isClone && FEATURE_FLAGS.rhvSupport;
              }

               if (env === 'kAWS') {
                return !isClone && FEATURE_FLAGS.cloudMigrationToggle;
              }

               if (env === 'kAzure' || env === 'kGCP') {
                return !isClone;
              }
              return true;
            }),
            excludeJobTypes: excludeJobTypes,
          };
          break;

        default:
          path = 'searchvms';
      }

      // If only the latest run is needed, add that param here.
      if (onlyLatestRun) {
        params.onlyLatestVersion = true;
      }

      // Spit it out
      return API.private(joinPathAndParams(path, params));
    }

    /**
     * @method   getPublicSearchURL
     * Generates the path for search the objects at the Public API.
     *
     * @param    {string}   type   Specifies the environment type.
     * @return   {string}   The search endpoint with environment.
     */
    function getPublicSearchURL(type) {
      var path = 'restore/objects';
      var params = {};

      switch(true) {
        case /office365/i.test(type):
          // NOTE: This will search only for user entities within O365.
          params = {
            environments: recoveryGroup.O365,

            // For backwards compatibility, both kUser & kMailbox should be
            // included for search.
            office365SourceTypes: ['kUser', 'kMailbox']
          };
          break;

        case /file/i.test(type):
          path = 'restore/files';
          break;

        case /emails/i.test(type):
          path = 'restore/office365/outlook/emails';
          break;

        case /onedrives/i.test(type):
          path = 'restore/office365/onedrive/documents?environments=kO365';
          break;

        case /sharePointDocs/i.test(type):
          path = 'restore/office365/sharepoint/documents?environments=kO365';
          break;

        case /adobjects/i.test(type):
          path = 'restore/adObjects/searchResults';
          break;

        // NOTE: This will search only for site entities within O365.
        case /sharePointSites/i.test(type):
          params = {
            environments: recoveryGroup.O365,
            office365SourceTypes: 'kSite'
          };

        // TODO(Tauseef): Migrate other adapters to public search API.
      }

      return API.public(joinPathAndParams(path, params));
    }

    /**
     * Utility Fn to return a given VM or Job entity's jobId. Typically
     * used with results returned by vmSearch() or fileSearch() in this
     * same service.
     *
     * @method     getJobId
     * @param      {Entity}  entity  EntityProto to work on
     * @return     {Integer}         The jobId of the given Entity. -1 if
     *                               not found.
     */
    function getJobId(entity) {
      if (!entity || !entity.vmDocument || !entity.vmDocument.objectId) {
        return -1;
      }
      return entity.vmDocument.objectId.jobId;
    }

    /**
     * Convenience function for searching only Views
     *
     * @method     viewSearch
     * @param      {object}        params  The query params
     * @param      {boolean}       retried  True if api was already tried once.
     * @return     {array|object}  The list of search results. If error, the
     *                             raw server response.
     */
    function viewSearch(params, retried) {
      var opts = {
        method: 'get',
        url: getSearchUrl('view'),
        params: params
      };
      return $http(opts)
        .then(function(response) {
          /**
           * Per ENG-303441 : If failed to find a View, then try a second time
           * without specifying View name because of the reverse replication
           * use case when View name is different. For nearly all cases, we do
           * not need to specify View name. Only reason we do is for the corner
           * case when a View is protected by more than one job.
           */
          if (!response.data.vms && !retried) {
            params.vmName = undefined;
            return viewSearch(params, true);
          } else {
            return transformViewResults(response);
          }
        });
    }

    /**
     * Convenience function for searching Mount Point recover VMs and/or
     * Physical Servers
     *
     * @method     serverSearch
     * @param      {object=}  params  Optional request parameters
     * @return     {object}   Promise to resolve with the list of results if
     *                        successful, or the raw server response if
     *                        failure.
     */
    function serverSearch(params) {

      var opts = {
        method: 'get',
        url: getSearchUrl('mountPoint'),
        params: params
      };

      // if specific filtering wasn't provided, limit search instant mount ENVs.
      if (!opts.params.entityTypes) {
        opts.params.entityTypes = cUtils.onlyStrings(ENV_GROUPS.instantMount);
      }

      return $http(opts)
        .then(transformServerResults);

    }

    /**
     * Search for databases with provided params
     *
     * @method   dbSearch
     * @param    {object}   [params]                Hash of query params.
     * @param    {string}   [dbEnvironment='sql']   The DB Environment to
     *                                              search for.
     * @param    {boolean}  [filterVersions=true]   Include all versions and not
     *                                              just restorable versions.
     * @return   {object}   Promise to resolve with the requested results..
     */
    function dbSearch(params, dbEnvironment, filterVersions) {
      var opts = {
        method: 'get',
        url: getSearchUrl(dbEnvironment || 'sql'),
        params: params,

        // NOTE: (Yoda || Iris Backend) do not honor this param, though it is
        // officially defined. Regardless, this means that for every repeat
        // query, we make one less request to the backend, unless it's "today".
        // And when toDateUsecs is less than now, it means runs that completed
        // after that time can be ignored.
        cache: params.toTimeUsecs < Date.clusterNow() * 1000,
      };
      filterVersions = filterVersions === undefined || filterVersions;

      return $http(opts).then(function transform(results) {
        return transformDBResults(results, filterVersions, dbEnvironment);
      });
    }

    /**
     * Get DB backup history
     *
     * @method   getDBVersions
     * @param    {object}   params   Typical properties are ownerEntityId and
     *                               jobIds[]. See REST docs for more.
     * @param    {string}   db       'sql' or 'oracle'
     * @return   {object}   Promise carrying the list of versions
     */
    function getDBVersions(params, db) {
      return dbSearch(params, db)
        .then(function versionsSquisher(entities) {
          // A Database was requested.
          if (params.entityIds) {
            return get(entities, '[0].vmDocument.versions', []);
          }

          // A host was requested.
          return generateDbVersionsList(entities)
            .sort(function sortVersions(a, b) {
              // Sort them newest first
              return (a.snapshotTimestampUsecs > b.snapshotTimestampUsecs) ?
                -1 : 1;
            });
        });
    }

    /**
     * Search for VMs with the provided criteria.
     *
     * @method   vmSearch
     * @param    {object}   params      $http compatible params object.
     * @param    {string}   type        Type of vm searching being done.
     * @return   {object}   Promise to resolve with the requested results, or
     *                      raw server response if failure.
     */
    function vmSearch(params, type = 'vm') {
      return $http({
        method: 'get',
        url: getSearchUrl(type),
        params: params,
      }).then(function vmResultsReceived(resp) {
        // If the request contains `entityIds` param, the controller only wants
        // entity results, not jobs. So this second arg tells the processor to
        // skip creating ~job objects.
        return processVmsSearchResults(resp, !!params.entityIds);
      });
    }

    /**
     * Search for RDS Instances with the provided criteria.
     *
     * @method   rdsSearch
     * @param    {object}   params   $http compatible params object.
     * @return   {object}   Promise to resolve with the requested results, or
     *                      raw server response if failure.
     */
    function rdsSearch(params) {
      return $http({
        method: 'get',
        url: getSearchUrl('rds'),
        params: params,
      }).then(function rdsResultsReceived(resp) {
        // results are similar to vm search. Apply the same processing.
        return processVmsSearchResults(resp, !!params.entityIds);
      });
    }

    /**
     * Search for entities by id with no entityType restrictions. Generic entity
     * search.
     *
     * @method   entitySearch
     * @param    {object|array}   ids   One or more ids to search for.
     * @return   {object}               Promsie to resolve with the flat list of
     *                                  results, or server's raw response if
     *                                  error.
     */
    function entitySearch(params) {
      var opts = {
        method: 'get',
        url: getSearchUrl(),
        params: params,

        // NOTE: (Yoda || Iris Backend) do not honor this param, though it is
        // officially defined. Regardless, this means that for every repeat
        // query, we make one less request to the backend, unless it's "today".
        // And when toDateUsecs is less than now, it means runs that completed
        // after that time can be ignored.
        cache: params.toTimeUsecs < Date.clusterNow() * 1000,
      };

      return $http(opts).then(function gotEntities(resp) {
        return (resp.data.vms || []).map(commonProcessSearchResult);
      });
    }

    /**
     * Search for files with the provided criteria
     *
     * @method     fileSearch
     * @param      {object}    fileSearchParams       $http compatible params
     *                                                object.
     * @param      {boolean}   [usePublicAPI=false]   True if public API for
     *                                                search is to be used
     * @return     {object}    Promise object carrying the results
     */
    function fileSearch(fileSearchParams, usePublicAPI) {
      /**
        Filename *string `json:"filename,omitempty"`
        JobId *int64 `json:"jobId,omitempty"`
        RegisteredSource *entityProto.EntityProto `json:"registeredSource,omitempty"`
        ViewBoxId *int64 `json:"viewBoxId,omitempty"`
        FromTimeUsecs *int64 `json:"fromTimeUsecs,omitempty"`
        ToTimeUsecs *int64 `json:"toTimeUsecs,omitempty"`
        From *int64 `json:"from,omitempty"`
      */

      var opts = {
        method: 'get',
        url: usePublicAPI ? getPublicSearchURL('file') :
          getSearchUrl('file'),
        params: fileSearchParams,
      };

      return $http(opts);
    }

    /**
     * Search for Office 365 Outlook Emails.
     *
     * @method   searchEmails
     * @param    {object}   emailParams   Specifies the optional parameters for
     *                                    searching emails.
     * @return   {object}   Promise object containing the response.
     */
    function searchEmails(emailParams) {
      return $http({
        method: 'get',
        url: getPublicSearchURL('emails'),
        params: emailParams,
      }).then(function onSuccessfulResponse(response) {
        return processPublicFileSearchResults(response);
      });
    }

    /**
     * Search for Pure volumes with the provided criteria
     *
     * @method     pureSearch
     * @param      {object}   params   $http compatible params object.
     * @return     {object}            Promise to resolve with array of
     *                                 results, or raw response if error
     */
    function pureSearch(params) {
      var opts = {
        method: 'get',
        url: getSearchUrl('pure'),
        params: params
      };

      return $http(opts)
        .then(transformPureResults);
    }

    /**
     * Search for NAS volumes with the provided criteria
     *
     * @method     nasSearch
     * @param      {object}   params   $http compatible params object.
     * @return     {object}            Promise to resolve with array of
     *                                 results, or raw response if error
     */
    function nasSearch(params) {
      var opts = {
        method: 'get',
        url: getSearchUrl('nas'),
        params: params,
      };

      return $http(opts)
        .then(transformNasResults);
    }

    /**
     * On-demand processor/transformer for File search results into a more
     * UI friendly list.
     *
     * @method     processFileSearchResults
     * @param      {Object}    resp      Server response
     * @param      {Object}    jobIds    List of jobIds to search in
     * @param      [Function]  isInCart  Function to search for files in cart
     * @return     {Array}               List of processed search results
     */
    function processFileSearchResults(resp, jobIds, isInCart) {
      var files = resp.data.files || [];
      return files.map(function resultsMapper(file, ii) {
        var fileDoc = file.fileDocument;

        // Detect which path delimiter we're using and cache it
        var pathDelimiter = (/\\[\S]/.test(fileDoc.fileName)) ? '\\' : '/';

        // RegExp for where to split the path
        var rxFileSplit = new RegExp(pathDelimiter);

        // RegExp for finding trailing delimiter
        var rxTrailingDelimiter = new RegExp(pathDelimiter+'$');

        // Split the path into an arry, each element is a folder or filename
        var fileName = fileDoc.filename
          .replace(rxTrailingDelimiter, '')
          .split(rxFileSplit);

        var currentFileId;
        var currentDocument;

        fileDoc.isDirectory = fileDoc.isDirectory || fileDoc.dataType === 1;

        if (rxTrailingDelimiter.test(fileDoc.filename)) {
          // This is a folder with trailing slash notation which indicates it
          // may be a file system root. Adding / back to be used as the folder
          // name. Details: https://cohesity.atlassian.net/browse/ENG-12384
          fileName.push(pathDelimiter);
        }

        currentFileId = [
          'file',
          (ii+1),
          file.fileDocument.filename.replace(/[\W]+/gi, '-')
        ].join('-');

        currentDocument = assign(commonProcessSearchResult(file), {
          _id: currentFileId,

          // Added for track by expression as in some adapters same object
          // can be protected by multiple jobs.
          _uniqueId: [currentFileId, file._jobId].join('-'),

          // Take the last element as the folder name
          _name: fileName.pop(),
          _path: (!!fileName.length && fileName[fileName.length - 1]) ?
            // When the resulting fileName[] is not empty and the new last
            // element is truthy, join fileName[] with the detected delimiter.
            fileName.join(pathDelimiter) :

            // When the resulting fileName[] is empty, or the new last element
            // is falsey, return the delimiter because it's considered a root
            // folder in this case.
            pathDelimiter,

          _hostType: SourceService.getEntityHostOSType(fileDoc),

          // Sets a bool whether isDirectory is set or not
          isDirectory: !!fileDoc.isDirectory,

          // Setting this as a string so we can also use the type to detect user
          // interaction when selecting snapshots in the snapshot selector.
          // String == pristine.
          _snapshotIndex: '0',
          _select: isInCart ? isInCart(file) : false,
          _serverType: _getServerType(fileDoc),
          _type: fileDoc.dataType ? _getFileType(fileDoc.dataType) :
            fileDoc.isDirectory ? 'directory' : 'file',
          _jobType: _getJobType(file, jobIds),
          _environment:
            ENV_TYPE_CONVERSION[file.fileDocument.objectId.entity.type],
        });

        // 2nd level of derived properties.
        assign(currentDocument, {
          _iconClass: _getIconClassForDocument(currentDocument),
          _tooltipText: _getTooltipTextForDocument(currentDocument),
        });

        return currentDocument;
      });
    }

    /**
     * Checks if an entity is of type View.
     * NOTE: In case of Searching Documents the entity object contains the
     * fileDocument property but when Browsing Objects, the entity contains
     * the vmDocument property.
     *
     * @method   _isEntityOfTypeView
     * @param    {object}   entity    Specifies the entity object
     * @return   {boolean}            returns true if entityType is View
     */
    function _isEntityOfTypeView(entity) {
      var entityType;

      if (entity.fileDocument) {
        entityType = get(entity, 'fileDocument.objectId.entity.type');
      }

      if (entity.vmDocument) {
        entityType = get(entity, 'vmDocument.objectId.entity.type');
      }

      return entityType === ENUM_ENV_TYPE.kView;
    }

    /**
     * Generates the Icon class for the Document.
     *
     * @method   _getIconClassForDocument
     * @param    {object}   document   Specifies the document object
     * @return   {string}   Icon class for document
     */
    function _getIconClassForDocument(document) {
      switch (document._type) {
        case 'directory':
          return 'icn-folder';
        default:
          return 'icn-'.concat(document._type);
      }
    }

    /**
     * Generates the Tooltip for the Document.
     *
     * @method   _getTooltipTextForDocument
     * @param    {object}   document   Specifies the document object
     * @return   {string}   Tooltip for the Document
     */
    function _getTooltipTextForDocument(document) {
      switch (document._type) {
        case 'directory':
          return 'folder';
        default:
          return document._type;
      }
    }

    /**
      * Gets the type (file/folder etc) of a document.
      *
      * @method   _getFileType
      * @param    {Number}   type   file document type
      * @return   {String}   file type of the file
      */
     function _getFileType(type) {
      switch(ENUM_FILE_DATA_TYPE[type]) {
        case 'kFile':
          return 'file';

        case 'kDirectory':
          return 'directory';

        case 'kSymlink':
          return 'symlink';
      }
    }

    /**
     * Decorates the Public API search result with properties
     *
     * @method   processPublicFileSearchResults
     * @param    {object}    response   Specifes the Public Search API response
     *
     * @return   {[]object}   Array of documents with additional properties.
     */
    function processPublicFileSearchResults(response) {
      var files = response.data.files;
      if (!files) {
        return [{ message: 'noSearchResults' }];
      }
      return files.map(function decorateFile(file) {
        assign(file, {
          _id: [file.type, file.filename].join('-'),
          _type: file.type,
          _jobId: file.jobId,
          _jobUid: file.jobUid,

          // Note: Job UID will be different for the case where replication has
          // happened but will be same as JobID on the same cluster.
          _jobUidId: file.jobUid.id,
          _clusterId: file.jobUid.clusterId,
          _clusterIncarnationId: file.jobUid.clusterIncarnationId,
          _sourceId: file.protectionSource.id,
          _filename: file.filename,
          _environment: file.protectionSource.environment,
          _uuid: [file.type, file.filename, file.jobId, file.protectionSource.id].join('-'),
        });

        // Decorate Additional properties based on the environment.
        switch(file.protectionSource.environment) {
          case 'kO365':
            return _transformO365MetaData(file);
          default:
            return file;
        }
      });
    }

    /**
      * Gets the jobType of a file based on its snapshot info. i.e the job type
      * used to create a snapshot of the file.
      *
      * @method   _getJobType
      * @param    {Object}   fileDoc     file Document
      * @param    {Object}   jobIds      list of jobIds to search in
      * @return   {Number}   job type of the file
      */
      function _getJobType(file, jobIds) {
        if (jobIds) {
          var jobType;

          jobIds.find(function jobFinder(_job) {
            if (_job.jobId === file._jobId) {
              jobType = _job.type;
              return true;
            }
          });

          return jobType;
        }
      }

    /**
     * Gets the entity server type
     *
     * @method   _getServerType
     * @param    {Object}   fileDoc    file Document
     * @return   {String}               server type
     */
    function _getServerType(fileDoc) {
      var defaultServerType = 'vm';

      switch(true) {
        case fileDoc.objectId.entity.type === 6:
          return 'physical';

        case ENV_GROUPS.nas.includes(fileDoc.objectId.entity.type):
          return 'nas';
      }

      return defaultServerType;
    }

    /**
     * Fetches the list of browsable snapshot versions.
     *
     * @method   getBrowsableSnapshotVersions
     * @param    {Array}     versionList        Snapshot version list
     * @return   {Array}     Browsable version list
     */
    function getBrowsableSnapshotVersions(versionList) {
      if (!versionList.replicaInfo) {
        // Currently, public API is missing the replica info for versions.
        return versionList;
      }
      return versionList.filter(function eachSnapshot(version) {
        // Local snapshot can be browsed via VM Read dir / Librarian Read dir.
        // Archived snapshot can only be browsed via Librarian Read Dir.
        return version.replicaInfo.replicaVec.some(
          function eachReplica(replica) {
            switch(replica.target.type) {
              case SNAPSHOT_TARGET_TYPE.kLocal:
                return true;
              case SNAPSHOT_TARGET_TYPE.kArchival:
                // Only indexed version can be browsed via Librarian.
                return version.indexingStatus === INDEXING_STATUS_TYPE.kDone;
              default:
                return false;
            }
          }
        );
      });
    }

    /**
     * On-demand processor/transformer for VM search results into a more UI
     * friendly list.
     *
     * For VM searches, we also have the ability to restore whole jobs. But
     * Magneto doesn't maintain a list of Jobs compatible with VMs. So here we
     * construct a list of jobs from the VM results and return them together.
     *
     * NOTE: This faux Job construct is the source of many bugs and issues.
     * Beware!!
     *
     * @method   processVmsSearchResults
     * @param    {object}    resp                  Server response.
     * @param    {boolean}   [excludeJobs=false]   True to exclude jobs.
     * @param    {boolean}   [showVapp=false]      True to display Vapp in the result.
     * @return   {array}     List of processed search results (Jobs + VMs).
     */
    function processVmsSearchResults(resp, excludeJobs, showVapp) {
      var vms = resp.data.vms || [];
      var jobs = [];
      var jobsHash = {};
      var out = [];

      vms.forEach(function eachVm(vm) {
        if (showVapp || get(vm.vmDocument.objectId.entity, 'vmwareEntity.type') !== 9) {
          var parentSource = SourceService.getEntityName(vm.registeredSource);
          var jobId = vm.vmDocument.objectId.jobId;
          var jobName = vm.vmDocument.jobName;
          var viewBox = $filter('viewBox')(vm.vmDocument.viewBoxId);
          var objectType;
          var iconType;
          var isBrowsable;
          var isVmTemplate =
            get(vm.vmDocument, 'objectId.entity.vmwareEntity.isVmTemplate');
          var typedEntity =
            SourceService.getTypedEntity(vm.vmDocument.objectId.entity);
          var isRpoProtected = vm.vmDocument.attributeMap &&
            _isObjectRpoProtected(vm.vmDocument.attributeMap);
          var entityKey = SourceService.getEntityKey(
            get(vm.vmDocument.objectId, 'entity.type')
          );
          var registeredSourceUuid =
            get(vm.registeredSource[entityKey], 'uuid');
          var rpoPolicy;
          if (isRpoProtected) {
            rpoPolicy = _getPolicyName(vm.vmDocument.attributeMap);
          }

          switch(true) {
            case vm.vmDocument.objectId.entity.physicalEntity:
              objectType = 'physical';
              isBrowsable = true;
              break;

            case vm.vmDocument.objectId.entity.kubernetesEntity:
              objectType = 'kubernetes';
              iconType = "type-kubernetes";
              isBrowsable = true;
              break;

            case vm.vmDocument.objectId.entity.vmwareEntity &&
              vm.vmDocument.objectId.entity.vmwareEntity.type === 9:
              objectType = 'vapp';
              iconType = 'vApp';
              isBrowsable = true;
              break;

            default:
              objectType = 'vm';
              iconType = isVmTemplate ? 'vmTemplate' : 'virtualMachine';
              isBrowsable = true;
              break;
          }

          // Create a ~"job" object if we haven't already (and have permission
          // to recover a job). This schema closely mimics a vmDocument for
          // compatability. vmDocument.versions (jobRuns) are fetched on-demand
          // when added to the Cart. The _versions check here ensures that the
          // Job has some _versions[] for dirty checking of snapshot
          // availability.
          if (!excludeJobs && !$rootScope.user.restricted &&
            (!jobsHash[jobId] || !jobsHash[jobId].vmDocument._versions.length) &&

            // Object is not protected by RPO Policy
            !isRpoProtected) {
            jobsHash[jobId] = {
              _id: jobId,
              _jobId: jobId,
              _jobType: vm.vmDocument.backupType,
              _name: jobName,
              _type: 'job',
              _uniqueId: ['job', jobName, jobId].join('-'),
              _viewBox: viewBox,
              _entityKey: entityKey,
              _parentSourceName: parentSource,
              registeredSource: vm.registeredSource,
              _registeredSourceUuid: registeredSourceUuid,
              vmDocument: {
                jobName: jobName,
                objectId: {
                  jobId: jobId,
                  jobUid: vm.vmDocument.objectId.jobUid,
                  entity: vm.vmDocument.objectId.entity,
                },
                viewBoxId: vm.vmDocument.viewBoxId,

                // Per note above, a Job's versions can't accurately be derived
                // from the VM search results being processed here, but this
                // convenience property will provide a dirty check as to whether
                // the Job has versions for recovery/clone.
                _versions: vm.vmDocument.versions || [],
              }
            };
          }

          out.push(angular.extend(commonProcessSearchResult(vm), {
            _id: vm.vmDocument.objectId.entity.id,

            // Entity display name or name provide the correct name but sometimes
            // unavailable for cloud retrieve jobs, where we fall back to objectName
            _name: vm.vmDocument.objectId.entity.displayName || typedEntity.name ||
              vm.vmDocument.objectName,

            // TODO(spencer): Normalize all uses of these to a single property.
            _parentSource: parentSource,
            _registeredSource: parentSource,
            _registeredSourceUuid: registeredSourceUuid,
            _type: objectType,
            _iconType: iconType,
            _isRPO: isRpoProtected,
            _rpoPolicy: rpoPolicy,

            // Specifies whether the object is browsable.
            _isBrowsable: isBrowsable,
            _isVmTemplate: isVmTemplate,
            _uniqueId: [
              objectType,
              typedEntity.name,
              vm.vmDocument.objectId.entity.id,
              vm.vmDocument.objectId.jobId,
            ].join('-'),
            _viewBox: viewBox,
          }));
        }
      });

      // Squash the hash of faux Jobs into an array and merge it with the VMs
      // list.
      return Object.values(jobsHash).concat(out);
    }

    /**
     * Determines if object is protected by an RPO Policy.
     *
     * @method   _isObjectRpoProtected
     * @param    {array}     attrMap   The attribute map
     * @return   {boolean}   True if object rpo protected, False otherwise.
     */
    function _isObjectRpoProtected(attrMap) {
      return attrMap.some(function isRpo(attr) {
        return attr.xKey === 'protection_policy_type' && attr.xValue === 'kRPO';
      });
    }

    /**
     * Gets the policy name from the attribute map
     *
     * @method   _getPolicyName
     * @param    {array}    attrMap   The attribute map
     * @return   {string}   The policy name.
     */
    function _getPolicyName(attrMap) {
      return chain(attrMap)
        .find(['xKey', 'protection_policy_name'])
        .get('xValue')
        .value();
    }

    /**
     * On-demand processor/transformer for DB Migration search results into a
     * more UI friendly list.
     *
     * @method   transformDbMigrateResults
     * @param    {Object}   response   Server response.
     * @return   {Array}    List of processed search results.
     */
    function transformDbMigrateResults(response) {
      // Pre-process as regular DBs.
      set(response, 'data.vms', transformDBResults(response, true, 'db-migration'));

      // Return a filtered sub-set excluding System DBs, AAG DBs, and DBs from
      // !FCBT jobs.
      return chain(response.data.vms).filter(
        function eachDb(db) {
          var jobId = db.vmDocument.objectId.jobId;

          // DB must not be a system DB. We may support this in a later release.
          return !db._isSystemDatabase &&
            // TODO (spencer): Remove this when we're comfortable supporting AAG
            // member DBs. ETA 6.2+
            !db._isAagMember &&

            // Do not return native DB in DB migration result.
            !db.__sql_native &&

            // DB snapshot must be from a FCBT SQL job.
            get(SQL_JOBS_HASH_CACHE[jobId], '_envParams.backupType') !== 'kSqlVSSVolume';
        }
      ).value();
    }

    /**
     * On-demand processor/transformer for DB search results (currently uses VM
     * search) into a more UI friendly list.
     *
     * @method   transformDBResults
     * @param    {Object}   resp                    Server response.
     * @param    {boolean}  [filterVersions=true]   Include all versions and not
     *                                              just restorable versions.
     * @param    {string}   [type='sql']            The db environment.
     * @param    {Array}    [hostEntities=[]]       host level entities.
     * @return   {Array}    List of processed search results, possibly filtered
     *                      by type.
     */
    function transformDBResults(resp, filterVersions, type, hostEntities) {
      type = type || 'sql';
      filterVersions = filterVersions === undefined || filterVersions;
      return chain(resp.data.vms).reduce(
        function resultsMapper(dbResults, result) {
          var thisEntity = result.vmDocument.objectId.entity;
          var hostServerType = result.registeredSource.type;
          var parentSource =
            SourceService.getEntityName(result.registeredSource);
          var isPhysical = !!result.registeredSource.physicalEntity;
          var objectType;
          var dbHostType;
          var isHost;

          switch (result.vmDocument.objectId.entity.type) {
            case 3:
              objectType = 'sql';

              /**
               * In SQL DBs, the host server's environment type is guaranteed to
               * be in this list of attribute maps. Verified with Abhijit.
               *
               * attributesMap = [
               *   { xKey: 'OWNER_ENV', xValue: 'kPhysical' },
               *   ...
               * ]
               *
               * Here we parse the list for the object who's xKey property is
               * 'OWNER_ENV' because it's corresponding xValue property is the
               * 'kType' environment type for the host server.
               */
              some(result.vmDocument.attributeMap,
                function findOwnerEnv(attrMap) {
                  // If the property value owner_env isn't found, this is a
                  // pre-6.0 job and we'll fall back on the initialized value
                  // above.
                  if (!/owner_env/i.test(attrMap.xKey)) { return; }

                  return hostServerType = ENV_TYPE_CONVERSION[attrMap.xValue];
                }
              );
              break;

            case 19:
              objectType = 'oracle';
              const host = (hostEntities || []).find(function findHost(host) {
                return get(host, 'appEntity.entity.id') === thisEntity.parentId;
              });
              dbHostType = get(host, '_appTypeEntity.hostType');
              break;

            default:
              objectType = 'server';
          }
          isHost = 'server' === objectType;

          return dbResults.concat(
            assign(
              commonProcessSearchResult(result, filterVersions),
              {
                _parentSource: parentSource,
                _hostServerType: hostServerType,
                _isHost: isHost,
                _isAagMember: !!(FEATURE_FLAGS.restoreSqlAag &&
                  !isHost &&
                  get(thisEntity, 'sqlEntity.dbAagEntityId')),
                _isPhysicalHost: isHost && isPhysical,
                _isVirtualHost: isHost && !isPhysical,
                _isSystemDatabase: SourceService.isSystemDatabase(thisEntity),
                _isMasterDatabase: /^master$/i.test(
                  get(thisEntity, 'sqlEntity.databaseName')
                ),
                _isTdeDatabase: SourceService.isTdeDatabase(thisEntity),
                _ownerId: (isHost && thisEntity.id) || undefined,
                _snapshotIndex: 0,
                _type: objectType,
                _versions: result.vmDocument.versions,
                _isWindowsHost: dbHostType === ENUM_HOST_TYPE_CONVERSION.kWindows,
                _isSolarisHost: dbHostType === ENUM_HOST_TYPE_CONVERSION.kSolaris,
              }
            )
          );
        },
        []
      )
      .filter(function dbSearchFilter(dbResult) {

        if (dbResult._type === 'oracle') {
          // Clone is not supported for Oracle Windows host type.
          if (dbResult._isWindowsHost && !FEATURE_FLAGS.enableOracleWindowsClone) {
            return !dbResult._isWindowsHost;
          }

          // Clone is not supported for Oracle Solaris host type.
          if (dbResult._isSolarisHost && !FEATURE_FLAGS.enableOracleSolarisClone) {
            return !dbResult._isSolarisHost;
          }
        }

        if (FEATURE_FLAGS.sqlNativeBackup && /clone/.test(type)) {
          return dbResult.__sql_native !== true;
        }

        return true;
      })
      .value();
    }

    /**
     * On-demand transformer of View search results into a more UI-friendly
     * list.
     *
     * @method     transformViewResults
     * @param      {Object}  resp    The server's response
     * @return     {Array}   The list of processed results
     */
    function transformViewResults(resp) {
      resp = resp.data.vms ? resp.data.vms : [];
      if (!resp.length) {
        return [{
          isEmpty: true,
        }];
      }
      return resp.map(commonProcessSearchResult);
    }

    /**
     * On-demand transformer of Mount Point (Physical, VM and HyperV) search
     * results into a more UI-friendly list.
     *
     * @method     transformServerResults
     * @param      {object}  resp    The server's response
     * @return     {Array}   The list of processed results
     */
    function transformServerResults(resp) {
      resp = resp.data.vms ? resp.data.vms : [];

      return resp.reduce(function serverMapperFn(servers, server) {
        var type = server.vmDocument.objectId.entity.type;
        var instantMount = cUtils.onlyNumbers(ENV_GROUPS.instantMount);

        if (FEATURE_FLAGS.instantVolumeMountHyperV) {
          // Entity type = 2 is same for both kHyperV and kHyperVVSS entities
          instantMount.push(2);
        }

        // If the type is allowed to do instant Mount and the entity is not a
        // vApp(9).
        if (instantMount.includes(type) &&
          get(server.vmDocument.objectId.entity, 'vmwareEntity.type') !== 9) {
          servers.push(angular.extend(
            commonProcessSearchResult(server),
            {
              _isPhysical: ENV_GROUPS.physical.includes(type),
              _isVM: ENV_GROUPS.hypervisor.includes(type),
            }
          ));
        }

        return servers;
      }, []);

    }

    /**
     * On-demand transformer of SAN:Pure search results into a more
     * UI-friendly list.
     *
     * @method     transformPureResults
     * @param      {object}  resp    The server's response
     * @return     {Array}   The list of processed results
     */
    function transformPureResults(resp) {
      resp = resp.data.vms || [];
      return resp.map(commonProcessSearchResult);
    }

    /**
     * On-demand transformer of NetApp & NAS search results into a more
     * UI-friendly list.
     *
     * @method     transformNasResults
     * @param      {object}  resp    The server's response
     * @return     {Array}   The list of processed results
     */
    function transformNasResults(resp) {
      resp = resp.data.vms || [];
      return resp.map(commonProcessSearchResult);
    }

    /**
     * On-demand transformer of Storage Volume (which for now is a combination
     * of SAN:Pure and NAS) search results into a more UI-friendly list.
     *
     * @method     transformStorageVolumeResults
     * @param      {object}  resp    The server's response
     * @return     {Array}   The list of processed results
     */
    function transformStorageVolumeResults(resp) {
      resp = resp.data.vms || [];
      return resp.map(commonProcessSearchResult);
    }

    /**
     * Transforms and decorates the search result object with useful props.
     *
     * NOTE: The argument searchType can be used to differentiate if the
     * search is requested for a recovery through browse or entity recovery.
     * TODO(tauseef): Ensure new adapters use the Public API for search.
     *
     * @method   transformPublicSearchResults
     * @param    {object}   searchObjects   Search results returned.
     * @param    {string}   searchType      Specifies the type of Object search
     *                                      'kMailboxSearch', 'kOneDriveSearch'
     *                                      or 'kOneDriveBrowse'.
     * @param    {boolean}  [excludeJobs]   Specifies whether jobs should be
     *                                      included within search results.
     */
    function transformPublicSearchResults(searchObjects, searchType,
      excludeJobs) {
      var isObjectBrowse = searchType === OFFICE365_SEARCH_TYPE.
        kOneDriveBrowse;
      var isOneDriveSearch = searchType === OFFICE365_SEARCH_TYPE.
        kOneDriveSearch;
      var isMailboxSearch = searchType === OFFICE365_SEARCH_TYPE.
        kMailboxSearch;
      var isSharePointBrowse = searchType === OFFICE365_SEARCH_TYPE.
        kSharePointSiteBrowse;
      var snapshotInfoList = searchObjects.data.objectSnapshotInfo;
      var currentEnvironment;
      var currentSnapshottedSource;

      // Object to hold the Jobs.
      var jobMap = {};
      var decoratedSearchObjects = [];

      if (!snapshotInfoList) {
        return [{ message: 'noSearchResults' }];
      }

      decoratedSearchObjects = (snapshotInfoList || []).map(
        function processObjectSnapshot(objectInfo) {
          currentSnapshottedSource = objectInfo.snapshottedSource;
          currentEnvironment = currentSnapshottedSource.environment;

          // Specifies the properties derived at the 1st level of nesting
          // within objectInfo.
          assign(objectInfo, {
            _environment: currentEnvironment,
            _environmentKey: SOURCE_KEYS[currentEnvironment],
            _domain: objectInfo.registeredSource.name,
            _id: currentSnapshottedSource.id,
            _jobName: objectInfo.jobName,
            _jobId: objectInfo.jobId,
            _jobUid: objectInfo.jobUid,
            _lastBackupTime: objectInfo.versions[0].startedTimeUsecs,
            _name: currentSnapshottedSource.name,
            _snapshot: objectInfo.versions[0],

            // This field is needed apart from _id for faster lookups.
            _sourceId: currentSnapshottedSource.id,
            _uuid: currentSnapshottedSource.id + '-' + objectInfo.jobId,
          });

          // Specifies the properties derived at the 2nd level of nesting within
          // objectInfo.
          assign(objectInfo, {
            _envProtectionSource:
              currentSnapshottedSource[objectInfo._environmentKey],
            _type: currentSnapshottedSource[objectInfo._environmentKey].type,
          });

          // Specifies the properties derived at the 3rd level of nesting within
          // objectInfo.
          assign(objectInfo, {
            // TODO(tauseef): Override the icon class incase of OneDrive
            // browse.
            _iconClass: ENUM_ENTITY_ICON_CLASS[currentEnvironment]
              [objectInfo._type],
            _isOffice365User: objectInfo._type === 'kUser',
            _isOffice365SharePointSite: objectInfo._type === 'kSite',
            _tooltip: SOURCE_TYPE_DISPLAY_NAME[
              objectInfo._environment][objectInfo._type],

            // TODO(tauseef): Currently there is no way to figure out whether
            // the given user has a OneDrive backed up. Figure a way out for
            // the same.
            _hasOneDrive: objectInfo._type === 'kUser' && isOneDriveSearch,
            _hasMailbox: objectInfo._type === 'kUser' && isMailboxSearch,

            // Both the SharePoint leaf sites & User's OneDrive are browsable.
            _canBrowseOneDrive:
              objectInfo._type === 'kUser' && isObjectBrowse ||
              objectInfo._type === 'kSite' && isSharePointBrowse,
            _oneDriveId: PubSourceService.getOneDriveId(
              currentSnapshottedSource),

            // Deprectaed field.
            _isMailbox: objectInfo._type === 'kMailbox',
          });

          // Add SharePoint site relative url
          if (objectInfo._isOffice365SharePointSite) {
            assign(objectInfo, {
              _siteRelativeUrl: _constructSharePointSiteRelativeUrl(objectInfo),
            });
          }

          // Add the current object's protection job to the job map.
          if (!jobMap[objectInfo.jobId]) {
            jobMap[objectInfo.jobId] = {
              // Parameters needed for restoring a Job.
              jobId: objectInfo.jobId,
              jobUid: objectInfo.jobUid,

              // Parameters needed for internal manipulation.
              _domain: objectInfo._domain,
              _iconClass: 'icn-protect',
              _isOffice365UserJob: objectInfo._isMailbox ||
                objectInfo._isOffice365User,
              _jobId: objectInfo.jobId,
              _jobType: currentEnvironment,
              _jobUid: objectInfo.jobUid,
              _lastBackupTime: objectInfo._lastBackupTime,
              _name: objectInfo.jobName,

              // TODO(Tauseef): Add details about the Job.
            };
          }

          return objectInfo;
        }
      );

      // Exclude jobs if caller has explicitly set 'excludeJobs' to true or
      // the search results will be used to browse objects.
      if (isObjectBrowse || excludeJobs) {
        return decoratedSearchObjects;
      }
      return Object.values(jobMap).concat(decoratedSearchObjects);
    }

    /**
     * Adds useful properties to the Mailbox Items such as Emails.
     *
     * @method   _transformO365MetaData
     * @param    {object}   file   Specifies the file document.
     * @return   {object}   File document
     */
    function _transformO365MetaData(file) {

      // Decorate Additional properties based on the environment.
      file._iconClass = ENUM_ENTITY_ICON_CLASS[file._environment][file._type];
      file._user = file.protectionSource.name;

      switch (file._type) {
        case 'kEmail':
        case 'kEmailFolder':
          var emailMetaData = file.emailMetaData;

          // TODO(Tauseef): Add properties for Calendar, Contacts, etc.
          return assign(file, {
            _bccRecipientAddress: emailMetaData.bccRecipientAddresses,
            _ccRecipientAddress: emailMetaData.ccRecipientAddresses,
            _isEmail: file._type === 'kEmail',
            _isEmailFolder: file._type === 'kEmailFolder',
            _name: _generateO365ItemName(file),
            _parentFolderKey: emailMetaData.folderKey,
            _receivedTime: emailMetaData.receivedTimeSeconds,
            _recipientAddress: emailMetaData.recipientAddresses,

            // Recovery Id is used for uniquely identifying an item
            // within mailbox. This key is not indexed but only used
            // for recovery.
            _recoveryId: emailMetaData.itemKey,
            _senderAddress: emailMetaData.senderAddress,
            _sentTime: emailMetaData.sentTimeSeconds,
            _directoryPath: emailMetaData.directoryPath,
          });

        case 'kOneDrive':
          // TODO(Tauseef): Add properties for Calendar, Contacts, etc.
          assign(file, {
            _name: _generateO365ItemName(file),
            _relativePath: file.filename,
            _sourceName: file.protectionSource.name,
            _sourceId: file.protectionSource.id,
            _isFile:
              file.oneDriveDocumentMetadata.documentType === 'kFile',
            _isDirectory:
              file.oneDriveDocumentMetadata.documentType === 'kDirectory',
          });

          // Derived properties.
          assign(file, {
            _iconClass: file._isFile ? 'icn-file' : 'icn-folder',
          });

          return file;

        case 'kSharepoint':
          assign(file, {
            _name: _generateO365ItemName(file),
            _relativePath: file.filename,
            _sourceName: file.protectionSource.name,
            _sourceId: file.protectionSource.id,
            _isFile:
              file.sharepointDocumentMetadata.documentType === 'kFile',
            _isDirectory:
              file.sharepointDocumentMetadata.documentType === 'kDirectory',
          });

          // Derived properties.
          assign(file, {
            _iconClass: file._isFile ? 'icn-file' : 'icn-folder',
          });

          return PubRestoreServiceFormatter.transformSharePointMetadata(file);

        default:
            return file;
        }
    }

    /**
     * Constructs SharePoint site's relative url from the object info.
     * Only applicable for SharePoint recovery.
     *
     * @method   _constructSharePointSiteRelativeUrl
     * @param    {object}   objectInfo   The snapshot object info.
     * @return   {string}   Relative web url if any.
     */
    function _constructSharePointSiteRelativeUrl(objectInfo) {
      const absoluteUrl = get(objectInfo, '_envProtectionSource.webUrl');
      if (absoluteUrl) {
        const url = new URL(absoluteUrl);
        return url.pathname;
      }
      return '';
    }

    /**
     * Generates the name of the Office 365 item depending upon the type.
     *
     * @method   _generateO365ItemName
     * @param    {object}   item   Specifies the Office 365 item received from
     *                             Librarian search.
     * @return   {string}   Name of the Office 365 item.
     */
    function _generateO365ItemName(item) {
      switch(item._type) {
        case 'kEmail':
          return item.emailMetaData.emailSubject
            || $translate.instant('emptyEmailSubject');

        case 'kEmailFolder':
          return item.emailMetaData.folderName;
        // TODO(Tauseef): Add other cases for O365 types.
        default:
          return item.filename.substring(item.filename.lastIndexOf('/') + 1,
            item.filename.length);
      }
    }

    /**
     * Attaches a number of commonly referenced values at the top level of
     * this source for convenience. Also pre-filters versions list to only
     * usable (restorable) versions & archiveTargets.
     *
     * @method   commonProcessSearchResult
     * @param    {object}   entity                  The search result entity to
     *                                              augment.
     * @param    {boolean}  [filterVersions=true]   Include all versions and not
     *                                              just restorable versions.
     * @return   {object}                           The augmented result entity.
     */
    function commonProcessSearchResult(entity, filterVersions) {
      var typeDoc = getTypedDocument(entity);
      var normalizedEntity =
        SourceService.normalizeEntity(typeDoc.objectId.entity);
      var typeEntity = SourceService.getTypedEntity(typeDoc.objectId.entity);
      var filteredVersions = RestoreService.getRestorableVersions(
        cloneDeep(typeDoc.versions));

      // If filtering the versions, update the original value, otherwise leave
      // it alone. If this method is called from a map function, the second
      // param will be a number. We should always filter in those cases.
      if (typeof(filterVersions) !== 'boolean' || filterVersions) {
        typeDoc.versions = filteredVersions;
      }

      // This takes the attrobuteMap array and converts each attribute to a
      // _decorator property for easy access.
      // Structure: [{ xKey: 'some_key', xValue: 'the value}, ...]
      assign(entity, reduce(
        typeDoc.attributeMap,
        function keyMapper(decorators, attr) {
          decorators['__' + attr.xKey.replace(/[\W]/g, '_')] = (function() {
            switch (true) {
              // Cast numeric strings to numbers.
              case /^[\d]+$/.test(attr.xValue):
                return Number(attr.xValue);

              // Cast boolean strings to booleans.
              case ['true', 'false'].includes(attr.xValue):
                return !!attr.xValue;

              // Everything else goes as-is.
              default:
                return attr.xValue;
            }
          })();

          return decorators;
        }, {}
      ));

      typeDoc.versions = typeDoc.versions
        // Process all the restorable versions/runs
        .map(function eachVersion(version) {
          var snapshotType = version.snapshotType;
          return angular.extend(version, {
            // These checks based on OBJECT_SNAPSHOT_TYPE.

            // This accounts for undefined or 0
            _isUnknownState: !snapshotType,
            _isAppConsistent: snapshotType === 2 ||
              !!(version.isAppConsistent ||
              version.quiesce ||
              version.instanceId.quiesce),
            _isCrashConsistent: snapshotType === 1,
            _isPoweredOff: snapshotType === 3,
          });
        });

      // Jobs which store snapshots remotely use default viewBoxId just as a
      // pseudo property for successful "create job" call. When this property is
      // returned, it should be removed.
      if (ENV_GROUPS.cloudJobsWithoutLocalSnapshot
        .includes(typeDoc.backupType)) {
          entity.vmDocument.viewBoxId = undefined;
        }

      return angular.extend(entity, {
        // TODO: Find a more accurate way to determine this.
        _isDeleted: /^_deleted_/i.test(typeDoc.jobName),

        // TODO: Are there other ways of detecting this?
        _isInactive:
          typeDoc.objectId.jobId !==
            (typeDoc.objectId.jobUid && typeDoc.objectId.jobUid.objectId),

        // Specifies whether the entity type if kView.
        _isViewEntity: _isEntityOfTypeView(entity),
        _type: typeDoc.objectId.entity.type,
        _jobUid: typeDoc.objectId.jobUid,
        _jobId: typeDoc.objectId.jobId,
        _jobName: typeDoc.jobName,
        _jobType: typeDoc.backupType,

        // TODO(tauseef): Check if this _id can be reduced to just
        // typeDoc.objectId.entity.id
        _id: [typeDoc.objectId.entity.id, typeDoc.objectId.jobId].join('-'),
        _viewBoxId: typeDoc.viewBoxId,

        // TODO(spencer): Deprecate this when nothing is using it. After ENG-11427
        _osType: typeDoc.osType,
        _hostType: SourceService.getEntityHostOSType(typeDoc),
        _name: typeDoc.objectName || typeEntity.name,
        _parentSourceName: !!entity.registeredSource &&
          entity.registeredSource[SourceService.getEntityKey(entity.registeredSource.type)].name,
        _entityKey: SourceService.getEntityKey(typeDoc.objectId.entity.type),
        _entityName: $filter('entityName')(typeDoc.objectId.entity),
        _entityType: $filter('entityType')(typeDoc.objectId.entity),

        // Use the first value from filteredVersions to avoid having an
        // invalid snapshot returned when not filtering the versions
        _snapshot: filteredVersions[0],

        // TODO(spencer): Refactor this obnoxious index out and jsut pass the
        // object.
        _snapshotIndex: 0,

        // This big pile of && checks is meant to accomodate file search on
        // clusters with the yoda gflag set false that omits cloud archive
        // targets from the file search response.
        _archiveTarget: (!!typeDoc.versions[0] &&
          !!typeDoc.versions[0].replicaInfo &&
          Array.isArray(typeDoc.versions[0].replicaInfo.replicaVec) &&
          typeDoc.versions[0].replicaInfo.replicaVec[0]) ||
          undefined,

        // Specifies the source object to recover/clone.
        _protectionSource: typeDoc.objectId.entity,

        // Specifies the id of the leaf object to recover, clone or recover
        // files/folders from.
        _protectionSourceId: typeDoc.objectId.entity.id,

        // A snapshot is uniquely identified by the Job Run id and the time when
        // the capture of the snapshot started.
        // Note: As we already have a _snapshot declared here, naming it as
        //       _pubSnapshot as its used in public API
        _pubSnapshot: SearchServiceFormatter.getPublicSnapshot(
          get(typeDoc, 'versions[0]')
        ),

        // The ID of the top-level parent source that is managing this entity.
        // For example, in a VMware environment, this would be the ID of the
        // vCenter or the standalone ESXi host managing the entity.
        _parentId: typeDoc.objectId.entity.parentId,

        _dataProtocols: normalizedEntity.dataProtocols,
        _volumeType: get(typeDoc.objectId.entity, 'netappEntity.volumeInfo.type'),
      });
    }

    /**
     * Generates a list of db-centric versions based on the databases
     * returned in dbSearch
     *
     * @method     generateDbVersionsList
     * @param      {Array}  dbs     List of vmDoc entities from search
     *                              results
     * @return     {Array}  Compiled versions list
     */
    function generateDbVersionsList(dbs) {
      /**
       * Hash of timstamps containing all dbs with the same timestamp
       *
       * @type       {object}
       * @example
        {
          'timestamp1': [
            { db snapshots }
          ],
          'timestampN': [
            { db snapshots }
          ]
        }
       */
      var hash = {};
      var out = [];
      var dbCount = 0;
      var snapshotCount = 0;
      var ii;
      var jj;
      var snapshot;

      if (dbs && Array.isArray(dbs)) {
        dbCount = dbs.length;
        // Create a hash by timestamp of all db versions at that timestamp
        for (ii = 0; ii < dbCount; ii++) {
          // Check that this is a SQL DB (type 3) and is an array
          if (dbs[ii].vmDocument &&
            3 === dbs[ii].vmDocument.objectId.entity.type &&
            Array.isArray(dbs[ii].vmDocument.versions)) {
              snapshotCount = dbs[ii].vmDocument.versions.length;
              for (jj = 0; jj < snapshotCount; jj++) {
                // Shorthand for the current snapshot in the loop
                snapshot = dbs[ii].vmDocument.versions[jj];
                // if the array for this timestamp doesn't
                // exist, create it
                if (!hash[snapshot.snapshotTimestampUsecs]) {
                  hash[snapshot.snapshotTimestampUsecs] = [];
                }
                hash[snapshot.snapshotTimestampUsecs].push(angular.extend(snapshot, {
                  name: dbs[ii].vmDocument.objectName
                }));
              }
          }
        }
        // Map the hash into an array
        if (Object.keys(hash).length) {
          angular.forEach(hash, function hashCruncherFn(snapshots, timestamp) {
            out.push({
              instanceId: angular.copy(snapshots[0].instanceId),
              snapshotTimestampUsecs: timestamp,
              sqlEntities: snapshots
            });
          });
        }
      }
      return out;
    }

    /**
     * Gets a single remote vault Search Job.
     *
     * @method   getRemoteVaultSearches
     * @param    {object}   id   The searchJobUid.id to get
     * @return   {object}   Promise to resolve with the searches list, or raw
     *                      response if failed.
     */
    function getRemoteVaultSearch(id) {
      var opts = {
        method: 'get',
        url: API.public('remoteVaults/searchJobs', id),
      };

      return $http(opts)
        .then(SearchServiceFormatter.transformJobSearchesResponse);
    }

    /**
     * Gets the remote vault searches.
     *
     * @method   getRemoteVaultSearches
     * @param    {object}   [params]   Optional URL params hash
     * @return   {object}   Promise to resolve with the searches list, or raw
     *                      response if failed.
     */
    function getRemoteVaultSearches(params) {
      var opts = {
        method: 'get',
        params: params,
        url: API.public('remoteVaults/searchJobs'),
      };

      return $http(opts)
        .then(SearchServiceFormatter.transformJobSearchesResponse);
    }

    /**
     * Gets the remote vault search results.
     *
     * @method   getRemoteVaultSearchResults
     * @param    {object}   [params]   The parameters
     * @return   {object}   promise to resovle with the results list, or raw
     *                      server response if failed..
     */
    function getRemoteVaultSearchResults(params) {
      var opts = {
        method: 'get',
        params: params,
        url: API.public('remoteVaults/searchJobResults'),
      };

      return $http(opts)
        .then(SearchServiceFormatter.transformRemoteRestoreSearchResults);
    }

    /**
     * Submit a Remote Vault Search query.
     *
     * @method   submitRemoteSearch
     * @param    {object}   data   Hash of query params
     * @return   {object}   Promsie to resolve with the created remote search
     *                      object, or the raw server response if failed.
     */
    function submitRemoteSearch(data) {
      var opts = {
        method: 'post',
        data: data,
        url: API.public('remoteVaults/searchJobs'),
      };

      return $http(opts).then(
        function searchReceived(resp) {
          return resp.data || {};
        }
      );
    }

    /**
     * Generates a standardized task name for a search task.
     *
     * @method   getSearchTaskName
     * @param    {string}   [seed]   Optional Seed string.
     * @return   {string}   The task name sring.
     */
    function getSearchTaskName(seed) {
      return [
        seed || text.search,
        DateTimeService.msecsToFormattedDate(Date.now())
      ].join('_')
        .replace(/[\s]/gi, '_')
        .replace(/[\W]/gi, '-');
    }

    /**
     * Runs a search for recoverable objects.
     *
     * @method   searchForObjects
     * @param    {object}   params   The parameters
     * @return   {object}   Promise to resolve request for objects
     */
    function searchForObjects(params) {
      return $http({
        method: 'get',
        url: API.public('restore/objects'),
        params: params || {},
      }).then(function searchSuccess(resp) {
        return (resp.data || {}).objectSnapshotInfo || [];
      });
    }

    /**
     * Returns an array of the browsable environment types.
     *
     * @method     getBrowsableEnvironments
     *
     * @return     {array}  The browsable environments, kValues.
     */
    function getBrowsableEnvironments() {
      const browsableEnvironments =
        cUtils.simpleCopy(ENV_GROUPS.fileBrowsable);

      return AdaptorAccessService.filterByAccessibleEnv(
        browsableEnvironments, 'browsing');
    }

    /**
     * Fetches a list of SQL jobs and caches them.
     *
     * @method   cacheSqlJobs
     */
    function cacheSqlJobs() {
      PubJobService.getJobs({
        environments: ['kSQL'],
        onlyReturnBasicSummary: true,
        includeLastRunAndStats: false,
        pruneExcludedSourceIds: true,
      }).then(function setJobsCacheFn(jobs) {
        SQL_JOBS_HASH_CACHE = keyBy(jobs, 'id');
      });
    }

    return service;
  }

})(angular);
