<!-- Date filter -->
<cog-filters class="margin-bottom-md">
  <cog-date-range-filter
    *cogFilterDef="
      let filter;
      let params = params;
      key: 'dateRange';
      label: 'dateRange' | translate;
      filterType: 'dateRange';
      quickFilter: true"
    [filterDefParams]="params"
    [filter]="filter"
    [useTimeframe]="true"
    [selectedRange]="filterDateList"
    [noClear]="true">
  </cog-date-range-filter>
</cog-filters>

<!-- Result table -->
<cog-table name="security-advisor-scan-result-table"
  class="result-table"
  *ngIf="summaryLists"
  [source]="summaryLists">
  <table mat-table matSort matSortActive="startTimeMSecs" matSortDirection="desc">

    <!-- Names -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'name' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="row-name-{{i}}">
        <a>{{ row.name | naLabel }}</a>
      </td>
    </ng-container>

    <!-- Lowest score -->
    <ng-container matColumnDef="lowestScore">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'securityScore' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="row-score-{{i}}">
        <div class="result-container">
          <dg-pa-icon [scoreValue]="row.lowestScore" size="sm"></dg-pa-icon>
          <span class="icon-text">{{ row.lowestScore | naLabel }} </span>
        </div>
      </td>
    </ng-container>

    <!-- Total cluster -->
    <ng-container matColumnDef="totalClusters">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'totalClusters' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="row-clusters-count-{{i}}">
        {{ row.clustersCount | naLabel }}
      </td>
    </ng-container>

    <!-- Total issues -->
    <ng-container matColumnDef="totalIssues">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'totalIssues' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="row-issues-{{i}}">
        {{ row.issuesCount | naLabel }}
      </td>
    </ng-container>

    <!-- Scan time -->
    <ng-container matColumnDef="startTimeMSecs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'scanTime' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="row-scan-time-{{i}}">
        {{ row.startTimeMSecs | cogDate | naLabel }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      class="row-click"
      (click)='scanResultClick.emit(row.scanId)'>
    </tr>

    <!-- Loading Footer -->
    <ng-container matColumnDef="loading">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        <cog-spinner></cog-spinner>
      </td>
    </ng-container>
    <tr class="loading-row" mat-footer-row [class.hidden]="!loading" *matFooterRowDef="['loading']"></tr>
