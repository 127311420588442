import { has } from 'lodash-es';
import { isObject } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
import { envGroups, Environment } from 'src/app/shared';

// Component: Source Group Modal Component

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Resolved bindings provided via uib-modal
       *
       * @type  {Object}  [resolve=undefined]
       */
      resolve: '=',

      /**
       * uib-modal instance used to close or dismiss then modal
       *
       * @type  {Object}  [resolve=modalInstance]
       */
      modalInstance: '=',
    },
    controller: 'SourceGroupModalCtrl',
    templateUrl:
      'app/global/c-source-tree/source-group-modal/source-group-modal.html',
  };

  angular.module('C.pubSourceTree')
    .controller('SourceGroupModalCtrl', SourceGroupModalCtrlFn)
    .component('sourceGroupModal', configOptions);

  function SourceGroupModalCtrlFn(_, evalAJAX, PubSourceService,
    PubJobServiceFormatter, AdaptorAccessService, ENV_GROUPS,
    cUtils, FEATURE_FLAGS) {

    var $ctrl = this;

    assign($ctrl, {
      // Indicate the purpose of usage for sourceGroupModal component i.e. for assignToUser or assignToTenant.
      assignObjectsV2: FEATURE_FLAGS.assignObjectsV2,
      purpose: '',
      isLoading: true,
      isSourcesLoading: false,
      filter: {
        sourceName: undefined,
        sourceType: 'all',
      },

      groupSourceTypesList: [],
      selectedSourceType: '',
      registeredSources: [],
      selectedRegisteredSource: '',

      jobsByEntityIdsMap: {},
      selectedObjects: [],
      rootNodes: [],
      type: 'modal',

      sourceGroupOptions: {
        hasSingleSourceGroup: FEATURE_FLAGS.assignObjectsV2,
        noTypeFilter: true,
        toggleNodeSelection: toggleNodeSelection,
      },
      caches: {
        registeredSources: [],
        rootNodes: {},
      },

      // Component methods.
      cancel: cancel,
      isTreeLoading: isTreeLoading,
      save: save,
      onChangeSourceType: onChangeSourceType,
      onChangeRegisteredSource: onChangeRegisteredSource,

      // Component life-cycle methods.
      $onInit: $onInit,
    });

    /**
     * Initializes the controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      if (isObject($ctrl.resolve.selectedObjects)) {
        $ctrl.selectedObjects = $ctrl.resolve.selectedObjects;
      }

      if ($ctrl.resolve.options) {
        $ctrl.jobsByEntityIdsMap =
          $ctrl.resolve.options.jobsByEntityIdsMap || {};

        $ctrl.hasSid = has($ctrl.resolve.options, 'principal');
        $ctrl.hasTenant = $ctrl.resolve.options.hasOwnProperty('tenant');
      }

      if ($ctrl.hasSid) {
        $ctrl.purpose = 'assignToUser';
      } else if ($ctrl.hasTenant) {
        $ctrl.purpose = 'assignToTenant';
      }

      // show source environment specific filters when source group is used for
      // restricted users to show vCenter folder, VM filter etc.
      if (FEATURE_FLAGS.restrictedUserWithSourceFilters ||
        FEATURE_FLAGS.tenantUserWithSourceFilters) {
        $ctrl.sourceGroupOptions.noFilters = false;
      }

      // init source types
      if ($ctrl.assignObjectsV2) {
        setSourceTypes();
      } else {
        getData();
      }
    }

    /**
     * Gets all registered sources
     *
     * @method   getData
     */
    function getData() {
      $ctrl.isLoading = true;
      let environments = cUtils.onlyStrings(ENV_GROUPS.all);

      if ($ctrl.hasTenant) {
        const { bifrostEnabled, _bifrostCapabilities } = $ctrl.resolve.options.tenant;
        const inputCtx = bifrostEnabled ? {
          isBifrostTenantUser: true,
          BIFROST_CAPABILITIES: _bifrostCapabilities || {},
        } : {};

        environments = AdaptorAccessService.filterByTenantAccessibleEnv(environments, 'backupAndRecovery', inputCtx);
      }

      // get full entity hierarchy from root.
      PubSourceService.getSources(
        undefined,
        {
          environments: environments,
          allUnderHierarchy: true,
          includeEntityPermissionInfo: true,
          includeVMFolders: true,
        },
        {
          jobsByEntityIdsMap: $ctrl.jobsByEntityIdsMap,
          prioritize: true,
        },
      ).then(
        function gotAllSources(sources) {
          $ctrl.rootNodes = sources;
          return performNodeSelection();
        },
        evalAJAX.errorMessage
      ).finally(function finallyGotResponse() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Sets source types
     *
     * @method setSourceTypes
     */
    function setSourceTypes() {
      $ctrl.isLoading = false;

      let environments = cUtils.onlyStrings(ENV_GROUPS.all);

      if ($ctrl.hasTenant) {
        const { bifrostEnabled, _bifrostCapabilities } = $ctrl.resolve.options.tenant;
        const inputCtx = bifrostEnabled ? {
          isBifrostTenantUser: true,
          BIFROST_CAPABILITIES: _bifrostCapabilities || {},
        } : {};

        environments = AdaptorAccessService.filterByTenantAccessibleEnv(environments, 'backupAndRecovery', inputCtx);
      }

      // ensure uniqueness wit set.
      $ctrl.groupSourceTypesList = groupSourceTypes([...new Set(environments)]);
    }

    /**
     * Groups source types into virtual machines, NAS, office 365 etc.
     *
     * @method groupSourceTypes
     * @param environments list of unique environments
     */
    function groupSourceTypes(environments) {
      return environments.reduce((groups, sourceType) => {
        let groupName = '';

        Object.keys(envGroups).forEach((key) => {
          const groupValues = envGroups[key];
          if (groupValues.includes(sourceType)) {
            groupName = key;
          }
        });

        if (!groupName) {
          groupName = 'others'
        }

        return [...groups, {
          groupName,
          sourceType
        }];

      }, []);
    }

    /**
     * Source type change handler
     *
     * @method onChangeSourceType
     */
    function onChangeSourceType() {
      const { sourceType } = $ctrl.selectedSourceType;
      $ctrl.selectedRegisteredSource = '';
      $ctrl.registeredSources = [];
      $ctrl.rootNodes = [];

      if (sourceType) {
        // check in cache first
        const cachedRegisteredSources = $ctrl
          .caches
          .registeredSources[sourceType];

        if (cachedRegisteredSources) {
          $ctrl.registeredSources = cachedRegisteredSources;
        } else {
          // make api call
          getRegisteredSourcesBySourceType(sourceType);
        }
      }
    }

    /**
     * Returns registered sources based on list of environments.
     * @method getRegisteredSourcesBySourceType
     * @param sourceType type of source vm, o365 etc
     */
     function getRegisteredSourcesBySourceType(sourceType) {
      $ctrl.isSourcesLoading = true;
      PubSourceService.getRootNodes(
        {
          environments: [sourceType],
        },
      ).then(
        (sources) => {
          const registeredSources = sources
            .map((source) => source['protectionSource']);

          $ctrl.registeredSources = registeredSources;
          // set cache
          $ctrl.caches.registeredSources[sourceType] = registeredSources;
        },
        evalAJAX.errorMessage
      ).finally(function finallyGotResponse() {
        $ctrl.isSourcesLoading = false;
      });
    }

    /**
     * Source change handler
     *
     * @method onChangeRegisteredSource
     * @param sourceId id of the source
     */
    async function onChangeRegisteredSource(sourceId) {
      if (sourceId) {
        // check in cache first
        const cachedRootNodes = $ctrl.caches.rootNodes[sourceId];
        $ctrl.rootNodes = [];

        if (cachedRootNodes) {
          $ctrl.rootNodes = cachedRootNodes;
          await performNodeSelection();
        } else {
          // make api call
          await getSourceNodesById(sourceId);
        }
      }
    }

    /**
     * getSourceNodes returns list of all nodes based on id
     * @method getSourceNodesById
     * @param sourceId source id
     * @param environments list of supported environments
     */
     function getSourceNodesById(sourceId) {
      $ctrl.isLoading = true;

      // get full entity hierarchy from root.
      PubSourceService.getSources(
        undefined,
        {
          id: sourceId,
          allUnderHierarchy: true,
          includeEntityPermissionInfo: true,
          includeVMFolders: true,
          pruneNonCriticalInfo: $ctrl.assignObjectsV2
        },
        {jobsByEntityIdsMap: $ctrl.jobsByEntityIdsMap}
      ).then((sources) => {
          $ctrl.rootNodes = sources;

          // set local cache
          $ctrl.caches.rootNodes[sourceId] = sources;

          // update full tree of nodes
          // for selected nodes view
          if ($ctrl.assignObjectsV2) {
            PubSourceService.addRootNodes(sources[0]);
          }

          return performNodeSelection();
        },
        evalAJAX.errorMessage
      ).finally(function finallyGotResponse() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Select all tenant assigned source nodes in sources group and keep a map
     * of selected ancestor nodes for quick lookup.
     *
     * @method   performNodeSelection
     */
    async function performNodeSelection() {
      var selectedNodeIdsMap = {};
      var selectedNodesByParentIdsMap = {};
      var selectedDuplicateNodeIdsMap = {};

      // create a map of selected node and there group by parent root node
      $ctrl.selectedObjects.forEach(function eachNode(node) {
        var parentId =
          node.protectionSource.parentId || node.protectionSource.id;

        selectedNodeIdsMap[node.protectionSource.id] = node;

        if (!selectedNodesByParentIdsMap[parentId]) {
          selectedNodesByParentIdsMap[parentId] = {};
        }

        // map of selected node by there root node
        selectedNodesByParentIdsMap[parentId][node.protectionSource.id] = node;
      });

      // select the root node if selected and keep the map of selected ancestor
      // nodes used by c-source-tree-pub to select them when descendant node are
      // loaded asynchronously.
      PubJobServiceFormatter.forEachNode($ctrl.rootNodes,
        function eachNode(node) {
          if (selectedNodeIdsMap[node.protectionSource.id]) {
            node._isSelected = true;
            node._selectedAncestor = true;

            PubJobServiceFormatter.forEachNode(
              node.nodes,
              function eachChildrenNode(childrenNode) {
                childrenNode._isSelected = true;

                // collecting the selected duplicate node and selecting them
                // later in an another loop below because duplicate node's can
                // be reached from different hierarchy and selecting them here
                // would need us to traverse the tree for each each duplicate
                // node which is not optimal.
                if (childrenNode._isDuplicate) {
                    selectedDuplicateNodeIdsMap[
                      childrenNode.protectionSource.id] = true;
                }
              }
            );
          }

          if (selectedNodesByParentIdsMap[node.protectionSource.id]) {
            node._selectedAncestorIdsMap =
              selectedNodesByParentIdsMap[node.protectionSource.id];
          }
        }
      );

      // selecting the duplicate node.
      await PubJobServiceFormatter.forEachNodeAsync($ctrl.rootNodes,
        async function eachNode(node, index, list, path) {
          await PubJobServiceFormatter.decorateSourceForAssignment(node, {
            purpose: $ctrl.purpose,
            pathToRoot: path,
            ...$ctrl.resolve.options,
            tenantId: get($ctrl.resolve.options, 'tenant.tenantId'),
          });

          if (selectedDuplicateNodeIdsMap[node.protectionSource.id]) {
            node._isSelected = true;
          }
        }
      );
    }

    /**
     * Toggle node ancestor node selection.
     * case 1: if ancestor node is selected then select all its descendant nodes
     * case 2: if an ancestor node is un-selected then un-select the all the
     *         parent nodes and mark sibling nodes as selected ancestor node.
     *
     * @method   toggleAncestorNodeSelection
     * @param    {Object}     node            The node to toggle
     * @param    {Function}   getPathToNode   Function that returns path to the
     * node provided treecontrol under scope.$path
     */
    function toggleNodeSelection(node, getPathToNode) {
      // list of node from touched node to root
      var pathToRoot = getPathToNode();

      // toggle node selection and if selected make him an selected ancestor.
      node._isSelected = !node._isSelected;
      node._selectedAncestor = node._isSelected;

      // Sync duplicate nodes if one of them get unselected or selected.
      // currently duplicates nodes can be found for vCenter host group,
      // resource pool or folder.
      if (node._isDuplicate || node._isApplicationHost) {
        syncToDuplicateSourceNodes(node);
      } else {
        const descendantDuplicateNodes = [];
        // toggle node selection for each descendant nodes
        PubJobServiceFormatter.forEachNode(node.nodes || [],
          function eachNode(descendantNode) {
            // skip tags since they are not selectable
            if (descendantNode._type === 'kTagCategory') {
              return;
            }

            // when un-selecting and we can't remove descendant node then show the un-assignment error message.
            if (!node._isSelected && !descendantNode._canRemove.result) {
              if (descendantNode.entityPermissionInfo ||
                (descendantNode._owner.isInferred && !descendantNode._owner.hasAssignedAncestor)) {
                descendantNode._showUnselectionError = true;
              }
              return;
            }

            descendantNode._selectedAncestor = false;
            descendantNode._isSelected = node._isSelected;
            if (descendantNode._isDuplicate) {
              descendantDuplicateNodes.push(descendantNode);
            }
          }
        );

        syncToDuplicateSourceNodes(descendantDuplicateNodes);
        updateAncestors(node, pathToRoot);
      }
    }

    /**
     * Sync node properties to it's duplicate node
     *
     * @method   syncToDuplicateSourceNodes
     * @param    {Object}     nodes            nodes to sync
     */
    function syncToDuplicateSourceNodes(nodes) {
      PubJobServiceFormatter.syncDuplicateSourceNodes(
        nodes, $ctrl.rootNodes,
        function eachDuplicateNode(node, path) {
          // preparing the path to reach root node from current node to root
          // node.
          var pathToRootForDuplicateNode = [...path, node].reverse();

          // Updating duplicates nodes ancestors as they are lying under some
          // other hierarchy.
          updateAncestors(node, pathToRootForDuplicateNode);
        },

        // Sync properties from current node to other duplicate nodes.
        true
      );
    }

    /**
     * update ancestors nodes if currenlty node is toggled.
     *
     * @method   updateAncestors
     * @param    {Object}     node            The node to toggle
     * @param    {Function}   pathToRoot   Path to Reach RootNode.
     */
    function updateAncestors(node, pathToRoot) {
      // If node is un-selected then un-select all the parent nodes till root
      // since now they have some nodes un-selected and mark the sibling nodes
      // as selected ancestor.
      if (!node._isSelected) {
        pathToRoot.forEach(function eachNode(intermediateNode, index) {
          // skip the toggled node
          if (!index) {
            return;
          }

          intermediateNode._isSelected = false;
          intermediateNode._selectedAncestor = false;

          // Make selected children of intermediate node as selected ancestor
          // since there parent is now having some un-selected descendant node
          (intermediateNode.nodes || []).forEach(
            function eachChildrenNode(childrenNode) {
              childrenNode._selectedAncestor = childrenNode._isSelected;
            }
          );
        });
      }
    }

    /**
     * Determines if root nodes children are getting loaded during that time
     * save button need to be disabled.
     *
     * @method   isTreeLoading
     * @return   {Boolean}   Return true when provided root nodes are getting
     *                       loaded else false
     */
    function isTreeLoading() {
      return $ctrl.rootNodes.reduce(function eachRootNode(result, rootNode) {
        if (!result) {
          result = rootNode._loadingChildren;
        }

        return result;
      }, false);
    }

    /**
     * handles on save action for modal
     *
     * @method   save
     */
    function save() {
      var selectedSubTree = [];
      var selectedNodesMap = {};

      // Find selected ancestor nodes.
      $ctrl.rootNodes.forEach(function eachRootNode(rootNode) {
        // early exit if root node is selected
        if (rootNode._selectedAncestor) {
          // In Generic NAS the root Node holds no significance as it is just
          // a prop for grouping the mount points under it. If user
          // selects the root node, then we filter out nodes that are already
          // selected before assigning to the current tenant.
          if (rootNode._environment === Environment.kGenericNas) {
            // we do not want to add root Node for generic NAS to the nodes map
            // as it is a dummy node.
            rootNode._selectedAncestor = false;
            rootNode.nodes.forEach(node => {
              selectedNodesMap[node.protectionSource.id] = node;
              node._selectedAncestor = true;
            })
          } else {
            selectedNodesMap[rootNode.protectionSource.id] = rootNode;
          }
        } else {
          // search for selected node and collect them in selectedNodesMap
          PubJobServiceFormatter.forEachNode(rootNode.nodes,
            function eachNode(node) {
              if (node._selectedAncestor) {
                selectedNodesMap[node.protectionSource.id] = node;
              }
            }
          );
        }
      });

      // filter out the assigned nodes.
      selectedSubTree = PubJobServiceFormatter.filterNodes($ctrl.rootNodes,
        function eachNode(node) {
          return selectedNodesMap[node.protectionSource.id];
        }
      );

      $ctrl.modalInstance.close(selectedSubTree);
    }

    /**
     * handles on cancel action for modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.modalInstance.dismiss();
    }
  }

})(angular);
