import { parseInt } from 'lodash-es';
import { isNumber } from 'lodash-es';
import { every } from 'lodash-es';
import { get } from 'lodash-es';
// Service: Cluster Service Formatter
import { FailureDomainPropKeys } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('ClusterServiceFormatter', ClusterServiceFormatterFn);

  function ClusterServiceFormatterFn(_, FORMATS, $translate, localStorageService) {

    var allEcOptionsMap;
    var nonLRCEcOptionsMap;

    /**
     * Hash of named installation Types and the clusterInfo.clusterType
     * enum properties that relate to them.
     *
     * @type  {Object}
     */
    var clusterInstallTypes = {
      cloud: ['kMicrosoftCloud', 'kAmazonCloud', 'kGoogleCloud'],
      // QUESTION(*): Is there a more meaningful name for this one now that
      // we're not using 'kAzure'?
      azure: ['kMicrosoftCloud'],
      amazon: ['kAmazonCloud'],
      google: ['kGoogleCloud'],
      physical: ['kPhysical'],
      vROBO: ['kVirtualRobo'],
    };

    // This Service's API
    return {
      parseSoftwareVersionString: parseSoftwareVersionString,
      transformClusterInfo: transformClusterInfo,
    };

    /**
     * Parse the cluster software version
     *
     * @example:-
     *  5.0.2b_release-20180611_61682f32
     *  {
          version: '5.0.2b',
          versionObj: {
            major: 5,
            minor: 0,
            patch: '2b',
          },
          buildType: 'release',
          date: '20180611',
          dateObj: JS Date object,
          hash: '61682f32',
     *  }
     *
     * @method   parseSoftwareVersionString
     * @param    {String}   softwareVersionString   The software version
     * @return   {Object}   Return the parsed software version
     */
    function parseSoftwareVersionString(softwareVersionString) {
      if (!softwareVersionString || !angular.isString(softwareVersionString)) {
        return {};
      }
      var versionMaster = /^master/i;
      var mainParts = softwareVersionString.split('-');

      var versionAndBuildType = mainParts[0].split('_');
      var version = versionAndBuildType.slice(0, -1).join('_');
      var versionParts = version.split('.');
      var vMajor = parseInt(versionParts[0]);
      var vMinor = parseInt(versionParts[1]);
      var patchVersionParts = (versionParts[2] || '').split('_');
      var versionObj = {
        major: isNumber(vMajor) ? vMajor : undefined,
        minor: isNumber(vMinor) ? vMinor : undefined,

        // Get patch version, Ex:(0a_p1 -> 0a), (0a_spl_jpmc_2 -> 0a), (0a -> 0a)
        patch: patchVersionParts[0],
        isMaster: versionMaster.test(version),

        // Hotfix version. Ex:(0a_p1 -> p1), (0a_spl_jpmc_2 -> spl_jpmc_2)
        hotFix: patchVersionParts.slice(1).join('_')
      };
      var buildType = versionAndBuildType[versionAndBuildType.length - 1];
      var dateAndHash = mainParts[1].split('_');
      var date = dateAndHash[0];
      var dateObj = moment(date, 'YYYYMMDD');
      var hash = dateAndHash[1];
      return {
        version: version,
        versionObj: versionObj,
        buildType: buildType,
        date: date,
        dateObj: dateObj,
        hash: hash,
      };
    }

    /**
     * Transforms the Cluster Info object by defaulting values and adding
     * convenience properties and returns the same object.
     *
     * @method     transformClusterInfo
     * @param      {Object}  clusterInfo    The Cluster Info object
     * @return     {Object}  The same object with added and transformed data
     */
    function transformClusterInfo(clusterInfo) {
      if (!clusterInfo) {
        return;
      }

      // TODO: do this check using clusterType but as iris-backend doesn't
      // support the virtual editon cluster yet.
      var hardwareModels = get(clusterInfo, 'hardwareInfo.hardwareModels') || [];

      var _isVirtualEditionCluster = !!every(hardwareModels,
        function findVirtualEditionModel(model) {
          return FORMATS.virtualEditionCluster.test(model);
        });

      // ensure clusterInfo has a supportedConfig{} and supportedErasureCoding[]
      angular.merge(clusterInfo, {
        supportedConfig: { supportedErasureCoding: [] }
      });

      var failureDomainType = clusterInfo.faultToleranceLevel || 'kNode';
      clusterInfo._failureDomainCount =
        clusterInfo[FailureDomainPropKeys[failureDomainType]] || 0;

      var supportedConfig = clusterInfo.supportedConfig;

      // Add a map of supported EC options, hashed by coded stripes.
      if (supportedConfig.supportedErasureCoding) {
        supportedConfig._ecOptionsMap = _getSupportedEcOptionsMap(
          supportedConfig.supportedErasureCoding, clusterInfo, true);
        supportedConfig._nonLRCEcOptionsMap = _getSupportedEcOptionsMap(
          supportedConfig.supportedErasureCoding, clusterInfo, false);
      }

      clusterInfo._isUpgrading = /kUpgrad/.test(clusterInfo.currentOperation);

      // Default Custom Failure Domain to 'node'.
      clusterInfo.failureType = clusterInfo.failureType || 'node';

      return angular.extend(clusterInfo, {
        // As the installable environments grow, modify these arrays
        // accordingly
        _isPhysicalInstall:
          (clusterInstallTypes.physical.includes(clusterInfo.clusterType)),
        _isCloudInstall: (clusterInstallTypes.cloud.includes(clusterInfo.clusterType)),
        _isAWSInstall: (clusterInstallTypes.amazon.includes(clusterInfo.clusterType)),
        _isGCPInstall: (clusterInstallTypes.google.includes(clusterInfo.clusterType)),
        _isAzureInstall: (clusterInstallTypes.azure.includes(clusterInfo.clusterType)),
        _isVirtualRobo: (clusterInstallTypes.vROBO.includes(clusterInfo.clusterType)),
        _isVirtualEditionCluster: _isVirtualEditionCluster,
        _clusterSoftwareVersion: parseSoftwareVersionString(
          clusterInfo.clusterSoftwareVersion),

        _hardwareModels: !_isVirtualEditionCluster
          ? [...new Set([...hardwareModels].sort())].join(', ')
          : $translate.instant('virtualEditionCluster'),

        // If the hardware model is PXG1, then it is Disaggregated Storage.
        // NOTE: There is now a iris-context-util (isFlashRecover) which
        // should be used/updated for this determination.
        _isDisaggregatedStorage: hardwareModels.includes('PXG1') || hardwareModels.includes('PXG2'),

        // True if all the hardware models of cluster begin with 'c'.
        _isCSeriesPlatform: hardwareModels.every(
          function checkCSeriesPlatform(hardwareModel) {
            return hardwareModel.toLowerCase().startsWith('c');
          })
      });
    }

    /**
     * Creates map of supported EC options for the ui-select.
     *
     * @method    _getSupportedEcOptionsMap
     * @param     {Object}    supportedEcConfigs   list of EC options
     * @param     {Number}    clusterInfo   ClusterInfo object.
     * @param     {boolean}   showLRCCodes  whether to show LRC EC Codes
     * @return    {Object}    ecOptionsMap hashed by `codedStripes`.
     */
    function _getSupportedEcOptionsMap(supportedEcConfigs, clusterInfo, showLRCCodes) {
      var ecOptionsMap = showLRCCodes ? allEcOptionsMap : nonLRCEcOptionsMap;
      if (!ecOptionsMap) {
        ecOptionsMap = {
          '1:1': [],
          '2:1': [],
          '2:2': [],
          '3:1': [],
          '3:2': [],
          '3:3': [],
        };
        supportedEcConfigs = supportedEcConfigs || [];
        // Creating the map of EC options, hashed by coded stripes.
        supportedEcConfigs.forEach(function mapEcConfig(item) {
          var stripesArray = item.split(":");
          var dataStripes = +stripesArray[0];
          var codedStripes = +stripesArray[1];
          var ecOption = {
            display: item,
            dataStripes: dataStripes,
            codedStripes: codedStripes,
            usableStoragePct:
              Math.round((dataStripes / (dataStripes + codedStripes)) * 100),
          };
          if (codedStripes === 1) {
            // All provided X:1 options are available to 1d:1n
            ecOptionsMap['1:1'].push(ecOption);
          } else if (codedStripes === 2) {
            // All provided X:2 options are available to 2d:1n
            ecOptionsMap['2:1'].push(ecOption);
            // For 2d:2n, the only allowed options are those wherein number of
            // failure domains is greater than or equal to `coded + data` stripes.
            if (clusterInfo._failureDomainCount >= codedStripes + dataStripes) {
              ecOptionsMap['2:2'].push(ecOption);
            }
          } else if (codedStripes == 3) {
            // All provided X:3 options are available to 3d:1n
            ecOptionsMap['3:1'].push(ecOption);
            // For 3d:2n and 3d:3n the only allowed options are those wherein
            // number of failure domains is greater than or equal to `coded +
            // data` stripes.
            if (clusterInfo._failureDomainCount >= codedStripes + dataStripes) {
              ecOptionsMap['3:2'].push(ecOption);
              ecOptionsMap['3:3'].push(ecOption);
            }
          } else if (codedStripes == 4) {
            if (!showLRCCodes) {
              return;
            }
            // All provided X:4 options are available to 3d:1n
            ecOptionsMap['3:1'].push(ecOption);
            // For 3d:2n the only allowed options are those wherein number of failure
            // domains is greater than or equal to `coded + data - 1` stripes.
            if (clusterInfo._failureDomainCount >= codedStripes + dataStripes - 1) {
              ecOptionsMap['3:2'].push(ecOption);
            } else if (clusterInfo._failureDomainCount >= codedStripes + dataStripes) {
              // For 3d:3n the only allowed options are those wherein number of
              // failure domains is greater than or equal to `coded + data` stripes.
              ecOptionsMap['3:3'].push(ecOption);
            }
          }
        });
      }
      return ecOptionsMap;
    }
  }

})(angular);
