<cog-settings-list-item
  [formGroup]="formGroup"
  [invalid]="indexingFormGroup.invalid"
  [label]="'indexing' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="indexingFormGroup.value.enabled">
      <span *ngIf="!properties.hideIncludeExcludePaths">
        {{'protectionGroups.enabled' | translate: {
          included: getPathCount('include') | number,
          excluded: getPathCount('exclude') | number}
        }}
      </span>
      <span *ngIf="properties.hideIncludeExcludePaths">
        {{'enabled' | translate}}
      </span>
    </ng-container>
    <ng-container *ngIf="!indexingFormGroup.value.enabled">
      {{'disabled' | translate}}
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="indexingFormGroup"
    cogDataId="settings-indexing">
    <mat-slide-toggle [inheritDataId]="true"
      cogDataId="indexing-toggle"
      formControlName="enabled">
      {{'indexing' | translate}}
    </mat-slide-toggle>
    <mat-hint *ngIf="isIndexingEnabledByDefault">
      {{ getIndexingHint() | translate }}
    </mat-hint>
    <!-- Warning message if indexing is enabled on Vmware CAD job -->
    <cog-banner status="warn" class="margin-top"
      *ngIf="(indexingFormGroup.get('enabled').value && isCadPolicy)">
      {{ getIndexingHint() | translate }}
    </cog-banner>

    <!-- If SnapMirrorBackup is configured, present warning for changes to subsequent backups -->
    <div class="c-message-inline icon-message margin-top" *ngIf="isSnapMirrorBackup">
      {{'protectionGroups.indexing.snapMirrorWarning' | translate}}
    </div>

    <!-- Show include and exclude sections -->
    <ng-container
      *ngIf="indexingFormGroup.get('enabled').value && !properties.hideIncludeExcludePaths && !isSnapMirrorBackup">
      <coh-include-exclude-paths [defaultPaths]="indexingFormGroup.get('paths').value"
        formControlName="paths">
      </coh-include-exclude-paths>
    </ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
