// Module: Test Dev Content
import { getConfigByKey } from '@cohesity/iris-core';

;(function(angular) {
  'use strict';

  angular
    .module('C.devOps', ['C.devOpsService', 'C.cloneButton', 'C.dbRestore',
      'C.cloneRefresh'])
    .config(stateConfigFn)
    .controller('DevOpsContentCtrl', devOpsContentCtrlFn)
    .component('cDevopsContent', {
      templateUrl: 'app/devops/devops.html',
      controller: 'DevOpsContentCtrl',
    });

  //TODO: Create clone-list component for devops.clone
  function stateConfigFn($stateProvider) {
    var noAccessToTenant = '$root.isTenantUser() ? false : ';
    var kuiperAccess = noAccessToTenant +
      'CLONE_VIEW && FEATURE_FLAGS.testDevEnabled';

    $stateProvider
      .state('devops', {
        url: '/devops/test-dev',
        canAccess: getAccessExpressionForTabs(),
        component: 'cDevopsContent',
      })
      .state('devops.clone', {
        url: '^/devops/test-dev/clone',
        help: 'devops_clone',
        title: 'Clone Summary',
        canAccess: (stateAccessContext) => stateAccessContext['CLONE_VIEW'] &&
          getConfigByKey(stateAccessContext.irisContext, 'devops.features.allow', true),
        params: {
          cloneDateRange: { type: 'string', value: null },
        },
        parentState: 'devops',
        views: {
          'tab-view': {
            component: 'cCloneSummary',
          },
        },
      })
      .state('devops.sources', {
        title: 'VM Sources',
        url: '^/devops/test-dev/sources',
        canAccess: kuiperAccess,
        parentState: 'devops',
        views: {
          'tab-view': {
            component: 'cKuiperVmSourceList',
          },
        },
      })
      .state('devops-hypervisor', {
        title: 'Hypervisor Summary',
        url: '^/devops/test-dev/vms?' + [
          '{vmState}'
        ].join('&'),
        params: {
          vmState: {
            type: 'string',
            value: '',
            dynamic: true,
            squash: true,
          },
        },
        canAccess: kuiperAccess,
        parentState: 'devops',
        component: 'cHypervisorVm'
      })
      .state('launch-console', {
        title: 'Launch Console',
        url: '^/devops/test-dev/console/{uuid}',
        canAccess: kuiperAccess,
        parentState: 'devops',
        params: {
          uuid: { type: 'string' },
        },
        // TODO: investigate cleaning up this state, its never been turned on
        // and seems abandoned. If cleaned up, can clean up associated ng-class
        // assignment in index.html.
        noHeaderFooter: true,
        component: 'cKuiperLaunchConsole',
      });
  }

  /**
   * tab config
   * heading is translated into respective text by tabs directive.
   * route is the state name
   *
   * @type    {Array}
   */
  var devOpsTabsConfig = [
    {
      'headingKey': 'vmSources',
      'route': 'devops.sources',
      'default': true,
    },
    {
      'headingKey': 'tasks',
      'route': 'devops.clone',
    },
  ];

  /**
   * Returns an expression which will indicate if the currently logged in user
   * can access any one of the tabs included in the set. if user can't access
   * any of the tabs, the entire tabbed page should/will be hidden.
   *
   * @method   getAccessExpressionForTabs
   * @return   {String}   The canAccess expression for tabs.
   */
  function getAccessExpressionForTabs() {
    return 'canUserAccessAnyTab(' + JSON.stringify(devOpsTabsConfig) + ')';
  }

  function devOpsContentCtrlFn($state, FEATURE_FLAGS, UserService,
    NgStateManagementService) {

    var $ctrl = this;
    var defaultTab;

    $ctrl.devOpsTabsConfig = getDevopsTabsConfig();

    if ($state.current.name === 'devops') {
      defaultTab =
        NgStateManagementService.getDefaultTab($ctrl.devOpsTabsConfig);
      $state.go(defaultTab.route);
    }

    /** Filters the devopsTabsConfig on the basis of feature flag
     *
     * @method   getDevopsTabsConfig
     * @return   {Array}   returns the filtered list of tabs config
     */
    function getDevopsTabsConfig() {
      if(!FEATURE_FLAGS.testDevEnabled || UserService.isTenantUser()) {
        return devOpsTabsConfig.filter(
          function removeKuiperTab(value, index, arr) {
            return value.route != 'devops.sources';
        });
      }
      return devOpsTabsConfig;
    }
  }
})(angular);
