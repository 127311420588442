import { inject, Injectable } from '@angular/core';
import { User } from '@cohesity/api/v1';
import { AnalyticsEnvironment, CoreAnalyticsService, EventProperties, IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';
import { isEmpty, omitBy } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';
import { environment } from 'src/environments/environment';

import { BroadcastChannelService } from './broadcast-channel.service';

/**
 * Keys used by intercom projects to load the SDK.
 */
const intercomKeys = {
  [AnalyticsEnvironment.development]: 'nbc4locn',
  [AnalyticsEnvironment.production]: 'k9ja36ud',
};

@Injectable({
  providedIn: 'root',
})
export class HeliosAnalyticsService extends CoreAnalyticsService {
  /**
   * Behavior subject to hold whether intercom is enabled.
   *
   * AdBlockers prevent intercom (and mixpanel) from loading, in that case the
   * UI may need to show differently (such as showing regular help over intercom
   * messenger help).
   */
  private _intercomEnabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable for whether intercom is enabled.
   */
  intercomEnabled$ = this._intercomEnabled$.asObservable();

  /**
   * Behavior subject to hold the current unread messages count.
   */
  private _intercomUnreadCount$ = new BehaviorSubject<number>(0);

  /**
   * Observable to emit the current unread messages count.
   */
  intercomUnreadCount$ = this._intercomUnreadCount$.asObservable();

  /**
   * Whether the intercom messenger is currently visible.
   */
  private intercomMessengerVisible = false;

  /**
   * Transition hook of intercom. Calling this function clears the hook.
   */
  private intercomTransitionHook: Function;

  /**
   * Specifies whether the UI is running in IBM Aqua mode.
   */
  private isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  constructor(
    private appServiceManager: AppServiceManagerService,
    private broadcastChannelService: BroadcastChannelService,
    protected irisContextService: IrisContextService,
    private transitionService: TransitionService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super();
  }

  /**
   * Toggle visibility of intercom messenger.
   */
  toggleIntercomMessenger() {
    window.Intercom?.(this.intercomMessengerVisible ? 'hide' : 'show');
  }

  /**
   * The default properties for a tracking event.
   *
   * @return The default properties.
   */
  override getDefaultProperties(): EventProperties {
    const fromUrlObject = new URL(window.location.href);

    return {
      scopeName: this.appServiceManager.getScopeName(),
      searchParams: omitBy(this.uiRouterGlobals.params, isEmpty),
      stateName: this.uiRouterGlobals.current.name,
      pageUrl: `${fromUrlObject.origin}${fromUrlObject.pathname}`,
    };
  }

  /**
   * Load and initialize analytics bundles.
   *
   * @param user The user object which has the user details.
   */
  initHeliosAnalytics(user: User) {
    if (this.isIBMAquaEnv || !this.analyticsEnabled) {
      // Currently, this is only loaded for specific users.
      return;
    }

    // Track when the user logs in.
    this.broadcastChannelService.subscribe('heliosUserLoggedIn', () => {
      // Wait for user to be identified and load before sending the logged in
      // event.
      this.userIdentified.pipe(
        filter(value => Boolean(value)),
        take(1)
      ).subscribe(() => {
        this.track('user_logged_in');
        this.analyticsTrack('User Logged In', this.getDefaultProperties(), false);
      });
    });

    // Clear analytics when the logged-in user logs out.
    this.broadcastChannelService.subscribe('sessionDestroyed', () => {
      this.terminateHeliosAnalytics();
    });

    if (this.intercomEnabled) {
      // Initialize intercom after it is loaded.
      this.setIntercom(user);
    }

    // initialize the analytics configuration
    this.initializeAnalytics(environment.production);

    // Track state transition as both a page view and an event.
    this.listenTransitionEvents();
  }

  /**
   * Function to setup user details for analytics.
   */
  override setupUser() {
    return combineLatest([
      this.irisContextService.irisContext$,
      this.intercomEnabled$,
    ]).pipe(
      // Only setup user details when a scope is selected (app is fully loaded)
      // and intercom sdk is loaded.
      filter(([irisContext, intercomEnabled]) =>
        !isEmpty(irisContext.selectedScope) && intercomEnabled
      ),

      // Map to scope name
      map(() => this.appServiceManager.getScopeName()),

      // Only emit when scope is changed
      distinctUntilChanged(),
    ).subscribe((scopeName: string) => {
      // Shut down existing Intercom. this.identifyUser will restart Intercom
      // with correct user scope.
      window.Intercom?.('shutdown');

      // Wait on the user to be identified.
      this.identifyUser(scopeName);

      // Reinitialize intercom as after shutdown, existing hooks for
      // intercom's lifecycle method don't work.
      this.initIntercom();
    });
  }

  /**
   * Function to terminate analytics.
   */
  private terminateHeliosAnalytics() {
    this.track('user_logged_out');
    this.analyticsTrack('User Logged Out', this.getDefaultProperties(), false);
    this.terminateAnalytics();
    window.Intercom?.('shutdown');
  }

  /**
   * hooks into transition service for page transition events
   */
  private listenTransitionEvents() {
    // Track state transition as both a page view and an event.
    this.transitionService.onSuccess({}, transition => {
      if (!this.userIdentified.value) {
        return;
      }

      const to = (transition.to() || {}) as any;
      const from = (transition.from() || {}) as any;
      const params = (transition.params() || {}) as any;

      // TODO: This comment is no longer active.
      // In addition to "window.analytics.page", which tracks page views as a
      // special event for analytics tool, also track it as a regular event
      // so that these events make it to data warehouses - which only have
      // access to the regular events and not the special events.
      this.track('page_transition', {
        value: to.title,
        from: from.title,
        hasIdInUrl: Boolean(params.id),
        legacyEvent: true,
      });

      const fromUrl = window.location.href;
      const fromUrlObject = new URL(fromUrl);
      const fromPageUrl = `${fromUrlObject.origin}${fromUrlObject.pathname}`;

      setTimeout(() => {
        const valueUrl = window.location.href;
        const valueUrlObject = new URL(valueUrl);
        const valuePageUrl = `${valueUrlObject.origin}${valueUrlObject.pathname}`;

        if (fromPageUrl === valuePageUrl) {
          // Some UI pages push a new state again after the loading.
          // For example, after visiting "Activity", a state with url
          // /"activity/listing" is pushed first, and then another with
          // "/activity/listing?timeRange=past24hours".
          return;
        }

        this.analyticsTrack('Page Change', {
          ...this.getDefaultProperties(),
          from: fromPageUrl,
          value: window.location.href,
        }, false);
      });
    });
  }

  /**
   * Wait for intercom's bundle to load and initialize intercom.
   *
   * @param user The user object which has the user details.
   */
  private setIntercom(user: User) {
    const analyticsEnvironment = this.getAnalyticsEnvironment(environment.production);

    if (flagEnabled(this.irisContextService.irisContext, 'intercomIdentityVerification')) {
      // Enable Identity Verification.
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: intercomKeys[analyticsEnvironment],
        user_id: user.username,
        user_hash: user.intercomMessengerToken,
      };
    }

    // This needs to be set since the intercom.js which is loaded relies on this.
    window.intercomAppId = intercomKeys[analyticsEnvironment];

    // Initialize intercom
    const intercomSdk = document.createElement('script');
    intercomSdk.setAttribute('src', '/intercom.js');
    window.document.body.appendChild(intercomSdk);

    // Initialize intercom after it is loaded.
    const intercomBundleInterval = window.setInterval(() => {
      // This is not ideal, but a consistent way to ensure intercom is loaded.
      if (!window.Intercom) {
        return;
      }

      window.clearInterval(intercomBundleInterval);
      this._intercomEnabled$.next(true);
    }, 50);
  }

  /**
   * Initialize intercom.
   */
  private initIntercom() {
    if (!this.intercomEnabled) {
      return;
    }

    if (this.intercomTransitionHook) {
      // Clear the previous transition hook.
      this.intercomTransitionHook();
    }

    // Update intercom with the current page.
    this.intercomTransitionHook = this.transitionService.onSuccess({}, () => {
      window.Intercom('update');
    });

    window.Intercom('onUnreadCountChange', unreadCount => {
      this._intercomUnreadCount$.next(unreadCount);
    });

    window.Intercom('onShow', () => {
      this.intercomMessengerVisible = true;
      this.track('messenger_toggled', {value: true});
      this.analyticsTrack('Messenger Toggled', {value: true}, false);
    });

    window.Intercom('onHide', () => {
      this.intercomMessengerVisible = false;
      this.track('messenger_toggled', {value: false});
      this.analyticsTrack('Messenger Toggled', {value: false}, false);
    });

    if (this.uiRouterGlobals.params?.product_tour_id) {
      this.startProductTour(this.uiRouterGlobals.params.product_tour_id);
    }
  }

  /**
   * Function to start an intercom product tour based on product tour id.
   *
   * @param id The product tour id.
   */
  private startProductTour(id: number) {
    const menuButton = document.getElementById('menuButton');

    if (menuButton && !document.querySelector('mat-sidenav.mat-drawer-opened')) {
      // All product tours start with selecting a navigation item.
      // If the navigation menu is hidden, show it at the start of the
      // product tour.
      menuButton.click();
    }

    // If a product tour id was set, start that product tour.
    window.Intercom?.('startTour', id);
  }
}
