import { clone } from 'lodash-es';
import { constant } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: Cart service.

; (function (angular, undefined) {
  'use strict';

  angular
    .module('C.cart', [])
    .service('Cart', CartFn);

  /**
   * @ngdoc service
   * @name C.cart:Cart
   * @function
   *
   * @description
   * This provides a cart data structure to store items and perform operations.
   * This data structure essentially replicates the Map object with the
   * <Key, Value> available as Array of Values.
   *
   * The Cart DS takes in a key generator function based on which Keys are
   * created and defaults to an internal definition for the key generation.
   *
   * TODO(Tauseef): Migrate to the Map.prototype if there are no compatibility
   * issues.
   * Reference: https://mzl.la/MV7EcO
   *
   * Supports the below methods:
      newCart(keyGeneratorFn, lockGeneratorFn)
      add(object)
      addAll(object[])
      has(object)
      isForbidden(object)
      remove(object)
      removeAll()
      removeByKey(string)
      union(cart2)
   */
  function CartFn(_) {

    return {
      newCart: _newCart,
    };

    /**
     * Return a new cart instance object.
     *
     * @method   _newCart
     *
     * @param    {function}   keyGeneratorFn    Specifies the function to
     *                                          generate key for an object.
     * @param    {[function]} isForbiddenFn     Specifies the function used to
     * determine whether we can add certain item in the cart or not for example
     * you want to have an ability to add student belonging to same class.
     * If not provided then always return false means no item is forbidden to be
     * in the cart.
     * @return   {object}     returns a new empty cart.
     */
    function _newCart(keyGeneratorFn, isForbiddenFn) {
      isForbiddenFn = isForbiddenFn || constant(false);
      return new Cart(keyGeneratorFn, isForbiddenFn);
    }

    /**
     * Constructor function for cart object.
     *
     * @constructor
     * @param    {function}   keyGeneratorFn    Specifies the function to
     *                                          generate key for an object.
     * @param    {function}   isForbiddenFn     Specifies the function used to
     * determine whether we can add certain item in the cart or not for example
     * you want to have an ability to add student belonging to same class.
     */
    function Cart(keyGeneratorFn, isForbiddenFn) {
      var service = this;

      assign(service, {
        // Cart variables.
        // Specifies the Array of cart items.
        cartList: [],

        // Specifies the <K,V> pair of cart items.
        cartMap: {},

        // Specifies the size of the cart.
        size: 0,

        // Cart methods.
        add: _add,
        addAll: _addAll,
        has: _has,
        isForbidden: isForbiddenFn,
        remove: _remove,
        removeAll: _removeAll,
        removeByKey: _removeByKey,
        toggle: _toggle,
        union: _union,
        update: _update,
      });

      /**
       * Adds the item to the cart or replaces the value if the item with the
       * key already exists.
       *
       * @method   _add
       * @param    {object}   item      Specifies the entity to be upserted.
       */
      function _add(item) {
        var key = keyGeneratorFn(item);

        // Do nothing if item is forbidden to be in the cart.
        if (service.isForbidden(item)) {
          return;
        }

        // Add item or update item in the cart.
        if (!_has(item)) {
          service.cartMap[key] = item;
          service.cartList.push(item);
          ++service.size;
        } else {
          _update(item);
        }
      }

      /**
       * Updates the item within the Cart at a given index if specified.
       * Otherwise, replace the value of the item in Cart.
       *
       * @method   _update
       * @param    {object}   item      Specifies the object to be updated.
       * @param    {number}   [index]   Specifies the index at which the item
       *                                has to replaced
       */
      function _update(item, index) {
        var key = keyGeneratorFn(item);

        // Do nothing if item is forbidden to be in the cart.
        if (service.isForbidden(item)) {
          return;
        }

        // Update the item if Index is not given
        if (index === undefined && _has(item)) {
          _remove(item);
          _add(item);
        }

        // Update the item at a given index.
        if (index && index < service.size && _has(item)) {
          service.cartMap[key] = item;
          service.cartList[index] = item;
        }
      }

      /**
       * Adds multiple items to the cart or replaces the value if the item has
       * the same key.
       *
       * @method   _addAll
       * @param    {object[]}   items   Specifies Array of object entities to
       *                                be recovered.
       */
      function _addAll(items) {
        items.forEach(_add);
      }

      /**
       * Determines whether the item with the key exists in the cart or not.
       *
       * @method   _has
       * @param    {object}   item   Specifies the entity to be recovered.
       */
      function _has(item) {
        // TODO(Tauseef): Use Set/WeakSet if ES6 support is finalized.
        return service.cartMap.hasOwnProperty(keyGeneratorFn(item));
      }

      /**
       * Removes the <key, value> pair from the current cart object along with
       * its entry from the cart list by the item as argument.
       *
       * @method   _remove
       * @param    {object}   item   Specifies the item to be removed from cart.
       */
      function _remove(item) {
        var key = keyGeneratorFn(item);
        _removeByKey(key);
      }

      /**
       * Removes the <key, value> pair from the current cart object along with
       * its entry from the cart list by the key as argument.
       *
       * @method   _removeByKey
       * @param   {string}   key   Specifies key of the object to be removed.
       */
      function _removeByKey(key) {
        var removalIndex = service.cartList.findIndex(
          function checkKey(currentItem) {
            return key === keyGeneratorFn(currentItem);
          }
        );

        service.cartList.splice(removalIndex, 1);
        delete service.cartMap[key];
        --service.size;
      }

      /**
       * Removes all items from the Cart.
       * @method   _removeAll
       * @return   {object}   current cart object.
       */
      function _removeAll() {
        assign(service, {
          cartList: [],
          cartMap: {},
          size: 0,
        });

        return service;
      }

      /**
       * Toggles the selection of the item in the cart.
       *
       * @method   _toggle
       * @param    {Object}   item   The item to be toggled.
       */
      function _toggle(item) {
        if (_has(item)) {
          _remove(item);
        } else {
          _add(item);
        }
      }

      /**
       * Creates a new Cart object combining the cart items of both the operand
       * cart and the argument cart. The keyGenFn and lockGenFn are defaulted
       * to the operand cart.
       *
       * @method   union
       * @param    {object}     nextCart   Specifies the Cart object.
       * @param    {function}   filterFn   Specifies the function which items
       *                                   should be checked against.
       * @return   {object}     Specifies the union Cart object.
       */
      function _union(nextCart, filterFn) {
        var unionCart = new Cart(keyGeneratorFn, isForbiddenFn);

        // Push the current cart data to the union cart.
        for (var operandCartKey in service.cartMap) {
          if (filterFn(service.cartMap[operandCartKey])) {
            unionCart.add(clone(service.cartMap[operandCartKey]));
          }
        }

        for (var argCartKey in nextCart.cartMap) {
          // Performance of hasOwnProperty may vary depending on the internal
          // implementation. TODO(Tauseef): Move this to Set/WeakSet.
          if (!unionCart.cartMap.hasOwnProperty(argCartKey) &&
            filterFn(nextCart.cartMap[argCartKey])) {
            unionCart.add(nextCart.cartMap[argCartKey]);
          }
        }

        return unionCart;
      }
    }
  }
})(angular);