import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

export const CustomizationConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'customization.**',
      url: '/customization',
      loadChildren: () => import('./customization.module').then(m => m.CustomizationModule),
    },
  ],
  allClusterMap: {
    'customization.list': {
      heliosOnly: false,
      globalContext: true,
      allClustersState: 'customization.list',
      singleClusterState: 'customization.list'
    },
    'customization.helios': {
      heliosOnly: true,
      allClustersState: 'customization.helios',
      singleClusterState: 'dashboards'
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'customization.list': (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.uiCustomization && getConfigByKey(ctx.irisContext, 'settings.features.customizations', true),
      'customization.helios': (ctx: StateAccessContext) => ctx.isHelios && ctx.FEATURE_FLAGS.heliosMTEnabled &&
        ctx.FEATURE_FLAGS.whitelabeling && !ctx.isHeliosTenantUser,
    };
  },
};
