import { isDate } from 'lodash-es';
import { clone } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: c-inline-date-range component

;(function(angular, undefined) {

  angular.module('C.inlineDateRange', ['C.inlineDatePicker'])
    .controller('InlineDateRangeCtrl', InlineDateRangeCtrlFn)
    .component('cInlineDateRange', {
      bindings: {
        /**
         * Optional provided default range will be used to initialize the
         * component and used by c-field to clear the range to default value.
         * defaultRange = {
         *    start:      Date object,
         *    end:        Date object,
         * }
         */
        defaultRange: '<?',
        /**
         * Optional, when provided all the dates after maxDate will be disabled
         * maxDate = Date object
         */
        maxDate: '<?',
        /**
         * Optional, specifies whether the start and end date can be same or
         * not.
         * allowSameDay = Boolean Object
         */
        allowSameDay: '<?',
      },
      require: {
        // require model controller to spit out selected date ranges.
        ngModel: 'ngModel',

        // Optional require the c-field controller to make c-inline-date-range
        // work with filters implementations.
        // all filter related setup is defined under initFieldSetup function.
        cField: '?^cField',

        // Optional require the c-filters controller to determine date range is
        // used with filters.
        cFilters: '?^^cFilters',
      },
      controller: 'InlineDateRangeCtrl',
      templateUrl: 'app/global/c-date-ranger/c-inline-date-range.html',
    });

  function InlineDateRangeCtrlFn(_, DateTimeService) {
    var $ctrl = this;

    // empty destructor fn for c-field setup and overridden if defined inside
    // $onInit.
    var cFieldSetupDestructor = angular.noop;

    assign($ctrl, {
      // keep date range dropdown hidden by default.
      isOpen: false,

      // by default don't keep date range dropdown open always and this will be
      // dynamically toggled by c-field.
      isAlwaysOpen: false,

      // by default don't show the filter actions like clear, abort and apply
      // buttons they are toggled by initFieldSetup.
      filterActions: false,

      // by default close the dropdown when clicked outside other possible
      // values like 'disabled' and 'always'.
      // 'disabled' is used when date ranges is rendered inside filter modal.
      closeConfig: 'outsideClick',

      // start and end date options
      startDateOptions: {
        // inRangeClasses used to lightly gray out dates which are in range b/w
        // start and end date.
        customClass: inRangeClasses,

        // disabling the dates which are more that end date.
        dateDisabled: startDateDisabled,
      },
      endDateOptions: {
        customClass: inRangeClasses,

        // disabling the dates which are less that start date.
        dateDisabled: endDateDisabled,
      },

      // component life cycle methods.
      $onInit: $onInit,
      $onDestroy: $onDestroy,

      // Component methods.
      getFormatedDate: getFormatedDate,
      updateExternalModel: updateExternalModel,
    });

    /**
     * Initialize this component.
     *
     * @method     $onInit
     */
    function $onInit() {
      // Enable date range to be used with filters.
      if ($ctrl.cFilters) {
        cFieldSetupDestructor = initFieldSetup();
      }

      // initialize the default range if not defined which is used when c-field
      // to clear the field value.
      $ctrl.defaultRange = $ctrl.defaultRange || getDefaultRange();

      // disable dates which are more than a specific date
      if ($ctrl.maxDate) {
        $ctrl.startDateOptions.maxDate = $ctrl.maxDate;
        $ctrl.endDateOptions.maxDate = $ctrl.maxDate;
      }

      // allow samedates to be allowed to picked for start date and end date.
      $ctrl.allowSameDay = $ctrl.allowSameDay || false;

      // update internal mode when external model got changed.
      $ctrl.ngModel.$render = updateInternalModel;
    }

    /**
     * $onDestroy cleanup function.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      // un-register the field to remove it from c-filters internal collection.
      cFieldSetupDestructor();
    }

    /**
     * Get the default date range value object.
     *
     * @method   getDefaultRange
     * @return   {Object}   Date range object.
     */
    function getDefaultRange() {
      return $ctrl.defaultRange = {
        start: DateTimeService.getWeekAgo(),
        end: new Date(Date.clusterNow()),
      };
    }

    /**
     * Get the formatted date range value used to to show selected range and
     * used inside filter pills.
     *
     * @example
       {start: '2018-12-05T12:44:53.177Z', end: '2018-12-27T12:44:53.177Z'}
       'Dec 20, 2018 - Dec 27, 2018'
     *
     * @method  getFormatedDate
     * @return  {String}    formatted date range string.
     */
    function getFormatedDate() {
      // early exit for invalid date range values.
      if (!isDateRangeValid()) {
        return '-';
      }

      return DateTimeService.getFormatedDateRange($ctrl.internalRange);
    }

    /**
     * Update internal model with external model changes.
     *
     * @method   updateInternalModel
     */
    function updateInternalModel() {
      if (isDateRangeValid()) {
        // sync internal mode with external if it is valid.
        $ctrl.internalRange = $ctrl.ngModel.$viewValue;
      } else {
        // reset internal and external model with default range if date range is
        // invalid.
        $ctrl.internalRange = clone($ctrl.defaultRange);
        updateExternalModel();
      }
    }

    /**
     * Update external model value on internal model changes.
     *
     * @method   updateExternalModel
     * @param    {String}   [type=undefined]   Type of the field got updated it
     *                                         may be start date or end date.
     */
    function updateExternalModel(type) {
      // fake end date updates when start date changes and vise versa to keep
      // both the date picker in sync.
      if (type === 'start') {
        $ctrl.internalRange.end = new Date($ctrl.internalRange.end);
      } else {
        $ctrl.internalRange.start = new Date($ctrl.internalRange.start);
      }

      // updating the external model.
      $ctrl.ngModel.$setViewValue({
        start: $ctrl.internalRange.start,
        end: $ctrl.internalRange.end,
      });
    }

    /**
     * Determines if a external model date range is valid or not.
     *
     * @method     isDateRangeValid
     * @returns    {Boolean}    Return true if date range is valid else false.
     */
    function isDateRangeValid() {
      var modelVal = $ctrl.ngModel.$viewValue;

      return modelVal &&
        modelVal.start &&
        modelVal.end &&
        isDate(modelVal.start) &&
        isDate(modelVal.end);
    }

    /**
     * Returns extra classes added to the date cell used to highlight selected
     * date range.
     *
     * @method   inRangeClasses
     * @param    {Object}   data   Provided data object for each date cell.
     *                             {
     *                               date: new Date('2018-12-05T12:44:53.177Z'),
     *                               mode: 'day||month||year'
     *                             }
     * @returns  {String}   Return highlight classes for the provided date which
     *                      is in between selected date range.
     */
    function inRangeClasses(data) {
      var dateToTest = +(data.date);
      var startEpoch;
      var endEpoch;

      if (isDateRangeValid()) {
        startEpoch = +($ctrl.internalRange.start);
        endEpoch = +($ctrl.internalRange.end);

        if (startEpoch < dateToTest && dateToTest < endEpoch) {
          return 'c-date-picker-in-range';
        }
      }

      return '';
    }

    /**
     * Determine if the provided start date selections is enabled or disabled.
     *
     * @method   startDateDisabled
     * @param    {Object}   data   Provided data object for each date cell.
     *                             {
     *                               date: new Date('2018-12-05T12:44:53.177Z'),
     *                               mode: 'day||month||year'
     *                             }
     * @returns  {Boolean}   Return true if provided start date need to be
     *                       disabled else false.
     */
    function startDateDisabled(data) {
      var dateToTest = +(data.date);
      var endEpoch;
      var isValidDate;

      // disabling dates which are greater than selected end date.
      if ($ctrl.internalRange && $ctrl.internalRange.end) {
        endEpoch = +($ctrl.internalRange.end);

        isValidDate = $ctrl.allowSameDay ? (dateToTest > endEpoch) :
        (dateToTest >= endEpoch);
        if (isValidDate) {
          return true;
        }
      }

      return false;
    }

    /**
     * Determine if the provided end date selections is enabled or disabled.
     *
     * @method   endDateDisabled
     * @param    {Object}   data   Provided data object for each date cell.
     *                             {
     *                               date: new Date('2018-12-05T12:44:53.177Z'),
     *                               mode: 'day||month||year'
     *                             }
     * @returns  {Boolean}   Return true if provided end date need to be
     *                       disabled else false.
     */
    function endDateDisabled(data) {
      var dateToTest = +(data.date);
      var startEpoch;
      var isValidDate;

      // disabling dates which are less than selected start date.
      if ($ctrl.internalRange && $ctrl.internalRange.start) {
        startEpoch = +($ctrl.internalRange.start);

        isValidDate = $ctrl.allowSameDay ? (dateToTest < startEpoch) :
        (dateToTest <= startEpoch);
        if (isValidDate) {
          return true;
        }
      }

      return false;
    }

    /**
     * Initialize the field setup by registering the c-field API to c-filters
     * used to interact with this field.
     *
     * @method   initFieldSetup
     * @returns  {function}   Function used to destroy the setup done.
     */
    function initFieldSetup() {
      // c-field interfaces implementation for date range selector.
      var cFieldApi = {
        abort: dateRangeAbort,
        apply: dateRangeApply,
        clear: dateRangeClear,
        disableAlwaysOpen: disableAlwaysOpen,
        enableAlwaysOpen: enableAlwaysOpen,

        // return formatted date out to be shown in filter pills.
        getSelectedValue: getFormatedDate,
      };

      // cached previous value used during restoring the field.
      var previousValue;

      // if set to true then dropdown toggle will prevent restoring the value
      // used when dropdown is closed by apply, clear and abort actions.
      var noCacheUpdates = false;

      // modifying date range config because they are programmatically
      // controlled by c-field setup.
      assign($ctrl, {
        isAlwaysOpen: false,
        closeConfig: 'outsideClick',
        filterActions: true,
        isOpen: false,
        onDropdownToggle: onDropdownToggle,
      });

      /**
       * Perform caching and restoring the selected value when dropdown is
       * toggled because of outside click, uib-dropdown-toggle click or
       * programmatically toggled isOpen flag.
       *
       * NOTE: don't touch cache if date range is updated because of apply,
       * clear or abort actions.
       *
       * @method   onDropdownToggle
       */
      function onDropdownToggle() {
        if (noCacheUpdates) {
          // releasing the lock now because dropdown had be toggled because of
          // apply, clear or abort actions.
          noCacheUpdates = false;
        } else {
          if ($ctrl.isOpen) {
            // dropdown is getting opened so cache the selected value.
            cacheSelectValue();
          } else {
            // dropdown is getting closed because of external reason hence
            // restoring the value.
            restoreSelectValue();
          }
        }
      }

      /**
       * Hide the dropdown on clear, apply or abort actions and preventing
       * onDropdownToggle to modify/update the cache because cache updates are
       * already handled via dateRangeAbort/Clear/Apply fns.
       *
       * @method   hideDropdown
       */
      function hideDropdown() {
        noCacheUpdates = true;
        $ctrl.isOpen = false;
      }

      /**
       * Set date range picker to stay always open when filters are shown in the
       * modal.
       *
       * @method   enableAlwaysOpen
       */
      function enableAlwaysOpen() {
        $ctrl.isAlwaysOpen = true;
        $ctrl.closeConfig = 'disabled';
        $ctrl.filterActions = false;
        $ctrl.isOpen = true;
      }

      /**
       * Reset date range picker to default state when filters is switched back
       * to in page view.
       *
       * @method   disableAlwaysOpen
       */
      function disableAlwaysOpen() {
        $ctrl.isAlwaysOpen = false;
        $ctrl.closeConfig = 'outsideClick';
        $ctrl.filterActions = true;
        hideDropdown();
      }

      /**
       * Abort current changes and restore the previously selected value from
       * the cache and used when Cancel button is clicked from the c-filter or
       * field-actions.
       *
       * NOTE: click outside will be captured in onDropdownToggle an naturally
       * we will perform restoring the value.
       *
       * @method   dateRangeAbort
       */
      function dateRangeAbort() {
        restoreSelectValue();
        hideDropdown();
      }

      /**
       * Apply the filter with selected value.
       *
       * @method   dateRangeApply
       */
      function dateRangeApply() {
        if ($ctrl.isAlwaysOpen) {
          // update the cache if filter is applied during always open mode.
          cacheSelectValue();
        } else {
          // burst the previously cached value.
          clearSelectValue();
        }

        hideDropdown();
      }

      /**
       * Clear the selected date range.
       *
       * @method   dateRangeClear
       */
      function dateRangeClear() {
        // resetting the internal model with default value.
        $ctrl.internalRange = clone($ctrl.defaultRange);

        // burst the previously cached value.
        clearSelectValue();

        // close the dropdown now.
        hideDropdown();
      }

      /**
       * Caches selected value used later to abort the modification.
       *
       * @method   cacheSelectValue
       */
      function cacheSelectValue() {
        previousValue = clone($ctrl.internalRange);
      }

      /**
       * Restore the selected value to its previous cached value and clear the
       * cache.
       *
       * @method   restoreSelectValue
       */
      function restoreSelectValue() {
        // nothing to restore when always open is set.
        if (!$ctrl.isAlwaysOpen) {
          $ctrl.internalRange = previousValue;

          // burst the previously cached value.
          clearSelectValue();
        }
      }

      /**
       * Clear the cached previous value with date range default value.
       *
       * @method   clearSelectValue
       */
      function clearSelectValue() {
        previousValue = clone($ctrl.defaultRange);
      }

      /**
       * Initialize the setup by registering the c-field API interfaces used by
       * c-filter and c-field to interact with date-range.
       *
       * @method   initSetup
       */
      function initSetup() {
        $ctrl.cField.registerUiSelect(cFieldApi);
        $ctrl.cField.endLoading();
      }

      /**
       * Destroy the registration when date-range is destroyed.
       *
       * @method   destroySetup
       */
      function destroySetup() {
        $ctrl.cField.deRegisterUiSelect(cFieldApi);
      }

      // initializing the setup.
      initSetup();

      // returns a fn used to destroy the setup.
      return destroySetup;
    }

  }
})(angular);
