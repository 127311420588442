import { chain } from 'lodash-es';
import { groupBy } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { includes } from 'lodash-es';
import { filter } from 'lodash-es';
import { map } from 'lodash-es';
import { concat } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: list-tenants component

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    controller: 'ListTenantsCtrl',
    templateUrl: 'app/admin/access-management/tenants/list/list.html',
  };

  angular.module('C.tenants')
    .filter('tenantListFilter', tenantListFilterFn)
    .controller('ListTenantsCtrl', listTenantsCtrlFn)
    .component('listTenants', configOptions);

  function listTenantsCtrlFn(_, $q, evalAJAX, TenantService, ViewBoxService,
    UserService, $state) {
    var $ctrl = this;

    // Added key 'undefined' to the 'All' filter to make all of the list visible
    // because of using native angular filter.
    var status = [
      {
        name: 'active',
        value: 'Active',
      },
      {
        name: 'inactive',
        value: 'Deactivated',
      },
      {
        name: 'deleted',
        value: 'Deleted',
      },
    ];

    // Declare Component Methods.
    assign($ctrl, {

      // Component methods.
      getData: getData,

      // component life cycle methods.
      $onInit: $onInit,
    });

    /**
     * initialize component context
     *
     * @method   _initializeCtrl
     */
    function _initializeCtrl() {
      assign($ctrl, {
        // UI States
        status: status,
        filterByStatus: status[0],
        isLoading: false,

        // UI State to show that there are tenants on this cluster. Used for
        // filtering.
        hasTenants: false,

        // Specifies the list of tenants after filtering.
        tenantsList: [],
      });
    }

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeCtrl();
      getData();
    }

    /**
     * make a request to get tenants list.
     *
     * @method   getData
     */
    function getData() {
      // The ngModel of filter status.
      var selectedStatus = $ctrl.filterByStatus;

      // Additional param for the API request to get the tenants.
      var params = selectedStatus.length && {
        status: map(selectedStatus, 'value'),
      };

      var promises = {
        viewboxes: ViewBoxService.getViewBoxes(),
        tenants: TenantService.getTenants(['ViewBox'], params),
      };

      $ctrl.isLoading = true;

      $q.all(promises).then(
        function gotTenants(response) {
          var viewboxesMap = keyBy(response.viewboxes, 'id');

          $ctrl.tenantsList = response.tenants.map(
            function eachTenant(tenant) {

              // resolve tenant viewboxes name
              tenant._viewboxes = map(tenant.viewBoxIds,
                function mapViewbox(viewboxId) {
                  return viewboxesMap[viewboxId];
                });

              tenant._menuList = getTenantMenuList(tenant);

              return tenant;
            });

          getTenantUsers();

          // Saves this UI state for filtering tenants.
          $ctrl.hasTenants = $ctrl.hasTenants || !!$ctrl.tenantsList.length;
        },
        evalAJAX.errorMessage
      ).finally(function finallyAfterResponse() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Gets the tenant users.
     *
     * @method   getTenantUsers
     */
    function getTenantUsers() {
      // Filter the tenants that are not deleted/marked for deletion. Thus,
      // saving the API calls.
      var tenantIds = chain($ctrl.tenantsList)
        .filter(function filterTenants(tenant) {
          // The tenant that are not in the deletion category will not have
          // 'deleted' property.
          return !tenant.hasOwnProperty('deleted');
        }).map('tenantId').value();

      var promises = {};

      if (tenantIds.length) {
        promises = {
          users: UserService.getTenantUsers(tenantIds),
          groups: UserService.getTenantGroups(tenantIds),
        };

        $q.all(promises).then(function onSuccess(resp) {
          var usersGroupByTenant = groupBy(resp.users, 'tenantId');
          var groupGroupByTenant = {};

          map(resp.groups, function mapGroups(group) {
            map(group.tenantIds, function forEachTenant(tenantId) {
              groupGroupByTenant[tenantId] =
                chain(groupGroupByTenant[tenantId])
                  .concat(group)
                  .filter()
                  .value();
            });
          });

          $ctrl.tenantsList.forEach(function eachTenant(tenant) {
            var userGroups =  concat(usersGroupByTenant[tenant.tenantId],
              groupGroupByTenant[tenant.tenantId]);
            tenant._users = filter(userGroups);
          });
        });
      }
    }

    /**
     * Returns the list of the menu items for an tenant.
     *
     * @method   getTenantMenuList
     * @param    {Object}   tenant   The tenant object.
     * @return   {Array}    The menu items list for the tenant.
     */
    function getTenantMenuList(tenant) {
      return [
        {
          icon: 'icn-edit',
          translateKey: 'edit',
          state: 'edit-tenant',
          stateParams: {
            id: tenant.tenantId,
          },
        },
        {
          icon: tenant.active ? 'icn-deactivate' : 'icn-activate',
          translateKey: tenant.active ? 'deactivate' : 'activate',
          action: function toggleTenantStatus() {
            TenantService.toggleTenantStatus(tenant)
              .then(function toggleSuccess(updatedTenant) {
                getData();
              }, evalAJAX.errorMessage);
          }
        },
        {
          icon: 'icn-delete',
          translateKey: 'delete',
          action: function removeTenant() {
            TenantService.openDeleteTenantModal(tenant)
              .then(function removeTenantSuccess() {
                $state.reload();
              }, evalAJAX.errorMessage);
          }
        },
      ];
    }
  }

  /**
   * @ngdoc   filter
   * @name    C.tenants.tenantListFilter
   *
   * @description
   *   Filters the list of tenants if provided search term found in the tenantId
   *   or tenantName.
   *
   *   NOTE: this is a special filter which can only work with st-table.
   *   1. https://docs.angularjs.org/api/ng/filter/filter#filter-arguments
   *   2. https://lorenzofox3.github.io/smart-table-website/ look for Strict mode filtering section
   *
   *
   * @example
   *   <table
   *    st-table="$ctrl.tenantsListTable"
   *    st-safe-src="::$ctrl.tenantsList"
   *    st-set-filter="tenantListFilter">
   *   </table>
   *
   * @param    {Array}    tenantList   The list of tenants.
   * @param    {Object}   predicate    The filter predicate provided by st-table
   * @return   {Array}    Return the filtered tenant list.
   */
  function tenantListFilterFn() {
    return function(tenantList, predicate) {
      var searchTerm = ((predicate || {}).$ || '').toLowerCase().trim();

      // do nothing if searchTerm is not defined or empty.
      if (!searchTerm) {
        return tenantList;
      }

      return (tenantList || []).filter(function eachItem(tenant) {
        return includes(tenant.name.toLowerCase(), searchTerm) ||
          includes(tenant.tenantId.toLowerCase(), searchTerm);
      });
    }
  }

})(angular);
