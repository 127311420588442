import { get } from 'lodash-es';
// Service: JobBackupNowService contains method for job backup now controller
// to populate $ctrl / $scope values to be used in the template.

(function(angular, undefined) {
  'use strict';

  angular
    .module('C.backupNow')
    .service('JobBackupNowService', jobBackupNowServiceFn);

  /**
   * Service Fn.
   *
   * @method   jobBackupNowServiceFn
   * @return   {object}    The Service's API object.
   */
  function jobBackupNowServiceFn(ENV_GROUPS) {
    var JobBackupNowService = {
      buildBackupTypeList: buildBackupTypeList,
      getDefaultBackupType: getDefaultBackupType,
      getSelectedObjects: getSelectedObjects,
    };

    /**
     * returns 'Incremental' or 'Log' as the default backupType to be shown in the
     * dropdown menu based on the backup type. If 'Incremental/Log' is not found we
     * will default to the first element in the array
     *
     * @param     {array}    backupTypes    array of backupType objects ex:
     *                                     [{
     *                                       name: 'Incremental',
     *                                       enum: 'kRegular'
     *                                     }]
     *
     * @param     {array}    isLogBackup   Backup Job Type
     *
     * @return    {object}                 object that contains the default
     *                                     value name and enum
     */
    function getDefaultBackupType(backupTypes, isLogBackup) {
      var defaultBackupType = backupTypes.find(function findType(type) {
        return isLogBackup ? type.enum === 'kLog' : type.enum === 'kRegular';
      });

      return defaultBackupType ? defaultBackupType : backupTypes[0];
    }

    /**
     * Extracts selected nodes from an array of objects.
     *
     * @function   getSelectedObjects
     * @param    {Array}   objectsList    Can be an array of sources, instances
     *                                    or nodes.
     * @param    {Object} job             Backup job object.
     * @returns  {Array}   Array of selected objects.
     */
    function getSelectedObjects(objectsList, job) {
      var selection = [];
      objectsList.forEach(function wrapperFn(node) {
        if (node._isSharePointSite) {
          _findSharePointSelectedChildren(node, job, selection);
        } else {
          _findSelectedChildren(node, job, selection);
        }
      });
      return selection;
    }

    /**
     * Recursively traverses items and appends selected nodes to the selection.
     *
     * @function   _findSelectedChildren
     * @param    {Object}   node           The node to traverse.
     * @param    {Object}   job            Backup job object.
     * @param    {Array}    selection      A list to append the selection to.
     */
    function _findSelectedChildren(node, job, selection) {
      // For Active Directory, the host is selected for backup rather than the
      // domain controller. Because of this, the selected node will show as
      // having children, but it should still be selectable.
      // for kubernetes, leaf are not selectable, only parent is selectable
      // For a physical job, a physical source should be selectable even if it
      // has children (It will have children if it's registered as sql/oracle).
      if (node._isSelectedForBackup && node._isSelected &&
        (!node.children ||
          node._isActiveDirectoryHost ||
          _isVmInSqlContext(node) ||
          job._isPhysical || job._isKubernetes)) {
        selection.push(node);
      } else if (node.children) {
        node.children.forEach(function traverseChildren(child) {
          _findSelectedChildren(child, job, selection);
        });
      }
    }

    /**
     * Recursively traverses items and appends selected nodes to the selection
     * for SharePoint sites.
     *
     * @function   _findSharePointSelectedChildren
     * @param    {Object}   node           The node to traverse.
     * @param    {Object}   job            Backup job object.
     * @param    {Array}    selection      A list to append the selection to.
     */
     function _findSharePointSelectedChildren(node, job, selection) {
      // For SharePoint site, a site can have sub-sites and should be selectable.
      if (node._isSelectedForBackup && node._isSelected) {
        selection.push(node);
      }

      // Keep checking the sub-sites even though parent site is selected since
      // they can be protected independently.
      if (node.children) {
        node.children.forEach(function traverseChildren(child) {
          _findSharePointSelectedChildren(child, job, selection);
        });
      }
    }

    /**
     * Determines if the given node is a VM in a SQL context.
     *
     * @function   _isVmInSqlContext
     * @param      {Object}    node   The node to detect on
     * @returns    {Boolean}   True if this is a VM in a SQL context. False
     *                         otherwise
     */
    function _isVmInSqlContext(node) {
      return !!node.entity.vmwareEntity &&
        node._isSqlHost &&
        node._isLeaf;
    }

    /**
     * Filters the backupTypes array according to the the schedules available on
     * the jobDetails object.
     *
     * @param     {object}    policy    object that contains job
     *                                  policyDetails.
     * @param     {object}    source    object that contains job
     *                                  parent source details.
     * @return    {array}               an array that contains the
     *                                  backupTypes to be shown in the
     *                                  dropdown.
     */
    function buildBackupTypeList(policy, source) {
      var backupTypes = [];

      // Full
      backupTypes.push({
        name: 'enum.jobRunType.kFull',
        enum: 'kFull',
      });

      // Incremental
      if (!source || !ENV_GROUPS.azureStackTypes.includes(
          get(source, 'azureEntity.commonInfo.subscriptionType')) ||
          get(source, 'viewEntity')) {
        backupTypes.push({
          name: 'enum.jobRunType.kRegular',
          enum: 'kRegular',
        });
      }

      // Log
      if (policy.logSchedulingPolicy) {
        backupTypes.push({
          name: 'enum.jobRunType.kLog',
          enum: 'kLog',
        });
      }

      // System
      if (policy.systemSchedulingPolicy) {
        backupTypes.push({
          name: 'enum.jobRunType.kSystem',
          enum: 'kSystem',
        });
      }

      return backupTypes;
    }

    return JobBackupNowService;
  }
})(angular);
