import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const viewAccess =
  (ctx: StateAccessContext) =>
    ctx.STORAGE_VIEW
    && ctx.FEATURE_FLAGS['ngAntivirus']
    && !ctx.isTenantUser
    && !ctx.isHeliosTenantUser
    && getConfigByKey(ctx.irisContext, 'views.features.antivirus', true);

export const AntivirusConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng-antivirus.**',
      url: '^/antivirus',
      loadChildren: () => import('./antivirus.module').then(m => m.AntivirusModule)
    },
    {
      name: 'view-av-provider-details.**',
      url: '/antivirus/providers/view',
      loadChildren: () => import('./antivirus.module').then(m => m.AntivirusModule)
    },
  ],
  allClusterMap: {
    'ng-antivirus': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'ng-antivirus': viewAccess,
      'ng-antivirus.threats': viewAccess,
      'ng-antivirus.secured-views': viewAccess,
      'ng-antivirus.providers': viewAccess,
      'view-av-provider-details': viewAccess,
    };
  }
};
