import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { HelixIntlService } from '../../helix-intl.service';
import { ThemeConfig } from '../dark-mode-toggle';
import { ThemeService } from '../dark-mode-toggle/theme.service';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';

/**
 * Interface for theme options.
 */
export interface ThemeOption {
  /**
   * Display name for theme
   */
  displayName: string;

  /**
   * Config of the theme
   */
  config: ThemeConfig;

  /**
   * True if used as default theme.
   */
  isDefault?: boolean;
}

@Component({
  standalone: true,
  imports: [CommonModule, DataIdModule, IconModule, MatButtonModule, MatIconModule, MatMenuModule, MatTooltipModule],
  selector: 'cog-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeSwitcherComponent implements OnInit {
  /**
   * Label text for app switcher button.
   */
  @Input() label = this.intl.switchTheme;

  /**
   * List of available themes.
   */
  @Input() themes: ThemeOption[] = [];

  constructor(private intl: HelixIntlService, private themeService: ThemeService) {}

  ngOnInit() {
    const defaultTheme = this.themes.find(theme => theme.isDefault);

    if (defaultTheme) {
      this.switchTheme(defaultTheme.config);
    }
  }

  /**
   * Switches the current theme.
   *
   * @param theme - the theme to switch to
   */
  switchTheme(theme: ThemeConfig): void {
    this.themeService.setTheme(theme.family, theme.mode);
  }
}
