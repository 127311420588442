import { assign } from 'lodash-es';
//Component: Licenseing Access permission modal, user will select permissions
// type here
;(function(angular) {
  'use strict';

  angular
    .module('C.clusterClaim')
    .controller('LicensingOptionModalCtrl', LicensingOptionModalCtrlFn)
    .component('licesningOptionModal', {
      templateUrl:'app/cluster-connect/license-options-modal.html',
      controller: 'LicensingOptionModalCtrl',
      bindings: {
        registrationType: '=',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      },
    });

  function LicensingOptionModalCtrlFn(_, HeliosService, $state) {
    var $ctrl = this;

    assign($ctrl, {
      licenseOption: {
        licenseValue: 'FullHeliosConnect',
      },
      optionSelected: optionSelected,
      cancelModal: cancelModal,
    });

    /**
     * This is function responsible for state changing and
     * redirection based on the option selected in the modal
     *
     * @method   optionSelected
     */
    function optionSelected() {
      $ctrl.cancelModal();

      if ($ctrl.licenseOption.licenseValue === 'FullHeliosConnect' ||
          $ctrl.licenseOption.licenseValue === 'ViewOnly') {
            HeliosService.updateHeliosConfiguration({
              mcmReadOnly:
                $ctrl.licenseOption.licenseValue === 'ViewOnly' ? true : false,
              licenseOnly: $ctrl.registrationType !== 'saas',
            }).then(function goToCheckConnectionPage() {
              $state.go('check-connection');
            });
      } else if ($ctrl.licenseOption.licenseValue === 'ManualConnect') {
        $state.go('cluster-connect-dark-site');
      }
    }

    /**
     * Cancels this modal
     *
     * @method     cancelModal
     *
     * @return     {Object}  Promise with the modal close result
     */
    function cancelModal() {
      return $ctrl.cModalHoc.close();
    }
  }
})(angular);
