import { Injectable } from '@angular/core';
import {
  HeliosStatsServiceApi,
  LastProtectionRunStats,
  LastProtectionRunStatsByEnv,
  StatsServiceApi,
} from '@cohesity/api/v1';
import { getDateRangeFilter } from '@cohesity/helix';
import { IrisContextService, isAllClustersScope, isMcm } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { SeriesColumnOptions } from 'highcharts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services';
import { enumGroupMap, EnvGroup, GroupData } from 'src/app/shared/constants';

/**
 * Serialize column value into an array with env group name as column label
 * and two numbers for met and missed SLA for column values.
 */
type ColumnValues = [ EnvGroup, number, number ];

/**
 * Compliance card data.
 */
export interface ComplianceData {
  totalMetSla?: number;
  totalMissedSla?: number;
  stats?: {
    nameKey: string;
    value: number;
    change?: number;
  }[];
  series?: SeriesColumnOptions[];
  categories?: string[];
}

/**
 * @description
 * SLA Compliance service for SlaComplianceCardComponent.
 */
@Injectable()
export class SlaComplianceService {

  constructor(
    private heliosStatsService: HeliosStatsServiceApi,
    private statsService: StatsServiceApi,
    private translateService: TranslateService,
    private appStateService: AppStateService,
    private irisContextService: IrisContextService,
  ) {}

  /**
   * Flag to indicate if dashboard is in MCM mode.
   */
  get isMcm() {
    return isMcm(this.irisContextService.irisContext);
  }

  /**
   * Flag to indicate cluster selection scope in MCM mode.
   */
  get allClusters() {
    return isAllClustersScope(this.irisContextService.irisContext);
  }

  /**
   * Returns observable of `LastProtectionRunStats` based on MCM or standalone cluster.
   */
  private getData(): Observable<LastProtectionRunStats> {
    const dateRangeFilter = getDateRangeFilter('past24hours');
    const params = {
      fromTimeUsecs: dateRangeFilter.start.valueOf() * 1000,
      toTimeUsecs: dateRangeFilter.end.valueOf() * 1000,
    };

    if (this.isMcm) {
      const mcmParams = [];
      const { clusterId, clusterIncarnationId } = this.appStateService.selectedScope;

      if (clusterId && clusterIncarnationId) {
        mcmParams.push(`${clusterId}:${clusterIncarnationId}`);
      }

      return this.heliosStatsService.McmGetLastProtectionRunStats({
        ...mcmParams,
        ...params,
      });
    }

    return this.statsService.GetLastProtectionRunStats(params);
  }

  /**
   * Returns observable of ComplianceData.
   */
  getComplianceData(): Observable<ComplianceData> {
    return this.getData().pipe(map((res: LastProtectionRunStats) => {
      const complianceData: ComplianceData = {
        totalMetSla: 0,
        totalMissedSla: 0,
        series: [],
        categories: [],
        stats: [{
          nameKey: 'kPass',
          value: res.numRunsMetSla,
          // TODO(alex.logashov): API doesn't support change value yet.
          change: 0
        }, {
          nameKey: 'kFail',
          value: res.numRunsFailedSla,
          // TODO(alex.logashov): API doesn't support change value yet.
          change: 0
        }]
      };

      const objectData: GroupData = {};

      res.statsByEnv.forEach((env: LastProtectionRunStatsByEnv) => {
        const { environment, numObjectsMetSla, numObjectsFailedSla } = env;
        const envGroup = enumGroupMap[environment] || 'other';

        if (!(envGroup in objectData)) {
          objectData[envGroup] = [ 0, 0 ];
        }

        const data = objectData[envGroup];
        data[0] += numObjectsMetSla;
        data[1] += numObjectsFailedSla;

        complianceData.totalMissedSla += numObjectsFailedSla;
        complianceData.totalMetSla += numObjectsMetSla;
      });

      // Create new data structure, with first value is category,
      // second and third values are met and missed SLAs ["vm", 12, 14]
      let columnValues: ColumnValues[] = Object.keys(objectData).map((group: EnvGroup) => {
        const [ metSla, missedSla ] = objectData[group];
        return [ group, metSla, missedSla ] as ColumnValues;
      });

      // Sort columns by aggregate met and missed SLA values
      columnValues.sort((a, b) => a[1] + a[2] > b[1] + b[2] ? -1 : 1);

      if (columnValues.length > 4) {
        // Pick top 3 columns and move all other values to "other" column
        const other = columnValues.slice(3).reduce((c, a) => [c[0], c[1] + a[1], c[2] + a[2]], ['other', 0, 0]);
        columnValues = columnValues.slice(0, 3).concat([other]);
      }

      // Create categories for column chart. d[0] is category, d[1] is met SLA, d[2] is missed SLA.
      complianceData.categories = columnValues.map(d => this.translateService.instant(`enum.envGroup.${d[0]}`));

      for (let i = 0; i < 2; i++) {
        complianceData.series.push({
          type: 'column',
          name: this.translateService.instant(i === 0 ? 'metSla' : 'missedSla'),
          data: columnValues.map(d => d[i + 1]) as number[]
        });
      }

      return complianceData;
    }));
  }
}
