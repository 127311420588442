import { assign } from 'lodash-es';
// Component: Parent for Upload/Download of licence component

(function(angular) {
  'use strict';

  angular
    .module('C.licenseServer')
    .controller('UploadDownloadModalWrapperCtrl',
       UploadDownloadModalWrapperCtrlFn)
    .component('uploadDownloadModalWrapper', {
      templateUrl: 'app/license/upload-download-modal-wrapper.html',
      controller: 'UploadDownloadModalWrapperCtrl',
      bindings: {
        uploadComponent: '=?',
      },
    });

  function UploadDownloadModalWrapperCtrlFn(LicenseServerService) {
    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method $onInit
     *
     */
    function $onInit() {
      $ctrl.uploadDownloadWrapperConfig = {
        steps: [{
          img: 'icn-download-arrow',
          description: 'license.downloadAuditReport',
          button: {
            text: 'download',
            callback: LicenseServerService.downloadAuditReport,
          },
        }, {
          img: 'icn-helios-blue',
          description: 'license.uploadDownloadDescription',
        }, {
          img: 'icn-upload',
          description: 'license.uploadLicenseFile',
          button: {
            text: 'upload',
            callback: $ctrl.uploadComponent.cb,
          },
        }],
      };
    }
  }
})(angular);
