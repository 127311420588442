<cog-card-section-filters>
  <div [class.compact-controls]="compactControls" class="control-row">
    <div class="control-list">
      <cog-filters (valueChange)="filters.setFilterValues($event)" [showFilterLabel]="false">
        <ng-container *ngFor="let filterGroup of filters.filterGroups$ | async">
          <coh-source-tree-filter *ngIf="!compactControls || filterGroup.alwaysShow"
            [filterGroup]="filterGroup">
          </coh-source-tree-filter>
        </ng-container>
        <cog-search-property-filter
          *cogFilterDef="
            let filter;
            key: 'search';
            label: 'search' | translate;
            quickFilter: true;
            filterType: 'searchProperty'"
          [filter]="filter"
          [placeholder]="'search' | translate">
        </cog-search-property-filter>
      </cog-filters>
    </div>

    <div class="control-list">
      <coh-source-tree-exclude-control-outlet
        *ngIf="excludeOptions"
        [component]="excludeComponent"
        [excludeOptions]="excludeOptions"
        (excludeResults)="excludeResults.emit($event)">
      </coh-source-tree-exclude-control-outlet>

      <button cogDataId="source-tree-expand-to"
        class="cog-quick-filter-button margin-left-sm"
        [ngClass]="{'apply-background': selectedOption}"
        type="button"
        mat-stroked-button
        [matMenuTriggerFor]="expandMenu"
        *ngIf="expandOptions?.length && !compactControls">
        <div class="flex-row align-items-center">
          <span>{{selectedOption ? (selectedOption | translate) : ('expandTo' | translate)}}</span>
          <cog-icon *ngIf="!selectedOption;
            else clearSelection" shape="arrow_drop_down" size="md"></cog-icon>
          <ng-template #clearSelection>
            <cog-icon class="margin-left-xs" shape="close" size="sm"
              (click)="onSelectOption(null)"></cog-icon>
          </ng-template>
        </div>
        <mat-menu #expandMenu="matMenu">
          <button *ngFor="let expandOption of expandOptions let index = index"
            mat-menu-item
            [trackingEvent]="{key: 'source_tree_expand_to_clicked', properties: {value: expandOption.label | translate}}"
            [cogDataId]="expandOption.label | translate"
            [inheritDataId]="true"
            (click)="onSelectOption(expandOption)">
            {{expandOption.label | translate}}
          </button>
        </mat-menu>
      </button>
      <cog-slicer
        *ngIf="(filters.viewFilters$ | async).length && showViewFilters"
        [compact]="false">
        <mat-button-toggle-group class="margin-left"
          [value]="filters.selectedViewFilter$ | async"
          (change)="clearSelectionAndSetNewFilter($event.value)">
          <ng-container *ngFor="let viewFilter of filters.viewFilters$ | async; let index = index">
            <mat-button-toggle id="view-option-{{index}}"
              [cogDataId]="(viewFilter.id || viewFilter.tooltip || viewFilter.label) | translate"
              [trackingEvent]="{key: 'source_tree_view_filter_clicked', properties: {
                value: ((viewFilter.tooltip || viewFilter.label) | translate)
              }}"
              [value]="viewFilter"
              [matTooltip]="viewFilter.tooltip | translate">
              <span class="button-label">
                {{viewFilter.label}}
              </span>
              <cog-icon class="{{viewFilter.icon}}" [shape]="viewFilter.icon"
                *ngIf="viewFilter.icon"></cog-icon>
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
      </cog-slicer>
      <button (click)="refreshSourceTree.emit()"
        *ngIf="showRefreshSourceTree"
        cogDataId="source-tree-refresh-button"
        mat-icon-button
        class="margin-left-sm"
        matTooltip="{{ lastRefreshedUsecs ?
          ('labelColonValue' | translate : {
            label: ('lastRefreshed' | translate),
            value: (lastRefreshedUsecs | humanizeFromUsecs)
          }) : ('refresh' | translate) }}"
        type="button">
        <cog-icon shape="refresh"></cog-icon>
      </button>
    </div>
  </div>
  <ng-container *ngIf="filters.selectedViewFilter$ | async as selectedViewFilter">
    <div *ngIf="selectedViewFilter.isTag && selectedViewFilter.id !== ViewFilterType.SimpleTag">
      <coh-select-tag-control class="tag-form"
        (valueChange)="tagsChanged()"
        [formControl]="selectedTags"
        [tagCategories]="filters.availableTags$ | async"
        [isTagLabel]="selectedViewFilter.isTagLabel">
      </coh-select-tag-control>
    </div>
  </ng-container>
</cog-card-section-filters>
