import { assign } from 'lodash-es';
// Module: VM Source list tab

;(function(angular) {
  'use strict';

  angular
    .module('C.devOps')
    .controller('VmSourceListCtrl', VmSourceListCtrlFn)
    .component('cKuiperVmSourceList', {
      templateUrl: 'app/devops/vm-source-list/vm-source-list.html',
      controller: 'VmSourceListCtrl',
    });

  function VmSourceListCtrlFn(_, $q, HypervisorVmService, SourceService,
    PubSourceService, SourcesUtil, evalAJAX, ENV_TYPE_CONVERSION) {

    var $ctrl = this;

    // Declare component methods and variables.
    assign($ctrl, {
      // Life cycle methods.
      $onInit: getSourceList,

      // Component methods.
      registerNewSource: registerNewSource,

      // List of the sources.
      registeredSources: [],
      dataReady: false,
    });

    /**
     * Gets the registered source list including hypervisor source
     *
     * @method    getSourceList
     * @returns   {Void}
     */
    function getSourceList() {
      var params = {
        query: 'all',
      };

      $ctrl.dataReady = false;

      var promises = {
        getSourcesInfo: PubSourceService.getSourcesInfo(),
        getHypervisorVMs: HypervisorVmService.getHypervisorVMs(params),
      };

      $q.all(promises).then(
        function getSources(responses) {
          var sourceGroups;
          var sourceInfo = HypervisorVmService.getSourceInformation(
            responses.getHypervisorVMs);
          $ctrl.registeredSources.push(sourceInfo);

          sourceGroups = SourcesUtil.groupSourcesTree(
            responses.getSourcesInfo.rootNodes);

          sourceGroups.forEach(function addSource(sourceGroup) {
            $ctrl.registeredSources = $ctrl.registeredSources.concat(
              sourceGroup.nodes);
          });
        }, evalAJAX.errorMessage).finally(
          function getSourcesFinally() {
            $ctrl.dataReady = true;
        });
    }

    /**
     * Register the new source
     *
     * @method    registerNewSource
     * @returns   {Void}
     */
    function registerNewSource() {
      SourceService.registerSourceSlider(ENV_TYPE_CONVERSION['kVMware']).then(
        function registerSourceSuccess(source) {
          PubSourceService.getSourcesInfo({ ids: source.entity.id }).then(
            function getSourcesInfoSuccess(response) {
              var sourceGroups = SourcesUtil.groupSourcesTree(
                response.rootNodes);

              if (sourceGroups.length) {
                $ctrl.registeredSources = $ctrl.registeredSources.concat(
                  sourceGroups[0].nodes);
              }
            }
          );
        });
    }
  }

})(angular);