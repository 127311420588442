import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { RecoverToOptions } from 'src/app/modules/restore/restore-shared';
import { RecoveryFormContextService } from 'src/app/modules/restore/restore-shared/model/recovery-form-context';
import { Environment } from 'src/app/shared/constants';
import { UdaRecoverToModel } from 'src/app/modules/restore/restore-shared';
import { RestoreTargetInfo } from 'src/app/modules/restore/restore-shared/model/recover-to-source';

/**
 * Uda environment type.
 */
export type UdaEnvironment = Environment.kUDA | Environment.kSAPHANA;

/**
 * Recovery target selector for UDA restore jobs.
 */
@Component({
  selector: 'coh-recover-uda-recover-to',
  templateUrl: './recover-uda-recover-to.component.html',
  styleUrls: ['./recover-uda-recover-to.component.scss'],
  providers: [subformComponentProviders(UdaRecoverToComponent)],
})
export class UdaRecoverToComponent extends NgxSubFormComponent<UdaRecoverToModel>
  implements OnInit {

  /**
   * Behavior subject to store list of registered UDA clusters.
   */
  readonly servers$ = new BehaviorSubject<RestoreTargetInfo[]>([]);

  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly: boolean;

  /**
   * Environment of the source.
   */
  environment: UdaEnvironment;

  /**
   * Expose 'recover to' type options for view.
   */
  recoverToOptions = RecoverToOptions;

  /**
   * Whether to allow sources with refresh/auth error. We are currently
   * piggybacking on the nosql feature flag for this. This can be removed later
   * if needed.
   */
  allowUnverifiedRecoveryTarget = flagEnabled(this.irisCtx.irisContext, 'nosqlAllowUnverifiedSourceForRecovery');

  constructor(
    private ajaxHandler: AjaxHandlerService,
    readonly recoveryContext: RecoveryFormContextService,
    private irisCtx: IrisContextService,
    readonly passthroughOptionsService: PassthroughOptionsService,
    private protectionSourcesApi: ProtectionSourcesServiceApi,
  ) {
    super();
  }

  /**
   * Fetch the list of recovery targets for UDA.
   *
   * @returns  Observable list of UDA restore targets.
   */
  private getRestoreTargets(environment: Environment): Observable<RestoreTargetInfo[]> {
    return this.protectionSourcesApi.ListProtectionSourcesRegistrationInfo({
      environments: [environment as UdaEnvironment],
      ...this.passthroughOptionsService.requestParams,
    }).pipe(
      map(info => info?.rootNodes || []),
      map(nodes => nodes
        .filter(node =>
            this.allowUnverifiedRecoveryTarget ||
            !(node.registrationInfo.refreshErrorMessage || node.registrationInfo.authenticationErrorMessage)
        )
        .map(node => ({
          id: node.rootNode.id,
          name: node.rootNode.name
        }))
      )
    );
  }

  ngOnInit() {

    this.recoveryContext.recoveryObjects$.pipe(
      takeUntilDestroyed(this),
      distinctUntilChanged(),
    ).subscribe(objects => {
      const environment = objects?.[0]?.objectInfo.environment as UdaEnvironment;
      this.getRestoreTargets(environment).pipe(
        takeUntilDestroyed(this)
      ).subscribe(
        (servers: RestoreTargetInfo[]) => this.servers$.next(servers),
        error => this.ajaxHandler.handler(error)
      );
    });

    this.formGroupControls.type.valueChanges.pipe(
      takeUntilDestroyed(this),
    ).subscribe((type) => {
      this.formGroupControls.newTarget.reset();

      if (type === RecoverToOptions.originalLocation) {
        this.formGroupControls.newTarget.clearValidators();
      } else {
        this.formGroupControls.newTarget.setValidators([Validators.required]);
      }

      this.formGroupControls.newTarget.updateValueAndValidity();
      this.formGroupControls.overwriteConfirmation.reset();
      this.formGroupControls.overwriteConfirmation.updateValueAndValidity();
      this.formGroupControls.overwrite.reset();
      this.formGroupControls.overwrite.updateValueAndValidity();
    });
  }

  getDefaultValues(): Partial<UdaRecoverToModel> {
    return {
      type: RecoverToOptions.originalLocation,
      overwrite: false
    };
  }

  protected getFormControls(): Controls<UdaRecoverToModel> {
    return {
      type: new UntypedFormControl(null, Validators.required),
      newTarget: new UntypedFormControl(null),
      overwrite: new UntypedFormControl(null),
      overwriteConfirmation: new UntypedFormControl(null),
    };
  }
}
