import { FeatureFlag } from 'src/app/core/services';
import { Environment } from 'src/app/shared/constants';

/**
 * Local storage key to fetch the flag specifying whether to hide/show the UDA onboarding screens.
 */
export const hideUdaOnboardingWizardFlag = 'hideUdaOnboardingWizard';

/**
 * Data type for UDA custom arguments stored against the registration, protection & recovery workflows.
 *
 * Although custom arguments are saved as <string, string> pairs, while rendering on UI, these may take
 * up data type specific values like numbers/booleans/etc.
 */
export type UdaCustomArgs = { [key in string]: string | number | boolean };

/**
 * Custom argument label and value pair model to be used for rendering these for the details page
 * for registration, backup and recovery.
 */
export interface CustomArgDisplayEntry {
  /**
   * Label to shown for the custom argument field.
   */
  label: string;

  /**
   * Value of the custom argument field.
   */
  value: string;
}

/**
 * Settings list model for a managed connector's protection details view.
 */
export interface ManagedConnectorProtectionSettings {

  /**
   * Title of the setting list panel.
   */
  title: string;

  /**
   * List of settings.
   */
  settings: CustomArgDisplayEntry[];
}

/**
 * Source types that can be registered as Universal Data Adapter sources.
 *
 * (Source types are now managed via UDA Dynamic Config. This enum is only to
 * handle transition during rolling upgrades, where the cluster nodes get
 * upgraded, but configs are only available post full cluster upgrade.)
 */
export enum UdaSourceType {

  /**
   * Any other database.
   */
  kOther = 'kOther',

  /**
   * CockroachDB
   */
  kCockroachDB = 'kCockroachDB',

  /**
   * IBM DB2
   */
  kDB2 = 'kDB2',

  /**
   * MariaDB
   */
  kMariaDB = 'kMariaDB',

  /**
   * MySQL
   */
  kMySQL = 'kMySQL',

  /**
   * SAP ASE
   */
  kSapASE = 'kSapASE',

  /**
   * SAP Hana.
   */
  kSapHana = 'kSapHana',

  /**
   * SAP MaxDB
   */
  kSapMaxDB = 'kSapMaxDB',

  /**
   * SAP Oracle.
   */
  kSapOracle = 'kSapOracle',

  /**
   * SAP Sybase
   */
  kSapSybase = 'kSapSybase',

  /**
   * SAP SybaseIQ
   */
  kSapSybaseIQ = 'kSapSybaseIQ',

  /**
   * PostgresSQL
   */
  kPostgreSQL = 'kPostgreSQL',
}

/**
 * List of supported UDA environment types.
 * This list only includes that UDA environment types which depend on the UDA
 * UI infra for their registration/backup/recovery workflows.
 */
export const UDA_ENVIRONMENTS = [
  Environment.kUDA,
  Environment.kSAPHANA
] as const;

/**
 * Consolidated type for all the supported UDA environemnts.
 */
export type UdaEnvironment = typeof UDA_ENVIRONMENTS[number];

/**
 * Generic type definition for any UDA adapter environment property map.
 */
export type UdaEnvPropertyMap = {[key in UdaEnvironment]?: string};

/**
 * UDA environment to icon map.
 */
export const UdaEnvToIcon: UdaEnvPropertyMap =  {
  kUDA: 'helix:group-database',
};

/**
 * Type definition for the the UDA environment to workflow titles mapping.
 */
export type UdaEnvTitleMap = {
  [key in 'registration' | 'protection' | 'restore']: UdaEnvPropertyMap
};

/**
 * Source type to Title translation map for UDA sources.
 */
export const UdaEnvToTitle: UdaEnvTitleMap = {
  registration: {
    kUDA: 'source.uda.registrationTitle',
  },
  protection: {
    kUDA: 'universalDataAdapter',
  },
  restore: {
    kUDA: 'universalDataAdapter',
  }
};

/**
 * UDA environment type to source type map.
 * This will not contain 'kUDA' as 'kUDA' maps to multiple source types. The
 * callers needs to ensure not to call this with kUDA.
 */
export const UdaEnvToSourceType: {[key in UdaEnvironment]?: UdaSourceType} = {
  // Add env to source type mappings here once FCC adapters are available.
};

/**
 * Consolidated type for all the UDA object types.
 */
export type UdaObjectType = 'Database' | 'Table' | 'Bucket';

/**
 * UDA object type to object icon map.
 */
export const UdaObjectTypeIconMap: {[k in UdaObjectType]?: string} = {
  'Database': 'helix:object-db',
  'Table': 'helix:object-table',
  'Bucket': 'helix:object-db',
};

/**
 * UDA environment type to source registration param name mapping.
 */
export const UdaEnvSourceParams: UdaEnvPropertyMap = {
  kUDA: 'udaParams',
  kSAPHANA: 'udaParams',
};

/**
 * UDA environment type to indexed objects search param name mapping.
 */
export const UdaEnvIndexedObjectsSearchParams: UdaEnvPropertyMap = {
  kUDA: 'udaParams',
  kSAPHANA: 'udaParams',
};

/**
 * UDA environment type to indexed objects search response param name mapping.
 */
export const UdaEnvIndexedObjectsSearchResponseParams: UdaEnvPropertyMap = {
  kUDA: 'udaObjects',
  kSAPHANA: 'udaObjects',
};

/**
 * UDA environment type to indexed object's object-type mapping.
 */
export const UdaEnvIndexedObjectType: UdaEnvPropertyMap = {
  kUDA: 'UdaObjects',
  kSAPHANA: 'UdaObjects',
};

/**
 * List of source types currently supported by the Universal Data Adapter for
 * on-prem.
 *
 * (Source types are now managed via UDA Dynamic Config. This enum is only to
 * handle transition during rolling upgrades, where the cluster nodes get
 * upgraded, but configs are only available post full cluster upgrade.)
 */
export const UdaOnPremSupportedSourceTypes: UdaSourceType[] = [
  UdaSourceType.kCockroachDB,
  UdaSourceType.kDB2,
  UdaSourceType.kMariaDB,
  UdaSourceType.kMySQL,
  UdaSourceType.kSapASE,
  UdaSourceType.kSapHana,
  UdaSourceType.kSapMaxDB,
  UdaSourceType.kSapOracle,
  UdaSourceType.kSapSybase,
  UdaSourceType.kSapSybaseIQ,
  UdaSourceType.kOther,
];

/**
 * List of source types currently supported by the Universal Data Adapter on
 * DMaaS.
 */
export const UdaDmsSupportedSourceTypes: UdaSourceType[] = [
  UdaSourceType.kOther,
];

/**
 * Map of UDA on-prem source types and the feature flags that control their
 * availability on UI.
 *
 * (Source types are now managed via UDA Dynamic Config. This enum is only to
 * handle transition during rolling upgrades, where the cluster nodes get
 * upgraded, but configs are only available post full cluster upgrade.)
 */
export const udaOnPremSourceTypeToFeatureFlagMap: { [key in UdaSourceType]?: FeatureFlag[] } = {
  [UdaSourceType.kDB2]: ['db2UdaEnabled'],
  [UdaSourceType.kMySQL]: ['mySqlUdaEnabled'],
  [UdaSourceType.kMariaDB]: ['mariaDBLinuxUdaEnabled'],
  [UdaSourceType.kSapASE]: ['sapAseLinuxUdaEnabled', 'sapAseWindowsUdaEnabled'],
  [UdaSourceType.kSapMaxDB]: ['sapMaxDBUdaEnabled'],
  [UdaSourceType.kSapSybase]: ['sapSybaseUdaEnabled'],
  [UdaSourceType.kSapSybaseIQ]: ['sapSybaseIQUdaEnabled'],
};

/**
 * Map of UDA DMaaS source types and the feature flags that control their
 * availability on UI.
 */
export const udaDmsSourceTypeToFeatureFlagMap: { [key in UdaSourceType]?: FeatureFlag[] } = {
  /* Add UDA DMaaS source type to feature flag mappings here. */
};

/**
 * Default value for the number of mounts/vips for a backup or restore job.
 */
export const DEFAULT_UDA_MOUNTS_OR_VIPS_PER_JOB = 4;
