import { assign } from 'lodash-es';
// Module: Add/Edit Identity Provider

;(function(angular) {
  'use strict';

  angular
    .module('C.apiKeys')
    .controller('modifyApiKeyCtrl', modifyApiKeyCtrlFn);

  /**
   * Controller for the Create New IDP
   */
  function modifyApiKeyCtrlFn(_, $state, UserService, DateTimeService) {
    var $ctrl = this;
    $ctrl.user = UserService.user;

    // Controller Definition
    assign($ctrl, {
      // Controller Variables
      dataReady: false,
      users: [],
      apiKey: {
        isActive: true,
        expiringDate: new Date(),
        expiringTime: {
          hour: 0,
          minute: 0,
        },
      },
      apiKeyForm: {},
      isEditMode: !!$state.params.id,
      apiKeyToken: $state.params.apiKeyToken,
      minDate: DateTimeService.beginningOfDay(new Date()),

      // Controller Methods
      $onInit: $onInit,
      showUserSelect: showUserSelect,
      submitForm: submitForm,
      downloadFilename: downloadFilename,
      rotateApiKey: rotateApiKey,
      goToApiKeys: goToApiKeys,
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeData();
    }

    /**
     * Initialize the data for the responses
     *
     * @method   _initializeData
     */
    function _initializeData() {
      if ($ctrl.isEditMode) {
        $ctrl.dataReady = false;

        UserService.getApiKey($state.params.userSid, $state.params.id).then(
          function getApiKey(apiKey) {
            $ctrl.apiKey = apiKey;

            if (apiKey.expiringTimeMsecs) {
              var dateObj = new Date(apiKey.expiringTimeMsecs);

              $ctrl.apiKey.setExpiration = true;
              $ctrl.apiKey.expiringDate = dateObj;
              $ctrl.apiKey.expiringTime = {
                hour: dateObj.getHours(),
                minute: dateObj.getMinutes(),
              };
            } else {
              $ctrl.apiKey.expiringDate = new Date();
              $ctrl.apiKey.expiringTime = {
                hour: 0,
                minute: 0,
              };
            }
          }).finally(function finalizeApiKey() {
          $ctrl.dataReady = true;
        });
      } else if (showUserSelect()) {
        UserService.getAllUsers().then(function getUsers(users) {
          $ctrl.users = users;

          // Select the logged in user by default
          $ctrl.apiKey.user = $ctrl.users.find(function findUser(user) {
            return $ctrl.user.sid === user.sid;
          });
        }).finally(function finalizeUsers() {
          $ctrl.dataReady = true;
        });
      } else {
        $ctrl.dataReady = true;
      }
    }

    /**
     * Determine whether user dropdown should be visible.
     */
    function showUserSelect() {
      return $ctrl.user.privs.PRINCIPAL_MODIFY;
    }

    /**
     * Submit the form
     *
     * @method   submitForm
     * @param    {object}    apiKey   The IDP configuration
     */
    function submitForm(apiKey) {
      var userSid;
      var data = apiKey;

      if ($ctrl.apiKeyForm.$invalid) {
        return;
      }

      if (apiKey.user) {
        // If a user is manually selected
        userSid = apiKey.user.sid;
      } else if ($state.params.id) {
        // If editing an existing API key
        userSid = $ctrl.apiKey.ownerUserSid;
      } else {
        // Creating a new API key
        userSid = $ctrl.user.sid;
      }

      if (apiKey.setExpiration) {
        var dateObj = new Date(apiKey.expiringDate);
        dateObj.setHours($ctrl.apiKey.expiringTime.hour);
        dateObj.setMinutes($ctrl.apiKey.expiringTime.minute);
        dateObj.setSeconds(0);

        data.expiringTimeMsecs = dateObj.getTime();
      } else {
        data.expiringTimeMsecs = null;
      }

      $ctrl.loading = true;

      ($ctrl.isEditMode
        ? UserService.updateApiKey(userSid, $state.params.id, $ctrl.apiKey)
        : UserService.createApiKey(userSid, $state.params.id, $ctrl.apiKey)
      ).finally(function finalizePromise() {
        $ctrl.loading = false;
      });
    }

    /**
     * Function to return a filename for the API Key when downloaded as a text
     * file.
     */
    function downloadFilename() {
      var name = $ctrl.apiKey.name;

      // Strip special characters from the name to make it a valid filename.
      return 'api_key_' + name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    }

    /**
     * Function to rotate an API key.
     */
    function rotateApiKey() {
      $ctrl.loading = true;

      UserService.rotateApiKey($ctrl.apiKey).finally(
        function finalizePromise() {
          $ctrl.loading = false;
        }
      );
    }

    /**
     * Navigates to previous router state.
     */
    function goToApiKeys() {
      $state.go('access-management.api-keys');
    }
  }
})(angular);
