import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutoDestroyable } from '@cohesity/utils';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';

import {
  BuilderConfig,
  ControlFlow,
  disableAllDescendantControlFlow,
  enableControlFlow,
  enableImmediateChildrensControlFlow,
  forEachControlFlow,
  FormBuilderControl,
  FormGroupValueMap,
  FormSectionRef,
  FormSectionRefByName,
} from './form-builder.model';

@Directive({
  selector: '[cohFormSectionHost]',
})
export class FormSectionHostDirective {
  constructor(
    public readonly templateRef: TemplateRef<unknown>,
    public viewContainerRef: ViewContainerRef,
  ) { }
}

@Component({
  selector: 'coh-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBuilderComponent<FormModel, DataModel> extends AutoDestroyable implements AfterViewInit, OnDestroy {
  /** Indicate whether we are fetching data. */
  isLoading = false;

  /** Indicates whether we are submitting the data or not. */
  isSubmitting = false;

  @Input() dataModel: DataModel;

  @Input() saveButtonTitle: string;

  formGroup = new FormGroup<FormBuilderControl<FormModel>>({} as FormBuilderControl<FormModel>);

  @Input() builderConfig: BuilderConfig;

  @ViewChild(FormSectionHostDirective, {static: true}) formSectionHost!: FormSectionHostDirective;

  private _formSectionRefByName =
    new BehaviorSubject<FormSectionRefByName<FormModel>>({} as FormSectionRefByName<FormModel>);

  get formSectionRefByName() {
    return this._formSectionRefByName.value;
  }

  get formSectionRefs() {
    return Object.values<FormSectionRef<FormModel>>(this.formSectionRefByName);
  }

  formSectionRefs$ = this._formSectionRefByName.pipe(
    map(() => this.formSectionRefs),
    filter(formSectionRefs => Boolean(formSectionRefs?.length)),
  );

  formGroupChanges$ = combineLatest([
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
    this.formGroup.statusChanges.pipe(startWith({})),
  ]).pipe(
    map(() => {
      const out = this.formSectionRefs.reduce(
        (acc, formSectionRef) => {
          acc[formSectionRef.name] = {
            value: formSectionRef.componentRef.instance.formControl.value,
            status: formSectionRef.componentRef.instance.formControl.status,
            valid: formSectionRef.componentRef.instance.formControl.valid,
            invalid: formSectionRef.componentRef.instance.formControl.invalid,
            enabled: formSectionRef.componentRef.instance.formControl.enabled,
            disabled: formSectionRef.componentRef.instance.formControl.disabled,
            mode: formSectionRef.componentRef.instance.mode,
          };
          return acc;
        },
        {} as Partial<FormGroupValueMap<FormModel>>
      );

      return out;
    }),
    distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
  );

  constructor(
    public cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderForm();
  }

  goBack() {
    this.builderConfig.goBack();
  }

  saveForm() {
    this.builderConfig.saveForm();
  }

  canShowSaveForm() {
    return this.builderConfig.canShowSaveForm();
  }

  isFormSectionVisible(formSectionRef: FormSectionRef<FormModel>) {
    return formSectionRef.componentRef.instance.formControl.enabled;
  }

  renderForm() {
    const formSectionRefByName = {} as FormSectionRefByName<FormModel>;

    (this.builderConfig.sections || []).map(section => {
      const componentRef = this.formSectionHost.viewContainerRef.createComponent(section);

      return {
        name: componentRef.instance.formSectionName,
        componentRef,
      } as FormSectionRef<FormModel>;
    }).forEach(formSectionRef => {
      formSectionRef.componentRef.instance.addFormSectionControl();
      formSectionRefByName[formSectionRef.name] = formSectionRef;
    });

    this._formSectionRefByName.next(formSectionRefByName);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formSectionHost.viewContainerRef.clear();
  }

  initFormSection() {
    this.formSectionRefs.forEach(formSectionRef => formSectionRef.componentRef.instance.initFormSection());
  }

  initControlFlow(root: ControlFlow<FormModel>) {
    enableControlFlow<FormModel>(root);
    disableAllDescendantControlFlow<FormModel>(root);

    this.formGroup.updateValueAndValidity();
    this.cdr.detectChanges();

    return this.formGroupChanges$.pipe(
      tap(() => {
        let shouldUpdate = false;

        forEachControlFlow(
          root,
          node => {
            if (node.formControl.disabled) {
              disableAllDescendantControlFlow(node);
              shouldUpdate = true;
              return false;
            } else if (node.isValidValue()) {
              if (
                node.formSectionRef.componentRef.instance.formSectionViewMode &&
                node.formSectionRef.componentRef.instance.mode === 'edit'
              ) {
                disableAllDescendantControlFlow(node);
                shouldUpdate = true;
                return false;
              }

              enableImmediateChildrensControlFlow(node);
              shouldUpdate = true;
            } else {
              disableAllDescendantControlFlow(node);
              shouldUpdate = true;
              return false;
            }

            return true;
          }
        );

        if (shouldUpdate) {
          this.formGroup.updateValueAndValidity();
          this.cdr.detectChanges();
        }
      }),
    );
  }
}
