import { get } from 'lodash-es';
// Type Icon directive
//
// This directive can be used to output an object type icon.
//
// Supported job object types include:
//      - type-view
//      - type-vm
//      - type-sql
//      - type-file
//      - type-script
//      - type-physical
//      - type-pure
//      - type-nas
//      - type-oracle
//
// Supported recover object types include:
//      - type-file
//      - type-vm
//      - type-sql
//      - type-view
//      - type-pure
//      - type-nas
//      - type-physical
//      - type-oracle
//      - type-office365
//
// Supported rbac types include:
//      - type-user
//      - type-group
//
// How to use, where data is a base object (including type) returned from
// the API (see below):
// <type-icon data="jobDescription" size="lg"></type-icon>

;(function (angular, undefined) {
  'use strict';

  angular.module('C.typeIcon', [])
    .directive('typeIcon', typeIconDirectiveFn);

  function typeIconDirectiveFn(_, ENUM_ICON_TYPE_MAPPING, ENUM_RESTORE_TYPE,
    ENV_TYPE_CONVERSION, ENV_GROUPS) {
    return {
      restrict: 'EA',
      scope: {
        // @type   {object}   Base object including type property as returned
        // from the API

        data: '<',

        // @type   {string=}  One of 'sm', 'md', 'lg'. Default: 'lg'
        size: '@?'
      },
      templateUrl: 'app/global/type-icon/type-icon.html',
      link: typeIconLinkFn,
    };

    function typeIconLinkFn(scope, elem, attrs) {

      scope.size = scope.size || 'lg';

      scope.ENUM_ICON_TYPE_MAPPING = ENUM_ICON_TYPE_MAPPING;

      switch (true) {

        // Cloud Cases
        case ENV_GROUPS.cloudSources
          .includes(get(scope, 'data.restoreInfo.type')):

          scope.iconType = scope.data.restoreInfo.type;

          // RDS has it's own icon
          if (!!get(scope.data, ['deployVmsToCloudTaskState',
            'deployVmsToCloudParams',
            'deployVmsToAwsParams',
            'rdsParams'].join('.'))) {

            scope.iconType = ENV_TYPE_CONVERSION.kRDSSnapshotManager;
          }
          scope.operation = 'job';
          break;

        // Detect Recovery operation
        case !!scope.data.base:
          // Set Icon type based on RestoreInfoProto
          // Differentiate between Oracle and SQL as both have recovery type as
          // KRecoveryApp/kCloneApp/kCloneAppView for recovery/clone.
          if (!!scope.data.restoreInfo &&
            [ENUM_RESTORE_TYPE.kRecoverApp,
            ENUM_RESTORE_TYPE.kCloneApp,
            ENUM_RESTORE_TYPE.kCloneAppView]
              .includes(scope.data.base.type)) {
            scope.iconType = scope.data.restoreInfo.type || 1;
            scope.operation = 'job';
          } else {
            scope.iconType = get(scope.data,
              'restoreFilesTaskState.restoreParams.isFileVolumeRestore') ? 10 :
              scope.data.base.type || 1;
            scope.operation = 'recover';
          }
          break;

        // Detect RBAC object
        case !!scope.data.lastUpdatedTimeMsecs:
          // Set Icon type based on rbac type
          scope.iconType = scope.data.type;
          scope.operation = 'rbac';
          break;

        // Otherwise, assume Job operation
        default:
          // Default set Icon type based on default Job Type, or Env Type
          scope.iconType = scope.data.environment || scope.data.type || 1;
          scope.operation = 'job';
      }
    }
  }

})(angular);
