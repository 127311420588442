import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: Recover DB: Snapshot Selector Modal

(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.dbRestore';

  angular
    .module(moduleName)
    .controller('dbSnapshotModalController', dbSnapshotModalControllerFn);

  function dbSnapshotModalControllerFn(_, $scope, $filter, $q, $state,
    RestoreService, SearchService, DateTimeService, task, entity,
    JobService, restoreTimeSecs, copyAttachOpts, timezone, FEATURE_FLAGS,
    isOlderAgent, PUB_TO_PRIVATE_ENV_STRUCTURES, LOCALE_SETTINGS) {

    var $ctrl = this;
    var isOracle = entity.vmDocument.objectId.entity.type === 19;
    var isSql = !isOracle;

    assign($ctrl, {
      restoreTimeSecs: restoreTimeSecs,
      pitDate: restoreTimeSecs && new Date(restoreTimeSecs * 1000),

      // Controller lifecycle methods
      $onInit: $onInit,

      isDbMigration: !!$state.params.isDbMigration,

      // Contains the validation message from API regarding non-availability
      // of restore time data.
      invalidRestoreTimeMessage: null,

      setInvalidPitMessage: setInvalidPitMessage,

      getRestoreTimeSecs: getRestoreTimeSecs,
    });

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      entity: entity,
      isOlderAgent: isOlderAgent,
      isOracle: isOracle,
      isSql: isSql,
      vdiSnapshotBasedVssBackup: isVdiSnapshotBasedVssBackup(),
      copyAttachOpts: copyAttachOpts,
      fullDateTimeFormat: DateTimeService.getFullDateTimeFormat(),
      recoveryPointsCount: entity._versions ? entity._versions.length : 0,
      _snapshotIndex: entity._snapshotIndex,
      task: task,
      currentTimezone: timezone.name,
      restoreTime: {},
      showMetaRow: {},
      dateOptions: {},

      // SCOPE METHODS
      saveSnapshotSelection: saveSnapshotSelection,
      getSnapshotIndex: getSnapshotIndex,
      setSnapshot: setSnapshot,
      toggleMetaRow: toggleMetaRow,
      changeDate: changeDate,
      changeSlider: changeSlider,
      makeLabel: makeLabel,
      isPITEnabled: isPITEnabled,
      isRACandPITDisabled: isRACandPITDisabled,
    });

    /**
     * Initialize all the things!
     *
     * @method     $onInit
     */
    function $onInit() {
      var versionsParams = entity._isHost ?
        { ownerEntityId: entity._ownerId } :
        { entityIds: entity.vmDocument.objectId.entity.id };
      var promises;

      versionsParams.jobIds = [entity._jobId];

      promises = {
        job: JobService.getJob(entity.vmDocument.objectId.jobId),
        versions: SearchService.getDBVersions(versionsParams,
          isOracle ? 'oracle' : 'sql'),
      };

      assign($ctrl, {
        canUsePitCalendar: _canUsePitCalendar(),

        // Init with any PIT already selected, otherwise use the selected
        // snapshot's date.
        snapshotsDate: $ctrl.pitDate || (entity._snapshot.instanceId.jobStartTimeUsecs/1000),
        preferredFormat: LOCALE_SETTINGS.preferredFormat,
      });

      $q.all(promises)
        .then(function versionsReceivedFn(responses) {
          $ctrl.job = responses.job;
          $ctrl.canRestoreFromArchiveTarget = _canRestoreFromArchiveTarget();

          if (responses.versions.length) {
            entity._versions = responses.versions;

            // Set the snapshot recovery points for the database
            $scope.recoveryPointsCount = responses.versions.length;
          }

          setSnapshot(
            entity._versions[entity._snapshotIndex || 0],
            entity._archiveTarget
          );

          if (_canUsePitCalendar()) { return; }

          // Classic PIT UI here
          setOptsForSelectedSnapshot();
        });
    }

    /**
     * Determines if this session can recover SQL DBs from a cloud archive.
     *
     * @method   _canRestoreFromArchiveTarget
     * @returns  {boolean}   True if can recover from Archive Targets.
     */
    function _canRestoreFromArchiveTarget() {
      if (isOracle) {
        return FEATURE_FLAGS.oracleRestoreFromArchive;
      }

      if (isSql && FEATURE_FLAGS.sqlRestoreFromArchive) {
        return FEATURE_FLAGS.sqlVolumeRestoreFromArchive ||
          // Job is not a volume-based SQL job.
          get(
            $ctrl.job,
            'envBackupParams.sqlBackupJobParams.fullBackupType'
          ) !== 0;
      }

      return false;
    }

    /**
     * Generates the label string for each cRangeSlider in the view.
     *
     * @method     makeLabel
     * @param      {string}  val     The label value
     * @return     {string}  The computed label string
     */
    function makeLabel(val) {
      return ['<small>', '</small>']
        .join($filter('usecsToDate')(val * 1000,
          $scope.fullDateTimeFormat,
          $scope.currentTimezone));
    }

    /**
     * Update restoreTime.date with a new value.
     *
     * @method     changeDate
     * @param      {date}  newDateTime  Slider's returned Date object
     */
    function changeDate(newDateTime) {
      $scope.restoreTime.date = new Date(+newDateTime || Date.clusterNow());
      $ctrl.setInvalidPitMessage(null);
    }

    /**
     * Update restoreTime.slider with the given value
     *
     * @method     changeSlider
     * @param      {Date}  date   Date object
     */
    function changeSlider(date) {
      $scope.restoreTime.slider = +date;
    }

    /**
     * Does some stuff when the meta row is toggled.
     *
     * @method     toggleMetaRow
     * @param      {object}  snapshot  The snapshot object
     */
    // TODO (spencer): Delete this Fn, among others when
    //   FEATURE_FLAGS.sqlSnapshotCalendar is enabled GA.
    function toggleMetaRow(snapshot) {
      var timestamp = snapshot.instanceId.jobStartTimeUsecs;

      // Clear previous time range details.
      $scope.dateOptions = {};
      if (!$scope.showMetaRow[timestamp]) {
        $scope.restoreTime = {};
      } else if (!$ctrl.canUsePitCalendar) {
        getTimeRanges(snapshot);
      }
    }

    /**
     * Get the usable restore time ranges from Magneto.
     *
     * @param      {object|Array}  snapshots  One, or array, of snapshot(s)
     *                                        to get time ranges for.
     * @return     {object}        The ranges response, or server response
     *                             (if error).
     */
    function getTimeRanges(snapshots) {
      // This is typically just one element, but could be multiple
      snapshots = [].concat(snapshots);
      var data = {
        type: isOracle ? 19 : 3,
        restoreAppObjectVec: (!entity._isHost) ?
          task.restoreAppParams.restoreAppObjectVec :
          [task.restoreAppParams.ownerRestoreInfo.ownerObject.entity],
        ownerObjectVec: snapshots.map(function ownerVecMapFn(snapshot) {
          return angular.extend(
            {},
            task.restoreAppParams.ownerRestoreInfo.ownerObject,
            {
              jobInstanceId: snapshot.instanceId.jobInstanceId,
              startTimeUsecs: snapshot.instanceId.jobStartTimeUsecs,
              attemptNum: snapshot.instanceId.attemptNum,
            }
          );
        }),
      };


      // Clear out previously set messages and errors
      snapshots.forEach(function eachSnapshotFn(snapshot) {
        var jobStartTime = snapshot.instanceId.jobStartTimeUsecs;

        if ($scope.dateOptions[jobStartTime]) {
          $scope.dateOptions[jobStartTime].error =
            $scope.dateOptions[jobStartTime].userMessage = undefined;
        }
      });

      $ctrl.invalidRestoreTimeMessage = null;
      $scope.loadingDateRanges = true;

      return RestoreService
        .getDBTimeRanges(data)
        .then(function gotDBTimeRangesFn(resp) {
          snapshots.forEach(function eachSnapshotFn(snapshot, ii) {
            $scope.dateOptions[snapshot.instanceId.jobStartTimeUsecs] =
              resp.ownerObjectTimeRangeInfoVec[ii];
          });

          return resp;
        }, function errorGettingDBTimeRangesFn(resp) {
          snapshots.forEach(function eachSnapshotFn(snapshot) {
            // Ensure this hash key exists
            $scope.dateOptions[snapshot.instanceId.jobStartTimeUsecs] =
              $scope.dateOptions[snapshot.instanceId.jobStartTimeUsecs] || {};
            $scope.dateOptions[snapshot.instanceId.jobStartTimeUsecs].error = true;
          });

          return resp;
        })
        .finally(function finalltGotDBTimeRangesFn(resp) {
          var timestamp = snapshots[0].instanceId.jobStartTimeUsecs;

          $scope.restoreTime.date = DateTimeService.usecsToDate(

            // If there's a set timeRangeVec,
            ($scope.dateOptions[timestamp] && $scope.dateOptions[timestamp].timeRangeVec) ?

            // Use that starting point as the pre-selection
            $scope.dateOptions[timestamp].timeRangeVec[0].startTimeUsecs :

            // Else use undefined
            undefined
          );

          // Sync the above set date to the slider as well
          changeSlider($scope.restoreTime.date);
          $scope.loadingDateRanges = false;

          return resp;
        });
    }

    /**
     * Set the opts for any previously selected snapshot & restore time.
     *
     * @method     setOptsForSelectedSnapshot
     */
    function setOptsForSelectedSnapshot() {
      var timestamp = restoreTimeSecs ||
        entity._versions[entity._snapshotIndex].instanceId.jobStartTimeUsecs;

      $scope.restoreTime.date = restoreTimeSecs ?
        DateTimeService.secsToDate(restoreTimeSecs) :
        undefined;
      changeSlider($scope.restoreTime.date);
      if (!!restoreTimeSecs) {
        getTimeRanges(entity._versions[entity._snapshotIndex]);
      }
      $scope.showMetaRow[timestamp] = !!restoreTimeSecs;
    }

    /**
     * Set the snapshotIndex and cleanup some things.
     *
     * @method   setSnapshot
     * @param    {object}   snapshot          The restore point selected.
     * @param    {object}   [archiveTarget]   The selected archive target.
     */
    function setSnapshot(snapshot, archiveTarget) {
      $scope._snapshotIndex = getSnapshotIndex(snapshot);
      $ctrl.snapshot = snapshot;
      setArchiveTarget(archiveTarget);
      $scope.restoreTime = {};
      $scope.showMetaRow = {};
    }

    /**
     * Sets the archive Target selected. If not provided, uses the latest
     * selection from the Entity.
     *
     * @method   setArchiveTarget
     * @param    {object}   [archiveTarget]   The user-selected target.
     */
    function setArchiveTarget(archiveTarget) {
      if (!$ctrl.canRestoreFromArchiveTarget) { return; }

      $ctrl.archiveTarget = archiveTarget ||
        get($ctrl.snapshot, 'replicaInfo.replicaVec[0]');
    }

    /**
     * Get the snapshot index.
     *
     * @method   getSnapshotIndex
     * @param    {object}    snapshot   Snapshot version
     * @return   {integer}   The found index
     */
    function getSnapshotIndex(snapshot) {
      var index = 0;

      if (snapshot) {
        entity._versions.some(function findIndex(row, ii) {
          if (snapshot.instanceId.jobStartTimeUsecs ===
            row.instanceId.jobStartTimeUsecs) {
            index = ii;

            return true;
          }

          return false;
        });
      }

      return index;
    }

    /**
     * Update the modal-scoped copy of the task with updated restoreTime data &
     * the selected snapshot
     *
     * @return   {object}   The updated task object.
     */
    function updateTaskCopy() {
      var snapshot =
        entity._snapshot || entity._versions[$scope._snapshotIndex];
      var restoreParams =
        task.restoreAppParams.restoreAppObjectVec[0].restoreParams;

      // Determine database restore params.
      var dbParams = isOracle ?
        restoreParams.oracleRestoreParams :
        restoreParams.sqlRestoreParams;

      assign(task.restoreAppParams.ownerRestoreInfo.ownerObject, {
        jobInstanceId: snapshot.instanceId.jobInstanceId,
        startTimeUsecs: +snapshot.instanceId.jobStartTimeUsecs,
      });

      // Populate restore time.
      dbParams.restoreTimeSecs = getRestoreTimeSecs();
      return task;
    }

    /**
     * Gets the restore time seconds.
     *
     * @return   {integer}   The selected point-in-time restore time in seconds
     */
    function getRestoreTimeSecs() {
      return !$ctrl.canUsePitCalendar ?
        Math.round($scope.restoreTime.date / 1000) :
        $ctrl.selections.pointInTime;
    }

    /**
     * Closes this modal.
     *
     * @param   {object}   [result]   The result object
     */
    function closeModal(result) {
      $scope.$close(result);
    }

    /**
     * Check if the chosen restore point is valid and save if it is. If not,
     * show some messaging about why not and stay in this modal.
     *
     * @method   saveSnapshotSelection
     */
    function saveSnapshotSelection() {
      var selections;

      // This is the object returned by this modal
      var result;

      if ($ctrl.canUsePitCalendar) {
        selections = get($ctrl, 'selections', {});
        // This assign is used to replicate legacy functionality from pre
        // 6.1.1
        assign(entity, {
          _snapshot: selections.snapshot,
          _snapshotIndex: getSnapshotIndex(selections.snapshot),
          _archiveTarget: selections.replica,
        });
        result = {
          selectedIndex: getSnapshotIndex(selections.snapshot),
          snapshot: selections.snapshot,
          archiveTarget: selections.replica,
          pointInTime: selections.pointInTime,
          task: angular.copy(updateTaskCopy()),
          isPIT: !selections.snapshot.attemptNumber,
        };
      } else {
        result = {
          selectedIndex: $scope._snapshotIndex,
          snapshot: entity._versions[$scope._snapshotIndex],
          archiveTarget: $ctrl.archiveTarget,
          task: angular.copy(updateTaskCopy()),
          isPIT: angular.isDate($scope.restoreTime.date),
        };
      }

      closeModal(result);
    }

    /**
     * Checks for the attribute vdi_snapshot_based_vss_backup in an atrributeMap
     * for a particular DB entity. This is only applicable for DB entities.
     *
     * @method   isVdiSnapshotBasedVssBackup
     * @return   {boolean}   true if the attribute is present, false otherwise
     */
    function isVdiSnapshotBasedVssBackup() {
      return entity.vmDocument.attributeMap.some(
        function filterAttributeMap(attribute){
          return attribute.xKey === "vdi_snapshot_based_vss_backup";
        });
    }

    /**
     * Oracle specific : Checks for RAC database and PIT RAC flag.
     *
     * @method   isRACandPITDisabled
     * @return   {boolean}  returns only if the source is oracle and the
     *                      selected object is RAC Database and RAC point
     *                      in time flag is enabled, False otherwise.
     */
    function isRACandPITDisabled() {
      var dbType = _getOracleDBType();
      return (dbType === PUB_TO_PRIVATE_ENV_STRUCTURES.kOracle.
        entityTypes.kRACDatabase) && !_isOraclePITEnabled();
    }

    /**
     * Oracle specific : gets the oracle database type.
     *
     * @method   _getOracleDBType
     * @return   {int}  returns type of database.
     */
    function _getOracleDBType() {
      return (get(entity.vmDocument.objectId.entity,
        'oracleEntity.dbEntityInfo.dbType'));
    }

    /**
     * Oracle specific : helper function to isPITEnabled.
     *
     * @method   _isOraclePITEnabled
     * @return   {boolean} checks the respective flags of RAC and standalone
     *                     Databases and returns true, if they are enabled.
     */
    function _isOraclePITEnabled() {
      var dbType;
      if (isOracle) {
        // In case of clone check for the clones PIT feature flag
        if (copyAttachOpts.isClone) {
          return FEATURE_FLAGS.oraclePITClone;
        }

        // In case of restore check for standalone and RAC feature flags
        dbType = _getOracleDBType();
        return dbType === PUB_TO_PRIVATE_ENV_STRUCTURES.kOracle.entityTypes
          .kRACDatabase ?
            FEATURE_FLAGS.oracleRACPITRecoverEnabled :
            FEATURE_FLAGS.oracleStandAlonePITRecoverEnabled;
      }
      return false;
    }

    /**
     * Determines if Point in Time is enabled.
     * (i) Virtual Host Entity -> PIT is enabled
     *
     * (ii) DB Entity -> Restore and Clone conditions are as below:
     *  Clone Workflow -> SqlPITCloneEnabled Flag ||
     *  vdiSqlPITCloneEnabled Flag && vdi_snapshot_based_vss_backup
     *  Restore Workflow ->
     *    1. Recovering back to original server ->  PIT is enabled
     *    2. Recovering to Alternate Location ->
     *      SqlPITAlternateLocationEnabled Flag ||
     *      vdiSqlPITAlternateLocationEnabled Flag &&
     *      vdi_snapshot_based_vss_backup
     *
     * @method   isPITEnabled
     * @return   {boolean}   True if Point in Time is enabled, False otherwise.
     */
    function isPITEnabled() {
      var recoverToOriginalServer = copyAttachOpts.recoverToOriginalServer;

      switch (true) {
        case isOracle:
          return _isOraclePITEnabled() && !isOlderAgent;

        // PIT is always enabled in case of VMs (Restore or Clone)
        case entity._isVirtualHost:
          return true;

        case copyAttachOpts.isClone:
          // Clone Flag to control SQL PIT clone functionality
          return FEATURE_FLAGS.sqlPITCloneEnabled ||

            // TODO(maulik): DEPRECATED condition. Remove after 6.0
            (FEATURE_FLAGS.vdiSqlPITCloneEnabled  &&
              $scope.vdiSnapshotBasedVssBackup);

        case isSql:
          // Recover: True only when recovering anything to original server.
          return recoverToOriginalServer ||

            // Restore Flag to control SQL PIT Restore functionality.
            FEATURE_FLAGS.sqlPITAlternateLocationEnabled ||

            // TODO(maulik): DEPRECATED condition. Remove after 6.0
            (FEATURE_FLAGS.vdiSqlPITAlternateLocationEnabled &&
              $scope.vdiSnapshotBasedVssBackup);
      }

      // It should never reach this return.
      return false;
    }

    /**
     * Determines if this flow can/should use the PIT calendar slider snapshot
     * picker widget.
     *
     * @method   _canUsePitCalendar
     * @return   {boolean}   True to use PIT calendar slider widget. False
     *                       otherwise.
     */
    function _canUsePitCalendar() {
      if (FEATURE_FLAGS.sqlSnapshotCalendar && isSql) {
        // Per https://jira.cohesity.com/browse/ENG-55059, we can't use new PIT
        // UI for host restoration in SQL flow (which is disabled by default and
        // only still used by a couple of customers and for internal use).
        return !$scope.entity._isHost;
      }

      return (FEATURE_FLAGS.oracleSnapshotCalendar && isOracle);
    }

    /**
     * Callback for the validate-pit directive. It is called with a message
     * received from API when the restore time data is not found.
     *
     * @method   setInvalidPitMessage
     * @param    {string}   message   The message to be set.
     */
    function setInvalidPitMessage(message) {
      $ctrl.invalidRestoreTimeMessage = message;
    }
  }
})(angular);
