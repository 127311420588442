import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: SQL Source List

;(function(angular, undefined) {

  /**
   * @ngdoc component
   * @name C.sqlDashboard:sqlSourceList
   *
   * @description
   * SQL Source data grid
   *
   * @example
   * <sql-source-list></sql-source-list>
   */
  angular.module('C.sqlDashboard')
    .controller('SqlSourceListController', SourceListCtrLFn)
    .component('sqlSourceList', {
      templateUrl: 'app/dashboard/sql/source-list/source-list.html',
      controller: 'SqlSourceListController',
    });


  /**
   * Component controller
   */
  function SourceListCtrLFn(
    _, evalAJAX, SourcesUtil, SqlDashboardService) {

    var $ctrl = this;

    $ctrl.hostNameFilter = '';

    assign($ctrl, {
      $onInit: $onInit,
      getAgentSortValue: getAgentSortValue,
      getConnectionStatus: getConnectionStatus,
    });

    /**
     * On onInit, make the call to get data
     *
     * @method $onInit
     */
    function $onInit() {
      _getData(true);
    }

    /**
     * Provides a sort key for a source node, based on whether an agent is
     * present, and the version of the agent.
     *
     * @method getAgentSortValue
     * @param    {object}   node   source node object
     * @return   {string}   '<0|1>_<version number>'
     */
    function getAgentSortValue(node) {
      return [
        node._envProtectionSource.hasPersistentAgent === false ? 0 : 1,
        get(node, '_agent.version') ? node._agent.version : 0
      ].join('_');
    }

    /**
     * Gets the agent connection status for a host.
     *
     * @method   getConnectionStatus
     * @param    {object}    node    source node object
     * @return   {boolean}   true if the agent is health, false if not
     */
    function getConnectionStatus(node) {
      // If we don't have any agent info, we'll just assume that the status is
      // ok. Agent info may not be included across all VM hosts yet.
      return !node.registrationInfo.authenticationErrorMessage &&
        !node._isConnectionError &&
        (!get(node, '_agent.version') || node._agent.status === 'kHealthy');
    }

    /**
     * Make the actual call to retreive data
     *
     * @method   _getData
     * @param    {boolean}   [allowCache]   If true, allows a cached value to be
     *                                      returned, otherwise forces a full
     *                                      refresh. We need to force a refresh
     *                                      When a user action triggers reaload.
     * @returns   {object}   Promise with source data
     */
    function _getData(allowCache) {

      // Clear sources before load to set the loading state on the table
      $ctrl.sources = undefined;
      return SqlDashboardService.getProtectionSources(!allowCache).then(
        function onSuccess(sources) {
          sources.forEach(_generateContextMenu);
          $ctrl.sources = sources;
        },
        evalAJAX.errorMessage);
    }

    /**
     * Generates a context menu for a node
     *
     * @method   _generateContextMenu
     * @param    {object}   source   a source node
     */
    function _generateContextMenu(source) {
      source._contextMenu = SourcesUtil.generateContextMenu(
        source, 'kSQL', _getData);
    }
  }

})(angular);
