<a *ngIf="!allClusters" cogDashcard [title]="title" uiSref="jobs" uiParams="{ slaViolation: true }">
  <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
</a>

<cog-dashcard *ngIf="allClusters" [title]="title">
  <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
</cog-dashcard>

<ng-template #cardTemplate>
  <cog-dashcard-layout-standard invert="true"
    *ngIf="complianceData && !isLoading">
    <cog-dashcard-top>
      <cog-statlist *ngIf="!displayProtectionRuns; else protectionStatsHeader"
        [title]="'runSlaStatus' | translate">
        <!--
          stopPropogation prevents clicks from propagating to the uiSref on the card itself.
          This propagation creates losing race conditions when a lazy loaded module hasn't
          been loaded yet.
        -->
        <a cogStatlistItem *ngFor="let stat of complianceData.stats"
          [label]="stat.nameKey | translate"
          uiSref="jobs"
          [uiParams]="{ lastRunSlaStatus: stat.nameKey }"
          [class.disabled]="allClusters"
          (click)="allClusters ? undefined : $event.stopPropagation()">
          {{ stat.value }}
          <small *ngIf="stat.change !== 0"
            [ngClass]="stat.change > 0 ? 'status-critical' : 'status-ok'">
            {{ stat.change | valueChangeTrend }}%
          </small>
        </a>
      </cog-statlist>

      <ng-template #protectionStatsHeader>
        <coh-protection-stats-header></coh-protection-stats-header>
      </ng-template>
    </cog-dashcard-top>

    <cog-dashcard-chart>
      <cog-bar-chart
        [seriesData]="complianceData.series"
        [categories]="complianceData.categories"
        [customChartOptions]="customChartOptions">
      </cog-bar-chart>
    </cog-dashcard-chart>

    <cog-dashcard-legend>
      <cog-statlist [title]="'objects' | translate" valueSize="md" [vertical]="true">
        <a cogStatlistItem [label]="'metSla' | translate"
          labelLegend="highcharts-color-0"
          uiSref="jobs"
          [uiParams]="{ slaViolation: false }"
          [class.disabled]="allClusters">
          {{ complianceData.totalMetSla | number }}
        </a>
        <a cogStatlistItem [label]="'missedSla' | translate"
          labelLegend="highcharts-color-1"
          uiSref="jobs"
          [uiParams]="{ slaViolation: true }"
          [class.disabled]="allClusters">
          {{ complianceData.totalMissedSla | number }}
        </a>
      </cog-statlist>
    </cog-dashcard-legend>
  </cog-dashcard-layout-standard>
  <cog-spinner *ngIf="isLoading"></cog-spinner>
  <coh-blank-card type="view" *ngIf="!complianceData && !isLoading"></coh-blank-card>
</ng-template>
