import { isBoolean } from 'lodash-es';
import { pick } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Cluster Replication Setup

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      cluster: '=',
      inEditMode: '=?',
    },
    controller: 'ClusterReplicationSetupCtrl',
    templateUrl: 'app/remote-clusters/modify/cluster-replication-setup.html',
  };

  angular.module('C.remoteClusters')
    .controller('ClusterReplicationSetupCtrl', clusterReplicationSetupCtrlFn)
    .component('clusterReplicationSetup', configOptions);

  function clusterReplicationSetupCtrlFn(_, FEATURE_FLAGS, FILESIZES) {
    var $ctrl = this;

    assign($ctrl, {
      FEATURE_FLAGS: FEATURE_FLAGS,

      // component methods used in the view
      getSetEncryptionKey: getSetEncryptionKey,
      getSetBandwidthOverride: getSetBandwidthOverride,
      getSetBandwidthRateLimit: getSetBandwidthRateLimit,
      addBlackoutWindow: addBlackoutWindow,
      deleteBlackoutWindow: deleteBlackoutWindow,
      newBlackoutWindow: getDefaultBlackoutWindow(),

      // component life cycle methods
      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      assign($ctrl.cluster, pick($ctrl.cluster._cache, [
        'viewBoxPairInfo',
        'allEndpointsReachable',
        'compressionEnabled',
        'encryptionKey',
        'bandwidthLimit',
        '_rateLimitBytesPerSec',
        '_timezone',
        '_bandwidthLimitOverrides',
      ]));
      $ctrl.cluster._enableEncryption = !!$ctrl.cluster.encryptionKey;

      // Disable Load distribution by default.
      if (!isBoolean($ctrl.cluster.allEndpointsReachable)) {
        $ctrl.cluster.allEndpointsReachable = false;
      }
    }

    /**
     * On destroy cache the current cluster setting to auto fill when user
     * toggle replication setting back.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      assign($ctrl.cluster._cache, {
        viewBoxPairInfo: $ctrl.cluster.viewBoxPairInfo,
        allEndpointsReachable: $ctrl.cluster.allEndpointsReachable,
        compressionEnabled: $ctrl.cluster.compressionEnabled,
        encryptionKey: $ctrl.cluster.encryptionKey,
        bandwidthLimit: $ctrl.cluster.bandwidthLimit,
      });

      if($ctrl.cluster.bandwidthLimit) {
        assign($ctrl.cluster._cache, {
          _rateLimitBytesPerSec:
            $ctrl.cluster.bandwidthLimit.rateLimitBytesPerSec,
          _timezone: $ctrl.cluster.bandwidthLimit.timezone,
          _bandwidthLimitOverrides:
            $ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides,
        });
      }

      assign($ctrl.cluster, {
        viewBoxPairInfo: undefined,
        allEndpointsReachable: undefined,
        compressionEnabled: undefined,
        encryptionKey: undefined,
        bandwidthLimit: undefined,
      });
    }

    /**
     * Returns the fresh blackout window config.
     *
     * @method   getDefaultBlackoutWindow
     * @return   {Object}   The default blackout window.
     */
    function getDefaultBlackoutWindow() {
      var defaultBlackoutWindow = {
        timePeriods: {
          days: undefined,

          // The time input requires a Date object, but the date portion is
          // discarded by the input since it only handles time. Therefore the
          // incorrect date is irrelevant.
          startTime: {
            hour: 9,
            minute: 0,
          },
          endTime: {
            hour: 17,
            minute: 0,
          },
        },
        bytesPerSecond: undefined,
        _rateLimitBytesPerSec: 100 * FILESIZES.megabyte,
        _typeConfig: undefined,
        _typesConfig: [
          {
            type: 'blackout',
            min: 0,
            bytesPerSecond: 0,
          },
          {
            type: 'throttle',
            min: 1,
            bytesPerSecond: 100 * FILESIZES.megabyte,
          },
        ],
      };

      defaultBlackoutWindow._typeConfig = defaultBlackoutWindow._typesConfig[0];
      defaultBlackoutWindow.bytesPerSecond =
        defaultBlackoutWindow._typeConfig.bytesPerSecond;

      return defaultBlackoutWindow;
    }

    /**
     * getter setter for remote cluster encryption key.
     *
     * @method   getSetEncryptionKey
     * @return   {boolean}   true if remote cluster encryption key is present
     *                       else false
     */
    function getSetEncryptionKey() {
      // arguments list would be present if used as setter
      if (arguments.length === 1) {
        if ($ctrl.cluster._enableEncryption) {
          $ctrl.cluster.encryptionKey = undefined;
          $ctrl.cluster._enableEncryption = false;
        } else {
          $ctrl.cluster._enableEncryption = true;
        }
      }

      return $ctrl.cluster._enableEncryption;
    }

    /**
     * getter setter for remote cluster data transfer rate limit.
     *
     * @method   getSetBandwidthRateLimit
     * @return   {boolean}   true if remote cluster data transfer rate limit is
     *                       present else false
     */
    function getSetBandwidthRateLimit() {
      // arguments list would be present if used as setter
      if (arguments.length === 1) {
        if (!$ctrl.cluster.bandwidthLimit) {
          $ctrl.cluster.bandwidthLimit = {};
        }

        if ($ctrl.cluster.bandwidthLimit.hasOwnProperty('rateLimitBytesPerSec')) {
          $ctrl.cluster._cache._rateLimitBytesPerSec =
            $ctrl.cluster.bandwidthLimit.rateLimitBytesPerSec;

          delete $ctrl.cluster.bandwidthLimit.rateLimitBytesPerSec;

          if (Object.keys($ctrl.cluster.bandwidthLimit).length === 0) {
            delete $ctrl.cluster.bandwidthLimit;
          }
        } else {
          $ctrl.cluster.bandwidthLimit.rateLimitBytesPerSec = (
            $ctrl.cluster._cache._rateLimitBytesPerSec ||
            $ctrl.newBlackoutWindow._rateLimitBytesPerSec
          );
        }
      }

      return ($ctrl.cluster.bandwidthLimit &&
        $ctrl.cluster.bandwidthLimit.hasOwnProperty('rateLimitBytesPerSec')
      );
    }

    /**
     * getter setter for remote cluster blackout windows & timezone settings.
     *
     * @method   getSetBandwidthOverride
     * @return   {boolean}   true if remote cluster blackout windows or timezone
     *                       settings is present else false
     */
    function getSetBandwidthOverride() {
      // arguments list would be present if used as setter
      if (arguments.length === 1) {
        if (typeof $ctrl.cluster.bandwidthLimit === 'undefined') {
          $ctrl.cluster.bandwidthLimit = {};
        }

        if ($ctrl.cluster.bandwidthLimit.hasOwnProperty('timezone')) {
          $ctrl.cluster._cache._timezone =
            $ctrl.cluster.bandwidthLimit.timezone;

          delete $ctrl.cluster.bandwidthLimit.timezone;

          if (Object.keys($ctrl.cluster.bandwidthLimit).length === 0) {
            delete $ctrl.cluster.bandwidthLimit;
          }
        } else {
          $ctrl.cluster.bandwidthLimit.timezone =
            $ctrl.cluster._cache._timezone;
        }

        if ($ctrl.cluster.bandwidthLimit.hasOwnProperty('bandwidthLimitOverrides')) {
          $ctrl.cluster._cache._bandwidthLimitOverrides =
            $ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides;

          delete $ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides;

          if (Object.keys($ctrl.cluster.bandwidthLimit).length === 0) {
            delete $ctrl.cluster.bandwidthLimit;
          }
        } else {
          $ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides =
            $ctrl.cluster._cache._bandwidthLimitOverrides || [];
        }
      }

      return $ctrl.cluster.bandwidthLimit && (
        $ctrl.cluster.bandwidthLimit.hasOwnProperty('timezone') ||
        $ctrl.cluster.bandwidthLimit.hasOwnProperty('bandwidthLimitOverrides')
      );
    }

    /**
     * Adds a new blackout window config to list.
     *
     * @method   addBlackoutWindow
     */
    function addBlackoutWindow() {
      // don't add if new blackout window config if it is invalid
      if ($ctrl.newBlackoutWindowForm.$invalid) {
        $ctrl.newBlackoutWindowForm.$setSubmitted();
        return;
      }

      if (!$ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides) {
        $ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides = [];
      }

      // if all day checkbox is selected, modify the times
      if ($ctrl.newBlackoutWindow.timePeriods.isAllDay) {
        $ctrl.newBlackoutWindow.timePeriods.startTime = {
          hour: 0,
          minute: 0,
        };
        $ctrl.newBlackoutWindow.timePeriods.endTime = {
          hour: 23,
          minute: 59,
        };
      }

      $ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides.push(
        angular.copy($ctrl.newBlackoutWindow)
      );

      $ctrl.newBlackoutWindow = getDefaultBlackoutWindow();
      $ctrl.newBlackoutWindowForm.$setPristine();
    }

    /**
     * deletes a blackout window setting from the list.
     *
     * @method   deleteBlackoutWindow
     * @param    {number}   indexToRemove   The index of blackout window config
     *                                      to remove
     */
    function deleteBlackoutWindow(indexToRemove) {
      $ctrl.cluster.bandwidthLimit.bandwidthLimitOverrides.splice(
        indexToRemove,
        1
      );
    }
  }

})(angular);
