<div class="download-agent-wrapper">
  <div mat-dialog-title>
    {{'downloadAgentsModal.downloadAgents' | translate}}
    <button type="button" mat-icon-button class="dialog-close" id="close-agent-dialog" [mat-dialog-close]="false">
      <cog-icon shape="close"></cog-icon>
    </button>
  </div>
  <p>
    {{ descriptionMessage | translate }}
  </p>
  <mat-dialog-content>
    <cog-table
      name="downloadAgentTable"
      id="download-table"
      class="standard-width"
      reflowScrollSize="disabled"
      [useCellPadding]="true"
      [source]="agents">
      <table mat-table aria-label="'downloadAgentTable' | translate" role="presentation">
        <ng-container matColumnDef="agent">
          <th mat-header-cell *matHeaderCellDef>
            {{'agent' | translate}}
          </th>
          <td mat-cell *matCellDef="let row; let index;">
            {{row.hostEnvText || row.hostEnv | translate}}
            <small *ngIf="row.agentType === 'kJava'">
              ({{'javaAgent' | translate}})
            </small>

            <!-- If go agent is being displayed show Recommended after java agent -->
            <small *ngIf="row.agentType === 'kJava' && flagEnabled('downloadGoAIXAgent')" translate>
              recommended
            </small>
            <div class="download-action">
              <mat-select id="agent-type" class="agent-type margin-right-sm"
                *ngIf="row.pkgTypeList"
                [aria-label]="'selectType' | translate"
                [formControl]="package"
                (selectionChange)="row.pkgType=package.value; transformHostAgent(row)">
                <mat-option *ngFor="let pkg of row.pkgTypeList" [value]="pkg">
                  {{pkg | translate}}
                </mat-option>
              </mat-select>
              <mat-select id="agent-solaris-type" class="agent-type margin-right-sm"
                *ngIf="row.solarisPkgTypeList"
                [aria-label]="'selectType' | translate"
                [formControl]="solarisPackage"
                (selectionChange)="row.solarisPkgType=solarisPackage.value; transformHostAgent(row)">
                <mat-option *ngFor="let solarisPkg of row.solarisPkgTypeList" [value]="solarisPkg">
                  {{solarisPkg | translate}}
                </mat-option>
              </mat-select>
              <mat-select cogDataId="esxi-version-select" class="agent-type margin-right-sm"
                *ngIf="row.esxiVersionsList"
                [formControl]="cdpVMWareEsxiVersion"
                (selectionChange)="row.esxiVersion = cdpVMWareEsxiVersion.value">
                <mat-option cogDataId="esxi-version-{{version}}"
                  *ngFor="let version of row.esxiVersionsList" [value]="version">
                  {{version | translate}}
                </mat-option>
              </mat-select>
              <a *ngIf="row.hostEnv !== 'cdpVMwareIOFilter'" id="download-{{row.hostEnv}}-agent"
                target="_blank" href="{{row.url}}" tabindex="0"
                [attr.aria-label]="(row.hostEnvText || row.hostEnv | translate) + (row.pkgType || '' | translate)">
                <cog-icon role="none" aria-hidden="true" shape="download" size="sm">
                </cog-icon>
              </a>
              <a *ngIf="row.hostEnv === 'cdpVMwareIOFilter'"
                id="download-cdpIOFilter-agent"
                (click)="downloadCdpIOFilter(row)">
                <cog-icon role="none" aria-hidden="true" shape="download" size="sm">
                </cog-icon>
              </a>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['agent']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['agent']"></tr>
      </table>
    </cog-table>
  </mat-dialog-content>
</div>
