import { ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
@Component({
  selector: 'coh-excluded-disk-handling',
  templateUrl: './settings-list-excluded-disk-handling.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsExcludedDiskHandlingComponent {
  /**
   * Form control for Recover Excluded Disks toggle.
   * Controls whether the option to recover excluded disks is enabled.
   */
  @Input() recoverExcludedDisks: UntypedFormControl;

  /**
   * Form control for Recover Independent Disks toggle.
   * Controls whether the option to recover independent disks is enabled.
   */
  @Input() recoverIndependentDisks: UntypedFormControl;

  /**
   * Determines if the component should be in read-only mode.
   */
  @Input() readOnly: boolean;

  /**
   * Feature Flag for Recover Excluded Disks Enabled toggle
   */
  recoverExcludedDisksFlag: boolean;

  constructor(
    public irisContext: IrisContextService
  ) {
    this.recoverExcludedDisksFlag = flagEnabled(this.irisContext.irisContext, 'vmwareRecoverExcludedDisksEnabled');
  }
}
