// Component: SNMP Configuration
import { getConfigByKey } from '@cohesity/iris-core';

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.snmp', [])
    .constant('SNMP_CONST', snmpConstants())
    .config(configFn);

  /**
   * This function will take in the necessary priv and then
   * return a function that determines whether the user with that priv
   * and the config override can access the snmp settings page.
   *
   * @param stateAccessContext
   * @returns a function that evaluates access granted or not.
   */
  function canAccess(priv) {
    return (stateAccessContext) => stateAccessContext[priv] &&
      getConfigByKey(stateAccessContext.irisContext, 'settings.features.snmp', true);
  }


  function configFn($stateProvider) {
    $stateProvider
      .state('snmp-view', {
        url: '^/admin/snmp',
        help: 'admin_snmp',
        title: 'SNMP Configuration',
        canAccess: canAccess('CLUSTER_VIEW'),
        component: 'viewSnmp',
      })
      .state('snmp-modify', {
        url: '^/admin/snmp/edit',
        help: 'admin_snmp_edit',
        title: 'Edit SNMP Configuration',
        canAccess: canAccess('CLUSTER_MODIFY'),
        parentState: 'snmp-view',
        component: 'editSnmp',
      });
  }

  function snmpConstants() {
    return {
      authProtocol: [
        'kNone',
        'kAuthMD5',
        'kAuthSHA',
      ],
      privProtocol: [
        'kNone',
        'kPrivDES',
        'kPrivAES',
      ],
      v3SecurityLevel: [
        'kNoAuthNoPriv',
        'kAuthNoPriv',
        'kAuthPriv',
      ],
      operationType: [
        'kOperationEnable',
        'kOperationDisable',
      ],
      version: [
        'kSnmpV2',
        'kSnmpV3',
      ],
      userType: [
        'kReadUser',
        'kReadWriteUser',
        'kTrapUser',
      ],
      userKey: [
        'trapUser',
        'readUser',
        'writeUser',
      ],
      defaultAgentPort: 161,
      defaultTrapPort: 162,
    };
  }
})(angular);
