import { assign } from 'lodash-es';
// Component: Select a search type for o365 recovery.

;(function(angular, undefined) {
  'use strict';

  var componentName = 'searchTypeSelector';
  var configOptions = {
    controller: 'SearchTypeSelectorCtrl',
    templateUrl: 'app/protection/recovery/office365/search/search-type-selector/search-type-selector.html',

    // Attribute bindings
    bindings: {
      // @type  {Array}    searchtypes   List of search types.
      searchTypes: '<?',

      // @type  {function} handleClick   Action to take on click event.
      handleClick: '<',
    },
  };

  angular.module('C.office365Recovery')
    .controller('SearchTypeSelectorCtrl', SearchTypeSelectorCtrlFn)
    .component(componentName, configOptions);

  function SearchTypeSelectorCtrlFn(_,  RecoveryStore, OFFICE365_SEARCH_TYPE) {
    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
      getLabel: getLabel,
      shared: RecoveryStore.get(),
    });

    /**
     * Initialize the default searchTypes in case this is empty.
     *
     * @method     init
     */
    function $onInit() {
      if(!$ctrl.searchTypes || !$ctrl.searchTypes.length) {
        $ctrl.searchTypes =  [
          OFFICE365_SEARCH_TYPE.kMailboxSearch,
          OFFICE365_SEARCH_TYPE.kEmailSearch,
        ];
      }
    }

    /**
     * Function to get the label for search type.
     *
     * @method   getLabel
     * @param    {string}   searchType   searchType.
     * @return   {string}   search type string.
     */
    function getLabel(searchType) {
      return 'office365Restore.searchType.' + searchType;
    }
  }
})(angular);
