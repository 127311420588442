import { get } from 'lodash-es';
// Hypervisor VM Service

; (function (angular) {
  'use strict';

  angular
    .module('C.devOpsService', [])
    .service('HypervisorVmService', hypervisorVmServiceFn);

  function hypervisorVmServiceFn(_, $http, API, $httpParamSerializer, $window,
    CHART_COLORS, JobRunsService, $translate, ENV_TYPE_CONVERSION, $sce,
    NETWORK) {

    var HypervisorSvc = {
      createHypervisorVm: createHypervisorVm,
      deleteVm: deleteVm,
      deleteVmConfig: deleteVmConfig,
      getAvailableVMComponents: getAvailableVMComponents,
      getCurrentTask: getCurrentTask,
      getHypervisorVMs: getHypervisorVMs,
      getSourceInformation: getSourceInformation,
      getUsageConfig: getUsageConfig,
      getVmStatus: getVmStatus,
      launchVncConsole: launchVncConsole,
      updateVmStatus: updateVmStatus
    };

    /**
     * Stores the promise for list of VMs hosted on cohesity cluster.
     *
     * @method   getHypervisorVMs
     * @param    {object}    params  params for the API call
     * @return   {object}    returns the promise to resolve with VMs list else
     *                       rejected with the error.
     */
    function getHypervisorVMs(params) {
      return $http({
        method: 'GET',
        url: API.private('nexus/testdev/vmlist'),
        params: params }).then(
        function gotVMsList(response) {
          return (response.data || []).map(transformHypervisorVm);
      });
    }

    /**
     * Generates the usage config for hypervisor hosted VMs.
     *
     * @method   getUsageConfig
     * @param    {object}    hypervisorVms  list of hypervisor vms
     * @return   {object}    returns the usage config of hypervisor hosted VMs
     */
    function getUsageConfig(hypervisorVms) {
      var usageCount = {
        total: 0,
        poweredOn: 0,
        poweredOff: 0,
        usedStorage: 0,
        vcpuAllocated: 0,
        memoryAllocated: 0
      };

      usageCount.total = hypervisorVms.length;
      hypervisorVms.forEach(function generateUsageCount(vm) {
        if (vm.state === 'shut-off') {
          usageCount.poweredOff++;
        } else {
          usageCount.poweredOn++;
        }
        usageCount.vcpuAllocated += vm.cores;
        usageCount.memoryAllocated += vm.memory;
        usageCount.usedStorage += vm.storage;
      });
      return [
        {
          value: usageCount.total,
          label: 'totalVms'
        },
        {
          value: usageCount.poweredOn,
          label: 'poweredOnVms',
          classes: 'status-ok'
        },
        {
          value: usageCount.poweredOff,
          label: 'poweredOffVms',
          classes: 'status-critical'
        },
        {
          value: usageCount.usedStorage,
          filterName: 'byteSize',
          label: 'usedStorage'
        },
        {
          value: usageCount.vcpuAllocated,
          label: 'vcpusAllocated'
        },
        {
          value: usageCount.memoryAllocated,
          filterName: 'byteSize',
          label: 'memoryAllocated'
        },
      ];
    }

    /**
     * Perform the specified operation on VM like power on, off, suspend, pause.
     *
     * @method   updateVmStatus
     * @param    {object}    params  params for the API call
     * @return   {object}    returns the promise to resolve/reject with the
     *                       API's outcome.
     */
    function updateVmStatus(params) {
      return $http({
        method: 'post',
        url: API.private('nexus/testdev/vmlcm'),
        data: params
        });
    }

    /**
     * Calls the API to get the status of specified VM.
     *
     * @method   getVmStatus
     * @param    {object}    vm  information about the VM
     * @return   {object}    returns the promise to resolve/reject with the
     *                       API's outcome.
     */
    function getVmStatus(vm) {
      var params = {vmName: vm.name};

      return $http({
        method: 'GET',
        url: API.private('nexus/testdev/vmstatus'),
        params: params }).then(
        function getVmStatusSuccess(response) {
          var status = response.data;

          if (get(status, 'cpuUsage') === 0) {
            status.cpuUsage = 1;
          }
          return status;
        });
    }

    /**
     * Launches the VNC console for the specified vm.
     *
     * @method   launchVncConsole
     * @param    {object}    vm  information about the VM
     * @return   {Void}
     */
    function launchVncConsole(vm) {
      // Params for noVNC client
      var vncParams = {
        host: vm.hostIP,
        port: vm.websocketPort,
        autoconnect: true,
        resize: 'scale',
        show_dot: true,
      };

      var url = 'https://' + vm.hostIP + ':' + vm.websocketPort + '/vnc.html?' +
        $httpParamSerializer(vncParams);
      return $sce.trustAsResourceUrl(url);
    }

    /**
     * Perform the delete operation on VM.
     *
     * @method   deleteVm
     * @param    {object}    data  Data for VM to delete
     * @return   {object}    returns the promise to resolve/reject with the
     *                       API's outcome.
     */
    function deleteVm(data) {
      return $http({
        method: 'DELETE',
        url: API.private('nexus/testdev/vmdel'),
        data: data
      });
    }

    /**
     * Adds the color information to VM.
     *
     * @method   transformHypervisorVm
     * @param    {object}    hypervisorVm  information about the VM
     * @return   {Void}
     */
    function transformHypervisorVm(hypervisorVm) {
      // TODO: States should be kConstants
      // JIRA Ticket: https://cohesity.atlassian.net/browse/ENG-49342
      if (hypervisorVm.state === 'shut-off') {
        hypervisorVm.color = CHART_COLORS.red;
      } else if (hypervisorVm.state === 'paused') {
        hypervisorVm.color = CHART_COLORS.gold;
      } else {
        hypervisorVm.color = CHART_COLORS.green;
      }
      hypervisorVm.operationCompleted = true;
      Object.keys(hypervisorVm).forEach(function setDefaultValues(key) {
        hypervisorVm[key] = hypervisorVm[key] || null;
      });
      return hypervisorVm;
    }

    /**
     * Creates a new hypervisor vm to host on cohesity cluster
     *
     * @method    createHypervisorVm
     * @param     {Object}    params         params for the API call
     * @returns   {Object}    returns the promise to resolve with hosted vm
     *                        details else rejected with the error.
     */
    function createHypervisorVm(params) {
      var checkParams = {
        memory: params.memory,
        iface: params.interface,
      };
      return checkAvailableHost(checkParams).then(
        function checkAvailableHostSuccess(response) {
          return getViewName(params.jobInstanceId, params.jobId).then(
            function getViewNameSuccess(resp) {
              var postData = {};

              params.jobId = resp.jobId;
              postData = {
                diskPath: getDiskPath(params, resp.viewName),
                doLaunch: params.doLaunch,
                hypervisorType: params.type,
                interface: params.interface,
                memory: params.memory,
                name: params.vmName,
                vcpu: params.vcpus,
                viewBoxId: resp.viewBoxId,
              };

              return $http({
                method: 'POST',
                url: API.private('nexus/testdev/vmcreate'),
                data: postData,
              });
          });
        });
    }

    /**
     * Checks if any host available to clone the VM on cohesity cluster with
     * required specifcations
     *
     * @method   checkAvailableHost
     * @param    {object}    params    params for the API call
     * @return   {object}    returns   the promise to resolve/reject with the
     *                                 API's outcome.
     */
    function checkAvailableHost(params) {
      return $http({
        method: 'GET',
        url: API.private('nexus/testdev/vmsched'),
        params: params,
      });
    }

    /**
     * Gets the available vm components like memory and vcpus
     *
     * @method   getAvailableVMComponents
     * @param    {object}    params    params specifying the information of vm
     *                                 which is being cloned
     * @return   {object}    returns the promise to resolve/reject with the
     *                                 API's outcome.
     */
    function getAvailableVMComponents(params) {
      return getViewName(params.jobInstanceId, params.jobId).then(
        function getViewNameSuccess(resp) {
          var diskParams = {};

          params.jobId = resp.jobId;
          diskParams = {
            hypervisorType: params.type,
            viewName: getDiskPath(params, resp.viewName),
          };

          return $http({
            method: 'GET',
            url: API.private('nexus/testdev/vmxparse'),
            params: diskParams,
          }).then(function getAvailableVMComponentsSuccess(resp) {
            return resp.data || {};
          });
        });
    }

    /**
     * Returns the internal view name for specific job
     *
     * @method   getViewName
     * @param    {number}   jobInstanceId    Id for which view name need to be
     *                                       retrieved
     * @param    {number}   jobId            Id of the job wrt to VM which is
     *                                       being cloned
     * @return   {object}   returns the promise to resolve/reject with the
     *                               API's outcome.
    */
    function getViewName(jobInstanceId, jobId) {
      var params = {
        excludeTasks: true,
        id: jobId,
      };

      return JobRunsService.getJobRuns(params).then(
        function getBackupJobsSuccess(resp) {
          var backupRun;

          if (resp.length) {
            resp[0].backupJobRuns.protectionRuns.forEach(
              function filterBackupRun(protectionRun) {
                if(protectionRun.backupRun.base.jobInstanceId === jobInstanceId)
                {
                  backupRun = protectionRun.backupRun.base;
                }
              });
          }
          return backupRun;
      });
    }

    /**
     * Generates the source information for source list
     *
     * @method    getSourceInformation
     * @param     {Object}    vms         List of the hypervisor VMs
     * @returns   {Object}    returns the accumulated information of hypervispr
     *                        source
     */
    function getSourceInformation(vms) {
      var sourceInfo = {
        rootNode: {
          name: $translate.instant('cohesityCluster'),
          environment: '_kKuiper',
        },
        stats: {
          protectedSize: 0,
          protectedCount: vms.length,
        },
        protectionSource: {
          environment: '_kKuiper',
          id: ENV_TYPE_CONVERSION._kKuiper,
          name: $translate.instant('cohesityCluster'),
          cohesityClusterProtectionSource: {
            type: '_kKuiper',
          }
        }
      };

      vms.forEach(function generateSourceInfo(vm) {
        sourceInfo.stats.protectedSize += vm.storage;
      });
      return sourceInfo;
    }

    /**
     * Gets the details for a particular task
     *
     * @method    getCurrentTask
     * @param     {object}    task        Provides the details of current task.
     * @param     {object}    components  Provides the information about the
     *                                    components like vcpus and memory.
     * @return    {object}    returns the updated task details.
     */
    function getCurrentTask(task, components) {
      return {
        interface: task.interface || NETWORK.defaultInterface,
        memory: components.memSize,
        name:  task.name || null,
        powerOffVM: task.powerOffVM || false,
        vcpus: components.vcpus,
      };
    }

    /**
     * Deletes the VM config if VM creation is failed
     *
     * @method    deleteVmConfig
     * @param     {string}    uuid    Uuid of the VM for which config needs to
     *                                be deleted
     * @return    {object}    returns the promise to resolve/reject with the
     *                        API's outcome.
     */
    function deleteVmConfig(uuid) {
      return $http({
        method: 'POST',
        url: API.private('nexus/testdev/vm_config_cleanup'),
        data: {uuid: uuid},
      });
    }

    /**
     * Generates the disk path for the VM
     *
     * @method   getDiskPath
     * @param    {object}    params     params specifying of the vm snapshot
     *                                  which is being cloned
     * @param    {string}    viewName   name of internal view on which vm is
     *                                  hosted
     * @return   {string}    returns the diskpath for the VM
     */
    function getDiskPath(params, viewName) {
      return viewName + '/' + params.uuid + '/' + params.jobId + '-' +
        params.jobInstanceId + '-' + params.attemptNum;
    }

    return HypervisorSvc;
  }

})(angular);