import { assign } from 'lodash-es';
// COMPONENT: cSearch

(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.search';
  var moduleDeps = ['smart-table', 'ngSanitize', 'C.filters'];
  var module;
  var searchTypes;

  try {
    module = angular
      .module(moduleName);
  } catch (e) {
    module = angular
      .module(moduleName, moduleDeps);
  }
  module
    .filter('interactableFilters', interactableFiltersFn)
    .directive('cSearch', DirectiveSearch);

  /**
   * @ngdoc directive
   * @name C.search.directive:cSearch
   * @description
   *   This directive handles the display and basic features of the search
   *   results and adding filters. It's also the primary interface to the parent
   *   controller. All configuration *must* be done on this directive.
   *
   * @restrict 'AE'
   * @element ANY
   * @priority 100
   * @scope
   * @example
     <example module="C.search" animation="false">
       <div c-search search-id="unique_identifier" endpoint="/path/to/search/api"
        filter="availableFilters" collection="unpaginatedCollection"
        search-type="vm"></div>
       <ul>
        <li class="c-search-results" search-id="unique_identifier"
          ng-repeat="row in pagedCollection track by $index">
            <label>
              <input type="checkbox" ng-model="row._selected">
              {{row.someProperty}}
            </label>
        </li>
       </ul>
     </example>
   */
  function DirectiveSearch(cSearchService, cFocus, SearchService) {
    searchTypes = cSearchService.getSearchTypes();
    var dirSearch = {
      restrict: 'AE',
      link: linkFn,
      controller: controller,
      scope: {

        // Optional: Fn to preprocess response from server
        // @type       {function=}
        preProcessResultsFn: '=?',

        // Optional: Wheather to exclude jobs or not while cloning or recovering
        // the VMs.
        // @type     {bool}
        excludeJobs: '<?',

        // Required: The full collection, optionally preProcessed
        // @type       {array}
        collection: '=',

        // Required: Unique identifier of this cSearch instance
        // @type       {string}
        searchId: '=',

        // Optional: Collection of available filters. Converted to url
        // params
        // @type       {array=}
        filterProps: '=?filters',

        // Optional: Endpoint URL to query
        // @type       {string}
        endpoint: '@?',

        // Optional: Hash of filter lookup options. Each hash key _must_
        // match a filterProps[n].property
        // @type       {object}
        // NOTE: Some clone/recover implementations are providing this option
        // and others are leveraging the template fallbacks to $parent.$parent.*
        filterOptions: '=?',

        // Optional: Hide the search input so that the component can be
        // be controlled externally.
        // @type       {bool<}
        hideInput: '<?',

        // Optional: Determines if this instance can add multiple items to
        // the cart at once. Default: false
        // @type       {bool=}
        multiSelect: '=?',

        // Optional: One of ['vm', 'file', 'sql', 'oracle', 'mountPoint']
        // @type       {string=}
        searchType: '@?',

        // Optional: Provided a pre-defined search query.
        // @type       {string=}
        searchQuery: '<?',

        // Optional: Boolean values specifying whether the search is to be
        // executed on Private or Public IRIS APIs.
        // Default: false(Execution will occue on private APIs)
        // @type       {bool=}
        searchPublicApi: '=?',

        // Optional: Use to toggle whether the showAddFilters are showing. This
        //  is used along with hide inputs so that the filter button can be
        // controlled by a separate component.
        showAddFilters: '=?',

        // Optional: This is updated by the component whenever data is being
        // loaded. This is used along with hide inputs so that a loading
        // spinner can be shown by a separate component.
        dataLoading: '=?',

        // Optional: This property exposes methods to the parent component.
        // @type       {object=}
        api: '=?',
      },
      template: require('raw-loader!./c-search.html').default,
    };
    var defaultAttrs = {
      multiSelect: false,
      enableFilters: true,
      collection: [],
    };
    var config = {};
    var searchId;

    /** @ngInject */
    function controller(_, $scope, $timeout, DateTimeService, evalAJAX,
      ENUM_ENTITY_TYPE) {

      // Watcher reference for termination
      var filtersInit;
      var activeFiltersWatcher;
      var filtersWatcher;
      var prevQuery;
      var prevType;

      assign($scope, {
        ENUM_ENTITY_TYPE: ENUM_ENTITY_TYPE,
        dateFormat: DateTimeService.getPreferredDateFormat(),
        displayThreshold: 100,
        activeFilters: [],
        localFilters: {
          entityTypes: []
        },
        primaryParam: 'vmName',
        searchDisplay: [],
        searchData: [],

        // The selection{} object represents the currently selected (but
        // unapplied) filter options.
        selection: {
          // To be bound to the selected filterProp.property value. For example:
          // 'parentSourceId'
          // @type   {string}
          // property: undefined,

          // To be bound to the input specific to the selected
          // filterProp.property
          // @type   {array|string|date}
          // value: undefined,
        },

        // $SCOPE METHODS
        $onChanges: $onChanges,
        addFilter: addFilter,
        changeLocalFilters: changeLocalFilters,
        clearFilters: clearFilters,
        containsFilter: cSearchService.containsFilter,
        displayLabel: cSearchService.displayLabel,
        fetchData: fetchData,
        getSearchLabelKey: getSearchLabelKey,
        hasLockedFilters: hasLockedFilters,
        localReset: localReset,
        processForDisplay: cSearchService.processForDisplay,
        removeFilter: removeFilter,
        reQuery: reQuery,
        toggleCheckAll: toggleCheckAll,

        // Expose directive's method.
        api: {
          resetSearch: function resetSearch() {
            localReset();
          }
        },
      });

      cFocus('input-search-query-text');

      /**
       * Use the $onChanges lifecycle hook to update the search
       * whenever the searchQuery changes.
       *
       * @method   $onChanges
       * @param    {object}    changes   A map of change objects.
       */
      function $onChanges(changes) {
        if (changes.searchQuery) {
          reQuery($scope.searchQuery);
        }
      }

      /**
       * No filtering is applied in this component, but the UI for adding
       * them is, so this adds them to the service for use by other
       * components in the suite.
       *
       * @method     addFilter
       */
      function addFilter() {
        // find the currently selected filter in the list, making an immediate
        // copy of it so as not to create a reference.
        var filter = angular.copy($scope.filterProps.find(
          function findFilterFn(filter) {
            return filter.property === $scope.selection.property;
          }
        ));

        // and copy the selected value into the filter;
        filter.value = $scope.selection.value;

        cSearchService.addFilter($scope.searchId, filter);

        // clear the uiSelect selections
        $scope.selection = {};
      }

      /**
       * Toggles the _selected state on all search results.
       * Currently disabled.
       *
       * @method     toggleCheckAll
       * @param      {Bool}  checked  Value to set on _selected
       */
      function toggleCheckAll(checked) {
        $scope.collection.forEach(function eachResult(row) {
          row._selected = checked;
        });
      }

      /**
       * Reusable filter reset
       *
       * @method     resetFilter
       * @param      {Object}  filter  Standard filter object
       * @return     {Object}  The reset filter
       */
      function resetFilter(filter) {
        filter.locked = false;
        return filter;
      }

      // WATCHERS

      // Watch service for changes to main collection and surface it to
      // the parent
      $scope.$watchCollection(
        function getServiceCollection() {
          var collection = cSearchService
            .getInstanceConfig($scope.searchId).collection || [];
          return collection;
        },
        function watchServiceCollection(nv) {
          $scope.collection = nv;
        }
      );

      // Watch service for changes to selectedRows and surface it to the
      // parent
      $scope.$watch(
        function getServiceSelected() {
          var selected = cSearchService
            .getInstanceConfig($scope.searchId).selectedRows || [];
          return selected;
        },
        function watchServiceSelected(nv, ov) {
          $timeout(function delayer1() {
            $scope.selectedCollection = nv;
          });
        },
      true);

      // Watch the activeFilters list on the service
      $scope.$watchCollection(
        function getServiceActiveFilters() {
          return cSearchService
            .getInstanceConfig($scope.searchId).activeFilters || [];
        },
        function watchServiceActiveFilters(nv, ov) {

          // There was a change in the activeFilters
          $scope.activeFilters = nv || [];

          ($scope.filterProps || []).forEach(function eachFilter(filter, ii) {
            var hasChanged = nv.some(function filterFinderFn(_filter) {
              // Is this filter in the list of activeFilters?
              return (_filter.property === filter.property);
            });
            if (!hasChanged) {
              // No match, reset it because it's no longer active.
              resetFilter(filter);
            }
          });
        }
      );

      // Watch the injected active filters and update the service
      // accordingly. Self-terminates after filterProps are set initially.
      filtersInit = $scope.$watch('filterProps', function filterWatcher(nv) {
        if (nv && nv.length) {
          config.filters = nv;
          cSearchService.updateInstanceConfig($scope.searchId, config);
          filtersInit();
        }
      });

      // Watch the filterOptions object for changes and post them to the
      // service for anything that's listening for it
      $scope.$watch('filterOptions', function filterOptionWatcherFn(nv, ov) {
        if (angular.isObject(nv) && Object.keys(nv).length) {
          config.filterOptions = nv;
          cSearchService.updateInstanceConfig($scope.searchId, config);
        }
      });

      $scope.$watch('searchType', function searchTypeWatcher(nv, ov) {
        if (nv) {
          config.searchType = (searchTypes.includes(nv)) ? nv : ov;
          cSearchService.updateInstanceConfig($scope.searchId, config);
        }
      });

      // Watch the Service raw _collection and update in the scope
      // when it changes.
      $scope.$watchCollection(
        function getRawCollectionFn() {
          return cSearchService.getInstanceConfig($scope.searchId)._collection;
        },
        function rawCollectionWatcherFn(nv, ov) {
          $scope._collection = nv;
        }
      );

      /**
       * Helper function to determine if there are any locked filters
       * within the activeFilters list.
       *
       * @method     hasLockedFilters
       * @return     {Bool|Integer}  False if none, the count of locked
       *                             filters if found
       */
      function hasLockedFilters() {
        if (!$scope.activeFilters) {
          return false;
        }
        return $scope.activeFilters.reduce(
          function lockCounterFn(count, filter) {
            if (filter.locked) {
              // Change the output to an integer and increment it by one.
              count = (count || 0) + 1;
            }
            return count;
          },
          // The default, no-locked filters output is false
          false);
      }

      /**
       * Returns the appropriate label string for the search based on the
       * type of flow (file, vm, etc)
       *
       * @method     getSearchLabelKey
       * @return     {String}  The string to display as the label
       */
      function getSearchLabelKey() {
        var searchTypeKeys =
          ['file', 'mountPoint', 'pure', 'sql', 'oracle', 'view', 'vm',
           'storageVolume', 'office365', 'onedrives', 'sharePointSites',
           'vmdk', 'activeDirectory', 'adObjects', 'kubernetes'];

        if ($scope.searchId === 'fileSearch' &&
          $scope.searchType === 'mountPoint') {
          return 'cSearch.searchLabel.fileSearch';
        }
        if (searchTypeKeys.includes($scope.searchType)) {
          return 'cSearch.searchLabel.' + $scope.searchType;
        }

        return 'cSearch.searchLabel.vm';
      }

      /**
       * Wrapper function that calls Global SearchService.getSearchUrl to
       * be used in conjunction with $scope.searchType
       *
       * @method     getEndpoint
       * @param      {String}  type    value corresponds to
       *                               $scope.searchTypeOpts
       * @return     {String}  endpoint for API consumption
       */
      function getEndpoint(type) {
        if (!type || !$scope.searchType) {
          return;
        }
        if ($scope.searchPublicApi) {
          return SearchService.getPublicSearchURL(type);
        }
        return config.endpoint || SearchService.getSearchUrl(type);
      }

      /**
       * Parses the filters & fetches the search results (via service)
       *
       * @method     fetchData
       */
      function fetchData() {
        var query = {};
        var httpOpts = {
          params: query
        };
        var endpoint = getEndpoint($scope.searchType);

        // If there is a search running, cancel it and start a new one.
        if ($scope.pendingSearch) {
          $scope.pendingSearch.abort();
        }

        // Empty search. Exit early. Leave any existing results in place.
        if (!$scope.searchQuery) {
          config._collection = [];
          cSearchService.updateCollection($scope.searchId, config._collection);
          return;
        }

        if ($scope.searchType === 'adObjects') {
          // AdObjects uses the public api, but has a different search query
          // name from other public apis.
          $scope.primaryParam = 'name';
        } else if ($scope.searchPublicApi) {
          // Public API require parameter name as 'search'.
          // This is in sync with ObjectSearchParameters.Search defined in
          // /iris/server/data/public/restore_data.go
          $scope.primaryParam = 'search';
          if (['onedrives', 'sharePointDocs'].includes($scope.searchType)) {
            $scope.primaryParam = 'documentName';
          }
        } else {
          // TODO: Add sniffing configured filters for primary: true
          // Switch Params based on $scope.searchType
          switch ($scope.searchType) {
            case 'file':
              $scope.primaryParam = 'filename';
              break;
            default:
              // Anything else
              $scope.primaryParam = 'vmName';
          }
        }

        $scope.dataLoading = true;

        if ($scope.searchQuery) {
          query[$scope.primaryParam] = $scope.searchQuery;
        }

        // Applying an artifical delay so that the spinner is guaranteed visible
        // for a minimal amount. This is particularly useful for addToCart
        // functionality so the user has a moment to process why the objects
        // listed (likely) changed.
        $timeout(function artificalFetchDelay() {
          $scope.pendingSearch = cSearchService
            .getResults($scope.searchId, httpOpts, endpoint)
            .catch(evalAJAX.errorMessage)
            .finally(function requestComplete(resp) {
              $scope.dataLoading = false;
              $scope.pendingSearch = undefined;
            }
          );
          }, 1000);
      }

      /**
       * On-demand application of changed localFilters.
       *
       * @method     changeLocalFilters
       * @param      {object}  filters  Hash of local filters to apply
       */
      function changeLocalFilters(filters) {
        if (filters) {
          angular.extend(config.localFilters, filters);
          angular.extend(
            config,
            cSearchService.updateLocalFilters(
              $scope.searchId, config.localFilters)
          );
        }
      }

      /**
       * Removes filters from the active filters
       *
       * @method     removeFilter
       * @param      {Object}  filter  Filter object to remove
       */
      function removeFilter(filter) {
        cSearchService.removeFilter($scope.searchId, filter);
      }


      /**
       * This clears filters
       *
       * @method     clearFilters
       * @param      {Bool}  force   True to force removal of locked
       *                             filters. False to only clear user-filters
       */
      function clearFilters(force) {
        cSearchService.resetFilters($scope.searchId, force);
      }

      /**
       * On-demand reQuery of the endpoint
       *
       * @method     reQuery
       * @param      {String}  query   The Query string
       */
      function reQuery(query) {
        // Only fetch if the query is new.
        if (query !== prevQuery || prevType !== $scope.searchType) {
          fetchData();

          // Track the previous searchQuery and $scope.searchType values
          prevQuery = query;
          prevType = $scope.searchType;
        }
      }

      /**
       * Resets some local vars related to the search params.
       *
       * @method   localReset
       */
      function localReset() {
        $scope.searchQuery = undefined;
        prevQuery = undefined;
        prevType = undefined;
      }

      // WATCHERS
      // Watch the filters for changes and issue a new search
      $scope.$watch('activeFilters', function queryFiltersWatcher(nv, ov) {
        // If the overall filters have changed...
        if (!cSearchService.areFiltersSame(nv, ov)) {
          // Requery the server because the user has entered new filters.
          fetchData();
        }
      });

      // Watch the service for changes to the activeFilters
      activeFiltersWatcher = $scope.$watch(
        function activeFilterGetter() {
          return cSearchService
            .getInstanceConfig($scope.searchId).activeFilters || [];
        },
        function serviceActiveFilterWatcher(activeFilters) {
          var arrayMethod;
          // Pre-filter and sort the active filters. Exclude filters that
          // (display == false) property, and sort (locked == true)
          // filters to the top.
          $scope.activeFilters = activeFilters.reduce(
            function activeFiltersFilterSorterFn(active, filter) {
              // Don't add filters with falsey display property
              if (filter.display) {
                // Insert this filter at the top or bottom of the list? Dirty
                // sorting by `locked` property
                arrayMethod = filter.locked ? 'unshift' : 'push';
                active[arrayMethod](filter);
              }
              return active;
            }, []
          );
        },
        true
      );

      // Watch the service for changes to the list of available filters
      filtersWatcher = $scope.$watch(
        function filterGetter() {
          return cSearchService
            .getInstanceConfig($scope.searchId).filters || [];
        },
        function serviceFiltersWatcher(filters) {
          filters.forEach(function propsParserFn(filter) {
            if (filter.primary) {
              $scope.primaryParam = filter.property;
            }
            if (filter.locked) {
              cSearchService.addFilter($scope.searchId, filter);
            }
            return filter;
          });
          $scope.filterProps = filters;
        },
        true
      );

      // Watch the service for changes to the filter lookup options
      $scope.$watch(function filterOptionsGetter() {
        return cSearchService.getInstanceConfig($scope.searchId).filterOptions;
      }, function serviceFiltersWatcher(nv) {
        $scope.filterOptions = nv;
      });

      // Watch the service for changes to the searchType
      $scope.$watch(function searchTypeGetter() {
        return cSearchService.getInstanceConfig($scope.searchId).searchType;
      }, function searchTypeWatcher(newVal, oldVal) {
        if (newVal !== oldVal) {
          $scope.searchType = newVal;
          localReset();
        }
      });
    }

    /**
    * Angular Directive Link Fn
    *
    * @method     linkFn
    * @param      {Object}  scope   Directive scope
    * @param      {Array}   elem    Angularized element reference of the
    *                               directive
    * @param      {Object}  attrs   Hash of ngNormalized attributes and
    *                               their $parsed values
    * @return     {Void}
    */
    function linkFn(scope, elem, attrs) {
      searchId = scope.searchId || attrs.searchId;
      config = cSearchService.registerInstance(searchId);
      config.endpoint = scope.endpoint;

      // Config object for this cSearch instance
      config = angular.extend({}, defaultAttrs, config, {
        // Detect a supported searchType, or default to the first
        searchType: (searchTypes.includes(scope.searchType)) ?
          scope.searchType : searchTypes[0],
        multiSelect: scope.multiSelect,
        filterOptions: scope.filterOptions || {},
        preProcessor: scope.preProcessResultsFn,
        excludeJobs: scope.excludeJobs,
      });

      // Send config back to the service with any updates
      config = cSearchService.updateInstanceConfig(searchId, config);

      // This component has been upgraded for use in angular, however attrs are
      // not supported for injection by angular, which will instead pass a
      // value of NOT_SUPPORTED. For now, this is fine, as long as the angular
      // component doesn't need to change the endpoint value after the component
      // has been initialized.
      if (attrs !== 'NOT_SUPPORTED') {
        // When the endpoint changes, let the service know.
        attrs.$observe('endpoint', function endpointUpdated(endpoint) {
          config.endpoint = endpoint;
          config = cSearchService.updateInstanceConfig(searchId, config);
        });
      }
    }

    return dirSearch;
  }

  /**
   * @ngdoc filter
   * @name  C.search.interactableFilters
   *
   * @returns   {Array}   List of filters to reduce
   */
  function interactableFiltersFn() {
    return function interactableFilters(filters) {
      return (filters || []).filter(function filterToVisibleFiltersFn(filter) {
        // Only include filters that aren't primary and have a display value.
        return !filter.primary && angular.isDefined(filter.display);
      });
    };
  }

})(angular);
