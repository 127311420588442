import { assign } from 'lodash-es';
// Component: Deploy Cloud Edition to AWS

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('deployAwsCloudEdition', {
      bindings: {
        /**
         * @type  {String}  The type of cloud (aws/aws-us-gov etc)
         */
        cloudType: '<'
      },
      templateUrl: 'app/platform/cloud-edition-manager/aws/deploy-aws.html',
      controller: 'deployAWSCtrl',
    })
    .controller('deployAWSCtrl', deployAWSCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:deployAwsCloudEdition
   * @function
   *
   * @description
   * Deploy a new Cohesity DataPlatform for cloud on AWS
   */
  function deployAWSCtrlFn(_, $rootScope, $state, $q, evalAJAX,
    SlideModalService, CloudEditionManagerService, FORMATS) {

    var $ctrl = this;
    var newAWSDeployOptions = {
      accessCredentials: {
        accessKey: '',
        secretKey: '',
      },
      deploymentConfig: {
        clusterName: '',
        dnsServer: ['8.8.8.8'],
        domainName: ['eng.cohesity.com'],
        enableClusterEncryption: true,
        instanceType: '',
        numInstances: 3,
        ntpServer: ['time.nist.gov', 'pool.ntp.org'],
        region: '',
        regionName: '',
        subnetId: '',
        subnetName: '',
        securityGroupId: [],
        securityGroupName: [],
        vpcId: '',
        vpcName: '',
        zone: '',
      },
      jobName: '',
      email: [$rootScope.user.username],
   };

    assign($ctrl, {
      // properties
      currentStep: 1,
      deployTarget: {},
      FORMATS: FORMATS,
      regionsAvailable: !!$ctrl.regions,
      steps: [{
        titleKey: 'awsCredentials',
      }, {
        titleKey: 'cloudEdition.cloudEnvironmentDetails',
      }, {
        titleKey: 'cloudEdition.dataPlatformForCloudDetails',
      }],
      submitButtonText: 'verify',
      tags: [],

      // methods
      $onInit: onInit,
      back: back,
      cancel: cancel,
      processForm: processForm,
      selectAWSVpc: selectAWSVpc,
      selectAWSZone: selectAWSZone,
      showPolicyModal: CloudEditionManagerService.showPolicyModal,
    });

    /**
     * Activate the controller
     *
     * @method   onInit
     */
    function onInit() {
      var promises = {};
      var queryParams = {};

      newAWSDeployOptions.cloudType = $ctrl.cloudType;

      if ($ctrl.cloudType === 'aws-us-gov') {
        queryParams.cloudtype = $ctrl.cloudType;
      }

      promises.regions = CloudEditionManagerService.getAWSRegions(queryParams);
      promises.nodeTypes = CloudEditionManagerService.getCENodeTypes('aws');

      $q.all(promises)
        .then(function cePromisesReslved(resp) {
          $ctrl.regions = resp.regions;
          $ctrl.nodeTypes = resp.nodeTypes;
        }, evalAJAX.errorMessage)
        .finally(function cePromisesFinally() {
          $ctrl.regionsAvailable = true;
        });

      assign($ctrl.deployTarget, newAWSDeployOptions);

      CloudEditionManagerService.showChecklistModal('aws');
    }

    /**
     * Callback after VPC selection to shortlist corressponding zones and
     * security groups
     *
     * @method  selectAWSVpc
     * @param   {Object}  vpc  The selected VPC Object
     */
    function selectAWSVpc(vpc) {
      var deploymentConfig = $ctrl.deployTarget.deploymentConfig;

      $ctrl.zones = Object.keys(vpc.subnets);
      $ctrl._subnets = vpc.subnets;
      $ctrl.securityGroups = vpc.securityGroups;
      deploymentConfig.vpcName = vpc.tag;

      // reset values if previously selected
      deploymentConfig.zone =
      deploymentConfig.subnetId =
      deploymentConfig.subnetName = undefined;
      deploymentConfig.securityGroupId.length =
      deploymentConfig.securityGroupName.length = 0;
    }

    /**
     * Callback after Zone selection to shortlist corressponding subnet
     *
     * @method  selectAWSZone
     * @param   {String}  zone  The selected Zone
     */
    function selectAWSZone(zone) {
      var deploymentConfig = $ctrl.deployTarget.deploymentConfig;

      $ctrl.subnets = $ctrl._subnets[zone];

      // reset values if previously selected
      deploymentConfig.subnetId =
      deploymentConfig.subnetName = undefined;
      deploymentConfig.securityGroupId.length =
      deploymentConfig.securityGroupName.length = 0;
    }

    /**
     * Submit the form to the server
     *
     * @method  processForm
     * @param   {object}  form   The form object to process
     */
    function processForm(form) {
      if (!form.$valid) {
        return false;
      }

      var regionsData = {};
      var queryParams = {};

      switch ($ctrl.currentStep) {

        // Step 1 (verification)
        case 1:
          if (!$ctrl.verified) {
            $ctrl.submitting = true;

            regionsData.accessCredentials =
              $ctrl.deployTarget.accessCredentials;
            regionsData.cloudType = $ctrl.deployTarget.cloudType;
            regionsData.region = $ctrl.deployTarget.deploymentConfig.region;

            // make service call to verify
            CloudEditionManagerService
              .validateCloudCredentials(regionsData, queryParams)
              .then(function gotRegionDetails(resp) {
                $ctrl.verified = true;
                $ctrl.submitButtonText = 'continue';
                $ctrl.currentStep = 2;
                $ctrl.vpc = resp.vpc;

                // reset the form validations for submit
                form.$setPristine();
              }, evalAJAX.errorMessage)
              .finally(function getAWSRegionsDetailsFinnally() {
                $ctrl.submitting = false;
              });

          // if verification is already done once, go directly to next step
          } else {
            $ctrl.currentStep = 2;
            form.$setPristine();
          }

          break;

        // Step 2
        case 2:
          $ctrl.currentStep = 3;
          $ctrl.submitButtonText = 'deploy';

          // reset the form validations for submit
          form.$setPristine();
          break;

        // Step 3 (submission)
        case 3:
          $ctrl.submitting = true;

          CloudEditionManagerService
            .deployCloudEdition($ctrl.deployTarget, $ctrl.tags)
            .catch(function failedCECreate(resp) {
              evalAJAX.errorMessage(resp);
              $ctrl.submitting = false;
            });

          break;
      }
    }

    /**
     * Go back one step in the form flow.
     *
     * @method     back
     */
    function back() {
      $ctrl.currentStep--;
      $ctrl.submitButtonText = 'next';
    }

    /**
     * Cancel this flow. Go back to cloud editions list view.
     *
     * @method     cancel
     */
    function cancel() {
      $state.go('cloud-edition-manager');
    }
  }

})(angular);