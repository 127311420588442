import { AppModuleConfig, CanAccess, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { Environment } from 'src/app/shared/constants';
import { hasSourceModifyPrivilege } from 'src/app/shared/helper-utils';

// Removing the preceding `k` character to make it compatible with hasSourceModifyPrivilege().
const key = Environment.kMongoDBPhysical.slice(1).toLowerCase();
const modifyAccess: CanAccess = (ctx) => hasSourceModifyPrivilege(ctx, key);

const mongodbPhysicalSourceModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  modifyAccess && ctx.workflow.backupAndRecovery.kMongoDBPhysical &&
  ctx.FEATURE_FLAGS.mongodbPhysicalProtection;

const mongodbPhysicalProtectionModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.mongodbPhysicalProtection &&
  ctx.PROTECTION_MODIFY;

export const MongodbPhysicalConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'mongodbPhysical.**',
      url: '/protection/protect/register',
      loadChildren: () => import('./mongodb-physical.module').then(m => m.MongoDBPhysicalModule),
    },
    {
      name: 'protection-builder-mongodb-physical.**',
      url: '/protection/protect/mongodb-physical',
      loadChildren: () => import('./mongodb-physical.module').then(m => m.MongoDBPhysicalModule),
    }
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'mongodbPhysical.new': mongodbPhysicalSourceModify,
      'mongodbPhysical.edit': mongodbPhysicalSourceModify,
      'protection-builder-mongodb-physical.create': mongodbPhysicalProtectionModify,
      'protection-builder-mongodb-physical.edit': mongodbPhysicalProtectionModify,
    };
  },
};
