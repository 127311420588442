import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { find } from 'lodash-es';

import { MenuOption } from './menu.model';

/**
 * Menu Component utilizes <mat-menu> to display a floating panel
 * containing list of options.
 *
 * @example
 *   <cog-menu [label]="'timeframe' | translate"
 *     [(value)]="selectedValue"
 *     [options]="options">
 *   </cog-menu>
 */
@Component({
  selector: 'cog-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {

  /**
   * The currently selected option value.
   */
  private _value = '';

  /**
   * Menu label.
   */
  @Input() label: string;

  /**
   * Optionally pass icon name to render as an icon next to the label.
   */
  @Input() iconName: string;

  /**
   * The options available for selection.
   */
  @Input() options: MenuOption[] = [];

  /**
   * The setter for value input/output.
   */
  @Input()
  set value(newValue: string) {
    if (typeof newValue === 'undefined') {
      this.valueChange.next(this._value);
    } else if (newValue !== this._value) {
      this._value = newValue;
      this.valueChange.next(newValue);
    }
  }

  /**
   * The getter for value input/output.
   */
  get value(): string {
    return this._value;
  }

  /**
   * Gets the current selected option.
   */
  get selectedOption(): MenuOption {
    return find(this.options, { value: this._value});
  }

  /**
   * Updates the output value.
   */
  @Output() valueChange = new EventEmitter<string>();

  /**
   * Indicates if the provided value is selected or not.
   *
   * @param   option   the menu option to evaluate.
   */
  isSelected(option: MenuOption): boolean {
    return option.value === this.value;
  }

}
