import { max } from 'lodash-es';
import { min } from 'lodash-es';
import { filter } from 'lodash-es';
import { get } from 'lodash-es';
// DB Object Service

(function _iife(angular) {
  'use strict';

  angular.module('C.dbDetails')
    .service('DbObjectService', DbObjectServiceFn);

  /**
   * @ngdoc service
   * @name C.dbDetails.DbObjectService
   *
   * @description
   * Returns useful info for a database object.
   **/
  function DbObjectServiceFn(_, PubSourceService, PubSourceServiceFormatter,
    RestoreService, SearchService, SimpleCacheService) {
    return {
      findSnapshots: findSnapshots,
      getDbAppInfo: getDbAppInfo,
      getRestoreTasks: getRestoreTasks,
    };

    /**
     * Returns basic host, instance, and db info for a database. Prunes results
     * to avoid storing the entire source tree.
     *
     * @method   getDbInfo
     * @param    {number}   dbId          Id of the db protection source.
     * @param    {number}   sourceId      Id of the db's parent source host.
     * @param    {string}   environment   The environment, ie kSQL.
     * @return   {Object}   Promise Resolved with details for the database
     *                      and related items.
     */
    function getDbAppInfo(dbId, sourceId, environment) {
      var params = {
        protectionSourcesRootNodeId: sourceId,
        application: environment,
      };
      var cacheId =
        ['DbObjectServiceAppServers', sourceId, environment].join('_');
      return SimpleCacheService.getCachedValue(cacheId,
        PubSourceService.getApplicationServers.bind(null, params)
      ).then(function getAppServer(servers) {
        return servers[0] || {};
      }).then(function formatResults(source) {
        var db;
        var instance;

        // Find the instance with our database
        if (get(source, 'applicationServer.protectionSource.sqlProtectionSource.type') === 'kAAG') {
          db = get(source, 'applicationServer.nodes', [])
            .find(function findDb(node) {
              return node.protectionSource.id === dbId;
            });
        } else {
          instance = get(source, 'applicationServer.applicationNodes', [])
          .find(function find(appNode) {
            db = appNode.nodes.find(function findDb(node) {
              return node.protectionSource.id === dbId;
            });
            return !!db;
          });
        }

        if (db && environment === 'kSQL') {
          db._isSystemDb = PubSourceServiceFormatter.isSystemDatabase(
            db.protectionSource);
          db._envProtectionSource = db.protectionSource.sqlProtectionSource;
          db._envProtectionSource._isFilestreamDb = PubSourceServiceFormatter
            .isFilestreamDatabase(db._envProtectionSource);
        }

        return {
          source: {
            protectionSource: source.applicationServer.protectionSource,
            _rootEnvironment: environment,
          },
          instance: instance ? {
            protectionSource: instance.protectionSource,
          } : undefined,
          db: db,
          parentProtectionSource: source.registeredProtectionSource,
        };
      });
    }

    /**
     * Gets a list of restore tasks for all successful sql clones and recoveries.
     *
     * @method   getRestoreTasks
     * @param    {string}   environment   Environment to filter by.
     * @param    {number}   entityId      Optional. Filter by entity id of the
     *                                    original, cloned or recovered object.
     * @return   {array}    A list of recovery and clone tasks filtered for sql.
     */
    function getRestoreTasks(environment, entityId) {
      // Cache the results of restore tasks
      var cacheKey =
        ['DBObjectServiceRestoreTasks', environment, entityId].join('_');
      return SimpleCacheService.getCachedValue(cacheKey,
        RestoreService.getAppRestoreTasks.bind(null, environment, entityId)
      ).then(function aggregateStats(tasks) {
        var stats = tasks.reduce(function getStats(accumulator, task) {
          if (task._status === 3)  {
            switch (task._restoreType) {
              case 'kRecoverApp':
                accumulator.activeRecoveries++;
                break;
              case 'kCloneApp':
                accumulator.activeClones++;
                break;
            }
          }
          return accumulator;
        }, {
          activeClones: 0,
          activeRecoveries: 0,
        });
        return {
          stats: stats,
          tasks: tasks,
        };
      });
    }

    /**
     * Find snapshots for a given db. Return the latest snapshot and an
     * aggregated summary of snapshots by type.
     *
     * @param    {number}   id             The db id.
     * @param    {number}   rootSourceId   The root source id.
     * @param    {string}   environment    The environment to search for.
     * @return   {object}   Object containing the latest snapshot and stats.
     */
    function findSnapshots(id, rootSourceId, environment) {
      var params = {
        // A specific entity
        entityIds: id,

        // On a specific source
        registeredSourceIds: rootSourceId,
      };

      // Map environments to a type appropiate for the search service
      var searchEnv = {
        kSQL: 'sql',
      };

      return SearchService.dbSearch(params, searchEnv[environment], false)
        .then(function jobResultsReceivedFn(results) {
          return filter(results, function findEntityFn(entity) {
            return entity.registeredSource.id === rootSourceId &&
              entity.vmDocument.objectId.entity.id === id;
          }) || [];
        })
        .then(function groupSnapshots(entities) {
          // These are the possible snapshot types as a map for easy lookup.
          // Iterate through all snapshots and update these stats. Then convert
          // to an array when we return to easily show the stats in a table
          var stats = {
            // Local
            1: {
              count: 0,
              target: {
                type: 1,
              },
            },

            // Remote
            2: {
              count: 0,
              target: {
                type: 2,
              },
            },

            // Archival
            3: {
              // Cloud
              0: {
                count: 0,
                names: [],
                target: {
                  type: 3,
                  archivalTarget: {
                    type: 0,
                  },
                },
              },

              // Tape
              1: {
                count: 0,
                names: [],
                target: {
                  type: 3,
                  archivalTarget: {
                    type: 1,
                  },
                },
              },

              // NAS
              2: {
                count: 0,
                names: [],
                target: {
                  type: 3,
                  archivalTarget: {
                    type: 2,
                  },
                },
              },
            },
          };
          var latestSnapshot;

          entities.forEach(function processEntity(entity) {
            var versions = entity._versions || [];

            // There could be multiple entities passed back which are protected
            // by multiple jobs. Pick the most recent snapshot and decorate it
            // with the job id.
            if (entity._snapshot) {
              entity._snapshot._jobId = entity._jobId;
              entity._snapshot._jobUid = entity._jobUid;
              if (!latestSnapshot ||
                latestSnapshot.snapshotTimestampUsecs <
                entity._snapshot.snapshotTimestampUsecs) {
                latestSnapshot = entity._snapshot;
              }
            }

            versions.forEach(function consolidateStats(snapshot) {
              snapshot.replicaInfo.replicaVec.forEach(function process(val) {
                var summary = stats[val.target.type];

                // Look up a subkey if this is an archive snapshot
                if (val.target.archivalTarget) {
                  summary = summary[val.target.archivalTarget.type];

                  // Capture the name of this target for display in the summary
                  // tooltip.
                  if (!summary.names.includes(val.target.archivalTarget.name)) {
                    summary.names.push(val.target.archivalTarget.name);
                  }
                }

                summary.count += 1;
                summary.earliestTimestampMs = min([
                  summary.earliestTimestampMs,
                  snapshot.snapshotTimestampUsecs / 1000]);
                summary.latestTimestampMs = max([
                  summary.latestTimestampMs,
                  snapshot.snapshotTimestampUsecs / 1000]);
              });
            });
          });

          return {
            latestSnapshot: latestSnapshot,
            snapshotSummary: [
              stats[1],
              stats[2],
              stats[3][0],
              stats[3][1],
              stats[3][2],
            ],
          };
        });
    }
  }
})(angular);
