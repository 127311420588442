import { ServiceGflags } from '@cohesity/api/v1';
import { checkClusterVersion } from '@cohesity/utils';
import { isHeliosTenantUser, isMcm, isPrivilegedUser } from './common-utils';
import { dmsTenantId, dmsTenantName, isDmsScope } from './dms-utils';
import { draasTenantId, draasTenantName, isDraasScope } from './draas-utils';
import { IrisContext } from './iris-context.model';
import { isRpaasScope, rpaasTenantId } from './rpaas-utils';
import { isSecurityCenterScope } from './security-center-utils';

const selectedAccountKey = 'C.salesforceAccount';

/**
 * Type info for iris can access functions.
 */
export type IrisContextAccessFn = (irisContext: IrisContext) => boolean;

/**
 * Get the remote access cluster id. This is only set if the current scope is differrent from the accessCluster.
 *
 * @param irisContext The current iris context.
 * @returns the id of the remote access cluster or undefined.
 */
export function getRemoteAccessClusterId(irisContext: IrisContext): number {
  return irisContext?.selectedScope?.clusterId !== irisContext?.accessClusterId
    ? irisContext?.selectedScope?.clusterId
    : undefined;
}

/**
 * Returns true if the current user is in "global" scope (hybrid helios and dmaas).
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in Global context.
 */
export function isGlobalScope(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.selectedScope?._globalContext);
}

/**
 * Returns true if the current user is in "All Clusters" scope.
 * All clusters is a Helios only concept, and isn't supported on prem.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in DMaaS context.
 */
export function isAllClustersScope(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.selectedScope?._allClusters);
}

/**
 * Returns true if the current user is in  "Cluster Context" scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in Cluster context.
 */
export function isClusterScope(irisContext: IrisContext): boolean {
  return (
    Boolean(irisContext?.selectedScope) &&
    !isAllClustersScope(irisContext) &&
    !isGlobalScope(irisContext) &&
    !isCohesityServiceScope(irisContext)
  );
}

/**
 * Returns true if the current is in a Cohesity service scope. DMaaS and DRaaS
 * are such Cohesity services.
 *
 * @param irisContext THe current iris context.
 * @returns boolean True if the uer is Cohesity service scope.
 */
export function isCohesityServiceScope(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.selectedScope?._cohesityService);
}

/**
 * If operating in a single cluster scope, provides the mcm cluster identifer
 * for the cluster.
 *
 * @param irisContext The current iris context.
 */
export function clusterScopeIdentifier(irisContext: IrisContext): string {
  if (!isClusterScope(irisContext)) {
    return undefined;
  }

  // Coercise this to a string because the mcm APIs are expecting a string.
  // NOTE: might be worth doing this in the implementation if it becomes
  // clear that its more appropriate as a number in most scenarios.
  return irisContext.selectedScope.clusterId + ':' + irisContext.selectedScope.clusterIncarnationId;
}

/**
 * Provides a list of cluster ids based on the current context.
 *
 * @param irisContext The current iris context.
 * @returns this list of cluster identifiers.
 */
export function clusterIdentifiers(irisContext: IrisContext): string[] {
  if (!irisContext?.selectedScope) {
    return [];
  }

  // For "global" or "all cluster" context, provide all the cluster ids.
  if (
    isGlobalScope(irisContext) ||
    isAllClustersScope(irisContext) ||
    isRpaasScope(irisContext) ||
    isDraasScope(irisContext) ||
    isSecurityCenterScope(irisContext)
  ) {
    let clusterDetails = irisContext.user?.clusterIdentifiers;

    // Check for helios tenant user and reassign clusterDetails from profiles
    if (isHeliosTenantUser(irisContext)) {
      const { profiles } = irisContext.user || {};
      clusterDetails = profiles?.length ? profiles[0].clusterIdentifiers : [];

      // {clusterId: -1, clusterIdentifier: -1} means tenant has access to all clusters
      if (clusterDetails?.[0]?.clusterId === -1) {
        clusterDetails = [];
      }
    }

    return (clusterDetails || []).map(identifier => `${identifier.clusterId}:${identifier.clusterIncarnationId}`);
  }

  const { clusterId, clusterIncarnationId, incarnationId } = irisContext.selectedScope;

  // BaaS accounts don't have any clusters
  if (clusterId && (incarnationId || clusterIncarnationId)) {
    // Else return the cluster id for the currently selected cluster.
    return [`${clusterId}:${incarnationId || clusterIncarnationId}`];
  }

  return [];
}

/**
 * Returns true if iris is running in MCM SaaS mode.
 *
 * @param irisContext The current iris context.
 * @returns true if iris is running in MCM SaaS mode.
 */
export function isMcmSaaS(irisContext: IrisContext): boolean {
  if (!irisContext.basicClusterInfo) {
    return false;
  }

  return irisContext.basicClusterInfo.mcmMode && !irisContext.basicClusterInfo.mcmOnPremMode;
}

/**
 * Return true if iris is in MCM on-prem mode.
 *
 * @param irisContext The current iris context.
 * @returns true if iris is in MCM on-prem mode.
 */
export function isMcmOnPrem(irisContext: IrisContext): boolean {
  if (!irisContext.basicClusterInfo) {
    return false;
  }

  return irisContext.basicClusterInfo.mcmMode && irisContext.basicClusterInfo.mcmOnPremMode;
}

/**
 * Return true if iris is in MCM gov cloud environment.
 *
 * @param irisContext The current iris context.
 * @returns true if iris is in gov cloud environment.
 */
export function isMcmGovCloud(irisContext: IrisContext): boolean {
  return ['HELIOS_GOV'].includes(
    irisContext?.basicClusterInfo?.heliosControlPlaneEnv
  );
}

/**
 * Return the Salesforce company privileged user selected.
 *
 * @param irisContext The current iris context.
 * @return Salesforce company object.
 */
export function getSalesforceCompany(irisContext: IrisContext) {
  if (isPrivilegedUser(irisContext)) {
    let { selectedAccount }: any = irisContext.user?.salesforceAccount || {};
    if (!selectedAccount) {
      selectedAccount = JSON.parse(localStorage.getItem(selectedAccountKey)) || undefined;
      if (!irisContext.user) {
        irisContext.user = {};
      }
      if (!irisContext.user.salesforceAccount) {
        irisContext.user.salesforceAccount = {};
      }
      (irisContext.user.salesforceAccount as any).selectedAccount = selectedAccount;
    }
    return selectedAccount;
  }
}

/**
 * Return true if user has company context.
 *
 * @param irisContext The current iris context.
 * @returns true if user has company context.
 */
export function hasCompanyContext(irisContext: IrisContext): boolean {
  return !isPrivilegedUser(irisContext) || Boolean(getSalesforceCompany(irisContext));
}

/**
 * Gets the current tenant ID, for both normal and DMS user.
 *
 * @param  irisContext  The current iris context.
 * @return The tenant ID of the current user or null.
 */
export function getUserTenantId(irisContext: IrisContext): null | string {
  switch (true) {
    case isDmsScope(irisContext):
      return dmsTenantId(irisContext);
    case isDraasScope(irisContext):
      return draasTenantId(irisContext);
    case isMcm(irisContext):
      return irisContext.user.tenantId?.split(':')[1];
    case isRpaasScope(irisContext):
      return rpaasTenantId(irisContext);
    case isSecurityCenterScope(irisContext):
      return dmsTenantId(irisContext);
    default:
      return irisContext.user.tenantId;
  }
}

/**
 * Function to return any available tenant id for a user.
 *
 * @param irisContext The current iris contex.
 * @return The tenant ID, id the user has one.
 */
export function getUserAnyTenantId(irisContext: IrisContext): null | string {
  // First try to get the scope specific ID
  return (
    getUserTenantId(irisContext) ||
    // Otherwise fallback to any available tenant ID
    dmsTenantId(irisContext) ||
    draasTenantId(irisContext) ||
    rpaasTenantId(irisContext)
  );
}

/**
 * DMaaS tenant ID is of the format 'salesforceAccountId:tenantId'. This
 * function determines the 'tenantId'.
 *
 * @param irisContext Specifies the iris context.
 * @returns standalone tenant ID.
 */
export function getStandaloneTenantId(irisContext: IrisContext): null | string {
  const tenantIdWithSfAccountId = getUserTenantId(irisContext);
  if (!tenantIdWithSfAccountId || tenantIdWithSfAccountId.indexOf(':') < 0) {
    return null;
  }
  return tenantIdWithSfAccountId.split(':')?.[1];
}

/**
 * Returns the account ID for a given context
 *
 * @param   irisContext  The current iris context.
 * @returns The account Id for the current user or null.
 */
export function getMcmAccountId(irisContext: IrisContext): null | string {
  // Account ID are only applicable to Helios SaaS.
  if (!isMcmSaaS(irisContext)) {
    return null;
  }

  return irisContext.user.salesforceAccount.accountId;
}

/**
 * Returns the tenant name for a given Iris context.
 *
 * @param  irisContext  The current iris context.
 * @return The tenant name of the current user or null.
 */
export function getUserTenantName(irisContext: IrisContext): null | string {
  switch (true) {
    case isDmsScope(irisContext):
      return dmsTenantName(irisContext);
    case isDraasScope(irisContext):
      return draasTenantName(irisContext);
  }

  return null;
}

/**
 * Determines whether the logged-in user organization is owning the provided
 * entity like vlan, user, viewboxes, remote cluster, source etc
 *
 * @param  irisContext  The current iris context.
 * @param  entityOwnerTenantIds The tenant id attached to the enitity.
 * @return Return true if logged-in user organization is the owner of the
 *         provided entity else false.
 */
export function isEntityOwner(irisContext: IrisContext, entityOwnerTenantIds: string | string[]): boolean {
  // DMS users are tenants by default.
  // They are also the owners of what they see.
  if (isDmsScope(irisContext)) {
    return true;
  }

  // perform match with logged in user tenantId or impersonating
  // organization tenantId.
  const userTenantId = getUserTenantId(irisContext);

  if (!Array.isArray(entityOwnerTenantIds)) {
    return userTenantId === entityOwnerTenantIds;
  }

  return entityOwnerTenantIds.some(tenantId => userTenantId === tenantId);
}

/**
 * Determines whether a given feature flag is enabled and cluster meets minimum
 * required version.
 *
 * @param irisContext The current iris context.
 * @param name The name of the feature flag.
 * @return True if feature should be enabled.
 */
export function flagEnabled(irisContext: IrisContext, name: string): boolean {
  const isFlagTrue = irisContext?.featureFlags?.[name] === true;
  const minimumRequiredVersion = irisContext?.minimumClusterVersions?.[name];
  const onPremClusterVersion = irisContext?.clusterInfo?.clusterSoftwareVersion;

  if (!minimumRequiredVersion) {
    // No minimum required version for this flag. Only evaluate flag value.
    return isFlagTrue;
  }

  return isFlagTrue && checkClusterVersion(onPremClusterVersion, minimumRequiredVersion);
}


/**
 * Returns the raw value of a feature flag.
 *
 * @param irisContext The current iris context.
 * @param name The name of the feature flag.
 * @return Return current value.
 */
export function flagValue(irisContext: IrisContext, name: string): boolean | string | number {
  return irisContext?.featureFlags?.[name];
}

/**
 *
 * Check whether helios organizations have been turned on for this users context or not.
 *
 * @param irisContext The current iris context.
 * @returns Return true if organizations are enabled.
 */
export function isOrganizationEnabled(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.isOrganizationEnabled);
}

/**
 * Indicates if the implementation is FlashRecover, which is our
 * Pure hardware offering.
 * Note: refered to as isDisaggregateStorage in legacy implementations.
 *
 * @param irisContext The current iris context.
 * @returns Return true if Flash Recover, false otherwise.
 */
export function isFlashRecover(irisContext: IrisContext): boolean {
  const hardwareModels = irisContext.clusterInfo?.hardwareInfo?.hardwareModels || [];
  return hardwareModels.includes('PXG1') || hardwareModels.includes('PXG2');
}

/**
 * Return whether a given gflag is enabled.
 *
 * @param irisContext The current iris context.
 * @param serviceName The service name of the gflag.
 * @param name The name of the feature flag.
 * @return Return true if gflag is enabled.
 */
export function gflagEnabled(
  irisContext: IrisContext,
  serviceName: ServiceGflags['serviceName'],
  name: string
): boolean {
  return irisContext?.gflags?.[serviceName]?.[name]?.value === 'true';
}

/**
 * Returns the raw value of a gflag.
 *
 * @param irisContext The current iris context.
 * @param serviceName The service name of the gflag.
 * @param name The name of the gflag.
 * @return Return current value.
 */
export function gflagValue(
  irisContext: IrisContext,
  serviceName: ServiceGflags['serviceName'],
  name: string
): boolean | string | number {
  return irisContext?.gflags?.[serviceName]?.[name]?.value;
}

/**
 * Indicates if the gFlag is enabled for SmartFiles UI in FlashRecover systems.
 *
 * @param irisContext The current iris context.
 * @returns Return true if SmartFiles UI is to be enabled in FlashRecover systems.
 */
export function isSmartFilesUIEnabledForFlashRecover(irisContext: IrisContext): boolean {
  return gflagEnabled(irisContext, 'kIris', 'iris_enable_smartfiles_ui_for_flash_recover');
}

/**
 * Indicates if the SmartFiles UI is enabled for the current system
 *
 * @param irisContext The current iris context.
 * @returns Return true if SmartFiles UI is to be enabled
 */
export function isSmartFilesUIEnabled(irisContext: IrisContext): boolean {
  return !isFlashRecover(irisContext) || isSmartFilesUIEnabledForFlashRecover(irisContext);
}

/**
 * Checks if the applianceManager UI is enabled for the current system.
 *
 * @param irisContext The current iris context.
 * @returns Returns true if the applianceManager UI is to be enabled.
 */
export function isOneHeliosAppliance(irisContext: IrisContext): boolean {
  return irisContext.basicClusterInfo?.softwareType === 'kOneHelios';
}

/**
 * Returns true for either of the following conditions:
 * - clusterDeploymentType === 'KIBMBaaS'
 * - Feature flag 'kIBMBaaSEnabled' is true.
 *
 * @param irisContext The current iris context.
 * @returns True if cluster needs to run in IBM Cloud mode
 */
export const isIbmBaaSEnabled = (irisContext: IrisContext): boolean =>
  flagEnabled(irisContext, 'kIBMBaaSEnabled') ||
  irisContext?.basicClusterInfo?.clusterDeploymentType === 'kIBMBaaS';

/**
 * Returns true if this cluster is Rx vault cluster.
 *
 * @param irisContext Iris context
 * @returns True if cluster is vault cluster
 */
export const isVaultCluster = (irisContext: IrisContext): boolean =>
  flagEnabled(irisContext, 'onPremVaultClusters') && irisContext.vaultClusterInfo?.isVaultCluster;

/**
 * Returns whether this cluster is in CARR mode, which is used
 * to
 *
 * @param irisContext
 * @returns if carr mode ( restricted ui ) enabled
 */
export const isCarrEnabled = (irisContext: IrisContext): boolean =>
  flagEnabled(irisContext, 'carrEnabled');
