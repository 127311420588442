import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AjaxHandlerService, AutoDestroyable, DialogService, DontShowAgainEnum, DontShowAgainService } from '@cohesity/utils';
import { AttributeFilter } from '@cohesity/api/reporting';
import { attributeToUrl } from '@cohesity/iris-reporting';
import { StateService } from '@uirouter/core';
import { NavigationWarningDialogData, NavigationWarningDialogComponent } from '@cohesity/shared-dialogs';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import { ComplianceDonutData, SlaComplianceAltService } from './sla-compliance-alt-card.service';
import { IrisContextService, isMcm, isTenantUser } from '@cohesity/iris-core';

/**
 * The parameter values of the sla status passed to the report.
 */
type SlaComplianceCardStatus = 'Met' | 'Missed';

/**
 * @description
 * SLA Compliance dashcard.
 * The card by default displays SLA status in the statlists.
 * If displayProtectionRuns is set to true, the card will display protection runs stats instead.
 *
 * @example
 *  <coh-sla-compliance-alt-card title="Compliance"></coh-sla-compliance-alt-card>
 */
@Component({
  selector: 'coh-sla-compliance-alt-card',
  templateUrl: './sla-compliance-alt-card.component.html',
  styleUrls: ['./sla-compliance-alt-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SlaComplianceAltService]
})
export class SlaComplianceAltCardComponent extends AutoDestroyable implements OnInit {
  /**
   * Title to be displayed on the card.
   */
  title = this.translateService.instant('compliance');

  /**
   * Flag to indicate single cluster selection scope.
   */
  get isClusterScope() {
    return this.slaComplianceService.isClusterScope;
  }

  /**
   * Custom Pie Chart Options.
   */
  readonly customChartOptions = {
    tooltip: { ...this.formatTooltip() },
  };

  /**
   * SLA compliance data.
   */
  complianceDonutData: ComplianceDonutData;

  /**
   * Flag for indicating data request.
   */
  isLoading = false;

  /**
   * Returns true for current user is tenant.
   * This is used to hide certain parts of the view.
   */
  readonly isTenantUser = isTenantUser(this.irisCtx.irisContext);

  readonly isMcm = isMcm(this.irisCtx.irisContext);

  get compliancePct() {
    const { totalMetSla = 0, totalMissedSla = 0 } = this.complianceDonutData;

    // Prevent division by zero
    if (!totalMetSla && !totalMissedSla) {
      return 0;
    }

    return ((totalMetSla / (totalMetSla + totalMissedSla)) * 100).toFixed();
  }

  /**
   * Custom tooltip formatter for highcharts series.
   */
  formatTooltip() {
    return {
      formatter() {
        return this.point.description;
      }
    };
  }

  constructor(
    private ajsEvalAjaxService: AjaxHandlerService,
    private slaComplianceService: SlaComplianceAltService,
    private state: StateService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private dontShowAgainService: DontShowAgainService,
    private change: ChangeDetectorRef,
    private irisCtx: IrisContextService,
  ) {
    super();
  }

  /**
   * Initialize component.
   */
  ngOnInit() {
    if(this.isMcm || !this.isTenantUser){
      this.getComplianceDonutData();
    }
  }

  private getComplianceDonutData() {
    this.isLoading = true;
    this.slaComplianceService.getComplianceDonutData()
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isLoading = false;
          this.change.markForCheck();
        }))
      .subscribe((data: ComplianceDonutData) => {
        data.series = data.series.map(series => {
          series.events = {
            click: $event => {
              // in single cluster mode, the whole card is clickable and sends to jobs.
              if (this.isClusterScope) {
                return;
              }
              $event.preventDefault();
              $event.stopPropagation();
              this.goToReport(
                $event.point.index === 0 ? 'Met' : 'Missed'
              );
            },
          };
          return series;
        });
        this.complianceDonutData = data;
      },
      this.ajsEvalAjaxService.errorMessage);
  }

  /**
   * This method sends the user to the protection-runs report and filters
   * the report by the provided params.  But only after warning the user that
   * the report and this widget do not exactly align.
   *
   * @param slaStatus - the sla status to filter the report by.  Defaults to Met.
   */
  goToReport(slaStatus: SlaComplianceCardStatus = 'Met') {

    // in single cluster mode, the whole card is clickable and sends to jobs.
    if (this.isClusterScope) {
      return;
    }

    const dontShowAgainKey = DontShowAgainEnum.navigationToReportProtectionRuns;

    const reportName = this.translateService.instant('reporting.dataModel.ProtectionRuns');
    const navigationWarningMessage = this.translateService.instant('navigation.interrupt.warning.report', { reportName });
    const destination = this.translateService.instant('reporting');

    const data: NavigationWarningDialogData = {
      title: this.translateService.instant('navigation.interrupt.title', { destination }),
      dontShowAgainKey,
      navigationWarningMessage
    };

    const interrupt = this.dontShowAgainService.isDontShowAgain(dontShowAgainKey) ?
      of(true) : this.dialogService.open(NavigationWarningDialogComponent, { data });

    interrupt.pipe(
      this.untilDestroy(),
    ).subscribe((result) => {
      if (result) {
        this.state.go('reporting.detail', { id: 'protection-runs', ...this.getReportParams(slaStatus) });
      }
    });
  }

  /**
   * Gets params values for reporting deeplink
   * and returns as url params
   *
   * @param slaStatus - the sla status to filter the report by.  Defaults to Met.
   * @returns - url params
   */
  getReportParams(slaStatus: SlaComplianceCardStatus): { [key: string]: string[] | string } {

    // build the report params
    const reportParams: AttributeFilter[] = [
      {
        attribute: 'date',
        filterType:'TimeRange',
        timeRangeFilterParams: {
          dateRange : 'Last24Hours',
        },
      },
      {
        attribute: 'slaStatus',
        filterType:'In',
        inFilterParams: {
          attributeDataType: 'String',
          attributeLabels: [slaStatus === 'Met' ? 'enum.global.Met' : 'enum.global.Missed'],
          stringFilterValues: [slaStatus],
        },
      },

    ];

    return attributeToUrl(reportParams);
  }
}
