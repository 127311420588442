import { isEmpty } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: Source Service Formatter

(function(angular, undefined) {
  'use strict';

  angular.module('C.sourceServiceFormatter', [])
    .service('SourceServiceFormatter', SourceServiceFormatterFn);

  function SourceServiceFormatterFn(PUB_TO_PRIVATE_ENV_STRUCTURES, ENV_GROUPS,
    FEATURE_FLAGS, ENTITY_KEYS, ENV_TYPE_CONVERSION, _) {

    /**
     * A hashmap to account for duplicate nodes
     */
    var nodeSelectionHash = {};

    return {
      buildEnvTypesMapper: buildEnvTypesMapper,
      calculateSourceInfo: calculateSourceInfo,
      extractAagInfo: extractAagInfo,
      findNumEntities: findNumEntities,
      pruneCloudTree: pruneCloudTree,
      transformAagInfo: transformAagInfo,
      nodeProtectionStatusDecorator: nodeProtectionStatusDecorator,
    };

    /**
     * Transform the AAG info response to UI usable format.
     *
     * @method   transformAagInfo
     * @param    {object}   resp   The server's resp.
     * @return   {object}   Hash of AAG details by AAG id.
     */
    function transformAagInfo(resp) {
      // This array check may need to be loosened...
      if (!resp || !Array.isArray(resp.data)) { return {}; }

      return resp.data.reduce(function rearrangeAagInfo(output, aagHost) {
        (aagHost.aagDatabases || []).forEach(function eachAag(aag) {
          var thisAag = output[aag.aag.id];

          if (!thisAag) {
            output[aag.aag.id] = thisAag = angular.extend(aag.aag, {
              aagDatabases: [],

              // NOTE: An available property that's not presently needed.
              // Leaving here for future reference.
              // databases: aagHost.databases,
              hosts: [],
            });
          }

          // Will be true if any AAG nodes are unknown to Cohesity.
          // Using $prefix so ngRepeat ignores it. This is desired.
          output.$hasUnknownAagNodes =
            output.$hasUnknownAagNodes || !!aagHost.unknownHostName;

          thisAag.hosts.push(
            aagHost.applicationNode ?
              aagHost.applicationNode.protectionSource :
              {
                errorMsg: aagHost.errorMessage,
                name: aagHost.unknownHostName,
              }
          );
          thisAag.aagDatabases
            .splice(thisAag.aagDatabases.length - 1, 0, aag.databases);
        });

        return output;
      }, {});
    }

    /**
     * Extracts AAG info from a given node in an EntityHierarchy.
     *
     * @method   extractAagInfo
     * @param    {object}   node   The node
     * @return   {object}   Map of AAGs this node is aware of (by aagId).
     */
    function extractAagInfo(node) {
      return getSqlEntitiesFromHost(node).reduce(
        function entitiesReducer(aags, entity) {
          var aagId = entity.sqlEntity.dbAagEntityId;

          if (!aagId) { return aags; }

          if (!aags[aagId]) {
            aags[aagId] = {
              entities: [],
              id: aagId,
              name: entity.sqlEntity.dbAagName || aagId,
            };
          }

          aags[aagId].entities.push(entity);

          return aags;
        },
        {}
      );
    }

    /**
     * Gets the sql entities from host.
     *
     * @method   getSqlEntitiesFromHost
     * @param    {object}   node   EntityHierarchy node.
     * @return   {array}    Array of found SQL entities.
     */
    function getSqlEntitiesFromHost(node) {
      var foundSqlEntities = !!node &&
        Array.isArray(node.auxChildren) &&
        node.auxChildren.reduce(
          function reduceToSqlEntities(sqlEntitiesHash, childEntity) {
            if (!childEntity.entity.sqlEntity) {
              return sqlEntitiesHash;
            }

            (childEntity.children || []).forEach(
              function eachSubChildEntity(subChildEntity) {
                if (sqlEntitiesHash[subChildEntity.entity.id]) { return; }

                sqlEntitiesHash[subChildEntity.entity.id] = subChildEntity.entity;
              }
            );

            return sqlEntitiesHash;
          },
          {}
        );

      // If foundSqlEntities is an object, return its values as an array,
      // otherwise return empty array.
      return Object.values(foundSqlEntities || {});
    }

    /**
     * Builds a hash based on PUB_TO_PRIVATE_ENV_STRUCTURES but extended to
     * allow int > kEnum and kEnum > int lookups.
     *
     * @method   buildEnvTypesMapper
     * @return   {object}   The environment types mapper.
     */
    function buildEnvTypesMapper() {
      // Looping over just the keys of this because we need to reduce it to a
      // new hash.
      return Object.keys(PUB_TO_PRIVATE_ENV_STRUCTURES)
        .reduce(function reducer(out, kTypeEnum) {
          // Reference to the envType config hash
          var thisEnv = PUB_TO_PRIVATE_ENV_STRUCTURES[kTypeEnum];

          // Create references to thisEnv config hash by both envType int and
          // kEnvString. This is a self-reference to the same object.
          out[kTypeEnum] = out[thisEnv.envType] = thisEnv;

          // Make this kTypeEnum programatically referencable as a look up value
          // with a fixed key name like `entityTypes{}` and
          // `sourceEntityTypes{}`.
          thisEnv.envTypeEnum = kTypeEnum;

          // Now, for each property of thisEnv...
          angular.forEach(thisEnv, function eachProperty(val) {
            // If it's value is an an object, look within it. We're not
            // concerned with primitive values at this level.
            if (angular.isObject(val)) {
              // For each subProp of this sub-object...
              angular.forEach(val, function eachSubProp(subVal, subProp) {
                // If the key is an integer..
                if (Number.isInteger(subProp)) {
                  // Assign it's inverse: string: int for cross-lookup.
                  val[subVal] = subProp;
                }
              });
            }
          });

          return out;
        }, {});
    }

    /**
     * Calculates at-a-glance information for the provided source.
     *
     * @method     calculateSourceInfo
     * @param      {object}  sourceEntity  The source entity object
     * @return     {object}  The at-a-glance information for the source
     */
    function calculateSourceInfo(sourceEntity) {
      var sourceType = sourceEntity.type;
      var totals = {
        protectedObjects: 0,
        protectedSize: 0,
        totalDatabases: 0,
        totalObjects: sourceEntity.children.length,
        totalSize: 0,
        unprotectedObjects: 0,
        unprotectedSize: 0,
      };
      var protectedVec;
      var unprotectedVec;

      switch (true) {
        case ENV_GROUPS.physical.includes(sourceType):
          // Calculates Physical Server info: total, protected, and unprotected.
          // NOTE: Magneto does not count file-based jobs when determining
          // whether a server is considered Protected. But in the UI we have to
          // consider both file-based and block-based jobs for our user-facing
          // count. So we have to aggregate some numbers in the front-end.
          sourceEntity.children.forEach(
            function forEachPhysicalEntity(child) {
              protectedVec = child.aggregatedProtectedInfoVec;
              unprotectedVec = child.aggregatedUnprotectedInfoVec;

              if (Array.isArray(protectedVec)) {
                protectedVec.some(function forEachProtectedVec(item) {
                  if (item.numEntities) {
                    if (item.totalLogicalSizeInBytes) {
                      totals.protectedSize += item.totalLogicalSizeInBytes;
                    }
                    if ([6, 13].includes(item.type)) {
                      totals.protectedObjects += item.numEntities;
                      return true;
                    }
                  }
                });
              }

              if (Array.isArray(unprotectedVec)) {
                unprotectedVec.forEach(function forEachUnprotectedVec(item) {
                  if (item.totalLogicalSizeInBytes) {
                    totals.unprotectedSize += item.totalLogicalSizeInBytes;
                  }
                });
              }
            }
          );

          totals.unprotectedObjects = totals.totalObjects - totals.protectedObjects;
          break;

        // Database Containers
        case ENV_GROUPS.databaseSources.includes(sourceEntity.entity.type):
          if (Array.isArray(sourceEntity.aggregatedProtectedInfoVec)) {
            sourceEntity.aggregatedProtectedInfoVec.some(
              function eachProtectedInfoVec(infoItem) {
                if (infoItem.numEntities &&
                  infoItem.type === sourceEntity.entity.type) {
                  totals.totalSize += infoItem.totalLogicalSizeInBytes || 0;
                  totals.protectedSize = infoItem.totalLogicalSizeInBytes || 0;
                  totals.totalDatabases += infoItem.numEntities;
                  totals.protectedObjects += infoItem.numEntities;
                  return true;
                }
              }
            );
          }

          if (Array.isArray(sourceEntity.aggregatedUnprotectedInfoVec)) {
            sourceEntity.aggregatedUnprotectedInfoVec.some(
              function eachUnprotectedInfoVec(infoItem) {
                if (infoItem.numEntities &&
                  infoItem.type === sourceEntity.entity.type) {
                  totals.totalSize += infoItem.totalLogicalSizeInBytes || 0;
                  totals.unprotectedSize = infoItem.totalLogicalSizeInBytes || 0;
                  totals.unprotectedObjects = infoItem.numEntities;
                  totals.totalDatabases += infoItem.numEntities;
                  return true;
                }
              }
            );
          }
          break;

        // All other source types.
        default:
          protectedVec = sourceEntity.aggregatedProtectedInfoVec;
          unprotectedVec = sourceEntity.aggregatedUnprotectedInfoVec;

          if (protectedVec && protectedVec.length) {
            totals.protectedObjects = protectedVec[0].numEntities || 0;
            totals.protectedSize = protectedVec[0].totalLogicalSizeInBytes || 0;
          }

          if (unprotectedVec && unprotectedVec.length) {
            totals.unprotectedObjects = unprotectedVec[0].numEntities || 0;
            totals.unprotectedSize =
              unprotectedVec[0].totalLogicalSizeInBytes || 0;
          }

          totals.totalObjects =
            totals.protectedObjects + totals.unprotectedObjects;
      }

      totals.totalSize = totals.protectedSize + totals.unprotectedSize;

      return totals;
    }

    /**
     * Traverse the cloud tree and filter all the unwanted nodes.
     * Also preserve the selection state for duplicate nodes.
     *
     * @method   pruneCloudTree
     * @param    {Object []}   nodes   Entity hierarchy tree nodes
     * @return   {Object []}   The filtered nodes
     */
    function pruneCloudTree(nodes) {
      // Find selected nodes in the tree
      var selectedCloudNodes = _findSelectedCloudNodes(nodes, []);
      var nodeIdMap = selectedCloudNodes.map(function mapNodes(node) {
        return node.entity.id;
      });

      nodes = _pruneCloudTree(nodes);

      // Restore the selected nodes state in case they got pruned
      nodes.forEach(function eachNode(node) {
        if (nodeIdMap.includes(node.entity.id)) {
          selectedCloudNodes.find(function findNode(nod) {
            if (nod.entity.id === node.entity.id) {
              node._isSelected = nod._isSelected;
            }
          });
        }
        if (node.children) {
          node.children.forEach(eachNode);
        }
      });

      return nodes;
    }

    /**
     * This function finds all the selected nodes in the tree.
     * Some nodes can be duplicates (in case of tags).
     * Some of these duplicates may have different selected state.
     * After pruning, duplicates are lost and consequently the true
     * selected state may also get lost.
     * So we filter the selected nodes initially.
     * After pruning, we will match the filtered nodes against this list and
     * restore the selected state.
     *
     * @method   _findSelectedCloudNodes
     * @param    {Object []}   nodes   Entity hierarchy tree nodes
     * @return   {Object []}   The selected nodes
     */

    function _findSelectedCloudNodes(nodes, selectedCloudNodes) {
      for (var i in nodes || []) {
        if (nodes[i]._isSelected) {
          selectedCloudNodes.push(nodes[i]);
        }
        if (nodes[i].children) {
          _findSelectedCloudNodes(nodes[i].children, selectedCloudNodes)
        }
      }

      return selectedCloudNodes;
    }

    /**
     * Traverse the cloud tree and filter all the unwanted nodes
     *
     * @method   _pruneCloudTree
     * @param    {Object []}   nodes   Entity hierarchy tree nodes
     * @return   {Object []}   The filtered nodes
     */
    function _pruneCloudTree(nodes) {
      var filteredNodes = [];

      nodes.forEach(function pruneNode(node) {
        switch (node.entity.type) {
          case ENV_TYPE_CONVERSION.kAzure:
            if (![0, 1, 2].includes(node.entity.azureEntity.type)) {
              return;
            }
            break;

          case ENV_TYPE_CONVERSION.kKVM:
            if (![0, 2, 3, 5].includes(node.entity.kvmEntity.type)) {
              return;
            }
            break;

          case ENV_TYPE_CONVERSION.kAWS:
            if (![0, 1, 2, 3, 10, 12, 16].includes(node.entity.awsEntity.type)) {
              return;
            }
            break;

          case ENV_TYPE_CONVERSION.kGCP:
            if (![0, 1, 2, 3, 4].includes(node.entity.gcpEntity.type)) {
              return;
            }
            break;
        }


        // recurse for children nodes
        if (node.children && node.children.length) {
          node.children = _pruneCloudTree(node.children);
        }

        // accept a node if it is a valid leaf, or has valid children
        if (node._isLeaf || (node.children && node.children.length)) {
          filteredNodes.push(node);

          // re set the numEntities since some children are filtered out
          if (!node._isLeaf) {
            node._numEntities = findNumEntities(node);
          }
        }
      });

      return filteredNodes;
    }

    /**
     * Returns the number of (filtered) leaf nodes for a given node
     *
     * @method   _findNumEntities
     * @param    {Object}   node   The node
     * @return   {Number}   The number of leaves
     */
    function findNumEntities(node) {
      return node.children.reduce(function getSum(total, child) {
        return total + child._numEntities;
      }, 0);
    }

    /**
     * Decorates a node with it's same-level protection status convenience
     * properties.
     *
     * @method   nodeProtectionStatusDecorator
     * @param    {object}   node   The node
     * @return   {object}   Copy of the input with decorators added.
     */
    function nodeProtectionStatusDecorator(node) {
      var protectedHash = _generateAggregatedProtectedInfoHash(node);
      var isProtected;
      var isDbProtected;
      var isSqlFileProtected;

      var isSqlProtected =
        ENV_GROUPS.sqlSources.includes(node.entity.type) &&
          (node._isSqlProtected ||
            !!protectedHash[ENV_TYPE_CONVERSION.kSQL]);

      var isOracleProtected =
        ENV_GROUPS.oracleSources.includes(node.entity.type) &&
          (node._isOracleProtected ||
            !!protectedHash[ENV_TYPE_CONVERSION.kOracle]);

      var isBlockProtected =
        node.entity.type === ENV_TYPE_CONVERSION.kPhysical &&
          (node._isBlockProtected ||
            !!protectedHash[ENV_TYPE_CONVERSION.kPhysical]);

      var isPhysicalFileProtected =  node.entity.type === ENV_TYPE_CONVERSION.kPhysicalFiles &&
        (node._isPhysicalFileProtected ||
        !!protectedHash[ENV_TYPE_CONVERSION.kPhysicalFiles]);

      if (node._isLeaf) {
        isSqlFileProtected =
          ENV_GROUPS.sqlSources.includes(node.entity.type) &&
            (node._isSqlFileProtected ||
              (isSqlProtected && !isBlockProtected));

        isDbProtected =
          ENV_GROUPS.databaseSources.some(
            function eachDBSourceType(type) {
              return !!protectedHash[type];
            }
          );

        isProtected = node._isProtected ||
          ENV_GROUPS.databaseSources
            .includes(node.entity.type) ?

            // If any DB source has a protection job, it's protected.
            isDbProtected :

            // Otherwise, true if any other entity has any protection jobs, but
            // not DB jobs.
            !isDbProtected && !isEmpty(protectedHash);
      }

      return assign(node, {
        _isProtected: isProtected,
        _isDbProtected: isDbProtected,
        _isSqlProtected: isSqlProtected,
        _isSqlFileProtected: isSqlFileProtected,
        _isOracleProtected: isOracleProtected,
        _isBlockProtected: isBlockProtected,
        _isPhysicalFileProtected: isPhysicalFileProtected,

        /*
         * This section is based on the matrix at https://goo.gl/JDm6L4
         */
        // True if this is a valid SQL source and not protected by another SQL
        // job.
        _canSqlFileProtect: !isSqlProtected &&
          ENV_GROUPS.sqlSources.includes(node.entity.type),
        _canSqlVolumeProtect:
          // If this node is physical
          node.entity.type === ENV_TYPE_CONVERSION.kPhysical ?
            // True if it's not already protected by a SQL job nor block
            // (physical) job.
            (!isSqlProtected && !isBlockProtected) :

            // Otherwise, true If it's a SQL host (no meaningful restrictions)
            ENV_GROUPS.sqlHosts.includes(node.entity.type),

        // True if not already protected by an Oracle job and is a valid Oracle
        // source.
        _canProtectOracle: !isOracleProtected &&
          ENV_GROUPS.oracleSources.includes(node.entity.type),

        _isOlderOracle: node._rootEnvironment === 'kOracle' &&
          _isOlderOracle(node),

        _canBlockProtect: !isBlockProtected,

        // `_canProtect` logic is far too complex for this decorator and depends
        // on unavailable external info. Must be done at controller level.
      });
    }

    /**
     * Indicates if the node is running an older oracle version (< 11.2.0.3)
     *
     * @method   _isOlderOracle
     * @return   {boolean}   True only if the node is running
     * older oracle version (< 11.2.0.3), False otherwise.
     */
    function _isOlderOracle(node) {
      if (!node._envProtectionSource.version) {
        return false;
      }
      var version = node._envProtectionSource.version;
      version = parseInt(version.split('.').join(''), 10);
      return (version < 11203);
    }

    /**
     * Generates a lookup map by PJ type from the aggregatedProtectedInfoVec
     * array on an EntityHierarchy node. Skips data not indicating protected
     * entities.
     *
     * @method   _generateAggregatedProtectedInfoHash
     * @param    {object}   node   The node
     * @return   {Object}   The hash map by PJ type
     */
    function _generateAggregatedProtectedInfoHash(node) {
      return (node.aggregatedProtectedInfoVec || []).reduce(
        function infoVecReducer(accumulator, infoVec) {
          // If we haven't hashed it yet AND the infoVec indicates protected
          // entities, add it to the hash.
          if (!accumulator[infoVec.type] && infoVec.numEntities) {
            accumulator[infoVec.type] = infoVec;
          }

          return accumulator;
        },
        {}
      );
    }
  }
})(angular);
