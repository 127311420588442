import { extend } from 'lodash-es';
import { reduce } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Cloud Edition View Page

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('cloudEditionView', {
      bindings: {
        // @type {String}  The id of the cloudEdition
        id: '<',
      },
      templateUrl: 'app/platform/cloud-edition-manager/view.html',
      controller: 'cloudEditionViewParentController',
    })
    .component('cloudEditionViewDetails', {
      bindings: {
        // @type {String}  The id of the cloudEdition
        id: '<',

        // @type {Object}  The cloudEdition Object
        cloudEdition: '<',
      },
      templateUrl: 'app/platform/cloud-edition-manager/details.html',
      controller: 'cloudEditionViewController',
    })
    .component('cloudEditionViewLogs', {
      bindings: {
        // @type {String}  The id of the cloudEdition
        id: '<',

        // @type {Object}  The cloudEdition Object
        cloudEdition: '<',
      },
      templateUrl: 'app/platform/cloud-edition-manager/logs.html',
      controller: 'cloudEditionViewController',
    })
    .controller(
      'cloudEditionViewParentController', cloudEditionViewParentCtrlFn)
    .controller('cloudEditionViewController', cloudEditionViewCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:cloudEditionView
   * @function
   *
   * @description
   * View the details of a deployed Cloud Edition
   */
  function cloudEditionViewParentCtrlFn($state, evalAJAX,
    CloudEditionManagerService) {
    var $ctrl = this;

    assign($ctrl, {
      // properties
      isLoading: true,

      // methods
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   onInit
     */
    function $onInit() {
      var tabParams;

      CloudEditionManagerService.getCloudEditionJob($ctrl.id)
        .then(function gotCloudEditionJob(resp) {
          $ctrl.cloudEdition = resp;

          if (get(resp.deploymentConfig, 'tags.length')) {
            // merge individual tag objects together
            resp.deploymentConfig._tags =
              reduce(resp.deploymentConfig.tags, extend);
            resp.deploymentConfig.tagKeys =
              Object.keys(resp.deploymentConfig._tags);
          }
          $ctrl.cloudEdition = resp;

          tabParams = {
            id: $ctrl.cloudEdition.jobId,
            cloudEdition: $ctrl.cloudEdition,
          };

          $ctrl.cloudEditionTabsConfig = [{
            'headingKey': 'cloudEdition.cloudDeploymentDetails',
            'route': 'cloud-edition-view.details',
            'params': tabParams,
          }, {
            'headingKey': 'cloudEdition.cloudDeploymentLog',
            'route': 'cloud-edition-view.logs',
            'params': tabParams,
          }];

          // this is needed to pass correct params to child states
          if ($state.current.name === 'cloud-edition-view.details') {
            $state.go('cloud-edition-view.details', tabParams);
          } else if ($state.current.name === 'cloud-edition-view.logs') {
            $state.go('cloud-edition-view.logs', tabParams);
          }
        }, evalAJAX.errorMessage)
        .finally(function getCloudEditionJobFinally() {
          $ctrl.isLoading = false;
        });
    }
  }

  function cloudEditionViewCtrlFn($state, $q, CloudEditionManagerService,
    PollTaskStatus) {

    var $ctrl = this;
    var isDoneDeferred = $q.defer();

    assign($ctrl, {
      // properties

      // methods
      $onInit: $onInit,
      $onDestroy: $onDestroy,
      getCloudEditionIconClass:
        CloudEditionManagerService.getCloudEditionIconClass,
    });

    /**
     * Activate the controller
     *
     * @method   onInit
     */
    function $onInit() {
      if ($state.current.name === 'cloud-edition-view.logs') {
        PollTaskStatus.createPoller({
          interval: 30,
          isDonePromise: isDoneDeferred.promise,
          iteratorFn: getData,
        });
      }
    }

    /**
     * Fetch the cloudEdition Job data for poll update
     *
     * @method   getData
     */
    function getData() {
      return CloudEditionManagerService.getCloudEditionJob($ctrl.id)
        .then(function gotCloudEditionJob(resp) {
          $ctrl.cloudEdition = resp;
        });
    }

    /**
     * on component destroy end polling for data.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      isDoneDeferred.resolve();
    }
  }

})(angular);