<!-- Template Selector Component -->
<div>
  <div class="template-container">
    <div class="template-header-row">
      <h4 class="title margin-right">{{'viewTemplates' | translate}}</h4>
      <button mat-icon-button
        *ngIf="hasTemplateViewPermission"
        type="button"
        class="create-template"
        cogDataId="create-viewby-template-btn"
        [attr.aria-label]="'createCustomTemplate' | translate"
        [matTooltip]="'createCustomTemplate' | translate"
        uiSref="ng-templates.modify">
        <cog-icon shape="add"></cog-icon>
      </button>
      <button mat-icon-button
        type="button"
        class="templates-help"
        cogDataId="templates-help"
        [attr.aria-label]="'templatesHelp' | translate"
        [matTooltip]="'learnAboutViewTemplates' | translate"
        cohHelpLink
        helpKeyInline="view_templates">
        <cog-icon shape="helix:help"></cog-icon>
      </button>
      <button mat-icon-button
        cogDataId="close-template-selector"
        mat-dialog-close>
        <cog-icon shape="clear"></cog-icon>
      </button>
    </div>
  </div>

  <cog-spinner *ngIf="loading"></cog-spinner>

  <div *ngIf="!loading">
    <ng-container *ngIf="hasTemplateViewPermission">
      <mat-tab-group class="margin-top-sm"
        dynamicHeight
        animationDuration="0ms"
        [(selectedIndex)]="activeTabIndex">
        <mat-tab [label]="'predefined' | translate"
          cogDataId="default-template-btn-toggle">
          <ng-container
            *ngTemplateOutlet="treeTemplate; context: {
              dataSource: defaultTemplates,
              control: defaultTreeControl,
              defaultTemplate: true}">
          </ng-container>
        </mat-tab>
        <mat-tab [label]="'custom' | translate"
          cogDataId="custom-template-btn-toggle">
          <ng-container *ngIf="noCustomTemplates">
            <coh-blank-card type="file-services-dashboard"
              container="dashboard"
              message="">
              <h3>{{ 'views.noCustomTemplates' | translate }}</h3>
              <p class="margin-bottom text-center">{{ 'views.noCustomTemplatesHint' | translate }}</p>
              <button cogDataId="create-custom-template-blank-btn"
                mat-flat-button
                color="primary"
                uiSref="ng-templates.modify">
                {{ 'createCustomTemplate' | translate }}
              </button>
            </coh-blank-card>
          </ng-container>

          <ng-container *ngIf="!noCustomTemplates">
            <ng-container
              *ngTemplateOutlet="treeTemplate; context: {
                dataSource: customTemplates,
                control: customTemplateTreeControl,
                defaultTemplate: false
              }">
            </ng-container>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <!-- If feature flag is off for custom view templates, only show the
      available templates -->
    <ng-container *ngIf="!hasTemplateViewPermission">
      <ng-container
        *ngTemplateOutlet="treeTemplate; context: {
          dataSource: defaultTemplates,
          control: defaultTreeControl,
          defaultTemplate: true}">
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #treeTemplate
  let-source="dataSource"
  let-dataTreeControl="control"
  let-defaultTemplate="defaultTemplate">
  <mat-tree [dataSource]="source" [treeControl]="dataTreeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node"
      class="leaf-node"
      [class.hidden]="defaultTemplate ? !node.template.isDefault : node.template.isDefault">
      <div class="col-xs-12 no-padding"
        matTooltip="{{'views.create.helios.noObjectServices' | translate}}"
        [matTooltipDisabled]="!isDisabled(node)">
        <div class="template-select-btn"
          cogDataId="create-view-from-template-{{node.template.viewParams.category}}-{{node.template.name}}"
          (click)="openViewDialog(node.template)"
          [class.disabled]="isDisabled(node)"
          [matTooltipDisabled]="isDisabled(node)"
          [matTooltip]="getTemplateName(node.template)">
          {{getTemplateName(node.template)}}
          <ng-container
            [class.hidden]="!node.template?.descKey"
            *ngTemplateOutlet="metaTemplate; context:{$implicit: node.template}">
          </ng-container>
        </div>
      </div>

      <button mat-icon-button
        type="button"
        class="more-options-btn"
        cogDataId="more-options-{{node.template.name}}"
        *ngIf="!node.template.isDefault"
        [matMenuTriggerFor]="menu"
        [matTooltip]="'moreOptions' | translate">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item
          (click)="editTemplate(node.template)"
          cogDataId="edit-{{node.template.name}}">
          <span class="padding-left">{{'edit' | translate}}</span>
        </button>

        <button mat-menu-item
          cogDataId="delete-{{node.template.name}}"
          (click)="deleteTemplate(node.template)">
          <span class="padding-left">{{'delete' | translate}}</span>
        </button>
      </mat-menu>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      class="parent-node"
      [class.hidden]="!showParentNode(node, defaultTemplate)"
      *matTreeNodeDef="let node; when: hasChild">
      <span class="view-category">{{viewCategoriesMap[node.viewCategory] | translate}}</span>
    </mat-tree-node>
  </mat-tree>
</ng-template>

<ng-template #metaTemplate let-info>
  <ul *ngIf="info?.descKey" class="meta-info no-margin-left padding-top-xs">
    <li translate>{{info?.descKey}}</li>
  </ul>
</ng-template>
