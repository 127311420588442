import { noop } from 'lodash-es';
import { set } from 'lodash-es';
// COMPONENT:  Cloud Retrieval: Search Details Controller

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloud-retrieval';

  angular
    .module(moduleName)
    .component('cloudSearchDetails', {
      controller: CloudRetrievalSearchDetailsControllerFn,
      templateUrl: 'app/protection/cloud-retrieval/cloud-retrieval-search-details.html',
    });


  /**
   * CloudRetrievalSearchDetails Controller
   **************************************************************************/
  function CloudRetrievalSearchDetailsControllerFn(
    $scope, $state, $timeout, evalAJAX, SearchService, $q, ExternalTargetService,
    RestoreService, PollTaskStatus, cModal, cMessage, ViewBoxService,
    DateRangerService, FEATURE_FLAGS, ENUM_ENV_TYPE, ENUM_ICON_TYPE_MAPPING, _) {

    /**
     * This controller.
     *
     * @type   {object}
     */
    var ctrl = this;

    /**
     * Hash of jobs (by Id) that have settings changes different from the edit
     * all settings.
     *
     * @type   {object}
     */
    var modifiedHash = {};

    /**
     * Hash map of jobs (by Id) that are selected.
     *
     * @type   {array}
     */
    var selectedHash = {};

    /**
     * Hash of unique job environments in the results.
     *
     * @type   {object}
     */
    var jobTypesHash = {};

    angular.extend(ctrl, {
      // General Template Vars
      clusters: [],
      jobTypes: [],
      pagedResults: [],
      requestArg: {},
      searchResults: [],
      viewBoxes: [],
      searchTask: {
        status: false,
      },

      // Template Methods
      changeViewBox: changeViewBox,
      editRetrievalConfig: editRetrievalConfig,
      restartSearch: restartSearch,
      selectAll: selectAll,
      startRetrieval: startRetrieval,
      stopSearch: stopSearch,
      updateModifiedHash: updateModifiedHash,
      updateSelectedHash: updateSelectedHash,
      updateSelectionCounts: updateSelectionCounts,
    });


    // METHODS
    /**
     * Activate this Controller!
     *
     * @method   activate
     */
    function activate() {
      // This isn't polled, only needs to be retrieved once.
      ViewBoxService.getViewBoxes().then(
        function viewBoxesRecieved(resp) {
          resp.unshift({
            icon: 'icn-add',
            displayKey: 'addViewBox',
          });
          ctrl.viewBoxes = resp;
        }
      );

      // Fetch job data
      getData();
    }

    /**
     * Stops a running Remote Archive Search task
     *
     * @method   stopSearch
     */
    function stopSearch() {
      // Attempt to stop this search job
      ExternalTargetService
        .stopVaultJob(ctrl.searchTask.searchJobUid)
        .then(function stopSearchSuccess() {
          cMessage.success({
            titleKey: 'cloudRetrievalSearch.searchIsOn.title',
            textKey: 'cloudRetrievalSearch.searchIsOn.text',
          });
          getData();
        }, evalAJAX.errorMessage);
    }

    /**
     * Fetch the searchJob data
     *
     * @method   getData
     * @return   {object}   Task Poller response promise to resolve with each
     *                      poll iteration's response data.
     */
    function getData() {
      var pollerConfig = {
        // Passing this allows self-termination on $destroy of this scope.
        scope: $scope,
        isDoneFn: isPollerDone,
        iteratorFn: getRemoteSearchResults,
      };


      return PollTaskStatus.createPoller(pollerConfig)
        .then(function detailsReceived() {
          ctrl.jobTypes = generateJobsFilterList();
          ctrl.clusters = generateClustersFilterList();
        });
    }

    /**
     * Determines if polling the remote search job is done by testing the
     * status..
     *
     * @method   isPollerDone
     * @param    {object}    resp   The TaskPoller's data response.
     * @return   {boolean}   True if poller done, False otherwise.
     */
    function isPollerDone(resp) {
      return !ctrl.searchTask._isRunning;
    }

    /**
     * Updates the selectedHash for the given job(s).
     *
     * @method   updateSelectedHash
     * @param    {object|array}   jobs   One or more Jobs to hash the selected
     *                                   state for.
     */
    function updateSelectedHash(jobs) {
      if (!jobs) {
        return;
      }

      [].concat(jobs).forEach(function eachJob(job) {
        if (job.$selected) {
          // Add a key+value pair for the selected job.
          selectedHash[job._id] = true;
        } else {
          // Delete the key+value pair from the hash. Setting `undefined`
          // misrepresents the selected state of this job in the hash.
          delete selectedHash[job._id];
        }
      });
    }

    /**
     * Updates the hash of job search results with modified settings.
     *
     * @method   updateModifiedHash
     * @param    {array}   jobs   The list of jobs.
     */
    function updateModifiedHash(jobs) {
      if (!jobs) {
        return;
      }

      [].concat(jobs).forEach(function eachJob(job) {
        if (job._hasCustomSettings) {
          // Add a key+value pair for the modified job settings.
          modifiedHash[job._id] = true;
        } else {
          // Delete the key+value pair from the hash. Setting `undefined`
          // misrepresents the selected state of this job's settings in the
          // hash.
          delete modifiedHash[job._id];
        }
      });
    }

    /**
     * Generate the uiSelect compatible list of Clusters from the results.
     *
     * @method   generateClustersFilterList
     * @return   {array}   The list of Clusters
     */
    function generateClustersFilterList() {
      var defaultFilter = [{
        displayKey: 'allClusters',
      }];
      return ctrl.searchResults.reduce(
        function reduceClusters(clusters, result) {
          if (!clusters[result.clusterName]) {
            clusters[result.clusterName] = true;
            clusters.push({
              displayKey: result.clusterName,
              value: result.clusterName,
            });
          }
          return clusters;
        },
        defaultFilter
      );
    }

    /**
     * Converts the JOB_TYPES hash to a ui-select friendly array of objects.
     *
     * @method   generateJobsFilterList
     * @return   {array}   The array of job types
     */
    function generateJobsFilterList() {
      var out = [{
        displayKey: 'allJobTypes',
        environment: '',
      }];

      return !ctrl.searchResults.length ?
        out :
        out.concat(ctrl.searchResults.reduce(
          function eachJob(output, job, ii) {
            // If this job type is already known, move on to find a unique one.
            if (jobTypesHash[job.environment]) {
              return output;
            }

            jobTypesHash[job.environment] = true;

            return output.concat({
              icon: ['icn-type-', ENUM_ICON_TYPE_MAPPING.job[job.environment]].join(''),
              displayKey: ENUM_ENV_TYPE[job.environment],
              environment: job.environment,
            });
          },
          []
        ));
    }

    /**
     * When triggered, this rebuilds the tally of selected objects (broken up by
     * job type).
     *
     * @method   updateSelectionCounts
     * @param    {Boolean}    isFiltered  whether a filter has cause this
     */
    function updateSelectionCounts(isFiltered) {
      // if a filter is applied, allow the table to update first before
      // recalculating the counts
      $timeout(function updateSelectionCountsAfterTimeout() {
        var selectedJobs = [];

        // if filter is applied, unselect "Select All" if it is already selected
        if (isFiltered && ctrl.selectAllCheckbox) {
          ctrl.selectAll(false, true);
        }

        ctrl.selection = ctrl.filteredResults.reduce(
          function eachResult(selections, result) {
            var env = result.environment;

            // Using $total so ngRepeat ignores it.
            selections.$total = selections.$total || 0;
            selections[env] = selections[env] || 0;

            if (result.$selected) {
              selectedJobs.push(result);
              selections.$total++;
              selections[env]++;
            }

            return selections;
          },
          {}
        );

        // using $knownJobs so angular will ignore it when looping
        // over this hash.
        ctrl.selection.$knownJobs = generateKnownJobsList(selectedJobs);
      }, 500);
    }

    /**
     * Toggles selection on all search results to the passed boolean. Uses
     * $selected so Angular will ignore it in loops, etc.
     *
     * @method   selectAll
     * @param    {boolean}   isChecked      True to select all, False to
     *                                      deselect all.
     * @param    {boolean}   ignoreFilters  Always select the filtered result
     *                                      set, unless this flag is True,
     *                                      in which case select all results
     */
    function selectAll(isChecked, ignoreFilters) {
      // 'Select All' should apply only to the filtered result set currently
      // being displayed. Now, in case 'Select All' is applied and the filter
      // is modified, the old selection should be reset. Hence, the need of
      // 'ignoreFilters'. When that flag is true, the previous selection is
      // reset over the entire list, ignoring the filters
      var resultsToSelect = ignoreFilters ? 'searchResults' : 'filteredResults';

      ctrl[resultsToSelect].forEach(function eachResult(result) {
        result.$selected = !!isChecked;
        updateSelectedHash(result);
      });

      updateSelectionCounts();

      // If this function was invoked because of a filter change and not by
      // clicking on "Select All", uncheck the "Select All" checkbox explicitly.
      if (!isChecked && ctrl.selectAllCheckbox) {
        ctrl.selectAllCheckbox = false;
      }
    }

    /**
     * Generates getRemoteSearchResults query params from given stateParams.
     *
     * @method   transformStateParams
     * @return   {object}   The generated object.
     */
    function transformStateParams() {
      // Convert these to integers because url params are always strings
      return {
        clusterId: +$state.params.clusterId,
        clusterIncarnationId: +$state.params.clusterIncarnationId,
        searchJobId: +$state.params.taskId,
      };
    }

    /**
     * Fetches the remote search results from the server.
     *
     * @method   getRemoteSearchResults
     * @return   {object}   Promise to resolve with the server's results
     *                      response. Full server response if error.
     */
    function getRemoteSearchResults() {
      var params = transformStateParams();
      var promises;

      promises = {
        jobDetails: getSearchJobDetails(),
        jobResults: SearchService.getRemoteVaultSearchResults(params)
      };

      return $q.all(promises).then(
        function searchReceived(responses) {
          ctrl.searchTask = responses.jobDetails;
          ctrl.searchTask.jobResults = responses.jobResults;
          ctrl.searchTask._selectedDateRange =
            DateRangerService.generateRangeObject(
              responses.jobResults.startTimeUsecs,
              responses.jobResults.endTimeUsecs
            );

            // Remove snapshot info for Oracle if feature flag is disabled.
            (responses.jobResults.protectionJobs || []).forEach(
              function checkSnapshot(job) {
                if (job.environment === 'kOracle' &&
                  !FEATURE_FLAGS.oracleCloudSearchDownloadSnapshot) {
                  job._snapshot = undefined;
                }
            });

          ctrl.searchResults = responses.jobResults.protectionJobs;
          _fetchMoreResults(ctrl.searchTask.jobResults);
        },
        evalAJAX.errorMessage
      )
      .finally(function dataFinally() {
        ctrl.isDataReady = true;
      });
    }

    /**
     * The default limit for number of job results returned by backend is 250.
     * If there are more jobs available, backend returns a cookie indicating
     * the same. Use the cookie to make additional request for the missing jobs.
     *
     * @method _fetchMoreResults
     * @param jobResults the jobResults API response which may contain the cookie
     */
    function _fetchMoreResults(jobResults) {
      if (jobResults.cookie) {
        SearchService.getRemoteVaultSearchResults(
          Object.assign(transformStateParams(), {
            cookie: jobResults.cookie,
          })
        ).then(resp => {
          ctrl.searchResults =
            ctrl.searchResults.concat(resp.protectionJobs);
          _fetchMoreResults(resp);
        });
      }
    }

    /**
     * Fetches the SearchJob details data.
     *
     * @method   getSearchJobDetails
     * @return   {object}   Promsie to resovle with the requested data, or full
     *                      server response if error.
     */
    function getSearchJobDetails() {
      var searchJobUid = transformStateParams();
      // Convert this to a searchJobUid.
      searchJobUid.id = searchJobUid.searchJobId;
      searchJobUid.searchJobId = undefined;

      return SearchService.getRemoteVaultSearch(searchJobUid.id);
    }

    /**
     * Generates a list of known jobs. This essentially filters out jobs with no
     * _jobSummary (retrieved during server response transformation).
     *
     * @method   generateKnownJobsList
     * @param    {array}   jobs   The jobs
     * @return   {array}   The filtered list of jobs we know about.
     */
    function generateKnownJobsList(jobs) {
      return jobs.reduce(
        function jobReducer(knownJobs, job) {
          if (job._jobSummary) {
            knownJobs.push(job);
          }
          return knownJobs;
        },
        []
      );
    }

    /**
     * Opens a modal to edit retrieval config for individual result objects AND
     * a collection of results objects.
     *
     * @method   editRetrievalConfig
     * @param    {object}   taskObject   The taskObject to configure the
     *                                   settings of.
     * @return   {object}   Promsie to resolve with the updated settings.
     */
    function editRetrievalConfig(taskObject) {
      var isJobObject = !!taskObject.jobUid;

      return RestoreService.cloudRestoreSettingsModal(taskObject, modifiedHash)
        .then(
          function settingsReceived(resp) {
            // Apply the updated settings.
            angular.extend(taskObject || ctrl.searchTask, {
              _hasCustomSettings: !angular.equals(taskObject, resp),
              _selectedDateRange: resp._selectedDateRange,
              _snapshot: resp._snapshot,
            });

            if (isJobObject) {
              // An individual job's settings have been modified. Update the
              // hash to reflect this change.
              updateModifiedHash(taskObject);
            } else {
              // An Edit Selected modal will overwrite all individual job
              // settings.
              applyMasterSettingsToJobs();
            }
          }
        );
    }

    /**
     * Applies the master settings ot all selected jobs in the restore task
     * settings.
     *
     * @method   applyMasterSettingsToJobs
     */
    function applyMasterSettingsToJobs() {
      var hasSelectedDateRange = !!ctrl.searchTask._selectedDateRange;
      var hasSelectedSnapshot = !!ctrl.searchTask._snapshot;

      ctrl.searchResults.forEach(function eachJob(job) {
        var hideSnapshot = job.environment === 'kOracle' &&
          !FEATURE_FLAGS.oracleCloudSearchDownloadSnapshot;
        job._hasCustomSettings = false;

        // Apply the snapshot range selection option, if selected.
        job._selectedDateRange = hasSelectedDateRange &&
          angular.copy(ctrl.searchTask._selectedDateRange);

        // Apply the restore point selection option, if selected.
        job._snapshot = hasSelectedSnapshot &&
          !hideSnapshot &&
          job.protectionJobRuns[0];

        updateModifiedHash(job);
      });
    }

    /**
     * Starts a retrieval.
     *
     * @method   startRetrieval
     * @param    {object}   form   ngForm controller
     */
    function startRetrieval(form) {
      var modalConfig = {
        controller: PostSubmitModalControllerFn,
        controllerAs: 'ctrl',
        templateUrl: 'app/protection/cloud-retrieval/post-submit-modal.html',
        resolve: {},
      };

      var modalOptions = {
        closeButtonKey: false,
        titleKey: 'cloudRetrievalSearchDetails.postSubmitModalTitle',
      };

      ctrl.requestArg = generateRequestArg();

      if (!form.$valid) {
        return;
      }

      if (ctrl.retrievalOption) {
        set(ctrl.requestArg, 'glacierRetrievalType',
          ctrl.retrievalOption);
      }

      RestoreService
        .createRemoteRestoreTask(ctrl.requestArg)
        .then(
          function restoreTaskCreated(resp) {
            modalConfig.resolve.restoreTask = resp;
            modalConfig.resolve.requestArg = ctrl.requestArg;
            return cModal.standardModal(modalConfig, modalOptions)
              .finally(function allDone() {
                $state.go('cloud-retrieval.retrievals');
              });
          },
          evalAJAX.errorMessage
        );

    }

    /**
     * Generates the restoreArg pass to Icebox to create restore tasks.
     *
     * @method   generateRequestArg
     * @return   {object}   The generated requestArg.
     */
    function generateRequestArg() {
      return {
        viewBox: ctrl.requestArg.viewBox,
        taskName: RestoreService.getDefaultTaskName('recover', 'remote-jobs'),
        searchJobUid: ctrl.searchTask.searchJobUid,
        vaultId: ctrl.searchTask.vaultId,
        restoreObjects: generateRestoreObjects(),
      };
    }

    /**
     * Generates the array of RestoreObjects for the requestArg from the
     * selected search results.
     *
     * @method   generateRestoreObjects
     * @return   {array}   The list of RestoreObjects
     */
    function generateRestoreObjects() {
      return ctrl.searchResults.reduce(
        function selectedReducer(_objects, job) {
          if (job.$selected) {
            _objects.push({
              // archiveTaskUid of the selected snapshot
              archiveTaskUid: !job._snapshot ?
                undefined : job._snapshot.archiveTaskUid,
              endTimeUsecs: !job._selectedDateRange ?
                undefined : job._selectedDateRange.endDate * 1000,
              startTimeUsecs: !job._selectedDateRange ?
                undefined : job._selectedDateRange.startDate * 1000,
              remoteProtectionJobUid: job.jobUid,
              viewBoxId:
                // First try to use View Boxes specified on the job if known on
                // this Cluster, OR
                (job._jobSummary && job._jobSummary.jobDescription.viewBoxId) ||

                // Use a selected viewbox, if specified.
                (ctrl.selectedViewBox && ctrl.selectedViewBox.id),
            });
          }
          return _objects;
        },
        []
      );
    }

    /**
     * Sets the restoreArg viewBox, or opens a modal to add a new one, and then
     * sets the requestArg viewBox with the newly created view box.
     *
     * @method    changeViewBox
     * @param     {object}   [viewBox]   The selected viewBox.
     */
    function changeViewBox(viewBox) {
      // If a viewBox is passed, set the viewbox and exit.
      if (viewBox && viewBox.name) {
        ctrl.requestArg.viewBox = viewBox;
        return;
      }

      // When a viewbox isn't passed, we need to create a new one.
      ViewBoxService.newViewBoxModal().then(
        function receivedVewBox(newViewBox) {
          // Add the new viewbox to the list and select it.
          ctrl.viewBoxes.splice(1, 0, newViewBox);
          ctrl.selectedViewBox = newViewBox;
          changeViewBox(newViewBox);
        },
        noop
      );
    }

    /**
     * Restart the search with the same search params.
     *
     * @method    restartSearch
     * @param     {object}   [params]   The search params.
     */
    function restartSearch(params) {
      $state.go('cloud-retrieval-search', {queryParams: params});
    }


    // Activate!
    activate();
  }

  /* @ngInject */
  function PostSubmitModalControllerFn($scope, restoreTask, requestArg) {
    var ctrl = this;
    ctrl.restoreTask = restoreTask;
    ctrl.requestArg = requestArg;
  }

})(angular);
