import { identity } from 'lodash-es';
import { clone } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: Protected Objects Trends Report

;(function(angular) {
  'use strict';

  angular.module('C.reports')
    .controller('ProtectedObjectsHeatmapCtrl', protectedObjectsHeatmapFn)
    .component('protectedObjectsTrends', {
      bindings: {
        $transition$: '<',
      },
      controller: 'ProtectedObjectsHeatmapCtrl',
      templateUrl:
        'app/reports/objects/protection-heatmap/protection-heatmap.html',
    });

  function protectedObjectsHeatmapFn(_, $state, ReportsService, ReportsUtil,
    cReportsControlsUtil, TenantService, evalAJAX, moment) {

    var $ctrl = this;

    var _statLabelLookup = {
      failed: 'error',
      successful: 'success',
      warning: 'warning',
      cancelled: 'canceled',
      total: 'totalRuns',
      objectCount: 'totalObjects',
    };

    assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,

      // Controller methods
      closeTrendRuns: closeTrendRuns,
      getData: getData,
      pipeTable: pipeTable,
      toggleTrendRuns: toggleTrendRuns,

      // Controller data
      loadingData: true,
      objects: [],
      stTableObjects: [],
      resultsPerPage: 100,
    });

    /**
     * Controller initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      var stateParams = $ctrl.$transition$.params();
      var defaultFilters = {
        rollup: stateParams.rollup || 'day',
        timezone: stateParams.timezone || moment.tz.guess(),
      };

      // TODO: Can this be abstracted to cReportsControlsUtil so other reports
      // can make use of it?
      if (stateParams.startTimeUsecs && stateParams.endTimeUsecs) {
        defaultFilters.timeObject = {
          from: moment(stateParams.startTimeUsecs / 1000).startOf('day'),
          until: moment(stateParams.endTimeUsecs / 1000).endOf('day'),
        };
      }

      $ctrl.objTrendsConfig =
        ReportsUtil.getReportConfig('kProtectedObjectsTrendsReport')();

      cReportsControlsUtil.getDefaultFilters(defaultFilters);

      getData(cReportsControlsUtil.filters);
    }

    /**
     * Requests the report data via Service.
     *
     * @method   getData
     * @param    {object}    filters       The filters
     * @param    {boolean}   [formatCsv]   Indicates if report should be CSV
     * @return   {object}    Promise to resolve request for report data.
     */
    function getData(filters, formatCsv) {
      var params = _getParams(filters);

      if (formatCsv) {
        params.outputFormat = 'csv';
      }

      $ctrl.objects.length = 0;
      $ctrl.loadingData = true;
      $ctrl.params = params;

      return ReportsService.getProtectedObjectsTrends(params).then(
        function getReportSuccess(objectData) {
          $ctrl.objects = objectData._trendObjects;
          $ctrl.trendSlots = objectData._trendSlots;
          $ctrl.aggregateStatListConfig =
            _getAggregateStatListConfig(objectData._trendAggregates);
        },
        evalAJAX.errorMessage
      ).finally(
        function getReportFinally() {
          $ctrl.loadingData = false;
        }
      );
    }

    /**
     * Splices the objects into $ctrl.stTableObjects for display purposes.
     * Leaving this up to stTable results in performance issues as it copies
     * the array before slicing leaving a window of opportunity for the renderer
     * to attempt render all of the objects. By self managing this splicing we
     * prevent this performance issue.
     *
     * @method     pipeTable
     * @param      {object}  tableState The smart table provided tableState,
     *                                  includes pagination, search and sort
     *                                  information
     * @param      {object}  tableCtrl  The smart table Controller
     */
    function pipeTable(tableState, tableCtrl) {
      // Make a copy of objects so sorting is not permenant and user can
      // return to initial sort order.
      var outputItems = clone($ctrl.objects);
      var pagination = tableState.pagination;

      // The starting index of the first object to be displayed.
      var startIndex = pagination.start || 0;

      // Number of objects to display per page.
      var numPerPage = pagination.number || $ctrl.resultsPerPage;

      // If sorting is requested, sort the items.
      if (tableState.sort.predicate && tableState.sort.predicate === 'name') {
        outputItems.sort(
          function sortItems(itemA, itemB) {
            if (itemA.name < itemB.name) {
              return tableState.sort.reverse ? 1 : -1;
            }
            if (itemA.name > itemB.name) {
              return tableState.sort.reverse ? -1 : 1;
            }
            return 0;
          }
        );
      }

      // Slice the full set to get the objects for display.
      $ctrl.stTableObjects =
        outputItems.slice(startIndex, startIndex + numPerPage);

      // Update total number of objects.
      pagination.totalItemCount = $ctrl.objects.length;

      // Update the number of pages.
      tableState.pagination.numberOfPages =
        Math.ceil($ctrl.objects.length / numPerPage);
    }

    /**
     * Returns a cStatList compatible configuration object based on provided
     * aggregate protection information.
     *
     * @method _getAggregateStatListConfig
     * @param {object} trendAggregates   aggregate info regarding objects
     */
    function _getAggregateStatListConfig(trendAggregates) {
      return map(trendAggregates, function aggregate(val, key) {
        var itemConfig = {
          value: val,
          label: _statLabelLookup[key] || key,
          classes: [],
        };

        switch (key) {
          case 'successful':
            itemConfig.classes.push('status-ok');
            break;

          case 'failed':
            itemConfig.classes.push('status-critical');
            break;

          case 'warning':
            itemConfig.classes.push('status-warn');
            break;
        }

        return val > 0 ? itemConfig : undefined;
      }).filter(identity);
     }

    /**
     *
     * @param {object} object   The object the trend belongs to
     * @param {object} trend    The trend for which to load runs
     */
    function toggleTrendRuns(object, trend) {
      var filters = cReportsControlsUtil.filters;
      var momentRollup = filters.rollup === 'week' ? 'isoWeek' : filters.rollup;
      var params;


      // Don't attempt display of empty trend block's details.
      if (!trend) { return; }

      params = {
        protectionSourceIds: [object.id],
        startTimeUsecs: moment(trend.trendStartTimeUsecs / 1000)
          .tz(filters.timezone).valueOf() * 1000,
        endTimeUsecs: moment(trend.trendStartTimeUsecs / 1000)
          .tz(filters.timezone).endOf(momentRollup).valueOf() * 1000,
      };

      $ctrl.closeTrendRuns(object);

      object._showTrend = trend;
      object._loadingDetails = true;

      ReportsService.getSourcesJobsRun(params).then(
        function getRuns(runsByObject) {
          // TODO: associate this with the trend so it can be used as cache.
          object._runDetails = runsByObject[0];
        },
        evalAJAX.errorMessage
      ).finally(function getObjDetailsFinally() {
        object._loadingDetails = false;
      });
    }

    /**
     * Closes the provided trend object's run detail page (by way of clearing
     * the detail decoration properties).
     *
     * @method closeTrendRuns
     * @param {object} object   The trend object to close run details for
     */
    function closeTrendRuns(object) {
      object._showTrend = undefined;
      object._runDetails = undefined;
    }

    /**
     * returns params object for api call
     *
     * @method   _getParams
     * @param     {object}    filters    filters from c-reports-controls
     * @return    {object}               filter values to be passed to API
     */
    function _getParams(filters) {
      var params = {
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
        rollup: filters.rollup,
        timezone: filters.timezone,
        protectedObjectIds: filters.protectedObjectIds,
        environments: get(filters, 'objectType.enum'),
        registeredSourceId: filters.registeredSourceId,
        jobIds: filters.jobId ? [].concat(filters.jobId) : undefined,
      };

      params = TenantService.extendWithTenantParams(
        params, filters.tenantIds);

      $state.go('.', params);

      return params;
    }

  }

})(angular);
