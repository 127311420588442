import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Recovery Objects Container

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.recoveryObjects', [])
    .controller('RecoveryObjectsCtrl', recoveryObjectsCtrlFn)
    .component('recoveryObjects', {
      bindings: {
        // @type   {objects[]}   Array containing the objects to be recovered.
        recoveryObjects: '=',

        // @type   {object}   Recovery task object.
        task: '=',

        // @type   {object}   Specifies teh search type for recovery.
        //                    Refer OFFICE365_SEARCH_TYPE for details.
        searchItemType: '<',
      },
      templateUrl:
        'app/protection/recovery/common/recovery-objects/recovery-objects.html',
      controller: 'RecoveryObjectsCtrl',
    });

  function recoveryObjectsCtrlFn(_, SlideModalService, $state,
    OFFICE365_SEARCH_TYPE) {
    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,

      // Methods exposed to template.
      navigateState: navigateState,
      selectRestorePoint: selectRestorePoint,
    });

    /**
     * @method   $onInit
     *
     * Component initialization hook.
     */
    function $onInit() {
      // TODO(Tauseef): Add logic to initialize restore task if needed.
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method   selectRestorePoint
     * @param    {object}   entity   The search result entity in question
     */
    function selectRestorePoint(entity) {
      var modalOpts = {
        component: 'snapshotSelector',
        size: 'lg',
        resolve: {
          entity: function getEntity() {
            return cloneDeep(entity);
          },
        },
      };

      SlideModalService.newModal(modalOpts).then(
        function assignNewRestorePoint(snapshotVersion) {
          entity._snapshot = snapshotVersion;
        }
      );
    }

    /**
     * Navigates to teh previous state preserving the cart selection.
     */
    function navigateState() {
      switch($ctrl.searchItemType) {
        // Mailbox state.
        case OFFICE365_SEARCH_TYPE.kMailboxSearch:
        case OFFICE365_SEARCH_TYPE.kEmailSearch:
          $state.go('recover-office365.search.mailboxes', {
            shouldPreserveCart: true,
          });
          break;

        // OneDrive state.
        case OFFICE365_SEARCH_TYPE.kOneDriveSearch:
        case OFFICE365_SEARCH_TYPE.kOneDriveBrowse:
        case OFFICE365_SEARCH_TYPE.kOneDriveDocumentSearch:
          $state.go('recover-office365.search.onedrives', {
            shouldPreserveCart: true,
            isSharePointSearch: false,
          });
          break;

        // SharePoint State.
        case OFFICE365_SEARCH_TYPE.kSharePointSiteSearch:
        case OFFICE365_SEARCH_TYPE.kSharePointSiteBrowse:
        case OFFICE365_SEARCH_TYPE.kSharePointSiteDocumentSearch:
          $state.go('recover-office365.search.sharePoint', {
            shouldPreserveCart: true,
            isSharePointSearch: true,
          });
          break;

        // Default to Mailbox search without preserving cart.
        default:
          $state.go('recover-office365.search.mailboxes', {
            shouldPreserveCart: false,
          });
          break;
      }
    }
  }
})(angular);