import { get } from 'lodash-es';
// Module: Access Management - View

/*
 * NOTE: The name Principal is a generic reference to any user or group.
 * This term comes from the naming convention in the API.
 */

;(function(angular) {
  'use strict';

  angular
    .module('C.accessManagement')
    .controller('viewPrincipalController', viewPrincipalControllerFn);

  // pageConfig is passed from state or model resolver and $uibModalInstance
  // will be mocked in case of page view
  function viewPrincipalControllerFn($state, $q, $rootScope, $log, $translate,
    UserService, GroupService, SourceService, PubSourceService, ClusterService,
    evalAJAX, cMessage, cModal, FEATURE_FLAGS, pageConfig, $uibModalInstance) {

    var $ctrl = this;

    var primaryTag = ' (' + $translate.instant('primary') + ')';

    angular.extend($ctrl, {
      gates: {},
      parentSources: [],
      principal: {},
      restrictedParentSources: {},
      rolesHash: {},
      pageConfig: pageConfig,
      isS3Enabled: FEATURE_FLAGS.
        enableS3ViewsForTenants || !UserService.isTenantUser(),

      // Scope Methods
      $onInit: $onInit,
      cancel: cancel,
      clipboardMessage: clipboardMessage,
      deletePrincipals: deletePrincipals,
      editPrincipal: editPrincipal,
      isNotDeletable: isNotDeletable,
      isNotEditable: isNotEditable,
      onClickGenNewSecretKey: onClickGenNewSecretKey,
    });

    /**
     * Initialization method for this controller
     *
     * @method     init
     */
    function $onInit() {
      var promises = {
        roles: UserService.getAllRoles(),
      };

      $ctrl.gates.loading = true;

      switch(true) {
        case ($ctrl.pageConfig.type === 'user'):
          promises.principal = UserService.getUser({
            usernames: $ctrl.pageConfig.name,
            domain: $ctrl.pageConfig.domain,
            _sid: $ctrl.pageConfig.sid,
          });
          break;
        case ($ctrl.pageConfig.type === 'group'):
          promises.principal = GroupService.getGroup($ctrl.pageConfig);
          break;
        default:
          // No valid data. Return to list.
          cancel();
      }

      $q.all(promises).then(
        function getAllSuccess(responses) {
          _processRoles(responses.roles);
          $ctrl.principal = _transformPrincipal(responses.principal);

          $ctrl.canManageS3Keys = $ctrl.principal._isLoggedInUser ||
            $rootScope.user.privs.MANAGE_S3_KEY;

          // Access denied if:
          // 1. Principal is not the logged-in User AND
          // 2. User does not have PRINCIPAL_MODIFY/MANAGE_S3_KEY privilege OR
          // 3. loggedIn User is of a Tenant and this is built-in principal
          if (!$ctrl.principal._isLoggedInUser &&
            (!$rootScope.user.privs.PRINCIPAL_MODIFY &&
            !$rootScope.user.privs.MANAGE_S3_KEY) ||
            ($rootScope.isTenantUser() && $ctrl.principal._isBuiltIn)) {
            $log.warn($rootScope.user.username +
              ' attempted to view User profile for ' +
              $ctrl.principal.username + '.');
            return $state.go('access-management');
          }

          if ($ctrl.principal.restricted) {
            SourceService.getSources({onlyReturnOneLevel: true}).then(
              function getSourcesSuccess(sources) {
                $ctrl.parentSources = _getParentSources(sources);
                $ctrl.gates.sourcesLoaded = true;

                // Gets the restriced access sources and views.
                $ctrl.gates.loadingSources = true;
                _getRestrictedAccessSources($ctrl.principal)
                  .finally(function getProtectionSourcesFinally() {
                    $ctrl.gates.loadingSources = false;
                  });
              },
              evalAJAX.errorMessage
            );
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function getAllFinally() {
          $ctrl.gates.loading = false;
        }
      );
    }


    /**
     * goto a users list page
     *
     * @method   _returnToList
     */
    function _returnToList() {
      return $state.go('access-management.user-groups');
    }

    /**
     * goto user listing page or dismiss the modal
     *
     * @method  cancel
     */
    function cancel() {
      if ($ctrl.pageConfig.inModal) {
        $uibModalInstance.dismiss();
      } else {
        _returnToList();
      }
    }

    /**
     * Handle success cases when user is edited or deleted
     *
     * @method   onSuccess
     */
    function onSuccess() {
      if ($ctrl.pageConfig.inModal) {
        $uibModalInstance.close();
      } else {
        _returnToList();
      }
    }

    /**
     * Fills the hash of all roles.
     *
     * @param      {Array}  roles   The roles list
     */
    function _processRoles(roles) {
      roles.forEach(function processRolesForEach(role) {
        $ctrl.rolesHash[role.name] = role;
      });
    }

    /**
     * Returns a list of top-level sources.
     *
     * @param      {Object}  sourceTree    The source tree
     * @return     {Array}   List of top-level sources
     */
    function _getParentSources(sourceTree) {
      var sources = [];

      if (sourceTree.entityHierarchy &&
        sourceTree.entityHierarchy.children &&
        sourceTree.entityHierarchy.children.length) {
        sources = sourceTree.entityHierarchy.children;
      }

      // Find and store the physical parent source id so it can later be
      // injected into physical entities which do not contain it.
      sources.some(function findPhysical(source) {
        if (source.entity.type === 6) {
          $ctrl.physicalEntityParentSourceId = source.entity.id;
          return true;
        }
      });

      return sources;
    }

    /**
     * For principals with restricted access, thifor restricted access
     * principals, gets the sources that the principal has access to.
     *
     * @method     _getRestrictedAccessSources
     *
     * @param      {object}  principal  The principal
     */
    function _getRestrictedAccessSources(principal) {
      var promises = {
        newRestrictedSources: FEATURE_FLAGS.restrictedUserEnhancements ?
          PubSourceService.getRestrictedUserSources(principal.sid) :
          $q.resolve(),
        oldRestrictedSourcesAndViews:
          UserService.getProtectionSources(principal.sid),
      };

      return $q.all(promises).then(function gotSourcesAndViews(res) {
        principal._protectionSources =
          FEATURE_FLAGS.restrictedUserEnhancements ? res.newRestrictedSources :
          res.oldRestrictedSourcesAndViews[0].protectionSources;

        principal._protectionViews = res.oldRestrictedSourcesAndViews[0].views;
        _buildRestrictedParentSources();
      }, evalAJAX.errorMessage);
    }

    /**
     * Builds the list of restrictedParentSources{} so restricted access
     * objects can be displayed per parentSource
     */
    function _buildRestrictedParentSources() {

      $ctrl.principal._protectionViews.forEach(
        function loopViewsFn(view) {
          if (!$ctrl.restrictedParentSources[view.viewBoxId]) {
            $ctrl.restrictedParentSources[view.viewBoxId] = {
              id: view.viewBoxId,
              name: view.viewBoxName,
              entityType: 0,
              envType: 4,
            };
          }
        }
      );

      $ctrl.principal._protectionSources.forEach(
        function loopSourcesFn(source) {
          var parentSourceName;
          var parentSourceEntityType;

          // Parent info isn't attached to physical source entities, add it.
          if (!source.parentId && source.type === 6) {
            source.parentId = $ctrl.physicalEntityParentSourceId;
          }

          if (!$ctrl.restrictedParentSources[source.parentId]) {
            $ctrl.parentSources.some(
              function findParentSourceName(parentSource) {
                if (parentSource.entity.id === source.parentId) {
                  parentSourceName =
                    parentSource.entity[parentSource._entityKey].name;
                  parentSourceEntityType =
                    parentSource.entity[parentSource._entityKey].type;
                  return true;
                }
              }
            );

            $ctrl.restrictedParentSources[source.parentId] = {
              id: source.parentId,
              name: parentSourceName,
              entityType: parentSourceEntityType,
              envType: source.type,
            };
          }
        }
      );
    }

    /**
     * Handles transformations to the returned principal
     *
     * @method   _transformPrincipal
     */
    function _transformPrincipal(principal) {
      if (!principal) {
        cMessage.error({
          textKey:'accessEdit.messages.noUser',
        });
        return cancel();
      }

      principal.roles = principal.roles || [];
      principal.type = principal.type || $ctrl.pageConfig.type;

      // Create a new array of user-facing role names
      principal._roles = principal.roles.map(function remapRoleNames(roleId) {
        var role = $ctrl.rolesHash[roleId] || {};

        if (!role.label) {
          $log.error('Unknown role ID: ' + roleId);
        }

        return role.label || '-';
      });

      // Concat all group names into a single array.
      principal._groupNames = [];
      if (principal.primaryGroupName) {
        principal._groupNames.push(principal.primaryGroupName + primaryTag);
      }
      if (get(principal, 'additionalGroupNames.length')) {
        principal._groupNames =
          principal._groupNames.concat(principal.additionalGroupNames);
      }

      return principal;
    }

    /**
     * Opens confirmation modal before generating new S3 Secret Access Key.
     *
     * @method     onClickGenNewSecretKey
     */
    function onClickGenNewSecretKey() {
      var requestObj = {
        domain: $ctrl.principal.domain,
        username: $ctrl.principal.username,
        tenantId: $ctrl.principal.tenantId,
      };

      var options = {
        titleKey: 'users.generateNewSecretKey',
        contentKey: 'users.generateNewSecretKey.content',
        actionButtonKey: 'generateKey',
        closeButtonKey: 'cancel',
      };

      return cModal.standardModal({}, options).then(
        function generateNewKeyModalResolve() {
          $ctrl.gates.refreshingKey = true;

          return UserService.generateNewS3SecretKey(requestObj).then(
            function generateNewKeySuccess(newKey) {
              cMessage.success({
                textKey: 'generatedNewSecretAccessKey',
              });

              if (!$ctrl.principal._isLoggedInUser &&
                !$rootScope.user.privs.MANAGE_S3_KEY) {
                return;
              }

              angular.extend($ctrl.principal, {
                s3SecretKey: newKey,
              });
            },
            evalAJAX.errorMessage
          ).finally(function generateNewKeyFinally() {
            $ctrl.gates.refreshingKey = false;
          });
        }
      );
    }

    /**
     * Displays a success message to indicate that S3 Access Keys were copied to
     * the clipboard was successfully.
     *
     * @param      {String}  textKey  The translation key to display
     */
    function clipboardMessage(textKey) {
      cMessage.success({
        textKey: textKey,
      });
    }

    /**
     * Go to the edit principal view
     *
     * @method     editPrincipal
     * @param      {object}  principal  A principal object
     */
    function editPrincipal(principal) {
      if ($ctrl.pageConfig.inModal) {
        UserService.modifyUserModal(principal).then(onSuccess);
        return;
      }

      $state.go('edit-principal', {
        name: $ctrl.principal.name,
        type: $ctrl.principal.type,
        domain: $ctrl.principal.domain,
        tenantId: $ctrl.principal.tenantId,
      });
    }

    /**
     * Delete selected principal and update list
     *
     * @method     deletePrincipals
     *
     * @param      {Object}  principal  A single principal to delete.
     */
    function deletePrincipals(principal) {
      var principalsToDelete = {};
      principalsToDelete[principal.sid] = principal;

      $ctrl.gates.deleting = true;

      UserService.deletePrincipals(principalsToDelete, onSuccess).finally(
        function deletePrincipalsFinally() {
          $ctrl.gates.deleting = false;
        }
      );
    }

    /**
     * Determines whether the logged-in user may delete this principal.
     *
     * @method  isNotDeletable
     * @returns {Boolean} True if logged-in user may not delete principal.
     */
    function isNotDeletable() {
      // The principal is not already deemed undeletable OR
      return !$ctrl.principal._isDeletable ||

        // The logged-in user does not have privilege
        (!$rootScope.user || !$rootScope.user.privs.PRINCIPAL_MODIFY) ||

        // The logged in user is not authorized to edit a tenant principal.
        UserService.isNotTenantAuthorized($ctrl.principal);
    }

    /**
     * Determines whether the logged-in user may edit this principal.
     *
     * @method  isNotEditable
     * @returns {Boolean} True if logged-in user may not edit principal.
     */
    function isNotEditable() {
      // return TRUE if principal is the built-in admin and not logged-in OR
      return ($ctrl.principal._isLocalAdmin && !$ctrl.principal._isLoggedInUser) ||

        // The principal is not the logged in user and doesn't have privilege OR
        (!$ctrl.principal._isLoggedInUser && !$rootScope.user.privs.PRINCIPAL_MODIFY) ||

        // The logged in user is not authorized to edit a tenant principal.
        UserService.isNotTenantAuthorized($ctrl.principal);
    }
  }

})(angular);
