<button
  mat-button
  cogDataId="timeframe-picker-menu-trigger"
  [inheritDataId]="true"
  #timeframePickerMenuButton
  [matMenuTriggerFor]="menu">
  <div class="timeframe-picker-container">
    <cog-icon shape="query_builder" size="sm"></cog-icon>
    <span class="timeframe-label">{{ getLabel(value) }}</span>
    <cog-icon shape="arrow_drop_down"></cog-icon>
  </div>
</button>
<mat-menu #menu="matMenu">
  <cog-date-range-picker
    cogDataId="timeframe-picker-date-range-picker"
    [inheritDataId]="true"
    [value]="value"
    [timeframeOptions]="timeframeOptions"
    (changes)="selectDateRange($event)"
    [useTimeframe]="true">
  </cog-date-range-picker>
</mat-menu>
