import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, CanAccess, StateAccessMap } from 'src/app/app-module.config';

const licenseAccess: CanAccess = ctx =>
  ctx.FEATURE_FLAGS.licensingRevampEnabled && !ctx.isTenantUser && getConfigByKey(ctx.irisContext, 'settings.features.license', true);

const globalLicenseAccess: CanAccess = ctx =>
  ctx.FEATURE_FLAGS.globalLicenseDashboardEnabled && !ctx.isTenantUser;

export const LicensingConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'license.**',
      url: '^/license',
      loadChildren: () => import('./licensing.module').then(m => m.LicenseModule)
    },
  ],
  allClusterMap: {
    'license': true,
    'license.dashboard': true,
    'license.dashboard.new': true,
    'license.details': true,
    'license-info': true,
    'license-info.usage': true,
    'license-info.plandetails': true,
    'license.mode': true,
    'license.global-license-dashboard': {
      globalContext: true
    },
    'license.global-license-dashboard.consumption': {
      globalContext: true
    },
    'license.global-license-dashboard.entitlements': {
      globalContext: true
    },
    'license.global-license-dashboard.cluster-license-usage': {
      globalContext: true
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'license': licenseAccess,
      'license.dashboard': licenseAccess,
      'license.dashboard.new': licenseAccess,
      'license.details': licenseAccess,
      'license-info': licenseAccess,
      'license-info.usage': licenseAccess,
      'license-info.plandetails': licenseAccess,
      'license.mode': licenseAccess,
      'license.revamp': licenseAccess,
      'license.global-license-dashboard': globalLicenseAccess,
      'license.global-license-dashboard.consumption': globalLicenseAccess,
      'license.global-license-dashboard.entitlements': globalLicenseAccess,
      'license.global-license-dashboard.cluster-license-usage': globalLicenseAccess
    };
  }
};
