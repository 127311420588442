import { chain } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: File Backup Paths

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.filePaths';
  var modDeps = ['C.path'];
  var componentName = 'cFilePaths';

  /**
   * @ngdoc component
   * @name C.filePaths:cFilePaths
   * @function
   *
   * @description
   * Handles the addition and modification of file backup paths for a node
   * by adding then in the job source special parameters list.
   *
   * @example
      <example module="C">
        <c-file-paths
          job="job"
          node="node">
        </c-file-paths>
      </example>
   */
  var options = {
    bindings: {
      /**
       * the job config modified by adding filePaths in source special parameter
       * compatable for kPhysical environment only
       *
       * @type   {Object}   job
       */
      job: '=',

      /**
       * the node for which filePaths setting to be modified
       *
       * @type   {Object}   node
       */
      node: '=',
    },
    controller: 'FilePathsFn',
    templateUrl: 'app/global/c-file-paths/c-file-paths-public.html',
  };

  angular
    .module(modName, modDeps)
    .controller('FilePathsFn', cFilePathsFn)
    .component(componentName, options);

  function cFilePathsFn(_, PubJobServiceFormatter, cUtils, FORMATS,
    ALL_LOCAL_DRIVES, FEATURE_FLAGS) {
    var $ctrl = this;

    // declare component methods
    assign($ctrl, {
      // methods exposed to template
      addFilePath: addFilePath,
      addExclusionPath: addExclusionPath,
      FEATURE_FLAGS: FEATURE_FLAGS,
      removeExclusionPath: removeExclusionPath,
      removeFilePath: removeFilePath,
      selectAll: selectAll,

      skipNestedVolumes: [],
      windowsExcludeFilePathRegex: FORMATS.windowsExcludeFilePathRegex,

      // component life cycle methods
      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    /**
     * initialize ctrl data
     *
     * @method _initializeCtrl
     */
    function _initializeCtrl() {
      // filePaths config initialized with to include the whole filesystem
      // root as default.
      assign($ctrl, {
        filePaths: [{
          backupFilePath: cUtils.getDefaultRootPath($ctrl.node._hostType),
          skipNestedVolumes: true,
        }],
        protectAllPathInfo: {
          backupFilePath: ALL_LOCAL_DRIVES,
          skipNestedVolumes: true,
          excludedFilePaths: [],
        },
        shouldProtectAllVolumes: false,
        isMetadataFilePath: false,
        metadataPathInfo: '',
      });
    }

    /**
     * initialize the component
     * support kPhysical environment only
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeCtrl();

      var nodeSpecialParameters =
        $ctrl.job._sourceSpecialParametersMap[$ctrl.node.protectionSource.id];
      var filePaths = get(
        nodeSpecialParameters, 'physicalSpecialParameters.filePaths', []);

      $ctrl.showSkipNestedMountDropdown =
        FEATURE_FLAGS.skipNestedMountPointVec &&
        $ctrl.node._hostType !== 'kWindows';

      $ctrl.shouldProtectAllVolumes =
        FEATURE_FLAGS.enablePhysicalFileAllDriveBackup && get(filePaths,
          '0.backupFilePath') === ALL_LOCAL_DRIVES;

      $ctrl.metadataPathInfo = get(
        nodeSpecialParameters, 'physicalSpecialParameters.metadataFilePath',
        '');
      $ctrl.isMetadataFilePath =
        FEATURE_FLAGS.enablePhysicalFileMetaFileBackup &&
        !!$ctrl.metadataPathInfo;

      // Get different type of mount types present on source.
      $ctrl.mountTypeDropdownChoices =
        chain($ctrl.node)
        .get('protectionSource.physicalProtectionSource.volumes', [])
        .map('mountType').compact().uniq().value();

      if (FEATURE_FLAGS.skipNestedMountPointVec) {
        // Set default value to skip all nested mount points.
        $ctrl.skipNestedVolumesVec = $ctrl.mountTypeDropdownChoices;
      }

      // use node filePaths if exist in job special parameters config
      if (nodeSpecialParameters) {
        if (FEATURE_FLAGS.skipNestedMountPointVec) {
          if (get(nodeSpecialParameters,
            'physicalSpecialParameters.usesSkipNestedVolumesVec', false)) {
              $ctrl.skipNestedVolumesVec =
                get(nodeSpecialParameters,
                  'physicalSpecialParameters.skipNestedVolumesVec', []);
          }
        } else {
          // If skipNestedMountPointVec flag is turned off, check if
          // skipNestedVolumes is set or not. If it is not, set true.
          $ctrl.filePaths.forEach(function pathIter(item) {
            if(item.skipNestedVolumes === undefined) {
              item.skipNestedVolumes = true;
            }
          })
        }
      }

      if (nodeSpecialParameters && !$ctrl.shouldProtectAllVolumes) {
        $ctrl.filePaths = filePaths;
      }

      if(nodeSpecialParameters && $ctrl.shouldProtectAllVolumes &&
        filePaths.length > 0) {
        $ctrl.protectAllPathInfo = filePaths[0];
      }
    }

    /**
     * update the job special parameters with updated filePaths before exiting
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      var filePaths = $ctrl.filePaths;
      var metadataFilePath = undefined;
      if($ctrl.shouldProtectAllVolumes) {
        filePaths = [$ctrl.protectAllPathInfo];
      }

      // If user has provided metadata file path we don't need to pass
      // file paths, they will be populated by magneto using metafile.
      if($ctrl.isMetadataFilePath) {
        filePaths = [];
        metadataFilePath = $ctrl.metadataPathInfo;
      }
      if (!$ctrl.node._isSelected) {
        PubJobServiceFormatter.removeSourceSpecialParameters(
          $ctrl.job,
          $ctrl.node
        );
      } else {
        // If user edits/creates the job, use usesSkipNestedVolumesVec
        // (set to true) to tell magneto to use skip_nested_volumes vec instead
        // of boolean to skip mount points(for backward compatability).
        PubJobServiceFormatter.updateSourceSpecialParameters(
          $ctrl.job,
          $ctrl.node,
          FEATURE_FLAGS.skipNestedMountPointVec ? {
            filePaths: filePaths,
            usesSkipNestedVolumesVec: true,
            skipNestedVolumesVec: $ctrl.skipNestedVolumesVec,
            metadataFilePath: metadataFilePath
          } : {
            filePaths: filePaths,
            metadataFilePath: metadataFilePath
          }
        );
      }
    }

    /**
     * Adds an inclusion path for this node
     *
     * @method     addFilePath
     */
    function addFilePath() {
      $ctrl.filePaths.push({
        backupFilePath: '',
        skipNestedVolumes: true,
      });
    }

    /**
     * Adds an exclusion path for this node
     *
     * @method     addExclusionPath
     */
    function addExclusionPath(pathInfo) {
      pathInfo.excludedFilePaths = pathInfo.excludedFilePaths || [];
      pathInfo.excludedFilePaths.push('');
    }

    /**
     * Removes the file path
     *
     * @method    removeFilePath
     * @param     {number}   index   The array index of the path to remove
     */
    function removeFilePath(index) {
      $ctrl.filePaths.splice(index, 1);
    }

    /**
     * Removes the exclusion path from the path it modifies
     *
     * @method    removeExclusionPath
     * @param     {object}   pathInfo  The path info for a node
     * @param     {number}   index     The array index of the path to remove
     */
    function removeExclusionPath(pathInfo, index) {
      pathInfo.excludedFilePaths.splice(index, 1);
    }

    /**
     * Select all sources.
     *
     * @method   selectAll
     * @param    status   True, if select all is selected, else false.
     */
    function selectAll(selected) {
      $ctrl.isAllSelected = selected;
      $ctrl.skipNestedVolumesVec = selected ? $ctrl.mountTypeDropdownChoices : [];
    }

  }

})(angular);
