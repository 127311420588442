import { getConfigByKey, isOneHeliosAppliance } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const clusterAlertsAccessFn = (ctx: StateAccessContext) =>
  !ctx.isDmsUser && ctx.ALERT_VIEW && ctx.FEATURE_FLAGS.ngHealth;

const heliosAlertsAccessFn = (ctx: StateAccessContext) => {
  if (ctx.ALERT_VIEW) {
    if (ctx.clusterInfo?._serviceType === 'dms') {
      return ctx.FEATURE_FLAGS.ngAlertsDms;
    }
    return ctx.FEATURE_FLAGS.ngHealth;
  }
  return false;
};

const clusterViewApplianceManager = (ctx: StateAccessContext) =>
  isOneHeliosAppliance(ctx.irisContext);

const notificationAccessFn = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.healthNotificationEnabled;

const clusterOrHeliosAlertsAccessFn = (ctx: StateAccessContext) =>
  clusterAlertsAccessFn(ctx) || heliosAlertsAccessFn(ctx);

const clusterOrHeliosAlertsAccessFnForConfig = (key: string) =>
  ctx => clusterOrHeliosAlertsAccessFn(ctx) && getConfigByKey(ctx.irisContext, key, true);

export const HealthConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'health.**',
      url: '/health',
      loadChildren: () => import('./health.module').then(m => m.HealthModule),
    },
    {
      name: 'alert-info.**',
      url: '/health/alert-info',
      loadChildren: () => import('./health.module').then(m => m.HealthModule),
    },
    {
      name: 'anomaly-alert-info.**',
      url: '/health/anomaly-alert-info',
      loadChildren: () => import('./health.module').then(m => m.HealthModule),
    },
    {
      name: 'health.resolution-summary.**',
      url: '/resolution-summary?&{tenantIds}',
      loadChildren: () => import('./health.module').then(m => m.HealthModule),
    },
    {
      name: 'resolution-details.**',
      url: '/health/resolution-details',
      loadChildren: () => import('./health.module').then(m => m.HealthModule),
    },
    {
      name: 'category-success.**',
      url: '/health/success',
      loadChildren: () => import('./health.module').then(m => m.HealthModule),
    },
  ],
  allClusterMap: {
    'health.components': false,
    'health': {
      allClustersState: 'health',
      singleClusterState: 'health',
      globalContext: true,
    },
    'health.alerts': {
      allClustersState: 'health.alerts',
      singleClusterState: 'health.alerts',
      globalContext: true,
    },
    'health.resolution-summary': {
      allClustersState: 'health.resolution-summary',
      singleClusterState: 'health.resolution-summary',
    },
    'resolution-details': {
      allClustersState: 'resolution-details',
      singleClusterState: 'resolution-details',
    },
    'health.settings': false,
    'category-success': false,
    'alert-info': {
      allClustersState: 'alert-info',
      singleClusterState: 'alert-info',
      globalContext: true,
    },
    'anomaly-alert-info': {
      allClustersState: 'anomaly-alert-info',
      singleClusterState: 'anomaly-alert-info',
      globalContext: true,
    },
    'health.analytics': false,
    'health.explorer': {
      allClustersState: 'health.explorer',
      singleClusterState: 'health.explorer',
      globalContext: true,
    },
    'health.notification-rules': {
      allClustersState: 'health.notification-rules',
      singleClusterState: 'health.notification-rules',
      globalContext: true,
    },
    'health.silence-rules': {
      allClustersState: 'health.silence-rules',
      singleClusterState: 'health.silence-rules',
      globalContext: true,
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'health': clusterOrHeliosAlertsAccessFn,
      'alert-info': clusterOrHeliosAlertsAccessFn,
      'anomaly-alert-info': clusterOrHeliosAlertsAccessFn,
      'category-success': clusterAlertsAccessFn,
      'health.components': clusterOrHeliosAlertsAccessFnForConfig('health.features.components'),
      'health.alerts': clusterOrHeliosAlertsAccessFnForConfig('health.features.alerts'),
      'health.resolution-summary': clusterOrHeliosAlertsAccessFnForConfig('health.features.resolutions'),
      'resolution-details': clusterOrHeliosAlertsAccessFn,
      'health.settings': clusterOrHeliosAlertsAccessFnForConfig('health.features.notifications'),
      'health.analytics': clusterOrHeliosAlertsAccessFnForConfig('health.features.analytics'),
      'health.application-services': clusterViewApplianceManager,
      'health.explorer': (ctx: StateAccessContext) => clusterAlertsAccessFn(ctx) && ctx.FEATURE_FLAGS.ngHealthExplorer,
      'health.notification-rules': notificationAccessFn,
      'health.silence-rules': clusterOrHeliosAlertsAccessFn,
    };
  },
};
