
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, iif, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { get } from 'lodash-es';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { HelixAssetsOptions, addCacheBuster, joinUrl, prefixSlash } from '@cohesity/helix';
import { PLATFORM_CONFIG_PATHS } from '../injection-tokens';

/**
 * Default app configuration type
 */
export type AppConfiguration = Record<string, unknown>;

/**
 * Base configuration directory path
 */
const BASE_CONFIG_PATH = 'assets/configurations';

/**
 * Default app configuration path
 */
export const APP_CONFIG_PATH = `${BASE_CONFIG_PATH}/config.json`;

/**
 * Global configuration path. This is absolute path because global.json
 * stays with Iris for now.
 */
export const GLOBAL_CONFIG_PATH = `/${BASE_CONFIG_PATH}/global.json`;

/**
 * Path to local configurations which are used in dev env
 */
export const LOCAL_CONFIG_PATH = `${BASE_CONFIG_PATH}/local.json`;

/**
 * Gateway to remote server for assets
 */
export const EXT_PATH = 'ext';

/**
 * Method to fetch the configuration paths for any app
 *
 * @param isDevMode true if app is running in dev environment
 * @param remoteAssetsPath path to remote assets directory
 */
export function getPlatformConfigPaths(isDevMode: boolean, remoteAssetsPath: string): string[] {
  if (isDevMode) {
    // load global.json, config.json from remote
    // load local.json
    return [
      GLOBAL_CONFIG_PATH,
      prefixSlash(joinUrl([remoteAssetsPath, APP_CONFIG_PATH] ))
    ].map(p => `${EXT_PATH}${p}`).concat([LOCAL_CONFIG_PATH]);
  }

  return [GLOBAL_CONFIG_PATH, APP_CONFIG_PATH];
}


/**
 * This service is used for cluster related apis, and to cache application wide
 * settings. It is downgraded to Angular JS as NgClusterService.
 */
@Injectable({ providedIn: 'root' })
export class ConfigurationService {

  /**
   * Configuration source
   */
  private _config$ = new BehaviorSubject<AppConfiguration>(null);

  /**
   * True if configurations are being fetched, else false
   */
  private _loadingConfig = false;

  /**
   * Configuration observable exposed to outside application
   */
  get config$(): Observable<AppConfiguration> {
    return this._config$.asObservable();
  }

  /**
   * Latest value of configuration
   */
  get config(): AppConfiguration {
    return this._config$.getValue();
  }

  constructor(
    private helixAssetsOptions: HelixAssetsOptions,
    private httpClient: HttpClient,

    @Optional()
    @Inject(PLATFORM_CONFIG_PATHS) private configPaths?: string[]
  ) {
    if (!this.configPaths) {
      this.configPaths = [];
    }
  }

  /**
   * Load the configuration
   *
   * @returns Observable with the merged configurations
   */
  fetchConfiguration(): Observable<AppConfiguration> {
    return iif(
      // Config loaded or in progress
      () => Boolean(this.config) || this._loadingConfig,
      this._config$,
      of(this.configPaths).pipe(
        tap(() => this._loadingConfig = true),

        map((paths) => paths.map(path => {
          const cacheBusterUrl = addCacheBuster([path], this.helixAssetsOptions.options);

          // addCacheBuster will remove the leading slash.
          if (path.startsWith('/')) {
            return prefixSlash(cacheBusterUrl);
          }
          return cacheBusterUrl;
        })),

        // map paths to fetch configurations
        switchMap(paths => forkJoin(paths.map((path) => this.loadConfiguration(path)))),

        // merge all configurations
        map((configurations) => configurations.reduce(
          (accumulated, curr) => this.mergeTopLevelKeys(accumulated, curr), {} as AppConfiguration)),

        // update subjects
        tap(_config => {
          this._loadingConfig = false;
          this._config$.next(_config);
        })
      )
    );
  }

  /**
   * Override properties of target configuration by source configuration
   *
   * @param target target object
   * @param source source object
   * @returns Merged app configuration
   */
  private mergeTopLevelKeys(target: AppConfiguration = {}, source: AppConfiguration = {}): AppConfiguration {
    return { ...target, ...source };
  }

  /**
   * Load configuration from the given file path
   *
   * @param path configuration file path
   */
  private loadConfiguration(path: string): Observable<AppConfiguration> {
    return this.httpClient.get<AppConfiguration>(path).pipe(
      catchError(() => of({} as AppConfiguration))
    );
  }

  /**
   * Add configurations for specific software types.
   * Call this method before fetching configurations
   *
   * @param type software type
   */
  addSoftwareTypePath(type: string) {
    const softwareTypeConfigPath = prefixSlash(
      joinUrl([
        BASE_CONFIG_PATH,
        `types/config_${type}.json`
      ])
    );
    this.configPaths.push(softwareTypeConfigPath);
  }

  /**
   * Get value for a give configuation key
   *
   * @param key configuration key
   * @param defaults default value if there's no value in configuration
   * @returns value for configuration key
   */
  getConfig<T = string>(key: string, defaults: T): T {
    return <T>get(this.config, key, defaults);
  }
}
