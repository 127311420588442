<mat-toolbar>

  <mat-toolbar-row>
    <div class="page-toolbar-row-content">
      <div class="flex-row" *ngIf="showBack || title || isTitleOverwrite">
        <cog-nav-banner
          *ngIf="navBanner"
          [title]="navBanner.title"
          [logo]="navBanner.logo"
          size="md"
          [showBranding]="navBanner.showBranding"
          [modifierClass]="navBanner.modifierClass"
          class="margin-right-lg no-margin-bottom">
          <small subtitle class="sub-text" *ngIf="navBanner.subtitle">
            <small [innerHTML]="navBanner.subtitle"></small>
          </small>
        </cog-nav-banner>
        <button class="back-button"
          *ngIf="showBack"
          (click)="back.emit()"
          cogDataId="close-workflow-button"
          mat-icon-button
          type="button"
          [matTooltip]="backTooltip"
          [matTooltipDisabled]="!backTooltip">
          <cog-icon [shape]="backButtonIcon || 'arrow_back'"></cog-icon>
          <span class="back-text" *ngIf="backText">{{backText}}</span>
        </button>

        <div class="cog-page-title-container page-title-with-breadcrumbs">
          <div class="page-breadcrumbs" *ngIf="pageBreadcrumbsComponent">
            <ng-content select="cog-page-breadcrumbs"></ng-content>
          </div>
          <h1 *ngIf="title && !backText && !isTitleOverwrite">
            {{title}}
          </h1>
          <span>
            <ng-content select="cog-page-title"></ng-content>
          </span>
        </div>
      </div>

      <div class="cog-page-title-container">
        <cog-nav-banner
          *ngIf="navBanner && !(showBack || title || isTitleOverwrite)"
          [title]="navBanner.title"
          [logo]="navBanner.logo"
          size="md"
          [showBranding]="navBanner.showBranding"
          [modifierClass]="navBanner.modifierClass"
          class="margin-right-lg no-margin-bottom">
          <small subtitle class="sub-text" *ngIf="navBanner.subtitle">
            <small [innerHTML]="navBanner.subtitle"></small>
          </small>
        </cog-nav-banner>
        <div class="default-content">
          <ng-content></ng-content>
        </div>
      </div>

      <div [ngClass]="{'toolbar-page-actions': pageActions}" class="page-actions" *ngIf="pageActionsComponent">
        <ng-content select="cog-page-actions"></ng-content>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="cog-page-nav-row" *ngIf="pageNavComponent && !tallHeader">
    <div class="page-toolbar-row-content">
      <ng-container *ngTemplateOutlet="pageNav"></ng-container>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="cog-page-tall-header-toolbar" *ngIf="pageNavComponent && tallHeader">
    <div class="cog-page-tall-header-row">
      <div class="tall-header-row-content">
        <ng-container *ngTemplateOutlet="pageNav"></ng-container>
      </div>
    </div>
  </mat-toolbar-row>

</mat-toolbar>

<ng-template #pageNav>
  <ng-content select="cog-page-nav"></ng-content>
</ng-template>
