import { getConfigByKey, isDmsScope, isDraasScope, isGlobalScope, isIbmBaaSEnabled, isTenantUser } from '@cohesity/iris-core';
import { AppModuleConfig, CanAccess, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { generalCdpAccess } from 'src/app/modules/cdp/cdp.config';

const hasDmsAccess = (ctx: StateAccessContext) => isDmsScope(ctx.irisContext);
const inGlobalContext = (ctx: StateAccessContext) => isGlobalScope(ctx.irisContext);
const editBoardAccess: CanAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.customDashboardEnabled;

const appPillarsAccess: CanAccess = () => true;

/**
 * Common/shared dashboard access checks for dasshboards serving on-prem or
 * Helios based cluster related dashboards.
 */
function sharedAccessCheckFnc(configKey) {
  return (ctx: StateAccessContext) =>
    !isGlobalScope(ctx.irisContext) &&
    !ctx.isTenantUser &&
    !ctx.isHeliosTenantUser &&
    !ctx.isRestrictedUser &&
    getConfigByKey(ctx.irisContext, configKey, true);
}

/**
 * Disaster Recovery dashboard access control.
 */
const hasDrAccess = (ctx: StateAccessContext) =>
  (isDraasScope(ctx.irisContext) ||
    (ctx.FEATURE_FLAGS.keplerEnabled && ctx.FEATURE_FLAGS.keplerDashboardEnabled)) &&
  !ctx.isTenantUser &&
  !ctx.isRestrictedUser;

export const DashboardsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'app-pillars.**',
      url: '^/data-cloud',
      loadChildren: () => import('./dashboards.module').then(m => m.DashboardsModule)
    },
    {
      name: 'app-blank.**',
      url: '^/data-cloud-app',
      loadChildren: () => import('./dashboards.module').then(m => m.DashboardsModule)
    },
    {
      name: 'dashboards.**',
      url: '/dashboards',
      loadChildren: () => import('./dashboards.module').then(m => m.DashboardsModule),
    },
  ],
  allClusterMap: {
    'app-pillars-home': {
      globalContext: true,
    },
    'app-pillars': {
      globalContext: true,
    },
    'app-blank': {
      globalContext: true,
    },
    'dashboards': {
      singleClusterState: 'dashboards',
      allClustersState: 'dashboards',
      globalContext: true,
    },
    'dashboards.summary': true,
    'dashboards.data-protection': true,
    'dashboards.cloud': true,
    'dashboards.ibm-baas': true,
    'dashboards.file-services': false,
    'dashboards.dms': true,
    'dashboards.global': {
      allClustersState: 'dashboards.global',
      globalContext: true,
      singleClusterState: 'dashboards.global',
    },
    'dashboards.disaster-recovery': true,
    'dashboards.organization': {
      heliosOnly: true,
      allClustersState: 'dashboards.organization',
      singleClusterState: 'dashboards.organization',
    },
    'dashboards.edit': {
      allClustersState: 'dashboards.edit',
      singleClusterState: 'dashboards.edit',
      globalContext: true,
    },
    'dashboards.add': {
      allClustersState: 'dashboards.add',
      singleClusterState: 'dashboards.add',
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'app-pillars-home': appPillarsAccess,
      'app-pillars': appPillarsAccess,
      'app-blank': appPillarsAccess,
      'dashboards': () => true,
      'dashboards.summary': sharedAccessCheckFnc('dashboards.features.dashboard-summary'),
      'dashboards.data-protection': sharedAccessCheckFnc('dashboards.features.dashboard-dataprotection'),
      'dashboards.cloud': (ctx: StateAccessContext) =>
        ctx.FEATURE_FLAGS.ngCloudDashboard &&
        sharedAccessCheckFnc('dashboards.features.dashboard-cloud')(ctx),
      'dashboards.ibm-baas': (ctx: StateAccessContext) =>
        isIbmBaaSEnabled(ctx.irisContext) &&
        isTenantUser(ctx.irisContext),
      'dashboards.file-services': sharedAccessCheckFnc('dashboards.features.dashboard-smartfiles'),
      'dashboards.dms': hasDmsAccess,
      'dashboards.global': inGlobalContext,
      'dashboards.cdp': (ctx: StateAccessContext) => generalCdpAccess(ctx) &&
        getConfigByKey(ctx.irisContext, 'dashboards.features.dashboard-cdp', true),
      'dashboards.disaster-recovery': hasDrAccess,
      'dashboards.organization': (ctx: StateAccessContext) => ctx.isHeliosTenantUser &&
        getConfigByKey(ctx.irisContext, 'dashboards.features.dashboard-organization', true),
      'dashboards.edit': editBoardAccess,
      'dashboards.add': editBoardAccess,
    };
  },
};
