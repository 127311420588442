<cog-dashcard
  [title]="'dg.sc.dashboard.clusterRiskDistribution' | translate"
  [subtitle]="(data$ | async)?.scanResults?.length ? lastScanTime : null">
  <ng-container *ngIf="!loading; else spinner">
    <ng-container *ngTemplateOutlet="topPatternsContent"></ng-container>
  </ng-container>
</cog-dashcard>

<ng-template #topPatternsContent>
  <mat-card-content *ngIf="(data$ | async)?.scanResults?.length; else blankCard">
    <cog-bar-chart
      [seriesData]="[seriesData]"
      [categories]="categories"
      [customChartOptions]="customOptions"
      [reflowOnFirstRender]="true">
    </cog-bar-chart>
  </mat-card-content>
</ng-template>

<ng-template #blankCard>
  <cog-blank-card size="sm2"></cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
