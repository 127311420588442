import { assign } from 'lodash-es';
// Component: Bifrost list component

;(function(angular, undefined) {
  'use strict';

  angular.module('C.tenants')
    .controller('BifrostListCtrl', BifrostListCtrlFn)
    .component('bifrostList', {
      bindings: {
        /**
         * Optional provide the ID for the tenant.
         *
         * @type  {String}  [tenantId=undefined]
         */
        tenantId: '<?',

        /**
         * Optional provide list of tenant proxy info in that case don't fetch
         * fresh list of proxies.
         *
         * @type  {Object[]}  [proxies=[]]
         */
        proxies: '<?',
      },
      controller: 'BifrostListCtrl',
      templateUrl:
        'app/admin/access-management/tenants/bifrost-proxy/list.html',
    });

  function BifrostListCtrlFn(_, evalAJAX, TenantService, CHART_COLORS) {
    var $ctrl = this;

    // Declare Component Methods.
    assign($ctrl, {
      CHART_COLORS: CHART_COLORS,
      isLoading: true,

      // component life cycle methods.
      $onInit: $onInit,
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      // fetch proxies if not provided externally.
      if (!$ctrl.proxies) {
        getData();
      }
    }

    /**
     * make a request to get tenants list.
     *
     * @method   getData
     */
    function getData() {
      $ctrl.isLoading = true;
      TenantService.getTenantProxies($ctrl.tenantId).then(
        function gotProxies(proxies) {
          $ctrl.proxies = proxies;
        }, evalAJAX.errorMessage).finally(function finallyGetProxies() {
        $ctrl.isLoading = false;
      });
    }
  }

})(angular);
