import { filter } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Nodes List

; (function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.clusterSetup:nodesList
   * @description
   *  This component renders a list of discovered nodes which are is used in
   *  creating a cluster or adding a node when it is of the type of virtual
   *  edition cluster
   * @example
   *  <nodes-list max-nodes="maxNodes" nodes="nodesList"></nodes-list>
   */

  var configOptions = {
    bindings: {
      /**
       * The maximum number of nodes that can be selected.
       *
       * @type   {Number}
       */
      maxNodes: '<',

      /**
       * The list of discovered nodes.
       *
       * @type   {Array}
       */
      nodes: '=',

      /**
       * The IP family preference of cluster.
       *
       * @type   {Number}
       */
      ipFamily: '<?',

      /**
       * True if used in cluster setup mode.
       *
       * @type   {boolean}
       */
      setupMode: '<?'
    },
    controller: nodesListCtrlFn,
    templateUrl: 'app/admin/cluster/setup/nodes-list.html',
  };

  angular.module('C.clusterSetup')
    .component('nodesList', configOptions);

  function nodesListCtrlFn(_) {
    var $ctrl = this;
    assign($ctrl, {
      // Disables the selection if the number of selected nodes exceed the limit
      disableSelection: false,

      // Component methods.
      toggleSelection: toggleSelection,
      toggleAllNodes: toggleAllNodes,
      areAllNodesSelected: areAllNodesSelected,
      filterMissingNodes: filterMissingNodes,
    });

    /**
     * Filter out missing nodes.
     *
     * @method   filterMissingNodes
     * @param    {Object}   node   The node object to filter.
     */
    function filterMissingNodes(node) {
      return !node.missingNode;
    }

    /**
     * Selects/Deselects the toggled node.
     *
     * @method   toggleSelection
     * @param    {Object}   node   The node selection to be toggled.
     */
    function toggleSelection(node) {
      node.selected = !node.selected;

      // Disable selection if maximum number of nodes are selected.
      $ctrl.disableSelection = !$ctrl.disableSelection &&
        (filter($ctrl.nodes, 'selected').length === $ctrl.maxNodes);
    }

    /**
     * Selects/Deselects all the nodes.
     *
     * @method   toggleAllNodes
     */
    function toggleAllNodes() {
      if ($ctrl.nodes.length < $ctrl.maxNodes) {
        if ($ctrl.areAllNodesSelected()) {
          $ctrl.nodes.forEach(function forEachNode(node) {
            // For every node that is not default selected.
            if (!node.connectedTo) {
              node.selected = false;
            }
          });
        } else {
          $ctrl.nodes.forEach(function forEachNode(node) {
            node.selected = true;
          });
        }
      }
    }

    /**
     * Checks if all the nodes are selected or not.
     *
     * @method   areAllNodesSelected
     * @returns  {Boolean}   True, if all are selected else false.
     */
    function areAllNodesSelected() {
      return filter($ctrl.nodes, 'selected').length === $ctrl.nodes.length;
    }
  }
})(angular);
