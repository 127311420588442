import { extend } from 'lodash-es';
import { isArray } from 'lodash-es';
import { assign } from 'lodash-es';
// Modules & Directive: cPostForm

(function (angular, undefined) {
  'use strict';

  angular.module('C.postForm', [])
    .component('cPostForm', {
      bindings: {
        /**
         * @type   {String} Request url for posting the data.
         */
        url: '<',

        /**
         * @type   {Object} POST body
         * Form will be submitted dynamically whenever the body changes
         */
        body: '<',

        /**
         * @type   {String} reference to target,
         * where the reponse from request should be loaded
         * target="_blank" for loading the response in new tab
         * target="iframeName" for loading inside an iframe
         */
        target: '<',
      },
      controller: 'PostFormCtrl',
      templateUrl: 'app/global/c-post-form/c-post-form.html',
    })
    .controller('PostFormCtrl', PostFormCtrl);

  function PostFormCtrl($scope, $element, $sce) {
    const $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
      $onChanges: $onChanges,
    });

    function $onInit() {
      $ctrl.url = $sce.trustAsResourceUrl($ctrl.url);
    }

    function $onChanges(changes) {
      if (changes.body && changes.body.currentValue) {
        submitForm(changes.body.currentValue);
      }
    }

    /**
     * Creates form inputs based on the input data
     * and dynamically submits the form
     * @method    submitForm
     * @param    {Object}   body   POST body
     *
     */
    function submitForm(body) {
      const form = $element.find('form');
      form.empty();

      Object.keys(body).forEach(key => {
        const value =
          (body[key] === null || body[key] === undefined) ? '' : body[key];

        if (isArray(value)) {
          // When sending url encoded params, arrays should be reperesented as
          // array[]=0&array[]=1, server converts this into proper array
          value.forEach(v => {
            form.append(getInputField(`${key}[]`, v));
          });
        } else {
          form.append(getInputField(key, value));
        }
      });
      form[0].submit();
    }

    /**
     * Creates an input element with given name and value
     * @method    getInputField
     * @param    {String}   name   name of the input field
     * @param     {Any}     value  value of the input field
     *
     */
    function getInputField(name, value) {
      const input = document.createElement('input');
      return extend(input, { type: 'text', name, value });
    }
  };
})(angular);
