import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configAccessCheck = (ctx: StateAccessContext) => getConfigByKey(ctx.irisContext, 'settings.features.accountSecurity', true);

const accountSecurityViewAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.securityAdvisorEnabled && ctx.SECURITY_ADVISOR_VIEW && configAccessCheck(ctx);

const accountSecurityModifyAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.securityAdvisorEnabled && ctx.SECURITY_ADVISOR_MODIFY && configAccessCheck(ctx);

const securityAdvisorViewAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.securityAdvisorEnabled &&
  ctx.SECURITY_ADVISOR_VIEW &&
  ['he16', 'he18'].includes(ctx.clusterInfo.heliosVersion) &&
  configAccessCheck(ctx);

export const AccountSecurityConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'account-security.**',
      url: '^/account-security',
      loadChildren: () => import('./account-security.module').then(m => m.AccountSecurityModule),
    },
    {
      name: 'security-advisor.**',
      url: '^/security-advisor/settings',
      loadChildren: () => import('./account-security.module').then(m => m.AccountSecurityModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'account-security': accountSecurityViewAccess,
      'account-security.settings': accountSecurityViewAccess,
      'account-security.settings.password-policy': accountSecurityViewAccess,
      'account-security.settings.edit': accountSecurityModifyAccess,
      'security-advisor': securityAdvisorViewAccess,
    };
  },
};
