import { assign } from 'lodash-es';
// Controller: Source Details - All tab controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.sources')
    .controller('sourceDetailsAllController', sourceDetailsAllControllerFn);

  function sourceDetailsAllControllerFn(_, $scope, $state, SourceInfo,
    SourceService, PubSourceService, PubJobServiceFormatter, evalAJAX,
    ENV_GROUPS, FEATURE_FLAGS, ClusterService) {

    var $ctrl = this;

    assign($ctrl, {
      $onInit: onInit,
      ngEnvironments: FEATURE_FLAGS.ngSourceDetailTreeEnabled ?
        ['kVMware', 'kCassandra', 'kHBase', 'kHdfs', 'kHive', 'kMongoDB', 'kCouchbase', 'kUDA'] : [],
      environment: SourceInfo.rootNode.environment,
      sourceTreeOpts: {
        canSelect: false,
        detailedView: true,
        contextMenu: true,
      },
    });

    /**
     * init function
     *
     * @method   onInit
     */
    function onInit() {
      _loadSourceDetails();
    }

    /**
     * Load the source tree based on the environment
     *
     * @method   _loadSourceDetails
     */
    function _loadSourceDetails() {
      if (ENV_GROUPS.applicationSources.includes($state.params.environment)) {
        _getApplicationServers();
      } else {
        _getSourceTree($state.params.id);
      }
    }

    /**
     * Database sources are noot rootNode entities so it is required to call
     * the applicationServers endpoint to get sourceTree data.
     *
     * @method   _getApplicationServers
     */
    function _getApplicationServers() {
      var id = $state.params.parentId || $state.params.id;
      var params = {
        protectionSourcesRootNodeId: id,
        environment: SourceInfo.rootNode.environment,
        application: $state.params.environment,
        protectionSourceId: $state.params.id,
      };

      PubSourceService.getApplicationServers(params)
        .then(function getServers(servers) {
          var applicationServers = servers.reduce(
            function flattenServers(applications, server) {
              applications = applications.concat(server.applicationServer);

              return applications;
            }, []);

          $ctrl.sourceTree =
            PubJobServiceFormatter.transformTree(applicationServers, null, {
              rootEnvironment: $state.params.environment,
              selectedCounts: {},
              totalCounts: {},
            });
        }, evalAJAX.errorMessage)
        .finally(function getApplicationServersFinally() {
          $ctrl.sourceTreeLoaded = true;
        });
    }

    /**
     * get the source entity hierarchy and transform it so it can be displayed
     * to the user
     *
     * @method   _getSourceTree
     * @param    {Number}    id    source id
     */
    function _getSourceTree(id) {
      var skipTransform = $ctrl.ngEnvironments.includes($ctrl.environment);
      var params = {
        includeVMFolders: true,
        excludeTypes: ['kResourcePool'],
        excludeKubernetesTypes: FEATURE_FLAGS.excludeKubernetesTypes ? ['kService', 'kLabel'] : undefined,
        includeSystemVApps: $ctrl.environment === 'kVMware' ? true : undefined,
        _useClientSideExcludeTypesFilter: (
          ClusterService.basicClusterInfo.multiTenancyEnabled &&
          FEATURE_FLAGS.useClientSideExcludeTypesFilterForMultiTenantCluster
        ),
      };

      var options = {
        rootEnvironment: $ctrl.environment,
        skipTransform,
      };

      // In addition to source, also try to get agent information
      PubSourceService.getSource(id, params, options)
        .then(function getSource(source) {
          // Inject registeredSource into the sourceOpts for context menu
          $ctrl.sourceTreeOpts.registeredSource = SourceService
            .publicEntityToPrivateEntity(source[0].protectionSource);
          $ctrl.sourceTree = skipTransform ? source : PubJobServiceFormatter
            .transformTree(source, null, {
              rootEnvironment: source[0].protectionSource.environment,
              selectedCounts: {},
              totalCounts: {},
            });
          // Disable the source tree nodes if environment type is
          // kUDA and udaEntityHierarchyEnabled feature flag is false.
          if (source[0].protectionSource.environment === 'kUDA' &&
            !FEATURE_FLAGS.udaEntityHierarchyEnabled) {
              if ($ctrl.sourceTree && $ctrl.sourceTree.length) {
                $ctrl.sourceTree[0].nodes = [];
              }
          }
        }, evalAJAX.errorMessage)
        .finally(function getSourceFinally() {
          $ctrl.sourceTreeLoaded = true;
        });
    }

    /**
     * Show the spinner to notify user the source is being refreshed.
     */
    $scope.$on('source-refresh-started', function refreshStarted() {
      $ctrl.sourceTreeLoaded = false;
    });

    /**
     * When refresh is done call _getSourceTree or _getApplicationServers
     * based on the environment to get updated tree.
     */
    $scope.$on('source-refresh-done', _loadSourceDetails);
  }
})(angular);
