import { assign } from 'lodash-es';
// Module: Reports Parent Module
import { getConfigByKey } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.reports', ['C.reportsControls', 'C.emailScheduler',
    'C.scheduledEmails', 'C.reportsHeader', 'C.reportsControlsConstants',
    'C.selectTenant', 'C.jobRunsServiceFormatter', 'C.tableCols',
    'C.tenantService', 'C.constants', 'C.statList', 'C.heliosReports'])
    .config(ConfigFn)
    .controller('reportsCtrl', ReportsCtrlFn);

  function ConfigFn($stateProvider) {
    var viewingAsTenant = '$root.isTenantUser() ? false : ';
    var reportsAccess = 'REPORTS_VIEW';

    var _defaultDynamicParamConfig = {
      dynamic: true,
      value: undefined,
      squash: true,
    };

    $stateProvider
      .state('reports', {
        url: '/monitoring/reports',
        help: 'reports',
        title: 'Cohesity Reports',
        canAccess: reportsAccess,
        allClustersSupport: {
          singleClusterState: 'reports',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/reports.html',
        controller: 'reportsCtrl as $ctrl',
        onEnter: checkReportsAccess,
      })
      .state('reports.scheduled-emails', {
        url: '^/monitoring/reports/scheduled-emails',
        help: 'reports',
        title: 'Scheduled Emails',
        canAccess: 'SCHEDULER_VIEW',
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.scheduled-emails',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/schedules/scheduled-emails.html',
        controller: 'scheduledEmailsController as scheduledEmailCtrl',
      })
      .state('reports.jobs', {
        url: '^/monitoring/reports/jobs-summary',
        help: 'reports_jobs',
        title: 'Protection Job Summary',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.jobs',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/jobs/summary.html',
        controller: 'reportsJobsSummaryController',
      })
      .state('reports.top-jobs', {
        url: '^/monitoring/reports/topjobs',
        help: 'reports_topjobs',
        title: 'Top Jobs',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.top-jobs',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/jobs/top/top-jobs.html',
        controller: 'reportsTopJobsController',
      })
      .state('reports.jobs-vms', {
        url: '^/monitoring/reports/vms',
        help: 'reports_vms',
        title: 'Objects Protected by Multiple Jobs',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.jobs-vms',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/jobs/vms/vms.html',
        controller: 'reportsVmsInMultipleJobsController',
      })
      .state('reports.jobs-inventory', {
        url: '^/monitoring/reports/vms/inventory',
        help: 'reports_vms_inventory',
        title: 'Backup Inventory and Schedule',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.jobs-inventory',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/jobs/inventory/inventory.html',
        controller: 'reportsJobsInventoryController'
      })
      .state('reports.protection-runs', {
        url: '^/monitoring/reports/protection-runs',
        help: 'reports_protection_runs',
        title: 'Protection Runs Summary',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.protection-runs',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/jobs/runs/runs-summary.html',
        controller: 'ReportRunsSummaryCtrl as $ctrl',
      })
      .state('reports.protection-heatmap', {
        url: '^/monitoring/reports/protection-heatmap?' + [
          '{startTimeUsecs}&',
          '{endTimeUsecs}&',
          '{rollup}&',
          '{timezone}',
        ].join(''),
        params: {
          startTimeUsecs: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          endTimeUsecs: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          rollup: assign({type: 'string'}, _defaultDynamicParamConfig),
          timezone: assign({type: 'string'}, _defaultDynamicParamConfig),
        },
        help: 'reports_protected_objects_heatmap',
        title: 'Protected Objects Trends',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.protection-heatmap',
          allClustersState: 'helios-reports',
        },
        component: 'protectedObjectsTrends',
      })
      .state('reports.restore', {
        url: '^/monitoring/reports/recover',
        help: 'reports_restore',
        title: 'Restore Summary',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.restore',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/restore/summary.html',
        controller: 'reportsRestoreSummaryController',
      })
      .state('reports.sources', {
        url: '^/monitoring/reports/sources-protected',
        help: 'reports_sources',
        title: 'Sources Summary',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.sources',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/sources/summary.html',
        controller: 'reportsSourcesSummaryCtrl',
      })
      .state('reports.storage', {
        url: '^/monitoring/reports/cluster-storage',
        help: 'reports_storage',
        title: 'Cluster Wide Storage',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.storage',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/storage/storage.html',
        controller: 'reportsStorageController',
      })
      .state('reports.storage-job', {
        url: '^/monitoring/reports/storage/jobs',
        help: 'reports_storage_jobs',
        title: 'Storage Consumed by Job',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.storage-job',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/storage/jobs/jobs.html',
        controller: 'reportsStorageJobsController',
      })
      .state('reports.storage-vm', {
        url: '^/monitoring/reports/storage/vms',
        help: 'reports_storage_vms',
        title: 'Storage Consumed by VM',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.storage-vm',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/storage/vms/vms.html',
        controller: 'reportsStorageVmsController',
      })
      .state('reports.storage-viewboxes', {
        url: '^/monitoring/reports/storagedomains',
        help: 'reports_storage_viewboxes',
        title: 'Storage Consumed by View Box',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.storage-viewboxes',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/storage/viewboxes/viewboxes.html',
        controller: 'reportsStorageViewboxesController',
      })
      .state('reports.storage-tenants', {
        url: '^/monitoring/reports/storage/organizations/{type}',
        help: 'reports_storage_organizations',
        title: 'Storage Consumed by Organizations',
        canAccess: reportsAccess,
        parentState: 'reports',
        params: {
          type: { type: 'string', value: '' },
        },
        allClustersSupport: {
          singleClusterState: 'reports.storage-tenants',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/storage/tenants/tenants.html',
        controller: 'reportsStorageTenantsCtrl',
        onEnter: function setDefaultType($transition$, FEATURE_FLAGS,
          UserService) {
          var paramsCopy = Object.assign({}, $transition$.params());
          var stateService = $transition$.router.stateService;
          var isFlagEnabled =
            FEATURE_FLAGS.tenantsPhysicalUsageStorageConsumptionReportEnabled;
          var defaultType =  isFlagEnabled ? 'physical-usage' : 'workload';

          if (paramsCopy.type === '' ||
            (!isFlagEnabled && paramsCopy.type === 'physical-usage')) {
            paramsCopy.type = defaultType;
            return stateService.target($transition$.to(), paramsCopy);
          }
        },
      })
      .state('reports.storage-growth', {
        url: '^/monitoring/reports/storage/growth',
        help: 'reports_storage_growth',
        title: 'Source Growth and Variance',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.storage-growth',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/storage/growth/growth.html',
        controller: 'reportsStorageGrowthController',
      })
      .state('reports.files-category', {
        url: '^/monitoring/reports/files/category/?{objId}&{jobId}&{vmName}',
        help: 'reports_files_category_cluster',
        title: 'Storage Consumed by File Category',
        canAccess: reportsAccess,
        parentState: 'reports',
        params: {
          objId: { type: 'string' },
          jobId: { type: 'string' },
          vmName: { type: 'string' },
        },
        allClustersSupport: {
          singleClusterState: 'reports.files-category',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/files/category/category.html',
        controller: 'reportsFilesCategoryController',
      })
      .state('reports.files-topfiles', {
        url: '^/monitoring/reports/files/topfiles/?{objId}&{jobId}&{vmName}',
        help: 'reports_files_category_vm',
        title: 'Top Storage Consumed by Files',
        canAccess: reportsAccess,
        parentState: 'reports',
        params: {
          objId: { type: 'string' },
          jobId: { type: 'string' },
          vmName: { type: 'string' },
        },
        allClustersSupport: {
          singleClusterState: 'reports.files-topfiles',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/files/topfiles/topfiles.html',
        controller: 'reportsFilesTopController',
      })
      .state('reports.vms-by-protection', {
        url: '^/monitoring/reports/vms-by-protection',
        help: 'reports_unprotected_vms',
        title: 'Unprotected VMs Report',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.vms-by-protection',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/vms/by-protection.html',
        controller: 'reportsVmsByProtectionController',
      })
      .state('reports.available-local-snapshots', {
        url: '^/monitoring/reports/available-local-snapshots',
        help: 'reports_available_local_snapshots',
        title: 'Available Local Snapshots',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.available-local-snapshots',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/vms/snapshots/local-snapshots.html',
        controller: 'reportsAvailableLocalSnapshotsController',
      })
      .state('reports.protection-details', {
        url: '^/monitoring/reports/protection-details/?{objectId}&{objectName}',
        params: {
          objectId: assign({type: 'string'}, _defaultDynamicParamConfig),
          objectName: assign({type: 'string'}, _defaultDynamicParamConfig),
        },
        help: 'reports_protection_details',
        title: 'Protection Details Per Object',
        canAccess: viewingAsTenant + reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.protection-details',
          allClustersState: 'helios-reports',
        },
        templateUrl:
          'app/reports/vms/protection-details/protection-details.html',
        controller: 'reportsProtectionDetailsController',
      })
      .state('reports.failed-objects', {
        url: '^/monitoring/reports/failed-objects',
        help: 'reports_failed_objects',
        title: 'Failed Objects',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.failed-objects',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/vms/failed/failed-objects.html',
        controller: 'reportsFailedObjectsController',
      })
      .state('reports.protection-summary', {
        url: '^/monitoring/reports/protection-summary',
        help: 'reports_protection_summary_by_object_type',
        title: 'Protection Summary by Object Type',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.protection-summary',
          allClustersState: 'helios-reports',
        },
        templateUrl:
          'app/reports/vms/protection-summary/protection-summary.html',
        controller: 'reportsProtectionSummaryController',
      })
      .state('reports.data-transferred-to-external-targets', {
        url: '^/monitoring/reports/data-transferred-to-external-targets',
        help: 'reports_data_transferred',
        title: 'Data Transferred to External Targets',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.data-transferred-to-external-targets',
          allClustersState: 'helios-reports',
        },
        templateUrl:
          'app/reports/archival/data-transferred-external-targets.html',
        controller: 'reportsDataTransferredToExternalTargetsController',
        controllerAs: 'dataTransferredVm',
      })
      .state('reports.user-quotas', {
        url: '^/monitoring/reports/user-quotas/?{viewName}&{viewId}&{unixUid}&{sid}&{excludeUsersWithinAlertThreshold}',
        help: 'reports_user_quotas',
        title: 'User Quotas Per View',
        canAccess: reportsAccess,
        parentState: 'reports',
        params: {
          viewName: { type: 'string' },
          viewId: { type: 'int' },
          unixUid: { type: 'string' },
          sid: { type: 'string' },
          excludeUsersWithinAlertThreshold: { type: 'cBool' },
        },
        reloadOnSearch: true,
        allClustersSupport: {
          singleClusterState: 'reports.user-quotas',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/views/quotas.html',
        controller: 'reportsQuotasController as quotasCtrl',
      })
      .state('reports.dir-quotas', {
        url: '^/monitoring/reports/dir-quotas/?{viewName}',
        help: 'reports_directory_quotas',
        title: 'Directory Quotas Per View',
        canAccess: reportsAccess,
        parentState: 'reports',
        params: {
          viewName: { type: 'string' },
        },
        allClustersSupport: {
          singleClusterState: 'reports.dir-quotas',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/views/dir-quotas.html',
        controller: 'reportsDirQuotasController as quotasCtrl',
      })
      .state('reports.gdpr-objects', {
        url: '^/monitoring/reports/gdpr-objects?' + [
            '{objectId}&',
            '{parentSourceId}&',
            '{startTimeUsecs}&',
            '{endTimeUsecs}',
          ].join(''),
        params: {
          objectId: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          parentSourceId: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          startTimeUsecs: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          endTimeUsecs: assign({type: 'cNum'}, _defaultDynamicParamConfig),
        },
        help: 'reports_GDPR_object_summary',
        title: 'GDPR Object Summary',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.gdpr-objects',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/gdpr/objects.html',
        controller: 'gdprObjectsController as $ctrl',
      })
      .state('reports.agent-status', {
        url: '^/monitoring/reports/agent-status?' + [
            '{objectId}&',
            '{parentSourceId}&',
            '{startTimeUsecs}&',
            '{endTimeUsecs}',
          ].join(''),
        params: {
          objectId: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          parentSourceId: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          startTimeUsecs: assign({type: 'cNum'}, _defaultDynamicParamConfig),
          endTimeUsecs: assign({type: 'cNum'}, _defaultDynamicParamConfig),
        },
        help: 'reports_agent_status',
        title: 'Agent Status',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.agent-status',
          allClustersState: 'helios-reports',
        },
        templateUrl: 'app/reports/agent/agent.html',
        controller: 'AgentStatusReportCtrl as $ctrl',
      })
      .state('reports.archival-summary', {
        url: '^/monitoring/reports/archival-summary',
        help: 'archival_summary',
        title: 'Archival Summary',
        canAccess: reportsAccess,
        parentState: 'reports',
        allClustersSupport: {
          singleClusterState: 'reports.archival-summary',
          allClustersState: 'helios-reports',
        },
        templateUrl:
          'app/reports/archival/summary/summary.html',
        controller: 'reportsArchivalSummaryController',
        controllerAs: 'archivalSummaryObj',
      });
  }

  /**
   * The Controller for reports parent state having tabs to default report and
   * scheduled emails pages.
   *
   * @method   ReportsCtrlFn
   * @param    cReportsControlsUtil      The report util.
   */
  function ReportsCtrlFn(cReportsControlsUtil, $rootScope, HeliosService, NgIrisContextService) {
    this.reportsTabConfig = cReportsControlsUtil.getReportsTabs();
    this.showHeliosReportsLink = !$rootScope.basicClusterInfo.mcmMode;
    this.hideDeprecationMessage = getConfigByKey(NgIrisContextService.irisContext, 'reportsAjs.hideDeprecationMessage', false);
    this.hasHeliosReporting = HeliosService.heliosStatus._isRegistered || $rootScope.basicClusterInfo.mcmMode;
    this.isAppMode = HeliosService.heliosStatus.isAppMode;
    if (HeliosService.heliosStatus.heliosEndpoint) {
      this.heliosReportingLink = HeliosService.heliosStatus.heliosEndpoint + '/reporting/list';
    }
  }

  /**
   * Validate the reports state access for current transition.
   *
   * @method   checkReportsAccess
   * @param    $transition$              The transition object.
   * @param    $state                    The state service.
   * @param    NgStateManagementService  The NG state management service.
   * @param    cReportsControlsUtil      The report util.
   * @returns  Return a new target state if current to state is not accessible
   *           by the user.
   */
  function checkReportsAccess($transition$, $state, NgStateManagementService,
    cReportsControlsUtil) {
    var toState = $transition$.to();
    var reportsTabs = cReportsControlsUtil.getReportsTabs();
    var defaultTab = NgStateManagementService.getDefaultTab(reportsTabs);

    /**
     * goto default tab
     * 1. if going to parent state ie, `reports` from navigation.
     * 2. if going to any report other than scheduling pages for which the user
     *    is not having access.
     */
    if (toState.name === 'reports' ||
      (toState.name !== 'reports.scheduled-emails' &&
        !cReportsControlsUtil.canAccessReport(toState))) {
      return $state.target(defaultTab.route);
    }
  }

})(angular);
