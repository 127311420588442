import { chain } from 'lodash-es';
import { noop } from 'lodash-es';
import { some } from 'lodash-es';
import { last } from 'lodash-es';
import { findLast } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { reduce } from 'lodash-es';
import { find } from 'lodash-es';
import { forEach } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: cDbMigrationTaskSettings

/**
 * There are 2 key distinctiosn to make for DB Migration: Sync ops vs. Finalize
 * ops.
 *
 * # Sync ops
 *
 * Sync ops (which includes initial copy ops) are responsible for keeping the
 * new target DB in sync with the latest and greatest from the source DB. A copy
 * op copies the DB as it is in it's current entirety, which can take a long
 * time.
 *
 * Each subsequent sync op updates the target DB with the diff from the snapshot
 * used for the previous sync. This is faster each time.
 *
 * The User can perform a sync op as many times as they want unless the latest
 * snapshot was already successfully used for a sync op. This includes failed
 * sync ops.
 *
 * NOTE: As of 6.3, we only allow syncing to the latest snapshot. Arbitrary
 * snapshot selection will come around 6.4 timeframe along with a fancy sync
 * snapshot visualizer & picker.
 *
 * # Finalize ops
 *
 * The User can perform a finalize op only when the Migration task is
 * successfully synced to the latest snapshot.
 *
 * NOTE: As of 6.3, the User can not finalize until successfully synced to the
 * latest snapshot. Come 6.4 timeframe, this will be relaxed and the User will
 * be given a choice to sync+finalize, or just finalize given condisitions are
 * met (TBD ~ 6.4).
 */

(function _iife(angular) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.recovery.component:cDatabaseMigrationDetails
   *
   * @param  {number}   taskId   The restoreTask ID to show details of.
   *
   * @description
   *   Shows sync actions & sync history views of a DB Migration (multi-stage,
   *   hot-standby) restore task.
   *
   * @example
   *   <c-db-migration-task-details taskId="1337"></c-db-migration-task-details>
   *
   *   // or
   *
   *   stateConfig: {
   *     ...
   *     component: 'cDbMigrationTaskDetails',
   *     resolve: {
   *       taskId: function() { return thing.taskId },
   *     },
   *   }
   */
  angular.module('C.recovery')
    .controller('CDbMigrationTaskDetailsController',
      CDbMigrationTaskDetailsController)
    .component('cDbMigrationTaskDetails', {
      bindings: {
        taskId: '<',
        migrationTask: '<',
      },
      controller: 'CDbMigrationTaskDetailsController',
      templateUrl: 'app/protection/recovery/detail/c-database-migration-' +
        'details/c-database-migration-details.component.html',
    });

  /**
   * Db Migration Task Details Controller.
   *
   * @function  CDbMigrationTaskDetailsController
   * @param     {Object}  _                                Lodash library.
   * @param     {Object}  $q                               Angular's $Q service.
   * @param     {Object}  $timeout                         The $timeout service.
   * @param     {Object}  RestoreService                   The Restore Service.
   * @param     {Object}  SearchService                    The Search Service.
   * @param     {Object}  PollTaskStatus                   The PollTaskStatus
   *                                                       Service.
   * @param     {Object}  evalAJAX                         The evalAJAX Service.
   * @param     {Object}  $state                           The StateService.
   * @param     {Object}  cModal                           cModal service to
   *                                                       show modals.
   * @param     {Object}  ENUM_PULSE_PROGRESS_TASK_STATUS  A constant.
   */
  function CDbMigrationTaskDetailsController(
    _, $q, $timeout, RestoreService, SearchService, PollTaskStatus, evalAJAX,
    $state, cModal, ENUM_PULSE_PROGRESS_TASK_STATUS) {
    var $ctrl = this;
    var pollerConfig = {};

    assign($ctrl, {
      // Controller lifecycle hooks
      $onInit: $onInit,
      $onDestroy: $onDestroy,

      // Values for use in templates
      ENUM_PULSE_PROGRESS_TASK_STATUS: ENUM_PULSE_PROGRESS_TASK_STATUS,

      // Based on const ENUM_PUBLIC_STATUS
      FINISHED_STATUSES: [
        'kCanceled',
        'kError',
        'kFailure',
        'kFinished',
        'kSuccess',
      ],
      enableAutoSync: false,
      isFinished: false,
      snapshots: [],
      snapshotsHash: {},
      taskId: +$ctrl.taskId,

      // Controller methods
      canFinalize: canFinalize,
      canSync: canSync,
      finalize: finalize,
      getOverallStatus: getOverallStatus,
      getWarnings: getWarnings,
      isMigrationIdle: isMigrationIdle,
      isSyncedToLatest: isSyncedToLatest,
      last: last,
      setAutoSync: setAutoSync,
      sync: sync,
    });

    /**
     * Initialize this controller.
     *
     * @function   $onInit
     */
    function $onInit() {
      _getAllData().then(_getPulse);
    }

    /**
     * On Destroy routine when this component is torn down.
     *
     * @function   $onDestroy
     */
    function $onDestroy() {
      pollerConfig.isDoneFn = function terminator() {
        return true;
      };
    }

    /**
     * Get all data dependencies.
     *
     * @function   _getAllData
     * @return     {Object}   Promise carrying all the data responses.
     */
    function _getAllData() {
      $ctrl.depsLoading = true;

      return _getTask().then(_getDbSnapshots).finally(function dataFinally() {
        $ctrl.depsLoading = false;
        $ctrl.isFinished = $ctrl.FINISHED_STATUSES.includes(
          get($ctrl.restoreTask, 'performRestoreTaskState.base.publicStatus')
        );
        $ctrl.enableAutoSync = get($ctrl.restoreParams, 'sqlRestoreParams.isAutoSyncEnabled', false);
      });
    }

    /**
     * Gets the task by it's ID.
     *
     * @function   _getTask
     * @return     {Object}   Promise carrying the restoreTask object.
     */
    function _getTask() {
      return $ctrl.migrationTask ?
        $q.resolve($ctrl.migrationTask) :
        RestoreService.getTask($ctrl.taskId)
          .then(function taskReceived(task) {
            var rootTask =
              $ctrl.restoreTask = [].concat(task)[0];
            var restoreAppObject =
              rootTask.performRestoreTaskState.restoreAppTaskState
                .restoreAppParams.restoreAppObjectVec[0];
            var subTasks = rootTask.restoreSubTaskWrapperProtoVec;
            var multiStageRestoreAction;

            forEach(subTasks, function eachSubTask(subTask) {
              multiStageRestoreAction = get(
                subTask,
                'performRestoreTaskState.restoreAppTaskState.restoreAppParams' +
                  '.restoreAppObjectVec[0].restoreParams.sqlRestoreParams' +
                  '.multiStageRestoreOptions.multiStageRestoreAction'
              );

              assign(subTask, {
                _isInitialCopyTask: multiStageRestoreAction === 'kCreate',
                _isFinalizeTask: multiStageRestoreAction === 'kFinalize',
                _isSyncTask: multiStageRestoreAction === 'kUpdate',
              });

              subTask._subTaskType = (function taskTypeGetter() {
                switch (true) {
                case subTask._isInitialCopyTask:
                  return 'copy';

                case subTask._isFinalizeTask:
                  return 'finalize';

                default:
                  return 'sync';
                }
              })();

              if (subTask._isFinalizeTask &&
                $ctrl.restoreTask.performRestoreTaskState.base
                  .publicStatus === 'kSuccess') {
                $ctrl.finalizeSuccessful = true;
              }
            });

            assign($ctrl, {
              restoreParams: restoreAppObject.restoreParams,
              restoreEntity: restoreAppObject.appEntity,

              // A list of pulse taskPaths on sync tasks. This will be narrowed
              // down on each pulse until all are completed and this list is
              // empty. At that point, pulse will terminate.
              pulsePaths: map(
                subTasks,
                'performRestoreTaskState.progressMonitorTaskPath'
              ),

              // Map of subTasks keyed by the jobInstanceId used in that task
              // for correlation to a snapshot.
              jobInstanceIdTaskMap: keyBy(
                subTasks,
                'performRestoreTaskState.restoreAppTaskState.restoreAppParams' +
                  '.ownerRestoreInfo.ownerObject.jobInstanceId'
              ),

              // Map of jobInstanceIds keyed by the taskPath for correlation to
              // a snapshot.
              jobInstanceIdTaskPathMap: reduce(
                subTasks,
                function reducer(hash, subTask) {
                  var subTaskState = subTask.performRestoreTaskState;

                  hash[subTaskState.progressMonitorTaskPath] =
                    subTaskState.restoreAppTaskState.restoreAppParams
                      .ownerRestoreInfo.ownerObject.jobInstanceId;

                  return hash;
                },
                {}
              ),

              // Map of sub restore tasks keyed by their pulse path.
              subTasksByTaskPathMap: keyBy(
                subTasks,
                'performRestoreTaskState.progressMonitorTaskPath'
              ),
            });

            return rootTask;
          }, evalAJAX.errorMessage);
    }

    /**
     * Gets snapshots for the entity restored in the restoreTask.
     *
     * @function   _getDbSnapshots
     * @return   {Object}   Promise carrying the Yoda snapshots for the DB being
     *                      migrated.
     */
    function _getDbSnapshots() {
      return SearchService.dbSearch({
        entityIds: $ctrl.restoreEntity.id,
        jobIds: $ctrl.restoreTask.performRestoreTaskState
          .restoreAppTaskState.restoreAppParams.ownerRestoreInfo.ownerObject
          .jobId,
      }).then(
        function dataReceived(results) {
          $ctrl.snapshots = get(results, '[0].vmDocument.versions', []);
          $ctrl.selectedSnapshot = get($ctrl.snapshots, '[0]');

          // The snapshots hashed by jobInstanceId
          $ctrl.snapshotsHash =
            keyBy($ctrl.snapshots, 'instanceId.jobInstanceId');

          _decorateSnapshots();

          return $ctrl.snapshots;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Decorates snapshots as synced, error, etc.
     *
     * @function   _decorateSnapshots
     */
    function _decorateSnapshots() {
      var errorSnapshotId = 0;

      forEach($ctrl.snapshots, function eachSnapshot(snapshot) {
        var syncedTask =
          $ctrl.jobInstanceIdTaskMap[snapshot.instanceId.jobInstanceId];
        var taskBase = get(syncedTask, 'performRestoreTaskState.base', {});
        var hasError = !!taskBase.error;
        var isSynced = !hasError && taskBase.endTimeUsecs > 1;

        errorSnapshotId = hasError ?
          snapshot.instanceId.jobInstanceId : errorSnapshotId;

        assign(snapshot, {
          _hasSyncError: hasError,
          _isSyncable: !isSynced &&
            snapshot.instanceId.jobInstanceId >= errorSnapshotId,
          _isSynced: isSynced,
        });
      });
    }

    /**
     * Triggers the pulse polling for this migration task.
     *
     * @function   _getPulse
     */
    function _getPulse() {
      pollerConfig = {

        // Polling interval in seconds
        interval: 30,
        isDoneFn: _isPollingCompleted,
        iteratorFn: _getPulseData,
        maxRetries: 3,
      };

      PollTaskStatus.createPoller(pollerConfig);
    }

    /**
     * Function to determine if polling is completed or not.
     *
     * @function   _isPollingCompleted
     * @returns    {boolean}   True if polling is done.
     */
    function _isPollingCompleted() {
      var hasRunning = some($ctrl.tasksList, ['progress.status.type', 0]);
      $ctrl.wasRunning = !!$ctrl.wasRunning || hasRunning;

      if ($ctrl.wasRunning && !hasRunning) {
        _refreshData(0);
      }

      return !hasRunning;
    }

    /**
     * The function called on each polling iteration. Actually gets the task
     * data and processes the results.
     *
     * @function   _getPulseData
     * @returns    {Object}   Promise carrying the pulse results.
     */
    function _getPulseData() {
      return PollTaskStatus.getProgress({
        taskPathVec: $ctrl.pulsePaths,
        includeFinishedTasks: true,
        excludeSubTasks: false,
      }, evalAJAX.errorMessage)
        .then(function pollerUpdate(resp) {
          $ctrl.pulseData = resp.resultGroupVec;

          // Get the list of sync tasks from this response.
          $ctrl.tasksList = map(resp.resultGroupVec, 'taskVec[0]');

          forEach($ctrl.tasksList, function eachTask(task) {
            // Mark each sync task with the snapshot it was synced from.
            task._fromSnapshot =
              $ctrl.snapshotsHash[
                $ctrl.jobInstanceIdTaskPathMap[task.taskPath]
              ];

            task._synced = !!task._fromSnapshot;
            task._percentCompleted =
              chain(task.progress).get('percentFinished', 0).round(2).value();
          });

          // Rebuild the pulsePaths from the tasks. There may be additional
          // tasks if a sync or finalize op has been issued.
          if ($ctrl.tasksList.length !== $ctrl.pulsePaths.length) {
            $ctrl.pulsePaths = map($ctrl.tasksList, 'taskPath');
          }

          return resp;
        });
    }

    /**
     * Sync this task to the selected snapshot.
     *
     * NOTE: At time of writing this, Magneto hasn't implemented the RPC to
     * handle arbitrary snapshots. It will default to latest.
     *
     * @function   sync
     * @returns    {Object}   Promise with the sync subtask?
     */
    function sync() {
      if (!canSync()) { return null; }

      return RestoreService
        .syncApplicationTask($ctrl.taskId, $ctrl.selectedSnapshot)
        .then(_refreshData, evalAJAX.errorMessage);
    }

    /**
     * Finalize this task to the selected snapshot.
     *
     * NOTE: At time of writing this, Magneto hasn't implemented the RPC to
     * handle arbitrary snapshots. It will default to latest.
     *
     * @function   finalize
     * @returns    {Object}   Promise with the finalize subtask?
     */
    function finalize() {
      // TODO (spencer): ~6.4; Once we can select arbitrary snapshots to
      // finalize/sync with, implement a richer modal with a controller &
      // template to handle this.
      var modalConfig;

      if (!canFinalize()) { return null; }

      modalConfig = {
        actionButtonKey: 'finalize',
        contentKey: 'databaseMigration.modals.finalizeContent',
        contentKeyContext: { snapshot: $ctrl.selectedSnapshot },
        titleKey: 'finalizeMigration',
      };

      return cModal.standardModal(null, modalConfig)
        .then(function proceedToFinalize(/* opts */) {
          // TODO (spencer): Handle opts param ~6.4 to determine which snapshot
          // to finalize with.
          return RestoreService
            .finalizeApplicationTask($ctrl.taskId, $ctrl.selectedSnapshot)
            .then(_refreshData, evalAJAX.errorMessage);
        }, noop);
    }

    /**
     * Sets the auto-sync setting for this task.
     *
     * @returns  {object}  Promise with the response.
     */
    function setAutoSync() {
      return RestoreService.setAutoSync($ctrl.taskId, $ctrl.enableAutoSync)
        .then(_refreshData, evalAJAX.errorMessage);
    }

    /**
     * Delays the _getAllData request.
     *
     * @function   _refreshData
     * @param      {number}   [delay=500]   Millisecond delay duration.
     */
    function _refreshData(delay) {
      $timeout($state.reload.bind(null), delay || 500);
    }

    /**
     * Determines if a finalize action can be performed.
     *
     * @function   canFinalize
     * @returns    {boolean}   True if a finalize action can be performed.
     */
    function canFinalize() {
      return isMigrationIdle() && isSyncedToLatest();
    }

    /**
     * Determines if a sync action can be performed.
     *
     * @function   canSync
     * @returns    {boolean}   True if a sync action can be performed.
     */
    function canSync() {
      return !$ctrl.enableAutoSync && isMigrationIdle() && !isSyncedToLatest();
    }

    /**
     * Determines if we're synched to the latest snapshot or not.
     *
     * @function   isSyncedToLatest
     * @returns    {boolean}   True if we're synched to the latest snapshot.
     */
    function isSyncedToLatest() {
      // Path for use in get
      var syncJobInstanceIdPath =
        'performRestoreTaskState.restoreAppTaskState.restoreAppParams' +
        '.ownerRestoreInfo.ownerObject.jobInstanceId';

      // Find the most recent sync op. We are not concerned with finalize ops.
      var latestSyncTask = findLast(
        // These are sorted oldest to newest.
        $ctrl.restoreTask.restoreSubTaskWrapperProtoVec,
        function findFirstSync(task) {
          return task._subTaskType !== 'finalize';
        }
      );

      // Compare the jobInstanceId used in the latest sync op with the latest
      // snapshot instanceId. If there are no errors on the latest sync op and
      // the instacneIds match, the snapshot is synced.
      return !latestSyncTask.performRestoreTaskState.base.error &&
        (get(latestSyncTask, syncJobInstanceIdPath) ===
          get($ctrl.snapshots, '[0].instanceId.jobInstanceId', 1));
    }

    /**
     * Determines if the migration task is idle or not.
     *
     * Idle means the Migration task hasn't finished, but there are no active
     * update tasks currently running.
     *
     * @function   isMigrationIdle
     * @returns    {boolean}   True if the task is running and idle, false
     *                         otherwise.
     */
    function isMigrationIdle() {
      // Is the overall task still running?
      return !$ctrl.FINISHED_STATUSES.includes(getOverallStatus()) &&
        // And is the latest sync/finalize action done?
        !!chain($ctrl.tasksList).last().get('progress.endTimeSecs').value();
    }

    /**
     * Gets the overall Migration task status.
     *
     * @function   getOverallStatus
     * @returns     {string}   The status kValue.
     */
    function getOverallStatus() {
      return get(
        $ctrl,
        'restoreTask.performRestoreTaskState.base.publicStatus',
        'kRunning'
      );
    }

    /**
     * Gets the warnings from the most recent subtask.
     *
     * @function   getWarnings
     * @returns    {Array}    The warning array.
     */
    function getWarnings() {
      return get(
        $ctrl.last($ctrl.restoreTask.restoreSubTaskWrapperProtoVec),
        'performRestoreTaskState.base.warnings'
      );
    }
  }
})(angular);
