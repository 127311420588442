import { assign } from 'lodash-es';
// Component: Office365 Mailboxes(Mailbox/Onedrive) search component.

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.office365Recovery')
    .controller('Office365MailboxesSearchCtrl', office365MailboxesSearchCtrlFn)
    .component('office365MailboxesSearch', {
      templateUrl:
        'app/protection/recovery/office365/search/mailboxes/mailboxes.html',
      controller: 'Office365MailboxesSearchCtrl',
    });

  /**
   * @ngdoc component
   * @name C.officeRecoverySearch:office365MailboxesSearch
   * @function
   * @param   {object}   _                       Lodash Library
   * @param   {object}   RecoveryStore           Cart service
   * @param   {object}   SearchService           Search service
   * @param   {object}   cSearchService          c-search-service
   * @param   {object}   OFFICE365_SEARCH_TYPE   search types available for
   *                                             Office365 recovery flow
   *
   * @description
   *  Component to provide search functionality for Office365 mailbox search.
   *  Mailbox object search is carried out by sending requests to
   *  Elastic Search(ES) through iris_exec to query under the index 'objindex'.
   *  Granular email search is carried out by sending requests to librarian.
   */
  function office365MailboxesSearchCtrlFn(_, RecoveryStore, SearchService,
    cSearchService, OFFICE365_SEARCH_TYPE, $stateParams) {
    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle hooks.
      $onInit: $onInit,

      state: {
        // Specifies domain UUIDs to search for protected mailbox within ES.
        selectedDomainUuids: [],
        selectedJobs: [],
        selectedMailboxes: [],
        selectedServers: [],
      },

      clearCartAndSearch: clearCartAndSearch,
      preProcessSearchResults: preProcessSearchResults,
      searchTypes: [
        OFFICE365_SEARCH_TYPE.kMailboxSearch,
        OFFICE365_SEARCH_TYPE.kEmailSearch,
      ],
    });

    /**
     * @method   $onInit
     *
     * Initializes component.
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();
      if ($stateParams.shouldPreserveCart) {
        return;
      }
      $ctrl.shared.searchItemType = OFFICE365_SEARCH_TYPE.kMailboxSearch;
      clearCartAndSearch();
    }

    /**
     * Processes the search results for the Mailboxes.
     *
     * @method   preProcessSearchResults
     * @param    {object}   results   Mailboxes containing search results.
     * @return   {object}   Mailboxes containing decorated search results.
     */
    function preProcessSearchResults(results) {
      return SearchService.transformPublicSearchResults(
        results, $ctrl.shared.searchItemType, true);
    }

    /**
     * Clears the recovery cart and the search results when the search type is
     * changed.
     * NOTE: Although both iris and magneto support recovery of mailbox,
     * folders & emails in a single recovery job but currently this is just to
     * segregate the flow. This method may be removed in future.
     *
     * @method   clearCartAndSearch
     */
    function clearCartAndSearch() {
      $ctrl.shared.cart.removeAll();
      _resetOffice365MailboxesSearch();
    }

    /**
     * Clears any old search result for Office365 object and resets the
     * c-search filters.
     *
     * @method   _resetOffice365MailboxesSearch
     */
    function _resetOffice365MailboxesSearch() {
      cSearchService.purgeResults($ctrl.shared.searchId);
      cSearchService.resetFilters($ctrl.shared.searchId, true);
      if ($ctrl.cSearchApi) {
        $ctrl.cSearchApi.resetSearch();
      }
    }
  }
})(angular);
