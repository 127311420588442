import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule, CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { PipesModule } from '../pipes';
import { BlankCardComponent } from './blank-card/blank-card.component';
import { ProtectionStatsHeaderComponent } from './card-statlist/protection-stats-header.component';
import { ProtectionCardComponent } from './protection-card/protection-card.component';
import { RunFailuresStatusComponent } from './protection-card/run-failures-status/run-failures-status.component';
import { SlaComplianceCardComponent } from './sla-compliance-card/sla-compliance-card.component';
import { MissedSlaStatusComponent } from './sla-compliance-alt-card/missed-sla-status/missed-sla-status.component';
import { SlaComplianceAltCardComponent } from './sla-compliance-alt-card/sla-compliance-alt-card.component';
import { TopologyElementComponent } from './topology-element/topology-element.component';
import { TopologyGraphComponent } from './topology-graph/topology-graph.component';
import { SlaGroupRunComplianceComponent } from './sla-compliance-alt-card/sla-group-run-compliance/sla-group-run-compliance.component';

const COMPONENTS = [
  BlankCardComponent,
  MissedSlaStatusComponent,
  ProtectionCardComponent,
  ProtectionStatsHeaderComponent,
  SlaComplianceCardComponent,
  SlaComplianceAltCardComponent,
  RunFailuresStatusComponent,
  TopologyElementComponent,
  TopologyGraphComponent,
  SlaGroupRunComplianceComponent,
];

/**
 * Module for reusable dashcards.
 */
@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    CohesityHelixModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatSliderModule,
    PipesModule,
    TranslateModule.forChild(),
    UIRouterModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DashcardsModule { }
