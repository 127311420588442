import { toArray } from 'lodash-es';
// Factory: JobActionFormatter contains methods that will format data for any
// job actions before it is sent to the API

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .factory('JobActionFormatter', jobActionFormatterFn);

  function jobActionFormatterFn() {
    return {
      backupNowFormatter: backupNowFormatter,
      editRunFormatter: editRunFormatter,
      formatJobUid: formatJobUid,
    };

    /**
     * Calls functions to format results from backup now modal before they are
     * sent to the API.
     *
     * @param    {object}   result   Contains values that where selected in
     *                               the backup now modal.
     * @return   {object}   Populated data object to be sent to the API.
     */
    function backupNowFormatter(result) {
      var backupNowRunData = {
        copyRunTargets: [],
        runNowParameters: [],
        runType: result.runType,
        usePolicyDefaults: result.usePolicyDefaults,
      };

      var formattedArchivalTargets =
        backupNowArchivalTargetsFormatter(result.archivalTargets, result.runType);

      var replicaVec =
        backupNowReplicationTargetsFormatter(result.replicationTargets, result.runType);

      var formattedReplicationTargets = replicaVec.replicationTargets;

      var formattedCloudReplicationTargets = replicaVec.cloudReplicationTargets;

      backupNowRunData.copyRunTargets =
        formattedArchivalTargets.concat(
          formattedReplicationTargets, formattedCloudReplicationTargets);

      // If policy defaults are set, copy run targets are ignored and not sent.
      if (backupNowRunData.usePolicyDefaults) {
        backupNowRunData.copyRunTargets = undefined;
      }

      backupNowRunData.runNowParameters =
        runNowParamsFormatter(result.objectsList);

      return backupNowRunData;
    }

    /**
     * Calls functions to format results from backup now modal before they are
     * sent to the API.
     *
     * @method   editRunFormatter
     * @param    {object}   result   Contains values that where selected in the
     *                               backup now modal.
     * @return   {object}   Populated data object to be sent to the API.
     */
    function editRunFormatter(result) {
      var editRunData = {
        jobRuns: [
          {
            copyRunTargets:
              backupNowReplicationTargetsFormatter(
                result.replicationTargets).replicationTargets
              .concat(
                backupNowArchivalTargetsFormatter(result.archivalTargets)),
            runStartTimeUsecs: result.runStartTimeUsecs,
            jobUid: formatJobUid(result.jobUid),
          }
        ]
      };

      if (result.localBackup) {
        editRunData.jobRuns[0].copyRunTargets.push(result.localBackup);
      }

      return editRunData;

    }

    /**
     * Formats the jobUid object as is needed by the API.
     *
     * @method   formatJobUid
     * @param    {object}   jobUid   jobUid object
     * @return   {object}   Formatted jobUid object
     */
    function formatJobUid(jobUid) {
      return {
        clusterId: jobUid.clusterId,
        clusterIncarnationId: jobUid.clusterIncarnationId,
        id: jobUid.objectId,
      };
    }

    /**
     * Converts an array of objects to an array of run now params. Run params
     * element consists of the source id and an optional list of database ids
     *
     * @param   {array}   objectsList    List of Objects that are associated to
     *                                   the selected job.
     * @return  {array}   New array containing source ids and optional list of
     *                    database ids.
     */
    function runNowParamsFormatter(objectsList) {
      // Start by making a map of source ids to entries
      var srcMap = objectsList.reduce(function toParamsMap(paramMap, object) {
        var sourceId = object.entity.id;

        // Exchange on prem is installed on a physical server.
        // We also support vm with exchange application registered
        // but does not support database backup.
        var isExchangeOnPrem = object._isExchangeHost && !object._isHypervisor && !object._isPhysicalEntity;

        if (object._isSqlEntity) {
          // entity.parentId is different for vm and physical systems, so
          // owner id is the better choice to use.
          sourceId = object.entity.sqlEntity.ownerId;

        // Is Oracle source or a exchange on prem.
        } else if (object._isOracleSource || isExchangeOnPrem) {
          sourceId = object.entity.parentId;
        }
        paramMap[sourceId] = paramMap[sourceId] || { sourceId: sourceId };
        if (object._isSqlEntity || object._isOracleSource || isExchangeOnPrem) {
          paramMap[sourceId].databaseIds = paramMap[sourceId].databaseIds || [];
          paramMap[sourceId].databaseIds.push(object.entity.id);
        }
        return paramMap;
      }, {});

      return toArray(srcMap);
    }

    /**
     * Loops over array of archivalTargets, if the target has been selected we
     * will create a formatted object with the target details that the API
     * endpoint requires.
     *
     * @param    {array}   archivalTargets   Array of archivalTarget objects.
     * @param    {string}  runType           Backup run type
     *
     * @return   {array}   New array of formatted arhivalTargets.
     */
    function backupNowArchivalTargetsFormatter(archivalTargets, runType) {
      return [].concat(archivalTargets).reduce(
        function archivalFormatter(accumulator, archivalTarget) {
          var vaultType =
            vaultTypeClassifier(
              archivalTarget.target.externalTargetType ||
              archivalTarget.target.vaultType ||
              archivalTarget.target.type
            );

          if (archivalTarget.target) {
            accumulator.push({
              archivalTarget: {
                vaultId:
                  archivalTarget.target.vaultId || archivalTarget.target.id,
                vaultName:
                archivalTarget.target.vaultName || archivalTarget.target.name,
                vaultType: vaultType,
              },
              copyPartial: archivalTarget.copyPartial,
              daysToKeep: runType === 'kLog' && archivalTarget?.daysToKeepLog ?
                archivalTarget?.daysToKeepLog: archivalTarget.daysToKeep,
              type: 'kArchival',
              holdForLegalPurpose: archivalTarget.target.holdForLegalPurpose
            });
          }

          return accumulator;
      }, []);
    }

    /**
     * This function takes in vaultType according icebox/magneto conventions and
     * classifies them into vaultType need by magneto
     *
     * @method   vaultTypeClassifier
     * @param    {number|string}   vaultType   The vault type
     * @return   {string}            vaultType in string form needed by Magneto
     */
    function vaultTypeClassifier(vaultType) {

      if ([1, 'kQStarTape'].includes(vaultType)) {
        return 'kTape';
      } else if ([2, 'kNas'].includes(vaultType)) {
        return 'kNas';
      }

      return 'kCloud';
    }

    /**
     * Loops over array of replicationTargets, if the target has been selected
     * we will create a formatted object with the target details that the API
     * endpoint requires.
     *
     * @param    {array}   replicationTargets   Array of replicationTarget
     *                                          objects.
     * @param    {string}  runType              Backup run type
     *
     * @return   {object}    Object with replication and cloud replication targets
     */
    function backupNowReplicationTargetsFormatter(replicationTargets, runType) {
      var replicaVec = {
        replicationTargets: [],
        cloudReplicationTargets: [],
      };

      [].concat(replicationTargets).forEach(
        function replicationFormatter(replicationTarget) {
          const daysToKeep = runType === 'kLog' && replicationTarget?.daysToKeepLog ?
            replicationTarget?.daysToKeepLog: replicationTarget.daysToKeep;

          if (replicationTarget.target) {
            replicaVec.replicationTargets.push({
              copyPartial: replicationTarget.copyPartial,
              daysToKeep,
              replicationTarget: {
                clusterId: replicationTarget.target.clusterId,
                clusterName: replicationTarget.target.clusterName ||
                  replicationTarget.target.name,
              },
              type: 'kRemote',
              holdForLegalPurpose: replicationTarget.target.holdForLegalPurpose
            });
          } else if (replicationTarget.cloudTarget) {
            replicaVec.cloudReplicationTargets.push({
              copyPartial: replicationTarget.copyPartial,
              daysToKeep,
              cloudReplicationTarget: replicationTarget.cloudTarget,
              type: 'kCloudReplication',
            });
          }
      });

      return replicaVec;
    }
  }
})(angular);
