import { assign } from 'lodash-es';
// component: component to show the license usage dashboard

(function(angular) {
  'use strict';

  angular
    .module('C.licenseServer', ['C.upload', 'C.licenseUploadDownload'])
    .config(configFn)
    .component('licenseServer', {
      templateUrl: 'app/license/license.html',
      controller: 'licenseServerCtrl',
      bindings: {
        // binding to be used if we want to open upload license
        // file modal on page load
        openModal: '@?',
      }
    })
    .controller('licenseServerCtrl', licenseServerCtrlFn);


  // component config function
  function configFn($stateProvider) {
    var canAccess = [
      'CLUSTER_VIEW',
      'FEATURE_FLAGS.licensing'
    ].join(' && ');

    $stateProvider.state('license-deprecated', {
      url: '^/license',
      title: 'User License',
      help: 'platform_license',
      canAccess: canAccess,
      parentState: 'access-management',
      allClustersSupport: true,
      component: 'licenseServer',
      params: {
        openModal: {
          type: 'string',
          value: ''
        }
      },
      resolve: {
        openModal: function resolve($transition$) {
          return $transition$.params().openModal;
        },
      },
      redirectTo(transition) {
          var featureFlag = transition.injector().get('FEATURE_FLAGS');
          var routeParams = transition.params();
          var routeOptions = transition.options();
          if (featureFlag.licensingRevampEnabled) {
            return transition.router.stateService.target('license', routeParams, routeOptions);
          }
      }
    });
  }

  function licenseServerCtrlFn(_, $rootScope, LicenseServerService, evalAJAX,
    SlideModalService, $q, $state, ClusterService, FEATURE_FLAGS) {

    var $ctrl = this;

    assign($ctrl, {
      // properties
      loading: true,
      name: '',
      mcmMode: $rootScope.basicClusterInfo.mcmMode,

      //Configuration to Define the size of widgets
      widgetConfig: {
        nColumn: 2,
        minHeight: '8rem',
        gutter: '2rem',
      },

      // methods
      $onInit: $onInit,
      downloadAuditReport: LicenseServerService.downloadAuditReport,
      openUploadDownloadFileModal: openUploadDownloadFileModal,
      openGenerateLicenseModal: openGenerateLicenseModal,
      uploadComponent: uploadComponent,
    });

    /**
     * Initialize this component.
     *
     * @method   $onInit
     */
    function $onInit() {
      var promises = {};

      $ctrl.allClusterSelected = !$rootScope.currentCluster ||
        $rootScope.currentCluster._allClusters;

      promises.license = LicenseServerService.getLicenseData();
      promises.user =  LicenseServerService.checkClassifiedUser();

      if ($ctrl[$ctrl.openModal]) {
        $ctrl[$ctrl.openModal]();
      }

      $q.all(promises).then(function gotData(response) {
        $ctrl.widgets = response.license.widgets;
        $ctrl.data = response.license.widgetData;
        $ctrl.lastUpdateTime = response.license.lastUpdateTime
        $ctrl.isClassifiedUser = $ctrl.mcmMode ? response.user.isClassified :
          response.user.IsClusterActivated;
      }, evalAJAX.errorMessage);
    }

    /**
     * opens modal for upload/download license
     *
     * @method openUploadDownloadFileModal
     *
     * @return {Object}  Promise object for modal
     */
    function openUploadDownloadFileModal() {
      // if user skipped licensing in starting of the cluster-claim flow
      // take him to cluster-claim flow on click of upload license
      if (ClusterService.clusterInfo.licenseState.state === 'kSkipped') {
        if (FEATURE_FLAGS.clusterLicensePageRevamp) {
          $state.go('connected-site-ng.select-mode');
        } else {
          $state.go('connected-site', { registrationType: 'saas' });
        }
      }

      LicenseServerService.openUploadModal();
    }

    /**
     * opens up upload modal which decides what the user is supposed to
     * upload depending on whether the user is on helios or cluster
     *
     * @method  uploadComponent
     */
    function uploadComponent() {
      LicenseServerService.openUploadLicensePageModal();
    }

    /**
     * opens up Generate License modal
     * It will generate license file/key depending on the type of user
     *
     * @method  openGenerateLicenseModal
     */
    function openGenerateLicenseModal() {
     LicenseServerService.openGenerateLicenseModal();
    }
  }
})(angular);
