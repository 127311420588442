import { find } from 'lodash-es';
// Helios Reports Service

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.HeliosReportsService', [
      'C', // for RemoteAccessClusterService
      'C.slideModal', // for SlideModalService
      'C.HeliosReportsServiceFormatter',

      // dependencies for resolving schedules details.
      'C.mcmSourceService',
    ])
    .service('HeliosReportsService', heliosReportsServiceFn);

  /**
   * @ngdoc service
   * @name      C.HeliosReportsService
   *
   * @function  heliosReportsServiceFn
   * @description
   *  Provides  the services for Helios Reports from the JReports.
   */
  function heliosReportsServiceFn(_, $http, API, $q, SlideModalService,
    HeliosReportsServiceFormatter, RemoteClusterService, McmSourceService,
    FEATURE_FLAGS, ENUM_FETCH_TYPE, ENUM_TASK_TYPE, NgClusterService) {

    /**
     * builtin Reports config.
     *
     * @property  {String}  labelKey            Report name translation key.
     * @property  {String}  params.reportType   Report Type which is send to
     *    backend to fetch report and will be shown in the URL.
     * @property  {Array}   filter.floor        List of most used filter fields
     *    that will be shown always in the page.
     * @property  {Array}   filter.ceil         List of rest of the filter
     *    fields that will be visible only on view all filters view.
     */
    var reportsConfig = [];

    populateReportsConfig();

    return {
      deleteReportSchedule: deleteReportSchedule,
      getReportSchedules: getReportSchedules,
      getReportsConfig: getReportsConfig,
      launchDownloadReport: launchDownloadReport,
      upsertReportSchedule: upsertReportSchedule,
    };

    /**
     * Get the list of builtin reports config.
     *
     * @method  getReportsConfig
     * @return  {Array}          The list of builtin reports config.
     */
    function getReportsConfig() {
      return reportsConfig;
    }

    /**
     * Launch modal to download the report.
     *
     * @method  launchDownloadReport
     * @return  {Promise} resolved with selected output format
     */
    function launchDownloadReport() {
      return SlideModalService.newModal({
        resolve: {
          innerComponent: 'exportReport',
          titleKey: 'exportReport',
          actionButtonKey: 'download',
        },
        autoHeight: true,
      });
    }

    /**
     * Creates or updates report email schedule.
     *
     * @method    upsertReportSchedule
     * @param     {Object}    filter         The applied filters.
     * @param     {Object}    schedule       The scheduled report config.
     * @param     {Object}    reportConfig   The selected report config.
     * @return    {Object}    Promise resolved with updated report schedule else
     *                        rejected with error.
     */
    function upsertReportSchedule(filters, schedule, reportConfig) {
      let params = HeliosReportsServiceFormatter
        .getScheduleReportParams(filters, schedule, reportConfig);

      return $http({
        method: 'POST',
        data: params,
        url: API.mcm('heliosReports/submitSchedule')
      });
    }

    /**
     * Delete an scheduled report.
     *
     * @method    deleteReportSchedule
     * @param     {Object}    taskId      The taskId of the schedule to delete.
     * @return    {Object}    Promise resolved on successful deletion of a
     *                        schedule else rejected with error.
     */
    function deleteReportSchedule(taskId) {
      return $http({
        method: 'POST',
        data: { taskId },
        url: API.mcm('heliosReports/deleteSchedule'),
      });
    }

    /**
     * Resolve the scheduled reports with clusters, sources etc details.
     *
     * @method   resolveSchedulesDetails
     * @return   {Object}    Promise resolved with the list of scheduled reports
     *                       with resolved details of there properties else
     *                       rejected with error.
     */
    function resolveSchedulesDetails(schedulesList) {
      const runStatus = HeliosReportsServiceFormatter.getJobRunStatus();
      const objectTypes = HeliosReportsServiceFormatter.getObjectTypes();

      schedulesList.forEach(function eachSchedule(schedule) {
        var currentReportConfig = getReportsConfig().find(
          function isFetchType(report) {
            return report.params.reportType === schedule.reportType;
          }
        );

        if (schedule.filterParams.RunStatus) {
          schedule.filterParams._runStatus =
            schedule.filterParams.RunStatus.map(entry => (
              find(runStatus, ['numericalValue', +entry])
            )).filter(Boolean);
        }
        if (schedule.filterParams.ObjectTypes) {
          schedule.filterParams._objectType =
            schedule.filterParams.ObjectTypes.map(entry => (
              find(objectTypes, ['numericalValue', +entry])
            )).filter(Boolean);
        }
        if (currentReportConfig.filter.floor.concat(
          currentReportConfig.filter.ceil).indexOf('fetchType') != -1) {
          schedule.filterParams.fetchType = {
            label: ENUM_FETCH_TYPE[schedule.filterParams.FetchType],
            value: schedule.filterParams.FetchType,
          };
        }
        if (currentReportConfig.filter.floor.concat(
          currentReportConfig.filter.ceil).indexOf('taskType') != -1) {
          schedule.filterParams.taskType = {
            label: schedule.filterParams.TaskType ?
              schedule.filterParams.TaskType[0]:'Backup',
            stringValue: schedule.filterParams.TaskType ?
              schedule.filterParams.TaskType[0]:'Backup',
          };
        }
      });

      return $q.allWithMerge([
        RemoteClusterService.resolveClusterDetails(
          schedulesList, 'filterParams.ClusterIdentifiers'),
        McmSourceService.resolveRootNodesDetails(
          schedulesList, 'filterParams.RegisteredSourceIds'),
      ]);
    }

    /**
     * gets the list of scheduled reports.
     *
     * @method    getReportSchedules
     * @return    {Object}   Promise resolved with the list of scheduled reports
     *                       else rejected with error.
     */
    function getReportSchedules() {
      return $http({
        method: 'POST',
        url: API.mcm('heliosReports/getSchedules'),
      }).then(function gotTasksDetails(response) {
        return resolveSchedulesDetails((response.data || []).map(
          HeliosReportsServiceFormatter.transformSchedule))
          .then(function onSuccess(schedulesList) {
            return schedulesList.map(
              HeliosReportsServiceFormatter.decorateWithHumanizedFilterValue);
          });
      });
    }

    /**
     * populates the reports config by checking the feature flags.
     *
     * @method    populateReportsConfig
     */
    function populateReportsConfig(){
      // Add Backup Summary Report.
      if (FEATURE_FLAGS.heliosReportsBackupSummaryEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.backupJobsSummary',
          params: {
            reportType: 'backup-summary',
          },
          filter: {
            floor: ['clusters', 'dateField'],
            ceil: ['sources', 'runStatus'],
          },
        });
      }

      // Add Clone Recovery Summary Report.
      if (FEATURE_FLAGS.heliosReportsCloneRecoverySummaryEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.restoreJobsSummary',
          params: {
            reportType: 'clone-recovery-summary',
          },
          filter: {
            floor: ['dateField', 'sources', 'clusters'],
            ceil: ['objectTypes'],
          },
        });
      }

      // Add Cluster Connections Report.
      if (FEATURE_FLAGS.heliosReportsClusterConnectionsEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.clusterConnection',
          params: {
            reportType: 'cluster-connection',
          },
          filter: {
            floor: ['clusters', 'dateField'],
            ceil: [],
          },
        });
      }

      // Add Cluster Wide Storage Report.
      if (FEATURE_FLAGS.heliosReportsClusterWideStorageEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.clusterWideStorage',
          params: {
            reportType: 'cluster-wide-storage',
          },
          filter: {
            floor: ['clusters', 'dateField'],
            ceil: [],
          },
        });
      }

      // Add Data Consumed By Backups Report.
      if (FEATURE_FLAGS.heliosReportsDataConsumedByBackupsEnabled && !NgClusterService.isMcmOnPrem) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.dataJobReport',
          params: {
            reportType: 'data-consumed-by-backups',
          },
          filter: {
            floor: ['clusters'],
            ceil: ['varianceOverDays', 'sources'],
          },
        });
      }

      // Add Failed Objects Report.
      if (FEATURE_FLAGS.heliosReportsFailedObjectsEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.failedObjects',
          params: {
            reportType: 'failed-objects',
          },
          filter: {
            floor: ['clusters', 'dateField', 'fetchType', 'taskType'],
            ceil: ['sources', 'runStatus', 'objectTypes'],
          },
        });
      }

      // Add Protection Summary By Object Type Report.
      if (FEATURE_FLAGS.heliosReportsProtectionSummaryByObjectTypeEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.protectionSummary',
          params: {
            reportType: 'protection-summary-objects',
          },
          filter: {
            floor: ['clusters', 'dateField'],
            ceil: ['objectTypes'],
          },
        });
      }

      // Add Protection Summary By Cluster Report.
      if (FEATURE_FLAGS.heliosReportsProtectionSummaryByClusterEnabled && !NgClusterService.isMcmOnPrem) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.protectionSummaryCluster',
          params: {
            reportType: 'protection-summary-cluster',
          },
          filter: {
            floor: ['clusters', 'dateField'],
            ceil: ['taskTypes'],
          },
        });
      }

      // Add SLA Violations Report.
      if (FEATURE_FLAGS.heliosReportsSLAViolationsEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.slaViolationsReport',
          params: {
            reportType: 'sla-violations',
          },
          filter: {
            floor: ['clusters', 'dateField'],
            ceil: ['sources', 'runStatus', 'objectTypes'],
          },
        });
      }

      // Add Storage Consumed By Backups Report.
      if (FEATURE_FLAGS.heliosReportsStorageConsumedByBackupsEnabled && !NgClusterService.isMcmOnPrem) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.storageJobReport',
          params: {
            reportType: 'storage-consumed-by-backups',
          },
          filter: {
            floor: ['clusters'],
            ceil: ['varianceOverDays', 'sources'],
          },
        });
      }

      // Add Top Jobs Report.
      if (FEATURE_FLAGS.heliosReportsTopJobsEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.topJobsReport',
          params: {
            reportType: 'top-jobs',
          },
          filter: {
            floor: ['clusters', 'dateField'],
            ceil: ['sources', 'runStatus', 'objectTypes'],
          },
        });
      }

      // Add Unprotected and Protected Objects Report.
      if (FEATURE_FLAGS.heliosReportsUnprotectedProtectedObjectsEnabled) {
        reportsConfig.push({
          labelKey: 'reportsControls.names.objectsByProtection',
          params: {
            reportType: 'unprotected-protected-objects',
          },
          filter: {
            floor: ['clusters'],
            ceil: ['sources'],
          },
        });
      }

    }
  }
})(angular);
