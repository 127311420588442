import { assign } from 'lodash-es';
// Component: Secured Views list

;(function(angular, undefined) {
  'use strict';

  angular.module('C.antivirus')
    .component('securedViewsList', {
      templateUrl: 'app/antivirus/secured-views/list/list.html',
      controller: securedViewsListCtrlFn,
      bindings: {
        // @type   {object}   list of secured Views
        securedViews: '<',

        // @type   {boolean}   Whether provider configured. If false, then hide
        // the add view button.
        providersConfigured: '<?',

        // @type   {boolean}   Whether to show loading animation
        loading: '<',
      },
    });

  /**
   * @ngdoc component
   * @name C.antivirus:securedViewsList
   * @function securedViewsListCtrlFn
   *
   * @description
   * Provides list of secured Views.
   *
   * @example
      <secured-views-list
        loading="$ctrl.loadingViews"
        secured-views="$ctrl.securedViews"
        providers-configured="$ctrl.providersConfigured”>
      </secured-views-list>
   */
  function securedViewsListCtrlFn(_, $state, AntivirusService) {
    var $ctrl = this;

    assign($ctrl, {
      addSecuredView: addSecuredView,
      getScanTriggerText: AntivirusService.getScanTriggerText,
    });

    /**
     * Opens modal to enable AV on a selected View.
     *
     * @method     addSecuredView
     */
    function addSecuredView() {
      AntivirusService.addSecuredView().then(
        function addedSecuredView() {
          $state.reload();
        }
      );
    }
  }
})(angular);
