import { isUndefined } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Select KMS Key
import { KmsModel } from 'src/app/modules/cluster/kms/models/kms.model';

;(function(angular) {
  'use strict';

  angular.module('C.kmsSelector', [])
    .component('cKmsSelector', {
      bindings: {
        /**
         * model for this component
         *
         * @type  {Object}
         */
        ngModel: '=',

        /**
         * Function to determine if and when to show an info message
         */
        infoMessage: '<',

        /**
         * Should the kms selection be disabled
         */
        disable: '<',

        /**
         * A function to determine if kms selector should be disabled.
         * The selected KmsKeyType, and the selected kmsKey are
         * passed as parameters to the function.
         */
        disableKmsSelectorFn: '<',

        /**
         * Callback function to be invoked when a kms key is selected
         */
        kmsSelected: '<'
      },
      controller: 'kmsSelectorCtrl',
      templateUrl: 'app/global/c-kms-selector/c-kms-selector.html',
    })
    .controller('kmsSelectorCtrl', kmsSelectorCtrlFn);

  /**
   * @ngdoc component
   * @name C.kmsSelector:kmsSelectorModal
   * @function
   *
   * @description
   * Show a dropdown to select a KMS Key
   */
  function kmsSelectorCtrlFn(ClusterService, evalAJAX, FEATURE_FLAGS) {
    var $ctrl = this;

    assign($ctrl, {
      // properties
      kmsTypes: KmsModel.KeyTypeLabels,
      keyTypes: KmsModel.KeyTypes,
      isEdit: false,
      isFeatureEnabled: FEATURE_FLAGS.ngKMS,

      // members
      $onInit: $onInit,
      kmsServiceTypeSelected: kmsServiceTypeSelected,
      shouldDisable: shouldDisable,
    });

    /**
     * Initialize the component
     */
    function $onInit() {
      if (!$ctrl.isFeatureEnabled) {
        return;
      }

      // Initialize the callback function, if empty
      $ctrl.kmsSelected = $ctrl.kmsSelected || function() {};

      ClusterService.getExternalKms()
        .then(kms => {
          $ctrl.allKeys = kms;
          $ctrl.internalKey =
            $ctrl.allKeys.find(key => key.serverType === $ctrl.keyTypes.kInternalKMS);

          if (!$ctrl.ngModel.kmsServerId) {
            $ctrl.ngModel.kmsServiceType = $ctrl.keyTypes.kInternalKMS;
            $ctrl.ngModel.kmsServerId = $ctrl.internalKey.id;
          } else {
            $ctrl.ngModel.kmsServiceType = kms.find(key => key.id === $ctrl.ngModel.kmsServerId).serverType;
            kmsServiceTypeSelected($ctrl.ngModel.kmsServiceType, true);
            $ctrl.isEdit = true;
          }
        }, evalAJAX.errorMessage);

    }

    /**
     * Filter keys when service type is selected
     * @param {String}  kms The selected kms key type
     * @param {boolean} preventReset true if ngModel should not be reset
     */
    function kmsServiceTypeSelected(kms, preventReset) {
      if (!preventReset) {
        $ctrl.ngModel.kmsServerId =
          kms === $ctrl.keyTypes.kInternalKMS ? $ctrl.internalKey.id : undefined;
      }

      $ctrl.keys = $ctrl.allKeys.filter(key => key.serverType === kms);
    }

    /**
     * Check for the disable/disableKmsSelectorFn binding passed to the
     * component and disable it if needed.
     */
    function shouldDisable() {
      if (!isUndefined($ctrl.disable)) {
        return $ctrl.disable;
      }

      if (!isUndefined($ctrl.disableKmsSelectorFn)) {
        return $ctrl.disableKmsSelectorFn(
          $ctrl.ngModel.kmsServiceType, $ctrl.ngModel.kmsServerId);
      }
    }
  }

})(angular);