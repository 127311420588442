import { flatten } from 'lodash-es';
import { find } from 'lodash-es';
import { map } from 'lodash-es';
import { get } from 'lodash-es';
// Service: External Target Service

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.externalTargetService', [
      'C.externalTargetsServiceFormatter', 'ngFileUpload'])
    .service('ExternalTargetService', ExternalTargetServiceFn);

  function ExternalTargetServiceFn(
    $rootScope, $http, $q, $window, ExternalTargetsServiceFormatter, API,
    cModal, SlideModalService, Upload, RemoteAccessClusterService, ENV_GROUPS,
    ARCHIVE_EXTERNAL_TARGETS, SNAPSHOT_TARGET_TYPE, NgPassthroughOptionsService) {

    var TargetSvc = {
      // methods
      downloadKeyFile: downloadKeyFile,
      getExternalTargetTypesList: getExternalTargetTypesList,
      getGlobalBandwidthSettings: getGlobalBandwidthSettings,
      getTargetName: getTargetName,
      getTargets: getTargets,
      getTargetType: getTargetType,
      isIndexingComplete: isIndexingComplete,
      isOverallTaskDone: isOverallTaskDone,
      isSameTarget: isSameTarget,
      isSnapshotDownloadComplete: isSnapshotDownloadComplete,
      isTaskExpired: isTaskExpired,
      modifyTargetModal: modifyTargetModal,
      stopVaultJob: stopVaultJob,
      updateGlobalBandwidthSettings: updateGlobalBandwidthSettings,
      uploadEncryptionKeys: uploadEncryptionKeys,
      isBulkRetrievalSupported: isBulkRetrievalSupported,
      unregisterTarget: unregisterTarget,
    };

    /**
     * List of incomplete Icebox job statuses
     *
     * @constant   {Array}
     */
    var IN_PROGRESS_VAULT_JOB_STATUSES = [
      'kJobRunning',
      'kJobPaused',
    ];

    /**
     * Maps External Target Names to External Target Id
     * @type   {Object}
     */
    TargetSvc.targetNameMapById = {};

    /**
     * Determines if an Icebox task expired. May only work with cloud retrieval
     * subtasks, but should work with any object that has the expiry props
     * referenced within. YMMV.
     *
     * @method   isTaskExpired
     * @param    {integer}   task   The task object
     * @return   {boolean}   True if task expired, False otherwise.
     */
    function isTaskExpired(task) {

      // If task status does not exist, return false
      if (!task) {
        return false;
      }
      /**
       * Since the expiry props are set by the cluster, we need to compare dates
       * relative to the cluster
       *
       * @type   {integer}
       */
      var now = Date.clusterNow();

      /**
       * One of two possible properties indicating the expiry time. Can be
       * undefined.
       *
       * @type   {integer}
       */
      var expiryTime = task.expiryTimeUsecs || task.latestExpiryTimeUsecs;

      return expiryTime > 0 && now >= (expiryTime / 1000);
    }

    /**
     * Stops an icebox Vault (External target) job.
     *
     * @method   stopVaultJob
     * @param    {object}   data   The data.
     *                             Properties: {
     *                               clusterId: 0,
     *                               clusterIncarnationId: 0,
     *                               id: 0,
     *                             }
     * @return   {object}   Promsie to resolve with undefined, or the raw
     *                      server response if failed.
     */
    function stopVaultJob(data) {
      var opts;

      if (!data || !data.id || !data.clusterId || !data.clusterIncarnationId) {
        return $q.reject(false);
      }

      opts = {
        data: {searchJobUid: data},
        method: 'delete',
        url: API.public('remoteVaults/searchJobs'),
      };

      return $http(opts).then(function jobStopped(resp) {
        // Even though this will be `undefined`, we're returning it in case that
        // changes. Then we shouldn't need to make changes here to pick that up.
        return resp && resp.data;
      });
    }

    /**
     * Uploads encryption keys.
     *
     * @method   uploadEncryptionKeys
     * @param    {integer}   id      The Vault ID the keys are associated with.
     * @param    {array}     files   Array of key files to upload.
     * @return   {object}    Promise to resolve with the server's empty
     *                       response, or the full response if error.
     */
    function uploadEncryptionKeys(id, files) {
      if (!id || !files.length) {
        return $q.reject(undefined);
      }

      // ngFileUpload docs
      // https://github.com/danialfarid/ng-file-upload/tree/7.0.15#usage
      return Upload.upload({
        file: files,
        method: 'put',
        url: API.public('remoteVaults/encryptionKeys', id),
      }).then(function keysUploaded(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Convenience method that gets the External Target vault name or type name.
     *
     * @method   getTargetName
     * @param    {object}   target   The target in question.
     * @return   {string}   The target name, or type string.
     */
    function getTargetName(target) {
      var text = $rootScope.text.servicesExternalTargetsService;
      var title;

      // No target provided. Lets assume local for now.
      if (!target) {
        return text.targetDisplayNames[1];
      }

      // We should be able to figure it out now from the provided target.
      switch (true) {
        // Archival targets through private API
        case !!(3 === target.type && target.archivalTarget):
          title = TargetSvc.targetNameMapById[target.archivalTarget.vaultId];
          break;

        // Archival targets through Public API
        case !!(SNAPSHOT_TARGET_TYPE.kArchival ===
            SNAPSHOT_TARGET_TYPE[get(target, 'snapshotTargetSettings.type')] &&
          !!get(target, 'snapshotTargetSettings.archivalTarget')):
          title = get(target, 'snapshotTargetSettings.archivalTarget.vaultName');
          break;

        // Anything else
        default:
          if (!target.type) {
            // Incase of public API, type is nested within snapshotTargetSettings.
            target.type = SNAPSHOT_TARGET_TYPE[get(target, 'snapshotTargetSettings.type')];
          }
          title = text.targetDisplayNames[target.type];
      }

      // If we failed to get a vault name, fall back on the type
      return title || text.targetDisplayNames[target.type];
    }

    /**
     * Retrieves all external targets including fortknox and pending removal
     * then update the cached map of targets.
     *
     * @method   getTargets
     * @param    {string|array}   [usageTypeFilters]   can be used to filter by
     *                                                 usageType as needed
     * @param    {object}         params               Any request params to be
     *                                                 sent to server
     * @param    {headers}        headers              Any headers to be sent to
     *                                                 server
     * @return   {object}         Promise to resolve  with array of external
     *                            targets, rejects with server response.
     */
    function getTargets(usageTypeFilters, params, headers) {
      params = Object.assign({includeMarkedForRemoval: true, includeFortKnoxVault: true,}, params || {});

      var opts = {
        method: 'get',
        url: API.public('vaults'),
        params: params,
        headers: Object.assign({}, headers, NgPassthroughOptionsService.requestHeaders),
      };

      usageTypeFilters = usageTypeFilters ?

        // If defined, coerce into an array
        [].concat(usageTypeFilters) :

        // Otherwise, assign as empty array
        [];

      return $http(opts).then(
        function getExternalTargetsSuccess(response) {
          response.data = response.data || [];

          // Update the cached map of targets
          if (response.data.length) {
            updateTargetNameMap(response.data);
          }

          return response.data

            // Apply any configured usageTypeFilters
            .filter(function filterTargetsFn(target) {
              // If no filter(s) are defined, return true.
              return !usageTypeFilters.length ||

                // Otherwise apply the filter(s).
                usageTypeFilters.includes(target.usageType);
            })

            // Then transform the filtered results.
            .map(ExternalTargetsServiceFormatter.transformVault);
        }
      );

    };

    /**
     * Get an External Target by id.
     *
     * @method   getTarget
     * @param    {integer}   id   id of external target
     * @return   {object}    Promise to resolve request with external target
     *                       object, rejects with server response.
     */
    TargetSvc.getTarget = function getTarget(id) {
      return $http({
        method: 'get',
        url: API.public('vaults', id),
        params: {
          includeMarkedForRemoval: true,
        },
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(ExternalTargetsServiceFormatter.transformVault);
    };

    /**
     * Create a new External Target
     *
     * @method   createTarget
     * @param    {data}     data   Request payload to create Target with.
     * @return   {object}   Promise to resolve with newly created external
     *                      target object, rejects with server response.
     */
    TargetSvc.createTarget = function createTarget(data) {
      data.vaultBandwidthLimits =
        ExternalTargetsServiceFormatter
          .untransformBandwidthLimitData(data.vaultBandwidthLimits);

      return $http({
        method: 'post',
        url: API.public('vaults'),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders,
      }).then(
        function createTargetSuccess(response) {
          var extTarget;
          if (angular.isObject(response.data)) {
            extTarget = response.data;
            updateTargetNameMap([extTarget]);
          }

          // update the promise with fresh data
          TargetSvc.vaultPromise = TargetSvc.getTargets();

          return extTarget;
        }
      );
    };

    /**
     * Edit an External Target
     *
     * @method   updateTarget
     * @param    {object}   data   Data representing the External Target with
     *                             updates
     * @return   {object}   Promise to resolve with newly created external
     *                      target object, rejects with server response.
     */
    TargetSvc.updateTarget = function updateTarget(data) {
      data.vaultBandwidthLimits =
        ExternalTargetsServiceFormatter
          .untransformBandwidthLimitData(data.vaultBandwidthLimits);

      return $http({
        method: 'put',
        url: API.public('vaults', data.id),
        data: data,
      }).then(
        function updateTargetSuccess(response) {
          var extTarget;
          if (angular.isObject(response.data)) {
            extTarget = response.data;
            updateTargetNameMap([extTarget]);
          }

          return extTarget;
        }
      );
    };

    /**
     * Get the archive media info for a give restore task
     *
     * @method   getArchiveMediaInfo
     *
     * @param    {Object}   params   Pre built params for API call, should be
     *                               passed as empty obj or undefined if
     *                               providing task obj. This should be used
     *                               when a restore task is still theoretical
     *                               (hasn't been created yet)
     * @param    {Object}   [task]   tape archival restore task
     * @return   {object}   Promise to resolve with a list of tape media
     *                      assoicated with the provided restore task, rejects
     *                      with server's response.
     */
    TargetSvc.getArchiveMediaInfo = function getArchiveMediaInfo(params, task) {

      if (task) {
        // params being provided should have the same properties except a
        // qstarArchiveJobId property rather than qstarRestoreTaskId
        params = {
          clusterId: task.retrieveArchiveTaskUidVec[0].clusterId,
          clusterIncarnationId: task.retrieveArchiveTaskUidVec[0].clusterIncarnationId,
          qstarRestoreTaskId: task.retrieveArchiveTaskUidVec[0].objectId,
          entityIds: task.objects.map(function mapIds(object) {
            return object.entity.id;
          }),
        };
      }

      return $http({
        method: 'get',
        url: API.public('vaults/archiveMediaInfo'),
        params: params,
      }).then(
        function getMediaInfoSuccess(response) {
          return response.data || [];
        }
      );

    };

    /**
     * Download the vault key file for the given vault Id and update the vault
     * to tell it the file has been downlaoded. This is because users can only
     * download the key file once using the UI. However, this restriction is
     * relaxed for CLI.
     *
     * @method   downloadKeyFile
     * @param    {object}   data   The vault object to download the file for.
     * @return   {object}   Promise to resolve with updated Vault config, or the
     *                      full server response if error.
     */
    function downloadKeyFile(data) {
      var simpleApiPath = 'vaults/encryptionKey/' + data.id;
      var text = $rootScope.text.servicesExternalTargetsService;
      var deferred = $q.defer();
      var modalOptions = {
        actionButtonText: text.downloadFile,
        closeButtonText: text.cancel,
        content: text.downloadVaultKeyConfirmationCopy,
        title: text.downloadVaultKeyConfirmationTitle,
      };

      cModal.showModal(undefined, modalOptions).then(
        function downloadFileConfirmed(resp) {
          if (!data || !data.id) {
            return;
          }

          data = angular.extend({}, data, {
            encryptionKeyFileDownloaded: true,
          });

          $window.open(
            RemoteAccessClusterService.decorateApiPathForRemote(
              API.public(simpleApiPath))
          );

          // Notify Icebox that the user has downloaded the key file and to set
          // the bool on the vault config.
          TargetSvc.updateTarget(data).then(deferred.resolve, deferred.reject);
        },

        deferred.reject
      );

      return deferred.promise;
    }

    /**
     * Determines if 2 targets are the same
     *
     * @method   isSameTarget
     * @param    {object}    targetA   Target object 1
     * @param    {object}    targetB   Target object 2
     * @return   {boolean}   True if the same, False otherwise.
     */
    function isSameTarget(targetA, targetB) {
      return angular.equals(targetA, targetB);
    }

    /**
     * Updates targetNameMapById
     *
     * @method   updateTargetNameMap
     * @param    {Array}   extTargets   A list of Vaults
     */
    function updateTargetNameMap(extTargets) {
      extTargets.forEach(function addMapping(vault) {
        TargetSvc.targetNameMapById[vault.id] = vault.name;
      });
    }

    /**
     * Gets the backup target type.
     *
     * @method   getTargetType
     * @param    {object}   [replicaInfo]   The replicaVec to get info about.
     * @return   {string}   The target type.
     */
    function getTargetType(replicaInfo) {
      var archivalTarget = get(replicaInfo, 'target.archivalTarget');

      // Public API will return enum string.
      if (!archivalTarget) {
        archivalTarget = get(replicaInfo, 'snapshotTargetSettings.archivalTarget');
      }

      switch (true) {
        case archivalTarget && ![1, 'kTape'].includes(archivalTarget.type):
          return 'cloud';

        case archivalTarget && [1, 'kTape'].includes(archivalTarget.type):
          return 'tape';

        // TODO(tauseef): Maybe add archival target for kNas.

        default:
          return 'local';
      }
    }

    /**
     * Fetches the external target global bandwidth config
     *
     * @method     getBandwidthSettings
     *
     * @return     {object}             Promise to resolve with the fetched data
     */
    function getGlobalBandwidthSettings() {
      return $http({
        method: 'get',
        url: API.public('vaults/bandwidthSettings'),
      }).then(
        function onSuccess(response) {
          return response.data || {};
        }
      );
    }

    /**
     * Updates the external target global bandwidth settings
     *
     * @method     updateBandwidthSettings
     * @param      {Object}  [data]     The bandwidth config data
     * @return     {object}             Promise to be resolved with updated
     *                                  settings config
     */
    function updateGlobalBandwidthSettings(data) {
      return $http({
        method: 'put',
        data: data,
        url: API.public('vaults/bandwidthSettings'),
      }).then(
        function onSuccess(response) {
          return response.data || {};
        }
      );
    }

    /**
     * Opens Register/Modify External Target flow in a modal.
     *
     * @method     modifyTargetModal
     * @param      {integer|undefined}  [id]              The Vault ID to edit.
     *                                                    Default: undefined
     * @param      {string|undefined}   [restrictToType]  kValue of allowed type
     * @param      {string|undefined}   [customClass]     The custom class name.
     * @return     {object}             Promise to resolve with the new or
     *                                  modified Target.
     */
    function modifyTargetModal(id, restrictToType, customClass) {
      let openedClassName = 'c-slide-modal-open';
      if (customClass) {
        openedClassName += ' ' + customClass;
      }
      const modalConfig = {
        controller: 'externalTargetModifyModalController',
        keyboard: false,
        size: 'xl',
        templateUrl: 'app/platform/external/target-modify.html',
        openedClass: openedClassName,
        resolve: {
          vaultId: function() { return id; },
          restrictToType:  function() { return restrictToType; },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Determines if the given index download task has completed, by error,
     * cancelation, failure, or sucess.
     *
     * @method   isIndexingComplete
     * @param    {object}    task   The task
     * @return   {boolean}   True if indexing complete, False otherwise.
     */
    function isIndexingComplete(task) {
      var indexStatus = task.currentIndexingStatus || {};
      return !IN_PROGRESS_VAULT_JOB_STATUSES.includes(indexStatus.indexingTaskStatus);
    }

    /**
     * Determines if the given snapshot download task has completed, by error,
     * cancelation, failure, or sucess.
     *
     * @method   isSnapshotDownloadComplete
     * @param    {object}    task   The task
     * @return   {boolean}   True if snapshot download is complete, False
     *                       otherwise.
     */
    function isSnapshotDownloadComplete(task) {
      var snapshotInfo = task.currentSnapshotStatus || {};
      return !IN_PROGRESS_VAULT_JOB_STATUSES.includes(snapshotInfo.snapshotTaskStatus);
    }

    /**
     * Determines if overall task done.
     *
     * @method   isOverallTaskDone
     * @param    {object}    task   The task
     * @return   {boolean}   True if overall task done, False otherwise.
     */
    function isOverallTaskDone(task) {
      return isSnapshotDownloadComplete(task) && isIndexingComplete(task);
    }

    /**
     * get a flat list of external target types suitable for dropdowns etc
     *
     * @method getExternalTargetTypesList
     * @return  {Object[]}  List of formatted External Target Types
     */
    function getExternalTargetTypesList() {
      return flatten(
        map(ARCHIVE_EXTERNAL_TARGETS, function mapTargets(target) {
          return map(target.types || [], function mapTypes(type) {
            return {
              name: (target.name || '') + ' ' + type.name,
              enum: type.vault || type.tier,
            };
          });
        })
      );
    }

    /**
     * Checks whether bulk retrieval is supported or not
     *
     * @method  isBulkRetrievalSupported
     * @param   {Number}  id  id of the vault to be checked for bulk retrieval
     * @return  {Object}  Promise to be resolved with boolean indicating
     *                    wheteher bulk retrieval is supported or not
     */
    function isBulkRetrievalSupported(id) {
      var deferred = $q.defer();

      TargetSvc.vaultPromise = TargetSvc.vaultPromise || getTargets();

      TargetSvc.vaultPromise.then(function vaultsSuccess(vaults) {
        deferred.resolve(ENV_GROUPS.vaultsSupportBulkRetrieval.includes(
           get(find(vaults, ['id', id]), '_tierData.tier')));
      });

      return deferred.promise;
    }

    /**
     * Unregister an external target
     *
     * @method unregisterTarget
     * @param  {Number}  id    id of target to be unregistered
     * @param  {boolean} force should 'Force Unregister' the vault
     * @return {Object} Promise to be resolved with unregister confirmation
     */
    function unregisterTarget(id, data) {
      data = Object.assign({removeAllArchives: true}, data || {});

      var opts = {
        method: 'delete',
        url: API.public('vaults', id),
        data: data,
      };

      return $http(opts).then(
        function unregisterExternalTargetsSuccess(response) {
          return response.data;
        });
    }

    return TargetSvc;
  }

}(angular));
