import { isEmpty } from 'lodash-es';
// Service: JobDetailsService contains methods that will get and populate
// the necessary data to be use in our Job Details views

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobDetails')
    .service('JobDetailsService', jobDetailsServiceFn);

  function jobDetailsServiceFn(_, $q, SourceService, TenantService) {
    var JobDetailsService = {
      tree: [],
      objectsList: [],
      getSourceTree: getSourceTree,
      traverseBranchForObjectList: traverseBranchForObjectList,
      getObjectsList: getObjectsList,
    };

    var jobSummary = {};

    /**
     * getSourceTree gets our sources then calls getTree to build our entity
     * tree
     *
     * @param   {object}   job   object that contains all job details and info
     *
     * @return   {object}        notifies us that the tree has been built and
     *                           populated
     */
    function getSourceTree(job) {
      var deferred = $q.defer();

      var params = {
        entityId: job.parentSource.id,
        excludeTypes: [5],
        includeVMFolders: true,
        allUnderHierarchy: false,
        includeSystemVApps: true,
      };

      jobSummary = job;

      JobDetailsService.tree.length = 0;

      /**
       * Adding appropriate filters for generic getSources API used below to
       * get protected objects by that job which is owned by tenant but its
       * viewed by SP(service provider) admins because SP admin has meta data
       * visibility of tenant's job, runs, sources, consumption reports etc
       * stuff except that he can't modify them like edit job, run now,
       * un register source etc.
       *
       * handing below use cases below for job run details page
       * 1. SP admin viewing tenant's job run w/o impersonation.
       * 2. SP admin viewing tenant's job run using impersonation.
       * 3. Tenant viewing there own job run.
       */
      if (!job._isJobOwner) {
        TenantService.extendWithTenantParams(
          params, [job._tenantId].filter(Boolean));
      }
      SourceService.getSources(params).then(
        function sourcesCallSuccess(response) {
          var opts = { job: job, };

          if (!response.entityHierarchy ||
            !Array.isArray(response.entityHierarchy.children)) {
            deferred.reject('no-children');

            return;
          }

          // don't fetch tree if entityHierarchy is empty this can happen when
          // sources is assigned to tenant and job is left with dangling source.
          if (!isEmpty(response.entityHierarchy.children)) {
            response.entityHierarchy._rootEnvironment = job.type;
            response.entityHierarchy.children.forEach(function eachChild(node) {
              node._rootEnvironment = job.type;
            });
            JobDetailsService.tree =
              SourceService.getTree([response.entityHierarchy], opts);
          }

          deferred.resolve(JobDetailsService.tree);
        },
        deferred.reject
      );

      return deferred.promise;
    }

    /**
     * will return objectsList if it is populated else it will call
     * recursive function (traverseBranchForObjectList) to populate
     * the objectsList
     *
     * @param    {array}   tree          the source tree we want to traverse
     *
     * @return   {array}   objectsList   array of node objects that have been
     *                                   selected for the current job
     */
    function getObjectsList(tree) {
      // used to track leaf objects already accounted for
      // so duplicates don't get added to the list
      var objHashMap = {};
      JobDetailsService.objectsList.length = 0;

      JobDetailsService.tree = tree || JobDetailsService.tree;

      JobDetailsService.tree.forEach(function wrapperFn(node) {
        traverseBranchForObjectList(node, objHashMap);
      });

      return JobDetailsService.objectsList;
    }

    /**
     * Determines if all volumes protected.
     *
     * @method   areAllVolumesProtected
     * @param    {object}    This is special case for physical entity
     * @return   {boolean}   As per Magneto, all volumes are protected if
     *                       physical params do not have volumeGuidVec array.
     */
    function areAllVolumesProtected(entity) {
      if (jobSummary.backupSourceParams) {
        return jobSummary.backupSourceParams.some(function checkParam(param) {
          return entity.id === param.sourceId && param.physicalParams &&
            !param.physicalParams.volumeGuidVec;
        });
      }

      return false;
    }

    /**
     * recursive function that populates objectsList array for all objects that
     * are a part of the selected job
     *
     * @param   {object}   node   the current node we are evaluating
     * @param   {object}   objHashMap    hashmap that contains all selected
     *                                   nodes that we want to display, so if
     *                                   the function encounters the same node
     *                                   we can exit early
     */
    function traverseBranchForObjectList(node, objHashMap) {
      // if a leaf node is selected then add it to the list
      if (node._isSelected && node._isLeaf) {
        if (objHashMap[node.entity.id]) {

          // leaf obj is already in the list,
          // exit early so it doesn't get added again
          return;
        }

        objHashMap[node.entity.id] = true;

        // for physical servers, count the number of volumes protected
        if (node.entity.type === 6) {
          node._areAllVolumesProtected = areAllVolumesProtected(node.entity);
          node.entity.physicalEntity.volumeInfoVec =
            node.entity.physicalEntity.volumeInfoVec || [];

          node._numProtectedVolumes = node.entity.physicalEntity.volumeInfoVec.reduce(
            function countProtectedVolumes(currentProtectedCount, volume) {
              if (volume.isProtected || node._areAllVolumesProtected) {
                volume._isProtected = true;
                currentProtectedCount++;
              }

              return currentProtectedCount;
            }, 0);
        }

        JobDetailsService.objectsList.push(node);
      } else if (node.entity.type === 43 && node._isSelected) {
        // Couchbase has no leaf level obj
        JobDetailsService.objectsList.push(node);

      // SharePoint site with sub-sites can be protected independently
      } else if (node.entity.type === 24 &&
        node._isSelected &&
        node.entity.o365Entity && node.entity.o365Entity.type === 9
      ) {
        JobDetailsService.objectsList.push(node);

        // Add descendant ids in current job to the hash map
        // so that they do not get added again.
        (node._inJobDescendants || []).forEach(
          function addInJobDescendants(descendantId) {
            objHashMap[descendantId] = true;
          }
        )
        return;
      } else if(node.entity.type === 4) {
        JobDetailsService.objectsList.push(node);
      }

      if (Array.isArray(node.children)) {
        node.children.forEach(function recursiveWrapperFn(node) {
          traverseBranchForObjectList(node, objHashMap);
        });
      }
    }

    return JobDetailsService;
  }
})(angular);
