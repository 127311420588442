import { find } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: cSourceMeta info

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.sourceMeta.component:cSourceMeta
   *
   * @param   {object}   node   The Source node to show meta info for.
   *
   * @description
   *   Displays an icon to click for additional meta information for the given
   *   node. Logic for when to and when not to show the icon is built-in.
   *
   * @example
   *   <c-source-meta node="::node"></c-source-meta>
   */
  var componentConfig = {
    controller: 'SourceMetaCtrl',
    templateUrl: 'app/global/c-source-meta/c-source-meta.html',
    bindings: {
      node: '<',
    },
  };

  angular.module('C.sourceMeta', ['ui.bootstrap'])
    .controller('SourceMetaCtrl', cSourceMetaController)
    .component('cSourceMeta', componentConfig);

  /**
   * Component controller.
   */
  function cSourceMetaController(_, $scope, ENV_GROUPS, SOURCE_TYPE_GROUPS,
    NgOracleUtilityService, $translate) {
    var $ctrl = this;

    /**
     * Will be used to compile down to the following strings depending on the
     * proto used.
     *
     * 'app/global/c-source-meta/c-source-meta-popover-pub-hypervisor.html',
     * 'app/global/c-source-meta/c-source-meta-popover-databases.html',
     * 'app/global/c-source-meta/c-source-meta-popover-pub-physical.html', etc.
     *
     * @type   {string}
     */
    var POPOVER_TEMPLATE_ROOT =
      'app/global/c-source-meta/c-source-meta-popover-';

    assign($ctrl, {
      $onInit: $onInit,
      ENV_GROUPS: ENV_GROUPS,
      getDatabaseOpenMode: getDatabaseOpenMode,
      getPopoverTemplate: getPopoverTemplate,
      getSqlInstances: getSqlInstances,
      hasPopover: hasPopover,
      oracleClusterTypes: ENV_GROUPS.oracleClusterTypes,
      displayHardwareVersion: displayHardwareVersion,
      ngtStatus: ngtStatus,
    });

    function $onInit() {
      $scope.node = $ctrl.node;

      // Determine if this instance template is using private or public protos.
      POPOVER_TEMPLATE_ROOT += $ctrl.node.entity ? '' : 'pub-';
    }

    /**
     * Returns the url for the respective popover template.
     *
     * @method   getPopoverTemplate
     * @param    {object}   node   The node.
     * @return   {string}          The popover template url
     */
    function getPopoverTemplate(node) {
      var template = 'hypervisor';
      var nodeEnvironment = node._environment || get(node.entity, 'type');

      switch (true) {
        case ENV_GROUPS.databaseSources.includes(nodeEnvironment):
          template = 'db';
          break;

        case ENV_GROUPS.physical.includes(nodeEnvironment):
          template = 'physical';
          break;

        case ENV_GROUPS.nas.includes(nodeEnvironment):
          template = 'nas';
          break;

        case nodeEnvironment === 'kExchange':
          template = 'exchange';
          break;

        // Default omitted. Hypervisor is the default, but we will need to add
        // new templates whenever new ENV_TYPES are added to the platform.
      }

      return [POPOVER_TEMPLATE_ROOT, template, '.html'].join('');
    }

    /**
     * Determines whether the node has a popover. Any leaf has a popover, and
     * some parent nodes.
     *
     * @method     hasPopover
     * @param      {object}   node    The node
     * @return     {boolean}  true if should show popover
     */
    function hasPopover(node) {
      /**
       * @type   {string|number}   This node's environment as string (public
       *                           protos), or number (private protos).
       */
      var nodeEnvironment = node._environment || node.entity.type;

      switch (true) {
        // All leaf nodes, SQL hosts, and SQL entities have popovers.
        case node._isLeaf:

        // Hypervisor VMs
        case ENV_GROUPS.hypervisor.includes(nodeEnvironment) &&
          ['kVirtualMachine', 'kHostSystem', 'kVCenter'].includes(node._type):

        // Physical Hosts
        case ENV_GROUPS.physical.includes(nodeEnvironment) &&
          (['kHost', 'kWindowsCluster'].concat(ENV_GROUPS.oracleClusterTypes))
            .includes(node._type):

        // Database App entities
        case ENV_GROUPS.databaseSources.includes(nodeEnvironment):

        // Acropolis Cluster
        case node._environment === 'kAcropolis' && node._type === 'kStandaloneCluster':
          return true;

        // HyperV Hosts have a popover.
        case node._environment === 'kHyperV':
          // NOTE: This code may need to be generalized if other non-leaf nodes
          // are granted their own popover.
          return SOURCE_TYPE_GROUPS.hypervHosts.includes(node._type);

        default:
          return false;
      }
    }

    /**
     * Get SQL Instances from a node, either from applicationNodes or
     * applications[].applicationTreeInfo
     *
     * @method   getSqlInstances
     * @return   {array}    An array of sql application protection sources
     */
    function getSqlInstances() {
      var sqlApp = find($ctrl.node.applications,
        function findSql(application) {
          return application.environment === 'kSQL';
        }) || {};
      return sqlApp.applicationTreeInfo || $ctrl.node.applicationNodes;
    }

    /**
     * Determine to display version in Popover.
     *
     * @method    displayHardwareVersion
     * @param     {Object} node The tree node
     * @return    {boolean} Return true if it's a vcenter or SCVMM source and
     *            version information exists.
     */
    function displayHardwareVersion(node) {
      return (['kVCenter', 'kSCVMMServer'].includes(node._rootSourceType) ||
        node._hostEnvironment === 'kAcropolis') &&
        node.protectionSource[node._sourceKey].version;
    }

    /**
     * Returns the Open Mode string represntation for the database.
     *
     * @method    getDatabaseOpenMode
     * @param     {string}   openMode   Specifies the Open Mode type for the DB
     * @returns   {string}   Returns the string representation of the open mode
     *                       for the database.
     */
    function getDatabaseOpenMode(openMode) {
      return NgOracleUtilityService.getDatabaseOpenModeString(openMode);
    }

    /**
     * Return a translated string to indicate NGT (Nutanix Guest Tools) status.
     *
     * @method  ngtStatus
     * @param   {object} protectionSource
     */
    function ngtStatus(protectionSource) {
      const ngtStatus = [];
      if (protectionSource.ngtEnableStatus === 'kEnabled') {
        ngtStatus.push($translate.instant('enabled'));
        if (protectionSource.ngtInstallStatus === 'kInstalled') {
          ngtStatus.push($translate.instant('installed'));
          if (protectionSource.ngtReachable) {
            ngtStatus.push($translate.instant('reachable'));
          } else {
            ngtStatus.push($translate.instant('notReachable'));
          }
        } else {
          ngtStatus.push($translate.instant('notInstalled'));
        }
      } else {
        ngtStatus.push($translate.instant('disabled'));
      }
      return ngtStatus.join(', ');
    }

  }

})(angular);
