import { noop } from 'lodash-es';
import { map } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: LDAP

;(function(angular) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Optional attribute. If present then LDAP edit/delete is not allowed
       * else those options will be shown.
       */
      disableModifications: '<?',

      /**
       * Optional attribute. If present, then hide the organization column.
       */
      hideTenantCol: '<?',

      /**
       * Optional attribute. If present, it means the component is opened inside
       * a modal.
       */
      inModal: '<?',

      /**
       * Optional provide the inViewMode flag and if true then don't allow
       * viewing the LDAP.
       *
       * @type  {Boolean}  [inViewMode=undefined]
       */
      inViewMode: '<?',

      /**
       * Optional attribute. If present, then show the provided LDAP Providers.
       */
      ldapProviders: '=?',

      /**
       * Optional attribute. If present, don't show the filter options.
       */
      noFilters: '<?',

      /**
       * Optional attribute. If present, then open the details of the AD inside
       * a modal.
       */
      viewDetailsInModal: '<?',

    },
    require: {
      /**
       * Optional provide ngModel. If present, allow LDAP selection and selected
       * LDAPs are provided via ngModel binding.
       */
      ngModel: '?ngModel',
    },
    controller: 'ldapController',
    templateUrl: 'app/admin/access-management/ldap/list.html',
  };

  angular
    .module('C.ldap')
    .controller('ldapController', LdapControllerFn)
    .component('ldapList', configOptions);

  /**
   * Controller for the List view
   */
  function LdapControllerFn(_, evalAJAX, cModal, LdapService, $timeout, Cart,
    TenantService, $state) {
    var $ctrl = this;

    // Use cart service to enable LDAP selection.
    var selectedLdaps = Cart.newCart(
      function keyGeneratorFn(ldap) {
        return ldap.name;
      }
    );

    assign($ctrl, {
      // Component states.
      haveRegisteredLdaps: false,
      areLdapsReady: false,

      // Filter for AD listing.
      filter: {
        tenantIds: [],
      },

      $onInit: $onInit,
      addLdap: addLdap,
      getData: getData,
      isSelected: selectedLdaps.has,
      removeLdapProvider: removeLdapProvider,
      toggleSelection: toggleSelection,
      viewLdap: viewLdap,
    });

    /**
     * Initializes the controller and gets the list of LDAP Providers
     *
     * @method   $onInit
     */
    function $onInit() {
      if (!$ctrl.ldapProviders) {
        getLdapProviders();
      } else {
        $ctrl.areLdapsReady = true;

        // This is usually handled inside the getLdapProviders method, but has
        // to be manually inserted in this use case when the list of ldaps is
        // passed into the component.
        $ctrl.ldapProviders.forEach(LdapService.getConnectionStatus);
      }
    }

    /**
     * Gets the ldap providers from API.
     */
    function getLdapProviders() {
      // If ngModel exists, then update the selected LDAPs using the given
      // value.
      if ($ctrl.ngModel) {
        $ctrl.ngModel.$render = function updateInternalModalOnExternalChange() {
          selectedLdaps.addAll(map($ctrl.ngModel.$modelValue));
        };
      }

      getData();
    }

    /**
     * Gets the list of LDAPs.
     *
     * @method   getData
     */
    function getData() {
      var params = !$ctrl.hideTenantCol ? TenantService.extendWithTenantParams({
        _includeTenantInfo: true,
      }, $ctrl.filter.tenantIds) : {};

      $ctrl.areLdapsReady = false;

      LdapService.getLdapProviders(params).then(
        function getLdapsSuccess(ldapProviders) {
          // Sort the Providers by name because the backend response is sorted
          // by last updated.
          $ctrl.ldapProviders = sortBy(ldapProviders,
            function convertToLower(ldap) { return ldap.name.toLowerCase(); }
          );

          // Query for connection status of each provider.
          $ctrl.ldapProviders.forEach(LdapService.getConnectionStatus);

          // Sets the variable for the first time, if LDAPs are registered.
          $ctrl.haveRegisteredLdaps = $ctrl.haveRegisteredLdaps ||
           !!ldapProviders.length;
        },
        evalAJAX.errorMessage
      ).finally(function getLdapsFinally() {
        $ctrl.areLdapsReady = true;
      });
    }

    /**
     * Toggle LDAP selection.
     *
     * @method   toggleSelection
     * @param    {Object}   ldap   The LDAP to select.
     */
    function toggleSelection(ldap) {
      selectedLdaps.toggle(ldap);

      // Set the selected Map to the ngModel.
      $ctrl.ngModel.$setViewValue(selectedLdaps.cartMap);
    }

    /**
     * Opens the LDAP details page inside a modal/page depending on the params.
     *
     * @method   viewLdap
     * @param    {Object}   ldap   The ldap whose details are to be viewed.
     */
    function viewLdap(ldap) {
      if ($ctrl.inModal || $ctrl.viewDetailsInModal) {
        LdapService.viewLdapModal(ldap);
      } else {
        $state.go('view-ldap', {id: ldap.id});
      }
    }

    /**
     * Opens a modal to add an LDAP Provider if inside a modal, else open the
     * page view
     *
     * @method   addLdap
     */
    function addLdap() {
      if ($ctrl.inModal || $ctrl.viewDetailsInModal) {
        LdapService.newLdapProviderModal()
          .then(function addAdSuccess(newLdap) {
            $ctrl.ldapProviders.push(newLdap);

            // Select the newly created ldap.
            toggleSelection(newLdap);
          });
      } else {
        $state.go('add-ldap');
      }
    }

    /**
     * Removes the given LDAP Provider. Pops a challenge modal to confirm the
     * action.
     *
     * @method     removeLdapProvider
     * @param      {Object}  ldap  LDAP config object
     */
    function removeLdapProvider(ldap) {
      var modalConfig = {
        controller: DeleteLdapControllerFn,
        resolve: {
          ldap: function resolveLdap() { return ldap; }
        },
      };

      var modalOptions = {
        titleKey: 'ldap.removeLdapProvider.title',
        contentKey: 'ldap.removeLdapProvider.text',
        contentKeyContext: ldap,
        actionButtonKey: 'delete',
        closeButtonKey: 'cancel',
      };

      cModal.standardModal(modalConfig, modalOptions).then(
        function deleteLdapSuccess() {
          // Add slight delay because bridge still has to update cluster config
          // and sometimes there is a small latency. If we don't delay, we may
          // get a stale list of LDAP Providers.
          $timeout(getLdapProviders, 100);
        },
        noop
      );
    }
  }

  /* @ngInject */
  function DeleteLdapControllerFn($uibModalInstance, LdapService, evalAJAX,
    ldap) {

    var $ctrl = this;

    /**
     * Handles onClick the primary button in the standardModal.
     */
    $ctrl.ok = function okayThen() {
      $ctrl.submitting = true;
      LdapService.removeLdapProvider(ldap).then(
        $uibModalInstance.close,
        evalAJAX.errorMessage
      ).finally(function deleteFinally() {
        $ctrl.submitting = false;
      });
    };
  }

})(angular);
