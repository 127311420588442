import { isUndefined } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// View Service
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C').service('ViewServiceFormatter', ViewServiceFormatterFn);

  function ViewServiceFormatterFn(_, $rootScope, ClusterService, FEATURE_FLAGS,
    UserService, NgIrisContextService) {

    var ViewServiceFormatter = {
      isS3ViewAccessEnabled: isS3ViewAccessEnabled,
      transformDirectoryQuota: transformDirectoryQuota,
      transformShare: transformShare,
      transformView: transformView,
      transformViewToEntity: transformViewToEntity,
      untransformDirectoryQuota: untransformDirectoryQuota,
      untransformShare: untransformShare,
      untransformView: untransformView,
    };

    /**
     * transforms a view as returned from API, adding derived information.
     *
     * @param  {object} view as returned form API
     * @return {object}       updated view
     */
    function transformView(view) {

      var clusterNowUsecs = Date.clusterNow() * 1000;

      if (!view) {
        return {};
      }

      // Shim for v2 or v1 api.
      const viewProtectionArray = view.viewProtection ?
        (view.viewProtection.protectionJobs ||
        view.viewProtection.protectionGroups || []) : [];

      // True if protected by at least one job.
      view._isProtected = !!view.viewProtection &&
        Array.isArray(viewProtectionArray) &&
        !!viewProtectionArray.length;

      // True if it is protected by at least one Remote Adapter job.
      view._isRemoteAdapterView = view._isProtected &&
        viewProtectionArray.some(
          function checkForRemoteAdapterJob(job) {
            return ['kRemoteAdapter', 'kPuppeteer'].includes(job.type);
          }
        );

      // True if view is a Tracking View.
      view._isTrackingView = view.isReadOnly;

      // True if view is a DR View.
      view._isInactive = !view.isReadOnly &&
        view.viewProtection && view.viewProtection.inactive;

      // User Quotas are not available for any 'S3' View nor any View which is
      // replicated from another Cluster.
      view._allowUserQuotas = FEATURE_FLAGS.viewsUserQuotas &&
        !view._isInactive && view.protocolAccess !== 'kS3Only';

      // If Array `view.allSmbMountPaths` is empty, then fill it with the single
      // path in `view.smbMountPath`
      if (view.smbMountPath &&
        (!view.allSmbMountPaths || !view.allSmbMountPaths.length)) {
        view.allSmbMountPaths = [view.smbMountPath];
      }

      // Determines whether there is an active View DataLock by comparing the
      // expiry datetime with now.
      view._hasViewDataLock = view.dataLockExpiryUsecs > clusterNowUsecs;

      // Determines whether this is or was ever a DataLock View.
      view._hadViewDataLock =
        view._hasViewDataLock || !isUndefined(view.dataLockExpiryUsecs);

      // Determines whether there is an active File DataLock by looking for
      // non-zero milliseconds remaining. Property changed in v2 API, so we
      // check first v2 property and then v1.
      const fileLockConfig = view.fileLockConfig || {};
      view._hasFileDataLock = !!fileLockConfig.defaultRetentionDurationMsecs ||
        !!fileLockConfig.defaultFileRetentionDurationMsecs ||
        (view.protocolAccess === 'kS3Only' && !!fileLockConfig);

      // This is `undefined` unless the it is a DataLock View. In that case,
      // `true` if lock has expired, otherwise `false`. This property is used
      // differently from _hasViewDataLock because we need to be able to show an
      // expired DataLock.
      view._dataLockExpired = view.dataLockExpiryUsecs ?
        view.dataLockExpiryUsecs <= clusterNowUsecs : undefined;

      // If mode is not specified, then it is kCompliance, except for S3 only views.
      if (view.fileLockConfig && view.protocolAccess !== 'kS3Only') {
        view.fileLockConfig.mode = view.fileLockConfig.mode || 'kCompliance';
      }

      view._isEditable =
        // if user has privilege AND
        ($rootScope.user.privs.STORAGE_MODIFY ||
          $rootScope.user.privs.DATA_SECURITY) &&

        // if View is not inactive AND
        !view._isInactive &&

        // if View is not a DataLock View OR user has DATA_SECURITY privilege
        (!view._hadViewDataLock || $rootScope.user.privs.DATA_SECURITY);

      view._isDeletable =
        // If File DataLock is enabled, it is deletable only by Data Security
        // Officer (also Local Admin in the case of Enterprise mode only)
        (!view.fileLockConfig ||
          $rootScope.user.privs.DATA_SECURITY ||
          ($rootScope.user._isLocalAdmin &&
          view.fileLockConfig.mode === 'kEnterprise')) &&

        // Otherwise, it is deletable if it is editable OR
        (view._isEditable ||

        // If there is no active View DataLock
        !!view._dataLockExpired);

      // Stub file extension filter object.
      angular.merge(view, {
        fileExtensionFilter: {
          fileExtensionsList: [],
        }
      });

      // Set default file extension filter mode if unset.
      view.fileExtensionFilter.mode =
        view.fileExtensionFilter.mode || 'kBlacklist';

      // Generate the list of Shares for this View.
      view._sharesList =
        getSharesList(view).map(ViewServiceFormatter.transformShare);

      // Stub smbPermissionsInfo object
      view.smbPermissionsInfo = view.smbPermissionsInfo || {};

      // Set default value if nil.
      view.securityMode = view.securityMode || 'kNativeMode';

      // Save the original security mode setting to another property.
      view._originalSecurityMode = view.securityMode;

      // True if user's belongs to view owner organization else false and used
      // to prevent user from making deleting, protecting, cloning etc requests.
      view._isViewOwner = isEntityOwner(NgIrisContextService.irisContext, view.tenantId);

      view.nfsRootPermissions = view.nfsRootPermissions || {};

      // Transform the S3 Scaling backend model to the UI model.
      view._s3Scaling = _transformS3KeyMapping(view.s3KeyMappingConfig);

      // Stub the stats object because it is not always requested.
      view.stats = view.stats || { dataUsageStats: {} };

      return view;
    }

    /**
     * transforms a view as returned from API, adding derived information.
     *
     * @param     {Object}    view    as returned form API
     * @return    {Object}    updated view
     */
    function untransformView(view) {
      var tempView = cloneDeep(view);
      var avScanFilter;

      if (!tempView) {
        return {};
      }

      // If SMB isn't supported, don't include SMB permission info
      if (!['kAll', 'kSMBOnly'].includes(tempView.protocolAccess)) {
        tempView.smbPermissionsInfo = undefined;
      }

      // alertThresholdPercentage is not allowed to update from UI
      if (tempView.logicalQuota) {
        tempView.logicalQuota.alertThresholdPercentage = undefined;
      }

      // Trim everything before and including the last period or asterisk from
      // the antivirus scan filter extension list.
      avScanFilter = get(tempView, 'antivirusScanConfig.scanFilter', {});
      if (avScanFilter.fileExtensionsList) {
        avScanFilter.fileExtensionsList = avScanFilter.fileExtensionsList
          .map(extension => extension.trim().replace(/.*[\.\*]/g, ''));

      }

      if (isEmpty(tempView.sharePermissions)) {
        // Empty array should be instead undefined.
        tempView.sharePermissions = undefined;
      }

      // Untransform the S3 Scaling UI to backend model.
      tempView.s3KeyMappingConfig =
        ['kS3Only', 'kSwiftOnly'].includes(view.protocolAccess) ?
        _untransformS3KeyMapping(view._s3Scaling) : undefined;

      return tempView;
    }

    /**
     * Assembles the sharesList for the shares-mount-paths component.
     *
     * @method     getSharesList
     * @param      {object}  view    The view
     */
    function getSharesList(view) {
      // The root View is always first in the list.
      var sharesList = [{
        viewName: view.name,
        shareName: view.name,
        auditingEnabled: !!view.enableFilerAuditLogging,
        protocolAccess: view.protocolAccess,
        path: '/',
        nfsMountPath: view.nfsMountPath,
        s3AccessPath: view.s3AccessPath,
        smbMountPath: view.smbMountPath,
        // Check first v1 property name and then v2 property name.
        allSmbMountPaths: view.allSmbMountPaths || view.smbMountPaths,

        // Inherit view owner tenantId for the default view share.
        tenantId: view.tenantId,
        _tenant: view._tenant,
      }];

      // Add entry for each Alias in this View.
      if (angular.isArray(view.aliases)) {
        view.aliases.forEach(function eachAlias(alias) {
          sharesList.push({
            viewName: view.name,
            shareName: alias.aliasName,
            auditingEnabled: !!alias.enableFilerAuditLog,
            protocolAccess: view.protocolAccess,
            path: '/' + alias.viewPath,
            nfsMountPath: view.nfsMountPath,
            s3AccessPath: view.s3AccessPath,
            smbMountPath: view.smbMountPath,
            allSmbMountPaths: view.allSmbMountPaths || view.smbMountPaths,

            // Inherit view owner tenantId for the default view share.
            tenantId: view.tenantId,
            _tenant: view._tenant,
          });
        });
      }

      return sharesList;
    }

    /**
     * Transforms a Share as returned from API, adding derived information.
     * Decorates shares with mount paths.
     *
     * @method     transformShare
     * @param      {Object}  share   The Share to update
     * @return     {Object}  updated Share
     */
    function transformShare(share) {
      if (!share) {
        return;
      }

      share.mountPaths = assemblePaths(share);
      share.path = share.path || '/';

      // True if the user belongs to view share owner's organization and used to
      // prevent un-authorized user from making delete share request.
      share._isShareOwner = isEntityOwner(NgIrisContextService.irisContext, share.tenantId);

      share.sharePermissions = share.sharePermissions || [];
      share.subnetWhitelist = share.subnetWhitelist || [];

      return share;
    }

    /**
     * Prepares a Share model object for a viewAliases API request.
     *
     * @param     {Object}    share    Share as returned form API
     * @return    {Object}    updated Share
     */
    function untransformShare(share) {
      var tempShare = clone(share);

      if (!tempShare) {
        return {};
      }

      // GET /shares returns shareName but the POST/PUT/DELETE /viewAliases all
      // expect aliasName. It's a legacy thing we were asked to accommodate.
      tempShare.aliasName = tempShare.aliasName || tempShare.shareName;

      return tempShare;
    }

    /**
     * Scrub Share name according to respective protocol requirements.
     *
     * @param   {string}   shareName    String Share name.
     * @param   {string}   protocol     String protocol name.
     * @return  {string}   Share name scrubbed according to respective protocol
     *                     requirements.
     */
    function scrubShareName(shareName, protocol) {
      if (protocol === 's3') {
        // For S3 mount paths, URL encode the Share name.
        return encodeURI(shareName);
      }

      if (protocol === 'nfs' && shareName.match(/\s/)) {
        // For NFS mount paths, enclose Share name with single quotes if it
        // contains spaces. This ensures that the mount path will actually work
        // when pasted into a CLI.
        return "'" + shareName + "'";
      }

      return shareName;
    }

    /**
     * Isolate the Share name in the NFS mount path and then scrub it.
     *
     * @param   {string}   mountPath    String mount path.
     * @return  {string}   Full mount path with Share name scrubbed.
     */
    function scrubNfsMountPath(mountPath) {
      var parts;

      if (!mountPath) {
        return mountPath;
      }

      parts = mountPath.split('/');
      parts.push(scrubShareName(parts.pop(), 'nfs'));
      return parts.join('/');
    }

    /**
     * Assembles the mount paths for a share.
     *
     * @method     assemblePaths
     * @param      {Object}  share   The share object
     * @return     {Array}  list of mount path objects
     */
    function assemblePaths(share) {
      // If this share is actually the root View, then we want to use the long
      // form mount path because that ensures an accurate path if the View and
      // its Storage Domain have the same name. Usually, we see a mount path in
      // the form of fqdn/share-name:
      //   sv4-dell85-c4-ve04.eng.cohesity.com:/share1
      //
      // However, if the Storage Domain and View have identical names, then they
      // need to be explicit in the mount path:
      //   sv4-dell85-c4-ve04.eng.cohesity.com:/same-name/same-name/fs
      //
      // Note: This does not apply to S3.
      var useLongPath = share.shareName === share.viewName;

      var nfsMountPath = share.nfsMountPath;
      var s3AccessPath = share.s3AccessPath;
      var smbMountPath = share.smbMountPath;
      var allSmbMountPaths = share.allSmbMountPaths || [];
      var mountPaths = [];

      if (nfsMountPath) {
        mountPaths.push({
          type: 'nfs',
          label: 'NFS:',
          path: useLongPath ? scrubNfsMountPath(nfsMountPath) :
            // Slice off the "fqdn:/" and append this Share name.
            nfsMountPath.slice(0, nfsMountPath.indexOf('/') + 1) +
            scrubShareName(share.shareName, 'nfs'),
        });
      }

      // Stub `allSmbMountPaths` if missing.
      if (smbMountPath && !allSmbMountPaths[0]) {
        allSmbMountPaths.push(smbMountPath);
      }

      if (allSmbMountPaths[0]) {
        angular.forEach(allSmbMountPaths,
          function assembleSmbMountPaths(smbPath) {
            mountPaths.push({
              type: 'smb',
              label: 'SMB:',
              // Slice off the "\\fqdn\" and append this Share name.
              path: useLongPath ? smbPath :
                smbPath.slice(0, smbPath.indexOf('\\', 2) + 1) +
                share.shareName,
            });
          }
        );
      }

      if (s3AccessPath && isS3ViewAccessEnabled(share)) {
        mountPaths.push({
          type: 's3',
          label: 'S3:',
          // Slice off the "https://fqdn:port/" and append this Share name.
          path: s3AccessPath.slice(0, s3AccessPath.indexOf('/', 8) + 1) +
          scrubShareName(share.shareName, 's3'),
        });
      }

      return mountPaths;
    }

    /**
     * Determines if logged in user can create view with S3 only access.
     *
     * @method  isS3ViewAccessEnabled
     * @param   {Object}   view   The view to test.
     * @return  {boolean}  True if S3 view access is allowed, False otherwise.
     */
    function isS3ViewAccessEnabled(view) {
      view = view || {};
      return UserService.isTenantUser() || view.tenantId ?
        FEATURE_FLAGS.enableS3ViewsForTenants : true;
    }

    /**
     * This method transforms a view from the getView(s) response into a
     * magneto-compliant EntityProto
     *
     * @method     transformViewToEntity
     * @param      {object}  view    The View
     * @return     {object}  The transformed Entity
     */
    function transformViewToEntity(view) {
      var clusterInfo = ClusterService.clusterInfo;
      // NOTE: Commented properties left in place for reference.
      return view && {
        vmDocument: {
          objectName: view.name,
          viewBoxId: view.viewBoxId,
          versions: [],
          // jobName: undefined,
          objectId: {
            // jobId: undefined,
            // jobUid: {
            //     clusterId: clusterInfo.id,
            //     clusterInstanceId: clusterInfo.incarnationId,
            //     objectId: undefined
            // },
            entity: {
              type: 4,
              viewEntity: {
                name: view.name,
                uid: {
                  clusterId: clusterInfo.id,
                  clusterIncarnationId: clusterInfo.incarnationId,
                  objectId: view.viewId,
                },
                type: 1,
              },
            },
          },
        },
      };
    }

    /**
     * Transforms a Directory Quota as returned from API, adding derived
     * information.
     *
     * @method     transformDirectoryQuota
     * @param      {Object}  quota   The Directory Quota to update
     * @return     {Object}  updated Quota
     */
    function transformDirectoryQuota(quota) {
      var defaultQuota = { policy: {}, dirPath: '/', usageBytes: 0 };
      quota = assign(defaultQuota, quota);

      // If quota and/or threshold values are missing, set to 0.
      quota.policy.hardLimitBytes = quota.policy.hardLimitBytes || 0;
      quota.policy.alertLimitBytes = quota.policy.alertLimitBytes || 0;

      // Split the path and save the terminating dir as the folder name. If
      // the path is root, then display slash as the folder name as well.
      quota._folderName =
        quota.dirPath.slice(quota.dirPath.lastIndexOf('/') + 1) || '/';

      return quota;
    }

    /**
     * Prepares a Directory Quota object for a put API request.
     *
     * @method    untransformDirectoryQuota
     * @param     {Object}    quota    Directory Quota model object.
     * @return    {Object}    updated quota
     */
    function untransformDirectoryQuota(quota) {
      var tempQuota = cloneDeep(quota);

      if (!tempQuota) {
        return {};
      }

      // If alertLimitBytes is zero, it should be removed because the backend
      // expects undefined in that case. Note: quota may be zero.
      if (get(tempQuota, 'quota.policy.alertLimitBytes') === 0) {
        delete tempQuota.quota.policy.alertLimitBytes;
      }

      return tempQuota;
    }

    /**
     * Maps single backend string enum to the front-end model with three
     * properties.
     *
     * @example
     * enum 'kHierarchical' => {
     *    type: 'kHierarchical',
     * }
     *
     * enum 'kObjectId' => {
     *    type: 'kObjectId',
     * }
     *
     * enum 'kRandom' => {
     *    type: 'kFlat',
     *    flatType: 'kRandom',
     * }
     *
     * enum 'kShort' => {
     *    type: 'kFlat',
     *    flatType: 'kStructured',
     *    keyLength: 'kShort',
     * }
     *
     * @method    _transformS3KeyMapping
     * @param     {String}    val    String enum value from API response.
     * @returns   {Object}    Mapped object for UI model.
     */
    function _transformS3KeyMapping(val) {
      var obj = {};

      switch(true) {
        case !val || val === 'kHierarchical':
          // If there is no mapping or it's kHierarchical, then we only have a
          // type property 'kHierarchical'.
          obj.type = 'kHierarchical';
          break;

        case val === 'kObjectId':
          // If it's kObjectId, then we only have a type property 'kObjectId'.
          obj.type = val;
          break;

        case val === 'kRandom':
          // Else if it's 'kRandom', then we have type:'kFlat' and a flatType
          // property.
          obj.type = 'kFlat';
          obj.flatType = val;
          break;

        default:
          // Else we have a type:kFlat and flatType:kStructured and a keyLength
          // property.
          obj.type = 'kFlat';
          obj.flatType = 'kStructured';
          obj.keyLength = val;
      }

      return obj;
    }

    /**
     * Maps front-end model to single backend string enum.
     *
     * @example
     * {
     *    type: 'kHierarchical',
     * } => enum 'kHierarchical'
     *
     * {
     *    type: 'kObjectId',
     * } => enum 'kObjectId'
     *
     * {
     *    type: 'kFlat',
     *    flatType: 'kRandom',
     * } => enum 'kRandom'
     *
     * {
     *    type: 'kFlat',
     *    flatType: 'kStructured',
     *    keyLength: 'kShort',
     * } => enum 'kShort'
     *
     * @method    _transformS3KeyMapping
     * @param     {Object}    keyMapConfig    Mapped object from UI model.
     * @returns   {String}    String enum value for API request.
     */
    function _untransformS3KeyMapping(keyMapConfig) {
      var kValue = 'kHierarchical';

      if (!keyMapConfig) {
        // If no config specified, then we default to 'kHierarchical'.
        return kValue;
      }

      switch(true) {
        case (keyMapConfig.type === 'kHierarchical' ||
          keyMapConfig.type === 'kObjectId'):
          kValue = keyMapConfig.type;
          break;

        case (keyMapConfig.flatType === 'kRandom'):
          kValue = keyMapConfig.flatType;
          break;

        default:
          kValue = keyMapConfig.keyLength;
      }

      return kValue;
    }

    return ViewServiceFormatter;
  }

})(angular);
