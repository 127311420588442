import { inject, Injectable } from '@angular/core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { flagEnabled, IrisContextService, isIbmBaaSEnabled, isMcmGovCloud } from '@cohesity/iris-core';

/**
 * @description
 * Login page information service.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginInfoService {
  /**
   * Determines whether the UI is running in IBM Aqua mode.
   */
  readonly isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  /**
   * True if showing Veritas info.
   */
  veritasEnabled = flagEnabled(this.ctx.irisContext, 'loginPageWithVeritasInfo') &&
    !isIbmBaaSEnabled(this.ctx.irisContext) &&
    !isMcmGovCloud(this.ctx.irisContext) &&
    !this.isIBMAquaEnv;

  constructor(
    private ctx: IrisContextService,
  ) {}
}
