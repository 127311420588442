import { map } from 'lodash-es';
import { isEmpty } from 'lodash-es';
// Service: apps Service formatter

(function(angular, undefined) {
  'use strict';
  angular.module('C.appsManagement').service('AppsServiceFormatter', AppsServiceFormatterFn);

  function AppsServiceFormatterFn(_, $window, cUtils, $rootScope, FEATURE_FLAGS) {
    return {
      transformAppInstances: transformAppInstances,
      transformApps: transformApps,
      untransformLaunchParams: untransformLaunchParams,
    };

    /**
     * Transform the server's response when getting an appInstances response
     *
     * @method     transformAppInstances
     * @param      {object}  resp    The server's response
     * @param      string    vip     One of the vip address in cluster.
     * @return     {object}          appInstances list
     */
    function transformAppInstances(resp, vip) {
      return (resp.data || []).map(function updateInstance(instance) {
        if(instance.nodePort || instance.uiClusterIPSvcAddr) {
          instance._appUrl = _buildAppUrl(instance, vip);
        }

        if (instance.state !== 'kTerminated') {
          instance._isActive = true;
        }

        if (['kInitializing', 'kPausing', 'kTerminating'].includes(instance.state)) {
          instance._progress = instance.state;
        }

        instance.settings._readViewNames = [];
        instance.settings._writeViewNames = [];

        // Setup deployment details if available
        (instance.vmGroups || []).forEach(function eachVmGroup(vmGroup) {
          (vmGroup.vms || []).forEach(function eachVm(vm) {
            (vm.nodePorts || []).forEach(function eachPort(nodePort) {
              switch (nodePort.tag) {
                case 'kSsh':
                  vm._sshPort = nodePort.port;
                  break;
                case 'kHttp':
                case 'kHttps':
                  vm._appUrl = _buildVmAppUrl(instance, nodePort);
                  break;
              }
            });
          });
        });

        return instance;
      });
    }

    /**
     * Constructs the application url.
     * It uses nodeIp on helios and address bar hostname for cluster ui.
     * Protocol is decided based on httpsUi flag.
     *
     * @method     _buildAppUrl
     * @param      {object}   instance   instance object with nodeIp and port
     * @param      {string}   vip        A Vip address to access cluster.
     * @return     {string}              Application url
     */
    function _buildAppUrl(instance, vip) {
      var mcmMode = $rootScope.basicClusterInfo.mcmMode;
      var host = mcmMode ? instance.nodeIp : (vip || instance.nodeIp);
      var protocol = instance.httpsUi ? 'https' : 'http';

      // Attach token if present
      var token = isEmpty(instance.appAccessToken) ? '' : `?token=${instance.appAccessToken}`;

      // use node port because app would not be running behind the cluster_ip i.e. behind the
      // iris marketplace proxy.
      if (instance.nodePort && !FEATURE_FLAGS.secureMarketplaceEnabled) {
        return `${protocol}://${host}:${instance.nodePort}/${token}`;
      }

      // using https always for athena based urls
      protocol = 'https';
      return `${protocol}://${$window.location.host}/athena-${instance.appInstanceId}/${token}`;
    }

    /**
     * Constructs the application url for vms.
     * It uses nodeIp on helios and address bar hostname for cluster ui.
     * Protocol is decided based on nodePort tag.
     *
     * @method     _buildVmAppUrl
     * @param      {object}   instance   instance object with nodeIp and port
     * @param      {object}   nodePort   nodePort object
     * @return     {string}              Application url
     */
    function _buildVmAppUrl(instance, nodePort) {
      var mcmMode = $rootScope.basicClusterInfo.mcmMode;
      var host = mcmMode ? instance.nodeIp : $window.location.hostname;
      var protocol = nodePort.tag === 'kHttps' ? 'https' : 'http';

      return protocol + '://' + host + ':' + nodePort.port;
    }

    /**
     * Transform the server's response when getting an apps response
     *
     * @method     transformApps
     * @param      {object}  resp    The server's response
     * @return     {object}          apps list
     */
    function transformApps(resp) {
      var progressStates = [
        'kDownloadNotStarted',
        'kDownloadComplete',
        'kDownloadFailed',
        'kInstallInProgress',
        'kUninstallInProgress',
        'kDownloadInProgress',
      ];

      return (resp.data || []).reduce(function updateApps(apps, app) {
        if (!isEmpty(app.metadata)) {
          // Assume no state means not installed.
          // TODO(mithun):  This should ideally be fixed in api.
          app.installState = app.installState || 'kNotInstalled';

          // Check whether app dev version can be upgraded and is compatible
          // with cluster version
          if (app.version === -1 || app.latestVersion === -1) {
            app._incompatible = true;
          } else if ((app.latestVersion !== undefined) &&
            (app.latestVersion !== app.version)) {
            app._isUpgradeAvailable = true;
          }

          if (progressStates.includes(app.installState)) {
            app._progress = app.installState;
          }

          // Fill percentage if its reported for any progress state.
          // Currently only download state reports progress.
          if (app.installState === 'kDownloadInProgress') {
            app._progressPct = app.downloadProgressPct || 0;
          }

          app._requiredPrivileges = (app.requiredPrivileges || []).reduce(function createIndex(privileges, privilege) {
            privileges[privilege] = true;
            return privileges;
          }, {});
          apps.push(app);
        }
        return apps;
      }, []);
    }

    /**
     * Transform the ui's collection of launch params to api compatible one
     *
     * @method     untransformLaunchParams
     * @param      {number}  id         app id
     * @param      {number}  version    app version
     * @param      {object}  settings   launch params collected from user
     * @return     {object}             api compatible launchParams
     */
    function untransformLaunchParams(id, version, settings) {
      var params = {
        appUid: parseInt(id, 10),
        appVersion: parseInt(version, 10),
        description: settings.description,
        creationUid: cUtils.generateUuid(),
        settings: {
          qosTier: settings.qosTier,
          protectedObjectPrivileges: {
            protectedObjectsprivilegesType: settings.protectedObjectsPrivileges.type
          }
        }
      };

      // Checking if application installed has dynamic form parameters
      // If Empty then do not add "deploymentParameters" else add it
      if(!isEmpty(settings.deploymentParameters)) {
        params.deploymentParameters = JSON.stringify(settings.deploymentParameters);
      }

      if (settings.vlan) {
        params.settings.externalNetworkInfo =  settings.vlan;
      }

      if (settings.instanceSize) {
        params.settings.instanceSize =  settings.instanceSize;
      }

      var privType = settings.viewPrivileges.type;
      var allType = settings.viewPrivileges.allType;

      var readPrivileges = { privilegesType: privType };
      var writePrivileges = { privilegesType: privType };

      params.settings.vmNumReplicasList = settings.vmNumReplicasList;

      switch (true) {
        case privType == 'kAll' && allType == 'read':
          params.settings.readViewPrivileges = readPrivileges;
          break;

        case privType == 'kAll' && allType == 'write':
          params.settings.readWriteViewPrivileges = writePrivileges;
          break;

        case privType == 'kSpecific':
          readPrivileges.viewIds = map(settings.viewPrivileges.read, 'viewId');

          params.settings.readViewPrivileges = readPrivileges;

          writePrivileges.viewIds = map(settings.viewPrivileges.write, 'viewId');

          params.settings.readWriteViewPrivileges = writePrivileges;
          break;
      }

      return params;
    }
}
})(angular);
