import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

// To-Do: need to add all the required privilege checks
const heliosBackupAccessFn = (ctx: StateAccessContext): boolean => {
  // Check if the feature flag for Helios Backup is enabled
  const isFeatureFlagEnabled = ctx.FEATURE_FLAGS.globalHeliosBackupEnabled;

  // Will add futher privilege checks once back end provides it.

  return isFeatureFlagEnabled;
};

export const HeliosBackupConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-backup.**',
      url: '/helios-backup',
      loadChildren: () => import('./helios-backup.module').then(m => m.HeliosBackupModule),
    },
  ],
  allClusterMap: {
    'helios-backup': {
      globalContext: true,
      heliosOnly: true,
    },
    'helios-backup.runs': {
      globalContext: true,
      heliosOnly: true,
    },
    'helios-backup.target-schedule': {
      globalContext: true,
      heliosOnly: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-backup': heliosBackupAccessFn,
      'helios-backup.runs': heliosBackupAccessFn,
      'helios-backup.target-schedule': heliosBackupAccessFn,
    };
  },
};
