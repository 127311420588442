import { isUndefined, map, omitBy } from 'lodash-es';

/**
 * Interface to pass params to the getMailtoLink function.
 */
export interface GetMailtoLinkParams {
  /**
   * A list of email IDs who are direct receipients of the email.
   */
  to?: string[];

  /**
   * A list of email IDs for the CC field.
   */
  cc?: string[];

  /**
   * A list of email IDs for the BCC field.
   */
  bcc?: string[];

  /**
   * The subject of the email.
   */
  subject?: string;

  /**
   * The body of the email.
   */
  body?: string;
}

/**
 * Sanitizes a file name by removing all special characters and replacing them with '_'. Some environments,
 * such as HyperV do not support ":" in files names.
 *
 * @param   fileName   The file name to sanitize
 * @returns A sanitized file name.
 */
export function sanitizeFileName(fileName: string): string {
  // Strip all special characters and replace with '_' as some environments,
  // like HyperV do not support ":".
  return fileName.replace(/[^A-Z0-9]+/gi, '_');
}

/**
 * Convert an object into a url param string
 * eg: {a: 1, b: '2/3'} => a=1&b=2%2D3
 *
 * @param   params The params object to be converted
 * @returns The converted url string
 */
export function sanitizeParameters(params: any): string {
  return map(omitBy(params, isUndefined), (val, key) => [key, encodeURIComponent(val)].join('=')).join('&');
}

/**
 * Replaces a key in url by encoded string value based on key value pair.
 * eg: {key: 'id', value: '1:2:3'} => 1%3A2%3A3
 *
 * @param   url    The url path
 * @param   params The params key value pair used for conversion
 * @returns The converted url string
 */
export function sanitizeUrlByParams(url: string, params: any): string {
  return url.replace(`{${params.key}}`, encodeURIComponent(params.value));
}

/**
 * Function to get an absolute page when a name and path are provided.
 *
 * @param name Optional. The name.
 * @param path Optional. The path.
 * @returns The absolute path.
 */
export function getAbsolutePath(name?: string, path?: string): string {
  // Replace multiple slashes in a path with just one.
  return (name ? `${path}/${name}` : path).replace(/\/\/+/g, '/');
}

/**
 * Split a string into substrings using the specified separators and return them as an array.
 * Only non empty tokens are returned and surrounding spaces are trimmed.
 *
 * @param   str         The string to be split.
 * @param   separators  The list of delimitors to split the string on.
 * @returns Array of string.
 */
export function splitBy(str: string = '', ...separators: string[]): string[] {
  const regexp = new RegExp(separators.join('|'), 'g');
  return str.split(regexp).filter(Boolean).map(v => v.trim());
}

/**
 * compare for sorting string lexicographically
 *
 * @param prev previous string
 * @param curr current string
 * @returns integer value after comparison
 */
export function stringCompare(prev: string, curr: string) {
  return prev.toUpperCase()
    .localeCompare(curr.toUpperCase());
}

/**
 * Generates and returns a mailto protocol link.
 *
 * @param params The params to generate the link from.
 * @returns The generated mailto link.
 */
export function getMailtoLink(params: GetMailtoLinkParams): string {
  const fields = [];
  const href = `mailto:${params.to?.map(email => encodeURIComponent(email))?.join(',')}`;

  if (params.subject?.trim()) {
    fields.push(`subject=${encodeURIComponent(params.subject)}`);
  }

  if (params.body?.trim()) {
    fields.push(`body=${encodeURIComponent(params.body)}`);
  }

  if (params.cc?.length) {
    fields.push(`cc=${params.cc.map(email => encodeURIComponent(email)).join(',')}`);
  }

  if (params.bcc?.length) {
    fields.push(`bcc=${params.bcc.map(email => encodeURIComponent(email)).join(',')}`);
  }

  return href + '?' + fields.join('&');
}
