<div *ngIf="region && isDrillDownMode" class="margin-bottom-lg">
  <h1 class="margin-bottom-xs">
    {{'heliosSecurityAdvisor.scoresFor' | translate: { region: region } }}
  </h1>
  <label *ngIf="country">
    {{ (state ? 'heliosSecurityAdvisor.regionsTextWithState' : 'heliosSecurityAdvisor.regionsTextWithNoState') |
      translate: { country: country, state: state } }}
  </label>
</div>

<cog-card-section>
  <div *ngIf="showFilters" class="flex-row flex-justify-space-between">
    <!-- Filter -->
    <cog-filters #filters>
      <cog-value-property-filter
        cogDataId="scores-table-filter-scores-range"
        *cogFilterDef="
          let params = params;
          let filter;
          quickFilter: true;
          key: 'scoresRange';
          label: 'score' | translate;
          filterType: 'valueProperty'"
        [filterDefParams]="params"
        [filter]="filter"
        [allowMultiple]="false"
        [filterValues]="scoreRangeFilterValues || []"
        [title]="'score' | translate"
        [trackFilters]="true">
      </cog-value-property-filter>
      <cog-value-property-filter
        cogDataId="scores-table-filter-cluster"
        *cogFilterDef="
          let params = params;
          let filter;
          quickFilter: true;
          key: 'cluster';
          label: 'cluster' | translate;
          filterType: 'valueProperty'"
        [filterDefParams]="params"
        [filter]="filter"
        [allowMultiple]="true"
        [filterValues]="clusterFilterValues"
        [title]="'cluster' | translate"
        [trackFilters]="true">
      </cog-value-property-filter>
      <cog-value-property-filter
        cogDataId="scores-table-filter-region"
        *cogFilterDef="
          let params = params;
          let filter;
          quickFilter: true;
          key: 'region';
          label: 'region' | translate;
          filterType: 'valueProperty'"
        [filterDefParams]="params"
        [filter]="filter"
        [allowMultiple]="false"
        [filterValues]="regionFilterValues"
        [title]="'region' | translate"
        [trackFilters]="true">
      </cog-value-property-filter>
    </cog-filters>

    <!-- Page actions -->
    <div class="flex-row">
      <button
        *ngIf="isDrillDownMode"
        mat-icon-button
        class="margin-right-sm"
        type="button"
        cogDataId="view-issues"
        (click)="openListIssuesDialog()"
        [matTooltip]="'viewIssues' | translate"
        [matTooltipPosition]="'left'"
        [inheritDataId]="true">
        <cog-icon shape="helix:inspect-bug"></cog-icon>
      </button>
      <button
        *ngIf="isDrillDownMode"
        [dgHasPermissions]="['SECOPS_MANAGE_POSTURE_ADVISOR', 'DGAAS_MODIFY']"
        cogDataId="perform-scan-button"
        mat-flat-button
        color="primary"
        (click)="openPerformScanDialog()">
        {{ 'performScan' | translate }}
      </button>
    </div>
  </div>

  <!-- Binding the score list table -->
  <dg-pa-scores-list-table
    [scoresData]="filteredScoresData$ | async"
    [loading]="loading"
    isRowClickable="true"
    (rowClicked)=drillDownRegion($event)
    (fixIssueActionClicked)="fixIssuesClickHandler($event)"
    [customWidth]="true">
  </dg-pa-scores-list-table>
</cog-card-section>
