<form class="vault-form" novalidate [formGroup]="region" *ngIf="focused">
  <div class="flex-row-sm vertical-align">
    <div class="flex-row-sm flex-grow">
      <mat-form-field class="flex-grow" *ngIf="multiVaultRegion">
        <mat-label>{{ 'vaultName' | translate }}</mat-label>
        <input type="text"
          matInput
          formControlName="vaultName"
          placeholder="{{ 'vaultName' | translate }}"
          cogDataId="vault-name"
          [inheritDataId]="true" />
        <mat-error *ngIf="vaultNameControl?.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
        <mat-error *ngIf="vaultNameControl?.errors?.duplicateName">
          {{'errors.duplicateName' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- Region Selector -->
      <mat-form-field class="flex-grow">
        <mat-label>{{'selectRegion' | translate}}</mat-label>
        <mat-select
          cogDataId="region-id"
          formControlName="regionId"
          [inheritDataId]="true">
          <ng-container [ngSwitch]="multiVaultRegion">
            <ng-container *ngSwitchCase="false">
              <mat-optgroup *ngFor="let group of regionList$ | async | keyvalue"
                label="{{'dms.' + group.key | translate}}">
                <mat-option *ngFor="let region of group.value"
                  [disabled]="group.key === 'unavailableRegions'"
                  [matTooltip]="(group.key === 'unavailableRegions' ?
                    'dms.message.alreadyProvisioned' : '') | translate"
                  [cogDataId]="'option-' + region.id"
                  [value]="region.id">
                  {{ region.id | translate }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
            <ng-container *ngSwitchCase="true">
              <mat-optgroup *ngFor="let group of regionsByCloudType$ | async | keyvalue"
                label="{{ group.key | translate }}">
                <mat-option *ngFor="let region of group.value"
                  [cogDataId]="'option-' + region.id"
                  [value]="region.id">
                  {{ region.id | translate }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </ng-container>
        </mat-select>
        <mat-error>
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- Storage Class Selector -->
      <mat-form-field class="flex-grow" *ngIf="storageClassEnabled">
        <mat-label>{{'storageClass' | translate}}</mat-label>
        <mat-select cogDataId="storage-class"
          formControlName="storageClass"
          [inheritDataId]="true">
          <mat-option *ngFor="let storageClass of storageClassOptions$ | async"
            [cogDataId]="'storage-class-option-' + storageClass"
            [value]="storageClass">
            {{ ('externalTargets.storageClass.Rpaas.' + storageClass) | translate }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- Encryption Option -->
      <mat-form-field *ngIf="isEncryptionEditEnabled" class="flex-grow">
        <mat-label>{{'selectEncryptionOption' | translate}}</mat-label>
        <mat-select
          cogDataId="encryption"
          [inheritDataId]="true"
          formControlName="encryption"
          (selectionChange)="setValidators($event.value)">
          <mat-option *ngFor="let encryption of encryptionTypes$ | async"
            [value]="encryption"
            [cogDataId]="'encryption-' + encryption">
            {{'dms.encryptionType.' + encryption | translate}}
          </mat-option>
        </mat-select>
        <mat-error>
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Add/Delete Regions -->
    <div class="add-delete-container">
      <cog-icon [class.visuallyhidden]="hideDelete"
        cogDataId="remove-btn"
        [inheritDataId]="true"
        size="md"
        shape="remove_circle_outline"
        inline
        (click)="removeRegion()">
      </cog-icon>
      <cog-icon class="add margin-left-sm"
        cogDataId="add-btn"
        [inheritDataId]="true"
        shape="add_circle_outline"
        size="md"
        inline
        (click)="addRegion()">
      </cog-icon>
    </div>
  </div>

  <!-- Encryption for AWS -->
  <div class="row" *ngIf="region.value.encryption === 'CustomerManaged'">
    <div class="col-xs-10 col-offset-2">
      <p>{{'dms.encryptionTitle' | translate}}</p>
    </div>
    <div class="col-xs-10 col-offset-2">
      <mat-form-field>
        <mat-label>{{'dms.encrytionKey' | translate}}</mat-label>
        <input cogDataId="encryption-key"
          [inheritDataId]="true"
          (keydown)="encryptionKeyChange()"
          matInput
          formControlName="encryptionKey">
        <button
          cogDataId="get-json-btn"
          [inheritDataId]="true"
          type="button"
          mat-stroked-button
          class="json-btn"
          color="primary"
          (click)="getJSON()"
          matSuffix>
          {{'getJSON' | translate}}
        </button>
        <mat-error>
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-10 col-offset-2">
      <coh-dms-token class="aws-encryption-json"
        [value]="region.value.encryptionJSON"
        placeholder="dms.noEncryptionJSON"
        fileName="{{this.region.value?.regionId + '-data.json'}}"
        applicationType="application/json">
      </coh-dms-token>
      <mat-error class="encryption-json-error" *ngIf="encryptionJsonControl?.errors?.required">
        {{'errors.selfManagedKmsRequired' | translate}}
      </mat-error>
    </div>
    <div class="col-xs-10 col-offset-2">
      <p [innerHtml]="'dms.encryptionDesc' | translate"></p>
    </div>
  </div>
</form>

<!-- Wrapper to view save encryption -->
<div class="vault-form row" *ngIf="!focused">
  <div class="view-config">
    <ul class="c-ul-inline">
      <li *ngIf="region?.value?.vaultName"
        translate="labelColonValue"
        [translateParams]="{
          label: 'vault' | translate,
          value: region.value.vaultName
        }">
      </li>
      <li *ngIf="region?.value?.regionId"
        translate="labelColonValue"
        [translateParams]="{
          label: 'region' | translate,
          value: region.value.regionId | translate
        }">
      </li>
      <li *ngIf="region?.value?.storageClass"
        translate="labelColonValue"
        [translateParams]="{
          label: 'storageClass' | translate,
          value: ('externalTargets.storageClass.Rpaas.' + region.value.storageClass) | translate
        }">
      </li>
      <li *ngIf="region?.value?.encryption"
        translate="labelColonValue"
        [translateParams]="{
          label: 'encryption' | translate,
          value: ('dms.encryptionType.' + region.value.encryption) | translate
        }">
      </li>
    </ul>
    <cog-icon size="sm" cogDataId="edit-btn"
      [inheritDataId]="true" class="edit-btn" inline shape="edit" (click)="editRegion()">
    </cog-icon>
  </div>
</div>
