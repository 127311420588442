import { assign } from 'lodash-es';
// Component: Add AV Provider Group

;(function(angular) {
  'use strict';

  angular.module('C.antivirus')
    .controller('AddAvProviderCtrl', AddAvProviderCtrlFn);

  function AddAvProviderCtrlFn(_, $state, evalAJAX, cMessage,
    AntivirusService) {
    var $ctrl = this;

    assign($ctrl, {
      isEditMode: !!$state.params.id,

      $onInit: $onInit,
      onSubmit: onSubmit,
    });

    /**
     * Calls when component is initialized
     *
     * @method   $onInit
     */
    function $onInit() {
      if ($ctrl.isEditMode) {
        AntivirusService.getAvProvider($state.params.id).then(
          function gotAvProviderSuccess(provider) {
            $ctrl.newProvider = provider;
          },
          evalAJAX.errorMessage
        ).finally(function gotAvProviderFinally() {
          $ctrl.fetchedProvider = true;
        });
      } else {
        // Start with an empty row.
        $ctrl.newProvider = {
          antivirusServices: [{}],
        };
        $ctrl.fetchedProvider = true;

        // Fetch list of Providers because after adding a new provider we will
        // automatically enable it if this is the first.
        AntivirusService.getAvProviderList().then(
          function gotAvProvidersSuccess(providers) {
            $ctrl.atLeastOneProvider = !!providers.length;
          }
        );
      }
    }

    /**
     * Adds or Updates the AV Provider group.
     *
     * @method   addOrUpdateAvProvider
     * @param    {Object}   promise    Either Add or Update Promise.
     * @returns  {Object}   Promise to add or update AV Provider.
     */
    function addOrUpdateAvProvider(promise) {
      $ctrl.submitting = true;
      return promise.then(
        function addUpdateAvProviderSuccess(provider) {
          if (!$ctrl.isEditMode && !$ctrl.atLeastOneProvider) {
            // Enable Provider Group if it's the first one.
            AntivirusService.setAvProviderState({
              id: provider.id,
              enable: true,
            }).then(_goToProvidersList, evalAJAX.errorMessage);
          } else {
            return _goToProvidersList();
          }
        },
        evalAJAX.errorMessage
      ).finally(function addUpdateAvProviderFinally() {
        $ctrl.submitting = false;
      });
    }

    /**
     * Issue add/update API.
     *
     * @method   onSubmit
     */
    function onSubmit(newProvider) {
      var icapConnected;

      if ($ctrl.newProviderForm.$invalid) {
        return;
      }

      // For Edit Mode, simply update the Provider config.
      if ($ctrl.isEditMode) {
        return addOrUpdateAvProvider(
          AntivirusService.updateAvProvider(newProvider));
      }

      // For Create Mode, check ICAP connection status before attempting to add
      // this group. Only add the group on success.
      newProvider._fetchingIcapStatus = $ctrl.submitting = true;
      AntivirusService.checkIcapConnectionStatus(newProvider).then(
        function checkedIcapStatus(connected) {
          icapConnected = connected;
        }
      ).finally(function fetchedStatusFinally() {
        newProvider._fetchedOnce = true;
        newProvider._fetchingIcapStatus = false;

        // If ICAP not reachable, then do not add this group.
        if (!icapConnected) {
          $ctrl.submitting = false;
          return cMessage.error({
            textKey: 'antivirus.failedToEnable',
          });
        }

        addOrUpdateAvProvider(AntivirusService.addAvProvider(newProvider));
      });
    }

    /**
     * Redirects to list of Providers.
     *
     * @method    _goToProvidersList
     * @returns   {Object}    State redirect.
     */
    function _goToProvidersList() {
      return $state.go('antivirus.providers');
    }
  }

})(angular);
