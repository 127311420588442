import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {
  BackupPolicy,
  PolicyTemplateResponse,
  ProtectionPolicyResponse,
  RegularBackupPolicy,
  TargetsConfiguration,
} from '@cohesity/api/v2';
import { LimitInterval, mapErrorMessage as mapErrorMessageFn, PolicyScheduleUnit } from '@cohesity/shared-forms';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import moment from 'moment';
import { envGroups, Environment, TimePeriodValue } from 'src/app/shared/constants';
import { TimePeriodOptions } from 'src/app/shared/forms';
import { HumanizeFromDaysPipe } from 'src/app/shared/pipes';
import {
  fromRunSchedule as fromRunScheduleFn,
  retentionToTimePeriod as retentionToTimePeriodFn,
  timePeriodToRetention as timePeriodToRetentionFn,
  toRunSchedule as toRunScheduleFn,
} from 'src/app/util';

import { DataLockConfigValue } from './form-group-components';
import { PolicyFormModel } from './models/policy-form.model';

/**
 * Protection Policy Utilities.
 */
export namespace PolicyUtils {
  /**
   * Validation min/max for different fields
   */
  export const validations = {
    retain: {
      min: 1,
      max: 365000,
    },
    retries: {
      min: 0,
      max: 5,
    },
    retryInterval: {
      min: 1,
    },
  };

  /**
   * Retention type options.
   */
  export const retentionTypeOptions: TimePeriodOptions[] = [
    { value: 'Days', label: 'day', labelPlural: 'days' },
    { value: 'Weeks', label: 'week', labelPlural: 'weeks' },
    { value: 'Months', label: 'month', labelPlural: 'months' },
    { value: 'Years', label: 'year', labelPlural: 'years' },
  ];

  /**
   * All of the possible type options for when to make a backup copy
   */
  export const allTypeOptions: TimePeriodOptions[] = [
    { value: PolicyScheduleUnit.Runs, label: 'run', hideValue: true },
    { value: PolicyScheduleUnit.Hours, label: 'hour', labelPlural: 'hours' },
    { value: PolicyScheduleUnit.Days, label: 'day', labelPlural: 'days' },
    { value: PolicyScheduleUnit.Weeks, label: 'week', labelPlural: 'weeks' },
    { value: PolicyScheduleUnit.Months, label: 'month', labelPlural: 'months' },
    { value: PolicyScheduleUnit.Years, label: 'year', labelPlural: 'years' },
  ];

  /**
   * Environments that support CAD policies
   */
  export const cadPolicyEnvironments = [Environment.kVMware, ...envGroups.nas];

  /**
   * Utility function to convert ScheduleSelectorValue formModel to a RunSchedule API model.
   *
   * Note - Moved the definition to src/shared/utils/schedule-utils. Can use this from there.
   *
   * @param   formModel  ScheduleSelectorValue FormModel.
   * @return  RunSchedule model or undefined if formModel is invalid.
   */
  export const toRunSchedule = toRunScheduleFn;

  /**
   * Utility function to convert RunSchedule API module to a ScheduleSelectorValue formModel.
   *
   * Note - Moved the definition to src/shared/utils/schedule-utils. Can use this from there.
   *
   * @param   schedule  RunSchedule from API.
   * @return  SchedulingPolicy model or undefined if schedule is invalid.
   */
  export const fromRunSchedule = fromRunScheduleFn;

  /**
   * Converts retention API model to TimePeriodValue
   *
   * Note - Moved the definition to src/shared/utils/schedule-utils. Can use this from there.
   *
   * @param   retention   Retention API model.
   * @param   granularType Optional param, If the type is granular or not
   * @return  Corresponding TimePeriodValue.
   */
  export const retentionToTimePeriod = retentionToTimePeriodFn;

  /**
   * Converts timePeriodValue to retention API model.
   *
   * Note - Moved the definition to src/shared/utils/schedule-utils. Can use this from there.
   *
   * @param   retention    TimePeriodValue.
   * @param   granularType Optional param, If the type should be granular or not
   * @return  Corresponding Retention model.
   */
  export const timePeriodToRetention = timePeriodToRetentionFn;

  /**
   * Returns corresponding error message based on the formControl errors object.
   *
   * Note - Moved the definition to src/shared/utils. Can use this from there.
   *
   * @param formControl The formControl to be checked for error.
   */
  export const mapErrorMessage = mapErrorMessageFn;

  /**
   * Maps policy template readOnly form groups to lock them from editing.
   *
   * @param   policyTemplate  Policy template info.
   * @return  Policy readonly formGroup map.
   */
  export function mapReadOnlyFormGroup(policyTemplate: PolicyTemplateResponse): PolicyFormModel.ReadOnlyFormGroupMap {
    const {
      backupPolicy = {} as BackupPolicy,
      extendedRetention,
      blackoutWindow,
      retryOptions,
      remoteTargetPolicy = {} as TargetsConfiguration,
    } = policyTemplate;

    const { regular = {} as RegularBackupPolicy } = backupPolicy;

    return {
      incrementalBackup: !!regular.incremental,
      retention: !!regular.retention,
      continuousBackup: !!backupPolicy.cdp,
      extendedRetention: (extendedRetention || []).map(extRetention => extRetention.configId),
      fullBackup: !!regular.full,
      blackouts: (blackoutWindow || []).map(blackout => blackout.configId),
      retryOptions: !!retryOptions,
      systemBackup: !!backupPolicy.bmr,
      logBackup: !!backupPolicy.log,
      storageSnapshotBackup: !!backupPolicy.storageArraySnapshot,
      replication: (remoteTargetPolicy.replicationTargets || []).map(target => target.configId),
      archive: (remoteTargetPolicy.archivalTargets || []).map(target => target.configId),
      cloudSpin: (remoteTargetPolicy.cloudSpinTargets || []).map(target => target.configId),
      cloudVault: (remoteTargetPolicy.rpaasTargets || []).map(target => target.configId),
      vaultCluster: (remoteTargetPolicy.onpremVaultTargets || []).map(target => target.configId),
    };
  }

  /**
   * Returns true if the current policy is ProtectOnce.
   *
   * @param   policy   Protection Policy
   */
  export function isProtectOncePolicy(policy: ProtectionPolicyResponse): boolean {
    return policy?.backupPolicy?.regular?.full?.schedule?.unit === 'ProtectOnce';
  }

  /**
   * Returns true if the current policy is a Cascaded Replication policy.
   *
   * @param   policy   Protection Policy
   */
  export function isCascadedReplicationPolicy(policy: ProtectionPolicyResponse): boolean {
    return Boolean(policy?.cascadedTargetsConfig?.length);
  }

  /**
   * Return true if the current policy has datalock enabled.
   *
   * @param   policy   Protection policy info.
   */
  export function isDataLockPolicy(policy: any): boolean {
    const { backupPolicy, remoteTargetPolicy } = policy;
    return (
      !!get(backupPolicy, 'regular.retention.dataLockConfig') ||
      !!get(backupPolicy, 'log.retention.dataLockConfig') ||
      !!get(backupPolicy, 'bmr.retention.dataLockConfig') ||
      !!(policy.extendedRetention || []).find(extRetention => !!extRetention.retention.dataLockConfig) ||
      !!((remoteTargetPolicy?.replicationTargets) || []).find(
        target => !!target.retention.dataLockConfig
      ) ||
      !!((remoteTargetPolicy?.archivalTargets) || []).find(
        target => !!target.retention.dataLockConfig
      ) ||
      !!((remoteTargetPolicy?.cloudSpinTargets) || []).find(
        target => !!target.retention.dataLockConfig
      )
    );
  }

  /**
   * Returns true if the current policy is a Cloud Archive Direct policy.
   *
   * @param   policy   Protection Policy object either from a form, API, etc.
   */
  export function isCadPolicy(policy: any): boolean {
    return policy?.backupPolicy?.regular?.primaryBackupTarget?.targetType === 'Archival';
  }

  /**
   * Returns true if the current policy is a Continuous Data Protection Policy.
   *
   * @param   policy   Protection Policy object either from a form, API, etc.
   */
  export function isCdpPolicy(policy: any): boolean {
    return Boolean(policy?.backupPolicy?.cdp?.retention);
  }

  /**
   * Returns true if the current policy is a Continuous Data Protection Policy
   * which is also replicating to a target.
   *
   * @param   policy   Protection Policy object either from a form, API, etc.
   */
  export function isCdpReplicationPolicy(policy: any): boolean {
    return isCdpPolicy(policy) && policy?.remoteTargetPolicy?.replicationTargets?.length > 0;
  }

  /**
   * Returns true if the current policy is a Log Backup policy.
   *
   * @param   policy   Protection Policy object either from a form, API, etc.
   */
  export function isLogBackupPolicy(policy: any): boolean {
    return !!policy?.backupPolicy?.log;
  }

  /**
   * Returns true if a form group / form array in policy form has dataLock enabled.
   * This is different from the global dataLock toggle of the whole form.
   *
   * @param  control   Form group or form array control in the policy form.
   */
  export function isPolicyFormGroupDataLocked(control: UntypedFormGroup | UntypedFormArray): boolean {
    if (control instanceof UntypedFormGroup) {
      return control.value?.dataLockConfig?.dataLockConfigEnabled;
    } else if (control instanceof UntypedFormArray) {
      return (control.value || []).some(val => !!val.dataLockConfig?.dataLockConfigEnabled);
    }
  }

  /**
   * Utility function to remove all config ids from policy details for policy cloning.
   *
   * @returns  Policy with configIds reset to null.
   */
  export function removeConfigIds(policy: ProtectionPolicyResponse): ProtectionPolicyResponse {
    const { blackoutWindow, extendedRetention, remoteTargetPolicy = {} as TargetsConfiguration } = policy;

    if (blackoutWindow) {
      blackoutWindow.forEach(blackout => (blackout.configId = null));
    }

    if (extendedRetention) {
      extendedRetention.forEach(blackout => (blackout.configId = null));
    }

    if (remoteTargetPolicy) {
      const { replicationTargets, archivalTargets, cloudSpinTargets } = remoteTargetPolicy;

      if (replicationTargets) {
        replicationTargets.forEach(target => (target.configId = null));
      }

      if (archivalTargets) {
        archivalTargets.forEach(target => (target.configId = null));
      }

      if (cloudSpinTargets) {
        cloudSpinTargets.forEach(target => (target.configId = null));
      }
    }

    return {
      ...policy,
      blackoutWindow,
      extendedRetention,
      remoteTargetPolicy,
    };
  }

  /**
   * Constructs label for run schedules and target schedules
   * Run schedules include: incremental, full, bmr, log
   * Target schedules include: extended retention,
   *
   * @param   schedule   Run schedule or target schedule.
   * @param   translate  Translate service.
   * @param   humanizeFromDaysPipe Days pipe.
   * @return  Translated label for policy summary.
   */
  export function constructScheduleLabel(
    schedule: any,
    translate: TranslateService,
    humanizeFromDaysPipe: HumanizeFromDaysPipe
  ): string {
    if (!schedule) {
      return '';
    }

    const { unit } = schedule;
    let frequency: number;
    let day = '';
    let week = '';

    // Target schedule
    if (schedule.frequency) {
      frequency = schedule.frequency;
    } else {
      // Run schedule
      switch (unit) {
        case PolicyScheduleUnit.Minutes:
          frequency = get(schedule, 'minuteSchedule.frequency', 1);
          break;
        case PolicyScheduleUnit.Hours:
          frequency = get(schedule, 'hourSchedule.frequency', 1);
          break;
        case PolicyScheduleUnit.Days:
          frequency = get(schedule, 'daySchedule.frequency', 1);
          break;

        // For weeks, months, and runs, the frequency is always 1 for run schedule.
        case PolicyScheduleUnit.Weeks:
          frequency = 1;
          day = humanizeFromDaysPipe.transform(get(schedule, 'weekSchedule.dayOfWeek', []));
          break;
        case PolicyScheduleUnit.Months: {
          frequency = 1;
          const { monthSchedule } = schedule;
          if (monthSchedule) {
            const { dayOfWeek, weekOfMonth, dayOfMonth } = monthSchedule;
            if (dayOfMonth) {
              day = moment.localeData().ordinal(dayOfMonth);
            } else if (dayOfWeek?.[0] && weekOfMonth) {
              day = translate.instant(dayOfWeek[0].toLowerCase());
              week = translate.instant(`enum.dayCountInMonth.${weekOfMonth.toLowerCase()}`);
            }
          }
          break;
        }
        case PolicyScheduleUnit.Years: {
          frequency = 1;
          const { yearSchedule } = schedule;
          if (yearSchedule) {
            const { dayOfYear } = yearSchedule;
            day = translate.instant(dayOfYear.toLowerCase());
          }
          break;
        }
        case PolicyScheduleUnit.Runs:
          frequency = 1;
          break;
      }
    }

    const prefix = 'policyDetails.periodicity';

    // Determine suffix based on frequency and day value.
    const suffix = frequency > 1 ? 'plural' : day ? 'singularWithDay' : 'singular';

    return translate.instant(`${prefix}.${unit}.${suffix}`, { frequency, day, week });
  }

  /**
   * Switches the type with a bigger one.
   *
   * @param type a time unit like: minute, hour, day, week, month, year.
   * @returns the next, bigger, time unit or the same in case of year.
   */
  export function nextRetainType(type: PolicyScheduleUnit): PolicyScheduleUnit {
    switch (type) {
      case PolicyScheduleUnit.Minutes:
        return PolicyScheduleUnit.Hours;
      case PolicyScheduleUnit.Hours:
        return PolicyScheduleUnit.Days;
      case PolicyScheduleUnit.Days:
        return PolicyScheduleUnit.Weeks;
      case PolicyScheduleUnit.Weeks:
        return PolicyScheduleUnit.Months;
      case PolicyScheduleUnit.Months:
        return PolicyScheduleUnit.Years;
      case PolicyScheduleUnit.Years:
        return PolicyScheduleUnit.Years;
      default:
        return PolicyScheduleUnit.Days;
    }
  }

  /**
   * Gets the limit interval for dataLock retention based on the regular retention.
   * DataLock retention cannot be longer than the regular retention.
   *
   * @param    retention  The regular retention.
   * @returns  The Limit interval for dataLock retention.
   */
  export function getDataLockRetentionLimit(retention: TimePeriodValue): LimitInterval {
    const { type, value } = retention ?? {};
    return {
      max: value ?? validations.retain.max,
      granularity: type
    };
  }

  /**
   * Gets the limit interval for regular retention based on the dataLock retention.
   * This is only applicable when dataLock is readonly (non DSO user edits a dataLocked policy).
   *
   * @param    dataLockConfig  The dataLock config.
   * @returns  The Limit interval for regular retention or undefined if dataLock is not enabled.
   */
  export function getRegularRetentionLimit(dataLockConfig: DataLockConfigValue): LimitInterval {
    const { dataLockConfigEnabled = false, dataLockConfigDuration } = dataLockConfig ?? {};

    if (!dataLockConfigEnabled) {
      return;
    }

    const { type, value } = dataLockConfigDuration ?? {};
    return {
      min: value ?? validations.retain.min,
      granularity: type
    };
  }
}
