import { assign } from 'lodash-es';
// Component: Select Resource Pool

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectResourcePool';
  var configOptions = {
    controller: selectResourcePoolCtrl,
    templateUrl: 'app/global/select-resource-pool/select-resource-pool.html',

    // Attribute bindings
    bindings: {
      /**
       * The id of the vCenter desired for resource pool selection
       *
       * @type   {number}
       */
      vCenterId: '<',

      /**
       * Optional attribute sniffed value, if attribute is present the uiSelect
       * component will open automatically on initialization if the model is
       * empty.
       */
      // autoOpenIfEmpty: '?'
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  };

  angular.module('C.selectResourcePool', ['ui.select'])
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectResourcePool:selectResourcePool
   * @scope
   * @link
   *
   * @requires ngModel
   * @methodOf angular.Module|AUTO.
   * @function
   * @description
   *   Provides a dropdown component for selecting a Resource Pool
   *
   * @example
       <select-resource-pool
         v-center-id="1"
         ng-model="$ctrl.selectedViewBox"></select-resource-pool>
   */
  function selectResourcePoolCtrl(_, $attrs, SourceService, evalAJAX) {
    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,
      $onChanges: $onChanges,

      // binding $attrs for disabled state pass through
      $attrs: $attrs,
      resourcePools: [],
      selectResourcePool: selectResourcePool,
    });

    /**
     * Initialize this component.
     *
     * @method     init
     */
    function $onInit() {
      angular.extend($ctrl, {
        id: [($attrs.id || componentName), 'ui-select'].join('-'),

        /*
         * TODO: / NOTE:
         * 1) if "name" would have been dynamically generated externally then
         * internal name will have an expression included.
         *
         * 2) Name is not getting used in the component template. Add it.
         */
        name: [componentName, $attrs.name, Date.now()].join('-'),
      });
    }

    function $onChanges(changesObj) {
      if (changesObj.vCenterId.currentValue) {
        _getResourcePools();
      }
    }

    /**
     * Set the model with the provided ViewBox, or open the new View Box modal
     * if no View Box provided, selecting the newly created View Box on
     * successful create.
     *
     * @method   selectResourcePool
     * @param    {object}   [resourcePool]   The selected View Box
     */
    function selectResourcePool(resourcePool) {
      $ctrl.ngModel.$setViewValue($ctrl.selectedResourcePool = resourcePool);
    }

    /**
     * Gets the Resource Pools
     *
     * @method   getResourcePools
     * @return   {object}   Promise to resolve with the requested data.
     */
    function _getResourcePools() {
      if (!$ctrl.vCenterId) { return; }

      $ctrl.loading = true;

      SourceService.getResourcePool({ vCenterId: $ctrl.vCenterId }).then(
        function getResourcePoolSuccess(pools) {
          $ctrl.resourcePools = pools;
        },
        evalAJAX.errorMessage
      ).finally(
        function getResourcePoolFinally() {
          $ctrl.loading = false;
        }
      );
    }
  }


})(angular);
