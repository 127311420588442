import { Injectable } from '@angular/core';
import { RemoteClusterApi } from '@cohesity/api/private';
import { ClusterInfo } from '@cohesity/api/v1';
import {
  RegisterRemoteClusterParameters,
  RemoteCluster,
  RemoteClusters,
  RemoteClustersServiceApi,
  StorageDomain,
  StorageDomainServiceApi,
} from '@cohesity/api/v2';
import { ClusterConfig, IrisContextService, flagEnabled, isMcm } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { filter as _filter, orderBy } from 'lodash-es';
import { Observable, forkJoin, from, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';

import { AjsUpgradeService } from './ajs-upgrade.service';
import { AppStateService } from './app-state.service';
import { ClusterService } from './cluster.service';
import { DialogService } from './dialog.service';
import { UserService } from './user.service';

// Cached value of the accessCluster, as it should not change during application lifecycle.
let accessCluster;

/**
 * Local and Remote storage domain pair
 *
 * @export
 * @interface StorageDomainPair
 */
export interface StorageDomainPairCalc {
  local: { localViewboxes: StorageDomain[]; encrypted: StorageDomain[]};
  remote: { remoteViewboxes: StorageDomain[]; encrypted: StorageDomain[]};
  }

@Injectable({
  providedIn: 'root'
})
export class RemoteClusterService {
  get selectedScope(): ClusterConfig {
    return this.irisContext.irisContext.selectedScope;
  }

  set selectedScope(cluster: ClusterConfig) {
    this.appStateService.selectedScope = cluster;
  }

  get clusterInfo(): ClusterInfo | any {
    return this.irisContext.irisContext.clusterInfo;
  }

  /**
   * Injected ajs services.
   */
  private heliosService: any;

  constructor(
    private appServiceManager: AppServiceManagerService,
    private ajaxHandlerService: AjaxHandlerService,
    private ajsUpgrade: AjsUpgradeService,
    private appStateService: AppStateService,
    private clusterService: ClusterService,
    private dialogService: DialogService,
    private remoteClusterApi: RemoteClusterApi,
    private remoteClusterServiceApi: RemoteClustersServiceApi,
    private storageDomainService: StorageDomainServiceApi,
    private translateService: TranslateService,
    private irisContext: IrisContextService,
    private userService: UserService,
  ) {
    this.heliosService = ajsUpgrade.get('HeliosService');
  }

  /**
   * Fetch the list of remote cluster including all and access cluster.
   *
   * @returns Promise resolved with list of cluster else rejected with error.
   */
  getRemoteClustersList(): Observable<ClusterConfig[]> {
    let observable: Observable<ClusterConfig[]>;

    /**
     * The cluster info where user had logged in.
     *
     * 1. for helios this would be null as there is no access cluster there.
     * 2. or on-prem SPOG this would be the cluster where user had logged in.
     */
    accessCluster = accessCluster ||
      Object.assign({}, this.clusterInfo, {
        clusterId: this.clusterInfo.id,

        // Resetting _allClusters as ajs is also cluster switching hence we need to reset this property manually unit
        // ajs logic is removed completely.
        _allClusters: false,
        _cohesityService: false,
        _globalContext: false,
        _serviceType: null,
      });

    /**
     * All Clusters context
     */
    const allCluster: ClusterConfig = Object.assign({}, accessCluster, {
      name: this.translateService.instant('allClusters'),
      _allClusters: true,
      _cohesityService: false,
      _globalContext: false,
      _nonCluster: true,
      _serviceType: null,
    });

    // get the list of registered clusters.
    if (isMcm(this.irisContext.irisContext)) {
      observable = from(this.heliosService.getClustersStatus()) as Observable<ClusterConfig[]>;
    } else if (this.irisContext.irisContext.privs.CLUSTER_REMOTE_VIEW) {
      observable =
        this.remoteClusterServiceApi.GetRemoteClusters({ purpose: ['RemoteAccess'] }) as Observable<ClusterConfig[]>;
    } else {
      observable = of([]);
    }

    return observable.pipe(
      tap((clustersList: ClusterConfig[]) => {
        if (isMcm(this.irisContext.irisContext)) {
          // Updating stale clusterIdentifier attribute of loggedIn User
          const clusterIdentifiers = (clustersList || [])
            .map(({ clusterId, clusterIncarnationId }) => ({ clusterId, clusterIncarnationId })
          );
          // this eventually call the subscription over loginData$ in user.service.ts
          this.userService.updateClusterIdentifiers(clusterIdentifiers);
        }
      }),
      map((clustersList: ClusterConfig[]) => {
      // In some instance we might not have cluster name populated, since cluster may be
      // registered only for licensing.
      clustersList = _filter(clustersList, cluster => !!(cluster.name || cluster.clusterName));

      // Order the list such that connected cluster comes 1st and then ordered list by cluster name.
      // NOTE: name property will be present for on-prem and clusterName for helios.
      const clusters = orderBy(clustersList,
        ['connectedToCluster', cluster => (cluster.name || cluster.clusterName).toLowerCase()], ['desc', 'asc']);

      if (isMcm(this.irisContext.irisContext)) {
        const availableContexts: ClusterConfig[] = this.appServiceManager.getAvailableServices()
        .map(service => ({
          ...accessCluster,
          ...service.clusterConfigPartial
        }));

        const saasServices = availableContexts.filter(context => context._cohesityService);
        const rpaasService = availableContexts.find(context => context._serviceType === 'rpaas');
        const draasService = availableContexts.find(context => context._serviceType === 'draas');

        if (saasServices.length) {

          // By default all users have access to manage clusters and data protect (DMaaS)
          // The way we differentiate only DataProtect users is via the following
          // 1. No DMaaS/ DRaaS entitlement, Regular Helios User
          // 2. If DMaaS/ DRaaS entitlement and no cluster => Data Protect/ SiteContinuity user
          // 3. If DMaaS/ DRaaS entitlement and clusters => Hybrid users.
          // 4. If Rpaas entitlement, with or without clusters => Hybrid users
          if (!clusters?.length && !rpaasService && !draasService) {
            return saasServices;
          }

          return [
            allCluster,
            ...availableContexts,
            ...clusters,
          ].filter(Boolean);
        }
        // For Helios there is no access cluster.
        return [allCluster, ...clusters];
      } else {
        // On-prem (non helios) Nextgen UI does not support "All Clusters"
        return [accessCluster, ...clusters];
      }
    }));
  }

  /**
   * Fetches the list of only remote clusters.
   *
   * @returns The list of remote clusters.
   */
  getOnlyRemoteClusters(): Observable<RemoteClusters> {
    return this.remoteClusterServiceApi.GetRemoteClusters({});
  }

  /**
   * Setup ajs watcher for remote cluster list updates sync app state accordingly.
   *
   * @param  forceRefresh  Optionally if present and true then force update the list else allow update only for access
   * cluster only.
   */
  updateRemoteClusterList(forceRefresh: boolean = false) {
    // Update the remote clusters list if new remote cluster was added for access cluster only.
    if (forceRefresh || this.isAccessCluster(this.appStateService.selectedScope)) {
      this.getRemoteClustersList().subscribe(remoteClusterList => {
        this.appStateService.remoteClusterList = remoteClusterList;
      });
    }
  }

  /**
   * Returns whether the current context is in mcm passthrough mode.
   */
  get isMcmPassthrough(): boolean {
    if (!isMcm(this.irisContext.irisContext)) {
      return false;
    }

    return !this.selectedScope._allClusters && !this.selectedScope._serviceType;
  }

  /**
   * Determines whether provided cluster is access cluster or not and Access cluster will present
   * only in on-prem using
   *  SPOG and it would be 1st cluster in the remoteClusterList.
   *
   * @param  cluster  The cluster to Test.
   * @returns  Return true for cluster is access cluster else return false.
   */
  isAccessCluster(cluster: ClusterConfig): boolean {
    const accessClusterIndex = 0;

    return !cluster._allClusters && !isMcm(this.irisContext.irisContext) &&
      (this.appStateService.remoteClusterList[accessClusterIndex].clusterId === cluster.clusterId);
  }

  /**
   * Get the access cluster from the remote cluster list.
   *
   * @returns  Return the access cluster.
   */
  getAccessCluster() {
    return this.appStateService.remoteClusterList.find(cluster => this.isAccessCluster(cluster));
  }

  /**
   * Gets the remote cluster by Id.
   *
   * @param  id  The id of the remote cluster.
   * @returns The remote cluster.
   */
  getRemoteCluster(id: number): Observable<RemoteCluster> {
    return this.remoteClusterServiceApi.GetRemoteClusterById({ clusterId: id });
  }

  /**
   * Gets the generated encryption key for the remote cluster.
   */
  getGeneratedEncryptionKey(): Observable<null | string> {
    return this.remoteClusterServiceApi.GetReplicationEncryptionKey()
      .pipe(map(res => res.encryptionKey));
  }

  /**
   * Validate the connection of the remote cluster. Works with or without
   * clusterId.
   *
   * Note - Need to pass the whole cluster details as the backend doesnt support
   *        only passing id.
   *
   * @param cluster The Remote cluster to validate.
   * @returns The validated remote cluster.
   */
  validateConnection(cluster: RemoteCluster): Observable<RemoteCluster> {
    const params: RemoteClustersServiceApi.ValidateRemoteClusterParams = {
      ...(cluster.clusterId && { clusterId: cluster.clusterId }),
      body: {
        nodeAddresses: cluster.nodeAddresses,
        password: cluster.password,
        username: cluster.username,
      }
    };
    return this.remoteClusterServiceApi.ValidateRemoteCluster(params);
  }

  /**
   * Registers the remote cluster.
   *
   * @param  cluster The cluster to register.
   * @returns The newly registered cluster.
   */
  registerRemoteCluster(cluster: RemoteCluster, accessClusterId?: number):
    Observable<RemoteCluster> {
    return this.remoteClusterServiceApi.RegisterRemoteCluster({
      accessClusterId: accessClusterId,
      body: cluster as RegisterRemoteClusterParameters,
    });
  }

  /**
   * Updates the remote cluster.
   *
   * @param cluster The Cluster to update.
   * @returns The updated remote cluster.
   */
  updateRemoteCluster(cluster: RemoteCluster, clusterId: number): Observable<RemoteCluster> {
    const param: RemoteClustersServiceApi.UpdateRemoteClusterParams = {
      clusterId: clusterId,
      body: {...cluster},
    };
    return this.remoteClusterServiceApi.UpdateRemoteCluster(param);
  }

  /**
   * Gets the list of viewboxes linked to the corresponding remote cluster.
   *
   * Note: This will pass the clusterId instead of the whole cluster if the
   *       cluster is already registered i.e. clusterId is already present.
   *
   * @param  cluster The remote cluster/clusterId.
   * @returns The list of veiwboxes
   */
  getRemoteViewBoxes(cluster: RemoteCluster): Observable<StorageDomain[]> {
    // If username and password are present, then always pass those new creds.
    const params = cluster.username && cluster.password ? cluster : cluster.clusterId;
    return this.remoteClusterApi.getRemoteViewBoxes(params).pipe(
      map((resp) => resp.storageDomains)
    );
  }

  /**
   * Opens the dialog to delete the remote cluster.
   *
   * @param remoteCluster The remote cluster to delete.
   * @returns Completed observable after the cluster is deleted.
   */
  deleteRemoteCluster(remoteCluster: RemoteCluster): Observable<unknown> {
    const contentText = this.translateService.instant(
      'replication.deleteConnectionConfirmText',
      { local: this.clusterInfo.name, remote: remoteCluster.clusterName });

    const dialogData = {
      title: 'replication.deleteConnectionTitle',
      copy: contentText,
      confirmHandler: () => this.remoteClusterServiceApi.DeleteRemoteCluster({ clusterId: remoteCluster.clusterId })
        .pipe(this.ajaxHandlerService.catchAndHandleError()),
      confirmButtonLabel: 'delete',
      declineButtonLabel: 'cancel',
    };

    return this.dialogService.simpleDialog(null, dialogData, { width: '30rem' })
      .pipe(filter(result => !!result));
  }

  /**
   * Check if SPOG is enabled or not.
   *
   * @returns True if SPOG is enabled.
   */
  isSpogSupported(): boolean {
    // SPOG is no longer supported, but we will remove this functionality in a separate CR.
    return this.irisContext.irisContext.privs.CLUSTER_REMOTE_VIEW
      && flagEnabled(this.irisContext.irisContext, 'spogEnabled')
      && !flagEnabled(this.irisContext.irisContext, 'spogDeprecated');
  }

  /**
   * Returns the encryption algorithm name if configured, otherwise "Not configured"
   *
   * @return String. If encrypted, returns the encryption Algorithm,
   */
  getEncryptionInFlight(effectiveAesEncryptionMode: string): string {
    let encryptionLabelKey = 'notConfigured';

    switch (effectiveAesEncryptionMode) {
      case 'GCM':
      case 'kGCM':
        encryptionLabelKey = 'aesGCMEncryption';
        break;

      case 'CBC':
      case 'kCBC':
        encryptionLabelKey = 'aesCBCEncryption';
        break;

      default:
        // some other case, fallback to 'Not configured'
        encryptionLabelKey = 'notConfigured';
        break;
    }

    return this.translateService.instant(encryptionLabelKey);
  }

  /**
   * Makes API call to fetch storage domains data for both
   * local and remote viewboxes and transforms it to make usable in UI.
   *
   * @param clusterId Cluster ID.
   * @return Observable<StorageDomainPairCalc>
   * @memberof RemoteClusterService
   */
  public fetchStorageDomainsData(clusterId: number): Observable<StorageDomainPairCalc> {
    const localApiCall = this.storageDomainService.GetStorageDomains({ includeTenants: true });
    const remoteApiCall = this.remoteClusterApi.getRemoteViewBoxes(clusterId);
    const apiCalls = [localApiCall, remoteApiCall];
    return forkJoin(apiCalls).pipe(
      map(results => {
        const localViewboxes: StorageDomain[] = results[0].storageDomains;
        const remoteViewboxes: StorageDomain[] = results[1].storageDomains;

        // Pick the fields to return
        const storageDomainPairData: StorageDomainPairCalc = {
          local: { localViewboxes, encrypted: this.getEncryptedViewboxes(localViewboxes) },
          remote: {
            remoteViewboxes,
            encrypted: this.getEncryptedViewboxes(remoteViewboxes),
          },
        };

        return storageDomainPairData;
      })
    );
  }

  /**
   * Filters out viewboxes that are Encrypted.
   *
   * @param viewBoxes
   * @memberof RemoteClusterService
   */
  getEncryptedViewboxes = (viewBoxes: StorageDomain[]): StorageDomain[] =>
    viewBoxes?.filter(
      viewbox =>
        viewbox?.storagePolicy?.encryptionType && viewbox?.storagePolicy?.encryptionType === 'Strong'
    );
}
