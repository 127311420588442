import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

// appsManagementEnabled feature flag should be enabled and user should have APP_LAUNCH privilege.
const appsAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.appsManagementEnabled && ctx.APP_LAUNCH &&
  ctx.CLUSTER_VIEW && getConfigByKey(ctx.irisContext, 'marketplace.features.allow', true);

export const AppDashboardConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'app-store-dashboard.**',
      url: '/app-store-dashboard',
      loadChildren: () => import('./apps-dashboard.module').then(m => m.AppsDashboardModule)
    },
  ],
  allClusterMap: {
    'app-store-dashboard': false,
  },

  /**
   * Method to get state access map.
   */
  getStateAccessMap(): StateAccessMap {
    return {
      'app-store-dashboard': appsAccess,
    };
  }
};
