/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { McmSessionMgmtConfig } from '../models/mcm-session-mgmt-config';
@Injectable({
  providedIn: 'root',
})
class SessionManagementService extends __BaseService {
  static readonly GetMcmSessionMgmtConfigPath = '/mcm/session-mgmt-config';
  static readonly SaveMcmSessionMgmtConfigPath = '/mcm/session-mgmt-config';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Fetch Session Management Config
   *
   * Specifies the request to fetch the session management configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the session management config response.
   */
  GetMcmSessionMgmtConfigResponse(regionId?: string): __Observable<__StrictHttpResponse<McmSessionMgmtConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/session-mgmt-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSessionMgmtConfig>;
      })
    );
  }
  /**
   * Fetch Session Management Config
   *
   * Specifies the request to fetch the session management configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the session management config response.
   */
  GetMcmSessionMgmtConfig(regionId?: string): __Observable<McmSessionMgmtConfig> {
    return this.GetMcmSessionMgmtConfigResponse(regionId).pipe(
      __map(_r => _r.body as McmSessionMgmtConfig)
    );
  }

  /**
   * Save Session Management Config
   *
   * Specifies the request to save session management configuration.
   * @param body Specifies the request parameters to save session management configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the session management config response.
   */
  SaveMcmSessionMgmtConfigResponse(body: McmSessionMgmtConfig,
    regionId?: string): __Observable<__StrictHttpResponse<McmSessionMgmtConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/session-mgmt-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSessionMgmtConfig>;
      })
    );
  }
  /**
   * Save Session Management Config
   *
   * Specifies the request to save session management configuration.
   * @param body Specifies the request parameters to save session management configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the session management config response.
   */
  SaveMcmSessionMgmtConfig(body: McmSessionMgmtConfig,
    regionId?: string): __Observable<McmSessionMgmtConfig> {
    return this.SaveMcmSessionMgmtConfigResponse(body, regionId).pipe(
      __map(_r => _r.body as McmSessionMgmtConfig)
    );
  }
}

module SessionManagementService {
}

export { SessionManagementService }
