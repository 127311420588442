import { assign } from 'lodash-es';
// Component: Field message detail modal

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    // Define bindings
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
    controller: 'FieldMessageDetailCtrl',
    templateUrl: 'app/monitoring/field-messages/field-message-detail.html',
  };

  angular.module('C.fieldMessages')
    .controller('FieldMessageDetailCtrl', fieldMessageDetailCtrlFn)
    .component('fieldMessageDetail', configOptions);

  function fieldMessageDetailCtrlFn(_, $state) {
    var $ctrl = this;

    // declare component methods
    assign($ctrl, {
      close: close,
    });

    /**
     * handles on close action for modal
     *
     * @method   close
     */
    function close() {
      $ctrl.modalInstance.dismiss();

      // Reload current state to remove query param
      $state.transitionTo($state.current);
    }

  }

})(angular);
