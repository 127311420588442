import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { AffectedFile, FileChangeType } from '@cohesity/api/argus';
import { RestoreTask, RestoreTasksServiceApi } from '@cohesity/api/v1';
import { DataFilterValue, FiltersComponent, NavItem, SnackBarService, ValueFilterSelection } from '@cohesity/helix';
import { emptyLinkFn, emptyLinkParamsFn, IrisNavigationService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { find, isEmpty, isEqual } from 'lodash-es';
import { pairwise, startWith, takeUntil, tap } from 'rxjs/operators';

import { SecurityDialogService } from '../../dialogs';
import { FileDataSource } from '../../file-data-source';
import {
  AffectedFileAction,
  AffectedFilesFilter,
  AnomalyAlert,
  AnomalyChartSeries,
  changeTypeActions,
  changeTypeIconMap,
  ChartPoint,
  FileStats,
  SensitivitiesFilters,
} from '../../security-shared.models';
import { RecoveryTaskService } from '../../services';

/**
 * Affected files component
 * This component will list all affected files with download action
 *
 * @example
 * <dg-ar-affected-files
 *  [cleanPoints]="cleanPoints"
 *  [data]="data"
 *  [seriesData]="seriesData"
 *  [selectedPoint]="selectedPoint">
 * </dg-ar-affected-files>
 *
 */
@Component({
  selector: 'dg-ar-affected-files',
  templateUrl: './affected-files.component.html',
  styleUrls: ['./affected-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedFilesComponent extends AutoDestroyable implements OnChanges, AfterViewInit, OnDestroy {
  /**
   * Display columns of affected files table
   */
  displayColumns = ['filename', 'operation', 'option'];

  /**
   * List of environments which need creds while recovering.
   */
  credentialsRequiredEnvs = [Environment.kVMware];

  /** Specifies whether to hide the sub title */
  @Input() hideSubTitle = false;

  /** Specifies whether to hide the change type stats or not */
  @Input() hideChangeTypeStats = false;

  /** Specifies whether to show pattern filter or not */
  @Input() showPatternFilter = false;

  /** Specifies whether to show change type filter or not */
  @Input() showChangeTypeFilter = true;

  /**
   * Specifies whether to show filters or not
   */
  @Input() hideFilters = false;

  /** Specifies whether filter is ready or not */
  get isFilterReady() {
    return this.showPatternFilter ? this.patternFilterReady : true;
  }

  /**
   * true, if every filter is marked as hidden
   */
  get areFiltersHidden(): boolean {
    return [
      this.showChangeTypeFilter,
      this.showPatternFilter
    ].every(filter => filter === false);
  }

  /**
   * Flag holding the status of pattern filter
   */
  patternFilterReady = false;

  /**
   * Reference of table filters.
   */
  @ViewChild(FiltersComponent) private filtersComponent: FiltersComponent;

  /**
   * Locked Filters
   */
  @Input() lockedFilters: (keyof AffectedFilesFilter)[];

  /**
   * Timestamp data points
   */
  @Input() cleanPoints: string[];

  /**
   * Anomaly data properties
   */
  @Input() data: AnomalyAlert;

  /**
   * Holds the series of graph related data of anomaly
   */
  @Input() seriesData: AnomalyChartSeries;

  /**
   * Current selected point on anomaly graph
   */
  @Input() selectedPoint: ChartPoint;

  /**
   * The state to use for anomaly recover details.
   */
  @Input() getRecoverDetailLinkFn = emptyLinkFn;

  /**
   * Recover detail state params
   */
  @Input() getRecoverDetailLinkParamsFn = emptyLinkParamsFn;

  /**
   * Holds all affected files related stats
   */
  @Input() fileStats?: FileStats = {};

  /**
   * affected files filters to filter affected files
   */
  @Input() affectedFilesFilters: AffectedFilesFilter;

  /**
   * stores all the filters used for the previous api call for affected files
   */
  appliedAffectedFilesFilters: AffectedFilesFilter;

  /**
   * Affected files changeType filters
   */
  @Input() changeTypeFilters: ValueFilterSelection[];

  /**
   * Table data source
   * It can either be inline or regular data source.
   */
  @Input() tableDataSource: FileDataSource;

  inlineFileDiffSupported: boolean;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private irisNavigationService: IrisNavigationService,
    private recoveryTaskService: RecoveryTaskService,
    private restoreTasksServiceApi: RestoreTasksServiceApi,
    private securityDialogService: SecurityDialogService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Initialize the filters after component is rendered.
   */
  ngAfterViewInit() {
    this.lockFilters();

    const paginator = this.tableDataSource?.paginator;
    const pageEvent: PageEvent = {
      pageIndex: paginator ? paginator.pageIndex : 0,
      pageSize: paginator ? paginator.pageSize : 25,
      length: paginator ? paginator.length : Number.MAX_SAFE_INTEGER,
    };

    // on page size aka items per page changes reset the pagination token.
    paginator?.page?.pipe(
      this.untilDestroy(),
      startWith(pageEvent),
      pairwise(),
      tap(([prevPage, page]) => {
        if (prevPage.pageSize !== page.pageSize) {
          paginator.pageIndex = 0;
        }
      })
    ).subscribe();
  }

  /**
   * locks all the filter in locked filters list
   */
  private lockFilters() {
    if (this.filtersComponent && this.lockedFilters) {
      this.lockedFilters.forEach(filter =>
        this.filtersComponent.lockFilter(filter, this.translateService.instant('argus.affectedFiles.lockedFilter'))
      );
    }
  }

  /**
   * Detects input change from parent component
   *
   * @param changes input changes metadata
   */
  ngOnChanges(changes: SimpleChanges) {
    const { data, seriesData, selectedPoint, fileStats, tableDataSource, lockFilters } = changes;

    if (
      this.isChanged(data) ||
      this.isChanged(seriesData) ||
      this.isChanged(selectedPoint) ||
      this.isChanged(fileStats)
    ) {
      tableDataSource?.previousValue?.cleanUp();
      this.updateTableDataSource();
    }

    if (this.isChanged(data)) {
      this.inlineFileDiffSupported = data?.currentValue.properties?.inlineSnapshotDiff ?? false;
    }

    if (this.isChanged(lockFilters)) {
      this.lockFilters();
    }
  }

  /**
   * Indicates whether input binding got changed or not.
   *
   * @param change The change object for a given input.
   * @returns True if input biding got changed else it will return false.
   */
  private isChanged(change: SimpleChange): boolean {
    return !isEmpty(change?.currentValue) && !isEqual(change?.currentValue, change?.previousValue);
  }

  /**
   * Once the component is about to get destroyed, empty the dataset
   * to avoid caching which will affect next details which might not have file diff.
   */
  ngOnDestroy() {
    // clean-up table data source
    this.tableDataSource?.cleanUp();

    super.ngOnDestroy();
  }

  /**
   * Updates the data source
   */
  updateTableDataSource() {
    // Assign table data source
    this.tableDataSource?.setFilters({
      anomaly: this.data,
      affectedFilesFilters: this.affectedFilesFilters,
      selectedPoint: this.selectedPoint,
      seriesData: this.seriesData,
      fileStats: this.fileStats,
    });
  }

  /**
   * Applies filter and update the table
   *
   * @param filters selected filters
   */
  applyFilters(filters: DataFilterValue<any, any>[]) {
    const params: Partial<SensitivitiesFilters> = {};
    filters?.forEach(({ key, value }) => {
      switch (key) {
        case 'changeTypes':
          params.changeTypes = value.map(filter => filter.value);
          break;
        case 'patternIds':
          params.patternIds = value.map(filter => filter.value);
      }
    });
    const newFilters = {
      ...this.affectedFilesFilters,
      ...params,
    };
    if (isEqual(this.appliedAffectedFilesFilters, newFilters)) {
      return;
    }
    this.appliedAffectedFilesFilters = newFilters;
    this.tableDataSource.setFilters({
      anomaly: this.data,
      affectedFilesFilters: this.appliedAffectedFilesFilters,
      selectedPoint: this.selectedPoint,
      seriesData: this.seriesData,
      fileStats: this.fileStats,
    });
  }

  /**
   * Gets icon name from icon map
   *
   * @param changeType affected files change type
   * @returns icon value
   */
  getChangeTypeIcon(changeType: FileChangeType): string {
    return changeTypeIconMap[changeType] ?? changeTypeIconMap.kAny;
  }

  /**
   * Gets icon name from icon map
   *
   * @param changeType affected files change type
   * @returns icon value
   */
  getChangeTypeActions(changeType: FileChangeType): NavItem[] {
    return changeTypeActions[changeType] ?? [];
  }

  /**
   * Handles action from Nav item menu
   *
   * @param   navItem   Selected nav item
   * @param   row   Current row information
   */
  download(navItem: NavItem, row: AffectedFile) {
    switch (navItem.displayName) {
      case AffectedFileAction.downloadFile:
        this.createSingleFileDownloadTask(row);
        break;

      case AffectedFileAction.recoverFile:
        this.openFileRecoveryDialog(row);
        break;
    }
  }

  /**
   * Creates download task for selected file.
   *
   * @param   row   Current row information includes file name
   */
  createSingleFileDownloadTask(row: AffectedFile) {
    // Get the anomalous snapshot and its instance id
    const dataPoint = find(this.seriesData.dataPointVec, {
      timestampUsecs: this.selectedPoint.x,
    });

    const downloadFileTaskBody = this.recoveryTaskService.getDownloadTask(this.data, dataPoint, [row]);

    this.recoveryTaskService
      .createDownloadFilesTask(downloadFileTaskBody)
      .pipe(this.untilDestroy())
      .subscribe(response => {
        this.snackBarService
          .openWithAction(
            this.translateService.instant('anomalyRecovery.downloadTask'),
            this.translateService.instant('viewTask'),
            'success'
          )
          .subscribe(() => this.goToRecoveryDetail(response.id));
      }, this.ajaxHandlerService.handler);
  }

  /**
   * Opens recovery dialog to show default settings with which recovery will happen
   *
   * @param row   Object   row on which recovery is clicked
   */
  openFileRecoveryDialog(row: AffectedFile) {
    const { environment, object, jobStartTimeUsecs } = this.data.properties;
    const showCredentials = this.credentialsRequiredEnvs.includes(Environment[environment]);

    this.securityDialogService
      .launchRecoverySnapshotSelectorDialog(showCredentials, object, jobStartTimeUsecs, this.cleanPoints)
      .pipe(this.untilDestroy())
      .subscribe(dialogMetadata => {
        const restoreFilesParam = this.recoveryTaskService.getFileRecoveryTask(
          this.data,

          // Get new jobInstanceId according to selected snapshot
          this.seriesData.timestampDataPoints?.[dialogMetadata?.snapshotTimestampUsec]?.[0],
          [row.filePath],
          dialogMetadata
        );
        this.createFileRecoveryTask(restoreFilesParam);
      });
  }

  /**
   * Creates file recovery task
   *
   * @param   restoreFilesParam   Object    Params to create file recovery
   */
  createFileRecoveryTask(restoreFilesParam: RestoreTasksServiceApi.CreateRestoreFilesTaskParams) {
    this.restoreTasksServiceApi
      .CreateRestoreFilesTask(restoreFilesParam)
      .pipe(takeUntil(this._destroy))
      .subscribe((response: RestoreTask) => {
        this.snackBarService
          .openWithAction(
            this.translateService.instant('anomalyFileRecovery.recoverTask'),
            this.translateService.instant('viewTask'),
            'success'
          )
          .subscribe(() => this.goToRecoveryDetail(response.id));
      }, this.ajaxHandlerService.handler);
  }

  /**
   * Goes to the recovery detail page
   *
   * @param id restore task's id
   */
  goToRecoveryDetail(id: number) {
    const { clusterId, properties: { clusterIncarnationId }} = this.data;
    const url = this.getRecoverDetailLinkFn(id, clusterId, clusterIncarnationId) as string;
    this.irisNavigationService.go(url, this.getRecoverDetailLinkParamsFn(id, clusterId) || {});
  }

  /**
   * updates patternFilterReady value
   *
   * @param value boolean value indicating status of pattern filter
   */
  updatePatternFilterReady(value: boolean) {
    this.patternFilterReady = value;
  }
}
