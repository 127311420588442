import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectedObject } from '@cohesity/api/v2';
import { DataTreeNode, DataTreeSelection, NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DialogService, PassthroughOptionsService, StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { CloudJobType, Environment, RecoveryAction } from 'src/app/shared';
import { AwsDBLeafTypes, AwsDBTypes, AwsIngestProtectionTypes, AwsLeafType, AwsLeafTypes, AwsTagTypes, CloudLeafToJobType } from 'src/app/shared/constants/cloud.constants';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';
import { AwsSourceDataNode } from 'src/app/shared/source-tree/protection-source/aws/aws-source-data-node';

import { AWSObjectActionCreator } from './aws-object-action-creator';
import { isPostgresDbInstance } from './aws-object-action-utils';
import { ObjectActionOptions } from './object-action-options.model';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';
/**
 * This is a simple implementation for construction object menu actions for AWS. It has some simple
 * logic around auto protection, and can be configured to restrict items to only be protected
 * by a single job.
 */
@Injectable()
export class AwsObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static awsObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    IrisContextService,
    AWSObjectActionCreator,
    DialogService,
    PassthroughOptionsService,
    UIRouterGlobals,
  ];

  exclusiveProtection = true;

  supportsBulkActions = true;

  constructor(
    objectStore: ObjectInfoService,
    restoreConfig: RestoreConfigService,
    stateManagementService: StateManagementService,
    stateService: StateService,
    irisContextService: IrisContextService,
    private awsActionCreator: AWSObjectActionCreator,
    private dialogService: DialogService,
    private passthroughOptionsService: PassthroughOptionsService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, awsActionCreator, irisContextService);
  }

  /**
   * Utility function to update Aws object info.
   *
   * @param object   The protected object.
   * @param actionFn Action function to be applied on the object.
   * @returns List of available actions.
   */
  getAwsObjectActions(object: SimpleObjectInfo, actionFn: any): any {
    return this.objectStore.getObjectInfo(object.id, this.passthroughOptionsService.requestParams).pipe(
      switchMap((info) => {
        if (info.item?.objectBackupConfiguration) {
          // For Aws tags, Magneto doesn't send protection info in entity hierarchy.
          if ((AwsTagTypes.includes(object.objectType))) {
            object.isProtected = true;
          }

          if (!object.objectActionKey) {
            // Add Object action incase it is missing for ingest protection.
            if (info.item?.objectBackupConfiguration?.awsParams.protectionType === 'kNative' ||
              info.item?.objectBackupConfiguration?.policyConfig?.policies?.find(
              policy => policy.protectionType === 'kNative')) {
              object.objectActionKey = 'kAWSNative';
            }
          }

          // For cases where the latest snapshot run is associated with a protection configuration
          // with policies having both kNative and kSnapShotManager protectionType,
          // kNative is set as the protection type such an instance
          object.protectionType = object.objectActionKey === 'kAWSNative' ? CloudJobType.kNative :
            info.item?.objectBackupConfiguration.awsParams?.protectionType;
        }

        // If context is on-prem where protectionGroup applies,
        // the protection type is available on the snapshot detail and set
        if(!object.protectionType && object.useRestorePointSelection) {
          object.protectionType = object.restorePointSelection?.snapshot?.awsParams?.protectionType;
        }
        return actionFn(object);
      })
    );
  }

  /**
   * Creates and returns authorize db action. Used for PostgresSQL DB.
   * Can be extended to other DBs later.
   *
   * @param objects The list of protected objects.
   * @param selection The selection made, used to get the parent/children map.
   * @returns An observable, which yields the NavItem or null.
   */
  getDbAuthorizeAction(objects: SimpleObjectInfo[], selection?: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>):
    Observable<NavItem | null> {
    let objectList = objects.map(obj => obj.v1Object);

    // Return null if feature flag is disabled. Only applicable for DMaaS and
    // for RDS instance tabs
    if (!this.objectStore.getProtectedObject || !isDmsScope(this.irisContextService.irisContext) ||
      !flagEnabled(this.irisContextService.irisContext, 'awsPostgresDbAuthorization') ||
      this.uiRouterGlobals.params.workload !== 'kRDSInstance') {
      return of(null);
    }

    // If v2 is enabled, then we can add credentials to the parent of the selection.
    if (flagEnabled(this.irisContextService.irisContext, 'awsPostgresDbAuthorizationV2')) {
      // If a selection is made, then get the parents of the selection.
      if (selection) {
        const allChildren = new Set();
        selection.selected.forEach((node: AwsSourceDataNode) => node.childIds.forEach(child => allChildren.add(child)));

        // Added all the children of the selection and checked if it exists in
        // the tree, then remove that selection as it's a child. Whatever's left
        // are the parents.
        objectList = selection.selected
          .filter(node => !allChildren.has(node.id))
          .map(node => node.data);
      }

      // Check for incompatible selection. We can select any non leaf node, but
      // the leaf node should be of RDS Postgres instance type, if selected.
      // Disabled for tags.
      const selectionCompatible = objectList.every(({ protectionSource: source }) => {
        if (AwsLeafTypes.includes(source.awsProtectionSource.type as AwsLeafType)) {
          const isInstance = [AwsLeafType.kRDS, AwsLeafType.kAurora]
            .includes(source.awsProtectionSource.type as AwsLeafType);
          return isInstance && isPostgresDbInstance(source);
        }

        return !AwsTagTypes.includes(source.awsProtectionSource.type);
      });

      // Don't show the Credentials button if incompatible selection.
      if (!selectionCompatible) {
        return of(null);
      }
    } else {
      // All the selection made should be Postgres DB instance.
      const allPostgresDb = objectList.every(object => isPostgresDbInstance(object.protectionSource));
      if (!allPostgresDb) {
        return of(null);
      }
    }

    // If no protection sources, don't show the action.
    if (!objectList?.length) {
      return of(null);
    }

    // Check if the objects have credentials provided or not.
    let isLocked = true;
    const protectionSources = objectList.map(object => {
      // TODO: update YAML to remove 'any'
      isLocked = isLocked && !(object as any).credentialList?.length;
      return object.protectionSource;
    });

    return of(this.awsActionCreator.createDbAuthorizeAction(() => {
      this.dialogService.showDialog('aws-authorize-db-dialog', protectionSources);
    }, isLocked));
  }

  /**
   * Get list of actions available for an object.
   *
   * @param object  The protected object.
   * @returns List of available actions.
   */
  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    return this.getAwsObjectActions(object, updatedObject =>
      combineLatest([
        super.getObjectActions(updatedObject),
        this.getRecoveryAction(object, RecoveryAction.RecoverRDS),
        this.getRecoveryAction(object, RecoveryAction.RecoverRDSPostgres),
        this.getRecoveryAction(object, RecoveryAction.RecoverAurora),
        this.getRecoveryAction(object, RecoveryAction.RecoverAwsDynamoDB),
        this.getRecoveryAction(object, RecoveryAction.RecoverS3),
        this.getDbAuthorizeAction([object])
      ]).pipe(
        map(([baseActions, ...awsActions]: [NavItem[], ...NavItem[]]) => [...baseActions, ...awsActions]),
        map(actions => actions.filter(action => !!action && this.filterRecoverFilesAction(action))),
        map(actions => {
          const sortOrder = ['protect', 'recover', 'recoverFiles', 'runNow',
            'pauseFutureRuns', 'editProtection', 'unprotect'];
          return actions.sort((it1, it2) => {
            let o1 = sortOrder.indexOf(it1.displayName);
            let o2 = sortOrder.indexOf(it2.displayName);
            if (o1 === -1) {
              o1 = sortOrder.length;
            }
            if (o2 === -1) {
              o2 = sortOrder.length;
            }
            return o1 - o2;
          });
        }),
      ));
  }

  /**
   * Returns true if RecoverFiles action is to be enabled.
   */
  filterRecoverFilesAction(action: NavItem) {
    if (action.displayName !== 'recoverFiles') {
      return true;
    } else if (flagEnabled(this.irisContextService.irisContext, 'dmsAwsEnableFileFolderRecovery')) {
      return action.stateParams?.objectActionKey ?
        action.stateParams?.objectActionKey === Environment.kAWSNative : true;
    }

    return false;
  }

  /**
   * Gets a list of nav item options available for one or more objects.
   *
   * @param   objects   The list of objects to get actions for
   * @returns Any applicable actions that can be run for all items
   */
  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    return combineLatest(
      objects.map(object => this.getAwsObjectActions(object, updatedObject => of(updatedObject))),
    ).pipe(
      switchMap((updatedObjects: SimpleObjectInfo[]) => combineLatest([
        super.getBulkObjectActions(updatedObjects),

        // Additional recovery actions for AWS usecases. This is explicitly
        // used on Global Search where Source Tree logic
        // (getBulkTreeObjectActions) is not applicable.
        this.getBulkRecoveryAction(updatedObjects, RecoveryAction.RecoverRDS),
        this.getBulkRecoveryAction(updatedObjects, RecoveryAction.RecoverRDSPostgres),
        this.getBulkRecoveryAction(updatedObjects, RecoveryAction.RecoverAurora),
        this.getBulkRecoveryAction(updatedObjects, RecoveryAction.RecoverAwsDynamoDB),
        this.getBulkRecoveryAction(updatedObjects, RecoveryAction.RecoverS3),
      ]).pipe(
        map(([actions, ...bulkRecoveryActions]) => ([...actions, ...bulkRecoveryActions])),
        map(actions => actions.filter(action => this.filterActionByAccess(action))),
        map(actions => this.sortActions(actions))
      ))
    );
  }

  /**
   * Gets a list of nav item options available for one or more objects. This assumes that
   * the selection is done within the context of a source tree, and will have additional
   * information about autoprotected, selected, or excluded items.
   *
   * @param   objects          The object to get actions for
   * @param   sourceSelection  The transformed api tree selection including excluded and special
   *                           params
   * @param   objectOptions    The object's action options
   * @returns Any applicable actions that can be run for all items
   */
  getBulkTreeObjectActions(
    selection: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>,
    sourceSelection: SourceSelection,
    objectOptions: ObjectActionOptions = {}
  ): Observable<NavItem[]> {
    if (!selection || (!selection.selected.length && !selection.autoSelected.length)) {
      return of([]);
    }

    /**
     * Spectial case for handling Postgres DB Instances and Db children.
     * We remove all the children dbs of the db instance, if all the child dbs
     * are selected along with the db instance. If all the selected ids are
     * removed, that would imply that only DB instances are selected.
     */
    const leafNodes = selection.selected.filter((node: AwsSourceDataNode) =>
      AwsLeafTypes.includes(node.type as AwsLeafType));
      const selectedIds = new Set(leafNodes.map(n => n.id));

      selection.selected.forEach((node: AwsSourceDataNode) => {
      if (node.isPostgresDbInstance) {
        // If all children are selected, then remove the children and select the
        // parent instance.
        if (node.childIds.length === 0 || node.childIds.every(n => selectedIds.has(n))) {
          node.childIds.forEach(n => selectedIds.delete(n));
          selectedIds.delete(node.id);
        }
      }
    });

    const allPostgresDbInstance = selectedIds.size === 0;

    // Filter out auto-protectable nodes from the initial selection, otherwise the source tree will interpret
    // them as being auto-protected
    const selected = selection.selected.filter((node: AwsSourceDataNode) => {
      // If the node is a Postgres DB instance, return true if it has all
      // it's children selected.
      if (node.isPostgresDbInstance) {
        return allPostgresDbInstance;
      }

      // If the node is a Postgres DB and all the selected nodes are not
      // db instances, then show this selection.
      if (node.isPostgresDb) {
        return !allPostgresDbInstance;
      }

      // Filter logic based on workloadType context and Auto-protection support for S3
      // Extracted canAutoSelect function expression/logic to "canAutoSelects3" value below due to multiple references
      // Of the original method
      // TODO: Revert back the use of "!node.canAutoSelect" for filter when S3 auto-protection support is enabled
      // ENG Link: ENG-319159
      const canAutoSelects3 = (node.expandable || node.isTag) && !node.isUnSupportedJobType;
      const canAutoSelectObject = node.workloadType === 'kS3Bucket' ? canAutoSelects3 : node.canAutoSelect();
      return !canAutoSelectObject;
    });

    const objects = [...selected, ...selection.autoSelected];

    const workloadType = objectOptions.workloadType;
    return combineLatest(objects.map((object: ProtectionSourceDataNode) => {
      const simpleObject = {
        id: object.protectionSource.id,
        environment: object.environment,
        // If a root node is selected, it will not have a parent source id, so we can just use its own id instead.
        sourceId: object.protectionSource.parentId || object.protectionSource.id,
        isProtected: object.protected,
        objectType: object.type,
        accessClusterId: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        v1Object: object,
        workloadType,
      } as SimpleObjectInfo;
      return this.getAwsObjectActions(simpleObject, updatedObject => of(updatedObject));
    })).pipe(
      switchMap((objectInfos: SimpleObjectInfo[]) => {
        let showCommonActions = true;
        // For DMS scope, we can't show the common actions like Protect/Recover
        // for different types of DBs.
        if (isDmsScope(this.irisContextService.irisContext)) {
          const awsLeafTypes = [...new Set(
            objects.map(object => object?.data?.protectionSource?.awsProtectionSource?.type)
          )];
          if (awsLeafTypes.length > 1) {
            showCommonActions = false;
          }
        }

        const commonActions = [
          this.getBulkAwsProtectAction(objectInfos, sourceSelection, selection),
          this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverVMs),
          this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverApps),
          this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverRDS),
          this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverRDSPostgres),
          this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverAwsDynamoDB),
          this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverAurora),
          this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverS3),
          this.getProtectedObjectAction(objectInfos, ObjectProtectAction.ProtectNow),
          this.getProtectedObjectAction(objectInfos, ObjectProtectAction.UnProtect),
          this.getProtectedObjectAction(objectInfos, ObjectProtectAction.Resume),
        ];

        return combineLatest([
          ...(showCommonActions ? commonActions : []),
          this.getDbAuthorizeAction(objectInfos, selection),
        ]).pipe(
          map(actions => actions.filter(action => this.filterActionByAccess(action))),
          map(actions => this.sortActions(actions))
        );
      }),
    );
  }

  /**
   * Checks a protected object and recovery action against the available restore configuration
   * to determine if the provided action is applicable.
   *
   * @param object The protected object
   * @param simpleObject The simple object
   * @param actionType A recovery action type
   * @returns True if the action can be applied to this object.
   */
  canRecover(
    actionType: RecoveryAction,
    object?: ProtectedObject,
    simpleObject?: SimpleObjectInfo
  ): boolean {
    const canRecover = super.canRecover(actionType, object, simpleObject);

    // For EC2 FLR, check if there is an archival snapshot.
    if(actionType === RecoveryAction.RecoverFiles || actionType === RecoveryAction.DownloadFilesAndFolders) {
      // LatestSnapshotsInfo is a array of length atmost two, one for archival and one for snapshotManager.
      // Check if either of them contain archivalSnapshotInfo.
      if (object?.latestSnapshotsInfo[0].archivalSnapshotsInfo) {
        return canRecover;
      }
      if (object?.latestSnapshotsInfo.length > 1) {
        return !!object?.latestSnapshotsInfo[1].archivalSnapshotsInfo && canRecover;
      }
    }
    // On Activty page, protectedObject isn't set after the first backup
    // (without refresh), so we need to use the simple object to check if this
    // is rds leaf. If the protection type is 'kAwsRDSPostgresBackup' that is
    // applicable only for RDS Postgres DBs.
    const awsPostgresLeafTypes = [
      AwsLeafType.kRDS,
      AwsLeafType.kAurora,
      AwsLeafType.kRDSPostgresDb,
      AwsLeafType.kAuroraClusterPostgresDb
    ];
    const isRdsLeaf = awsPostgresLeafTypes.includes(object?.objectType as AwsLeafType)
      || ['kRDSSnapshotManager', 'kAuroraSnapshotManager'].includes(simpleObject?.objectActionKey)
      || AwsIngestProtectionTypes.includes(object?.protectionType);

    const isRdsRecovery = [
      RecoveryAction.RecoverRDS,
      RecoveryAction.RecoverRDSPostgres,
      RecoveryAction.RecoverAurora,
    ].includes(actionType);

    return canRecover && (
      (!isRdsRecovery && !isRdsLeaf) ||
      (isRdsRecovery && isRdsLeaf)
    );
  }

  /**
   * Creates and returns the edit protection action for protected AWS object.
   *
   * @param object The object to edit protection settings for.
   * @returns An observable, which yields the NavItem or Null.
   */
  getEditObjectProtectionAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo || !object.isProtected) {
      return of(null);
    }

    // If no object's have region ids, we are dealing with a cluster rather than on prem, and the
    // protected object actions do not apply.
    if (!object.regionId && !object.isObjectProtected) {
      return of(null);
    }

    return  this.objectStore.getObjectInfo(
      object.id,
      { accessClusterId: object.accessClusterId, regionId: object.regionId }
    ).pipe(
      filter(entry => !entry.loading),
      map(entry => entry.item),
      map(item => {
        if (!item || !item.objectBackupConfiguration || item?.objectBackupConfiguration?.isAutoProtectConfig) {
          return null;
        }

        const dmsScope = isDmsScope(this.irisContextService.irisContext);
        let environment: Environment;

        switch (true) {
          case object.objectType === AwsLeafType.kS3: {
            environment = Environment.kAwsS3;
            break;
          }
          case object.objectType === AwsLeafType.kDynamoDB: {
            environment = Environment.kAwsDynamoDB;
            break;
          }
          case dmsScope && AwsDBLeafTypes.includes(object.objectType as AwsLeafType):
          case dmsScope && AwsDBTypes.includes(object.objectActionKey as Environment): {
            environment = CloudLeafToJobType[object?.objectType] ?? object.objectActionKey;
            break;
          }
          default: environment = item.objectBackupConfiguration.environment as Environment;
        }

        const navItem = this.actionCreator.createEditObjectProtectionAction(
          environment,
          item.id,
          { accessClusterId: object.accessClusterId, regionId: object.regionId }
        );

        // 'cloudJobType' is required to work with the Postgres Dbs. Adding a
        // guard to make sure it doesn't cause regressions.
        if (this.uiRouterGlobals.params.workload === 'kRDSInstance' &&
          isDmsScope(this.irisContextService.irisContext) &&
          flagEnabled(this.irisContextService.irisContext, 'awsPostgresDbAuthorization')) {
          navItem.stateParams.cloudJobType = (this.actionCreator as AWSObjectActionCreator).getCloudStateParams(object);
        }

        return navItem;
      })
    );
  }

  /**
   * Wrapper for 'getProtectedObjectAction' to check if the all the objects
   * are protected or not. We hide the protected actions for mixed selection.
   *
   * @param objects The list of objects
   * @param type The type of object protection action
   * @returns returns null, if all the objects are protected or not.
   */
  getProtectedObjectAction(objects: SimpleObjectInfo[], type: ObjectProtectAction): Observable<NavItem> {
    const protectedObjects = objects?.filter(object => object.isProtected);

    if (protectedObjects.length && protectedObjects.length !== objects.length) {
      return of(null);
    }

    return super.getProtectedObjectAction(objects, type);
  }

  /**
   * Wrapper for 'getBulkProtectAction' to check if the all the objects
   * are normally or autoprotected. Not supporting Protection for mixed
   * selection.
   *
   * @param objects The objects to protect.
   * @param sourceSelection  The source selection info.
   * @param selection Protected or Autoprotected selection.
   *
   * @returns An observable, which yields the NavItem or null.
   */
  getBulkAwsProtectAction(
    objects: SimpleObjectInfo[],
    sourceSelection: SourceSelection,
    selection: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>
  ): Observable<NavItem> {
    const { selected, autoSelected } = selection ?? {};
    // If the objects are selected normally and for autoprotection, then hide
    // the option to protect the objects.
    if (selected?.length && autoSelected?.length) {
      return of(null);
    }

    return super.getBulkProtectAction(objects, sourceSelection);
  }

  /**
   * Wrapper for 'getBulkRecoveryAction' to check if the all the objects
   * are protected or not. We hide the recovery actions for mixed selection.
   *
   * @param objects The list of objects
   * @param type The type of recvovery action
   * @returns Returns null, if all the objects are recoverable or not.
   */
  getBulkRecoveryAction(objects: SimpleObjectInfo[], type: RecoveryAction): Observable<NavItem> {
    const protectedObjects = objects?.filter(object => object.isProtected);

    if (protectedObjects.length && protectedObjects.length !== objects.length) {
      return of(null);
    }

    return super.getBulkRecoveryAction(objects, type);
  }
}
