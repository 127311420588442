import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'mat-list-option[cogScrollIntoView]',
})
export class ScrollIntoViewDirective implements OnChanges {
  @Input() cogScrollIntoView: boolean;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    if (coerceBooleanProperty(this.cogScrollIntoView)) {
      setTimeout(() => {
        this.elementRef.nativeElement.scrollIntoView();
      });
    }
  }
}
