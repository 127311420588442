<span *ngIf="label" class="cog-chart">
  {{ label }}
</span>
<span *ngIf="!label && durationMs" class="duration-label cog-chart">
  {{ durationMs | cogDuration }}
</span>
<mat-progress-bar color="primary"
  mode="determinate"
  [value]="progress">
</mat-progress-bar>
