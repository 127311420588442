import { InjectionToken } from '@angular/core';

import { AppModuleConfig } from './app-module.config';
import { appConfigs } from './app-modules';
import { GlobalConfig } from './app-services/global/global.config';
import { RpaasConfig } from './app-services/rpaas/rpaas.config';
import { AppStateDeclaration } from './core';
import {
  HeliosAccessManagementConfig,
} from './modules/access-management/helios-access-management/helios-access-management.config';
import { AbacConfig } from './modules/access-management/on-prem-access-management/abac/abac.config';
import {
  ActiveDirectoryConfig as ClusterActiveDirectoryConfig,
} from './modules/access-management/on-prem-access-management/active-directory/active-directory.config';
import { KeystonesConfig } from './modules/access-management/on-prem-access-management/keystones/keystones.config';
import { LdapConfig } from './modules/access-management/on-prem-access-management/ldap/ldap.config';
import { NisConfig } from './modules/access-management/on-prem-access-management/nis/nis.config';
import {
  OnPremAccessManagementConfig,
} from './modules/access-management/on-prem-access-management/on-prem-access-management.config';
import { RolesConfig } from './modules/access-management/on-prem-access-management/roles/roles.config';
import { UsersGroupsConfig } from './modules/access-management/on-prem-access-management/users-groups/users-groups.config';
import { AccountSecurityConfig } from './modules/account-security/account-security.config';
import { ClusterCreateConfig } from './modules/admin/cluster/cluster-create/cluster-create.config';
import {
  FlashRecoverOnboardingConfig,
} from './modules/admin/cluster/remote-disks/onboarding/flashrecover-onboarding.config';
import { RemoteDisksConfig } from './modules/admin/cluster/remote-disks/remote-disks.config';
import { SyslogSettingsConfig } from './modules/admin/cluster/syslog/syslog.config';
import { FirewallConfig } from './modules/admin/networking/firewall/firewall.config';
import { IpmiConfig } from './modules/admin/networking/ipmi/ipmi.config';
import { NetworkingConfig } from './modules/admin/networking/networking.config';
import { StaticRoutesConfig } from './modules/admin/networking/static-routes/static-routes.config';
import { VipsVlansConfig } from './modules/admin/networking/vips-vlans/vips-vlans.config';
import { AgentConfig } from './modules/agent/agent.config';
import { AntivirusConfig } from './modules/antivirus/antivirus.config';
import { ApolloSupportabilityConfig } from './modules/apollo-supportability/apollo-supportability.config';
import { ApplianceManagerConfig } from './modules/appliance-manager/appliance-manager.config';
import { AppDashboardConfig } from './modules/apps-dashboard/apps-dashboard.config';
import { AppsConfig } from './modules/apps/apps.config';
import { AuditLogsConfig } from './modules/audit-logs/audit-logs.config';
import { BusinessServicesConfig } from './modules/business-services/business-services.config';
import { CdpConfig } from './modules/cdp/cdp.config';
import { CertificateManagementConfig } from './modules/certificate-management/certificate-management.config';
import { CloneConfig } from './modules/clone/clone.config';
import { ClusterConnectConfig } from './modules/cluster-connect/cluster-connect.config';
import { ClusterConfig } from './modules/cluster/cluster.config';
import { CustomizationConfig } from './modules/customization/customization.config';
import { DashboardsConfig } from './modules/dashboards/dashboards.config';
import { DataPoolsConfig } from './modules/data-pools/data-pools.config';
import { DataSitesConfig } from './modules/data-sites/data-sites.config';
import { DataTieringConfig } from './modules/data-tiering/data-tiering.config';
import { DisasterRecoveryConfig } from './modules/disaster-recovery/disaster-recovery.config';
import { DmsOnboardingConfig } from './modules/dms-onboarding/dms-onboarding.config';
import { DmsConfig } from './modules/dms/dms.config';
import { DownloadsConfig } from './modules/downloads/downloads.config';
import { ErrorConfig } from './modules/error/error.config';
import { ExamplesConfig } from './modules/examples/examples.config';
import { FeatureFlagsConfig } from './modules/feature-flags/feature-flags.config';
import { GflagRecipesConfig } from './modules/gflag-recipes/gflag-recipes.config';
import { GlobalSearchConfig } from './modules/global-search/global-search.config';
import { HealthConfig } from './modules/health/health.config';
import { HeliosAuditLogsConfig } from './modules/helios-audit-logs/helios-audit-logs.config';
import { HeliosBackupConfig } from './modules/helios-backup/helios-backup.config';
import { HeliosMfPromptConfig } from './modules/helios-mfa-prompt/helios-mfa-prompt.config';
import { HeliosOnpremLoginConfig } from './modules/helios-onprem-login/login.config';
import { HeliosSecurityConfig } from './modules/helios-security/helios-security.config';
import { HeliosSmartfilesDashboardConfig } from './modules/helios-smartfiles-dashboard/helios-smartfiles-dashboard.config';
import { HelpConfig } from './modules/help/help.config';
import { ImportExportConfig } from './modules/import-export/import-export.config';
import { ExternalTargetsConfig } from './modules/infrastructure/external-targets/external-targets.config';
import { InfrastructureConfig } from './modules/infrastructure/infrastructure.config';
import { InventoryConfig } from './modules/inventory/inventory.config';
import { LicensingConfig } from './modules/licensing/licensing.config';
import { LoginConfig } from './modules/login/login.config';
import { ObjectActivityConfig } from './modules/object-activity/object-activity.config';
import { ObjectDetailsConfig } from './modules/object-details/object-details.config';
import { OrganizationsConfig } from './modules/organizations/organizations.config';
import { PerformanceConfig } from './modules/performance/performance.config';
import { ProtectionPolicyConfig } from './modules/protection-policy/protection-policy.config';
import { ActiveDirectoryConfig } from './modules/protection/active-directory/active-directory.config';
import { AwsDbConfig } from './modules/protection/aws-db/aws-db.config';
import { AwsRdsConfig } from './modules/protection/aws-rds/aws-rds.config';
import { AwsS3Config } from './modules/protection/aws-s3/aws-s3.config';
import { AzureAdConfig } from './modules/protection/azure-ad/azure-ad.config';
import { AzureSqlConfig } from './modules/protection/azure-sql/azure-sql.config';
import { CouchbaseConfig } from './modules/protection/couchbase/couchbase.config';
import { DataMigrationConfig } from './modules/protection/data-migration/data-migration.config';
import { ExchangeConfig } from './modules/protection/exchange/exchange.config';
import { KubernetesConfig } from './modules/protection/kubernetes/kubernetes.config';
import { NasConfig } from './modules/protection/nas/nas.config';
import { NosqlConfig } from './modules/protection/nosql/nosql.config';
import { Office365Config } from './modules/protection/office365/office365.config';
import { OracleConfig } from './modules/protection/oracle/oracle.config';
import { PhysicalConfig } from './modules/protection/physical/physical.config';
import { MongodbPhysicalConfig } from './modules/protection/mongodb-physical/mongodb-physical.config';
import { ProtectionBuilderConfig } from './modules/protection/protection-builder/protection-builder.config';
import { ProtectionGroupConfig } from './modules/protection/protection-group/protection-group.config';
import { PureConfig } from './modules/protection/pure/pure.config';
import { RemoteAdapterConfig } from './modules/protection/remote-adapter/remote-adapter.config';
import { SanConfig } from './modules/protection/san/sanConfig';
import { SfdcConfig } from './modules/protection/sfdc/sfdc.config';
import { SqlConfig } from './modules/protection/sql/sql.config';
import { UdaConfig } from './modules/protection/uda/uda.config';
import { SapHanaConfig } from './modules/protection/saphana/saphana.config';
import { ViewConfig as ViewProtectionConfig } from './modules/protection/view/view.config';
import { VmConfig } from './modules/protection/vm/vm.config';
import { RemoteClustersConfig } from './modules/remote-clusters/remote-clusters.config';
import { ReportingConfig } from './modules/reporting/reporting.config';
import { RestoreConfig } from './modules/restore/restore.config';
import { RunbooksConfig } from './modules/runbooks/runbooks.config';
import { SecurityConfig } from './modules/security/security.config';
import { SelfServicePortalConfig } from './modules/self-service-portal/self-service-portal.config';
import { SimulationConfig } from './modules/simulator/simulation.config';
import { SoftwareUpgradesConfig } from './modules/software-upgrades/software-upgrades.config';
import { SqlSourceConfig } from './modules/sources/ms-sql/ms-sql.config';
import { SfdcSourceConfig } from './modules/sources/sfdc/sfdc.config';
import { SourcesConfig } from './modules/sources/sources.config';
import { StorageConfig } from './modules/storage/storage.config';
import { ViewsConfig } from './modules/views/views.config';
import { VaultClusterConfig } from './modules/vault-cluster/vault-cluster.config';


export const APP_MODULES = new InjectionToken<AppModuleConfig[]>('APP_MODULES');
export const appModulesProvider = {
  provide: APP_MODULES,
  useValue: [
    ...appConfigs,
    AbacConfig,
    ActiveDirectoryConfig,
    AgentConfig,
    ApolloSupportabilityConfig,
    AppDashboardConfig,
    ApplianceManagerConfig,
    AppsConfig,
    AwsRdsConfig,
    AwsS3Config,
    AuditLogsConfig,
    AntivirusConfig,
    AwsDbConfig,
    AzureAdConfig,
    AzureSqlConfig,
    BusinessServicesConfig,
    CdpConfig,
    CloneConfig,
    ClusterActiveDirectoryConfig,
    ClusterConfig,
    ClusterConnectConfig,
    ClusterCreateConfig,
    CouchbaseConfig,
    CustomizationConfig,
    DashboardsConfig,
    DataMigrationConfig,
    DataPoolsConfig,
    DataSitesConfig,
    DataTieringConfig,
    DisasterRecoveryConfig,
    DmsConfig,
    DmsOnboardingConfig,
    DownloadsConfig,
    ErrorConfig,
    ExamplesConfig,
    ExchangeConfig,
    ExternalTargetsConfig,
    FeatureFlagsConfig,
    FirewallConfig,
    FlashRecoverOnboardingConfig,
    GflagRecipesConfig,
    GlobalConfig,
    GlobalSearchConfig,
    HealthConfig,
    HeliosAccessManagementConfig,
    HeliosAuditLogsConfig,
    HeliosBackupConfig,
    HeliosMfPromptConfig,
    HeliosSecurityConfig,
    HeliosSmartfilesDashboardConfig,
    HelpConfig,
    ImportExportConfig,
    InfrastructureConfig,
    InventoryConfig,
    IpmiConfig,
    KeystonesConfig,
    KubernetesConfig,
    LdapConfig,
    LicensingConfig,
    LoginConfig,
    HeliosOnpremLoginConfig,
    NasConfig,
    NetworkingConfig,
    NisConfig,
    NosqlConfig,
    ObjectActivityConfig,
    ObjectDetailsConfig,
    Office365Config,
    OnPremAccessManagementConfig,
    OrganizationsConfig,
    OracleConfig,
    PerformanceConfig,
    PhysicalConfig,
    ProtectionBuilderConfig,
    ProtectionGroupConfig,
    ProtectionPolicyConfig,
    PureConfig,
    RemoteAdapterConfig,
    RemoteClustersConfig,
    RemoteDisksConfig,
    ReportingConfig,
    RestoreConfig,
    RolesConfig,
    RpaasConfig,
    RunbooksConfig,
    SanConfig,
    AccountSecurityConfig,
    CertificateManagementConfig,
    SecurityConfig,
    SelfServicePortalConfig,
    SfdcConfig,
    SfdcSourceConfig,
    SimulationConfig,
    SoftwareUpgradesConfig,
    SourcesConfig,
    SqlConfig,
    SqlSourceConfig,
    StaticRoutesConfig,
    StorageConfig,
    SyslogSettingsConfig,
    UdaConfig,
    SapHanaConfig,
    UsersGroupsConfig,
    VaultClusterConfig,
    ViewProtectionConfig,
    ViewsConfig,
    VipsVlansConfig,
    VmConfig,
    MongodbPhysicalConfig
  ],
};
/**
 * This defines the lazy loaded states for the entire application. It would be trivial to
 * rewrite this as a function based on the least of features above, but it would probably
 * break AOT, which needs to be able to statically analyze application routes at compile
 * time.
 */
export const APP_STATES: AppStateDeclaration[] = [
  ...(appConfigs.reduce((out, { futureStates }) => [...out, ...futureStates], [])),
  ...AbacConfig.futureStates,
  ...ActiveDirectoryConfig.futureStates,
  ...AgentConfig.futureStates,
  ...ApolloSupportabilityConfig.futureStates,
  ...AppDashboardConfig.futureStates,
  ...ApplianceManagerConfig.futureStates,
  ...AppsConfig.futureStates,
  ...AwsRdsConfig.futureStates,
  ...AwsS3Config.futureStates,
  ...AuditLogsConfig.futureStates,
  ...AntivirusConfig.futureStates,
  ...AwsDbConfig.futureStates,
  ...AzureAdConfig.futureStates,
  ...AzureSqlConfig.futureStates,
  ...BusinessServicesConfig.futureStates,
  ...CdpConfig.futureStates,
  ...CloneConfig.futureStates,
  ...ClusterActiveDirectoryConfig.futureStates,
  ...ClusterConfig.futureStates,
  ...ClusterConnectConfig.futureStates,
  ...ClusterCreateConfig.futureStates,
  ...CouchbaseConfig.futureStates,
  ...CustomizationConfig.futureStates,
  ...DashboardsConfig.futureStates,
  ...DataMigrationConfig.futureStates,
  ...DataPoolsConfig.futureStates,
  ...DataSitesConfig.futureStates,
  ...DataTieringConfig.futureStates,
  ...DisasterRecoveryConfig.futureStates,
  ...DmsConfig.futureStates,
  ...DmsOnboardingConfig.futureStates,
  ...DownloadsConfig.futureStates,
  ...ErrorConfig.futureStates,
  ...ExamplesConfig.futureStates,
  ...ExchangeConfig.futureStates,
  ...ExternalTargetsConfig.futureStates,
  ...FeatureFlagsConfig.futureStates,
  ...FirewallConfig.futureStates,
  ...FlashRecoverOnboardingConfig.futureStates,
  ...GflagRecipesConfig.futureStates,
  ...GlobalConfig.futureStates,
  ...GlobalSearchConfig.futureStates,
  ...HealthConfig.futureStates,
  ...HeliosAccessManagementConfig.futureStates,
  ...HeliosAuditLogsConfig.futureStates,
  ...HeliosBackupConfig.futureStates,
  ...HeliosSmartfilesDashboardConfig.futureStates,
  ...HeliosMfPromptConfig.futureStates,
  ...HeliosSecurityConfig.futureStates,
  ...HelpConfig.futureStates,
  ...ImportExportConfig.futureStates,
  ...InfrastructureConfig.futureStates,
  ...InventoryConfig.futureStates,
  ...IpmiConfig.futureStates,
  ...KeystonesConfig.futureStates,
  ...KubernetesConfig.futureStates,
  ...LdapConfig.futureStates,
  ...LicensingConfig.futureStates,
  ...LoginConfig.futureStates,
  ...HeliosOnpremLoginConfig.futureStates,
  ...MongodbPhysicalConfig.futureStates,
  ...NasConfig.futureStates,
  ...NetworkingConfig.futureStates,
  ...NisConfig.futureStates,
  ...NosqlConfig.futureStates,
  ...ObjectActivityConfig.futureStates,
  ...ObjectDetailsConfig.futureStates,
  ...Office365Config.futureStates,
  ...OnPremAccessManagementConfig.futureStates,
  ...OrganizationsConfig.futureStates,
  ...OracleConfig.futureStates,
  ...PerformanceConfig.futureStates,
  ...PhysicalConfig.futureStates,
  ...ProtectionBuilderConfig.futureStates,
  ...ProtectionGroupConfig.futureStates,
  ...ProtectionPolicyConfig.futureStates,
  ...PureConfig.futureStates,
  ...RemoteAdapterConfig.futureStates,
  ...RemoteClustersConfig.futureStates,
  ...RemoteDisksConfig.futureStates,
  ...ReportingConfig.futureStates,
  ...RestoreConfig.futureStates,
  ...RolesConfig.futureStates,
  ...RpaasConfig.futureStates,
  ...RunbooksConfig.futureStates,
  ...SanConfig.futureStates,
  ...AccountSecurityConfig.futureStates,
  ...CertificateManagementConfig.futureStates,
  ...SapHanaConfig.futureStates,
  ...SecurityConfig.futureStates,
  ...SelfServicePortalConfig.futureStates,
  ...SfdcConfig.futureStates,
  ...SfdcSourceConfig.futureStates,
  ...SimulationConfig.futureStates,
  ...SoftwareUpgradesConfig.futureStates,
  ...SourcesConfig.futureStates,
  ...SqlConfig.futureStates,
  ...SqlSourceConfig.futureStates,
  ...StaticRoutesConfig.futureStates,
  ...StorageConfig.futureStates,
  ...SyslogSettingsConfig.futureStates,
  ...UdaConfig.futureStates,
  ...UsersGroupsConfig.futureStates,
  ...VaultClusterConfig.futureStates,
  ...ViewProtectionConfig.futureStates,
  ...ViewsConfig.futureStates,
  ...VipsVlansConfig.futureStates,
  ...VmConfig.futureStates,
];
