import { find } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: IdP List

;(function(angular) {
  'use strict';

  angular
    .module('C.idp')
    .controller('idpListCtrl', idpListCtrlFn);

  /**
   * Controller for the List view
   */
  function idpListCtrlFn(_, $q, evalAJAX, IdpService, TenantService, cMessage,
    UserService, $rootScope) {
    var $ctrl = this;

    // Component Declaration
    assign($ctrl, {
      // Component Methods
      $onInit: $onInit,

      // Component Variables
      loading: true,
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      _getIdentityProviders();
    }

    /**
     * Retrieves the list of configured Identity Providers
     *
     * @method   _getIdentityProviders
     */
    function _getIdentityProviders() {
      var roleName;
      var currentRole;
      var promises = {
        tenants: $rootScope.user.privs.ORGANIZATION_VIEW ?
          TenantService.getTenants() : $q.resolve([]),
        roles: UserService.getAllRoles(),
        listIdp: IdpService.getAllExternalIdp(),
      };

      $ctrl.loading = true;
      $q.all(promises).then(function resolvePromises(resps) {
        // Map the tenantId to tenant name and role id to role name
        resps.listIdp.forEach(function formatIdp(idp) {
          idp._tenant = find(resps.tenants, {tenantId: idp.tenantId});
          if (idp.roles && idp.roles.length) {
            roleName = [];
            idp.roles.forEach(function readRoles(role) {
              currentRole = find(resps.roles, {name: role});
              roleName.push(get(currentRole, 'label' , ''));
            });
            idp._userRole = roleName.join(', ');
          }
        });

        $ctrl.idps = resps.listIdp.map(_addContextMenu);
      }, evalAJAX.errorMessage).finally(function finalizeExternalIdp() {
        $ctrl.loading = false;
      });
    }

    /**
     * Add context menu actions to given Identity Provider by reference
     *
     * @method   _generateContextMenu
     * @param    {object}    idp    An identity provider configuration
     * @return   {object}    An updated identity provider configuration
     */
    function _addContextMenu(idp) {
      // Show the context menu items only if the IDP is owned by the current
      // user.
      idp._contextItems = idp._isIdpOwner ? [
        {
          icon: 'icn-edit',
          translateKey: 'edit',
          disabled: !$rootScope.user.privs.PRINCIPAL_MODIFY,
          state: 'edit-sso',
          stateParams: {
            id: idp.id
          },
        },
        {
          icon: 'icn-delete',
          translateKey: 'delete',
          disabled: !$rootScope.user.privs.PRINCIPAL_MODIFY,
          action: function deleteIdp() {
            IdpService.deleteExternalIdp(idp).then(_getIdentityProviders);
          }
        },
        {
          icon: idp.enable ? 'icn-deactivate' : 'icn-activate',
          translateKey: idp.enable ? 'deactivate' : 'activate',
          disabled: !$rootScope.user.privs.PRINCIPAL_MODIFY,
          action: function deactivateIdp() {
            var idpCopy = angular.copy(idp);
            idpCopy.enable = !idpCopy.enable;
            IdpService.updateEnableStatusForIdp(idpCopy).then(
              function updatedIdp() {
                _getIdentityProviders();
                cMessage.success({
                  textKey: idpCopy.enable ? 'sso.activated' : 'sso.deactivated',
                  textKeyContext: {
                    sso: idp.name,
                    tenant: idp._tenantName,
                  },
                  timeout: 3000,
                });
              });
          }
        },
      ] : [];

      return idp;
    }
  }
})(angular);
