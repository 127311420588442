import { chain } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: Edit Run Modal

;(function(angular, undefined) {
  'use strict';

  var moduleDeps = [ 'C.retention', 'C.selectReplicationTarget' ];

  angular
    .module('C.editRun', moduleDeps)
    .controller('JobEditRunController', jobEditRunControllerFn);

  /**
   * Controller Fn
   *
   * @method   jobEditRunControllerFn
   */
  function jobEditRunControllerFn($rootScope, $uibModalInstance, job, run, $q,
    $timeout, TIME, JobRunsService, JobService, evalAJAX, cMessage, UserService,
    DateTimeService, JobRunsServiceFormatter, DEFAULT_DAYS_TO_KEEP, _,
    FEATURE_FLAGS, ENUM_JOB_RUN_TYPE, ENV_TYPE_CONVERSION,
    NgProtectionGroupService, NgClusterService) {

    var $ctrl = this;
    var JOB_RUN_TYPE_ENUM =
      chain(ENUM_JOB_RUN_TYPE).invert().mapValues(Number).value();

    assign($ctrl, {
      job: job,
      run: run,
      retentionOptions: [
        {
          displayKey: 'extend',
          multiplier: 1,
        },
        {
          displayKey: 'reduce',
          multiplier: -1,
        },
      ],
      currentUsecs: DateTimeService.getCurrentUsecs(),
      replicationTargets: [],
      archivalTargets: [],
      newReplicationTargets: [],
      newArchivalTargets: [],
      cancelTargets: [],
      jobUid: {},
      retentionExpiryTimeError: false,

      isCloudArchiveDirect: false,

      isRpaasJob: false,

      // Methods
      $onInit: onInit,
      runNow: runNow,
      cancel: cancel,
      cloudEditionEnabled: cloudEditionEnabled,
      insertNewConfig: insertNewConfig,
      isArchivalAllowed: isArchivalAllowed,
      isEditBackupRunAllowed: isEditBackupRunAllowed,
      isUpdateWithDataLockAllowed: isUpdateWithDataLockAllowed,
      isLegalHoldDisabled: isLegalHoldDisabled,
      isTargetLegalHoldDisabled: isTargetLegalHoldDisabled,
      isReplicationAllowed: isReplicationAllowed,
      legalHoldToggled: legalHoldToggled,
      targetLegalHoldToggled: targetLegalHoldToggled,
      showWarningMessage: showWarningMessage,
      hasLegalHold: hasLegalHold,
      removeConfig: removeConfig,
      updateTargetExpiryDate: updateTargetExpiryDate,
      userService: UserService,
      onRetentionParamToggle: onRetentionParamToggle,
      isSubmitDisabled:isSubmitDisabled
    });

    /**
     * Activate function populates $ctrl values to be used in template. calls
     * getter functions to populate replication and archival targets arrays.
     *
     * @method   onInit
     */
    function onInit() {
      var jobRunsParams = {
        id: job.jobId,
        exactMatchStartTimeUsecs: run.base.startTimeUsecs,
        excludeTasks: true,
      };

      $ctrl.isSqlJob = !!get(job, 'envBackupParams.sqlBackupJobParams');
      $ctrl.isOracleJob = job.type === ENV_TYPE_CONVERSION.kOracle;
      $ctrl.isUdaJob = job.type === ENV_TYPE_CONVERSION.kUDA;

      JobRunsService.getJobRuns(jobRunsParams).then(
        function getJobRunsSuccess(jobs) {
          var protectionRun = JobRunsServiceFormatter.transformProtectionRun(
            jobs[0].backupJobRuns.protectionRuns[0]
          );
          $ctrl.run = protectionRun.backupRun;

          // Set Legal hold if applicable
          // If legalHoldPerTarget is enabled, this is only applicable for
          // local backup legal hold only instead of the whole run.
          $ctrl.enableLegalHold = $ctrl.run._legalHoldEnabled;

          // Add Copy Task info
          $ctrl.run._copyTasks = JobRunsService.getCopyTasks(
            protectionRun,
            jobs[0].backupJobRuns.jobDescription.jobUid,
            jobs[0].backupJobRuns.jobDescription.isActive
          );

          // Select the targets for this run.
          setReplicationTargets($ctrl.run._copyTasks);
          setArchivalTargets($ctrl.run._copyTasks);

          // Set default retention Option
          $ctrl.run.base._retentionMultiplier =
            $ctrl.retentionOptions[0].multiplier;

          // Update the new expiry date according to default retention option
          updateRetentionParameters($ctrl.run.base);
          updateDataLockValidation($ctrl.run);

          // Set default retention options for all the targets.
          $ctrl.archivalTargets.forEach(setDefaultMultiplier);
          $ctrl.replicationTargets.forEach(setDefaultMultiplier);

          //Setup targets that are already selected and now should be disabled
          // for further selection
          $ctrl.disabledArchivalTargetIds = getDisabledTargetIds('archive');
          $ctrl.disabledReplicationTargetIds =
            getDisabledTargetIds('replicate');
        });

        const {
          clusterId,
          clusterIncarnationId,
          objectId
        } = job.jobUid;

        NgProtectionGroupService.getGroup(
          `${clusterId}:${clusterIncarnationId}:${objectId}`,
        ).toPromise().then(group =>
          NgProtectionGroupService.getPolicy(group.policyId).toPromise().then(
            policy => {
              const {
                backupPolicy: {
                  regular: {
                    primaryBackupTarget: {
                      targetType
                    } = {}
                  } = {}
                } = {},
                remoteTargetPolicy: {
                  rpaasTargets = [],
                } = {}
              } = {} = policy;

              $ctrl.isCloudArchiveDirect = targetType === 'Archival';
              $ctrl.isCascadedReplication = !!(
                policy.cascadedTargetsConfig && policy.cascadedTargetsConfig.length);
              $ctrl.isRpaasJob = rpaasTargets.length > 0;
            })
          );
    }

    /**
     * Set default multipliers for retentionOptions on targets
     *
     * @method   setMultiplier
     * @param    {Object}   target   Replication/Archival Target
     */
    function setDefaultMultiplier(target) {
      target._retentionMultiplier = $ctrl.retentionOptions[0].multiplier;
      if (target._expiryTimeUsecs > DateTimeService.getCurrentUsecs()) {
        updateRetentionParameters(target);
        updateDataLockValidation(target);
      } else {
        setRetentionParameters(target);
      }
    }

    /**
     * Decorates all (non-deleted) replication or archival targets with the
     * legal hold attribute if the legal hold is toggled on/off during editing
     * the run
     *
     * @method   _generateLegalHoldTargets
     * @param    {array}   targets   The replication or archival target list
     * @param    {Boolean} includeResync Whether to include re-synced targets
     * @return   {array}   Decorated array of targets with legal hold
     */
    function _generateLegalHoldTargets(targets, includeResync) {
      return targets
        .filter(target => (target._expiryTimeUsecs > $ctrl.currentUsecs ||
          $ctrl.disableLegalHold || target._isNewTarget) ||
          !!(target.resync && includeResync) ||
          !!target.legalHoldToggled)
        .map(function setTargetForLegalHold(target) {
          if (FEATURE_FLAGS.legalHoldPerTarget) {
            if (target._isNewTarget) {
              target.target.holdForLegalPurpose = !!$ctrl.enableLegalHold;
            } else {
              target.target.holdForLegalPurpose = !!target.legalHoldEnabled;
            }
          } else {
            target.target.holdForLegalPurpose = !!$ctrl.enableLegalHold;
          }
          return target;
        });
    }

    /**
     * Generates an array of target ids which have been modified while editing
     * the run
     *
     * @method   _getModifiedTargetIds
     * @param    {Array}   modifiedTargets   The modifies targets list
     * @return   {Array}   The modifies target ids list.
     */
    function _getModifiedTargetIds(modifiedTargets) {
      return modifiedTargets.map(function getModifiedIds(target) {
        return target.target.id || target.target.clusterId;
      });
    }

    /**
     * Tracks the replication or archival targets which
     *
     * @method   _getUnmodifiedTargets
     * @param    {Array}   targets      The replication or archvial target list
     * @param    {Array}   modifiedTargetIds   The modified target ids
     * @return   {Array}   The unmodified targets list
     */
    function _getUnmodifiedTargets(targets, modifiedTargetIds) {
      return targets.filter(function findUnmodifiedTargets(target) {
          return !modifiedTargetIds
            .includes(target.target.id || target.target.clusterId);
        });
    }

    /**
     * Returns the replication or archival targets decorated with the legal hold
     * attributes
     *
     * @method   _getLegalHoldTargets
     * @param    {string}   type   The type: 'replicate', or 'archive'.
     * @param    {Boolean}  includeResync  Whether to include re-synced targets.
     * @return   {Array}    The legal hold decorated targets.
     */
    function _getLegalHoldTargets(type, includeResync) {
      var targets = type === 'replicate' ? $ctrl.replicationTargets :
        $ctrl.archivalTargets;
      var newTargets = type === 'replicate' ? $ctrl.newReplicationTargets :
        $ctrl.newArchivalTargets;

      newTargets.forEach(function decorateNewTarget(target) {
        target._isNewTarget = true;
      });

      var modifiedTargetIds;
      var modifiedTargets;
      var unmodifiedTargets;

      /**
       * If targets are modified along with placing the run on legal hold,
       * we need to treat the modified and the unmodified targets separately as
       * modified targets will have retention period values along with legal
       * hold. Unmodified targets will only have legal hold.
       */

      // Getting modified targets. Modified targets include the resynced targets
      // and the new targets added while editing the run.
      modifiedTargets = resyncTargets(targets).concat(newTargets);
      modifiedTargetIds = _getModifiedTargetIds(modifiedTargets);

      if (modifiedTargetIds[0]) {
        // The targets which are not newly added or resynced would be unmodified
        // targets
        unmodifiedTargets = _getUnmodifiedTargets(targets, modifiedTargetIds);

        // Apply legal hold to both modified and unmodified targets
        return _generateLegalHoldTargets(
          modifiedTargets.concat(unmodifiedTargets),
          includeResync,
        );
      }

      // In case we have no modifications in the edit run, just apply legal hold
      // to all the targets present in the run.
      return _generateLegalHoldTargets(targets, includeResync);

    }

    /**
     * Submit function that gets called on saving the form. Checks for the
     * canceled runs and saves all the data before the API request.
     *
     * @method   runNow
     */
    function runNow() {
      var promises = [];
      var selectedModalData;
      var archivalTargets;
      var replicationTargets;


      if ($ctrl.editRunModalForm.$invalid) { return; }
      $ctrl.submitting = true;

      // Get target list which are cancelled
      $ctrl.cancelTargets = getCancelTargets($ctrl.replicationTargets)
        .concat(getCancelTargets($ctrl.archivalTargets));

      // If the legal hold is turned on/off and the user has data security
      // privileges, we need to decorate the targets with legal hold attributes
      if ($rootScope.user.privs.DATA_SECURITY &&
        ($ctrl.enableLegalHold || $ctrl.disableLegalHold) &&
        !FEATURE_FLAGS.legalHoldPerTarget) {
        archivalTargets = _getLegalHoldTargets('archive');
        replicationTargets = _getLegalHoldTargets('replicate');

        if ($ctrl.disableLegalHold) {
          _removeDefaultRetention(archivalTargets);
          _removeDefaultRetention(replicationTargets);
        }
      } else {
        $ctrl.deletedArchivalTargets =
          setTargetsForDeletion($ctrl.archivalTargets);
        $ctrl.deletedReplicatedTargets =
          setTargetsForDeletion($ctrl.replicationTargets);

        archivalTargets =
          resyncTargets($ctrl.archivalTargets)
            .concat($ctrl.newArchivalTargets, $ctrl.deletedArchivalTargets);
        replicationTargets =
          resyncTargets($ctrl.replicationTargets)
            .concat($ctrl.newReplicationTargets,
              $ctrl.deletedReplicatedTargets);
      }

      // If legalHold per target is enabled, decorated archival and replication
      // targets with legalHold status.
      if ($rootScope.user.privs.DATA_SECURITY &&
        FEATURE_FLAGS.legalHoldPerTarget) {
          archivalTargets = _getLegalHoldTargets('archive', true);
          replicationTargets = _getLegalHoldTargets('replicate', true);

          // Removing the default retention value. This is needed since every
          // target is preset with the default retention value if it's not
          // available.
          _removeDefaultRetention(archivalTargets);
          _removeDefaultRetention(replicationTargets);
      }

      selectedModalData = {
        archivalTargets: archivalTargets,
        replicationTargets: replicationTargets,
        jobUid: $ctrl.run.base.jobUid,
        runStartTimeUsecs: $ctrl.run.base.startTimeUsecs,
      };

      if ($ctrl.deleteLocalSnapshot) {
        // If local backup is being deleted
        selectedModalData.localBackup = {
          daysToKeep: 0,
          type: 'kLocal',
        };
      } else if ($ctrl.editLocalBackup) {
        // If the local backup is being edited
        selectedModalData.localBackup = {
          daysToKeep: $ctrl.run.base.daysToKeep,
          type: 'kLocal',
        };
      }

      const isLocalLegalHoldToggled = FEATURE_FLAGS.legalHoldPerTarget ?
        !!$ctrl.localLegalHoldToggled :
        ($ctrl.enableLegalHold || $ctrl.disableLegalHold);

      const isSnapshotDeletedManually = $ctrl.run.base._expiryTimeUsecs === 0;
      const isSnapshotExpired = $ctrl.run.base._expiryTimeUsecs !== 0 &&
        $ctrl.run.snapshotsDeleted;

      // TODO(maulik): Refactor the logic to handle if legal hold is touched by
      // using form elements rather than conditions
      // Merge Legal hold attributes only if legal hold toggle is touched and
      // the user has data security privileges and snapshot is not deleted and
      // local snapshot is not expired.
      if ($rootScope.user.privs.DATA_SECURITY &&
        isLocalLegalHoldToggled &&
        !isSnapshotDeletedManually && !isSnapshotExpired
      ) {
        selectedModalData.localBackup = selectedModalData.localBackup || {};
        // If legal hold is either turned on or off
        selectedModalData.localBackup.type = 'kLocal';
        selectedModalData.localBackup.holdForLegalPurpose =
          !!$ctrl.enableLegalHold;
      }

      // Building promises array for the cancel task requests. Cancel tasks
      // need to be processed first and only after all cancellation requests
      // are done, we can go ahead and edit the run.
      if ($ctrl.cancelTargets[0]) {
        $ctrl.cancelTargets.forEach(function pushPromise(target) {
          var jobRunParams = {
            jobId: $ctrl.run.base.jobId,
            copyTaskUid: {
              id: target.copyTaskUid.objectId,
              clusterId: target.copyTaskUid.clusterId,
              clusterIncarnationId: target.copyTaskUid.clusterIncarnationId,
            },
          };

          promises.push(
            JobRunsService.cancelJobRun($ctrl.run.base.jobId, jobRunParams)
          );
        });
      } else {
        promises.push($q.resolve());
      }

      if (promises[0]) {
        $q.all(promises).then(
          function getAllSuccess(r) {
            $timeout(
              // Give magneto a brief delay to update this job
              // before refreshing the page again
              function refreshPageDelay() {
                if (isFormEdited(selectedModalData)) {
                  editRun(selectedModalData);
                } else {
                  $uibModalInstance.close();
                }
              },
              300
            );
          },
          evalAJAX.errorMessage
        );
      }
    }

    /**
     * Gets the form data and sends the data to JobService for edit Run request
     *
     * @method   editRun
     * @param    {object}   selectedModalData   Form data to send for request
     */
    function editRun(selectedModalData) {
      if ($ctrl.isCloudArchiveDirect) {
        delete selectedModalData.localBackup;
      }

      JobService.editJobRun(selectedModalData).then(
        function editJobRunSuccess(response) {
          $uibModalInstance.close(response);
        }, evalAJAX.errorMessage)
        .finally(function editJobRunFinished() {
          $ctrl.submitting = false;
        });
    }

    /**
     * Checks if the form is edited.
     *
     * @method   isFormEdited
     * @param    {object}   selectedModalData   Form data to send for request
     */
    function isFormEdited(selectedModalData) {
      return selectedModalData.localBackup ||
        selectedModalData.replicationTargets[0] ||
        selectedModalData.archivalTargets[0] ||
        $ctrl.enableLegalHold ||
        $ctrl.disableLegalHold;
    }

    /**
     * Sets a list of all replication targets of the job run. Filters the
     * copytasks of the run and creates a replication targets array.
     *
     * @method   setReplicationTargets
     * @param    {Array}   copyTasks   List of all copy tasks of the job run.
     */
    function setReplicationTargets(copyTasks) {
      copyTasks.forEach(function loopOverCopyTasks(task) {
        var target = {target: {}};
        if (task.snapshotTarget && task.snapshotTarget.type === 2) {
          target.copyTaskUid = task.taskUid;
          target.target.name =
            task.snapshotTarget.replicationTarget.clusterName;
          target.target.clusterId =
            task.snapshotTarget.replicationTarget.clusterId;
          target.publicStatus = task.publicStatus;
          target._expiryTimeUsecs = task.expiryTimeUsecs;
          if (target._expiryTimeUsecs !== 0 && target._expiryTimeUsecs > $ctrl.currentUsecs) {
            target._dataLockExpiryUsecs = task._dataLockExpiryUsecs || task.expiryTimeUsecs;
            target._dataLockExpired = target._dataLockExpiryUsecs <= $ctrl.currentUsecs;
            target._dataLocked = !!task.dataLockConstraints && !target._dataLockExpired;
          }

          // Set legalHold at target level
          if (FEATURE_FLAGS.legalHoldPerTarget) {
            target.legalHoldEnabled = task.legalHoldEnabled;
          }

          $ctrl.replicationTargets.push(target);
        }
      });
    }

    /**
     * Sets a list of all archival targets of the job run. Filters the copytasks
     * of the run and creates a archival targets array.
     *
     * @method   setArchivalTargets
     * @param    {Array}   copyTasks   List of all copy tasks of the job run.
     */
    function setArchivalTargets(copyTasks) {
      // filter out rpaas targets (ownershipContext = 1)
      copyTasks = copyTasks.filter(target => target.snapshotTarget &&
        target.snapshotTarget.archivalTarget &&
        target.snapshotTarget.archivalTarget.ownershipContext !== 1)

      copyTasks.forEach(function loopOverCopyTasks(task) {
        var target = {target: {}};
        if (task.snapshotTarget && task.snapshotTarget.type === 3) {
          target.copyTaskUid = task.taskUid;
          target.target.id = task.snapshotTarget.archivalTarget.vaultId;
          target.target.name = task.snapshotTarget.archivalTarget.name;
          target.target.type = task.snapshotTarget.archivalTarget.type;
          target.publicStatus = task.publicStatus;
          target._expiryTimeUsecs = task.expiryTimeUsecs;
          if (target._expiryTimeUsecs !== 0 && target._expiryTimeUsecs > $ctrl.currentUsecs) {
            target._dataLockExpiryUsecs = task._dataLockExpiryUsecs || task.expiryTimeUsecs;
            target._dataLockExpired = target._dataLockExpiryUsecs <= $ctrl.currentUsecs;
            target._dataLocked = !!task.dataLockConstraints && !target._dataLockExpired;
          }

          // Set legalHold at target level
          if (FEATURE_FLAGS.legalHoldPerTarget) {
            target.legalHoldEnabled = task.legalHoldEnabled;
          }

          $ctrl.archivalTargets.push(target);
        }
      });

    }

    /**
     * Gets a list of all targets marked for cancellation.
     *
     * @method   getCancelTargets
     * @param    {Array}   targets   List of all archival or replication targets
     * @return   {Array}   Filtered list of targets marked for cancellation.
     */
    function getCancelTargets(targets) {
      return targets.filter(function filterCancelTargets(target) {
        return target.cancel === true;
      });
    }

    /**
     * Sets the targets for deletion.
     *
     * @method   setTargetsForDeletion
     * @param    {Array}   targets   The targets
     * @return   {Array}   List of all targets that are set for deletion.
     */
    function setTargetsForDeletion(targets) {
      return targets.reduce(
        function setTargetForDeletion(accumulator, target) {
          if (target.deleteTargetSnapshot) {
            target.daysToKeep = 0;
            target.resync = false;
            accumulator.push(target);
          }
          return accumulator;
        }, []);
    }

    /**
     * Gets a list of all targets marked to be re-synced.
     *
     * @method   resyncTargets
     * @param    {Array}   targets   List of all archival or replication targets
     * @return   {Array}   Filtered list of targets marked to be re-synced.
     */
    function resyncTargets(targets) {
      targets.forEach(function removeRetention(target) {
        // In case of future expiry, the below condition would look for
        // true/false, and in case of expired state, it would look for
        // 'set' value.
        if (!target.updateRetention && !target.deleteTargetSnapshot) {
          target.daysToKeep = undefined;
        }
      });

      return targets.filter(function filterResyncTargets(target) {
        return target.resync === true;
      });
    }

    /**
     * Closes modal no values passed
     *
     * @method   cancel
     */
    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    /**
     * Gets a default target configuration.
     *
     * @method   generateDefaultTargetConfig
     * @return   {object}   The default configuration.
     */
    function generateDefaultTargetConfig() {
      return { daysToKeep: DEFAULT_DAYS_TO_KEEP };
    }

    /**
     * Adds a Repication or Archival Config to the list.
     *
     * @method   insertNewConfig
     * @param    {string}   [type='replicate']   The type: 'replicate', or
     *                                           'archive'.
     */
    function insertNewConfig(type) {
      // Determine which targets list to add to.
      var list = type === 'archive' ?
        $ctrl.newArchivalTargets :
        $ctrl.newReplicationTargets;

      list.push(generateDefaultTargetConfig());
    }

    /**
     * Removes a Replication or Archival Config from the list.
     *
     * @method   removeConfig
     * @param    {string}   [type='replicate']   The type: 'replicate', or
     *                                           'archive'.
     * @param    {object}   removableTarget      The target config to remove.
     */
    function removeConfig(type, removedTarget) {
      // Determine which targets list to remove from.
      var list = (type === 'archive') ?
        $ctrl.newArchivalTargets :
        $ctrl.newReplicationTargets;

      // Loop over the selected list until the matching config object is found
      // and remove it.
      list.some(function removeGivenTarget(target, index) {
        if (angular.equals(removedTarget, target)) {
          list.splice(index, 1);
        }
      });

      // When target is removed it needs to be enabled again for selection
      var disabledList = (type === 'archive') ?
        $ctrl.disabledArchivalTargetIds :
        $ctrl.disabledReplicationTargetIds;

      JobRunsService
        .removeTargetFromDisabledList(disabledList, type, removedTarget);
    }

    /**
     * Updates or sets the new expiry date according to the updated retention
     * values
     *
     * @method   updateTargetExpiryDate
     * @param    {object}   selectedTarget           Target which is being
     *                                               updated
     * @param    {string}   [type='replicate']       The type: 'replicate', or
     *                                               'archive'.
     * @param    {string}   [updateParam='update']   Optional param to check
     *                                               whether to update or set
     *                                               the retention value.
     *                                               Available options are 'set'
     *                                               and 'update'
     */
    function updateTargetExpiryDate(selectedTarget, type, updateParam) {
      if (type) {
        var list = (type === 'archive') ?
          $ctrl.archivalTargets :
          $ctrl.replicationTargets;

        var index = list.findIndex(function findTarget(target) {
          return angular.equals(selectedTarget, target);
        });

        list[index] = (updateParam === 'set') ?
          setRetentionParameters(list[index]) :
          updateRetentionParameters(list[index]);
        updateDataLockValidation(list[index]);
      } else {
        updateRetentionParameters($ctrl.run.base);
        updateDataLockValidation($ctrl.run);
      }
    }

    /**
     * Sets the retention parameters for the target object. Used in case when
     * the target is already expired and needs a new retention value.
     *
     * @method   setRetentionParameters
     * @param    {object}   object   Target which is being set
     * @return   {object}   Updated target
     */
    function setRetentionParameters(object) {
      object.setDaysToKeep = object.setDaysToKeep || DEFAULT_DAYS_TO_KEEP;
      object._newExpiryTimeUsecs =  DateTimeService.getCurrentUsecs() +
        object.setDaysToKeep * TIME.usecsPerDay;
      object.daysToKeep = object.setDaysToKeep;
      object.updateRetention = 'set';
      return object;
    }

    /**
     * Updates the retention parameters for the target object. Used in case when
     * the target has expiry date and is being updated.
     *
     * @method   updateRetentionParameters
     * @param    {object}   object   Target which is being updated
     * @return   {object}   Updated target
     */
    function updateRetentionParameters(object) {
      object.changeDaysToKeep = object.changeDaysToKeep || DEFAULT_DAYS_TO_KEEP;
      object._newExpiryTimeUsecs = object._expiryTimeUsecs +
      object._retentionMultiplier * object.changeDaysToKeep *
        TIME.usecsPerDay;
      object.daysToKeep = object._retentionMultiplier * object.changeDaysToKeep;
      $ctrl.onRetentionParamToggle(object);
      return object;
    }

    /**
     * Gets the list of disabled target identifiers. This is used when we have
     * a list of replicate/archive targets and we disabled the targets from the
     * dropdown list if they are already added.
     *
     * @method   getDisabledTargetIds
     * @param    {string}   [type='replicate']       The type: 'replicate', or
     *                                               'archive'.
     * @return   {array}   List of disabled target identifiers
     */
    function getDisabledTargetIds(type) {
      var list = (type === 'archive') ?
        $ctrl.archivalTargets :
        $ctrl.replicationTargets;

      return list.reduce(function getIds(targetIds, target) {
        if (target && target.target && target._expiryTimeUsecs !== 0) {
          targetIds.push(target.target.id || target.target.clusterId);
        }
        return targetIds;
      }, []);
    }

    /**
     * Displays warning when legal hold is turned on. Sets enable/disable
     * attributes when legal hold is turned on/off.
     *
     * @method   legalHoldToggled
     */
    function legalHoldToggled() {
      if ($ctrl.enableLegalHold) {
        $ctrl.disableLegalHold = undefined;
        $ctrl.showWarningMessage(true);
      } else {
        $ctrl.disableLegalHold = true;
        $ctrl.enableLegalHold = undefined;
      }

      if (FEATURE_FLAGS.legalHoldPerTarget) {
        $ctrl.localLegalHoldToggled = !$ctrl.localLegalHoldToggled;
      }
    }

    /**
     * Displays warning when legal hold is turned on.
     * Update target's attribute legalHoldToggled.
     *
     * @method   targetLegalHoldToggled
     * @param   {Object}  target   The replication/archive target
     * @param   {string}  type     The target type (archive/replicate).
     */
    function targetLegalHoldToggled(target, type) {
      target.legalHoldToggled = !target.legalHoldToggled;

      // Do not show warning message for archive target legalHold.
      if (type !== 'archive') {
        $ctrl.showWarningMessage(target.legalHoldEnabled);
      }
    }

    /**
     * Displays warning when legal hold is toggled on.
     *
     * @method   showWarningMessage
     * @param    {Boolean}  legalHoldEnabled   Whether legalHold is enabled.
     */
    function showWarningMessage(legalHoldEnabled) {
      if (legalHoldEnabled) {
        cMessage.warn({ textKey: 'editRunModal.legalHoldWarning' });
      }
    }

    /**
     * Checks whether a copy has Legal Hold.
     *
     * @method   hasLegalHold
     * @param    {Object}   target   Replication/Archival Target
     * @return   {Boolean}  True if the target has Legal Hold.
     */
    function hasLegalHold(target) {
      if (FEATURE_FLAGS.legalHoldPerTarget) {
        return !!(target && target.legalHoldEnabled);
      }

      return $ctrl.enableLegalHold;
    }

    /**
     * Determines if the edited run has any replication or archival targets in
     * running state
     *
     * @method   _hasRunningTargets
     * @param    {Array}   targets   The targets
     * @return   {Array}   True if edited run has running targets, False
     *                     otherwise.
     */
    function _hasRunningTargets(targets) {
      return targets.some(function filterCancelTargets(target) {
        return ["kRunning", "kAccepted"].includes(target.publicStatus);
      });
    }

    /**
     * Determines if the edited run has any replication or archival targets for
     * which reduced retention is being set
     *
     * @method   _hasReducedRetentionTargets
     * @param    {Array}     targets   The replication or archival targets list
     * @return   {boolean}   True if edited run has reduced retention targets,
     *                       False otherwise.
     */
    function _hasReducedRetentionTargets(targets) {
      return targets.some(function getReducedRetentionTargets(target) {
        return target._retentionMultiplier === -1;
      });
    }

    /**
     * Determines if legal hold toggle is disabled.
     *
     * @method   isLegalHoldDisabled
     * @return   {boolean}   True if legal hold disabled, False otherwise.
     */
    function isLegalHoldDisabled() {
      var targets = $ctrl.replicationTargets.concat($ctrl.archivalTargets);

      // If the user does not have data security privileges OR
      return !$rootScope.user.privs.DATA_SECURITY ||

        // Local backup has a reduced retention OR
        $ctrl.run.base._retentionMultiplier === -1 ||

        // Local backup is being deleted OR
        $ctrl.deleteLocalSnapshot ||

        // Any replication or archival target has reduced retention OR
        _hasReducedRetentionTargets(targets) ||

        // Any replication or archival target is in running state
        _hasRunningTargets(targets) ||

        // If the run type is storage array snapshot
        $ctrl.run.base.backupType === 6;
    }

    /**
     * Determines if legal hold toggle at the target (replication/archival)
     * should be disabled.
     *
     * @method   isTargetLegalHoldDisabled
     * @param    {Object}    target  The replication or archival target.
     * @return   {boolean}   True if legal hold disabled, False otherwise.
     */
    function isTargetLegalHoldDisabled(target) {
      var isSnapshotExpired = target._expiryTimeUsecs < $ctrl.currentUsecs;

      // Disable the toggle if the main conditions are not met (see above)
      // or the target snapshot is already expired with legalHold status is off.
      return $ctrl.isLegalHoldDisabled() ||
        (isSnapshotExpired && !target.legalHoldEnabled &&
          !target.legalHoldToggled);
    }

     /**
     * Determines if legal hold toggle is disabled.
     *
     * @method   isSubmitDisabled
     * @return   {boolean}   True if submit disabled, False otherwise.
     */
    function isSubmitDisabled() {
      return $ctrl.editRunModalForm.$invalid;
    }

    /**
     * Function to determine if replication is enabled for a job or not
     *
     * @method  isReplicationAllowed
     * @return  {Boolean}   True if replication is enabled. False otherwise
     */
    function isReplicationAllowed() {
      return ($ctrl.job.isActive ||
        ($ctrl.isCascadedReplication &&
          FEATURE_FLAGS.cascadedReplicationAndArchivalEnabled)||
        FEATURE_FLAGS.editRunForInactiveJobsEnabled) &&
          $rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW &&
            !$ctrl.job.isDirectArchiveEnabled &&
              ((!$ctrl.cloudEditionEnabled() && !$ctrl.isCloudArchiveDirect) ||
                ($ctrl.cloudEditionEnabled() && $ctrl.isCloudArchiveDirect))  &&
                  $ctrl.run.base.backupType !== 6;
    }

    /**
     * Return true if cluster is NGCE.
     *
     * @method  cloudEditionEnabled
     * @return  {Boolean}   True if cluster is NGCE
     */
    function cloudEditionEnabled() {
      return NgClusterService.isClusterNGCE;
    }

    /**
     * Function to determine if archival is enabled for a job or not
     *
     * @method  isArchivalAllowed
     * @return  {Boolean}   True if archival is enabled. False otherwise
     */
    function isArchivalAllowed() {
      var isArchivalAllowed =
        $ctrl.run.base.backupType !== JOB_RUN_TYPE_ENUM.Log;

      if (($ctrl.isSqlJob && FEATURE_FLAGS.sqlLogArchival) ||
        ($ctrl.isOracleJob && FEATURE_FLAGS.oracleLogArchivalEnabled) ||
        ($ctrl.isUdaJob && FEATURE_FLAGS.udaLogArchivalEnabled)) {
        isArchivalAllowed = true;
      }

      return (($ctrl.job.isActive ||
        FEATURE_FLAGS.editRunForInactiveJobsEnabled) ||
        ($ctrl.isCascadedReplication &&
          FEATURE_FLAGS.cascadedReplicationAndArchivalEnabled) &&
        isArchivalAllowed) &&
          $rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW &&
            $ctrl.run.base.backupType !== 6;
    }

    /**
     * Function to determine if editing of backup run is allowed
     *
     * @method  isEditBackupRunAllowed
     * @return  {Boolean}   True if edit is allowed. False otherwise
     */
    function isEditBackupRunAllowed() {
      return $ctrl.isUpdateWithDataLockAllowed($ctrl.run) &&
        !$ctrl.job.isDirectArchiveEnabled;
    }

    /**
     * Function to determine a target can be updated with dataLocked.
     * This check only applies to dataLock.
     *
     * @method  isTargetResyncAllowed
     * @param   {Object}   target  The current target.
     * @return  {Boolean}  True if update is allowed. False otherwise.
     */
    function isUpdateWithDataLockAllowed(target) {
      // Target is not dataLocked, allow update.
      if (!target._dataLocked) {
        return true;
      }
      return FEATURE_FLAGS.ngPolicyDefaultDataLock ||
        $rootScope.user.privs.DATA_SECURITY;
    }

    /**
     * Determines retention toggle state and set retentionExpiryTimeError
     * accordingly.
     *
     * @method   onRetentionParamToggle
     */
    function onRetentionParamToggle(retentionObj) {
      $ctrl.retentionExpiryTimeError =
      retentionObj._newExpiryTimeUsecs <= $ctrl.currentUsecs &&
        retentionObj.updateRetention && retentionObj.resync;
    }

    /**
     * Check datalock retention and set shortRetentionExpiryTimeError
     * accordingly.
     *
     * @method   updateDataLockValidation
     * @param    retentionObj
     */
    function updateDataLockValidation(retentionObj) {
      if (!retentionObj._dataLocked) {
        return;
      }
      // Determine new expiry time for either local run or copy target
      let newExpiryTimeUsecs = retentionObj.base ? retentionObj.base._newExpiryTimeUsecs :
        retentionObj._newExpiryTimeUsecs;
      retentionObj._dataLockExpiryTimeError = !retentionObj._dataLockExpired &&
          newExpiryTimeUsecs < retentionObj._dataLockExpiryUsecs;
    }

    /**
     * Remove the default daysToKeep value is the existing target retention
     * is not updated and snapshot is not deleted.
     *
     * @method  removeDefaultRetention
     * @param   {Object}  targets  Replication/archival targets.
     */
    function _removeDefaultRetention(targets) {
      targets.forEach(function removeRetention(target) {
        if (!target._isNewTarget && !target.resync && !target.deleteTargetSnapshot) {
          target.daysToKeep = undefined;
        }
      });
    }
  }
})(angular);
