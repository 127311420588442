
/**
 * Enum for Authorisation User Type
 */
export enum AuthorisedUserType {
  Automatic = 'Automatic',
  LocalUser = 'LocalUser',
  SpecificActiveDirectoryIdentity = 'SpecificActiveDirectoryIdentity',
}

export interface AuthorisedUser {
  /**
   * Specifies the type of User Authentication
   */
  type: AuthorisedUserType;

  /**
   * Specifies the local user name
   */
  user?: null | any;

  /**
   * Specifies the name of the principal.
   */
  principal?: null | string;

  /**
   * Specifies the unique Security id (SID) of the principal.
   */
  sid?: null | string;

  /**
   * Specifies the password
   */
  password?: null | string;

  /**
   * Specifies the domainName
   */
  domainName?: null | string;
}
