import { get } from 'lodash-es';
import { assign } from 'lodash-es';
;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.sources')
    .config(sourcesDetailsConfigFn)
    .controller('sourceDetailsControllerNew', sourcesDetailsControllerFn);

  function sourcesDetailsConfigFn($stateProvider, $urlRouterProvider) {
    var viewAccess = 'PROTECTION_VIEW';

    $stateProvider
      .state('source-details', {
        url: '/protection/source-details/{id}/?{environment}&{parentId}&{forceUpgrade}',
        help: 'protection_sources',
        title: 'Source Details',
        canAccess: viewAccess,
        allClustersSupport: {
          singleClusterState: 'source-details',
          allClustersState: 'source-details',
          globalContext: true,
        },
        params: {
          id: { type: 'string' },
          environment: { type: 'string' },
          parentId: { type: 'string' },
          forceUpgrade: { type: 'string' },
        },
        templateUrl: 'app/protection/sources/details2/source-details.html',
        controller: 'sourceDetailsControllerNew as $ctrl',
        parentState: 'sources-new',
        resolve: {
          // TODO(Mohit): Move the SourceInfo inside the controllers where it
          // is being used due to the latency before state change is completed.
          SourceInfo: function getSourceInfo($stateParams, PubSourceService,
            evalAJAX) {
            var params = {
              ids: $stateParams.id,
              allUnderHierarchy: true,
            };
            return PubSourceService.getSourcesWithReconciledOwnerInfo(params,
              $stateParams.environment)
                .then(function getSource(data) {
                  return data.rootNodes[0];
                }, evalAJAX.errorMessage);
          },
        },
      })
      .state('source-details.protected', {
        url: 'protected',
        help: 'protection_sources',
        title: 'Source - Protected VMs',
        canAccess: viewAccess,
        templateUrl: 'app/protection/sources/details2/source-details-protected/source-details-protected.html',
        controller: 'sourceDetailsProtectedController as $ctrl',
        parentState: 'sources-new',
      })
      .state('source-details.all', {
        url: 'all',
        help: 'protection_sources',
        title: 'Source - All VMs',
        canAccess: viewAccess,
        templateUrl: 'app/protection/sources/details2/source-details-all/source-details-all.html',
        controller: 'sourceDetailsAllController as $ctrl',
        parentState: 'sources-new',
      })
      .state('source-details.settings', {
        url: 'settings',
        help: 'protection_sources',
        title: 'Source Details',
        canAccess: viewAccess,
        templateUrl: 'app/protection/sources/details2/source-details-settings/source-details-settings.html',
        controller: 'sourceDetailsSettingsController as $ctrl',
        parentState: 'sources-new',
      });
  }

  function sourcesDetailsControllerFn(_, $scope, $state, SourcesUtil, evalAJAX,
    SourceService, SourceInfo, PubSourceService, ENV_GROUPS, ENUM_ENV_TYPE,
    FEATURE_FLAGS, SOURCE_TYPE_GROUPS, HADOOP_ENVIRONMENTS, ENV_TYPE_CONVERSION) {

    var $ctrl = this;

    if (!SourceInfo) {
      return $state.go('sources-new');
    }

    assign($ctrl, {
      $onInit: onInit,
      ENUM_ENV_TYPE: ENUM_ENV_TYPE,
      isLogicalSizeAvailable: PubSourceService.isLogicalSizeAvailable,
      isProtectedSizeAvailable: PubSourceService.isProtectedSizeAvailable,
      source: SourceInfo,
      sourceName: (SourceInfo.rootNode.customName ?  SourceInfo.rootNode.customName : SourceInfo.rootNode.name),
      environment: $state.params.environment,
      sourceStats: SourceInfo._stats || {},
      pageActions: _generatePageActions(SourceInfo),
      tabConfig: _getTabConfig(),
    });

    /**
     * init function
     */
    function onInit() {
      $ctrl.sourceDetailsLoaded = true;

      // goto default protected objects state when landing to source-details
      // directly.
      if ($state.current.name === 'source-details' &&
        !HADOOP_ENVIRONMENTS.includes($state.params.environment)) {
        $state.go('source-details.protected', $state.params);
      } else {
        $state.go('source-details.settings', $state.params);
      }
    }

    /**
     * Dynamically generate the source details tabs depending on the environment.
     *
     * @return    {Array}    array of tab config objects
     */
    function _getTabConfig() {
      const tabConfigList = [];

      // Hide 'Objects' tab for Hadoop sources.
      if (!HADOOP_ENVIRONMENTS.includes($state.params.environment)) {
        tabConfigList.push({
          headingKey: 'protectedObjects',
          route: 'source-details.protected',
        });

        tabConfigList.push({
          headingKey: 'allObjects',
          route: 'source-details.all',
        });
      }

      tabConfigList.push({
        headingKey: 'settings',
        route: 'source-details.settings',
      });

      return tabConfigList;
    }

    /**
     * this method is used to refresh the glancebar when a user clicks the
     * refresh button in the header. gets info and stats for a source by Id.
     *
     * @param     {Number}    id    id of source
     * @return    {Object}    Promise
     */
    function _getSourceInfo(id) {
      return PubSourceService.getSourcesInfo({ ids: id },
        $ctrl.environment)
          .then(function getSourceInfo(data) {
            // Currently when the protectionSource is queried we get a duplicate
            // of the source, as physical and SQL and they are exactly the same
            // object. It's safer to assume rootNodes[0].
            $ctrl.sourceStats = {
              sourcesCount: data.rootNodes.length,
              stats: data.rootNodes[0]._stats,
            };

            return SourceInfo = data.rootNodes[0];
          }, evalAJAX.errorMessage);
    }

    /**
     * returns pageActions that can be used in the page header
     *
     * @param    {Object}    source    source object containing source details
     * @return   {Array}     array of pageAction config objects
     */
    function _generatePageActions(source) {
      var pageActions = [];
      var environments = [];
      var isSqlVM = $state.params.environment === 'kSQL' &&
        source._type === 'kVirtualMachine';
      var isHadoop = HADOOP_ENVIRONMENTS.includes($state.params.environment);

      if (!source._owner.isSourceOwner) {
        return pageActions;
      }

      if (['kSQL', 'kOracle', 'kAD', 'kExchange'].includes($state.params.environment)) {
        environments.push($state.params.environment);
        source._rootEnvironment = $state.params.environment;
      }

      if ($scope.user.privs.PROTECTION_MODIFY) {
        if ($state.params.environment === 'kPhysical') {
          if (SOURCE_TYPE_GROUPS.physicalBlockBased.includes(source._hostType)) {
            pageActions.push({
              clickHandler: function protectClickFn() {
                environments = ['kPhysical'];

                SourcesUtil.goToProtectionJobFlow(source, environments);
              },
              displayKey: 'protectBlockBased',
              icon: 'icn-protect',
              disabled: source._isBlockProtected,
            });
          }
          if (SOURCE_TYPE_GROUPS.physicalFileBased.includes(source._hostType)) {
            pageActions.push({
              clickHandler: function protectClickFn() {
                environments = ['kPhysicalFiles'];

                SourcesUtil.goToProtectionJobFlow(
                  source, environments, 'physicalFileBased'
                );
              },
              displayKey: 'protectFileBased',
              icon: 'icn-protect',
              disabled: source._isProtected,
            });
          }

        // Protect workflow takes a job which has auto-protect enabled.
        // As auto-protect is not supported for oracle,
        // this icon should not be present for oracle
        // https://jira.cohesity.com/browse/ENG-50952
        // Also disable protection for Nimble as that is not supported
        // https://jira.cohesity.com/browse/ENG-93626
        } else if (!['kNimble','kHpe3Par'].includes($state.params.environment)) {
          pageActions.push({
            clickHandler: function protectClickFn() {
              SourcesUtil.goToProtectionJobFlow(source, environments);
            },
            displayKey: 'protect',
            icon: 'icn-protect',

            // Disable protection if source has registration error.
            // For AD, Exchange, Oracle source, disable if it is already protected
            // and there is no unprotected leaves count.
            disabled: source._isRegError ||
              (['kExchange', 'kAD', 'kOracle'].includes($state.params.environment) &&
              source._isProtected && !get(source, '_stats.unprotectedLeavesCount', 0)),
          });
        }
      }

      if (!$scope.user.privs.PROTECTION_SOURCE_MODIFY) {
        return pageActions;
      }

      pageActions.push(
        {
          clickHandler: function editClickFn() {
            switch (true) {
              // "Edit" for VMs in the SQL hierarchy means edit app
              // registrations. Does not apply to any other entity types at this
              // time.
              case isSqlVM:
                return $state.go('sql-modify', {
                  entityId: source.rootNode.id,
                  host: PubSourceService.getRegisterableHostObject(source, ENV_TYPE_CONVERSION.kSQL),
                  username: source.registrationInfo && source.registrationInfo.username,
                });

              default:
                // All other source types go to standard "edit."
                SourcesUtil.editSource(source);
            }

          },
          displayKey: isHadoop ? 'reconfigure' : 'editRegistration',
          icon: 'icn-edit',
        },

        // Omit this option if this is a SQL VM because the User has to use the
        // Edit action to modify application settings.
        isSqlVM ? null : {
          clickHandler: function unregisterClickFn() {
            SourcesUtil.unregisterSource(source).then(
              function deleteSourceSuccess() {
                $state.go('sources-new');
              });
          },
          displayKey: 'unregister',
          icon: 'icn-delete',
        },
        {
          clickHandler: function refreshClickFn() {
            $scope.$broadcast('source-refresh-started');

            SourcesUtil.refreshSource(source.rootNode.id).then(
              function refreshSuccess() {
                _getSourceInfo(source.rootNode.id).then(
                  function getSource(sourceInfo) {
                    $scope.$broadcast('source-refresh-done', {
                      sourceInfo: sourceInfo,
                    });
                });
            });
          },
          disabled: !source._isRegistered,
          displayKey: 'refresh',
          icon: 'icn-refresh',
        }
      );

      if ($state.params.environment === 'kPhysical') {
        pageActions.push({
          clickHandler: function upgradeAgentClickFn() {
            var agentIds = PubSourceService.getAgentsToUpgrade(source);
            SourceService.upgradeSourceApi(agentIds).then(
              function upgardeSource() {
                $state.reload();
              }
            );
          },
          disabled: (source._isUpgrading || !source._agent._isUpgradable) && !$state.params.forceUpgrade,
          displayKey: 'upgradeAgent',
          icon: 'icn-upgrade',
        });
      }

      return pageActions;
    }
  }
})(angular);
