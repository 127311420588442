import { assign } from 'lodash-es';
// Component: cPath

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.path', [])
    .component('cPath', {
      bindings: {
        // @type   {boolean}   flag to show '/' or '\' in path
        displayBackslashes: '<?',

        // @type    {expression}    optional placeholder
        placeholder: '<?',

        // @type    {String}    optional type to determine path type
        type: '@?',

        // @type    {regex}     optional regex to use in place of default
        //                      regex(FORMATS.windowsFilePath)
        customRegex: '<?'
      },
      require: { ngModel: 'ngModel' },
      templateUrl: '/app/global/c-path/c-path.html',
      controller:  cPathCtrlFn,
    });

  /**
   * @ngdoc component
   * @name C.path:cPath
   * @function
   *
   * @description
   * Reusable component for path.
   * ViewValue will be different for windows and linux paths.
   * ModelValue will always be linux style path.
   *
   * @example
     <c-path id="backup-file-path-0"
      name="backupFilePath-0"
      placeholder="::'fullPathToExclude' | translate"
      ng-model="pathInfo.backupFilePath"
      type="include"
      display-backslashes="$ctrl.node._hostType === 'kWindows'"
      required></c-path>
   */
  function cPathCtrlFn(_, FORMATS, cUtils) {
    var $ctrl = this;

    assign($ctrl, {
      syncModel: syncModel,

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * Component init function.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.ngModel.$render = $ctrl.syncModel;
      $ctrl.ngModel.$parsers.push(_parse);
      $ctrl.ngModel.$formatters.push(_format);
      $ctrl.ngModel.$validators.pattern = _validatePattern;
    }

    /**
     * Sync internal and external model values.
     *
     * @method   syncModel
     * @param    {string}   [value]   Input value
     */
    function syncModel(value) {
      if (arguments.length) {
        $ctrl.ngModel.$setViewValue(value);
      } else {
        $ctrl.internalModel = $ctrl.ngModel.$viewValue;
      }
    }

    /**
     * formats the viewValue
     *
     * @method   _format
     * @param    {string}   [value]   viewValue
     * @return   {string}   formatted value in case of windows.
     *                      same value in other cases
     */
    function _format(value) {
      // In case of windows replace front slashes with back slashes
      if ($ctrl.displayBackslashes) {
        value = cUtils.transformToWindowsPath(value);
      }
      return value;
    }

    /**
     * parse the modelValue.
     *
     * @method   _parse
     * @param    {string}   [value]   modelValue
     * @return   {string}   Parsed value
     */
    function _parse(value) {
      // In case of windows replace the blackslashes to forward slashes,
      // As magneto only accepts forward slashes.
      if($ctrl.displayBackslashes) {
        value = _replaceBackwardSlashes(value, '/');

        // Change the c: to /c as Magneto accepts only UNIX path
        if(value.charAt(1) === ':') {
          value = value.replace(/:/, '');

          if (value.charAt(0) !== "/") {
            value = '/' + value;
          }
        }
      }

      // '//' occurs when we have a volume name as '/' so ideally the path
      // is correct (ex: //home/). In these cases magneto requires the extra
      // slash to be removed so that it searches within root - '/'
      return _replaceDoubleSlashes(value);
    }

    /**
     * @method   _validatePattern
     * @param    {string}   modelValue
     * @param    {string}   viewValue
     * @returns  {boolean}  return if the viewValue is valid or not
     */
    function _validatePattern(modelValue, viewValue){
      var regexForValidation = $ctrl.customRegex ||
        FORMATS.windowsFilePathRegex;

      if (!viewValue) { return true; }

      return $ctrl.displayBackslashes ?
        regexForValidation.test(viewValue) : true;
    }

    /**
     * Replace double slashes in the path
     *
     * @method   _replaceDoubleSlashes
     * @param    {string}   [path]   filePath
     * @return   {string}   path with single '/'.
     * @example
     * Input                     Output
     * '//program-files'       '/program-files'
     * '/Documents'            '/Documents'
     */
    function _replaceDoubleSlashes(path) {
      return path.replace(/\/\//g, '/');
    }

    /**
     * Replace backward slashes.
     * By default it replaces backslashes with ''.
     *
     * @method   _replaceBackwardSlashes
     * @param    {string}   [path]   filePath
     * @param    {string}   [replaceWith] replace backward slashes with
     * @param    {boolean}  [onlyFirst]   flag to replace only first instance
     * @return   {string}   path
     * @example
     * path                  replaceWith   onlyFirst    Output
     * 'c:\program-files\'    '_'          undefined    'c:_program-files_'
     * 'c:\program-files\'    '_'          true         'c:_program-files\'
     */
    function _replaceBackwardSlashes(path, replaceWith, onlyFirst) {
      replaceWith = replaceWith ? replaceWith : '';
      return path.replace(onlyFirst ? /\\/ : /\\/g, replaceWith);
    }
  }
})(angular);
