import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { flatten } from 'lodash-es';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { HelixIntlService } from '../../../helix-intl.service';
import { PageBackdropRef } from '../../page/page-backdrop/page-backdrop-ref';
import { PageComponent } from '../../page/page.component';
import { DataFilter, DataFilterItem, DataFilterValue, ValueFilterSelection } from '../comparators';
import { FilterDefDirective } from './filter-def.directive';

export interface HelixFilterOptions {
  /** Whether to show the filters label or not */
  showFilterLabel?: boolean;
}

/**
 * This injection token can be set to override the dafault data id name.
 */
export const HELIX_FILTER_OPTIONS = new InjectionToken<HelixFilterOptions>('helix-filters-options', {
  providedIn: 'root',
  factory: () => ({
    /** 'Filters' label will be disabled by default */
    showFilterLabel: false,
  }),
});

/**
 * Filter alignment type.
 */
export type FilterAlignment = 'row' | 'column';

/**
 * Grouped filters hashmap to group filter definitions by group name.
 */
export interface FilterGroup {
  [ groupName: string ]: FilterDefDirective[];
}

/**
 * Grouped filters hashmap to group filter tags by group name.
 */
export interface FilterGroupTags {
  [ groupName: string ]: DataFilterItem[];
}

/**
 * Grouped filters hashmap to group filter tags by group name.
 */
export interface GroupFilters {
  [ groupName: string ]: DataFilter<any>[];
}

/**
 * The filters component takes a list of filter defs and outputs an array of filters
 * that can be applied to a table or any other filterable data source. It also takes care
 * of displaying filters, either inline or an expanded, 'all filters' view.
 */
@Component({
  selector: 'cog-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltersComponent implements AfterContentInit, OnDestroy {

  /**
   * Custom filter defs can be manually added to the filters component.
   */
  customFilterDefs: FilterDefDirective[] = [];

  /**
   * These are the filter defs passed to this component
   */
  @ContentChildren(FilterDefDirective, { descendants: true })
  filterDefs: QueryList<FilterDefDirective>;

  /**
   * The filter dialog template - this will be replaced with something to show
   * in the backdrop eventually.
   */
  @ViewChild('backdropFilters', { read: TemplateRef, static: false })
  backdropFiltersRef: TemplateRef<any>;

  /**
   * These are the filters that have been configured as quick filters. They will
   * always show inline within the component.
   */
  quickFilterDefs: FilterDefDirective[];

  /**
   * Extra filters that will be rendered in page backdrop component.
   */
  extraFilterDefs: FilterDefDirective[];

  /**
   * Filters that will be grouped by group name using `filterGroup` property on `FilterDefDirective`.
   */
  groupFilterDefs: FilterGroup;

  /**
   * Filter tags grouped by filter group name.
   */
  readonly groupFilterTags: FilterGroupTags = {};

  /**
   * List of all filter group names from `filterGroup` property.
   */
  filterGroups: string[];

  /**
   * `FilterDefDirective` grouped by `filterGroup` name.
   */
  groupFilters: GroupFilters;

  /**
   * Tracks selected values for extra filters.
   */
  private readonly extraFilterTagsSubject = new ReplaySubject<DataFilterItem[]>();

  /**
   * Make extraFilterTags$ as observable to restrict usage.
   */
  readonly extraFilterTags$ = this.extraFilterTagsSubject.asObservable();

  /**
   * These are the current filters values configured to be displayed as a chip list.
   */
  private readonly filterTagsSubject = new ReplaySubject<DataFilterItem[]>();

  /**
   * Make filterTags$ as observable to restrict usage.
   */
  readonly filterTags$ = this.filterTagsSubject.asObservable();

  /**
   * These are the current filters that can be used to apply filters to a set of data.
   */
  private readonly filterValuesSubject = new ReplaySubject<DataFilterValue<any>[]>();

  /**
   * Filter alignment which can be row or column and by default we use row alignment.
   */
  @Input() alignment: FilterAlignment = 'row';

  /**
   * Make filterValues$ as observable to restrict usage.
   */
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('valueChange') readonly filterValues$ = this.filterValuesSubject.asObservable();

  /**
   * The ref for a current showing page backdrop.
   */
  backdropRef: PageBackdropRef<any>;

  /**
   * Show the `Filter:` label before the actual filters i.e. protocol, volume type etc.
   */
  @Input() showFilterLabel  = !!this.defaultOptions.showFilterLabel;

  /**
   * Hold the element reference of the last clicked pill.
   */
  clickedPill: HTMLElement;

  /**
   * Hold the element reference of the last opened selection list.
   */
  openedSelectionList: HTMLElement;

  /**
   * Returns all of the filter defs, both content children and manually added ones. This places
   * custom filters defs before the normally defined ones by default.
   * But if position are defined, it will be inserted at the position.
   */
  get allFilterDefs(): FilterDefDirective[] {
    const mergeList = [];
    const insertList = [];

    (this.customFilterDefs || []).forEach(filterDef => {
      if (filterDef.position === undefined) {
        mergeList.push(filterDef);
      } else {
        insertList.push(filterDef);
      }
    });

    const result = [
      ...mergeList,
      ...(this.filterDefs?.toArray() || [])
    ];

    insertList.forEach(filterDef => result.splice(filterDef.position, 0, filterDef));
    return result;
  }

  selectionModelByFilterDef = new Map<DataFilter<any, any>, SelectionModel<ValueFilterSelection>>();

  selectionModelSubByFilterDef = new Map<DataFilter<any, any>, Subscription>();

  /**
   * Used by observables to cancel subscription when component is destroyed.
   */
  private readonly destroy$ = new Subject<void>();

  /**
   * Listen to click on the button pills to focus the first available option
   * automatically.
   */
  @HostListener('click', ['$event.target']) onClick(eventTarget: HTMLElement) {
    if (!this.elementRef.nativeElement.contains(eventTarget)) {
      return;
    }

    // Cache the opened selection list. This works since at any time, there's only
    // one cdk overlay container present, and the opened cdk overlay container
    // was likely opened because of clicking this pill
    this.openedSelectionList = document.querySelector('.cdk-overlay-container .filter-container mat-selection-list');

    if (!this.openedSelectionList) {
      return;
    }

    // Automatically focus the first list option available for better keyboard accessibility.
    this.openedSelectionList.querySelector<HTMLElement>('mat-list-option')?.focus();

    // Cache the clicked pill for other tabbing keyboard actions.
    this.clickedPill = eventTarget;
  }

  /**
   * Listen to Enter and Tab keydown events to make the filter pills keyboard accessible.
   */
  @HostListener('document:keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && document.activeElement?.tagName === 'MAT-LIST-OPTION'
      && this.openedSelectionList.contains(document.activeElement)) {
      (document.activeElement as HTMLElement).click();
    }

    if (event.key !== 'Tab') {
      // Remaining actions are for tab click.
      return;
    }

    // Find the next closest pill to highlight if tab is clicked.
    const closest = this.clickedPill?.closest('.quick-filter');

    // For shift tab, select the previous pill, otherwise select the next pill.
    const next = event.shiftKey ? closest?.previousElementSibling : closest?.nextElementSibling;

    if (!next?.classList.contains('quick-filter')) {
      // If next element is not a quick filter, don't do anything and let the default
      // event take place.
      return;
    }

    // Quick filter either has a button or a text input for search.
    const element = next?.querySelector('button') || next?.querySelector('[type="text"]');

    if (!element) {
      return;
    }

    // If focusable element is present, focus it.
    event.preventDefault();
    element.focus();
    this.clickedPill = null;
    this.openedSelectionList = null;
  }

  constructor(
    @Optional() @Inject(PageComponent) private page: PageComponent,
    @Inject(HELIX_FILTER_OPTIONS) private defaultOptions: HelixFilterOptions,
    public intl: HelixIntlService,
    private elementRef: ElementRef
  ) { }

  /**
   * Manually adds a filter def to the filters component. This makes it possible to add custom filters that define a
   * filterDef that would not otherwise be picked up by Content Children.
   *
   * @param   filterDef   The filter def to add.
   */
  addFilterDef(filterDef: FilterDefDirective) {
    if (filterDef && this.customFilterDefs.indexOf(filterDef) === -1) {
      this.customFilterDefs.push(filterDef);
    }
  }

  /**
   * Removes a custom filter def from the list.
   *
   * @param   filterDef   The filter def to remove
   */
  removeFilterDef(filterDef: FilterDefDirective) {
    const index = this.customFilterDefs.indexOf(filterDef);
    if (index !== -1) {
      this.customFilterDefs.splice(index, 1);
    }
  }

  /**
   * FilterDefs are available after content init.
   */
  ngAfterContentInit() {
    // Adding a timeout here forces the filter def to be updated on the next
    // change detection cycle. Otherwise, the component will cause an
    // expression changed after it has been checked error.
    setTimeout(() => this.onFilterDefsChanges(this.allFilterDefs));
  }

  /**
   * Clean up observable subscriptions.
   */
  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Toggles showing the complete set of filters in a dialog.
   */
  toggleFilters() {
    if (!this.backdropRef) {
      this.backdropRef = this.page.setBackdrop(this.backdropFiltersRef);
      this.backdropRef.afterClosed().subscribe(() => this.backdropRef = undefined);
    } else {
      this.backdropRef.close();
    }
  }

  /**
   * Helper function which allows implementations to manually set the value for a given
   * filter. This can be used to programmatically set filter values based on state params
   * or some user interaction not directly tied to the filters.
   *
   * @param   filterName   The name/key of the filter on which the value will be set.
   * @param   newValue     The value to set the filter to.
   */
  setValue(filterName: string, newValue: any) {
    const filter = this.getFilter(filterName);

    if (filter) {
      filter.setValue(newValue);
    }
  }

  /**
   * Helper function to lock a filter. A locked filter cannot be cleared by the user.
   *
   * @param   filterName   The name/key of the filter on which the value will be set.
   */
  lockFilter(filterName: string, lockedMessage: string) {
    const filter = this.getFilter(filterName);

    if (filter) {
      filter.lockedMessage = lockedMessage;
    }
  }

  /**
   * Helper function to unlock a filter. A locked filter cannot be cleared by the user.
   *
   * @param   filterName   The name/key of the filter on which the value will be set.
   */
  unlockFilter(filterName: string) {
    const filter = this.getFilter(filterName);

    if (filter) {
      filter.lockedMessage = undefined;
    }
  }

  /**
   * Look up a given filter by name.
   *
   * @param   filterName  The filter key name.
   * @return  The filter object.
   */
  private getFilter(filterName: string): DataFilter<any, any> {
    const filterDef = this.allFilterDefs.find(def => def.filter.key === filterName);
    if (!filterDef) {
      return null;
    }
    return filterDef.filter;
  }

  /**
   * Update the main observables based on the current filters.
   *
   * @param   defs   The filter defs that were passed to the component
   */
  private onFilterDefsChanges(defs: FilterDefDirective[]) {
    // keep track of unique filter group names when filtering quick filters
    const filterGroups = {};
    // quick and grouped filters mix together
    // first filter with group name will define position within quick filters
    this.quickFilterDefs = defs.filter(def => {
      if (def.quickFilter) {
        return true;
      } else if (def.filterGroup && !filterGroups[def.filterGroup]) {
        filterGroups[def.filterGroup] = def;
        return true;
      }

      return false;
    });
    this.extraFilterDefs = defs.filter(def => !def.quickFilter && !def.filterGroup);

    const filters = defs.map(def => def.filter);
    const filterValues = filters.map(filter => filter.currentValue$);
    combineLatest(filterValues)
      .pipe(
        takeUntil(this.destroy$),
        map((values: DataFilterValue<any>[]) => values.filter(value => value))
      )
      .subscribe(values => this.filterValuesSubject.next(values));

    // Create a single array of all of the currently applied filter tag values.
    const filterTags = filters.map(filter => filter.tagValues$);
    combineLatest(filterTags)
      .pipe(
        takeUntil(this.destroy$),
        map((values: DataFilterItem[][]) => flatten(values))
      )
      .subscribe(values => this.filterTagsSubject.next(values));

    // Tags for "extra"/"more"/"backdrop" filters
    const extraTags = this.extraFilterDefs.map(def => def.filter.tagValues$);
    combineLatest(extraTags)
      .pipe(
        takeUntil(this.destroy$),
        map((values: DataFilterItem[][]) => flatten(values))
      )
      .subscribe(values => this.extraFilterTagsSubject.next(values));

    // generate map of filters grouped by groupName
    this.groupFilterDefs = defs.reduce((groups: FilterGroup, def) => {
      const { filterGroup } = def;
      if (filterGroup && !def.quickFilter) {
        groups[filterGroup] = groups[filterGroup] || [];
        groups[filterGroup].push(def);
      }

      return groups;
    }, {});

    // list all group names for grouped filters.
    this.filterGroups = Object.keys(this.groupFilterDefs);

    this.groupFilters = {};

    this.filterGroups.forEach(groupName => {
      const groupDefs = this.groupFilterDefs[groupName];

      const groupFilters = groupDefs.map(({ filter }: FilterDefDirective) => filter);
      this.groupFilters[groupName] = groupFilters;

      const groupTags = groupDefs.map(def => def.filter.tagValues$);
      combineLatest(groupTags)
        .pipe(
          takeUntil(this.destroy$),
          map((values: DataFilterItem[][]) => flatten(values))
        )
        .subscribe(values => this.groupFilterTags[groupName] = values);
    });

    this.selectionModelSubByFilterDef.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.selectionModelSubByFilterDef.clear();

    this.selectionModelByFilterDef.clear();

    Object.keys(this.groupFilterDefs).forEach(groupName => {
      const filterDefs = this.groupFilterDefs[groupName];
      const wrongConfigFilterDefs = filterDefs.filter(filterDef => !filterDef.allowSelectionFromSingleFilter);
      const isInvalid = wrongConfigFilterDefs.length > 0 && wrongConfigFilterDefs.length !== filterDefs.length;

      if (isInvalid) {
        console.error([
          'Validation Error:',
          wrongConfigFilterDefs.map(filterDef => `"${filterDef.filter.key}"`).join(', '),
          'filter\'s are missing allowSelectionFromSingleFilter: true as other filter\'s part of group',
          'are having allowSelectionFromSingleFilter: true.'
        ].join(' '));
      }

      filterDefs.forEach(filterDef => {
        this.selectionModelByFilterDef.set(
          filterDef.filter,
          new SelectionModel<ValueFilterSelection>(false)
        );
      });
    });

    Object.keys(this.groupFilterDefs).forEach(groupName => {
      const groupFilterDef = this.groupFilterDefs[groupName];
      const selectionModels = groupFilterDef.map(filterDef => this.selectionModelByFilterDef.get(filterDef.filter));

      groupFilterDef.forEach((filterDef, index) => {
        const selectionModel = selectionModels[index];

        this.selectionModelSubByFilterDef.set(
          filterDef.filter,
          selectionModel.changed.pipe(
            takeUntil(this.destroy$),
            tap(updates => {
              if (updates.added.length) {
                selectionModels.forEach((model, i) => {
                  if (index !== i && !model.isEmpty()) {
                    model.clear();
                  }
                });
              }
            }),
          ).subscribe()
        );
      });
    });
  }

  /**
   * Apply multiple filters grouped by specified group name at once.
   *
   * @param  groupName  Group name that filters are grouped by.
   */
  applyGroupFilters(groupName: string) {
    this.groupFilters[groupName].forEach((filter: DataFilter<any>) => filter.apply$.next());
  }

  /**
   * Clear multiple filters grouped by specified group name at once.
   *
   * @param  groupName  Group name that filters are grouped by.
   */
  clearGroupFilters(groupName: string) {
    this.groupFilters[groupName].forEach((filter: DataFilter<any>) => filter.clear$.next());
  }
}
