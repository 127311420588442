import { assign } from 'lodash-es';
// Component: cStorageVolumeSearch

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.storageVolumeRecoverySearch', [])
    .component('cStorageVolumeRecoverySearch', {
      bindings: {
        /**
         *  addToCart callback function defined in parent view
         *
         * @method   addToCart
         * @type     {function}
         */
        addToCart: '=',

        /**
         *  getModeTextKey callback function defined in parent view
         *
         * @method   getModeTextKey
         * @type     {function}
         * @return   {string}
         */
        getModeTextKey: '=',
      },
      templateUrl:
        'app/protection/recovery/storage-volume/storage-volume.search.html',
      controller: cStorageVolumeSearchCtrlFn,
    });

  /**
   * Recover Storage Volume Search Controller
   *
   * @method   cStorageVolumeSearchCtrlFn
   */
  function cStorageVolumeSearchCtrlFn(_, ClusterService,
    DateTimeService, ENTITY_KEYS, SearchService, RecoveryStore) {
    var $ctrl = this;

    /**
     * Default Filters for cSearchFilters.
     *
     * @example
      {
        property:    {String},
        display:     {String|Fn=},
        primary:     {Bool=},
        locked:      {Bool=},
        transformFn: {Fn=},
        value:       {Array|Integer|String|Bool=}
      }
     * @type   {Array}
     */
    var defaultFilterOptions = [];

    // declare component methods
    assign($ctrl, {
      preProcessSearchResults: preProcessSearchResults,
      isRemoteObject: isRemoteObject,

      // component life cycle methods
      $onInit: $onInit,
    });

    defaultFilterOptions = [{
      property: 'registeredSourceIds',
      display: 'sourceName',
      transformFn: _sourceIdFromName,
      locked: false
    }, {
      property: 'viewBoxIds',
      display: 'viewBox',
      transformFn: _viewBoxIdFromName,
      locked: false
    }, {
      property: 'entityIds',
      display: 'volumes',
      transformFn: _entityIdFromName,
      locked: false
    }, {
      property: 'jobIds',
      display: 'protectionJob',
      transformFn: _jobIdFromName,
      locked: false
    }, {
      property: 'fromTimeUsecs',
      display: 'startDate',
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'toTimeUsecs',
      display: 'endDate',
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'vmName',
      primary: true
    }];

    /**
     * Component init function
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeCtrl();
      _initSearchConfig();
    }

    /**
     * Initialize the data required for the component
     *
     * @method   _initializeCtrl
     */
    function _initializeCtrl() {
      assign($ctrl, {
        shared: RecoveryStore.get(),
      });
    }

    /**
     *  Init required config for search
     *
     * @method   _initSearchConfig
     */
    function _initSearchConfig() {
      $ctrl.shared.filters = angular.copy(defaultFilterOptions);
    }

    /**
     * Transform a single, or an array of Source names to Source IDs
     *
     * @method   _sourceIdFromName
     * @param    {object|array}  names   Array Source names
     * @return   {array}         Array of Source IDs
     */
    function _sourceIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return $ctrl.shared.filterLookups.registeredSourceIds
          .reduce(function matchSources(sources, source) {
            var enitityKey = ENTITY_KEYS[source.entity.type];
            if (source.entity[enitityKey] && !!~names.indexOf(source.entity[enitityKey].name)) {
              sources.push(source.entity.id);
            }
            return sources;
          }, []);
      }
      return out;
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method   _viewBoxIdFromName
     * @param    {object|array}  viewBoxes  The viewBox names to get the
     *                                      IDs for
     * @return   {array}         The viewBox ids
     */
    function _viewBoxIdFromName(viewBoxes) {
      var out = [];
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        return $ctrl.shared.filterLookups.viewBoxIds
          .reduce(function matchViewboxes(boxes, vb) {
            if (!!~viewBoxes.indexOf(vb.name)) {
              boxes.push(vb.id);
            }
            return boxes;
          }, []);
      }
      return out;
    }

    /**
     * Transforms a list of entity names to a list of ids for matching entities.
     *
     * @method   _entityIdFromName
     * @param    {array|object}   names   Single, or array of entity names
     * @return   {array}                  The list of ids, if any match
     */
    function _entityIdFromName(names) {
      names = (!!names) ? [].concat(names) : [];
      return $ctrl.shared.filterLookups.entityIds
        .reduce(function matchEntities(ids, entity) {
          if (names.includes(entity[entity._entityKey].name)) {
            ids.push(entity.id);
          }
          return ids;
        }, []);
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method   _jobIdFromName
     * @param    {Array}  names   Array of Job names
     * @return   {Array}          Array of Job IDs
     */
    function _jobIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return $ctrl.shared.filterLookups.jobIds
          .reduce(function matchJobs(jobs, job) {
            if (names.includes(job.jobName)) {
              jobs.push(job.jobId);
            }
            return jobs;
          }, []);
      }
      return out;
    }

    /**
     * Determines if the given entity is a remote entity or not
     *
     * @method     isRemoteObject
     * @param      {object}   entity  The entity
     * @return     {boolean}  True if remote object, False otherwise.
     */
    function isRemoteObject(entity) {
      return (entity && entity.vmDocument) ?
        (ClusterService.clusterInfo.id !==
        entity.vmDocument.objectId.jobUid.clusterId) :
        false;
    }

    /**
     * Pre-process the search results. Uses the SearchService's transform
     * method under the hood + a little more.
     *
     * @method     preProcessSearchResults
     * @param      {object}  results  The server's raw response object
     * @return     {array}   The results of the SearchService's transformer
     */
    function preProcessSearchResults(results) {
      var resp = SearchService.transformStorageVolumeResults(results);
      if (!resp.length) {
        return [{
          isEmpty: true
        }];
      }
      return resp;
    }
  }
})(angular);
