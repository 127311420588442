import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Point } from 'highcharts';
import { finalize } from 'rxjs/operators';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';

import { ComplianceData, SlaComplianceService } from './sla-compliance-card.service';

/**
 * @description
 * SLA Compliance dashcard.
 * The card by default displays SLA status in the statlists.
 * If displayProtectionRuns is set to true, the card will display protection runs stats instead.
 *
 * @example
 *  <coh-sla-compliance-card title="Compliance"
 *    [displayProtectionRuns]="true">
 *  </coh-sla-compliance-card>
 */
@Component({
  selector: 'coh-sla-compliance-card',
  templateUrl: './sla-compliance-card.component.html',
  styleUrls: ['./sla-compliance-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SlaComplianceService]
})
export class SlaComplianceCardComponent extends AutoDestroyable implements OnInit {

  /**
   * Title to be displayed on the card.
   */
  @Input() title = '';

  /**
   * Whether to display the protection run stats in the header instead of SLA success/failure statuses.
   * Default to false.
   */
  @Input() displayProtectionRuns = false;

  /**
   * Flag to indicate cluster selection scope in MCM mode.
   */
  get allClusters() {
    return this.slaComplianceService.allClusters;
  }

  /**
   * Custom Bar Chart Options.
   */
  readonly customChartOptions = {
    plotOptions: {
      column: {
        minPointLength: 2
      }
    },
    yAxis: {
      min: 0,
      minRange: 1,
    },
    tooltip: {
      formatter() {
        const point: Point = this.point;
        return `${point.category}: ${point.y} ${point.series.name}`;
      }
    },
  };

  /**
   * SLA compliance data.
   */
  complianceData: ComplianceData;

  /**
   * Flag for indicating data request.
   */
  isLoading = false;

  constructor(
    private ajsEvalAjaxService: AjaxHandlerService,
    private slaComplianceService: SlaComplianceService,
    private change: ChangeDetectorRef) {
    super();
  }

  /**
   * Initialize component.
   */
  ngOnInit() {
    this.isLoading = true;
    this.slaComplianceService.getComplianceData()
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isLoading = false;
          this.change.markForCheck();
        }))
      .subscribe((data: ComplianceData) => {
        this.complianceData = data;
      },
      this.ajsEvalAjaxService.errorMessage);
  }
}
