import { IrisContext } from './iris-context.model';

/**
 * Returns true if the current user is a Gaia configured user.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is Gaia user.
 */
export function isGaiaUser(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.user?.salesforceAccount?.isGaiaUser);
}

/**
 * Returns true if the current user is in Gaia scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in Gaia context.
 */
export function isGaiaScope(irisContext: IrisContext): boolean {
  return Boolean(isGaiaUser(irisContext) && irisContext?.selectedScope?._serviceType === 'diaas');
}
