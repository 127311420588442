import { assign } from 'lodash-es';
// Component: View More Button Component

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.viewMoreButton';
  var componentName = 'viewMoreButton';

  var configOptions = {
    bindings: {
      /**
       * The total number of items.
       */
      total: '<',

      /**
       * Number of items to show when showing less items.
       */
      limitTo: '=',

      /**
       * Optional provide a custom filtering logic fn which will be called when
       * view more button is toggled or initialized and using custom filtering
       * will run less digest cycles.
       *
       * $limitTo will be passed as locals while evaluating filter expression
       *
       * @example
         <view-more-button
          total="$ctrl.allSources.length"
          limit-to="$ctrl.maxItemsToShow"
          filter="$ctrl._visibleSources | limitTo: $limitTo">
        </view-more-button>
       */
      filter: '&?',
    },
    controller: 'ViewMoreButtonCtrl',
    templateUrl: 'app/global/view-more-button/view-more-button.html',
  };

  /**
   * @ngdoc component
   * @name C.viewMoreButton:viewMoreButton
   * @function
   *
   * @description
   * Gives us the functionality of using the view more button when the list is
   * too long to display. To be used with after the ng-repeated list where
   * the filter, 'limitTo' is the same which gets passed to this component.
   *
   * @example
   * use case 1:
      <li ng-repeat="user in $ctrl.usersList |
        limitTo: $ctrl.maxItemsToShow track by user.sid">
        {{user.name}}
      </li>

      <view-more-button
        total="$ctrl.usersList.length"
        limit-to="$ctrl.maxItemsToShow">
      </view-more-button>

   * use case 2:
     <c-source-tree-pub
      tree="$ctrl._visibleSources"
      options="..."></c-source-tree-pub>

      <view-more-button
        total="$ctrl.allSources.length"
        limit-to="$ctrl.maxItemsToShow"
        filter="$ctrl._visibleSources | limitTo: $limitTo">
      </view-more-button>
   */
  angular.module(modName, [])
    .controller('ViewMoreButtonCtrl', viewMoreButtonCtrl)
    .component(componentName, configOptions);

  function viewMoreButtonCtrl(_) {
    var $ctrl = this;

    // declare component methods
    assign($ctrl, {
      toggleViewMore: toggleViewMore,
      getRemainingCount: getRemainingCount,

      // Component life-cycle methods.
      $onInit: $onInit,
      $onChanges: $onChanges,
    });

    /**
     * Initializes the controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      // Minimum number of items which should be displayed.
      $ctrl.min = $ctrl.limitTo;
    }

    /**
     * Update filter when binding got updated.
     *
     * @method   $onChanges
     * @param    {Object}   changes   The angular changes object
     */
    function $onChanges(changes) {
      if (changes && changes.total) {
        // update the limitTo to reflect new total value when view more is open
        if ($ctrl.limitTo === changes.total.previousValue &&
          !changes.total.isFirstChange()) {
          $ctrl.limitTo = $ctrl.total;
        }
        updateFilter();
      }
    }

    /**
     * Get the remaining number of elements.
     *
     * @method   getRemainingCount
     * @return   {number}  The remaining number of items.
     */
    function getRemainingCount() {
      return $ctrl.total - $ctrl.min;
    }

    /**
     * Toggles the viewMore Button by using the two-way binded limitTo.
     *
     * @method   toggleViewMore
     */
    function toggleViewMore() {
      $ctrl.limitTo = $ctrl.min === $ctrl.limitTo ? $ctrl.total : $ctrl.min;
      updateFilter();
    }

    /**
     * Executable external filter expression to update the view.
     *
     * @method   updateFilter
     */
    function updateFilter() {
      if ($ctrl.filter) {
        $ctrl.filter({$limitTo: $ctrl.limitTo});
      }
    }
  }

})(angular);
