import { Injectable } from '@angular/core';
import { BasicClusterInfo, ClusterServiceApi } from '@cohesity/api/v1';
import { AsyncBehaviorSubject, updateWithStatus } from '@cohesity/utils';
import { filter, map, take, tap } from 'rxjs/operators';
import { clusterIsIbmBaas } from '../iris-context/platform-config-utils';

/**
 * This is a generic environment service based on API calls to know the environment.
 * The initial code is copied from ClusterService in iris-ui-angular app and is used by connector app.
 */
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  /**
   * Observable to the basic cluster info async behavior subject.
   */
  private basicClusterInfoSubject = new AsyncBehaviorSubject<BasicClusterInfo>();

  /**
   * Expose basic cluster info as an observable.
   */
  readonly basicClusterInfo$ = this.basicClusterInfoSubject.pipe(
    map(info => info.result),
    filter(info => !!info),
    take(1),
  );

  /**
   * True if is IBM BaaS environment.
   */
  readonly isIbmBaas$ = this.basicClusterInfo$.pipe(map(clusterIsIbmBaas));

  /**
   * The currently cached value of basic cluster info.
   */
  get basicClusterInfo() {
    return this.basicClusterInfoSubject.value.result;
  }

  /**
   * The currently cached value of isIbmBaas.
   */
  get isIbmBaas() {
    return clusterIsIbmBaas(this.basicClusterInfoSubject.value.result);
  }

  constructor(
    private clusterService: ClusterServiceApi,
  ) {
  }

  /**
   * Fetch the cluster info and cache it.
   *
   * @param      forceQuery  force an API query  even if the cache is populated.
   * @return     An observable of the response.
   */
  fetch(forceQuery: boolean = false) {
    if ((!this.basicClusterInfo && !this.basicClusterInfoSubject.value.loading) || forceQuery) {
      this.clusterService.GetBasicClusterInfo().pipe(
        // Remove duplicate domains
        tap(clusterInfo => clusterInfo.domains = Array.from(new Set<string>(clusterInfo.domains))),
        updateWithStatus(this.basicClusterInfoSubject)
      ).subscribe();
    }
  }
}
