import { some } from 'lodash-es';
import { find } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: Vmdk Recovery: Options step

;(function(angular, undefined){
  'use strict';

  var moduleName = 'C.vmdkRecovery';

  angular
    .module(moduleName)
    .controller('VmdkRecoveryOptionsController',
      VmdkRecoveryOptionsControllerFn);

  function VmdkRecoveryOptionsControllerFn(_, $scope, RestoreService,
    VmdkRecoveryService) {
    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
      entity: {},
      virtualDisks: [],
      overwritingDiskSelected: false,
      dataReady: false,
      isAlternateTargetLocation : false,
      selectedVlanTarget: undefined,

      // Recovery Type Defaults
      recoveryTypes: [{
        enum: 'kOverwrite',
        label: 'overwriteOriginalDisk',
      }, {
        enum: 'kRecoverNewDisk',
        label: 'recoverAsANewDisk',
      }],

      // Controller Methods
      onPowerOffVmsChange: onPowerOffVmsChange,
      onRecoveryTypeChange: onRecoveryTypeChange,
      overwriteOriginalDiskIsSelected: overwriteOriginalDiskIsSelected,
      selectAllDisks: selectAllDisks,
      selectTarget: selectTarget,
      selectRestorePoint: selectRestorePoint,
      startFlowOver: VmdkRecoveryService.startFlowOver,
      submitVmdkRecovery: VmdkRecoveryService.submitTask,
    });

    /**
     * init function
     *
     * @method     onInit
     */
    function $onInit() {
      if (!$scope.shared.cart.length) {
        // Nothing to recover.
        VmdkRecoveryService.startFlowOver();
      }

      $ctrl.entity = $scope.shared.cart[0];

      // Initially we assume we are recovering to the same source
      $scope.shared.task.recoverVirtualDiskParams.targetEntity =
        $scope.shared.task.objects[0].entity;

      // Prepares Virtual disks info for the selected object
      _prepareVirtualDisksInfo();

      $ctrl.dataReady = true;
    }

    /**
     * Gets the virtual disks and sets it in the local scope.
     *
     * @method   _prepareVirtualDisksInfo
     */
    function _prepareVirtualDisksInfo() {
      $ctrl.virtualDisksLoaded  = false;
      VmdkRecoveryService.getVirtualDisksInfo($ctrl.entity)
        .then(function getDisksInfoSuccess(virtualDisks) {
          $ctrl.virtualDisks = virtualDisks;
          $ctrl.virtualDisks.forEach(function loopVirtualDisks(virtualDisk) {
            virtualDisk.mountPoints = virtualDisk.mountPoints ?
              virtualDisk.mountPoints.join(', ') : 'Unmapped';
          });
          $ctrl.virtualDisksLoaded = true;
        });
    }

    /**
     * When Select All checkbox is checked set the correct value on
     * model
     *
     * @method     selectAllDisks
     */
    function selectAllDisks() {
      $ctrl.virtualDisks.forEach(function selectDisk(virtualDisk) {
        virtualDisk._isSelected = $ctrl.selectAll;
      });
    }

    /**
     * Called when a target is selected or changed. After the target selection,
     * we reset the certain values in virtual disk recovery selection.
     *
     * @method   selectTarget
     */
    function selectTarget() {
      VmdkRecoveryService.selectTarget().then(_resetVirtualDisks);
    }

    /**
     * Opens restore point selection modal. If a restore point is changed,
     * we get the virtual disks again as they could change for the new
     * snapshot.
     *
     * @method   selectRestorePoint
     * @param    {object}   row   The restore point version selected
     */
    function selectRestorePoint(row) {
      VmdkRecoveryService.selectRestorePoint(row).then(
        function restorePointChanged() {
          // The snapshot has changed now after new restore point is selected,
          // so we need to fetch the virtual disks again.
          _prepareVirtualDisksInfo();

          // Recovery options are reset
          $ctrl.overwritingDiskSelected = false;

          // Reset power on/off options as virtual disks are reset
          _resetPowerSettings();
        });
    }

    /**
     * When target is changed, we reset the virtual disk recovery options and
     * other selections like powering off/on vms and datastores are affected
     * as well.
     *
     * @method   _resetVirtualDisks
     */
    function _resetVirtualDisks() {
      var targetId =
        get($scope.shared.task, 'recoverVirtualDiskParams.targetEntity.id');
      $ctrl.isAlternateTargetLocation =
        targetId !== $scope.shared.task.objects[0].entity.id;

      $ctrl.virtualDisks.forEach(function loopVirtualDisks(virtualDisk) {
        // Recovery type options would change now if the target is changed
        // Overwrite the virtual disk option is only available if target and
        // source are same
        virtualDisk._selectedRecoveryType = $ctrl.isAlternateTargetLocation ?
          $ctrl.recoveryTypes[1] : undefined;

        // Datastore depends on recovery type. So reset datastores as well
        virtualDisk.selectedDatastore = undefined;
      });

      // Recovery options are reset
      $ctrl.overwritingDiskSelected = false;

      // Reset power on/off options as target has changed
      _resetPowerSettings();

      // If the target is different from the source, we need datastores' list
      // for the target VM.
      if ($ctrl.isAlternateTargetLocation) {
        _populateDatastores(targetId);
      }
    }

    /**
     * Resets the power on/off before and after restore settings to false.
     *
     * @method   _resetPowerSettings
     */
    function _resetPowerSettings() {
      assign($scope.shared.task.recoverVirtualDiskParams, {
        powerOnVmAfterRecovery: false,
        powerOffVmBeforeRecovery: false,
      });
    }

    /**
     * Gets the datastores list and is populated in the datastores dropdown.
     *
     * @method   _populateDatastores
     * @param    {number}   targetId   The target VM id
     */
    function _populateDatastores(targetId) {
      $ctrl.datastoreLoading = true;
      RestoreService.getTargetDatastores({ sourceId: targetId }).then(
        function getDatastoresSuccess(datastores) {
          $ctrl.datastores = datastores;
        }
      ).finally(function getDatastoreFinallyFn() {
        $ctrl.datastoreLoading = false;
      });
    }

    /**
     * when setting powerOffVmsBeforeRestore to true, the default for
     * powerOnVmsAfterRestore should be defaulted to true. when setting
     * powerOffVm to false also set powerOnVmsAfterRestore to false.
     *
     * @method     onPowerOffVmsChange
     */
    function onPowerOffVmsChange() {
      $scope.shared.task.recoverVirtualDiskParams.powerOnVmAfterRecovery =
        $scope.shared.task.recoverVirtualDiskParams.powerOffVmBeforeRecovery;
    }

    /**
     * according to the selected recoveryType set the correct values in the
     * settings section
     *
     * @method   onRecoveryTypeChange
     * @param    {Object}   virtualDisk   The virtual disk object
     */
    function onRecoveryTypeChange(virtualDisk) {
      // If the Recovery Type is changed, select the checkbox for the user.
      virtualDisk._isSelected = true;

      // In case of overwrite scenario we can preselect the datastore as we know
      // the target and source are same and we have source datastore info
      if (virtualDisk._selectedRecoveryType.enum === "kOverwrite") {
        $ctrl.overwritingDiskSelected = true;

        // Setting the target location from the source datastore location
        virtualDisk.selectedDatastore = virtualDisk.diskLocation;
      } else {
        // Empty the datastore if kRecoverNewDisk is selected
        virtualDisk.selectedDatastore = undefined;

        if (!$ctrl.datastores) {
          _populateDatastores($scope.shared.task.recoverVirtualDiskParams.targetEntity.id);
        }
      }

      $ctrl.overwritingDiskSelected = $ctrl.overwritingDiskSelected &&
        some($ctrl.virtualDisks,
          ["_selectedRecoveryType.enum", "kOverwrite"]);

      if ($ctrl.overwritingDiskSelected) {
        assign($scope.shared.task.recoverVirtualDiskParams, {
          powerOnVmAfterRecovery: true,
          powerOffVmBeforeRecovery: true,
        });
      } else {
        assign($scope.shared.task.recoverVirtualDiskParams, {
          powerOnVmAfterRecovery: false,
          powerOffVmBeforeRecovery: false,
        });
      }
    }

     /**
     * Return the first object which has kOverwrite recovery type.
     *
     * @method   overwriteOriginalDiskIsSelected
     * @return   {object}   Return the first object which has kOverwrite recovery type.
     */
    function overwriteOriginalDiskIsSelected() {
      return find($ctrl.virtualDisks, ['_selectedRecoveryType.enum', 'kOverwrite']);
    }
  }
})(angular);
