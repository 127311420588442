<cog-table name="group-runs-table"
  reflowScrollSize="sm"
  *ngIf="runs$ | async as runs"
  [canSelectRowFn]="canSelectRow"
  [selection]="selection"
  [source]="runs"
  [selectRowTooltipFn]="selectRowTooltip"
  [toggleSelectAllFn]="masterToggle">

  <cog-table-multi-action>
    <ng-container *ngFor="let action of actions">
      <button *ngIf="hasAction(action)"
        cog-button-small
        mat-stroked-button
        color="primary"
        (click)="handleAction(action)"
        cogDataId="btn-{{ backupActionName[action] }}">
        <mat-icon class="material-icons-outlined">
          {{ backupActionIcon[action] }}
        </mat-icon>
        {{ backupActionName[action] | translate }}
      </button>
    </ng-container>
    <ng-container *ngFor="let item of menuItems$ | async">
      <button
      cog-button-small
      mat-stroked-button
      color="primary"
      (click)="handleItem(item, selection.selected[0])">
      {{item.displayName | translate}}
      </button>
    </ng-container>
  </cog-table-multi-action>

  <mat-table matSort [trackBy]="trackById" #runTableSort="matSort">
    <ng-container matColumnDef="startDate">
      <mat-header-cell id="group-run-table-start-time-col" *matHeaderCellDef mat-sort-header class="start-date-column">
        {{ 'startTime' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run; index as i;" class="start-date-column">
        <a class="start-date-link"
          cogDataId="anchor-{{ run.name }}-{{ i }}"
          [uiSref]="run.defaultState"
          [uiParams]="hydrateStateParams(run.defaultStateParams)">
          {{ run.startDate | cohDate }}
        </a>
        <div class="icon">
          <cog-icon *ngIf="run.onLegalHold || run.hasLegalHoldTargets"
            shape="gavel"
            size="sm"
            [matTooltip]="'legalHold' | translate">
          </cog-icon>
        </div>
        <div class="icon">
          <cog-icon *ngIf="isDataLockRun(run)"
            shape="lock"
            size="sm"
            [matTooltip]="'datalock' | translate">
          </cog-icon>
        </div>
        <!-- Showing the Snapshot deleted icon if the Snapshot is deleted OR the Snapshot is not available -->
        <coh-snapshot-deleted
          class="icon"
          [deleted]="run.isLocalSnapshotsDeleted ||
            (!run.isDirectArchive && !run.hasLocalSnapshot && !(run.isInProgress && pollProgress))"
          [useIcon]="true"
          iconSize="sm">
        </coh-snapshot-deleted>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="externallyTriggeredBackupTag">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        cogDataId="group-run-table-externally-triggered-backup-tag-col">
        {{ 'tags' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run">
        {{ run?.externallyTriggeredBackupTag | naLabel }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="duration">
      <mat-header-cell id="group-run-table-duration-col"
        *matHeaderCellDef
        mat-sort-header
        class="duration-column">
        {{ 'duration' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run"
        class="duration-column"
        [ngSwitch]="run.isInProgress && pollProgress">
        <ng-container *ngSwitchCase="false">
          {{ run.duration | humanizeDuration }}
        </ng-container>
        <coh-run-progress *ngSwitchCase="true"
          (updateDuration)="onRunDuration($event, run)"
          [runId]="run.runId"
          (completed)="runPulseFinished()">
        </coh-run-progress>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="runType">
      <mat-header-cell id="group-run-table-backup-type-col" *matHeaderCellDef mat-sort-header>
        {{ 'backupType' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run;" class="run-type">
        <ng-container *ngIf="run.runType">
          {{ 'enum.jobRunType.' + run.runType | translate }}
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="readBytes">
      <mat-header-cell id="group-run-table-read-col" *matHeaderCellDef mat-sort-header>
        {{ 'dataRead' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run;" class="read-bytes">
        {{ isStorageArraySnapshot(run)? null : run.readBytes | byteSize }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="writeBytes">
      <mat-header-cell id="group-run-table-write-col" *matHeaderCellDef mat-sort-header>
        <div [matTooltip]="'dataWrittenDesc' | translate" matTooltipPosition="above">{{ 'dataWritten' | translate }}</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let run;" class="write-bytes">
        {{ isStorageArraySnapshot(run)? null: run.writeBytes | byteSize }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell id="group-run-table-size-col" *matHeaderCellDef mat-sort-header>
        {{ 'size' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run;" class="size">
        {{ run.totalBytes | byteSize }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="successOrError">
      <mat-header-cell id="group-run-table-objects-col" *matHeaderCellDef class="objects-column">
        {{ 'successOrError' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run;" class="objects-column">
        <ng-container *ngIf="hasValidObjectsCount(run)">
          {{ 'numObjectsPassFail' | translate: {
            pass: run.isDbRun ? run.successfulAppObjectsCount : run.successfulObjectsCount,
            fail: run.isDbRun ? run.failedAppObjectsCount : run.failedObjectsCount }
          }}
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="slaStatus">
      <mat-header-cell id="group-run-table-sla-status-col" *matHeaderCellDef class="sla-status-column">
        {{ 'sla' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run;" class="sla-status-column">
        <div class="icon-container">
          <coh-status-icon *ngIf="run.slaStatus"
            [status]="[run.slaStatus]">
          </coh-status-icon>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="copyTasks">
      <mat-header-cell *matHeaderCellDef class="copy-tasks-column">
        {{ 'status' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let run; index as i;" class="copy-tasks-column">
        <div class="icon-container">
          <coh-status-icon *ngIf="run.backupStatus"
            cogDataId="backup {{ run.name }} {{ i }}"
            [status]="[run.backupStatus]">
          </coh-status-icon>
        </div>
        <div class="icon-container">
          <coh-status-icon *ngIf="run.replicationStats && !run.isCloudRetrieveRun(targetClusterId$ | async)"
            cogDataId="replication-{{ run.name }} {{ i }}"
            [status]="run.replicationStats">
          </coh-status-icon>
        </div>
        <div class="icon-container">
          <coh-status-icon *ngIf="run.archivalStats"
            cogDataId="archive-{{ run.name }} {{ i }}"
            [status]="run.archivalStats">
          </coh-status-icon>
        </div>
        <div class="icon-container">
          <coh-status-icon *ngIf="run.vaultStats"
            cogDataId="vault-{{ run.name }} {{ i }}"
            [status]="run.vaultStats">
          </coh-status-icon>
        </div>
        <div class="icon-container">
          <coh-status-icon *ngIf="run.cloudSpinStats"
          cogDataId="cloudspin-{{ run.name }} {{ i }}"
          [status]="run.cloudSpinStats">
          </coh-status-icon>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="actions-column"></mat-header-cell>
      <mat-cell *matCellDef="let run; index as i;" class="actions-column">
        <coh-runs-menu [run]="run" [disabled]="canDisableRow(run)" cogDataId="run-menu-{{ run.name }}-{{ i }}" [inheritDataId]="true">
        </coh-runs-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns$ | async"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns$ | async"></mat-row>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef>
        {{ 'noRunsFound' | translate }}
      </mat-footer-cell>
    </ng-container>

    <mat-footer-row class="nodata-row" [class.hidden]="runs?.length" *matFooterRowDef="['noData']">
    </mat-footer-row>
  </mat-table>
  <div class="table-controls">
    <coh-runs-limit-banner [totalRuns]="totalRuns"
      [runsLimit]="runsLimit"
      (viewAllClicked)="viewMoreRuns($event)">
    </coh-runs-limit-banner>
    <div class="spacer"></div>
    <mat-paginator></mat-paginator>
  </div>
</cog-table>
