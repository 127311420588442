import { assign } from 'lodash-es';
// Component: Countdown Timer

;(function(angular) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('CountdownCtrl', CountdownCtrlFn)
    .component('countdownTimer', {
      templateUrl: 'app/apps-management/countdown-timer/countdown-timer.html',
      controller: 'CountdownCtrl',
      require: {
        ngModel: 'ngModel'
      }
    });

  function CountdownCtrlFn(_, $timeout) {
    var $ctrl = this;
    var countdownTimeout;

    assign($ctrl, {
      // Component life-cycle methods
      $onInit: $onInit,
      $onDestroy: $onDestroy
    });

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.ngModel.$render = function $render() {
        countdown($ctrl.ngModel.$viewValue);
      };
    }

    /**
     * Do cleanup on component destroy
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      $timeout.cancel(countdownTimeout);
    }

    /**
     * Shows count down timer untill the page refresh is initiated
     *
     * @method   countdown
     * @param    {Integer} count   count value for countdown timer
     */
    function countdown(count){
      if (count === 0) {
        return;
      }
      count--;
      $ctrl.count = count;
      countdownTimeout = $timeout(function callback(){countdown(count)}, 1000);
    }

  }
})(angular);
