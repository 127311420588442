import { pull } from 'lodash-es';
import { chain } from 'lodash-es';
import { every } from 'lodash-es';
import { some } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module and Component: jobModifySqlSettings

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobModify')
    .component('jobModifySqlSettings', {
      bindings: {
        /**
         * Required protection job setting.
         *
         * @type   {object}
         */
        job: '=',

        /**
         * Optional: The job sources
         *
         * @type   {object[]}
         */
        jobSources: '<?',

        /**
         * Indicates if the component is being included in Job flow edit mode.
         *
         * @type   {boolean}
         */
        isEditMode: '<',
      },
      controller: jobModifySqlSettingsCtrl,
      templateUrl: 'app/protection/jobs/modify2/sql-settings/sql-settings.html',
    });

  /**
   * @ngdoc component
   * @name C.jobModify:jobModifySqlSettings
   * @function
   *
   * @description
   * Provides interface and logic for managing job SQL settings in the job flow.
   *
   * @example
   *  <job-modify-sql-setings job="$ctrl.job"></job-modify-sql-settings>
   */
  function jobModifySqlSettingsCtrl(_, PubJobService, FEATURE_FLAGS,
    USER_DATABASE_BACKUP_PREFERENCE_TYPE, AAG_BACKUP_PREFERENCE_TYPE) {

    var $ctrl = this;
    var envParams;

    assign($ctrl, {
      // lifecycle hooks
      $onInit: $onInit,
      $onChanges: $onChanges,

      // $ctrl properties
      AAG_BACKUP_PREFERENCE_TYPE: AAG_BACKUP_PREFERENCE_TYPE,
      USER_DATABASE_BACKUP_PREFERENCE_TYPE:
        USER_DATABASE_BACKUP_PREFERENCE_TYPE,

      // TODO (spencer): Leverage ENUM_FULL_BACKUP_TYPE instead. Having trouble
      // cleanly filtering out the numeric key names though.
      BACKUP_TYPES: ['kSqlVSSFile', 'kSqlNative', 'kSqlVSSVolume'],

      // Methods
      canSelectDbVolumes: canSelectDbVolumes,
      changeAAGPreference: changeAAGPreference,
      changeUserDatabasePreference: changeUserDatabasePreference,
    });

    /**
     * Component initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      envParams = $ctrl.job._envParams;

      // If this feature is disabled, remove the option from the list.
      if (!FEATURE_FLAGS.sqlNativeBackup) {
        pull($ctrl.BACKUP_TYPES, 'kSqlNative');
      }

      // Early exit for edit mode, as setting default values isn't desirable.
      if ($ctrl.isEditMode) { return; }

      // Set default values.
      angular.extend(envParams, {
        backupVolumesOnly: false,
        userDatabasePreference: 'kBackupAllDatabases',
        aagPreferenceFromSqlServer: true,
        backupSystemDatabases: true,
        backupType: _detectBackupType(),
      });
    }

    /**
     * Handles changes to '<' bound component attributes.
     *
     * @method   $onChanges
     */
    function $onChanges(changes) {
      // This var is used to compare if the hostType of the selected entities
      // has changed. When the job selection is cleared, this will be the same
      // as whatever is currently assigned to $ctrl.hostEnvironment.
      var selectedHostEnvironment =
        get($ctrl.jobSources, '[0]._hostEnvironment', $ctrl.hostEnvironment);

      // Make sure the envParams are updated when the job env params change
      envParams = $ctrl.job._envParams;

      if (get(changes, 'jobSources.currentValue.length') >= 0) {
        $ctrl.jobHasSqlClusters = _jobContainsSqlClusters();

        // When the job selection changes, set this true (DB volumes only) if a
        // SQL Cluster is selected. Otherwise leave it as is.
        if ($ctrl.jobHasSqlClusters) {
          envParams.backupVolumesOnly = true;
        }
      }

      if (!$ctrl.isEditMode && !angular.equals(changes.jobSources.currentValue,
        changes.jobSources.previousValue)) {
        // If the selected hostEnvironment has changed, update the backup type.
        if (selectedHostEnvironment !== $ctrl.hostEnvironment) {
          // Update the hostEnvironment to match current selection.
          $ctrl.hostEnvironment = selectedHostEnvironment;

          // Update the hostEnvironment to match current selection.
          $ctrl.job._envParams.backupType = _detectBackupType();
        }
      }

      $ctrl.canChangeBackupType = _canChangeBackupType();
    }

    /**
     * Detects the appropriate backup type for this job based on the selection.
     *
     * @method   _detectBackupType
     * @return   {string}   The detected appropariate backup type kValue.
     */
    function _detectBackupType() {
      // If the job has no sources and a backupType already configured, use that
      if (isEmpty($ctrl.jobSources) && $ctrl.job._envParams.backupType) {
        return $ctrl.job._envParams.backupType;
      }

      // If something is selected AND VMware file-based is disabled
      if (!FEATURE_FLAGS.protectSqlFileBasedVmware &&
        $ctrl.hostEnvironment === 'kVMware') {
        return 'kSqlVSSVolume';
      }

      // If the user has already selected objects and selected specific volumes
      // to protect.
      if (chain($ctrl.job).get('sourceSpecialParameters', [])
        .some('physicalSpecialParameters.volumeGuid').value()) {
        return 'kSqlVSSVolume';
      }

      return 'kSqlVSSFile';
    }

    /**
     * Determines DB Volumes setting can be changed.
     *
     * @method   canSelectDbVolumes
     * @return   {boolean}   True if changing the setting is permitted.
     */
    function canSelectDbVolumes() {
      switch (envParams.backupType) {
        default:
          // If FCBT job, we can not choose volume options.
          return false;

        case 'kSqlVSSVolume':
          // When CBT Job, we can select volume options, regardless of the
          // entity selected, as long as it's a Job with entities from a
          // Physical host, and there are no SQL Clusters selected.
          return !$ctrl.jobHasSqlClusters &&
            $ctrl.hostEnvironment === 'kPhysical';
      }
    }

    /**
     * Determines if the job selection contains at elast one MS SQL Cluster
     * entity.
     *
     * @method   _jobContainsSqlClusters
     * @return   {boolean}   True if job selection contains at least one MS SQL
     *                       Cluster.
     */
    function _jobContainsSqlClusters() {
      return some($ctrl.jobSources, '_isSqlCluster');
    }

    /**
     * Lightweight wrapper arround PubJobService.jobHasDbSources with this job
     * pre-populated.
     *
     * @method   _jobHasDbSources
     * @return   {boolean}   The return value of the internal method.
     */
    function _jobHasDbSources() {
      return PubJobService.jobHasDbSources($ctrl.job);
    }

    /**
     * Determines if the current job has VMs selected.
     *
     * @method   _jobHasVMs
     * @return   {booelan}   True if VMs found in the selection list. False
     *                       otherwise.
     */
    function _jobHasVMs() {
      var kVMware = 'kVMware';

      return ($ctrl.job._parentSource._environment === kVMware) ||
        ($ctrl.jobSources || []).some(function findVms(source) {
          return source._environment === kVMware;
        });
    }

    /**
     * Determines if the current job config permits changing of the SQL
     * backupType.
     *
     * @method   _canChangeBackupType
     * @return   {boolean}   True if property can be changed. False otherwise.
     */
    function _canChangeBackupType() {
      var backupType = $ctrl.job._envParams.backupType;

      // TODO (spencer): Delete this block after GA of Filestream feature.
      if (!FEATURE_FLAGS.enableFilestream) {
        switch (true) {
          case $ctrl.isEditMode:
            return false;

          default:
            // TODO (spencer): AFter GA, these Fns can also be removed.
            return !_jobHasVMs() && !_jobHasDbSources();
        }
      }

      switch (true) {
        // Forbidden in edit mode.
        case $ctrl.isEditMode:
          return false;

        // Nothing is selected, change away.
        case !get($ctrl, 'jobSources.length'):
          return true;

        // Backup type is currently SQL FCBT, so all selected sources must be
        // allowed to be used in a SQL CBT Job.
        case ['kSqlVSSFile', 'kSqlNative'].includes(backupType):
          return every($ctrl.jobSources, '_canSqlVolumeProtect');

        // Backup type is currently SQL CBT, so all selected sources must be
        // allowed to be used in a SQL FCBT Job.
        case backupType === 'kSqlVSSVolume':
          return every($ctrl.jobSources, '_canSqlFileProtect');
      }
    }

    /**
     * This method is called when user database preferences are changed. When
     * AAG databases are excluded, the aag preference options should be hidden.
     *
     * @method   changeUserDatabasePreference
     */
    function changeUserDatabasePreference() {
      if (envParams.userDatabasePreference === 'kBackupAllExceptAAGDatabases') {
        envParams.aagPreferenceFromSqlServer = undefined;
        envParams.aagPreference = undefined;
      } else {
        envParams.aagPreferenceFromSqlServer = true;
      }
    }

    /**
     * This method is called when AAG preferences are changed. OnEdit When
     * AAG preference is "Use Server Preference" we undefine
     * aagPreference
     *
     * @method   changeAAGPreference
     */
    function changeAAGPreference() {
      if (envParams.aagPreferenceFromSqlServer) {
        envParams.aagPreference = undefined;
      }
    }
  }

})(angular);
