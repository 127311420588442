import { times } from 'lodash-es';
import { isNumber } from 'lodash-es';
import { clone } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: ui-select decorator to make it work with c-field.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters')
    .config(configFn);

  function configFn($provide) {
    $provide.decorator('uiSelectDirective', cFieldUiSelect);
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      cFieldUiSelect
   *
   * @description
   * Decorate uiSelect with c-field filter interface implementations for
   * ui-select.
   */
  function cFieldUiSelect(_, $delegate) {
    var directive = $delegate[0];
    var compile = directive.compile;

    // Optionally require c-field controller used to provide c-field API
    // interfaces implementation for ui-select.
    directive.require.push('?^cField');

    // Optionally require c-filter controller used to determine whether
    // ui-select is used with filters.
    directive.require.push('?^^cFilters');

    directive.compile = function proxyCompileFn() {
      // Cache the existing linkFn to execute after our decorator.
      var linkFn = compile.apply(this, arguments);

      return function proxyLinkFn(scope, elem, attrs, ctrls) {
        var uiSelect = ctrls[0];
        var uiSelectMultiple = scope.$selectMultiple;
        var cField = ctrls[2];
        var cFilters = ctrls[3];

        // setup when ui-select is used inside filter.
        if (cFilters) {
          var destroySetup = cFieldSetup(uiSelect, cField, uiSelectMultiple);
          var destroyWatch = angular.noop;

          // mark field ready when list is loaded else by default consider it
          // is loaded which is needed by c-filters to determine when to
          // initialize & apply the filter.
          if (attrs.hasOwnProperty('cInputSpinner')) {
            destroyWatch = scope.$watch(attrs.cInputSpinner,
              function onToggleLoading(newVal, oldVal) {
                if (newVal !== oldVal) {
                  if (newVal) {
                    cField.startLoading();
                  } else {
                    cField.endLoading();
                  }
                }
              }
            );
          } else {
            // assume field is loaded if there is no spinner condition present.
            cField.endLoading();
          }

          // cleanup the setup on scope destroy.
          scope.$on('$destroy', function cleanup() {
            destroySetup();
            destroyWatch();
          });
        }

        // Execute the default linkFn now.
        return linkFn.apply(this, arguments);
      };
    };

    /**
     * Perform necessary setup when ui-select is used inside c-field by
     * providing custom implementation methods for c-field interfaces which
     * includes methods like clear, abort, apply etc.
     *
     * @method   cFieldSetup
     * @param    {Object}  uiSelect           ui-select controller reference.
     * @param    {Object}  cField             c-field controller reference.
     * @param    {Object}  uiSelectMultiple   ui-select-multiple controller.
     * @returns  {function}  Function used to destroy the setup done.
     */
    function cFieldSetup(uiSelect, cField, uiSelectMultiple) {
      // c-field interfaces implementation for ui-select.
      var cFieldApi = {
        abort: uiSelectAbort,
        apply: uiSelectApply,
        clear: uiSelectClear,
        disableAlwaysOpen: disableAlwaysOpen,
        enableAlwaysOpen: enableAlwaysOpen,
        getSelectedValue: getSelectedValue,
        multiple: uiSelect.multiple,
      };

      // caching original fns and used laster conditionally.
      var original = {
        activate: uiSelect.activate,

        // decorating original close fn for always open case.
        close: getAlwaysOpenCloseFn(uiSelect.close),
        toggle: uiSelect.toggle,
        removeChoice: uiSelectMultiple && uiSelectMultiple.removeChoice,
      };

      // specially setup for multiple selection mode.
      if (uiSelectMultiple) {
        uiSelectMultiple.removeChoice = removeSelected;
      }

      // customize the original ui-select to be used with c-field.
      assign(uiSelect, {
        // initialize the default always open flag used to prevent closing the
        // dropdown when filters are rendered in a modal.
        _isAlwaysOpen: false,

        // enable showing sticky c-field-actions having apply, abort and clear
        // buttons.
        filterActions: true,

        // override ui-select methods with filter friendly implementation for
        // those functions.
        activate: newActivate,
        close: newClose,
        toggle: newToggle,
      });

      // providing default dropdown position as `down` since it is defined
      // internally by evaluating attribute bindings.
      Object.defineProperty(uiSelect, 'dropdownPosition', {
        get: function getDropdownPosition() {
          return 'down';
        },
        set: function setDropdownPosition() {
          return 'down';
        },
      });

      // always hide showing selected choices in multi selection mode because
      // we are showing then in c-filter-pills.
      Object.defineProperty(uiSelect, 'hideMatches', {
        get: function getHideMatchesProperty() {
          return true;
        },
        set: function setHideMatchesProperty() {
          return true;
        },
      });

      /**
       * Setup ui-select states to stay always open.
       *
       * @method   enableAlwaysOpen
       */
      function enableAlwaysOpen() {
        uiSelect._isAlwaysOpen = true;
        uiSelect.filterActions = false;
        uiSelect.activate();
      }

      /**
       * Destroy the ui-select states to stay always open.
       *
       * @method   disableAlwaysOpen
       */
      function disableAlwaysOpen() {
        uiSelect._isAlwaysOpen = false;
        uiSelect.filterActions = true;
      }

      /**
       * Returns the shallow clone of the selected values.
       *
       * @method   getSelectedValue
       */
      function getSelectedValue() {
        // To show only one pill when all choices are selected
        if (uiSelect.multiple && uiSelect.selectAll.allRowsCheckboxState) {
          return [{ [cField.getFieldValueKey()]: 'All' }];
        }

        return clone(uiSelect.selected);
      }

      /**
       * Apply the filter with selected value.
       *
       * @method   uiSelectApply
       */
      function uiSelectApply() {
        if (uiSelect._isAlwaysOpen) {
          // update the cache if filter is applied during always open mode.
          cacheSelectValue();
        } else {
          // burst the previously cached value.
          clearSelectValue();
        }

        // close the dropdown now.
        original.close();
      }

      /**
       * Abort the filter changes to previously cached value and used when
       * Cancel button is clicked from the c-filter/field-actions or click
       * outside.
       *
       * @method   uiSelectAbort
       */
      function uiSelectAbort() {
        restoreSelectValue();
        original.close();
      }

      /**
       * Clear the all selected value and optionally provide index of the choice
       * to remove used in multi selection mode to remove a specific choice.
       *
       * @method   uiSelectClear
       * @param    {number}  [index]   Optionally provide index of the choice to
       * remove and make sense only during multi selection mode.
       */
      function uiSelectClear(index) {
        // specially handle multi selection mode.
        if (uiSelect.multiple) {
          if (uiSelect.selectAll.allRowsCheckboxState) {
            // remove all choices when clear is clicked on All.
            removeSelected();
            uiSelect.selectAll.allRowsCheckboxState = false;
            uiSelect.selectAll.checkboxClickHandler(false);
          } else {
            // removing provided choice or all selected choice.
            removeSelected(index);
          }
        } else {
          // clear selected value.
          uiSelect.select(null);
        }

        // burst the previously cached value.
        clearSelectValue();
        // close the dropdown now.
        original.close();
      }

      /**
       * Removed provided choice or remove all selected choice.
       *
       * @method   removeSelected
       * @param    {number}  [index]   Optionally provide index of the choice to
       * remove.
       * @return   {Boolean} Return true if items are removed else return false.
       */
      function removeSelected(index) {
        var result = false;

        // remove provided choice if provided and it may be index = 0 hence
        // checking for arguments length.
        if (isNumber(index)) {
          result = original.removeChoice(index);
        } else {
          // removing all fields one by one because ui-select doesn't provide
          // clear all in multi selection mode.

          let indexToRemove = 0;
          times((uiSelect.selected || []).length, function eachField() {
            const choiceRemoved = original.removeChoice(indexToRemove);
            // return true if any one item is removed.
            result = result || choiceRemoved;
            // update index if any choice cannot be removed
            indexToRemove += choiceRemoved ? 0 : 1;
          });
        }

        return result;
      }

      /**
       * Modified activate implementation called before showing the dropdown.
       *
       * @method   newActivate
       */
      function newActivate() {
        // don't close the dropdown since controlled manually via c-field.
        uiSelect.closeOnSelect = false;

        // opening the dropdown so cache the current value.
        cacheSelectValue();
        return original.activate.apply(this, arguments);
      }

      /**
       * Modified close implementation called before hiding the dropdown.
       *
       * @method   newClose
       */
      function newClose() {
        // getting close so restore the old value.
        restoreSelectValue();

        original.close.apply(this, arguments);
      }

      /**
       * Returns modified ui-select close fn to work with always open flag.
       *
       * @method   getAlwaysOpenCloseFn
       * @param    {Function}   originalClose   The original close fn.
       */
      function getAlwaysOpenCloseFn(originalClose) {
        return function alwaysOpenCloseFn() {
          // don't call the original close fn if always open if true.
          if (!uiSelect._isAlwaysOpen) {
            originalClose.apply(this, arguments);
          }
        };
      }

      /**
       * Modified toggle implementation called before toggling dropdown.
       *
       * @method   newToggle
       */
      function newToggle() {
        if (uiSelect.open) {
          // getting close so restore the old value.
          restoreSelectValue();
        } else {
          // opening the dropdown so cache the current value.
          cacheSelectValue();
        }
        return original.toggle.apply(this, arguments);
      }

      /**
       * Caches selected value used later to abort the modification.
       *
       * @method   cacheSelectValue
       */
      function cacheSelectValue() {
        uiSelect._previousValue = clone(uiSelect.selected);
      }

      /**
       * Restore the selected value to its previous cached value and clear the
       * cache.
       *
       * @method   restoreSelectValue
       */
      function restoreSelectValue() {
        // nothing to restore when always open is set.
        if (!uiSelect._isAlwaysOpen) {
          if (uiSelect.multiple) {
            // specially handling then multi selection mode and using empty
            // array as default selected state.
            var selected = uiSelect._previousValue &&
              uiSelect._previousValue.length ? uiSelect._previousValue : [];

            uiSelect.selected = selected;
            uiSelectMultiple.updateModel();
          } else {
            uiSelect.selected = uiSelect._previousValue;
          }

          // burst the previously cached value.
          clearSelectValue();
        }
      }

      /**
       * Clear the cached previous value.
       *
       * @method   clearSelectValue
       */
      function clearSelectValue() {
        uiSelect._previousValue = null;
      }

      /**
       * Initialize the setup by registering the c-field API interfaces used by
       * c-filter and c-field to interact with ui-select.
       *
       * @method   initSetup
       */
      function initSetup() {
        cField.registerUiSelect(cFieldApi);
      }

      /**
       * Destroy the registration when ui-select is destroyed.
       *
       * @method   destroySetup
       */
      function destroySetup() {
        cField.deRegisterUiSelect(cFieldApi);
      }

      // initializing the setup.
      initSetup();

      // returns a fn used to destroy the setup.
      return destroySetup;
    }

    return $delegate;
  }
})(angular);
