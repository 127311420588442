import dayjs from 'dayjs/esm';
import objectSupport from 'dayjs/esm/plugin/objectSupport';
import tz from 'dayjs/esm/plugin/timezone';
import { isInteger, toNumber } from 'lodash-es';

import { DayToMsecs, Granularity } from '../constants/date.constants';

dayjs.extend(tz);
dayjs.extend(objectSupport);

/**
 * The timezone of current user.  If this can not be determined,
 * it is set to UTC as default.
 */
export const userTimezone = dayjs.tz.guess() || 'UTC';

/**
 * DayJS with timezone.
 */
export { dayjs as dayjsWithTz };

/**
 * Specifies the time of day. Used for scheduling purposes.
 */
export interface TimeOfDay {
  /**
   * Specifies the hour of the day (0-23).
   */
  hour: null | number;

  /**
   * Specifies the minute of the hour (0-59).
   */
  minute: null | number;

  /**
   * Specifies the time zone of the user. If not specified, default value is assumed as America/Los_Angeles.
   */
  timeZone?: null | string;
}

/**
 * Generic interface to use for time period values
 */
export interface TimePeriodValue {
  value?: number;
  type: string;
}

/**
 * Calculate the best fit TimePeriodValue for the provided number numbers of milli secs.
 *
 * @param nMsecs Number of milli secs.
 */
export const getMsecsToTimePeriodValue = (nMsecs: number): TimePeriodValue => {
  let out: TimePeriodValue = { value: 0, type: '' };

  Object.keys(DayToMsecs).filter(unit => !toNumber(unit)).reverse().find((unit, index, units) => {
    const divide = nMsecs / DayToMsecs[unit];

    if (divide === 0) {
      out = { value: divide, type: 'kDay' };
      return true;
    }

    if ((index - 1) === units.length) {
      out = { value: divide, type: units[index] };
      return true;
    }

    if (isInteger(divide)) {
      out = { value: divide, type: units[index] };
      return true;
    }

    return false;
  });

  return out;
};

/**
 * Calculate the mSecs Value from the given time period.
 *
 * @param timePeriod the time period.
 */
export const getTimePeriodtoMsecsValue = (timePeriod: TimePeriodValue): number =>
  timePeriod.value * DayToMsecs[timePeriod.type as string];

/**
 * Converts the time period value to readable display value string.
 *
 * @param timePeriod the time period.
 * @returns The display value.
 */
export const getTimePeriodDisplayValue = (timePeriod: TimePeriodValue): string =>
  `${timePeriod.value} ${Granularity[timePeriod.type]}`;

/**
 * Returns the current time in hours, minutes format.
 *
 * @param offset The offset by which the current time should be offsetted.
 * @returns The current time.
 */
export const getCurrentTime = (offset?: TimeOfDay): TimeOfDay => {
  let currentTime = dayjs((Date as any).clusterNow());

  if (offset) {
    currentTime = currentTime.subtract(offset);
  }

  return {
    hour: currentTime.hour(),
    minute: currentTime.minute(),
    timeZone: userTimezone,
  };
};

/**
 * Convert the time (TimeofDay) to msecs.
 *
 * @param time The time to be converted
 * @returns The msec value.
 */
export const convertTimeOfDayToMsecs = (time: TimeOfDay) => (time.hour * 60 + time.minute) * 6000;

/**
 * Splits the current time value in greatest best fit and remainder value.
 * - I/O is in msecs.
 * For e.g. 234 weeks(in msecs) will be split into quot - 4years(in msecs), remain - 26weeks(in msecs).
 *
 * @param val the value to be looked at in msecs.
 * @returns Array of two values in msecs.
 */
export const splitTimeValue = (val: number): [number, number] => {
  let quot = val;
  let rem = 0;

  Object.keys(DayToMsecs).filter(unit => !toNumber(unit)).reverse().find((unit) => {
    const divide = val / DayToMsecs[unit];

    if (divide === 0) {
      quot = 0;
      return true;
    }

    if (divide >= 1) {
      rem = val % DayToMsecs[unit];
      quot = val - rem;
      return true;
    }

    return false;
  });

  return [quot, rem];
};

/**
 * Determines if a given date is Today. Handles milliseconds.
 *
 * @param timestampMsecs The timestamp for the date.
 * @returns Return true if date is todays else false.
 */
export const isTodayDateMsecs = (timestampMsecs: number): boolean => {
  const startMsecs = dayjs().startOf('day').valueOf();
  const endMsecs = dayjs().endOf('day').valueOf();

  return startMsecs <= timestampMsecs && timestampMsecs <= endMsecs;
};
