import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: VmdkRecoveryService

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.vmdkRecovery')
    .service('VmdkRecoveryService', VmdkRecoveryServiceFn);

  function VmdkRecoveryServiceFn(_, $rootScope, $q, $state, DateTimeService,
    SearchService, ViewBoxService, SourceService, JobService, RestoreService,
    SlideModalService, evalAJAX, ENUM_ARCHIVAL_TARGET) {

    var vmdkRecoveryService = {
      addToCart: addToCart,
      fetchDependencies: fetchDependencies,
      getOsType: getOsType,
      getRecoverStateNames: getRecoverStateNames,
      getSharedState: getSharedState,
      getVirtualDisksInfo: getVirtualDisksInfo,
      initRecoverTask: initRecoverTask,
      initSearchConfig: initSearchConfig,
      selectRestorePoint: selectRestorePoint,
      selectTarget: selectTarget,
      startFlowOver: startFlowOver,
      submitTask: submitTask,
      updateTaskWithEntity: updateTaskWithEntity,
    };

    var defaultRestoreTaskState = {

      // @type       {string} The task name
      name: undefined,

      // @type       {array} The object(s) to recover Virtual Disks from
      objects: [],

      // @type       {object} Virtual Disk params
      recoverVirtualDiskParams: {

        // @type       {boolean} Power off the VM before recovery is started
        powerOffVmBeforeRecovery: false,

        // @type       {boolean} Power on the VM after recovery is completed
        powerOnVmAfterRecovery: false,

        // @type       {object} The chosen target Entity
        targetEntity: undefined,

        // @type       {object} Virtual Disk mapping params
        virtualDiskMappings: {

          // @type       {object} Source SCSI params
          srcDisk: undefined,

          // @type       {object} SCSI params for disk to overwrite
          diskToOverwrite: undefined,

          // @type       {object} Target Data Store
          targetLocation: undefined,
        },
      },

      // @type    {object}  Includes vlanId and interfaceName
      restoreVlanParams: undefined,
    };

    /**
     * Default list of Filters for use in the cSearchFilters.
     *
     * @example
     *  {
     *    property: {String},
     *    display: {String|Fn=},
     *    primary: {Bool=},
     *    locked: {Bool=},
     *    transformFn: {Fn=},
     *    value: {Array|Integer|String|Bool=}
     *  }
     * @type       {Array}
     */
    var defaultFilterOptions = [{
      property: 'registeredSourceIds',
      display: $rootScope.text.sourceName,
      transformFn: _sourceIdFromName,
      locked: false
    }, {
      property: 'viewBoxIds',
      display: $rootScope.text.viewBox,
      transformFn: _viewBoxIdFromName,
      locked: false
    }, {
      property: 'jobIds',
      display: $rootScope.text.protectionJob,
      transformFn: _jobIdFromName,
      locked: false
    }, {
      property: 'fromTimeUsecs',
      display: $rootScope.text.startDate,
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'toTimeUsecs',
      display: $rootScope.text.endDate,
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'vmName',
      primary: true
    }];

    /**
    * Ordered list of state names in this recovery flow
    *
    * @type       {Array}
    */
    var recoverStateNames = [
      'recover',
      'recover-vmdk.search',
      'recover-vmdk.options',
    ];

    // This is shared object across all the steps in this recovery flow
    var sharedState = {
      task: {},

      // @type       {array} The object(s) to recover Virtual Disks from
      cart: [],

    };

    /**
     * Sets up the lookups skeleton needed for this flow
     *
     * @method     initFilterLookups
     */
    function initFilterLookups() {
      sharedState.filterLookups = sharedState.filterLookups || {
        viewBoxIds: [],
        registeredSourceIds: [],
        jobIds: []
      };
    }

    /**
     * Fetch dependencies from the server. Mostly for filter lookups.
     *
     * @method     fetchDependencies
     */
    function fetchDependencies() {
      var promises = {
        // viewBoxes
        viewBoxes: ViewBoxService.getOwnViewBoxes(),

        // ParentSources
        parentSources: SourceService.getEntitiesOfType({
          environmentTypes: ['kVMware'],
          vmwareEntityTypes: ['kVCenter'],
        }),

        // All VM jobs - VM(1)
        jobs: JobService.getJobs({envTypes: [1]}),
      };

      return $q.all(promises)
        .then(function allFetched(resp) {
          initFilterLookups();
          if (Array.isArray(resp.viewBoxes)) {
            sharedState.filterLookups.viewBoxIds = resp.viewBoxes;
          }
          if (Array.isArray(resp.parentSources)) {
            sharedState.filterLookups.registeredSourceIds = resp.parentSources;
          }
          if (Array.isArray(resp.jobs)) {
            sharedState.filterLookups.jobIds = resp.jobs.map(
              function augmentJobsFn(job) {
                return assign(job, {
                  jobName: job.name,
                });
              }
            );
          }
        });
    }

    /**
     * Initializes recover task with defaults
     *
     * @method     initRecoverTask
     */
    function initRecoverTask() {
      return sharedState.task = angular.merge({}, defaultRestoreTaskState, {
        name: RestoreService.getDefaultTaskName('recover','vmdk')
      });
    }

    /**
     * Update the task with the given Entity and any other task defaults
     * that are entity related.
     *
     * @method     updateTaskWithEntity
     * @param      {Object}  entity  The Entity
     */
    function updateTaskWithEntity(entity) {
      sharedState.task.objects = [entity.vmDocument.objectId];

      // If the new entity is a different type than any previously
      // selected targetEntity, clear targetEntity & restoreParentSource
      // params out. They have to be the same type.
      if (get(sharedState.task,
        'recoverVirtualDiskParams.targetEntity.type') !==
          entity.vmDocument.objectId.entity.type) {
        angular.extend(sharedState.task, {
          recoverVirtualDiskParams:
            angular.copy(defaultRestoreTaskState.recoverVirtualDiskParams),
        });
      }
    }

    /**
     * Gets the recover state names.
     *
     * @method   getRecoverStateNames
     * @return   {string}   The recover state names.
     */
    function getRecoverStateNames() {
      return recoverStateNames.slice(0);
    }

    /**
     * Gets the shared state.
     *
     * @method   getSharedState
     * @return   {object}   The shared state.
     */
    function getSharedState() {
      return sharedState;
    }

    /**
     * Init the shared scope object.
     *
     * @method     initSearchConfig
     */
    function initSearchConfig() {
      assign(sharedState, {
        searchId: 'vmdk-search',
        results: [],
        pagedResults: [],
        filters: angular.copy(defaultFilterOptions),
        selectedResults: [],
        endpoint: SearchService.getSearchUrl('vmdk')
      });
    }

    /**
     * When an entity is selected from the search, it is added to cart. The
     * shared state will also be updated with this entity. It is then redirected
     * to the options page
     *
     * @method   addToCart
     * @param    {Object}   entity   The entity
     */
    function addToCart(entity) {
      var firstVersion;

      firstVersion = entity.vmDocument.versions[0];

      angular.extend(entity, {
        // Default the selectedSnapshot to the first in the list
        _selectedSnapshot: firstVersion,

        // If the first replicaVec isn't a local target (we don't send
        // that), select the first by default. Unsupported Replicaiton
        // (type 2) targets have already been filtered out at this point.
        _archiveTarget: (get(firstVersion,
          'replicaInfo.replicaVec[0].target.type') !== 1) ?

          // It's not local, use it
          firstVersion.replicaInfo.replicaVec[0] :

          // It's local, don't set it
          undefined
      });

      // Determine which source type for an accurate task name.
      sharedState.task.name =
        RestoreService.getDefaultTaskName('recover', 'vmdk');

      sharedState.cart = [entity];

      updateTaskWithEntity(entity);

      $state.go(recoverStateNames[2], {
        entity: entity.vmDocument.objectId.entity,
        host: entity.registeredSource,
        jobId: entity.vmDocument.objectId.jobId
      }, false);
    }

    /**
     * Gets the virtual disk information by sending params via GET call.
     *
     * @method   getVirtualDisksInfo
     * @param    {Object}   entity   The entity
     * @return   {Object}   The virtual disks information.
     */
    function getVirtualDisksInfo(entity) {
      var archivalTargetParam = entity._archiveTarget ? entity._archiveTarget.target.archivalTarget : {};
      var params = {
        clusterId: entity._jobUid.clusterId,
        clusterIncarnationId: entity._jobUid.clusterIncarnationId,
        jobId: entity._jobUid.objectId,
        jobRunId: entity._snapshot.instanceId.jobInstanceId,
        sourceId: entity._id,
        startTimeUsecs: entity._snapshot.instanceId.jobStartTimeUsecs,
        vaultId: archivalTargetParam.vaultId,
        vaultName: archivalTargetParam.name,
        vaultType: ENUM_ARCHIVAL_TARGET[archivalTargetParam.type],
      };

      return RestoreService.getVirtualDiskInfo(params)
        .then(function getInfoSuccess(data) {
          return data;
        }, evalAJAX.errorMessage);
    }

    /**
     * Transform a single, or an array of Source names to Source IDs
     *
     * @method     _sourceIdFromName
     * @param      {object|array}  names   Array Source names
     * @return     {array}         Array of Source IDs
     */
    function _sourceIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return sharedState.filterLookups.registeredSourceIds
          .reduce(function matchSources(sources, source) {
            if (names.includes(source.entity.vmwareEntity.name)) {
              sources.push(source.entity.id);
            }
            return sources;
          }, []);
      }
      return out;
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     _viewBoxIdFromName
     * @param      {object|array}  viewBoxes  The viewBox names to get the
     *                                        IDs for
     * @return     {array}         The viewBox ids
     */
    function _viewBoxIdFromName(viewBoxes) {
      var out = [];
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        return sharedState.filterLookups.viewBoxIds
          .reduce(function matchViewboxes(boxes, vb) {
            if (viewBoxes.includes(vb.name)) {
              boxes.push(vb.id);
            }
            return boxes;
          }, []);
      }
      return out;
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     _jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function _jobIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return sharedState.filterLookups.jobIds
          .reduce(function matchJobs(jobs, job) {
            if (names.includes(job.jobName)) {
              jobs.push(job.jobId);
            }
            return jobs;
          }, []);
      }
      return out;
    }

    /**
     * Shared function to start the flow over and replaces the browser history
     *
     * @method     startFlowOver
     */
    function startFlowOver() {
      $state.go(recoverStateNames[1], undefined, {
        location: 'replace'
      });
    }

    /**
     * Selects a restore point and updates the task with the new restore point.
     *
     * @method   selectRestorePoint
     * @param    {Object}   row   The restore point selected by user
     */
    function selectRestorePoint(row) {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/common/snapshot-selector/common.snapshot-selector.html',
        controller: 'commonRestoreSnapshotModalController',
        size: 'lg',
        resolve: {
          task: cloneDeep(sharedState.task),
          entity: row,
        }
      };

      return SlideModalService.newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {

          angular.extend(row, {
            _snapshot: resp.snapshot,
            _archiveTarget: resp.archiveTarget,
          });

          _updateTaskWithSnapshot(row._snapshot);
          _updateTaskWithArchiveTarget(row._archiveTarget);
        });
    }

    /**
     * Update the task with the given snapshot
     *
     * @method     _updateTaskWithSnapshot
     * @param      {object}  snapshot  The entity snapshot
     */
    function _updateTaskWithSnapshot(snapshot) {
      var instance = get(snapshot, 'instanceId');
      if (instance) {
        assign(sharedState.task.objects[0], {
          jobInstanceId: instance.jobInstanceId,
          startTimeUsecs: instance.jobStartTimeUsecs,
        });
      }
    }

    /**
     * Update the task with the given archiveTarget
     *
     * @method     _updateTaskWithArchiveTarget
     * @param      {object}  target  The archiveTarget
     */
    function _updateTaskWithArchiveTarget(archiveTarget) {
      assign(sharedState.task.objects[0], {
        archivalTarget: get(archiveTarget, 'target.archivalTarget'),
      });
    }

    /**
     * When a target entity is selected open vm-browser modal
     *
     * @method   selectTarget
     * @return   {Object}   Promise object
     */
    function selectTarget() {
      var envType = sharedState.task.objects[0].entity.type;
      var hostType;
      var filters = {
        // Exclude ESXi Hosts
        excludeTypes: [10],
        requireVmTools: false,
        singleSelect: true,
      };

      // Open modal with type restrictions
      return SourceService.browseForLeafEntities(envType, hostType, filters)
        .then(_updateTaskWithTargetEntity);
    }

    /**
     * when updating the target entity using the source browser modal, set the
     * new target on the task
     *
     * @method     _updateTaskWithTargetEntity
     * @param      {Object}    target    Object containing target entity info
     */
    function _updateTaskWithTargetEntity(target) {
      assign(sharedState.task.recoverVirtualDiskParams, {
        targetEntity: target,
      });

      sharedState.task.restoreParentSource = { id: target.parentId };
    }

    /**
     * Gets the OS type.
     *
     * @method     getOStype
     * @param      {object}  entity  The entity
     * @return     {string}  The OS type string
     */
    function getOsType(entity) {
      return entity.vmDocument.osType || 'unknown';
    }

    /**
     * Generates the virtual disk mappings needed by the restore API from the
     * form data we have on virtual disk selections.
     *
     * @method   _generateVirtualDiskMappings
     * @param    {Array}   virtualDisks   The virtual disks data
     * @return   {Array}   Virtual Disk mappings as needed by API
     */
    function _generateVirtualDiskMappings(virtualDisks) {
      return virtualDisks.reduce(
        function updateTaskWithVirtualDisks(mappings, virtualDisk) {
          if (virtualDisk._isSelected) {
            var mapping = {
              srcDisk: {
                diskId: virtualDisk.diskId,
                unitNumber: virtualDisk.unitNumber,
                controllerBusNumber: virtualDisk.busNumber,
                controllerType: virtualDisk.controllerType,
              },
              targetLocation: {
                id: virtualDisk.selectedDatastore.id,
              }
            };

            if (virtualDisk._selectedRecoveryType.enum === 'kOverwrite') {
              mapping.diskToOverwrite = mapping.srcDisk;
            }
            mappings.push(mapping);
          }
          return mappings;
        }, []);
    }

    /**
     * Submits the final recovery task for restoring Virtual Disks.
     *
     * @method   submitTask
     * @param    {Object}   form           The form object
     * @param    {Array}    virtualDisks   The virtual disks
     * @param    {Object}   selectedVlanTarget  The selected network interface
     * @return   {Object}   Promise Object
     */
    function submitTask(form, virtualDisks, selectedVlanTarget) {
      if (!form || form.$invalid) {
        return false;
      }

      sharedState.task.recoverVirtualDiskParams.virtualDiskMappings =
        _generateVirtualDiskMappings(virtualDisks);

      sharedState.task.restoreVlanParams =
        RestoreService.getVlanParams(selectedVlanTarget);

      sharedState.task.continueRestoreOnError = false;
      sharedState.isSubmitting = true;

      return RestoreService.restoreVM(sharedState.task)
        .then(function taskAcceptedFn(restoreTask) {
          var taskId = restoreTask.performRestoreTaskState.base.taskId;

          // Navigate to task detail page
          if (restoreTask.performRestoreTaskState.objects[0].archivalTarget) {
            $state.go('recover-detail-archive', { id: taskId });
          } else {
            $state.go('recover-detail-local', { id: taskId });
          }
        }, evalAJAX.errorMessage)
        .finally(function taskSubmittedFn() {
          sharedState.isSubmitting = false;
        });
    }

    return vmdkRecoveryService;
  }
})(angular);
