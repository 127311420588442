import { each } from 'lodash-es';
import { orderBy } from 'lodash-es';
import { isString } from 'lodash-es';
import { map } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Deploy Cloud Edition to Azure

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('deployAzureCloudEdition', {
      templateUrl: 'app/platform/cloud-edition-manager/azure/deploy-azure.html',
      controller: 'deployAzureCtrl',
    })
    .controller('deployAzureCtrl', deployAzureCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:deployAzureCloudEdition
   * @function
   *
   * @description
   * Deploy a new Cohesity DataPlatform for cloud on Azure
   */
  function deployAzureCtrlFn(_, $rootScope, $state, evalAJAX,
    CloudEditionManagerService, FORMATS) {

    var $ctrl = this;
    var newAzureDeployOptions = {
      accessCredentials: {
        applicationId: '',
        applicationKey: '',
        subscriptionId: '',
        tenantId: '',
      },
      cloudType: 'azure',
      deploymentConfig: {
        availabilityZone:'',
        clusterName: '',
        dnsServer: ['8.8.8.8'],
        domainName: ['eng.cohesity.com'],
        enableClusterEncryption: true,
        faultTolerance: 1,
        instanceType: '',
        numInstances: 3,
        ntpServer: ['time.nist.gov', 'pool.ntp.org'],
        regionId: '',
        regionName: '',
        subnetName: '',
        computeResourceGroupName: '',
        storageResourceGroupName: '',
        securityGroup: undefined,
        virtualNetwork: '',
      },
      jobName: '',
      email: [$rootScope.user.username],
   };

    assign($ctrl, {
      // properties
      currentStep: 1,
      deployTarget: {},
      FORMATS: FORMATS,
      steps: [{
        titleKey: 'azureCredentials',
      }, {
        titleKey: 'cloudEdition.cloudEnvironmentDetails',
      }, {
        titleKey: 'cloudEdition.dataPlatformForCloudDetails',
      }],
      regions: [],
      submitButtonText: 'verify',
      tags: [],

      // methods
      $onInit: onInit,
      back: back,
      cancel: cancel,
      processForm: processForm,
      selectRegion: selectRegion,
      selectSecurityGroup: selectSecurityGroup,
      selectVirtualNetwork: selectVirtualNetwork,
      showPolicyModal: CloudEditionManagerService.showPolicyModal,
    });

    /**
     * Activate the controller
     *
     * @method   onInit
     */
    function onInit() {
      CloudEditionManagerService.getCENodeTypes('azure')
        .then(function cePromisesReslved(resp) {
          $ctrl.nodeTypes = resp;
        }, evalAJAX.errorMessage);

      assign($ctrl.deployTarget, newAzureDeployOptions);

      CloudEditionManagerService.showChecklistModal('azure');
    }

    /**
     * Callback when a region is selected.
     * Shortlist the resourceGroups, virtualNetwork etc based on selected region.
     *
     * @method selectRegion
     * @param  {Object}  region  The selected region object
     */
    function selectRegion(region) {
      $ctrl.deployTarget.deploymentConfig.regionName = region.displayName;
      $ctrl.computeResourceGroups =
        map(region.resourceGroups, function mapResourceGroups(grp) {
          return {
            resourceGroupId: grp,
            _displayName: grp
          };
        });
      $ctrl.storageResourceGroups = $ctrl.computeResourceGroups;
      $ctrl.securityGroups =
        region.securityGroups.map(function eachSG(value) {
          return {
            securityGroup: value,
            _displayName: value.securityGroupId,
          };
        });
      $ctrl.virtualNetworks = region.virtualNetworks;
    }

    /**
     * Callback when a virtualNetwork is selected.
     * Shortlist the subnet based on selected virtual network.
     *
     * @method selectVirtualNetwork
     * @param  {Object}  virtualNetwork  The selected virtualNetwork object
     */
    function selectVirtualNetwork(virtualNetwork) {
      $ctrl.subnets = [];

      each(virtualNetwork.subnets, function eachSubnet(value, key) {
        $ctrl.subnets.push({
          subnet: key,
          _displayName: key + ' | ' + value,
        });
      });
    }

    /**
     * Submit the form to the server
     *
     * @method  processForm
     * @param   {object}  form   The form object to process
     */
    function processForm(form) {
      if (!form.$valid) {
        return false;
      }

      var validationData = {};
      var ssdv2Data = {};

      switch ($ctrl.currentStep) {

        // Step 1 (verification)
        case 1:
          if (!$ctrl.verified) {
            $ctrl.submitting = true;

            validationData.accessCredentials =
              $ctrl.deployTarget.accessCredentials;
            validationData.cloudType = $ctrl.deployTarget.cloudType;

            // make service call to verify
            CloudEditionManagerService.validateCloudCredentials(validationData)
              .then(function gotRegionDetails(resp) {
                $ctrl.verified = true;
                $ctrl.submitButtonText = 'continue';
                $ctrl.currentStep = 2;

                each(resp, function eachRegion(value, key) {
                  $ctrl.regions.push(assign(value, {
                    regionId: key,
                  }));
                });

                // Alpha Sort
                $ctrl.regions =
                  orderBy($ctrl.regions, ['displayName'], ['asc']);

                // reset the form validations for submit
                form.$setPristine();
              }, evalAJAX.errorMessage)
              .finally(function getAWSRegionsDetailsFinnally() {
                $ctrl.submitting = false;
              });

              // Set payload details for describe call to get zone details
              ssdv2Data.accessCredentials = $ctrl.deployTarget.accessCredentials;
              ssdv2Data.cloudType = $ctrl.deployTarget.cloudType;
              ssdv2Data.requestType = 'ssdv2_zones';
              $ctrl.zones = [];

              CloudEditionManagerService.getZoneDetailsForAzureNGCE(ssdv2Data)
                .then(function gotZoneDetails(resp) {
                  each(resp, function eachRegion(value, key) {
                    $ctrl.zones.push(assign(value, {
                      regionId: key,
                    }));
                  });

                  each($ctrl.zones, function eachZone(zone) {
                    each($ctrl.regions, function eachRegion(region) {
                      if (zone.regionId === region.regionId) {
                        region.zones = zone.zones;
                      }
                    });
                  });
              });
          // if verification is already done once, go directly to next step
          } else {
            $ctrl.currentStep = 2;
            form.$setPristine();
          }

          break;

        // Step 2
        case 2:
          $ctrl.currentStep = 3;
          $ctrl.submitButtonText = 'deploy';

          // reset the form validations for submit
          form.$setPristine();
          break;

        // Step 3 (submission)
        case 3:
          $ctrl.submitting = true;

          $ctrl.deployTarget.deploymentConfig.instanceName =
            $ctrl.deployTarget.deploymentConfig.instanceType;

          CloudEditionManagerService
            .deployCloudEdition($ctrl.deployTarget, $ctrl.tags)
            .catch(function failedCECreate(resp) {
              evalAJAX.errorMessage(resp);
              $ctrl.submitting = false;
            });

          break;
      }
    }

    /**
     * Callback when a security Group is selected.
     *
     * @method selectSecurityGroup
     * @param  {Object}  securityGroup  The selected securityGroup object
     */
    function selectSecurityGroup(securityGroup) {
      // "new" SG is created
      if (isString(securityGroup.securityGroup)) {
        securityGroup.securityGroup = null
      }
    }

    /**
     * Go back one step in the form flow.
     *
     * @method     back
     */
    function back() {
      $ctrl.currentStep--;
      $ctrl.submitButtonText = 'next';
    }

    /**
     * Cancel this flow. Go back to cloud editions list view.
     *
     * @method     cancel
     */
    function cancel() {
      $state.go('cloud-edition-manager');
    }
  }

})(angular);