/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CohesityV2ApiConfiguration, CohesityV2ApiConfigurationInterface } from './cohesity-v2api-configuration';

import { AccessTokenService } from './services/access-token.service';
import { ActiveDirectoryService } from './services/active-directory.service';
import { AlertService } from './services/alert.service';
import { AntivirusServiceService } from './services/antivirus-service.service';
import { UserService } from './services/user.service';
import { AuditLogService } from './services/audit-log.service';
import { RegistrationService } from './services/registration.service';
import { HeliosBackupService } from './services/helios-backup.service';
import { StorageDomainService } from './services/storage-domain.service';
import { PlatformService } from './services/platform.service';
import { SecurityService } from './services/security.service';
import { TenantService } from './services/tenant.service';
import { ExternalConnectionService } from './services/external-connection.service';
import { ConnectorsService } from './services/connectors.service';
import { DataAccessorService } from './services/data-accessor.service';
import { AgentService } from './services/agent.service';
import { ExternalTargetService } from './services/external-target.service';
import { FailoverService } from './services/failover.service';
import { ObjectService } from './services/object.service';
import { PolicyService } from './services/policy.service';
import { ProtectedObjectService } from './services/protected-object.service';
import { ProtectionGroupService } from './services/protection-group.service';
import { RecoveryService } from './services/recovery.service';
import { CloudRetrieveTaskService } from './services/cloud-retrieve-task.service';
import { SearchService } from './services/search.service';
import { SourceService } from './services/source.service';
import { TemplatesService } from './services/templates.service';
import { DataSourceConnectionService } from './services/data-source-connection.service';
import { DataSourceConnectorLocalService } from './services/data-source-connector-local.service';
import { DataSourceConnectorService } from './services/data-source-connector.service';
import { DataTieringService } from './services/data-tiering.service';
import { DMaaSTenantCertificateService } from './services/dmaa-stenant-certificate.service';
import { MFAService } from './services/mfa.service';
import { ViewService } from './services/view.service';
import { FleetInstanceService } from './services/fleet-instance.service';
import { FortknoxOnpremService } from './services/fortknox-onprem.service';
import { HeliosOnPremService } from './services/helios-on-prem.service';
import { OneHeliosService } from './services/one-helios.service';
import { IdentityProviderService } from './services/identity-provider.service';
import { IndexingCloudConfigService } from './services/indexing-cloud-config.service';
import { KerberosProviderService } from './services/kerberos-provider.service';
import { KeystoneService } from './services/keystone.service';
import { KeyManagementSystemService } from './services/key-management-system.service';
import { LDAPService } from './services/ldap.service';
import { MarketplaceAppService } from './services/marketplace-app.service';
import { HeliosAccountsService } from './services/helios-accounts.service';
import { AwsRegistrationService } from './services/aws-registration.service';
import { HeliosNotificationsService } from './services/helios-notifications.service';
import { HeliosClaimService } from './services/helios-claim.service';
import { ClusterRegistrationService } from './services/cluster-registration.service';
import { ClusterManagementService } from './services/cluster-management.service';
import { SoftwareManagementService } from './services/software-management.service';
import { CustomizeUIService } from './services/customize-ui.service';
import { CopyStatsService } from './services/copy-stats.service';
import { HeliosDataProtectStatsService } from './services/helios-data-protect-stats.service';
import { DSPMService } from './services/dspm.service';
import { RpaasService } from './services/rpaas.service';
import { FortknoxService } from './services/fortknox.service';
import { FortKnoxService } from './services/fort-knox.service';
import { PrivilegeService } from './services/privilege.service';
import { HeliosIdentityProviderService } from './services/helios-identity-provider.service';
import { HeliosLoginConfigurationService } from './services/helios-login-configuration.service';
import { HeliosPrincipalsService } from './services/helios-principals.service';
import { SessionManagementService } from './services/session-management.service';
import { HeliosSignupService } from './services/helios-signup.service';
import { CertificateService } from './services/certificate.service';
import { HeliosStatsService } from './services/helios-stats.service';
import { StatsService } from './services/stats.service';
import { TaggingServiceService } from './services/tagging-service.service';
import { UserPreferencesService } from './services/user-preferences.service';
import { FirewallService } from './services/firewall.service';
import { IPsService } from './services/ips.service';
import { RoutesService } from './services/routes.service';
import { NetworkResetService } from './services/network-reset.service';
import { NetworkInformationServiceService } from './services/network-information-service.service';
import { NodeGroupService } from './services/node-group.service';
import { RemoteClustersService } from './services/remote-clusters.service';
import { PatchManagementService } from './services/patch-management.service';
import { BaseosPatchManagementService } from './services/baseos-patch-management.service';
import { InternalService } from './services/internal.service';
import { RemoteStorageService } from './services/remote-storage.service';
import { RoleService } from './services/role.service';
import { TasksService } from './services/tasks.service';
import { RunbooksService } from './services/runbooks.service';
import { ObjectAuthServiceService } from './services/object-auth-service.service';
import { SecurityIntegrationService } from './services/security-integration.service';
import { SupportService } from './services/support.service';
import { SyslogService } from './services/syslog.service';
import { TagService } from './services/tag.service';
import { TestDataManagementService } from './services/test-data-management.service';
import { UdaConnectorConfigService } from './services/uda-connector-config.service';

/**
 * Provider for all CohesityV2Api services, plus CohesityV2ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CohesityV2ApiConfiguration,
    AccessTokenService,
    ActiveDirectoryService,
    AlertService,
    AntivirusServiceService,
    UserService,
    AuditLogService,
    RegistrationService,
    HeliosBackupService,
    StorageDomainService,
    PlatformService,
    SecurityService,
    TenantService,
    ExternalConnectionService,
    ConnectorsService,
    DataAccessorService,
    AgentService,
    ExternalTargetService,
    FailoverService,
    ObjectService,
    PolicyService,
    ProtectedObjectService,
    ProtectionGroupService,
    RecoveryService,
    CloudRetrieveTaskService,
    SearchService,
    SourceService,
    TemplatesService,
    DataSourceConnectionService,
    DataSourceConnectorLocalService,
    DataSourceConnectorService,
    DataTieringService,
    DMaaSTenantCertificateService,
    MFAService,
    ViewService,
    FleetInstanceService,
    FortknoxOnpremService,
    HeliosOnPremService,
    OneHeliosService,
    IdentityProviderService,
    IndexingCloudConfigService,
    KerberosProviderService,
    KeystoneService,
    KeyManagementSystemService,
    LDAPService,
    MarketplaceAppService,
    HeliosAccountsService,
    AwsRegistrationService,
    HeliosNotificationsService,
    HeliosClaimService,
    ClusterRegistrationService,
    ClusterManagementService,
    SoftwareManagementService,
    CustomizeUIService,
    CopyStatsService,
    HeliosDataProtectStatsService,
    DSPMService,
    RpaasService,
    FortknoxService,
    FortKnoxService,
    PrivilegeService,
    HeliosIdentityProviderService,
    HeliosLoginConfigurationService,
    HeliosPrincipalsService,
    SessionManagementService,
    HeliosSignupService,
    CertificateService,
    HeliosStatsService,
    StatsService,
    TaggingServiceService,
    UserPreferencesService,
    FirewallService,
    IPsService,
    RoutesService,
    NetworkResetService,
    NetworkInformationServiceService,
    NodeGroupService,
    RemoteClustersService,
    PatchManagementService,
    BaseosPatchManagementService,
    InternalService,
    RemoteStorageService,
    RoleService,
    TasksService,
    RunbooksService,
    ObjectAuthServiceService,
    SecurityIntegrationService,
    SupportService,
    SyslogService,
    TagService,
    TestDataManagementService,
    UdaConnectorConfigService
  ],
})
export class CohesityV2ApiModule {
  static forRoot(customParams: CohesityV2ApiConfigurationInterface): ModuleWithProviders<CohesityV2ApiModule> {
    return {
      ngModule: CohesityV2ApiModule,
      providers: [
        {
          provide: CohesityV2ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
