import { assign } from 'lodash-es';
// Component: Apps

import { isAllClustersScope } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .component('apps', {
      templateUrl: 'app/apps-management/apps/apps.html',
      controller: appsCtrlFn,
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:apps
   * @function appsCtrlFn
   *
   * @description
   * Provides component which manages the apps.
   *
   * @example
     <apps></apps>
   */
  function appsCtrlFn(_, $rootScope, AppsService, ngDialogService, evalAJAX, cMessage, $q, $log,
    AppStoreService, PollTaskStatus, FEATURE_FLAGS, $state, NgIrisContextService) {
    let $ctrl = this;

    let pollIntervalSec = 15;
    let isDoneDeferred = $q.defer();

    assign($ctrl, {
      // data
      allClusters: isAllClustersScope(NgIrisContextService.irisContext),
      apps: [],
      loadingApps: false,
      firstFetch: true,

      // Methods
      runApp: runApp,
      uploadAppPackage: uploadAppPackage,

      // Component lifecycle methods
      $onInit: $onInit,
      $onDestroy: $onDestroy
    });

    /**
     * Initialize and fetch data
     *
     * @method   $onInit
     */
    function $onInit() {
      if(FEATURE_FLAGS.appsManagementPollingEnabled) {
        $log.info('Fetching apps with polling enabled.');
        PollTaskStatus.createPoller({
          interval: pollIntervalSec,
          isDonePromise: isDoneDeferred.promise,
          iteratorFn: _fetchApps,
        });
      } else {
        _fetchApps();
      }
    }

    /**
     * Do cleanup on component destroy
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      isDoneDeferred.resolve();
    }

    /**
     * Show upload modal dialog
     *
     * @method   uploadAppPackage
     */
    function uploadAppPackage() {
      AppsService.launchUploadAppModal().then(
        function success() {
          _fetchApps();
        });
    }

    /**
     * Fetches the list of apps
     *
     * @method   _fetchApps
     */
    function _fetchApps() {
      $ctrl.loadingApps = $ctrl.firstFetch;

      if ($state.params.upgradeApp && $ctrl.allClusters) {
        AppStoreService.getAppDetails($state.params.id).then(
          function getAppData(app) {
            // Set this property to true in order to prevent modal dismiss on state
            // change within modal.
            app._persistOnStateChanges = true;

            // Setting upgradeApp property as null in order to avoid opening the modal
            // on state reload
            $state.go('.',
              {
                id: null,
                upgradeApp: null,
              }
            );
            return AppsService.launchUpgradeAppModal(app);
          }
        )
      }

      return AppsService.fetchAppsWithInstances()
        .then(function fetchAppsSuccess(appList) {
          $ctrl.apps = _addContextMenus(appList);
        }, evalAJAX.errorMessage)
        .finally(function fetchAppsDone() {
          if ($state.params.cid) {
            $state.go('.', {cid: null});
          }

          $ctrl.loadingApps = false;
          $ctrl.firstFetch = false;
        });
    }

    /**
     * Initiates app installation
     *
     * @method   _installApp
     * @param    {object}   app   app to install
     */
    function _installApp(app) {
      AppsService.installApp(app.appId, app.version)
        .then(function installAppSuccess(resp) {
          cMessage.success({
            titleKey: 'apps.installSuccessTitle',
            textKey: 'apps.installSuccessDetail',
            textKeyContext: {name: app.metadata.name},
          });

          _fetchApps();

        }, evalAJAX.errorMessage);
    }

    /**
     * Initiates app uninstall
     *
     * @method   _uninstallApp
     * @param    {object}   app   app to uninstall
     */
    function _uninstallApp(app) {
      AppsService.launchConfirmUninstallModal(app).then(function Accepted() {
        AppsService.uninstallApp(app.appId, app.version)
          .then(function uninstallAppSuccess(resp) {
            cMessage.success({
              titleKey: 'apps.uninstallSuccessTitle',
              textKey: 'apps.uninstallSuccessDetail',
              textKeyContext: {name: app.metadata.name},
            });

            _fetchApps();

          }, evalAJAX.errorMessage);
      }, angular.noop);
    }

    /**
     * Initiate app run in all clusters view
     *
     * @method   runApp
     * @param    {string}   app app information
     */
    function runApp(app) {
      if ($ctrl.allClusters) {
        return AppsService.launchSelectClustersModal(app);
      }

      if (app._numActiveInstances > 0) {
        const options = {
          copy: 'apps.launch.message',
          confirmButtonLabel: 'ok',
          declineButtonLabel: 'cancel',
        };

        ngDialogService.simpleDialog(null, options).subscribe(
          response => {
            if (response) {
              return $state.go('apps-management-applaunch', {appUid: app.appId,
                version: app.version,
                appName:  app?.metadata?.name,
                devVersion: app?.metadata?.devVersion});
            }
          },
        );
      } else {
        return $state.go('apps-management-applaunch', {appUid: app.appId,
          version: app.version,
          appName: app?.metadata?.name,
          devVersion: app?.metadata?.devVersion});
      }
    }

    /**
     * Adds relevant context menus to app
     *
     * @method   _addContextMenus
     * @param    {object}   appList   list of apps
     * @return   {array}    list of apps
     */
    function _addContextMenus(appList) {
      var privs = $rootScope.user.privs;
      appList.forEach(function (app) {
        app._contextMenus = [];

        switch(true) {
        case (app.installState === 'kInstalled' && privs.APPS_MANAGEMENT):
          if ($ctrl.allClusters) {
            app._contextMenus.push({
                icon: 'icn-install',
                translateKey: 'apps.installApp.selectMoreClusters',
                action: function installAppOnMoreClusters() {
                  AppStoreService.launchInstallAppModal(app);
                },
              });
          }

          // Show upgrade option if upgrade is required
          if (app._numClusters > 0 && $ctrl.allClusters) {
            app._contextMenus.push({
              icon: 'icn-upgrade',
              translateKey: 'upgrade',
              action: function upgradeApp() {
                AppsService.launchUpgradeAppModal(app);
              },
            });
          }
          app._contextMenus.push({
            icon: 'icn-uninstall',
            translateKey: $ctrl.allClusters ? 'apps.uninstallFromCluster'
              : 'uninstallApp',
            action: function uninstallApp() {
              if ($ctrl.allClusters) {
                AppsService.launchUninstallAppModal(app);
                return;
              }
              _uninstallApp(app);
            },
          });
          break;

        /*
          Allowing to uninstall the App even if it has state - Failed
          Covering below failed statuses:
          1. kInstallFailed
          2. kDownloadFailed
          3. kUninstallFailed
        */
        case app.installState === 'kInstallFailed' && privs.APPS_MANAGEMENT:
        case app.installState === 'kDownloadFailed' && privs.APPS_MANAGEMENT:
        case app.installState === 'kUninstallFailed' && privs.APPS_MANAGEMENT:
          app._contextMenus.push({
            icon: 'icn-uninstall',
            translateKey: $ctrl.allClusters ? 'apps.uninstallFromCluster'
              : 'uninstallApp',
            action: function uninstallApp() {
              if ($ctrl.allClusters) {
                AppsService.launchUninstallAppModal(app);
                return;
              }
              _uninstallApp(app);
            },
          });
          break;

        case app.installState === 'kPurchased' && privs.APPS_MANAGEMENT:
          app._contextMenus.push({
              icon: 'icn-install',
              disabled: app._incompatible,
              translateKey: 'installApp',
              action: function installApp() {
                AppStoreService.launchInstallAppModal(app);
              },
            });
          break;

        case app.installState === 'kNotInstalled' && privs.APPS_MANAGEMENT:
          app._contextMenus.push({
              icon: 'icn-install',
              translateKey: 'installApp',
              disabled: app._incompatible,
              action: function installApp() {
                _installApp(app);
              },
            });
          break;
        }

        if ($ctrl.allClusters) {
          app._contextMenus.push({
            icon: 'icn-appstore',
            translateKey: 'showDetails',
            action: function viewAppDetails() {
              return $state.go('app-details',
                {id: app.appId, type: 'overview'});
            },
          });
        }
      });

      return appList;
    }
  }
})(angular);
