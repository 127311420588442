<div class="flex-row vertical-align" [cogDataId]="obj.id" [inheritDataId]="true">
  <cog-icon
    *ngIf="obj?.object?.environment"
    [size]="'md'"
    [shape]="objectTypeIcon"
    [matTooltip]="obj.object.environment | environmentType">
  </cog-icon>
  <div class="detail">
    <h5
      [inheritDataId]="true"
      [matTooltip]="obj.object.name"
      [ngClass]="{'auto-truncate user-data-lg' : autoTruncate}"
      class="object-name"
      cogDataId="name">
      {{ obj.object.name }}
    </h5>
    <ul class="flex-row c-ul-inline c-ul-dot-separator no-margin mat-caption">
      <li class="no-padding-right">
        <span class="item-label">{{ 'system' | translate }}:</span>
        <span [ngClass]="{'auto-truncate object-detail' : autoTruncate}"
          [matTooltip]="objectName"
          matTooltipPosition="below" cogDataId="system" [inheritDataId]="true">
          {{ objectName }}
        </span>
      </li>
      <li class="no-padding-right">
        <span class="item-label">{{ 'source' | translate }}:</span>
        <span
          [ngClass]="{'auto-truncate object-detail' : autoTruncate}"
          [matTooltip]="obj?.sourceInfo?.name"
          matTooltipPosition="below"
          cogDataId="source"
          [inheritDataId]="true">
          {{ obj?.sourceInfo?.name}}
        </span>
      </li>

      <ng-container *ngIf="clusterStatus$ | async as clusterStatus">
        <li *ngIf="showInstallConnectorLink && clusterStatus && !notStartedStatus.has(clusterStatus)">
          <a [irisRouterLink]="(getInstallAppLinkFn$ | async)?.()"
            [params]="getInstallAppLinkParamsFn()">
            {{ 'argus.installConnector' | translate }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <button
    mat-icon-button
    *ngIf="enableRemove"
    (click)="remove.emit(obj.id)"
    class="remove-button">
    <cog-icon shape="close"></cog-icon>
  </button>
</div>
