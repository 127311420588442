import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: sql recoveries log

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'SqlRecoveryLogController',
    templateUrl: 'app/global/c-widgets/sql/recovery-log/recovery-log.html',
    bindings: {
      data: '<',
    },
  };

  angular.module('C.widgets')
    .controller('SqlRecoveryLogController', sqlRecoveryLogCtrlFn)
    .component('wSqlRecoveryLog', componentConfig);

  /**
   * @ngdoc component
   * @name C.widgets.wSqlRecoveryLog
   *
   * @param   {Object}   data   arary of items like this:
   *                            {
   *                              params: object;
   *                              startTimeUsecs: number;
   *                              status: number;
   *                              type: string;
   *                              user: string;
   *                            }
   *
   * @description
   * Recovery log widget
   *
   * @example
   * <w-sql-recoveries recoveries="$ctrl.recoveries"></w-sql-recoveries>
   */
  function sqlRecoveryLogCtrlFn($translate) {
    var $ctrl = this;

    var displayKeys = {
      'kRecoverApp': 'restore.tasklog.recover',
      'kCloneApp': 'restore.tasklog.clone',
    };

    assign($ctrl, {
      $onChanges: $onChanges
    });

    /**
     * Converts the recovery log to a displayable format
     *
     * @method $onChanges
     * @param   {Object}   changes   simple changes object
     */
    function $onChanges(changes) {
      if (changes.data) {
        $ctrl.display =
          $ctrl.data ? _transformRecoveries($ctrl.data) : undefined;
      }
    }

    /**
     * Transform recovery tasks to a display friendly format
     *
     * @method _transformRecoveries
     * @param    {array}   tasks   array of tasks objects
     * @return   {array}   task objects formatted for the log list component
     */
    function _transformRecoveries(tasks) {
      return tasks.map(function toDisplayValue(task) {
        var taskDatabaseName = get(task, '_appEntity.sqlEntity.databaseName', '');

        var childTaskVec = get(task,
          '_envTaskParams.restoreAppTaskState.childRestoreAppParamsVec', []);

        // Extract the database names from the child task(s) and join them
        // using comma delimiter.
        if (!taskDatabaseName && childTaskVec && childTaskVec.length) {
          taskDatabaseName = childTaskVec.reduce(
            function concatDbName(dbName, childTask) {
              var name = get(childTask,
                'restoreAppObjectVec[0].appEntity.sqlEntity.databaseName');
              if (name) {
                dbName.push(name);
              }
              return dbName;
          }, []).join('\', \'');
        }
        return {
          timestampUsecs: task._startTimeUsecs,
          details: $translate.instant(displayKeys[task._restoreType], {
            user: task._user,
            name: taskDatabaseName,
          })
        };
      });
    }
  }

})(angular);
