import { get } from 'lodash-es';
// HTTP Interceptor Service
// Intercept http response errors

;(function(angular, undefined) {
  'use strict';

  angular.module('C')
    .service('authHttpResponseInterceptor', authHttpResponseInterceptorFn);

  function authHttpResponseInterceptorFn(_, $q, $injector) {

    return {
      responseError: function responseErrorFn(response) {

        var $state = $injector.get('$state');
        var NgRemoteClusterService = $injector.get('NgRemoteClusterService');
        var $rootScope;
        var UserService;
        var cMessage;
        var currentCluster;
        var _isRunningInHelios;

        const statesToIgnore = ['login', 'login-ng', 'helios-onprem-mfa', 'helios-onprem-mfa-verify'];
        if (response.status === 401 && !statesToIgnore.includes($state.current.name)) {
          UserService = $injector.get('UserService');
          $rootScope = $injector.get('$rootScope');

          _isRunningInHelios = !!get($rootScope.basicClusterInfo, 'mcmMode');
          currentCluster = NgRemoteClusterService.selectedScope;

          // If running in on-prem mode, logout user when the response
          // is unauthorized
          if (!_isRunningInHelios) {
            UserService.onUnAuthorizedApiAccess(UserService.logout);
          } else {
            // If the currentCluster is not selected, return to login.
            // This might happens if the user abort sign in.
            if (!currentCluster) {
              UserService.onUnAuthorizedApiAccess(UserService.logout);
              return;
            }

            // If you are running on Helios Mode and the current cluster is the
            // all cluster then we will inform the user that the session has
            // expired or we will show a message and revert to all cluster.
            cMessage = $injector.get('cMessage');

            // In Helios, If allCluster or SaaS service return 401,
            // we can definitely say that the user session has expired.
            // Display the error message and then logout the user.
            // In Helios as part of the Single Sign out experience, We need to
            // inform the user that we are logging out for a specific reason.
            // Or if it's a Helios GCP User, We receive a 401 we logout the
            // user.
            switch (true) {
              case currentCluster._allClusters:
              case !!currentCluster._cohesityService:
              case !!get($rootScope, 'user.googleAccount'):
                UserService.onUnAuthorizedApiAccess(handleSessionExpired);
                break;
              default:
                UserService.onUnAuthorizedApiAccess(handleSessionExpired);
                break;
            }
          }
        }

        return $q.reject(response);
      }
    };
  }

  /**
   * Inform and logout the user
   */
  function handleSessionExpired() {
    UserService.informAndLogOutUser('sessionExpired');
  }

})(angular);
