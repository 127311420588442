import { McmProtectionGroupArchivalRunActivityParams } from '@cohesity/api/v2';
import { TopologyGraphEdge, TopologyGraphNode } from '@cohesity/helix-yfiles';

import { FortknoxStorageClass } from '../constants';

/**
 * Possible style values for topology graph items.
 */
export type RpaasTopologyItemStyle =  'disconnected' | 'noActivity' | 'success' | 'warning' | 'error' | 'inProgress';

/**
 * Rpaas Graph Node. This can represent a cluster or a vault
 */
export interface RpaasTopologyGraphNode extends TopologyGraphNode {
  /**
   * The label of the cluster or vault
   */
  label: string;

  /**
   * Either the cluster id or the region id
   */
  id: string;

  /**
   * The type of node
   */
  type: 'cluster' | 'vault';

  /**
   * The style for the node
   */
  style: RpaasTopologyItemStyle;

  /**
   * A list of activities related to this node. Note that the API isn't written yet, so it is typed as any.
   */
  activities?: RpaasActivityStatSummary[];

  /**
   * The date range filiter that was used to look up these results.
   */
  dateRange: [number, number];
}

export interface RpaasTopologyGraphEdge extends TopologyGraphEdge {
  /**
   * The id of the source node
   */
  from: string;

  /**
   * The id of the target node
   */
  to: string;

  /**
   * A unique id for this edge. Usually derived from the from and to ids
   */
  id: string;

  /**
   * The style for this edge
   */
  style: RpaasTopologyItemStyle;

  /**
   * A list of activities related to this node. Note that the API isn't written yet, so it is typed as any.
   */
  activities?: RpaasActivityStatSummary[];
}

/**
 * An entire graph's definition
 */
export interface RpaasTopologyGraphData {
  /**
   * The list of nodes
   */
  nodes: RpaasTopologyGraphNode[];

  /**
   * The list of edges
   */
  edges: RpaasTopologyGraphEdge[];
}

/**
 * Map vault usage stats by storage class
 */
export type VaultUsageByStorageClass = { [storageClass in FortknoxStorageClass]?: VaultUsageData };

/**
 * Historical Vault Usage Data
 */
export interface VaultUsageData {
  /**
   * The usage value of the most recent data for this set of data
   */
  totalUsage: number;

  /**
   * Historical data for this set of data
   */
  usageData: {
    /**
     * Timestamp in usecs
     */
    timestampUsecs: number;

    /**
     * Capacity usage
     */
    usageBytes: number;
  }[];

  /**
   * percent change between the first and last data point in this set.
   */
  changePercent: number;
}

/**
 * Aggregated summary stats for rpaas activities, grouped by cluster, region, and status.
 */
export interface RpaasActivityStatSummary {
  /**
   * The cluster id for this stat info
   */
  clusterIdentifier: string;

  /**
   * The number of activities on this cluster and region with this status.
   */
  count: number;

  /**
   * The region/vault id for this stat info
   */
  regionId: string;

  /**
   * The status value for this stat info
   */
  status: McmProtectionGroupArchivalRunActivityParams['status'];
}

/**
 * Activity info that can be shown in a glancebar
 */
export interface NodeActivityStats {
  /**
   * Statlist label
   */
  label: string;

  /**
   * Statlist icon
   */
  icon: string;

  /**
   * Stat list value
   */
  value: number;

  /**
   * The status value represented by this item;
   */
  status: RpaasActivityStatSummary['status'];
}

/**
 * Config for defining the dashboard grid properties.
 */
export const gridConfig = {
  /**
   * Space b/w cards in px.
   */
  gutterSize: 16,

  /**
   * Dashboard grid cell height in px and Adjacent cells can be grouped to form bigger cards.
   */
  rowHeight: 155,

  /**
   * Cyber-Vault dashboard grid cell height in px.
   */
  cyberVaultingRowHeight: 170
};
