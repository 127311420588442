import { noop } from 'lodash-es';
import { merge } from 'lodash-es';
import { find } from 'lodash-es';
import { forEach } from 'lodash-es';
import { clone } from 'lodash-es';
import { concat } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Controller: cVMBrowserModal
// TODO(tauseef): Make this a component.

/**
 * Note: c-vm-browser is used for browsing file systems & views.
 *
 * Browse is supported through 4 flows:
 *
 *  -- VM Read dir op (Default)   - Local snapshot copy is mounted and browsed.
 *  -- View Read dir op           - Starts files/dir lookup in the given view
 *                                  directory.
 *  -- Librarian Read dir op      - Indexed data within Librarian is used to
 *                                  provide the Parent-Child hierarchy.
 *  -- OneDrive Read dir op       - Incase of browsing OneDrive on non-indexed
 *                                  data, RocksDB is looked up for the path &
 *                                  browse is served through the same for the
 *                                  children of the same.
 *
 * Any change to this directive must be tested for the following:
 *  -- File/Folder Recovery
 *      1. Entities with Volume
 *      2. Entities without Volume (ENV_GROUPS.indexableEntitiesExposedAsViews)
 *  -- Browse View (Create Share)
 *  -- Browse OneDrive Files & Folders
 *  -- Browse SharePoint Document Repository(Site OneDrive)
 *
 * c-vm-browser internally uses finder-tree.js which broadcasts an event
 * 'vm-browser-node-selected' handled here. Incase of any changes using click
 * traversal debugging should be done looking into finder-tree.js first and then
 * here.
 *
 * Incase of direct file traversal, tree view is generated within the function
 * traverseFSDirectly() so any changes here should be checked here first.
 */

(function(angular) {
  'use strict';

  // nodes can be of type: kDirectory, kFile, or volumes (see volumeTypes[])
  var volumeTypes = ['kSimpleVolume', 'kLVM', 'kLDM'];

  // specifies the types of files. It can either be a simple file('kFile') or it
  // can be a special file(symbolic link-'kSymlink')
  var fileTypes = ['kFile', 'kSymlink'];

  // Cached jQuerified window.document
  var $doc = angular.element(document);

  var defaultAclPermissions = [{
    // 'Everyone'
    sid: 'S-1-1-0',
    access: 'FullControl',
    mode: 'FolderSubFoldersAndFiles',
    type: 'Allow',
}];

  angular
    .module('C.VMBrowser', ['C.clearable'])
    .controller('cVMBrowserParentController', cVMBrowserParentController)
    .service('SharedVMBrowserService', sharedVMBrowserServiceFn);

  /**
   * @ngdoc  service
   * @name   C.VMBrowser.sharedVMBrowserServiceFn
   * @description
   *   Utility functions that manage selected Volumes, Directories, and Files
   *   within C.VMBrowser
   */
  function sharedVMBrowserServiceFn() {
    var service = {
      addSelectedVolume: addSelectedVolume,
      decorateVolume: decorateVolume,
      getSelectedVolumes: getSelectedVolumes,
      getSelectedNodes: getSelectedNodes,
      isVolSelected: isVolSelected,
      reconstructOriginalItem: reconstructOriginalItem,
      removeSelectedVolume: removeSelectedVolume,
      resetSelections: resetSelections,
      updateSelectedNodes: updateSelectedNodes,
      updateSelectedVolumes: updateSelectedVolumes,
    };

     /**
     * Configuration Array for selected Volumes
     * Stores volume names for easy parsing
     * @type {Array}
     */
    var selectedVolumes = [];

    /**
     * Configuration Array for selected Nodes
     * @type {Array}
     */
    var selectedNodes = [];

    /**
     * Evaluates if a volume is selected
     * @param  {Object}  vol
     * @return {Boolean} Volume is selected
     */
    function isVolSelected(vol) {
      if (!vol || !vol.name) {
        return false;
      }
      return selectedVolumes.includes(vol.name);
    }

    /**
     * Overwrites selectedNodes with values from $scope.cart
     * @param  {Array}  nodes
     */
    function updateSelectedNodes(nodes) {
      selectedNodes = nodes;
    }

    /**
     * Returns selectedVolumes
     * @return {Array} selectedVolumes
     */
    function getSelectedVolumes() {
      return selectedVolumes;
    }

    /**
     * Returns selectedNodes
     * @return {Array} selectedNodes
     */
    function getSelectedNodes() {
      return selectedNodes;
    }

    /**
     * Appends volume name to SelectedVolumes
     * @param  {String}  volName
     */
    function addSelectedVolume(volName) {
      if (!selectedVolumes.includes(volName)) {
        selectedVolumes.push(volName);
      }
    }

    /**
     * Removes volume name to SelectedVolumes
     * @param  {String}  volName
     */
    function removeSelectedVolume(volName) {
      var index = selectedVolumes.indexOf(volName);
      selectedVolumes.splice(index, 1);
    }

    /**
     * Overwrites selectedVolumes with new array of volume names
     * Can be used to clear selectedVolumes if empty array is passed in
     * @param  {Array}  vols
     */
    function updateSelectedVolumes(vols) {
      selectedVolumes = vols;
    }

    /**
     * Reset selections in one swoop.
     *
     * @method   resetSelections
     */
    function resetSelections() {
      updateSelectedNodes([]);
      updateSelectedVolumes([]);
    }

    /**
     * Reconstructs an file object from a given fileDocument object because it
     * wasn't selected via this flow, but a Yoda search, instead.
     *
     * @method    reconstructOriginalItem
     * @param     {object}   fileObject   Yoda search result item.
     * @returns   {object}   The reconstructed vm-browser compatible object.
     */
    function reconstructOriginalItem(fileObject) {
      var isFolder = fileObject.isDirectory;
      return {
        fileDocument: fileObject.fileDocument,
        fullPath: [fileObject._path, fileObject._name].join('/'),
        isFolder: isFolder,
        isVolume: false,
        name: fileObject._name,
        path: fileObject._path,
        type: isFolder ? 'kFolder' : 'kFile',
        volumeName: fileObject._path.substr(0, fileObject._path.indexOf('/', 1))
      };
    }

    /**
     * Decorates both simple and trailing slash volumes with sanitized volume
     * name.
     *
     * Trailing Slash Volumes are those which have a trailing '/' at the end of
     * the volume name which can be created on linux machine by renaming mount
     * point entry within the /etc/fstab file. eg: 'foo/', /foo/bar/' etc.
     *
     * @method   decorateVolume
     * @example
     * Incase of trailing slash volume '/foo/bar/':
     * currentDirectoryPath  -> '/foo/bar//dir/'
     * branch.fullPath       -> '/foo/bar/dir/'
     *
     * Note: @method String.prototype.splice() is used here defined in
     * app/global/c-utils/_c-utils.js.
     *
     * @param    {Object}    volume    Object containing volume details
     */
    function decorateVolume(volume) {
      if (!volume) { return; }
      if (volume.name.charAt(volume.name.length - 1) === '/') {
        volume._sanitizedVolumeName = volume.name.splice(
          volume.name.length - 1, 1);
      } else {
        volume._sanitizedVolumeName = volume.name;
      }
    }

    return service;
  }

  function cVMBrowserParentController(
    _, $scope, $rootScope, $q, evalAJAX, cModal, cUtils, cMessage, ENV_GROUPS,
    FEATURE_FLAGS, FILESIZES, SourceService, RestoreService, SearchService,
    DateTimeService, ViewService, SharedVMBrowserService, UserService,
    ActiveDirectoryService, GroupService, replaceStringFilter, pageConfig,
    $uibModalInstance, SNAPSHOT_TARGET_TYPE, INDEXING_STATUS_TYPE,
    PUB_TO_PRIVATE_ENV_STRUCTURES, INDEXED_DOCUMENT_TYPE, NgTenantService,
    WELL_KNOWN_PRINCIPALS, ngDialogService, NgViewsService) {

    var rootScopeListener;
    var vm = pageConfig.vm;
    var view = pageConfig.view;
    var lockedSnapshot = pageConfig.lockedSnapshot;
    var taskCart = Array.isArray(taskCart) ? taskCart : [];
    const defaultSelectionCounts =
      { dirCount: 0, fileCount: 0, symlinkCount: 0 };

    /**
     * Params for API call
     * @type {Object}
     */
    $scope.params = {
      jobid: undefined,
      jobUidObjectId: undefined,
      clusterId: undefined,
      clusterIncarnationId: undefined,
      entityId: undefined,
      jobInstanceId: undefined,
      jobStartTimeUsecs: undefined,
      attemptNum: undefined,
      path: undefined,
      snapshot: undefined,
      volumeInfoCookie: undefined,
      lockedSnapshot: undefined,
      statFileEntries: false,
      useLibrarian: false,
    };

    /**
     * Option to disable the Save and Continue button. This is used for the ng recovery flow.
     */
    $scope.hideSaveAndContinue = pageConfig.hideSaveAndContinue || false;

    /**
     * Flag for enabling non-browsable volumes view.
     */
    $scope.nonBrowsableSupportEnabled =
      FEATURE_FLAGS.nonBrowsableSupportEnabled;

    /**
     * Flag for enabling librarian browse.
     */
    $scope.librarianBrowseEnabled = FEATURE_FLAGS.librarianBrowseEnabled;

    /**
     * Config Object for MetaData
     * For use with large data result sets (in excess of 1000)
     * @type {Object}  {
     *   '/file/path': {
     *      cookie: string|undefined
     *      isFinished: boolean,
     *      dirCount: integer,
     *      fileCount: integer,
     *      symlinkCount: integer,
     *    }
     * }
     */
    $scope.metadata = {};

    /**
     * Config Object for Snapshot Versions
     * @type {Array}
     */
    $scope.versions = [];

    /**
     * does snapshots available
     * @type {Boolean}
     */
    $scope.snapshotsAvailable = true;

    /**
     *
     * Config for Snapshots Drop List
     * @type {Array}
     */
    $scope.snapshotOptions = [];

    /**
     * Config for Tree Data
     * Returned from RestoreService.getVolumeInfo
     * @type {Array}
     */
    $scope.volumes = [];

    /**
    * Config for Tree Data
    * Returned from RestoreService.getVolumeInfo
    * @type {Array}
    */
    $scope.browsableVolumes = [];

    /**
    * Config for Tree Data
    * Returned from RestoreService.getVolumeInfo
    * @type {Array}
    */
    $scope.nonBrowsableVolumes = [];

    /**
     * Config for Finder Tree
     * @type {Object} tree = {
     *       dirs: [],
     *       files: [],
     *       symlinks: []
     * }
     */
    $scope.tree = {
      dirs: [],
      files: [],
      symlinks: [],
    };

    /**
     * Config for selected node
     * @type {Object}
     */
    $scope.selected = {
      path: undefined,
      name: undefined,
      volumeName: undefined,
      logicalQuotaBytes: FILESIZES.gigabyte * 20,
      ...defaultSelectionCounts,
    };

    /**
     * Selected Volume
     * @type {Object}
     */
    $scope.selectedVolume = {};

    /**
     * state object for file system browser
     * @type {Object}
     */
    $scope.state = {
      /**
       * Flag for non browsable volume
       * @type {Boolean}
       */
      isNonBrowsableVolume: false,

      /**
       * Flag for non browsable path
       * @type {Boolean}
       */
      isNonBrowsablePath: false,

       /**
       * Flag for invalid volume
       * @type {Boolean}
       */
      isInvalidVolume: false,

      /**
       * Flag for invalid || non browsable alerts
       * @type {Boolean}
       */
      hasAlert: false,

      /**
       * Specifies whether the toggle for browse using Librarian is selected.
       * @type {Boolean}
       */
      useLibrarianForBrowse: true,
    };

    /**
     * Cart. Collection of selected files/folders.
     * @type {Array}
     */
    $scope.cart = taskCart.map(function mapTaskCartItem(item) {
      var browserCartItem;

      // If the `_originalItem` property isn't previously set on this cart item,
      // it means it was added to the cart from a Yoda file search, and not a
      // Server browse. They are incompatible so we need to reconstruct it.
      item._originalItem = item._originalItem ||
        SharedVMBrowserService.reconstructOriginalItem(item);

      browserCartItem = item._originalItem;

      if (volumeTypes.includes(browserCartItem.type)) {
        SharedVMBrowserService.addSelectedVolume(browserCartItem.name);
      }

      return browserCartItem;
    });

    /**
     * Is Cart Expanded
     * @type {Boolean}
     */
    $scope.cartModal = {
      isExpanded: false
    };

    /**
     * Is API fetching data
     * @type {Boolean}
     */
    $scope.fetching = false;

    /**
     * OneDrive metadata for browsing Office-365 OneDrive.
     * @type {Object}
     */
    $scope.oneDriveSnapshotMetadata = {
      isFileStructureFlat: false,
      driveId: undefined,

      // Differentiates between a user's OneDrive or a SharePoint Site's
      // drive.
      isSharePointDrive: false,
    }

    /**
     * Path suffix for onedrive/sharepoint rocksdb.
     * @type  {String}
     */
    const o365RocksDBPathSuffix = '/metadata/rocksdb';

    /**
     * Number of directories in the sharepoint doc repo root path.
     * It is used to append the o365RocksDBPathSuffix when querying
     * anything under sharepoint drive.
     * @type  {Integer}
     */
    const dirsInSharepointDrivePath = 3;

    assign($scope, {
      // List of all Trusted domains on the Cluster.
      clusterTrustedDomains: ['LOCAL'],

      // Hash of domains, each with a hash of Principals in that domain.
      domainPrincipalsHash: {},
      FEATURE_FLAGS: FEATURE_FLAGS,

      // Hash of all known Principals for display-only when we don't know
      // domain.
      flatPrincipalsHash: {},

      // Indicates which mode: 'share' | 'quota' | 'file' (default)
      mode: pageConfig.mode || 'file',

      addPrincipalsToHash: addPrincipalsToHash,
      onChangeSuperUsers: onChangeSuperUsers,
      addWhitelist: addWhitelist,
      editWhitelist: editWhitelist,
      deleteWhitelist: deleteWhitelist,
    });

    /**
     * Reset the tree containing files, directories and symlinks
     *
     * @method   _resetDataTree
     */
    function _resetDataTree() {
      $scope.tree = {
        dirs: [],
        files: [],
        symlinks: [],
      };
    }

    /**
     * Activate this module
     *
     * @method   activate
     */
    function activate() {
      if (vm) {
        // Context: Recovery flow. Browse the provided `vm` object which may be
        // Physical Servers, VMs, Views, or more. In this response, `vm` is a
        // legacy name.
        $scope.vm = vm;
        $scope.versions = SearchService.getBrowsableSnapshotVersions(
          vm.vmDocument.versions);

        // no active snapshot to view.
        if ($scope.versions.length === 0) {
          $scope.snapshotsAvailable = false;
          return;
        }

        $scope.entityKey = SourceService.getEntityKey(
          vm.vmDocument.objectId.entity.type
        );

        // Set up params for API call
        // based on $state params
        $scope.params = {
          attemptNum: $scope.versions[0].instanceId.attemptNum,
          clusterId: vm.vmDocument.objectId.jobUid.clusterId,
          clusterIncarnationId:
            vm.vmDocument.objectId.jobUid.clusterIncarnationId,
          entityId: vm.vmDocument.objectId.entity.id,
          jobId: vm.vmDocument.objectId.jobId,
          jobInstanceId: $scope.versions[0].instanceId.jobInstanceId,
          jobStartTimeUsecs:
            $scope.versions[0].instanceId.jobStartTimeUsecs,
          jobUidObjectId: vm.vmDocument.objectId.jobUid.objectId,
          lockedSnapshot: lockedSnapshot,
          statFileEntries: false,
          useLibrarian: false,
          versions: $scope.versions,
        };

        buildSnapshotOptions();

      } else if (view) {
        // Context: In this case, the user is browsing the current View rather
        // than a snapshot of a View as is done in the recovery flow.
        $scope.browsingView = true;
        $scope.view = view;

        $scope.view._hasSmb = !!view.smbMountPaths;
        $scope.view._hasNfs = !!view.nfsMountPaths;
        $scope.view._hasS3 = !!view.s3AccessPath;
        $scope.view._hasNfsOnly = $scope.view._hasNfs && !$scope.view._hasSmb &&
          !$scope.view._hasS3;

        // The following two lines ensure that the default state of the
        // c-vm-browser is the root directory.
        $scope.userInput = null;
        $scope.inputPath = $scope.selected.path = '/';
        $scope.isDirectoryNodeSelected = true;

        // Assign default Share level permissions.
        $scope.selected.sharePermissions = clone(defaultAclPermissions);

        $scope.selected.subnetWhitelist = [];

        // Fetch Dependencies for ACL Permissions and Super Users.
        _getTrustedDomains().finally(() => {
          _assembleSuperUserComponentInputs();
          _assembleWhitelistTableComponentInputs();
        });

        // Get all known Principals to seed the dropdown menus.
        _getSharePrincipals($scope.selected).then(addPrincipalsToHash);

        // Stub hash with Well-Known Principals.
        addPrincipalsToHash(WELL_KNOWN_PRINCIPALS);
      }

      /**
       * In case of source having no volumes/having views call getDir() else
       * call getVolumes().
       *
       * For verifying that the entity is browsable, backupType for the entity
       * should be verified against ENV_GROUPS.indexableEntitiesExposedAsViews
       * instead of checking registeredSource type
       */
      if ($scope.browsingView ||
          ENV_GROUPS.indexableEntitiesExposedAsViews
            .includes(vm.vmDocument.backupType)) {
        getDir('/', false, false, true);

        // The type of object being browsed does not have volumes.
        $scope.hasNoVolumes = true;
      } else if (ENV_GROUPS.office365.includes(vm.vmDocument.environment)) {
        // NOTE: Since kO365 entities have both Mailbox and OneDrive within
        // an Office365 User WITHOUT an actual leaf entity corresponding to
        // oneDrive or Mailbox within EH, a backup of User falls into both
        // indexable & nonIndexable entities.
        //
        // Incase of kO365(kUser):
        //  - Mailbox is indexable but non-browsable
        //  - OneDrive is both indexable & browsable
        //    Refer yoda/master/ops/read_dir_op.cc#200 for implementation.
        //
        // Incase of kO365(kSite):
        //  - Site may have multiple OneDrives(document repositories) which may
        //    or may not have been indexed.
        //
        // Hence this is treated as a special case.
        //
        var useLibrarianForBrowse = $scope.state.useLibrarianForBrowse;
        $scope.state.useLibrarianForBrowse = false;

        // Check if the OneDrive is for the SharePoint Doc repository.
        if (get(vm, 'vmDocument.objectId.entity.o365Entity.type') ===
          PUB_TO_PRIVATE_ENV_STRUCTURES.kO365.entityTypes.kSite) {
          $scope.oneDriveSnapshotMetadata.isSharePointDrive = true;
          $scope.oneDriveSnapshotMetadata.isFileStructureFlat = true;
        }

        // _populateOneDriveMetadata call should be made in non-index-browse
        // mode as it traverses the snapFS. After its completion, reset to
        // original value.
        _populateOneDriveMetadata().then(function onSuccess(res) {
          $scope.state.useLibrarianForBrowse = useLibrarianForBrowse;
          if ($scope.state.useLibrarianForBrowse &&
              FEATURE_FLAGS.librarianBrowseEnabled) {
            // Browsing on indexed data should happen starting with '/'.
            getDir('/', false, false, $scope.state.useLibrarianForBrowse);
          } else {
            // incase we are browsing a Site, then browse should start with
            // '/OneDrives' which will list all the drives within the sites.
            // TODO(tauseef): Jump to the root directory if any of the site's
            // drive is being browsed.
            if ($scope.oneDriveSnapshotMetadata.isSharePointDrive) {
              getDir(_getRootDirectoryNameForOneDrive(), false, true, true);
              return;
            }
            // Browse of a User's OneDrive should start from the root directory
            // instead of '/'.
            getDir(_getRootDirectoryNameForOneDrive(), false, true);
          }
        });

        // The type of object being browsed does not have volumes.
        $scope.hasNoVolumes = true;
      } else {
        getVolumes();
      }
    }

    /**
     * This method populates metadata of an Office365 OneDrive entity in
     * $scope.oneDriveSnapshotMetadata.
     * OneDrive paths are of the below pattern -
     * 1.* '/OneDrives/OneDrive-<driveId>/data/root/...' OR
     * 2.* '/OneDrives/OneDrive-<driveId>/metadata/rocksdb/...' OR
     * 3.* '/OneDrives/OneDrive-<driveId>/flat-file-structure-indicator-dir'
     * The 3rd path will only be present in cluster versions 6.5.1 and above.
     * If it is present, we need to use the 2nd path only to query from the
     * iris-ui.
     * To browse this paths and make queries, OneDriveFileStructure and
     * OneDriveId info is required. We get this info via browsing the
     * following paths:
     * '/OneDrives' and '/OneDrives/OneDrive-<driveId>'
     *
     * @method   _populateOneDriveMetadata
     */
    function _populateOneDriveMetadata() {
      var oneDriveId = SourceService.getOneDriveId(
        vm.vmDocument.objectId.entity);

      // Bail out early if a site is being browsed.
      if ($scope.oneDriveSnapshotMetadata.isSharePointDrive) {
        return Promise.resolve();
      }

      // Due to throttling issues while registration of O365 entity, update
      // of oneDriveId may be flagged off from Magneto, resulting in its
      // absence from entity proto. Hence the OneDrive Id has to fetched
      // from the snapshot path itself using getDir(...) method.
      if (!oneDriveId) {
        // Determine OneDrive id by making a call to Yoda with the path as
        // 'OneDrives/' and then parse the directory name in the response.
        return getDir('/OneDrives', false, true, true).then(
          function onSuccess(res) {
            if ($scope.tree.dirs && $scope.tree.dirs.length) {
              // Since the OneDriveID directory path within the view is of the
              // form 'OneDrive-<driveId>, we take the substring starting from
              // the index 9.
              oneDriveId = $scope.tree.dirs[0].name.substring(9);
              _resetDataTree();
              _getOneDriveFileStructure(oneDriveId);
            }
          }
        );
      } else {
        return _getOneDriveFileStructure(oneDriveId);
      }
    }

    /**
     * Determines whether the file structure is flat, based on the presence
     * of a pre-determined number of dirs in the snapshot .
     *
     * @method       _getOneDriveFileStructure
     * @param        {string}  oneDriveId    The id of the drive.
     */
    function _getOneDriveFileStructure(oneDriveId) {
      $scope.oneDriveSnapshotMetadata.driveId = oneDriveId;
      var dirPath = '/OneDrives/OneDrive-' + oneDriveId;
      return getDir(dirPath, false, true, true) .then(function onSuccess(res) {
        // If the file structure isn't flat, we only create 2 directories
        // inside /OneDrive/OneDrive-<id>. With flat-file structure enabled,
        // We create a special dir, so that browse workflows can figure out
        // this backward-incompatibility and act on it.
        // TODO(writo) : Should we check the name of the special folder?
        $scope.oneDriveSnapshotMetadata.isFileStructureFlat =
          ($scope.tree.dirs.length >= 3);
        _resetDataTree();
      });
    }

    /**
     * This method returns the root directory name of the OneDrive that will
     * be used to make queries.
     * If OneDrive file structure is flat, we will use rocksdb path
     * ('/OneDrives/OneDrive-<driveId>/metadata/rocksdb/) to use in the
     * queries.
     *
     * @method _getRootDirectoryNameForOneDrive
     */
    function _getRootDirectoryNameForOneDrive() {
      var dirPath = '/OneDrives';

      // Site browse will always start from '/OneDrives'.
      if ($scope.oneDriveSnapshotMetadata.isSharePointDrive) {
        return dirPath;
      }

      // User's OneDrive browse will start from the root directory.
      dirPath += '/OneDrive-' + $scope.oneDriveSnapshotMetadata.driveId;
      dirPath += $scope.oneDriveSnapshotMetadata.isFileStructureFlat // --- state true
        ? o365RocksDBPathSuffix : '/data/root';
      return dirPath;
    };

    /**
     * Gets Trusted Domains for the Cluster.
     *
     * @method     _getTrustedDomains
     */
    function _getTrustedDomains() {
      // Get Trusted Domains
      return ActiveDirectoryService.getActiveDirectories().then(
        function gotActiveDirectories() {
          $scope.clusterTrustedDomains = concat($scope.clusterTrustedDomains,
            ActiveDirectoryService.allTrustedDomainsCache);
        },
        noop
      );
    }

    /**
     * Fetches any Principals associated with the Share and all LOCAL principals
     * on the Cluster.
     *
     * @method     _getSharePrincipals
     * @param      {Object}     share     Share config object.
     * @returns    {Object}     Promise to return a list of Principals.
     */
    function _getSharePrincipals(share) {
      var promises = {
        localGroups: GroupService.getGroups({domain: 'LOCAL'}),
        localUsers: UserService.getAllUsers({domain: 'LOCAL'}),
        smbPrincipals: ViewService.getViewPrincipals(share),
      };

      return $q.all(promises).then(
        function getPrincipalsSuccess(response) {
          return concat([],
            response.smbPrincipals,
            response.localGroups,
            response.localUsers);
        },
        evalAJAX.errorMessage
      ).finally(function getViewPrincipalsSuccess() {
        $scope.fetchedPrincipals = true;
      });
    }

    /**
     * Adds Principals to the Principals hashes.
     *
     * @method     addPrincipalsToHash
     * @param      {array}    principals    The principals
     */
    function addPrincipalsToHash(principals) {
      var domainPrincipals = $scope.domainPrincipalsHash;

      forEach(principals || [], function hashEachPrincipal(principal) {
        // If no domain property, then it's a Well-known SID and we add our
        // temporary custom domain: "All Domains".
        principal.domain = principal.domain || 'allDomains';

        // Stub the domain in the hash.
        domainPrincipals[principal.domain] =
        domainPrincipals[principal.domain] || {};

        // Insert the principal into its respective domain.
        domainPrincipals[principal.domain][principal.sid] = principal;

        // "Flat" hash with every known principal for display-only when we don't
        // know domain.
        $scope.flatPrincipalsHash[principal.sid] = principal;
      });
    }

    /**
     * returns the node path for preview.
     *
     * @method   getPreviewNodePath
     * @param    {object}   volume   The volume object
     * @param    {string}   path     The path string
     * @return   {string}            The preview node path.
     */
    $scope.getPreviewNodePath = function getPreviewNodePath(volume, path) {
      // In case of Entities without volumes, displayName will be undefined
      var displayName = volume.displayName || '';
      if (get(vm, '_osType') === 'Windows') {
        return cUtils.transformToWindowsPath(displayName + path);
      }
      return [
        displayName !== '/' ? displayName : '',
        path
      ].join('');
    };

    /**
     * submit a request to download the currently selected file.
     *
     * @method downloadFile
     */
    $scope.downloadFile = function downloadFile() {
      var fullFilePath = $scope.selected.fullPath;

      // If this download is via Index-browsing for OneDrive, the 'Root
      // Directory' name must be prepended to $scope.selected.fullPath because
      // Librarian browse only gives the relative OneDrive path.
      if (ENV_GROUPS.office365.includes(vm.vmDocument.environment) &&
          $scope.state.useLibrarianForBrowse &&
          FEATURE_FLAGS.librarianBrowseEnabled) {
        if ($scope.oneDriveSnapshotMetadata.isSharePointDrive) {

          // For sharepoint, fullFilePath will be of the form -
          // "/doc-repo-name/A/B/c.txt"
          // Which needs to be converted to -
          // "/OneDrives/doc-repo-name/metadata/rocksdb/A/B/c.txt"
          const indexOfSecondSlash = fullFilePath.indexOf('/',1);
          if (indexOfSecondSlash != -1) {
            fullFilePath = _getRootDirectoryNameForOneDrive() +
              fullFilePath.substring(0, indexOfSecondSlash) +
              o365RocksDBPathSuffix +
              fullFilePath.substring(indexOfSecondSlash);
          }
        } else {
          fullFilePath = _getRootDirectoryNameForOneDrive() +
            $scope.selected.fullPath;
        }
      }
      RestoreService.downloadFileFromSnapshot(
        fullFilePath,
        vm.vmDocument,
        $scope.currentSnapshot
      );
    };

    /**
     * Determines if the 'Download Now' option is disabled and updates the disabling reason.
     *
     * @method    isDownloadDisabled
     * @returns   {boolean}     true if the disabled otherwise false.
     */
    $scope.isDownloadDisabled = function isDownloadDisabled() {
      // If SP is impersonating as a tenant
      if (!!NgTenantService.impersonatedTenant) {
        $scope.disableDownloadMessage = 'recovery.disableMessage.onlyTenantDownloadSupported';
        return true;
      }

      $scope.disableDownloadMessage = '';
      return false;
    };

    /**
     * Determines if the 'Download Now' option should be shown.
     *
     * @method    showDownloadLink
     * @returns   {boolean}     True if the option should be shown.
     *                          False otherwise
     */
    $scope.showDownloadLink = function showDownloadLink() {
      return FEATURE_FLAGS.downloadFilesAndFoldersEnabled &&
        !$scope.browsingView &&
        $scope.selected.type === 'kFile' &&
        !$scope.currentSnapshot.hasOnlyArchivedReplica &&
          // User should have RESTORE_DOWNLOAD privilege
          (UserService.user.privs.RESTORE_DOWNLOAD ||
            // Or show when SP is impersonating as tenant,
            // but this case should be disabled
            NgTenantService.impersonatedTenant);
    }

    /**
     * Toggle the data source used for browsing b/w librarian and locally
     * mounted snapshot. We reset the browse tree since the cookie format and
     * the order in which we get response is different for fetch directory
     * using librarian and locally mounted snapshot.
     *
     * @method toggleBrowsingSource
     */
    $scope.toggleBrowsingSource = function toggleBrowsingSource() {
      $scope.selectSnapshot($scope.currentSnapshot);
    };

    /**
     * Constructs a lightweight copy of list of versions of any object having
     * browsable snapshots through 2 flows:
     *
     * -- VM Read dir op (Default) using the local replica
     * -- Librarian Read dir op using the indexed archive replica.
     *
     * @method     buildSnapshotOptions
     */
    function buildSnapshotOptions() {
      var selectedIndex = 0;

      $scope.snapshotOptions = [];

      $scope.versions.forEach(function eachSnapshot(snapshot, index) {
        $scope.snapshotOptions.push(assign(snapshot, {
          name: DateTimeService.usecsToFormattedDate(
            snapshot.instanceId.jobStartTimeUsecs
          ),
          value: snapshot.instanceId.jobStartTimeUsecs,

          // Determines if librarian browse should be enabled by default based
          // on the fact that indexing of snapshot is done.
          isLibrarianBrowseSupported:
            _determineSnapshotIndexingStatus(snapshot),

          // Determines if the snapshot has local snapshot deleted but has an
          // archived replica of snapshot.
          hasOnlyArchivedReplica: _determineSnapshotReplicaStatus(
            snapshot.replicaInfo),
        }));

        if (lockedSnapshot && angular.equals(lockedSnapshot, snapshot)) {
          selectedIndex = index;
        }
      });

      // Set current snapshot object for use with parent controller.
      $scope.currentSnapshot = $scope.params.versions[selectedIndex];

      if (!$scope.currentSnapshot.isLibrarianBrowseSupported) {
        $scope.state.useLibrarianForBrowse = false;
      }
    }

    /**
     * Determines whether the indexing of snapshot is done.
     *
     * If indexing is not done, then the browse via Librarian toggle is not
     * enabled by default.
     *
     * @method   _determineSnapshotIndexingStatus
     * @param    {Object}    snapshot  Object containing the selected snapshot
     * @return   {Boolean}   True if indexing of snapshot is done
     */
    function _determineSnapshotIndexingStatus(snapshot) {
      return (snapshot &&
        // The private API return int data type for INDEXING_STATUS_TYPE.
        (snapshot.indexingStatus === INDEXING_STATUS_TYPE.kDone) ||

        // The public API return string data type for INDEXING_STATUS_TYPE.
        (INDEXING_STATUS_TYPE[snapshot.indexingStatus] === INDEXING_STATUS_TYPE.kDone));
    }

    /**
     * Determines whether the given snapshot has a replica archived but has no
     * local snapshot copy.
     *
     * VM read dir doesn't support browse on archived snapshot as a local
     * snapshot is needed to mount and browse. On the other hand, Librarian
     * read dir supports on snapshots which have indexed archives.
     * Such snaphots can only be browsed via Librarian if they were indexed.
     *
     * @method   _determineSnapshotReplicaStatus
     * @param    {Object}    replicaInfo   Specifies the information about
     *                                     replicas of a snapshot.
     * @return   {Boolean}   True if the snapshot has no local copy but has
     *                       archived snasphot.
     */
    function _determineSnapshotReplicaStatus(replicaInfo) {
      var hasArchivedReplica = false;
      var hasLocalSnapshotVersion = false;
      var hasOnlyArchivedReplica = false;

      if (!get(replicaInfo, 'replicaVec.length')) {
        return hasOnlyArchivedReplica;
      }

      hasOnlyArchivedReplica = replicaInfo.replicaVec.some(
        function analyzeReplicaTarget(replica) {
          switch(replica.target.type) {
            // Snapshot Replica, if deleted, will have 'expiryTimeUsecs' as 0.
            case SNAPSHOT_TARGET_TYPE.kLocal:
              hasLocalSnapshotVersion = !!replica.expiryTimeUsecs;
              break;

            // Out of all the Archival replicas, any 1 which is not expired
            // can be browsed.
            case SNAPSHOT_TARGET_TYPE.kArchival:
              if (replica.expiryTimeUsecs) {
                hasArchivedReplica = true;
              }
              break;
          }

          return !hasLocalSnapshotVersion && hasArchivedReplica;
        }
      );

      return hasOnlyArchivedReplica;
    }

    /**
     * Determines whether the toggle to browse on Indexed data should be
     * disabled either in on/off state. This happens in 2 scenarios:
     *
     *  -- (ON state)  Absence of a local snapshot copy for an indexed archive.
     *  -- (OFF state) Local snapshot which hasn't been indexed.
     *
     * @method   shouldDisableLibrarianBrowse
     * @return   {Boolean}   True, if Librarian toggle is to be disabled.
     *                       False, otherwise.
     */
    $scope.shouldDisableLibrarianBrowse =
      function shouldDisableLibrarianBrowse() {
        // Since snapshot.indexingStatus is not present, we are assuming it to
        // be INDEXING_STATUS_TYPE.kDone for now.
        // TODO(Tauseef): Remove this temporary fix once indexingStatus is
        // fixed from iris_exec.
        if (ENV_GROUPS.office365.includes(vm.vmDocument.environment)) {
          return false;
        }
        return $scope.currentSnapshot.hasOnlyArchivedReplica ||
          $scope.currentSnapshot.indexingStatus !== INDEXING_STATUS_TYPE.kDone;
    }

    /**
     * Sanitize Params
     *
     * Even though we track versions in $scope.params
     * we don't want to pass 'versions' argument to API
     *
     * @method sanitizeParams
     * @param  {Object} params
     * @return {Object} params Sanitized params
     */
    function sanitizeParams(params) {
      var p = angular.copy(params);

      if (p.hasOwnProperty('versions')) {
        delete p.versions;
      }

      if (p.hasOwnProperty('lockedSnapshot')) {
        delete p.lockedSnapshot;
      }

      return p;
    }

    /**
     * Fetch Volumes
     * This populates the first panel of Finder Tree
     */
    function getVolumes() {
      $scope.fetching = true;

      RestoreService.getVolumeInfo(sanitizeParams($scope.params)).then(
        function getVolumeInfoSuccess(response) {

          $scope.volumes = response.volumeInfos;

          // segregate browsable and non-browsable volumes
          response.volumeInfos.forEach(function(vol) {
            SharedVMBrowserService.decorateVolume(vol);
            if (vol.isSupported) {
              $scope.browsableVolumes.push(vol);
            } else {
              $scope.nonBrowsableVolumes.push(vol);
            }
          });

          $scope.params.volumeInfoCookie = response.volumeInfoCookie;
        },
        evalAJAX.errorMessage
      ).finally(
        function getVolumeInfoFinally() {
          $scope.fetching = false;
        }
      );
    }

    /**
     * Scope event that triggers getDir for a given volume
     *
     * @method     selectVolume
     * @param      {Object}  volume  Object as returned from
     *                               RestoreService.getVolumeInfo()
     */
    $scope.selectVolume = function selectVolume(volume) {
      // If volume is not in cart (selected), passing an isChecked value is not
      // desired, as the individual items should be set based on their own
      // presence in the cart.
      var isChecked = SharedVMBrowserService.isVolSelected(volume) || undefined;

      $scope.params.volumeName = volume.name;
      $scope.selectedVolume = clone(volume);
      $scope.metadata = {};
      _resetDataTree();

      // check for browsable volume
      if (volume.isSupported) {
        $scope.state.isNonBrowsablePath = false;
        $scope.state.isNonBrowsableVolume = false;
        $scope.state.hasAlert = false;

        // set the volume path selected inside input box
        $scope.userInput = null;
        $scope.inputPath = volume.name;
        getDir('/', isChecked, false /* isUserInput */, true /* ignoreStat */);
        detectScrollToBottom();
      } else {
        $scope.state.isNonBrowsableVolume = true;
        $scope.state.hasAlert = true;
      }
      selectNode(volume, '/');
    };

    /**
     * Fetch Single Directory.
     *
     * @method   getDir
     * @param    {String}   path         Unique full path of a directory.
     * @param    {Boolean}  isChecked    true if the path is checked.
     * @param    {Boolean}  isUserInput  true if path is entered by user.
     * @param    {Boolean}  ignoreStat   true if the file stat call is to be
     *                                   skipped
     * @param    {Boolean}  nextPage     true if the request is coming from
     *                                   scroll to get the next page
     */
    function getDir(path, isChecked, isUserInput, ignoreStat, nextPage) {
      // RestoreService.getDirectoryInfo requires parameter 'path', which is the
      // directory relative to the volume. Unfortunately, getDirectoryInfo
      // returns results including a 'fullPath' property (this includes the
      // volume in the path). Because of this, we must maintain a distinction
      // between 'path' and 'fullPath' $scope.metadata will rely on 'fullPath'.
      // Let's generate that now based on the $scope.selectedVolume and 'path'.
      var cookiePath = _sanitizeCookiePath(path);

      if ($scope.metadata[cookiePath] &&
        ($scope.metadata[cookiePath].isFinished || !nextPage)) {
        // If the directory has been completely fetched already
        // avoid fetching again

        // If the request is not from scroll
        // do not fetch the next page
        return Promise.resolve();
      }

      $scope.fetching = true;
      $scope.params.dirPath = path;

      // Conditionally set cookie param
      if ($scope.metadata[cookiePath]) {
        // If the current path is in our cookie hash, let's add the value to
        // params.
        $scope.params.cookie = $scope.metadata[cookiePath].cookie;
      } else if ($scope.params.cookie) {
        // The current path is not in our current cookie hash, AND
        // $scope.params.cookie is set, we should remove it.
        $scope.params.cookie = undefined;
      }

      if ($scope.browsingView) {
        // Prepopulate the potential Share name with the name of current dir.
        // This will be consumed only in the Create Share workflow.
        $scope.selected.shareName = path.split('/').pop();

        // Add additional params specific to browsing a View.
        angular.merge($scope.params, {
          maxEntries: 5000,
          dirPath: path,
          viewName: $scope.view.name,

          // v1 API only sends viewBoxId.
          viewBoxId: $scope.view.storageDomainId || $scope.view.viewBoxId,
        });
      }

      // Clean out params that will make the API barf
      const params = sanitizeParams($scope.params);

      // Use Librarian for Browse.
      params.useLibrarian = FEATURE_FLAGS.librarianBrowseEnabled &&
        $scope.state.useLibrarianForBrowse;

      // get stat for the directory.
      if (!ignoreStat) {
        getStatInfo(params.dirPath);
      }

      return RestoreService.getDirectoryInfo(params).then(
        function getDirectoryInfoSuccess(r) {
          // Set the cookie object for large data sets. (in excess of 1000)
          $scope.metadata[cookiePath] = {
            cookie: r.cookie,
            // is this directory completely fetched?
            isFinished: !r.cookie
          };

          // Add the response to Finder Tree
          addBranch(path, r.entries, cookiePath, isChecked, isUserInput,
            nextPage);
        },
        evalAJAX.errorMessage
      ).finally(
        function getDirectoryInfoFinally() {
          $scope.fetching = false;

          detectScrollToBottom();

          // Make sure to mark all previously selected branches checked.
          angular.forEach(
            SharedVMBrowserService.getSelectedNodes(),
            function setAsChecked(selectedNode) {
              evaluateBranch($scope.tree, selectedNode, true);
            }
          );
        }
      );
    }

    /**
     * Fetch stat info for the given path. File stat can now be requested
     * through 2 flows:
     *    -- VM File stat (Default)
     *    -- Librarian File stat (Available only for Indexed data)
     *
     * @method   getStatInfo
     * @param    {String}   filePath   full path of a file or directory
     * @return   {Number}   The type of document where type is mapped as:
     *                      <1, kFile>
     *                      <2, kDirectory>
     *                      <3, kSymlink>
     */
    function getStatInfo(filePath) {
      // Handle Office365 environments separately.
      // TOOD(tauseef): Remove this block once Rocksdb stat is implemented.
      if ((vm && ENV_GROUPS.office365.includes(vm.vmDocument.environment) &&

          // Librarian doesn't require a stat call.
          !($scope.state.useLibrarianForBrowse &&
            FEATURE_FLAGS.librarianBrowseEnabled) &&

          // The FS belongs to a OneDrive with flat file structure.
          ($scope.oneDriveSnapshotMetadata.isFileStructureFlat ||

          // The FS belongs to a SharePoint Site Document library.
          $scope.oneDriveSnapshotMetadata.isSharePointDrive))) {
        // Assume it is a valid directory.
        return Promise.resolve(INDEXED_DOCUMENT_TYPE.kDirectory);
      }

      var getStatParams = sanitizeParams($scope.params);

      // prepare params for getStatInfo
      getStatParams.filePath = filePath;
      getStatParams.dirPath = undefined;
      getStatParams.statFileEntries = undefined;
      getStatParams.useLibrarian = $scope.state.useLibrarianForBrowse;
      $scope.fetchingStat = true;

      return RestoreService.getStatInfo(getStatParams).then(
        function getStatInfoSuccess(statInfo) {
          // update stat info.
          $scope.selected.size = statInfo.size;
          $scope.selected.mtimeUsecs = statInfo.mtimeUsecs;
          return statInfo.type;
        },
        evalAJAX.errorMessage
      ).finally(function getStatInfoFinally() {
        $scope.fetchingStat = false;
      });
    }

    /**
     * Adds a new branch to $scope.tree
     *
     * @param   {string}    path          full path of the directory
     * @param   {Object[]}  dirItems      the items in the directory as returned
     *                                    by the API
     * @param   {string}    fullPath      the full path of the directory
     *                                    including volume
     * @param   {boolean}   isChecked     is the branch checked?
     * @param   {boolean}   isUserInput   true in case of user input
     * @param   {boolean}   nextPage      true if the request is coming from
     *                                    scroll to get the next page
     */
    function addBranch(path, dirItems, fullPath, isChecked, isUserInput,
                       nextPage) {
      // Parse the directory info object into a format that finder-tree can use
      var dirItemsByType = hashDirItemsByType(dirItems, isChecked);
      // add directories either when user traverses through tree or directly
      if (path === '/' || isUserInput || ($scope.userInput && nextPage)) {
        // If the path is '/' we know that we are at the volumes root directory

        // If this is from user input then we know we have reset the whole tree

        // Tree will be empty if this is fresh fetch or it will have prev page
        // info if this is the page fetch, either way we can concat to existing
        $scope.tree.dirs = $scope.tree.dirs.concat(dirItemsByType.dirs);
        $scope.tree.files = $scope.tree.files.concat(dirItemsByType.files);

        // Append symlinks to the tree.
        $scope.tree.symlinks = $scope.tree.symlinks.concat(
          dirItemsByType.symlinks);

        // If this is a user input tree has been hidden, lets show it
        $scope.tree.displayed = true;
      } else {
        // If the path is not '/' will traverse the tree to find the
        // appropriate branch
        if ($scope.selectedVolume.displayName !== '/') {
          // Prepend path with selectedVolume.name if it's not '/'
          path = fullPath;
        }
        findAndUpdateBranch($scope.tree.dirs, path, dirItemsByType);
      }
    }

    /**
     * Recursivly traverse the tree one branch at a time. Look for matching
     * fullPaths. Update that branch accordingly.
     *
     * @method    findAndUpdateBranch
     * @param     {Object[]}  branches         Array of branches
     * @param     {String}    path             Full path to match against
     * @param     {Object}    parsedDirInfo    Directory info
     * @return    {Boolean}   returns a boolean stating if a branch has been
     *                          found and updated
     */
    function findAndUpdateBranch(branches, path, parsedDirInfo) {
      return branches.some(function eachBranch(branch) {
        if (path === branch.fullPath ||
            ($scope.oneDriveSnapshotMetadata.isSharePointDrive &&
            path === branch.fullPath + o365RocksDBPathSuffix)) {
          branch.dirs = (branch.dirs || []).concat(parsedDirInfo.dirs);
          branch.files = (branch.files || []).concat(parsedDirInfo.files);
          branch.symlinks = (branch.symlinks || [])
            .concat(parsedDirInfo.symlinks);

          // We're done here, exit the function.
          return true;
        }

        if (branch.dirs && branch.dirs.length) {
          return findAndUpdateBranch(branch.dirs, path, parsedDirInfo);
        }

        return false;
      });
    }

    /**
     * Recursively traverse a branch of the tree and mark it checked or not
     *
     * @method     evaluateBranch
     * @param      {Object}   branch        this object comes from the tree
     * @param      {Object}   selectedNode  this object comes from the cart
     * @param      {Boolean}  isChecked     Do we want to check or uncheck this
     *                                      branch?
     * @return     {Object}   branch       updated branch
     */
    function evaluateBranch(branch, selectedNode, isChecked) {
      var branchFullPath = [branch.fullPath, '/'].join('');
      var branchVolume = getMatchedPattern(branchFullPath, $scope.volumes);
      var isSelectedVolumeDecendant = false;
      var selectedNodePath;

      var filePathStructure = fetchFilePathStructure(selectedNode.fullPath,
        branchVolume);

      // normalize selected node path
      selectedNodePath = ''.concat(selectedNode.volumeName || '',
        filePathStructure.qualifiedPath, '/');

      // Check if it is a selected volume
      if (volumeTypes.includes(branch.type) &&
        SharedVMBrowserService.isVolSelected(branch)) {
        branch.isChecked = isChecked;
      }

      // Check if this branches volume is selected. If so, we can exit early
      // SharedVMBrowserService.getSelectedVolumes has length,
      // and this branch is not a volume,
      // and this branches volume is in selectedVolumes
      if (SharedVMBrowserService.getSelectedVolumes().length &&
        !volumeTypes.includes(branch.type) &&
        SharedVMBrowserService.isVolSelected({'name': branchVolume})) {
        isSelectedVolumeDecendant = true;
        branch.isChecked = isChecked;
        if (fileTypes.includes(branch.type)) {
          // Because this branch is a file/symlink, we may safely
          // stop traversing this branch at this point
          return branch;
        }
      }

      // directory and not a decendant of selectedVolume
      if (!isSelectedVolumeDecendant &&
        branch.type === 'kDirectory' &&
        !fileTypes.includes(selectedNode.type) &&
        (
          branch.fullPath === selectedNode.path ||
          branch.fullPath === selectedNode.fullPath ||
          branchFullPath.indexOf(
            filePathStructure.qualifiedPath.concat('/')) === 0)) {
        branch.isChecked = isChecked;
      }

      // file/symlink and not a decendant of selectedVolume
      if (!isSelectedVolumeDecendant &&
          fileTypes.includes(branch.type)) {
        // selectedNode is the same as this node or
        // this node is a decendant of selectedNode
        if (branchFullPath.indexOf(selectedNodePath) === 0 ||
          // there will be 2 leading '/' if volume name is '/'
          branchFullPath.indexOf(selectedNodePath.slice(1)) === 0) {
          branch.isChecked = isChecked;
        }

        // Because this branch is a file, we may safely
        // stop traversing this branch at this point
        return branch;
      }

      // Traverse through directories
      if (Array.isArray(branch.dirs)) {
        branch.dirs.forEach(function loopBranchDirs(dir) {
          evaluateBranch(dir, selectedNode, isChecked);
        });
      }

      // Traverse through files
      if (Array.isArray(branch.files)) {
        branch.files.forEach(function loopBranchFiles(file) {
          evaluateBranch(file, selectedNode, isChecked);
        });
      }

      // Traverse through symlinks
      if (Array.isArray(branch.symlinks)) {
        branch.symlinks.forEach(function loopSymlinks(symlink) {
          evaluateBranch(symlink, selectedNode, isChecked);
        });
      }

      return branch;
    }

    /**
     * hashes a given directory's items by type so they are finderTree friendly
     *
     * @method     hashDirItemsByType
     * @param      {array}    dirItems   directory items as returned by API
     * @param      {Boolean}  isChecked  indicates if items should be checked
     *                                   (displayed as represented in cart)
     * @return     {Object}   directory items hashed by type
     */
    function hashDirItemsByType(dirItems, isChecked) {
      var hashedDirItems = {
        files: [],
        dirs: [],
        symlinks: [],
        volumeName: $scope.selectedVolume.name,
      };

      angular.forEach(dirItems,
        function(item, key) {
          // Verify if the item is already present in the cart only when the
          // item is unchecked.
          item.isChecked = isChecked ? true : itemIsInCart(item);
          switch(item.type) {
            case 'kDirectory':
              hashedDirItems.dirs.push(item);
              break;

            case 'kFile':
              hashedDirItems.files.push(item);
              break;

            case 'kSymlink':
              hashedDirItems.symlinks.push(item);
              break;
          }
        }
      );

      // sort dirs, files & symlinks alphabetically
      hashedDirItems.dirs.sort(localeAlphaSort);
      hashedDirItems.files.sort(localeAlphaSort);
      hashedDirItems.symlinks.sort(localeAlphaSort);

      // Update the count of subfolders and files in Preview pane.
      $scope.selected.dirCount += hashedDirItems.dirs.length;
      $scope.selected.fileCount += hashedDirItems.files.length;
      $scope.selected.symlinkCount += hashedDirItems.symlinks.length;

      return hashedDirItems;
    }

    /**
     * sorting function for files/directories to be used via Array#sort
     *
     * @param      {object}   a       file or dir object A
     * @param      {object}   b       file or dir object B
     * @return     {integer}  value representing the order relationship between
     *                        the two object's names
     */
    function localeAlphaSort(a, b) {
      return a.name.localeCompare(b.name);
    }

    /**
     * Reset the Vm Browser
     *
     * @method resetVmBrowser
     * Refreshes tree data with a new call to getVolumes()
     * Also emptys the cart
     */
    function resetVmBrowser() {
      $scope.metadata = {};
      $scope.volumes = [];
      $scope.browsableVolumes = [];
      $scope.nonBrowsableVolumes = [];
      $scope.selectedVolume = {};
      $scope.inputPath = '';
      $scope.userInput = null;

      $scope.selected = {
        path: undefined
      };

      _resetDataTree();
      emptyCart();

      // Only fetch volumes when the entity is browsable and has volumes.
      if (!$scope.hasNoVolumes) {
        getVolumes();
      }

      SharedVMBrowserService.resetSelections();
    }

    /**
     * Select a Node. Update $scope.selected object for use with preview panel
     * and path bar.
     *
     * @method   selectNode
     * @param    {Object}   node   Node selected
     * @param    {String}   path   Node path
     */
    function selectNode(node, path) {
      var name;
      merge($scope.selected, {
        type: node.type || node.volumeType,
        path: path,
        fullPath: node.fullPath ?
          node.fullPath : [$scope.selectedVolume.name, path].join(''),
        level: node.level,
        volumeName: $scope.selectedVolume.name,
        cookiePath: _sanitizeCookiePath(path),
        size: node.fstatInfo && node.fstatInfo.size,
        mtimeUsecs: node.fstatInfo && node.fstatInfo.mtimeUsecs,
        isChecked: Boolean(node.isChecked),
        isFolder: node.type === 'kDirectory',
        isVolume: volumeTypes.includes(node.volumeType),
      });

      if (node.name) {
        $scope.selected.name = node.name;
      } else {
        name = path.split('/');
        $scope.selected.name = name[name.length - 1];
      }
    }

    /**
     * If user is browsing a directory or file and selects a (node/file) set the
     * correct properties so they can be displayed in the preview panel
     *
     * @param    {object}   data    contains selected node data
     * @param    {string}   path    node path
     */
    function selectDirectoryNode(node, path) {
      var name = node.name;
      angular.extend($scope.selected, {
        type: node.type,
        name: name,
        fullPath: node.fullPath || path,
        cookiePath: _sanitizeCookiePath(path),
        // This attribute toggles 'download' button in preview pane
        isFolder: node.type === 'kDirectory',

        /**
         * Path should not include filename incase of 'kFile', hence should not
         * be equal to fullPath always. The parsing of fullPath and generation
         * of filePathStructure for browsable entities having no volumes has
         * been implemented in fetchFilePathStructure()
         */
        path: path,
        size: node.fstatInfo && node.fstatInfo.size,
        mtimeUsecs: node.fstatInfo && node.fstatInfo.mtimeUsecs,

        /**
         * The addition of isChecked corrects the evaluation of
         * `!(!isItemInCart() && selected.isChecked)` within ng-if condition for
         * 'addToCart' button.
         */
        isChecked: node.isChecked,
      });

      if (!name) {
        name = path.split('/');
        $scope.selected.name = name[name.length - 1];
      }

      $scope.isDirectoryNodeSelected = true;
    }

    /**
     * Add selected Node to cart
     *
     * @method   addSelectedToCart
     */
    $scope.addSelectedToCart = function addSelectedToCart() {
      var selectedNode = transformSelectedNode($scope.selected);

      if (!itemIsInCart(selectedNode)) {
        if (volumeTypes.includes(selectedNode.type)) {
          SharedVMBrowserService.addSelectedVolume($scope.selected.volumeName);
        }
        // If it does not already exist in $scope.cart, Mark selectedNode as
        // checked
        evaluateBranch($scope.tree, selectedNode, true);

        $scope.selected.isChecked = true;

        // Check Cart for Decendants
        if ($scope.cart.length) {
          removeDecendantNodesFromCart(selectedNode);
        }

        // add selected node to $scope.cart
        $scope.cart.push(selectedNode);

        SharedVMBrowserService.updateSelectedNodes($scope.cart);

      }
    };

    /**
     * Determines whether to show the Add to Cart button.
     *
     * @method    showAddToCartButton
     * @returns   {Boolean}   true if should show the button.
     */
    $scope.showAddToCartButton = function showAddToCartButton() {
      return !$scope.browsingView && !$scope.isItemInCart() &&
        !$scope.selected.isChecked;
    };

    /**
     * Removes previously selected decendant nodes of selectedNode
     * @param   {Object}   selectedNode   The selected node
     */
    function removeDecendantNodesFromCart(selectedNode) {

      var i = $scope.cart.length;

      // Loop though cart backwards so as not to disturb the
      // indexing order when removing items
      while (i--) {

        // splice cart item if path is found within selectedNode.path
        if (!fileTypes.includes(selectedNode.type) &&
          $scope.cart[i].path.includes(selectedNode.path)) {
          $scope.cart.splice(i, 1);
        }

      }
    }

    /**
     * Transforms Node into a format that can be parsed by other methods
     *
     * @method   transformSelectedNode
     * @param    {Object}   node   selection made by the user in vm browser tree
     * @return   {Object}   Transformed Node
     */
    function transformSelectedNode(node) {
      return {
        path: [node.volumeName, $scope.selected.path].join(''),
        name: node.name,
        volumeName: node.volumeName,
        isFolder: $scope.selected.isFolder,
        fullPath: node.fullPath,
        type: node.type,
        isVolume: node.isVolume && !node.isFolder,

        // The fileDocument property is consumed by recoverFilesParentFn
        fileDocument: {
          filename: _getFilename(node),
          size: node.size,
          objectId: {
            entity: vm.vmDocument.objectId.entity,
            jobId: vm.vmDocument.objectId.jobId,
            jobUid: vm.vmDocument.objectId.jobUid
          },
          versions: [$scope.currentSnapshot]
        },

        // Adapter specific properties.
        //
        // Recovery of files & folders within a OneDrive requires not just the
        // entity info but also needs the OneDrive Id and File structure
        // additionally.
        oneDriveSnapshotMetadata: {
          oneDriveId: $scope.oneDriveSnapshotMetadata.driveId,
          isFileStructureFlat: $scope.oneDriveSnapshotMetadata.
            isFileStructureFlat,
          isSharePointDrive: $scope.oneDriveSnapshotMetadata.isSharePointDrive
        },
        useLibrarian: $scope.state.useLibrarianForBrowse &&
          FEATURE_FLAGS.librarianBrowseEnabled
      };
    }

    /**
     * get filename for a node
     *
     * @method   getFilename
     * @param    {Object}   node   selection made by the user in vm browser tree
     * @return   {String}   filename
     */
    function _getFilename(node) {
      if (node.isVolume) {
        return node.volumeName;
      } else if (node.isFolder) {
        return [node.volumeName, node.path].join('');
      } else {
        return [node.volumeName, node.path, '/', node.name].join('');
      }
    }

    /**
     * Is Item in Cart
     * $scope method used to show/hide add/remove controls in the UI
     * @return {Boolean} Is the item already in the cart?
     */
    $scope.isItemInCart = function isItemInCart() {
      return itemIsInCart($scope.selected);
    };

    /**
     * Remove Item From Cart
     *
     * @method   removeFromCart
     * @param    {Object}        item  Object containing details of item to be
     *                                 removed.
     */
    $scope.removeFromCart = function removeFromCart(item) {

      var path = [item.volumeName, item.path].join('');
      var pathWithName = [path, item.name].join('');
      var markVolumeForRemoval = false;

      var foundIndex = $scope.cart.findIndex(function eachCartItem(cartItem) {
        var isNameMatch = item.name === cartItem.name;
        var isPathMatch = path.includes(cartItem.path);
        var isPathWithNameMatch = pathWithName.includes(
          [cartItem.path, cartItem.name].join('')
        );

        if (item.type === 'kDirectory') {
          // for directory we need to compare against the path
          return isPathMatch;
        } else if (fileTypes.includes(item.type)) {
          // for file we need to compare against the path including filename
          return isPathWithNameMatch;
        } else if (volumeTypes.includes(item.type) && isNameMatch) {
          // otherwise item is a volume. Direct name comparison is sufficient.
          // Mark the volume for removal after the branch has been evaluated
          // recursively
          markVolumeForRemoval = true;
          return true;
        }

        return false;
      });

      // remove item from cart.
      if (foundIndex !== -1) {
        evaluateBranch($scope.tree, $scope.cart[foundIndex], false);
        $scope.cart.splice(foundIndex, 1);
        item.isChecked = false;
      }

      if (!$scope.cart.length) {
        // cart was fully emptied, we can release the (possible) snapshot lock
        $scope.params.lockedSnapshot = undefined;
        lockedSnapshot = undefined;
      }

      // in cases where an item is removed via the cart but still
      // selected, this will allow the "Select" button to be displayed
      // immediately. This is necessary as items aren't added to the
      // cart by reference.
      if ($scope.selected.fullPath &&
        ($scope.selected.fullPath === item.fullPath ||
        $scope.selected.fullPath.includes(item.fullPath))) {
        $scope.selected.isChecked = false;
      }

      // remove the volume which was marked for removal above
      if (markVolumeForRemoval) {
        SharedVMBrowserService.removeSelectedVolume(item.volumeName);
      }

      SharedVMBrowserService.updateSelectedNodes($scope.cart);
    };

    /**
     * Removes all items in Cart
     *
     * @method  emptyCart
     */
    function emptyCart() {
      $scope.cart = [];
    }

    /**
     * indicates if an item is represented in the cart
     *
     * @method   itemIsInCart
     * @param    {object}   item    finderTree node
     * @return   {boolean}  true if item is in cart, false otherwise
     */
    function itemIsInCart(item) {
      return $scope.cart.some(
        function itemAlreadyExists(cartItem) {
          return item.fullPath === cartItem.fullPath;
        }
      );
    }

    /**
     * Resolve the Modal
     * passes the selected files back to the parent controller
     *
     * @param    {Boolean}    saveAndContinue    Flag signaling parent
     *                                           controller to advance to next
     *                                           step in flow
     */
    $scope.saveSelection = function saveSelection(saveAndContinue) {

      var obj = {
        files: $scope.cart,
        vm: $scope.vm,
        volumeName: $scope.selectedVolume.name,
        saveAndContinue: saveAndContinue,
        snapshot: $scope.currentSnapshot,
        _snapshot: $scope.currentSnapshot,
        _archiveTarget: $scope.currentSnapshot.replicaInfo &&
          $scope.currentSnapshot.replicaInfo.replicaVec.find(
            repl => repl.expiryTimeUsecs > 0,
          ),
      };
      $uibModalInstance.close(obj);
      SharedVMBrowserService.resetSelections();
    };

    /**
     * Creates a Share on the selected path.
     *
     * @method     createShare
     * @param      {Object}  selection  The selected directory object
     */
    $scope.createShare = function createShare(selection) {
      const auditingValue = selection.enableFilerAuditLog !== null ?
        selection.enableFilerAuditLog : undefined;

      const body = {
        enableFilerAuditLogging: auditingValue,
        viewName: $scope.view.name,
        viewPath: selection.path,
        name: selection.shareName,
        clientSubnetWhitelist: selection.subnetWhitelist,
        smbConfig: {
          permissions: selection.sharePermissions,
          superUserSids: selection.superUserSids,
        }
      };
      $scope.browserForm.$submitted = false;

      if ($scope.browserForm.$invalid) {
        $scope.browserForm.$submitted = true;
        return;
      }

      $scope.creatingShare = true;

      ViewService.createShare(body).then(
        function createShareSuccess() {
          cMessage.success({
            textKey: 'views.share.created',
          });

          $uibModalInstance.close(body);
          SharedVMBrowserService.resetSelections();
        },
        evalAJAX.errorMessage
      ).finally(
        function createShareFinally() {
          $scope.creatingShare = false;
        }
      );
    };

    /**
     * Creates a logical quota for a selected directory in the View.
     *
     * @method     createDirectoryQuota
     * @param      {Object}  selection  The selected directory object
     */
    $scope.createDirectoryQuota = function createDirectoryQuota(selection) {
      var quotaParams = {
        id: $scope.view.viewId,
        body: {
          directoryPath: selection.path,
          quotaPolicy: {
            alertLimitBytes: Math.round(selection.logicalQuotaBytes * 0.9),
            hardLimitBytes: selection.logicalQuotaBytes,
          },
        },
      };

      if ($scope.browserForm.$invalid) {
        return false;
      }

      $scope.creatingQuota = true;

      ViewService.updateDirectoryQuota(quotaParams).then(
        function createQuotaSuccess() {
          cMessage.success({
            textKey: 'views.directoryQuota.created',
          });

          $uibModalInstance.close(quotaParams);
          SharedVMBrowserService.resetSelections();
        },
        evalAJAX.errorMessage
      ).finally(
        function createQuotaFinally() {
          $scope.creatingQuota = false;
        }
      );
    };

    /**
     * Invokes the proper onSubmit function based on the page `mode`.
     *
     * @method     submitForm
     * @param      {Object}    selection  The selected directory object.
     * @param      {String}    mode       The mode enum file|share|quota.
     */
    $scope.submitForm = function submitForm(selection, mode) {
      switch (mode) {
        case 'share':
          $scope.createShare(selection);
          break;

        case 'quota':
          $scope.createDirectoryQuota(selection);
          break;

        default:
          noop();
      }
    };

    /**
     * Raises warnings for a Share name with certain characters and protocols.
     * This method is called on ng-change instead of ng-pattern because this is
     * not a blocking validation. The ng-pattern still retains its function of
     * blocking disallowed characters. This ng-change function merely raises
     * warnings which explain the implications of certain allowed, but
     * discouraged characters.
     *
     * @param      {String}  name    The new Share name
     * @param      {Object}  view    The view
     */
    $scope.validateShareName = function validateShareName(name, view) {
      $scope.nameWarningKeys =
        ViewService.getViewNameWarnings(name, view.protocolAccess);
    };

    /**
     * Dismiss the modal
     */
    $scope.cancelBrowse = function cancelBrowse() {
      $uibModalInstance.dismiss('user canceled');
      SharedVMBrowserService.resetSelections();
    };

    /**
     * Select a different snapshot
     *
     * @method   selectSnapshot
     * @param    {Object}   snapshot   Object containing the selected snapshot
     */
    $scope.selectSnapshot = function selectSnapshot(snapshot) {
      var opts;
      $scope.currentSnapshot = snapshot;

      // Check if Librarian is to be disabled for browse which happens only
      // when indexing is not done for the selected snapshot.
      if (!snapshot.isLibrarianBrowseSupported) {
        $scope.state.useLibrarianForBrowse = false;
      }

      if ($scope.cart.length) {
        // Prepare the modal before changing snapshot.
        opts = {
          title: $rootScope.text['cVmBrowser.selectSnapshotTitle'],
          content: $rootScope.text['cVmBrowser.selectSnapshotText'],
          actionButtonText: $rootScope.text['cVmBrowser.selectSnapshotOk']
        };

        // Show the modal.
        cModal.showModal({}, opts).then(function selectSnapshotSuccess() {
          updateParams($scope.currentSnapshot);
          // Reset VM Browser is executed when snapshot change is triggered with
          // non empty cart and user selects to empty the cart.
          resetVmBrowser();
        });
      } else {
        updateParams($scope.currentSnapshot);
        // Reset VM Browser is executed when a snapshot change is triggered
        // with an empty cart.
        resetVmBrowser();
      }

      // if we are browsing any source without volumes, we do not want to
      // make the getVolumes call we just want to make the getDir call
      if ($scope.hasNoVolumes) {
        if (vm && ENV_GROUPS.office365.includes(vm.vmDocument.environment) &&
            !($scope.state.useLibrarianForBrowse &&
            FEATURE_FLAGS.librarianBrowseEnabled)) {

          // For onedrive browse, switching the snapshot might cause change
          // from flat file to hierarchical and vice-versa. Need to update the
          // Onedrive metadata (_populateOneDriveMetadata) for this case.
          // Since sharepoint snapshots follow only flat structure, extra call
          // is not needed.
          if ($scope.oneDriveSnapshotMetadata.isSharePointDrive) {
            getDir(_getRootDirectoryNameForOneDrive(), false, true, true);
            return;
          }

          // _populateOneDriveMetadata call should be made in non-index-browse
          // mode as it traverses the snapFS. After its completion, reset to
          // original value.
          var useLibrarianForBrowse = $scope.state.useLibrarianForBrowse;
          $scope.state.useLibrarianForBrowse = false;
          _populateOneDriveMetadata().then(function onSuccess(res) {
            $scope.state.useLibrarianForBrowse = useLibrarianForBrowse;

            // Browse of a User's OneDrive should start from the root
            // directory instead of '/'.
            getDir(_getRootDirectoryNameForOneDrive(), false, true);
          });
          return;
        }

        // If browsing via librarian is enabled then the details are already
        // indexed, so no need to make the stat call.
        // In other cases, we need to make a stat call to get the required
        // info.
        getDir('/', false, false, $scope.state.useLibrarianForBrowse);
        return;
      }

      $scope.cartModal.isExpanded = false;
    };

    /**
     * Updates API params after a snapshot is selected
     *
     * @method   updateParams
     * @param    {object}    currentSnapshot    object containing selected
     *                                          snapshot data
     */
    function updateParams(currentSnapshot) {
      $scope.params.jobInstanceId = currentSnapshot.instanceId.jobInstanceId;
      $scope.params.attemptNum = currentSnapshot.instanceId.attemptNum;
      $scope.params.jobStartTimeUsecs =
        currentSnapshot.instanceId.jobStartTimeUsecs;
    }

    /**
     * Returns a string of the path corrosponding
     * to the Finder Tree panel with focus
     *
     * @param  {Integer} level Level as returned from Finder Tree
     * @return {String}  panelPath
     */
    function getFocusedPathString(level) {
      const inputPath = $scope.userInput || '';

      // If this is the root level,
      // return inputPath (if exists) or '/' immediately
      if (level === 0) {
        return inputPath || '/';
      }

      const panelPath =
        $scope.selected.path
          .slice(inputPath.length)
          .split('/')
          .slice(0, level + 1)
          .join('/');

      return inputPath + panelPath;
    }

    /**
     * isVolSelected
     * $scope method used to display checked icon on volumes
     * @return {Boolean} This volume is selected
     */
    $scope.isVolSelected = function isVolSelected(vol) {
      return SharedVMBrowserService.isVolSelected(vol);
    };

    /**
     * Scroll Right
     * Leverage jQuery to scroll right in vmBrowsePanel,
     * If the content width is less than the width of vmBrowsePanel
     * this animation effectively does nothing
     */
    function scrollRight() {
      var vmBrowsePanel = $doc.find('.vm-browser-panels');

      $(vmBrowsePanel).animate({
          scrollLeft: vmBrowsePanel.innerWidth(),
      }, 750);
    }

    /**
     * Detect scrolled to bottom. Leverage jQuery to detect the user has
     * scrolled to the bottom and request the next set of results.
     *
     * @method     detectScrollToBottom
     */
    function detectScrollToBottom() {
      angular.forEach(
        $doc.find('.parent-panel'),
        function watchForScroll(panel, index) {
          panel = angular.element(panel);

          panel
            // Unbind previous bound events
            .unbind('scroll')

            // Bind new events
            .bind(
              'scroll',
              function detectBottom() {
                var activePanel = angular.element(this);
                var activePath = getFocusedPathString(
                  +activePanel.attr('level')
                );

                // Calculate cookiePath based on
                // $scope.selectedVolume and activePath
                var cookiePath = [
                  $scope.selectedVolume._sanitizedVolumeName,
                  activePath
                ].join('');

                // don't make a new request when getDir request is in progress
                if (!$scope.fetching &&

                  // Not all result hits have been received yet
                  !$scope.metadata[cookiePath].isFinished &&

                  // get more directories when user reached almost bottom i.e.
                  // only 250px(25*10) alway from the bottom most point.
                  activePanel.scrollTop() + activePanel.innerHeight() >=
                    activePanel[0].scrollHeight - 250) {
                  // Let's refresh the active panel.
                  // TODO: Finder Tree clears out child sub directories at this
                  // point. Would be a nice enhancement to preserve child data.
                  getDir(activePath, false, false, false, true);
                }
              }
            );
        }
      );
    }

    /**
     * Fetch the input path entered and traverse browsable volumes directly
     *
     * ALGORITHM:
     * check for enter key press and fetch input path
     * clear the current displayed tree browser
     * replace '\' with '/'
     * fetch the volume and check for browsability
     * fetch the file path structure if path is browsable
     * getStatInfo(path) for the path
     * create a proxy node, either kDirectory || kFile
     * select the proxyNode amd update the tree
     * display the tree
     *
     * @param   {Object}  event   Event fired on ng-keydown inside input field
     */

    $scope.traverseFSDirectly = function traverseFSDirectly(event) {
      // TODO(Tauseef): Fix direct traversal for Onedrive with flat-file
      // hierarchy and Sharepoint doc-repo browse.
      var selectedVolume = '';
      var filePathStructure = {};
      var proxyNode = {};
      var inputPath = $scope.inputPath;

      $scope.state.isNonBrowsableVolume = false;
      $scope.state.isInvalidVolume = false;
      $scope.state.isNonBrowsablePath = false;

      // message flag
      $scope.state.hasAlert = false;

      if (!inputPath) { return; }

      if (event.keyCode === 13 && inputPath) {
        // clear current tree
        clearCurrentTree();

        // replace any '\' with '/' for consistent filepath
        inputPath = replaceStringFilter(inputPath.trim(), "\\\\", '/');

        // remove final '/'
        inputPath = inputPath.replace(/(?!^\/)\/*$/, '');

        // add initial '/'
        if (inputPath[0] !== '/') {
          inputPath = '/' + inputPath;
        }

        // Volume Validation
        selectedVolume = getMatchedPattern(inputPath, $scope.volumes);

        /**
         * If selectedVolume and inputPath are the same then an additional `/`
         * has to be appended if the last character is not `/` to the inputPath
         * for generating the fetchFilePathStructure correctly.
         * (Applicable to only Browsable Volumes)
         */
        if (selectedVolume === inputPath &&
          inputPath.charAt(inputPath.length - 1) !== '/') {
          inputPath = inputPath.concat('/');
        }

        // Update visible path based on trims.
        $scope.userInput = $scope.inputPath = inputPath;

        // Invalid volumes validation can only happen when browsing entities
        // exposed as servers(indexableServers). This doesn't include entities
        // backed up and exposed as views(indexableViews) and kView itself.
        if (!selectedVolume && !$scope.browsingView && !$scope.hasNoVolumes) {
          $scope.state.isInvalidVolume = true;
        } else {
          // Non browsable volumes can only be present when browsing entities
          // whose indexing is unsupported by Yoda currently.
          $scope.state.isNonBrowsableVolume =
            checkVolumeBrowsability(selectedVolume);
          if ($scope.state.isNonBrowsableVolume) {
            $scope.state.isNonBrowsablePath = true;
          } else {
            // fetch filepath structure for volumes/views
            filePathStructure = fetchFilePathStructure(inputPath,
              selectedVolume);

            // Create a proxy node
            proxyNode = {
              type: 'kDirectory',
              name: filePathStructure.fileName,
              fullPath: inputPath,
            };

            $scope.params.volumeName = selectedVolume;
            // In case of Entities without volumes, set empty object
            $scope.selectedVolume = find($scope.browsableVolumes,
                { name: selectedVolume }) || {};

            // Since librarian doesn't index volumes as documents, stat call to
            // librarian for volume will fail. So if browse via librarian is
            // enabled we directly call getDir() since we already know that the
            // volume exist.
            if ($scope.state.useLibrarianForBrowse &&
              (selectedVolume + '/' === inputPath ||
                selectedVolume === inputPath)) {
              getDir(filePathStructure.qualifiedPath, false /* isChecked */,
                true /* isUserInput */, true /* ignoreStat */);
              selectNode(proxyNode, filePathStructure.qualifiedPath);
              $scope.selected.path = filePathStructure.qualifiedPath;
              return;
            }

            // check for the filepath for kDirectory or kFile/kSymlink.
            // Note:
            // The API irisservices/api/v1/file/stat returns file statistics
            // containing size, modification time and the type.
            // The 'type' is mapped as:
            // <1, kFile>
            // <2, kDirectory>
            // <3, kSymlink>
            getStatInfo(filePathStructure.qualifiedPath)
            .then(function (pathType) {
              if (pathType === INDEXED_DOCUMENT_TYPE.kDirectory) {
                // Path is kDirectory
                getDir(filePathStructure.qualifiedPath, false, true, true);
                selectNode(proxyNode, filePathStructure.qualifiedPath);
                $scope.selected.path = filePathStructure.qualifiedPath;
              } else {
                // Path is kFile or kSymlink.
                var path = filePathStructure.directoryPath || '/';
                if (pathType === INDEXED_DOCUMENT_TYPE.kSymlink) {
                  proxyNode.type = 'kSymlink';
                } else {
                  proxyNode.type = 'kFile';
                }
                getDir(path, false, true, true);
                selectNode(proxyNode, path);
                $scope.selected.path = path;
              }
            });
          }
        }
      }

      // analyze message flag
      $scope.state.hasAlert = $scope.state.isNonBrowsableVolume ||
        $scope.state.isNonBrowsablePath || $scope.state.isInvalidVolume;

    };

    /**
     *  Clears current tree displayed for file system browser
     */
    function clearCurrentTree() {
      $scope.tree.displayed = false;
      _resetDataTree();

      $scope.metadata = {};
      angular.extend($scope.selected, defaultSelectionCounts);
    }

    /**
     * Generates the correct cookie path for all the volumes using their
     * sanitized volume name for correctly matching currentDirectoryPath and
     * the branch.fullPath within the @method addBranch(...).
     *
     * @method    _sanitizeCookiePath
     * @param     {String}   currentDirectoryPath   Full path of the current
     *                                              directory.
     * @param     {Object}   currentVolume          Object containing volume
     *                                              details
     * @return    {String}   correct cookie path at which the branch is to be
     *                       checked for update/add.
     */
    function _sanitizeCookiePath(currentDirectoryPath, currentVolume) {
      var selectedVolume = currentVolume || $scope.selectedVolume;
      if (!currentDirectoryPath) { return; }

      // Absence of volume specifies the browse of Entities without Volume
      // (ENV_GROUPS.indexableEntitiesExposedAsViews).
      if (isEmpty(selectedVolume)) { return currentDirectoryPath; }

      return ''.concat(selectedVolume._sanitizedVolumeName || '',
        currentDirectoryPath);
    }

    /**
     * Check for Browser compatibility
     *
     * @param  {String}   inputVolume   Volume entered by user
     * @return {Boolean}  true if non-browsable volumes contain the volume
     *                    entered
     */
    function checkVolumeBrowsability(inputVolume) {
      return $scope.nonBrowsableVolumes.some(function(vol) {
          return vol.displayName === inputVolume;
      });
    }

    /**
     * Generate file Path structure
     *
     * @param  {String}  inputPath       input string entered by user
     * @param  {String}  selectedVolume  volume entered by user
     * @return {Object}  filepath structure with fully qualified path, directory
     *                   & filename
     */
    function fetchFilePathStructure(inputPath, selectedVolume) {
      var fpStructure = {
        qualifiedPath: '',
        directoryPath: '',
        fileName: ''
      };

      var delimiterIndex = 0;

      if (!inputPath) { return fpStructure; }

      /**
       * Entities having Views, volumes are not present hence inputPath
       * shouldn't be parsed
       */
      if ($scope.browsingView) {
        fpStructure.qualifiedPath = fpStructure.directoryPath = inputPath;
        return fpStructure;
      }

      /**
       * Entities which have directory structures and can be browsed as
       * directories should have their inputPaths parsed with their
       * qualifiedPaths containing the file/folder name.
       */
      if ($scope.hasNoVolumes) {
        delimiterIndex = inputPath.lastIndexOf('/');
        fpStructure.qualifiedPath = inputPath;
        fpStructure.directoryPath = inputPath.slice(0, delimiterIndex);
        fpStructure.fileName = inputPath.slice(delimiterIndex+1,
          inputPath.length);

        return fpStructure;
      }

      /**
       * Entities which have volume blocks and can be browsed as volumes should
       * have their inputPaths parsed with their qualifiedPaths containing the
       * volume and file/folder name.
       */
      if (!selectedVolume) { return fpStructure; }

      delimiterIndex = inputPath.lastIndexOf('/');

      // For mounted folders, the selected volume will be the same as inputPath.
      // In this case, assume the selected volume to be 'root' and the rest of
      // the path to be the directory.
      // In case user input is provided and click-based traversal is not done,
      // use selectedVolume for computing directoryPath.
      if (selectedVolume === inputPath || !$scope.userInput) {
        fpStructure.directoryPath =
            inputPath.slice($scope.selectedVolume.name.length, delimiterIndex);
      } else {
        fpStructure.directoryPath =
            inputPath.slice(selectedVolume.length, delimiterIndex);
      }

      /**
       * Ensure directoryPath has a leading `/`
       * This condition arises with volume name `/`
       */
      if (fpStructure.directoryPath &&
          fpStructure.directoryPath.charAt(0) !== '/') {
        fpStructure.directoryPath = ''.concat('/', fpStructure.directoryPath);
      }

      fpStructure.fileName = inputPath.slice(delimiterIndex+1,
        inputPath.length);

      /**
       * The qualified path should contain the file name (if any) or if it is a
       * volume name ie. no file name or no directory path, then it should have
       * a '/' in its qualified path.
       */
      if (!fpStructure.fileName && fpStructure.directoryPath) {
        fpStructure.qualifiedPath = fpStructure.directoryPath;
      } else {
        fpStructure.qualifiedPath =
          fpStructure.directoryPath.concat('/', fpStructure.fileName);
      }

      return fpStructure;
    }

    /**
     * Fetch matched string from input string
     *
     * @param  {String}  inputString   string in which pattern is to be matched
     * @param  {Array}   patternArray  array of patterns which are to be matched
     * @return {String}                matchedString within patternArray
     */
    function getMatchedPattern(inputString, patternArray) {
      // weight stores the max of matched pattern lengths
      var currentWeight = 0;
      var matchedString = '';

      // the pattern to compare the inputString with
      var comparator;

      if (!inputString || !patternArray || patternArray.length === 0) {
        return '';
      }

      patternArray.forEach(function matchPattern(pattern) {
        comparator = '';

        /**
         * displayName || name should be match within the input string entered
         * or selected by user.
         *
         * For full path such as `/F/some_dir/Some_file`
         * If there is a volume `/S` occurring after `/F` in patternArray the
         * match will be successful and volume fetched will be `/S` instead. To
         * avoid this we must make sure we are looking at the start of path but
         * volumes can be composed of `/` in their names too, hence we cannot
         * split and compare. Thus, there is a need to have 0 as the
         * startingIndex .
         */
        if (inputString.indexOf(pattern.displayName) === 0 ) {
          comparator = pattern.displayName;
        }

        if (inputString.indexOf(pattern.name) === 0 ) {
          comparator = pattern.name;
        }

        if (comparator) {
          // The comparator(volume) may or may not have a trailing slash which
          // needs sanitization if the volume name itself is not '/'.
          //
          // This step is necessary to correctly match 'inputString' with a
          // comparator(volume) which have trailing slashes in their 'name' or
          // 'displayName' property.
          if (comparator.length &&
              comparator !== '/' &&
              comparator.charAt(comparator.length - 1) === '/') {
            comparator = comparator.slice(0, -1);
          }

          // An 'inputString' matching the pattern with a longer length will be
          // preferred over the other.
          if (pattern.name.length > currentWeight &&

            // default volume if nothing else matches
            (comparator === '/' ||

              // /mnt === /mnt
              inputString === comparator ||

              // inputString /mnt/vol1 compared with /mnt + '/'
              // This ensures that the matched string is a volume appended with
              // a '/' at the end of matched pattern.
              inputString.indexOf(comparator + '/') === 0)) {

            matchedString = pattern.name;
            currentWeight = pattern.displayName.length;
          }
        }
      });

      return matchedString;
    }

    /**
     * Update selection counts in `setCountsIn` with
     * dirCount, fileCount and symlinkCount values from `takeCountsFrom`
     *
     * @param  {Object}  setCountsIn
     * @param  {Object}  takeCountsFrom
     */
    function _updateSelectionCount(setCountsIn,
      { dirCount, fileCount, symlinkCount } /* takeCountsFrom */) {
      angular.extend(setCountsIn, { dirCount, fileCount, symlinkCount });
    }

    /**
     * Assemble the inputs and outputs for the downgraded NG Super Users
     * component.
     *
     * @method     _assembleWhitelistTableComponentInputs
     */
    function _assembleWhitelistTableComponentInputs () {
      $scope.whitelistTableInputs = {
        whitelists: $scope.selected.subnetWhitelist || [],
        hideNFS: !$scope.view._hasNfs,
        hideSMB: !$scope.view._hasSmb,
        hideS3: !$scope.view._hasS3,
        hideSquash: true,
        showOrgs: false,
      };

      $scope.whitelistTableOutputs = {
        editWhitelist: $scope.editWhitelist,
        deleteWhitelist: $scope.deleteWhitelist,
      };
    }

    /**
     * Helper function to add new whitelist.
     *
     * @method   addWhitelist
     */
    function addWhitelist() {
      _modifyWhitelist('add');
    }

    /**
     * Helper function to edit existing whitelist
     *
     * @method   editWhitelist
     * @param    {Object}   The whitelist to be edited.
     */
    function editWhitelist(whitelist) {
      _modifyWhitelist('edit', whitelist);
    }

    /**
     * Helper function to delete existing whitelist
     *
     * @method   deleteWhitelist
     * @param    {Object}   The whitelist to be deleted.
     */
    function deleteWhitelist(whitelist) {
      _modifyWhitelist('delete', whitelist);
    }

    /**
     * Private helper function to modify whitelist.
     *
     * @method   _modifyWhitelist
     * @param    {string}   The action string. Possible values are edit, add, delete
     * @param    {Object}   The whitelist to be modified.
     */
    function _modifyWhitelist(actionType, whitelist) {
      const data = {
        whitelists: $scope.selected.subnetWhitelist,
        type: actionType,
        isGlobal: false,
        hideNfsSquash: true,
        viewProtocols: $scope.view.protocolAccess.map(protocol => protocol.type),
      };

      // transforming data based on action type.
      if (['edit', 'delete'].includes(actionType)) {
        data.existingWhitelist = whitelist;
      }

      // Opens dialog to update whitelist.
      ngDialogService.showDialog('whitelist-dialog', data).toPromise()
        .then(function afterClosed(updatedWhitelist) {
          if (!updatedWhitelist) {
            return Promise.reject();
          }

          // update view data model
          $scope.selected.subnetWhitelist = updatedWhitelist.filter(
            function(element) {
              return element !== undefined;
            });

          // Shim the updated entries according to NG Views Service
          $scope.selected.subnetWhitelist = NgViewsService.transformIPWhitelist($scope.selected.subnetWhitelist);

          // update whitelistInput.
          _assembleWhitelistTableComponentInputs();
        })
        .catch(Promise.reject);
    }

    /**
     * Assemble the inputs and outputs for the downgraded NG Super Users
     * component.
     *
     * @method     _assembleSuperUserComponentInputs
     */
    function _assembleSuperUserComponentInputs () {
      $scope.superUsersInputs = {
        // Component expects an observable of domainPrincipalsHash.
        domainPrincipalsHash: $scope.flatPrincipalsHash,
        trustedDomains: $scope.clusterTrustedDomains,
        superUserSids: [],
        removeValidator: true,
      };
      $scope.superUsersOutputs = {
        updatePermissions: $scope.onChangeSuperUsers,
      };

      $scope.superUsersComponentReady = true;
    }

    /**
     * On changed list of Super Users, updates the local model and issues an
     * update API request if necessary.
     *
     * @method   onChangeSuperUsers
     * @param    {array}    superUsersSids    List of Super User SIDs
     */
    function onChangeSuperUsers(superUsersSids) {
      $scope.selected.superUserSids = superUsersSids;
    }

    /**
     * Listen for 'vm-browser-node-selected' event. Initializes a getDir API
     * call based on path.
     *
     * @param      {object}  event   the event object
     * @param      {object}  data    event data
     */
    rootScopeListener = $rootScope.$on(
      'vm-browser-node-selected',
      function browserNodeSelected(event, data) {
        // Join the path array returned from finder tree into a string.
        var path = data.path.join('/');

        // Fetch selected volume from path
        var selectedVolume = getMatchedPattern(path, $scope.volumes);

        /**
         * selectedVolume in click traversal of Filesystem can never be null but
         * is undefined when browsing views
         */
        var filePathStructure = fetchFilePathStructure(path, selectedVolume);

        if (!data.type || data.type === 'kDirectory') {
          /**
           * assign directory path(filepath with dir name) if kDirectory for
           * updating selectNode(path)
           */
          path = filePathStructure.qualifiedPath;

          /**
           * call getdir(path)
           * If the directory isn't in the cart (checked) then don't pass a
           * value for isChecked so the child items can be evaluated against
           * the cart.
           */
          data.isChecked = !!data.isChecked;

          // if it is sharepoint non-indexed browse and if doc-repo is
          // clicked, append metadata in the path for getDir() call.
          if ($scope.oneDriveSnapshotMetadata.isSharePointDrive &&
              !($scope.state.useLibrarianForBrowse &&
              FEATURE_FLAGS.librarianBrowseEnabled) &&
              // When doc-repo is clicked, data.path has following 3 entries:
              // "", "OneDrives" and "Sharepoint-drive-name"
              data.path.length === dirsInSharepointDrivePath) {
            path += o365RocksDBPathSuffix;
          }
          getDir(path, data.isChecked).then(scrollRight);
        } else if (fileTypes.includes(data.type)) {
          // get stat for the selected file.
          getStatInfo(filePathStructure.qualifiedPath);

          /**
           * assign directory path(filepath without filename) if kFile/kSymlink
           * for updating selectNode(path)
           */
          path = filePathStructure.directoryPath;
        }

        // Update input box to show current path
        $scope.inputPath = path === selectedVolume ?
          path : _sanitizeCookiePath(path);

        // Save previous selected counts for future use
        if ($scope.metadata[$scope.selected.cookiePath]) {
          _updateSelectionCount(
            $scope.metadata[$scope.selected.cookiePath],
            $scope.selected);
        }

        // Update Preview Panel
        if ($scope.hasNoVolumes) {
          selectDirectoryNode(data, path);
        } else {
          selectNode(data, path);
        }

        // if there are saved counts fetch them and set selected counts
        // or set them to 0
        _updateSelectionCount($scope.selected,
          $scope.metadata[$scope.selected.cookiePath] ||
          defaultSelectionCounts);

        detectScrollToBottom();
      }
    );

    $scope.$on('$destroy', rootScopeListener);

    // Here we are now, Entertain us!
    activate();
  }
})(angular);
