// Module: SQL Dashboard
import { getConfigByKey } from '@cohesity/iris-core';

;(function(angular, undefined) {

  var dashboardInjections = [
    'C.widgets',
    'C.dbDetails',
    'C.statList',
    'C.sqlDashboardService'
  ];

  angular
    .module('C.sqlDashboard', dashboardInjections)
    .config(sqlDashboardConfigFn);

  function sqlDashboardConfigFn($stateProvider) {

    /**
     * This function does all the various checks against feature flags
     * and contexts and lastly if there is a specific platform configuration.
     *
     * @param stateAccessContext
     * @returns boolean whether user has access
     */
    function canAccess(stateAccessContext) {
      return stateAccessContext['PROTECTION_VIEW'] &&
        stateAccessContext.FEATURE_FLAGS.sqlDashboardEnabled &&
        stateAccessContext.isClusterUser &&
        !stateAccessContext.irisContext.clusterInfo._allClusters &&
        (stateAccessContext.isTenantUser || stateAccessContext.FEATURE_FLAGS.sqlDashboardForTenants) &&
        (stateAccessContext.isRestrictedUser || stateAccessContext.FEATURE_FLAGS.sqlDashboardForRestrictedUsers) &&
        getConfigByKey(stateAccessContext.irisContext, 'dashboards.features.dashboard-mssql', true);

    }

    $stateProvider
    .state('sql-dashboard', {
      url: '/dashboard/sql',
      title: 'MS SQL Dashboard',
      help: 'dashboard_sql',
      component: 'sqlDashboard',
      canAccess: canAccess,
      redirectTo: 'sql-dashboard.hosts',
    })
    .state('sql-dashboard.hosts', {
      url: '^/dashboard/sql',
      title: 'MS SQL Dashboard',
      help: 'dashboard_sql',
      canAccess: canAccess,
      parentState: 'sql-dashboard',
      component: 'sqlSourceList',
    })
    .state('sql-dashboard.databases', {
      url: '^/dashboard/sql/dbs',
      title: 'MS SQL Dashboard - Databases',
      help: 'dashboard_sql',
      canAccess: canAccess,
      parentState: 'sql-dashboard',
      component: 'sqlDatabaseList',
    });
  }

})(angular);
