import { forEach } from 'lodash-es';
import { constant } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: cModal HOC Component

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Resolved bindings provided via uib-modal
       *
       * @type  {Object}  [resolve=undefined]
       */
      resolve: '=',

      /**
       * uib-modal instance used to close or dismiss then modal
       *
       * @type  {Object}  [resolve=modalInstance]
       */
      modalInstance: '=',
    },
    controller: 'cModalHocCtrl',
    templateUrl: 'app/global/cModal/c-modal-hoc.html',
  };

  /**
   * @ngdoc component
   * @name C:cModalHoc
   * @function
   *
   * @description
   * Provides a wrapper over component to incorporate common templates and
   * methods. This also provides the flexibility to use ng-model by making this
   * a middleware which takes in the default value of the ng-model and gives it
   * to the inner component which when modified, the changes is propogated to
   * the parent caller.
   *
   * @requires   innerComponent [resolve.innerComponent]  This is the component
   *                                                      name which is passed
   *                                                      to the
   *                                                      c-compile-component
   * @example
   * The component config which is passed inside the resolve block is of the
   * type
   * {
   *  innerComponent: 'inner component name',
   *  idKey: 'component id key',
   *  titleKey: 'value',
   *  actionButtonKey: 'value',
   *  closeButtonKey: 'value',
   *  closeButtonDisabled: 'value',
   *  bindings: {
   *    ...
   *  }
   * }
   * Where innerComponent and titleKey need no description. The idKey is the
   * key attached to the buttons in the template such as
   * `close-{{key}}-modal-button` which is required for IDs automation. The
   * closeButtonDisabled is used to enable or disable close button.
   *
   * The bindings includes the bindings which are to be passed to the inner
   * component. It also includes the ngModel value.
   */
  angular.module('C')
    .controller('cModalHocCtrl', cModalHocCtrlFn)
    .component('cModalHoc', configOptions);

  function cModalHocCtrlFn(_, $q) {
    var $ctrl = this;

    assign($ctrl, {
      // Component methods.
      save: save,
      cancel: cancel,
      close: close,

      // Component life-cycle methods.
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      assign($ctrl, {
        // The methods which can be overridden inside the inner component
        // to incorporate form validations and API calls etc. If these are
        // not overridden then by default resolve the bindings passed to the
        // component.
        onModalSave: constant($q.resolve($ctrl.resolve.bindings)),
        onModalCancel: constant($q.resolve($ctrl.resolve.bindings)),
        onModalClose: constant($q.resolve($ctrl.resolve.bindings)),
      });

      // Assign default options for text keys in the modal.
      $ctrl.resolve = assign({
        actionButtonKey: 'ok',
        closeButtonKey: 'cancel',
      }, $ctrl.resolve);

      // Adding inModal key which gets passed to the inner component as a
      // binding.
      $ctrl.bindings = {
        inModal: true,
      };

      // For each resolved bindings, map the key value to the $ctrl.bindings
      // object as a string which is required for the c-compile-component.
      forEach($ctrl.resolve.bindings, function eachBinding(value, key) {
        $ctrl.bindings[key] = '$ctrl.resolve.bindings.' + key;
      });
    }

    /**
     * On save, return the selected value.
     *
     * @method   save
     * @param    {*} resolveData  Optional data to resolve the promise with
     */
    function save(resolveData) {
      var promise =  $ctrl.onModalSave();

      if (resolveData) {
        promise.then($ctrl.modalInstance.close.bind(this, resolveData));
      } else {
        promise.then($ctrl.modalInstance.close);
      }
    }

    /**
     * handles on cancel action for modal
     *
     * @method   cancel
     * @param    {*} resolveData  Optional data to resolve the promise with
     */
    function cancel(resolveData) {
      var promise =  $ctrl.onModalCancel();

      if (resolveData) {
        promise.then($ctrl.modalInstance.dismiss.bind(this, resolveData));
      } else {
        promise.then($ctrl.modalInstance.dismiss);
      }
    }

    /**
     * handles on dismiss action for modal
     *
     * @method   close
     * @param    {*} resolveData  Optional data to resolve the promise with
     */
    function close(resolveData) {
      var promise =  $ctrl.onModalClose();

      if (resolveData) {
        promise.then($ctrl.modalInstance.dismiss.bind(this, resolveData));
      } else {
        promise.then($ctrl.modalInstance.dismiss);
      }
    }
  }

})(angular);
