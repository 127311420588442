import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Service: JobRunsServiceFormatter utility service.
;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobRunsServiceFormatter', [])
    .service(
      'JobRunsServiceFormatter',
      JobRunsServiceFormatterFn
    );

  function JobRunsServiceFormatterFn($rootScope, cUtils, JobService,
    $filter, FEATURE_FLAGS, ENV_GROUPS, DateTimeService) {

    // This Service's API
    return {
      decoratePublicJobRuns: decoratePublicJobRuns,
      transformProtectionRun: transformProtectionRun,
      transformScheduleContext: transformScheduleContext,
    };

    /**
     * transformation function for injecting useful information into job runs
     * after receiving them from the API
     *
     * @method     transformProtectionRun
     * @param      {object}  protectionRun  The protection run
     * @return     {object}  the updated/modified protection run
     */
    function transformProtectionRun(protectionRun) {
      // Based on BACKUP_JOB_RUN_ORIGINATOR_TYPE.
      var originatorType;
      var backupRun;
      var copyRun;
      var backupTask;
      var hasNewDataLock;

      if (!protectionRun) {
        return;
      }

      protectionRun = addCopyTaskFlagsToRun(protectionRun);
      backupRun = protectionRun.backupRun;
      copyRun = protectionRun.copyRun || {};
      originatorType = backupRun.originatorType || 1;

      backupRun.base._dedupe = cUtils.getRatio(
        backupRun.base.totalLogicalBackupSizeBytes,
        backupRun.base.totalPhysicalBackupSizeBytses
      );

      // Adding local backup task decorators to backup run
      if (copyRun.finishedTasks && copyRun.finishedTasks[0]) {
        // Finding the local backup task from the copy runs
        backupTask = copyRun.finishedTasks.find(
          function loopThroughFinishedTaks(task) {
            return task.snapshotTarget && task.snapshotTarget.type === 1;
          }
        );

        // Local backup legal hold
        if (FEATURE_FLAGS.legalHoldPerTarget) {
          backupRun._legalHoldEnabled = !!backupTask.legalHoldEnabled;
        }

        // Check new datalock
        hasNewDataLock = copyRun.finishedTasks.some(
          function hasTaskWithNewDataLock(task) {
            return !!(task.dataLockConstraints &&
              task.dataLockConstraints.dataLockExpiryUsecs);
          }
        );

        // Decorations are different for old datalock runs vs new datalock runs
        // as they differ in behavior.
        if (hasNewDataLock) {
          copyRun.finishedTasks.forEach(
            function loopThroughFinishedTaks(task) {
              // Set datalock for copy targets
              if (task.dataLockConstraints) {
                // If new datalock definition is present
                task._dataLockExpiryUsecs = task.dataLockConstraints.dataLockExpiryUsecs;
              }

              // Set datalock for local snapshot if snapshot is not expired or
              // deleted and dataLockConstraints are present
              if (task.snapshotTarget && task.snapshotTarget.type === 1 &&
                !backupRun.snapshotsDeleted && !!task.dataLockConstraints) {
                backupRun._dataLockExpiryUsecs = task.dataLockConstraints.dataLockExpiryUsecs;
                backupRun._dataLockExpired = backupRun._dataLockExpiryUsecs < DateTimeService.getCurrentUsecs();
                backupRun._dataLocked = !backupRun._dataLockExpired;
              }
            }
          );
        } else {
          copyRun.finishedTasks.forEach(
            function loopThroughFinishedTaks(task) {
              // Set datalock for copy targets
              if (get(backupRun, "wormRetention.policyType") === 'kCompliance') {
                // Convert old datalock to new datalock definition with snapshot
                // expiry as datalock expiry.
                task.dataLockConstraints = {
                  policyType: 'kCompliance'
                };
                task._dataLockExpiryUsecs = task.expiryTimeUsecs;
              }

              // Set datalock for local snapshot if snapshot is not expired or
              // deleted and dataLockConstraints are present
              if (task.snapshotTarget && task.snapshotTarget.type === 1 &&
                !backupRun.snapshotsDeleted && !!task.dataLockConstraints) {
                backupRun._dataLockExpiryUsecs = task.expiryTimeUsecs;
                backupRun._dataLockExpired = task.expiryTimeUsecs < DateTimeService.getCurrentUsecs();
                backupRun._dataLocked = !backupRun._dataLockExpired;
              }
            }
          );
        }

        // Check for legal hold
        copyRun.finishedTasks.forEach(
          function loopThroughFinishedTaks(task) {
            if (task.snapshotTarget && task.snapshotTarget.type === 1) {
              // ExpiryTime value 0 means a user has deleted the snapshot. A past
              // date would suggest that snapshot is past retention period.
              backupRun.base._expiryTimeUsecs = task.expiryTimeUsecs;

              if (task.entityLegalHoldMap) {
                backupRun._entitiesOnLegalHold =
                  task.entityLegalHoldMap.map(
                    function getLegalHoldEntityIds(entity) {
                      return entity.key;
                    }
                  );
              }
            }

            // If legal hold at target level is not enabled and if any task has
            // legal hold, we can confirm that the whole run has legal hold.
            if (!FEATURE_FLAGS.legalHoldPerTarget) {
              backupRun._legalHoldEnabled = backupRun._legalHoldEnabled || task.legalHoldEnabled;
            }
          }
        );
      }

      // This determines if the snapshots of the backup run can be deleted and
      // can be used to disable checkboxes while deleting job runs.
      backupRun._isDeletedDisabled = _isDeleteRunDisabled(backupRun);

      backupRun.base._status = JobService.getStatus(backupRun);
      backupRun.base._errorMsg = JobService.getErrorMsg(backupRun);
      backupRun._slaString = getSlaStatusString(backupRun);
      backupRun._slaStatusClass = getSlaStatusClass(backupRun);

      // If snapshots are not deleted, but the expiryTime is 0, display
      // this run as 'Marked for Deletion'.
      backupRun._snapshotsMarkedForDeletion =
        (!backupRun.snapshotsDeleted && copyRun &&
          copyRun.finishedTasks &&
          copyRun.finishedTasks[0] &&
          copyRun.finishedTasks[0].expiryTimeUsecs === 0);

      // Expressions based on BACKUP_JOB_RUN_ORIGINATOR_TYPE
      angular.extend(protectionRun, {
        _isJobRunLocal: originatorType === 1,
        _isJobRunRemote: originatorType === 2,
        _isJobRunFromArchive: originatorType === 3,
        _hasLocalSnapshot: hasLocalSnapshot(protectionRun),
      });

      // The below decrators have been duplicated in backupRun object as this
      // object is used at job details page. Do add new decorators below if
      // introduced to the protectionRun.
      backupRun._hasArchiveTask = protectionRun._hasArchiveTask;
      backupRun._hasReplicationTask = protectionRun._hasReplicationTask;
      backupRun._hasLocalSnapshot = protectionRun._hasLocalSnapshot;

      // A protection run should always have a copyRun, but provide a failsafe
      // just in case. Also create a quick refer
      protectionRun.copyRun = transformCopyRun(protectionRun);

      backupRun._isFinished =
        ['kSuccess', 'kFailure', 'kCanceled']
          .includes(backupRun.base.publicStatus);

      return protectionRun;
    }



    /**
     * Decorates a copyRun with convenience properties for easier template
     * consumption.
     *
     * @method   transformCopyRun
     * @param    {object}   protectionRun   The protectionRun containing the
     *                                      copyRun to be decorated
     * @return   {object}   The transformed/updated copyRun
     */

    function transformCopyRun(protectionRun) {

      var copyRun = protectionRun.copyRun || {};

      // Make sure these tasks arrays are always present so they can be looped
      // etc without fear of error.
      copyRun.finishedTasks = copyRun.finishedTasks || [];
      copyRun.activeTasks = copyRun.activeTasks || [];
      copyRun._isFinished = !!copyRun.endTimeUsecs;

      copyRun._rxTasks = copyRun.finishedTasks.concat(copyRun.activeTasks)
        .filter(function filterRxTasks(task) {
          if (task.snapshotTarget &&
            [2, 4, 5].includes(task.snapshotTarget.type)) {

            // This is a replication task. Add desired convenience properties.
            task._isActive = copyRun.activeTasks.includes(task);

            task._isReplication = task.snapshotTarget.type === 2;
            task._isCloudReplication = task.snapshotTarget.type === 5;
            task._isCloudDeploy = task.snapshotTarget.type === 4;

            task.finishedCopySubTasks = task.finishedCopySubTasks || [];
            task.activeCopySubTasks = task.activeCopySubTasks || [];

            task._allCopySubTasks =
              task.finishedCopySubTasks.concat(task.activeCopySubTasks);

            // These default values to be incremented based on subTasks in
            // immediately following forEach().
            task._rxTransferInfo = {
              bytesTransferred: 0,
              logicalBytesTransferred: 0,
              logicalSizeBytes: 0,
              metadataActionsCompleted: 0,
              estimatedLogicalBytesToTransfer: 0,

              // This property will not be incremented because it is the same
              // value for all subtasks. We simply take the first.
              blackoutRemainingTimeUsecs: get(task._allCopySubTasks,
                '[0].replicationInfo.blackoutRemainingTimeUsecs'),
            };

            task._allCopySubTasks.forEach(function loopSubTasks(subTask) {

              subTask._isActive = task.activeCopySubTasks.includes(subTask);

              subTask._queuedTimeUsecs =
                deriveRxTaskQueuedTime(
                  protectionRun.backupRun, subTask.backupTaskId);

              if (subTask.status === 5 && subTask.replicationInfo) {
                // Job was cancelled, so set the sub task end time same as the
                // job end time if this sub task wasn't completed.
                subTask.replicationInfo.endTimeUsecs =
                  subTask.replicationInfo.endTimeUsecs || subTask.endTimeUsecs;
              }

              if (task.snapshotTarget.type === 2) {
                // Add bytes info to the parent task aggregated info.
                task._rxTransferInfo.logicalBytesTransferred +=
                  subTask.replicationInfo.logicalBytesTransferred || 0;
                task._rxTransferInfo.bytesTransferred +=
                  subTask.replicationInfo.bytesTransferred || 0;
                task._rxTransferInfo.logicalSizeBytes +=
                  subTask.replicationInfo.logicalSizeBytes || 0;
                task._rxTransferInfo.estimatedLogicalBytesToTransfer +=
                  subTask.replicationInfo.estimatedLogicalBytesToTransfer || 0;
                task._rxTransferInfo.metadataActionsCompleted +=
                  subTask.replicationInfo.metadataActionsCompleted || 0;
              }
            });

            return true;
          }
          // Lack of return (undefined) filters out non replication copyTasks.
        });
      return copyRun;
    }

    /**
     * Take inline schedule and return ui.json readable translate context
     *
     * @method   transformScheduleContext
     * @param    {object}   startTime           Job start time.
     * @param    {object}   stubbingPolicy      Data migration job policy.
     * @return   {object}                       ui.json readable value.
     */
    function transformScheduleContext(startTime, stubbingPolicy) {
      return {
        'timeObj': $filter('assembleTimeString')
          ({ hour: startTime.hour, minute : startTime.minute}),
        'weeklyScheduledDays':
          get(stubbingPolicy.schedulingPolicy.dailySchedule, 'days')?
          $filter('humanizeArrayOfDays')
            (stubbingPolicy.schedulingPolicy.dailySchedule.days) :
          undefined,
        'monthlyScheduledDays':
          stubbingPolicy.schedulingPolicy.monthlySchedule ?
          $filter('humanizeArrayOfDays')
            ([stubbingPolicy.schedulingPolicy.monthlySchedule.day]) :
          undefined,
        'monthlyScheduledDayCount':
          stubbingPolicy.schedulingPolicy.monthlySchedule ?
          $filter('ordinaryDayCount')
            (stubbingPolicy.schedulingPolicy.monthlySchedule.count) :
          undefined,
      };
    }

    /**
     * Determine endtime for provided backup task id. This is used as a
     * mechanism for deriving a queued time for replication objects, as
     * icebox/thunderbolt internalizes this information. In cases where an
     * object's backup is retried, the queued time will become the end time of
     * the most recent attempt to backup the object.
     *
     * @method     deriveRxTaskQueuedTime
     * @param      {number}  backupTaskId  The backup task id
     * @return     {number}  return endTimeUsecs or undefined
     */
    function deriveRxTaskQueuedTime(backupRun, backupTaskId) {
      var endTimeUsecs;

      (backupRun.latestFinishedTasks || []).some(
        function testForMatchingBackupTask(task) {
          if (task.taskId === backupTaskId) {
            return endTimeUsecs = task.base.endTimeUsecs;
          }
        }
      );

      return endTimeUsecs;
    }

    /**
     * Sets a number of archive & replication status flags on a given run
     * object.
     *
     * @method   addCopyTaskFlagsToRun
     * @param    {Object}   run   Job Run Object
     * @return   {Object}   Updated Job Run Object
     */
    function addCopyTaskFlagsToRun(run) {
      // First we will manually set _hasArchiveTasks and _hasReplicationTasks to
      // false.
      run._hasArchiveTask =
        run._hasCloudTask =
        run._hasTapeTask =
        run._hasReplicationTask =
        run._hasIndexingTask =
        run._hasCloudDeployTask = false;

      // Required property is missing, exit early.
      if (!run.copyRun) {
        return run;
      }

      // Does this job have indexing tasks
      if (FEATURE_FLAGS.yodaJobIndexingPulseEnabled &&
        run.copyRun.finishedTasks &&
        run.copyRun.finishedTasks[0]) {
        run._hasIndexingTask = !!run.backupRun.yodaProgressMonitorRootPath;
      }

      // CSM jobs do not support indexing since they
      // dont store snapshots locally
      if (ENV_GROUPS.cloudJobsWithoutLocalSnapshot
        .includes(run.backupRun.base.type)) {
          run._hasIndexingTask = false;
        }

      // Combine active and completed tasks into a single array and evaluate the
      // flags against the mixed list. Terminates early when all flags are true.
      (run.copyRun.activeTasks || []).concat(run.copyRun.finishedTasks || [])
        .some(function eachTask(task) {
          // Short circuit if no snapshotTarget found.
          if (!task.snapshotTarget) {
            return false;
          }

          // Does this task have a Replication Task?
          if (!run._hasReplicationTask) {
            run._hasReplicationTask = [2, 5].includes(task.snapshotTarget.type);
          }

          // Does this task have an Archive Task?
          if (!run._hasArchiveTask) {
            run._hasArchiveTask = task.snapshotTarget.type === 3;
          }

          // Does this task have a cloudDeploy task?
          if (!run._hasCloudDeployTask) {
            run._hasCloudDeployTask = task.snapshotTarget.type === 4;
          }

          // Short circuit additional tests if no archiveTarget found
          if (!task.snapshotTarget.archivalTarget) {
            return false;
          }

          // Does this task have a Cloud Task?
          if (!run._hasCloudTask) {
            // 1 is tape, but 0 and 2+ (at this time) are additional cloud
            // targets, NAS, NetApp, etc.
            run._hasCloudTask = task.snapshotTarget.archivalTarget.type !== 1;
          }

          // Does this task have a Tape Task?
          if (!run._hasTapeTask) {
            run._hasTapeTask = task.snapshotTarget.archivalTarget.type === 1;
          }

          // All of these must be true to stop looping early, otherwise all
          // tasks will be evaluated.
          return run._hasTapeTask &&
            run._hasCloudTask &&
            run._hasReplicationTask;
        });

      return run;
    }

    /**
     * receives a backupRun object and returns an sla status string.
     *
     * @method     getSlaStatusString
     * @param      {object}   backupRun  Object as returned from API.
     * @return     {string}   string representation of sla status
     */
    function getSlaStatusString(backupRun) {
      var text = $rootScope.text.servicesJobRunsService;
      switch (true) {
        // SLA does not apply to View jobs
        case (backupRun.base.type === 4):
          return text.na;

        // Job isn't finished or canceled or it has errors after finishing
        case (![2, 3].includes(backupRun.base.status) || !!backupRun.base.error):
          return text.na;

        // Job violated SLA policy
        case (backupRun.base.slaViolated):
          return text.sla.fail;
      }

      return text.sla.pass;
    }

    /**
     * provides a status class to be applied to SLA display value
     * for a given backupRun
     *
     * @method     getSlaStatusClass
     * @param      {object}  backupRun  The backup run
     * @return     {string}  The sla status class.
     */
    function getSlaStatusClass(backupRun) {

      switch (true) {

        // SLA does not apply to View jobs
        case (backupRun.base.type === 4):
          return '';

        case (backupRun.base.slaViolated):
          return 'status-critical';

        case (backupRun.base.status === 2 && !backupRun.base.slaViolated):
          return 'status-ok';

      }

      return '';

    }

    /**
     * Determines if a particular job run has local snapshot.
     *
     * @method   hasLocalSnapshot
     * @param    {object}    jobRun   The job run
     * @return   {boolean}   True if has local snapshot, False otherwise.
     */
    function hasLocalSnapshot(jobRun) {
      if (jobRun.backupRun.snapshotsDeleted ||
        !jobRun.copyRun ||
        !jobRun.copyRun.finishedTasks ||
        !jobRun.copyRun.finishedTasks[0]) {
        return false;
      }

      return jobRun.copyRun.finishedTasks.some(function hasLocalTask(copyTask) {
          return copyTask.snapshotTarget && copyTask.snapshotTarget.type === 1;
        });
    }

    /**
     * Determines if delete job run checkboxes should be disabled or not.
     *
     * @method   _isDeleteRunDisabled
     * @param    {object}    run   The job run
     * @return   {boolean}   True if delete run is not allowed, False otherwise.
     */
    function _isDeleteRunDisabled(run) {
      return run._wormLocked || run._legalHoldEnabled ||
        run.base.status === 1 || run.snapshotsDeleted ||
        !run._hasLocalSnapshot;
    }

    /**
     * Decorates the Job run with properties otherwise nested within
     * backupRun statistics.
     *
     * @method   decoratePublicJobRuns
     * @param    {object[]}   jobRuns    Specifies the Array of snapshot
     *                                   versions returned from public API.
     * @return   {object[]}   Array of decorated Job runs.
     */
    function decoratePublicJobRuns(jobRuns) {
      return (jobRuns || []).map(
        function decorateVersion(run) {
          return assign(run, {
            backupType: run.backupRun.runType,
            jobRunId: run.backupRun.jobRunId,
            startedTimeUsecs: run.backupRun.stats.startTimeUsecs,
          });
        }
      );
    }

  }

})(angular);
