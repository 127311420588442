import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { envGroups, Environment } from 'src/app/shared/constants';

const sourcesAccess = (ctx: StateAccessContext) => ctx.PROTECTION_VIEW;
const sourceDetailAccess = (ctx: StateAccessContext) => {
  if (!sourcesAccess(ctx) || !ctx.FEATURE_FLAGS.ngSourceDetailsEnabledGlobally) {
    return ctx.isDmsUser;
  }

  // Only really care about the environment if it is coming from a redirect from ajs that specifies
  // the environment. Otherwise, just alow the new page to be loaded.
  if (!ctx.stateParams.environment) {
    return true;
  }

  const environment = ctx.stateParams.environment;
  switch (true) {
    case environment === Environment.kVMware:
      return ctx.FEATURE_FLAGS.ngSourceDetailsVMware || ctx.isDmsUser;
    case environment === Environment.kPure:
      return ctx.FEATURE_FLAGS.ngSourceDetailsPure || ctx.isDmsUser;
    case environment === Environment.kIbmFlashSystem:
      return ctx.FEATURE_FLAGS.ibmFlashSystemEnabled || ctx.isDmsUser;
    case environment === Environment.kKubernetes:
        return ctx.FEATURE_FLAGS.ngSourceDetailsKubernetes || ctx.isDmsUser;
    case environment === Environment.kAWS:
      return ctx.FEATURE_FLAGS.ngSourceDetailsAWS || ctx.isDmsUser;
    case environment === Environment.kAzure:
      return ctx.FEATURE_FLAGS.ngSourceDetailsAzure || ctx.isDmsUser;
    case environment === Environment.kGCP:
      return ctx.FEATURE_FLAGS.ngSourceDetailsGCP || ctx.isDmsUser;
    case envGroups.nas.includes(environment):
      return ctx.FEATURE_FLAGS.ngSourceDetailsNas || ctx.isDmsUser;
    case environment === Environment.kSQL:
      return ctx.FEATURE_FLAGS.ngSourceDetailsSql || ctx.isDmsUser;
    case environment === Environment.kO365:
      return ctx.FEATURE_FLAGS.ngSourceDetailsOffice365 || ctx.isDmsUser;
    case environment === Environment.kPhysical:
      return ctx.FEATURE_FLAGS.ngSourceDetailsPhysical || ctx.isDmsUser;
    case environment === Environment.kSfdc:
      return ctx.FEATURE_FLAGS.ngSourceDetailsSfdc || ctx.isDmsUser;
    case environment === Environment.kOracle:
      return ctx.FEATURE_FLAGS.ngSourceDetailsOracle || ctx.isDmsUser;
    case environment === Environment.kHyperV:
      // TODO(Tung): check if hyper-v needs separate source details flag
      // or if it can use the same ngSourceDetailsVMware.
      return ctx.isDmsUser;
    case environment === Environment.kUDA:
      return ctx.FEATURE_FLAGS.ngSourceDetailsUda || ctx.isDmsUser;
    case environment === Environment.kSAPHANA:
      return ctx.FEATURE_FLAGS.sapHanaEnabledDms || ctx.isDmsUser;
    case environment === Environment.kMongoDBPhysical:
      return true;
  }
  return false;
};

// Source summary tab access
const sourceSummaryAccess = (ctx: StateAccessContext) => {
  const environment = ctx.stateParams.environment;
  switch (true) {
    case environment === Environment.kO365:
      return ctx.FEATURE_FLAGS.dmsOffice365ThrottlingStats && ctx.isDmsUser;
  }
  return false;
};

const connectionTabAccess = (ctx: StateAccessContext) =>
  (ctx.isDmsUser || ctx.isDraasUser) && ctx.FEATURE_FLAGS.dmsSourceConnections || ctx.isIbmBaas;
const ngSourcesAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngSourceEnabled &&ctx.PROTECTION_VIEW &&
  getConfigByKey(ctx.irisContext, 'sources.features.allow', true);
const agentActivitiesListAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.sourceListAgentTasks && ctx.PROTECTION_VIEW;
const dmsSourcesAccess = (ctx: StateAccessContext) => ctx.clusterInfo?._cohesityService && ctx.PROTECTION_VIEW;

export const SourcesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'connection-page.**',
      url: '/source-connection/{id}',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'dms-connection.**',
      url: '/connection',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'dms-azure-connections.**',
      url: '/connections/azure',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'dms-connections.**',
      url: '/connections',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'dms-connector.**',
      url: '/connector',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'sources-ng.**',
      url: '/protection/sources-new',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'sources-deprecated.**',
      url: '/protection/sources',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'source-details-ng.**',
      url: '/protections/sources/details/{id}',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
    {
      name: 'dms-sources.**',
      url: '/protection/sources-list',
      loadChildren: () => import('./sources.module').then(m => m.SourcesModule),
    },
  ],
  allClusterMap: {
    'connection-page': true,
    'dms-connection': true,
    'dms-connection.details': true,
    'dms-connection.edit-groups': true,
    'dms-connection.group': true,
    'dms-connection.sources': true,
    'dms-connection.traffic': true,
    'dms-connection.traffic.list': true,
    'dms-connection.traffic.edit': true,
    'dms-connections': true,
    'dms-connector': true,
    'dms-azure-connections': true,
    'dms-azure-connections.create': true,
    'dms-azure-connections.edit': true,
    'sources-ng': {
      singleClusterState: 'sources-ng',
      allClustersState: 'sources-ng',
      globalContext: true,
    },
    'dms-sources.landing': true,
    'source-details-ng': {
      singleClusterState: 'sources-details-ng',
      allClustersState: 'sources-details-ng',
      globalContext: true,
    },
    'source-details-ng.connections': true,
    'source-details-ng.connection': true,
    'source-details-ng.objects': {
      singleClusterState: 'source-details-ng.objects',
      allClustersState: 'source-details-ng.objects',
      globalContext: true,
    },
    'source-details-ng.settings': {
      singleClusterState: 'source-details-ng.settings',
      allClustersState: 'source-details-ng.settings',
      globalContext: true,
    },
    'source-details-ng.summary': {
      singleClusterState: 'source-details-ng.summary',
      allClustersState: 'source-details-ng.summary',
      globalContext: true,
    },
    'sources-deprecated': {
      singleClusterState: 'sources-deprecated',
      allClustersState: 'sources-deprecated',
      globalContext: false,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'connection-page': connectionTabAccess,
      'dms-connection': connectionTabAccess,
      'dms-connection.details': connectionTabAccess,
      'dms-connection.edit-groups': connectionTabAccess,
      'dms-connection.group': connectionTabAccess,
      'dms-connection.sources': connectionTabAccess,
      'dms-connection.traffic': connectionTabAccess,
      'dms-connection.traffic.list': connectionTabAccess,
      'dms-connection.traffic.edit': connectionTabAccess,
      'dms-connections': connectionTabAccess,
      'dms-connector': connectionTabAccess,
      'dms-azure-connections': connectionTabAccess,
      'dms-azure-connections.create': connectionTabAccess,
      'dms-azure-connections.edit': connectionTabAccess,
      sources: sourcesAccess,
      'source-details-ng': sourceDetailAccess,
      'source-details-ng.connections': connectionTabAccess,
      'source-details-ng.connection': connectionTabAccess,
      'source-details-ng.objects': sourceDetailAccess,
      'source-details-ng.settings': (ctx) => sourceDetailAccess(ctx) && ctx.FEATURE_FLAGS.ngSourceDetailSettings,
      'source-details-ng.summary': (ctx) => sourceDetailAccess(ctx) && sourceSummaryAccess(ctx),
      'sources.source-landing': sourcesAccess,
      'sources.source-landing.summary': sourcesAccess,
      'sources.source-landing.backup-activity': sourcesAccess,
      'sources-ng': ngSourcesAccess,
      'sources-ng.sources': ngSourcesAccess,
      'sources-ng.agent-activities': agentActivitiesListAccess,
      'dms-sources.landing': dmsSourcesAccess,
      'sfdc.authenticate': sourcesAccess,
      'sources-deprecated': sourcesAccess,
    };
  },
};
