import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HeliosStatsServiceApi, ProtectionRunsStats, StatsServiceApi } from '@cohesity/api/v1';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { AutoDestroyable, calcChange } from '@cohesity/utils';
import { clusterIdentifiers, IrisContextService, isMcm } from '@cohesity/iris-core';

// TODO(ang): Revisit the location of this component. Right now it placed here
// because both dashboard and data-protection module need to use it.

/**
 * @description
 * Header of Protection card which contains summary information.
 *
 * @example
 *  <coh-protection-stats-header></coh-protection-stats-header>
 */
@Component({
  selector: 'coh-protection-stats-header',
  templateUrl: './protection-stats-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtectionStatsHeaderComponent extends AutoDestroyable implements OnInit {

  /**
   * Protection card data.
   */
  stats?: {
    nameKey?: string;
    change?: number;
    value?: number;
  }[];

  /**
   * Provides a list of job state params based on the particular stat list item passed in.
   * Passes `undefined` for params that aren't desired as a means of ensuring they don't get persisted from previous
   * state visit.
   */
  getStatSrefParams(stat): object {
    switch (stat.nameKey) {
      case 'backups':
        return {
          protectedObject: undefined,
          jobType: undefined,
          lastRunLocalBackupStatus: 'kError',
          lastRunSlaStatus: undefined,
          jobRunError: undefined,
        };

      default:
        // NOTE: No real mechanism in place for filtering/sorting archival and replication failures. So, simply ensure
        // no params are being persisted on state change.
        return {
          protectedObject: undefined,
          jobType: undefined,
          lastRunLocalBackupStatus: undefined,
          lastRunSlaStatus: undefined,
          jobRunError: undefined,
        };
    }
  }

  /**
   * Constructor.
   */
  constructor(
    private change: ChangeDetectorRef,
    private heliosStatsService: HeliosStatsServiceApi,
    private irisContextService: IrisContextService,
    private statsService: StatsServiceApi,
  ) {
    super();
  }

  /**
   * Initialize component and load stats data.
   */
  ngOnInit() {
    // Current time in microseconds
    const now = moment();
    const timestampList = [
      now.valueOf() * 1000,
      moment(now).subtract(24, 'hour').valueOf() * 1000,
      moment(now).subtract(48, 'hour').valueOf() * 1000
    ];

    const ctx = this.irisContextService.irisContext;

    let req: Observable<ProtectionRunsStats>[];
    if (isMcm(ctx)) {
      const params: HeliosStatsServiceApi.McmProtectionRunsStatsParams = {
        status: 'kFailure',
        startTimeUsecs: 0,
        endTimeUsecs: 0
      };

      if (clusterIdentifiers(ctx)) {
        params.clusterIdentifiers = clusterIdentifiers(ctx);
      }

      req = [0, 1].map(index => {
        params.startTimeUsecs = timestampList[index + 1];
        params.endTimeUsecs = timestampList[index];
        return this.heliosStatsService.McmProtectionRunsStats(params);
      });
    } else {
      req = [0, 1].map(index => this.statsService.GetProtectionRunsStats({
        status: 'kFailure',
        startTimeUsecs: timestampList[index + 1],
        endTimeUsecs: timestampList[index],
      }));
    }

    forkJoin(req)
    .pipe(this.untilDestroy())
    .subscribe((statsList: ProtectionRunsStats[]) => {
      const [ currentStats, previousStats ] = statsList;
      this.stats = [
        [ 'backups', 'numBackupRuns' ],
        [ 'replications', 'numReplicationRuns' ],
        [ 'archives', 'numArchivalRuns' ]
      ].map((meta: string[]) => {
        const [ name, attribute ] = meta;

        return {
          nameKey: name,
          value: currentStats[attribute],
          change: calcChange(currentStats[attribute], previousStats[attribute])
        };
      });
      this.change.markForCheck();
    });
  }
}
