import { get } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataFilter, DataFilterItem, DataFilterValue } from './data-filter';

/**
 * This is used for 2 purposes:
 *   1. As a data structure for ValuePropertyFilter to store 'value' which contains
 *      both display value (label) and actual filter value (value) for filtering
 *   2. As input filter values for ValuePropertyFilterComponent which also requires
 *      both display value and actual filter value
 */
export interface ValueFilterSelection {
  /**
   * Optional shape of icon
   */
  icon?: string;

  /**
   * Optional size of icon
   */
  iconSize?: string;

  /**
   * Optional hint text which will be displayed below label
   */
  hintText?: string;

  /**
   * Display value of value filter
   */
  label?: string;

  /**
   * The legend to be passed as input property to cog-legend-indicator component
   */
  labelLegend?: string;

  /**
   * Actual value of value filter for filtering
   */
  value?: string | number | boolean | any;

  /**
   * Optional sub items for use with NestedValuePropertyFilterComponent.
   */
  subItems?: ValueFilterSelection[];

  /**
   * Optional By default If showLabelTooltip is true then label will be display as tooltip.
   * If you want to override that behaviour pass customTooltip
   */
  customTooltip?: string;
}

/**
 * The data model for ValuePropertyFilterComponent
 */
export class ValuePropertyFilter implements DataFilter<any, ValueFilterSelection[]> {
  /**
   * The property/column name of an object to be filtered by
   */
  key: string;

  /**
   * A display label for a filter. Used to show filter chips
   */
  get tagValues$(): Observable<DataFilterItem[]> {
    return this.currentValue$.pipe(
      map(filterValues =>
        filterValues
          ? filterValues.value.map(val => {
            const { label, value }: DataFilterItem = val || {};
            return {
              label,
              value,
              remove: () => this.removeValue(val),
            };
          })
          : []
      )
    );
  }

  /**
   * The current value of the filter.
   */
  readonly currentValue$ = new BehaviorSubject<DataFilterValue<ValueFilterSelection[]>>(undefined);

  /**
   * A ui friendly label for the filter name.
   */
  label: string;

  /**
   * A note placed in the top of the open menu.
   */
  note?: string;

  /**
   * Message to show when a filter is locked. If this is not set, the filter is unlocked.
   */
  lockedMessage: string;

  /**
   * Value filter component will be notified when it should clear its values using this subject.
   */
  readonly clear$ = new Subject<void>();

  /**
   * Value filter component will be notified when it should apply its values using this subject.
   */
  readonly apply$ = new Subject<void>();

  /**
   * A filter predicate to use for a filter.
   */
  predicate(item: any, filters: ValueFilterSelection[], key?: any): boolean {
    if (!filters || !filters.length) {
      return true;
    }

    return filters.some(
      filter =>
        typeof filter.value === 'undefined' ||
        get(item, key) === filter.value ||
        (Array.isArray(get(item, key)) && get(item, key).includes(filter.value))
    );
  }

  /**
   * Removes the requested value from the filter.
   *
   * @param    value   The value to remove.
   */
  removeValue(value: ValueFilterSelection) {
    const filterValue: DataFilterValue<ValueFilterSelection[]> = this.currentValue$.value;
    const values: ValueFilterSelection[] = filterValue.value;
    const valueIndex = values.findIndex(val => val === value);
    if (valueIndex !== -1) {
      const newValue = [...values];
      newValue.splice(valueIndex, 1);
      this.setValue(newValue);
    }
  }

  /**
   * Sets the filter's value.
   *
   * @param   value   The value to set.
   */
  setValue(value: ValueFilterSelection[]) {
    this.currentValue$.next({
      key: this.key,
      value,
      predicate: this.predicate,
    });
  }
}

export const alphaSortValueFilterSelection =
  (a: ValueFilterSelection, b: ValueFilterSelection) => (a.label || '').localeCompare(b.label || '');

export type CanSelect = (item: ValueFilterSelection) => boolean;
