import { assign } from 'lodash-es';
// Module and Component: viewListing

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.viewListing';
  var moduleDependencies = [];
  var componentName = 'viewListing';
  var options = {
    controller: 'ViewListingCtrl',
    templateUrl: 'app/global/view-listing/view-listing.html',
    bindings: {
      /**
       * Required protection job setting.
       *
       * @type   {Object}   [job]    Protection Job configration.
       */
      job: '<?',

      /**
       * Option policy for indicating if a View is incompatible with the
       * Policies replication configuration based on lack of appropriate View
       * Box pairing.
       *
       * @type   {Object}  [policy]   Current policy applied to provided Job
       */
      policy: '<?',

      /**
       * a default View id for selection defaulting. This can be used instead
       * of ngModel. This is particularly useful in the Job flow, as a View
       * object and a View protectionSource are two different concepts with
       * different proto structures and id.
       *
       * @type {Integer} [defaultId] id of the View to select by default.
       */
      defaultId: '<?',

      /**
       * Optional attribute sniffed value, if attribute is present then list all
       * views owned by the logged in user organization.
       */
      // only-own: '?'
    },
    require: {
      ngModel: 'ngModel',
    },
  };

  angular
    .module(modName, moduleDependencies)
    .controller('ViewListingCtrl', viewListingCtrlFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.jobObjects:cJobObjects
   * @function
   *
   * @description
   * Provides a shopping cart style listing of objects protected by a given Job.
   *
   * @example
   *
   * Show all Views:
   * <view-listing></view-listing>
   *
   * Show Views for a given View Box:
   * <view-listing view-box-id="viewBox.id"></view-listing>
   *
   * Select a single View:
   * <view-listing ng-model="$ctrl.myView" ng-change="myViewRules">
   * </view-listing>
   */
  function viewListingCtrlFn(_, $q, $filter, ViewService, RemoteClusterService,
    evalAJAX, $attrs) {

    var $ctrl = this;
    var remoteClusterHash;

    /**
     * Component initialization function.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      var promises = {};

      assign($ctrl, {
        isViewBoxRxCompatible: isViewBoxRxCompatible,
        filterString: '',
        filterViews: filterViews,
        viewProtocolSortValGetter: $filter('viewProtocols'),
        updateExternalModel: updateExternalModel,
        views: [],
        selectedView: undefined,
        onlyOwn: $attrs.hasOwnProperty('onlyOwn'),
      });

      promises.views = _getViews();

      // If a Policy was provided and it has replication, load remote clusters
      // for View Box compatiblity checking.
      if ($ctrl.policy &&
        ($ctrl.policy.snapshotReplicationCopyPolicies || []).length) {
        promises.remoteClusterHash =
          RemoteClusterService.getRemoteClusterHash();
      }

      if ($ctrl.ngModel) {
        $ctrl.selectedView = $ctrl.ngModel.$modelValue;
      }

      $q.all(promises).then(function getViewsSuccess(responses) {

        remoteClusterHash = responses.remoteClusterHash;

        if ($ctrl.defaultId ||
          ($ctrl.selectedView && $ctrl.selectedView.viewId)) {
          // If the currently selected View is in this set of Views, update
          // $ctrl.selectedView so the object reference works for the ngModel
          // in the template.
          $ctrl.views.some(function seekSelected(view) {
            if ([$ctrl.selectedView.viewId, $ctrl.defaultId]
              .includes(view.viewId)) {
              return $ctrl.selectedView = view;
            }
          });
        }
      });
    };

    /**
     * Gets the Views.
     *
     * @method   _getViews
     * @param    {object}    [params]   The parameters
     * @return   {promise}   Promise to resolve request for Views
     */
    function _getViews(params) {

      params = params || {};

      $ctrl.views.length = 0;
      $ctrl.viewsLoaded = false;
      return ViewService[$ctrl.onlyOwn ? 'getOwnViews' : 'getViews'](params)
        .then(
          function getViewsSuccess(views) {
            return $ctrl.views = views;
          },
          evalAJAX.errorMessage
        ).finally(
          function getViewsFinally() {
            $ctrl.viewsLoaded = true;
          }
        );
    }

    /**
     * Sets appropriate filters based on user interaction and request an updated
     * list of Views.
     *
     * @method   filterViews
     */
    function filterViews() {
      var params = !$ctrl.filterString ? undefined : {
        viewNames: [$ctrl.filterString],
        matchPartialNames: true,
      };

      _getViews(params);
    }

    /**
     * Indicates if the provided ViewBoxId is compatible with the replication
     * targets in provided policy.
     *
     * @method   isViewBoxRxCompatible
     * @param    {integer}    viewBoxId   The view box identifier
     * @return   {boolean}   True if view box compatible, False otherwise.
     */
    function isViewBoxRxCompatible(viewBoxId) {
      if (!$ctrl.policy) { return true; }

      return ($ctrl.policy.snapshotReplicationCopyPolicies || []).every(
        function hasMatchingVbPairing(rxCopyPolicy) {
          var remoteCluster = remoteClusterHash[rxCopyPolicy.target.clusterId];

          if (!remoteCluster) { return false; }

          return (remoteCluster.viewBoxPairInfo || []).some(
            function findPair(vb) {
              return vb.localViewBoxId === viewBoxId;
            }
          );
        }
      );
    }

    /**
     * Updates the external ngModel to match the current internal model.
     *
     * @method   updateExternalModel
     */
    function updateExternalModel() {
      $ctrl.ngModel.$setViewValue($ctrl.selectedView);
    }

  }

})(angular);
