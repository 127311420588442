<cog-table
  [source]="searchResults"
  [selection]="selection"
  [canSelectRowFn]="canSelectRow"
  [selectRowTooltipFn]="selectRowTooltip"
  [persistSelection]="true"
  [isAllSelectedFn]="isAllSelectedFn"
  [toggleSelectAllFn]="toggleSelectAllFn"
  name="dg.scan.objectSearchResults">
  <cog-table-multi-action [displayStatus]="false">
    <div class="flex-row-lg">
      <span>{{ 'dg.td.scan.objects' | translate }}</span>
      <span>{{
        'dg.td.selectedObjectsCount' | translate: { items: selection?.selected?.length, n: searchResults?.length }
      }}</span>
    </div>
  </cog-table-multi-action>
  <table mat-table>
    <ng-container matColumnDef="detail">
      <th mat-header-cell class="padding-left-md" *matHeaderCellDef>{{ 'dg.td.scan.objects' | translate }}</th>
      <td mat-cell *matCellDef="let row; let index = index">
        <dg-scan-object-search-item-detail
          [obj]="row"
          [autoTruncate]="selection?.selected?.length"
          [showInstallConnectorLink]="true">
        </dg-scan-object-search-item-detail>
      </td>
    </ng-container>
    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td colspan="5" mat-footer-cell *matFooterCellDef>
        {{ 'noSearchResults' | translate }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['detail']" [class.hidden]="!searchResults?.length"></tr>
    <tr mat-row *matRowDef="let row; columns: ['detail']" (click)="toggleObjectSelection(row)"></tr>
    <tr class="nodata-row" mat-footer-row [class.hidden]="!noData" *matFooterRowDef="['noData']"></tr>
  </table>
  <mat-paginator
    [class.hidden]="!searchResults?.length"
    [length]="searchResults?.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</cog-table>
