import { assign } from 'lodash-es';
// Component: DB Object Summary

;(function(angular, undefined) {
  'use strict';

  var ONE_WEEK_MSECS = 1000 * 60 * 60 * 24 * 7;

  angular.module('C.dbDetails')
    .controller('DbSummaryController', DbSummaryCtrlFn)
    .filter('humanizeOrDiff', formatTimeStampFn)
    .component('dbSummary', {
      templateUrl: 'app/dashboard/sql/detail/summary/summary.html',
      controller: 'DbSummaryController',
    });

  /**
   * @ngdoc component
   * @name C.dbDetails:dbSummary
   *
   * @description
   * Summary info for a db
   *
   * @example
   * <db-summary></db-summary>
   */
  function DbSummaryCtrlFn(_, $q, $state, DbObjectService, PubJobService,
    PolicyService) {

    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
    });

    /**
     * Initialize the component and start api calls
     *
     * @method $onInit
     */
    function $onInit() {

      assign($ctrl, $state.params);

      // Don't make calls for policies or jobs if the there were no params
      // passed.
      $q.all({
        job: $ctrl.jobId ? PubJobService.getJob($ctrl.jobId) : undefined,
        policy: $ctrl.policyId ?
          PolicyService.getPolicy($ctrl.policyId) : undefined,
        restoreTasks: DbObjectService.getRestoreTasks($ctrl.env, $ctrl.id),
        snapshots: DbObjectService.findSnapshots(
          $ctrl.id, $ctrl.rootSourceId, $ctrl.env),
        dbInfo: DbObjectService.getDbAppInfo(
          $ctrl.id, $ctrl.sourceId, $ctrl.env),
      }).then(function onSuccess(result) {
        assign($ctrl, {
          policy: result.policy,
          job: result.job,
          tasks: result.restoreTasks.tasks,
          info: result.dbInfo.db._envProtectionSource,
          latestSnapshot: result.snapshots.latestSnapshot,
          snapshotSummary: result.snapshots.snapshotSummary,
          loaded: true,
        });
      });
    }

  }

  /**
   * Format snapshot timestamps as either a date or a humanized date diff based
   * on whether they are older than a week or not
   *
   * @method   formatTimeStampFn
   * @param    {Object}   $filter   search filter
   * @return   {Object}   Filter that filters certain properties.
   */
  function formatTimeStampFn($filter) {
    return function filter(timestampMsecs) {
      var diff = Date.clusterNow() - timestampMsecs;

      // Display a date for anything older than a week.
      if (diff > ONE_WEEK_MSECS) {
        return $filter('msecsToDate')(timestampMsecs);
      }

      // Otherwwise show the diff from the current time
      return $filter('humanizeMsecsDiff')(timestampMsecs, 'minutes');
    };
  }

})(angular);
