<ng-container *ngIf="dataReady">
  <cog-statlist valueSize="md">
    <a
      cogStatlistItem
      cogDataId="total"
      iconName="helix:status-info-alt!info"
      iconSize="xs"
      [label]="'total' | translate">
      {{ (successfulCloudVaults + unsuccessfulCloudVaults) | number }}
    </a>
    <a
      cogStatlistItem
      cogDataId="failed"
      iconName="helix:status-error-alt!critical"
      iconSize="xs"
      [label]="'failed' | translate">
      {{ unsuccessfulCloudVaults | number }}
    </a>
    <a
      cogStatlistItem
      cogDataId="succeeded"
      iconName="helix:status-success-alt!success"
      iconSize="xs"
      [inheritDataId]="true"
      [label]="'succeeded' | translate">
      {{ successfulCloudVaults | number }}
    </a>
  </cog-statlist>

  <cog-bar-chart
    *ngIf="successfulCloudVaults; else blankCard"
    [categories]="barChartCategories"
    [seriesData]="barSeriesOptions"
    [customChartOptions]="barCustomOptions">
  </cog-bar-chart>
</ng-container>

<ng-template #blankCard>
  <cog-blank-card size="sm2" [message]="'noDataAvailable' | translate" class="blank-card"></cog-blank-card>
</ng-template>
