import { isEmpty } from 'lodash-es';
// Reports: Archival Summary

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsArchivalSummaryController',
      reportsArchivalSummaryControllerFn);

  function reportsArchivalSummaryControllerFn($q, $translate,
    ReportsService, evalAJAX, cReportsControlsUtil,
    ReportsUtil, moment, ExternalTargetService) {

    var archivalSummaryObj = this;
    var targetsPromise = ExternalTargetService.getTargets();
    var targets;

    archivalSummaryObj.getArchivalSummaryObjects = getArchivalSummaryObjects;
    archivalSummaryObj.downloadArchivalSummaryReport =
      downloadArchivalSummaryReport;

    archivalSummaryObj.archivalSummaryControlsConfig =
      ReportsUtil.getReportConfig('kArchivalSummaryReport')();

    archivalSummaryObj.archivalSummaryObjects = [];

    archivalSummaryObj.getIconObj = getIconObj;

    /**
     * Loads necessary data for controller
     */
    function activate() {
      var vaultIds = [];
      var defaultFilters;

      archivalSummaryObj.dataLoading = true;

      $q.all(archivalSummaryObj.archivalSummaryControlsConfig.datasetPromiseObject)
        .then(function getDataset(dataset) {
          archivalSummaryObj.dataset = dataset;

          vaultIds = dataset.externalTargets.map(function mapTargets(target) {
            return target.id;
          });

          defaultFilters = cReportsControlsUtil.getDefaultFilters({
            timeObject: {
              from: moment().subtract(1, 'months'),
              until: moment().endOf('day'),
            },
            vaultIds: vaultIds,
          });

          getArchivalSummaryObjects(defaultFilters);
        }, evalAJAX.errorMessage)
      .finally(function getTargetsFinally() {
        archivalSummaryObj.reportsControlsDataReady = true;
      });

      targetsPromise.then((resp) => targets = resp);
    }

    /**
     * returns params object for api call
     *
     * @param     {object}    filters    filters from c-reports-controls
     *
     * @return    {object}               filter values to be passed to API
     */
    function getParams(filters) {
      return {
        vaultIds: filters.vaultIds,
        // convert timestamps to milliseconds
        startTimeMsecs: filters.timeObject.from.valueOf(),
        endTimeMsecs: filters.timeObject.until.valueOf(),
      };
    }

    /**
     * gets data needed for controller and template calls getSummaryStats()
     * to aggregate data
     *
     * @param    {object}    filters    values to filter report data
     */
    function getArchivalSummaryObjects(filters) {
      archivalSummaryObj.dataLoading = true;
      archivalSummaryObj.archivalSummaryObjects.length = 0;

      // Expose filters on scope for ui.json string consumption.
      archivalSummaryObj.filters = filters;

      ReportsService.getArchivalSummary(getParams(filters))
        .then(function getExternalObjects(data) {
          archivalSummaryObj.archivalSummaryObjects = _decorateData(data);
          archivalSummaryObj.archivalSummaryStats = _getStats();
        }, evalAJAX.errorMessage)
        .finally(function getExternalObjectsFinally() {
          archivalSummaryObj.dataLoading = false;
        });
    }

    /**
     * decorate API data to be used for display
     *
     * @param    {object[]}    data    archival summary data returned by API
     * @return   {object[]}   The decorated data
     */
    function _decorateData(data) {
      return data.map(function eachData(obj) {
        var numSuccessRuns = 0;
        var numFailedRuns = 0;
        var numQueuedRuns = 0;
        var vaults = [];

        obj.runs.forEach(function eachRun(run, i) {
          (run.archivalInfo || []).forEach(function eachArchive(archive) {
            if (!isEmpty(archive)) {
              if (archive.publicStatus === 'kAccepted') {
                numQueuedRuns += 1;
              }

              if (archive.publicStatus === 'kSuccess') {
                numSuccessRuns += 1;
              }

              if (archive.publicStatus === 'kFailure') {
                numFailedRuns += 1;
              }

              if (!vaults.find(vault => vault.vaultId === archive.vaultId)) {
                vaults.push({
                  vaultName: archive.vaultName,
                  vaultId: archive.vaultId,
                });
              }
            }
          });
        });

        return Object.assign({
          _vaults: vaults,
          _numSuccessRuns: numSuccessRuns,
          _numFailedRuns: numFailedRuns,
          _numQueuedRuns: numQueuedRuns,
        }, obj);
      });
    }

    /**
     * get stats to be displayed in stats bar
     *
     * @return   {object[]}   The stats for archival summary
     */
    function _getStats(data) {
      return archivalSummaryObj.archivalSummaryObjects.reduce(function reducer(obj, curr) {
        obj.totSuccess += curr._numSuccessRuns || 0;
        obj.totFail += curr._numFailedRuns || 0;
        // obj.avgBandwidth += curr.bandwidth || 0;

        return obj;
      }, {
        totSuccess: 0,
        totFail: 0,
        avgBandwidth: 0,
      });
    }

    /**
     * Initiates CSV download
     */
    function downloadArchivalSummaryReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getArchivalSummary(params);
    }

    /**
     * Return an object containing icon and tooltip for an archival task based
     * on its status
     *
     * @param   {object} run The archival run object
     * @returns {object} object containing icon and tooltip
     */
    function getIconObj(run) {
      // remove 'k from status'. Eg: 'kSuccess' -> 'success'
      var status = run.status.substring(1).toLowerCase();

      // 'failed' status maps to 'error' icon.
      // other status map to the same icon name.
      var icon = status === 'failure' ? 'error' : status;

      // prefix vault type to an icon.
      icon = run.vaultType === 'kTape' ? 'tape-' + icon : 'cloud-' + icon;

      return {
        icon: icon,
        tooltip: run.vaultName + ': ' + $translate.instant(status),
      }

    }

    activate();
  }
})(angular);
