import { isNil } from 'lodash-es';
import { filter } from 'lodash-es';
import { forEach } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Clone Landing

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.devOps')
    .controller('CloneSummaryCtrl', cloneSummaryControllerFn)
    .component('cCloneSummary', {
      controller: 'CloneSummaryCtrl',
      template: require('raw-loader!./clone.html').default,
      bindings: {
        /**
         * Specifies the clone category. This can be one of the following:
         * 'vms', 'views'.
         *
         * @type {string}
         */
        category: '<?',
      },
    });

  function cloneSummaryControllerFn(_, $rootScope, $scope, $state, evalAJAX,
    RestoreService, DateTimeService, DateRangerService, TenantService,
    CloneService, ENV_TYPE_CONVERSION, FEATURE_FLAGS, ClusterService) {
    var $ctrl = this;

    // If the hardware model is PXG1, then cluster is using Disaggregated Storage.
    $ctrl.isDisaggregatedStorage = ClusterService.clusterInfo._isDisaggregatedStorage;

    /**
     * calls the API and updates $scope values accordingly
     * @return {Void}
     */
    function getCloneTasks() {
      var params = cloneDeep($scope.restoreTasksParams);

      $scope.dataReady = false;
      $scope.cloneTasks = [];
      $scope.statusCounts = {};

      // Override clone types, if category is defined.
      if (!isNil($ctrl.category)) {
        params.restoreTypes = cloneDeep($ctrl.categoryConfig.cloneTypes);
      }

      params = TenantService.extendWithTenantParams(params, params._tenantIds);
      RestoreService.getRestoreTasks(params).then(
        function getCloneTasksSuccess(cloneTasks) {
          $scope.cloneTasks = _filterCloneTasks(cloneTasks);
          $scope.statusCounts = RestoreService.getStatusCounts($scope.cloneTasks);

          forEach($scope.cloneTasks, function taskIterator(task) {
            task._actions = _getTaskActions(task);
          });
        },

        evalAJAX.errorMessage
      ).finally(function getCloneTasksFinally() {
        $scope.dataReady = true;
      });
    }

    /**
     * filter clone tasks for the given filter options
     *
     * @method   _filterCloneTasks
     * @param    {Object[]}   cloneTasks
     * @return   {Object[]}   filtered clone tasks
     */
    function _filterCloneTasks(cloneTasks) {
      // Filtering out oracle instant recover clone tasks.
      cloneTasks = cloneTasks.filter(function filterMigrationTasks(task) {
        return !get(task.performRestoreTaskState.restoreAppTaskState,
          'restoreAppParams.restoreAppObjectVec[0].restoreParams.oracleRestoreParams.isMultiStageRestore', false);
      });

      if (!!$scope.filterOptions) {
        switch(true) {
          case !!$scope.filterOptions.restoreInfoType:
            return filter(cloneTasks,
              ['performRestoreTaskState.restoreInfo.type',
                $scope.filterOptions.restoreInfoType]);
        }
      }
      return cloneTasks;
    }

    /**
     * Returns a collection of actions for the task. These actions show up in
     * the context menu against each task.
     *
     * @method   _getTaskActions
     * @param    {Object}   task   The clone task object.
     * @return   {Array}    An array of possible actions on the task.
     */
    function _getTaskActions(task) {
      var actions = [];

      if (RestoreService.canTearDownTask(task)) {
        actions.push({
          translateKey: 'protectionRecoveryDetail.tearDown',
          icon: 'icn-delete',
          action: function tearDown() {
            RestoreService.teardownTaskModal(task).then(getCloneTasks);
          },
        });
      }

      if (CloneService.isRefreshable(task)) {
        // Refresh with latest snapshot
        actions.push({
          translateKey: 'cloneRefresh.contextMenu.latestSnapshot',
          icon: 'icn-refresh',
          action: function refreshWithLatest() {
            CloneService.confirmCloneRefresh(task).then(getCloneTasks);
          },
        });

        // Refresh with snapshot
        actions.push({
          translateKey: 'cloneRefresh.contextMenu.snapshot',
          icon: 'icn-refresh',
          action: function refreshFromPit() {
            CloneService.openPitSelectionModal(task).then(getCloneTasks);
          },
        });
      }

      if (RestoreService.canDownloadDebugLog(task)) {
        actions.push({
          translateKey: 'downloadDebugLogs',
          icon: 'icn-download',
          action: function downloadDebugLog() {
            RestoreService.downloadRestoreDebugLog(task.performRestoreTaskState.base.taskId);
          },
        });
      }

      return actions;
    }

    /**
     * respond to new filter updates
     * and then initiates an api call to update $scope data
     *
     * @method   filterTasks
     * @param    {any}   [options]      extra options for cases where
     *                                  just the restoreTypes cannot
     *                                  differentiate the results.
     *                                  ex: restoreType is kCloneApp
     *                                  for both oracle and sql
     *                                  It can be differentiated only
     *                                  via restoreInfo -> type
     * @param   boolean   exclusive     Set to true if we want to use the given
     *                                  options otherwise use the old options.
     *                                  Currently, we use the given options when
     *                                  the user switch between filter the task
     *                                  type; preserve the old options when
     *                                  users filter task by date and organization.
     */
    function filterTasks(options, exclusive) {
      $scope.restoreTasksParams.startTimeUsecs =
        DateTimeService.dateToUsecs($scope.dates.startDate);
      $scope.restoreTasksParams.endTimeUsecs =
        DateTimeService.dateToUsecs($scope.dates.endDate);

      $scope.filterOptions = exclusive ? options : $scope.filterOptions;
      getCloneTasks();
    };

    /**
     * AngularJS component lifecycle hook.
     */
    $ctrl.$onInit = function $onInit() {
      assign($ctrl, {
        oracleCloneTypes: ['kCloneApp', ENV_TYPE_CONVERSION.kOracle],
        sqlClonetypes: ['kCloneApp', ENV_TYPE_CONVERSION.kSQL],
        viewCloneTypes: ['kCloneView'],
        vmCloneTypes: ['kConvertAndDeployVMs', 'kCloneVMs', 'kDeployVMs'],
      });

      /**
       * The configuration items for each category.
       */
      var categoryConfigs = {
        vms: {
          pageTitleKey: 'vms',
          newCloneState: 'clone-vms',
          cloneTypes: $ctrl.vmCloneTypes,
        },
        views: {
          pageTitleKey: 'views',
          newCloneState: 'clone-view.search',
          cloneTypes: $ctrl.viewCloneTypes,
        },
      };

      assign($scope, {
        // convenience functions and vars
        allowedCloneTypes: RestoreService.getAllowedCloneTypes(),

        // dates for display and interaction with cDateRanger
        dates: DateRangerService.getDateRangerValues($state.params ?
          $state.params.cloneDateRange : undefined),

        ENV_TYPE_CONVERSION: ENV_TYPE_CONVERSION,
        FEATURE_FLAGS: FEATURE_FLAGS,
        filterTasks: filterTasks,

        // counts of various status types
        statusCounts: {},
        text: $rootScope.text.devopsCloneClone,
      });

      // parameters to be used in getCloneTasks() API call
      $scope.restoreTasksParams = {
        startTimeUsecs: DateTimeService.dateToUsecs($scope.dates.startDate),
        endTimeUsecs: DateTimeService.dateToUsecs($scope.dates.endDate),
        restoreTypes: $scope.allowedCloneTypes,
        _tenantIds: [],
        _includeTenantInfo: true,
      };

      $ctrl.categoryConfig = categoryConfigs[$ctrl.category];
      getCloneTasks();
    };
  }

})(angular);
