import { find } from 'lodash-es';
import { forEach } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Oracle node setings component

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.oracleNodeSettings', [])
    .controller('OracleNodeSettingsCtrl', OracleNodeSettingsCtrlFn)
    .component('oracleNodeSettings', {
      bindings: {
        /**
         * @type   {Object[]}   Specifies array of Nodes available for
         *                      Multi-node backup/restore.
         */
        formData: '=',

        /**
         * @type   {Boolean}   Specifies whether node selection is taken care
         *                     by magneto i.e 'auto' or by user i.e. 'manual'.
         */
        nodeSelectType: '=',

        /**
         * @type   {Boolean}   Specifies whether the selected oracle db entity
         *                     is OS or DB authenticated.
         */
        isDbAuthenticated: '<',

        /**
         * @type   {Object}    Holds the DB credentials object.
         */
        dbCredentials: '=',

        /**
         * @type   {Object}    Specifies the protection source environment.
         */
        nodeEnvSource: '=',

        /**
         * @type   {String}   Specifies if node settings is for
         *                    Backup/Recovery
         */
        nodeSettingsFor: '<',

        /**
         * @type   {String}   Specifies global SBT library path for
         *                    Windows Backup/Recovery
         */
        sbtLibraryPath: '=?',

        /**
         * @type   {Boolean}   Specifies if Alternate Restore or not.
         *                     i.e not Overwrite Restore, can be both
         *                     recover to alternate server altogether/
         *                     recover to same server but different
         *                     database.
         */
        isAlternateRestore: '<?',

        /**
         * @type   {Boolean}   Specifies if view restore or not.
         */
        isViewExposeRestore: '<?',

        /**
         * @type   {Boolean}   Specifies if Active Passive Restore or not.
         */
        isActivePassive: '<?',

        /**
         * @type   {Boolean}  Select all supported nodes by default
         */
        selectAllByDefault: '<?',

        /**
         * @type   {Boolean}  Specifies if save button is enabled or not.
         */
        canSaveSettings: '=',

        /**
         * @type   {Number}  Specifies the number of days after which archive
         *                   logs can be deleted.
         */
        archiveDeleteAfter: '=',

        /**
         * @type   {Boolean}  Specifies whether the database having the Primary
         *                    role within Data Guard configuration is to be
         *                    backed up.
         */
        enableDgPrimaryBackup: '=?',

        /**
         * @type   {Number}  Specifies the host type of the source(kLinux/kWindows).
         */
        parentHostType: '=?',
      },
      templateUrl:
        'app/protection/common/oracle-node-settings/oracle-node-settings.html',
      controller: 'OracleNodeSettingsCtrl',
    });

  /**
   * @ngdoc component
   * @name OracleNodeSettings
   *
   * @description
   * This component provides multi node multi channel selection for
   * oracle database.
   *
    @example
    <oracle-node-settings
      form-data="$ctrl.formData"
      is-db-authenticated="$ctrl.isDbAuthenticated"
      node-select-type="$ctrl.nodeSelectType"
      db-credentials="$ctrl.credentials"
      node-env-source="$ctrl.thisNode._envProtectionSource"
      is-alternate-restore="$ctrl.isAlternateRestore"
      parent-host-type="$ctrl.hostType"
      sbt-library-path="$ctrl.sbtLibraryPath">
    </oracle-node-settings>
   */
  function OracleNodeSettingsCtrlFn(_, PRIVATE_TO_PUBLIC_ENV_STRUCTURES, FEATURE_FLAGS,
    ENUM_HOST_TYPE_CONVERSION) {
    var $ctrl = this;

    /**
     * Initializes the default parameters for recovery.
     *
     * @method   initializeDefaultValues
     */
    $ctrl.$onInit = function onInit() {
      var dbType = typeof $ctrl.nodeEnvSource.dbType === 'number' ?
        PRIVATE_TO_PUBLIC_ENV_STRUCTURES.kOracle.databaseTypes[
          $ctrl.nodeEnvSource.dbType]:$ctrl.nodeEnvSource.dbType;

      assign($ctrl, {
        // methods
        analyzeChannelsRequested: analyzeChannelsRequested,
        analyzeNodes: analyzeNodes,
        clearDbCredentials: clearDbCredentials,
        getNodesCount: getNodesCount,
        getSelectedNodesCount: getSelectedNodesCount,
        getSupportedNodesCount: getSupportedNodesCount,
        hasGlobalSbtLibraryPath: hasGlobalSbtLibraryPath,
        isOracleWindowsSupported: isOracleWindowsSupported,
        onChangeSingleNodeSelection: onChangeSingleNodeSelection,
        setArchiveDeleteAfter: setArchiveDeleteAfter,
        toggleDatabaseNodesSelectAll: toggleDatabaseNodesSelectAll,

        // variables
        allNodesSelected: false,
        areCredentialsMandatory: false,
        confirmDeleteMessage: undefined,
        isShowDeleteArchiveLog: false,
        oracleEnvironment: ['enum.oracleDatabaseType.', dbType].join(''),
        dbType: dbType,
        showDatabaseCredentialsForm: false,
        singleNodeSelectedIp: undefined,
        FEATURE_FLAGS: FEATURE_FLAGS,
      });

      // Enable toggle if archive days are already set
      if (typeof $ctrl.archiveDeleteAfter !== 'undefined') {
        $ctrl.isShowDeleteArchiveLog = true;
      }

      // Trigger node analysis.
      analyzeNodes();

      // Auto select first node for active passive overwrite/alternate
      // restore and node count > 1.
      if ($ctrl.isActivePassive && $ctrl.formData.length > 1) {
        var nodeSelected = find($ctrl.formData, ['isSelected', true]);
        $ctrl.formData[0].isSelected = nodeSelected ? $ctrl.formData[0].isSelected
          : $ctrl.formData[0].isSupported;
      }
    };

    /**
     * Number of selected nodes
     *
     * @method    getSelectedNodesCount
     * @return    {Number}   Count of selected nodes.
     */
    function getSelectedNodesCount() {
      return $ctrl.formData.filter(function selected(node) {
        return node.isSelected && node.isSupported;
      }).length;
    }

    /**
     * Number of supported nodes
     *
     * @method    getSupportedNodesCount
     * @return    {Number}   Count of supported nodes.
     */
    function getSupportedNodesCount() {
      return $ctrl.formData.filter(function supported(node) {
        return node.isSupported;
      }).length;
    }

    /**
     *  Number of nodes
     *
     * @method    getNodesCount
     * @return    {Number}   Count of nodes.
     */
    function getNodesCount() {
      return $ctrl.formData.length;
    }

    /**
     * Toggles all the database nodes selection.
     *
     * @method   toggleDatabaseNodesSelectAll
     */
    function toggleDatabaseNodesSelectAll() {
      $ctrl.formData.forEach(function toggleNode(node) {
        if (node.isSupported) {
          node.isSelected = $ctrl.allNodesSelected;
        }
      });
      analyzeNodes();
    }

    /**
     * Determines whether global SBT path is applicable or not.
     *
     * @method   hasGlobalSbtLibraryPath
     * @return   {Boolean}   True, global sbt path is applicable.
     *                       False, otherwise.
     */
    function hasGlobalSbtLibraryPath() {
      return $ctrl.nodeSelectType === 'auto' && isOracleWindowsSupported();
    }

    /**
     * Determines whether oracle windows source is selected or not.
     *
     * @method   isOracleWindowsSupported
     * @return   {Boolean}   True, If oracle windows host type is selected.
     *                       False, otherwise.
     */
    function isOracleWindowsSupported() {
      return ($ctrl.parentHostType === ENUM_HOST_TYPE_CONVERSION.kWindows) &&
        FEATURE_FLAGS.oracleWindowsRestoreEnabled;
    }

    /**
     * Select all the database nodes.
     *
     * @method   selectAllDatabaseNodes
     */
    function selectAllDatabaseNodes() {
      $ctrl.formData.forEach(function selectNode(node) {
        if (node.isSupported) {
          node.isSelected = true;
        }
      });
    }

    /**
     * Sets the archiveDeleteAfter variable based on the toggle.
     *
     * @method   setArchiveDeleteAfter
     */
    function setArchiveDeleteAfter() {
      if (!$ctrl.isShowDeleteArchiveLog) {
        $ctrl.archiveDeleteAfter = undefined;
      }
    }

    /**
     * Analyzes the Node selections within the Auto/Manual Mode. Also mandates
     * the credentials within Manual mode and clears Node selection within auto
     * mode.
     *
     * @method   analyzeNodes
     */
    function analyzeNodes() {
      if ($ctrl.nodeSelectType === 'auto') {
        $ctrl.canSaveSettings = true;
        _clearNodeSelections();
        $ctrl.clearDbCredentials();
      } else {
        if ($ctrl.selectAllByDefault) {
          selectAllDatabaseNodes();
        }

        var nodeCount = getSupportedNodesCount();

        // If node count is one make it selected by default
        if (nodeCount === 1) {
          forEach($ctrl.formData, function setAsSelected(node) {
            if (node.isSupported) {
              node.isSelected = true;
            }
          });
        }
        var selectedNodeCount = getSelectedNodesCount();

        $ctrl.allNodesSelected = nodeCount === selectedNodeCount;
        $ctrl.areCredentialsMandatory = _areCredentialsMandatory();

        // Show the credentials form when
        // 1. credentials are mandatory
        // 2. user has already entered some credentials manually
        $ctrl.showDatabaseCredentialsForm = $ctrl.areCredentialsMandatory ||
          !!$ctrl.dbCredentials.username || !!$ctrl.dbCredentials.password;

        // Enable Save when user selects at least one node and
        // 1. isAlternateRestore
        // 2. Already DbAuthenticated
        // 3. Only one Node available
        // 4. Nodes > 1 and Selected Nodes === 1
        // 5. Nodes > 1 and not already dbAuthenticated and
        //    Selected Nodes > 1 and
        //    user manually entered credentials.
        $ctrl.canSaveSettings = selectedNodeCount >= 1 &&
        (
          $ctrl.isAlternateRestore ||
          $ctrl.isViewExposeRestore ||
          $ctrl.isDbAuthenticated ||
          nodeCount === 1 ||
          (nodeCount > 1 && selectedNodeCount === 1) ||
          (nodeCount > 1 && !$ctrl.isDbAuthenticated)
        );

        if ($ctrl.isAlternateRestore || $ctrl.isActivePassive || $ctrl.isViewExposeRestore) {
          var nodeSelected = find($ctrl.formData, ['isSelected', true]);
          $ctrl.singleNodeSelectedIp = nodeSelected ? nodeSelected.ip :
            undefined;
        }
      }
    }

    /**
     * Callback on selecting a single node selection i.e radio buttion
     * for altertnate restore case.
     *
     * @method   onChangeSingleNodeSelection
     */
    function onChangeSingleNodeSelection(ip) {
      forEach($ctrl.formData, function eachNode(node) {
        node.isSelected = node.ip === ip;
      });

      // Trigger node analysis.
      analyzeNodes();
    }

    /**
     * Determines if the channels requested are more than the recommended
     * number of channels.
     *
     * @method   analyzeChannelsRequested
     * @param    {Number}   index   Specifies the index of the node
     */
    function analyzeChannelsRequested(index) {
      var node = $ctrl.formData[index];
      node.hasChannelWarning = node.channels > node.recommendedChannels;
    }

    /**
     * Deselect the nodes selected within the manual mode.
     *
     * @method   _clearNodeSelections
     */
    function _clearNodeSelections() {
      $ctrl.formData.forEach(function deSelectNode(node) {
        node.isSelected = false;
      });
    }

    /**
     * Clear db credentials data.
     *
     * @method   clearDbCredentials
     */
    function clearDbCredentials() {
      $ctrl.dbCredentials.username = $ctrl.dbCredentials.password = undefined;
    }

    /**
     * Determines whether the Credentials are mandatory for the Oracle Nodes
     * selected.
     *
     * @method   _areCredentialsMandatory
     * @return   {Boolean}   True, if the credentials are mandatory.
     *                       False, otherwise.
     */
    function _areCredentialsMandatory() {
      var nodeCount = getSupportedNodesCount();
      var selectedNodeCount = getSelectedNodesCount();

      // 1. Not mandatory if already db authenticated.
      // 2. Not mandatory when number of nodes selected is one or zero.
      // 3. Not mandatory when no of nodes available is < = one.
      // 4. Not mandatory when Active passive usecase /Alternate Restore/
      // View expose restore as user can select at max one node.
      if ($ctrl.isDbAuthenticated || selectedNodeCount <= 1 || nodeCount <= 1
        || $ctrl.isAlternateRestore || $ctrl.isViewExposeRestore || $ctrl.isActivePassive) {
        return false;
      }
      return true;
    }
  }
})(angular);
