<div [cogDataId]="'settings-list-header-' + (title | lowercase)"
  class="settings-list-header"
  (click)="toggleSettingsVisibility()">
  <h3 class="cog-modal-title" *ngIf="title">{{title}}</h3>

  <!-- Using div wrapper for the tooltip as the tooltip won't display when attached to a disabled button. -->
  <div *ngIf="!readOnly && !alwaysEdit && !alwaysOpen"
    [matTooltipDisabled]="!invalid"
    [matTooltip]="intlService.settingsListDisabledTooltip"
    class="visibility-toggle-button">
    <button [class.settings-visible]="settingsVisible"
      [disabled]="invalid"
      [inheritDataId]="true"
      [trackingEvent]="{properties: {name: title, value: !settingsVisible}}"
      cogDataId="toggle-visibility"
      mat-icon-button
      type="button">
      <cog-icon [shape]="settingsVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"></cog-icon>
    </button>
  </div>
</div>

<div #additionalSettingsContainer [@openClose]="settingsVisible ? 'open' : 'close'"
  [cogDataId]="'settings-list-content-' + (title | lowercase)"
  class="settings-list"
  [ngClass]="{
    'card-style': mode === 'card',
    'definition-list': mode === 'list',
    'inside-card': mode === 'insideCard',
    'no-padding-top': noTopPadding
  }">
  <ng-content></ng-content>
</div>
