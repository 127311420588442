import { Injectable } from '@angular/core';
import { AggregatedAttributesParams, Component } from '@cohesity/api/reporting';
import { camelCase } from 'lodash-es';
import { Dimension, Measurement } from '../iris-reporting.model';

/**
 * This is a measurement value with a label that can be shown in a dropdown
 */
export interface AvailableMeasurement {
  label: string;
  measurement: Measurement;
}

/**
 * This is a dimension value with a label that can be shown in a dropdown
 */
export interface AvailableDimension {
  label: string;
  dimension: Dimension;
}

/**
 * This is used to capture enough metdata info for a value in a report type
 * to generate available measuremenbt and dimension configurations.
 */
export interface ReportColumnConfig {
  /**
   * This is a list of aggregation types that can be rendered in UI charts.
   * For instance, count, sum, avg, etc... Only certain types of aggregations
   * really make sense to apply to a given data type. Most text types would only
   * use count. Bytes types would usually use sum, etc...
   */
  aggs?: AggregatedAttributesParams['aggregationType'][];

  /**
   * Whether or not the data type can be used for grouping. This is usually done when
   * we have a single measurement, it will split the data up into multiple series based
   * on the value of a specific key. For instance, if the measurement is a count of items
   * and the x axis is their environments, you can add a grouping dimension to protection
   * status and create a protected series and unprotected series with separate values
   * for each environment.
   */
  canGroup?: boolean;

  /**
   * When the data type is used as an x dimension, we can show the largest values
   * and group the rest into an 'other' category. This value sets the max number of
   * values to show before
   */
  maxGroupingValues?: number;

  /**
   * The data type represented by the column (without any aggregations). This ultimately
   * gets passed down to the charts, which will need to render the data type correctly.
   */
  dataType?: 'date' | 'number' | 'text' | 'bytes' | 'uuid';
}

/**
 * A map of column configs by attribute name.
 */
export interface ReportColumnConfigs {
  [column: string]: ReportColumnConfig;
}

/**
 * This service is used to calculate available measurements and dimensions that can be
 * used for charts.
 */
@Injectable({ providedIn: 'root' })
export class CustomReportsService {
  /**
   * Given a component definition and list of known columns, this will return the names
   * of the properties that will be included for the data. When there are no aggregations,
   * the return will just be the list of knownColumns. When there are aggregations, we include
   * the grouped attributes and construct column names from the aggregation attributes and
   * aggregation types. For instance, aggregation on entityUuid with count, will return
   * a countEntityUuid.
   *
   * @param component A component definition.
   * @param knownColumns The column names that are included for the report type when there are no aggregations
   * @returns The attributes that will be including for each row.
   */
  getAttributeNamesFromComponent(component: Component, knownColumns: string[]): string[] {
    if (!component.aggs) {
      return [...knownColumns];
    }
    const aggs = component.aggs.aggregatedAttributes.map(aggregatedAttribute =>
      camelCase(`${aggregatedAttribute.aggregationType} ${aggregatedAttribute.attribute}`)
    );
    return [...aggs, ...(component.aggs.groupedAttributes || [])];
  }

  /**
   * Gets measurement configs based on the column names and available config. If a column
   * name directly matches one in our config, we are working with an unaggregated type,
   * though we may have to do a simple aggregation in the UI for certain data types.
   * If it doesn't directly match one in the config, it's an aggregated type, and we
   * can loook for that instead.
   *
   * @param columnNames The column names that were derived from the component config.
   * @param columnConfig Column configuration that matches the component's report type.
   * @rerturns A list of measurements that can be selected in a form.
   */
  getAvailableMeasurements(columnNames: string[], columnConfig: ReportColumnConfigs): AvailableMeasurement[] {
    const measurements = [];
    columnNames.forEach(name => {
      Object.keys(columnConfig || {}).forEach(availableColumn => {
        const config = columnConfig[availableColumn];
        if (availableColumn === name) {
          switch (true) {
            case ['number', 'bytes'].includes(config.dataType):
              measurements.push({
                label: name,
                measurement: {
                  valueKey: name,
                  dataType: config.dataType,
                },
              });
              break;
            case config.aggs.includes('count'):
              measurements.push({
                label: camelCase(`count ${name}`),
                measurement: {
                  valueKey: name,
                  aggregation: 'count',
                },
              });
          }
        } else if (config.aggs?.length) {
          const matchingAgg = config.aggs.find(agg => name === camelCase(`${agg} ${availableColumn}`));
          if (matchingAgg) {
            measurements.push({
              label: name,
              measurement: {
                valueKey: name,
                dataType: config.dataType === 'bytes' ? 'bytes' : undefined,
              },
            });
          }
        }
      });
    });
    return measurements;
  }

  /**
   * Gets dimension configs based on the column names and available config.
   *
   * @param columnNames The column names that were derived from the component config.
   * @param columnConfig Column configuration that matches the component's report type.
   * @returns A list of measurements that can be selected in a form.
   */
  getAvailableDimensions(
    columnNames: string[],
    isGroupingDimension: boolean,
    columnConfig: ReportColumnConfigs
  ): AvailableDimension[] {
    return (columnConfig ?
      columnNames.filter(name => ['text', 'date'].includes(columnConfig[name]?.dataType) &&
          (!isGroupingDimension || columnConfig[name]?.canGroup)) :
      [])
      .map(name => ({
        label: name,
        dimension: {
          dimensionKey: name,
          maxValues: isGroupingDimension ? undefined : columnConfig[name].maxGroupingValues,
        },
      }));
  }
}
