import { Injectable } from '@angular/core';
import {
  AggregatedSubtreeInfo,
  ObjectsDiscoveryParams,
  ProtectionSourceNode,
  ProtectionSourcesServiceApi,
} from '@cohesity/api/v1';
import {
  CommonSearchIndexedObjectsRequestParams,
  FolderItem,
  SourceAttributeFilter,
  SourceAttributeFiltersResponseParams,
  RecoveryServiceApi,
  TargetMailboxParam,
  TargetOneDriveParam,
  GetM365BackupControllerResponseParams,
} from '@cohesity/api/v2';
import { DataFilterItem, DataFilterValue } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Office365LeafNodeType } from '@cohesity/iris-shared-constants';
import { AttributeFilter, AttributeFilterType, Office365FolderAttribute } from 'src/app/modules/protection/office365/shared/office365.model';
import {
  Environment,
  OFFICE365_GROUPS,
  Office365BackupType,
  Office365ContainerNodeType,
  Office365FilterAPIKey,
  Office365GranularSearchResponseType,
  Office365GroupsSearchResultItemMap,
  Office365ObjectTypeToTitle,
  Office365ProtectionGroupEnvMap,
  Office365SearchType,
  RecoveryAction,
  RegistrationStatusType,
  Office365HierarchyType,
  Office365HierarchyMetadataActionType,
  Office365FolderExclusionMode,
  Office365DownloadItemType,
  Office365MailboxItemType,
} from 'src/app/shared/constants';
import { SourceStatusMessage } from '../../models';
import { Office365IndexedItem } from 'src/app/modules/restore/recover-office365/model/office365-common';
import { RestorePointSelection, CreateRecoveryForm } from 'src/app/modules/restore/restore-shared';
import { Office365ObjectSearchResult, Office365ObjectSearchResultGroup } from 'src/app/modules/restore/recover-office365/model';
import {
  DocumentLibraryItemType,
  CreateRecoveryFormO365
} from 'src/app/modules/restore/recover-office365/model/office365-recovery.model';


@Injectable({
  providedIn: 'root'
})
export class Office365UtilityService {
  constructor(
    private irisCtx: IrisContextService,
    private translateService: TranslateService,
  ) { }

  /**
   * Generates the UUID for an Office365 source which is a property within an
   * Elastic Search document.
   *
   * TODO(tauseef): Use this method within the older codes as well including
   * recovery flows in AJS.
   *
   * @param     sourceNode   Specifies the domain node for Office365 entity
   * @returns   UUID acceptable for search within ElasticSearch.
   */
  generateEsCompatibleSourceUuid(sourceNode: ProtectionSourceNode): string {
    if (!sourceNode.protectionSource) {
      return '';
    }
    return 'kDomain_' + sourceNode.protectionSource.name;
  }

  /**
   * Transforms the Filter Attributes obtained from the API into a struct
   * acceptable by CogFilter. The API only returns Dynamic filters. This method
   * also adds the static filters.
   *
   * @param     filters                 Specifies the API response for the
   *                                    source filters.
   * @param     office365WorkloadType   Specifies the backup type.
   * @returns   Instance of Office365Filters.
   */
  transformfilterAttributes(
    filters: SourceAttributeFiltersResponseParams,
    office365WorkloadType: Office365BackupType): Record<AttributeFilterType, AttributeFilter> {
    const office365Filters = this.generateDefaultAttributeFilter();

    /**
     * Office365 workloads for Mailbox & OneDrive only support filtering based
     * on the dynamic filters such as department, country or title.
     */
    const areDynamicFiltersSupportedByWorkload =
      [Office365BackupType.kMailbox, Office365BackupType.kOneDrive].includes(office365WorkloadType);

    if (!filters?.sourceAttributeFilters) {
      return office365Filters;
    }

    // Append dynamic filters.
    filters.sourceAttributeFilters.forEach((sourceAttributeFilter: SourceAttributeFilter) => {
      const attributeFilter: AttributeFilter = {
        filterKey: sourceAttributeFilter.filterAttribute,
        canUseFilter: areDynamicFiltersSupportedByWorkload,
        attributes: sourceAttributeFilter.attributeValues.map((attributeValue: string): DataFilterItem => ({
          label: attributeValue,
          value: attributeValue,
          remove: null,
        })),
      };

      switch (sourceAttributeFilter.filterAttribute) {
        case Office365FilterAPIKey.kUserDepartmentList:
          office365Filters.departmentFilter = attributeFilter;
          break;

        case Office365FilterAPIKey.kUserCountryList:
          office365Filters.countryFilter = attributeFilter;
          break;

        case Office365FilterAPIKey.kUserTitleList:
          office365Filters.titleFilter = attributeFilter;
          break;
      }
    });

    // Append static filters.
    office365Filters.objectStatusFilter = {
      filterKey: 'objectStatus',
      canUseFilter: true,
      attributes: [
        {
          label: 'Unprotected',
          value: 'unprotected',
          remove: null,
        },
        {
          label: 'Protected',
          value: 'protected',
          remove: null,
        },
      ]
    };
    return office365Filters;
  }

  /**
   * Extracts the array of filter values acceptable as parameters by ElasticSearch for filtering Office365 sources.
   *
   * @param     filterPair   Specifies the DataFilterValue object.
   * @returns   Array of filter values.
   */
  extractSelectedFilterValue(filterPair: DataFilterValue<string, any>): string[] {
    if (!filterPair || !filterPair.value || !filterPair.value.length) {
      return [];
    }

    return filterPair.value.map((val: DataFilterItem) => val.value);
  }

  /**
   * Generates the default Office365 filters as acceptable by CogFilter.
   *
   * @returns   Default instance of Record<AttributeFilterType, AttributeFilter>
   */
  generateDefaultAttributeFilter(): Record<AttributeFilterType, AttributeFilter> {
    const defaultAttributeFilter: AttributeFilter = {
      canUseFilter: false,
      attributes: [
        {
          label: '',
          value: '',
          remove: null,
        }
      ],
    };

    const office365Filters: Record<AttributeFilterType, AttributeFilter> = {
      objectStatusFilter: defaultAttributeFilter,
      departmentFilter: defaultAttributeFilter,
      titleFilter: defaultAttributeFilter,
      countryFilter: defaultAttributeFilter,
    };

    return office365Filters;
  }

  /**
   * Generates the Office365 workload name for Protection group / Recovery.
   *
   * @param     workloadType                Specifies the workload type.
   * @param     isGranularBrowse            Specifies whether this is granular browse.
   * @param     isGranularSearch            Specifies whether this is granular search.
   * @param     objectName                  Specifies whether the name of object within
   *                                        which granular search is triggered.
   * @param     mailboxItemsRecoveryEnabled True if mailbox items recovery feature is enabled.
   * @returns   Translated string for Office365 workload
   */
  getWorkloadNameString(
    workloadType: string,
    isGranularBrowse?: boolean,
    isGranularSearch?: boolean,
    objectName?: string,
    mailboxItemsRecoveryEnabled = false,
    downloadItemType?: Office365DownloadItemType
  ): string {
    const defaultPrefix = this.translateService.instant('microsoft365');

    if (isGranularSearch) {
      // Granular search flow
      // TODO(tauseef): Introduce the granular naming for OneDrive & SharePoint.
      switch (workloadType) {
        case Office365BackupType.kSharePoint:
          return objectName
            ? `${this.translateService.instant('office365Restore.recover.RecoverSharePointDocuments')} - ${objectName}`
            : `${this.translateService.instant('office365Restore.recover.RecoverSharePointDocuments')}`;
        case Office365BackupType.kOneDrive:
        case Office365BackupType.kOneDriveCSM:
          return objectName
            ? `${this.translateService.instant('office365Restore.recover.RecoverOneDriveDocuments')} - ${objectName}`
            : `${this.translateService.instant('office365Restore.recover.RecoverOneDriveDocuments')}`;
        case Office365BackupType.kTeams:
          return objectName
            ? `${this.translateService.instant('office365Restore.recover.RecoverTeamsDocuments')} - ${objectName}`
            : `${this.translateService.instant('office365Restore.recover.RecoverTeamsDocuments')}`;
        case Office365BackupType.kGroups:
          return objectName
            ? `${this.translateService.instant('office365Restore.recover.RecoverMsGroupsDocuments')} - ${objectName}`
            : `${this.translateService.instant('office365Restore.recover.RecoverMsGroupsDocuments')}`;
        default: {
          const translateKey = mailboxItemsRecoveryEnabled ?
            'office365Restore.recover.RecoverMailboxItems' :
            'office365Restore.recover.RecoverMails';
          return objectName
            ? `${this.translateService.instant(translateKey)} - ${objectName}`
            : `${this.translateService.instant(translateKey)}`;
        }
      }
    } else if (isGranularBrowse) {
      // Only one of isGranularBrowse & isGranularSearch should be set to true.
      // If both are set give priority to search label.
      switch (workloadType) {
        case Office365BackupType.kOneDrive:
        case Office365BackupType.kOneDriveCSM:
          return `${this.translateService.instant('office365Restore.browse.kOneDrive')}`;
        case Office365BackupType.kSharePoint:
          return `${this.translateService.instant('office365Restore.browse.kSharePoint')}`;
      }
    } else if (downloadItemType) {
      switch (downloadItemType) {
        case Office365DownloadItemType.kTeamPosts:
          return objectName
            ? `${this.translateService.instant('office365Restore.recover.downloadTeamPosts')} - ${objectName}`
            : `${this.translateService.instant('office365Restore.recover.downloadTeamPosts')}`;
        default:
          return objectName
            ? `${this.translateService.instant('office365Restore.recover.downloadChats')} - ${objectName}`
            : `${this.translateService.instant('office365Restore.recover.downloadChats')}`;
      }
    } else {
      switch (workloadType) {
        case Office365BackupType.kOneDrive:
        case Office365BackupType.kOneDriveCSM:
          return `${defaultPrefix} - ${this.translateService.instant('oneDrive')}`;
        case Office365BackupType.kSharePoint:
          return `${defaultPrefix} - ${this.translateService.instant('sharePointOnline')}`;
        case Office365BackupType.kPublicFolders:
          return `${defaultPrefix} - ${this.translateService.instant('publicFolder')}`;
        case Office365BackupType.kGroups:
          return `${defaultPrefix} - ${this.translateService.instant('groups')}`;
        case Office365BackupType.kTeams:
          return `${defaultPrefix} - ${this.translateService.instant('teams')}`;
        default:
          // Handle Object search.
          return `${defaultPrefix} - ${this.translateService.instant('mailbox')}`;
      }
    }
  }

  /**
   * Generates the Office365 Object type for given workload.
   *
   * @param     workloadType   Specifies the workload type.
   * @returns   Translated string for Office365 object type.
   */
  generateObjectTypeHeader(workloadType: string): string {
    switch (workloadType) {
      case Office365BackupType.kOneDrive:
      case Office365BackupType.kOneDriveCSM:
      case Office365BackupType.kMailbox:
        return this.translateService.instant('users');
      case Office365BackupType.kSharePoint:
        return this.translateService.instant('sites');
      case Office365BackupType.kPublicFolders:
        return this.translateService.instant('publicFolders');
      case Office365BackupType.kGroups:
        return this.translateService.instant('groups');
      case Office365BackupType.kTeams:
        return this.translateService.instant('teams');
    }
  }

  /** Gets the corresponding recovery action from O365 workload type.
   *
   * @param     workloadType   Specifies the workload type.
   * @returns   The corresponding recovery action based on workload type.
   */
  getRecoveryAction(workloadType: Office365BackupType): RecoveryAction {
    switch (workloadType) {
      case Office365BackupType.kOneDrive:
        return RecoveryAction.RecoverOneDrive;
      case Office365BackupType.kOneDriveCSM:
        return RecoveryAction.RecoverOneDriveCSM;
      case Office365BackupType.kMailbox:
        return RecoveryAction.RecoverMailbox;
      case Office365BackupType.kMailboxCSM:
        return RecoveryAction.RecoverMailboxCSM;
      case Office365BackupType.kPublicFolders:
        return RecoveryAction.RecoverPublicFolders;
      case Office365BackupType.kSharePoint:
        return RecoveryAction.RecoverSharePoint;
      case Office365BackupType.kSharePointCSM:
        return RecoveryAction.RecoverSharePointCSM;
      case Office365BackupType.kTeams:
        return RecoveryAction.RecoverMsTeam;
      case Office365BackupType.kGroups:
        return RecoveryAction.RecoverMsGroup;
    }
  }

  /**
   * Gets the corresponding workload type from recovery action.
   *
   * @param    recoveryAction  The O365 Recovery Action.
   * @returns  The corresponding workload type.
   */
  getRecoveryWorkload(recoveryAction: RecoveryAction): Office365BackupType {
    switch (recoveryAction) {
      case RecoveryAction.RecoverOneDrive:
        return Office365BackupType.kOneDrive;
      case RecoveryAction.RecoverOneDriveCSM:
        return Office365BackupType.kOneDriveCSM;
      case RecoveryAction.RecoverMailboxCSM:
        return Office365BackupType.kMailboxCSM;
      case RecoveryAction.RecoverPublicFolders:
        return Office365BackupType.kPublicFolders;
      case RecoveryAction.RecoverSharePoint:
        return Office365BackupType.kSharePoint;
      case RecoveryAction.RecoverSharePointCSM:
        return Office365BackupType.kSharePointCSM;
      case RecoveryAction.RecoverMsTeam:
        return Office365BackupType.kTeams;
      case RecoveryAction.RecoverMsGroup:
        return Office365BackupType.kGroups;
      default:
        return Office365BackupType.kMailbox;
    }
  }

  /**
   * Returns the container entity for the given Office365 workload.
   * Container entities include -> [kUsers, kSites, kPublicFolders].
   * Refer office365.constants.ts for details.
   *
   * @param     protectionSource   Specifies the Office365 domain node
   * @param     workloadType       Specifies the Office365 workload
   * @returns   container node based on the workload type
   */
  getContainerEntity(
    protectionSource: ProtectionSourceNode,
    workloadType: Office365BackupType
  ): ProtectionSourceNode {
    if (!get(protectionSource, 'nodes.length') || !workloadType) {
      return null;
    }

    return protectionSource.nodes.find((sourceNode: any) => {
      const office365NodeType = sourceNode.protectionSource.office365ProtectionSource.type;
      switch (workloadType) {
        case Office365BackupType.kMailbox:
        case Office365BackupType.kMailboxCSM:
        case Office365BackupType.kOneDrive:
        case Office365BackupType.kOneDriveCSM:
          return office365NodeType === Office365ContainerNodeType.kUsers ||
            office365NodeType === Office365ContainerNodeType.kOutlook;
        case Office365BackupType.kSharePoint:
        case Office365BackupType.kSharePointCSM:
          return office365NodeType === Office365ContainerNodeType.kSites;
        case Office365BackupType.kPublicFolders:
          return office365NodeType === Office365ContainerNodeType.kPublicFolders;
        case Office365BackupType.kTeams:
          return office365NodeType === Office365ContainerNodeType.kTeams;
        case Office365BackupType.kGroups:
          return office365NodeType === Office365ContainerNodeType.kGroups;
      }
    });
  }

  /**
   * Determines whether the node is container entity for the given Office365 source.
   * Container entities include -> [kUsers, kSites, kPublicFolders].
   * Refer office365.constants.ts for details.
   *
   * @param     protectionSource   Specifies the Office365 domain node
   * @returns   boolean
   */
  isContainerEntity(protectionSource: ProtectionSourceNode[]): boolean {
    const containerNodeType = protectionSource[0]?.protectionSource?.office365ProtectionSource?.type;
    return OFFICE365_GROUPS.office365EntityContainers.includes(containerNodeType);
  }

  /**
   * Determines whether the current search workflow is to browse items through
   * indexed View or RocksDB read dir. Refer Office365SearchType for details.
   *
   * @param     searchType   Specifies the search type
   * @returns   True, iff it is a browse to be executed within 'cfileindex'
   *            or 'rocksDB'.
   *            False otherwise.
   */
  isGranularBrowse(searchType: Office365SearchType): boolean {
    switch (searchType) {
      case Office365SearchType.kOneDriveBrowse:
      case Office365SearchType.kSharePointSiteBrowse:
        return true;
      default:
        return false;
    }
  }

  /**
   * Determines whether the current search workflow is to search documents
   * within 'cfileindex'. Refer Office365SearchType for details.
   *
   * @param     searchType   Specifies the search type
   * @returns   True, iff the search is to be executed within 'cfileindex'.
   *            False if the search goes to 'objindex'.
   */
  isGranularSearch(searchType: Office365SearchType): boolean {
    switch (searchType) {
      case Office365SearchType.kEmailSearch:
      case Office365SearchType.kOneDriveDocumentSearch:
      case Office365SearchType.kSharePointSiteDocumentSearch:
      case Office365SearchType.kPublicFolderContentSearch:
      case Office365SearchType.kTeamChannelSearch:
      case Office365SearchType.kTeamContentSearch:
      case Office365SearchType.kGroupMailboxItemSearch:
      case Office365SearchType.kGroupSiteItemSearch:
        return true;
      default:
        return false;
    }
  }

  /**
   * Determines the document type for search request within the V2 search API
   * for documents within 'cfileindex'.
   *
   * @param     searchType   Specifies the search type
   * @returns   The indexed doc type
   */
  getIndexedItemType(searchType: Office365SearchType): CommonSearchIndexedObjectsRequestParams['objectType'] {
    switch (searchType) {
      case Office365SearchType.kEmailSearch:
        return 'Emails';
      case Office365SearchType.kSharePointSiteDocumentSearch:
        return 'SharepointObjects';
      case Office365SearchType.kOneDriveDocumentSearch:
        return 'OneDriveObjects';
      case Office365SearchType.kTeamContentSearch:
      case Office365SearchType.kTeamChannelSearch:
        return 'TeamsObjects';
      case Office365SearchType.kGroupMailboxItemSearch:
      case Office365SearchType.kGroupSiteItemSearch:
        return 'GroupsObjects';
      default:
        return 'PublicFolders';
    }
  }

  /**
   * Determines the type for the response received frm the V2 search API for
   * documents within 'cfileindex'.
   *
   * @param     searchType   Specifies the search type
   * @returns   The granular search response type
   */
  getIndexedItemSearchResponseType(searchType: Office365SearchType): Office365GranularSearchResponseType {
    switch (searchType) {
      case Office365SearchType.kEmailSearch:
        return Office365GranularSearchResponseType.kEmails;
      case Office365SearchType.kPublicFolderContentSearch:
        return Office365GranularSearchResponseType.kPublicFolderItems;
      case Office365SearchType.kSharePointSiteDocumentSearch:
        return Office365GranularSearchResponseType.kSharePointItems;
      case Office365SearchType.kOneDriveDocumentSearch:
        return Office365GranularSearchResponseType.kOneDriveItems;
      case Office365SearchType.kTeamContentSearch:
      case Office365SearchType.kTeamChannelSearch:
        return Office365GranularSearchResponseType.kTeamsItems;
      case Office365SearchType.kGroupMailboxItemSearch:
      case Office365SearchType.kGroupSiteItemSearch:
        return Office365GranularSearchResponseType.kMsGroupItems;

      // TODO(tauseef): Wire up other workloads within Office365.
    }
  }

  /**
   * Determines the available search types within Office365 workload
   *
   * @param     workloadType   Specifies the Office365 workload
   * @returns   Array of possible search types within the given workload
   */
  getSearchTypes(workloadType: Office365BackupType): Office365SearchType[] {
    switch (workloadType) {
      case Office365BackupType.kMailbox:
        return [
          Office365SearchType.kMailboxSearch,
          Office365SearchType.kEmailSearch
        ];

      case Office365BackupType.kOneDrive:
        return [
          Office365SearchType.kOneDriveSearch,
          Office365SearchType.kOneDriveBrowse,
          Office365SearchType.kOneDriveDocumentSearch
        ];

      case Office365BackupType.kSharePoint:
        return [
          Office365SearchType.kSharePointSiteSearch,
          Office365SearchType.kSharePointSiteBrowse,
          Office365SearchType.kSharePointSiteDocumentSearch
        ];

      case Office365BackupType.kPublicFolders:
        return [
          Office365SearchType.kPublicFolderSearch,
          ...(flagEnabled(this.irisCtx.irisContext, 'office365PublicFolderGranularRecoveryEnabled')
            ? [Office365SearchType.kPublicFolderContentSearch]
            : []),
        ];

      case Office365BackupType.kTeams:
        return [
          Office365SearchType.kTeamSearch,
          ...(flagEnabled(this.irisCtx.irisContext, 'office365TeamsGranularChannelsRecoveryEnabled')
            ? [Office365SearchType.kTeamChannelSearch]
            : []),
          ...(flagEnabled(this.irisCtx.irisContext, 'office365TeamsGranularFilesRecoveryEnabled')
            ? [Office365SearchType.kTeamContentSearch]
            : []),
        ];

      case Office365BackupType.kGroups:
        return [
          Office365SearchType.kGroupSearch,
          ...(this.groupGlrEnabled
            ? [
              Office365SearchType.kGroupMailboxItemSearch,
              Office365SearchType.kGroupSiteItemSearch
            ]
            : []),
        ];
    }
  }

  /**
   * Determines the backup type for the given 'searchType'.
   *
   * @param searchType Specifies the search type.
   * @returns Corresponding Office365BackupType.
   */
  getWorkloadFromSearchType(searchType: Office365SearchType): Office365BackupType {
    if (!searchType) {
      return null;
    }

    switch (searchType) {
      // Mailbox.
      case Office365SearchType.kMailboxSearch:
      case Office365SearchType.kEmailSearch:
        return Office365BackupType.kMailbox;

      // OneDrive.
      case Office365SearchType.kOneDriveSearch:
      case Office365SearchType.kOneDriveBrowse:
      case Office365SearchType.kOneDriveDocumentSearch:
        return Office365BackupType.kOneDrive;

      // SharePoint.
      case Office365SearchType.kSharePointSiteSearch:
      case Office365SearchType.kSharePointSiteBrowse:
      case Office365SearchType.kSharePointSiteDocumentSearch:
        return Office365BackupType.kSharePoint;

      // PublicFolder.
      case Office365SearchType.kPublicFolderSearch:
      case Office365SearchType.kPublicFolderContentSearch:
        return Office365BackupType.kPublicFolders;

      // Teams
      case Office365SearchType.kTeamSearch:
      case Office365SearchType.kTeamChannelSearch:
      case Office365SearchType.kTeamContentSearch:
        return Office365BackupType.kTeams;

      // Groups.
      case Office365SearchType.kGroupSearch:
      case Office365SearchType.kGroupMailboxItemSearch:
      case Office365SearchType.kGroupSiteItemSearch:
        return Office365BackupType.kGroups;

      default:
        return null;
    }
  }

  /**
   * Determines the available granular search types within Office365 workload
   * TODO(Vipul): Wire granular search types in case of mailbox, public folders,
   * teams and groups once they're supported for browse. For now, they won't be
   * coming here as wer're setting recovery type in initializeAbbreviatedFlow
   * for these workloads. Also, create a UT for this function.
   *
   * @param     workloadType   Specifies the Office365 workload
   * @returns   Array of possible granularsearch types within the given workload
   */
  getGranularSearchTypes(workloadType: Office365BackupType): Office365SearchType[] {
    switch (workloadType) {
      case Office365BackupType.kMailbox:
        return [
          Office365SearchType.kEmailSearch
        ];

      case Office365BackupType.kOneDrive:
        return [
          Office365SearchType.kOneDriveBrowse,
          Office365SearchType.kOneDriveDocumentSearch
        ];

      case Office365BackupType.kSharePoint:
        return [
          Office365SearchType.kSharePointSiteBrowse,
          Office365SearchType.kSharePointSiteDocumentSearch
        ];

      case Office365BackupType.kPublicFolders:
        return [
          ...(flagEnabled(this.irisCtx.irisContext, 'office365PublicFolderGranularRecoveryEnabled')
            ? [Office365SearchType.kPublicFolderContentSearch]
            : []),
        ];

      case Office365BackupType.kTeams:
        return [
          ...(flagEnabled(this.irisCtx.irisContext, 'office365TeamsGranularChannelsRecoveryEnabled')
            ? [Office365SearchType.kTeamChannelSearch]
            : []),
          ...(flagEnabled(this.irisCtx.irisContext, 'office365TeamsGranularFilesRecoveryEnabled')
            ? [Office365SearchType.kTeamContentSearch]
            : []),
        ];

      case Office365BackupType.kGroups:
        return [
          ...(this.groupGlrEnabled
            ? [
              Office365SearchType.kGroupMailboxItemSearch,
              Office365SearchType.kGroupSiteItemSearch
            ]
            : []),
        ];
    }
  }

  /**
   * Determines whether the registration for the given Office365 domain is
   * complete.
   *
   * @param     protectionSource   Specifies the Office365 domain entity.
   * @returns   True, if the registartion is complete, false otherwise.
   */
  isRegistrationComplete(protectionSource: ProtectionSourceNode): boolean {
    return protectionSource?.registrationInfo?.authenticationStatus === RegistrationStatusType.kFinished;
  }

  /**
   * Generates the entity filters for the given workload type within Office365.
   * Currently this is only applicable for 'kUser' entities which can have
   * either 'kMailbox' or 'kOneDrive' as the workload. Users can may or may not
   * have a mailbox/onedrive provisioned within Office365.
   *
   * 'kMailbox'  -- User list should contain entities with valid mailbox only.
   * 'kOneDrive' -- User list should contain entities with valid onedrive only.
   *
   * @param     workloadType   Specifies the Office365 workload type.
   * @returns   Partial request parameters for fetching User entities.
   */
  getEntityFilterParameters(
    workloadType: Office365BackupType): Partial<ProtectionSourcesServiceApi.ListProtectionSourcesParams> {
    if (workloadType === Office365BackupType.kMailbox || workloadType === Office365BackupType.kMailboxCSM) {
      return {
        hasValidMailbox: true,
        isSecurityGroup: true,
      };
    } else if (workloadType === Office365BackupType.kOneDrive
        || workloadType === Office365BackupType.kOneDriveCSM) {
      return {
        hasValidOnedrive: true,
        isSecurityGroup: true,
      };
    } else if (workloadType === Office365BackupType.kGroups) {
      return {
        isSecurityGroup: false,
      };
    }

    return null;
  }

  /**
   * Determines O365 workload type based on container or leaf node type.
   *
   * @param objectType  Office 365 container or leaf node type.
   * @param viewType Specifies the Office365 filter view.
   * @param shouldIncludeCSMWorkload Specifies whether MSFT Backup Storage is enabled.
   *
   * @returns  The corresponding Office 365 workload types.
   */
  getWorkloadFromEntity(objectType: Office365LeafNodeType | Office365ContainerNodeType,
    protectionSourceNode?: ProtectionSourceNode,
    shouldIncludeCSMWorkload?: boolean): Office365BackupType[] {

    switch (objectType) {
      case Office365ContainerNodeType.kGroups:
      case Office365LeafNodeType.kGroup:
        // kGroup entities with security-enabled attribute can only have
        // mailbox/onedrive workload.
        if (protectionSourceNode?.protectionSource?.office365ProtectionSource?.groupInfo?.isSecurityEnabled) {
          return [Office365BackupType.kMailbox, Office365BackupType.kOneDrive];
        }
        return [Office365BackupType.kGroups];
      case Office365ContainerNodeType.kPublicFolders:
      case Office365LeafNodeType.kPublicFolder:
        return [Office365BackupType.kPublicFolders];
      case Office365ContainerNodeType.kSites:
      case Office365LeafNodeType.kSite:
        return [
          Office365BackupType.kSharePoint,
          shouldIncludeCSMWorkload && Office365BackupType.kSharePointCSM
        ].filter(Boolean);
      case Office365ContainerNodeType.kTeams:
      case Office365LeafNodeType.kTeam:
        return [Office365BackupType.kTeams];
      case Office365ContainerNodeType.kUsers:
      case Office365LeafNodeType.kUser:
        return [
          Office365BackupType.kMailbox,
          Office365BackupType.kOneDrive,
          shouldIncludeCSMWorkload && Office365BackupType.kMailboxCSM,
          shouldIncludeCSMWorkload && Office365BackupType.kOneDriveCSM
        ].filter(Boolean);
      case Office365LeafNodeType.kO365Exchange:
        return [
          Office365BackupType.kMailbox,
          shouldIncludeCSMWorkload && Office365BackupType.kMailboxCSM,
        ].filter(Boolean);
      case Office365LeafNodeType.kO365OneDrive:
        return [
          Office365BackupType.kOneDrive,
          shouldIncludeCSMWorkload && Office365BackupType.kOneDriveCSM,
        ].filter(Boolean);
      default:
        return [];
    }
  }

  /**
   * Gets the corresponding recovery action from O365 workload type.
   *
   * @param     workloadType   Specifies the workload type.
   * @returns   The corresponding recovery action based on workload type.
   */
  getRecoveryActionByJobEnv(environment: Environment): RecoveryAction[] {
    switch (environment) {
      case Environment.kO365Exchange:
        return [
          RecoveryAction.RecoverMailbox,
          RecoveryAction.RecoverMailboxCSM,
          RecoveryAction.ConvertToPst,
          RecoveryAction.DownloadChats
        ];
      case Environment.kO365Group:
        return [
          RecoveryAction.RecoverMsGroup,
          RecoveryAction.ConvertToPst,
          RecoveryAction.DownloadFilesAndFolders
        ];
      case Environment.kO365OneDrive:
        return [
          RecoveryAction.RecoverOneDrive,
          RecoveryAction.RecoverOneDriveCSM,
          RecoveryAction.DownloadFilesAndFolders
        ];
      case Environment.kO365PublicFolders:
        return [RecoveryAction.RecoverPublicFolders];
      case Environment.kO365Sharepoint:
        return [
          RecoveryAction.RecoverSharePoint,
          RecoveryAction.RecoverSharePointCSM,
          RecoveryAction.DownloadFilesAndFolders
        ];
      case Environment.kO365Teams:
        return [RecoveryAction.RecoverMsTeam, RecoveryAction.DownloadChats, RecoveryAction.DownloadFilesAndFolders];
    }
  }

  /**
   * Checks if workload type is selected for discovery at the time of registration.
   *
   * @param  newWorkload   Workload type.
   * @param  objectsDiscoveryParams   Object discovery params.
   *
   * @returns   Is the workload discovered based on container nodes.
   */
  isO365WorkloadDiscovered(newWorkload: string, objectsDiscoveryParams: ObjectsDiscoveryParams): boolean {
    if (!objectsDiscoveryParams) {
      return true;
    }
    switch (newWorkload) {
      case Office365BackupType.kGroups:
        return objectsDiscoveryParams?.discoverableObjectTypeList?.includes(Office365ContainerNodeType.kGroups);
      case Office365BackupType.kMailbox:
        return objectsDiscoveryParams?.usersDiscoveryParams?.discoverUsersWithMailbox;
      case Office365BackupType.kOneDrive:
        return objectsDiscoveryParams?.usersDiscoveryParams?.discoverUsersWithOnedrive;
      case Office365BackupType.kSharePoint:
        return objectsDiscoveryParams?.discoverableObjectTypeList?.includes(Office365ContainerNodeType.kSites);
      case Office365BackupType.kMailboxCSM:
        return objectsDiscoveryParams?.usersDiscoveryParams?.discoverUsersWithMailbox;
      case Office365BackupType.kOneDriveCSM:
        return objectsDiscoveryParams?.usersDiscoveryParams?.discoverUsersWithOnedrive;
      case Office365BackupType.kSharePointCSM:
        return objectsDiscoveryParams?.discoverableObjectTypeList?.includes(Office365ContainerNodeType.kSites);
      case Office365BackupType.kTeams:
        return objectsDiscoveryParams?.discoverableObjectTypeList?.includes(Office365ContainerNodeType.kTeams);
      case Office365BackupType.kPublicFolders:
        return objectsDiscoveryParams?.discoverableObjectTypeList?.includes(Office365ContainerNodeType.kPublicFolders);
    }
  }

  /**
   * Returns the aggregation summary matching with the workload from the list
   * of aggregation summary passed. This is useful to determine the accurate
   * aggregation stat when the same entity can be protected by multiple
   * workloads.
   *
   * @param     aggregationSummaryList   Specifies the list of aggregation info
   * @param     workloadType             Specifies the workload type
   * @returns   Returns the aggregation info corresponding to the workload.
   */
  getAggregationSummaryByWorkload(aggregationSummaryList: AggregatedSubtreeInfo[],
    workloadType: Office365BackupType): AggregatedSubtreeInfo {
    if (!aggregationSummaryList) {
      return {};
    }

    return (aggregationSummaryList || []).find(sum =>
      sum.environment === Office365ProtectionGroupEnvMap[workloadType]
    );
  }

  /**
   * Calculates the summary of all leaf entities protected and unprotected.
   *
   * TODO(tauseef): Include Groups & PublicFolders later.
   *
   * @param     domainNode   Specifies the domain entity for O365
   * @returns   Map of protection status to the Map of leaf entity types to
   *            their count.
   */
  getAggregationSummayAcrossAllWorkload(domainNode: ProtectionSourceNode): any {
    // Container for workload 'kMailbox' & 'kOnedrive' are same, hence either
    // can be picked up.
    const usersContainer = this.getContainerEntity(
      domainNode, Office365BackupType.kMailbox);
    const sitesContainer = this.getContainerEntity(
      domainNode, Office365BackupType.kSharePoint);
    const teamsContainer = this.getContainerEntity(
      domainNode, Office365BackupType.kTeams);
    const groupsContainer = this.getContainerEntity(
      domainNode, Office365BackupType.kGroups);

    // Calculate protected count.
    let usersProtectedCount = 0;
    let sitesProtectedCount = 0;
    let teamsProtectedCount = 0;
    let groupsProtectedCount = 0;

    if (usersContainer?.protectedSourcesSummary?.length) {
      usersProtectedCount = usersContainer.protectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365)?.leavesCount || 0;
    }

    if (sitesContainer?.protectedSourcesSummary?.length) {
      sitesProtectedCount = sitesContainer?.protectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365)?.leavesCount || 0;
    }

    if (teamsContainer?.protectedSourcesSummary?.length) {
      teamsProtectedCount = teamsContainer?.protectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365Teams)?.leavesCount || 0;
    }

    if (groupsContainer?.protectedSourcesSummary?.length) {
      groupsProtectedCount = groupsContainer?.protectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365Group)?.leavesCount || 0;
    }

    // Calculate unprotected count.
    let usersUnprotectedCount = 0;
    let sitesUnprotectedCount = 0;
    let teamsUnprotectedCount = 0;
    let groupsUnprotectedCount = 0;

    if (usersContainer?.unprotectedSourcesSummary?.length) {
      usersUnprotectedCount = usersContainer.unprotectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365)?.leavesCount || 0;
    }

    if (sitesContainer?.unprotectedSourcesSummary?.length) {
      sitesUnprotectedCount = sitesContainer?.unprotectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365Sharepoint)?.leavesCount || 0;
    }

    if (teamsContainer?.unprotectedSourcesSummary?.length) {
      teamsUnprotectedCount = teamsContainer?.unprotectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365Teams)?.leavesCount || 0;
    }

    if (groupsContainer?.unprotectedSourcesSummary?.length) {
      groupsUnprotectedCount = groupsContainer?.unprotectedSourcesSummary.find(
        summary => summary?.environment === Environment.kO365Group)?.leavesCount || 0;
    }

    const unprotectedMap = new Map<string, number>();
    unprotectedMap.set('total',
      usersUnprotectedCount + sitesUnprotectedCount + teamsUnprotectedCount +
      groupsUnprotectedCount);
    unprotectedMap.set('users', usersUnprotectedCount);
    unprotectedMap.set(Office365ObjectTypeToTitle[Office365ContainerNodeType.kSites],
      sitesUnprotectedCount);
    unprotectedMap.set(Office365ObjectTypeToTitle[Office365ContainerNodeType.kTeams],
      teamsUnprotectedCount);
    unprotectedMap.set(Office365ObjectTypeToTitle[Office365ContainerNodeType.kGroups],
      groupsUnprotectedCount);

    const protectedMap = new Map<string, number>();
    protectedMap.set('total',
      usersProtectedCount + sitesProtectedCount + teamsProtectedCount +
      groupsProtectedCount);
    protectedMap.set('users', usersProtectedCount);
    protectedMap.set(Office365ObjectTypeToTitle[Office365ContainerNodeType.kSites],
      sitesProtectedCount);
    protectedMap.set(Office365ObjectTypeToTitle[Office365ContainerNodeType.kTeams],
      teamsProtectedCount);
    protectedMap.set(Office365ObjectTypeToTitle[Office365ContainerNodeType.kGroups],
      groupsProtectedCount);

    return {
      unprotectedMap,
      protectedMap
    };
  }

  /**
   * Determines the Map of Office365 workload to its container node.
   *
   * @param rootNode Specifies the Office365 DOmain Node(kDomain entity)
   *                 Refer office365.constants.ts for details on hierarchy.
   * @returns Map of workload to its container node.
   */
  getWorkloadContainerMap(rootNode: ProtectionSourceNode): Map<Office365BackupType, ProtectionSourceNode> {
    if (!rootNode?.nodes?.length) {
      return null;
    }

    const workloadContainerMap = new Map<Office365BackupType, ProtectionSourceNode>();
    rootNode.nodes.forEach((containerNode: ProtectionSourceNode) => {
      // Copy registration info on container nodes.
      containerNode.registrationInfo = rootNode.registrationInfo;
      const office365NodeType = containerNode.protectionSource.office365ProtectionSource.type;
      switch (office365NodeType) {
        case Office365ContainerNodeType.kUsers:
          workloadContainerMap.set(Office365BackupType.kMailbox, containerNode);
          workloadContainerMap.set(Office365BackupType.kOneDrive, containerNode);
          break;

        case Office365ContainerNodeType.kSites:
          workloadContainerMap.set(Office365BackupType.kSharePoint, containerNode);
          break;

        case Office365ContainerNodeType.kTeams:
          workloadContainerMap.set(Office365BackupType.kTeams, containerNode);
          break;

        case Office365ContainerNodeType.kGroups:
          workloadContainerMap.set(Office365BackupType.kGroups, containerNode);
          break;

        case Office365ContainerNodeType.kPublicFolders:
          workloadContainerMap.set(Office365BackupType.kPublicFolders, containerNode);
          break;

        default:
          // Nothing To Do.
      }
    });

    return workloadContainerMap;
  }

  /**
   * Determines the workload for M365 based on the type of View applied to the
   * EH.
   *
   * @param workloadType Specifies the current workload type
   * @param viewType Specifies the view type
   * @returns Applicable workload for the container
   */
  getContainerWorkloadMapping(workloadType: Office365BackupType,
    hierarchyType: Office365HierarchyType): Office365BackupType {
    // The exception here is only Security Groups which are actually 'kGroup'
    // entities but protect the members' mailbox/onedrive.
    if (hierarchyType && hierarchyType === Office365HierarchyType.SecurityGroup) {
      if (workloadType === Office365BackupType.kMailbox ||
          workloadType === Office365BackupType.kOneDrive) {
        return Office365BackupType.kGroups;
      }
    }
    return workloadType;
  }

  /**
   * @param workloadType Specifies the current workload type
   * @returns True iff the current workload is for mailbox/onedrive
   */
  isMailboxOrOneDriveWorkload(workloadType: Office365BackupType): boolean {
    return [
      Office365BackupType.kMailbox,
      Office365BackupType.kOneDrive,
      Office365BackupType.kMailboxCSM,
      Office365BackupType.kOneDriveCSM
    ].includes(workloadType);
  }

  /**
   * @param entityType Specifies the entity type
   * @returns True iff the entity supports the mailbox/onedrive backup
   */
  isMailboxOrOneDriveWorkloadSupported(entityType: Office365LeafNodeType): boolean {
    return [
      Office365LeafNodeType.kUser,
      Office365LeafNodeType.kGroup
    ].includes(entityType);
  }

  /**
   * Maps the container node to its leaf node.
   *
   * @param containerType Specifies the container node type.
   * @returns Leaf node type applicable for the container.
   */
  getLeafNodeTypeForContainerType(containerType: Office365ContainerNodeType): Office365LeafNodeType {
    if (!containerType) {
      return null;
    }

    switch (containerType) {
      case Office365ContainerNodeType.kUsers:
        return Office365LeafNodeType.kUser;
      case Office365ContainerNodeType.kGroups:
        return Office365LeafNodeType.kGroup;
      case Office365ContainerNodeType.kSites:
        return Office365LeafNodeType.kSite;
      case Office365ContainerNodeType.kTeams:
        return Office365LeafNodeType.kTeam;
      case Office365ContainerNodeType.kPublicFolders:
        return Office365LeafNodeType.kPublicFolder;

      // Office365ContainerNodeType.kOutlook is deprecated.
    }
  }

  /**
   * Generates the actionable messages for the given workload. The actionable
   * message has 3 properties, namely, status, actionText & type.
   * Based on the actionText the update to sources are triggerred by caller.
   *
   * @param workloadType Specifies the M365 backup type.
   * @param objectDiscoveryParams Specifies instance of the
   *                              ObjectsDiscoveryParams
   * @returns List of status messages for the given workload.
   */
  getActionableMessagesForWorkload(workloadType: Office365BackupType,
    objectDiscoveryParams: ObjectsDiscoveryParams): SourceStatusMessage[] {

    if (!workloadType || !objectDiscoveryParams) {
      return null;
    }

    const statusMessageList: SourceStatusMessage[] = [];

    switch (workloadType) {
      case Office365BackupType.kMailbox:
        if (!objectDiscoveryParams?.usersDiscoveryParams?.fetchMailboxInfo) {
          statusMessageList.push(this.generateActionableMessageForActionType(
            Office365HierarchyMetadataActionType.FetchMailboxInfo));
        }
        break;

      case Office365BackupType.kOneDrive:
        if (!objectDiscoveryParams?.usersDiscoveryParams?.fetchOneDriveInfo) {
          statusMessageList.push(this.generateActionableMessageForActionType(
            Office365HierarchyMetadataActionType.FetchOneDriveInfo));
        }
        if (objectDiscoveryParams?.usersDiscoveryParams?.skipUsersWithoutMySite) {
          statusMessageList.push(this.generateActionableMessageForActionType(
            Office365HierarchyMetadataActionType.IncludeUsersWithoutMySite));
        }
        break;

      case Office365BackupType.kSharePoint:
        if (!objectDiscoveryParams?.sitesDiscoveryParams?.enableSiteTagging) {
          statusMessageList.push(this.generateActionableMessageForActionType(
            Office365HierarchyMetadataActionType.EnableSiteTagging));
        }
        break;

      default:
        // Do nothing. Other workloads may choose to add their status messages.
    }

    return statusMessageList;
  }

  /**
   * Specifies the comparator function for sorting default folder names within
   * the backup workflow. This is currently only used by
   * SettingsListFolderExclusionComponent.
   *
   * @param f1 Specifies the instance of Office365FolderAttribute.
   * @param f2 Specifies the instance of Office365FolderAttribute.
   * @returns 1 if the translatedName of f1 is lexicographically
   *          greater than translatedName of f2. Returns -1 for opposite and 0
   *          is they are equal.
   */
  office365FolderCompareFn = (f1: Office365FolderAttribute, f2: Office365FolderAttribute) => {
    if (!f1 || !f1.translatedName) {
      return -1;
    }
    if (!f2 || f2.translatedName) {
      return 1;
    }

    // String.prototype.localeCompare() guarantees sorting of string containing
    // non-ASCII characters as well.
    return f1.translatedName.localeCompare(f2.translatedName);
  };


  /**
   * Returns whether GLR of non-email items of a mailbox is enabled. Non-email items
   * include Calendar, Contacts, Tasks & Notes.
   */
  isMailboxNonEmailItemsRecoveryEnabled(): boolean {
    return isDmsScope(this.irisCtx.irisContext) ? flagEnabled(this.irisCtx.irisContext,
      'dmsOffice365MailboxItemsRecovery'): flagEnabled(this.irisCtx.irisContext,
      'office365MailboxItemsRecovery');
  }

  /**
   * Returns a map from the default folder name to the exclusion mode.
   * Refer Office365FolderExclusionMode for details.
   *
   * @returns Specifies an instance of Map
   */
  getUntranslatedOffice365FolderNameToExclusionModeMap(): Map<string, Office365FolderExclusionMode> {
    const nonEmailFolderSet = this.getUntranslatedOffice365NonEmailFolderSet();
    const recoverableItemFolderSet = this.getUntranslatedOffice365RecoverableItemFolderSet();

    const defaultFolderNameToExclusionModeMap = new Map<string, Office365FolderExclusionMode>([
      // ALLOW Mode default folders.
      ['job.settings.o365Outlook.archiveRecoverableItems', Office365FolderExclusionMode.kAllow],
      ['job.settings.o365Outlook.recoverableItems', Office365FolderExclusionMode.kAllow],

      // DENY Mode default folders.
      ['calendar', Office365FolderExclusionMode.kDeny],
      ['contacts', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.deletedItems', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.drafts', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.infectedItems', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.inPlaceArchive', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.junkEmail', Office365FolderExclusionMode.kDeny],
      ['notes', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.outbox', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.rssSubscriptions', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.sentItems', Office365FolderExclusionMode.kDeny],
      ['job.settings.o365Outlook.syncIssues', Office365FolderExclusionMode.kDeny],
      ['tasks', Office365FolderExclusionMode.kDeny]
    ]);

    const enabledFolderNmeToExclusionModeMap = new Map<string, Office365FolderExclusionMode>();
    defaultFolderNameToExclusionModeMap.forEach((exclusionMode, folderKey) => {
      // Add unguarded default folders.
      if (!nonEmailFolderSet.has(folderKey) && !recoverableItemFolderSet.has(folderKey)) {
        enabledFolderNmeToExclusionModeMap.set(folderKey, exclusionMode);
      }

      // Check for flag for non-email items.
      if (nonEmailFolderSet.has(folderKey) && this.isMailboxNonEmailItemsRecoveryEnabled()) {
        enabledFolderNmeToExclusionModeMap.set(folderKey, exclusionMode);
      }

      // Check for flags for recoverable items.
      if (recoverableItemFolderSet.has(folderKey) && ((isDmsScope(this.irisCtx.irisContext) &&
          flagEnabled(this.irisCtx.irisContext, 'dmsOffice365RecoverableItemsSupportEnabled')) ||
          flagEnabled(this.irisCtx.irisContext, 'office365RecoverableItemsSupportEnabled'))) {
        enabledFolderNmeToExclusionModeMap.set(folderKey, exclusionMode);
      }
    });

    return enabledFolderNmeToExclusionModeMap;
  }

  /**
   * @returns Set of folder names which are to be marked as excluded by default
   * for new backups.
   */
  getUntranslatedOffice365DefaultFolderExclusions(): Set<string> {
    return new Set<string>([
      'job.settings.o365Outlook.archiveRecoverableItems',
      'job.settings.o365Outlook.inPlaceArchive',
      'job.settings.o365Outlook.recoverableItems',
    ]);
  }

  /**
   * @returns Set of Non-email folders within a mailbox.
   */
  getUntranslatedOffice365NonEmailFolderSet(): Set<string> {
    return new Set<string>([
      'calendar',
      'contacts',
      'notes',
      'tasks',
    ]);
  }

  /**
   * @returns Set of Recoverable items' Folders within a Mailbox.
   */
  getUntranslatedOffice365RecoverableItemFolderSet(): Set<string> {
    return new Set<string>([
      'job.settings.o365Outlook.archiveRecoverableItems',
      'job.settings.o365Outlook.recoverableItems',
    ]);
  }

  /**
   * Determines whether the Protection Setting for Preservation Hold Library
   * should be removed from Additional Settings or not. This only applies to
   * the OneDrive & SharePoint workload.
   *
   * @param workload Specifies the Office365 workload type
   * @returns True, if the PHL setting is to be removed.
   */
  shouldRemovePhlSettingForWorkload(workload: Office365BackupType): boolean {
    if (!workload || ![Office365BackupType.kSharePoint,
      Office365BackupType.kOneDrive].includes(workload)) {
      return true;
    }

    let onPremFlagName, dmaasFlagName: string;
    if (workload === Office365BackupType.kOneDrive) {
      onPremFlagName = 'office365OneDrivePreservationHoldLibraryBackupSupportEnabled';
      dmaasFlagName = 'dmsOffice365OneDrivePreservationHoldLibraryBackupSupportEnabled';
    }

    if (workload === Office365BackupType.kSharePoint) {
      onPremFlagName = 'office365SharePointPreservationHoldLibraryBackupSupportEnabled';
      dmaasFlagName = 'dmsOffice365SharePointPreservationHoldLibraryBackupSupportEnabled';
    }

    if (isDmsScope(this.irisCtx.irisContext)) {
      return !flagEnabled(this.irisCtx.irisContext, dmaasFlagName);
    }
    return !flagEnabled(this.irisCtx.irisContext, onPremFlagName);
  }

  /*
   * Prunes unnecessary properties on the protection source node.
   * TODO(tauseef): Move this pruning logic within Magneto.
   *
   * @param protectionSource Specifies the instance of protection source node.
   * @return returns a lightweight instance of the source node.
   */
  pruneProtectionSource(protectionSource: ProtectionSourceNode): ProtectionSourceNode {
    if (!this.shouldImproveUiScalability()) {
      return protectionSource;
    }

    if (protectionSource?.registrationInfo?.warningMessages?.length) {
      protectionSource.registrationInfo.warningMessages.length = 0;
    }

    return protectionSource;
  }

  /**
   * Specifies whether the UI flag for improving scalability is enabled.
   *
   * @returns True, if the flag is enabled. False, otherwise.
   */
  shouldImproveUiScalability(): boolean {
    return flagEnabled(this.irisCtx.irisContext,
      'office365ImproveUiScaleEnabled');
  }

  /**
   * Generates the instance of SourceStatusMessage for the given action type.
   *
   * @param actionType Specifies instance of Office365HierarchyMetadataActionType
   * @returns Actionable message for the given action type
   */
  private generateActionableMessageForActionType(
    actionType: Office365HierarchyMetadataActionType): SourceStatusMessage {
    if (!actionType) {
      return null;
    }

    return {
      status: this.translateService.instant('sources.office365.' + actionType + '.statusInfo'),
      actionText:  this.translateService.instant('sources.office365.' + actionType),
      actionType: actionType,
      type: 'info'
    };
  }

  /**
   * Returns the nodeType and leafNodeType for the corresponding workflow
   *
   * @param workloadType    The current workflow to use within the tree.
   * @returns The nodeType and leafNodeType for the workflow
   */
  getNodeTypeAndNameFromWorkLoad(workloadType: Office365BackupType): { nodeType: string; leafNodeType: string} {
    let nodeType, leafNodeType;
    switch(workloadType) {
      case Office365BackupType.kMailbox:
      case Office365BackupType.kOneDrive:
        nodeType = Office365ContainerNodeType.kUsers;
        leafNodeType = Office365LeafNodeType.kUser;
        break;
      case Office365BackupType.kTeams:
        nodeType = Office365ContainerNodeType.kTeams;
        leafNodeType = Office365LeafNodeType.kTeam;
        break;
      case Office365BackupType.kGroups:
        nodeType = Office365ContainerNodeType.kGroups;
        leafNodeType = Office365LeafNodeType.kGroup;
        break;
      case Office365BackupType.kPublicFolders:
        nodeType = Office365ContainerNodeType.kPublicFolders;
        leafNodeType = Office365LeafNodeType.kPublicFolder;
        break;
      case Office365BackupType.kSharePoint:
        nodeType = Office365ContainerNodeType.kSites;
        leafNodeType = Office365LeafNodeType.kSite;
        break;
      default:
        nodeType = '';
        leafNodeType = '';
    }
    return { nodeType, leafNodeType };
  }

  /*
   * Extracts path inside rocksDB. For indexed browse, the path is already in
   * correct format. It trims prefix for non-indexed browse and recover as the
   * v2 API expects trimmed paths.
   * e.g. {/OneDrives/Onedrive-<driveId>/metadata/rocksdb/}.../<name>
   * The entire string inside {} is a prefix to be removed in case of O365
   * non-indexed browse and recover.
   *
   * @param path Specifies the path inside RocksDB.
   * @returns a string with path sanitized of prefixes
   */
  extractPathInsideRocksdb(path: string): string {
    const pathTokens = path?.split('/');
    if (pathTokens?.length > 4 && pathTokens[3] === 'metadata' &&
      pathTokens[4] === 'rocksdb') {
      path = '/' + pathTokens.slice(5).join('/');
    }
    return path;
  }

  /**
   * Process selected objects items so as to keep all the indexed granular
   * documents within the same root level entity.
   *
   * @param     objects   Array of selected restore objects.
   * @returns   Array of processed restore objects.
   */
  processSelectedObjects(objects: RestorePointSelection[]): RestorePointSelection[] {
    // Map from root level entity Id(Object Id) to the restore object.
    // This is to optimise lookup while processing objects for recovery.
    const recoveryObjectMap = new Map<number, RestorePointSelection>();

    // The below block removes redundancy and also reduces the recovery
    // payload size.
    objects.forEach(restoreObject => {
      // This will always be of size 1 unless we support 'job level restore'
      const objectId = restoreObject.objectIds[0];
      const restoreObjectInfo = restoreObject.objectInfo as Office365ObjectSearchResultGroup;
      if (recoveryObjectMap.has(objectId as number)) {
        const mapRestorePointObject: RestorePointSelection = recoveryObjectMap.get(objectId as number);
        const mapObjectInfo = mapRestorePointObject.objectInfo as Office365ObjectSearchResultGroup;

        // Add the current object's granular items array within the object in
        // the map.
        mapObjectInfo.office365ObjectSearchResults.push(...restoreObjectInfo.office365ObjectSearchResults);
        recoveryObjectMap.set(objectId as number, mapRestorePointObject);
      } else {
        // This block will get executed incase of object(root) level restores
        // or if the item selected doesn't belong to the list of known
        // objectIds within the 'recoveryObjectMap'.
        recoveryObjectMap.set(objectId as number, restoreObject);
      }
    });

    return [...recoveryObjectMap.values()];
  }

  /**
   * Generates the Array of folder items organised for the purpose of creating
   * a restore task for mailbox items. This is currently reused by both Mailbox
   * & MS Groups workload.
   *
   * @param recoveryObjectInfo Specifies the selected items for recovery
   * @returns Array of folder items.
   */
  generateMailboxFolderParameters(
    recoveryObjectInfo: Office365ObjectSearchResultGroup): FolderItem[] {
    const recoverFolderList: FolderItem[] = [];
    const folderItemMap = new Map<string, Office365ObjectSearchResult[]>();

    // Compute the Map of parent folder ID to the folder items for optimized
    // parameter generation.
    recoveryObjectInfo.office365ObjectSearchResults.forEach((o365Item: Office365ObjectSearchResult) => {
      const folderId = o365Item.parentFolderId;

      // Absence of folder id indicates that this item is itself a subfolder.
      if (o365Item.objectType === Office365MailboxItemType.kFolder) {
        folderItemMap.set(o365Item.indexedObjectId, []);
        return;
      }

      // Presence of folder id for the given item indicates that the item has a
      //  parent folder within which all its items to be recovered should be
      // grouped.
      if (folderItemMap.has(folderId)) {
        const currentFolderItems = folderItemMap.get(folderId);
        folderItemMap.set(folderId, [...currentFolderItems, o365Item]);
      } else {
        folderItemMap.set(folderId, [o365Item]);
      }
    });

    // Prepare the recover Array of Mailbox folder item.
    folderItemMap.forEach((o365ItemList, folderId) => {
      recoverFolderList.push({
        key: Number(folderId),
        recoverEntireFolder: !o365ItemList.length,
        itemIds: o365ItemList.map((o365Item: Office365ObjectSearchResult) => o365Item.indexedObjectId)
      });
    });

    return recoverFolderList;
  }

  /**
   * Transforms recovery form into DocLib Objects download parameters
   *
   * @param form Recovery form value.
   * @returns DocLib Download Params
   */
  transformFormToDocLibObjectsDownloadParams(form: CreateRecoveryForm<any>, regionId?: string) {
    const { objects, objectParams } = form;
    let filesAndFolders = objects.map(object => {
      const searchObject = (object?.objectInfo as any)?.office365ObjectSearchResults?.[0];
      let path = searchObject?.path;
      if (!path) {
        return null;
      }
      const pathParts = path?.split('//');

      // The current path does not have the drive name
      // Insert Drive Name in between '//'  to create the full path
      // TODO: Modify the api to provide the full path
      if (pathParts?.length > 1) {
        pathParts.splice(1, 0, searchObject?.driveName);
        path = pathParts.join('/');
      }

      return {
        absolutePath: path,
        isDirectory: DocumentLibraryItemType.Folder === searchObject?.objectType
      };
    });

    // filter out the null entries
    filesAndFolders = filesAndFolders.filter(obj => obj !== null);

    return {
      regionId: regionId || '',
      body: {
        name: objectParams?.taskName,
        object: {
          snapshotId: objects?.[0]?.restorePointId,
        },
        filesAndFolders: filesAndFolders,
      }
    } as any;
  }


  /**
   * Transforms recovery form into DocLib Objects download parameters List
   *
   * @param form Recovery form value.
   * @returns DocLib Download Params List.
   */
  transformFormToDocLibObjectsDownloadParamsList(form: CreateRecoveryForm<any>, regionId?: string):
    RecoveryServiceApi.InternalApiCreateDownloadFilesAndFoldersRecoveryParams[] {
    const { objects, objectParams } = form;
    const snapshotIdToObjectMap = {};
    let multipleSnapshots = false;
    objects.forEach((object, index) => {
      const snapshotId = object.restorePointId;
      if (index > 0 && !snapshotIdToObjectMap[snapshotId]) {
        multipleSnapshots = true;
      }
      snapshotIdToObjectMap[snapshotId] = snapshotIdToObjectMap[snapshotId] || [];
      snapshotIdToObjectMap[snapshotId].push(object);
    });

    const ret = [];
    let snapshotCount = 0;
    for(const key in snapshotIdToObjectMap) {
      if(Object.prototype.hasOwnProperty.call(snapshotIdToObjectMap, key)) {
        let newObjectParams = objectParams;
        if (multipleSnapshots) {
          snapshotCount += 1;
          newObjectParams = {
            ...objectParams,
            // Append count to the taskname as multiple tasks will be created
            taskName: objectParams.taskName ? objectParams.taskName + '_' + snapshotCount : snapshotCount
          };
        }
        ret.push(
          this.transformFormToDocLibObjectsDownloadParams({
            objectParams: newObjectParams,
            objects: snapshotIdToObjectMap[key],
          }, regionId)
        );
      }
    }
    return ret;
  }

  /**
   * Checks if all the selected objects are from the same snapshot
   *
   * @param formValue Recovery form value.
   * @returns True if all objects are from the same snapshot
   */
  checkObjectsFromSingleSnapshot(formValue: CreateRecoveryFormO365<any>): boolean {
    const objects = formValue?.objects || [];
    const recoveryType = formValue?.recoveryType;
    switch (recoveryType) {
      case Office365SearchType.kTeamContentSearch:
        return objects?.every(obj => obj.restorePointId === objects[0].restorePointId);
      default:
        return true;
    }
  }

  /**
   * Creates the consent URI for a given M365 tenant and a Client ID.
   *
   * @param domainName Specifies the domain name/tenantId for the M365 source
   * @param clientId Specifies the client ID for the App.
   * @returns Consent URI.
   */
  createConsentUriForClientId(domainName: string, clientId: string): string{
    return `https://login.microsoftonline.com/${domainName}/adminconsent?client_id=${clientId}`;
  }

  /*
   * Generates common target object acceptable for restore to alternate
   * mailbox or onedrive for CSM based recoveries.
   * NOTE: Sites do not support restore to alternate site.
   *
   * @param object Specifies the object selected for restore.
   * @returns Returns alternate target object for mbx/od.
   */
  generateCommonTargetObjectParamForCSMRecovery(
    object: RestorePointSelection): (TargetMailboxParam | TargetOneDriveParam) {
    if (!object) {
      return null;
    }

    return {
      id: Number(object?.objectInfo?.id),
      parentSourceId: object?.objectInfo?.sourceId,
      name: object?.objectInfo?.name,
      targetFolderPath: this.translateService.instant(
        'office365Restore.recover.csmNewFolderName')
    };
  }

  /**
   * Determines whether the Backup controller registration is successful.
   *
   * @param backupControllerResponse Specifies the Backup controller response.
   * @returns True, if controller is successfully registered. False, otherwise.
   */
  isBackupControllerRegistrationSuccessful(
    backupControllerResponse: GetM365BackupControllerResponseParams): boolean {
    if (backupControllerResponse.controllers?.length) {
      return true;
    }
    return false;
  }

  /**
   * Determines whether the Backup controller activation is successful
   *
   * @param backupControllerResponse
   * @returns True, if controller is successfully activated. False, otherwise.
   */
  isBackupControllerActivationSuccessful(
    backupControllerResponse: GetM365BackupControllerResponseParams): boolean {
    if (backupControllerResponse.controllers?.length &&
        backupControllerResponse.controllers?.[0]?.status.toLowerCase() === 'active') {
      return true;
    }
    return false;
  }

  /**
   * Determines whether the Backup controller activation is pending active.
   *
   * @param backupControllerResponse
   * @returns True, if controller is in pending active state. False, otherwise.
   */
  isBackupControllerActivationPending(
    backupControllerResponse: GetM365BackupControllerResponseParams): boolean {
    if (backupControllerResponse.controllers?.length &&
        backupControllerResponse.controllers?.[0]?.status.toLowerCase() === 'pendingactive') {
      return true;
    }
    return false;
  }

  /**
   * Determines whether the Backup controller activation is successful
   *
   * @param backupControllerResponse
   * @returns True, if controller is successfully activated. False, otherwise.
   */
  isBackupControllerDeactivationSuccessful(
    backupControllerResponse: GetM365BackupControllerResponseParams): boolean {
    if (backupControllerResponse.controllers?.length &&
      backupControllerResponse.controllers?.[0]?.status.toLowerCase() === 'inactive') {
      return true;
    }
    return false;
  }

  /**
   * Flattens the indexed search result object in case of Groups granlular search
   *
   * @param indexedObject The object returned from search
   * @param office365SearchType The search type
   * @returns Flattened indexed item.
   */
  flattenGropusSearchObject(indexedObject: Office365IndexedItem, office365SearchType: string): Office365IndexedItem {
    const nestedObject = indexedObject?.[Office365GroupsSearchResultItemMap[office365SearchType]] || {};
    return { ...indexedObject, ...nestedObject };
  }

  /**
   * Generates the text for recovery type during any M365 restore to display on the
   * recovery page.
   *
   * @param recoveryType Specifies the M365 recovery type. It is one of the values from the
   *                     enum Office365SearchType.
   *
   * @returns Translated string for the M365 recovery type corresponding to the given
   *          Office365SearchType
   */
  getM365SearchTypeNameString(recoveryType: Office365SearchType): string {
    if (recoveryType === Office365SearchType.kEmailSearch &&
      this.isMailboxNonEmailItemsRecoveryEnabled()) {
      return this.translateService.instant('office365Restore.searchMailboxItems');
    }

    return this.translateService.instant('office365Restore.recoveryType.' + recoveryType);
  }

  /**
   * Specifies whether glr recovery is enabled or not.
   */
  get groupGlrEnabled() {
    return (isDmsScope(this.irisCtx.irisContext) ?
      flagEnabled(this.irisCtx.irisContext, 'dmsOffice365GroupsGranularRecoveryEnabled') :
      (flagEnabled(this.irisCtx.irisContext, 'office365GroupGranularRecoveryEnabledv2') ||
      flagEnabled(this.irisCtx.irisContext, 'office365GroupGranularRecoveryEnabled')));
  }

  /**
   * Trims prefix for non-indexed browse and recover as the v2 API expects
   * trimmed paths and returns drive name and the actual path as a list.
   * In case of Sharepoint Site non-indexed browse, example paths are:
   * A. {/OneDrives}/<doclib-name>{/metadata/rocksdb}/file
   * B. {/OneDrives}/<doclib-name> in case of entire doclib recovery
   * The entire string inside {} is a prefix to be removed in case of O365
   * non-indexed browse and recover.
   *
   * @returns item's doclibe name and tokenized
   * path inside rocksdb
   */
  extractDriveNameAndPath(path: string) {
    const pathTokens = path?.split('/');

    // This works when it is indexed browse. Ignore the first part since the
    // path starts with /
    let [driveName, ...rest] = pathTokens.slice(1);
    if (pathTokens?.length === 3 && pathTokens[1] === 'OneDrives') {
      // Case B
      driveName = pathTokens[2];
      rest = [''];
    } else if (pathTokens?.length > 4 && pathTokens[3] === 'metadata' &&
        pathTokens[4] === 'rocksdb') {
      // Case A
      driveName = pathTokens[2];
      rest = pathTokens.slice(5);
    }
    return {
      driveName: driveName,
      tokenizedPath: rest,
    };
  }

  /**
   * Returns the common parent folder and files
   * excludes the folder/file paths if the parent path is present
   * eg. if 'a/b',  'a/b/c.jpg' & 'a/b/d.pdf'
   * are present only 'a/b' will be returned
   *
   * @returns folder and file paths
   */
  getMinimumPaths (paths: Office365ObjectSearchResult[]) {
    const sortedObjects = paths.sort((a, b) => b.path > a.path ? -1 : 1);

    const retPath = [];

    let lastIndex = 0;
    let currentIndex = 1;

    while (lastIndex < sortedObjects.length) {
      while (currentIndex < sortedObjects.length &&
        this.checkSubPath(sortedObjects[lastIndex].path, sortedObjects[currentIndex].path)) {
          currentIndex += 1;
      }
      retPath.push(sortedObjects[lastIndex]);
      lastIndex = currentIndex;
      currentIndex = lastIndex + 1;
    }

    return retPath;

  }

  /**
   * Check if the mPath is a prefix of
   * nPath
   */
  private checkSubPath(mPath , nPath) {
    // if the paths are same return true
    if (mPath === nPath) {
      return true;
    }

    // append / to the end of the path
    // if already not present
    if (mPath.substr(-1) !== '/') {
      mPath = mPath + '/';
    }

    if (nPath.indexOf(mPath) === 0) {
      return true;
    }

    return false;
  }
}
