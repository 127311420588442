import { assign } from 'lodash-es';
// Component: This component checks connection to external network
// to determine if it is a dark/connected site

;(function(angular) {
  'use strict';

  angular
    .module('C.clusterClaim')
    .config(configFn)
    .component('checkConnectionRedirectHandler', {
      controller: 'checkConnectionRedirectHandlerCtrl',
    })
    .controller('checkConnectionRedirectHandlerCtrl',
      checkConnectionRedirectHandlerCtrl);

  /**
   * Config Fn for this module.
   */
  function configFn($stateProvider) {
    $stateProvider.state('check-connection-redirect-handler', {
      title: 'Check Connection Redirection',
      url: '^/check-connection-redirect-handler',
      component: 'checkConnectionRedirectHandler',
      canAccess: 'true',
    });
  }

  function checkConnectionRedirectHandlerCtrl(_, $state, LicenseServerService) {
    var $ctrl = this;

    assign($ctrl, {
      //methods
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      LicenseServerService.clusterClaimInstantSyncup();
      $state.go('check-connection', { redirectedFromHelios: true });
    }
  }
})(angular);
