import { clone } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Nas Volume

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.NasVolume', ['C.sourceMeta'])
    .component('cNasVolume', {
      bindings: {
        /**
         * Selected search Object
         *
         * @param  selectedObject    selected object from search results
         * @type   {object}
         */
        selectedObject: '=',

        /**
         * boolean to hide/show change action
         *
         * @param  isShowEditBtn     show/hide change button
         * @type   [boolean]
         */
        isShowEditBtn: '<?',

        /**
         * get the data protocol text key
         *
         * @method   getModeTextKey
         * @type     {function}
         * @return   {string}
         */
        getModeTextKey: '<',

        /**
         *  onClickChange callback function
         *
         * @method   onClickChange
         * @type     {function}
         */
        onClickChange: '<?',
      },
      templateUrl: 'app/protection/recovery/storage-volume/inc/nas.volume.html',
      controller:  cNasVolumeCtrlFn,
    });

  /**
   * @ngdoc component
   * @name C.NasVolume:cNasVolume
   * @function
   *
   * @description
   * Reusable component for nas volume display.
   *
   * @example
     <c-nas-volume
      is-show-edit-btn="false"
      on-click-change="$ctrl.startFlowover"
      get-mode-text-key="$ctrl.getModeTextKey"></c-nas-volume>
   */
  function cNasVolumeCtrlFn(_, ENV_GROUPS) {
    var $ctrl = this;
    $ctrl.$onInit = function $onInit() {
      assign($ctrl, {
        nasEnvGroups: clone(ENV_GROUPS.nas),
        isShowEditBtn: angular.isUndefined($ctrl.isShowEditBtn) ||
          $ctrl.isShowEditBtn,
      });
    };
  }
})(angular);