import { once } from 'lodash-es';
// SERVICE: Poll Task Service

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('PollTaskStatus', pollTaskStatusController);

  function pollTaskStatusController(_, $http, $q, $timeout, $log, $transitions,
    API, $document, NgPassthroughOptionsService) {

    return {
      createPoller: createPoller,
      getProgress: getProgress,
      getTask: getTask,
      getTaskNoPoll: getTaskNoPoll,
    };

    /**
     * Generic poller with provisions for external controllers to determine when
     * polling has finished and self-cleanup.
     *
     * @method     createPoller
     * @param      {object}   opts    Config object: See defaultOptions below.
     * @return     {object}   Promise with results of the iteratorFn across
     *                        polling intervals.
     */
    function createPoller(opts) {
      var deferred = $q.defer();
      var defaultOptions = {

        // @type {object}  - Optional $scope (for self-termination on
        // $destroy).
        scope: undefined,

        // @type {number}  - Frequency (in seconds) between poll
        // intervals (not exact, varies by API response times).
        interval: 30,

        // @type {function}  - Required. Function to determine if the
        // poll is done (terminates on truthy @return).
        isDoneFn: undefined,

        // @type {Object}  - Required. The promise which when resolved will end
        // the poll. (any one of isDoneFn or isDonePromise would be Required,
        // if specified both then both will be used to determine the poll end)
        isDonePromise: undefined,

        // @type {function}  - Required. Function to execute on each
        // poll iteration (expects a promise return).
        iteratorFn: undefined,

        // @type {integer}  - Number of errors to retry on before
        // stopping.
        maxRetries: 3,

        // @type {Boolean}  - Optional. If false, then the poller function is
        // still called  when tab is not in focus.
        pauseOnTabChange: true,
      };

      // Tally of failed attempts.
      var attempts = 0;

      // This will hold the poller $timeout
      var poller;

      // This will hold the current request promise
      var currentReqPromise;

      // Current tab state.
      var isTabActive = true;

      // Handles the case for attaching the listener only once.
      var callHandleTabChange = once(_handleTabChange);

      // Combine passed options with defaults.
      opts = angular.extend({}, defaultOptions, opts);

      // If Required params are missing, we can't proceed. Exit early.
      if (!opts.iteratorFn || !(opts.isDoneFn || opts.isDonePromise)) {
        deferred.reject();
        return;
      }

      if (opts.isDonePromise) {
        opts.isDonePromise.then(terminatePoller);

        if (!opts.isDoneFn) {
          opts.isDoneFn = function defaultIsDoneFn() {
            return false;
          };
        }
      }

      // If the opts.scope was passed, setup the self-cleanup to prevent
      // additional pollers after leaving.
      if (opts.scope && opts.scope.hasOwnProperty('$$watchers')) {
        opts.scope.$on('$destroy', function onDe$troy() {
          if (poller) {
            $log.info('Poller stopped on $scope.$destroy', poller);
          }
          terminatePoller();
        });
      }

      // Add the event listener only when the option is provided.
      if (opts.pauseOnTabChange) {
        callHandleTabChange();
      }

      // Start it off
      runPoller();

      // Return the deferred promise. This allows the calling controller
      // of this method to respond to standard promise responses.
      return deferred.promise;

      /**
       * Function to handle tab focus changes, to pause the poller fn. Uses
       * browser's Page Visibility API.
       *
       * Reference -
       * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
       *
       * @method   _handleTabChange
       */
      function _handleTabChange() {
        var hidden;
        var visibilityChange;

        // Get the Document object from $document API.
        var _document = $document[0];

        // Handle cases for different browsers.
        if (typeof _document.hidden !== 'undefined') {
          hidden = 'hidden';
          visibilityChange = 'visibilitychange';
        } else if (typeof _document.msHidden !== 'undefined') {
          hidden = 'msHidden';
          visibilityChange = 'msvisibilitychange';
        } else if (typeof _document.webkitHidden !== 'undefined') {
          hidden = 'webkitHidden';
          visibilityChange = 'webkitvisibilitychange';
        }

        // Set the tab state when tab visibility/focus changes.
        function handleVisibilityChange() {
          isTabActive = !_document[hidden];
        }

        // Attach the event listener function to the DOM.
        _document.addEventListener(visibilityChange, handleVisibilityChange,
          false);
      }

      /**
       * Runs the poller and handles deferred promise routing.
       *
       * @method    runPoller
       */
      function runPoller() {

        if (poller) {
          return;
        }

        // If tab is inactive, call the successHandler fn which sets the
        // $timeout which in turn calls the runPoller fn after the delay
        // without calling the iteratorFn recursively.
        currentReqPromise = !isTabActive ? successHandler() :
          opts.iteratorFn().then(successHandler, errorHandler, deferred.notify);
      }

      /**
       * Shortcut to cancel the poller.
       *
       * @method    terminatePoller
       */
      function terminatePoller() {
        if (poller) {
          $log.info('Terminating poller', poller);
        }
        $timeout.cancel(poller);

        // abort the current request if we can.
        if (currentReqPromise && currentReqPromise.abort) {
          currentReqPromise.abort();
        }
      }

      /**
       * Handles success response from iteratorFn. Resolves deferred
       * promise if the isDoneFn is truthy, otherwise notifies and kicks
       * off another iteration timer.
       *
       * @method    successHandler
       * @param     {*}        resp   The response from iteratorFn.
       * @returns   {object}   Results of the deferred notify or resolve
       *                       methods.
       */
      function successHandler(resp) {
        if (opts.isDoneFn()) {
          return deferred.resolve(resp);
        }
        poller = $timeout(
          function runAgain() {
            poller = undefined;
            runPoller();
          },
          opts.interval * 1000
        );
        return deferred.notify(resp);
      }

      /**
       * Handles error response from iteratorFn. Manages maxRetry attempts
       * tracking and, if exceeding that, rejects the deferred promise.
       *
       * @method    errorHandler
       * @param     {*}        resp   The response from iteratorFn.
       * @returns   {object|undefined}   Results of the deferred notify or
       *                                 resolve methods. Or undefined if
       *                                 retries still available.
       */
      function errorHandler(resp) {
        if (attempts >= opts.maxRetries) {

          // We've exceeded maxRetries. Reject deferred promise.
          terminatePoller();
          return deferred.reject(resp);
        } else {
          poller = $timeout(
            function runAgain() {
              poller = undefined;
              runPoller();
            },
            opts.interval * 1000
          );

          // Update attempts
          attempts++;
        }
      }
    }

    /**
     * AJAX Call to get Task Data.
     *
     * @method     getTask
     * @param      {String}     endpoint         API endpoint
     * @param      {Int}        [interval]       Interval of milliseconds
     *                                           between API calls, default is
     *                                           30000, minimum is 1000
     * @param      {Int}        [maxRetries]     How many times to retry after
     *                                           fail, defaults to 10
     * @param      {Function}   [canTerminate]   Logic to terminate the poll.
     *                                           Should return a boolean
     * @param      {Object}     [isDonePromise]  The promise which when
     *                                           resolved will end the poll.
     * @param      {Function}   [transformFn]    Custom Logic to transform the
     *                                           result of the poll.
     * @return     {object}     Promise to resolve with the response
     */
    function getTask(
      endpoint, interval, maxRetries, canTerminate, isDonePromise,
      transformFn) {
      var url;
      var deferred;
      var failCount;
      var data;
      var poll;
      var pollTimeoutPromise;

      interval = interval || 30000;
      maxRetries = maxRetries || 10;
      interval = (interval < 1000) ? 1000 : interval;
      url = API.private(endpoint);
      deferred = $q.defer();
      failCount = 0;
      data = {};

      // Endpoint is required
      // Return an empty object if endpoint is not specified
      if (!endpoint) {
        return deferred.resolve(data);
      }

      if (isDonePromise) {
        isDonePromise.then(stopPolling);
      }

      /**
       * Stops a polling.
       *
       * @method   stopPolling
       * @param   {Boolean}   reject   If true, polling will be stopped
       *                               and promised will be rejected.
       */
      function stopPolling(reject) {
        data._isDecoratedResponse = true;
        reject ? deferred.reject(data) : deferred.resolve(data);
        $timeout.cancel(pollTimeoutPromise);
        poll = undefined;
      }

      /**
     * Transform ProgressMonitorAPI result such that the listeners can update
     * the data.
     *
     * @method  defaultTransformPollFn()
     * @param   {Object}     r          Response got from progressMonitor call.
     * @param   {Promise}    deferred   The promise for listeners.
     */
    function defaultTransformPollFn(r, deferred){
      if (r.data &&
        r.data.resultGroupVec &&
        r.data.resultGroupVec.length &&
        r.data.resultGroupVec[0].taskVec &&
        r.data.resultGroupVec[0].taskVec.length) {
        data = r.data.resultGroupVec[r.data.resultGroupVec.length - 1].taskVec[0];

        if (data.progress.status.type === 0) {
          // If the task is still running
          data._pollingStatus = 'active';
          deferred.notify(r.data.resultGroupVec);
        }

        if (data.progress.status.type !== 0) {
          // If the task is not running
          if (data.progress.percentComplete === 100 || data.progress.percentFinished === 100) {
            // If the task is 100% complete
            data.progress._pollingStatus = 'completed';
          } else {
            // If the task is not 100% complete
            // but it's still not running, we will assume an error occurred
            data.progress._pollingStatus = 'error';
          }

          // Send a notification to listeners.
          deferred.notify(r.data.resultGroupVec);

          // Let's resolve the promise
          deferred.resolve(r.data.resultGroupVec);
        }
      }
    }

      // It should be taken care that the variable holding a function should
      // not have the same name as the function as this can lead to unexpected
      // and unintended scenarios.
      poll = function pollFn() {
        $http.get(url, null)
          .then(function successPoll(r) {
            // Reset failCount
            failCount = 0;
            // If a transform function is passed as an argument, then invoke
            // it. Otherwise the default transform function is invoked.
            if (angular.isFunction(transformFn)) {
              transformFn(r, deferred);
            } else {
              defaultTransformPollFn(r, deferred);
            }
          }, function errorPoll(r) {
            failCount++;

            if (failCount >= maxRetries) {
              data = {
                _pollingStatus: 'error'
              };
              stopPolling(true);
            }
          })
          .finally(function afterPoll() {
            // If terminate function is defined and it returns true
            // Let's call it quits for this one
            if (angular.isFunction(canTerminate) && canTerminate()) {
              stopPolling();
              return;
            }

            // if our poll is not yet complete,
            // let's kick off another timeout
            if (angular.isDefined(poll)) {
              pollTimeoutPromise = $timeout(poll, interval);
            }
          });
      };

      // instantiate our poll immediately
      poll();


      $transitions.onStart({}, function onChange(trans) {
        $timeout.cancel(pollTimeoutPromise);
      }, { invokeLimit: 1 });

      return deferred.promise;
    }

    /**
     * Generic getProgress against progressMonitor endpoint.
     *
     * @method   getProgress
     * @param    {object}   [params]   Optional parameters
     * @return   {object}   Promise to resolve with the requested progress
     *                      object.
     */
    function getProgress(params) {
      // Ref: /docs/restApiDocs/bootprintinternal/#operation--progressMonitors--path--get
      var opts = {
        method: 'get',
        url: API.private('progressMonitors'),
        params: params,
        headers: NgPassthroughOptionsService.requestHeaders,
      };

      return $http(opts).then(function responseHandler(resp) {
        return resp.data;
      });
    }

    /**
     * AJAX Call to get Task Data. Use this to query for the task status data
     * only once (non-polling).
     *
     * TODO: Get rid of this garbage. Maybe can reuse getProgress() above.
     *
     * @param      {String}   endpoint   API endpoint
     * @return     {object}   Promsie to resolve with the response
     */
    function getTaskNoPoll(url) {
      var request = $http({
        method: 'get',
        url: API.private(url),
        headers: NgPassthroughOptionsService.requestHeaders,
      });
      return request;
    }

  }
})(angular);
