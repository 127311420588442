import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const softwareUpgradeAccess = (ctx: StateAccessContext) =>
ctx.FEATURE_FLAGS.ngHeliosClusterUpgrade
  && ctx.isHelios
  && !ctx.isHeliosTenantUser
  && ctx.CLUSTER_VIEW;

const importExportAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.importExportEnabled
  && ctx.isHelios
  && !ctx.isHeliosTenantUser
  && ctx.CLUSTER_VIEW;

const clusterClaimTokensAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_VIEW || ctx.MCM_VIEW_SUPER_ADMIN;

/**
 * Summary dashboard is single cluster state.
 * This tab should only be visible in all cluster state.
 */
const singleClusterState = 'dashboards';

export const SoftwareUpgradesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'software-upgrade.**',
      url: '/software-upgrade',
      loadChildren: () => import('./software-upgrades.module').then(m => m.SoftwareUpgradesModule),
    },
    {
      name: 'cluster-detail.**',
      url: '/cluster-detail',
      loadChildren: () => import('./software-upgrades.module').then(m => m.SoftwareUpgradesModule),
    },
  ],
  allClusterMap: {
    'software-upgrade.cluster-list': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.cluster-list',
      singleClusterState,
      globalContext: true,
    },
    'software-upgrade.tokens': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.tokens',
      singleClusterState,
      globalContext: true,
    },
    'software-upgrade.cluster-activity': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.cluster-activity',
      singleClusterState,
      globalContext: true,
    },
    'software-upgrade.cluster-backup': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.cluster-backup',
      singleClusterState,
      globalContext: true,
    },
    'software-upgrade.release-list': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.release-list',
      singleClusterState,
    },
    'cluster-detail': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.cluster-detail',
      singleClusterState,
      globalContext: true,
    },
    'cluster-detail.home': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.cluster-detail',
      singleClusterState,
      globalContext: true,
    },
    'cluster-detail.history': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.cluster-detail',
      singleClusterState,
      globalContext: true,
    },
    'cluster-detail.activity': {
      heliosOnly: true,
      allClustersState: 'software-upgrade.cluster-detail',
      singleClusterState,
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'software-upgrade.cluster-list': softwareUpgradeAccess,
      'software-upgrade.tokens': clusterClaimTokensAccess,
      'software-upgrade.cluster-activity': importExportAccess,
      'software-upgrade.cluster-backup': importExportAccess,
      'software-upgrade.release-list': softwareUpgradeAccess,
      'cluster-detail': importExportAccess,
      'cluster-detail.home': importExportAccess,
      'cluster-detail.history': importExportAccess,
      'cluster-detail.activity': importExportAccess,
    };
  },
};
