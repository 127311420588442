import { Injectable } from '@angular/core';
import { SearchServiceApi } from '@cohesity/api/v2';
import { DcAdapterAccessService } from '@cohesity/data-govern/data-classification';
import { ThAdapterAccessService } from '@cohesity/data-govern/threat-detection';
import { Environment, GroupEnum, envGroups } from '@cohesity/iris-shared-constants';
import { uniq } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { FilterFieldOption, IFieldFilterService } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ObjectTypeFilterService implements IFieldFilterService {
  /**
   * cached options so that we don't need to generate those every time
   */
  private cachedOptions: FilterFieldOption[];

  /**
   * group of the supported environments
   */
  private supportedGroups: GroupEnum = this.getSupportedEnvironmentGroups();

  constructor(
    private thAdapterAccessService: ThAdapterAccessService,
    private dcAdapterAccessService: DcAdapterAccessService,
  ) { }

  /**
   * Object type option generator
   *
   * @returns All static options for the possible object types
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    if (this.cachedOptions) {
      return of(this.cachedOptions);
    }

    this.cachedOptions = Object.keys(this.supportedGroups).map((group) => ({
      key: group,
      labelKey: `enum.envGroup.longName.${group}`
    }));

    this.cachedOptions.sort((a, b) => a.labelKey.localeCompare(b.labelKey));

    return of(this.cachedOptions);
  }

  /**
   * consolidated object type filters into a format that API understands
   *
   * @param selectedValues currently applied object type filters
   * @returns object type filter that API understands
   */
  transformFilterValues(selectedValues: string[]): SearchServiceApi.SearchObjectsParams {
    const environments = (!selectedValues.length
      ? this.getSupportedEnvironments()
      : [].concat(...selectedValues.map(value => this.supportedGroups[value]))).join();

    const apiFilters = {
      environments: [environments],
    } as SearchServiceApi.SearchObjectsParams;

    return apiFilters;
  }

  /**
   * Generates a list of supported environments based on the environment supported by the threat scanning and
   * the data classification
   *
   * @returns list of the supported environements
   */
  private getSupportedEnvironments(): Environment[] {
    return uniq([
      ...this.thAdapterAccessService.supportedAdaptors,
      ...this.dcAdapterAccessService.supportedAdaptors,
    ]);
  }

  /**
   * Generates a group enum for the supported environments
   *
   * @returns group enum of the supported environements
   */
  private getSupportedEnvironmentGroups(): GroupEnum {
    const supportedEnvironments = this.getSupportedEnvironments();
    const supportedGroups = Object.keys(envGroups).filter(
      (group) => envGroups[group].some(env => supportedEnvironments.includes(env)));

    return supportedGroups.reduce((acc, group) => {
      acc[group] = envGroups[group].filter(env => supportedEnvironments.includes(env));
      return acc;
    }, {});
  }
}
