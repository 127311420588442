import { some } from 'lodash-es';
import { merge } from 'lodash-es';
import { get } from 'lodash-es';
(function _iife(angular, undefined) {
  angular.module('C.fileStubbingSettings', [])
    .controller('FileStubbingCtrl', FileStubbingControllerFn)
    .component('fileStubbingSettings',{
      templateUrl: 'app/protection/jobs/modify2/file-stubbing-settings/file-stubbing-settings.html',
      bindings: {
        /**
         * Job detail.
         *
         * @type   {Object}
         */
        job: '=',
        /**
         * Edit mode or new mode.
         *
         * @type   {boolean}
         */
        isEditMode: '<',
        /**
         * Indicate whether component is being used for data-migration up-tiering.
         *
         * @type   {boolean}
         */
        isUpTiering: '@',
      },
      controller: 'FileStubbingCtrl',
    });

  /**
   * File Stubbing section Controller.
   *
   * @method    FileStubbingControllerFn
   * @params    {object}  _         _.
   * @params    {object}  DAY_KVAL  DAY_KVAL.
   * @params    {object}  FORMATS   FORMATS.
  */
  function FileStubbingControllerFn(_, DAY_KVAL, FORMATS, FEATURE_FLAGS) {
    var $ctrl = this;
    var now = new Date();

    angular.extend($ctrl, {
      $onInit: $onInit,

      FORMATS: FORMATS,

      dataMigrateDayCountsInMonth: [
        { kValue: 'kFirst', nameValue: 'enum.dayCountInMonth.first' },
        { kValue: 'kSecond', nameValue: 'enum.dayCountInMonth.second'},
        { kValue: 'kThird', nameValue: 'enum.dayCountInMonth.third' },
        { kValue: 'kLast', nameValue: 'enum.dayCountInMonth.last' },
      ],

      dataMigrateFileSizePolicy: [
        { kValue: 'kGreaterThan', nameValue: 'greaterThan' },
        { kValue: 'kSmallerThan', nameValue: 'smallerThan' },
      ],

      dataMigrationSchedulePeriodicity: [
        { kValue: 'daily', nameKey: 'daily' },
        { kValue: 'weekly', nameKey: 'weekly' },
        { kValue: 'monthly', nameKey: 'monthly' },
      ],

      scheduleOptionsHash: {
        daily: {
          periodicity: 'kDaily',
          dailySchedule: {},
        },
        weekly: {
          periodicity: 'kDaily',
          dailySchedule: {
            days: [DAY_KVAL[now.getDay()]],
          },
        },
        monthly: {
          periodicity: 'kMonthly',
          monthlySchedule: {
            dayCount: 'kFirst',
            day: 'kSunday',
          },
        },
      },

      defaultDataMigrationPolicy: {
        dataMigrationPolicy: {
          schedulingPolicy: {
            periodicity: 'kDaily',
            dailySchedule: {},
          },
        },
      },

      defaultDataMigrationParams: {
        dataMigrationJobParameters: {
          fileSelectionPolicy: 'kLastAccessed',
          fileSizePolicy: 'kGreaterThan',
        }
      },

      // methods
      getSetSchedule: getSetSchedule,
      shouldShowMountPathInput: shouldShowMountPathInput,
      isMigrationWithoutStubToggleEnabled: isMigrationWithoutStubToggleEnabled,
      isDeleteOrphanDataToggleEnabled: isDeleteOrphanDataToggleEnabled
    });

    /**
     * Init function.
     *
     * @method    $onInit
    */
    function $onInit() {
      $ctrl.dataMigrateFileSelectionPolicy = [
        { kValue: 'kLastAccessed', nameValue: 'lastAccess' },
        { kValue: 'kLastModified', nameValue: $ctrl.isUpTiering ? 'modified' : 'lastModified' },
      ];

      if ($ctrl.isEditMode) {
        _translateSchedulePeriodicity(
          $ctrl.job.dataMigrationPolicy.schedulingPolicy);
      } else {
        // set default periodicity value.
        $ctrl.fileStubbingPeriodicity = 'daily';

        // set default data migration policy value.
        merge($ctrl.job, $ctrl.defaultDataMigrationPolicy);

        // Set default data migration params value.
        merge($ctrl.job._envParams, $ctrl.defaultDataMigrationParams);
      }
    }

    /**
     * When edit data migration job mode, translate inline schedule.
     *
     * @method   _translateSchedulePeriodicity
     * @param    {Object}   schedule   File Stubbing schedule object.
     */
    function _translateSchedulePeriodicity(schedule) {
      if (schedule.periodicity === 'kDaily') {
        $ctrl.fileStubbingPeriodicity = get(schedule.dailySchedule, 'days') ?
          'weekly' : 'daily';
      } else if (schedule.periodicity === 'kMonthly') {
        $ctrl.fileStubbingPeriodicity = 'monthly';
      }
    }

    /**
     * Gets and Sets the schedule periodicity.
     *
     * @method    getSetSchedule
     * @param     {String}    newVal    The newly selected periodicity.
     * @returns   {String}              The selected periodicity.
     */
    function getSetSchedule(newVal) {
      var schedule = $ctrl.job.dataMigrationPolicy.schedulingPolicy;

      // set new periodicity selection value.
      switch (newVal) {
        case 'daily':
          schedule = merge(schedule, $ctrl.scheduleOptionsHash['daily']);
          schedule.monthlySchedule = undefined;
          schedule.periodicity = 'kDaily';
          $ctrl.fileStubbingPeriodicity = 'daily';
          break;

        case 'weekly':
          schedule = merge(schedule, $ctrl.scheduleOptionsHash['weekly']);
          schedule.monthlySchedule = undefined;
          schedule.periodicity = 'kDaily';
          $ctrl.fileStubbingPeriodicity = 'weekly';
          break;

        case 'monthly':
          schedule = merge(schedule, $ctrl.scheduleOptionsHash['monthly']);
          schedule.dailySchedule = undefined;
          schedule.periodicity = 'kMonthly';
          $ctrl.fileStubbingPeriodicity = 'monthly';
          break;
      }

      // return derived periodicity selection value.
      return $ctrl.fileStubbingPeriodicity;
    }

    /**
     * Determine to show the mount point input or not.
     * We only show mount point input when user select nfs source.
     *
     * @method    shouldShowMountPathInput
     * @returns   {boolean}    True only if user select at least one nfs source.
     */
    function shouldShowMountPathInput() {
      return some($ctrl.job._selectedSources,
        ['protectionSource.nasProtectionSource.protocol', 'kNfs3']);
    }

    /**
     * Enables/disable the toogle button for migration without stubs after data transfer.
     *
     * @method  isMigrationWithoutStubToggleEnabled
     */
    function isMigrationWithoutStubToggleEnabled() {
      if ($ctrl.isEditMode || $ctrl.job._envParams.dataMigrationJobParameters.deleteOrphanData) {
        return true;
      }

      return false;
    }

    /**
     * Enables/disable the toogle button for deleteing orphan symbolic links.
     *
     * @method  isDeleteOrphanDataToggleEnabled
     */
    function isDeleteOrphanDataToggleEnabled() {
      if ($ctrl.job._envParams.dataMigrationJobParameters.migrateWithoutStub) {
        return true;
      }

      return false;
    }
  }
})(angular);
