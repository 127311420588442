import { forOwn } from 'lodash-es';
import { isNumber } from 'lodash-es';
import { map } from 'lodash-es';
// Utility functions to be used throughout the application

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.utils', ['C.NgService'])
    .factory('cUtils', cUtilsFn);

  function cUtilsFn(_,
    $log, $filter, $translate, $parse, Highcharts, ENUM_UI_RESTORE_TASK_STATUS,
    FORMATS, ENV_TYPE_CONVERSION, ENUM_HOST_TYPE_CONVERSION,
    NAS_KVALUE_DATA_PROTOCOL_MAP, NgByteSizeService, NgMathService) {

    var self = {
      bytesToSize: NgByteSizeService.bytesToSize.bind(NgByteSizeService),
      sizeToBytes: NgByteSizeService.sizeToBytes.bind(NgByteSizeService),
      dateDiff: dateDiff,
      fileReader: new FileReader(),
      getDataProtocolFromKValue: getDataProtocolFromKValue,
      getDefaultRootPath: getDefaultRootPath,
      getMonochromeColors: getMonochromeColors,
      isBoolean: isBoolean,
      returnTrueAlways: returnTrueAlways,
      round: NgMathService.toFixed.bind(NgMathService),
      searchTree: searchTree,
      selfOrDefault: selfOrDefault,
      transformToOsSpecificPath: transformToOsSpecificPath,
      transformToWindowsPath: transformToWindowsPath,
      uiSelectEmailValidator: uiSelectEmailValidator,
      uiSelectIPValidator: uiSelectIPValidator,
    };

    /**
     * Set provided value if provided property is not defined inside input
     * object.
     *
     * @method   selfOrDefault
     * @param    {Object}   input           The input object to test.
     * @param    {Object}   propertyName    The property to test.
     * @param    {Object}   defaultValue    The default value if property not
     *                                      found.
     */
    function selfOrDefault(input, propertyName, defaultValue) {
      if (!input.hasOwnProperty(propertyName)) {
        input[propertyName] = defaultValue;
      }
    }

    /**
     * Returns the difference b/w test and reference data(default current date).
     *
     * @examples
     *   dateDiff(new Date(0), 'years'); // 48 Years
     *   dateDiff(new Date(0), 'years', (new Date(0)).setYear(1)); // 1 Years
     *   dateDiff(new Date(0), 'Days', (new Date(0)).setYear(1)); // 365 Days
     *
     * @method   dateDiff
     * @param    {number}   testTimeMsecs         The test time
     * @param    {String}   [resultUnit]          The unit in which date
                                                  difference result would be
                                                  returned like days, years etc
     * @param    {number}   [referenceTimeMsecs]  The reference time if not
                                                  given will use current time
     * @return   {String}   The data difference b/w test and reference time.
     */
    function dateDiff(testTimeMsecs, resultUnit, referenceTimeMsecs) {
      var difference;
      var keysMap = {
        years: 'y',
        quarters: 'Q',
        months: 'M',
        weeks: 'w',
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
        milliseconds: 'ms',
      };

      if (resultUnit && !keysMap[resultUnit]) {
        $log.error(
          'C.utils.cUtils.dateDiff:',
          'invalid result unit (',
          resultUnit,
          ') choose one from:',
          Object.keys(keysMap)
        );

        return $translate.instant('naNotAvailable');
      }

      if (!resultUnit) {
        resultUnit = 'days';
      }

      referenceTimeMsecs = referenceTimeMsecs || Date.clusterNow();

      if (isNaN(testTimeMsecs) || isNaN(referenceTimeMsecs)) {
        $log.error(
          'C.utils.cUtils.dateDiff:',
          'either test or reference time is not a number.'
        );
        return $translate.instant('naNotAvailable');
      }

      difference = Math.floor(moment.duration(
        Math.abs(referenceTimeMsecs - testTimeMsecs)).as(keysMap[resultUnit])
      );

      return [
        difference,
        $translate.instant(difference === 1 ?
          resultUnit.slice(0, resultUnit.length - 1) :
          resultUnit
        )
      ].join(' ');
    }

    /**
     * Returns default path for a given host type
     *
     * @method   getDefaultRootPath
     * @param    {string}   hostType
     * @returns  {string}   default Path
     *
     * @example getDefaultRootPath('kWindows') // 'C:\\'
     *          getDefaultRootPath('kLinux')   // '/'
     */
    function getDefaultRootPath(hostType) {
      if (hostType == 'kWindows') {
        return 'C:\\';
      }
      return '/';
    }

    /**
     * Returns the number of color shade for the given base color.
     * using Highcharts to generate brighten color of a base color.
     *
     * @method   getMonochromeColors
     * @param    {String}   baseColor   The base color eg. '#feabe8'
     * @param    {number}   count       The number of color shade you want.
     * @return   {Array}    The monochrome colors shades.
     */
    function getMonochromeColors(baseColor, count) {
      var index;
      var colors = [];

      for (index = 0; index < count; index++) {
        // Start out with a lightest base color (negative brighten), and end up
        // with a much darker color.
        colors.push(
          Highcharts.Color(baseColor).brighten((3 - index)/7).get()
        );
      }

      return colors;
    }

    /**
     * returns data protocol for the given kValue
     *
     * @method    getDataProtocolFromKValue
     * @param     {string}     kValue      data protocol kValue
     * @return    {string}     protocol    this is a generic value like nfs/smb
     *
     * @example   getDataProtocolFromKValue('KNfs') returns 'nfs'
     *            getDataProtocolFromKValue('KNfs3') returns 'nfs'
     */
    function getDataProtocolFromKValue(kValue) {
      return NAS_KVALUE_DATA_PROTOCOL_MAP[kValue];
    }

    /** Function which always returns true.
     *
     * @method   returnTrueAlways
     * @return   {Boolean}   Always true, as the name suggests.
     */
    function returnTrueAlways() {
      return true;
    }

    /**
     * Groups the Objects based on the prop
     *
     * @param  {Array}    objects
     * @param  {string}   prop
     * @return {object}   grouped by prop Object
     */
    self.groupObjects = function groupObjects(objects, prop) {
      var getter = $parse(prop);

      return objects.reduce(function groupTypes(accumulator, item) {
        var key = getter(item);

        accumulator[key] = accumulator[key] || [];
        accumulator[key].push(item);

        return accumulator;
      }, {});
    };

    /**
     * Gets the timezone offset. Provided the timezone in string long format
     * (America/Los_Angeles) return the string offset (-07:00).
     *
     * @method     getTimezoneOffset
     * @param      {string}  timezone  The timezone
     * @return     {string}  The timezone offset.
     */
    self.getTimezoneOffset = function getTimezoneOffset(timezone) {
      return moment.tz(timezone).format('Z');
    };

    /**
     * Determines if the given value is a boolean type.
     *
     * @method   isBoolean
     * @param    {*}         [val]   The thing to check.
     * @return   {boolean}   True if boolean, False otherwise.
     */
    function isBoolean(val) {
      return typeof val === 'boolean';
    }

    /**
     * Generic recursive tree search. Useful for searching for entities in the
     * EntityHierarchy, or other trees. Assumes an EntityHierarchy unless
     * configured otherwise. See params.
     *
     * @method   searchTree
     * @param    {object}     tree                        The tree root.
     * @param    {function}   compareFn                   Comparison Fn to test
     *                                                    if the tree item is a
     *                                                    match. Should return a
     *                                                    boolean-y value.
     * @param    {string}     [childrenProp='children']   String name of the
     *                                                    array property to
     *                                                    recurse into.
     * @return   {object}     The found item in the tree, or undefined.
     */
    function searchTree(tree, compareFn, childrenProp) {
      var result;

      childrenProp = childrenProp || 'children';

      if (!angular.isFunction(compareFn)) {
        throw new Error('searchTree() requires a `compareFn` argument.');
      }

      if (!tree || compareFn(tree)) {
        return tree;
      }

      if (Array.isArray(tree[childrenProp])) {
        for (var cc = 0, len = tree[childrenProp].length; cc < len; cc++) {
          result = searchTree(tree[childrenProp][cc], compareFn);
          if (result) { break; }
        }
      }

      return result;
    }

    /**
     * Spits out the path for a given os
     *
     * @method    transformToOsSpecificPath
     * @param     {string}         path                  input path
     * @param     {number|string}  [hostType='kLinux']   can be a kType or
     *                                                   number, by default it
     *                                                   is a linux path
     * @return    {string}         appropriate path for the given host type
     *
     * @example  transformToOsSpecificPath('/C/Administrator', 'kWindows')
     *              returns 'C:\Administrator'
     *           transformToOsSpecificPath('/bin', 0)
     *              returns '/bin'
     */
    function transformToOsSpecificPath(path, hostType) {
      if (!path || !hostType) { return path; }

      if (isNumber(hostType)) {
        hostType = ENUM_HOST_TYPE_CONVERSION[hostType];
      }

      // Note: Currently we just convert unix paths to windows.
      //       Lets add conditions here if we need any other.
      if (hostType === 'kWindows') {
        return transformToWindowsPath(path);
      }
      return path;
    }

    /**
     * Transforms a windows path which is stored as a unix path in magneto
     * to windows style path with backslashes and : for display purpose.
     * The logic here is also copied to:
     * src/app/modules/restore/restore-shared/model/recovery-file-object.ts.
     *
     * @method   transformToWindowsPath
     * @param    {string}   path
     * @returns  {string}   windows path
     *
     * @example   transformToWindowsPath('/C/Users') returns 'C:\Users'
     */
    function transformToWindowsPath(path) {
      if (!path) { return path; }

      // Handle the case when path is something like '/C', we want to return 'C:/'
      if (path.length === 2 && path.charAt(0) === '/') {
        path = path + '/';
      }

      path = path.replace(/\//g, '\\');

      if (path.charAt(0) === '\\' &&  path.charAt(2) === '\\') {
        path = path.replace(/\\/, '');
        path = path.splice(1, 0, ':');
      }
      return path;
    }


    /**
     * Filters out non-numbers.
     *
     * @method     onlyNumbers
     * @param      {array}  arr     the array to be filtered
     * @return     {array}  new filtered array
     */
    self.onlyNumbers = function onlyNumbers(arr) {
      return (arr || []).filter(function filterArr(val) {
        return (typeof val === 'number');
      });
    };

    /**
     * Filters out non-strings.
     *
     * @method     onlyStrings
     * @param      {array}  arr     the array to be filtered
     * @return     {array}  new filtered array
     */
    self.onlyStrings = function onlyStrings(arr) {
      return (arr || []).filter(function filterArr(val) {
        return (typeof val === 'string');
      });
    };

    /**
     * converts provided bytes into megabytes (integers only)
     * @param  {Integer} bytes - number of bytes
     * @return {Integer} Megabytes
     */
    self.bytesToMegabytes = function bytesToMegabytes(bytes) {
      if (!bytes || bytes === 0) {
        return 0;
      }
      return +bytes / (1024 * 1024);
    };

    /**
     * converts provided bytes into X (integers only)
     * @param  {Integer} bytes - number of bytes
     * @param  {Integer} unit - desired unit
     * @return {Integer} adjusted bytes
     */
    self.bytesToUnit = function bytesToUnit(bytes, unit) {
      if (!bytes || bytes === 0) {
        return 0;
      }
      var sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB'];
      var i = sizes.indexOf(unit);
      return bytes / Math.pow(1024, i);
    };

    /**
     * Returns a ratio
     * @param  {String/Integer} numerator numerator
     * @param  {String/Integer} denominator denominator
     * @param  {String} string to return if ratio can't be calculated
     * @return {Float} ratio rounded to 2 places of decimal
     */
    self.getRatio = function getRatio(numerator, denominator, noResultString) {
      if (!numerator) {
        return noResultString || $translate.instant('naNotAvailable');
      }
      if (!denominator) {
        // denominator is undefined or 0
        return noResultString || $translate.instant('naNotAvailable');
      }
      return self.round(+numerator / +denominator, 2);
    };

    /**
     * parses the provided status value and returns a UI friendly status (in progress, success, errorText)
     * @param  {Object} restoreTask  restore Object as returned from API call
     * @param  {Boolean} toClassName if true, returns a lowercased string, with spaces replaced by hyphens
     * @return {String}              UI friendly string or class name
     */
    self.getTaskStatus = function getTaskStatus(restoreTask, toClassName) {
      toClassName = !!toClassName;
      var statusString = '';
      var taskStatus = restoreTask.performRestoreTaskState.base.status;
      if (taskStatus === 5) {
        if (restoreTask.performRestoreTaskState.base.error.type === 0) {
          // success
          statusString = ENUM_UI_RESTORE_TASK_STATUS[5];
        } else {
          // error
          statusString = ENUM_UI_RESTORE_TASK_STATUS[6];
        }
      } else {
        statusString = ENUM_UI_RESTORE_TASK_STATUS[taskStatus];
      }

      if (toClassName) {
        statusString = statusString.replace(/\s+/g, '-').toLowerCase();
      }
      return statusString;
    };

    /**
     * returns true for valid ip and false for invalid
     * @param  {String} ip  IP address
     * @return {boolean}    boolean representing ip validation result
     */
    self.validateIp = function validateIp(ip) {
      return FORMATS.IPv4AndIPv6.test(ip);
    };

    /**
     * ui select tags value IP validator.
     *
     * @method   uiSelectIPValidator
     * @param    {string}      ip            The IP address to test
     * @param    {function}    transformFn   The IP transformation function
     * @return   {boolean}   return false for invalid IP else the test input IP
     */
    function uiSelectIPValidator(ip, transformFn) {
      if (self.validateIp(ip)) {
        return angular.isFunction(transformFn) ? transformFn(ip) : ip;
      }

      return false;
    }

    /**
     * ui select tags value Email validator.
     *
     * @method   uiSelectEmailValidator
     * @param    {string}           email            The email to test
     * @return   {boolean|string}   return false for invalid email else the test
     *                              input Email.
     */
    function uiSelectEmailValidator(email) {
      if (FORMATS.email.test(email)) {
        return email.trim();
      }

      return false;
    }

    /**
     * builds an array of IP addresses based on a provided low IP address
     * and a final value high IP address
     * @param  {String} low  starting IP address for the range to be generated
     * @param  {String} high final value for last position in IP address range (192.168.1.XXX)
     * @return {Array}      Array of IP addresses in consecutive order
     */
    self.buildRange = function buildRange(low, high) {
      var array = low.split(".");
      if (array.length >= 3) {
        var prefix = array[0] + '.' + array[1] + '.' + array[2];
        var lowNum = array[3];
        var highNum = high;
        var total = highNum - lowNum;
        var range = [];
        for (var i = 0; i <= total; i++) {
          var ip = prefix + '.' + (parseInt(lowNum, 10) + i);
          range.push(ip);
        }
        return range;
      }
    };

    /**
     * return the number of properties in an object
     * @param {Object} obj to count the number of properties of
     * @return {Integer} number of properties for the object
     */
    self.jsObjectPropertyCount = function jsObjectPropertyCount(obj) {
      var size = 0;
      var key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          size++;
        }
      }
      return size;
    };

    /**
     * takes a number and determines if its actually a number
     * @param  {Integer/Float}  n some value to test for numberness
     * @return {Boolean}   does the value pass the number test?
     */
    self.isNumeric = function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    };

    /**
     * takes a value and determines if its an integer
     * @param  {Integer?}  some value to test for integer ()
     * @return {Boolean}   does the value pass the integer test?
     */
    self.isInteger = function isInteger(value) {
      return typeof value === 'number' &&
        isFinite(value) &&
        Math.floor(value) === value;
    };

    /**
     * takes an array and shuffles it randomly,
     * this is the Fisher-Yates shuffle
     * http://bost.ocks.org/mike/shuffle/
     * @param  {Array} array to be shuffled
     * @return {Array}       shuffled
     */
    self.arrayShuffle = function arrayShuffle(array) {
      var m = array.length;
      var t;
      var i;

      // While there remain elements to shuffle…
      while (m) {
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }

      return array;
    };

    /**
     * takes an array with assumed integer values
     * and returns the sum of the individual values
     * @param  {Array} array containing integer values
     * @return {Integer}       the sum of the array contents
     */
    self.arraySum = function arraySum(array) {
      var i = array.length;
      var sum = 0;
      while (i--) {
        sum = sum + array[i];
      }
      return sum;
    };

    /**
     * takes an array with assumed integer values and
     * returns the average of the individual values
     * @param  {Array} array containing integer values
     * @param  {Integer} precision number of decimals places to return
     * @return {Float}       average of array values
     */
    self.arrayAverage = function arrayAverage(array, precision) {
      if (!array.length) {
        return null;
      }
      var len = array.length;

      // if precision is an integer apply rounding
      if (precision === parseInt(precision, 10)) {
        return self.round(self.arraySum(array) / len, precision);
      } else {
        return self.arraySum(array) / len;
      }
    };

    /**
     * takes an array with assumed integer values and
     * returns the average of the individual values after filtering
     * out null/non-numeric values
     * @param  {Array} array containing integer values
     * @param  {Integer} precision number of decimals places to return
     * @return {Float}       average of array values
     */
    self.arrayFilteredAverage = function arrayFilteredAverage(array, precision) {
      if (!array.length) {
        return null;
      }
      var filteredArray = $filter('filter')(array, function(value, index) {
        return self.isNumeric(value);
      });

      var len = filteredArray.length;

      // if precision is an integer apply rounding
      if (precision === parseInt(precision, 10)) {
        return self.round(self.arraySum(filteredArray) / len, precision);
      } else {
        return self.arraySum(filteredArray) / len;
      }
    };

    /**
     * Returns an array of objects sorted by a given property
     * TODO: replace implementations with angular $filter sorting
     * @param  {property} object property on which to be sorted
     * @return {array}  sorted array of name/value pairs
     */
    self.sortObjectsByProp = function sortObjectsByProp(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function(a, b) {
        var result;
        if (typeof(a[property]) === 'string' && typeof(b[property]) === 'string') {
          result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property]) ? 1 : 0;
        } else {
          result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        }
        return result * sortOrder;
      };
    };

    /**
     * Escapes the regex special characters.
     * @param  {string} Regex string.
     * @return {string} Escaped regex string.
     */
    self.escapeRegex = function escapeRegex(str) {
      // $& in javascript regex means current pattern match.
      return (str + '').replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
    };

    /**
     * Escapes the HTML characters.
     * @param  {string} String containing inline HTML.
     * @return {string} Escaped HTML string.
     */
    self.escapeHtml = function escapeHtml(html) {
      return html.replace(/&/g, "&amp;")
                 .replace(/</g, "&lt;")
                 .replace(/>/g, "&gt;")
                 .replace(/"/g, "&quot;")
                 .replace(/'/g, "&#39;");
    }

    /**
     * dedupe the list of copyPolicy objects
     *
     * @param     {array}     objects     array of objects
     * @param     {string}    property    the unique property you needed to
     *                                    dedupe the list
     *
     * @return    {array}                 new array of deduped objects
     */
    self.dedupeListOfObjects = function dedupeListOfObjects(objects, property) {
      var policyHash = {};
      var list = [];
      var value;

      objects.forEach(function(object) {
        value = self.deepValueFromObjectWithStringPath(object, property);

        if (!policyHash[value]) {
          policyHash[value] = true;

          list.push(object);
        }
      });

      return list;
    };

    /**
     * will get a nested value from an object
     *
     * @param      {obj}     obj     the object you want to get the value from
     * @param      {string}  path    the string path ex. 'target.id'
     *
     * @return                       the value you wanted
     */
    self.deepValueFromObjectWithStringPath =
      function deepValueFromObjectWithStringPath(obj, path) {
        var keys = path.split('.');
        var current = obj;
        var i;

        for (i = 0; i < keys.length; i++) {
          if (current[keys[i]] === undefined) {
            return undefined;
          } else {
            current = current[keys[i]];
          }
        }

        return current;
    };

    /**
     * Deduplicates a list of objects
     *
     * @method     dedupe
     * @param      {Array}     list             List of mixed Objects &
     *                                          Primitives
     * @param      {Function}  containsObjectFn  Function to compare 2
     *                                          objects. Returns {Bool}
     * @return     {Array}     The deduplicated list
     */
    self.dedupe = function dedupe(list, containsObjectFn) {
      var primitives = {
        'boolean': {},
        'number': {},
        'string': {}
      };
      var objects = [];
      var out = [];
      var len = list.length;
      var ii = 0;
      var type;
      var item;

      // If no containsObjectFn argument is passed, lets use a simple
      // default one. Returns true with objects :/
      containsObjectFn = containsObjectFn || defaultComparatorFn;

      for (;ii < len; ii++) {
        item = list[ii];
        type = typeof item;
        if (type in primitives) {
          if (!primitives[type].hasOwnProperty(item)) {
            primitives[type][item] = true;
            out.push(item);
          }
        } else {
          if (!containsObjectFn(objects, item)) {
            objects.push(item);
            out.push(item);
          }
        }
      }

      /**
       * Default comparison to check if an object is in a collection
       *
       * @method     defaultComparatorFn
       * @param      {Array}   list    The collection we're looking into
       * @param      {Object}  item    The object we're looking for the
       *                               presence of
       * @return     {Bool}    If the list contains the item
       */
      function defaultComparatorFn(list, item) {
        return list.includes(item);
      }

      return out;
    };

    /**
     * sanatizes a string provided by uiSelect in tagging mode
     *
     * @param      {string}  str     The string
     * @return     {string}  sanatized string
     */
    self.cleanUiSelectTag = function cleanUiSelectTag(str) {
      if (!str) {
        return str;
      }
      return str.replace(/,/g, '').replace(/\//g, '').trim();
    };

    /**
     * Replaces special characters in a Google Generated Private Key.
     * Google generates these special charactters when registering a new
     * Vault/Source. For use specifically with Google's Client Private
     * Key.
     *
     * @param    {String}   str   Client Private Key
     * @return   {String}         Cleaned Client Private Key
     */
    self.normalizeGooglePrivateKey = function normalizeGooglePrivateKey(str) {
      return (str || '')
        // Replace all encoded equal signs with actual '='
        .replace(/\\u003d/g, '=')

        // Replace all string representations of '\n' with actual new lines
        .replace(/\\n/g, '\n');
    };

    /**
     * converts a string into byte array
     *
     * @method  convertStringToBytesArr
     * @param   (String)     str   string to be converted to bytes array
     * @return  {Number[]}   The bytes array
     */
    self.convertStringToBytesArr = function convertStringToBytesArr(str) {
      // char codes
      var bytes = [];
      var code;

      for (var i = 0; i < str.length; ++i) {
        code = str.charCodeAt(i);
        bytes = bytes.concat([code]);
      }

      return bytes;
    };

    /**
     * Returns a copy of a simple object structure. To be used instead
     * of angular.copy() when performance is an issue, as angular.copy
     * performance is poor, particularly with large objects (i.e. 1000+
     * VM tree). This approach shouldn't be used for more complex
     * objects (no circles, no functions, no objects other than
     * string/number/plain-object/null).
     *
     * @param      {object}    obj           The object
     * @param      {function}  [replacerFn]  JSON.stringify replacer Fn.
     *                                       Arguments: key, value
     *                                       https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#The_replacer_parameter
     * @return     {object}                  A deep copy of the object
     */
    self.simpleCopy = function simpleCopy(obj, replacerFn) {
     if (typeof obj !== 'object') {
      return obj;
     }
     return JSON.parse(JSON.stringify(obj, replacerFn));
    };

    /**
     * Returns the kValue of a numeric envType. Otherwise returns the envType
     * unchanged.
     *
     * NOTE: This is a transitional tool intended to be used only until all APIs
     * have been standardized to return and accept kValues.
     *
     * Usage:
     *    envType = enforceEnvKvalue(envType);
     *
     * After standardization has been completed, all such invocations may be
     * removed gracefully.
     *
     * @method     enforceEnvKvalue
     * @param      {number|string}  envType  The environment type
     * @return     {string}         envType kValue
     */
    self.enforceEnvKvalue = function enforceEnvKvalue(envType) {
      return isFinite(envType) ? ENV_TYPE_CONVERSION[envType] : envType;
    };


    /**
     * Copies property values from source object to dest without creating
     * new keys. If key exists in dest and source, source value is used
     * irrespective of current value of the dest.
     *
     * @method   assignValues
     * @param    {object}    dest     Object to set defaults for
     * @param    {object}    source   Object from which to copy values
     * @return   {object}             dest object
     */
    self.assignValues = function assignValues(dest, source) {
      forOwn(dest, function setValue(value, key) {
        if (source.hasOwnProperty(key)) {
          dest[key] = source[key];
        }
      });
      return dest;
    };


    /**
     * Converts a valid netmask to CIDR Mask number
     * CIDR mask is leading number of 1's in binary representation of netmask
     * E.g. 255.255.255.0 => 24
     *
     * @method   netmaskToCIDRMask
     * @param    {string}    netMask  Valid Netmask e.g. 255.255.0.0
     * @return   {number}             CIDR mask bits
     */
    self.netmaskToCIDRMask = function netmaskToCIDRMask(netmask) {
      netmask = netmask || '';
      var netmaskDecimals = map(netmask.split('.'), Number);

      var netmaskBinary = map(netmaskDecimals, function strToBinary(str) {
        return str.toString(2);
      }).join('');

      return netmaskBinary.split('1').length - 1;
    };

    /**
     * Converts a valid CIDR string to ip/netmaskBits tuple
     * CIDR string represents subnet in perticular way
     * E.g. 10.16.0.0/16
     *
     * @method   cidrToIpBits
     * @param    {string}    cidrStr  Valid CIDR subnet e.g. 10.16.0.0/16
     * @return   {array}              Array with 2 values ["10.16.0.0", 16]
     */
    self.cidrToIpBits = function cidrToIpBits(cidrStr) {
      var cidr = cidrStr.split("/");

      if(cidr.length == 2) {
        return [cidr[0], Number(cidr[1])];
      }

      return [];
    };


    /**
     * Generates UUID with low chance of collision. This is taken from
     * https://stackoverflow.com/a/8809472.
     * License is marked as Public Domain/MIT
     *
     * @method   generateUuid
     * @return   {string}   RFC4122 version 4 uuid
     */
    self.generateUuid = function generateUuid() {
      var d = new Date().getTime();
      if (typeof performance !== 'undefined' &&
        typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
      }

      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };


    /**
     * Returns provided string after converting the first letter to a capital
     * and prepending with a 'k'.
     *
     * @method    makeKval
     * @param     {string}    str   Aany string.
     * @returns   {string}    New string prefaced by 'k'.
     */
    self.makeKval = function makeKval(str) {
      return 'k' + self.capitalize(str);
    };

    /**
     * Transforms the first letter of given string to upperCase
     *
     * @method    capitalize
     * @param     {string}    str   The String to capitalize.
     * @returns   {string}    Transformed string.
     */
    self.capitalize = function capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return self;
  }

  /**
   * POLYFILLS
   * Note: If many more polyfills are added here, consider relocating them into
   * individual partials under `static/polyfills/`
   ******************************************************************************/
  // TODO: Delete this polyfill when our supported versions of IE support it (none
  // do at this time).
  if (!Array.prototype.includes) {
    /**
     * Polyfill for Array.prototype.includes(). Determines if a queried element
     * is within a collection. This is only loaded if the browser doesn't
     * already have the native method. MDN docs http://goo.gl/iP7XCX.
     *
     * @param    {object}    searchElement   Object or primitive value to
     *                                       determine the inclusion of.
     * @param    {integer=}  fromIndex       Index from which to begin the
     *                                       search. Default: 0
     * @return   {boolean}                   True if found, False otherwise.
     */
    Array.prototype.includes = function arrIncludes(searchElement, fromIndex) {
      // Default to 0 if falsey
      fromIndex = fromIndex || 0;
      // Use this or an empty Array to prevent errors
      var _this = (this || []);
      // Get the subset list from fromIndex to the end
      var list = _this.slice(fromIndex, (_this.length) ? _this.length : 0);
      // Return if the query element is found or not
      return !!~(list).indexOf(searchElement);
    };
  }

  if (!String.prototype.includes) {
    /**
     * Polyfill for String.prototype.includes(). Determines if a substring is
     * found within a string. MDN docs https://goo.gl/CCnWvS
     *
     * @param      {string}   search  The string to search for
     * @param      {number}   start   The starting index for searching. Default:
     *                                0
     * @return     {boolean}  true if search string is found, false otherwise.
     */
    String.prototype.includes = function strIncludes(search, start) {
      if (typeof start !== 'number') {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  }

  /**
   * Polyfill for Number.isInteger, which is missing in IE11.
   * https://goo.gl/fLxPkK
   */
  Number.isInteger = Number.isInteger || function isInteger(value) {
    return typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value;
  };

  if (!Object.values) {
    /**
     * Polyfill for Object.values (companion to Object.keys). MDN docs
     * https://goo.gl/0JSkxP
     *
     * @method    Object.values
     * @param     {object}   obj   Object to retrieve values from.
     * @returns   {array}    List of own enumerable values of the given object.
     */
    Object.values = function objectValues(obj) {
      return (!obj || typeof obj !== 'object') ?
        // Not an object, return empty array
        [] :
        // Otherwise, return an array of own enumerable values
        Object.keys(obj)
          .map(function eachOwnProperty(prop) {
            return obj[prop];
          });
    };
  }

  // https://tc39.github.io/ecma262/#sec-array.prototype.find
  if (!Array.prototype.find) {
    /**
     * Polyfill for Array.find
     * MDN goo.gl/sTSZrx
     */
    Object.defineProperty(Array.prototype, 'find', {
      value: function(predicate) {
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        var o = Object(this);
        var len = o.length >>> 0;

        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }

        var thisArg = arguments[1];
        var k = 0;

        while (k < len) {
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return kValue;
          }

          k++;
        }

        return undefined;
      }
    });
  }

  // https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
  if (!Array.prototype.findIndex) {
    /**
     * Polyfill for Array.findIndex
     */
    Object.defineProperty(Array.prototype, 'findIndex', {
      value: function(predicate) {
        var obj;
        var len;
        var thisArg;
        var index;

        if (!this) {
          throw new TypeError('"this" is null or not defined');
        }

        obj = Object(this);

        len = obj.length >>> 0;

        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }

        thisArg = arguments[1];

        index = 0;

        while (index < len) {
          if (predicate.call(thisArg, obj[index], index, obj)) {
            return index;
          }
          index++;
        }

        return -1;
      }
    });
  }

  if (!String.prototype.splice) {
    /**
     * Polyfill for non existent String.splice. Behavior matches that of
     * Array.prototype.splice():
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
     *
     * @method   String.splice
     * @param    {number}   index      The index to splice at
     * @param    {number}   delCount   The number of characters to delete
     * @param    {string}   [add='']   The string to splice in
     * @return   {string}   new string with spliced in content
     */
    String.prototype.splice = function stringSplice(index, delCount, add) {
      if (index < 0) {
        index = this.length + index;
        if (index < 0) {
          index = 0;
        }
      }
      return this.slice(0, index) + (add || '') + this.slice(index + delCount);
    };
  }
})(angular);
