import { each } from 'lodash-es';
import { compact } from 'lodash-es';
// Service: Cloud Edition Manager Service

;(function(angular) {
  'use strict';
  angular
    .module('C.cloudEditionManagerService', [])
    .service('CloudEditionManagerService', CloudEditionManagerServiceFn);

  function CloudEditionManagerServiceFn($http, $timeout, $state, cMessage, API,
    SlideModalService, FEATURE_FLAGS) {

    var CloudEditionManagerSvc = {
      createCloudEditionJob: createCloudEditionJob,
      deleteCloudEdition: deleteCloudEdition,
      deployCloudEdition: deployCloudEdition,
      getAllCloudEditionJobs: getAllCloudEditionJobs,
      getAWSRegions: getAWSRegions,
      getCENodeTypes: getCENodeTypes,
      getCloudEditionJob: getCloudEditionJob,
      getCloudEditionIconClass: getCloudEditionIconClass,
      getClusterImageVersions: getClusterImageVersions,
      getGCPRegionDetails: getGCPRegionDetails,
      getGCPRegions: getGCPRegions,
      showChecklistModal: showChecklistModal,
      showPolicyModal: showPolicyModal,
      validateCloudCredentials: validateCloudCredentials,
      getZoneDetailsForAzureNGCE: getZoneDetailsForAzureNGCE,
    };

    /**
     * Get the available AWS regions
     *
     * @method getAWSRegions
     *
     * @param  {Object} params  queryParams containing the cloudType
     * @return {Object} Promise to be resolved with aws regions data
     */
    function getAWSRegions(params) {
      var opts = {
        method: 'get',
        url: _getCloudServiceUrl('aws/regions'),
        params: params,
      };

      return $http(opts).then(function gotRegions(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Get the available GCP regions
     *
     * @method getGCPRegions
     *
     * @param  {Object} data  The metadata for the request containing
     *                        credentials and projectId
     * @return {Object} Promise to be resolved with gcp regions data
     */
    function getGCPRegions(data) {
      var opts = {
        method: 'post',
        url: _getCloudServiceUrl('gcp/regions'),
        data: data,
      };

      return $http(opts).then(function gotRegions(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Get the GCP region details like subnet/vpc etc
     *
     * @method getGCPRegionDetails
     *
     * @param  {Object} data  The metadata for the request containing
     *                        credentials and projectId
     * @return {Object} Promise to be resolved with gcp region details data
     */
    function getGCPRegionDetails(data) {
      var opts = {
        method: 'post',
        url: _getCloudServiceUrl('gcp/regionDetails'),
        data: data,
      };

      return $http(opts).then(function gotRegionDetails(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Get the cluster image versions available for all CE clusters
     *
     * @method getClusterImageVersions
     *
     * @param  {Object} data    The metadata for the request containing
     *                          credentials and projectId
     * @param  {Object} imageParams  Request params for the api. Changes based on clusterType.
     * @param  {String} clusterType  Type of cluster(aws/azure/gcp)
     * @return {Object} Promise to be resolved with gcp image version details
     */
    function getClusterImageVersions(data, imageParams, clusterType) {
      var opts = {
        method: clusterType == 'gcp' ? 'post' : 'get',
        url: _getCloudServiceUrl(`${clusterType}/images`),
        params: imageParams,
        data: data,
      };

      return $http(opts).then(function gotRegionDetails(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Get the available AWS node types
     *
     * @method getCENodeTypes
     */
    function getCENodeTypes(cloudType) {
      var opts = {
        method: 'get',
        url: _getCloudServiceUrl('nodeTypes'),
        params: {source: cloudType},
      };

      return $http(opts).then(function gotRegions(resp) {
        if (resp && resp.data) {
          if (!FEATURE_FLAGS.ceXLNodeSupport) {
            return resp.data.filter(data => data.type !== 'xlarge');
          }
        }

        return resp && resp.data;
      });
    }

    /**
     * Get a list of all the cloud editions (deployed or under deployment)
     *
     * @method getAllCloudEditionJobs
     */
    function getAllCloudEditionJobs() {
      var opts = {
        method: 'get',
        url: _getCloudServiceUrl('jobs'),
      };

      return $http(opts).then(function gotJobs(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Get a cloud edition (deployed or under deployment) based on the given id
     *
     * @method getCloudEditionJob
     * @param  {Number}   id    id of cloud edition to be fetched
     */
    function getCloudEditionJob(id) {
      var opts = {
        method: 'get',
        url: _getCloudServiceUrl('jobs', id),
      };

      return $http(opts).then(function gotJob(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Create a new Cloud Edition Deployment job
     *
     * @method createCloudEditionJob
     * @param  {Object}   job    job object with data to create the new job
     * @return {Object}   Promise to be resolved with cloudEdition Data
     */
    function createCloudEditionJob(job) {
      var opts = {
        method: 'post',
        url: _getCloudServiceUrl('jobs'),
        data: job,
      };

      return $http(opts).then(function sentJob(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Delete a deployed Cloud Edition
     *
     * @method deleteCloudEdition
     * @param  {Number}   id    id of the cloudEdition
     */
    function deleteCloudEdition(id, data) {
      var opts = {
        method: 'delete',
        url: _getCloudServiceUrl('jobs', id),
        data: data,
      };

      return $http(opts).then(function sentJob(resp) {
        return resp && resp.data;
      });
    }

    /**
     * Verify Cloud user credentials and return the details of account
     *
     * @method validateCloudCredentials
     * @param  {Object}   data    object containing Cloud credentials
     * @return {Object}   Promise to be resolved/rejected if
     *                    validation passes/fails
     */
    function validateCloudCredentials(data) {
      var opts = {
        method: 'post',
        url: _getCloudServiceUrl('describe'),
        data: data,
      };

      return $http(opts).then(function sentJob(resp) {
        return _decorateDescribeData(resp && resp.data);
      });
    }

    /**
     * Get the zone details for every region for Azure NGCE deplyment
     *
     * @method getZoneDetailsForAzureNGCE
     * @param  {Object}   data    object containing Cloud credentials and requestType
     * @return {Object}   Promise to be resolved/rejected if validation passes/fails
     */
    function getZoneDetailsForAzureNGCE(data) {
      var opts = {
        method: 'post',
        url: _getCloudServiceUrl('describe'),
        data: data,
      };

      return $http(opts).then(function sentJob(resp) {
        return _decorateDescribeData(resp && resp.data);
      });
    }

    /**
     *
     * Decorate describe data with helper properties
     *
     * @method _decorateDescribeData
     * @param  {Object} data       The data to be decorated
     * @param  {String} cloudType  The cloud type (Aws/Azure etc)
     * @return {Object} decorated data
     */
    function _decorateDescribeData(data) {
      var taggable = ['vpc', 'subnets', 'securityGroups', 'resourceGroups',
        'virtualNetworks'];

      each(data, function eachData(obj, key) {
        if (taggable.includes(key)) {
          switch (key) {
            case 'vpc':
              each(obj, function eachVPC(vpc) {
                _createTaggedDisplayName('vpcId', vpc);

                _decorateDescribeData(vpc);
              });

              break;

            case 'securityGroups':
              each(obj, function eachVPC(sg) {
                _createTaggedDisplayName('securityGroupId', sg);
              });

              break;

            case 'subnets':
              each(obj, function eachVPC(subnetObj) {
                each(subnetObj, function eachSubnetObj(subnet) {
                  _createTaggedDisplayName('subnetId', subnet);
                });
              });

              break;

            default:
             angular.noop();
          }
        }
      });

      return data;
    }

    /**
     * Decorates an object with a _displayName property using its 'tag'
     * property, if avaialble
     *
     * @method _createTaggedDisplayName
     * @param {String} key
     * @param {Object} obj
     */
    function _createTaggedDisplayName(key, obj) {
      obj._displayName =
        obj.tag ? [obj[key], '|', obj.tag].join(' ') : obj[key];
    }

    /**
     * Returns the css class for icon to be used for a gived cloudEdition
     * Job status
     *
     * @param   {String}  status  The status of the CloudEdition Job
     * @return  {String}  The css class
     */
    function getCloudEditionIconClass(status) {
      switch(status) {
        case ('SUCCESS'):
          return 'ce-success';

        case ('SCHEDULED'):
          return 'ce-scheduled';

        case ('FAILED'):
          return 'ce-error';

        case ('IN_PROGRESS'):
          return 'icn-cloud';

        case ('CANCELLED'):
          return 'ce-cancelled';

        default:
          return '';
      }
    }

    /**
     * Appends 'cloudservice' string to all urls for cloudservice endpoint
     *
     * @method  _getCloudServiceUrl
     */
    function _getCloudServiceUrl() {
      Array.prototype.unshift.call(arguments, 'cloudservice');
      return API.mcm.apply(this, arguments);
    }

    /**
     * Display a modal for additional email addresses
     *
     * @method  _showEmailModal

     * @return  {Object}  Promise to be resolved with modal form value
     */
    function _showEmailModal() {
      var modalConfig = {
        resolve: {
          innerComponent: 'cloudDeploymentEmail',
          actionButtonKey: false,
          closeButtonKey: false,
          titleKey: 'emailNotifications',
        },
        autoHeight: true,
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Submit the form to create a new CloudEdition deployment job
     * @method deployCloudEdition
     *
     * @param   {Object}    config  The configuration paramaeters for new job
     * @param   {Object[]}  tags    List of tag data for the CE
     * @return  {Object}    Promise to be resolved with response from API
     */
    function deployCloudEdition(config, tags) {
      // securityGroupId may have 'null' if new SecurityGroup is selected.
      // remove it before sending to backend
      if (config.deploymentConfig.securityGroupId) {
        config.deploymentConfig.securityGroupId =
          compact(config.deploymentConfig.securityGroupId);
      }

      // map tags to server side format
      config.deploymentConfig.tags =
        tags.map(function mapTags(tag) {
          var obj = {};
          obj[tag.key] = tag.value;
          return obj;
        });

      return _showEmailModal()
        .then(function addedEmailAddress(resp) {
          config.email = config.email.concat(resp);
        })
        .finally(function afterEmailModal() {
          createCloudEditionJob(config)
            .then(function jobDeployed(resp) {

              // Give a slight delay between completion of this POST call and
              // the new GET call on cloud-edition-manager state otherwise the
              // GET does not return the newly created cloudEdition job.
              $timeout(function updateJobPage() {
                $state.go('cloud-edition-manager');

                cMessage.info({
                  textKey: 'cloudEdition.deployInitiatedNote',
                  textKeyContext: {
                    name: config.jobName,
                  },
                  titleKey: 'deployInitiated',
                  timeout: 6000,
                });

              }, 1000);
            });
          });

    }

    /**
     * Show a modal with a checklist guide for CE deployment
     * @method  showChecklistModal
     *
     * @param {String} type The type of cloudEdition
     */
    function showChecklistModal(type) {
      var modalConfig = {
        resolve: {
          innerComponent: 'cloudDeploymentChecklist',
          actionButtonKey: 'close',
          closeButtonKey: false,
          titleKey: 'cloudEdition.deploymentChecklist',
          bindings: {
            type: type,
          },
        },
        autoHeight: true,
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Show the appropriate Cloud Policy for deployement of CE in a modal
     *
     * @method showPolicyModal
     */
    function showPolicyModal(type) {
      var modalConfig = {
        resolve: {
          innerComponent: 'cloudEditionPolicy',
          actionButtonKey: 'close',
          closeButtonKey: false,
          bindings: {
            cloud: type,
          },
        },
        autoHeight: true,
      };

      return SlideModalService.newModal(modalConfig);
    }

    return CloudEditionManagerSvc;
  }
}(angular));
