import { AppEnvironment, heliosProductionHostnames } from '@cohesity/iris-shared-constants';

/**
 * This file defines production environment configurations. During compile, this
 * file will replace environment.ts.
 */

export const environment: AppEnvironment = {
  production: true,
  heliosInFrame: false,
  get heliosProduction(): boolean {
    return heliosProductionHostnames.includes(window.document.location.hostname);
  }
};
