import { assign } from 'lodash-es';
// Component: c-reports-controls component

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.reportsControls:cReportsControls
   * @function
   *
   * @description
   * Reusable component for reports, that allows us to easily share
   * data between the report controller and the reportsControls
   * component.
   *
   * @example
   * <c-reports-controls
   *   dataset="dataset"
   *   config="dataTransferredToExternalTargetsControlsConfig"
   *   apply-filters="getExternalTargetsObjects(filters)">
   * </c-reports-controls>
   *
   */
  var options = {
    controller: 'ReportsControlsCtrl',
    templateUrl: 'app/reports/components/c-reports-controls/c-reports-controls.html',
    bindings: {

      /* the config defined by the report will show / hide filters
       all possible config values (all values are defaulted to false): {
         emailSchedulerMode: false,
         showReportsSelector: false,
         showHeader: false,
         showDateRangeFilter: false,
         showNDaysFilter: false,
         showRollupFilter: false,
         showTimezoneFilter: false,
         showVmNameFilter: false,
         showObjectTypeFilter: false,
         showTargetTypeFilter: false,
         showTargetNameFilter: false,
         showSingleSelectRegisteredSourceFilter: false,
         showRegisteredSourceFilter: false,
         enableTypeAhead: false,
         showViewBoxFilter: false,
         showReportTimeSpanFilter: false,
         showStatusFilter: false,
         showConsecutiveFailuresFilter: false,
         showApplyFiltersBtn: false,
         datasetPromiseObject: {
          if you need the control panel to load data for the filters, define
          those functions here.
         },
         typeAheadLabel: the label for the typeAhead
         typeAheadDatasetKey: ('jobId', 'objectId' the filter key you want
         the typeahead to set)
       }
       */
      config: '<',

      // this is an optional value, some reports will pass in their own datasets
      // for the filters to use
      dataset: '<?',

      // this is an optional value, some reports will need to defined a callback
      // function so they can updated the report according to the new filters
      // selected
      applyFilters: '&?',
    },
  };

  angular
    .module('C.reportsControls', ['C.reportsControlsConstants',
      'C.reportsControlsUtil'])
    .controller('ReportsControlsCtrl', ReportsControlsCtrlFn)
    .component('cReportsControls', options);

  function ReportsControlsCtrlFn(_, moment, $scope, $state, $q,
    cReportsControlsUtil, evalAJAX, NavStateService, ExternalTargetService,
    ReportsUtil, ENV_TYPE_CONVERSION, FEATURE_FLAGS) {

    var $ctrl = this;

    var config;

    /**
     * inits values and gets necessary data for component to function correctly
     */
    $ctrl.$onInit = function $onInit() {
      config = $ctrl.config;

      assign($ctrl, {
        changeSelectedReport: changeSelectedReport,
        getNdaysFilterLabel: getNdaysFilterLabel,
        selectFromDate: selectFromDate,
        selectObjectType: selectObjectType,
        selectRegisteredSources: selectRegisteredSources,
        selectStatusFilter: selectStatusFilter,
        selectTargetNames: selectTargetNames,
        selectTargetTypes: selectTargetTypes,
        selectTypeaheadValue: selectTypeaheadValue,
        selectUntilDate: selectUntilDate,
        selectViewBox: selectViewBox,
        submitReportFilters: submitReportFilters,
        selectMultiTypeAheadValue: selectMultiTypeAheadValue,

        objectTypes: [{ name: 'All', type: 0, }]
          .concat(NavStateService.getObjectTypes()),
        targetTypes: ExternalTargetService.getExternalTargetTypesList(),
        rollups: ['day', 'week', 'month'],

        // For external target reports.
        groupByReportOptions: [{
          translateKey: 'target',
          value: 'kTarget',
        }, {
          translateKey: 'protectionJob',
          value: 'kProtectionJob',
        }],

        // sets filters on $ctrl value to be used in template
        filters: cReportsControlsUtil.filters,
        reportFilterOptions: cReportsControlsUtil.getReportsSelectorOptions(),
      });



      // the component is loading the data necessary for filters to work
      // correctly this is necessary because the control panel can be used in
      // places where it has no context of data, so it should handle getting its
      // own data if necessary
      if (config.datasetPromiseObject && !$ctrl.dataset) {
        $q.all(config.datasetPromiseObject).then(
          function getDatasets(dataset) {
            $ctrl.dataset = dataset;
          }, evalAJAX.errorMessage);
      }

      // if we are going directly to a report through an absolute url we need
      // to get the selectedReport for the scheduler modal and to correctly
      // display the selected report in the control panel
      if (!$ctrl.selectedReport) {
        $ctrl.selectedReport = $ctrl.reportFilterOptions.find(
          function findReport(report) {
            return report.value === $state.current.name;
          });
      }

      angular.extend($ctrl.filters, {
        reportType: $ctrl.filters.reportType || $ctrl.selectedReport.type,
        typeAhead: $ctrl.filters.typeAhead || {},
        runStatus: $ctrl.filters.runStatus || [],
        multiSelectTypeAhead: $ctrl.filters.multiSelectTypeAhead || [],
        registeredSources: $ctrl.filters.registeredSources || [],
      });

      // if we are using the date picker range filter call the function
      // to set default values
      if (config.showDateRangeFilter) {
        initTimeFilterValues();
      }

      initFilterValues();
    };

    /**
     * some filter values need to be updated according to the report config
     */
    function initFilterValues() {
      // if the lastNDays filter is not defined, it can be safely assumed
      // that the date range filter is being used so we should get the date
      // range difference
      if (!$ctrl.filters.lastNDays && config.showReportTimeSpanFilter) {
        getDateRangeDifference();
      }

      if (config.showStatusFilter) {
        initStatusFilterValues();
      }

      // when using the email-scheduler we dont need to show the lastNDays
      // filter and timeSpanFilter at the same time they're essentially the
      // same thing, reportTimeSpanFilter should take precedence
      if (config.showNDaysFilter &&
        config.showReportTimeSpanFilter) {

        config.showNDaysFilter = false;
      }

      // If multiSelectTypeAhead contains values, populate object ids from it.
      selectMultiTypeAheadValue($ctrl.filters.multiSelectTypeAhead);

      // If Registered Sources contain values, populate registeredSourceId or
      // registeredSourceIds depending upon whether single select or
      // multiselect is being used.
      if ($ctrl.filters.registeredSources) {
        selectRegisteredSources($ctrl.filters.registeredSources);
      }

      // If typeahead has a value, populate job id and job name from it.
      if ($ctrl.filters.typeAhead) {
        selectTypeaheadValue($ctrl.filters.typeAhead);
      }
    }

    /**
     * initiates callback function to report controller to update report data
     */
    function submitReportFilters() {
      if (config.enableTypeAhead) {
        updateSelectedTypeaheadFilters();
      }

      $ctrl.applyFilters({ filters: $ctrl.filters });
    }

    /**
     * gets the time difference in days between 2 date objects, this method is
     * mainly used when the email scheduler modal is opened
     *
     */
    function getDateRangeDifference() {
      $ctrl.filters.lastNDays =
        $ctrl.filters.timeObject.until.diff($ctrl.filters.timeObject.from, 'days');
    }

    /**
     * sets the defaults for the run status filter
     */
    function initStatusFilterValues() {
      $ctrl.jobStatus = {
        all: !FEATURE_FLAGS.reportsDefaultJobStatusErrorEnabled && !$ctrl.filters.runStatus.length,
        kBackupJobSuccess: $ctrl.filters.runStatus.includes('kBackupJobSuccess'),
        kBackupJobRunning: $ctrl.filters.runStatus.includes('kBackupJobRunning'),
        kBackupJobFailure: FEATURE_FLAGS.reportsDefaultJobStatusErrorEnabled || $ctrl.filters.runStatus.includes('kBackupJobFailure'),
        kBackupJobCanceled: $ctrl.filters.runStatus.includes('kBackupJobCanceled'),
      };
    }

    /**
     * when a new run status is selected this method is called to update
     * the selected filter properly
     *
     * @param    {string}    status    the kValue of the selected run status
     */
    function selectStatusFilter(status) {
      if (status === 'all') {
        $ctrl.jobStatus.kBackupJobSuccess = false;
        $ctrl.jobStatus.kBackupJobRunning = false;
        $ctrl.jobStatus.kBackupJobFailure = false;
        $ctrl.jobStatus.kBackupJobCanceled = false;
      } else {
        $ctrl.jobStatus.all = false;
      }

      updateSelectedStatusFilters(status);
    }

    /**
     * after a new run status is selected we need to update the filters.runStatus
     * property. if the status is true we add it to the array if its false
     * we remove it.
     *
     * @param    {string}    status    kValues of the selected run status
     */
    function updateSelectedStatusFilters(status) {
      var index;

      if (status === 'all') {
        $ctrl.filters.runStatus.length = 0;

        return;
      }

      // if the selected status is true add it to our run status
      // array, else get the indexOf the status and remove it from
      // runStatus array
      if ($ctrl.jobStatus[status] && status !== 'all') {
        $ctrl.filters.runStatus.push(status);
      } else if (!$ctrl.jobStatus[status]) {
        index = $ctrl.filters.runStatus.indexOf(status);

        $ctrl.filters.runStatus.splice(index, 1);
      }
    }

    /**
     * when selecting a registered source from the dropdown filter this method
     * will updated the $ctrl.filters object accordingly
     *
     * @param    {array}    sources    array of objects of the source that
     *                                 has been selected.
     */
    function selectRegisteredSources(sources) {
      sources = [].concat(sources);

      // ensure the model property gets updated so changes from the controller
      // are reflected in the view. This function can be called when object
      // type (environment) filter is used.
      $ctrl.filters.registeredSources =
        $ctrl.config.showSingleSelectRegisteredSourceFilter ?
          sources[0] || undefined : sources;

      sources = sources.map(function mapSources(source) {
        return source.id || source.value.id;
      });

      // Backend requires Protection Summary by Object Type report to send array of ids instead.
      if ($ctrl.config.showSingleSelectRegisteredSourceFilter &&
        $ctrl.selectedReport.type !== 'kProtectionSummaryByObjectTypeReport') {
        $ctrl.filters.registeredSourceIds = undefined;
        $ctrl.filters.registeredSourceId = sources[0];

      } else {
        $ctrl.filters.registeredSourceIds = sources;
        $ctrl.filters.registeredSourceId = undefined;
      }
    }

    /**
     * updates $ctrl.filters with the selected value from the
     * multiSelectTypeAheadValue
     *
     * @param     {Array}    items    array of objects of selected values
     */
    function selectMultiTypeAheadValue(items) {
      var ids = [];

      ids = items.map(function mapItems(item) {
        return Number(item.id);
      });

      $ctrl.filters[config.multiSelectTypeAheadDatasetKey] = ids;
    }

    /**
     * Updates $ctrl.filters with the selected value from the typeAhead
     *
     * @param    {object}    item     details of the selected item
     */
    function selectTypeaheadValue(item) {
      if (item) {
        $ctrl.filters[config.typeAheadDatasetKey] = item.id;
      } else {
        $ctrl.filters[config.typeAheadDatasetKey] = undefined;
      }
    }

    /**
     * Will show or hide parentSourceFilter based off the Object Type that
     * was selected. Will also set selectedFilters with the 'Object Type'
     * that was selected. If a valid Object Type was selected it wall call
     * getSources and getJobs to populate the dropdown and typeAhead with
     * the correct data.
     *
     * @param  {object} item  the object type that was selected ex:
     *                        {
     *                          name: 'VMWare',
     *                          type: 'kVMware',
     *                          id: 1
     *                        }
     */
    function selectObjectType(item) {
      var promiseObject = {};
      var registeredSources;

      if (item.name === 'all') {
        config.showSingleSelectRegisteredSourceFilter = false;

        // reset filters so empty params are sent to api
        $ctrl.filters.objectType = { name: 'All' };
        $ctrl.filters.typeAhead = {};
        $ctrl.filters.registeredSourceIds = [];
      } else {
        config.showSingleSelectRegisteredSourceFilter = true;
        $ctrl.filters.objectType = item;

        // If registered sources are set, ensure they match the selected
        // environment. This is only relevant if registeredSource filters
        // and object type filters are exposed on the same form.
        if ($ctrl.filters.registeredSources) {
          registeredSources =
            [].concat($ctrl.filters.registeredSources || []).filter(
              function filterEnv(source) {
                return ENV_TYPE_CONVERSION[source.value.type] === item.enum;
              }
            );

          selectRegisteredSources(registeredSources);
        }


        promiseObject.registeredSources = ReportsUtil.getSources({
          envTypes: item.type,
        }, false);

        promiseObject.typeAheadData = ReportsUtil.getJobs({
          envTypes: item.type
        });

        $q.all(promiseObject).then(function getData(data) {
          $ctrl.dataset.typeAheadData = data.typeAheadData;
          $ctrl.dataset.registeredSources = data.registeredSources;
        }, evalAJAX.errorMessage);
      }
    }

    /**
     * maps the selected targetTypes to return the enum of each type and calls
     * a method that will filter the targetNames list
     *
     * @param    {array}    targetTypes    array of targetType objects that have
     *                                     been selected
     */
    function selectTargetTypes(targetTypes) {
      var targetTypeEnums = targetTypes.map(function mapTargetTypes(target) {
        return target.enum;
      });

      filterExternalTargetsList(targetTypeEnums);
    }

    /**
     * filters our externalTargets array according to the selected targetTypes
     *
     * @param    {array}    targetEnums    array of target enums that have
     *                                     been selected
     */
    function filterExternalTargetsList(targetEnums) {
      var filteredTargets = [];

      // we need to maintain an original copy of our external targets list
      // so if user clears all filters we can reset to default and get a report
      // for all targets
      if ($ctrl.dataset.externalTargets && !$ctrl.dataset.externalTargetsCopy) {
        $ctrl.dataset.externalTargetsCopy =
          angular.copy($ctrl.dataset.externalTargets);
      }

      if (targetEnums.length) {
        filteredTargets = $ctrl.dataset.externalTargetsCopy.filter(
          function filterTargets(target) {
            return targetEnums.includes(target._tierData.targetType);
          });

        $ctrl.dataset.externalTargets = filteredTargets;
      } else {
        $ctrl.dataset.externalTargets = $ctrl.dataset.externalTargetsCopy;
      }

      selectTargetNames(filteredTargets);
    }

    /**
     * maps the selected targetNames to return the id of each target / vault
     * selected
     *
     * @param    {array}    targetNames    array of targetName objects that
     *                                     have been selected
     *
     * @param   {Boolean}   applyFilters   forces an applyFilters call without
     *                                     clicking on the button
     */
    function selectTargetNames(targets) {
      if (targets.length) {
        // if a targetName is selected get the vaultIds
        $ctrl.filters.vaultIds = targets.map(
          function maptargets(target) {
            return target.id;
          });
      } else if (!targets.length && !$ctrl.dataset.externalTargets.length) {
        // if no targets are found set vaultId filter to empty array
        $ctrl.filters.vaultIds = [];
      } else {
        // resets filters to the default where all vaultIds are selected
        $ctrl.filters.vaultIds = $ctrl.dataset.externalTargetsCopy.map(
          function mapTargets(target) {
            return target.id;
          });
      }

      // if we are filtering target types while in the email scheduler flow
      // we do not need to apply filters for report data to refresh
      if (!config.emailSchedulerMode) {
        submitReportFilters();
      }
    }

    /**
     * sets our default values for the c-date-timepicker directive
     */
    function initTimeFilterValues() {
      $ctrl.fromInitDate =
        $ctrl.filters.timeObject.from || moment().subtract(7, 'days').startOf('day').format();

      $ctrl.untilInitDate =
        $ctrl.filters.timeObject.until || moment().endOf('day').format();

      $ctrl.today = moment().format();

      $ctrl.maxFromDate = $ctrl.untilInitDate;
      $ctrl.minUntilDate = $ctrl.fromInitDate;
    }

    /**
     * when selecting a new from data update filter values accordingly
     *
     * @param  {object}    date    date object
     */
    function selectFromDate(date) {
      $ctrl.filters.timeObject.from = moment(date).startOf('day');

      // update the minimum date the 'until' datepicker is allowed to choose
      $ctrl.minUntilDate = moment(date).startOf('day').format();
    }

    /**
     * when selecting a new until date updated filter values accordingly
     * @param    {object}    date    date object
     */
    function selectUntilDate(date) {
      $ctrl.filters.timeObject.until = moment(date).endOf('day');

      // update the maximum date the 'from' datepicker is allowed to choose
      $ctrl.maxFromDate = moment(date).startOf('day').format();
    }

    /**
     * when a new report is selected go to that newly selected report
     *
     * @param    {object}    selectedReport    object that contains the state
     *                                         name so we can go to the
     *                                         correct report
     */
    function changeSelectedReport(selectedReport) {
      var config;
      var parent = $scope;

      if (selectedReport.type) {
        $ctrl.filters.reportType = selectedReport.type;
      }

      // Find the parent reports state in order to access the tab configuration
      // which it manages.
      while (!parent.reportsTabConfig && parent.$parent) {
        parent = parent.$parent;
      }
      if (parent.reportsTabConfig) {
        config = parent.reportsTabConfig.find(function findTab(config) {
          return config.headingKey === 'reports';
        });

        // Dynamically set the tab route after user selection so that it
        // always goes back to the same report when clicking on the Reports tab
        if (config) {
          config.route = selectedReport.value;
        }
      }

      $state.go(selectedReport.value);
    }

    /**
     * when a viewBox is selected find it by id in the viewBoxes dataset and
     * set the found values on the filters object
     *
     * @param    {Number}    id    id of viewBox
     */
    function selectViewBox(viewbox) {
      $ctrl.filters.viewBox = {
        viewBoxId: viewbox.value,
        name: viewbox.name,
      };
    }

    /**
     * There are edge cases for a how a user can input info into the typeahead.
     * this function will normalize those edge cases
     */
    function updateSelectedTypeaheadFilters() {
      const foundItem = $ctrl.dataset.typeAheadData.find(function findItem(data) {
        return data.name === $ctrl.filters.typeAhead.name;
      });
      // if the  user types in a job name without selecting it from the
      // typeahead we need to manually set the filter values
      if ($ctrl.filters.typeAhead.name &&
        !$ctrl.filters.typeAhead.id) {
        $ctrl.filters[config.typeAheadDatasetKey] =
          foundItem ? foundItem.id : -1;
      }
      $ctrl.filters.typeAhead.viewId = foundItem ? foundItem.viewId : undefined;
      // reset typeahead.id to undefined if the user clears search input
      // which allows the user to get All jobRuns
      if (!$ctrl.filters.typeAhead.name) {
        $ctrl.filters[config.typeAheadDatasetKey] = undefined;
      }
    }

    /**
     * the lastNDays filter can have different labels according to the report
     * its being used in, this will return the correct ui.json string
     *
     * @param     {object}   selectedReport    object that contains the
     *                                         currently selectedReport values
     *
     * @return    {string}                     ui.json string to be translated
     */
    function getNdaysFilterLabel(selectedReport) {
      var reportStateName = selectedReport.value;
      if (!['reports.storage-viewboxes', 'reports.storage-growth'].includes(
        reportStateName)) {
        return 'reportsControls.nDaysVariance';
      }

      if (reportStateName === 'reports.storage-growth') {
        return 'reportsControls.nDaysGrowthAndVariance';
      }

      if (reportStateName === 'reports.storage-viewboxes') {
        return 'reportsControls.nDaysGrowth';
      }
    }
  }
})(angular);
