import { assign } from 'lodash-es';
// Component: App view privileges

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .component('appViewPrivileges', {
      templateUrl: 'app/apps-management/app-view-privileges/app-view-privileges.html',
      controller: appViewPrivilegesCtrlFn,
      bindings: {
        // @type   {object}   list of views
        views: '<',

        // @type   {function}   Callback to call when remove is clicked
        onRemove: '<',

        // @type   {string}   title of the list
        heading: '@'
      },
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:appViewPrivileges
   * @function appViewPrivilegesCtrlFn
   *
   * @description
   * Provides list of views
   *
   * @example
     <app-view-privileges
       views="$ctrl.views"
       on-remove="$ctrl.remove">
     </app-view-privileges>
   */
  function appViewPrivilegesCtrlFn(_) {
    var $ctrl = this;

    assign($ctrl, {
      defaultViewShowCount: 5,
      showAll: false,

      toggleViewAll: toggleViewAll,
    });

    /**
     * Toggles showing all views
     *
     * @method   toggleViewAll
     */
    function toggleViewAll() {
      $ctrl.showAll = !$ctrl.showAll;
    }
  }
})(angular);
