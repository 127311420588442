import { identity } from 'lodash-es';
import { get } from 'lodash-es';
// Service: Database Actions

(function _iife(angular) {
  'use strict';

  angular.module('C.sqlDashboard')
    .service('DbActionsService', DbActionsServiceFn);


  /**
   * @ngdoc service
   * @name C.sqlDashboard.DbActionsService
   *
   * @description
   * Generates a list of actions that can be executed on database actions.
   *
   * @param     {Object}   _                   Lodash.
   * @param     {Object}   $rootScope          $rootScope.
   * @param     {Object}   $state              $state.
   * @param     {Object}   $timeout            $timeout.
   * @param     {Object}   cMessage            CMessage.
   * @param     {Object}   cModal              CModal.
   * @param     {Object}   evalAJAX            EvalAJAX.
   * @param     {Object}   JobService          JobService.
   * @param     {Object}   FEATURE_FLAGS       FEATURE_FLAGS.
   * @param     {Object}   JobRunsService      JobRunsService.
   * @param     {Object}   PubSourceService    PubSourceService.
   * @returns   {Object}   The Service's API.
   */
  function DbActionsServiceFn(
    _, $rootScope, $state, $timeout, cMessage, cModal, evalAJAX, JobService,
    FEATURE_FLAGS, JobRunsService, PubSourceService) {
    return {
      getDbActions: getDbActions,
    };

    /**
     * Initializes modal config and options. Opens modal for backup now flow.
     *
     * @function   startBackupNowFlow
     * @param      {Object}     job          Job object from public api with all
     *                                       job details.
     * @param      {Object}     [node]       The node selected.
     * @param      {Function}   [callback]   Function to be executed on success.
     * @return     {Object}     Promise containing the modal's resolve & reject
     *                          response.
     */
    function startBackupNowFlow(job, node, callback) {
      var modalConfig = {
        size: 'lg',
        templateUrl: 'app/protection/jobs/modals/job-backup-now.html',
        controller: 'JobBackupNowController',
        resolve: {
          nodeId: function nodeId() {
            return get(node, 'protectionSource.id');
          },
          jobId: function jobId() {
            return job.id;
          },
          clusterId: function clusterId() {
            return job.uid.clusterId;
          },
        },
      };

      var windowOptions = {
        actionButtonKey: false,
        closeButtonKey: false,
        titleKey: 'backupNowModal.header',
        titleKeyContext: job,
      };

      return cModal.standardModal(modalConfig, windowOptions)
        .then(callback || angular.noop);
    }

    /**
     * Interfaces JobService to pause or resume future Job Runs.
     *
     * @param      {Object}    job       JobDescription.
     * @param      {boolean}   pause     If true, pause the job, else resume it.
     * @param      {Function}  callback  Function to be executed on success.
     */
    function pauseorResumeJob(job, pause, callback) {
      var windowOptions;
      var successKey = 'jobActionService.pauseJobSuccess';
      var titleKey = 'databaseActions.pauseRuns.title';
      var actionButtonKey = 'databaseActions.pauseRuns.continue';
      var contentKey = 'databaseActions.pauseRuns.text';
      var jobConfig = {
        backupJob: {
          jobId: job.id,
          isPaused: pause,
        },
      };

      if (!pause) {
        successKey = 'jobActionService.resumeJobSuccess';
        titleKey = 'databaseActions.resumeRuns.title';
        actionButtonKey = 'databaseActions.resumeRuns.continue';
        contentKey = 'databaseActions.resumeRuns.text';
      }


      windowOptions = {
        titleKey: titleKey,
        actionButtonKey: actionButtonKey,
        closeButtonKey: 'cancel',
        contentKey: contentKey,
        contentKeyContext: {
          name: job.name,
        },
      };

      cModal.standardModal({}, windowOptions).then(function then() {
        JobService.pauseOrResumeJob(jobConfig).then(
          function onSuccess() {
            cMessage.success({
              textKey: successKey,
            });
            (callback || angular.noop)();
          },
          evalAJAX.errorMessage);
      });
    }

    /**
     * Interfaces JobRunsService to request the cancelation of a job run.
     * Presents a confirmation modal before submitting request.
     *
     * @param      {Object}    job       To be canceled.
     * @param      {Function}  callback  Function to be executed on success.
     */
    function cancelJobRun(job, callback) {
      var windowOptions = {
        titleKey: 'jobActionService.cancelModal.title',
        actionButtonKey: 'jobActionService.cancelModal.cancelJobRun',
        closeButtonKey: 'jobActionService.cancelModal.cancel',
        contentKey: 'databaseActions.cancelModal.content',
        contentKeyContext: {
          name: job.name,
        },
      };

      cModal.standardModal({}, windowOptions).then(function then() {
        JobRunsService.cancelJobRun(
          job.id, job.lastRun.backupRun).then(
          function cancelJobSuccess() {
            cMessage.success({
              textKey: 'jobActionService.cancelJobRunSuccess',
            });
            (callback || angular.noop)();
          },
          evalAJAX.errorMessage
        );
      });
    }

    /**
     * Launches the protection workflow for a database. This initializes the
     * page with the parent source of the database selected.
     *
     * @param    {number}   parentSourceId   The parent source id of the object.
     * @param    {Object}   db               The database to protect.
     */
    function protectDb(parentSourceId, db) {
      PubSourceService.getSourcesInfo({
        ids: [parentSourceId],
      }, 'kSQL').then(
        function protect(source) {
          var rootNode = source.rootNodes[0];

          // If the source is vmware, then we need to auto select the host
          // system when we attempt to protect it. Otherwise, we can select only
          // the database.
          var protectSources = [rootNode &&
            rootNode.protectionSource.environment === 'kVMware' ?
            rootNode.protectionSource : db.protectionSource];

          $state.go('job-modify', {
            parentSourceId: rootNode ? rootNode.protectionSource.id : undefined,
            environments: ['kSQL'],
            parentSourceEnvironment: 'kSQL',
            protectSources: protectSources,
          });
        }
      );
    }

    /**
     * Get Database Actions for a database. Includes protected, run, pause,
     * resume, cancel, clone, and recover.
     *
     * @param    {Object}     db                 Db node, with decorated with a
     *                                           _job property.
     * @param    {function}   [reloadCallback]   Callback to execute after task.
     * @return   {Array}      Array of context tasks.
     */
    function getDbActions(db, reloadCallback) {

      var actions = [];
      var job = db._job;

      // If not protected, Add the protect database action
      if (!db._isProtected && $rootScope.user.privs.PROTECTION_MODIFY) {
        actions.push({
          translateKey: 'protect',
          icon: 'icn-protect',
          id: 'protect-db-anchor',
          action: function protectWrapper() {
            protectDb(db._appProtectionSource.id, db);
          },
        });
      }

      // If protected, add tasks to start, cancel, pause, or resume jobs.
      if (db._job &&
        !db._job.isDeleted &&
        $rootScope.user.privs.PROTECTION_JOB_OPERATE) {
        var jobAtRest = !job.lastRun ||
          !['kRunning', 'kCancelling', 'kAccepted']
            .includes(job.lastRun.backupRun.status);
        var canStart = job.isActive && !job.isPaused && jobAtRest;
        var canPause = !job.isPaused && job.isActive;
        var canResume = job.isPaused;
        var canCancel = job.lastRun && ['kAccepted', 'kRunning']
          .includes(job.lastRun.backupRun.status);


        if (canStart) {
          actions.push({
            translateKey: 'jobActionService.runNow',
            icon: 'icn-run-now',
            id: 'run-job-now-anchor',
            action: function startJobWrapper() {
              startBackupNowFlow(job, db, function delayReload() {
                $timeout(reloadCallback, 2500);
              });
            },
          });
        }

        if (canPause) {
          actions.push({
            translateKey: 'jobActionService.pauseFutureRuns',
            icon: 'icn-pause',
            id: 'pause-future-runs-anchor',
            action: function pauseJobWrapper() {
              pauseorResumeJob(job, true, reloadCallback);
            },
          });
        }

        if (canResume) {
          actions.push({
            translateKey: 'jobActionService.resumeFutureRuns',
            icon: 'icn-play',
            id: 'resume-future-runs-anchor',
            action: function resumeJobWrapper() {
              pauseorResumeJob(job, false, reloadCallback);
            },
          });
        }

        if (canCancel) {
          actions.push({
            translateKey: 'jobActionService.cancelJobRunToolTip',
            icon: 'icn-cancel',
            id: 'cancel-job-run-anchor',
            action: function cancelJobRunWrapper() {
              cancelJobRun(job, function delayReload() {
                $timeout(reloadCallback, 2500);
              });
            },
          });
        }
      }

      // Add clone options
      if (db._latestSnapshot && db._snapshotJob) {
        actions.push(
          db._snapshotJob._envParams.backupType !== 'kSqlNative' && {
            translateKey: 'clone',
            icon: 'icn-clone',
            id: 'clone-db-anchor',
            state: 'clone-db.options',
            stateParams: {
              dbType: 'sql',
              jobId: db._latestSnapshot._jobId,
              jobInstanceId: db._latestSnapshot.instanceId.jobInstanceId,
              entityId: db.protectionSource.id,
              sourceId: db._snapshotJob.parentSourceId,
            },
          },
          {
            translateKey: 'recover',
            icon: 'icn-recover',
            id: 'recover-db-anchor',
            state: 'recover-db.options',
            stateParams: {
              dbType: 'sql',
              jobId: db._latestSnapshot._jobId,
              jobUid: db._snapshotJob.uid,
              jobRunStartTime: db._latestSnapshot.snapshotTimestampUsecs,
              jobInstanceId: db._latestSnapshot.instanceId.jobInstanceId,
              entityId: db.protectionSource.id,
              sourceId: db._snapshotJob.parentSourceId,
            },
          },

          // DB Migration is only applicable to file based backups and non AAG dbs
          FEATURE_FLAGS.databaseMigration &&
            !db._isAagDb &&
            db._snapshotJob._envParams.backupType === 'kSqlVSSFile' && {
            translateKey: 'migrate',
            icon: 'icn-migrate',
            id: 'migrate-db-anchor',
            state: 'migrate-db.options',
            stateParams: {
              jobId: db._latestSnapshot._jobId,
              jobInstanceId: db._latestSnapshot.instanceId.jobInstanceId,
              entityId: db.protectionSource.id,
              sourceId: db._snapshotJob.parentSourceId,
            },
          }
        );
      }
      return actions.filter(identity);
    }
  }
})(angular);
