import { reduce } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Join Active Directory

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.activeDirectory')
    .controller('addAdController', JoinAdCtrlFn);

  // If this flow is instantiated as a modal, this var will hold the parent
  // state's context help id so we can restore it when the modal is closed.
  var parentHelpId;

  /**
   * Controller for the Join (Create New) view
   */
  function JoinAdCtrlFn($state, _, $log, evalAJAX, $timeout, cFocus,
    FEATURE_FLAGS, AD_ENCRYPTION_TYPES, ActiveDirectoryService, ClusterService,
    $uibModalInstance) {

    var $ctrl = this;

    // Default config object use to stub out model when creating.
    var defaultAdConfig = {
      domainName: undefined,
      encryption: 31,
      machineAccounts: [],
      overwriteExistingAccounts: undefined,
      password: undefined,
      preferredDomainControllers: [],
      trustedDomainsEnabled: false,
      userIdMapping: {},
      userName: undefined,
    };

    $ctrl.inModal = angular.isObject($uibModalInstance);

    if ($ctrl.inModal) {
      // Store the parent's context help ID so we can restore it later.
      parentHelpId = $state.current.help;

      // Set the context help ID for this modal.
      $state.current.help = 'admin_activedirectory';
    }

    assign($ctrl, {
      adConfig: {},
      clusterName: ClusterService.clusterInfo.name,
      domainControllers: [],
      encryptionTypes: AD_ENCRYPTION_TYPES,
      forms: {
        newAd: {},
      },
      invalidMachineNames: [],
      joiningDomain: false,
      newAdConfig: angular.copy(defaultAdConfig),
      selectedEncryptionTypes: {
        1: true,
        2: true,
        4: true,
        8: true,
        16: true,
      },

      // Methods
      $onInit: $onInit,
      fetchDomainControllers: fetchDomainControllers,
      addDomain: addDomain,
      finish: finish,
      machineNamesHandler: machineNamesHandler,
      onChangeEncryptionType: onChangeEncryptionType,
    });

    /**
     * Initializes the controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      if ($ctrl.clusterName && $ctrl.clusterName.length <= 15) {
        // Prepopulate with the name of cluster if 15 characters or fewer.
        machineNamesHandler($ctrl.clusterName);
      }

      cFocus('input-domain-name');
    }

    /**
     * fetch the domain controllers.
     *
     * @method   fetchDomainControllers
     * @param    {String}   value     The entered domainName
     */
    function fetchDomainControllers(domain){
      $ctrl.domainControllers = [];
      $ctrl.fetchingDomainControllers = true;

      // clear the previous selected domain controllers
      $ctrl.selectedDomainControllers = undefined;
      ActiveDirectoryService.fetchDomainControllers({domainName: domain})
        .then(function fetchDomainControllersSuccess(controllers) {
          // set domainControllers equals to new controllers
          $ctrl.domainControllers = controllers;
        }).catch(function fetchDomainControllersFailure() {
          $log.error('No domain controllers found for domain: ' + domain);
        }).finally(function fetchDomainControllersFinally() {
          $ctrl.fetchingDomainControllers = false;
        });
    }

    /**
     * Join an AD domain with the given configuration.
     *
     * @method     addDomain
     * @param      {Object}  config  AD config object
     */
    function addDomain(config) {
      if (!config || $ctrl.forms.newAd.$invalid) {
        return;
      }

      if (FEATURE_FLAGS.addAdPreferredDomainController) {
        config.preferredDomainControllers = [{
          domainName: $ctrl.newAdConfig.domainName,
          domainControllers: $ctrl.selectedDomainControllers,
        }];
      }

      $ctrl.joiningDomain = true;

      ActiveDirectoryService.addDomain(config).then(
        function addDomainSuccess(adConfig) {
          $ctrl.adConfig = adConfig;
          return $ctrl.finish('complete');
        },
        evalAJAX.errorMessage
      ).finally(
        function addDomainFinally() {
          $ctrl.joiningDomain = false;
        }
      );
    }

    /**
     * Passes through to a service method which handles validation of entered
     * tags.
     *
     * @method     machineNamesHandler
     * @param      {String}   value     The entered string value
     * @return     {Boolean}  returns false to notify ui-select not to add the
     *                        name
     */
    function machineNamesHandler(value) {
      return ActiveDirectoryService.machineNamesHandler(
        value,
        $ctrl.newAdConfig.machineAccounts,
        $ctrl.invalidMachineNames
      );
    }

    /**
     * Recalculate the encryption type value for the API-facing model.
     *
     * The encryption value is the sum of selected doubles in a sequence of
     * 1, 2, 4, 8, 16. Each double is the ID of one encryption type. If the
     * selected encryption types are 1 and 4, then the encryption value is 5.
     *
     * @method    onChangeEncryptionType
     * @returns   {Number}   Sum of all selected encryption type values.
     */
    function onChangeEncryptionType() {
      $ctrl.newAdConfig.encryption =
        reduce($ctrl.selectedEncryptionTypes, function(result, value, key) {
          return result += value ? +key : 0;
        }, 0);
    }

    /**
     * Cancels the create mode and either returns to main list or dismisses the
     * modal.
     *
     * @method     finish
     * @param      {String}  [reason='cancel']  describes reason for ending
     *                                          flow, 'cancel' or 'complete'
     */
    function finish(reason) {
      reason = reason || 'cancel';

      if ($ctrl.inModal) {
        switch (reason) {
          case 'cancel':
            $uibModalInstance.dismiss('cancel');
            break;
          case 'complete':
            $uibModalInstance.close($ctrl.adConfig);
            break;
        }

        // After closing the Add AD modal, restore context help ID to the
        // original value of the parent page.
        $state.current.help = parentHelpId;
      } else {
        // Need a slight pause before the next state issues a new GET call
        // because the Join Domain API response comes before the backend is done
        // updating cluster config.
        return $timeout($state.go('access-management.active-directory'), 300);
      }
    }
  }

})(angular);
