import { assign } from 'lodash-es';
// Module: Cloud Edition Delete Page

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('cloudEditionDelete', {
      bindings: {
        /**
         * cloudEdition object which is to be deleted
         *
         * @type   {String}
         */
        cloudEdition: '<',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      },
      controller: 'cloudEditionDeleteCtrl',
      templateUrl: 'app/platform/cloud-edition-manager/delete.html',
    })
    .controller('cloudEditionDeleteCtrl', cloudEditionDeleteCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:cloudEditionDelete
   * @function
   *
   * @description
   * Delete a Cloud Edition from cloud
   */
  function cloudEditionDeleteCtrlFn(_, $q, cMessage, evalAJAX, $timeout,
    $rootScope, CloudEditionManagerService) {

    var $ctrl = this;

    assign($ctrl, {
      submitButtonText: 'yes',
      confirmed: false,
      uploadConfig: {
        usePadding: true,
        icon: 'icn-license-file',
        onFileSelect: function onFileSelect(file) {
          $ctrl.file = file;
        }
      },

      // methods
      $onInit: $onInit,
      cancel: cancel,
      processForm: processForm,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      // If a task is 'in_progress' or 'scheduled' or 'failed' before reaching
      // aws, delete action is considered 'cancel'. If the task is finished,
      // then delete is considered actual delete.
      $ctrl.isCancel = !$ctrl.cloudEdition.canDelete;
    }

    /**
     * Submit the form to the server to delete the cloudEdition
     *
     * @method  processForm
     * @param   {object}  form   The form object to process
     */
    function processForm(form) {
      if (!form.$valid) {
        return false;
      }

      var fileReader;
      var fileDeferred = $q.defer();

      if (!$ctrl.isCancel && !$ctrl.confirmed) {
        $ctrl.confirmed = true;
        $ctrl.submitButtonText = 'delete';

        // reset the form validations for submit
        // not working without $timeout. not sure why
        $timeout(function setFormPristine() {
          form.$setPristine();
        });

      } else {
        $ctrl.submitting = true;

        // For GCP, read credentials from uploaded file
        if ($ctrl.cloudEdition.cloudType === 'gcp' && !$ctrl.isCancel) {
          fileReader = new FileReader();

          fileReader.onload = function fileLoaded(e) {
            $ctrl.accessCredentials = JSON.parse(fileReader.result);
            fileDeferred.resolve();
          };

          fileReader.onerror = function fileLoadFailed(err) {
            cMessage.error({
              title: 'errorReadingFile',
            });
          };

          fileReader.readAsText($ctrl.file);
        } else {
          fileDeferred.resolve();
        }

        fileDeferred.promise.then(function onFilePromiseResolve() {
          CloudEditionManagerService.deleteCloudEdition(
            $ctrl.cloudEdition.jobId, {
              accessCredentials: $ctrl.accessCredentials,
              email: [$rootScope.user.username],
            }).then(function deletedCloudEdition(resp) {
              $ctrl.cModalHoc.save(resp);

              cMessage.info({
                titleKey: 'cloudEdition.cloudEditionDeletedTitle',
                textKey: 'cloudEdition.cloudEditionDeletedMessage',
                textKeyContext: {
                  jobName: $ctrl.cloudEdition.jobName,
                  context: $ctrl.isCancel ? 'cancel' : 'delete',
                },
                timeout: 6000,
              });
            }, evalAJAX.errorMessage)
            .finally(function cloudEditionDeleteFinally() {
              $ctrl.submitting = false;
            });
        });
      }
    }

    /**
     * Cancel form submission and close the modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.cModalHoc.close();
    }
  }

})(angular);