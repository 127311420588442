import { Pipe, PipeTransform } from '@angular/core';
import { Environment } from '@cohesity/iris-shared-constants';

/**
 * This pipe returns icons based on environment.
 */
@Pipe({
  name: 'anomalyObjectIcon',
  pure: true,
})
export class AnomalyObjectIconPipe implements PipeTransform {
  /**
   * Evaluates the expression and returns Icon string
   *
   * @method   transform
   * @param    value     Environment
   * @return   Icon names
   */
  transform(value: Environment | string): string {
    let icon = 'helix:object-generic';
    switch (value) {
      case Environment.kVMware:
      case Environment.kHyperV:
        icon = 'helix:object-vm';
        break;
      case Environment.kView:
        icon = 'helix:object-view';
        break;
      case Environment.kNetapp:
      case Environment.kPure:
      case Environment.kIsilon:
        icon = 'helix:object-volume';
        break;
      case Environment.kSQL:
      case Environment.kOracle:
        icon = 'helix:database';
        break;
      case Environment.kPhysical:
        icon = 'helix:object-server';
    }
    return icon;
  }
}
