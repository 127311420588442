import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProtectionGroup, ProtectionGroups, ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { finalize, take } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { ComplianceDonutData } from '../sla-compliance-alt-card.service';
import { SeriesPieOptions } from 'highcharts';
import { TranslateService } from '@ngx-translate/core';

/**
 * Types and labels for alert legends (critical, warning, info).
 */
const alertLegends = [
  {
    type: 'totalMetSla',
    label: 'Met BCO',
  },
  {
    type: 'totalMissedSla',
    label: 'Missed BCO',
  },
];

/**
 * Generic data model for statistics data.
 */
class Stat {
  /**
   * Constructor.
   */
  constructor(public label: string, public type: string, public count: number) {}
}

/**
 * @description
 * SLA Compliance dashcard.
 * The card by default displays SLA status in the statlists.
 * If displayProtectionRuns is set to true, the card will display protection runs stats instead.
 *
 * @example
 *  <coh-sla-group-run-compliance></coh-sla-group-run-compliance>
 */
@Component({
  selector: 'coh-sla-group-run-compliance',
  templateUrl: './sla-group-run-compliance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sla-group-run-compliance.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SlaGroupRunComplianceComponent extends AutoDestroyable implements OnInit {
  /**
   * Title to be displayed on the card.
   */
  @Input() title = '';

  /**
   * SLA compliance data.
   */
  complianceDonutData: Partial<ComplianceDonutData>;

  /**
   * Display compliance stats.
   */
  stats = {
    totalMetSla: 0,
    totalMissedSla: 0,
  };

  /**
   * Data model for Legend (Met BCO, Missed BCO).
   */
  legends: Stat[] = [];

  /**
   * Total number of alerts.
   */
  totalAlerts = 0;

  /**
   * The health chart series data.
   */
  chartSeries: SeriesPieOptions = {
    type: 'pie',
    point: { events: {} },
    data: [],
  };

  /**
   * Flag for indicating data request.
   */
  isLoading = false;

  /**
   * Constructor.
   */
  constructor(
    private groupsService: ProtectionGroupServiceApi,
    private change: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    super();
  }

  /**
   * Initialize component and load stats data.
   */
  ngOnInit() {
    const params: ProtectionGroupServiceApi.GetProtectionGroupsParams = {
      pruneSourceIds: true,
      pruneExcludedSourceIds: true,
      isDeleted: false,
      includeTenants: true,
      includeLastRunInfo: true,
    };

    this.groupsService
      .GetProtectionGroups(params)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((resp: ProtectionGroups) => {
        const protectionsGroups = resp.protectionGroups || [];
        protectionsGroups.forEach((group: ProtectionGroup) => {
          const slaStatus = this.computeSlaStatus(group.lastRun);
          if (slaStatus) {
            const slaMet = slaStatus.status === 'kSuccess';
            this.stats[slaMet ? 'totalMetSla' : 'totalMissedSla']++;
          }
        });
        this.computeChartSeries();
        this.change.markForCheck();
      });
  }

  /**
   * Maps the provided data to the chart series
   * format required for displaying in a Donut chart.
   */
  private computeChartSeries() {
    alertLegends.forEach(({ label, type }) => {
      this.legends.push(new Stat(label, type, this.stats[type]));
      this.totalAlerts += this.stats[type];
    });

    this.chartSeries = {
      ...this.chartSeries,
      data: this.legends.map(({type, count, label}) => ({
        custom: {
          label: type,
          count: count,
          type: type,
        },
        name: label,
        y: count
      }))
    };
  }

  /**
   * Computes the SLA status based on the latest archival target result.
   * Logic Extracted from 'src/app/modules/protection-group-shared/models/protection-run.models';
   *
   * @param lastRun - The last run data object which may contain archival info.
   * @returns An object containing the SLA status ('kWarning' or 'kSuccess')
   * and type ('sla') if `isSlaViolated` is a boolean, otherwise `null`.
   */
  computeSlaStatus(lastRun) {
    let isSlaViolated;

    if (lastRun?.isCloudArchivalDirect) {
        // Validate presence of required properties for cloud archival.
        const archivalTargetResults = lastRun.archivalInfo?.archivalTargetResults;
        if (Array.isArray(archivalTargetResults) && archivalTargetResults.length > 0) {
            isSlaViolated = archivalTargetResults[0]?.isSlaViolated;
        }
    } else {
        // Use original or local backup information to determine SLA status.
        isSlaViolated = lastRun.originalBackupInfo?.isSlaViolated ?? lastRun.localBackupInfo?.isSlaViolated;
    }

    // Return the SLA status if isSlaViolated is a boolean.
    if (typeof isSlaViolated === 'boolean') {
        return {
            status: isSlaViolated ? 'kWarning' : 'kSuccess',
            type: 'sla',
        };
    }

    // Return an object with status undefined if no valid boolean was found.
    return {
        status: undefined,
        type: 'sla',
    };
  }
}
