import { differenceWith } from 'lodash-es';
import { countBy } from 'lodash-es';
import { filter } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { clone } from 'lodash-es';
import { uniqBy } from 'lodash-es';
// Service: apps Service
import { isAllClustersScope, isClusterScope } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';
  angular
    .module('C.appsManagement')
    .service('AppsService', AppsServiceFn);

  function AppsServiceFn(_, $http, $q, $log, API, AppsServiceFormatter, cModal,
    SlideModalService, $rootScope, Upload, UpgradeClusterService, $translate,
    NgIrisContextService, NgAppStateService) {

    let _appsCache = {};

    return {
      // Apps
      fetchApps: fetchApps,
      fetchAppsWithInstances: fetchAppsWithInstances,
      installApp: installApp,
      launchApp: launchApp,
      uninstallApp: uninstallApp,
      uploadAppPackage: uploadAppPackage,

      launchUploadAppModal: launchUploadAppModal,
      launchUpgradeAppModal: launchUpgradeAppModal,
      launchUninstallAppModal: launchUninstallAppModal,
      launchConfirmUninstallModal: launchConfirmUninstallModal,
      launchSelectClustersModal: launchSelectClustersModal,

      // App
      getApp: getApp,

      // App instances
      fetchAppInstances: fetchAppInstances,
      pauseAppInstance: pauseAppInstance,
      resumeAppInstance: resumeAppInstance,
      terminateAppInstance: terminateAppInstance,
      downloadAppInstanceLog: downloadAppInstanceLog,

      // Summary
      getAppInstancesSummary: getAppInstancesSummary,

      // track App
      fetchData: fetchData
    };


    /**
     * Initiates async fetch of the apps list
     * Upon receiving data, it transforms it into object usable for view.
     *
     * @method    fetchApps
     * @returns   {object}   Promise to return apps or error
     */
    function fetchApps() {
      // Use MCM API if we are not in cluster scope,
      // and there is no context switch happening to a cluster.
      let useMcmApi = !isClusterScope(NgIrisContextService.irisContext) &&
          !NgAppStateService.isSwitchingContext &&
          NgIrisContextService.irisContext?.accessClusterId !== NgAppStateService.clusterInTransition?.clusterId;
      let url = useMcmApi ?
        API.mcm('apps') : API.public('apps');
      return $http(
        {
          method: 'get',
          url: url,
        }
      ).then(AppsServiceFormatter.transformApps);
    }

    /**
     * Get app object using id and version.
     *
     * @method    getApp
     * @param     {string}   id        app id
     * @param     {string}   version   app version
     * @returns   {object}   Promise to return app or error
     */
    function getApp(id, version) {
      return _getAppCached(id, version, true /* forceRefresh */);
    }

    /**
     * Create apps cache using apps array.
     *
     * @param     {array}   apps   list of apps
     * @method    _buildAppsCache
     */
    function _buildAppsCache(apps) {
      _appsCache = apps.reduce(function reduceApps(cache, app) {
        cache[_appCacheKey(app.appId, app.version)] = app;
        return cache;
      }, {});
      $log.info('App cache refreshed', _appsCache);
    }


    /**
     * Get app object using id and version.
     * It will try to get it from cache, if cache misses,
     * backend call is made to refresh it.
     *
     * @method    _getAppCached
     * @param     {string}   id        app id
     * @param     {string}   version   app version
     * @returns   {object}   Promise to return app or error
     */
    function _getAppCached(id, version, forceRefresh) {
      var key = _appCacheKey(id, version);
      var app = _appsCache[key];

      if(!app || forceRefresh) {
        return fetchApps().then(function getApp(apps) {
          _buildAppsCache(apps);
          return _appsCache[key];
        });
      }

      // Cache is hit, but provide promise for consistency
      $log.info('App cache hit', key);
      return $q(function (resolve, reject) {
        resolve(app);
      });
    }

    /**
     * Buils key for app cache.
     *
     * @method    _appCacheKey
     * @param     {string}   id        app id
     * @param     {string}   version   app version
     * @returns   {object}   Promise to return apps or error
     */
    function _appCacheKey(id, version) {
      return id + '_' + version;
    }

    /**
     * Provides list of apps with number of active instances.
     * If instances information is not available, it defaults count to 0
     *
     * @method    fetchAppsWithInstances
     * @returns   {object}   Promise to return apps or error
     */
    function fetchAppsWithInstances() {
      let allClusterMode = isAllClustersScope(NgIrisContextService.irisContext);
      let promises = {
        apps: fetchApps(),

        // TODO(Mythri): Need to remove return of empty promise once active
        //               instances API is available via backend services.
        instances: allClusterMode ? $q.resolve() : fetchAppInstances(),
        remoteClusters: $rootScope.basicClusterInfo.mcmMode ?
          UpgradeClusterService.getMcmClustersForUpgrade() : $q.resolve(),
      };

      return $q.allSettled(promises).then(
        function updateSummary(result) {
          if(result.apps.$status == 'error') {
            return $q.reject(result.apps.resp);
          }

          var apps = result.apps.resp;
          var instances = result.instances.resp;
          var activeInstances = filter(instances, {_isActive: true});
          var countByAppUid = countBy(activeInstances, 'appUid');

          apps.forEach(function addInstanceCount(app) {
            app._numActiveInstances = countByAppUid[app.appId] || 0;

            // To show cluster details on which app is installed
            if (app.clusters) {
              app._remoteClusterList = _.cloneDeep(result.remoteClusters.resp);
              app._clusterList = _.differenceWith(
                app._remoteClusterList,
                app.clusters,
                function compare(remoteCluster, appCluster) {
                  return remoteCluster.clusterId === appCluster.clusterId;
                }
              );
              // Set _installed flag for the clusters where app is installed.
              app._clusterList = _.map(app._clusterList, function(o) { o._installed = true; return o; })
            }

            if (allClusterMode) {
              app._numClusters = (app._clusterList && app._clusterList.length) || 0;
              app.installState = app._clusterList && app._clusterList.length > 0 ?
                'kInstalled' : 'kPurchased';
              app._isEulaAccepted = true;
            }

          });

          return $q.resolve(apps);
        });
    }

    /**
     * Initiates app installation
     *
     * @method    installApp
     * @param     {string}   id        app id
     * @param     {string}   version   app version
     * @returns   {object}   Promise to check for errors
     */
    function installApp(id, version) {
      return $http({
        method: 'post',
        url: API.public('apps/' + id + '/versions/' + version),
      });
    }

    /**
     * Initiates app instance launch
     *
     * @method    launchApp
     * @param     {string}   id             app id
     * @param     {string}   version        app version
     * @param     {object}   launchParams   launch params collected from user
     * @returns   {object}   Promise to check for errors
     */
    function launchApp(id, version, launchParams) {
      return $http({
        method: 'post',
        data: AppsServiceFormatter.untransformLaunchParams(
          id, version, launchParams),
        url: API.public('appInstances'),
      });
    }

    /**
     * Initiates app uninstall
     *
     * @method    uninstallApp
     * @param     {string}   id        app id
     * @param     {string}   version   app version
     * @returns   {object}   Promise to check for errors
     */
    function uninstallApp(id, version) {
      return $http({
        method: 'delete',
        url: API.public('apps/' + id + '/versions/' + version),
      });
    }

    /**
     * Initiates async fetch of the app instances
     * Upon receiving data, it transforms it into object usable for view.
     *
     * @method    fetchAppInstances
     * @param     string     VIP address to access cluster.
     * @returns   {object}   Promise to return app instances or error
     */
    function fetchAppInstances(vip) {
      return $http({
        method: 'get',
        url: API.public('appInstances'),
      }).then(function fetchSuccess(data) {
        return AppsServiceFormatter.transformAppInstances(data, vip);
      });
    }

    /**
     * Initiates app instance pause
     *
     * @method    pauseAppInstance
     * @param     {string}   id   app instance id
     * @returns   {object}   Promise to check for errors
     */
    function pauseAppInstance(id) {
      return $http({
        method: 'put',
        data: {state: 'kPaused'},
        url: API.public('appInstances/' + id + '/states'),
      });
    }

    /**
     * Initiates app instance resume.
     *
     * @method    resumeAppInstance
     * @param     {string}   id   app instance id
     * @returns   {object}   Promise to check for errors
     */
    function resumeAppInstance(id) {
      return $http({
        method: 'put',
        data: {state: 'kRunning'},
        url: API.public('appInstances/' + id + '/states'),
      });
    }

    /**
     * Initiates app instance termination.
     *
     * @method    terminateAppInstance
     * @param     {string}   id   app instance id
     * @returns   {object}   Promise to check for errors
     */
    function terminateAppInstance(id) {
      return $http({
        method: 'put',
        data: {state: 'kTerminated'},
        url: API.public('appInstances/' + id + '/states'),
      });
    }

    /**
     * Download app instance log.
     *
     * @method    downloadAppInstanceLog
     * @param     {string}   id   app instance id
     */
    function downloadAppInstanceLog(id) {
      window.open(API.public('appInstances/' + id + '/logs'));
    }

    /**
     * Get the summary of the app instances
     *
     * @method    getAppInstancesSummary
     * @param     {object}   appInstances  list of appInstance
     * @returns   {object}   object containing summary
     */
    function getAppInstancesSummary(appInstances) {
      return {
        apps: uniqBy(appInstances, 'appName').length,
        totalInstances: filter(appInstances, (instance) => instance.state !== 'kTerminated').length,
        runningInstances: filter(appInstances, {
          state: 'kRunning'}).length,
        pausedInstances: filter(appInstances, {
          state: 'kPaused'}).length,
        notHealthyInstances: filter(appInstances, {
          state: 'kNotHealthy'}).length,
      };
    }

    /**
     * Upload app package to cluster for installation.
     *
     * @method     uploadAppPackage
     * @param   file   package file to be uploaded
     */
    function uploadAppPackage(file) {
      return Upload.upload({
        url: API.public('apps'),
        file: file,
        method: 'post',
        fileFormDataName: 'uploadFile',
      });
    }

    /**
     * launches app upload modal
     *
     * @method launchUploadAppModal
     * @param   {function}   closeFn   Function to call on window close.
     */
    function launchUploadAppModal(closeFn) {
      var modalConfig = {
        templateUrl: 'app/apps-management/app-upload/app-upload.html',
        controller: 'uploadAppCtrl',
      };
      var windowConfig = {
        actionButtonKey: undefined,
        closeButtonKey: undefined,
        titleKey: 'apps.upload.title',
        close: closeFn,
      };
      return cModal.standardModal(modalConfig, windowConfig);
    }

    /**
     * launches app upgrade modal
     *
     * @method launchUpgradeAppModal
     * @param {object}   app           app specific information
     */
    function launchUpgradeAppModal(app) {
      app._operationType = 'upgrade';

      var modalConfig = {
        autoHeight: true,
        size: 'md',
        resolve: {
          innerComponent: 'upgradeApp',
          titleKey: 'apps.upgrade.title',
          actionButtonKey: false,
          closeButtonKey: false,
          bindings: {
            app: app,
          },
          persistOnStateChanges: app._persistOnStateChanges,
        },
      };

      if ($rootScope.basicClusterInfo.mcmMode) {
        modalConfig = {
          size: 'md',
          resolve: {
            innerComponent: 'installApp',
            actionButtonKey: 'upgrade',
            titleKey: 'apps.selectClusterToUpgradeApp',
            bindings: {
              app: app,
            },
            persistOnStateChanges: app._persistOnStateChanges,
          },
        };
      }

      SlideModalService.newModal(modalConfig);
    }

     /**
     * launches app uninstall modal
     *
     * @method launchUninstallAppModal
     * @param {object}   app             app specific information
     */
    function launchUninstallAppModal(app) {
      app._operationType = 'uninstall';
      var modalConfig = {
        size: 'md',
        resolve: {
          innerComponent: 'installApp',
          actionButtonKey: 'uninstall',
          customTitle: $translate.instant('apps.selectClustersToUninstallApp', { appName: app.metadata.name }),
          bindings: {
            app: app,
          },
        },
      };
      SlideModalService.newModal(modalConfig);
    }


    /**
     * launche uninstall confirmation modal
     *
     * @method launchConfirmUninstallModal
     */
    function launchConfirmUninstallModal(app) {
      var options = {
        actionButtonKey: 'uninstall',
        closeButtonKey: 'cancel',
        titleKey: 'apps.confirmUninstallTitle',
        titleKeyContext: {name: app.metadata.name},
        contentKey: 'apps.confirmUninstallDetail',
      };

      // Show the modal.
      return cModal.standardModal({}, options);
    }

    /**
     * launch select clusters modal for app installation
     *
     * @method launchSelectClustersModal
     * @param {object} app   app specific information
     */
    function launchSelectClustersModal(app) {
      var modalConfig = {
        size: 'md',
        resolve: {
          innerComponent: 'clusterSelect',
          actionButtonKey: 'run',
          customTitle: $translate.instant('apps.selectClustersToRunApp', { appName: app.metadata.name}),
          bindings: {
            app: app,
          },
        },
      };
      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Fetch data from provided url from the API
     *
     * @method     fetchData
     * @param      {Object}  params  API required and optional params
     * @return     {Object}  Promise to resolve the API request
     */
     function fetchData(params,url,method,extractKey) {
      params = params || {};
      return $http({
        method: method || 'get',
        url,
        params: params
      }).then(
        function fetchDataSuccess(response) {
          var data = (response.data[extractKey] || []);
          return data;
        }
      );
    }
  }
})(angular);
