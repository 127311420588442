import { assign } from 'lodash-es';
// Break Auth Mapping Modal Controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.breakAuthMappingController', [])
    .controller('BreakAuthMappingController', breakAuthMappingControllerFn);

  function breakAuthMappingControllerFn(_, $uibModalInstance, LdapService,
    authData) {

    var $ctrl = this;

    assign($ctrl, {
      authData: authData,
      ldapProviderName:
        LdapService.getLdapProvider(authData.ldapProviderId).name,

      // Exposed methods
      cancel: cancel,
      save: save,
    });

    /**
     * Closes modal with resolved authData.
     */
    function save() {
      $uibModalInstance.close(authData);
    }

    /**
     * Dismisses modal without saving.
     */
    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})(angular);