import { Injectable } from '@angular/core';
import { PassthroughOptions } from '@cohesity/api/private';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateService } from '@uirouter/core';
import { cloudGroups, Environment, RecoveryAction } from 'src/app/shared';
import { AwsLeafType, AwsLeafTypes, CloudLeafToJobType } from 'src/app/shared/constants/cloud.constants';
import { AwsSourceDataNode } from 'src/app/shared/source-tree/protection-source/aws/aws-source-data-node';

import { ProtectionGroupService } from '../protection-group-shared';
import { RestorePointSelection } from '../restore/restore-shared';
import { ObjectActionCreator } from './object-action-creator';
import { SimpleObjectInfo } from './object-menu-provider';
import { ProtectedObjectsService } from './protected-objects.service';

/**
 * Helper class for creating object action items for AWS.
 */
@Injectable({
  providedIn: 'root',
})
export class AWSObjectActionCreator extends ObjectActionCreator {
  constructor(
    private awsObjectsService: ProtectedObjectsService,
    private awsProtectionGroupService: ProtectionGroupService,
    private awsStateService: StateService) {
    super(awsObjectsService, awsProtectionGroupService, awsStateService);
  }
  /**
   * Creates a protect object nav item
   *
   * @param objects          The objects being protected
   * @param selection        Most of the time, we can easily determine the selection based on
   *                         list of objects. Using sourceSelection will allow for more complex
   *                         configurations for tags, auto protect, etc...
   * @returns                The NavItem for AWS
   */
  createProtectAction(objects: SimpleObjectInfo[], selection?: SourceSelection): NavItem {
    const navItem = super.createProtectAction(objects, selection);
    navItem.stateParams.cloudJobType = this.getCloudStateParams(objects[0]);
    return navItem;
  }

  /**
   * Adds cloudJobType to navItem.stateParams in order to navigate to
   * another page for cases like RDS instances in AWS.
   * Support for Aurora and other types can be added in future.
   *
   * @param object The object currently selected.
   * @returns cloudjobType to be added to stateParams.
   */
  getCloudStateParams(object: SimpleObjectInfo) {
    if (cloudGroups.cloud.includes(object.environment) &&
        AwsLeafTypes.includes(object.objectType as AwsLeafType)) {
      return CloudLeafToJobType[object.objectType];
    }
    // In on-prem there is no workload.
    if ((object?.v1Object as AwsSourceDataNode)?.children?.[0].protectionSource?.
      awsProtectionSource?.type === AwsLeafType.kS3) {
        return CloudLeafToJobType[AwsLeafType.kS3];
    }
    if (object.workloadType && (object.workloadType in CloudLeafToJobType)) {
      return CloudLeafToJobType[object.workloadType];
    }
    return null;
  }

  /**
   * Creates a recover object nav item.
   *
   * @param type The type of the recovery.
   * @param simpleObject The simple object.
   * @param object The protected object.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createRecoverAction(
    type: RecoveryAction,
    simpleObject: SimpleObjectInfo,
    object?: ProtectedObject,
    restorePointSelection?: RestorePointSelection,
    objectOptions?: PassthroughOptions
  ): NavItem {
    const objectsArr = object ? [object] : null;
    const simpleObjectsArr = simpleObject ? [simpleObject] : null;
    switch (type) {
      case RecoveryAction.RecoverVMs:
        return this.createRecoverVmAction(objectsArr, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverRDS:
      case RecoveryAction.RecoverRDSPostgres:
      case RecoveryAction.RecoverAurora:
        return this.createRecoverRdsAction(simpleObjectsArr, objectsArr, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverS3:
        return this.createRecoverS3Action(simpleObjectsArr, objectsArr, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverFiles:
      case RecoveryAction.DownloadFilesAndFolders:
        // If a restorePointSelection is provided, do not return recover files
        // action as that is not supported with a specific restore point.
        return this.createRecoverFilesAction(simpleObject, type, restorePointSelection, objectOptions);
      case RecoveryAction.RecoverAwsDynamoDB:
          return this.createRecoveryDynamoDBAction
          (simpleObjectsArr, objectsArr, restorePointSelection, objectOptions);
    }
  }

  /**
   * Creates a recover file nav item for AWS object that will open the source for browsing.
   *
   * @param simpleObjects The simple objects being recovered.
   * @param objects The objects being recovered.
   * @param type The type of the recovery.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverFilesAction(
    simpleObject: SimpleObjectInfo,
    type: RecoveryAction.RecoverFiles | RecoveryAction.DownloadFilesAndFolders,
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {
    const action = super.createRecoverFilesAction(simpleObject, type, restorePointSelection, objectOptions);
    // We should set the first selected snapshot for file recovery to be a Native Snapshot.
    action.stateParams.objectActionKey = Environment.kAWSNative;
    return action;
  }

  /**
   * Creates and returns a recovery action for the supplied protected object, based on the supplied recovery type.
   *
   * @param type The type of the recovery.
   * @param objects The array of protected objects.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createBulkRecoverAction(
    type: RecoveryAction,
    objects?: ProtectedObject[],
    objectOptions?: PassthroughOptions
  ): NavItem {
    switch (type) {
      case RecoveryAction.RecoverVMs:
        return this.createRecoverVmAction(objects, null, objectOptions);
      case RecoveryAction.RecoverRDS:
      case RecoveryAction.RecoverRDSPostgres:
      case RecoveryAction.RecoverAurora:
        return this.createRecoverRdsAction(null, objects, null, objectOptions, type);
      case RecoveryAction.RecoverS3:
        return this.createRecoverS3Action(null, objects, null, objectOptions);
      case RecoveryAction.RecoverAwsDynamoDB:
        return this.createRecoveryDynamoDBAction(null, objects, null, objectOptions);
    }
  }

  /**
   * Creates a recover rds nav item
   *
   * @param simpleObjects The simple objects.
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @param type The type of the recovery.
   * @returns The NavItem
   */
  createRecoverRdsAction(
    simpleObjects?: SimpleObjectInfo[],
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
    type?: RecoveryAction,
  ): NavItem {
    // Do not allow multiple objects recovery except for Ingest recoveries.
    if (objects && objects.length > 1 && type !== RecoveryAction.RecoverRDSPostgres) {
      return;
    }

    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    for (let index = 0; index < restorePoints.length; index++) {
      // On the Activity page, protectedObject isn't set the first time backup completes, hence
      // objects is null when creating actions. So, we need to use simpleObject for protectionType.
      if (objects && index < objects.length) {
        restorePoints[index].objectInfo.protectionType = objects[index].protectionType;
      } else if (simpleObjects && index < simpleObjects.length) {
        restorePoints[index].objectInfo.protectionType = simpleObjects[index].objectActionKey;
      } else {
        return;
      }
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-rds-ng',
      stateParams: {
        objectInfo: (simpleObjects ?? objects ?? [])[0],
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      },
    } as any;
  }

  /**
   * Creates a recover S3 nav item
   *
   * @param simpleObjects The simple objects.
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoverS3Action(
    simpleObjects?: SimpleObjectInfo[],
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {}
  ): NavItem {
    // Do not allow multiple objects recovery.
    if (objects && objects.length > 1) {
      return;
    }

    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    for (let index = 0; index < restorePoints.length; index++) {
      // On the Activity page, protectedObject isn't set the first time backup completes, hence
      // objects is null when creating actions. So, we need to use simpleObject for protectionType.
      if (objects && index < objects.length) {
        restorePoints[index].objectInfo.protectionType = objects[index].protectionType;
      } else if (simpleObjects && index < simpleObjects.length) {
        restorePoints[index].objectInfo.protectionType = simpleObjects[index].objectActionKey;
      } else {
        return;
      }
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-s3-ng',
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      },
    } as any;
  }

  /**
   * Creates a recover DynamoDB nav item
   *
   * @param simpleObjects The simple objects.
   * @param objects The objects being recovered.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options
   * @returns The NavItem
   */
  createRecoveryDynamoDBAction(
    simpleObjects?: SimpleObjectInfo[],
    objects?: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {},
  ): NavItem {
    const restorePoints = this.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    // Get the source id from the selected object, required to fetch the KMS keys
    // when original source is selected.
    const selectedSourceId = (simpleObjects || objects || [])[0]?.sourceId;

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-aws-db-ng',
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        recoveryAction: RecoveryAction.RecoverAwsDynamoDB,
        selectedSourceId,
      },
    };
  }

  /**
   * Creates DB authorization action.
   *
   * @param action The action to perform on click of this button
   * @param isLocked If the node is unlocked or not.
   * @returns The Action nav item.
   */
  createDbAuthorizeAction(action: () => void, isLocked?: boolean): NavItem {
    return {
      displayName: 'databaseCredentials',
      icon: isLocked ? 'lock!outline' : 'lock_open',
      action,
    };
  }
}
