import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: data migration
import { nasSources } from 'src/app/shared/constants';
import { Workflow } from 'src/app/app-module.config';

(function _iife(angular, undefined) {
  'use strict';

  angular
    .module('C.dataMigration', [])
    .config(configFn)
    .controller('MigrationCtrl', MigrationCtrlFn);

  /**
   * State configuration.
   *
   * @method    configFn
   * @param     {object} $stateProvider   StateProvider.
   */
  function configFn($stateProvider) {
    $stateProvider
      .state('dataMigration', {
        url: '/platform/migration',
        help: 'platform_datamigration',
        title: 'Data Tiering',
        canAccess: (ctx) => {
          return (
            ctx.PROTECTION_VIEW &&
            ctx.canAccessSomeEnvItems(nasSources, Workflow.dataMigration) &&
            !ctx.clusterInfo._isDisaggregatedStorage
          );
        },
        templateUrl: 'app/platform/migration/migration-landing.html',
        controller: 'MigrationCtrl as $ctrl',
      });
  }

  /**
   * Data Migration Controller.
   *
   * @method    MigrationCtrlFn
   * @param     {object} _                                      _.
   * @param     {object} $state                                 State.
   * @param     {object} ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME.
   * @param     {object} ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME.
   * @param     {object} ENUM_BACKUP_JOB_STATUS                 ENUM_BACKUP_JOB_STATUS.
   * @param     {object} ENV_GROUPS                             ENV_GROUPS.
   * @param     {object} FEATURE_FLAGS                          FEATURE_FLAGS.
   * @param     {object} JobRunsService                         JobRunsService.
   * @param     {object} JobActionService                       JobActionService.
   * @param     {object} TenantService                          TenantService.
   * @param     {object} DateTimeService                        DateTimeService.
   */
  function MigrationCtrlFn(_, $state, $timeout,
    ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME,
    ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME,
    ENUM_BACKUP_JOB_STATUS, ENV_GROUPS,
    FEATURE_FLAGS,
    JobRunsService, JobActionService, TenantService, DateTimeService) {
    var $ctrl = this;

    assign($ctrl, {

      ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME:
        ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME,
      ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME:
        ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME,
      ENUM_BACKUP_JOB_STATUS: ENUM_BACKUP_JOB_STATUS,
      ENV_GROUPS: ENV_GROUPS,

      jobs: [],
      jobListReady: false,
      noJob: false,
      ngDataMigrationEnabled:
        FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
        FEATURE_FLAGS.ngProtectionGroupDataMigrationModify,

      filters: {
        activeJobFilter: null,
        jobTypeFilter: [],
        jobTenantFilter: [],
      },
      totals: {
        cancel: 0,
        error: 0,
        running: 0,
        success: 0,
        total: 0,
        warning: 0,
        active: 0,
      },

      filterListOptions: [
        {
          value: 'all',
          name: 'jobs.allJobs',
        },
        {
          value: 'active',
          name: 'jobs.activeJobs',
        },
        {
          value: 'inactive',
          name: 'jobs.inactiveJobs',
        },
        {
          value: 'deleted',
          name: 'jobs.deletedJobs',
        },
        {
          value: 'downtiering',
          name: 'downtieringJobs',
        },
        {
          value: 'uptiering',
          name: 'uptieringJobs',
        },
      ],

      $state: $state,

      // Component life-cycle methods.
      $onInit: $onInit,

      // methods
      loadData: loadData,
      translateSchedule: translateSchedule,
    });

    /**
     * Initializes component View.
     *
     * @method        $onInit
     */
    function $onInit() {
      loadData();
      buildGlanceBar();
    }

    /**
     * Load job list data.
     *
     * @method       loadData
     */
    function loadData() {
      var params = buildParams();
      $ctrl.jobListReady = false;
      JobRunsService.getJobSummary(params).then(
        function getJobSummarySuccess(jobs) {
          $ctrl.jobListReady = true;


          $ctrl.jobs = jobs.data.filter(function eachJob(job) {
            // Update Actions Menu Configuration
            job.backupJobSummary._actionsMenu = getActionsMenuConfig(job.backupJobSummary);

            if ($ctrl.filters.activeJobFilter === 'downtiering') {
              return job.backupJobSummary.jobDescription._isDownTieringJob;
            } else if ($ctrl.filters.activeJobFilter === 'uptiering') {
              return job.backupJobSummary.jobDescription._isUpTieringJob;
            }

            return true;
          });
        }
      ).catch(function (error) {
        $ctrl.noJob = true;
      });
    }

    /**
     * Build glance bar.
     *
     * @method      buildGlanceBar
     */
    function buildGlanceBar() {
      var runs = {
        numActiveRuns: 0,
        numSuccessfulRuns: 0,
        numErrorRuns: 0,
        numWarningRuns: 0,
        numCanceledRuns: 0,
      };
      var dayAgo = new Date(Date.clusterNow());
      var job;
      var jobRun;
      var runsParams;

      dayAgo.setDate(dayAgo.getDate() - 1);
      runsParams = {
        endTimeUsecs: DateTimeService.dateToUsecs(new Date(Date.clusterNow())),
        excludeTasks: true,
        numRuns: 1000,
        startTimeUsecs: DateTimeService.dateToUsecs(dayAgo),
        onlyReturnDataMigrationJobs: true,
      };

      JobRunsService.getJobRuns(runsParams).then(
        function getJobRuns(response) {
          angular.forEach(response, function loopJobs(job) {
            angular.forEach(job.backupJobRuns.protectionRuns,
              function loopRuns(jobRun) {
                switch (jobRun.backupRun.base._status) {
                case 2.1:
                  runs.numSuccessfulRuns++;
                  break;
                case 2.2:
                  runs.numErrorRuns++;
                  break;
                case 2.3:
                  runs.numWarningRuns++;
                  break;
                case 3:
                  runs.numCanceledRuns++;
                  break;
                default:
                  runs.numActiveRuns++;
                  break;
                }
            });
          });

          $ctrl.totals.success = runs.numSuccessfulRuns;
          $ctrl.totals.error = runs.numErrorRuns;
          $ctrl.totals.warning = runs.numWarningRuns;
          $ctrl.totals.cancel = runs.numCanceledRuns;
          $ctrl.totals.active = runs.numActiveRuns;
          $ctrl.totals.total = $ctrl.totals.success +
            $ctrl.totals.error +
            $ctrl.totals.warning +
            $ctrl.totals.cancel +
            $ctrl.totals.active;
        }
      );
    }

    /**
     * Configure params for Job Summary API call
     * Prams are based on $ctrl.filters.
     *
     * @returns {Object}    Parameters which is used for getJobRuns api.
     */
    function buildParams() {
      var params = {
        includeLastRunAndStats: true,
        onlyReturnBasicSummary: true,
        onlyReturnDataMigrationJobs: true,
      };

      switch ($ctrl.filters.activeJobFilter) {
      case 'all': params.isDeleted = false;
        break;
      case 'active': params.isActive = true;
        break;
      case 'inactive': params.isActive = false;
        break;
      case 'deleted': params.isDeleted = true;
        break;
      default : params.isDeleted = false;
        break;
      }

      return TenantService.extendWithTenantParams(params,
        $ctrl.filters.jobTenantFilter);
    }


    /**
     * Translate job Schedule to ui.json key value.
     *
     * @param   {object}    jobSchedule  Job schedule detail.
     * @return  {String}                 ui.json readable value.
     */
    function translateSchedule(jobSchedule) {
      var periodicity = jobSchedule.periodicity;
      var rst;
      switch (periodicity) {
        case 'kDaily':
          rst = get(jobSchedule.dailySchedule, 'days') ?
            'weeklyAtTime' : 'everydayAtTime';
          break;
        case 'kMonthly':
          rst = 'monthlyAtTime';
          break;
      }
      return rst;
    }

    /**
     * Waits a moment and then reloads the data.
     *
     * @method  delayLoadData
     */
    function delayLoadData() {
      return $timeout(loadData, 2500);
    }

    /**
     * Get Actions Config: returns a configuration object
     * for contextual actions menu based on job status.
     *
     * @param    {Object}    job      Job object.
     * @return   {Array}              Contextual Menu Configuration Objects.
     */
    function getActionsMenuConfig(job) {
      var config = [];

      if (JobActionService.isValidJobAction('upTiering', job)) {
        config.push(
          JobActionService.getJobAction('upTiering', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('start', job)) {
        config.push(
          JobActionService.getJobAction('start', job, delayLoadData)
        );
      }

      if (JobActionService.isValidJobAction('pause', job)) {
        config.push(
          JobActionService.getJobAction('pause', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('resume', job)) {
        config.push(
          JobActionService.getJobAction('resume', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('cancel', job)) {
        config.push(
          JobActionService.getJobAction('cancel', job, delayLoadData)
        );
      }

      if (JobActionService.isValidJobAction('edit', job)) {
        config.push(
          JobActionService.getJobAction('edit', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('delete', job)) {
        config.push(
          JobActionService.getJobAction('delete', job, loadData)
        );
      }
      return config;
    }

  }
})(angular);
