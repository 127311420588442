import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { ActiveDirectoryServiceApi as ActiveDirectoryServiceApiV1, ActiveDirectoryPrincipal } from '@cohesity/api/v1';
import { ActiveDirectories, ActiveDirectoryServiceApi as ActiveDirectoryServiceApiV2, UserParams, UserServiceApi } from '@cohesity/api/v2';
import { finalize, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { keyBy } from 'lodash-es';

import { AuthorisedUser, AuthorisedUserType } from '../../models';

@Component({
  selector: 'coh-settings-list-authorised-user',
  templateUrl: './settings-list-authorised-user.component.html',
  styleUrls: ['./settings-list-authorised-user.component.scss'],
  providers: subformComponentProviders(SettingsListAuthorisedUserComponent),
})
export class SettingsListAuthorisedUserComponent
  extends NgxSubFormComponent<AuthorisedUser> implements OnInit, AfterViewInit {

  /**
   * To determine if recovery is on re-submit
   */
  @Input() isResubmit = false;

  /**
   * AuthorisedUserType enum for template
   */
  AuthorisedUserType = AuthorisedUserType;

  /**
   * List of all active directory domains.
   */
  availableDomains: string[] = [];

  /**
   * Hash of domain and it's principal
   */
  domainPrincipalsHash: any = null;

  /**
   * Async list of users.
   */
  users$ = this.userServiceV2.GetUsers({}).pipe(
    map(response => {
      if (!response?.users) {
        return [];
      }

      // Populate selected local user for re-submit
      const { type, user } = this.formGroup.value;
      if (type === AuthorisedUserType.LocalUser && user) {
        this.formGroupControls.user.setValue(response.users.find(u => u.sid === user));
      }

      return response.users;
    })
  );

  /**
   * Ready state indicator.
   */
  domainsFetched$ = new BehaviorSubject<boolean>(false);

  constructor(
    private cdr: ChangeDetectorRef,
    private userServiceV2: UserServiceApi,
    private activeDirectoryServiceApiV1: ActiveDirectoryServiceApiV1,
    private activeDirectoryServiceApiV2: ActiveDirectoryServiceApiV2,
  ) {
    super();
  }

  /**
   * Init component.
   */
  ngOnInit() {
    this.getActiveDirectories();
    // Update validation of local users based on selected type
    this.formGroupControls.type.valueChanges.pipe(takeUntilDestroyed(this)).subscribe(type => {
      this.updateValidators(type);
    });
  }

  /**
   * Triggers after view is initialized
   */
  ngAfterViewInit() {
    this.getInitialPrincipals();
    this.updateValidators(this.formGroupControls.type.value);
    this.formGroup.markAllAsTouched();
  }

  /**
   * Private function fetches the principal object for given sid and builds the
   * initial domain principals hash.
   */
  private getInitialPrincipals() {
    const { sid, domainName, user } = this.formGroup.value;

    // domainPrincipalsHash is not required when it's a local user
    if (user) {
      this.domainPrincipalsHash = {};
      return;
    }

    if (!sid) {
      this.domainPrincipalsHash = {};
      return;
    }

    this.activeDirectoryServiceApiV1.SearchActiveDirectoryPrincipals({
      sids: [sid],
      domain: domainName,
    }).pipe(
      takeUntilDestroyed(this)
    ).subscribe(({ principals }: any) => {
      this.domainPrincipalsHash = keyBy(principals, 'sid');
      this.cdr.detectChanges();
    });
  }

  /**
   * To remove validator from form control
   */
  removeValidator(controlName) {
    const control = this.formGroup.get(controlName);
    control.setValidators(null);
    control.setErrors(null);
    control.reset();
    control.updateValueAndValidity();
  }

  /**
   * To update validators based on type of Authorised User
   */
  updateValidators(type: AuthorisedUserType){
    if (type === this.AuthorisedUserType.LocalUser) {
      this.formGroupControls.user.setValidators(Validators.required);
      this.formGroupControls.user.updateValueAndValidity();
      this.removeValidator('password');
      this.removeValidator('principal');
      this.removeValidator('sid');
    } else if (type === this.AuthorisedUserType.SpecificActiveDirectoryIdentity) {
      this.removeValidator('user');
      this.formGroupControls.principal.setValidators(Validators.required);
      this.formGroupControls.principal.updateValueAndValidity();
      this.formGroupControls.sid.setValidators(Validators.required);
      this.formGroupControls.sid.updateValueAndValidity();
      this.formGroupControls.password.setValidators(Validators.required);
      this.formGroupControls.password.updateValueAndValidity();
    } else {
      this.removeValidator('user');
      this.removeValidator('password');
      this.removeValidator('principal');
      this.removeValidator('sid');
    }
    this.formGroup.updateValueAndValidity();
  }

  /**
   * Load all active directories.
   */
  getActiveDirectories() {
    this.activeDirectoryServiceApiV2.GetActiveDirectory({ includeTenants: true })
      .pipe(finalize(() => this.domainsFetched$.next(true)))
      .subscribe((ads: ActiveDirectories) => {
        if (ads.activeDirectories && ads.activeDirectories.length) {
          this.availableDomains = this.availableDomains.concat(ads.activeDirectories.map(ad => ad.domainName));
        }
      });
  }

  /**
   * Returns default values of form
   */
  getDefaultValues(): Partial<AuthorisedUser> {
    return {
      type: AuthorisedUserType.Automatic,
      user: undefined,
      principal: undefined,
      sid: undefined,
      password: undefined,
      domainName: undefined,
    };
  }

  /**
   * Function to return all the form controls.
   *
   * @return The form controls object.
   */
  protected getFormControls(): Controls<AuthorisedUser> {
    return {
      type: new UntypedFormControl(AuthorisedUserType.Automatic),
      user: new UntypedFormControl(),
      principal: new UntypedFormControl(),
      sid: new UntypedFormControl(),
      password: new UntypedFormControl(),
      domainName: new UntypedFormControl(),
    };
  }

  /**
   * Display function for autocomplete for local user provider.
   *
   * @param    local user provider object or string.
   * @returns  local user provider name.
   */
  displayLocalUser(localUser: UserParams): string {
    return (localUser as UserParams)?.username || '';
  }

  /**
   * Listen on principal selection and update form values.
   */
  selectPrincipal(principal: ActiveDirectoryPrincipal) {
    this.formGroupControls.principal.setValue(principal.principalName);
    this.formGroupControls.sid.setValue(principal.sid);
    this.formGroupControls.domainName.setValue(principal.domain);
  }
}
