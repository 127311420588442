import { assign } from 'lodash-es';
// Service: PublicService utility service.
// Add the tranformations required for public api can be added here

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.pubServiceUtil', [])
    .service('PubServiceUtil', PubServiceUtilFn);

  function PubServiceUtilFn(_) {
    return {
      transformJobUidFromPrivateToPublic: transformJobUidFromPrivateToPublic,
    };

    /**
     * Transforms jobUid from private to public
     *
     * @method   transformJobUidFromPrivateToPublic
     * @param    {object}   jobUid   private jobUid containing 'objectId'
     * @return   {object}            public jobUid containing 'id'
     */
    function transformJobUidFromPrivateToPublic(jobUid) {
      return assign(jobUid, {
        id: jobUid.id || jobUid.objectId,
        objectId: undefined,
      });
    }
  }
})(angular);
