<div class="cluster-name" *ngIf="!loading && clusterId">
  {{ clusterName }}
</div>
<div class="cluster-region" *ngIf="!loading && (clusterRegionName && !clusterId)">
  {{ totalClusters > 1 ? ('securityAdvisor.allIssues.regionAndTotalClusters' | translate: {
      regionName: clusterRegionName, totalClusters: totalClusters
    }) : ('securityAdvisor.allIssues.region' | translate: { regionName: clusterRegionName })
  }}
</div>

<!-- Cluster name Filter -->
<div class="filter-container">
  <cog-filters>
    <ng-container *ngIf="!clusterId">
      <cog-value-property-filter
        cogDataId="argus-dlp-table-filter-by-selected-cluster"
        *cogFilterDef="
          let params = params;
          let filter;
          quickFilter: true;
          key: 'clusterName';
          label: 'cluster' | translate;
          filterType: 'valueProperty'"
        [filterDefParams]="params"
        [filter]="filter"
        [allowMultiple]="true"
        [autoApply]="true"
        [filterValues]="filterValuesList.cluster"
        [title]="'cluster' | translate"
        [trackFilters]="true">
      </cog-value-property-filter>
    </ng-container>
    <ng-container>
      <cog-search-property-filter
        cogDataId="argus-dlp-table-filter-by-pattern-name"
        *cogFilterDef="
          let filter;
          key: 'issueName';
          label: 'securityAdvisor.allIssues.issueName' | translate;
          quickFilter: true;
          filterType: 'searchProperty'"
        [filter]="filter"
        [value]="issueName"
        [placeholder]="'securityAdvisor.allIssues.issueName' | translate">
      </cog-search-property-filter>
    </ng-container>
  </cog-filters>
</div>

<div [hidden]="loading">
  <ng-container *ngIf="filteredIssues?.length && !loading; else noData">
    <div *ngIf="showColumns" class="issue-columns">
      <div *ngFor="let column of columns; index as i">
        {{ column | translate }}
      </div>
    </div>
    <div class="issues-container">
      <mat-card *ngFor="let issue of filteredIssues; index as i">
        <div class="issue-row" [class.issue-row-expanded]="issue === expandedIssue">
          <div class="issue-title-container">
            <div class="issue-content-title">{{ issue.issueName }}</div>
          </div>
          <div class="occurs-on-container" *ngIf="showOccursOnInfo">
            <div class="issue-content-title">
              {{ 'securityAdvisor.allIssues.inferredClusters' | translate: {
                inferredClusters: issue.occursOn, totalClusters: issue.totalClusters
              } }}
            </div>
          </div>
          <div class="action-container">
            <button
              mat-icon-button
              type="button"
              cogDataId="expand-issue-{{i}}"
              (click)="toggleExpand(issue)">
              <cog-icon [shape]="issue === expandedIssue ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"></cog-icon>
            </button>
          </div>
        </div>
        <div *ngIf="issue === expandedIssue">
          <div class="suggestion-container">
            <div class="suggestion-content-title">
              {{ 'suggestion' | translate }}
            </div>
            <p class="suggestion-content-description">
              {{ issue.suggestion }}
            </p>
            <a target="_blank" href="{{issue.documentUrl}}">
              <button cogDataId="suggestion-link-{{i}}" mat-flat-button color="primary">
                {{ 'learnMore' | translate }}
              </button>
            </a>
          </div>
          <div>
            <div class="cluster-list-content-title">
              {{ 'securityAdvisor.allIssues.nonCompliantClusters' | translate: {
                issueClustersLength: issue.clusterList.length
              } }}
            </div>
            <div class="cluster-list-container">
              <div *ngFor="let cluster of issue.clusterList"
                class="cluster-item"
                [matTooltip]="'clusterIdColonValue' | translate: { id: cluster.clusterId }">
                {{ cluster.clusterName }}
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </ng-container>
  <mat-paginator
    [ngClass]="{'hidden': (!clusterRegionName && !clusterId) || filteredIssues.length === 0}"
    [pageSize]="pageSize"
    (page)="onPageChange($event)">
  </mat-paginator>
</div>

<ng-template #noData>
  <cog-blank-card
    message="{{ 'noResultsFound' | translate }}"
    size="sm"
    type="helix:no-data-img-no-activity-found">
  </cog-blank-card>
</ng-template>

<cog-spinner *ngIf="loading"></cog-spinner>
