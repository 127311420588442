import { findIndex } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Office 365 Recovery Options

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.office365RecoveryOptions', [])
    .controller('Office365RecoveryOptionsCtrl', office365RecoveryOptionsCtrlFn)
    .component('office365RecoveryOptions', {
      templateUrl: 'app/protection/recovery/office365/options/options.html',
      controller: 'Office365RecoveryOptionsCtrl',
    });

  /**
   * @ngdoc component
   * @name C.office365RecoveryOptions:cOffice365RecoveryOptions
   * @function
   *
   * @description
   * Component to provide recovery options for Office 365 entities.
   */
  function office365RecoveryOptionsCtrlFn(_, RecoveryStore, RestoreService,
    FORMATS, PubRestoreService, PubSourceService, GlobalSearchService, $state,
    evalAJAX, cMessage, Cart, $rootScope, $filter, OFFICE365_SEARCH_TYPE,
    FEATURE_FLAGS, OFFICE365_GROUPS, OFFICE365_CONTAINER_NODE_TYPE) {
    var $ctrl = this;

    assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,

      // Methods exposed to template.
      createRecoveryTask: createRecoveryTask,
      handleAlternateRecoveryToggle: handleAlternateRecoveryToggle,
      handleExportPstToggle: handleExportPstToggle,
      selectTargetEntity: selectTargetEntity,
      updateEntityList: updateEntityList,

      // Variables.
      FORMATS: FORMATS,
      office365ServerList: [],
      office365EntityList: [],
      state: {
        fetchingEntities: false,
        entityNotFound: false,
        targetDocumentLibraryPrefix: undefined,
        targetDocumentLibraryName: '',
        targetFolderPath: undefined,
        disableAlternateRecovery: false,
        exportPst: false,
        pstPassword: '',
      },
    });

    /**
     * Component initialization hook.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();
      $ctrl.recoveryName = RestoreService.getDefaultTaskName('recover',
        'Microsoft365');

      _intializeRecoveryTaskType();
      _initializeRecoveryCart();
      _initializeDefaultOptions();

      // TODO(Tauseef): Add support for recovery through Global search.
    }

    /**
     * Specifies the method to generate a key, unique to cart item.
     * The hierarchy of the objects can be maintained if they are being
     * restored from the same job run id(snapshot).
     *
     * @method   generateKey
     * @param    {object}   item   Specifies the item held in cart.
     * @return   {string}   Specifies the key for the item.
     */
    function _generateKey(item) {
      return [item._sourceId, item._snapshot.jobRunId].join('-');
    }

    /**
     * Fetches the registered Office365 servers.
     *
     * @method   _fetchOffice365Servers
     */
    function _fetchOffice365Servers() {
      $ctrl.state.fetchingO365Servers = true;
      PubSourceService.getRootNodes({
        environments: 'kO365',
      }).then(
        function successfulRequest(rootNodes) {
          rootNodes.forEach(function eachNode(node) {
            $ctrl.office365ServerList.push(node.protectionSource);
          });
        },
        evalAJAX.errorMessage
      ).finally(
        function completeOutlookServers() {
          $ctrl.state.fetchingO365Servers = false;
        }
      );
    }

    /**
     * Fetches and updates the user/site list based on the Office365 server
     * selected and the search string by using the Global Search API for
     * Office365 environment.
     *
     * Currently all the Registered Entities are indexed within ES under the
     * index 'magnetostate' which has been utilized here.
     *
     * TODO(tauseef): Check if Global Search Indexing status can be checked so
     * as to scale this solution even for Job creation.
     *
     * @method   updateEntityList
     * @param    {String}   [userName=*]   Specifies the name of an O365 entity
     */
    function updateEntityList(userName='*') {

      if ($ctrl.state.selectedServer &&
        FEATURE_FLAGS.office365FetchEntitiesFromMagnetoInAlternateRestore) {
        var params = {
          excludeOffice365Types:[],
          pruneNonCriticalInfo: true,
          pruneAggregationInfo: true
        };
        if ($ctrl.shared.isSharePointTask) {
          params.excludeOffice365Types = [
            OFFICE365_CONTAINER_NODE_TYPE.kUsers,
            OFFICE365_CONTAINER_NODE_TYPE.kPublicFolders,
            OFFICE365_CONTAINER_NODE_TYPE.kGroups,
            OFFICE365_CONTAINER_NODE_TYPE.kTeams
          ];
        } else if ($ctrl.shared.isOutlookTask || $ctrl.shared.isOneDriveTask) {
          params.excludeOffice365Types = [
            OFFICE365_CONTAINER_NODE_TYPE.kSites,
            OFFICE365_CONTAINER_NODE_TYPE.kPublicFolders,
            OFFICE365_CONTAINER_NODE_TYPE.kGroups,
            OFFICE365_CONTAINER_NODE_TYPE.kTeams
          ];
        }
        $ctrl.state.fetchingEntities = true;
        $ctrl.shared.defaultTask.newParentId = $ctrl.state.selectedServer.id;
        PubSourceService.getSource($ctrl.state.selectedServer.id, params)
          .then(function getSourceSuccess(sources) {
          $ctrl.state.entityNotFound = !sources.length || !sources[0].nodes.length
            || !sources[0].nodes[0].nodes.length;
          if (!$ctrl.state.entityNotFound) {
            $ctrl.office365EntityList = [];
            // No need to skip addSubSitesRecursively() for user nodes, it can
            // be generalized.
            sources[0].nodes[0].nodes.forEach(addSubSitesRecursively)
          }
        }).finally(function handleRequestCompletion() {
          $ctrl.state.fetchingEntities = false;
        });
        return;
      }

      var globalSearchParams = {
        environments: ['kO365'],

        // Reduce response count to 20 entities.
        pageCount: 20,

        // If unspecified, search for all entities.
        searchString: userName,
      };

      if ($ctrl.shared.isOutlookTask || $ctrl.shared.isOneDriveTask) {
        globalSearchParams.office365ProtectionSourceTypes = ['kUser'];
      }

      if ($ctrl.shared.isSharePointTask) {
        globalSearchParams.office365ProtectionSourceTypes = ['kSite'];
      }

      // Set the parent id for the target office 365 domain.
      if ($ctrl.state.selectedServer) {
        $ctrl.state.fetchingEntities = true;
        $ctrl.shared.defaultTask.newParentId = $ctrl.state.selectedServer.id;

        // Query on the Parent's UUID.
        globalSearchParams.registeredSourceUuids =
          $filter('o365DomainIdentifier')($ctrl.state.selectedServer);
        // Returns only top 100 matched.
        GlobalSearchService.getResults(globalSearchParams).then(
          function onSuccessfulRequest(sources) {
            $ctrl.state.entityNotFound = !sources.length;
            $ctrl.office365EntityList = sources;
          }
        ).catch(
          (resp) => {
            // Global search is not MT aware,
            // Handling forbidden error gracefully.
            if (resp.status === 403) {
              $ctrl.state.disableAlternateRecovery = true;
              $ctrl.state.showAlternateRecoverySettings = false;
              cMessage.error({
                textKey:
                  'office365Restore.cmessages.alternateRestoreInvalidError',
              });
              handleAlternateRecoveryToggle();
            }
          }, evalAJAX.errorMessage
        ).finally(
          function handleRequestCompletion() {
            $ctrl.state.fetchingEntities = false;
          }
        );
      }
    }

    /**
     * Some source nodes, site nodes for example, contain children nodes also.
     * This function recursively visits nodes in the hierarchy to add them in
     * the  $ctrl.office365EntityList when the response is fetched from the
     * magneto instead of gloabl search.
     *
     * @method   addSubSitesRecursively
     * @param    {node}   source   Specifies the node object of the EH.
     */

    function addSubSitesRecursively(source) {
      $ctrl.office365EntityList.push(source);
      if (!source.nodes.length)
        return;
      source.nodes.forEach(addSubSitesRecursively);
    }

    /**
     * Selects the target Office365 entity(user or site).
     *
     * @method   selectTargetEntity
     */
    function selectTargetEntity() {
      // NOTE: User entities returned from the Global Search API has its source
      // ID and ParentSource ID accumulated for the given source together for
      // each cluster.
      // No need to verify cluster details if target details are fetched from the
      // Magneto.
      if (!FEATURE_FLAGS.office365FetchEntitiesFromMagnetoInAlternateRestore) {
        $ctrl.state.selectedEntity.protectionSourceUidList.some(
          function verifyClusterDetail(detail) {
            if (detail.clusterId === $rootScope.clusterInfo.id) {
              // Populate correct Source ID and parent source ID.
              $ctrl.state.selectedEntity.source.id = detail.sourceId;
              $ctrl.state.selectedEntity.source.parentId =
                detail.parentSourceId;
              return true;
            }
          }
        );
      }

      var source = FEATURE_FLAGS.office365FetchEntitiesFromMagnetoInAlternateRestore ?
        $ctrl.state.selectedEntity.protectionSource :
        $ctrl.state.selectedEntity.source;

      // Set the target source for the Office365 recovery based on type.
      switch(true) {
        case $ctrl.shared.isOutlookTask:
          $ctrl.shared.defaultTask.outlookParameters.targetMailbox = source;
          break;
        case $ctrl.shared.isOneDriveTask:
          $ctrl.shared.defaultTask.oneDriveParameters.targetUser = source;
          $ctrl.shared.defaultTask.oneDriveParameters.targetDriveId =
            // Only Office365 entities of type 'kUser' having OneDrives will
            // have their Drive ID populated within UserInfo.
            get(source.office365ProtectionSource,
              'userInfo.oneDriveId');
          break;
        case $ctrl.shared.isSharePointTask:
          $ctrl.shared.defaultTask.sharePointParameters.targetSite = source;
      }
      $ctrl.shared.defaultTask.outlookParameters.targetMailbox = source;
    }

    /**
     * Updates the target mailbox and parent Office 365 domain for restore to
     * alternate location.
     *
     * @method   handleAlternateRecoveryToggle
     */
    function handleAlternateRecoveryToggle() {
      if ($ctrl.state.showAlternateRecoverySettings) {
        $ctrl.state.targetFolderPath = $ctrl.recoveryName;
        $ctrl.state.targetDocumentLibraryPrefix = $ctrl.recoveryName;

        // Reset selected server and entity
        $ctrl.state.selectedServer = undefined;
        $ctrl.state.selectedEntity = undefined;

        // With SPO site creation support, update restoreToOriginalSite
        if ($ctrl.shared.isSharePointTask &&
          FEATURE_FLAGS.office365SharePointRecoverySiteCreation) {
            assign($ctrl.shared.defaultTask.sharePointParameters, {
              restoreToOriginalSite: false,
            });
        }
      } else {
        // Remove target information for restore.
        $ctrl.shared.defaultTask.newParentId = undefined;
        $ctrl.state.targetFolderPath = undefined;
        switch(true) {
          case $ctrl.shared.isOutlookTask:
            assign($ctrl.shared.defaultTask.outlookParameters, {
              targetMailbox: undefined,
            });
          case $ctrl.shared.isOneDriveTask:
            assign($ctrl.shared.defaultTask.oneDriveParameters, {
              targetUser: undefined,
              targetDriveId: undefined,
            });
            break;
          case $ctrl.shared.isSharePointTask:
            assign($ctrl.shared.defaultTask.sharePointParameters, {
              targetSite: undefined,
              targetDocumentLibraryPrefix: undefined,
              targetDocumentLibraryName: undefined,
            });
            break;
        }
      }
    }

    /**
     * Updates the AlternateRecoveryToggle and disable if exportPst toggle is on.
     *
     * @method   handleExportPstToggle
     */
    function handleExportPstToggle() {
      if ($ctrl.state.exportPst) {
        $ctrl.state.disableAlternateRecovery = true;
        $ctrl.state.showAlternateRecoverySettings = false;
        handleAlternateRecoveryToggle();
      } else {
        $ctrl.state.disableAlternateRecovery = false;
      }
    }

    /**
     * Triggers creation of recovery task for Outlook/OneDrive restore.
     *
     * @method   createRecoveryTask
     */
    function createRecoveryTask() {
      // Prepare restore parameters.
      $ctrl.state.submittingRecoveryTask = true;
      $ctrl.shared.cart.cartList.forEach(_generateRecoveryParamForO365Item);

      switch (true) {
        case $ctrl.shared.isOutlookTask:
          $ctrl.shared.defaultTask.oneDriveParameters = undefined;
          $ctrl.shared.defaultTask.sharePointParameters = undefined;
          _createOutlookRecoveryTask();
          break;

        case $ctrl.shared.isOneDriveTask:
          $ctrl.shared.defaultTask.outlookParameters = undefined;
          $ctrl.shared.defaultTask.sharePointParameters = undefined;
          _createOneDriveRecoveryTask();
          break;

        case $ctrl.shared.isSharePointTask:
          $ctrl.shared.defaultTask.outlookParameters = undefined;
          $ctrl.shared.defaultTask.oneDriveParameters = undefined;
          _createSharePointRecoveryTask();
          break;
      }
    }

    /**
     * Creates a recovery task for Outlook Restore.
     *
     * @method   _createOutlookRecoveryTask
     */
    function _createOutlookRecoveryTask() {
      // Iterate over the MailboxCart and create recovery param.
      $ctrl.recovery.mailboxCart.cartList.forEach(
        function populateRecoveryRequest(cartItem) {
          $ctrl.shared.defaultTask.objects.push(cartItem._objectParam);

          if (cartItem._outlookParam) {
            $ctrl.shared.defaultTask.outlookParameters.outlookMailboxList
              .push(cartItem._outlookParam);
          }
        }
      );

      if ($ctrl.state.exportPst) {
        $ctrl.shared.defaultTask.type = 'kConvertToPst';
        assign($ctrl.shared.defaultTask.outlookParameters, {
          pstParams: {
            createPst: !!$ctrl.state.exportPst,
            pstPassword: $ctrl.state.pstPassword,
          }
        });
      } else {
        $ctrl.shared.defaultTask.type = 'kRecoverEmails';
      }

      assign($ctrl.shared.defaultTask.outlookParameters, {
        targetFolderPath: $ctrl.state.targetFolderPath,
      });

      _executeRestoreRequest();
    }

    /**
     * Creates a recovery task for OneDrive request.
     *
     * @method   _createOneDriveRecoveryTask
     */
    function _createOneDriveRecoveryTask() {
      // Iterate over the oneDrive cart.
      $ctrl.recovery.oneDriveCart.cartList.forEach(
        function populateRecoveryRequest(cartItem) {
          $ctrl.shared.defaultTask.objects.push(cartItem._objectParam);

          if (cartItem._oneDriveParam) {
            $ctrl.shared.defaultTask.oneDriveParameters.driveOwnerList.push(
              cartItem._oneDriveParam);
          }
        }
      );

      assign($ctrl.shared.defaultTask.oneDriveParameters, {
        targetFolderPath: $ctrl.state.targetFolderPath,
      });
      _executeRestoreRequest();
    }

    /**
     * Creates a recovery task for SHarePoint request.
     *
     * @method   _createOneDriveRecoveryTask
     */
    function _createSharePointRecoveryTask() {
      // Iterate over the oneDrive cart.
      $ctrl.recovery.sharePointCart.cartList.forEach(
        function populateRecoveryRequest(cartItem) {
          $ctrl.shared.defaultTask.objects.push(cartItem._objectParam);

          if (cartItem._sharePointParam) {
            $ctrl.shared.defaultTask.sharePointParameters.siteOwnerList.push(
              cartItem._sharePointParam);
          }
        }
      );

      var isSharePointSiteCreationSupported =
        FEATURE_FLAGS.office365SharePointRecoverySiteCreation &&
        !$ctrl.shared.isSharePointGranularTask;

      assign($ctrl.shared.defaultTask.sharePointParameters, {
        targetDocumentLibraryPrefix: isSharePointSiteCreationSupported ?
          undefined :
          $ctrl.state.targetDocumentLibraryPrefix,
        targetDocumentLibraryName: $ctrl.state.targetDocumentLibraryName,
      });
      _executeRestoreRequest();
    }

    /**
     * Executes a POST request for recovering Office365 items.
     *
     * @method   _executeRestoreRequest
     */
    function _executeRestoreRequest() {
      $ctrl.shared.defaultTask.name = $ctrl.recoveryName;
      PubRestoreService.restore($ctrl.shared.defaultTask).then(
        function recoveryTaskExecuted(restoreResponse) {
          var targetType =
            restoreResponse.objects[0].archivalTarget ? 'archive' : 'local';
          var stateName =
            RestoreService.getRestoreTaskDetailStateName('recover', targetType);
          var stateParams = { id: restoreResponse.id, };
          var restoreSuccessTextKey;
          switch(true) {
            case $ctrl.shared.isOutlookTask:
              restoreSuccessTextKey =
                'office365Restore.cmessages.successfulMailboxRecovery';
              break;
            case $ctrl.shared.isOneDriveTask:
              restoreSuccessTextKey =
                'office365Restore.cmessages.successfulOneDriveRecovery';
              break;
            case $ctrl.shared.isSharePointTask:
              restoreSuccessTextKey =
                'office365Restore.cmessages.successfulSharePointRecovery';
              break;
          }
          cMessage.success({
            textKey: restoreSuccessTextKey,
            timeout: 5000,
          });

          // Clear the shared store to keep things sane.
          RecoveryStore.clear();

          // Move to the next state.
          $state.go(stateName, stateParams);
        }, evalAJAX.errorMessage
      ).finally(
        function requestSubmitted() {
          $ctrl.state.submittingRecoveryTask = false;

          // Empty the added parameters.
          _initializeRecoveryCart();

          // Skip the reset logic below if defaultTask is not available
          if (!$ctrl.shared.defaultTask) {
            return;
          }

          $ctrl.shared.defaultTask.objects.length = 0;
          if ($ctrl.shared.isOutlookTask) {
            $ctrl.shared.defaultTask.outlookParameters.outlookMailboxList
              .length = 0;
          } else if ($ctrl.shared.isOneDriveTask) {
            $ctrl.shared.defaultTask.oneDriveParameters.driveOwnerList
              .length = 0;
          } else if ($ctrl.shared.isSharePointTask) {
            $ctrl.shared.defaultTask.sharePointParameters.siteOwnerList
              .length = 0;
          }
        }
      );
    }

    /**
     * Adds the mailbox to the recovery cart.
     *
     * @method   _addMailbox
     * @param    {object}   mailbox   Specifies the mailbox object.
     */
    function _addMailbox(mailbox) {
      // NOTE: Mailbox is added by default and will replace any child being
      // restored from the same recovery point within mailbox.
      mailbox = _generateDefaultRecoveryParam(mailbox);
      $ctrl.recovery.mailboxCart.add(mailbox);
    }

    /**
     * Adds the mailbox job to the recovery cart.
     *
     * @method   _addMailboxJob
     * @param    {object}   mailboxJob   Specifies the details about the job.
     */
    function _addMailboxJob(mailboxJob) {
      mailboxJob = _generateDefaultRecoveryParam(mailboxJob, true);
      $ctrl.recovery.mailboxCart.add(mailboxJob);
    }

    /**
     * Adds the Email Folder to the recovery cart.
     *
     * @method   _addEmailFolder
     * @param    {object}   folder   Specifies the Email folder object.
     */
    function _addEmailFolder(folder) {
      var key;

      // NOTE: Email folder is only added if its parent mailbox is not
      // being recovered entirely from the same snapshot.
      // Check for the presence of the mailbox within the mailboxCart.
      if (!$ctrl.recovery.mailboxCart.has(folder)) {
        folder = _generateDefaultRecoveryParam(folder);

        // Modify the Outlook Parameters to specify Folders.
        folder._outlookParam.restoreEntireMailbox = false;
        folder._outlookParam.outlookFolderList = [];
        _addEntireFolderRestoreOption(folder);

        // Add the Folder to the mailbox Cart item.
        $ctrl.recovery.mailboxCart.add(folder);
      } else {
        key = _generateKey(folder);
        folder._outlookParam = $ctrl.recovery.mailboxCart
          .cartMap[key]._outlookParam;

        // If restoreEntireMailbox is false, it means a folder is already
        // added with the same recovery point, hence the folder list is to
        // be appended. Otherwise, since there is a parent wrapper mailbox
        // requesting to be restored entirely hence this folder can be
        // omitted from the recovery task.
        if (!folder._outlookParam.restoreEntireMailbox) {
          if (!folder._outlookParam.outlookFolderList) {
            folder._outlookParam.outlookFolderList = [];
          }
          _addEntireFolderRestoreOption(folder);
        }
      }
    }

    /**
     * Adds the entire email folder restore option.
     *
     * @method   _addEntireFolderRestoreOption
     * @param    {param}   folder   Specifies the Email folder to be recovered.
     */
    function _addEntireFolderRestoreOption(folder) {
      folder._outlookParam.outlookFolderList.push({
        folderKey: folder._parentFolderKey,
        restoreEntireFolder: true,
      });
    }

    /**
     * Adds the Email to the recovery cart.
     *
     * @method   _addEmail
     * @param    {object}   item   Specifies the Email object.
     */
    function _addEmail(item) {
      // NOTE: Email is only added if its parent Mailbox or its parent folder
      // is not being recovered entirely.
      var key;
      if (!$ctrl.recovery.mailboxCart.has(item)) {
        item = _generateDefaultRecoveryParam(item);

        // Modify the Outlook Parameters to specify Folders.
        item._outlookParam.restoreEntireMailbox = false;
        item._outlookParam.outlookFolderList = [];
        item._outlookParam.outlookFolderList.push({
          folderKey: item._parentFolderKey,
          restoreEntireFolder: false,
          outlookItemIdList: [
            item._recoveryId
          ],
        });

        // Add the Email to the Mailbox Cart item.
        $ctrl.recovery.mailboxCart.add(item);
      } else {
        key = _generateKey(item);
        item._outlookParam = $ctrl.recovery.mailboxCart
          .cartMap[key]._outlookParam;

        if (!item._outlookParam.restoreEntireMailbox) {
          // Check if the parent folder of the Outlook item is being
          // restored entirely.
          var index = findIndex(item._outlookParam.outlookFolderList,
            function(folder) {
              return folder.folderKey === item._parentFolderKey;
            });

          // If the folder is found and is not being restored entirely then
          // append the item id list else omit if being restored entirely.
          if (index > -1) {
            if (!item._outlookParam.outlookFolderList[index]
              .restoreEntireFolder) {
              item._outlookParam.outlookFolderList[index]
                .outlookItemIdList.push(item._recoveryId);
            }
          } else {
            // This means a new Outlook item is being restored(Email).
            if (!item._outlookParam.outlookFolderList) {
              item._outlookParam.outlookFolderList = [];
            }
            item._outlookParam.outlookFolderList.push({
              folderKey: item._parentFolderKey,
              restoreEntireFolder: false,
              outlookItemIdList: [
                item._recoveryId
              ],
            });
          }
        }
      }
    }

    /**
     * Adds a OneDrive to the recovery cart. This selected directly via the
     * search results from ES for objects within 'objindex'.
     *
     * @method   _addOneDrive
     * @param    {object}   oneDrive   Specifies the OneDrive object to be
     *                                 restored.
     */
    function _addOneDrive(oneDrive) {
      oneDrive = _generateDefaultRecoveryParam(oneDrive);
      $ctrl.recovery.oneDriveCart.add(oneDrive);
    }

    /**
     * Adds OneDrive item to the recovery cart. This is selected from the
     * browse flow for a OneDrive item.
     *
     * @method   _addOneDriveItem
     * @param    {object}   oneDriveItem   Specifies OneDrive item to be
     *                                     restored.
     */
    function _addOneDriveItem(oneDriveItem) {
      // Specifies the key for the CartMap.
      var key;

      // Add OneDrive item.
      if (!$ctrl.recovery.oneDriveCart.has(oneDriveItem)) {
        oneDriveItem = _generateDefaultRecoveryParam(oneDriveItem);
        oneDriveItem._oneDriveParam.driveInfoList[0].restoreEntireDrive =
          false;
        oneDriveItem._oneDriveParam.driveInfoList[0].driveItemList = [];
        oneDriveItem._oneDriveParam.driveInfoList[0].driveItemList.push({
          itemPath: oneDriveItem._relativePath,
          isFileItem: oneDriveItem._isFile,
        });

        // Add item to the OneDrive cart.
        $ctrl.recovery.oneDriveCart.add(oneDriveItem);
      } else {
        // Update the existing item in the cart.
        key = _generateKey(oneDriveItem);
        oneDriveItem._oneDriveParam = $ctrl.recovery.oneDriveCart
          .cartMap[key]._oneDriveParam;

        if (!oneDriveItem._oneDriveParam.driveInfoList[0].restoreEntireDrive) {
          if (!oneDriveItem._oneDriveParam.driveInfoList[0].driveItemList) {
            oneDriveItem._oneDriveParam.driveInfoList[0].driveItemList = [];
          }
          oneDriveItem._oneDriveParam.driveInfoList[0].driveItemList.push({
            itemPath: oneDriveItem._relativePath,
            isFileItem: oneDriveItem._isFile,
          });
        }
      }
    }

    /**
     * Adds SHarePoint item to the recovery cart.
     *
     * @method   _addSharePointSite
     * @param    {object}   site   Specifies the SharePoint Site object
     */
    function _addSharePointSite(site) {
      site = _generateDefaultRecoveryParam(site);
      $ctrl.recovery.sharePointCart.add(site);
    }

    /**
     * Adds SharePoint Drive item to the recovery cart.
     *
     * @method   _addSharePointSiteItem
     * @param    {object}   siteDriveItem   Specifies the drive item
     */
    function _addSharePointSiteItem(siteDriveItem) {
      var key;

      if (!$ctrl.recovery.sharePointCart.has(siteDriveItem)) {
        siteDriveItem = _generateDefaultRecoveryParam(siteDriveItem);

        // Add item to the SHarePoint cart.
        $ctrl.recovery.sharePointCart.add(siteDriveItem);
      } else {
        key = _generateKey(siteDriveItem);
        siteDriveItem._sharePointParam = $ctrl.recovery.sharePointCart
          .cartMap[key]._sharePointParam;

        // Iterate over the drive list to find the Drive ID.
        let currentDriveInfo = siteDriveItem._sharePointParam.driveInfoList[0];
        let currentDriveInfoIndex = 0;

        // Boolean to determine whether the Drive(document library) is already
        // present in the recovery params.
        let isDriveFound = false;

        siteDriveItem._sharePointParam.driveInfoList.forEach((driveInfo, i) => {
          if (siteDriveItem._siteDriveName === driveInfo.driveName) {
            currentDriveInfo = driveInfo;
            currentDriveInfoIndex = i;
            isDriveFound = true;
            driveInfo.restoreEntireDrive = driveInfo.restoreEntireDrive ||
              siteDriveItem._isSiteDrive;
          }
        });

        // Every Site has document libraries(drives) containing drive items.
        // If the drive is not found within existing drives added in the cart,
        //  anew drive is to added along with the drive items selected.
        if (!isDriveFound) {
          siteDriveItem._sharePointParam.driveInfoList.push({
            driveName: siteDriveItem._siteDriveName,
            restoreEntireDrive: siteDriveItem._isSiteDrive,
            driveItemList: undefined,
          });

          currentDriveInfoIndex =
            siteDriveItem._sharePointParam.driveInfoList.length - 1;
          currentDriveInfo = siteDriveItem._sharePointParam.driveInfoList[
            currentDriveInfoIndex];
        }

        // Incase the item added is not a drive itself, this boolean will be
        // false and the drive items are to added within the parameters.
        if (!currentDriveInfo.restoreEntireDrive) {
          if (!currentDriveInfo.driveItemList) {
            currentDriveInfo.driveItemList = [];
          }
          currentDriveInfo.driveItemList.push({
            itemPath: siteDriveItem._relativePath,
            isFileItem: siteDriveItem._isFile,
          });

          siteDriveItem._sharePointParam.driveInfoList[currentDriveInfoIndex] =
            currentDriveInfo;
        } else {
          currentDriveInfo.driveItemList = undefined;
        }
      }
    }

    /**
     * Analyzes and generates the hierarchy of current recovery cart keeping
     * recovery point and mailbox id as the unique key. Additionally, it adds
     * the item if absent in the hierarchy and skips if a parent is being
     * recovered entirely from the same snapshot of the child item.
     *
     * @method   _generateRecoveryParamForO365Item
     * @param    {object}   item   Specifies the O365 item to be recovered.
     * @return   {object}   Request parameter for the specific object.
     */
    function _generateRecoveryParamForO365Item(item) {
      switch (true) {
        // --------------------------------------------------------------------
        // Deprecated case clause.
        case item._isMailbox:

        // --------------------------------------------------------------------
        // Object level recovery.
        case item._hasMailbox:
          _addMailbox(item);
          break;

        case item._hasOneDrive:
          _addOneDrive(item);
          break;

        case item._isOffice365SharePointSite:
          _addSharePointSite(item);

        // --------------------------------------------------------------------
        // Job level recovery.
        case item._isOffice365UserJob:
          _addMailboxJob(item);
          break;

        // --------------------------------------------------------------------
        // Granular recovery.
        case item._isEmailFolder:
          _addEmailFolder(item);
          break;

        case item._isEmail:
          _addEmail(item);
          break;

        case item._isFile:
        case item._isDirectory:
          $ctrl.shared.isSharePointTask ?
            _addSharePointSiteItem(item) : _addOneDriveItem(item);
          break;

        // TODO(Tauseef): Add other data types such as Calendar, Task,
        // Contacts, etc.
      }
    }

    /**
     * Generates the recovery object parameter. This is in sync with
     * RestoreObject defined in iris/server/data/public/restore_data.go
     *
     * @method   _generateDefaultRecoveryObjectParam
     * @param    {object}   item   Specifies the recovery item.
     * @return   {object}   Object specifying entity recovery details.
     */
    function _generateDefaultRecoveryObjectParam(item) {
      return {
        jobId: item._jobId,
        jobUid: item._jobUid,
        protectionSourceId: item._sourceId,
        jobRunId: item._snapshot.jobRunId,
        startedTimeUsecs: item._snapshot.startedTimeUsecs,
        archivalTarget: item._snapshot.archivalTarget,
      };
    }

    /**
     * Generates the recovery object parameter for Outlook Restore. This is in
     * sync with OutlookRestoreParameters defined in
     * iris/server/data/public/restore_data.go.
     *
     * @method   _generateDefaultRecoveryOutlookParam
     * @param    {object}   item   Specifies the recovery item.
     * @return   {object}   Object specifying outlook recovery details.
     */
    function _generateDefaultRecoveryOutlookParam(item) {
      return {
        mailboxObject: {
          jobId: item._jobId,
          jobUid: item._jobUid,
          protectionSourceId: item._sourceId,
        },
        restoreEntireMailbox: true,
      };
    }

    /**
     * Generates the recovery object parameter for OneDrive Restore. This is in
     * sync with OneDriveRestoreParameters defined in
     * iris/server/data/public/restore_data.go.
     *
     * @method   _generateDefaultRecoveryOneDriveParam
     * @param    {object}   item
     */
    function _generateDefaultRecoveryOneDriveParam(item) {
      return {
        userDetailObject: {
          jobId: item._jobId,
          jobUid: item._jobUid,
          protectionSourceId: item._sourceId,
        },

        driveInfoList: [{
          driveId: item._oneDriveId,
          restoreEntireDrive: true,
        }],
      };
    }

    /**
     * Generates the recovery object parameter for SharePoint Restore. This is
     * in sync with SharePointRestoreParameters defined in
     * iris/server/data/public/restore_data.go.
     *
     * @param   {object}   item   Specifies the SharePoint item to be recovered
     */
    function _generateDefaultRecoverySharePointParam(item) {
      const siteDefaultParam = {
        siteDetailObject: {
          jobId: item._jobId,
          jobUid: item._jobUid,
          protectionSourceId: item._sourceId,
        },
        driveInfoList: [],
      };

      // This will be only present for items/doc lib within SharePoint.
      if (item._siteDriveName) {
        siteDefaultParam.driveInfoList.push({
          driveName: item._siteDriveName,
          restoreEntireDrive: item._isSiteDrive,
          driveItemList: undefined,
        });

        // If the item is not a document library, drive item list needs to be
        // populated.
        if (!item._isSiteDrive) {
          siteDefaultParam.driveInfoList[0].driveItemList = [{
            itemPath: item._relativePath,
            isFileItem: item._isFile,
          }];
        }
      }
      return siteDefaultParam
    }

    /**
     * Generates the default recovery parameters along with Outlook/OneDrive
     * specific restore parameters.
     *
     * @method   _generateDefaultRecoveryParam
     * @param    {object}   item                  Specifies the recovery item.
     * @param    {boolean}  [isMailboxJob=false]  Specifes whether the item is
     *                                            a mailbox job.
     * @return   {object}   Object specifying recovery details.
     */
    function _generateDefaultRecoveryParam(item, isMailboxJob) {
      // Specifies the Entity Details to be restored.
      item._objectParam = _generateDefaultRecoveryObjectParam(item);

      if (!isMailboxJob) {
        switch (true) {
          // Specifies the Outlook Parameters for restoring.
          // '_isMailbox'  is for older entity hierarchy(kMailbox).
          // '_hasMailbox' is for newer entity hierarhcy(kUser).
          case item._hasMailbox ||
            item._isMailbox ||
            item._isEmail ||
            item._isEmailFolder:
            item._outlookParam = _generateDefaultRecoveryOutlookParam(item);
            break;

          // Specifies the OneDrive parameters for restoring.
          case (item._hasOneDrive ||
                item._isFile ||
                item._isDirectory) &&
            $ctrl.shared.isOneDriveTask:
            item._oneDriveParam = _generateDefaultRecoveryOneDriveParam(item);
            break;

          // Specifies the SharePoint parameters for restoring.
          case (item._isOffice365SharePointSite ||
                item._isFile ||
                item._isDirectory) && $ctrl.shared.isSharePointTask:
            item._sharePointParam =
              _generateDefaultRecoverySharePointParam(item);
            break;
        }

      }

      return item;
    }

    /**
     * Initializes the default parameters for recovery.
     *
     * @method   _initializeDefaultTask
     */
    function _initializeDefaultOptions() {
      assign($ctrl.shared.defaultTask, {
        name: $ctrl.recoveryName,
        user: 'Admin',
        objects: [],
        continueOnError: true,
        newParentId: undefined,

        // Outlook Restore Parameters.
        outlookParameters: {
          outlookMailboxList: [],
          targetMailbox: undefined,
          targetFolderPath: undefined,
        },

        // OneDrive restore parameters.
        oneDriveParameters: {
          driveOwnerList: [],
          restoreToOriginalDrive: true,
          targetUser: undefined,
          targetDriveId: undefined,
          targetFolderPath: undefined,
        },

        // SharePoint restore parameters.
        sharePointParameters: {
          siteOwnerList: [],
          restoreToOriginalSite: true,
          targetSite: undefined,
          targetDocumentLibraryPrefix: undefined,
          targetDocumentLibraryName: undefined,
        },
      });

      // Initialize the servers.
      _fetchOffice365Servers();
    }

    /**
     * Creates a new instance of mailbox cart for recovery.
     *
     * @method   _initializeRecoveryCart
     */
    function _initializeRecoveryCart() {
      $ctrl.recovery = {
        // Stores the Objects to be restored and optimizes lookups for creating
        // nested recovery request.
        mailboxCart: Cart.newCart(_generateKey),

        // Stores the objects to be restored and optimizes lookups for creating
        // nested recovery request for OneDrive.
        oneDriveCart: Cart.newCart(_generateKey),

        // Stores the objects to be restored and optimizes lookups for creating
        // nested recovery request for SharePoint.
        sharePointCart: Cart.newCart(_generateKey),
      };
    }

    /**
     * Initializes the recovery type from the given search type.
     *
     * @method   _intializeRecoveryTaskType
     */
    function _intializeRecoveryTaskType() {
      switch ($ctrl.shared.searchItemType) {
        // For recovery of Outlook items, recovery type is 'kRecoverEmails'.
        case OFFICE365_SEARCH_TYPE.kMailboxSearch:
        case OFFICE365_SEARCH_TYPE.kEmailSearch:
          $ctrl.shared.defaultTask.type = 'kRecoverEmails';
          $ctrl.shared.isOutlookTask = true;
          $ctrl.shared.isOneDriveTask = false;
          $ctrl.shared.isSharePointTask = false;
          $ctrl.shared.isSharePointGranularTask = false;
          break;

        // For recovery of OneDrive items, recovery type is
        // 'kRecoverO365Drive'.
        case OFFICE365_SEARCH_TYPE.kOneDriveBrowse:
        case OFFICE365_SEARCH_TYPE.kOneDriveSearch:
        case OFFICE365_SEARCH_TYPE.kOneDriveDocumentSearch:
          $ctrl.shared.defaultTask.type = 'kRecoverO365Drive';
          $ctrl.shared.isOutlookTask = false;
          $ctrl.shared.isOneDriveTask = true;
          $ctrl.shared.isSharePointTask = false;
          $ctrl.shared.isSharePointGranularTask = false;
          break;

        // For the recovery of SharePoint Site, recovery type is
        // 'kRecoverSites'.
        case OFFICE365_SEARCH_TYPE.kSharePointSiteSearch:
          $ctrl.shared.defaultTask.type = 'kRecoverSites';
          $ctrl.shared.isOutlookTask = false;
          $ctrl.shared.isOneDriveTask = false;
          $ctrl.shared.isSharePointTask = true;
          $ctrl.shared.isSharePointGranularTask = false;
          break;

        case OFFICE365_SEARCH_TYPE.kSharePointSiteBrowse:
        case OFFICE365_SEARCH_TYPE.kSharePointSiteDocumentSearch:
          $ctrl.shared.defaultTask.type = 'kRecoverSites';
          $ctrl.shared.isOutlookTask = false;
          $ctrl.shared.isOneDriveTask = false;
          $ctrl.shared.isSharePointTask = true;
          $ctrl.shared.isSharePointGranularTask = true;
          break;
      }
    }
  }
})(angular);
