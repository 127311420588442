import { Injectable } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { AffectedFile, FileChangeType } from '@cohesity/api/argus';
import { FileOperation, ObjectServiceApi, SnapshotDiffResult } from '@cohesity/api/v2';
import { DataSearchResult } from '@cohesity/helix';
import { Poller } from '@cohesity/utils';
import { camelCase } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, filter as RxJSFilter, finalize, map } from 'rxjs/operators';

import { AffectedFilesFilterArguments } from '../security-shared.models';
import { FileDataSource, InlineFileSearchFilter } from './file-data-source';

/**
 * Data source implementation for the files search table.
 */
@Injectable()
export class InlineFileDataSource extends FileDataSource {
  constructor(private objectsService: ObjectServiceApi) {
    super();
  }

  /**
   * Poller for in progress simulations.
   */
  poller: Poller<SnapshotDiffResult>;

  /**
   * Executes file search
   *
   * @param   filter    The currently applied filter.
   * @param   page      The current page info.
   * @param   sort      Sort info - currently not enabled in the table and ignored here.
   * @return  An observable with the data search results.
   */
  getData(
    filter: InlineFileSearchFilter,
    page: PageEvent,
    _sort: Sort | null | void
  ): Observable<DataSearchResult<any>> {
    if (!filter) {
      return of(undefined);
    }

    // Set loading indicators
    this.hasData$.next(false);
    this.loadingData$.next(true);

    // Update pageSize and index
    const { pageSize, pageIndex } = page;
    filter.body.pageSize = pageSize;
    filter.body.pageNumber = pageIndex + 1;

    // Create poller
    this.poller = new Poller<SnapshotDiffResult>({
      acceptFirstIteration: false,
      interval: 5,
      maxRetries: 0,
      iterator: () =>
        this.objectsService.InternalApiGetSnapshotDiff(filter).pipe(catchError(() => this.emptyDataSet())),
      shouldContinue: (snapshotDiffResult): boolean => snapshotDiffResult?.status === 'kRunning',
    });

    return this.poller.poller.pipe(
      finalize(() => this.loadingData$.next(false)),
      RxJSFilter(response => response.status !== 'kRunning'),
      map((response: SnapshotDiffResult) => {
        const { status, fileOperations } = response;
        const errorStatus: SnapshotDiffResult['status'][] = [
          'kError',
          'kObjectNotFound',
          'kSnapshotExpired',
          'kSnapshotNotFound'
        ];
        const hasError  = errorStatus.includes(status);

        if (hasError || fileOperations?.length === 0) {
          return this.emptyDataSet();
        }
        this.hasData$.next(true);
        return {
          totalSize: filter.totalRecords,
          data: this.transformAffectedFiles(fileOperations || []),
        };
      }),
      catchError(() =>
        /*
         * No need to show errors, since the API is available 6.3 onwards.
         * So return undefined and handle it in template.
         */
        this.emptyDataSet()
      )
    );
  }

  /**
   * Converts different file objects to common AffectedFile
   *
   * @param fileOperation change type file object
   * @returns converted file object
   */
  transformAffectedFiles(fileOperations: FileOperation[]): AffectedFile[] {
    return fileOperations.map(fileOperation => {
      let changeType: FileChangeType;
      switch (camelCase(fileOperation.operation)) {
        case 'kDeleted':
          changeType = FileChangeType.KDeleted;
          break;

        case 'kAdded':
          changeType = FileChangeType.KAddedOrModified;
          break;

        default:
          changeType = FileChangeType.KAny;
      }

      return {
        changeType,
        filePath: fileOperation.filePath,
      };
    });
  }

  /**
   * Sets required filter data based on table source
   *
   * @param filters anomaly and classification details details
   */
  setFilters(filters: AffectedFilesFilterArguments) {
    const { clusterId, properties } = filters.anomaly;
    const { timestampDataPoints } = filters.seriesData;
    const { modified, deleted } = filters.fileStats;

    this.filter = {
      accessClusterId: clusterId,
      totalRecords: modified + deleted,
      id: properties.entityId,
      body: {
        baseSnapshotJobInstanceId: Number(properties.jobInstanceId),
        baseSnapshotTimeUsecs: Number(properties.jobStartTimeUsecs),
        clusterId,
        entityType: properties.environment as any,
        incarnationId: Number(properties.clusterIncarnationId),
        jobId: Number(properties.jobId),
        partitionId: Number(properties.clusterPartitionId),
        snapshotJobInstanceId: timestampDataPoints?.[filters?.selectedPoint?.x]?.[0]?.jobInstanceId,
        snapshotTimeUsecs: Number(filters?.selectedPoint?.x),
        // These values will be updated in data source, assigning defaults for interface
        pageNumber: 0,
        pageSize: 50,
      },
    };
  }

  /**
   * Emit observable and return undefined.
   */
  emptyDataSet() {
    this.hasData$.next(false);
    return of(undefined);
  }

  /**
   * Clears polling and timeout.
   */
  clearPolling() {
    if (this.poller) {
      this.poller.finish();
      this.poller = undefined;
    }
  }

  /**
   * Resets all essential data when component is destroyed
   */
  cleanUp() {
    this.clearPolling();
    this.hasData$.next(false);
  }
}
