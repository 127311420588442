import { noop } from 'lodash-es';
// state management module

;(function(angular, undefined) {
  'use strict';
  var moduleName = 'C.stateManagement';

  angular.module(moduleName, ['ui.router'])
    .config(uiStateDecoratorConfig)
    .service('StateManagementService', StateManagementServiceFn);

  // decorator $state with goto home state shortcut.
  function uiStateDecoratorConfig($provide) {
    $provide.decorator('$state', addGoHomeToState);
    $provide.decorator('uiSrefDirective', canAccessUiSref);
    $provide.decorator('uiStateDirective', canAccessUiState);
  }

  /**
   * decorate $state with gotoHome state shortcut which internally uses
   * HOME_STATE constant to determine the current default state.
   *
   * @example
      $state.goHome();       // navigated to dashboard by default

      HOME_STATE.set('jobs');

      $state.goHome();       // navigated to protection job page
   */
  function addGoHomeToState($delegate, $location, HOME_STATE, GLOBAL_STATE_PARAMS) {
    /**
     * goHome is an shortcut utility used to quickly navigate to set home page
     *
     * @method   goHome
     * @param    {Object}  [options=]   Optional state transition options
     */
    $delegate.goHome = function goHome(options) {
      const queryParams = $location.search();
      const transitionParams = {};

      // Pass on only global params from the query params
      Object.keys(GLOBAL_STATE_PARAMS).forEach(key => {
        if (queryParams.hasOwnProperty(key)) {
          transitionParams[key] = queryParams[key];
        }
      });

      HOME_STATE.goHome(options, transitionParams);
    };

    return $delegate;
  }

  function StateManagementServiceFn(_, $rootScope, $state, $log,
    NgStateManagementService) {

    var self = {
      addPreviousState: NgStateManagementService.addPreviousState.bind(NgStateManagementService),
      canUserAccessState: NgStateManagementService.canUserAccessState.bind(NgStateManagementService),
      goToEffectiveFallbackState: goToEffectiveFallbackState,
      goToPreviousState: NgStateManagementService.goToPreviousState.bind(NgStateManagementService),
      implementCanAccess: implementCanAccess,
    };

    // Exposing canUserAccessState on $rootScope in order to hide the
    // uib-tab/tabs if required access is not held by the logged in user.
    $rootScope.canUserAccessState = self.canUserAccessState;

    // Exporting to $rootScope as workaround for a circular dependency
    // because UserService.js redirectPostLogin() methods need to use goToEffectiveFallbackState() method.
    // StateManagementService.js <- UserService.js <- NgStateManagementService.ts <- StateManagementService.js
    // to fix this we need to break dependency between UserService.js & NgStateManagementService.ts
    // by moving state access context related stuff into a new service having privs & other stuff
    // which is currently kept with UserService.js after this UserService.js NgStateManagementService.ts
    // and other's will use this new service new service & then we can safely inject
    // NgStateManagementService.ts at UserService.js
    $rootScope.goToEffectiveFallbackState = goToEffectiveFallbackState;

    return self;

    /**
     * Redirects the user according the fallback states.
     * 1. Check if the state is accessible, if yes, then go to the state.
     * 2. If not, then check for the fallback state recursively. If not found,
     *    then go to default state (Home).
     *
     * @method   goToEffectiveFallbackState
     * @param    {Object}   state   The state to check for.
     * @param    {Object}   params   Params to be passed to transition
     */
    function goToEffectiveFallbackState(state, params) {
      // Sets the default state if state is undefined.
      state = state || $state.current;

      if (self.canUserAccessState(state.name, null, true)) {
          // A simple hack as going to the same state with the param reload
          // doesn't actually reloads the state. Hence, reload manually.
          if (state.name === $state.current.name) {
            $state.reload();
          } else {
            $state.go(state.name, params || {}, {reload: true});
          }
        } else if (state.parentState) {
          // If a fallback state exists, then call the same function with the
          // fallback state.
          goToEffectiveFallbackState($state.get(state.parentState));
        } else {
          // If no access to the state or the fallback states, then go to the
          // default page.
          $state.goHome({reload: true});
        }
    }

    /**
     * Implement the can access check for ui-sref and ui-state directives.
     *
     * @method   implementCanAccess
     * @param    {Object}      scope            The directive scope.
     * @param    {Object}      element          The directive element.
     * @param    {Object}      attrs            The directive attributes.
     * @param    {Function}    originalLinkFn   The ui-sref or ui-state original link fn.
     * @param    {Function}    getWatchGroupFn  The callback used to get the watcher expression for state name & params.
     * @param    {Boolean}     [noWatch=false]  If true then immediately check for access and skip the watcher setup.
     */
    function implementCanAccess(scope, element, attrs, originalLinkFn, getWatchGroupFn, noWatch) {
      let canAccess = true;
      let currentStateName;

      // Optional attribute if present then skip adding no access class can be
      // used to show custom disabling reason for the link.
      const skipNoAccessClass = attrs.hasOwnProperty('skipNoAccessClass');

      // get the watch watchGroup expressions.
      const watchGroupExprs = getWatchGroupFn();

      if (noWatch) {
        // immediately checking for access.
        // get current value by evaluating the watch expression.
        const currentValues = watchGroupExprs.map(watchExpr => scope.$eval(watchExpr));

        // checking state access & adding relevant classes.
        handleStateChange(currentValues);

        if (canAccess) {
          // call original ui-state link fn.
          originalLinkFn.apply(this, arguments);
        } else {
          logError();
        }
      } else {
        // watch for target state changes and perform state access check.
        let deregisterWatch = scope.$watchGroup(watchGroupExprs, handleStateChange);

        // handle click and prevent navigation if link is not accessible.
        element.on('click', handleClick);

        // call original ui-state link fn.
        originalLinkFn.apply(this, arguments);

        // cleanup watchers on scope destroy.
        scope.$on('$destroy', function cleanUp() {
          (deregisterWatch || noop)();
          element.off('click', handleClick);
        });
      }

      /**
       * Perform new state access test and toggle no-access class accordingly.
       *
       * @method   handleStateChange
       * @param    {stateName}        stateName     The new state name.
       */
      function handleStateChange(newValues) {
        var stateName = newValues[0];
        var stateParams = newValues[1];

        // keeping a closure of stateName used while logging the state for which
        // navigation is prevented.
        currentStateName = stateName;

        // keeping a closure of canAccess result which will be used on click
        // to prevent navigation.
        canAccess = self.canUserAccessState(stateName, stateParams, true);

        // toggle 'no-access' class based on state access and if
        // skipNoAccessClass attribute is present then don't add 'no-access'
        // class.
        element.removeClass('no-access');
        if (!canAccess && !skipNoAccessClass) {
          element.addClass('no-access');
        }
      }

      /**
       * Prevent state navigation if link is not accessible.
       *
       * @method   handleClick
       * @param    {$event}        $event     The on click $event object.
       */
      function handleClick($event) {
        if (!canAccess) {
          // prevent browser default behaviour.
          $event.preventDefault();

          // stop bubbling the event to parent element or any other click
          // handler on the same element this will prevent original ui-state
          // click handler execution.
          $event.stopImmediatePropagation();
          logError();
        }
      }

      /**
       * Log the error message.
       *
       * @method   logError
       */
      function logError() {
        // log target state in dev mode or when productionLogging is on for
        // debugging.
        $log.log(
          moduleName + '.canAccessUiState/UiSref: `' + (attrs.uiState || attrs.uiSref) +
          '` state is not accessible by users since it needs `' +
          ($state.get(currentStateName) || {}).canAccess + '` privilege.'
        );
      }
    }
  }

  /**
   * @ngdoc decorator
   * @name        ui.router
   * @method      canAccessUiSref
   *
   * @description
   * Extend capabilities of ui-sref by performing state access check and disable
   * the link if it not accessible by the user.
   *
   * @example
   * <a ui-sref="protection">goto protection jobs</a>
   *
   * if user is not having PROTECTION_VIEW then 'no-access' class will be added
   * which will reset the default anchor styling and prevent navigation on click
   *
   * resultant HTML
   * <a ui-sref="protection" class="no-access">goto protection jobs</a>
   */
  function canAccessUiSref($delegate, StateManagementService) {
    var originalLinkFn = $delegate[0].link;

    // deleting the old link fn.
    $delegate[0].link = undefined;

    // decorating compile fn.
    $delegate[0].compile = function newCompileFn() {
      return {
        pre: noop,
        post: canAccessUiSrefLinkFn,
      };
    };

    /**
     * Parse input ui-sref expression and return state name and params expression.
     *
     * code taken from ui-router
     * https://github.com/angular-ui/ui-router/blob/f144e8b02664ee0c5c7b188fd943f5680e6b649b/src/directives/stateDirectives.ts#L41
     *
     * @method   parseStateRef
     * @param    {string}        stateConfig     The state config.
     * @return   {string}        Extracted state name.
     */
    function parseStateRef(ref) {
      var parsed;

      /**
       * Perform test for presence of only params in state config.
       * regex expected behaviour: https://regex101.com/r/2h7y3H/1
       *
       * {severity: $ctrl.severity}               true
       * alerts({severity: $ctrl.severity})       false
       * alerts                                   false
       */
      var paramsOnly = ref.match(/^\s*({[^}]*})\s*$/);

      // if target name is not defined then consider current state as target
      // state.
      if (paramsOnly) {
        ref = '(' + paramsOnly[1] + ')';
      }

      /**
       * parse and extract the target state name and params eg.
       * regex expected behaviour: https://regex101.com/r/lXbNvF/1
       *
       * alerts({severity: $ctrl.severity}) would be parsed to
       * parsed[0]: original input         alerts({severity: $ctrl.severity})
       * parsed[1]: state name             alerts
       * parsed[2]: state params           ({severity: $ctrl.severity})
       * parsed[3]: state params obj       {severity: $ctrl.severity}
       */
      parsed = ref
        // prune by removing new line.
        .replace(/\n/g, ' ')
        .match(/^\s*([^(]*?)\s*(\((.*)\))?\s*$/);

      if (!parsed || parsed.length !== 4) {
        throw new Error(`Invalid state ref '${ref}'`);
      }

      return {
        state: parsed[1],
        paramExpr: parsed[3],
      };
    }

    /**
     * new link fn for ui-sref which perform access check for target state.
     *
     * @method   canAccessUiSrefLinkFn
     */
    function canAccessUiSrefLinkFn(scope, element, attrs) {
      // parsing the ui-sref and extract state name and params.
      var ref = parseStateRef(attrs.uiSref);
      var args = [
        scope, element, attrs, originalLinkFn,
        function getWatchGroup() {
          return [`'${ref.state}'`, ref.paramExpr];
        }
      ];

      if (ref.paramExpr) {
        // setup watcher if param expression is found eg.
        // ui-sref"alerts({severity: $ctrl.severity})"
        StateManagementService.implementCanAccess.apply(this, args);
      } else {
        // immediately checking for access eg.
        // ui-sref="alerts"
        StateManagementService.implementCanAccess.apply(this, args.concat(true));
      }
    }

    return $delegate;
  }

  /**
   * @ngdoc decorator
   * @name        ui.router
   * @method      canAccessUiState
   *
   * @description
   * Extend capabilities of ui-state by performing state access check and
   * disable the link if it not accessible by the user.
   *
   * NOTE: ui-state will keep on watching the target state and perform state
   * access check.
   *
   * @example
   * $ctrl.toState = 'protection';
   * <a ui-state="$ctrl.toState">goto protection jobs</a>
   *
   * if user is not having PROTECTION_VIEW then 'no-access' class will be added
   * which will reset the default anchor styling and prevent navigation on click
   *
   * resultant HTML
   * <a ui-state="protection" class="no-access">goto protection jobs</a>
   */
  function canAccessUiState($delegate, StateManagementService) {
    var originalLinkFn = $delegate[0].link;

    // deleting the old link fn.
    delete $delegate[0].link;

    // decorating compile fn.
    $delegate[0].compile = function newCompileFn() {
      return {
        pre: noop,
        post: canAccessUiStateLinkFn,
      };
    };

    /**
     * new link fn for ui-sref which perform access check for target state.
     *
     * @method   canAccessUiStateLinkFn
     */
    function canAccessUiStateLinkFn(scope, element, attrs) {
      StateManagementService.implementCanAccess(
        scope, element, attrs, originalLinkFn,
        function getWatchGroup() {
          return [attrs.uiState, attrs.uiStateParams];
        }
      );
    }

    return $delegate;
  }
})(angular);
