import { first } from 'lodash-es';
import { find } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// Module: Add/Edit Identity Provider

;(function(angular) {
  'use strict';

  angular
    .module('C.idp')
    .controller('modifyIdpCtrl', modifyIdpCtrlFn);

  /**
   * Controller for the Create New IDP
   */
  function modifyIdpCtrlFn(_, $q, $state, IdpService,
    TenantService, UserService, cMessage, evalAJAX, StateManagementService,
    $rootScope) {
    var $ctrl = this;

    // Controller Definition
    assign($ctrl, {
      // Controller Variables
      dataReady: false,
      idpConfig: {},
      idpForm: {},
      isEditMode: !!$state.params.id,
      organization: [],
      roles: [],

      // Controller Methods
      $onInit: $onInit,
      readSSOCertificate: readSSOCertificate,
      submitForm: submitForm,
      goBack: goBack,
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      _initializeData();
    }

    /**
     * Initialize the data for the responses
     *
     * @method   _initializeData
     */
    function _initializeData() {
      var promises = {
        roles: UserService.getAllRoles(),
      };

      if ($ctrl.isEditMode) {
        promises.idpConfig = IdpService.getExternalIdp({
          ids: [$state.params.id]
        });
        promises.tenants = $rootScope.user.privs.ORGANIZATION_VIEW ?
          TenantService.getTenants() : $q.resolve([]);
      }

      $ctrl.dataReady = false;

      $q.all(promises).then(function gotPromises(resps) {
        var selectedRole;
        $ctrl.roles = resps.roles;

        // If it is edit mode, poplate with the configured values from backend
        if ($ctrl.isEditMode) {
          $ctrl.idpConfig = resps.idpConfig;

          // Fill Tenant Information if present
          if ($ctrl.idpConfig.tenantId) {
            $ctrl.idpConfig._tenantName = get(find(resps.tenants,
              {tenantId: $ctrl.idpConfig.tenantId}), 'name');
          }

          // Fill the file select button
          $ctrl.idpConfig.ssoCertificate = {
            name: $ctrl.idpConfig.certificateFilename,
            type: 'application/x-x509-ca-cert',
          };

            // Fill the user role
          selectedRole =find(resps.roles,
            { name: first($ctrl.idpConfig.roles) });
          $ctrl.idpConfig.idpRoles = selectedRole;
        }
      }, evalAJAX.errorMessage).finally(function finalizePromise() {
        $ctrl.dataReady = true;
      });
    }

    /**
     * Reads the SSO certificate on loading file
     *
     * @method     readSSOCertificate
     */
    function readSSOCertificate() {
      var ssoCertificateReader = new FileReader();
      ssoCertificateReader.readAsText($ctrl.idpConfig.ssoCertificate);
      ssoCertificateReader.onload = function onFileLoad() {
        $ctrl.idpConfig.certificate = ssoCertificateReader.result;
      };
    }

    /**
     * Submit the form
     *
     * @method   submitForm
     * @param    {object}    idpConfig   The IDP configuration
     */
    function submitForm(idpConfig) {
      if ($ctrl.idpForm.$invalid) {
        return;
      }

      $ctrl.updatingSSO = true;

      if ($ctrl.isEditMode) {
        IdpService.updateExternalIdp(idpConfig).then(function successIdp(response) {
          cMessage.success({
            textKey: 'sso.updatedContent',
            textKeyContext: {
              sso: idpConfig.domain,
              tenant: get(idpConfig, 'tenant.name', 'cluster'),
            },
          });
          $state.go('access-management.sso');
        }, evalAJAX.errorMessage(response)).finally(function finalizePromise() {
          $ctrl.updatingSSO = false;
        });

      } else {
        IdpService.createExternalIdp(idpConfig).then(function successIdp() {
          cMessage.success({
            textKey: 'sso.createdContent',
            textKeyContext: {
              sso: idpConfig.domain,
              tenant: get(idpConfig, 'tenant.name', 'cluster'),
            },
          });
          $state.go('access-management.sso');
        }, evalAJAX.errorMessage).finally(function finalizePromise() {
          $ctrl.updatingSSO = false;
        });
      }
    }

    /**
     * Navigates to previous router state.
     */
    function goBack() {
      StateManagementService.goToPreviousState('access-management.sso');
    }
  }
})(angular);
