import { chain } from 'lodash-es';
import { isObject } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Tenant AD/LDAP Modal Component

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Resolved bindings provided via uib-modal
       *
       * @type  {Object}  [resolve=undefined]
       */
      resolve: '=',

      /**
       * uib-modal instance used to close or dismiss then modal
       *
       * @type  {Object}  [resolve=modalInstance]
       */
      modalInstance: '=',
    },
    controller: 'TenantAdLdapModalCtrl',
    templateUrl: 'app/admin/access-management/tenants/tenant-ad-ldap-modal/tenant-ad-ldap-modal.html',
  };

  angular.module('C.tenants')
    .controller('TenantAdLdapModalCtrl', tenantAdLdapModalCtrlFn)
    .component('tenantAdLdapModal', configOptions);

  function tenantAdLdapModalCtrlFn(_, evalAJAX, UserService, $translate) {
    var $ctrl = this;

    assign($ctrl, {
      // UI states.
      submitting: false,
      isLoading: false,

      keystoneInput: {},
      keystoneOutput: {},
      principalToAssignByDomainMap: {},
      selectedADs: {},
      selectedKeystone: undefined,
      selectedLdaps: {},
      tenantId: null,
      tenantPrincipalSidSet: new Set(),
      unAssignableDomainNameSet: new Set(),

      // Component methods.
      cancel: cancel,
      canSelectAd: canSelectAd,
      getAdDisablingReason: getAdDisablingReason,
      save: save,
      updateKeystoneSelection: updateKeystoneSelection,

      // Component life-cycle methods.
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      if (isObject($ctrl.resolve.selectedADs)) {
        $ctrl.selectedADs = $ctrl.resolve.selectedADs;
      }

      if (isObject($ctrl.resolve.selectedLdaps)) {
        $ctrl.selectedLdaps = $ctrl.resolve.selectedLdaps;
      }

      if (isObject($ctrl.resolve.selectedKeystone)) {
        $ctrl.selectedKeystone = $ctrl.resolve.selectedKeystone;
      }

      $ctrl.keystoneInput = {
        enableSelect: true,
        enableDelete: false,
        selectedKeystone: $ctrl.resolve.selectedKeystone,
        keystoneList: $ctrl.resolve.keystoneList.keystones,
      };

      $ctrl.keystoneOutput = {
        keystoneSelectionChange: $ctrl.updateKeystoneSelection,
      };

      $ctrl.tenantId = $ctrl.resolve.tenantId;
      $ctrl.tenantPrincipalSidSet = new Set(
        chain($ctrl.resolve).get('tenantPrincipals.principals', []).map('sid').value()
      );
      $ctrl.resolve.tenantPrincipals.principals || [];

      getData();
    }

    /**
     * Get data & dependence's required for AD assignment validations.
     *
     * @method  getData
     */
    function getData() {
      $ctrl.isLoading = true;

      // get all Users/Groups aka principals.
      UserService.getAllPrincipals().then(principals => {
        $ctrl.unAssignableDomainNameSet = new Set();
        $ctrl.principalToAssignByDomainMap = {};

        chain(principals)
          .groupBy('domain')
          .forEach((principalList, domain) => {
            // do nothing for local domains users.
            if (domain === 'LOCAL') {
              return;
            }

            // Unique set of Users/Groups tenantIds.
            // NOTE: having undefined values in the set means SP (service provider).
            const tenantIdSet = new Set();
            principalList.forEach(principal => {
              [].concat(principal.tenantId || principal.tenantIds).forEach(tenantIdSet.add, tenantIdSet);
            });

            // abbreviations used
            // SP:         service provider.
            // T1:         currently selected tenant for modification.
            // T2 or T3:   some other tenant.

            // Indicate there exist some Users/Groups belonging to SP.
            const hasSomeServiceProviderPrincipal = tenantIdSet.has(undefined);

            // Indicate there exist some Users/Groups belonging to T1.
            const hasSomeEditedTenantPrincipal = tenantIdSet.has($ctrl.tenantId);

            // AD can't be assigned if un-assigned Users/Groups is
            // case 1: owned by 2 or more tenants including & excluding SP eg. (SP T1 T2) or (T1 T2 T3)
            // case 2: owned by SP and some other tenants eg. (SP T2) or (SP T3)
            // case 3: owned by some other tenants other that SP eg. (T2) or (T3)
            if (
              tenantIdSet.size > 2 ||
              (tenantIdSet.size === 2 && hasSomeServiceProviderPrincipal && !hasSomeEditedTenantPrincipal) ||
              (tenantIdSet.size === 1 && !hasSomeServiceProviderPrincipal && !hasSomeEditedTenantPrincipal)
            ) {
              $ctrl.unAssignableDomainNameSet.add(domain);
            }

            // AD can be assigned if un-assigned Users/Groups is
            // case 1: owned by SP and currently selected tenant for modification eg. (SP T1)
            // case 2: owned by SP alone eg. (SP)
            // case 3: owned by currently selected tenant for modification alone eg. (T1)

            // finding the list of un-assigned Users/Groups.
            if (hasSomeServiceProviderPrincipal) {
              const unassignedPrincipals = principalList.filter(
                principal => !$ctrl.tenantPrincipalSidSet.has(principal.sid)
              );

              if (unassignedPrincipals.length) {
                $ctrl.principalToAssignByDomainMap[domain] = unassignedPrincipals;
              }
            }
          })
          .value();
      }, evalAJAX.errorMessage)
      .finally(() => $ctrl.isLoading = false);
    }

    /**
     * Determines whether AD selection is allowed or not.
     *
     * @method  canSelectAd
     * @param   {Object}    ad   The AD details.
     * @returns {boolean}   True if AD selection is allowed else false.
     */
    function canSelectAd(ad) {
      // AD can't be assigned if there exist some Users/Groups assigned to some other tenant.
      return !$ctrl.unAssignableDomainNameSet.has(ad.domainName) &&
        // AD can be assigned if all its Users/Groups is also being assigned.
        ($ctrl.principalToAssignByDomainMap[ad.domainName] || []).length === 0;
    }

    /**
     * Return the AD selection disabling reason translated text.
     *
     * @method  getAdDisablingReason
     * @param   {Object}   ad   The AD details.
     * @returns {String}   The selection disabling reason text.
     */
    function getAdDisablingReason(ad) {
      // do nothing if you can select the AD.
      if ($ctrl.canSelectAd(ad)) {
        return '';
      }

      const unassignedPrincipals = ($ctrl.principalToAssignByDomainMap[ad.domainName] || []);

      // AD can be assigned if all its Users/Groups is also being assigned.
      if (unassignedPrincipals.length) {
        return $translate.instant(
          'tenants.disableAdAssignmentAsRequiredPrincicalAssignment',
          { principals: unassignedPrincipals }
        );
      }

      // AD can't be assigned if there exist some Users/Groups assigned to some other tenant.
      return $translate.instant('tenants.disableAdAssignmentAsUsedByOtherTenant');
    }

    /**
     * Helper function to updated selectedKeystone when user change keystone.
     *
     * @method   updateKeystoneSelection
     * @private  updatedKeystone   New selected keystone.
     */
    function updateKeystoneSelection(updatedKeystone) {
      if (updatedKeystone) {
        $ctrl.selectedKeystone = updatedKeystone;
      };
    }

    /**
     * On save, tag selected access permissions with the provided tenant.
     *
     * @method   save
     */
    function save() {
      $ctrl.modalInstance.close({
        ads: $ctrl.selectedADs,
        keystone: $ctrl.selectedKeystone,
        ldaps: $ctrl.selectedLdaps,
      });
    }

    /**
     * handles on cancel action for modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.modalInstance.dismiss();
    }
  }

})(angular);
