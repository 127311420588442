import { rpaasDynamicComponents } from './app-services/rpaas/rpaas.config';
import { DynamicComponentManifest } from './dynamic-component-loader/dynamic-component-manifest';

/**
 * This file contains a list of components in lazy-loaded modules which can be
 * loaded dynamically. Typically, a lazy loaded module is loaded by navigating to
 * a route which will automatically load the module. In some cases - especially
 * dialogs, a component needs to be exposed so that it can be used in other parts
 * of the application. This file contains a list of ids of all of the components
 * that can be dynamically loaded. Each component needs a unique id, and the path
 * to the lazy-loaded module that is contained in.
 *
 * To configure the lazy-loaded module, add an import for
 * DynamicComponentLoaderModule.forChild(componentClass, 'component-id').
 * This will register the component which can then be loaded with the
 * DynamicComponentLoaderService.
 */
export const manifests: DynamicComponentManifest[] = [
  ...rpaasDynamicComponents,
  {
    componentId: 'register-active-directory-dialog',
    loadChildren: () =>
      import('./modules/protection/active-directory/active-directory.module').then(m => m.ActiveDirectoryModule),
  },
  {
    componentId: 'ad-quick-protection-dialog',
    loadChildren: () =>
      import('./modules/protection/active-directory/active-directory.module').then(m => m.ActiveDirectoryModule),
  },
  {
    componentId: 'ad-protection-group-settings',
    loadChildren: () =>
      import('./modules/protection/active-directory/active-directory.module').then(m => m.ActiveDirectoryModule),
  },
  {
    componentId: 'active-directory-recovery-details',
    loadChildren: () =>
      import('./modules/protection/active-directory/active-directory.module').then(m => m.ActiveDirectoryModule),
  },
  {
    componentId: 'aws-rds-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/aws-rds/aws-rds.module').then(m => m.AwsRdsModule),
  },
  {
    componentId: 'aws-db-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/aws-db/aws-db.module').then(m => m.AwsDbModule),
  },
  {
    componentId: 'aws-rds-protection-group-settings',
    loadChildren: () => import('./modules/protection/aws-rds/aws-rds.module').then(m => m.AwsRdsModule),
  },
  {
    componentId: 'aws-s3-quick-protect-dialog',
    loadChildren: () => import('./modules/protection/aws-s3/aws-s3.module').then(m => m.AwsS3Module),
  },
  {
    componentId: 'aws-s3-object-protection-settings',
    loadChildren: () => import('./modules/protection/aws-s3/aws-s3.module').then(m => m.AwsS3Module),
  },
  {
    componentId: 'aws-authorize-db-dialog',
    loadChildren: () => import('./modules/sources-shared/sources-shared.module').then(m => m.SourcesSharedModule),
  },
  {
    componentId: 'app-install-requirements',
    loadChildren: () => import('./modules/runbooks/runbooks.module').then(m => m.RunbooksModule),
  },
  {
    componentId: 'register-san-dialog',
    loadChildren: () => import('./modules/protection/san/san.module').then(m => m.SANModule),
  },
  {
    componentId: 'register-cassandra-dialog',
    loadChildren: () => import('./modules/sources/cassandra/cassandra.module').then(m => m.CassandraSourceModule),
  },
  {
    componentId: 'register-mongodb-dialog',
    loadChildren: () => import('./modules/sources/mongodb/mongodb.module').then(m => m.MongoDBSourceModule),
  },
  {
    componentId: 'register-mongodb-physical-dialog',
    loadChildren: () => import('./modules/protection/mongodb-physical/mongodb-physical.module').then(m => m.MongoDBPhysicalModule),
  },
  {
    componentId: 'register-couchbase-dialog',
    loadChildren: () => import('./modules/sources/couchbase/couchbase.module').then(m => m.CouchbaseSourceModule),
  },
  {
    componentId: 'edit-hadoop-kerberos',
    loadChildren: () => import('./modules/sources/hadoop/hadoop.module').then(m => m.HadoopSourceModule),
  },
  {
    componentId: 'register-hadoop-dialog',
    loadChildren: () => import('./modules/sources/hadoop/hadoop.module').then(m => m.HadoopSourceModule),
  },
  {
    componentId: 'register-exchange-dialog',
    loadChildren: () => import('./modules/protection/exchange/exchange.module').then(m => m.ExchangeModule),
  },
  {
    componentId: 'register-dag-exchange-dialog',
    loadChildren: () => import('./modules/protection/exchange/exchange.module').then(m => m.ExchangeModule),
  },
  {
    componentId: 'exchange-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/exchange/exchange.module').then(m => m.ExchangeModule),
  },
  {
    componentId: 'exchange-protection-group-settings',
    loadChildren: () => import('./modules/protection/exchange/exchange.module').then(m => m.ExchangeModule),
  },
  {
    // TODO(tauseef): Make a state for Office365 once migration to new NG UX is done.
    // Note: This is added here to dynamically load this module. The module is lazy loaded through an explicit option
    // added within angular.json. Components can be lazyloaded this way.
    componentId: 'office365-ng-source-tree',
    loadChildren: () => import('src/app/modules/protection/office365/office365.module').then(m => m.Office365Module),
  },
  {
    componentId: 'profile-delete',
    loadChildren: () => import('./modules/runbooks/runbooks.module').then(m => m.RunbooksModule),
  },
  {
    componentId: 'policy-builder-dialog',
    loadChildren: () =>
      import('./modules/protection-policy/protection-policy.module').then(m => m.ProtectionPolicyModule),
  },
  {
    componentId: 'protect-once-policy-dialog',
    loadChildren: () =>
      import('./modules/protection-policy/protection-policy.module').then(m => m.ProtectionPolicyModule),
  },
  {
    componentId: 'autofill-ips-dialog',
    loadChildren: () =>
      import('./modules/admin/cluster/cluster-create/cluster-create.module').then(m => m.ClusterCreateModule),
  },
  {
    componentId: 'flash-recover-onboarding-dialog',
    loadChildren: () =>
      import('./modules/admin/cluster/remote-disks/onboarding/flashrecover-onboarding.module').then(
        m => m.FlashRecoverOnboardingModule
      ),
  },
  {
    componentId: 'switch-account-dialog',
    loadChildren: () => import('./modules/company-account/company-account.module').then(m => m.CompanyAccountModule),
  },
  {
    componentId: 'assign-org-keystone',
    loadChildren: () =>
      import('./modules/access-management/on-prem-access-management/keystones/keystones.module').then(
        m => m.KeystonesModule
      ),
  },
  {
    componentId: 'add-swift-roles-dialog',
    loadChildren: () =>
      import('./modules/access-management/on-prem-access-management/keystones/keystones.module').then(
        m => m.KeystonesModule
      ),
  },
  {
    componentId: 'apps-network-dialog',
    loadChildren: () => import('./modules/apps/apps.module').then(m => m.AppsModule),
  },
  {
    componentId: 'apps-update-inetwork-dialog',
    loadChildren: () => import('./modules/apps/apps.module').then(m => m.AppsModule),
  },
  {
    componentId: 'apps-confirmation-dialog',
    loadChildren: () => import('./modules/apps/apps.module').then(m => m.AppsModule),
  },
  {
    componentId: 'assign-racks-chassis-dialog',
    loadChildren: () =>
      import('./modules/admin/cluster/rack-chassis/rack-chassis.module').then(m => m.RackChassisModule),
  },
  {
    componentId: 'netgroup-whitelist',
    loadChildren: () => import('./modules/views/views.module').then(m => m.ViewsModule),
  },
  {
    componentId: 'modify-view-dialog',
    loadChildren: () => import('./modules/views-shared/views-shared.module').then(m => m.ViewsSharedModule),
  },
  {
    componentId: 'create-view-dialog-new',
    loadChildren: () => import('./modules/views-shared/views-shared.module').then(m => m.ViewsSharedModule),
  },
  {
    componentId: 'create-storage-domain-dialog',
    loadChildren: () => import('./modules/cluster/cluster.module').then(m => m.ClusterModule),
  },
  {
    componentId: 'delete-view-dialog',
    loadChildren: () => import('./modules/views/views.module').then(m => m.ViewsModule),
  },
  {
    componentId: 'smb-super-users',
    loadChildren: () => import('./modules/views/views.module').then(m => m.ViewsModule),
  },
  {
    componentId: 'whitelist-dialog',
    loadChildren: () => import('./modules/views/views.module').then(m => m.ViewsModule),
  },
  {
    componentId: 'whitelist-table',
    loadChildren: () => import('./modules/views/views.module').then(m => m.ViewsModule),
  },
  {
    componentId: 'edit-node-ipmi',
    loadChildren: () => import('./modules/admin/networking/ipmi/ipmi.module').then(m => m.IpmiModule),
  },
  {
    componentId: 'add-edit-vip-vlan',
    loadChildren: () => import('./modules/admin/networking/vips-vlans/vips-vlans.module').then(m => m.VipsVlansModule),
  },
  {
    componentId: 'add-static-route',
    loadChildren: () =>
      import('./modules/admin/networking/static-routes/static-routes.module').then(m => m.StaticRoutesModule),
  },
  {
    componentId: 'nosql-protection-group-settings',
    loadChildren: () => import('./modules/protection/nosql/nosql.module').then(m => m.NosqlModule),
  },
  {
    componentId: 'nas-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/nas/nas.module').then(m => m.NasModule),
  },
  {
    componentId: 'nas-protection-group-settings',
    loadChildren: () => import('./modules/protection/nas/nas.module').then(m => m.NasModule),
  },
  {
    componentId: 'vm-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/vm/vm.module').then(m => m.VmModule),
  },
  {
    componentId: 'saphana-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/saphana/saphana.module').then(m => m.SapHanaModule),
  },
  {
    componentId: 'office365-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/office365/office365.module').then(m => m.Office365Module),
  },
  {
    componentId: 'office365-protection-group-settings',
    loadChildren: () => import('./modules/protection/office365/office365.module').then(m => m.Office365Module),
  },
  {
    componentId: 'vm-protection-group-settings',
    loadChildren: () => import('./modules/protection/vm/vm.module').then(m => m.VmModule),
  },
  {
    componentId: 'physical-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/physical/physical.module').then(m => m.PhysicalModule),
  },
  {
    componentId: 'sql-protection-group-settings',
    loadChildren: () => import('./modules/protection/sql/sql.module').then(m => m.SqlModule),
  },
  {
    componentId: 'sql-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/sql/sql.module').then(m => m.SqlModule),
  },
  {
    componentId: 'oracle-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/oracle/oracle.module').then(m => m.OracleModule),
  },
  {
    componentId: 'physical-protection-group-settings',
    loadChildren: () => import('./modules/protection/physical/physical.module').then(m => m.PhysicalModule),
  },
  {
    componentId: 'oracle-protection-group-settings',
    loadChildren: () => import('./modules/protection/oracle/oracle.module').then(m => m.OracleModule),
  },
  {
    componentId: 'remote-adapter-protection-group-settings',
    loadChildren: () =>
      import('./modules/protection/remote-adapter/remote-adapter.module').then(m => m.RemoteAdapterModule),
  },
  {
    componentId: 'view-protection-group-settings',
    loadChildren: () => import('./modules/protection/view/view.module').then(m => m.ViewModule),
  },
  {
    componentId: 'add-dmaas-region',
    loadChildren: () => import('./modules/dms/dms.module').then(m => m.DmsModule),
  },
  {
    componentId: 'dms-pre-setup-checklist-dialog',
    loadChildren: () => import('./modules/dms-onboarding/dms-onboarding.module').then(m => m.DmsOnboardingModule),
  },
  {
    componentId: 'modify-syslog-dialog',
    loadChildren: () => import('./modules/admin/cluster/syslog/syslog.module').then(m => m.SyslogSettingsModule),
  },
  {
    componentId: 'agent-job-progress-dialog',
    loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule),
  },
  {
    componentId: 'agent-upgrade-dialog',
    loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule),
  },
  {
    componentId: 'schedule-agent-upgrade-dialog',
    loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule),
  },
  {
    componentId: 'edit-firewall-settings',
    loadChildren: () => import('./modules/admin/networking/firewall/firewall.module').then(m => m.FirewallModule),
  },
  {
    componentId: 'agent-activity-upgrade-detail-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'register-sql-dialog',
    loadChildren: () => import('./modules/sources/ms-sql/ms-sql.module').then(m => m.MsSqlSourceModule),
  },
  {
    componentId: 'configure-sql-dialog',
    loadChildren: () => import('./modules/sources/ms-sql/ms-sql.module').then(m => m.MsSqlSourceModule),
  },
  {
    componentId: 'cdp-io-filter-upgrade-dialog',
    loadChildren: () => import('./modules/cdp/cdp.module').then(m => m.CdpModule),
  },
  {
    componentId: 'cdp-io-filter-uninstall-dialog',
    loadChildren: () => import('./modules/cdp/cdp.module').then(m => m.CdpModule),
  },
  {
    componentId: 'get-new-package-dialog',
    loadChildren: () =>
      import('./modules/cluster/cluster-upgrade/cluster-upgrade.module').then(m => m.ClusterUpgradeModule),
  },
  {
    componentId: 'upload-patch-file-dialog',
    loadChildren: () =>
      import('./modules/cluster/cluster-upgrade/cluster-upgrade.module').then(m => m.ClusterUpgradeModule),
  },
  {
    componentId: 'upgrade-confirmation-dialog',
    loadChildren: () =>
      import('./modules/cluster/cluster-upgrade/cluster-upgrade.module').then(m => m.ClusterUpgradeModule),
  },
  {
    componentId: 'register-kubernetes-dialog',
    loadChildren: () => import('./modules/protection/kubernetes/kubernetes.module').then(m => m.KubernetesModule),
  },
  {
    componentId: 'kubernetes-protection-group-settings',
    loadChildren: () => import('./modules/protection/kubernetes/kubernetes.module').then(m => m.KubernetesModule),
  },
  {
    componentId: 'register-office365-dialog',
    loadChildren: () => import('./modules/protection/office365/office365.module').then(m => m.Office365Module),
  },
  {
    componentId: 'register-remote-storage-dialog',
    loadChildren: () =>
      import('./modules/admin/cluster/remote-disks/remote-disks.module').then(m => m.RemoteDisksModule),
  },
  {
    componentId: 'add-file-systems-remote-storage-dialog',
    loadChildren: () =>
      import('./modules/admin/cluster/remote-disks/remote-disks.module').then(m => m.RemoteDisksModule),
  },
  {
    componentId: 'add-dms-hypervisor-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'add-dms-nas-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'add-dms-uda-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'add-dms-sql-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'add-dms-office365-dialog',
    loadChildren: () => import('./modules/protection/office365/office365.module').then(m => m.Office365Module),
  },
  {
    componentId: 'add-dms-aws-ec2-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'add-dms-oracle-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'add-dms-physical-dialog',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
  },
  {
    componentId: 'add-dms-sfdc-dialog',
    loadChildren: () => import('./modules/sources/sfdc/sfdc.module').then(m => m.SfdcSourceModule),
  },
  {
    componentId: 'policy-compliance',
    loadChildren: () => import('./modules/sources-shared/sources-shared.module').then(m => m.SourcesSharedModule),
  },
  {
    componentId: 'reset-networking-dialog',
    loadChildren: () => import('./modules/admin/networking/networking.module').then(m => m.NetworkingModule),
  },
  {
    componentId: 'reset-networking-confirmation-dialog',
    loadChildren: () => import('./modules/admin/networking/networking.module').then(m => m.NetworkingModule),
  },
  {
    componentId: 'register-uda-dialog',
    loadChildren: () => import('./modules/sources/uda/uda.module').then(m => m.UdaSourceModule),
  },
  {
    componentId: 'register-nas-dialog',
    loadChildren: () => import('./modules/protection/nas/nas.module').then(m => m.NasModule),
  },
  {
    componentId: 'upload-hosts-file-confirmation-dialog',
    loadChildren: () => import('./modules/admin/networking/networking.module').then(m => m.NetworkingModule),
  },
  {
    componentId: 'runbooks-recovery-point-dialog',
    loadChildren: () => import('./modules/runbooks/runbooks.module').then(m => m.RunbooksModule),
  },
  {
    componentId: 'runbooks-snapshot-override-dialog',
    loadChildren: () => import('./modules/runbooks/runbooks.module').then(m => m.RunbooksModule),
  },
  {
    componentId: 'add-health-notification-rule',
    loadChildren: () => import('./modules/health/health.module').then(m => m.HealthModule),
  },
  {
    componentId: 'add-health-silence-rule',
    loadChildren: () => import('./modules/health/health.module').then(m => m.HealthModule),
  },
  {
    componentId: 'register-physical-dialog',
    loadChildren: () => import('./modules/protection/physical/physical.module').then(m => m.PhysicalModule),
  },
  {
    componentId: 'add-helios-user-component',
    loadChildren: () =>
      import('./modules/access-management/helios-access-management/helios-access-management.module').then(
        m => m.HeliosAccessManagementModule
      ),
  },
  {
    componentId: 'claim-cluster-component',
    loadChildren: () =>
      import('./modules/software-upgrades/software-upgrades.module').then(
        m => m.SoftwareUpgradesModule
      ),
  },
  {
    componentId: 'claim-cluster-token-creation-dialog',
    loadChildren: () =>
      import('./modules/software-upgrades/software-upgrades.module').then(
        m => m.SoftwareUpgradesModule
      ),
  },
  {
    componentId: 'pure-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/pure/pure.module').then(m => m.PureModule),
  },
  {
    componentId: 'pure-protection-group-settings',
    loadChildren: () => import('./modules/protection/pure/pure.module').then(m => m.PureModule),
  },
  {
    componentId: 'uda-protection-group-settings',
    loadChildren: () => import('./modules/protection/uda/uda.module').then(m => m.UdaModule),
  },
  {
    componentId: 'saphana-protection-group-settings',
    loadChildren: () => import('./modules/protection/saphana/saphana.module').then(m => m.SapHanaModule),
  },
  {
    componentId: 'disable-mfa-current-user',
    loadChildren: () =>
      import('./modules/access-management/on-prem-access-management/on-prem-access-management.module').then(
        m => m.OnPremAccessManagementModule
      ),
  },
  {
    componentId: 'add-data-site-dialog',
    loadChildren: () => import('./modules/data-sites/data-sites.module').then(m => m.DataSitesModule),
  },
  {
    componentId: 'data-pool-builder-dialog',
    loadChildren: () => import('./modules/data-pools/data-pools.module').then(m => m.DataPoolsModule),
  },
  {
    componentId: 'dr-plan-builder-dialog',
    loadChildren: () =>
      import('./modules/disaster-recovery/disaster-recovery.module').then(m => m.DisasterRecoveryModule),
  },
  {
    componentId: 'create-source-with-connection',
    loadChildren: () => import('./modules/sources-shared/sources-shared.module').then(m => m.SourcesSharedModule),
  },
  {
    componentId: 'create-external-target',
    loadChildren: () =>
      import('./modules/infrastructure/external-targets/external-targets.module').then(m => m.ExternalTargetsModule),
  },
  {
    componentId: 'edit-cloud-vault-target',
    loadChildren: () =>
      import('./modules/infrastructure/external-targets/external-targets.module').then(m => m.ExternalTargetsModule),
  },
  {
    componentId: 'sfdc-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/sfdc/sfdc.module').then(m => m.SfdcModule),
  },
  {
    componentId: 'recovery-detail-page',
    loadChildren: () => import('./modules/restore/recovery/recovery.module').then(m => m.RecoveryModule),
  },
  {
    componentId: 'register-remote-cluster-dialog',
    loadChildren: () => import('./modules/remote-clusters/remote-clusters.module').then(m => m.RemoteClustersModule),
  },
  {
    componentId: 'ng-modify-storage-domain-dialog',
    loadChildren: () => import('./modules/cluster/cluster.module').then(m => m.ClusterModule),
  },
  {
    componentId: 'physical-re-register-dialog',
    loadChildren: () => import('./modules/protection/physical/physical.module').then(m => m.PhysicalModule),
  },
  {
    componentId: 'ng-modify-active-directory-dialog',
    loadChildren: () =>
      import('./modules/access-management/on-prem-access-management/active-directory/active-directory.module').then(
        m => m.ActiveDirectoryModule
      ),
  },
  {
    componentId: 'domain-controllers-dialog',
    loadChildren: () =>
      import('./modules/access-management/on-prem-access-management/active-directory/active-directory.module').then(
        m => m.ActiveDirectoryModule
      ),
  },
  {
    componentId: 'manage-domain-controllers-dialog',
    loadChildren: () =>
      import('./modules/access-management/on-prem-access-management/active-directory/active-directory.module').then(
        m => m.ActiveDirectoryModule
      ),
  },
  {
    componentId: 'govcloud-notice-dialog',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    componentId: 'user-groups-builder-dialog',
    loadChildren: () =>
      import('./modules/access-management/on-prem-access-management/users-groups/users-groups.module')
      .then(m => m.UsersGroupsModule),
  },
  {
    componentId: 'option-selection-dialog',
    loadChildren: () =>
      import('./modules/access-management/shared/active-directory/active-directory.module').then(
        m => m.ActiveDirectorySharedModule
      ),
  },
  {
    componentId: 'helios-select-source-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration-shared/helios-source-registration-shared.module').then(
        m => m.HeliosSourceRegistrationSharedModule
      ),
  },
  {
    componentId: 'unified-m365-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/m365-registration/m365-registration.module').then(
        m => m.M365RegistrationModule
      ),
  },
  {
    componentId: 'unified-vm-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/vm-registration/vm-registration.module').then(
        m => m.VmRegistrationModule
      ),
  },
  {
    componentId: 'unified-sap-hana-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/sap-hana-registration/sap-hana-registration.module').then(
        m => m.SapHanaRegistrationModule
      ),
  },
  {
    componentId: 'unified-nas-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/nas-registration/nas-registration.module').then(
        m => m.NasRegistrationModule
      ),
  },
  {
    componentId: 'unified-kubernetes-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/kubernetes-registration/kubernetes-registration.module').then(
        m => m.KubernetesRegistrationModule
      ),
  },
  {
    componentId: 'unified-physical-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/physical-registration/physical-registration.module').then(
        m => m.PhysicalRegistrationModule
      ),
  },
  {
    componentId: 'upload-crl-file-dialog-component',
    loadChildren: () =>
      import('./modules/cluster/cluster-update/cluster-update.module').then(m => m.ClusterUpdateModule),
  },
  {
    componentId: 'add-dms-azure-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/azure-registration/azure-registration.module').then(
        m => m.AzureRegistrationModule
      ),
  },
  {
    componentId: 'coh-cluster-upgrade-review-dialog',
    loadChildren: () =>
      import('./modules/cluster/cluster-update/cluster-update.module').then(m => m.ClusterUpdateModule),
  },
  {
    componentId: 'coh-cluster-upgrade-confirm-dialog',
    loadChildren: () =>
      import('./modules/cluster/cluster-update/cluster-update.module').then(m => m.ClusterUpdateModule),
  },
  {
    componentId: 'coh-ng-port-settings-warning-dialog',
    loadChildren: () =>
      import('./modules/cluster/cluster-settings/cluster-settings.module').then(m => m.ClusterSettingsModule),
  },
  {
    componentId: 'unified-mssql-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/mssql-registration/mssql-registration.module').then(
        m => m.MssqlRegistrationModule
      ),
  },
  {
    componentId: 'azure-authorize-sql-db-dialog',
    loadChildren: () => import('./modules/sources-shared/sources-shared.module').then(m => m.SourcesSharedModule),
  },
  {
    componentId: 'coh-change-password',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    componentId: 'azure-sql-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/azure-sql/azure-sql.module').then(m => m.AzureSqlModule),
  },
  {
    componentId: 'azure-sql-protection-group-settings',
    loadChildren: () => import('./modules/protection/azure-sql/azure-sql.module').then(m => m.AzureSqlModule),
  },
  {
    componentId: 'azure-ad-quick-protection-dialog',
    loadChildren: () => import('./modules/protection/azure-ad/azure-ad.module').then(m => m.AzureAdModule),
  },
  {
    componentId: 'azure-ad-protection-group-settings',
    loadChildren: () => import('./modules/protection/azure-ad/azure-ad.module').then(m => m.AzureAdModule),
  },
  {
    componentId: 'physical-agent-upgrade-dialog',
    loadChildren: () => import('./modules/sources-shared/sources-shared.module').then(m => m.SourcesSharedModule),
  },
  {
    componentId: 'unified-oracle-source-registration-dialog',
    loadChildren: () =>
      import('./modules/helios-source-registration/oracle-registration/oracle-registration.module').then(
        m => m.OracleRegistrationModule
      ),
  }
];
