<cog-dashcard
  [title]="'dg.sc.dashboard.threatsDiscovered' | translate"
  [subtitle]="('dg.sc.dashboard.threatsDiscovered.subtitle' | translate) + (
    timeframeLabel ? (' - ' + timeframeLabel) : ''
  )">
  <cog-dashcard-header-aux *ngIf="!timeframeLabel">
    <h5 class="no-margin-bottom">
      <a class="margin-right" (click)="dashboardService.navigateToUrl.next('/threat-detection')">
        {{ 'viewAll' | translate }}
      </a>
    </h5>
    <cog-icon shape="helix:help" [matTooltip]="'dg.sc.dashboard.threatsDiscoveredHelp' | translate"></cog-icon>
  </cog-dashcard-header-aux>
  <ng-container *ngIf="!loading; else spinner">
    <ng-container *ngTemplateOutlet="threatDistributionCardContent"></ng-container>
  </ng-container>
</cog-dashcard>

<ng-template #threatDistributionCardContent>
  <mat-card-content class="card-content">
    <div class="charts-container" *ngIf="totalIOCs > 0 || totalSnapshots > 0; else blankCard">
      <div class="threats-discovered-container" *ngIf="!timeframeLabel">
        <cog-card-section-glancebar>
          <cog-statlist valueSize="md" title="{{ 'dg.sc.dashboard.threatsDiscovered.scanDetails' | translate }}">
            <a cogStatlistItem
              [label]="'dg.sc.dashboard.threatsDiscovered.totalScans' | translate"
              labelLegend="success"
              iconSize="xs">
              {{ totalScans | number }}
            </a>

            <a cogStatlistItem
              [label]="'dg.sc.dashboard.threatsDiscovered.totalObjectsScanned' | translate"
              labelLegend="success"
              iconSize="xs">
              {{ totalObjectsScanned | number }}
            </a>

            <a cogStatlistItem
              [label]="'dg.sc.dashboard.threatsDiscovered.totalIOCsFound' | translate"
              labelLegend="critical"
              iconSize="xs">
              {{ totalIOCs | number }}
            </a>
          </cog-statlist>
        </cog-card-section-glancebar>
      </div>
      <div class="threats-discovered-container infected-chart-theme">
        <cog-donut-chart
          [title]="timeframeLabel ? null : ('dg.sc.dashboard.threatsDiscovered.scanned' | translate)"
          [reflowOnFirstRender]="true"
          [seriesData]="[infectedChartData]">
          <span class="total-count">
            {{ totalSnapshots | number }}
            <label>{{ 'dg.sc.dashboard.threatsDiscovered.scanned' | translate }}</label>
          </span>
        </cog-donut-chart>
        <cog-statlist [vertical]="true">
          <cog-statlist-item
            cogDataId="threats-discovered-legend-{{legend.label}}"
            *ngFor="let legend of infectedChartlegends; index as i;"
            [label]="legend.label | translate"
            labelLegend="highcharts-color-{{i}}">
            {{ legend.value | number }}
          </cog-statlist-item>
        </cog-statlist>
      </div>
    </div>
  </mat-card-content>
</ng-template>

<ng-template #blankCard>
  <cog-blank-card type="helix:no-data-img-threats" size="sm" [message]="'dg.noThreats' | translate" noData>
    <h5>
      <a (click)="dashboardService.navigateToUrl.next('/scan')">
        {{ 'scanNow' | translate }}
      </a>
    </h5>
  </cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
