import { noop } from 'lodash-es';
import { assign } from 'lodash-es';
// Component: Antivirus Secured Views

;(function(angular, undefined) {
  'use strict';

  angular.module('C.antivirus')
    .component('securedViews', {
      templateUrl: 'app/antivirus/secured-views/secured-views.html',
      controller: securedViewsCtrlFn,
    });

  /**
   * @ngdoc component
   * @name C.antivirus:securedViews
   * @function securedViewsCtrlFn
   *
   * @description
   * Provides component which manages the list of secured Views.
   *
   * @example
     <secured-views></secured-views>
   */
  function securedViewsCtrlFn(_, $state, evalAJAX, cModal, AntivirusService) {
    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
      providersConfigured: false,
    });

    /**
     * Initializes and fetches data
     *
     * @method   $onInit
     */
    function $onInit() {
      _checkProviders();
      _fetchSecuredViews();
    }

    /**
     * Check if provider is configured or not.
     *
     * @method   _checkProviders
     */
    function _checkProviders() {
      AntivirusService.getAvProviderList().then(
        function gotProviders(providers) {
          if (providers.length) {
            $ctrl.providersConfigured = true;
          }
        }
      );
    }

    /**
     * Fetches the list of Secured Views
     *
     * @method   _fetchSecuredViews
     */
    function _fetchSecuredViews() {
      $ctrl.loadingViews = true;

      AntivirusService.getSecuredViews().then(
        function getSecuredViewsSuccess(views) {
          $ctrl.securedViews = _addContextMenus(views);
          _getStatsPerView(views);
        },
        evalAJAX.errorMessage
      ).finally(function getSecuredViewsDone() {
        $ctrl.loadingViews = false;
      });
    }

    /**
     * Presents a challenge modal before allowing user to remove antivirus
     * config from a View.
     *
     * @method    _confirmRemoveView
     * @param     {Object}   view    The View object.
     */
    function _confirmRemoveView(view) {
      var modalConfig = {
        controller: RemoveViewControllerFn,
        resolve: {
          view: function resolveView() { return view; }
        },
      };

      var options = {
        titleKey: 'antivirus.remove.view.title',
        contentKey: 'antivirus.remove.view.text',
        contentKeyContext: {name: view.name},
        actionButtonKey: 'remove',
        closeButtonKey: 'cancel',
      };

      return cModal.standardModal(modalConfig, options)
        .then(_fetchSecuredViews)
        .catch(noop);
    };

    /**
     * Adds context menus to each View
     *
     * @method   _addContextMenus
     * @param    {array}   views   List of Secured Views.
     * @return   {array}   List of Secured Views.
     */
    function _addContextMenus(views) {
      views.forEach(function addProviderContextMenu(view) {
        view._contextMenus = [];

        view._contextMenus.push(
          {
            icon: 'icn-edit',
            translateKey: 'edit',
            action: function editSecuredView() {
              AntivirusService.editSecuredView(view).then(
                function updatedSecuredView() {
                  $state.reload();
                },
                noop
              );
            }
          },
          {
            icon: 'icn-delete',
            translateKey: 'remove',
            action: function confirmRemoveView() {
              _confirmRemoveView(view)
            }
          }
        );
      });

      return views;
    }

    /**
     * Gets a list of threats for the View and compiles the stats.
     *
     * @method   _getStatsPerView
     */
    function _getStatsPerView(views) {
      views.forEach(function eachView(view) {
        AntivirusService.getThreats({viewNames: [view.name]}).then(
          function getThreatsSuccess(threats) {
            // Set starting values.
            var stats = {
              _quarantinedFiles: 0,
              _unquarantinedFiles: 0,
            };

            // Compile the stats for the View.
            threats.forEach(function forEachThreat(threat) {
              if (threat.remediationState === 'kQuarantine') {
                stats._quarantinedFiles++;
              } else {
                stats._unquarantinedFiles++;
              }
            });

            // Assign the stats to the View.
            assign(view, stats);
          },
          noop
        );

      });
    }
  }

  /* @ngInject */
  function RemoveViewControllerFn($uibModalInstance,
    ViewService, evalAJAX, view) {

    var $ctrl = this;

    /**
     * Handles onClick the primary button in the standardModal.
     */
    $ctrl.ok = function okayThen() {
      // Disabled the AV config on this view.
      view.antivirusScanConfig = {
        isEnabled: false,
      };

      $ctrl.submitting = true;
      ViewService.editView(view).then(
        $uibModalInstance.close,
        evalAJAX.errorMessage
      ).finally(function removeViewFinally() {
        $ctrl.submitting = false;
      });
    };
  }

})(angular);
