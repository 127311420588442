import { Injectable } from '@angular/core';
import {
  DataSourcesApiService,
  IoCsScansApiService,
  LogSearchApiService,
  Scan,
  ScanHealthStatus,
  ShieldsApiService,
  ShieldType,
} from '@cohesity/api/argus';
import { SecurityService, TimeRange } from '@cohesity/data-govern/anti-ransomware';
import { HasCustomRBACPermissions, ScanHealthStatusIconPipe } from '@cohesity/data-govern/shared';
import { ActivityItemSeverity, DataFilterValue } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { finalize, shareReplay, switchMap } from 'rxjs/operators';

import { DecoratedRecentActivity, ThreatsDiscoveredData } from '../components';
import { UserActivity } from '../components/cards/user-activity';
import { AnomalyCausePipe } from '../pipes';
import {
  FilterValue,
  securityCenterDashboards,
  SecurityCenterDashboardService,
} from './security-center-dashboard.service';

@Injectable()
export class ThreatProtectionDashboardService {
  /**
   * BehaviorSubject to store loading states of anomalies investigated.
   */
  private _anomaliesInvestigatedLoading = new BehaviorSubject<boolean>(true);

  /**
   * Observable with anomalies investigated loading states.
   */
  anomaliesInvestigatedLoading$ = this._anomaliesInvestigatedLoading.asObservable();

  /**
   * BehaviorSubject to store loading states of anomalies activity.
   */
  private _anomaliesActivityLoading = new BehaviorSubject<boolean>(true);

  /**
   * Observable with anomalies activity loading states.
   */
  anomaliesActivityLoading$ = this._anomaliesActivityLoading.asObservable();

  /**
   * Anomalies activity.
   */
  private _anomaliesActivity = new BehaviorSubject<DecoratedRecentActivity[]>([]);

  /**
   * An observable with anomalies activity.
   */
  anomaliesActivity$ = this._anomaliesActivity.asObservable();

  /**
   * BehaviorSubject to store loading states of Threats Discovered.
   */
  private _threatsDiscoveredLoading = new BehaviorSubject<boolean>(false);

  /**
   * Observable with Threats Discovered loading states.
   */
  threatsDiscoveredLoading$ = this._threatsDiscoveredLoading.asObservable();

  /**
   * The date filter timeframe label.
   */
  timeFrameLabel$ = new BehaviorSubject<string>(null);

  /**
   * Data for user activity widget
   */
  userActivityData: UserActivity = null;

  /**
   * Loading state for user activities.
   */
  userActivityDataLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * Threat Protection Dashboard will only be shown if datahawk is supported.
   * Otherwise, only Anti Ransomware Dashboard will be shown.
   * This property will be set in the dashboard component after checking against
   * appinitializer service value.
   */
  isDatahawkSupported = true;

  /**
   * Threats Discovered.
   */
  private _threatsDiscovered = new BehaviorSubject<{
    stats: ThreatsDiscoveredData;
    activities: DecoratedRecentActivity[];
  }>({
    stats: {
      clean: 0,
      criticalIocs: 0,
      infected: 0,
      nonCriticalIocs: 0,
      totalIOCCount: 0,
      totalObjectCount: 0,
      totalScanCount: 0,
    },
    activities: []
  });

  /**
   * An observable with threats discovered.
   */
  threatsDiscovered$ = this._threatsDiscovered.asObservable();

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private anomalyCausePipe: AnomalyCausePipe,
    private dataSourcesService: DataSourcesApiService,
    private iocScansApiService: IoCsScansApiService,
    private irisContext: IrisContextService,
    private logSearchApiService: LogSearchApiService,
    private scanHealthStatusIconPipe: ScanHealthStatusIconPipe,
    private shieldsApiService: ShieldsApiService,
    private translateService: TranslateService,
    readonly securityCenterDashboardService: SecurityCenterDashboardService,
    readonly securityService: SecurityService,
  ) {}

  /**
   * Load data for consumption
   */
  loadData() {
    this.securityCenterDashboardService.dateRangeFilter$.pipe(
      shareReplay(1)
    ).subscribe((dataFilter: DataFilterValue<FilterValue>) => {
      if (dataFilter) {
        if (dataFilter) {
          const value = dataFilter.value;
          this.loadAnomalyData(value);
          this.timeFrameLabel$.next(value.timeframeLabel);
        }
      }
    });
  }

  /**
   * Load data for anomaly widgets.
   *
   * @param dateRange The date range for which data is to be fetched
   */
  loadAnomalyData(dateRange: FilterValue) {
    const startTime = dateRange?.start.valueOf();
    const endTime = dateRange?.end.valueOf();

    const timeRange = {
      startTime,
      endTime,
    };

    combineLatest([
      this.getAnomaliesInvestigated(timeRange),
      this.getAnomaliesActivity(timeRange),
      this.getThreatsDiscovered(timeRange),
    ]).subscribe(
      ([_alerts, incidences, threatsDiscovered]) => this.computeDashboardStatus(incidences, threatsDiscovered?.stats),
      this.ajaxHandlerService.handler
    );
  }

  /**
   * Get the 'severity' and 'icon' for a threst scan.
   *
   * @param scan The scan for which severity is to be determined.
   */
  private getSeverityAndIcon(scan: Scan): {severity: ActivityItemSeverity; icon: string} {
    let icon = this.scanHealthStatusIconPipe.transform(scan.lastRun.health.status, true);
    let severity: ActivityItemSeverity;

    switch (scan.lastRun.health.status) {
      case ScanHealthStatus.Succeeded:
        if (scan.lastRun?.stats.totalIocCount > 0) {
          // We want the scan results to take precedence over scan health status,
          // when run has succeeded but there are IOC's found.
          icon = this.scanHealthStatusIconPipe.transform(ScanHealthStatus.Failed, true);
          severity = 'error';
        } else {
          severity = 'success';
        }

        break;
      case ScanHealthStatus.Accepted:
      case ScanHealthStatus.Running:
        severity = 'info';
        break;

      case ScanHealthStatus.Canceled:
      case ScanHealthStatus.SucceededWithWarning:
        severity = 'warn';
        break;

      case ScanHealthStatus.Failed:
        icon = 'helix:status-error';
        severity = 'failed';
        break;
    }

    return {
      icon,
      severity
    };
  }

  /**
   * Get the 'severity' and 'icon' for an anomaly.
   *
   * @param anomalyStrength Anomaly strength score.
   */
  private getAnomalySeverityAndIcon(anomalyStrength: number): {severity: ActivityItemSeverity; icon: string} {
    if (anomalyStrength >= 60) {
      return {
        severity: 'error',
        icon: 'helix:status-error!critical'
      };
    } else if (anomalyStrength >= 30) {
      return {
        severity: 'warn',
        icon: 'helix:status-warning!warning'
      };
    }

    return {
      severity: 'info',
      icon: 'helix:status-info!info'
    };
  }

  /**
   * Determine the color and icon for the dashboard's tab
   *
   * @param incidences The anomalous incidences
   * @param scans Scan results containing IOCs
   */
  computeDashboardStatus(incidences: DecoratedRecentActivity[], scans: ThreatsDiscoveredData) {
    this.securityCenterDashboardService.setDashboardTabStatus(
      securityCenterDashboards.threatProtection,
      this.getThreatScoreClass(incidences, scans),
    );
  }

  /**
   * Get the class for dashboard's color and icon
   *
   * @param incidences The anomalous incidences
   * @param scans Scan results containing IOCs
   */
  getThreatScoreClass(incidences: DecoratedRecentActivity[], scans: ThreatsDiscoveredData): string {
    let criticalAnomalies = 0;
    let nonCriticalAnomalies = 0;
    const criticalIocs = scans?.criticalIocs || 0;
    const nonCriticalIocs = scans?.nonCriticalIocs || 0;

    incidences.forEach(incident => {
      if (incident.anomalyStrength >= 70) {
        criticalAnomalies += 1;
      } else {
        nonCriticalAnomalies += 1;
      }
    });

    const weightedThreatScore =
      (1 * criticalAnomalies + 0.5 * nonCriticalAnomalies + 2 * criticalIocs + 0.5 * nonCriticalIocs);
    const totalThreatScore =
      (weightedThreatScore / (criticalAnomalies + nonCriticalAnomalies + criticalIocs + nonCriticalIocs)) || 4;
    const normalizedThreatScore = 100 - (totalThreatScore * 25);

    return normalizedThreatScore < 30 ? 'low' : (normalizedThreatScore <= 60 ? 'medium' : 'high');
  }

  /**
   * Get anomalies activity from shields service api.
   */
  getAnomaliesActivity(timeRange: TimeRange): Observable<DecoratedRecentActivity[]> {
    this._anomaliesActivityLoading.next(true);

    return this.shieldsApiService
      .getIncidences({
        startTimeMsecs: timeRange.startTime,
        endTimeMsecs: timeRange.endTime,
        includeAnomalyCause: true,
      })
      .pipe(
        finalize(() => this._anomaliesActivityLoading.next(false)),
        switchMap(response => {
          const incidences = response.incidences ?? [];
          const activities: DecoratedRecentActivity[] = incidences.map(incident => {
            const { icon, severity } = this.getAnomalySeverityAndIcon(incident.antiRansomwareDetails?.anomalyStrength);
            return {
              title: incident.antiRansomwareDetails?.entityName,
              icon,
              severity,
              subtitle: this.translateService.instant('dg.sc.dashboard.anomaliesActivity.cause', {
                cause: this.anomalyCausePipe.transform(incident.antiRansomwareDetails?.anomalyCause) ?? ''
              }),
              timestampSecs: incident.incidenceTimeMsecs / 1000,
              activityLink: incident.shieldType === ShieldType.ANTI_RANSOMWARE
                ? `/anti-ransomware/incidences/${incident.id}`
                : null,
              anomalyStrength: incident.antiRansomwareDetails?.anomalyStrength,
            };
          });

          this._anomaliesActivity.next(activities);

          return this.anomaliesActivity$;
        })
      );
  }

  /**
   * Get threats discovered data from the API.
   */
  getThreatsDiscovered(timeRange: TimeRange): Observable<{
    stats: ThreatsDiscoveredData;
    activities: DecoratedRecentActivity[];
  }> {

    return this.securityCenterDashboardService.paywallNeeded$.pipe(
      switchMap((paywallNeeded) =>
        (paywallNeeded.threatProtection || !this.isDatahawkSupported) ?
          of(null) : this.getIocScans(timeRange)
      )
    );
  }

  /**
   * Get threat scans data from the API.
   */
  getIocScans(timeRange: TimeRange): Observable<{
    stats: ThreatsDiscoveredData;
    activities: DecoratedRecentActivity[];
  }> {
    const hasPermission =
      HasCustomRBACPermissions(['DGAAS_VIEW_THREAT_SCAN', 'DGAAS_VIEW'], this.irisContext.irisContext);

    if (!hasPermission) {
      return of(null);
    }

    this._threatsDiscoveredLoading.next(true);
    return this.iocScansApiService.getScans({
      startTimeMsecs: timeRange.startTime * 1000,
      endTimeMsecs: timeRange.endTime * 1000,
    }).pipe(
      finalize(() => this._threatsDiscoveredLoading.next(false)),
      switchMap((response) => {
        const clean = response.stats?.cleanSnapshotCount ?? 0;
        const infected = response.stats?.affectedSnapshotCount ?? 0;
        let criticalIocs = 0, nonCriticalIocs = 0;

        response.scans.forEach(scan => {
          scan.lastRun.stats.iocCounts.forEach(count => {
            if (count.severity === 'high') {
              criticalIocs += count.count;
            } else {
              nonCriticalIocs += count.count;
            }
          });
        });

        const threatActivities = response?.scans.map(scan => {
          const severityAndIcon = this.getSeverityAndIcon(scan);

          return {
            title: scan.name,
            subtitle: scan.lastRun.health.status === ScanHealthStatus.Failed ?
              scan.lastRun.health.message :
              this.translateService.instant('dg.sc.dashboard.threatsDiscovered.message', {
                n: scan.lastRun?.stats?.totalIocCount,
                m: scan.lastRun.stats.totalObjectCount
              }),
            activityLink: `/threat-detection/scans/${scan.id}/detection-run`,
            timestampSecs:
              (scan.lastRun.endTimeUsecs || scan.lastRun.startTimeUsecs) / (1000 * 1000),
            icon: severityAndIcon?.icon,
            severity: severityAndIcon?.severity
          };
        });

        this._threatsDiscovered.next({
          stats: {
            clean,
            criticalIocs: criticalIocs,
            infected,
            nonCriticalIocs: nonCriticalIocs,
            totalIOCCount: response.stats?.totalIocCount,
            totalObjectCount: response.stats?.totalObjectCount ?? 0,
            totalScanCount: response.stats?.totalScanCount ?? 0,
          },
          activities: threatActivities
        });

        return this.threatsDiscovered$;
      })
    );
  }

  /**
   * Get the anomaly summary for the anomalies investigated
   *
   * @param timeRange time range for which the data is required
   * @returns anomaly summary
   */
  getAnomaliesInvestigated(timeRange: TimeRange) {
    this._anomaliesInvestigatedLoading.next(true);

    return this.securityService.getAnomalySummaryInfo({
      startTime: timeRange.startTime * 1000,
      endTime: timeRange.endTime * 1000,
    }).pipe(
      finalize(() => this._anomaliesInvestigatedLoading.next(false)),
    );
  }
}
