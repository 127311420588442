import { assign } from 'lodash-es';
// Component:  Cloud Retrieval master controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.cloud-retrieval', [])
    .config(CloudRetrievalConfigFn)
    .controller('CloudRetrievalCtrl', cloudRetrievalCtrlFn)
    .component('cloudRetrieval', {
      controller: 'CloudRetrievalCtrl',
      templateUrl: 'app/protection/cloud-retrieval/cloud-retrieval.html',
    });

  function CloudRetrievalConfigFn($stateProvider) {
    var viewAccess = 'FEATURE_FLAGS.cloudRetrieve && REMOTE_RESTORE';

    $stateProvider
      .state('cloud-retrieval', {
        url: '^/protection/cloud-retrieval',

        // NOTE: This being a parent state, the help links are defined by child
        // states.
        title: 'Cloud Retrieval',
        canAccess: viewAccess,
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
          },
          'col-l@cloud-retrieval': {
            template: '<cloud-retrieval></cloud-retrieval>',
          },
        },
        redirectTo: 'cloud-retrieval.retrievals'
      })
      .state({
        name: 'cloud-retrieval.searches',
        url: '^/protection/cloud-retrieval/searches',
        help: 'protection_cloudretrieval_searches',
        title: 'Cloud Retrieval: Searches',
        canAccess: viewAccess,
        parentState: 'cloud-retrieval',
        template: '<submitted-cloud-searches></submitted-cloud-searches>',
      })
      .state({
        name: 'cloud-retrieval.retrievals',
        url: '^/protection/cloud-retrieval/retrievals',
        help: 'protection_cloudretrieval_retrievals',
        title: 'Cloud Retrieval: Retrieved Jobs',
        canAccess: viewAccess,
        parentState: 'cloud-retrieval',
        template: '<cloud-restore-tasks></cloud-restore-tasks>',
      })
      .state('cloud-retrieval-search', {
        url: '^/protection/cloud-retrieval/search',
        help: 'protection_cloudretrieval_search',
        title: 'Cloud Retrieval: Search',
        canAccess: viewAccess,
        parentState: 'cloud-retrieval.searches',
        params: {
          // To be populated when repeating a search using the same params.
          queryParams: undefined,
        },
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
          },
          'col-l@cloud-retrieval-search': {
            template: '<cloud-retrieval-search></cloud-retrieval-search>',
          },
        },
      })
      .state('cloud-retrieval-search-details', {
        url: '^/protection/cloud-retrieval/search/details/task/{taskId}/{clusterId}/{clusterIncarnationId}',
        help: 'protection_cloudretrieval_search_details',
        title: 'Cloud Retrieval: Search Details',
        canAccess: viewAccess,
        params: {
          taskId: { type: 'string' },
          clusterId: { type: 'string' },
          clusterIncarnationId: { type: 'string' },
        },
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
          },
          'col-l@cloud-retrieval-search-details': {
            template: '<cloud-search-details></cloud-search-details>',
          },
        },
        parentState: 'cloud-retrieval.searches',
      });
  }

  function cloudRetrievalCtrlFn(_,
    $scope, $state, cUtils, evalAJAX, SearchService, RestoreService, $q) {

    var ctrl = this;

    assign(ctrl, {
      // General Template Vars
      cloudSearches: [],
      restoreTasks: [],
      tabsConfig: [],
      // Template Methods
    });

    // METHODS
    /**
     * Activate this Controller!
     *
     * @method   activate
     */
    function activate() {
      getData().then(
        function dataReceived(resp) {
          generateTabs();

          // If retrievals & no results, go to searches
          if (ctrl.tabsConfig.length &&
            'cloud-retrieval.retrievals' === $state.current.name &&
            !ctrl.restoreTasks.length) {
            $state.go(
              'cloud-retrieval.searches',
              undefined,
              { location: 'replace' }
            );
          }

        }
      );
    }

    /**
     * Generates ui-router-tabs for displaying submitted searchJobs and
     * restoreTasks.
     *
     * @method   generateTabs
     */
    function generateTabs() {
      if (ctrl.restoreTasks.length) {
        ctrl.tabsConfig.push({
          headingKey: 'retrievedJobs',
          route: 'cloud-retrieval.retrievals',
        });
      }

      if (ctrl.cloudSearches.length) {
        ctrl.tabsConfig.push({
          headingKey: 'searches',
          route: 'cloud-retrieval.searches',
        });
      }
    }

    /**
     * Fetch the necessary data for this flow.
     *
     * @method   getData
     */
    function getData() {
      var promises = {
        tasks: RestoreService.getRemoteRestoreTasks(),
        searches: SearchService.getRemoteVaultSearches(),
      };

      ctrl.isDataReady = false;

      return $q.all(promises).then(
        function dataReceived(data) {
          ctrl.cloudSearches = data.searches;
          ctrl.restoreTasks = data.tasks;
        },
        evalAJAX.errorMessage
      )
      .finally(
        function allReceived() {
          ctrl.isDataReady = true;
        }
      );
    }

    // Activate!
    activate();
  }

})(angular);
