import { assign } from 'lodash-es';
// Component: Log List

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'LogListController',
    templateUrl: 'app/global/c-log-list/log-list.html',
    bindings: {
      logs: '<',
    },
  };

  angular.module('C.logList', [])
    .controller('LogListController', LogListCtrlFn)
    .component('cLogList', componentConfig);



  /**
   * @ngdoc component
   * @name C.logList:cLogList
   *
   * @param {Object} logs list of logs.
   *
   * @description
   * Displays a list of logs. Logs should be an array of
   * objects with `details` and `timestampUsecs` properties
   *
   * @example
   * <c-log-list logs="logs"></c-log-list>
   */
  function LogListCtrlFn(_, $filter) {
    var $ctrl = this;

    assign($ctrl, {
      $onChanges: $onChanges
    });


    /**
     * Update the log data whenever the input binding changes
     * @param {*} changes
     */
    function $onChanges(changes) {
      if (changes.logs) {
        prepareAuditLogsData();
      }
    }

    /**
     * Prepare logs for display and add human readable form of log timestamp.
     *
     * @method   prepareAuditLogsData
     */
    function prepareAuditLogsData() {
      var dateDiff = $filter('humanizeMsecsDiff');

      ($ctrl.logs || []).forEach(
        function eachLog(log, index, list) {
          log._preciseDiff =
            dateDiff(Math.floor(log.timestampUsecs / 1000), 'days');

            // show date checkpoint only for unique logs.
          log._showDateCheckpoint =
            (index === 0 || list[index - 1]._preciseDiff !== log._preciseDiff);
        }
      );
    }
  }

})(angular);
