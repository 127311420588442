import { BoolLabelPipe, NaLabelPipe } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isArray, isObject } from 'lodash-es';

export type ValueFormatter<T> = (value: T) => string;

/**
 * Creates a transformer function for boolean value formatting
 *
 * @param yesNoPipe yesNoPipe Instance for transforming value
 * @param trueLabel label key that will be used when the value is truthy
 * @param falseLabel label key that will be used when the value is falsy
 * @returns transformer function
 */
export const booleanValueFormatterFn = (
  boolLabelPipe: BoolLabelPipe,
  trueLabel?: string,
  falseLabel?: string,
): ValueFormatter<boolean> => value => boolLabelPipe.transform(value, trueLabel, falseLabel);

/**
 * Creates transformer function for VM recovery power on/off state.
 *
 * @returns transformer function for recovery process type
 */
export const vmPowerStateValueFormatterFn = (boolLabelPipe: BoolLabelPipe) =>
  booleanValueFormatterFn(boolLabelPipe, 'on', null);

/**
 * Creates transformer function for recover VM to new location.
 *
 * @returns transformer function for recovery process type
 */
export const vmRecoverToNewValueFormatterFn = (boolLabelPipe: BoolLabelPipe) =>
  booleanValueFormatterFn(boolLabelPipe, null, 'originalLocation');

/**
 * Creates transformer function for recover VM to original location location.
 *
 * @returns transformer function for recovery process type
 */
export const recoveryToOriginalValueFormatterFn = (boolLabelPipe: BoolLabelPipe) =>
  booleanValueFormatterFn(boolLabelPipe, 'originalLocation', null);

/**
 * Creates a transformer function for optionally empty values
 *
 * @param naLabelPipe naLabelPipe instance
 * @param customNaText custom text that need to be shown when the value is empty
 * @returns transformer function
 */
export const naLabelValueFormatterFn = (
  naLabelPipe: NaLabelPipe,
  translate: TranslateService,
  customNaText = translate.instant('none'),
): ValueFormatter<string> => value => naLabelPipe.transform(value, customNaText);


/**
 * Creates transformer function for recovery process type which returns i18n keys for the incoming process types
 *
 * @returns transformer function for recovery process type
 */
export const recoveryProcessTypeValueFormatterFn = (translate: TranslateService): ValueFormatter<string> => value => {
  switch (value) {
    case 'InstantRecovery':
      return translate.instant('instantRecovery');
    default:
      return value;
  }
};

/**
 * Creates transformer function for recovery action values which returns i18n keys for the incoming values
 *
 * @returns transformer function for recovery process type
 */
export const recoveryActionValueFormatterFn = (translate: TranslateService): ValueFormatter<string> => value =>
  translate.instant(`enum.recoveryType.${value}`);


/**
 * Checks if the given item is of the premitive type
 *
 * @param item item that need to be evaluated
 * @returns true if the item is of premitive type, otherwise false
 */
const isPrimitive = (item) => !isObject(item) && !isArray(item);

/**
 * Flattens the given object with nested fields
 *
 * @param obj object that need to be flattened
 * @param dict dictionary holding the flattened values
 * @param keyPrefix prefix for the keys in the dictionary
 * @returns dictionary containing all the flattened values
 */
export const flattenRecoveryParams = (obj: object, dict: Record<string, any>, keyPrefix) => {
  if (obj === null) {
    return dict;
  }

  for (const name in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, name)) {
      let keyName: string;

      if (keyPrefix) {
        keyName = keyPrefix + '.' + name;
      } else {
        keyName = name;
      }

      if (isPrimitive(obj[name])) {
        dict[keyName] = obj[name];
      } else {
        // continue recursing
        // TODO assumes objects, need to deal with arrays too
        flattenRecoveryParams(obj[name], dict, keyName);
      }
    }
  }

  return dict;
};
