import { filter } from 'lodash-es';
import { get } from 'lodash-es';
import { assign } from 'lodash-es';
// COMPONENT: Restore Files to Source: Search view

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.fileRecovery';

  angular
    .module(moduleName)
    .controller('fileSearchController', fileSearchControllerFn);

  function fileSearchControllerFn($rootScope, $scope, _, $q, $transition$,
    cSearchService, ViewBoxService, SourceService, JobService,
    PubJobServiceFormatter, SearchService, ExternalTargetService, cUtils,
    RestoreService, ENUM_ENV_TYPE, ENV_TYPE_CONVERSION, ENV_GROUPS,
    AdaptorAccessService) {

    // List of indexable types for the Object Type filter when search type is
    // "Files and Folders".
    var indexableTypes = AdaptorAccessService.filterByAccessibleEnv(
      PubJobServiceFormatter.getIndexableEnvironments()).map(
        function mapEnvToFilters(env) {
          return {
            display: ENUM_ENV_TYPE[env],
            value: env,
          };
        });

    // NOTE: Job and Object environment types differ only for kPhysicalFiles,
    // so omitting those.
    var indexableObjectTypes = filter(indexableTypes,
      function filterObjectEnvironmentTypes(type) {
        // Remove Office365 entities as their email/folder recovery has a
        // different flow.
        // Remove Native Snapshots Types since they are not pure entity Types
        // but rather pseudo job types
        return type.value !== 'kPhysicalFiles' && !ENV_GROUPS.office365
          .includes(type.value) && !ENV_GROUPS.nativeSnapshotTypes
            .includes(type.value);
      });

    // List of browsable types for the Object Type filter when search type is
    // "Browse or Specify path".
    var browsableTypes = SearchService.getBrowsableEnvironments().map(
      function mapEnvToFilters(env) {
        return {
          display: ENUM_ENV_TYPE[env],
          value: env,
        };
      }
    );

    /** @type {String}   Determines which type of asset this flow is
    searching for/recovering */
    var searchType;
    var defaultFilterProps;
    var searchTypeWatcher;

    angular.merge($scope, {
      // GENERAL SCOPE VARS
      searchAPI: SearchService.getSearchUrl(searchType),
      itemsPerPage: 10,
      filterProps: [],

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoveryFilesFilesSearchText,

      // Errors
      errorText: $rootScope.text.protectionRecoveryFilesFilesSearch1ErrorText,

      // SCOPE METHODS
      preProcessSearchResults: preProcessSearchResults,
      getJobName: getJobName,
      getTypedEntity: SourceService.getTypedEntity,
      isFileRecoveryDisabled: isFileRecoveryDisabled,
    });

    // Configuration options for searchType
    $scope.searchTypeOpts = {
      0: 'file',
      1: 'browsableEntities',
    };

    // Search Type specifies content type on which to search
    searchType = $scope.shared.searchType = $scope.searchTypeOpts[0];

    // The default filterProps used by the cSearch directive
    defaultFilterProps = [{
      display: 'object',
      property: 'entityIds',
      transformFn: $scope.transformVMnamesToIds,
      locked: false
    }, {
      display: 'objectType',
      property: 'entityTypes',
      transformFn: $scope.transformEntityTypeFilter,
      locked: false
    }, {
      display: 'protectionJob',
      property: 'jobIds',
      transformFn: $scope.jobIdFromName,
      locked: false
    }, {
      display: 'viewBox',
      property: 'viewBoxIds',
      transformFn: $scope.viewBoxIdFromName,
      locked: false
    }, {
      display: 'source',
      property: 'registeredSourceIds',
      transformFn: $scope.sourceIdFromName,
      locked: false
    }, {
      display: 'startDate',
      property: 'fromTimeUsecs',
      transformFn: $scope.formatFilterDate,
      locked: false
    }, {
      display: 'endDate',
      property: 'toTimeUsecs',
      transformFn: $scope.formatFilterDate,
      locked: false
    }, {
      display: 'filesOrFolders',
      property: 'isFolder',
      transformFn: $scope.transformFolderFilter,
      locked: false
    }, {
      property: 'filename',
      primary: true
    }];

    /**
     * @type  Object   Hash of lookup values for the filterProps above to
     * be passed into cSearch. Each map name must match the filter.property
     */
    $scope.shared.filterPropLookups = $scope.shared.filterPropLookups || {};


    // WATCHERS

    // Watch the taskCart for changes and update the filters accordingly
    $scope.$watchCollection('shared.taskCart', updateLockedFilters);

    // Watch the search results for changes (shallow) and do some stuff.
    // See inside for more details.
    $scope.$watch(
      'shared.searchData',
      function searchWatcherFn(nv, ov) {
        if (nv && nv.length) {
          // Update the list of known VMs for lookup in the filters with
          // this search result set.
          updateKnownVMs(nv);
        }
      }
    );

    // Watch the searchType for changes
    // Reset the search
    searchTypeWatcher = $scope.$watch(
      'shared.searchType',
      function searchTypeWatcherFn(nv, ov) {
        if (nv && nv !== ov) {
          updateLockedFilters();
          $scope.shared.searchData.length = 0;
        }
      }
    );

    // Clean up
    $scope.$on('destroy-task', destroySearch);

    // Reset the search after downloading
    $scope.$on('reset-task', resetSearch);


    // METHODS

    /**
     * Determines whether File Recovery is Disabled
     *
     * @return    {Boolean}    true if File Recovery should be disabled
     */
    function isFileRecoveryDisabled() {
      var recoveryStateObj =
        RestoreService.getFileRecoveryDisabledState($scope.shared.taskCart[0]);

      $scope.disableRecoveryMessage = recoveryStateObj.message;

      return recoveryStateObj.isDisabled;
    }

    /**
     * Initialize this view. Gets pre-reqs and does some other stuff
     *
     * @method     searchInit
     */
    function searchInit() {
      var stateParams = $transition$.params();
      fetchDependencies();

      if (stateParams.browseSourceId) {
        $scope.shared.searchType = 'browsableEntities';

        // Setting a pre-defined search query will result in the cSearch
        // directive running a search on initialization, and the function
        // provided via preProcessResultsFn will find and select the desired
        // result for browsing.
        $scope.shared.searchQuery = stateParams.browseSourceName;
      }

      if (!$scope.filterProps || !$scope.filterProps.length) {
        $scope.filterProps = angular.copy(defaultFilterProps);
      }
    }

    /**
     * (Re)initialize the filterPropLookups for use in the search filters
     *
     * @method     initFilterLookups
     * @return     {Void}
     */
    function initFilterLookups() {
      // These aren't the props we're looking for. Move along.
      if (Object.keys($scope.shared.filterPropLookups).length) {
        return;
      }
      $scope.shared.filterPropLookups = {
        registeredSourceIds: [],
        viewBoxIds: [],
        jobIds: [],
        entityIds: [],
        entityTypes: indexableObjectTypes,
        isFolder: [{
          display: $scope.text.foldersOnly,
          value: true
        }, {
          display: $scope.text.filesOnly,
          value: false
        }]
      };
    }

    /**
     * On changing search type, updates the filter options.
     *
     * @method     onChangeSearchType
     */
    $scope.onChangeSearchType = function onChangeSearchType() {
      if ($scope.shared.searchType === 'file') {
        $scope.shared.filterPropLookups.entityTypes = indexableObjectTypes;
        $scope.shared.filterPropLookups.jobIds = $scope.shared.indexableJobIds;
      } else {
        $scope.shared.filterPropLookups.entityTypes = browsableTypes;
        $scope.shared.filterPropLookups.jobIds = $scope.shared.browsableJobIds;
      }
    };

    /**
     * Gets the jobName of a job by the given jobId
     *
     * @method     getJobName
     * @param      {Int}  jobId   JobId we're looking for the name of
     * @return     {String}       The name, or undefined if not found
     */
    function getJobName(jobId) {
      var name;
      if ($scope.shared.filterPropLookups.jobIds) {
        $scope.shared.filterPropLookups.jobIds.some(function jobFinder(_job) {
          if (_job.jobId === jobId) {
            name = _job.name;
          }
          return !!name;
        });
      }
      return name ? name : $scope.text.deletedJob;
    }

    /**
     * Resets the search results
     *
     * @method     resetSearch
     */
    function resetSearch() {
      $scope.shared.searchData.length = 0;
      cSearchService.resetSearch($scope.shared.searchId);
    }

    /**
     * Returns the environment types according to the search type selected by
     * the user.
     *
     * @method   getEnvTypes
     * @param    {String}   searchType   Search type can be 'file' or
     *                                   'browsableEntities'
     * @return   {Array}    Array of environment types
     */
    function getEnvTypes(searchType) {
      var entityTypes = searchType === 'file' ? indexableTypes : browsableTypes;
      return (entityTypes || []).map(function getEntityTypes(entity) {
        return ENV_TYPE_CONVERSION[entity.value];
      });
    }

    /**
     * Pre-fetch several pieces of data we're going to need along the way
     * here.
     *
     * @method     fetchDependencies
     * @return     {Promise}       $q promise
     */
    function fetchDependencies() {
      var promises = {
        // viewBoxes
        viewBoxes: ViewBoxService.getOwnViewBoxes(),

        // ParentSources. We exclude Physical sources because those are not
        // parent sources. They are objects and are filtered using the Objects
        // filter.
        parentSources: SourceService.getEntitiesOfType({
          environmentTypes: [
            'kVMware',
            'kHyperV',
            'kHyperVVSS',
            'kAcropolis',
            'kAzure',
            'kAWS',
            'kGCP',
          ],
          vmwareEntityTypes: [
            'kVCenter',
            'kStandaloneHost',
          ],
          hypervEntityTypes: [
            'kSCVMMServer',
            'kStandaloneHost',
            'kStandaloneCluster',
          ],
          acropolisEntityTypes: [
            'kPrismCentral',
            'kStandaloneCluster',
          ],
          azureEntityTypes: [
            'kSubscription',
          ],
          awsEntityTypes: [
            'kIAMUser',
          ],
          gcpEntityTypes: [
            'kIAMUser',
          ],
        }),

        // All objects that can be indexed
        servers: SourceService.getIndexedObjects(true),

        // Indexable or browsable jobs based on search type
        jobs: JobService.getJobs(),

        // Icebox Vaults
        vaults: $rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW ?
          ExternalTargetService.getTargets() : $q.resolve([]),
      };

      return $q.all(promises)
        .then(function allFetched(responses) {
          var indexableEnvTypes = getEnvTypes('file');
          var browsableEnvTypes = getEnvTypes('browsableEntities');
          var jobs;

          initFilterLookups();

          if (Array.isArray(responses.viewBoxes)) {
            $scope.shared.filterPropLookups.viewBoxIds = responses.viewBoxes;
          }

          if (responses.parentSources) {
            $scope.shared.filterPropLookups.registeredSourceIds =
              responses.parentSources;
          }

          if (Array.isArray(responses.servers)) {
            $scope.shared.filterPropLookups.entityIds = responses.servers;
          }

          if (Array.isArray(responses.jobs)) {
            jobs = responses.jobs.map(
              function augmentJobsFn(job) {
                return angular.merge(job, {
                  jobName: job.name,
                });
              });

            $scope.shared.indexableJobIds = jobs.filter(
              function getIndexableJobs(job) {
                return indexableEnvTypes.includes(job.type);
              }
            );

            $scope.shared.browsableJobIds = jobs.filter(
              function getIndexableJobs(job) {
                return browsableEnvTypes.includes(job.type);
              }
            );

            // Default filter to indexable jobs
            $scope.shared.filterPropLookups.jobIds =
              $scope.shared.indexableJobIds;
          }

          $scope.shared.filterPropLookups.vaults =
            ExternalTargetService.targetNameMapById;
        });
    }

    /**
     * Update the locked filters
     *
     * @method     updateLockedFilters
     */
    function updateLockedFilters() {
      var typedEntity;
      var objectName;

      if ($scope.shared.taskCart.length && !$scope.serverRestricted) {
        typedEntity = SourceService.getTypedEntity(
          $scope.shared.taskCart[0].fileDocument.objectId.entity);
        $scope.serverRestricted = $scope.shared.taskCart[0]._server;

        // lock filterProps for server
        $scope.filterProps.forEach(function enforceFilterLockFn(filter) {
          if (filter.property === 'entityIds') {
            // Note: We do not get name in typed entities in some cases.
            //       Use display name in that case(ENG-59789).
            objectName = get($scope.shared.taskCart[0],
              'fileDocument.objectId.entity.displayName');
            angular.extend(filter, {
              locked: true,
              value: [typedEntity.name || objectName]
            });
            cSearchService.addFilter($scope.shared.searchId, filter);
          }

          // lock protection job filter
          if (filter.property === "jobIds") {
            angular.extend(filter, {
              locked: true,
              value: [
                getJobName(
                  $scope.shared.taskCart[0].fileDocument.objectId
                    .jobId
                )
              ]
            });
            cSearchService.addFilter($scope.shared.searchId, filter);
          }
        });
      } else if (!$scope.shared.taskCart.length) {
        // Clear the lock and delete serverRestriction and remove the filters.
        $scope.serverRestricted = undefined;
        cSearchService.unlockFilters($scope.shared.searchId, true);
      }
      // Enables/Disable filters
      updateDisabledFilterProps();
    }

    /**
     * enables/disables filter props given $scope.shared.searchType
     *
     * @method     updateDisabledFilterProps
     */
    function updateDisabledFilterProps() {
      $scope.filterProps.forEach(
        function enforceFilterLockFn(filter) {
          if (['entityIds', 'isFolder'].includes(filter.property)) {
            assign(filter, {
              disabled: $scope.shared.searchType === $scope.searchTypeOpts[1],
            });
            if (filter.property === 'isFolder' && filter.disabled) {
              cSearchService.removeFilter($scope.shared.searchId, filter);
            }
          }
          if (['fromTimeUsecs', 'toTimeUsecs'].includes(filter.property)) {
            assign(filter, {
              disabled: $scope.shared.searchType === 'file',
            });
          }
          return true;
        }
      );
    }


    /**
     * Destroys this search
     *
     * @method     destroySearch
     */
    function destroySearch() {
      $scope.filterProps = angular.copy(defaultFilterProps);
      cSearchService.destroy($scope.shared.searchId);
    }

    /**
     * Pre-process the file search results
     *
     * @method     preProcessSearchResults
     * @param      {Object}  resp    Server's response
     * @return     {Array}           The list of response data, scrubbed and
     *                               cleaned.
     */
    function preProcessSearchResults(resp) {
      var stateParams = $transition$.params();
      var noResults = {
        message: $scope.text.noSearchResults
      };
      var jobIds = $scope.shared.filterPropLookups.jobIds;
      var out;
      var browseEntity;

      if (resp.data.vms) {
        // If this is a vm search, let's process the results as such
        out = SearchService.processVmsSearchResults(resp, true, false);
      } else {
        // This is a file search, let's process the results as such
        out = SearchService.processFileSearchResults(
          resp, jobIds, $scope.isInCart);
      }

      // If an id was provided for browsing, find it in the list and browse it.
      if (stateParams.browseSourceId && out.length) {
        browseEntity = out.find(function findBrowseEtity(resultItem) {
          return +stateParams.browseSourceId === resultItem._id;
        });
        if (browseEntity) {
          $scope.browseVm(browseEntity);
        }
      }

      return (out.length) ? out : [noResults];
    }

    /**
     * Helper function to update the list of known VMs (for lookup in
     * filtering)
     *
     * @method     updateKnownVMs
     * @param      {Array}  list    List of File Entities to be processed.
     */
    function updateKnownVMs(list) {
      var vmsFromSearch = SourceService.knownVMs(list);
      var mixedList = vmsFromSearch.concat($scope.shared.filterPropLookups.entityIds);
      if (mixedList.length) {
        $scope.shared.filterPropLookups.entityIds = cUtils.dedupe(
          mixedList,
          SourceService.listContainsVM
        );
      }
    }

    // Initialize!
    searchInit();
  }

})(angular);
