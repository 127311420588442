import { assign } from 'lodash-es';
// Component: DB Object Backup Activity

;(function(angular, undefined) {
  'use strict';

  angular.module('C.dbDetails')
    .controller('DbBackupActivityController', DbBackupActivityCtrlFn)
    .component('dbBackupActivity', {
      templateUrl: 'app/dashboard/sql/detail/backup-activity.html',
      controller: 'DbBackupActivityController',
    });

  /**
   * @ngdoc component
   * @name C.dbDetails:dbBackupActivity
   *
   * @description
   * Backup activity stats for a db
   *
   * @example
   * <db-backup-activity></db-backup-activity>
   */
  function DbBackupActivityCtrlFn(_) {

    var $ctrl = this;

    assign($ctrl, {
      $onInit: $onInit,
    });

    /**
     * Initialize the component
     */
    function $onInit() {

    }

  }

})(angular);
