import { map } from 'lodash-es';
// Partition Service

;(function(angular, undefined) {

  angular.module('C').service('PartitionService', PartitionServiceFn);

  function PartitionServiceFn($http, $q, VlanService, API, cUtils, cMessage,
    Upload) {

    return {
      addVipRange: addVipRange,
      createPartition: createPartition,
      deletePartition: deletePartition,
      getHostMapping: getHostMapping,
      getPartition: getPartition,
      getPartitions: getPartitions,
      updateHostMapping: updateHostMapping,
      updatePartition: updatePartition,
      postHostsFile: postHostsFile,
    };

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////
    // Method to Create a new view box
    // params: name, clusterPartionName, storagePolicy (object)
    // response: session cookie, user object
    function createPartition(data) {
      var request = $http({
        method: 'post',
        url: API.private('clusterPartitions'),
        data: data
      });
      return request;
    }

    function updatePartition(data) {
      var request = $http({
        method: 'put',
        url: API.private('clusterPartitions', data.id),
        data: data
      });
      return request;
    }

    /**
     * Get partition information
     *
     * @method   getPartition
     * @param    {number}   partition id
     * @return   {object}   promise to return partion or error
     */
    function getPartition(id) {
      return $http({
        method: 'get',
        url: API.public('clusterPartitions', id),
      }).then(function getPartitionSuccess(response) {

        // transform vlans of the partition
        if(response.data && response.data.vlans) {
          response.data.vlans = map(response.data.vlans,
            VlanService.transformVlan);
        }
        return response;
      });
    }

    function deletePartition(id) {
      var request = $http({
        method: 'delete',
        url: API.private('clusterPartitions', id),
      });
      return request;
    }

    /**
     * provides an array of paritition via an API promise
     *
     * @return {Promise} promise to resolve the API call
     */
    function getPartitions() {

      var deferred = $q.defer();

      $http({
        method: 'get',
        url: API.public('clusterPartitions'),
      }).then(
        getPartitionsSuccess,
        getPartitionsFailure
      );

      function getPartitionsSuccess(response) {
        var partitions = [];

        if (response.data && response.data.length) {
          partitions = response.data;
        }

        deferred.resolve(partitions);
      }

      function getPartitionsFailure(response) {
        deferred.reject(response);
      }

      return deferred.promise;
    }

    /**
     * provides an array of host mapping
     *
     * @return {Promise} promise to resolve the API call
     */
    function getHostMapping() {

      return $http({
        method: 'get',
        url: API.private('nexus/cluster/get_hosts_file'),
      }).then(
        gethostMappingSuccess
      );

      function gethostMappingSuccess(response) {
        return response.data;
      }
    }

    /**
     * Updates the host IP mapping
     *
     * @param     {object}  host mapping object
     * @return    {Promise} promise to resolve the API call
     */
    function updateHostMapping(data) {
      var request = $http({
        method: 'put',
        url: API.private('nexus/cluster/upload_hosts_file'),
        data: data
      });
      return request;
    }

    /**
     * Adds an ip or range of ip addresses (deduped) to the provided array
     * of ip addresses.
     * TODO: implement this on Partition create/edit. Currently only
     * leveraged in Cluster create flow.
     *
     * @param      {string}  ip          the Ip address to add or beginning
     *                                   ip address of range to add
     * @param      {string}  upperLimit  The upper limit of range to add
     * @param      {array}   ipList      The ip list to add ip addresses to
     * @return     {array}   updated list of ip addresses
     */
    function addVipRange(ip, upperLimit, ipList) {

      var lowerLimit = parseInt(ip.split('.')[3], 10);
      var duplicateIps = [];

      ipList = ipList || [];

      // Check for high suffix value
      if (upperLimit) {
        upperLimit = +upperLimit;
        if (upperLimit > lowerLimit) {
          ipList = ipList.concat(cUtils.buildRange(ip, upperLimit));
        }
      } else {
        // single IP
        ipList.push(ip);
      }

      // dedupe the list of IPs
      ipList = ipList.filter(function dedupeList(currentIp, index, self) {
        if (index === self.indexOf(currentIp)) {
          return true;
        }
        duplicateIps.push(currentIp);
      });


      if (duplicateIps.length) {
        // Duplicate IPs were found (and not added). Inform the user.
        cMessage.warn({
          textKey: 'partitionDetails.duplicateIPs',
          textKeyContext: {
            duplicateIPs: duplicateIps,
          },
        });
      }

      return ipList;
    }

    /**
     * Call upload api to upload hosts file
     *
     * @param      {File}   hostsFile      hosts file object
     * @return     promise
     */
    function postHostsFile(hostsFile) {
      return Upload.upload({
        method: 'put',
        url: API.private('network/hostsFile'),
        file: hostsFile
      });
    }
  }

})(angular);
