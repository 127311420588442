import { sortBy, identity, property } from 'lodash-es';
// Module: Alerts Management Notifications

;(function(angular, undefined) {
  'use strict';

  angular.module('C.alerts')
    .controller('alertsTabAlertsCtrl', AlertsTabAlertsCtrlFn);

  function AlertsTabAlertsCtrlFn(_, $q, $translate, $scope, $state,
    AlertService, DateTimeService,
    ENUM_ALERTS_STATUS, ENUM_ALERTS_STATUS_LABEL_CLASSNAME,
    ALERTS_DATE_RANGE_LIST, ALERTS_SEVERITY_LIST,
    ALERTS_TYPE_BUCKET_LIST, FEATURE_FLAGS) {

    $scope.property = property;
    $scope.identity = identity;
    $scope.showNgAlert = FEATURE_FLAGS.ngAlerts;

    $scope.ENUM_ALERTS_STATUS = ENUM_ALERTS_STATUS;
    $scope.ENUM_ALERTS_STATUS_LABEL_CLASSNAME =
      ENUM_ALERTS_STATUS_LABEL_CLASSNAME;
    $scope.ALERTS_DATE_RANGE_LIST = ALERTS_DATE_RANGE_LIST;
    $scope.ALERTS_SEVERITY_LIST = ALERTS_SEVERITY_LIST;
    $scope.ALERTS_TYPE_BUCKET_LIST = ALERTS_TYPE_BUCKET_LIST;

    // number of items to display per table page
    $scope.itemsPerPage = 20;

    // setup our status filter,
    $scope.statusFilters = [
      { name: $translate.instant('open'), value: 'kOpen' },

      // NOT SUPPORTING SUPPRESSION FOR INITIAL RELEASE
      // { name: $translate.instant('suppressed'), selected: false },
      { name: $translate.instant('resolved'), value: 'kResolved' },
      { name: $translate.instant('note'), value: 'kNote' },
    ];

    $scope.categoryFilters = [];

    $scope.alertFilters.alertStateList = [$scope.statusFilters[0].value];
    $scope.alertFilters.alertCategoryList = [];
    $scope.alertFilters.alertSeverityList = [];
    $scope.alertFilters.alertTypeBucketList = [];

    /**
     * updates the value of the filter start date, if it has actually changed
     * @param  {Object} newDate updated date value
     */
    $scope.updateFilterStartDate = function updateFilterStartDate(newDate) {
      var newDateObject = DateTimeService.beginningOfDay(new Date(newDate));

      if(newDateObject.getTime() !== $scope.alertFilters.startDate.getTime()) {
        $scope.alertFilters.startDate = newDateObject;
      }
      // if our start date is greater than our end date, change the end date
      if ($scope.alertFilters.startDate > $scope.alertFilters.endDate) {
        $scope.updateFilterEndDate($scope.alertFilters.startDate);
      }
    };

    /**
     * updates the value of the filter end date, if it has actually changed
     * @param  {Object} newDate updated date value
     */
    $scope.updateFilterEndDate = function updateFilterEndDate(newDate) {
      var newDateObject = DateTimeService.endOfDay(new Date(newDate));

      if(newDateObject.getTime() !== $scope.alertFilters.endDate.getTime()) {
        $scope.alertFilters.endDate = newDateObject;
      }
    };

    /**
     * clear the selected alerts
     */
    var clearSelected = function clearSelected() {
      while($scope.selectedAlerts.length) {
        $scope.selectedAlerts.pop();
      }
    };

    /**
     * select all alerts currently in $scope.filteredAlerts
     */
    $scope.selectAll = function selectAll() {
      clearSelected();
      angular.forEach($scope.filteredAlerts, function(currentAlert, index) {
        // only add open alerts to selection
        if(currentAlert.alertState === 'kOpen') {
          currentAlert.$selected = true;
          $scope.selectedAlerts.push(currentAlert);
        }
      });
    };

    /**
     * unselect all alerts currently in $scope.filteredAlerts
     */
    $scope.selectNone = function selectNone() {
      clearSelected();
      angular.forEach($scope.filteredAlerts, function(currentAlert, index) {
        currentAlert.$selected = false;
      });
    };

    /**
     * select a specific alert
     * @param  {Object} theAlert the alert to be selected
     */
    $scope.selectAlert = function selectAlert(theAlert) {
      theAlert.$selected = true;
      $scope.selectedAlerts.push(theAlert);
    };

    /**
     * uneselect a specific alert
     * @param  {Object} theAlert the alert to be unselected
     */
    $scope.unselectAlert = function unselectAlert(theAlert) {
      angular.forEach($scope.selectedAlerts, function(currentAlert, index) {
        if(currentAlert === theAlert) {
          $scope.selectedAlerts.splice(index, 1);
          return;
        }
      });
      theAlert.$selected = false;
    };

    /**
     * toggle a specific alerts selection status
     * @param  {Object} theAlert the alert to toggle selection for
     */
    $scope.toggleSelection = function toggleSelection(theAlert) {
      if(theAlert.$selected) {
        $scope.unselectAlert(theAlert);
      } else {
        $scope.selectAlert(theAlert);
      }
    };

    /**
     * start the resolve flow for a single alert
     * @param  {Object} theAlert the alert to be resolved
     */
    $scope.singleResolve = function singleResolve(theAlert) {
      clearSelected();
      $scope.selectAlert(theAlert);
      $scope.resolve();
    };

    /**
     * start the suppress flow for a single alert
     * @param  {Object} theAlert the alert to be suppressed
     */
    $scope.singleSuppress = function singleSuppress(theAlert) {
      clearSelected();
      $scope.selectAlert(theAlert);
      $scope.suppress();
    };

    /**
     * start the resolution flow by
     * going to the resolve state
     */
    $scope.resolve = function resolve() {
      $state.go('alerts.resolve');
    };

    /**
     * start the suppression flow by
     * going to the suppress state
     */
    $scope.suppress = function suppress() {
      $state.go('alerts.suppress');
    };

    /**
     * initialization function to be run on
     * each load of the controller
     */
    function init() {
      // clear out any previously selected alerts.
      clearSelected();
      AlertService.getAlertCategories(true).then(
        function fetchedAlertCategories(categoriesEnum) {
          $scope.alertCategoriesEnum = categoriesEnum;
          $scope.categoryFilters =
            sortBy(AlertService.getCategoryFilters(), 'name');
        }
      );
    }

    // initialize the controller
    init();
  }

})(angular);
