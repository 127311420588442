import { Injectable } from '@angular/core';
import { AjaxHandlerService } from '@cohesity/utils';
import { Transition } from '@uirouter/core';
import { from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AjsClusterService } from 'src/app/shared/ng-upgrade/services';

import { AppStateService, RemoteClusterService, ScopeSelectorService, UserService } from '../../services';
import { GuardPriority, StateGuard } from '../state-guard';

/**
 * ScopeGuard is responsible for ensuring a scope is selected/set during
 * initialization of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class ScopeGuard implements StateGuard {
  /**
   * This guard should be run before gaurds with App priority
   */
  guardPriority = GuardPriority.InitialScopeSelection;


  constructor(
    private ajsClusterService: AjsClusterService,
    private ajsEvalAjaxService: AjaxHandlerService,
    private appStateService: AppStateService,
    private remoteClusterService: RemoteClusterService,
    private scopeSelectorService: ScopeSelectorService,
    private userService: UserService
  ) {}

  /**
   * Perform state access check and hydrate necessary iris shared states like
   * 1. selected remote cluster scope.
   * 2. list of remote cluster.
   *
   * @param  trans  The current Transition object.
   */
  onStart(trans: Transition) {
    // do nothing if not logged or cluster is not created yet because app.js is taking care of those currently.
    if (!this.userService.loginData || !this.ajsClusterService.clusterState.found) {
      return true;
    }

    // If a scope is selected all is good...
    if (this.appStateService.selectedScope) {
      return true;
    }

    // Do not fetch clusters for Self Service user.
    if (this.userService.isSelfServiceUser()) {
      return true;
    }

    // ... otherwise perform initial cluster scope selection.
    return this.remoteClusterService.getRemoteClustersList().pipe(
      switchMap(remoteClusterList => {
        this.appStateService.remoteClusterList = remoteClusterList;

        return from(this.scopeSelectorService.performInitialSelection(trans));
      }),
      catchError(error => {
        this.ajsEvalAjaxService.errorMessage(error);
        return null;
      }),
    );
  }
}
