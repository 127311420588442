import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { BannerModule } from '../banner/banner.module';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { LegendIndicatorModule } from '../legend-indicator/legend-indicator.module';
import { PageModule } from '../page/page.module';
import { ScrollIntoViewDirective } from '../scroll-into-view';
import { DateRangePickerModule } from './../date-range-picker/date-range-picker.module';
import { AppliedFiltersComponent } from './applied-filters/applied-filters.component';
import { DateRangeFilterComponent } from './built-in/date-range-filter/date-range-filter.component';
import {
  NestedValuePropertyFilterComponent,
} from './built-in/nested-value-property-filter/nested-value-property-filter.component';
import { NumberPropertyFilterComponent } from './built-in/number-property-filter/number-property-filter.component';
import { SearchPropertyFilterComponent } from './built-in/search-property-filter/search-property-filter.component';
import {
  TreeSelectValuePropertyFilterComponent,
} from './built-in/tree-select-value-property-filter/tree-select-value-property-filter.component';
import { ValuePropertyFilterComponent } from './built-in/value-property-filter/value-property-filter.component';
import { FilterDefDirective } from './filters/filter-def.directive';
import { FiltersComponent } from './filters/filters.component';
import { QuickFilterButtonComponent } from './filters/quick-filter-button/quick-filter-button.component';
import { QuickFilterComponent } from './filters/quick-filter/quick-filter.component';

const filterComponents = [
  AppliedFiltersComponent,
  DateRangeFilterComponent,
  FilterDefDirective,
  FiltersComponent,
  NestedValuePropertyFilterComponent,
  NumberPropertyFilterComponent,
  QuickFilterButtonComponent,
  QuickFilterComponent,
  SearchPropertyFilterComponent,
  TreeSelectValuePropertyFilterComponent,
  ValuePropertyFilterComponent,
];

@NgModule({
  imports: [
    AngularMaterialModule,
    BannerModule,
    CommonModule,
    DataIdModule,
    DateRangePickerModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    IconModule,
    LegendIndicatorModule,
    PageModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ScrollIntoViewDirective,
    TranslateModule,
  ],
  declarations: filterComponents,
  exports: filterComponents,
})
export class DataFiltersModule {}
