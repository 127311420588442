import { some } from 'lodash-es';
import { map } from 'lodash-es';
import { assign } from 'lodash-es';
// Module and Component: dedupSourceExclusion

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.dedupSourceExclusion';
  var componentName = 'dedupSourceExclusion';

  var options = {
    bindings: {
      /**
       * Required protection job setting.
       *
       * @type   {Object[]}   source    selected source for this protection job
       */
      selectedSources: '<',
    },
    require: { ngModel: 'ngModel' },
    controller: 'DedupSourceExclusionCtrl',
    templateUrl:
      'app/protection/jobs/modify2/dedup-source-exclusion/dedup-source-exclusion.html'
  }

  angular
    .module(moduleName, [])
    .controller('DedupSourceExclusionCtrl', DedupSourceExclusionCtrl)
    .component(componentName, options);


  /**
   * @ngdoc component
   * @name C.dedupSourceExclusion:dedupSourceExclusion
   * @function
   *
   * @description
   * A source exclusion component used when creating a job
   *
   * @example
   * <dedup-source-exclusion
   *    ng-model="$ctrl.job.dedupDisabledSourceIdVec"
   *    selected-sources="$ctrl.job._selectedSources">
   * </dedup-source-exclusion>
   *
   */
  function DedupSourceExclusionCtrl(_) {
    var $ctrl = this;

    assign($ctrl, {
      // component life cycle methods
      $onInit: $onInit,
      $onChanges: $onChanges,

      // controller methods
      syncModel: syncModel,
      removeExcludedSource: removeExcludedSource,
      toggleShowExclusionInput: toggleShowExclusionInput,
      filterSources: filterSources,
      onAddSourceSideDedupExclusion: onAddSourceSideDedupExclusion,

      shouldShowAddExclusionDropdown: false
    });

    /**
     * Initialization function
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.ngModel.$render = $ctrl.syncModel;
    }

    /**
     * binding change listener,
     *
     * @method   $onChanges
     * @param    {object}   changesObj   The changes object
     */
    function $onChanges(changesObj) {
      //check if selectedSources changed
      if (changesObj.selectedSources) {
        updateModel();
      }
    }

     /**
     * updates $ctrl.dedupDisabledSourceIds if it contains sources which are
     * not selected.
     *
     * @method   updateModel
     */
    function updateModel() {
      // get sourceIds array from $ctrl.selectedSources
      var selectedSourceIds = map($ctrl.selectedSources,
        'protectionSource.id');

      // check if $ctrl.dedupDisabledSourceIds contains sources which are
      // not selected.
      var doesContainInvalidId = some($ctrl.dedupDisabledSourceIds,
        function doesContainInvalidIdFilterFn(item) {
          return !selectedSourceIds.includes(item);
        }
      );

      if (doesContainInvalidId) {
        $ctrl.dedupDisabledSourceIds = $ctrl.dedupDisabledSourceIds.filter(
          function dedupDisabledSourceIdsFilterFn(item) {
            return selectedSourceIds.includes(item);
          }
        );

        //update the model
        $ctrl.ngModel.$setViewValue($ctrl.dedupDisabledSourceIds);
      }
    }

    /**
     * Sync internal and external model values.
     *
     * @method   syncModel
     * @param    {string}   [value]   Input value
     */
    function syncModel(value) {
      if (arguments.length) {
        $ctrl.ngModel.$setViewValue(value);
      } else {
        $ctrl.dedupDisabledSourceIds = $ctrl.ngModel.$viewValue;
      }
    }

    /**
     * event handler for excluded source delete button
     *
     * @method   removeExcludedSource
     * @param    {integer}   sourceId   sourceId of removed source
     */
    function removeExcludedSource(sourceId) {
      $ctrl.dedupDisabledSourceIds = $ctrl.dedupDisabledSourceIds.filter(
        function dedupDisabledSourceIdsFilterFn(item) {
          return item !== sourceId;
        }
      );

      $ctrl.ngModel.$setViewValue($ctrl.dedupDisabledSourceIds);
    }

    /**
     * toggle visibility of sources dropdown
     *
     * @method   toggleShowExclusionInput
     */
    function toggleShowExclusionInput() {
      $ctrl.shouldShowAddExclusionDropdown =
        !$ctrl.shouldShowAddExclusionDropdown;
    }

    /**
     * filter used with ui-select to remove already selected sources
     *
     * @method   filterSources
     * @param    {object}   item   source array item
     */
    function filterSources(item) {
      return !$ctrl.dedupDisabledSourceIds.includes(item.protectionSource.id);
    }

    /**
     * on-change handler for ui-select, handles adding selected source to
     * $ctrl.dedupDisabledSourceIds and updating the model
     *
     * @method   onAddSourceSideDedupExclusion
     * @param    {object}   item   source array item
     */
    function onAddSourceSideDedupExclusion() {
      var source = $ctrl.addSourceToSourceSideDedupExclusion;
      $ctrl.dedupDisabledSourceIds.push(source.protectionSource.id);
      $ctrl.ngModel.$setViewValue($ctrl.dedupDisabledSourceIds);
      $ctrl.addSourceToSourceSideDedupExclusion = null;

      // if all sources are selected, hide the add source exclusion dropdown
      if ($ctrl.selectedSources.length ===
        $ctrl.dedupDisabledSourceIds.length) {
        $ctrl.shouldShowAddExclusionDropdown = false;
      }
    }
  }

})(angular);
