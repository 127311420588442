import { Injectable } from '@angular/core';
import { SearchFileRequestParamsBase, SearchServiceApi } from '@cohesity/api/v2';
import { IrisContextService, isMcm } from '@cohesity/iris-core';
import { Office365LeafNodeType } from '@cohesity/iris-shared-constants';
import { BehaviorSubject } from 'rxjs';
import { DmsService } from 'src/app/core/services';
import { Environment } from 'src/app/shared/constants';

import { GlobalSearchEnvironmentFilters, GlobalSearchGroupedType } from '../global-search-filter-options.constants';
import { GlobalSearchFilters } from '../models/global-search-filters.model';

/**
 * Interface for result of file filters helper function.
 */
interface FileFilterParams {
  /**
   * The params foe fileParams key in the request.
   */
  fileParams: SearchFileRequestParamsBase;

  /**
   * The array of clusters where to get file results from.
   */
  clusterIdentifiers: string[];
}

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchFiltersService {
  /**
   * Behavior subject to hold the current filters for the search results.
   */
  private _filters$ = new BehaviorSubject<GlobalSearchFilters>({});

  /**
   * Observable to emit current filters for the search results.
   */
  filters$ = this._filters$.asObservable();

  /**
   * Function to get the current filters for the search results.
   */
  get filters(): GlobalSearchFilters {
    return this._filters$.value;
  }

  /**
   * Function to set the current filters for the search results.
   */
  set filters(value: GlobalSearchFilters) {
    this._filters$.next(value);
  }

  constructor(
    private irisContextService: IrisContextService,
    private dmsService: DmsService,
  ) {}

  /**
   * Function return params for the indexedObjects API call from a global
   * search filters object.
   *
   * @param filters The global search filters object.
   * @return The partial params objects which contain the filters.
   */
  getFileParamsFromFilters(filters: GlobalSearchFilters = {}): FileFilterParams {
    const params: FileFilterParams = {
      fileParams: {
        sourceEnvironments: [],
        types: [],
      },
      clusterIdentifiers: [],
    };

    if (filters.type) {
      for (const type of filters.type) {
        if (GlobalSearchEnvironmentFilters[type]) {
          // If this filter has nested environment options, use those.
          params.fileParams.sourceEnvironments.push(
            ...this.getTypeFilterEnvironments(filters, type as GlobalSearchGroupedType) as any[]
          );
        } else {
          // Otherwise add the filter value directly.
          params.fileParams.sourceEnvironments.push(type as any);
        }
      }
    }

    if (filters.fileType) {
      params.fileParams.types.push(...filters.fileType);
    }

    if (filters.cluster) {
      params.clusterIdentifiers.push(...filters.cluster);
    }

    return params;
  }

  /**
   * Function return params for the searchObjects API call from a global
   * search filters object.
   *
   * @param filters The global search filters object.
   * @return The partial params objects which contain the filters.
   */
  getObjectParamsFromFilters(
    filters: GlobalSearchFilters = {}
  ): SearchServiceApi.SearchObjectsParams {
    const params = {
      clusterIdentifiers: [],
      environments: [],
      lastRunStatusList: [],
      o365ObjectTypes: [],
      azureObjectTypes: [],
      osTypes: [],
      protectionStatusList: [],
      protectionTypes: [],
      regionIds: [],
      sourceIds: [],
      sourceUuids: [],
      tenantIds: [],
    } as SearchServiceApi.SearchObjectsParams;

    if (filters.type) {
      for (const type of filters.type) {
        if (GlobalSearchEnvironmentFilters[type]) {

          if (type === 'm365') {
            // If no sub object type is selected with M365, just send kO365
            // environment filter. Otherwise, also send the sub object type
            // filters.
            const values = filters[GlobalSearchEnvironmentFilters.m365.stateParamName] || [];

            params.environments.push(Environment.kO365);

            if (values.length) {
              params.o365ObjectTypes.push(...values);

              // This is needed for kO365Exchange and kO365OneDrive as they are
              // global_entity index in ES only. We still need to add the actual
              // Magneto's leaf entity which is kUser for backward compatibility.
              if (values.includes(Office365LeafNodeType.kO365Exchange) ||
                values.includes(Office365LeafNodeType.kO365OneDrive)) {
                params.o365ObjectTypes.push(Office365LeafNodeType.kUser);
              }
            }
          } else if (type === 'azure') {
            const azureObjectTypes = filters[GlobalSearchEnvironmentFilters.azure.stateParamName] || [];

            params.environments.push(Environment.kAzure);

            if (azureObjectTypes.length) {
              params.azureObjectTypes.push(...azureObjectTypes);
            }
          } else {
            const values = this.getTypeFilterEnvironments(filters, type as GlobalSearchGroupedType)  as any[];

            // If this filter has nested environment options, use those.
            params.environments.push(...values);
          }
        } else {
          // Otherwise add the filter value directly.
          params.environments.push(type as any);
        }
      }
    }

    if (filters.sourceIds) {
      for (const id of filters.sourceIds) {
        if (isMcm(this.irisContextService.irisContext)) {
          // MCM uses v2 source which has string source ids, these should go to
          // sourceUuids[].
          params.sourceUuids.push(String(id));
        } else {
          // OnPrem uses v1 source has number source ids, these should go to
          // sourceIds[].
          params.sourceIds.push(Number(id));
        }
      }
    }

    if (filters.protectionType) {
      params.protectionTypes.push(...filters.protectionType);
    }

    if (filters.os) {
      params.osTypes.push(...filters.os);
    }

    if (filters.region) {
      params.regionIds.push(...filters.region);
    }

    if (filters.cluster) {
      params.clusterIdentifiers.push(...filters.cluster);
    }

    if (filters.lastRun?.length) {
      params.lastRunStatusList.push(...filters.lastRun);
    }

    if (filters.status?.includes('unavailable')) {
      params.isDeleted = true;
    }

    const hasProtected = filters.status?.includes('protected');
    const hasUnprotected = filters.status?.includes('unprotected');

    if (hasProtected || hasUnprotected) {
      // The UI has two separate "Protected" and "Unprotected" checkboxes, while
      // the API filter is a "isProtected" boolean filter.
      // If both of checkboxes are selected, it is same as not passing
      // "isProtected" filter.
      if (hasProtected && !hasUnprotected) {
        params.isProtected = true;
      } else if (!hasProtected && hasUnprotected) {
        params.isProtected = false;
      }
    }

    if (filters.tenant) {
      params.tenantIds.push(...filters.tenant);
    }

    return params;
  }

  /**
   * Function to return the environments to filter results on based on the
   * provided type.
   *
   * @param filters The global search filters object.
   * @param type The type of the filter.
   * @return The partial params objects which contain the env filters.
   */
  getTypeFilterEnvironments(filters: GlobalSearchFilters, type: GlobalSearchGroupedType): Environment[] {
    const {stateParamName, environments} = GlobalSearchEnvironmentFilters[type];

    if ((filters[stateParamName] || []).length) {
      // If specific environments are selected, filter on those.
      return filters[stateParamName];
    }

    // Otherwise filter on all environments.
    return environments;
  }
}
